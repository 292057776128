import axios from 'axios'
import apiDetails from './apiDetails'
import apiList from './apiList'

var auditLog = {};

const activity = {
    CFR_AR : { code: 'CFR-AR', name: 'Approve Report', module: 'CFR', type: 2 },
    CFR_CR : { code: 'CFR-CR', name: 'Cancel Report', module: 'CFR', type: 2 },
    CFR_DR : { code: 'CFR-DR', name: 'Disapprove Report', module: 'CFR', type: 2 },
    CFR_EDR : { code: 'CFR-EDR', name: 'Email Draft Report', module: 'CFR', type: 2 },
    CFR_EFR : { code: 'CFR-EFR', name: 'Email Final Report', module: 'CFR', type: 2 },
    CFR_ER : { code: 'CFR-ER', name: 'Edit Report', module: 'CFR', type: 2 },
    CFR_RR : { code: 'CFR-RR', name: 'Reopen Report', module: 'CFR', type: 2 },
    CFR_SR : { code: 'CFR-SR', name: 'Started Review', module: 'CFR', type: 1 },
    DC_ED : { code: 'DC-ED', name: 'Edit Data', module: 'DataCapture', type: 2 },
    DC_SFR : { code: 'DC-SFR', name: 'Submit Form for Review', module: 'DataCapture', type: 3 },
    DC_WSC : { code: 'DC-WSC', name: 'WO Status Changed', module: 'DataCapture', type: 3 },
    J_CJ : { code: 'J-CJ', name: 'Created Job', module: 'Job', type: 1 },
    J_EJ : { code: 'J-EJ', name: 'Edit Job', module: 'Job', type: 1 },
    S_NS : { code: 'S-NS', name: 'New Schedule', module: 'Scheduler', type: 1 },
    S_URA : { code: 'S-URA', name: 'Updated Resource Assignment', module: 'Scheduler', type: 2 },
    S_US : { code: 'S-US', name: 'Updated Schedule', module: 'Scheduler', type: 2 },
    WO_CW : { code: 'WO-CW', name: 'Created Work Order', module: 'WorkOrder', type: 1 },
    WO_EW : { code: 'WO-EW', name: 'Edit Work Order', module: 'WorkOrder', type: 2 },
    DC_URA : { code: 'DC-URA', name: 'Update Resource Assignment', module: 'DataCapture', type: 2 },
    DC_SLI : { code: 'DC-SLI', name: 'Submit To Lead Inspector', module: 'DataCapture', type: 2 }

}

const sections = [
    { id: 1, name: 'Quantity Verification' },
    { id: 2, name: 'Functional Check Measurement' },
    { id: 3, name: 'Package Check' },
    { id: 4, name: 'Barcode Verification' },
    { id: 5, name: 'Workmanship' },
    { id: 6, name: 'Onsite Tests' },
    { id: 7, name: 'General' },
    { id: 8, name: 'Supplier/Site' },
    { id: 9, name: 'Sampling' },
    { id: 10, name: 'Product' },
    { id: 11, name: 'Stock Inspected' },
    { id: 12, name: 'Remarks and Comments' },
    { id: 13, name: 'CDF Listed Component Verification Report' },
    { id: 14, name: 'Serial Number Sampled' },
    { id: 15, name: 'Sample Carton Number' },
    { id: 16, name: 'Inpsection Summary' },
    { id: 17, name: 'Others' },
];
/** list of routes that will trigger and log audit changes
 * This is the routes when opening the work order
 */
const formRoutesToLog = [
    'dynamicFormId',
    'workOrderDetailsId',
    'uploadPhotoGalleryId',
    'uploadPhotoGalleryId',
    'workOrderInspectionQuantityId',
    'workOrderInspectionSizeId',
    'workOrderInspectionPackageId',
    'workOrderInspectionBarcodeId',
    'workOrderInspectionWorkmanshipId',
    'workOrderInspectionOnsiteId',
    'workOrderDefectId',
    'workOrderDefectEditId',
    'uploadPhotoInspectionId',
    'pastInspectionsSignOff',
    'workOrderRemarksId',
    'workOrderDefectAddDefectPhotoId',
    'uploadPhotoGeneralId',
    'workOrderInspectionProductConformityId'
];

/** Routes to log but need to reset the header values when coming to this  route */
const routesToLogReset = [
    'assignmentId',
    'workOrderId'
]

/** this object that contains the static activity types */
auditLog.activity = activity;

/** a function that will check if the current route will log audit changes */
auditLog.isInFormRoute = function (routeName) {
    return formRoutesToLog.indexOf(routeName) !== -1;
}
auditLog.isInResetRoute = function (routeName) {
    return routesToLogReset.indexOf(routeName) !== -1;
}
/** */
auditLog.resetTransactionCodeIfInRoute = function(url) {
    let currentRoute = window.sessionStorage.getItem('currentRoute');
    url = url.toLowerCase();
    if ((currentRoute === 'workOrderId' || currentRoute === 'assignmentId') &&
        (url.indexOf(apiList.assignmentUpdate.toLowerCase()) !== -1
            || url.indexOf(apiList.assignmentListUpdate.toLowerCase()) !== -1
            || url.indexOf(apiList.upsertSectionAssignment.toLowerCase()) !== -1
        )) {
        auditLog.setAuditHeaders({transactionCode: this.getUniqueIdentifier(), entityKey: null});
    }
}


auditLog.disableAutoLogginOnLoad = function() {
    window.sessionStorage.setItem('EditDataLogging', false);
}


/**
 * a function that will set the header for each api requests
 * @param {object} params       parameter that contains transactionCode, entityKey
 */
auditLog.setAuditHeaders = function(params) {
    let _transactionCode = this.getUniqueIdentifier();
    if (params.transactionCode) {
        _transactionCode = params.transactionCode;
    }
    else if (window.sessionStorage.getItem('transactionCode')) {
        _transactionCode = window.sessionStorage.getItem('transactionCode');
    }


    const currentEntityKey = window.sessionStorage.getItem('entityKey');
    let _entityKey = params.entityKey || currentEntityKey;
    if (!_entityKey) {
        const workOrder = JSON.parse(window.sessionStorage.workOrderDetails || null);
        _entityKey = workOrder.workOrderId;
    }

    window.sessionStorage.setItem('transactionCode', _transactionCode);
    window.sessionStorage.setItem('userAction', '' );
    window.sessionStorage.setItem('appName', 'DataCapture' );
    window.sessionStorage.setItem('module', 'DataCapture' );
    window.sessionStorage.setItem('entityKey', _entityKey );
    window.sessionStorage.removeItem('EditDataLogging');
    window.sessionStorage.removeItem('EditDataLoggingGeneral');

}

/** a function that will clear the audit related headers for each request */
auditLog.removeAuditHeader = function() {
    window.sessionStorage.setItem('appName', 'DataCapture' );
    window.sessionStorage.setItem('module', 'DataCapture' );
    window.sessionStorage.removeItem('transactionCode');
    window.sessionStorage.removeItem('userAction',);
    window.sessionStorage.removeItem('entityKey');
    window.sessionStorage.removeItem('currentUpload');
    window.sessionStorage.removeItem('deletedPhotoCount');
    window.sessionStorage.removeItem('EditDataLogging');
    window.sessionStorage.removeItem('EditDataLoggingGeneral');
    window.sessionStorage.removeItem('EditDataPage');
    window.sessionStorage.removeItem('DefectId');
    window.sessionStorage.removeItem('WorkOrderProductId');
}

/** get the audit header object */
auditLog.getHeaderDetails = function() {
    return {
        transactionCode: window.sessionStorage.getItem('transactionCode') || '',
        appName: window.sessionStorage.getItem('appName') || '',
        module: window.sessionStorage.getItem('appName') || '',
        userAction: window.sessionStorage.getItem('userAction') || '',
        entityKey: window.sessionStorage.getItem('entityKey') || '',
        resourceId: window.localStorage.getItem('resourceID') || '',

        editDataLogging: window.sessionStorage.getItem('EditDataLogging') || '',
        editDataLoggingGeneral: window.sessionStorage.getItem('EditDataLoggingGeneral') || '',
        sectionId: window.sessionStorage.getItem('SectionId') || '',
        workOrderProductId: window.sessionStorage.getItem('WorkOrderProductId') || '',
        imageDescription: window.sessionStorage.getItem('ImageDescription') || '',
        defectId: window.sessionStorage.getItem('DefectId') || '',
    };
}

/** a function that will generate a unique transaction code */
auditLog.getUniqueIdentifier = function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

auditLog.resetTransactionCode = function() {
    window.sessionStorage.setItem('transactionCode', auditLog.getUniqueIdentifier());
}


/**
 * A function that will create an activity object an will send to api
 * @param {object}  workOrder       the workOrder object that contains workOrder details
 * @param {code}    code            (optional) the current type of activity
 */
auditLog.createActivity = async function(headers, url, requestData, responseData) {
    const workOrder = JSON.parse(window.sessionStorage.workOrderDetails || null);
    if (!headers.EntityKey && !workOrder) {
        console.log('Can not create an Activity log. WorkOrder details missing.');
        return;
    }

    let groupTransactionCode = headers.TransactionCode;
    let currentRoute = window.sessionStorage.getItem('currentRoute');
    let description = null;
    let addedActionText = null;
    let entityKey = headers.EntityKey || workOrder.workOrderId;
    let customNewValue = null;
    let customOldValue = null;
    //let activities = [];
    url = url.toLowerCase();

    let activity = {
        module: 'DataCapture',
        transactionCode: this.getUniqueIdentifier(),
        groupTransactionCode: groupTransactionCode || window.sessionStorage.getItem('transactionCode'),
        entity: 'WorkOrder',
        description: description,
        addedActionText: addedActionText,
        customOldValue,
        customNewValue,
        entityKey: entityKey
    };

    if ((currentRoute === 'workOrderId' || currentRoute === 'assignmentId') &&
        (url.indexOf(apiList.assignmentUpdate.toLowerCase()) !== -1
        || url.indexOf(apiList.assignmentListUpdate.toLowerCase()) !== -1)) {

        const noURALogging = window.sessionStorage.getItem('updateResourceNoLogging');
        const workOrderDetail = JSON.parse(window.sessionStorage.getItem('workOrderDetails') || null);
        if ((!noURALogging || noURALogging === 'false') && workOrderDetail && workOrderDetail.resources.length > 1) {
            activity.activityCode = this.activity.DC_URA.code;

            // activities.push(activity);
            this.postActivity(activity);
        }

        window.sessionStorage.setItem('updateResourceNoLogging', false);
    }
    else if (url.indexOf(apiList.patchWorkOrderResults.toLowerCase()) !== -1
        || url.indexOf(apiList.upsertProductResult.toLowerCase()) !== -1
        || url.indexOf(apiList.upsertWorkOrderRemarks.toLowerCase()) !== -1
        || url.indexOf(apiList.upsertWorkOrderOnSiteTests.toLowerCase()) !== -1

        || url.indexOf(apiList.createWorkOrderCDFReport.toLowerCase()) !== -1
        || url.indexOf(apiList.updateWorkOrderCDF.toLowerCase()) !== -1
        || url.indexOf(apiList.deleteWorkOrderCDF.toLowerCase()) !== -1

        || url.indexOf(apiList.createWorkOrderSerialNumberSampled.toLowerCase()) !== -1
        || url.indexOf(apiList.updateWorkOrderSerialNumberSampled.toLowerCase()) !== -1
        || url.indexOf(apiList.deleteWorkOrderSerialNumberSampled.toLowerCase()) !== -1

        || url.indexOf(apiList.createWorkOrderSampledCartonNumber.toLowerCase()) !== -1
        || url.indexOf(apiList.updateWorkOrderSampledCartonNumber.toLowerCase()) !== -1
        || url.indexOf(apiList.DeleteWorkOrderSampledCartonNumber.toLowerCase()) !== -1

        || url.indexOf(apiList.updateWorkOrderProductDefects.toLowerCase()) !== -1
        || url.indexOf(apiList.upsertWorkOrderProductBarcodeVerificationResult.toLowerCase()) !== -1
        || url.indexOf(apiList.upsertWorkOrderBarcodeVerification.toLowerCase()) !== -1
        || url.indexOf(apiList.upsertWorkOrderSizeMeasurement.toLowerCase()) !== -1
        || url.indexOf(apiList.updateWorkOrderProduct.toLowerCase()) !== -1
        || url.indexOf(apiList.upsertWorkOrderProduct.toLowerCase()) !== -1
    ) {

        // Log activity if api has been called,
        // if specifically in WorkOrderDetails page, check if editDataLogging is true --- this is to determine if any changes has been made
        // const editDataLogging = window.sessionStorage.getItem('EditDataLogging');
        if (headers.EditDataLogging == 'true') {
            activity.activityCode = this.activity.DC_ED.code;
            activity.entityKey = headers.EntityKey;
            window.sessionStorage.setItem('EditDataLogging', false);

            // activities.push(activity);
            this.postActivity(activity);
        }

        // work instruction transaction code
        const wiTransactionCode = window.sessionStorage.getItem('workInstructionTransactionCode');
        if (wiTransactionCode != null) {
            activity.activityCode = this.activity.DC_ED.code;
            activity.entityKey = headers.EntityKey;

            // activities.push(activity);
            this.postActivity(activity);
        }

        window.sessionStorage.removeItem('workInstructionTransactionCode');

    }
    else if (url.indexOf(apiList.upsertWorkOrderDataCaptureFields.toLowerCase()) !== -1) {
        if (headers.EditDataLoggingGeneral == 'true') {
            activity.activityCode = this.activity.DC_ED.code;
            activity.entityKey = headers.EntityKey;
            window.sessionStorage.removeItem('EditDataLoggingGeneral');

            // activities.push(activity);
            this.postActivity(activity);
        }
    }


    // Section Upload
    else if (url.indexOf(apiList.uploadWorkOrderImage.toLowerCase()) !== -1) {
        const sectionId = headers.SectionId || 1;
        const index = sections.findIndex(x => x.id == sectionId);
        const sectionName = index != -1 ? sections[index].name : '';

        activity.activityCode = this.activity.DC_ED.code;
        activity.groupTransactionCode = headers.TransactionCode;
        activity.description = JSON.stringify({ UploadedPhotos:  1, SectionId: +sectionId, SectionName: sectionName });
        // activities.push(activity);
        this.postActivity(activity);
    }
    // Defect Upload
    else if (url.indexOf(apiList.uploadWorkOrderProductDefectImage.toLowerCase()) !== -1) {
        const defectId = headers.DefectId;
        const woProductId = headers.WorkOrderProductId;

        activity.activityCode = this.activity.DC_ED.code;
        activity.groupTransactionCode = headers.TransactionCode;
        activity.description = JSON.stringify({ UploadedPhotos:  1, DefectId: defectId, WorkOrderProductId: woProductId  });
        // activities.push(activity);
        this.postActivity(activity);
    }
    // Product Upload
    else if (url.indexOf(apiList.uploadWorkOrderProductImage.toLowerCase()) !== -1) {
        const woProductId = headers.WorkOrderProductId;
        const _description = { UploadedPhotos:  1, WorkOrderProductId: woProductId};

        if (headers.ImageDescription) {
            _description.Description = headers.ImageDescription;
        }

        activity.activityCode = this.activity.DC_ED.code;
        activity.groupTransactionCode = headers.TransactionCode;
        activity.description = JSON.stringify(_description);
        // activities.push(activity);
        this.postActivity(activity);
    }
    else if (url.indexOf(apiList.deleteImage.toLowerCase()) !== -1) {
        if (headers.DefectId) {
            const defectId = headers.DefectId;
            const woProductId = headers.WorkOrderProductId;
            activity.description = JSON.stringify({ DeletedPhotos:  1, DefectId: defectId, WorkOrderProductId: woProductId });
        }
        else if (headers.WorkOrderProductId) {
            const woProductId = headers.WorkOrderProductId;
            const _description = { DeletedPhotos:  1, WorkOrderProductId: woProductId};

            if (headers.ImageDescription) {
                _description.Description = headers.ImageDescription;
            }
            activity.description = JSON.stringify(_description);
        }
        else {
            const sectionId = headers.SectionId || 1;
            const index = sections.findIndex(x => x.id == sectionId);
            const sectionName = index != -1 ? sections[index].name : '';
            activity.description = JSON.stringify({ DeletedPhotos:  1, SectionId: +sectionId, SectionName: sectionName });
        }

        activity.activityCode = this.activity.DC_ED.code;
        activity.groupTransactionCode = headers.TransactionCode;
        // activities.push(activity);
        this.postActivity(activity);
    }
    else if (url.indexOf(apiList.submitInspectionSection.toLowerCase()) !== -1) {
        const _data = JSON.parse(requestData || null)
        if (workOrder && workOrder.resources && workOrder.resources.length > 1 && _data) {
            const _section =  sections.find(x => x.id == _data.sectionId)
            activity.addedActionText = _section ? _section.name : null;

            activity.activityCode = this.activity.DC_SLI.code;
            activity.groupTransactionCode = this.getUniqueIdentifier();


            // activities.push(activity);
            this.postActivity(activity);
        }
    }
    else if (url.indexOf(apiList.submitWorkOrderReport.toLowerCase()) !== -1) {
        activity.activityCode = this.activity.DC_SFR.code;
        activity.groupTransactionCode = headers.TransactionCode;
        // activities.push(activity);

        const editActivity = Object.assign({}, activity);
        editActivity.activityCode = this.activity.DC_ED.code;
        editActivity.description = requestData.get('SupplierEmailAddress') ? JSON.stringify({ SupplierEmailAddress: requestData.get('SupplierEmailAddress') }) : null;
        // activities.push(editActivity);

        this.postActivity(editActivity).then(() => {
            this.postActivity(activity);
        })



    }

}

/**
 * a function that post the activity to server
 * @param {object} activity     the object that holds the activity details
 */
auditLog.postActivity = async function (activity) {
    // await axios.post(apiList.createActivity, activity, {
    //     headers: apiDetails.header.headersDetail,
    // });
}

export default auditLog;
