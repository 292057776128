<template>
  <div name="Remarks">
    <v-snackbar class="snackbar-loading" v-model="loading" top :timeout="-1" color="#4caf50">
      <v-progress-circular :size="15" indeterminate color="primary"></v-progress-circular>
      <span style="padding-left:10px;">Loading</span>
    </v-snackbar>

    <v-data-table show-select v-model="selectedRemarks" :headers="remarksHeader" :sort-by="['index']"  :items="numberedRemarksList" :items-per-page="-1" :hide-default-footer="false" class="elevation-1" style="margin-top: 30px;" auto-id="workorder remarks table">
      <template v-slot:header.data-table-select="{ on, props }">
                <div style="width:65px">
                    <v-simple-checkbox
                        v-bind="props"
                        v-on="on"
                        style="width: 50%;float: left;padding-top: 6px;"
                    ></v-simple-checkbox>    
                    <v-btn :id="'remarks_delete_select'" :disabled="!isAuthorized || !selectedRemarks || selectedRemarks.length < 1" icon @click="remarks_table_del_selected()" auto-id="workorder remarks delete 2" style="width: 45%;float: left;">
                        <v-icon color="rgba(0,0,0,.87)">delete</v-icon>
                    </v-btn>            
                </div>
        </template>
      <template v-slot:item.description="{ item }">
        <div style="position: relative;">
          <!-- <div style="background-color: transparent; position: absolute; width: 100%; z-index: 9999; height: 100%;"></div>
                    <div style="background-color: #FFF; position: absolute; width: 100%; z-index: 999; height: 50px;"></div> -->
          <span>
            <!-- <wysiwyg v-model="item.description"  :options="config"/> -->
            <froalaView v-model="item.description" :options="config" />
          </span>
        </div>
      </template>
      <template v-slot:item.classification="{ item }">
        <span>{{ remarkClassification(item.classification) }}</span>
      </template>
      <template v-slot:item.menu="{ item }">
            <div class="d-flex justify-end">
                <div class="mr-4">
                    <v-btn :id="'remarks_edit_' + item.id"  :disabled="item.index <= 1 || !isAuthorized || item.isOffline" icon text @click="sort(item, 'up')" auto-id="workorder remarks edit">
                        <v-icon color="rgba(0,0,0,.87)">keyboard_arrow_up</v-icon>
                    </v-btn>
                    <v-btn  :id="'remarks_delete_' + item.id" :disabled="item.index === remarksLists.length || !isAuthorized || item.isOffline" icon @click="sort(item, 'down')" auto-id="workorder remarks delete">
                        <v-icon color="rgba(0,0,0,.87)">keyboard_arrow_down</v-icon>
                    </v-btn>
                </div>

                <div>
                    <v-btn :id="'remarks_edit_' + item.id"  :disabled="!isAuthorized || item.isOffline" icon text @click="go_remark(item)" auto-id="workorder remarks edit 2">
                        <v-icon color="rgba(0,0,0,.87)">edit</v-icon>
                    </v-btn>
                    <!-- <v-btn :id="'remarks_delete_' + item.id" :disabled="!isAuthorized || item.isOffline" icon @click="remarks_table_del(item)" auto-id="workorder remarks delete 2">
                        <v-icon color="rgba(0,0,0,.87)">delete</v-icon>
                    </v-btn> -->
                </div>
            </div>
      </template>
    </v-data-table>
    <v-btn class="add_remarks" color="#B14C4C" style="color:#fff" @click="go_remark(-1)" auto-id="add remarks and comments">Add Remarks & Comments</v-btn>
  </div>
</template>

<script>
import { mapState } from "vuex";
import apiDetails from '../../shared/request/apiDetails';
import * as _ from "lodash";

export default {
  name: "WorkOrderRemarks",
  data() {
    return {
      remarksHeader: [
        { text: "Index", align: "left", sortable: false, value: "index" },
        { text: "Description", sortable: false, value: "description", disable: true },
        { text: "Classification", align: "center", sortable: false, value: "classification" },
        { text: "", align: "center", sortable: false, value: "menu" },
      ],
      selectedRemarks: [],

      // remarksLists:[],
      remarkClassificationList: [
        { id: 1, description: 'F'}, //Fail
        { id: 2, description: 'PE'}, // Pending
        { id: 3, description: 'I'}, // Informative
        { id: 4, description: "A" }, // Abort
        { id: 5, description: "PA"}, // Pass
        { id: 6, description: "CAPA"},
        { id: 7, description: "Not Ready"}
      ],
      hasLoadedTheItems: false,
      config: {
        hideModules: {
          bold: true,
          italic: true,
          underline: true,
          justifyLeft: true,
          justifyCenter: true,
          justifyRight: true,
          headings: true,
          link: true,
          code: true,
          orderedList: true,
          unorderedList: true,
          image: true,
          table: true,
          removeFormat: true,
          separator: true,
        },
      },
      loading: false,
    };
  },
  computed: {
    ...mapState("inspection", {
      updatedRemarksEntry: (state) => state.updatedRemarksEntry,
      remarksLists: (state) => state.remarksLists,
    }),

    numberedRemarksList() {
        return this.remarksLists.map((v, i) => ({...v, index: i + 1}));
    }

  },
  activated() {
    //if (this.remarksLists.length > 0 && this.remarksLists[0].workOrderId.toLowerCase() != this.$route.params.id.toLowerCase()) {
    if (this.remarksLists.length > 0) {
      this.getTheRecordList();
     }
  },
  mounted() {
    this.getTheRecordList();
  },
  methods: {
    getTheRecordList() {
      if (window.navigator.onLine == true) {
        this.$store.dispatch("inspection/setRemarksList", []);
        this.loading = true;
      }
      this.utilityFn.getServerData("get", this.apiList.getWorkOrderRemarks + "/" + this.$route.params.id, {}, (result) => {
        this.loading = false;
        const data = result.data.data.sort((a,b)=> 0 - (a.order > b.order ? -1 :1));
        this.$store.dispatch("inspection/setRemarksList", data);
        this.upsertEntry();
        this.hasLoadedTheItems = true;
      });
    },
    remarkClassification(classification) {
      return this.remarkClassificationList.find((x) => x.id == classification)?.description;
    },
    go_remark(id) {
      this.$router.push({ name: "workOrderRemarksId", params: { id: this.$route.params.id, action: id === -1 ? "add" : "edit", data: id } }); //{id: id}})
    },
    // remarks_table_del(item) {
    //   var index = this.remarksLists.findIndex(x => x.id == item.id);
    //   this.loading = true;
    //     this.utilityFn.getServerData(
    //       "delete",
    //       this.apiList.deleteWorkOrderRemarks + "/" + item.id,
    //       {},
    //       (result) => {
    //         this.loading = false;

    //         this.remarksLists.splice(index, 1);
    //         this.$store.dispatch("inspection/setRemarksList", this.remarksLists);
    //       },
    //       (errresult) => {
    //         this.loading = false;

    //         this.remarksLists.splice(index, 1);
    //         this.$store.dispatch("inspection/setRemarksList", this.remarksLists);
    //       },
    //     );
    // },
    async remarks_table_del_selected() {
      if (confirm('Are you sure you want to delete these items?')) {
        //get selected remarks to delete
        this.loading = true;
        for (let i = 0; i < this.selectedRemarks.length; i++) {
          const remarkToDelete = this.selectedRemarks[i];
          let res = await this.utilityFn.getServerDataAsync('delete', this.apiList.baseUrl + '/api/AIMS/DeleteWorkOrderRemark/' + remarkToDelete.id, {});
          if (res?.data) {
            var index = this.remarksLists.findIndex(x => x.id == remarkToDelete.id);
            this.remarksLists.splice(index, 1);
          }
        }
        this.selectedRemarks = [];
        this.$store.dispatch('inspection/setRemarksList', this.remarksLists);
        this.loading = false;
      }
    },

    submit_item() {
      var other = {
        headers: {
          wresult: this.store_token,
          "Content-Type": "application/json",
        },
      };
      const section = {
        sectionId: this.sectionId,
        workOrderId: this.$route.params.id,
      };
      this.utilityFn.getServerData("post", this.apiList.submitInspectionSection, section, (res) => {}, other);
    },
    upsertEntry() {
      if (this.updatedRemarksEntry && this.updatedRemarksEntry.id) {
        const index = this.remarksLists.findIndex((x) => x.id === this.updatedRemarksEntry.id);
        if (index !== -1) {
          this.$set(this.remarksLists, index, this.updatedRemarksEntry);
        } else {
          this.remarksLists.push(this.updatedRemarksEntry);
        }
        this.$store.dispatch("inspection/setRemarksList", this.remarksLists);
      }
    },
    
    sort(item, direction) {
        // get indexes from the ordered list
        // get index of the current item
        let currentItemIndex = this.remarksLists.findIndex(x => x.id === item.id);
        let newIndex = direction === 'up' ? (currentItemIndex - 1) : (currentItemIndex + 1);

        if (currentItemIndex !== -1 && newIndex !== -1) {
            const currentItemOrder = this.remarksLists[currentItemIndex]?.order;
            const swapItemOrder = this.remarksLists[newIndex]?.order;
            
            this.remarksLists[currentItemIndex].order = swapItemOrder;
            this.remarksLists[newIndex].order = currentItemOrder;

            const data = this.remarksLists.sort((a,b)=> 0 - (a.order > b.order ? -1 :1));
            this.$store.dispatch('inspection/setRemarksList', data);
            this.sortRemarks();
        }
    },

    sortRemarks: _.debounce(function() {
        const sortedList = this.numberedRemarksList.map(x => ({ workOrderId: this.$route.params.id, workOrderRemarkId: x.id, order: x.index}));
        this.utilityFn.getServerDataAsync('post', this.apiList.sortWorkOrderRemarks, sortedList);
    }, 1000)
  },

  watch: {
    updatedRemarksEntry(val) {
      if (val && val.id && this.hasLoadedTheItems) {
        this.upsertEntry();
      }
    },
  },
  props: ["generalData", "isAuthorized"],
};
</script>

<style lang="stylus" scoped>
.add_remarks
    width 60%
    color #fff
    margin 50px auto
    margin-top 100px
    display block

img{
   width:100%;
   height:100%;
   padding:7px 4px;
   object-fit: contain;
}
table, th, td {
    border: 1px solid black;
}
.snackbar-loading
    margin-top 50%
    display flex
    justify-content center
</style>
