<template>
  <div>
    <div class="uploadPhotoDefectId">
      <v-row v-for="(item, index) in workOrderProduct.workOrderProductDefects" :key="index">
        <v-col cols="12" sm="10">
          <h3>{{ item.defectName.name }}</h3>
        </v-col>
        <v-col cols="12" sm="2">
          <v-btn icon dark style="float: right;" @click="option_file(item)">
            <v-icon color="rgba(0,0,0,.87)">mdi-camera</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" sm="12">
          <!-- v-model="item.workOrderDefectAttachments" -->
          <draggable class="imglist select_img">
            <transition-group tag="ul">
              <li v-for="(defectAttachment, defectAttachmentIndex) in item.workOrderDefectAttachments" :key="defectAttachmentIndex" class="item">
                <div v-if="!defectAttachment.src">
                  Loading...
                </div>
                <div v-else class="holder">
                  <span class="del_button" @click="del_img(item.workOrderDefectAttachments, defectAttachment)">
                    <icon name="times-circle" class="icon"></icon>
                  </span>

                  <img class="imgItem" :src="defectAttachment.src" />
                </div>
              </li>
            </transition-group>
          </draggable>
        </v-col>
      </v-row>

      <v-dialog v-model="showProgressDialog" :persistent="true">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <v-container v-for="(image, index) in addedImages" :key="index">
              <v-progress-linear :value="image.uploadProgress" color="light-blue" height="25" reactive>
                <template v-slot="{ value }">
                  <strong>{{ image.fileName }} {{ Math.ceil(image.uploadProgress) }}%</strong>
                </template>
              </v-progress-linear>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import * as _ from "lodash";

let state = {
  appHeaderState: {
    show: true,
    title: "",
    showMenu: true,
    showBack: true,
    showLogo: false,
    showTitle: true,
    showSearch: false,
  },
};

function setState(store) {
  store.dispatch("navigation/setAppHeader", state.appHeaderState);
}
export default {
  name: "uploadPhotoDefectId",

  components: {
    draggable,
  },

  data() {
    return {
      workOrderProduct: {},
      showProgressDialog: false,
      addedImages: [],
      workOrderId: "",
      sku: "",
    };
  },
  mounted() {
    this.workOrderId = this.$route.params.id;
    this.sku = this.$route.query.sku;

    state.appHeaderState.title = "Add Defect Photo：" + this.sku;
    setState(this.$store);

    this.utilityFn.getServerData("get", this.apiList.workOrderDetails + "/" + this.workOrderId, {}, (res) => {
      this.workOrderProduct = res.data.data[0].products.find((x) => x.productID == this.$route.query.id);
      this.workOrderProduct.workOrderProductDefects.forEach((defectItem) => {
        defectItem.workOrderDefectAttachments.forEach((x) => {
          this.apiFn.getImage(x.fileUploadId).then((result) => {
            let src = "data:" + result.fileType + ";base64," + result.data;
            this.$set(x, "src", src);
          });
        });
      });
    });

    this.setSessionDetails();
  },
  methods: {
    del_img(obj, item) {
      let index = obj.findIndex((x) => x.fileUploadId === item.fileUploadId);
      obj.splice(index, 1);
      this.apiFn.deleteImage(item.fileUploadId).then((result) => {});

      this.logChanges();
    },
    option_file(item) {
      var inputObj = document.createElement("input");
      inputObj.setAttribute("id", "_ef");
      inputObj.setAttribute("multiple", "multiple");
      inputObj.setAttribute("type", "file");
      inputObj.setAttribute("style", "visibility:hidden");
      document.body.appendChild(inputObj);
      inputObj.click();
      inputObj.onchange = (res) => {
        //this.showProgressDialog = true;
       
        for (let i = 0; i < inputObj.files.length; i++) {
          const file = inputObj.files[i];
          var sizeInfo = this.utilityFn.formatBytes(file.size)    
          console.log('sizeInfo=>',sizeInfo)
          if(sizeInfo.sizeKB  > 500){
            this.$store.dispatch("navigation/setSnackbar",{
              color :'error',
              text:`Each upload only accepts image size less than 500KB. Please resize/compact the image with size greater than 500KB. Filename: ${file.name}.`
              });
          }
          else{
            if (window.navigator.onLine) {
              this.showProgressDialog = true;
            } else {
              alert("Photo saved offline, will auto update when device is online");
            }
            const addedImage = { id: i, uploadProgress: 0, fileName: inputObj.files[i].name };
            this.addedImages.push(addedImage);
            const form = new FormData();
            form.append("image", file);
            form.append("defectId", item.id);
            form.append("description", item.defectName.name);
            form.append("screen", 11); // the current screen does not exist yet, should be added to the db

            this.apiFn
              .uploadWorkOrderProductDefectImage(form, (progress) => {
                if(this.addedImages[i])this.addedImages[i].uploadProgress = progress;
              })
              .then(
                (result) => {
                  const imgIndex = this.addedImages.findIndex((x) => x.id === addedImage.id);
                  this.addedImages.splice(imgIndex, 1);
                  this.showProgressDialog = this.addedImages.length !== 0;

                  // get the base64 string of the file
                  this.getBase64(file).then((base64) => {
                    item.workOrderDefectAttachments.push({
                      fileUploadId: result.fileUploadId,
                      description: result.description,
                      isIncludeInCFR: result.isIncludeInCFR,
                      src: base64,
                    });
                  });
                },
                (errRes) => {
                  if (window.navigator.onLine != true) {
                  /*  this.showProgressDialog = false;
                    const imgIndex = this.addedImages.findIndex((x) => x.id === addedImage.id);
                    this.addedImages.splice(imgIndex, 1);

                    // get the base64 string of the file
                    this.getBase64(file).then((base64) => {
                      item.workOrderDefectAttachments.push({
                        fileUploadId: result.fileUploadId,
                        description: result.description,
                        isIncludeInCFR: result.isIncludeInCFR,
                        src: base64,
                      });
                    }); */
                  }
                }
              );
          }
          
        }
      };

      this.logChanges();
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    logChanges() {
      window.sessionStorage.setItem("EditDataLogging", true);
    },

    setSessionDetails() {
      window.sessionStorage.setItem("SectionId", 11);
    },
  },
};
</script>

<style lang="stylus" scoped>
>>>ul {
    padding: 0;
}
.row
    background #fff
    margin 15px
    padding 15px 0
.item
    display inline-block
    width 12%
    margin 1.14%
.holder
    position relative

.imglist .holder .imgItem
    width 100%

.select_img
    text-align left
    /*border 1px #ccc solid*/

.imgItem
    width 12%
    margin 1.14%

.del_button
    position absolute
    right -3px
    top -5px
</style>
