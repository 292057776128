import Vue from "vue";
import Vuex from "vuex";
import navigationModule from './modules/navigation/index.js';
import inspectionModule from './modules/inspection/index.js';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    navigation: navigationModule,
    inspection: inspectionModule
  },
  state: {
    token: null,
    user: null,
    msalConfig: {
      auth: {
        clientId: process.env.VUE_APP_CLIENT_ID,
        authority: process.env.VUE_APP_AUTHORITY,
        knownAuthorities: [process.env.VUE_APP_KNOWN_AUTHORITY],
        redirectUri: window.location.origin ,
        navigateToLoginRequestUrl: false,
        //redirectStartPage: true
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true
      },
      system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                //console.log(message);
            },
            piiLoggingEnabled: false,
        },
    }
    },
    accessToken: ''
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setAccessToken(state, token){
      state.accessToken = token;
    }
  },
  actions: {},
  getters: {
    getToken(state) {
      return state.token;
    },
    b2cToken(state) {
      return state.accessToken;
    }
  }
});
