<template>
    <div>
        <div class="profile">

            <v-row class="formDate">
                <v-col cols="12" sm="12" md="12" style="text-align: center;">
                    <img class="default_avatar" src="../../assets/images/defaultAvatar.jpg" />
                    <a style="padding-top: 20px;display: inline-block;">Change Profile Photo</a>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="form.firstName" label="First Name" readonly></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="form.lastName" label="Last Name" readonly></v-text-field>
                </v-col>
                <!-- <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="form.coordinationOffice.name" label="Coordination Office"></v-text-field>
                </v-col> -->
                <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="form.industry" label="Industry" readonly></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="form.phoneMobile" label="Phone(Mobile)" readonly></v-text-field>
                </v-col>
            </v-row>


          <!-- <v-btn class="submit" dark color="#B14C4C" @click="submit">Submit</v-btn> -->
        </div>
    </div>
</template>

<script>

    import {mapActions} from 'vuex';

    function setState(store) {
        store.dispatch('navigation/setAppHeader', {
            show: true,
            title: 'Profile',
            showTitle: true,
            showMenu: false,
            showBack: true,
            showLogo: false,
            showSearch_job: false,
            showSearch_work: false
        });
    }

    export default {
        name: 'profile',
        data () {
            return {
                form: {
                    coordinationOffice: {}
                }
            };
        },
        async activated (){
            let resource = localStorage.getItem("username") === null ? '' : localStorage.getItem("username")
            const result = await this.utilityFn.getServerDataAsync("get", this.apiList.getResourceApplication, {'email':resource});
            if (result?.data) {
                this.form = result.data
            }


        },
        async mounted () {
            setState(this.$store);

            //get the username in localstorage
            let resource = localStorage.getItem("username") === null ? '' : localStorage.getItem("username")
            const result = await this.utilityFn.getServerDataAsync("get", this.apiList.getResourceApplication, {'email':resource});
            if (result?.data) {
                this.form = result.data
            }

            // Get profile data
            // this.apiFn.api.mainApi.getServerData('get', '/api/AIMS/GetCurrentUserDetails', {}, res => {
            //     this.form = res.data
            // })
            // this.apiFn.api.mainApi.getServerData('get', this.apiList.userDetails, {}, res => {
            //     this.form = res.data
            // })
        },
        methods: {
            submit () {
                
            },

           
        }
    };
</script>

<style lang="stylus" scoped>
    
    .profile
        background #fff
        position absolute
        top 0
        bottom 0

    .logo_img
        display block
        margin 10% auto 5%
    .default_avatar 
        width 150px
        height 150px
        display block
        margin auto
    .formDate
        padding 15px
        width 90%
        margin auto

    .submit
        display block
        margin 7% auto 0;
        width 80%
    
</style>
