<template>
  <div>
    <div class="pastInspectionsResubmit">
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-select v-bind:items="ratings" item-text="description" item-value="id" v-model="formData.ratingId" @change="onRatingSelectChange()" :rules="ratingRules" label="*Overall Result"></v-select>
        </v-col>
      </v-row>
      <v-row v-show="formData.ratingId === getRatingId(overallResultCodes.ABORT)">
        <v-col cols="6" sm="6" md="6">
          <span class="required">Meet Quality/Performance Expectation*</span>
          <br />
          <span class="required">(Note: Any non-conformance, fail or pending point will tick No)</span>
        </v-col>
        <v-col cols="3" sm="3" md="3">
          <input type="radio" value="true" v-model="formData.abortMeetExpectations" />
          <label for="true">Yes</label>
        </v-col>
        <v-col cols="3" sm="3" md="3">
          <input type="radio" value="false" v-model="formData.abortMeetExpectations" />
          <label for="false">No</label>
        </v-col>
      </v-row>
      <v-row v-if="showResultChangeRemarks">
        <v-col cols="12">
          <v-textarea v-model="formData.ratingRemarks" label="*Enter remarks for updating/changing the work order's Overall Result" :counter="maxLength" :rules="remarksRules" :rows="2"></v-textarea>
        </v-col>
      </v-row>
      <v-row v-show="showFailCodes">
        <v-col cols="12">
          <v-textarea v-model="formData.failReason" :label="failReasonDescription" :counter="maxLength" :rules="remarksRules" :rows="2"></v-textarea>
        </v-col>
      </v-row>
      <v-row v-if="showFailCodes">
        <v-col cols="12">
          <v-container fluid>
            <span>*{{ resultCodeLabel }}</span>
            <v-btn icon v-show="!isCustomResultCodes" dark @click="show_toopltips = true">
              <v-icon color="rgb(115, 115, 115)" style="margin-left: 15px;">help</v-icon>
            </v-btn>
            <v-checkbox v-for="(item, index) in resultCodes" :key="index" v-model="formData.failCodes" :label="resultCodeDescription(item)" :value="resultCodeValue(item)" :rules="listRules" hide-details></v-checkbox>
          </v-container>
          <v-dialog v-model="show_toopltips" width="500px">
            <v-card>
              <v-card-content>
                <table class="fail_code">
                  <tr>
                    <td valign="top">UL0</td>
                    <td>
                      <p>Finding of issue on product that was not covered by below reasons</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">UL1</td>
                    <td>
                      <p><strong>Product Deviation</strong></p>
                      <p>Product is not conform with specification/ gold seal sample</p>
                      <p>- Dimension</p>
                      <p>- Color and style</p>
                      <p>- Accessory</p>
                      <p>- Required function and feature</p>
                      <p>- Material</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">UL2</td>
                    <td>
                      <p><strong>Retail Packaging Deviation</strong></p>
                      <p>Labeling / Manuals / Hang tag / Markings / Artwork / Barcode</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">UL3</td>
                    <td>
                      <p><strong>Shipping Carton Deviation</strong></p>
                      <p>Export carton dimension / weight / shipping marks</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">UL4</td>
                    <td>
                      <p><strong>Onsite test Issues</strong></p>
                      <p>- No equipment to conduct test</p>
                      <p>- Factory refuse to conduct functional test</p>
                      <p>- Functional test failed</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">UL5</td>
                    <td>
                      <p><strong>Carton Drop Test</strong></p>
                      <p>- Factory refuse to conduct carton drop test</p>
                      <p>- Carton drop test failed</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">UL6</td>
                    <td>
                      <p>Client specification/ Approval Sample not available during inspection</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">UL7</td>
                    <td>
                      <p>Exceed AQL</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">UL8</td>
                    <td>
                      <p>Shortage of Shipment Quantity</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">UL9</td>
                    <td>
                      <p><strong>Missing Documents</strong></p>
                      <p>- CDF not available</p>
                      <p>- Client POs</p>
                      <p>- Product specification requirement</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">UL10</td>
                    <td>
                      <p>All issues related for Sample Collection</p>
                    </td>
                  </tr>
                </table>
              </v-card-content>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="show_toopltips = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-autocomplete v-bind:items="reworkReasons" v-model="formData.reworkReasons" :rules="listRules" label="*Select Rework Reason" item-text="reworkReasonListOption" item-value="id" multiple chips hide-details @change="onReworkSelectChange()">
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <small class="error--text">*Required Field</small>
        </v-col>
      </v-row>

      <v-btn class="submit" :disabled="!valid" color="#B14C4C" @click="showPopupMessage" >Submit</v-btn>
    </div>

    <v-dialog v-model="showConfirm" width="450">
      <v-card>
        <v-card-title class="headline"></v-card-title>
        <v-card-text
          >Submit <b>{{ workOrder.workOrderDisplayId }}</b> to Service Specialist Team/Reviewer for Review?”</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="submit">Yes</v-btn>
          <v-btn color="blue darken-1" text @click="showConfirm = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

        <v-snackbar v-model="snackbarError" :timeout="timeoutError" :color="colorError">
              {{ messageError }}
              <v-btn text @click="snackbarError = false"> Close </v-btn>
            </v-snackbar>

  </div>
</template>
<script>
import { mapState } from "vuex";
import auditLog from "../../shared/request/auditLogs";
import * as moment from "moment";
import * as _ from "lodash";

let state = {
  appHeaderState: {
    show: true,
    title: "Work Order: ",
    showMenu: true,
    showBack: true,
    showLogo: false,
    showTitle: true,
    showSearch_job: false,
    showSearch_work: false,
  },
};

const overallResultCodes = {
  PASS: "Pass",
  FAIL: "Fail",
  PENDING: "Pending",
  ABORT: "Abort",
};

function setState(store) {
  store.dispatch("navigation/setAppHeader", state.appHeaderState);
}
export default {
  name: "pastInspectionsResubmit",
  data() {
    return {
      formData: {
        ratingId: null,
        failCodes: [],
        ratingRemarks: null,
        reworkReasons: [],
        reworkRemarks: null,
        failReason: null,
      },
      workOrder: {
        overAllResult: {},
      },
      overallResultCodes,
      ratings: [],
      show_toopltips: false,
      defaultFailCodes: ["UL0", "UL1", "UL2", "UL3", "UL4", "UL5", "UL6", "UL7", "UL8", "UL9", "UL10"],
      failCodes: [],

      valid: false,
      ratingRules: [(v) => !!v || "Required"],
      remarksRules: [(v) => (!!v && v.length <= 200) || "Maximum 200 characters only"],
      listRules: [(v) => v.length > 0 || "Required"],
      maxLength: 200,

      reworkReasons: [],
      showConfirm: false,
      snackbarError: false,
      messageError: "",
      timeoutError: 3000,
      colorError: "red darken-2",
      remarkLists: []
    };
  },

  mounted() {
    state.appHeaderState.title = "Work Order:" + this.$route.query.displayId;
    setState(this.$store);

    // 在订单详情中获取clientId来获取选项
    this.utilityFn.getServerData("get", this.apiList.workOrderRework + "/" + this.$route.query.id, {}, (res) => {
      this.workOrder = res.data.data[0];
      this.workOrder.overAllResult = this.workOrder.overAllResult || { id: null };
      this.formData.ratingId = this.workOrder.overAllResult.id;
      this.formData.failCodes = this.workOrder.workOrderFailCodes.map((wf) => wf.failCode);
      this.formData.reworkRemarks = this.workOrder.reworkRemarks;
      this.formData.resultChangeRemarks = this.workOrder.resultChangeRemarks;
      const clientId = this.workOrder.clientId;

      /*   this.utilityFn.getServerData("post", this.apiList.GetCompanyWorkOrderResultTypeAliases, { id: clientId }, (res) => {
        this.ratings = res.data.filter((x) => x.description && x.isActive);
      }); */

      this.utilityFn.getServerData("get", this.apiList.GetCompanyWOResultTypeAliases
       + "?companyId=" + clientId, {},(res) => {
        this.ratings = res.data.data.filter((x) => x.description && x.isActive);
      });

      this.$store.dispatch("inspection/setWorkOrderDetails", this.workOrder);
    });

    this.utilityFn.getServerData("get", this.apiList.getReworkReason, {}, (res) => {
      const reasons = res.data.data;
      const headers = Array.from(new Set(reasons.map((x) => x.reworkReasonHeader)));

      headers.forEach((header) => {
        this.reworkReasons.push({ header });
        const filteredReasons = reasons.filter((r) => r.reworkReasonHeader === header);
        this.reworkReasons.push(...filteredReasons);
        this.reworkReasons.push({ divider: true });
      });
    });

    this.utilityFn.getServerData('get', this.apiList.getWorkOrderRemarks + '/' +  this.$route.query.id, {}, result => {
        console.log('result remarks', result)
        this.remarkLists =  result.data.data;
      },errres =>{
          console.log(errres);
      });
  },

  methods: {
    getRatingId(description) {
      let rating = this.ratings.find((x) => x.description.toLowerCase().indexOf(description.toLowerCase()) !== -1);
      return rating != null ? rating.id : "";
    },
    onRatingSelectChange() {
      this.formData.failCodes = [];
    },

    onReworkSelectChange() {
      this.valid = this.formData.reworkReasons.length == 0 ? false : true;
    },

    isValidRemarksCommentSection(){
      if( this.workOrder.client == "WALMART GLOBAL SOURCING" || this.workOrder.client.toLowerCase() == "asda stores limited"){
        let ratingValue = this.ratings.filter(x => x.id == this.formData.ratingId)[0].description

        if(ratingValue.includes("Pass")){
          return true;
        }

        if( !this.remarkLists || this.remarkLists.length == 0){
            this.snackbarError = true;
            this.messageError = "Missing Remark/Comment";
            return false;
        }       
        if(ratingValue.includes("Fail")) {
          let remarksListsFail = this.remarkLists.filter(x => x.classification == 1)
          if(remarksListsFail.length == 0) {
            this.snackbarError = true;
            this.messageError = "Please provide the Fail Comment(s)";
          return false;
          }
        } else if(ratingValue.includes("Pending")) {
          let remarksListsPending = this.remarkLists.filter(x => x.classification == 2)
            if(remarksListsPending.length == 0) {
              this.snackbarError = true;
              this.messageError = "Please provide the Pending Comment(s)";
            return false;
            }
        } else if(ratingValue.includes("Abort")) {
          let remarksListsAbort = this.remarkLists.filter(x => x.classification == 4)
            if(remarksListsAbort.length == 0) {
              this.snackbarError = true;
              this.messageError = "Please provide the Abort Comment(s)";
          return false;
            }
        }
      }
      return true
    },

    showPopupMessage() {
      if(this.isValidRemarksCommentSection()){
          this.showConfirm = true;    
      }     
    },

    submit() {
      this.showConfirm = false;
      const form = {
        workOrderId: this.workOrder.workOrderId,
        workOrderResultId: this.formData.ratingId,
        reworkReasons: [],
        workOrderFailCodes: [],
        resultChangeRemarks: this.formData.ratingRemarks,
        reworkRemarks: this.formData.reworkRemarks,
        failReason: this.formData.failReason,
        abortMeetExpectations: this.formData.abortMeetExpectations,
      };

      this.formData.failCodes.forEach((f) => {
        const item = this.workOrder.workOrderFailCodes.find((wf) => wf.failCode == f);
        form.workOrderFailCodes.push({ id: item ? item.id : null, workOrderId: this.workOrder.workOrderId, failCode: f });
      });

      this.formData.reworkReasons.forEach((reasonId) => {
        const item = this.reworkReasons.find((r) => r.id === reasonId);
        if (item) {
          form.reworkReasons.push(item);
        }
      });

      this.utilityFn.getServerData("post", this.apiList.resubmitWorkOrderReport, form, (res) => {
        localStorage.setItem("signedOffWorkOrder", this.workOrder.workOrderId);
        this.$router.push({ name: "index", query: { tab: 2 } });
      });
    },

    resultCodeDescription(item) {
      return item.code ? item.code + " - " + item.description : item;
    },

    resultCodeValue(item) {
      return item.code ? item.code : item;
    },
  },
  computed: {
    
   ...mapState("inspection", {
      _remarksLists: state => state.remarksLists,
      _workorderDetails: state => state.workOrderDetails
    }),
    // showReworkRemarks() {
    //     return this.reworkReasons.findIndex(r => this.formData.reworkReasons.indexOf(r.id) !== -1 && r.reworkReasonListOption === 'Others' ) !== -1;
    // },
    rating() {
      return this.ratings.find((x) => x.id === this.formData.ratingId);
    },

    showResultChangeRemarks() {
      return this.formData.ratingId && this.formData.ratingId !== this.workOrder.overAllResult.id;
    },

    showFailCodes() {
      // const rating = this.ratings.find(x => x.id === this.formData.ratingId);
      return (
        this.rating &&
        (this.rating.workOrderResultType.description === overallResultCodes.FAIL ||
          ((this.rating.description.toLowerCase().indexOf(overallResultCodes.ABORT.toLowerCase()) != -1 || this.rating.description.toLowerCase().indexOf(overallResultCodes.PENDING.toLowerCase()) != -1) &&
            this.rating.companyWorkOrderResultTypeCodes.length > 0))
      );
    },

    resultCodes() {
      // const rating = this.ratings.find(r => r.id === this.formData.ratingId);
      const codes = this.rating ? _.orderBy(this.rating.companyWorkOrderResultTypeCodes.filter(x => x.isActive), ["order"], ["asc"]).map((c) => ({ code: c.code, description: c.description })) : [];

      return codes.length > 0 ? codes : this.defaultFailCodes;
    },

    isCustomResultCodes() {
      // const this.rating = this.ratings.find(r => r.id === this.formData.ratingId);
      return !!this.rating && this.rating.companyWorkOrderResultTypeCodes.length > 0;
    },

    resultCodeLabel() {
      // const this.rating = this.ratings.find(x => x.id === this.formData.ratingId);
      if (this.rating && this.rating.companyWorkOrderResultTypeCodes.length > 0) {
        if (this.rating.description.toLowerCase().indexOf(overallResultCodes.ABORT.toLowerCase()) != -1) {
          return "Abortive Code";
        } else if (this.rating.description.toLowerCase().indexOf(overallResultCodes.PENDING.toLowerCase()) != -1) {
          return "Pending Code";
        }
      }
      return "Fail Code";
    },

    failReasonDescription() {
      if (this.showFailCodes && this.rating) {
        return `Please provide a descriptive ${this.rating.description} reason. *`;
      }
      return '';
    },
  },
};
</script>
<style lang="stylus">
.pastInspectionsResubmit
    background #fff
    padding 15px

.error--text
    color #ff5252 !important
    caret-color #ff5252 !important

.submit
    color #fff !important
    display block
    margin 40px auto
    width 60%
.required
    color red
</style>
