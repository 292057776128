<template>
  <div class="box">
    <v-overlay :value="loadingAssignment"></v-overlay>
    <v-snackbar class="snackbar-loading" v-model="loadingAssignment" top :timeout="0" color="#4caf50">
      <v-progress-circular :size="15" indeterminate color="primary"></v-progress-circular>
      <span style="padding-left:10px;">Loading</span>
    </v-snackbar>
    <div class="assignment">
      <v-row style="background: #FCE2E2; color: #000">
        <v-col cols="12" class="d-flex align-start" v-if="!isOnlyInspector && !this.isLoggedInUserLead && !this.workOrderDetails.workInstruction">
          <v-icon x-large style="color: #000; margin-right: 5px">mdi-alert-circle</v-icon>
          <div>
            Work Order Instruction name was found unfilled. please coordinate with the assigned lead inspector to fill-up this section in order to proceed
          </div>
        </v-col>
      </v-row>
      <v-row style="background:#fff;margin:auto; padding:30px" justify="center">
        <v-col cols="12" sm="12" md="12">
          <span v-if="isLoggedInUserLead">Please enter at least 3 characters to search for Work Instruction Name.</span>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-autocomplete
            v-bind:items="filteredWorkInstructionList"
            :disabled="!isLoggedInUserLead"
            v-model="workInstructionName"
            item-text="wiName"
            item-value="wiName"
            label="Work Instruction Name"
            :search-input.sync="search"
            no-data-text="No Data Available"
            :hide-no-data="!showNoDataText"
            @change="onChangeWorkInstructionName"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-autocomplete
            v-bind:items="filteredWorkInstructionList"
            :disabled="!isLoggedInUserLead"
            v-model="protocolNo"
            item-text="wiId"
            item-value="wiId"
            label="Protocol #"
            :search-input.sync="searchProtocolNo"
            no-data-text="No Data Available"
            :hide-no-data="!showNoDataText"
            @change="onChangeProtocolNo"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-text-field
            v-model="workInstructionNameDisplay"
            label="Selected Work Instruction Name"
            variant="underlined"
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-text-field
            v-model="workInstructionId"
            label="Selected Protocol #"
            variant="underlined"
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12" v-if="isLoggedInUserLead && !isOnlyInspector">
          <div v-for="(item, index) in workOrderSectionList" :key="index">
            <v-row v-if="item.isAssignable" style="margin-top:20px;">
              <v-col cols="4" sm="4" md="4" align-self="center">
                {{ item.sectionName }} <br />
                <small v-if="item.submittedDate">{{ item.submittedDate | dateFormat("MM/DD/YYYY hh:mm:ss A") }}</small>
                <small v-else-if="item.isInProgress">in-progress</small>
                <small v-else-if="!item.isInProgress">Not yet started</small>
              </v-col>
              <v-col cols="4" sm="4" md="4" align-self="center" class="text-center">
                {{ item.fullName }}
              </v-col>
              <v-col cols="4" sm="4" md="4" class="text-right">
                <v-select v-if="!item.id || item.isNew" :items="inspectorList" item-text="name" item-value="resourceId" v-model="item.resourceId" :disabled="(!workInstructionNameDisplay && !workInstructionName) || snackbar" @change="assignNew()" placeholder="Select Inspector">
                </v-select>
                <v-btn v-if="item.isReassign" :disabled="snackbar" @click="reassign(item)">Re-assign</v-btn>
                <v-btn v-if="item.isUnlock" :disabled="snackbar" @click="confirmUnlock(item)">Unlock</v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>

        <!--   -->
        <v-col cols="12" sm="12" md="12" v-if="!isLoggedInUserLead">
          <div v-for="(item, index) in workOrderSectionList" :key="index">
            <v-row v-if="item.isAssignable" style="margin-top:20px;">
              <v-col cols="6" sm="6" md="6" align-self="center">
                {{ item.sectionName }} <br />
                <small v-if="item.submittedDate">{{ item.submittedDate | dateFormat("MM/DD/YYYY hh:mm:ss A") }}</small>
                <small v-else-if="item.isInProgress">in-progress</small>
                <small v-else-if="!item.isInProgress">Not yet started</small>
              </v-col>
              <v-col cols="6" sm="6" md="6" align-self="center" class="text-right">
                {{ item.fullName }}
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col cols="12" sm="12" md="12" class="text-right">
          <v-btn v-if="isMergeable && isLoggedInUserLead" class="submit mr-1" color="#B14C4C" :disabled="!isFormValid || snackbar" @click="merge">
            Merge
          </v-btn>
          <v-btn class="submit" color="#B14C4C" :disabled="!isFormValid || snackbar" @click="submit">
            <v-icon>
              arrow_forward
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar" top :timeout="0" color="#4caf50">
        <v-progress-circular :size="15" v-if="!message" indeterminate color="primary"></v-progress-circular>
        <span style="padding-left:10px;">{{ message || "Loading" }}</span>
      </v-snackbar>

      <!-- Reassign dialog -->
      <v-dialog v-model="isReassign" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">Re-assign</v-card-title>
          <v-container>
            <v-select :items="inspectorList" item-text="name" item-value="resourceId" v-model="selectedSection.resourceId" :disabled="snackbar" placeholder="Select Inspector"> </v-select>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="close()" :disabled="snackbar">Cancel</v-btn>
            <v-btn class="submit" color="#B14C4C" @click="confirmReassign()" :disabled="snackbar || !selectedSection.resourceId">Confirm</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Unlock Dialog -->
      <v-dialog v-model="isUnlock" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">Unlock</v-card-title>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="close()" :disabled="snackbar">Cancel</v-btn>
            <v-btn @click="confirmUnlock()" :disabled="snackbar || !selectedSection.resourceId">Confirm</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

  <div class="hideFroala">
      <froalaView id="myHTMLId" v-model="myHTML" />
  </div>

  </div>

  

</template>

<script>
import { mapState } from "vuex";
import apiDetails from "../../shared/request/apiDetails";
import * as _ from "lodash";
import * as moment from "moment";
import auditLog from "../../shared/request/auditLogs";
import html2canvas from "html2canvas";

let state = {
  appHeaderState: {
    show: true,
    showMenu: true,
    showBack: true,
    showLogo: false,
    showTitle: true,
    title: "Assignment",
    showSearch_job: false,
    showSearch_work: false,
    showCalendar: true,
  },
};

function setState(store) {
  store.dispatch("navigation/setAppHeader", state.appHeaderState);
}
export default {
  name: "assignmentId",
  data() {
    return {
      loadingAssignment: false,
      form: {
        name: "111",
        instructionName: "",
      },
      inspectorList: [{ resourceId: "bfb824f2-110b-4034-6e0f-08d5ed80b17c", name: "James1", isLeadInspector: true }],
      leadInspectorId: null,
      isLoggedInUserLead: false,
      isOnlyInspector: true,
      snackbar: false,
      message: null,
      sections: [],
      workOrderSectionList: [],
      workInstructionList: [],
      workInstructionName: "",
      protocolNo: "",
      workInstructionId: "",
      workInstructionNameDisplay: "",
      originalworkInstructionId: "",
      filteredWorkInstructionList: [],
      search: null,
      searchProtocolNo: null,

      selectedSectionToUnlock: [],
      selectedSection: {
        id: null,
        workOrderId: null,
        sectionId: null,
        isHidden: false,
        resourceId: null,
        lastModifiedDate: null,
        result: null,
        workInstructionName: null,
        findings: null,
        submittedDate: null,
        isResourceReAssign: null,
      },
      isReassign: false,
      isUnlock: false,
      isAssignmentsUntouched: true,
      isAssigningDone: true,
      showNoDataText: false,
      workOrderDetails: {},
      resources: [],
      isAdditionalInformationTrue: false,
      myHTML: "",
      isWalmart: false
    };
  },
  mounted() {
    if (this.$route.params.id === undefined) {
      this.$router.push({
        path: "/error",
        query: {
          error: this.msgList.NoWoId,
          noAccess: "false",
        },
      });
      return;
    }
    this.myHTML = "";
    state.appHeaderState.title = this.$route.params.workOrderDisplayId;
    setState(this.$store);

    this.getAndSetCompanySubscriptionByWorkOrderId();
    
  },
  methods: {
    getWorkOrderDetails() {
      this.loadingAssignment = true;

      this.utilityFn.getServerData("get", this.apiList.workOrderAssignment + "/" + this.$route.params.id, {}, (result) => {
        this.loadingAssignment = false;
        this.workOrderDetails = result.data.data[0];

        this.isWalmart = this.workOrderDetails?.client?.toLowerCase() == "walmart global sourcing" || this.workOrderDetails?.client?.toLowerCase() == "asda stores limited";
        console.log('isWalmart:' + this.isWalmart);
        state.appHeaderState.title = this.workOrderDetails.workOrderDisplayId;
        setState(this.$store);

        this.resources = this.workOrderDetails.resources;
        this.checkInspectorList();
        this.getWorkInstructions();
      });
    },
    disableWorkInstruction() {
      let val = this.workOrderSectionList.filter((x) => x.sectionId == 6 && (x.isUnlock == true || x.resourceId != this.utilityFn.getResourceId().toLowerCase()));
      return val.length == 0 || this.isLoggedInUserLead ? false : true;
    },
    checkInspectorList() {
      // get the inspector list from the route params
      this.inspectorList = this.resources.map((item) => {
        return { resourceId: item.resourceId, name: item.fullName, isLeadInspector: item.isLeadInspector };
      });
      console.log(this.resources);
      // get the lead inspector id
      const index = this.inspectorList.findIndex((x) => x.isLeadInspector);
      this.leadInspectorId = index !== -1 ? this.inspectorList[index].resourceId : null;

      // check if the current logged in user is a lead inspector
      this.isLoggedInUserLead = this.inspectorList.findIndex((x) => x.resourceId?.toLowerCase() === apiDetails.resourceId().toLowerCase() && x.isLeadInspector) !== -1;

      // check if there is only one inspector for this job/work order
      this.isOnlyInspector = this.inspectorList.length === 1;
    },

    getWorkInstructions() {
      var other = {
        headers: {
          wresult: this.store_token,
          "Content-Type": "application/json",
        },
      };
      this.utilityFn.getServerData(
        "get",
        this.apiList.workInstructions + "/" + this.workOrderDetails.clientId, {} ,
        (res) => {
          this.workInstructionList = res.data.data;

          this.getWorkOrderSections();
        },
        other
      );
    },
    getWorkOrderSections() {
      this.workInstructionNameDisplay = this.workOrderDetails.workInstructionName;
      this.workInstructionId = this.workOrderDetails.workInstruction;
      this.originalworkInstructionId = this.workOrderDetails.workInstruction;
      this.utilityFn.getServerData("get", this.apiList.getWorkOrderInspectionSections + "/" + this.workOrderDetails.workOrderId, {}, (res) => { 
        const woSections = res.data.data;
        this.workOrderSectionList = woSections.map((w) => {
          w.isReassign = w.resourceId && !w.submittedDate;
          w.isNew = !w.resourceId;
          w.isResourceReAssign;
          w.isUnlock = w.submittedDate != null;
          w.isInProgress = w.lastModifiedDate && !w.submittedDate && w.resourceId;
          w.resourceId = w.resourceId || this.leadInspectorId;
          w.fullName = w.resourceName;
          return w;
        });

        var copyWorkOrderSectionList = this.workOrderSectionList;

        if (!this.isAdditionalInformationTrue){
          this.workOrderSectionList.forEach((workOrderSection,index) => {
            if (workOrderSection.sectionName == 'Additional Information'){
              copyWorkOrderSectionList.splice(index,1);
            }
          });
        }

        this.workOrderSectionList = copyWorkOrderSectionList;
        this.isAssigningDone = this.workOrderSectionList.filter((x) => x.isAssignable && x.id == null).length == 0;
        this.isAssignmentsUntouched = this.workOrderSectionList.filter(x => x.isAssignable && x.resourceId && !x.isNew).length >= this.workOrderSectionList.filter((x) => x.isAssignable).length;
      });
    },
    submit() {
      if (this.selectedSectionToUnlock.length >= 1) {
        this.upsertToUnlockSection();
      } else {
        this.submitAssignmentPage();
      }
    },
    upsertToUnlockSection() {
      if (this.selectedSectionToUnlock.length >= 1) {
        this.snackbar = true;
        this.utilityFn.getServerData("post", this.apiList.assignmentUpdate, this.selectedSectionToUnlock, (response) => {
          this.snackbar = false;
          auditLog.resetTransactionCode();
          this.submitAssignmentPage(true);
        });
      }
    },
    submitAssignmentPage(fromUpsert = false) {
      if (!this.isLoggedInUserLead || (this.isWorkInstructionUntouched && this.isAssignmentsUntouched)) {
        if (this.workOrderDetails.formId) {
          this.$router.push({ name: "dynamicFormId", params: { id: this.$route.params.id, resources: this.resources }, query: { displayId: this.workOrderDetails.workOrderDisplayId } });
        } else {
          this.$router.push({ name: "workOrderDetailsId", params: { id: this.$route.params.id }, query: { displayId: this.workOrderDetails.workOrderDisplayId } });
        }
        return;
      }
      this.snackbar = true;
      if (!this.isWorkInstructionUntouched || !this.isAssigningDone || !this.isAssignmentsUntouched) {
        const workOrderSections = this.workOrderSectionList.map((ws) => {
          // for sections like the sections under the Others tab,
          // since they are not assignable in the assignment page,
          // get the resourceId of the parent section and assign
          if (!ws.isAssignable && ws.section && ws.section.parentSectionId && ws.section.isParentDefaultAssignment) {
            const parentSection = this.workOrderSectionList.find((w) => w.sectionId == ws.section.parentSectionId);
            if (parentSection) {
              ws.resourceId = parentSection.resourceId;
            }
          }

          return ws;
        });

        const data = {
          workOrderId: this.workOrderDetails.workOrderId,
          workInstruction: this.filteredWorkInstructionList.find(w => w.wiName === this.workInstructionName)?.wiId,
          workInstructionName: this.workInstructionName,
          workOrderSections: _.cloneDeep(workOrderSections).map(ws => {
            // set to null before sending
            ws.dynamicSection = null;
            ws.section = null;
            return ws;
          }),
        };

        if (!fromUpsert){
          this.createRemarksAndComments();
        }

        this.utilityFn.getServerData("post", this.apiList.upsertSectionAssignment, data, (res) => {
          this.snackbar = false;

          this.$store.dispatch("inspection/setWorkOrderDetails", { ...this._workOrderDetails, workOrderInspectionSections: workOrderSections});
          if (!!this.workOrderDetails.formId) {
            this.$router.push({ name: "dynamicFormId", params: { id: this.$route.params.id, resources: this.resources }, query: { displayId: this.workOrderDetails.workOrderDisplayId } });
          } else {
            this.$router.push({ name: "workOrderDetailsId", params: { id: this.$route.params.id }, query: { displayId: this.workOrderDetails.workOrderDisplayId } });
          }
        });
      }
    },

    reassign(item) {
      this.selectedSection = { ...item };
      this.isReassign = true;
    },

    close() {
      this.selectedSection = {
        id: null,
        workOrderId: null,
        sectionId: null,
        isHidden: false,
        resourceId: null,
        lastModifiedDate: null,
        result: null,
        workInstructionName: null,
        findings: null,
        submittedDate: null,
        isResourceReAssign: null,
      };
      this.isReassign = false;
      this.isUnlock = false;
    },
    confirmReassign() {
      const index = this.workOrderSectionList.findIndex((x) => x.sectionId == this.selectedSection.sectionId && x.dynamicSectionId == this.selectedSection.dynamicSectionId);
      const selectedResource = this.inspectorList.find((x) => x.resourceId?.toLowerCase() == this.selectedSection.resourceId.toLowerCase());
      if (index !== -1) {
        if (this.workOrderSectionList[index].resourceId.toLowerCase() != selectedResource.resourceId.toLowerCase()) {
          this.isAssignmentsUntouched = false;
        }

        this.workOrderSectionList[index].resourceId = this.selectedSection.resourceId;
        this.workOrderSectionList[index].fullName = selectedResource ? selectedResource.name : "";
        this.workOrderSectionList[index].isResourceReAssign = true;
      }

      this.close();
    },
    confirmUnlock(item) {
      this.isAssignmentsUntouched = false;
      this.snackbar = true;
      this.selectedSection = { ...item };
      this.selectedSection.resourceId = null;
      this.selectedSection.submittedDate = null;
      this.selectedSection.IsResourceReAssign = true;
      this.selectedSection.isHidden = false;
      this.selectedSectionToUnlock.push(this.selectedSection);

      const index = this.workOrderSectionList.findIndex((x) => (x.sectionId && x.sectionId == this.selectedSection.sectionId) || (x.dynamicSectionId && x.dynamicSectionId == this.selectedSection.dynamicSectionId));

      if (index !== -1) {
        this.workOrderSectionList[index].fullName = null;
        this.workOrderSectionList[index].resourceId = null;
        this.workOrderSectionList[index].isNew = true;
        this.workOrderSectionList[index].submittedDate = null;
        this.workOrderSectionList[index].isResourceReAssign = true;
        this.workOrderSectionList[index].isUnlock = false;
        this.workOrderSectionList[index].isHidden = false;
        this.snackbar = false;
      }
    },
    assignNew() {
      this.isAssignmentsUntouched = false;
    },
    merge() {
      this.snackbar = true;
      setTimeout(() => {
        this.message = "Success!";
        setTimeout(() => {
          this.message = null;
          this.snackbar = false;
        }, 1000);
      }, 2000);
    },
    requestHeader() {
      return {
        headers: {
          wresult: this.store_token,
          "Content-Type": "application/json",
        },
      };
    },
    logChanges(log = true) {
      window.sessionStorage.setItem("EditDataLogging", log);
    },
    async getAndSetCompanySubscriptionByWorkOrderId(){
      const result = await this.utilityFn.getServerDataAsync("get", this.apiList.getCompanySubscriptionByWorkOrderId + "/" + this.$route.params.id, {});
      this.isAdditionalInformationTrue = result.data.additionalInformationSectionInAIMS;
      this.getWorkOrderDetails();
    },
    onChangeWorkInstructionName() {
      this.protocolNo = this.filteredWorkInstructionList.find(x => x.wiName === this.workInstructionName)?.wiId;
    },
    onChangeProtocolNo() {
      this.workInstructionName = this.filteredWorkInstructionList.find(x => x.wiId === this.protocolNo)?.wiName;
    },
    async createRemarksAndComments(){
      const statementGlossary = await this.utilityFn.getServerDataAsync("post", this.apiList.getStatementGlossaryByWorkOrderId + "/" + this.workOrderDetails.workOrderId, {})

      if (statementGlossary.data.length){
        statementGlossary.data.forEach((statement,statementIndex) => {
          let getCanvas
        let that = this;
        this.myHTML = statement.statement;
        let utilityFnLink = this.utilityFn;
        let apiRemarks = this.apiList.upsertWorkOrderRemarks;
        let wOId = this.workOrderDetails.workOrderId;
        var classification =   [
                { id: 1, description: "Fail" },
                { id: 2, description: "Pending" },
                { id: 3, description: "Informative" },
                { id: 4, description: "Abort" },
                { id: 5, description: "Pass"}, 
                { id: 6, description: "CAPA"},
                { id: 7, description: "Not Ready"}
            ];
        var myHtmlId = document.getElementById("myHTMLId");
        myHtmlId.textContent = statement.statement;
        console.log(myHtmlId);

        html2canvas(myHtmlId).then(async function(canvas) {
          var screenshot = canvas.toDataURL("image/png");
          const url = screenshot;
          fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], "File name", {
              type: "image/png",
            });
            getCanvas = file;

            that.getBase64(file).then((base64) => {
              getCanvas.base64 = base64;

              var classificationList = classification.filter(x => x.description == statement.classification);
              var classificationInt = null;
              if (classificationList.length != 0){
                classificationInt = classificationList[0].id;
              }
              const result = utilityFnLink.getServerDataAsync(
              "post",
              apiRemarks,
              {
                  workOrderId: wOId,
                  description: statement.statement,
                  classification: classificationInt,
                  sectionId: 12,
                  screen: 12,
                  image: getCanvas.base64,
                  validateCharCount : that.isWalmart,
                  order: statementIndex
              },
              (res) => {
                result = res.data;
                console.log(result);
              })
            });
          })
        });
        });
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
  computed: {
    ...mapState("inspection", {
      store_token: (state) => state.token,
      _workOrderDetails: (state) => state.workOrderDetails
    }),
    isFormValid() {
      if ((this.workInstructionName || this.workInstructionNameDisplay) && (!this.isLoggedInUserLead || this.isOnlyInspector)) return true;

      let isValid = (this.workInstructionName || this.workInstructionNameDisplay) && this.workOrderSectionList.length > 0;
      this.workOrderSectionList.forEach((section) => {
        if (!section.resourceId) {
          isValid = false;
          return;
        }
      });

      return isValid;
    },

    isMergeable() {
      const submittedSections = this.workOrderSectionList.filter(x => !!x.submittedDate).length;
      const submittableSections = this.workOrderSectionList.filter(x => x.isAssignable && x.isSubmittable).length;
      return submittedSections > 0 && submittedSections >= submittableSections;
    },

    isWorkInstructionUntouched() {
      if(this.workInstructionName) {
        return this.originalworkInstructionId === this.workInstructionList.find(w => w.wiName === this.workInstructionName)?.wiId;
      } else {
        return this.originalworkInstructionId === this.workInstructionList.find(w => w.wiName === this.workInstructionNameDisplay)?.wiId;
      }
    },
  },
  watch: {
    search(val) {
      if (!val || val.length < 3) {
        this.workInstructionName = null;
        this.filteredWorkInstructionList = [];
        this.showNoDataText = false;
        return;
      }

      this.showNoDataText = true;
      this.filteredWorkInstructionList = this.workInstructionList.filter((x) => x.wiName.toLowerCase().includes(val.toLowerCase()));
    },

    searchProtocolNo(val) {
      if (!val || val.length < 3) {
        this.workInstructionName = null;
        this.filteredWorkInstructionList = [];
        this.showNoDataText = false;
        return;
      }

      this.showNoDataText = true;
      this.filteredWorkInstructionList = this.workInstructionList.filter((x) => x.wiId.toLowerCase().includes(val.toLowerCase()));
    },
  },
};
</script>

<style lang="stylus" scoped>
.box
    background: #fff;
>>>.v-snack__content
    justify-content:center;
.submit:not([disabled])
    color: #fff;
    max-width:10%
 .snackbar-loading
        align-items center
        display flex
        justify-content center
  .hideFroala
        position: absolute !important
        z-index: -1 !important
    
</style>
