<template>
    <div name="stockInspected">
        <v-row v-if="generalData.resources.length > 1 && isAuthorized">
            <v-col cols="12 " sm="12" md="12" style="text-align:right;">
                <v-btn id="stockinspected_submitButton" :disabled="isSectionSubmitted()" class="submit-btn" @click="submit_item()" small depressed color="#1867c0" auto-id="stock inspected submit button">Submit</v-btn>
            </v-col>
        </v-row>
        <v-card :style="isAuthorized ? '':'margin:24px 0 8px'">
            <v-card-title style="padding-bottom:0px;">
                <div class="select_sort">
                    <span id="stockinspected_skuButton" :class="change_sort_type == 'sku'? 'active':''" @click="change_sort('sku')" auto-id="stock inspected sku button">SKU</span>
                    <span id="stockinspected_poButton" :class="change_sort_type == 'po'? 'active':''" @click="change_sort('po')" auto-id="stock inspected po# button">PO#</span>
                </div>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-title style="padding-top:0px;">
                <v-text-field id="stockinspected_search_input" v-model="search" append-icon="search" label="Search" single-line hide-details auto-id="stock inspected search input"></v-text-field>
            </v-card-title>
            <v-data-table id="stockinspected_table" :headers="headers" :items="generalData.products" :items-per-page="5" :search="search" auto-id="stock inspected table">
                    <template v-slot:item.menu="{ item }">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn :id="'stockinspected_menu_' + item.id" icon v-on="on" :disabled="!isAuthorized" auto-id="stock inspected menu">
                                <v-icon color="rgba(0,0,0,.87)">menu</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-for="(x, index) in stockInspected_menu" :key="index" auto-id="stock inspected menu">
                                <v-list-item-title :id="'stockinspected_menu_' + x.name" :auto-id="'stock inspected menu item'+index">
                                    <router-link :to="{name: x.url_name,params:{ id: $route.params.id, headerTitle:'Stock Inspected '}, query:{id: item.id, headerTitle:'Stock Inspected ', workOrderId: $route.params.id}}">
                                        {{ x.title }}
                                    </router-link>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>

            </v-data-table>
        </v-card>
        <v-row style="padding-left: 0" v-if="isAuthorized">
            <v-col cols="12" sm="12" @click="go_add_defect" class="add_defect_button">
                <icon name="plus-circle"></icon>
                <span id="stockinspected_add_defect" style="vertical-align: text-bottom;" auto-id="add defect button">Add Defect</span>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'stockInspected',
    data (){
        return {
            // Stock Inspected 
            change_sort_type: 'sku',
            search: '',
            headers: [
                { text: 'SKU', align: 'left', value: 'skuDisplay',},
                { text: 'PO#', align: 'left', value: 'po',},
                { text: 'Menu', align: 'left', sortable: false, value: 'menu',}

            ],
            workOrderProducts: [],
            desserts: [
                {
                    sku: 43220,
                    po: 160,
                    menu: ''
                },
                {
                    sku: 43221,
                    po: 159,
                    menu: ''
                }
            ],
            stockInspected_menu: [
                { title: 'Add Defect' , url_name: 'workOrderDefectId', id: 1, name:'add_defect'},
                { title: 'Edit', url_name: 'workOrderDefectEditId', name:'edit'},
                { title: 'Add Defect Photo', url_name: 'workOrderDefectAddDefectPhotoId', name:'add_defect_photo' },
                { title: 'Add Inspection Photo', url_name: 'uploadPhotoInspectionId', name:'add_inspection_photo'}
            ],
            sectionId: 11
        }
    },
    computed: {
        ...mapState('inspection', {
            sectionData: state => state.sectionData,
            workOrderDetails: (state) => state.workOrderDetails,
        }),
    },
    mounted () {
        let skuSort = this.generalData.products;
        skuSort = _.orderBy(skuSort, ['sku'],['asc']);
        this.generalData.products  = skuSort
    },
    methods: {
        go_add_defect () {
            this.$router.push({name: 'workOrderDefectId', params:{id: this.$route.params.id}});
        },
        change_sort (type) {
            this.headers.splice(1, 1);
            this.change_sort_type = type;
            if (type == 'sku') {
                this.headers.unshift({ text: 'SKU', align: 'left',value: 'skuDisplay',});
            } else {
                this.headers.unshift({ text: 'PO#', align: 'left',value: 'po',});

            }
        },
        // 
        submit_item(sectionName) {
            const index = this.workOrderDetails.workOrderInspectionSections.findIndex(x => x.sectionId === this.sectionId);
            if (index != -1) {
                const section = {
                    sectionId: 11,
                    workOrderId: this.$route.params.id
                }
                this.utilityFn.getServerData('post', this.apiList.submitInspectionSection, section, res => {
                    this.workOrderDetails.workOrderInspectionSections[index].submittedDate = new Date();

                    this.sectionData.StockInspected.isSectionSubmitted = true;
                    this.$store.dispatch("inspection/setWorkOrderDetails", this.workOrderDetails);
                    this.$store.dispatch('inspection/setSectionData', this.sectionData);
                    this.$store.dispatch('inspection/editMyAuthorized', {id:11, key:'write', value:false});
                });
            }
        },
        isSectionSubmitted() {
            const section = this.workOrderDetails.workOrderInspectionSections.find(x => x.sectionId === this.sectionId);
            return section && !!section.submittedDate;
        }
    },
    props: ['generalData', 'isAuthorized']
};
</script>

<style lang="stylus" scoped>
    // Stock Inspected 
    .select_sort
        border: 1px #1890FF solid
        display inline-block
        text-align center
    .select_sort > span
        width 60px
        height 30px
        font-size 12px
        float left
    .select_sort > .active
        background #1890FF
        color #fff

    .add_defect_button
        color #b14c4c
        margin-top 10px
        padding-bottom: 0px
    .submit-btn
        color: #fff !important
</style>
