
<template>
  <div id="cfrDraftShortReportFormDialog">
     <v-dialog v-model="showDialog" persistent max-width="900"  >
      <v-card id="cfr-draft-short-report-dialog">
        <v-card-title>
          <span class="headline">Select a Report</span>
        </v-card-title>
        <v-row class="mb-2" align="center" justify="space-around"> 
             <v-btn  width="600px" height="50px" @click="clickAbortShortReport()">
                Abort Short Report
            </v-btn>
        </v-row>
        <v-row class="mb-2" align="center" justify="space-around">
            <v-btn  width="600px" height="50px" @click="clickAbortInspectionDetailsSummaryReport()">
                Abort Inspection Details Summary Report
            </v-btn>
        </v-row>
         
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn id="btnClose" color="blue darken-1" text @click="closePreview()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <report-view :showDialog.sync="showReportview" :reportData="reportDraftShortData" ></report-view>
            
  </div>
</template>

<script>
import * as pbi from "powerbi-client";
import ReportView from "@/components/reports/ReportView";

export default {
    components: {
       ReportView,
    },
  props: {
    showDialog: {
      type: Boolean,
    },
    reportData: {
      type: Object,
    },
  },

  data() {
    return {
      // power bi preview
      reportDraftShortData:{},
      showReportview:false,
      showReport: false,
      showPreviewContainer: false,
      genericTemplates: [],
      previewUnavailable: false,
      reworkAbortReportConfig: null
    };
  },
  methods: {
   clickAbortShortReport() {
       this.showReportview = true;
       this.reportData.url = process.env.VUE_APP_POWER_BI_DRAFT_URL;
       this.reportDraftShortData = this.reportData
      
   },
   clickAbortInspectionDetailsSummaryReport() {
       this.showReportview = true;
       this.reportData.url = process.env.VUE_APP_POWER_BI_PREVIEW_URL;
       this.reportDraftShortData = this.reportData
   },

    closePreview() {
      this.$emit("update:showDialog", false);
    },
  },
  watch: {
        $route (to, from) {
            // if navigating to a different page, close the preview dialog if open
            if (this.showReportview) {
                this.showReportview = false;
            }
        },
        showReportview(v){
            console.log("showdialog", v);
          if(!v){
            this.reportDraftShortData = {};
            
          }
        }
    }

  
};
</script>

<style lang="stylus" scoped>
#preview-container
    height 100%

#cfr-draft-short-report-dialog
    display flex
    flex-direction column
    height 35vh

#cfr-draft-short-report-dialog .v-card__text
    flex-grow 1
    height 85%

>>> .v-dialog
    max-height 100%
</style>
