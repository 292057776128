export default {
    getToken(state) {
      return state.token;
    },
    getWorkOrderDetails(state) {
      return state.workOrderDetails;
    },
    getProductQuantityVerificationList(state) {
      return state.productQuantityVerificationList;
    },
     
    
}