<template>
  <div class="content" v-if="visibleDay">
    <v-overlay :value="showLoading" :z-index="10"></v-overlay>
      <v-snackbar class="snackbar-loading" v-model="showLoading" centered :timeout="0" color="#4caf50">
        <v-progress-circular :size="15" indeterminate color="primary"></v-progress-circular>
        <span style="padding-left:10px;">Loading</span>
      </v-snackbar>
    <v-row class="mt-2">
      <label>{{ day }}</label>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="computedDateFormatted"
              persistent-hint
              prepend-icon="event"
              :readonly="false"
              label="Inspection Date"
              v-on="on"
              :append-icon="true ? 'edit' : ''"
              @keyup="formatInputtedDate($event)"
              auto-id="Inspection Date"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="inspectionDate"
            @change="saveFieldValues()"
            @input="dateMenu = false"
            color="blue darken-1"
            auto-id="Inspection Date Picker"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="3" align-self="center">
          <v-btn outlined color="error" text @click="setTodayDate()" auto-id="today">Today</v-btn>
      </v-col>
      <v-col cols="12" sm="1">
      </v-col>
      <v-col cols="12" sm="1" align-self="center">
          <v-btn small outlined color="error" @click="addNewInspectionTime()" :disabled="!addButtonEnable" auto-id="inspection time add">
            <span class="material-icons">
              add
            </span>
          </v-btn>
      </v-col>
      <v-col cols="12" sm="1" align-self="center">
          <v-btn small outlined color="error" @click="deleteInspectionTime()" :disabled="inspectionTimeCount <= 1" auto-id="inspection time delete">
            <span class="material-icons">
              delete
            </span>
          </v-btn>
      </v-col>
    </v-row>

    <!-- Factory Time IN | OUT -->
    <v-row class="rowClass">
      <v-col class="colClass" cols="12" sm="6" md="5">
        <span class="mr-right-3">Factory Time In</span>
        <!-- Factory Time In -->
        <v-row>
          <v-col cols="12" sm="5" md="4">
            <v-text-field
              id="txtFactoryTimeIn"
              maxlength="2"
              height="90px"
              max
              class="hrClass textAlignCenter"
              @keyup="keyUpValue($event, 'FactoryTimeIn')"
              v-model="factoryTimeIn"
              hide-details
              outlined
              dense
              auto-id="factory time in"
            ></v-text-field>
            <span class="hintClass">01-12</span>
          </v-col>
          <v-col cols="12" sm="1" md="1">
            <span class="colonClass">:</span>
          </v-col>
          <v-col cols="12" sm="5" md="4">
            <v-text-field
              id="txtFactoryTimeInMin"
              maxlength="2"
              height="90px"
              class="hrClass textAlignCenter"
              @keyup="keyUpMinValue($event, 'FactoryTimeInMin')"
              v-model="factoryTimeInMin"
              hide-details
              outlined
              dense
              auto-id="factory time in min"
            ></v-text-field>
            <span class="hintClass">00-59</span>
          </v-col>
          <v-col cols="12" sm="1" md="1">
            <div class="divClass">
              <v-btn
                id="btnTimeInAM"
                elevation="2"
                depressed
                x-small
                :color="timeInAm ? '#c2e0fd' : '#fff'"
                class="btnSize"
                @click="convertTime('TimeInAM', true)"
                auto-id="btn time in am"
                >AM</v-btn
              >
              <br />
              <v-btn
                id="btnTimeInPM"
                elevation="2"
                depressed
                x-small
                :color="timeInPm ? '#c2e0fd' : '#fff'"
                class="btnSize"
                @click="convertTime('TimeInPM', false)"
                auto-id="btn time in pm"
                >PM</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col class="colClass" cols="12" sm="6" md="5">
        <span class="mr-right-3">Factory Time Out</span>
        <v-row>
          <!-- Factory Time Out -->
          <v-col cols="12" sm="5" md="4">
            <v-text-field
              id="txtFactoryTimeOut"
              maxlength="2"
              height="90px"
              class="hrClass textAlignCenter"
              @keyup="keyUpValue($event, 'FactoryTimeOut')"
              v-model="factoryTimeOut"
              hide-details
              outlined
              dense
              @blur="saveFieldValues()"
              auto-id="factory time out"
            ></v-text-field>
            <span class="hintClass">01-12</span>
          </v-col>
          <v-col cols="12" sm="1" md="1">
            <span class="colonClass">:</span>
          </v-col>
          <v-col cols="12" sm="5" md="4">
            <v-text-field
              id="txtFactoryTimeOutMin"
              maxlength="2"
              height="90px"
              class="hrClass textAlignCenter"
              @keyup="keyUpMinValue($event, 'FactoryTimeOutMin')"
              v-model="factoryTimeOutMin"
              hide-details
              outlined
              dense
              @blur="saveFieldValues()"
              auto-id="factory time out min"
            ></v-text-field>
            <span class="hintClass">00-59</span>
          </v-col>
          <v-col cols="12" sm="1" md="1">
            <div class="divClass">
              <v-btn
                id="btnTimeOutAM"
                elevation="2"
                depressed
                x-small
                :color="timeOutAm ? '#c2e0fd' : '#fff'"
                class="btnSize"
                @click="convertTime('TimeOutAM', true)"
                auto-id="factory time out am"
                >AM</v-btn
              >
              <br />
              <v-btn
                id="btnTimeOutPM"
                elevation="2"
                depressed
                x-small
                :color="timeOutPm ? '#c2e0fd' : '#fff'"
                class="btnSize"
                @click="convertTime('TimeOutPM', false)"
                auto-id="factory time out pm"
                >PM</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Inspection Start | End Time -->
    <v-row class="rowClass">
      <v-col class="colClass" cols="12" sm="6" md="5">
        <span class="mr-right-3">Inspection Start Time</span>
        <v-row>
          <!-- Inspection Start -->
          <v-col cols="12" sm="5" md="4">
            <v-text-field
              id="txtInspectionStartTime"
              maxlength="2"
              height="90px"
              class="hrClass textAlignCenter"
              @keyup="keyUpValue($event, 'InspectionStartTime')"
              v-model="inspectionStartTime"
              hide-details
              outlined
              dense
              auto-id="inspection start time"
            ></v-text-field>
            <span class="hintClass">01-12</span>
          </v-col>
          <v-col cols="12" sm="1" md="1">
            <span class="colonClass">:</span>
          </v-col>
          <v-col cols="12" sm="5" md="4">
            <v-text-field
              id="txtInspectionStartTimeMin"
              maxlength="2"
              height="90px"
              class="hrClass textAlignCenter"
              @keyup="keyUpMinValue($event, 'InspectionStartTimeMin')"
              v-model="inspectionStartTimeMin"
              hide-details
              outlined
              dense
              auto-id="inspection start time min"
            ></v-text-field>
            <span class="hintClass">00-59</span>
          </v-col>
          <v-col cols="12" sm="1" md="1">
            <div class="divClass">
              <v-btn
                id="btnInspectionStartTimeAM"
                elevation="2"
                depressed
                x-small
                :color="startTimeAm ? '#c2e0fd' : '#fff'"
                class="btnSize"
                @click="convertTime('StartTimeAM', true)"
                auto-id="inspection start time am"
                >AM</v-btn
              >
              <br />
              <v-btn
                id="btnInspectionStartTimePM"
                elevation="2"
                depressed
                x-small
                :color="startTimePm ? '#c2e0fd' : '#fff'"
                class="btnSize"
                @click="convertTime('StartTimePM', false)"
                auto-id="inspection start time pm"
                >PM</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="colClass" cols="12" sm="6" md="5">
        <span class="mr-right-3">Inspection End Time</span>
        <v-row>
          <!-- Inspection End -->
          <v-col cols="12" sm="5" md="4">
            <v-text-field
              id="txtInspectionEndTime"
              maxlength="2"
              height="90px"
              class="hrClass textAlignCenter"
              @keyup="keyUpValue($event, 'InspectionEndTime')"
              v-model="inspectionEndTime"
              hide-details
              outlined
              dense
              @blur="saveFieldValues()"
              auto-id="inspection end time"
            ></v-text-field>
            <span class="hintClass">01-12</span>
          </v-col>
          <v-col cols="12" sm="1" md="1">
            <span class="colonClass">:</span>
          </v-col>
          <v-col cols="12" sm="5" md="4">
            <v-text-field
              id="txtInspectionEndTimeMin"
              maxlength="2"
              height="90px"
              class="hrClass textAlignCenter"
              @keyup="keyUpMinValue($event, 'InspectionEndTimeMin')"
              v-model="inspectionEndTimeMin"
              hide-details
              outlined
              dense
              @blur="saveFieldValues()"
              auto-id="inspection end time min"
            ></v-text-field>
            <span class="hintClass">00-59</span>
          </v-col>
          <v-col cols="12" sm="1" md="1">
            <div class="divClass">
              <v-btn
                id="btnInspectionEndTimeAM"
                elevation="2"
                depressed
                x-small
                :color="endTimeAm ? '#c2e0fd' : '#fff'"
                class="btnSize"
                @click="convertTime('EndTimeAM', true)"
                auto-id="inspection end time am"
                >AM</v-btn
              >
              <br />
              <v-btn
                id="btnInspectionEndTimePM"
                elevation="2"
                depressed
                x-small
                :color="endTimePm ? '#c2e0fd' : '#fff'"
                class="btnSize"
                @click="convertTime('EndTimePM', false)"
                auto-id="inspection end time pm"
                >PM</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="10">
      </v-col>
      <v-col cols="12" sm="1">
        <v-btn small outlined color="error" @click="clearData()" auto-id="inspection time clear">Clear</v-btn>
      </v-col>
      <v-col cols="12" sm="1">
        <v-btn small outlined color="error" @click="saveFieldValues()" auto-id="inspection time save">Save</v-btn>
      </v-col>
    </v-row>

    <v-row v-if="clientName.toLowerCase() !== 'lojas renner s/a'">
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          :id="totalTravelTime"
          v-model="totalTravelTime"
          label="Total Travel Time (mins)"
          :readonly="false"
          :append-icon="true ? 'edit' : ''"
          type="number"
          @change="saveFieldValues()"
          auto-id="total travel time"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          :id="lunchTime"
          v-model="lunchTime"
          label="Lunch Time (mins)"
          :readonly="false"
          :append-icon="true ? 'edit' : ''"
          type="number"
          @change="saveFieldValues()"
          auto-id="lunch time"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row v-if="clientName.toLowerCase() === 'lojas renner s/a'">
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          :id="waitingTime"
          v-model="waitingTime"
          label="Waiting Time (mins)"
          :readonly="false"
          :append-icon="true ? 'edit' : ''"
          type="number"
          @change="saveFieldValues()"
          auto-id="waiting time"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-snackbar
      class="snackbar-loading" 
      v-model="snackbarError"
      :timeout="timeoutError"
      :color="colorError"
    >
      {{ messageError }}
      <v-btn text @click="snackbarError = false"> Close </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import * as moment from "moment";

export default {
  name: "InspectionTime",
  data() {
    return {
      isMobile: false,
      id: null,
      inspectionDate: null,
      lunchTime: null,
      totalTravelTime: null,
      waitingTime: null,
      dateMenu: false,
      factoryTimeIn: '00',
      factoryTimeInMin: '00',
      factoryTimeOut: '00',
      factoryTimeOutMin: '00',
      inspectionStartTime: '00',
      inspectionStartTimeMin: '00',
      inspectionEndTime: '00',
      inspectionEndTimeMin: '00',
      getFactoryTimeIn: null,
      getFactoryTimeOut: null,
      getInspectionStartTime: null,
      getInspectionEndTime: null,
      getFactoryTimeInMin: null,
      getFactoryTimeOutMin: null,
      getInspectionStartTimeMin: null,
      getInspectionEndTimeMin: null,
      timeInAm: true,
      timeInPm: false,
      timeOutAm: true,
      timeOutPm: false,
      startTimeAm: true,
      startTimePm: false,
      endTimeAm: true,
      endTimePm: false,
      snackbarError: false,
      messageError: "",
      timeoutError: 3000,
      colorError: "red darken-2",
      errorMessage1: "Factory Time Out should not be earlier than the factory time in",
      errorMessage2: "Inspection start time should not be earlier than the factory time in",
      errorMessage3: "Inspection end time should not be less than or equal to inspection start time",
      errorMessage4: "Please input valid minute(s)",
      errorMessage5: "Please input valid hour(s)",
      errorMessage: "",
      isMinuteValid: true,
      isValueNull: true,
      visibleDay: true,
      showLoading: false
    };
  },
  computed: {
    computedDateFormatted: {
      get() {
        return this.formatDate(this.inspectionDate);
      },
      set(){
        return this.formatDate(this.inspectionDate);
      }
    },
    addButtonEnable(){
      return this.day.split(' ')[1] == this.inspectionTimeCount;
    }
  },
  mounted() {
    if (this.resourceId != null){
      this.loadValues();
    }
  },
  methods: {
      getFormattedTime(value, key) {
            if(value)
            {
            var [secondM, firstM, secondH, firstH] = value
              .toString()
              .split("")
              .reverse();

            firstH = firstH ? firstH : "0";
            secondH = secondH ? secondH : "0";
            firstM = firstM ? firstM : "0";
            secondM = secondM ? secondM : "0";

            var hours = Number(firstH + secondH);
            var minutes = Number(firstM + secondM);

            hours = ((hours + 11) % 12 + 1);

            var sHours = hours.toString();
            var sMinutes = minutes.toString();

            if (hours < 10) sHours = "0" + sHours;
            if (minutes < 10) sMinutes = "0" + sMinutes;

          return key == 'hours' ? sHours : sMinutes
        }
        else
        {
          return '00';
        }
    },
    convertTime(val, isAM) {
      this.snackbarError = false;
       if (val== 'TimeInAM' || val== 'TimeInPM') {
        this.factoryTimeIn =  this.getFactoryTimeIn != null ? this.getTheResult(this.getFactoryTimeIn, isAM) : this.factoryTimeIn
        this.isValueNull = this.getFactoryTimeIn == null ? true : false
      }
      if (val== 'TimeOutAM' || val== 'TimeOutPM') {
        this.factoryTimeOut =  this.getFactoryTimeOut != null ? this.getTheResult(this.getFactoryTimeOut, isAM) : this.factoryTimeOut
        this.isValueNull = this.getFactoryTimeOut == null ? true : false
      }
      if (val== 'StartTimeAM' || val== 'StartTimePM') {
        this.inspectionStartTime =  this.getInspectionStartTime != null ? this.getTheResult(this.getInspectionStartTime, isAM) : this.inspectionStartTime
        this.isValueNull = this.getInspectionStartTime == null ? true : false
      }
      if (val== 'EndTimeAM' || val== 'EndTimePM') {
        this.inspectionEndTime =  this.getInspectionEndTime != null ? this.getTheResult(this.getInspectionEndTime, isAM) : this.inspectionEndTime
        this.isValueNull = this.getInspectionEndTime == null ? true : false
      }

      if (!this.isValueNull) {
    if (this.isMinuteValid) {
        if (val == 'TimeInAM' || val == 'TimeInPM') {
            if ((this.factoryTimeOut == '00' || this.factoryTimeOut == null) || (Number(this.factoryTimeOut) >= Number(this.factoryTimeIn))) {
                if (
                    Number(this.inspectionStartTime) > Number(this.factoryTimeIn)
                        || (Number(this.inspectionStartTime) == Number(this.factoryTimeIn) && Number(this.inspectionStartTimeMin) >= Number(this.factoryTimeInMin))
                        || this.inspectionStartTime == '00'
                        || this.inspectionStartTime == null) {
                    this.factoryTimeIn = this.factoryTimeIn
                    this.factoryTimeInMin = this.factoryTimeInMin
                    if (val == 'TimeInAM') {
                        this.timeInAm = true
                        this.timeInPm = false
                    }
                    if (val == 'TimeInPM') {
                        this.timeInAm = false
                        this.timeInPm = true
                    }
                }
                else {
                    this.factoryTimeIn = this.getFactoryTimeIn
                    this.messageError = this.errorMessage2
                    this.timeInAm = false
                    this.timeInPm = false
                    this.snackbarError = true;
                }
            }
            else {
                this.factoryTimeIn = this.getFactoryTimeIn
                this.messageError = this.errorMessage1
                this.timeInAm = false
                this.timeInPm = false
                this.snackbarError = true;
            }
        }
        if (val == 'TimeOutAM' || val == 'TimeOutPM') {
            if (Number(this.factoryTimeOut) > Number(this.factoryTimeIn)
                || (Number(this.factoryTimeOut) == Number(this.factoryTimeIn) && Number(this.factoryTimeOutMin) >= Number(this.factoryTimeInMin))) {
                this.factoryTimeOut = this.factoryTimeOut
                this.factoryTimeOutMin = this.factoryTimeOutMin
                if (val == 'TimeOutAM') {
                    this.timeOutAm = true
                    this.timeOutPm = false
                }
                if (val == 'TimeOutPM') {
                    this.timeOutAm = false
                    this.timeOutPm = true
                }
            }
            else {
                this.factoryTimeOut = this.getFactoryTimeOut
                this.messageError = this.errorMessage1
                this.timeOutAm = false
                this.timeOutPm = false
                this.snackbarError = true;
            }
        }
        if (val == 'StartTimeAM' || val == 'StartTimePM') {
            if (Number(this.inspectionStartTime) > Number(this.factoryTimeIn)
                || (Number(this.inspectionStartTime) == Number(this.factoryTimeIn) && Number(this.inspectionStartTimeMin) >= Number(this.factoryTimeInMin))) {
                if (Number(this.inspectionEndTime) > Number(this.inspectionStartTime)
                    || (Number(this.inspectionEndTime) == Number(this.inspectionStartTime) && Number(this.inspectionEndTimeMin) > Number(this.inspectionStartTimeMin))
                    || this.inspectionEndTime == null
                    || this.inspectionEndTime == '00') {
                    this.inspectionStartTime = this.inspectionStartTime
                    this.inspectionStartTimeMin = this.inspectionStartTimeMin
                    if (val == 'StartTimeAM') {
                        this.startTimeAm = true
                        this.startTimePm = false
                    }
                    if (val == 'StartTimePM') {
                        this.startTimeAm = false
                        this.startTimePm = true
                    }
                }
                else {
                    this.inspectionStartTime = this.getInspectionStartTime
                    this.messageError = this.errorMessage3
                    this.startTimeAm = false
                    this.startTimePm = false
                    this.snackbarError = true;
                }
            }
            else {
                this.inspectionStartTime = this.getInspectionStartTime
                this.messageError = this.errorMessage2
                this.startTimeAm = false
                this.startTimePm = false
                this.snackbarError = true;
            }
        }
        if (val == 'EndTimeAM' || val == 'EndTimePM') {
            if (Number(this.inspectionEndTime) > Number(this.inspectionStartTime)
                || (Number(this.inspectionEndTime) == Number(this.inspectionStartTime) && Number(this.inspectionEndTimeMin) > Number(this.inspectionStartTimeMin))
                || this.inspectionStartTime == null
                || this.inspectionStartTime == '00') {
                this.inspectionEndTime = this.inspectionEndTime
                this.inspectionEndTimeMin = this.inspectionEndTimeMin
                if (val == 'EndTimeAM') {
                    this.endTimeAm = true
                    this.endTimePm = false
                }
                if (val == 'EndTimePM') {
                    this.endTimeAm = false
                    this.endTimePm = true
                }
            }
            else {
                this.inspectionEndTime = this.getInspectionEndTime
                this.messageError = this.errorMessage3
                this.endTimeAm = false
                this.endTimePm = false
                this.snackbarError = true;
            }
        }
    }
    else {
        this.messageError = this.errorMessage
        this.snackbarError = true;
    }

    if (!this.snackbarError) {
        this.saveFieldValues();
    }

      }
    },
    getTheResult(value, isAM){
       let valueReturn
      this.isMinuteValid = true
      value = parseInt(value)
      if (isAM) {
        if (value < 10) {
          valueReturn = '0' + value
        }
        else {
          if(value == 12) {
            valueReturn = '00'
          }
          else {
            valueReturn = value
          }
        }
      }
      else {
        if (value <= 11) {
          valueReturn = value + 12
        }
        else {
          valueReturn = 12
        }
      }
      return valueReturn;
    },
    keyUpValue(val, input) {
      let checkIfNumbers = /^[0-9]+$/;
      let isNumber = checkIfNumbers.test(val.target.value)

      if (isNumber && val.target.value != '' && (val.target.value == 0 || Number(val.target.value) <= 12)) {
        if(input == 'FactoryTimeIn') {
          this.getFactoryTimeIn = val.target.value
        }
        if(input == 'FactoryTimeOut') {
          this.getFactoryTimeOut = val.target.value
        }
        if(input == 'InspectionStartTime') {
          this.getInspectionStartTime = val.target.value
        }
        if(input == 'InspectionEndTime') {
          this.getInspectionEndTime = val.target.value
        }
      }
      else {
          if (val.target.value.length != 0) {
            if(input == 'FactoryTimeIn') {
              this.factoryTimeIn = this.getFactoryTimeIn
            }
            if(input == 'FactoryTimeOut') {
              this.factoryTimeOut = this.getFactoryTimeOut
            }
            if(input == 'InspectionStartTime') {
              this.inspectionStartTime = this.getInspectionStartTime
            }
            if(input == 'InspectionEndTime') {
              this.inspectionEndTime = this.getInspectionEndTime
            }
          }
      }
    },

    keyUpMinValue(val, input) {
      let checkIfNumbers = /^[0-9]+$/;
      let isNumber = checkIfNumbers.test(val.target.value)
      if (isNumber && val.target.value != '' && (val.target.value == 0 || Number(val.target.value) <= 59)) {
        if(input == 'FactoryTimeInMin') {
          this.getFactoryTimeInMin = val.target.value
        }
        if(input == 'FactoryTimeOutMin') {
          this.getFactoryTimeOutMin = val.target.value
        }
        if(input == 'InspectionStartTimeMin') {
          this.getInspectionStartTimeMin = val.target.value
        }
        if(input == 'InspectionEndTimeMin') {
          this.getInspectionEndTimeMin = val.target.value
        }
      }
      else {
        if (val.target.value.length != 0) {
          if(input == 'FactoryTimeInMin') {
            this.factoryTimeInMin = this.getFactoryTimeInMin
          }
          if(input == 'FactoryTimeOutMin') {
            this.factoryTimeOutMin = this.getFactoryTimeOutMin
          }
          if(input == 'InspectionStartTimeMin') {
            this.inspectionStartTimeMin = this.getInspectionStartTimeMin
          }
          if(input == 'InspectionEndTimeMin') {
            this.inspectionEndTimeMin = this.getInspectionEndTimeMin
          }
        }
      }
    },
    saveFieldValues() {

      const validationMsg = this.validateInspectionTime();
      if (validationMsg) {
        this.messageError = validationMsg;
        this.snackbarError = true;
        return;
      }

      //this.showLoading = true;

      let idate = null;
      if (this.inspectionDate.includes("-")) {
        idate = this.formatDate(this.inspectionDate);
      } else {
        idate = this.inspectionDate;
      }

      let data = {
        day: this.day,
        factoryTimeIn: this.padDigit(this.factoryTimeIn) + this.padDigit(this.factoryTimeInMin),
        factoryTimeOut: this.padDigit(this.factoryTimeOut) + this.padDigit(this.factoryTimeOutMin),
        inspectionDate: idate,
        inspectionEndTime: this.padDigit(this.inspectionEndTime) + this.padDigit(this.inspectionEndTimeMin),
        inspectionStartTime: this.padDigit(this.inspectionStartTime) + this.padDigit(this.inspectionStartTimeMin),
        isFactoryTimeInAM: this.timeInAm,
        isFactoryTimeOutAM: this.timeOutAm,
        isInspectionEndTimeAM: this.endTimeAm,
        isInspectionStartTimeAM: this.startTimeAm,
        lunchTime: this.lunchTime,
        resourceId: this.resourceId,
        totalTravelTime: this.totalTravelTime,
        workOrderId: this.workOrderId,
        id: this.id,
        waitingTime: this.waitingTime
      };

      this.utilityFn.getServerData("post", this.apiList.upsertInspectionTime, data, (res) => {
        console.log(res);
        //this.showLoading = false;
      },(errRes) =>{
        console.log(errRes);
        //this.showLoading = false;
      });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${this.padDigit(month)}/${this.padDigit(day)}/${year}`;
    },
    uiFormatDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${this.padDigit(month)}-${this.padDigit(day)}`;
    },

    // validate time in and time out, return a message if has an error or empty if valid
    validateInspectionTime() {
      const factoryTimeIn = Number(this.padDigit(this.factoryTimeIn) + this.padDigit(this.factoryTimeInMin));
      const factoryTimeOut = Number(this.padDigit(this.factoryTimeOut) + this.padDigit(this.factoryTimeOutMin));
      const inspectionStartTime = Number(this.padDigit(this.inspectionStartTime) + this.padDigit(this.inspectionStartTimeMin));
      const inspectionEndTime = Number(this.padDigit(this.inspectionEndTime) + this.padDigit(this.inspectionEndTimeMin));

      // factory time out should not be before the factory time in
      if (factoryTimeOut < factoryTimeIn) {
        return this.errorMessage1;
      }

      // inspection start time should not be before the factory time in
      if (inspectionStartTime != 0 && inspectionStartTime < factoryTimeIn) {
        return this.errorMessage2;
      }

      // inspection end time should not be equal or before the inspection start time
      if (inspectionEndTime != 0 && inspectionEndTime <= inspectionStartTime) {
        return this.errorMessage3;
      }

      if (this.inspectionDate === null){
        return "Inspection Date is blank.";
      }

      // return empty string if all input times are valid
      return '';
    },
    
    // add padding 0 to single digit
    padDigit(value) {
      const digit = 2;
      return (new Array(digit).fill(0).join('') + value).slice(-digit);
    },

    setTodayDate(){
      const today = new Date();
      const date = `${today.getFullYear()}-${this.padDigit(today.getMonth()+1)}-${today.getDate()}`;
      this.inspectionDate = date;
    },

    formatInputtedDate(val){

      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
        this.timer = setTimeout(() => {
              let splitted = [];
        if (val.target.value.split('/').length > 2)
        {
          splitted = val.target.value.split('/');
          if (splitted[0].length == 1 && (splitted[2].length == 4) || splitted[2].length == 2)
          { 
            if (splitted[2].length == 2)
            {
              var padDate = new Date().getFullYear().toString().slice(0,2);
              splitted[2] = padDate + splitted[2];
            }
            this.inspectionDate = `${splitted[2]}-${this.padDigit(splitted[0])}-${splitted[1]}`;
          }
        }
      }, 1000);
    },
    clearData(){
      this.inspectionDate = null;
      this.factoryTimeIn = '00';
      this.factoryTimeInMin= '00';
      this.factoryTimeOut= '00';
      this.factoryTimeOutMin= '00';
      this.inspectionStartTime= '00';
      this.inspectionStartTimeMin= '00';
      this.inspectionEndTime= '00';
      this.inspectionEndTimeMin= '00';
      this.totalTravelTime = '0';
      this.lunchTime = '0';
      this.timeInAm = true;
      this.timeInPm = false;
      this.timeOutAm = true;
      this.timeOutPm = false;
      this.endTimeAm = true;
      this.endTimePm = false;
      this.startTimeAm = true;
      this.startTimePm = false;
    },
    addNewInspectionTime(){
      this.$emit('UpdateInspectionTimeCount',this.inspectionTimeCount);
    },
    deleteInspectionTime(){
      this.utilityFn.getServerData("post", this.apiList.deleteInspectionTime +'/'+ this.id,  (res) => {
        console.log('success inspection time delete');
      });

      this.$emit('UpdateInspectionTimeCount',this.indexNumber,'delete');
    },
    loadValues(){
      this.showLoading = true;
      this.utilityFn.getServerData(
        "get",
        this.apiList.getInspectionTime + "/" + this.workOrderId + "/" + this.resourceId + "/" + this.day,
        {},
        (res) => {
          if (res && res.data && res.data.data) {
            if (res.data.data.inspectionTimeAdding){
                this.addNewInspectionTime();
            }
            if (res.data.data.id === null){
              this.deleteInspectionTime();              
            }
              this.id = res.data.data.id;
              (this.inspectionDate = this.uiFormatDate(res.data.data.inspectionDate)),
              (this.lunchTime = res.data.data.lunchTime == null ? '0' : res.data.data.lunchTime),
              (this.totalTravelTime = res.data.data.totalTravelTime == null ? '0' :res.data.data.totalTravelTime),
              (this.waitingTime = res.data.data.waitingTime),
              (this.timeInAm = (res.data.data.isFactoryTimeInAM == null || res.data.data.isFactoryTimeInAM) == true ? true : false),
              (this.timeInPm = (res.data.data.isFactoryTimeInAM == null || res.data.data.isFactoryTimeInAM) == true ? false : true),
              (this.factoryTimeIn = (res.data.data.factoryTimeIn && res.data.data.factoryTimeIn.length === 4) ? res.data.data.factoryTimeIn.slice(0,2) : '00'),
              (this.factoryTimeInMin =  (res.data.data.factoryTimeIn && res.data.data.factoryTimeIn.length === 4) ? res.data.data.factoryTimeIn.slice(2,4): '00'),
              // (this.getFactoryTimeInMin = this.factoryTimeInMin),
              (this.getFactoryTimeIn = this.getFormattedTime(res.data.data.factoryTimeIn, 'hours' )),
              (this.getFactoryTimeInMin = this.getFormattedTime(res.data.data.factoryTimeIn, 'minutes' )),

              (this.timeOutAm = (res.data.data.isFactoryTimeOutAM == null || res.data.data.isFactoryTimeOutAM) == true ? true : false),
              (this.timeOutPm = (res.data.data.isFactoryTimeOutAM == null || res.data.data.isFactoryTimeOutAM) == true ? false : true),
              (this.factoryTimeOut = (res.data.data.factoryTimeOut && res.data.data.factoryTimeOut.length === 4) ? res.data.data.factoryTimeOut.slice(0,2) : '00'),
              (this.factoryTimeOutMin = (res.data.data.factoryTimeOut && res.data.data.factoryTimeOut.length === 4) ? res.data.data.factoryTimeOut.slice(2,4): '00'),
              (this.getFactoryTimeOut = this.getFormattedTime(res.data.data.factoryTimeOut, 'hours' )),
              (this.getFactoryTimeOutMin = this.getFormattedTime(res.data.data.factoryTimeOut, 'minutes' )),

              (this.startTimeAm = (res.data.data.isInspectionStartTimeAM == null || res.data.data.isInspectionStartTimeAM) == true ? true : false),
              (this.startTimePm = (res.data.data.isInspectionStartTimeAM == null || res.data.data.isInspectionStartTimeAM) == true ? false : true),
              (this.inspectionStartTime = (res.data.data.inspectionStartTime && res.data.data.inspectionStartTime.length === 4) ? res.data.data.inspectionStartTime.slice(0,2) : '00'),
              (this.inspectionStartTimeMin = (res.data.data.inspectionStartTime && res.data.data.inspectionStartTime.length === 4) ? res.data.data.inspectionStartTime.slice(2,4): '00'),
              (this.getInspectionStartTime = this.getFormattedTime(res.data.data.inspectionStartTime, 'hours' )),
              (this.getInspectionStartTimeMin = this.getFormattedTime(res.data.data.inspectionStartTime, 'minutes' )),


              (this.endTimeAm = (res.data.data.isInspectionEndTimeAM == null || res.data.data.isInspectionEndTimeAM) == true ? true : false),
              (this.endTimePm = (res.data.data.isInspectionEndTimeAM == null || res.data.data.isInspectionEndTimeAM) == true ? false : true),
              (this.inspectionEndTime = (res.data.data.inspectionEndTime && res.data.data.inspectionEndTime.length === 4) ? res.data.data.inspectionEndTime.slice(0,2) : '00'),
              (this.inspectionEndTimeMin = (res.data.data.inspectionEndTime && res.data.data.inspectionEndTime.length === 4) ? res.data.data.inspectionEndTime.slice(2,4): '00'),

              (this.getInspectionEndTime = this.getFormattedTime(res.data.data.inspectionEndTime, 'hours' )),
              (this.getInspectionEndTimeMin = this.getFormattedTime(res.data.data.inspectionEndTime, 'minutes' )),


              (this.visibleDay = true)
            } else {
              this.visibleDay = false;
              this.deleteInspectionTime();
            }
            this.showLoading = false; 
        },
        {}
      );
    }
  },
  watch:{
    resourceId: function(newVal,oldVal){
      if (newVal != null){
        this.inspectionTimeCount = 1;
        this.day = "Day 1";   
        this.loadValues();
      }
    }
  },

  props: {
    workOrderId: String,
    resourceId: null,
    day: String,
    clientName: String,
    inspectionTimeCount: Number,
    indexNumber: Number
  },
};
</script>

<style lang="stylus" scoped>
.content
    padding: 0 24px 16px;
    flex: 1 1 auto;
    max-width: 100%;
.time-field-padding
    padding: 0 0 0 24px !important;
.btn-width
    max-width: 5%;
.pastInspectionsPreview {
  background: #fff;
}

.title {
  text-align: center;
  padding-top: 30px;
}

.select_type > span {
  padding: 5px 20px;
  display: inline-block;
}

.select_type > .active {
  background: #1890FF;
  color: #fff;
}

.top_title {
  color: red;
  font-size: 16px;
}

.sign_box {
  border: 1px #ccc solid;
  height: 300px;
}

.submit {
  color: #fff;
  display: block;
  margin: 40px auto;
  width: 60%;
}

.signature_box #btns {
  float: right;
  background: rgb(0, 118, 255);
  color: #fff;
}

.signature_box button {
  height: 40px;
  width: 100px;
}

#btns {
  float: right;
  background: rgb(0, 118, 255);
  color: #fff;
}

#reset {
  height: 40px;
  width: 100px;
}

.fail_code {
  padding: 20px;
  padding-bottom: 0px;
}

.fail_code p {
  margin-bottom: 5px;
}

.btnSize
  height: 30px;
  width: 1%;
  color: #000;

#preview-container
    height 100%

#preview-dialog
    display flex
    flex-direction column
    height 98vh

#preview-dialog .v-card__text
    flex-grow 1
    height 85%

>>> .v-dialog
    max-height 100%

>>> .v-input input
  max-height: 80px
  top: 5px;
  right: 2px;
  position: relative;

.hrClass
  text-align: center;
  width: 107px;
  border-radius: 10px;
  height: 74px;
  font-size: 70px;

.colonClass
  font-size: 100px;
  font-family: 'Material Design Icons';
  position: relative;
  bottom: 31px;

.divClass
  top: 25px;
  position: relative;
  right: 20px;

.hintClass
  color: red;
  font-style: italic;
  position: relative;
  padding-left: 30px;
  text-align: center;
  top: 15px;

.rowClass
  text-align: center;

.colClass
  text-align: left;

.colPadding
  padding-left: 30px;

.snackbar-loading
    margin-bottom 60px
</style>
