<template>
  <div>
    <div class="page-error">
      <img src="../../assets/images/top.png" />
      <img class="logo_img" src="../../assets/images/logo.png" />
      <v-row class="formDate">
        <v-col cols="12" sm="12" md="12">
          <p>{{ message }}</p>
        </v-col>
      </v-row>
      <v-btn v-if="noAccess === 'true'" class="submit" dark color="#B14C4C" @click="submit">Back to Login</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "error",
  computed: {
    message() {
      //debugger;
      if (!window.navigator.onLine) {
        return "You are offline We're sorry, this page hasn't been cached yet ";
      } else {
        return this.$route.params.error || "Uncaught error";
      }
    },
    noAccess() {
      return this.$route.params.noAccess;
    },
  },
  methods: {
    submit() {
      //remove token since user user don't have access
      localStorage.setItem("token", "");

      this.$router.push({ name: "login" });
    },
  },
  created() {
    let query = this.$route.query;
    if (Object.keys(query).length !== 0) {
      this.$router.replace({
        name: "error",
        params: query,
      });
    }
  },
};
</script>

<style lang="stylus" scoped>
.page-error
    background #fff
    position absolute
    top 0
    bottom 0
.logo_img
    display block
    margin 10% auto 5%
.formDate
    width 80%
    margin auto

.submit
    display block
    margin 7% auto 0;
    width 30%
</style>
