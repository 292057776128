<template>
  <div name="Expense" style="margin-top: 100px;">
    <v-data-table id="Expense" :sort-by="'dateCompleted'" v-bind:headers="expenseHeaders" :search="search_value" must-sort :items="expenseList" :items-per-page="-1" class="elevation-1" style="margin-top: 10px;">
      <template v-slot:body="{ items }">
        <tbody>
          <tr :id="'row_' + item.jobDisplayId" v-for="item in items" :key="item.name">
            <td style="text-align: center;">
              <span :style="item.isDue == 0 ? '' : 'color:red'">
                {{ item.jobDisplayId }}
              </span>
            </td>
            <td style="text-align: center;">
              <span :style="item.isDue == 0 ? '' : 'color:red'">
                {{ item.workOrderDisplayIds }}
              </span>
            </td>
            <td style="text-align: center;">
              <span :style="item.isDue == 0 ? '' : 'color:red'">
                {{ returnDateTime(item.dateCompleted) | dateFormat("MM-DD-YYYY") }}
              </span>
            </td>
            <td style="text-align: center;">
              <span :style="item.isDue == 0 ? '' : 'color:red'">
                {{ returnDateTime(item.dueDate) | dateFormat("MM-DD-YYYY") }}
              </span>
            </td>
            <td :id="'btn_' + item.jobDisplayId" style="text-align: center;">
              <span @click.stop="editExpense(item)">
                <v-icon>edit</v-icon>
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import apiDetails from "../../shared/request/apiDetails";
import * as moment from "moment";

export default {
  name: "PastInspection",
  data() {
    return {
      // Expense table data  moment(String(value)).format('MM/DD/YYYY hh:mm')
      expenseHeaders: [
        { text: "Job ID", align: "center", value: "jobDisplayId", sortable: true },
        { text: "Work Order ID", align: "center", value: "workOrderDisplayIds", sortable: true },
        { text: "Date Completed", align: "center", value: "dateCompleted", sortable: true },
        { text: "Due Date", align: "center", value: "dueDate", sortable: true },
        { text: "", align: "center", sortable: false, value: "menu" },
      ],
      expenseList: [],
    };
  },
  computed: {
    ...mapState("inspection", {
      store_token: (state) => state.token,
      search_value: (state) => state.searchValue,
    }),
  },
  activated() {
    //reload data when expense detail is save
    this.loadData();
  },
  mounted() {
    this.loadData();
  },
  methods: {
    returnDateTime(item) {
      return moment(String(item)).format("MM-DD-YYYY");
    },

    loadData() {

      this.utilityFn.getServerData(
        "get",
        this.apiList.expenseList,
        {},
        (res) => {
          this.expenseList = res.data.data;
          this.expenseList = this.expenseList.filter((x) => x.dateCompleted != null && x.air == null && x.hotel == null && x.transportation == null && x.meals == null && x.misc == null);
          this.expenseList = this.expenseList.map(x => ({...x, workOrderDisplayIds: x.workOrders.map(y => y.workOrderDisplayId)?.join(', ')}))
          this.expenseList.forEach((item) => {
            if (new Date(new Date(item.dueDate).toLocaleDateString()).getTime() < new Date(new Date().toLocaleDateString()).getTime()) {
              this.$set(item, "isDue", 1);
            } else {
              this.$set(item, "isDue", 0);
            }
          });
          if (localStorage.getItem("expenseDialogTime") != new Date(new Date().toLocaleDateString()).getTime()) {
            this.expenseDialog = true;
            localStorage.setItem("expenseDialogTime", new Date(new Date().toLocaleDateString()).getTime());
          }
          this.$emit("msgExpenseCount", this.expenseList.length);
        }
      );
    },
    editExpense(item) {
      const workOrderIds = this.expenseList.filter(x => x.jobId == item.jobId).map(x => x.workOrderDisplayIds).join(', ');
      this.$router.push({ name: "expenseDetail", params: { expense: item, workOrderIds } });
    },
  },
};
</script>

<style lang="stylus" scoped></style>
