<template>
  <div>
    <div class="workOrderInspectionPackageId">
      <div style="position: relative;">
        <v-row>
          <v-col cols="12" sm="2" style="text-align:left;">
            <v-switch :disabled="isSectionDisabled" id="isVaryingAQL" color="green" v-model="packageCheckObj.isVaryingAQL" inset label="Apply Varying AQL" @change="onChangeVaryingAQL($event)"></v-switch>
          </v-col>
          <v-col cols="12" sm="2" style="text-align:left;">
            <SpecialInstructions style="margin-top:5%" :workOrderId="this.$route.params.id"></SpecialInstructions>
          </v-col>  
          <v-col cols="12" sm="8" style="text-align:right;">
            <v-btn v-if="showButton" :disabled="isSectionDisabled" class="submit-btn" @click="submit_item" small depressed color="#1867c0">Submit</v-btn>
            <v-btn :disabled="isSectionDisabled" class="submit-btn" small depressed color="#1867c0" @click="go_upload_photo()">
              <v-icon>camera_alt</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-tabs v-model="tab" background-color="rgb(0, 81, 138)" dark>
          <v-tab id="packageCheck" href="#tab-1" :disabled="keepLogoLabelTabActive">
            Package Check
          </v-tab>
          <v-tab id="packingAssortment" href="#tab-2" :disabled="keepLogoLabelTabActive">
            Packing & Assortment
          </v-tab>
          <v-tab id="packagingMarking" href="#tab-3" :disabled="keepLogoLabelTabActive">
            Packaging & Marking
          </v-tab>
          <v-tab id="logosLabelsTags" href="#tab-4">
            Logos, Labels, Tags
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" touchless>
          <v-tab-item class="tab_item" :value="'tab-1'" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
            <pulse-validator v-if="errorsPackageCheckTab.length > 0" :errors="errorsPackageCheckTab"></pulse-validator>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select id="pc_result" hide-details v-model="packageCheckObj.pkgCheckOverallRslt" v-bind:items="select_result" item-value="value" item-text="text" :label="resultLabel" @change="changePkgCheckOverallRslt()"></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <div class="code-container">
                  <div v-if="(packageCheckObj.pkgCheckOverallRslt == 0 || packageCheckObj.pkgCheckOverallRslt == 2) && isGenericCodes" class="helper-container">
                    <GenericCodeHelper></GenericCodeHelper>
                  </div>
                  <v-form @submit.prevent v-model="isPackageCheckFormValid">
                    <v-select v-if="packageCheckObj.pkgCheckOverallRslt == 0 || packageCheckObj.pkgCheckOverallRslt == 2"
                            id="pc_resultcode"
                            v-model="packageCheckObj.packageCheckResultCode" 
                            :item-text="(item) => item.description ? `${item.code} - ${item.description}` : `${item.code}`"
                            item-value="code"
                            :items="getResultCodes(packageCheckObj.pkgCheckOverallRslt)"
                            :rules="[rules.required]"
                            :label="packageCheckObj.pkgCheckOverallRslt == 2 ? 'Pending Code' : 'Fail Code'">
                    </v-select>
                  </v-form>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field id="pc_remarks" v-model="packageCheckObj.remarks" label="Remarks"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select id="pc_sampling_level" hide-details v-model="packageCheckObj.inspectionLevel1" :items="inspectionLevel1Opetions" item-value="name" item-text="name" label="Sampling Level" @change="onChangeInspectionLevel()"> </v-select>
              </v-col>
              <v-col cols="12" sm="6" v-show="packageCheckObj.inspectionLevel1 == 'Double'">
                <v-select id="pc_double_sp" hide-details v-model="packageCheckObj.doubleSamplingPlan" :items="inspectionRoundOptions" item-text="name" item-value="name" label="Double Sampling Plan Iteration" append-icon="edit" @change="onChangeInspectionLevel()">
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select id="pc_inspection_level" hide-details v-model="packageCheckObj.ilPkgCheck" :items="filteredLookup" item-value="name" item-text="name" :label="inspectionLevelLabel" @change="onChangeInspectionLevel()"> </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select id="pc_sampling_methodology" v-model="packageCheckObj.samplingMethodology" :items="SamplingMethodology" label="Sampling Methodology" readonly item-text="text" item-value="value" @change="onChangeSamplingMethodology()"> </v-select>
              </v-col>
            </v-row>

            <v-row v-if="packageCheckObj.isVaryingAQL">
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  id="pc_carton_sz"
                  hide-details
                  :readonly="packageCheckObj.ilPkgCheck == 'Square Root'"
                  v-model="packageCheckObj.crtnSampleSize"
                  :label="cartonSampleSizeLabel"
                  @keyup="returnNumber(packageCheckObj, 'crtnSampleSize', { isDecimal: false })"
                  @change="
                    onSampleSizeChange();
                    returnNumber(packageCheckObj, 'crtnSampleSize', { isDecimal: false, showMsg: true });
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field id="pc_sz_critical" hide-details v-model="packageCheckObj.sampleSizeCritical" label="SZ -Critical"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field id="pc_sz_major" hide-details v-model="packageCheckObj.sampleSizeMajor" label="SZ -Major"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field id="pc_sz_minor" hide-details v-model="packageCheckObj.sampleSizeMinor" label="SZ -Minor"></v-text-field>
              </v-col>
            </v-row>

            <v-row v-else>
              <v-col cols="12" sm="6">
                <v-text-field
                  id="pc_carton_sz"
                  hide-details
                  :readonly="packageCheckObj.ilPkgCheck == 'Square Root'"
                  v-model="packageCheckObj.crtnSampleSize"
                  :label="cartonSampleSizeLabel"
                  @keyup="returnNumber(packageCheckObj, 'crtnSampleSize', { isDecimal: false })"
                  @change="
                    onSampleSizeChange();
                    returnNumber(packageCheckObj, 'crtnSampleSize', { isDecimal: false, showMsg: true });
                  "
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-show="packageCheckObj.isVaryingAQL" style="margin-bottom:1%;margin-top:1%">
              <v-col cols="12" sm="1" md="2">
                <v-select id="pc_aql_critical" hide-details v-model="packageCheckObj.aqlCriticalId" :items="aqlOptions" item-value="id" item-text="name" label="AQL - Critical" @change="updateAQL()"> </v-select>
              </v-col>
              <v-col cols="12" sm="1" md="2">
                <v-select id="pc_aql_major" hide-details v-model="packageCheckObj.aqlMajorId" :items="aqlOptions" item-value="id" item-text="name" label="AQL - Major" @change="updateAQL()"> </v-select>
              </v-col>
              <v-col cols="12" sm="1" md="2">
                <v-select id="pc_aql_minor" hide-details v-model="packageCheckObj.aqlMinorId" :items="aqlOptions" item-value="id" item-text="name" label="AQL - Minor" @change="updateAQL()"> </v-select>
              </v-col>
              <v-col cols="12" sm="1" md="2">
                <v-select id="pc_aql_others" hide-details v-model="packageCheckObj.aqlOtherId" :items="aqlOptions" item-value="id" item-text="name" label="AQL - Others"> </v-select>
              </v-col>
               <v-col cols="12" sm="3" md="2" style="text-align:right;" class="add_defect_button">
                  <v-btn id="pc_add_defect_btn" color="red" @click="openDefectFormDialog()" class="ma-2 white--text"> <v-icon left dark> mdi-plus-circle </v-icon> Defect </v-btn>
                </v-col>
            </v-row>

            <v-simple-table v-if="ShowAQLinSumarry()">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"></th>
                    <th class="text-center">Found</th>
                    <th class="text-center">Acc</th>
                    <th class="text-center">Rej</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Critical</td>
                    <td>
                      <v-text-field id="packageCheck_tab1_foundCritical" class="number_text" v-model="packageCheckObj.foundCritical" type="number" readonly></v-text-field>
                    </td>
                    <td>
                      <v-text-field id="packageCheck_tab1_AllowedCritical" class="number_text" v-model="packageCheckObj.allowedCritical" type="number"></v-text-field>
                    </td>
                    <td>
                      <v-text-field id="packageCheck_tab1_RejectedCritical" class="number_text" v-model="packageCheckObj.rejectedCritical" type="number"></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td>Major</td>
                    <td>
                      <v-text-field id="packageCheck_tab1_foundMajor" class="number_text" v-model="packageCheckObj.foundMajor" type="number" readonly></v-text-field>
                    </td>
                    <td>
                      <v-text-field id="packageCheck_tab1_AllowedMajor" class="number_text" v-model="packageCheckObj.allowedMajor" type="number"></v-text-field>
                    </td>
                    <td>
                      <v-text-field id="packageCheck_tab1_RejectedMajor" class="number_text" v-model="packageCheckObj.rejectedMajor" type="number"></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td>Minor</td>
                    <td>
                      <v-text-field id="packageCheck_tab1_foundMinor" class="number_text" v-model="packageCheckObj.foundMinor" type="number" readonly></v-text-field>
                    </td>
                    <td>
                      <v-text-field id="packageCheck_tab1_AllowedMinor" class="number_text" v-model="packageCheckObj.allowedMinor" type="number"></v-text-field>
                    </td>
                    <td>
                      <v-text-field id="packageCheck_tab1_RejectedMinor" class="number_text" v-model="packageCheckObj.rejectedMinor" type="number"></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-data-table
              :sort-by="['index', 'po', 'skuDisplay']"
              v-bind:headers="showHeadersPackageCheck"
              :items="workOrderProducts"
              :hide-default-footer="false"
              class="elevation-1"
              style="margin-top: 30px;"
              id="package-check-data-table"
              :loading="isLoadingItems"
              @update:options="resetPackageCheckIndexMarkers"
              :items-per-page="-1"
            >
              <template v-slot:progress>
                <v-progress-linear indeterminate></v-progress-linear>
              </template>

              <template v-slot:header.critical="{ header }" v-if="isVaryingSeparated()">
                <v-row>
                  <v-col cols="12">Critical</v-col>
                  <v-col cols="4">F</v-col>
                  <v-col cols="4">A</v-col>
                  <v-col cols="4">R</v-col>
                </v-row>
              </template>
              <template v-slot:header.major="{ header }" v-if="isVaryingSeparated()">
                <v-row>
                  <v-col cols="12">Major</v-col>
                  <v-col cols="4">F</v-col>
                  <v-col cols="4">A</v-col>
                  <v-col cols="4">R</v-col>
                </v-row>
              </template>
              <template v-slot:header.minor="{ header }" v-if="isVaryingSeparated()">
                <v-row>
                  <v-col cols="12">Minor</v-col>
                  <v-col cols="4">F</v-col>
                  <v-col cols="4">A</v-col>
                  <v-col cols="4">R</v-col>
                </v-row>
              </template>

              <!-- isVaryingCombined -->
              <template v-slot:header.found_defect="{ header }" v-if="isVaryingCombined()">
                <v-row>
                  <v-col cols="12">Found</v-col>
                  <v-col cols="4">Critical</v-col>
                  <v-col cols="4">Major</v-col>
                  <v-col cols="4">Minor</v-col>
                </v-row>
              </template>


              <template v-slot:body="{ items, pagination }">
                <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>
                      <span style="display: none;">{{ (item.indexMarkerPackageCheck = index + pagination.pageStart) }}</span>
                      {{ item.index }}
                    </td>
                    <td>{{ item.clientProductId }}</td>
                    <td>{{ item.skuDisplay }}</td>
                    <td>{{ item.po }}</td>
                    <td v-if="isVaryingSeparated()" class="w-lg">
                      <v-row>
                        <v-col cols="4">
                          <v-text-field outlined dense :id="'PackageCheckAqlCriticalFound_' + item.id" class="number_text" v-model="item.workOrderProductResults.pkgCheckAqlCriticalFound" type="number" readonly></v-text-field>
                       </v-col>
                        <v-col cols="4">
                          <v-text-field :id="'PackageCheckAqlCriticalAccept_' + item.id"  v-model="item.workOrderProductResults.pkgCheckAqlCriticalAccept" :rules="[requiredAcceptRejectValue]" outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field :id="'PackageCheckAqlCriticalReject_' + item.id"  v-model="item.workOrderProductResults.pkgCheckAqlCriticalReject" :rules="[requiredAcceptRejectValue]" @change="calculateRowResult(item)" outlined dense></v-text-field>
                        </v-col>
                      </v-row>
                    </td>
                    <td v-if="isVaryingSeparated()" class="w-lg">
                      <v-row>
                        <v-col cols="4">
                          <v-text-field outlined dense :id="'PackageCheckAqlMajorFound_' + item.id" class="number_text" v-model="item.workOrderProductResults.pkgCheckAqlMajorFound" type="number" readonly></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field  v-model="item.workOrderProductResults.pkgCheckAqlMajorAccept" :rules="[requiredAcceptRejectValue]" ref="pkgCheckAqlMajorAccept" outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field  v-model="item.workOrderProductResults.pkgCheckAqlMajorReject" :rules="[requiredAcceptRejectValue]" ref="pkgCheckAqlMajorReject" @change="calculateRowResult(item)" outlined dense></v-text-field>
                        </v-col>
                      </v-row>
                    </td>
                     <td v-if="isVaryingSeparated()" class="w-lg">
                      <v-row>
                        <v-col cols="4">
                          <v-text-field outlined dense :id="'PackageCheckAqlMinorFound_' + item.id" class="number_text" v-model="item.workOrderProductResults.pkgCheckAqlMinorFound" type="number" readonly></v-text-field>                
                        </v-col>
                        <v-col cols="4">
                          <v-text-field  v-model="item.workOrderProductResults.pkgCheckAqlMinorAccept" :rules="[requiredAcceptRejectValue]" outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field  v-model="item.workOrderProductResults.pkgCheckAqlMinorReject" :rules="[requiredAcceptRejectValue]" @change="calculateRowResult(item)" outlined dense></v-text-field>
                        </v-col>
                      </v-row>
                    </td>
                    <td v-if="isVaryingCombined()" class="w-lg">
                      <v-row>
                        <v-col cols="4">
                          <v-text-field outlined dense :id="'PackageCheckAqlCriticalFound_' + item.id"  v-model="item.workOrderProductResults.pkgCheckAqlCriticalFound" type="number" readonly></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field outlined dense :id="'PackageCheckAqlMajorFound_' + item.id" class="number_text" v-model="item.workOrderProductResults.pkgCheckAqlMajorFound" type="number" readonly></v-text-field>
                        </v-col>
                        <v-col cols="4">
                           <v-text-field outlined dense :id="'PackageCheckAqlMinorFound_' + item.id" class="number_text" v-model="item.workOrderProductResults.pkgCheckAqlMinorFound" type="number" readonly></v-text-field>
                        </v-col>
                      </v-row>
                    </td>
                    <td v-if="!isVaryingCombined()">
                      <v-select hide-details :items="select_result" v-model="item.workOrderProductResults.pkgCheckResult" item-value="value" item-text="text" :rules="[rules.required]" outlined dense @change="calculatePackageCheckResult('tab-1')">
                      </v-select>
                    </td>
                    <td>
                      <v-text-field hide-details v-model="item.workOrderProductResults.pkgCheckFindings" outlined dense></v-text-field>
                    </td>
                    <td>
                      <div class="d-flex align-center">
                        <!-- <v-btn v-if="packageCheckObj.isVaryingAQL" color="red" @click.stop="openDefectFormDialog(item)" class="ma-2 white--text"> <v-icon left dark> mdi-plus-circle </v-icon> Defect </v-btn> -->
                        <template v-if="item.indexMarkerPackageCheck >= 1">
                          <v-btn icon title="Copy From Above" @click="copyAboveRowPackageCheck(item.indexMarkerPackageCheck)">
                            <v-icon>mdi-content-copy</v-icon>
                          </v-btn>
                        </template>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>

            </v-data-table>
          </v-tab-item>

          <v-tab-item class="tab_item" :value="'tab-2'" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
            <pulse-validator v-if="errorsPackingAssortmentTab.length > 0" :errors="errorsPackingAssortmentTab"></pulse-validator>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-switch id="packingAssortmentShowHide" color="green" v-model="packingAssortmentShowHide" inset label="Show/Hide Section" style="float: right;"></v-switch>
              </v-col>
            </v-row>
            <div v-for="(item, index) in packingAssortmentSubSections" :key="index" style="margin-bottom: 15px;">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select hide-details v-model="resultForPackingAndAssortment" v-bind:items="select_result" item-value="value" item-text="text" :label="resultLabel" @change="changeFormToDirty()"></v-select>
                </v-col>
                 <v-col cols="12" sm="6">
                  <div class="code-container">
                    <div v-if="(resultForPackingAndAssortment == 0 || resultForPackingAndAssortment == 2) && isGenericCodes" class="helper-container">
                      <GenericCodeHelper></GenericCodeHelper>
                    </div>
                    <v-form v-model="isPackingAndAssortmentFormValid">
                      <v-select v-if="resultForPackingAndAssortment == 0 || resultForPackingAndAssortment == 2"
                          v-model="packingAssortmentResultCode" 
                          :item-text="(item) => item.description ? `${item.code} - ${item.description}` : `${item.code}`"
                          item-value="code"
                          :items="getResultCodes(resultForPackingAndAssortment)"
                          :rules="[rules.required]"
                          :label="resultForPackingAndAssortment == 2 ? 'Pending Code' : 'Fail Code'">
                      </v-select>
                    </v-form>
                  </div>
                <!-- <v-select v-if="resultForPackingAndAssortment == 0" v-model="packingAssortmentResultCode" item-value="description"
                  :item-text="(item) => item.details ? `${item.description} - ${item.details}` : `${item.description}`" :items="failResultOptions" :rules="[rules.required]" label="Fail Code"> </v-select>

                <v-select v-if="resultForPackingAndAssortment == 2" v-model="packingAssortmentResultCode" item-value="description"
                  :item-text="(item) => item.details ? `${item.description} - ${item.details}` : `${item.description}`"  :items="pendingResultOptions" :rules="[rules.required]" label="Pending Code"> </v-select> -->
              </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea v-model="findingsForPackingAndAssortment" outlined :counter="200" :rules="[rules.minLength]" rows="2" label="Remarks"></v-textarea>
                </v-col>
              </v-row>
            </div>

            <v-data-table
              :sort-by="['index', 'po', 'skuDisplay']"
              v-bind:headers="packingAssortmentHeader"
              :items="workOrderProducts"
              :hide-default-footer="false"
              class="elevation-1"
              style="margin-top: 30px;"
              item-key="id"
              id="packing-assortment-data-table"
              :loading="isLoadingItems"
              @update:options="resetPackingAssortmentIndexMarkers"
              :items-per-page="-1"
            >
              <template v-slot:progress>
                <v-progress-linear indeterminate></v-progress-linear>
              </template>

              <template v-slot:body="{ items, pagination }">
                <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>
                      <span style="display: none;">{{ (item.indexMarkerPackingAssortment = index + pagination.pageStart) }}</span>
                      {{ item.index }}
                    </td>
                    <td>{{ item.clientProductId }}</td>
                    <td>{{ item.skuDisplay }}</td>
                    <td>{{ item.po }}</td>
                    <td>
                      <v-select
                        hide-details
                        :items="select_result"
                        v-model="item.workOrderProductResults.packingAssortmentResult"
                        item-value="value"
                        item-text="text"
                        :rules="[rules.required]"
                        outlined
                        dense
                        @change="calculatePackageCheckResult('tab-2')"
                      >
                      </v-select>
                    </td>
                    <td>
                      <v-text-field hide-details v-model="item.workOrderProductResults.packingAssortmentFindings" outlined dense></v-text-field>
                    </td>
                    <td>
                      <template v-if="item.indexMarkerPackingAssortment >= 1">
                        <v-btn icon title="Copy From Above" @click="copyAboveRowPackingAssortment(item.indexMarkerPackingAssortment)">
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-tab-item>

          <v-tab-item class="tab_item" :value="'tab-3'" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
            <pulse-validator v-if="errorsPackingAssortmentTab.length > 0" :errors="errorsPackingAssortmentTab"></pulse-validator>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-switch id="packagingMarkingsShowHide" color="green" v-model="packingAssortmentShowHide" inset label="Show/Hide Section" style="float: right;"></v-switch>
              </v-col>
            </v-row>

            <div v-for="(item, index) in packagingMarkingSubSections" :key="index" style="margin-bottom: 15px;">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select hide-details v-model="resultForPackingAndMarking" v-bind:items="select_result" item-value="value" item-text="text" :label="resultLabel" @change="changeFormToDirty()"></v-select>
                </v-col>
                 <v-col cols="12" sm="6">
                  <div class="code-container">
                    <div v-if="(resultForPackingAndMarking == 0 || resultForPackingAndMarking == 2) && isGenericCodes" class="helper-container">
                      <GenericCodeHelper></GenericCodeHelper>
                    </div>
                    <v-form v-model="isPackagingAndMarkingFormValid">
                      <v-select v-if="resultForPackingAndMarking == 0 || resultForPackingAndMarking == 2"
                          v-model="packagingMarkingResultCode" 
                          :item-text="(item) => item.description ? `${item.code} - ${item.description}` : `${item.code}`"
                          item-value="code"
                          :items="getResultCodes(resultForPackingAndMarking)"
                          :rules="[rules.required]"
                          :label="resultForPackingAndMarking == 2 ? 'Pending Code' : 'Fail Code'">
                      </v-select>
                    </v-form>
                  </div>
              </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea v-model="findingsForPackingAndMarking" :counter="200" outlined :rules="[rules.minLength]" rows="2" label="Remarks"></v-textarea>
                </v-col>
              </v-row>
            </div>

            <v-data-table
              :sort-by="['index', 'po', 'skuDisplay']"
              v-bind:headers="packagingMarkingHeader"
              :items="workOrderProducts"
              :hide-default-footer="false"
              class="elevation-1"
              style="margin-top: 30px;"
              id="packaging-marking-data-table"
              :loading="isLoadingItems"
              @update:options="resetPackagingMarkingIndexMarkers"
              :items-per-page="-1"
            >
              <template v-slot:progress>
                <v-progress-linear indeterminate></v-progress-linear>
              </template>

              <template v-slot:body="{ items, pagination }">
                <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>
                      <span style="display: none;">{{ (item.indexMarkerPackagingMarking = index + pagination.pageStart) }}</span>
                      {{ item.index }}
                    </td>
                    <td>{{ item.clientProductId }}</td>
                    <td>{{ item.skuDisplay }}</td>
                    <td>{{ item.po }}</td>
                    <td>
                      <v-select
                        hide-details
                        :items="select_result"
                        v-model="item.workOrderProductResults.packagingMarkingResult"
                        item-value="value"
                        item-text="text"
                        outlined
                        dense
                        ref="packagingMarkingResult"
                        :rules="[rules.required]"
                        @change="calculatePackageCheckResult('tab-3')"
                      >
                      </v-select>
                    </td>
                    <td>
                      <v-text-field hide-details v-model="item.workOrderProductResults.packagingMarkingFindings" outlined dense></v-text-field>
                    </td>
                    <td>
                      <template v-if="item.indexMarkerPackagingMarking >= 1">
                        <v-btn icon title="Copy From Above" @click="copyAboveRowPackagingMarking(item.indexMarkerPackagingMarking)">
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-tab-item>

          <v-tab-item class="tab_item" :value="'tab-4'" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-switch id="logoLabelShowHide" color="green" v-model="logoLabelShowHide" inset label="Show/Hide Section" @change="onSectionToggle($event, logoLabelSectionId)" style="float: right;"></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select hide-details v-bind:items="select_result" v-model="logoLabelResult" label="Result" @change="changeFormToDirty()"></v-select>
              </v-col>
            </v-row>
            <v-form ref="logoLabelForm" v-model="logoLabelValid">
              <v-row>
                <v-col cols="12">
                  <div v-for="(item, index) in logoLabelSubSections" :key="index" style="margin-bottom: 15px;">
                    <h3>{{ item.subSection.name }}</h3>
                    <v-row>
                      <v-col cols="6" sm="6">
                        <v-select v-model="item.result" :items="select_result" :rules="[(v) => !logoLabelShowHide || !!v || 'Please choose a result']" item-value="value" item-text="text" label="Result" @change="resultChange(item)"> </v-select>
                      </v-col>
                      <v-col cols="6" sm="6">
                        <div class="code-container">
                          <div v-if="(item.result == 0 || item.result == 2) && isGenericCodes" class="helper-container">
                            <GenericCodeHelper></GenericCodeHelper>
                          </div>
                          <v-form v-model="isLogosLabelsTagsFormValid">
                            <v-select v-if="item.result == 0 || item.result == 2"
                                    v-model="item.reason" 
                                    :item-text="(item) => item.description ? `${item.code} - ${item.description}` : `${item.code}`"
                                    item-value="code"
                                    :items="getResultCodes(item.result)"
                                    :rules="[rules.required]"
                                    :label="item.result == 2 ? 'Pending Code' : 'Fail Code'">
                            </v-select>
                          </v-form>
                      </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-text-field label="Remarks" v-model="item.remarks" :counter="200" :rules="[(v) => !v || v.length <= 200 || 'Maximum 200 characters only']"></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>

    <v-snackbar v-model="snackbar" :timeout="timeout" :color="color"> {{ message }} <v-btn text @click="snackbar = false"> Close</v-btn> </v-snackbar>

    <v-snackbar v-model="delImgSnackbar.isShow">
      {{ delImgSnackbar.text }}
      <v-progress-circular indeterminate color="#ccc"></v-progress-circular>
    </v-snackbar>

    <div v-if="showFloatingButton">
      <FloatingButton></FloatingButton>
    </div>

    <v-dialog v-model="showDefectFormDialog" width="100%">
      <v-card>
        <v-card-title>Add Defect</v-card-title>

        <section-defect-form-dialog v-if="showDefectFormDialog" :sectionData="dclSectionData" @changeValue="workorderProductDefectSectionValue($event)"></section-defect-form-dialog>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeSave">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
let state = {
  appHeaderState: {
    show: true,
    title: "Package Check",
    showMenu: true,
    showBack: true,
    showLogo: false,
    showTitle: true,
    showSearch: false,
    showCalendar: true,
  },
};

function setState(store) {
  store.dispatch("navigation/setAppHeader", state.appHeaderState);
}

import { mapActions, mapState } from "vuex";
import apiDetails from "@/shared/request/apiDetails";
import FloatingButton from "@/components/common/FloatingButton";
import SectionDefectFormDialog from "@/components/workorder/SectionDefectFormDialog";
import * as _ from "lodash";
import PulseValidator from "@/components/common/PulseValidator";
import GenericCodeHelper from "../../../views/common/GenericCodeHelper.vue"
import { defineAsyncComponent } from 'vue'

export default {
  name: "workOrderInspectionPackageId",
  components: {
    FloatingButton,
    "section-defect-form-dialog": SectionDefectFormDialog,
    PulseValidator,
    GenericCodeHelper,
    "SpecialInstructions": defineAsyncComponent(() => import('../../../components/forms/SpecialInstructions')) 
  },
  data() {
    return {
      workOrderPendingCode: [],
      workOrderFailCode: [],
      loadingDf: false,
      tab: null,
      SamplingMethodology: [
        { text: "Separated Sample Size", value: "Separated Sample Size" },
        { text: "Combined Sample Size", value: "Combined Sample Size" },
      ],

      // 提示框
      delImgSnackbar: {
        isShow: false,
        text: "",
      },

      // snackbar
      showFloatingButton: false,
      snackbar: false,
      timeout: 3000,
      color: "red darken-2",
      message: "",

      // package check overall result selections
      resultOptions: [
        { text: "Conform", value: "1" },
        { text: "Non-Conformed", value: "0" },
        { text: "Pending", value: "2" },
        { text: "N/A", value: "3" },
      ],
      dialog: false,
      showDefectFormDialog: false,
      dialogNewTab: false,
      packageHeader: [
        { text: "#", align: "left", sortable: true, value: "index" },
        { text: "Client Product ID", align: "left", sortable: true, value: "clientProductId" },
        { text: "SKU#", align: "left", sortable: true, value: "skuDisplay" },
        { text: "PO No.", align: "center", sortable: true, value: "po" },
        { text: "Result", align: "center", sortable: false, value: "workOrderProductResults.pkgCheckResult" },
        { text: "Finding(s)", align: "center", sortable: false, value: "workOrderProductResults.pkgCheckFindings" },
        { text: "", align: "center", sortable: false, width: 110, value: "menu" },
      ],
      packageHeaderAQL: [
        { text: "#", align: "left", sortable: true, value: "index" },
        { text: "Client Product ID", align: "left", sortable: true, value: "clientProductId" },
        { text: "SKU#", align: "left", sortable: true, value: "skuDisplay" },
        { text: "PO No.", align: "center", sortable: true, value: "po" },
        { text: "Critical", align: "center", sortable: false, value: "critical", width: 270 },
        { text: "Major", align: "center", sortable: false, value: "major", width: 270 },
        { text: "Minor", align: "center", sortable: false, value: "minor", width: 270 },
        { text: "Result", align: "center", sortable: false, value: "workOrderProductResults.pkgCheckResult", width: 200 },
        { text: "Finding(s)", align: "center", sortable: false, value: "workOrderProductResults.pkgCheckFindings", width: 300 },
        { text: "", align: "center", sortable: false, width: 110, value: "menu" },
      ],
      packageHeaderAQLCombined: [
        { text: "#", align: "left", sortable: true, value: "index" },
        { text: "Client Product ID", align: "left", sortable: true, value: "clientProductId" },
        { text: "SKU#", align: "left", sortable: true, value: "skuDisplay" },
        { text: "PO No.", align: "center", sortable: true, value: "po" },
        { text: "Found", align: "center", sortable: false, width: 220, value: "found_defect"},
        //{ text: "Result", align: "center", sortable: false, value: "workOrderProductResults.pkgCheckResult", width: 200 },
        { text: "Finding(s)", align: "center", sortable: false, value: "workOrderProductResults.pkgCheckFindings", width: 300 },
        { text: "", align: "center", sortable: false, width: 110, value: "menu" },
      ],
      editedItem: {
        woProductId: "",
        result: 0,
        pkgCheckAqlCriticalAccept: null,
        pkgCheckAqlCriticalReject: null,
        pkgCheckAqlMajorAccept: null,
        pkgCheckAqlMajorReject: null,
        pkgCheckAqlMinorAccept: null,
        pkgCheckAqlMinorReject: null,
        finding: 0,
        image: {},
        fileInput: [],
        uploadPhoto: [],
        serverPhoto: [],
        uploadLoading: false,
      },
      packageCheckObj: {
        workOrderId: null,
        crtnSampleSize: null,
        ilPkgCheck: null,
        pkgCheckOverallRslt: null,
        packageCheckResultCode:null,
        inspectionLevel1: null,
        doubleSamplingPlan: null,
        samplingMethodology: null,
        isManulaInputSZ: false,
        aqlCriticalId: null,
        aqlMinorId: null,
        aqlMajorId: null,
        aqlOtherId: null,
        sampleSizeCritical: null,
        sampleSizeMajor: null,
        sampleSizeMinor: null,
        isVaryingAQL: false,
      },
      workOrderId: "",
      workOrder: {
        resources: [],
        workOrderInspectionSections: [],
      },
      workOrderProducts: [],
      isDateEntryValid: true,
      sectionId: 3,
      // showButton: false,

      filteredLookup: [
        {
          id: "",
          name: "",
          inspectionClassFieldNameId: "",
          inspectionClassFieldName: {
            id: "",
            name: "",
          },
        },
      ],
      inspectionLevel2LookupOrder: [
        { name: "I", order: 10 },
        { name: "II", order: 20 },
        { name: "III", order: 30 },
        { name: "S1", order: 40 },
        { name: "S2", order: 50 },
        { name: "S3", order: 60 },
        { name: "S4", order: 70 },
        { name: "Fixed", order: 80 },
        { name: "Square Root", order: 90 },
      ],
      inspectionLevel1Opetions: [],
      inspectionRoundOptions: [],
      sectionData: {
        PackageCheck: {
          id: 3,
          name: "Package Check",
          isAuthorized: false,
          isSectionSubmitted: false,
        },
      },
      productQuantityVerificationList: [],
      isVaryingAQL: false,
      aqlOptions: [],

      select_result: [
        { text: "Pass", value: "1", resultValue: "Conformed" },
        { text: "Fail", value: "0", resultValue: "Non-Conformed" },
        { text: "Pending", value: "2", resultValue: "Pending" },
        { text: "N/A", value: "3", resultValue: "N/A" },
      ],

      // Packing & Assortment
      packingAssortmentShowHide: true,
      packingAssortmentSectionId: 22,
      packingAssortmentSubSections: [{ sectionId: 22, subSectionid: 1, subSection: { id: 1, name: "Default" } }],
      packingAssortmentHeader: [
        { text: "#", align: "left", sortable: true, value: "index" },
        { text: "Client Product ID", align: "left", sortable: true, value: "clientProductId" },
        { text: "SKU#", align: "left", sortable: true, value: "skuDisplay" },
        { text: "PO No.", align: "center", sortable: true, value: "po" },
        { text: "Result", align: "center", sortable: false, value: "workOrderProductResults.packingAssortmentResult" },
        { text: "Finding(s)", align: "center", sortable: false, value: "workOrderProductResults.packingAssortmentFindings" },
        { text: "", align: "center", sortable: false, value: "menu" },
      ],

      // Packaging & Marking
      packagingMarkingsShowHide: true,
      packagingAndMarkingSectionId: 23,
      packagingMarkingSubSections: [{ sectionId: 23, subSectionid: 1, subSection: { id: 1, name: "Default" } }],
      packagingMarkingHeader: [
        { text: "#", align: "left", sortable: true, value: "index" },
        { text: "Client Product ID", align: "left", sortable: true, value: "clientProductId" },
        { text: "SKU#", align: "left", sortable: true, value: "skuDisplay" },
        { text: "PO No.", align: "center", sortable: true, value: "po" },
        { text: "Result", align: "center", sortable: false, value: "workOrderProductResults.packagingMarkingResult" },
        { text: "Finding(s)", align: "center", sortable: false, value: "workOrderProductResults.packagingMarkingFindings" },
        { text: "", align: "center", sortable: false, value: "menu" },
      ],

      // Logo, Labels, Tag
      logoLabelShowHide: true,
      logoLabelSectionId: 24,
      logoLabelResult: null,
      logoLabelSubSections: [
        { sectionId: 24, reason: null, result: null, remarks: null, subSectionid: 5, subSection: { id: 5, name: "Labelling" } },
        { sectionId: 24, reason: null, result: null, remarks: null, subSectionid: 6, subSection: { id: 6, name: "Printed Material" } },
        { sectionId: 24, reason: null, result: null, remarks: null, subSectionid: 7, subSection: { id: 7, name: "Markings" } },
      ],
      logoLabelValid: false,
      failResultOptions: [],
      pendingResultOptions:[],
      isShowHideToggle: false,
      dclSectionData: [],
      maxcharacter: 200,
      findingsForPackingAndAssortment: null,
      findingsForPackingAndMarking: null,
      resultForPackingAndAssortment: null,
      resultForPackingAndMarking: null,
      isBulkUpsertAcRe: true,

      rules: {
        required: (v) => !!v || "Required",
        minLength: (a) => (a || "").length < 200 || "Findings must be 200 characters or less",
      },
      isLoadingItems: true,

      // copy of workOrderProducts and will be used for checking affected rows
      workOrderProductsClone: [],
      packingAssortmentResultCode:null,
      packagingMarkingResultCode:null,
      logoLabelResultCode:null,

      workOrderRatingOptions: [],
      defaultFailCodeOptions: ['UL0', 'UL1', 'UL2', 'UL3', 'UL4', 'UL5', 'UL6', 'UL7', 'UL8', 'UL9', 'UL10'],
      isFormDirty: false,
      isFormReadyCheck: false,
      isGenericCodes: false,
      isPackageCheckFormValid: true,
      isPackingAndAssortmentFormValid: true,
      isPackagingAndMarkingFormValid: true,
      isLogosLabelsTagsFormValid: true,
      totalCartonQty: null
    };
  },
  async activated() {
    if (window.navigator.onLine !== true) {
      let response = await caches.match(this.apiList.workOrderDetailsPackage + "/" + this.$route.params.id);
      if (!response) {
        this.$router.replace({
          path: "/error",
          name: "error",
        });
      }
    }
  },
  async mounted() {
    if (window.navigator.onLine !== true) {
      let response = await caches.match(this.apiList.workOrderDetailsPackage + "/" + this.$route.params.id);
      if (!response) {
        this.$router.replace({
          path: "/error",
          name: "error",
        });
      }
    }

    await this.loadLookup();
    setState(this.$store);

    this.samplingPlan = JSON.parse(localStorage.getItem("samplingPlanList"));
    this.showFloatingButton = this.samplingPlan == undefined ? false : this.samplingPlan.showFabButton == false ? false : true;
    this.sectionData = this._sectionData.PackageCheck ? this._sectionData : this.sectionData;

    this.utilityFn.getServerData("get", this.apiList.getAllWorkOrderPendingFailCode, {}, (res) => {
      this.workOrderPendingCode = res.data.data.filter((x) => x.type == "Pending");
      this.workOrderFailCode = res.data.data.filter((x) => x.type == "Fail");
    });
    this.utilityFn.getServerData("get", this.apiList.getAllWorkOrderPendingFailCode, {}, (res) => {
      this.pendingResultOptions = res.data.data.filter((x) => x.type == "Pending");
      this.failResultOptions = res.data.data.filter((x) => x.type == "Fail");

    });
    await this.loadData();
    await this.loadWorkOrderRatingOptions();
  },
  methods: {
    changePkgCheckOverallRslt(){
      this.packageCheckObj.packageCheckResultCode = null
    },
    workorderProductDefectSectionValue(workorderProductDefectSection) {
      this.workOrderProducts.forEach((element) => {
        const index = this.workOrderProducts.findIndex((x) => x.id === element.id);

        let foundCritical = null;
        let foundMajor = null;
        let foundMinor = null;

        //Critical
        this.workOrderProducts[index].workOrderProductResults.pkgCheckAqlCriticalFound = workorderProductDefectSection.reduce(function(tot, arr) {
          if (arr.workOrderProductId == element.id && arr.defectClassificationId == 1) {
            foundCritical += Number(arr.qty);
          }
          return foundCritical;
        }, null);

        //Major
        this.workOrderProducts[index].workOrderProductResults.pkgCheckAqlMajorFound = workorderProductDefectSection.reduce(function(tot, arr) {
          if (arr.workOrderProductId == element.id && arr.defectClassificationId == 2) {
            foundMajor += Number(arr.qty);
          }
          return foundMajor;
        }, null);

        //Minor
        this.workOrderProducts[index].workOrderProductResults.pkgCheckAqlMinorFound = workorderProductDefectSection.reduce(function(tot, arr) {
          if (arr.workOrderProductId == element.id && arr.defectClassificationId == 3) {
            foundMinor += Number(arr.qty);
          }
          return foundMinor;
        }, null);
         this.calculateRowResult(element);
      });  
    },
    onChangeVaryingAQL(event) {
      if (event) {
        this.isBulkUpsertAcRe = true;
       
        this.bulkUpsertPackageProductAQL();
      }

      this.updateAQL();
    },

    openDefectFormDialog() {
      this.dclSectionData.clientId = this.workOrder.clientId;
      this.dclSectionData.dclType = 1;
      this.dclSectionData.sectionId = this.sectionData.PackageCheck.id;
      this.dclSectionData.sectionName = this.sectionData.PackageCheck.name;
      this.dclSectionData.workorderProductId = null
      this.showDefectFormDialog = true;
    },

    getAQL(id) {
      if (!id || id == "") {
        return null;
      }
      let aqlName = this.aqlOptions.find((x) => x.id === id)?.name;
      return aqlName;
    },

    getIdAQL(name) {
      if (!name || name == "") {
        return null;
      }
      let aqlId = this.aqlOptions.find((x) => x.name === name)?.id;
      return aqlId;
    },

    async loadData() {
      this.loadingDf = true;
      this.$store.dispatch("navigation/setLoadingPage",{show: this.loadingDf });
      this.isLoadingItems = true;
      const result = await this.utilityFn.getServerDataAsync("get", this.apiList.workOrderDetailsPackage + "/" + this.$route.params.id, {});
      this.workOrder = result.data;
      this.workOrderProducts = this.workOrder.products.map((p, i) => {
        p.index = i + 1;
        p.workOrderProductResults = p.workOrderProductResults || {};
        return p;
      });

            /*this.totalCartonQty = this._samplingPlanLists.totalSampleSizePerSKU.reduce(function (tot, arr) {
        return Number(tot) + Number(arr.totalCartonQuantity);
      }, 0);*/
      this.totalCartonQty = this.workOrder.products.reduce(function (tot, arr) {
        return Number(tot) + Number(arr.cartonQty);
      }, 0);      

      this.packageCheckObj = this.workOrder.workOrderPackageCheck || {
        workOrderId: this.workOrder.workOrderId,
        crtnSampleSize: null,
        ilPkgCheck: null,
        pkgCheckOverallRslt: null,
        packageCheckResultCode:null,
        inspectionLevel1: null,
        doubleSamplingPlan: this.workOrder.samplingPlan,
        samplingMethodology: null,
        isManulaInputSZ: false,
        aqlCriticalId: null,
        aqlMinorId: null,
        aqlMajorId:null,
        aqlOtherId: null,
        sampleSizeCritical: null,
        sampleSizeMajor: null,
        sampleSizeMinor: null,
        isVaryingAQL: this.workOrder.isVaryingAQL,
      };
      this.packageCheckObj.inspectionLevel1 = this.packageCheckObj.inspectionLevel1 == null ? "Single" : this.packageCheckObj.inspectionLevel1;
      this.packageCheckObj.doubleSamplingPlan = this.packageCheckObj.doubleSamplingPlan == null ? this.workOrder.samplingPlan : this.packageCheckObj.doubleSamplingPlan;
      this.packageCheckObj.remarks = this.packageCheckObj.remarks == null ? null : this.packageCheckObj.remarks;

      //this.packageCheckObj.samplingMethodology =
      //this.packageCheckObj.samplingMethodology == null ? (this.workOrder.samplingMethodology === "Combined Sample Size" ? "Combined Sample Size" : "Separated Sample Size") : this.packageCheckObj.samplingMethodology;
      this.packageCheckObj.samplingMethodology =this._samplingPlanLists.samplingMethodology ?  this._samplingPlanLists.samplingMethodology : this.workOrder.samplingMethodology ;
     
      this.packageCheckObj.ilPkgCheck = this.packageCheckObj.ilPkgCheck == null ? null : this.packageCheckObj.ilPkgCheck;
      this.packageCheckObj.pkgCheckOverallRslt = this.packageCheckObj.pkgCheckOverallRslt == null ? null : this.packageCheckObj.pkgCheckOverallRslt.toString();
      this.packageCheckObj.packageCheckResultCode = this.packageCheckObj.packageCheckResultCode == null ? null : this.packageCheckObj.packageCheckResultCode.toString();
      this.resultForPackingAndAssortment = this.workOrder.packingAssortmentResult == null ? null : this.workOrder.packingAssortmentResult.toString();
      this.packingAssortmentResultCode = this.workOrder.packingAssortmentResultCode == null ? null : this.workOrder.packingAssortmentResultCode.toString();

      this.resultForPackingAndMarking = this.workOrder.packagingMarkingResult == null ? null : this.workOrder.packagingMarkingResult.toString();
      this.packagingMarkingResultCode = this. workOrder.packagingMarkingResultCode == null ? null : this.workOrder.packagingMarkingResultCode.toString()


      this.findingsForPackingAndAssortment = this.workOrder.packingAssortmentFindings == null ? null : this.workOrder.packingAssortmentFindings;
      this.findingsForPackingAndMarking = this.workOrder.packagingMarkingFindings == null ? null : this.workOrder.packagingMarkingFindings;

   

      this.initQuantityVerification();
      if (this.workOrder.workOrderPackageCheck?.recompute){
        this.onChangeInspectionLevel(false);
      }
      else if (this.packageCheckObj.ilPkgCheck && this.packageCheckObj.ilPkgCheck !== "Fixed" && this.isQVListUpdated) {
        this.onChangeInspectionLevel(true);
      }

      // load the subSections for Logo, Labels & Tags Section
      this.loadLogoLabelTagsSection();

      // load Packing And Assortment
      this.loadPackingAssormentSection();

      // load Packaing And Marking
      this.loadPackagingMarkingSection();

      // clone the workOrderProducts list - this will be the baseline to check if items were modified
      this.workOrderProductsClone = _.cloneDeep(this.workOrderProducts);
      this.isLoadingItems = false;
      this.loadingDf = false;
      this.$store.dispatch("navigation/setLoadingPage",{show: this.loadingDF });
    },

    async loadLookup() {
      const resLookup = await this.utilityFn.getServerDataAsync(
      "get",
      this.apiList.getLookupAsync);

      this.aqlOptions = resLookup.data.aql.filter((x) => x.isActive === true);
        this.filteredLookup = resLookup.data.inspectionClass
          .filter((x) => x.inspectionClassFieldName.name === "Inspection Level 2")
          .map((x) => {
            const item = this.inspectionLevel2LookupOrder.find((o) => o.name === x.name);
            x.order = item ? item.order : 0;
            return x;
          });
        this.filteredLookup = _.orderBy(this.filteredLookup, ["order"], ["asc"]);
        this.inspectionLevel1Opetions = resLookup.data.inspectionClass.filter((x) => x.inspectionClassFieldName.name === "Inspection Level 1" && x.name !== "Multiple");
        this.inspectionRoundOptions = resLookup.data.inspectionClass.filter((x) => x.inspectionClassFieldName.name === "Inspection Round");
    },

    changeAQLBySamplingMethodolgy() {
      let criticalAc = null;
      let criticalRe = null;
      let majorAc = null;
      let majorRe = null;
      let minorAc = null;
      let minorRe = null;

      let totalFoundCritical = null;
      let totalFoundMajor = null;
      let totalFoundMinor = null;

      if (this.packageCheckObj.isVaryingAQL && this.packageCheckObj.samplingMethodology === "Combined Sample Size") {
        if (this.packageCheckObj.ilPkgCheck != "Fixed" || this.packageCheckObj.ilPkgCheck != "Square Root") {
          //Critical
          let varyingAQLCritical = this.samPlan.getVaryingAQL(this.samplingPlan.totalCartonPOQty, this.packageCheckObj.ilPkgCheck, this.workOrder.inspectionLevel, this.getAQL(this.packageCheckObj.aqlCriticalId), this.workOrder.samplingPlan);
          criticalAc = this.getAQL(this.packageCheckObj.aqlCriticalId) == "0" ? "0" : varyingAQLCritical.aC;
          criticalRe = this.getAQL(this.packageCheckObj.aqlCriticalId) == "0" ? "1" : varyingAQLCritical.rE;

          //Major
          let varyingAQLMajor = this.samPlan.getVaryingAQL(this.samplingPlan.totalCartonPOQty, this.packageCheckObj.ilPkgCheck, this.workOrder.inspectionLevel, this.getAQL(this.packageCheckObj.aqlMajorId), this.workOrder.samplingPlan);
          majorAc = this.getAQL(this.packageCheckObj.aqlMajorId) == "0" ? "0" : varyingAQLMajor.aC;
          majorRe = this.getAQL(this.packageCheckObj.aqlMajorId) == "0" ? "1" : varyingAQLMajor.rE;

          //Minor
          let varyingAQLMinor = this.samPlan.getVaryingAQL(this.samplingPlan.totalCartonPOQty, this.packageCheckObj.ilPkgCheck, this.workOrder.inspectionLevel, this.getAQL(this.packageCheckObj.aqlMinorId), this.workOrder.samplingPlan);
          minorAc = this.getAQL(this.packageCheckObj.aqlMinorId) == "0" ? "0" : varyingAQLMinor.aC;
          minorRe = this.getAQL(this.packageCheckObj.aqlMinorId) == "0" ? "1" : varyingAQLMinor.rE;

          totalFoundCritical = this.workOrderProducts.reduce(function(tot, item) {
            return tot + item.workOrderProductResults.pkgCheckAqlCriticalFound;
          }, 0);

          totalFoundMajor = this.workOrderProducts.reduce(function(tot, item) {
            return tot + item.workOrderProductResults.pkgCheckAqlMajorFound;
          }, 0);

          totalFoundMinor = this.workOrderProducts.reduce(function(tot, item) {
            return tot + item.workOrderProductResults.pkgCheckAqlMinorFound;
          }, 0);
        }

        // this.packageCheckObj.foundCritical = totalFoundCritical
        // this.packageCheckObj.allowedCritical = criticalAc
        // this.packageCheckObj.rejectedCritical = criticalRe

        // this.packageCheckObj.foundMajor = totalFoundMajor
        // this.packageCheckObj.allowedMajor = majorAc
        // this.packageCheckObj.rejectedMajor = majorRe

        //  this.packageCheckObj.foundMinor = totalFoundMinor
        // this.packageCheckObj.allowedMinor = minorAc
        // this.packageCheckObj.rejectedMinor = minorRe

      }
    },

    ShowAQLinSumarry() {
      let val = false;
      if (this.packageCheckObj.isVaryingAQL && this.packageCheckObj.samplingMethodology === "Combined Sample Size") {
        val = true;
      } else {
        val = false;
      }
      return val;
    },

     isVaryingCombined(){
     let val = false;
      if (this.packageCheckObj.isVaryingAQL && this.packageCheckObj.samplingMethodology === "Combined Sample Size") {
        val = true;
      } else {
        val = false;
      }
      return val;
    },
    isVaryingSeparated(){
     let val = false;
      if (this.packageCheckObj.isVaryingAQL && this.packageCheckObj.samplingMethodology === "Separated Sample Size") {
        val = true;
      } else {
        val = false;
      }
      return val;
    },

    onChangeAQLLevel(aqlValue, aqlLevel) {
      let aC = 0;
      let rE = 0;
      let found = 0;
      let sz = 0;

      this.changeAQLBySamplingMethodolgy();

      if (this.packageCheckObj.ilPkgCheck == "Fixed" || this.packageCheckObj.ilPkgCheck == "Square Root") {
        aC = null;
        rE = null;
        sz = null;

        if (aqlLevel == "Critical") {
          // this.packageCheckObj.sampleSizeCritical = sz
        } else if (aqlLevel == "Major") {
          // this.packageCheckObj.sampleSizeMajor = sz
        } else if (aqlLevel == "Minor") {
          // this.packageCheckObj.sampleSizeMinor = sz
        }
      } else {
        let varyingAQL = this.samPlan.getVaryingAQL(this.samplingPlan.totalCartonPOQty, this.packageCheckObj.ilPkgCheck, this.workOrder.inspectionLevel, this.getAQL(aqlValue), this.workOrder.samplingPlan);

        aC = this.getAQL(aqlValue) == "0" ? "0" : varyingAQL.aC;
        rE = this.getAQL(aqlValue) == "0" ? "1" : varyingAQL.rE;
        sz = varyingAQL.sampleSize;

        if (aqlLevel == "Critical") {
          // this.packageCheckObj.sampleSizeCritical = sz
        } else if (aqlLevel == "Major") {
          // this.packageCheckObj.sampleSizeMajor = sz
        } else if (aqlLevel == "Minor") {
          // this.packageCheckObj.sampleSizeMinor = sz
        }
      }

      this.workOrderProducts.forEach((element) => {
        const index = this.workOrderProducts.findIndex((x) => x.id === element.id);
        if (aqlLevel == "Critical") {
          this.workOrderProducts[index].workOrderProductResults.pkgCheckAqlCriticalAccept = aC;
          this.workOrderProducts[index].workOrderProductResults.pkgCheckAqlCriticalReject = rE;
        } else if (aqlLevel == "Major") {
          this.workOrderProducts[index].workOrderProductResults.pkgCheckAqlMajorAccept = aC;
          this.workOrderProducts[index].workOrderProductResults.pkgCheckAqlMajorReject = rE;
        } else if (aqlLevel == "Minor") {
          this.workOrderProducts[index].workOrderProductResults.pkgCheckAqlMinorAccept = aC;
          this.workOrderProducts[index].workOrderProductResults.pkgCheckAqlMinorReject = rE;
        }
      });

      if (!this.isBulkUpsertAcRe) {
        let data = [];
        if (aqlLevel == "Critical") {
          data = [
            { op: "replace", path: "/pkgCheckAqlCriticalAccept", value: aC },
            { op: "replace", path: "/pkgCheckAqlCriticalReject", value: rE },
          ];
        }
        if (aqlLevel == "Major") {
          data = [
            { op: "replace", path: "/pkgCheckAqlMajorAccept", value: aC },
            { op: "replace", path: "/pkgCheckAqlMajorReject", value: rE },
          ];
        }
        if (aqlLevel == "Minor") {
          data = [
            { op: "replace", path: "/pkgCheckAqlMinorAccept", value: aC },
            { op: "replace", path: "/pkgCheckAqlMinorReject", value: rE },
          ];
        }

        const patchData = this.workOrderProducts.map((x) => ({ id: x.id, patch: data, sectionId: 3 }));

        this.utilityFn.getServerData("patch", this.apiList.bulkUpsertWorkOrderProductResult, patchData, (result) => {});
      }
    },

    bulkUpsertPackageProductAQL() {
      let patchData = [];
      this.workOrderProducts.forEach((element) => {
        let data = [];
        const index = this.workOrderProducts.findIndex((x) => x.id === element.id);

        data = [
          { op: "replace", path: "/pkgCheckAqlCriticalAccept", value: this.workOrderProducts[index].workOrderProductResults.pkgCheckAqlCriticalAccept },
          { op: "replace", path: "/pkgCheckAqlCriticalReject", value: this.workOrderProducts[index].workOrderProductResults.pkgCheckAqlCriticalReject },
          { op: "replace", path: "/pkgCheckAqlMajorAccept", value: this.workOrderProducts[index].workOrderProductResults.pkgCheckAqlMajorAccept },
          { op: "replace", path: "/pkgCheckAqlMajorReject", value: this.workOrderProducts[index].workOrderProductResults.pkgCheckAqlMajorReject },
          { op: "replace", path: "/pkgCheckAqlMinorAccept", value: this.workOrderProducts[index].workOrderProductResults.pkgCheckAqlMinorAccept },
          { op: "replace", path: "/pkgCheckAqlMinorReject", value: this.workOrderProducts[index].workOrderProductResults.pkgCheckAqlMinorReject },
        ];

        const newPatchRow = {
          id: element.id,
          patch: data,
          sectionId: 3,
        };
        patchData.push(newPatchRow);
      });

      if (patchData.length > 0) {
        this.utilityFn.getServerData("patch", this.apiList.bulkUpsertWorkOrderProductResult, patchData, (result) => {
          this.isBulkUpsertAcRe = false;
        });
      }
    },

    closeSave() {
      let patchData = [];
      this.workOrderProducts.forEach((element) => {
        let data = [];
        const index = this.workOrderProducts.findIndex((x) => x.id === element.id);

        data = [
          { op: "replace", path: "/pkgCheckAqlCriticalFound", value: this.workOrderProducts[index].workOrderProductResults.pkgCheckAqlCriticalFound },
          { op: "replace", path: "/pkgCheckAqlMajorFound", value: this.workOrderProducts[index].workOrderProductResults.pkgCheckAqlMajorFound },
          { op: "replace", path: "/pkgCheckAqlMinorFound", value: this.workOrderProducts[index].workOrderProductResults.pkgCheckAqlMinorFound },
        ];

        const newPatchRow = {
          id: element.id,
          patch: data,
          sectionId: 3,
        };
        patchData.push(newPatchRow);
      });

      if (patchData.length > 0) {
        this.changeAQLBySamplingMethodolgy();
        this.utilityFn.getServerData("patch", this.apiList.bulkUpsertWorkOrderProductResult, patchData, (result) => {
          this.showDefectFormDialog = false;
        },() => {
           this.showDefectFormDialog = false;
        });
      }
    },

    del_temp_img(fileId, fileArray, index) {
      this.delImgSnackbar.isShow = true;
      this.delImgSnackbar.text = "Deleting picture";
      this.utilityFn.deleteImage(fileId, (res) => {
        fileArray.splice(index, 1);
        this.delImgSnackbar.isShow = false;
        // 删除成功后重新获取列表数据
        this.populateWorkorderList();
      });
      this.loadData();
    },

    async updatePackageCheck() {
      this.packageCheckObj.workOrderId = this.workOrder.workOrderId;
      this.packageCheckObj.doubleSamplingPlan = this.packageCheckObj.inspectionLevel1 != "Double" ? null : this.packageCheckObj.doubleSamplingPlan;
      this.packageCheckObj.sectionId = 3;
      this.packageCheckObj.recompute = false;
      this.sectionDetail.result = this.sectionOverAllResult;

      const request1 = this.utilityFn.getServerDataAsync("post", this.apiList.upsertWorkOrderPackageCheck, this.packageCheckObj);
      const request2 = this.utilityFn.getServerDataAsync("post", this.apiList.sectionUpdate, this.sectionDetail);
      const index = this._workOrderDetails.workOrderInspectionSections.findIndex((x) => x.sectionId == this.sectionId);
      if (index !== -1) {
        this._workOrderDetails.workOrderInspectionSections[index] = this.sectionDetail;
        this.inspectionSummarySectionResult.forEach(x => {
            if(x.sectionId==3){
              x.result = this.sectionDetail.result;
            }
          })
        this.$store.dispatch("inspection/setWorkOrderDetails", _.cloneDeep(this._workOrderDetails));
      }

      await Promise.all([request1, request2]);
    },

    resultDescription(resultId) {
      const result = this.select_result.find((x) => x.value == resultId);
      return result ? result.text : "";
    },

    onImageChange(event, screenId, editItem) {
      editItem.uploadLoading = true;
      event.forEach((item) => {
        let formData = new FormData();
        formData.append("image", item);
        formData.append("description", editItem.finding);
        formData.append("workOrderProductId", editItem.id);
        formData.append("screen", screenId);

        this.utilityFn.uploadImg(
          this.apiList.uploadWorkOrderProductImage,
          { formData: formData },
          (progress) => {
            // 获得上传进度
          },
          (res) => {
            // 显示上传进度
            editItem.uploadLoading = false;
            editItem.serverPhoto.push({ fileUploadId: res.data.fileUploadId, img: window.URL.createObjectURL(item) });
          }
        );
      });
      this.loadData();
    },

    // _options = { isDecimal: true, showMsg: false}
    returnNumber(obj, name, _options) {
      _options = _options || {};
      let options = {};
      options.isDecimal = _options.isDecimal !== false;
      options.showMsg = _options.showMsg !== false;

      if (!obj[name] || this.packageCheckObj.ilPkgCheck == "Square Root") return;

      if (options.isDecimal) {
        obj[name] = obj[name].replace(/[^\d+(\.\d+)]/g, "");
      } else {
        obj[name] = obj[name].replace(/[^\d]/g, "");
      }

      if (options.showMsg && isNaN(obj[name])) {
        this.isDateEntryValid = false;
        this.snackbar = true;
        this.message = "Must be a number";
        return;
      }

      this.isDateEntryValid = true;
      this.onChange();
    },

    async submit_item() {
      if(!this.isPackageCheckFormValid || !this.isPackingAndAssortmentFormValid || !this.isPackagingAndMarkingFormValid || !this.isLogosLabelsTagsFormValid) {
        this.snackbar = true;
        this.message = "Missing Input Required Fields";
        return;
      }

      if(this.isWalmartOrAsda() && this._workOrderDetails.isPreShipOrFinalRandom) {
        var validatePackageCheck = await this.utilityFn.getServerDataAsync('get',`${this.apiList.validatePackageCheck}/${this.$route.params.id}`,{});
        if (validatePackageCheck.data){
          if (validatePackageCheck.data.item1){
            this.snackbar = true;
            this.message = validatePackageCheck.data.item2;
            return;
          }
        }
      }
      
      const index = this._workOrderDetails.workOrderInspectionSections.findIndex((x) => x.sectionId == this.sectionId);
      if (index !== -1) {
        const section = {
          sectionId: this.sectionId,
          workOrderId: this.$route.params.id,
        };
        this.utilityFn.getServerData("post", this.apiList.submitInspectionSection, section, (res) => {
          this._workOrderDetails.workOrderInspectionSections[index].submittedDate = new Date();

          this.sectionData.PackageCheck.isSectionSubmitted = true;
          this.$store.dispatch("inspection/setSectionData", this.sectionData);
          this.$store.dispatch("inspection/setWorkOrderDetails", _.cloneDeep(this._workOrderDetails));
        });
      }
    },

    go_upload_photo() {
      this.$router.push({
        name: "uploadPhotoGalleryId",
        params: { id: this.$route.params.id, name: this.sectionData.PackageCheck.name, sectionId: this.sectionData.PackageCheck.id },
        query: { name: this.sectionData.PackageCheck.name, sectionId: this.sectionData.PackageCheck.id },
      });
    },

    onChangeInspectionLevel(isInitialLoad) {
      if (this.packageCheckObj.isVaryingAQL) {
        this.isBulkUpsertAcRe = true;
       
        this.bulkUpsertPackageProductAQL();
      }

      const value = this.packageCheckObj.ilPkgCheck;
      const uniqueSkuList = _.uniq(this.workOrder.products.map((x) => x.productID));
      const uniquePoList = _.uniq(this.workOrder.products.map((x) => x.po));

      this.$store.dispatch("inspection/setQVListUpdated", false);

      // let samplingPlanByInspectionLevel = this.samPlan.getSamplingPlanByInspectionLevel(value)

      if (value == "Fixed") {
        this.packageCheckObj.crtnSampleSize = null;
        this.packageCheckObj.isManulaInputSZ = true;
        return;
      }

      // if (value == 'Square Root') {
      //     const totalAvailCartonQty = this.productQuantityVerificationList.reduce((prev, cur) => (Number(prev) + Number(cur.availableQuantityCarton)), 0);
      //     const sqrtTotal = this._productQuantityVerificationList.reduce((prev, cur) => (Number(prev) + this.getSquareRootCarton(cur.availableQuantityCarton || 0, totalAvailCartonQty)), 0);
      //     this.packageCheckObj.crtnSampleSize = Math.round(sqrtTotal);
      //     // if (uniqueSkuList.length === 1 || uniqueSkuList.length > 1 && uniquePoList.length > 1) {

      //     //     // const sqrtTotal = this._productQuantityVerificationList.reduce((prev, cur) => (Number(prev) + Math.ceil(Math.sqrt(Number(cur.availableQuantityCarton || 0)))), 0);
      //     //     const sqrtTotal = this._productQuantityVerificationList.reduce((prev, cur) => (Number(prev) + this.getSquareRootCarton(cur.availableQuantityCarton || 0, totalAvailCartonQty)), 0);
      //     //     this.packageCheckObj.crtnSampleSize = Math.round(sqrtTotal);
      //     // }

      //     // else if (uniqueSkuList.length > 1 && uniquePoList.length === 1) {
      //     //     const totalCartonQty = this.workOrderProducts.reduce((prev, cur) => (Number(prev) + Number(cur.availableQuantityCarton)), 0);

      //     //     this.packageCheckObj.crtnSampleSize = this.getSquareRootCarton(totalCartonQty, totalAvailCartonQty);
      //     // }
      // }

      this.packageCheckObj.isManulaInputSZ = false;

      if (value == "Square Root") {
        const totalAvailCartonQty = this.productQuantityVerificationList.reduce((prev, cur) => prev + cur.availableQuantityCarton, 0);
        // const sqrtTotalAvailCartonQty  =  Math.ceil(Math.sqrt(totalAvailCartonQty))
        const sumAllSqrtPerSkuCartonQty = this.productQuantityVerificationList.reduce((prev, cur) => prev + Math.ceil(Math.sqrt(cur.availableQuantityCarton)), 0);
        const sqrtTotalAvailCartonQty = this.productQuantityVerificationList.reduce((prev, cur) => prev + Math.ceil((Math.sqrt(totalAvailCartonQty) / totalAvailCartonQty) * cur.availableQuantityCarton), 0);

        if (this.workOrderProducts.length == 1) {
          return (this.packageCheckObj.crtnSampleSize = Math.ceil(Math.sqrt(totalAvailCartonQty)));
        }

        if (this.workOrderProducts.length > 1 && this.packageCheckObj.samplingMethodology == "Combined Sample Size") {
          //const sqrtTotal = this.productQuantityVerificationList.reduce((prev, cur) => Number(prev) + this.getSquareRootCarton(cur.availableQuantityCarton || 0, totalAvailCartonQty), 0);
          const sqrtTotal = Math.ceil(Math.sqrt(this._samplingPlanLists.totalCartonPOQty));
          return (this.packageCheckObj.crtnSampleSize = Math.ceil(sqrtTotal));
        }

        if (this.workOrderProducts.length > 1) {
          return (this.packageCheckObj.crtnSampleSize = sumAllSqrtPerSkuCartonQty);
        }
      } else {
        this.packageCheckObj.crtnSampleSize = this.samPlan.getSamplingPlanByInspectionLevelSamplingMethodologyInspectionLevel1doubleSamplingPlanIteration(
          value,
          this.packageCheckObj.samplingMethodology,
          this.packageCheckObj.inspectionLevel1,
          this.packageCheckObj.doubleSamplingPlan
        ).totalCartonSampleSize;

        this.updateAQL(isInitialLoad);
      }

      this.onChange();
      return;
    },

    onChangeSamplingMethodology() {
      const value = this.packageCheckObj.ilPkgCheck;
      const uniqueSkuList = _.uniq(this.workOrder.products.map((x) => x.productID));
      const uniquePoList = _.uniq(this.workOrder.products.map((x) => x.po));

      this.$store.dispatch("inspection/setQVListUpdated", false);

      this.changeAQLBySamplingMethodolgy();

      // let samplingPlanByInspectionLevel = this.samPlan.getSamplingPlanByInspectionLevel(value)

      if (value == "Fixed") {
        this.packageCheckObj.crtnSampleSize = null;
        this.packageCheckObj.isManulaInputSZ = true;
        return;
      }

      this.packageCheckObj.isManulaInputSZ = false;

      if (value == "Square Root") {
        const totalAvailCartonQty = this.productQuantityVerificationList.reduce((prev, cur) => prev + cur.availableQuantityCarton, 0);
        // const sqrtTotalAvailCartonQty  =  Math.ceil(Math.sqrt(totalAvailCartonQty))
        const sumAllSqrtPerSkuCartonQty = this.productQuantityVerificationList.reduce((prev, cur) => prev + Math.ceil(Math.sqrt(cur.availableQuantityCarton)), 0);
        const sqrtTotalAvailCartonQty = this.productQuantityVerificationList.reduce((prev, cur) => prev + Math.ceil((Math.sqrt(totalAvailCartonQty) / totalAvailCartonQty) * cur.availableQuantityCarton), 0);

        if (this.workOrderProducts.length == 1) {
          return (this.packageCheckObj.crtnSampleSize = Math.ceil(Math.sqrt(totalAvailCartonQty)));
        }

        if (this.workOrderProducts.length > 1 && this.packageCheckObj.samplingMethodology == "Combined Sample Size") {
          const sqrtTotal = this.productQuantityVerificationList.reduce((prev, cur) => Number(prev) + this.getSquareRootCarton(cur.availableQuantityCarton || 0, totalAvailCartonQty), 0);

          return (this.packageCheckObj.crtnSampleSize = Math.ceil(sqrtTotal));
        }

        if (this.workOrderProducts.length > 1) {
          return (this.packageCheckObj.crtnSampleSize = sumAllSqrtPerSkuCartonQty);
        }
      } else {
        this.packageCheckObj.crtnSampleSize = this.samPlan.getSamplingPlanByInspectionLevelSamplingMethodologyInspectionLevel1doubleSamplingPlanIteration(
          value,
          this.packageCheckObj.samplingMethodology,
          this.packageCheckObj.inspectionLevel1,
          this.packageCheckObj.doubleSamplingPlan
        ).totalCartonSampleSize;
      }

      this.onChange();
      return;
    },

    getSquareRootCarton(quantity, totalCartons) {
      let squareRootCarton = null;
      // if(this.workOrderProducts.length == 1){
      //     return squareRootCarton = Math.ceil(Math.sqrt(quantity))
      // }

      if (this.workOrderProducts.length > 1 && this.packageCheckObj.samplingMethodology == "Combined Sample Size") {
        return (squareRootCarton = Math.ceil((Math.sqrt(totalCartons) / totalCartons) * quantity));
      }
      return squareRootCarton;

      // if(this.workOrderProducts.length > 1 ){
      //     return squareRootCarton = Math.ceil(Math.sqrt(quantity))
      // }
    },

    initQuantityVerification() {
      let existingQuantityVerificationList = [];
      this.workOrderProducts.forEach((x) => {
        if (x.workOrderProductQuantityVerificationResults.length > 0) {
          x.workOrderProductQuantityVerificationResults.forEach((item) => {
            existingQuantityVerificationList.push(item);
          });
        }
      });
      if (existingQuantityVerificationList.length > 0) {
        this.loadQuantityVerificationProductDetails(existingQuantityVerificationList);
      }
    },

    loadQuantityVerificationProductDetails(quantityVerificationProductDetails) {
      if (quantityVerificationProductDetails) {
        if (quantityVerificationProductDetails.length > 0) {
          let quantityVerificationList = [];
          quantityVerificationProductDetails.forEach((res) => {
            const skuItem = this.workOrder.products.find((x) => x.id === res.workOrderProductId) || {};
            const quantityVerification = {
              id: res.id,
              workOrderProductId: res.workOrderProductId,
              productID: skuItem.productID,
              skuDisplay: skuItem.skuDisplay,
              po: skuItem.po,
              cartonQty: skuItem.cartonQty,
              qty: skuItem.qty,
              //caseCountProduct: this.getSKUDisplay(res.workOrderProductId).caseCountProduct,
              caseCountProduct: res.caseCountProduct,
              availableQuantityCarton: res.availableQuantityCarton,
              sampleSizeCarton: res.sampleSizeCarton,
              sampleSizeCarton2: res.sampleSizeCarton,
              sampleSizeCartonTotal: res.sampleSizeCartonTotal,
              excessShortQuantity: res.excessShortQuantity,
              sampleSizeProduct: res.sampleSizeProduct,
              sampleSizeProduct2: res.sampleSizeProduct,
              sampleSizeProductTotal: res.sampleSizeProductTotal,
              exportCartonNumbersOpened: res.exportCartonNumbersOpened,
              remarks: res.remarks,
              packedQty: res.packedQty,
              underPackingQty: res.underPackingQty,
              underProductionFinishingQty: res.underProductionFinishingQty,
              availableQuantityProduct: res.availableQuantityProduct,
              orderNumber: res.orderNumber,
              sqrtSampleSizeCarton: res.availableQuantityCarton ? Math.ceil(Math.sqrt(Number(res.availableQuantityCarton))) : 0,
            };

            if (this.editedIndex > -1) {
              Object.assign(this.productQuantityVerificationList[this.editedIndex], quantityVerification);
            } else {
              this.productQuantityVerificationList.push(quantityVerification);
            }
          });
          this.editedIndex = -1;
        }
      }
    },

    onSampleSizeChange() {
      this.packageCheckObj.isManulaInputSZ = true;
    },

    resultChange(item) {
      item.reason = null;
      const hasAllResult = this.logoLabelSubSections.every(x => x.result !== null);

      if(!hasAllResult) return;

      const hasFail = this.logoLabelSubSections.find(x => x.result === '0');

      const hasPending = this.logoLabelSubSections.find(x => x.result === '2');

      const hasNA = this.logoLabelSubSections.find(x => x.result === '3');

      const hasPass = this.logoLabelSubSections.find(x => x.result === '1')
      
      if(hasFail) {
        this.logoLabelResult = '0'
      }

      if(hasPending && !hasFail) {
        this.logoLabelResult = '2'
      }

      if(hasNA && !hasPass && !hasPending && !hasFail) {
        this.logoLabelResult = '3';
      }

      if(hasPass && !hasPending && !hasFail) {
        this.logoLabelResult = '1';
      }

    },

    onSectionToggle(event, tab) {
      this.triggerFormValidation();
      if (event) {
        return;
      }

      switch (tab) {
        case this.packingAssortmentSectionId:
          this.packingAssortmentSubSections.forEach((logo) => {
            logo.reason = null;
            logo.result = null;
            logo.remarks = null;
          });
          break;

        case this.packagingAndMarkingSectionId:
          this.packagingMarkingSubSections.forEach((logo) => {
            logo.reason = null;
            logo.result = null;
            logo.remarks = null;
          });
          break;

        case this.logoLabelSectionId:
          this.logoLabelResult = null;
          this.logoLabelSubSections.forEach((logo) => {
            logo.reason = null;
            logo.result = null;
            logo.remarks = null;
          });
          break;
      }
    },

    onChange() {
      this.logChanges();
    },

    logChanges(log = true) {
      window.sessionStorage.setItem("EditDataLogging", log);
    },

    loadLogoLabelTagsSection() {
      const section = this.workOrder.workOrderInspectionSections.find((wis) => wis.sectionId == this.logoLabelSectionId);

      this.logoLabelShowHide = !section.isHidden;
      this.logoLabelResult = this.workOrder.logoLabelTagResult != null ? this.workOrder.logoLabelTagResult.toString() : null;
      this.logoLabelResultCode = this.workOrder.logoLabelResultCode != null ? this.workOrder.logoLabelResultCode.toString() : null;
      const woSubSections = this.workOrder.workOrderSubSections.filter((ws) => ws.sectionId === this.logoLabelSectionId);
      this.logoLabelSubSections.forEach((currentItem, index, logoLabelSubSections) => {
        const item = woSubSections.find((ws) => ws.subSectionId === currentItem.subSection.id);
        if (item) {
          this.logoLabelSubSections.splice(index, 1, item);
        }
      });
    },

    loadPackingAssormentSection() {
      const section = this.workOrder.workOrderInspectionSections.find((wis) => wis.sectionId == this.packingAssortmentSectionId);

      this.packingAssortmentShowHide = !section.isHidden;
      const woSubSections = this.workOrder.workOrderSubSections.filter((ws) => ws.sectionId === this.packingAssortmentSectionId);
      this.packingAssortmentSubSections.forEach((currentItem, index, logoLabelSubSections) => {
        const item = woSubSections.find((ws) => ws.subSectionId === currentItem.subSection.id);
        if (item) {
          this.packingAssortmentSubSections.splice(index, 1, item);
        }
      });
    },

    loadPackagingMarkingSection() {
      const section = this.workOrder.workOrderInspectionSections.find((wis) => wis.sectionId == this.packagingAndMarkingSectionId);

      this.packingAssortmentShowHide = !section.isHidden;
      const woSubSections = this.workOrder.workOrderSubSections.filter((ws) => ws.sectionId === this.packagingAndMarkingSectionId);
      this.packagingMarkingSubSections.forEach((currentItem, index, logoLabelSubSections) => {
        const item = woSubSections.find((ws) => ws.subSectionId === currentItem.subSection.id);
        if (item) {
          this.packagingMarkingSubSections.splice(index, 1, item);
        }
      });
    },

    async saveLogoLabelTagsSection() {
      this.logoLabelSubSections.forEach((subSection) => {
        subSection.workOrderId = this.workOrder.workOrderId;
      });
      const resultData = [
        { op: "replace", path: "/logolabeltagresult", value: this.logoLabelResult },
        { op: "replace", path: "/packingassortmentresult", value: this.resultForPackingAndAssortment },
        { op: "replace", path: "/packagingmarkingresult", value: this.resultForPackingAndMarking },
        { op: "replace", path: "/packingassortmentfindings", value: this.findingsForPackingAndAssortment },
        { op: "replace", path: "/packagingmarkingfindings", value: this.findingsForPackingAndMarking },
        { op: "replace", path: "/packingassortmentresultcode", value: this.packingAssortmentResultCode },
        { op: "replace", path: "/packagingmarkingresultcode", value: this.packagingMarkingResultCode },
        { op: "replace", path: "/logolabeltagresultcode", value: this.logoLabelResultCode },

      ];
      const section = this.workOrder.workOrderInspectionSections.find((wis) => wis.sectionId == this.logoLabelSectionId);
      section.isHidden = !this.logoLabelShowHide;
      section.section = null;
      const request1 = this.utilityFn.getServerDataAsync("patch", this.apiList.patchWorkOrderResults + "/" + this.workOrder.workOrderId + "/" + this.logoLabelSectionId, resultData);
      const request2 = this.utilityFn.getServerDataAsync("post", this.apiList.upsertWorkOrderSubSections, this.logoLabelSubSections);
      const request3 = await this.utilityFn.getServerDataAsync("post", this.apiList.sectionUpdate, section);

      await Promise.all([request1, request2, request3]);
    },

    savePackingAssortment() {
      // this.packingAssortmentSubSections.forEach(subSection => { subSection.workOrderId = this.workOrder.workOrderId; });
      // const resultData = [{ "op": "replace", "path": "/packingassortmentresult", "value": +this.resultForPackingAndAssortment }];
      // this.utilityFn.getServerData('patch', this.apiList.patchWorkOrderResults + '/' + this.workOrder.workOrderId + '/' + this.packingAssortmentSectionId, resultData, result => { })
      // this.utilityFn.getServerData('post', this.apiList.upsertWorkOrderSubSections, this.packingAssortmentSubSections, result => { });
      // const section = this.workOrder.workOrderInspectionSections.find(wis => wis.sectionId == this.packingAssortmentSectionId);
      // console.log('savePackingAssortment', section);
      // section.isHidden = !this.packingAssortmentShowHide;
      // section.result = this.resultForPackingAndAssortment;
      // section.findings = this.findingsForPackingAndAssortment;
      // section.section = null;
      // this.utilityFn.getServerData('post', this.apiList.sectionUpdate, section, response => {});
    },

    savePackagingMarkingSection() {
      // this.packagingMarkingSubSections.forEach(subSection => { subSection.workOrderId = this.workOrder.workOrderId; });
      // const resultData = [{ "op": "replace", "path": "/packagingmarkingresult", "value": +this.resultForPackingAndMarking }];
      // this.utilityFn.getServerData('patch', this.apiList.patchWorkOrderResults + '/' + this.workOrder.workOrderId + '/' + this.packagingAndMarkingSectionId, resultData, result => { })
      // this.utilityFn.getServerData('post', this.apiList.upsertWorkOrderSubSections, this.packagingMarkingSubSections, result => { });
      // const section = this.workOrder.workOrderInspectionSections.find(wis => wis.sectionId == this.packagingAndMarkingSectionId);
      // console.log('savePackagingMarkingSection', section);
      // section.isHidden = !this.packagingMarkingsShowHide;
      // section.result = this.resultForPackingAndMarking;
      // section.findings = this.findingsForPackingAndMarking;
      // section.section = null;
      // this.utilityFn.getServerData('post', this.apiList.sectionUpdate, section, response => {});
    },

    triggerFormValidation() {
      if (this.$refs.logoLabelForm) {
        this.$refs.logoLabelForm.validate();
      }
    },

    charCount(item) {
      if (item.remarks.length >= this.maxcharacter) {
        var remainCharacters = this.maxcharacter - item.remarks.length;

        if (remainCharacters <= -1) {
          if (remainCharacters < -1) {
            item.remarks = item.remarks.slice(0, 200);
          } else {
            item.remarks = item.remarks.slice(0, -1);
          }
        }
      }
    },

    isFormValid() {
      if (
        this.editedItem.result == 1 ||
        this.editedItem.result == 2 ||
        this.editedItem.result == 3 ||
        (this.editedItem.result == 0 && !this.packingAssortmentShowHide) || (this.editedItem.result == 0 && this.packingAssortmentShowHide && this.editedItem.finding && this.editedItem.finding.trim().length > 0)
      ) {
        return true;
      }
      return false;
    },

    calculatePackageCheckResult(tab) {
      switch (tab) {
        case "tab-1":
          // Non-Conformed [If 1 SKU result is FAIL]
          this.packageCheckObj.packageCheckResultCode = null;
          if (this.workOrderProducts.filter((p) => p.workOrderProductResults.pkgCheckResult == 0).length > 0) {
            this.packageCheckObj.pkgCheckOverallRslt = "0";
          }
          // PENDING [If 1 SKU result is PENDING and no FAIL]
          else if (this.workOrderProducts.filter((p) => p.workOrderProductResults.pkgCheckResult == 2).length > 0) {
            this.packageCheckObj.pkgCheckOverallRslt = "2";
          }
          //  N/A [If All SKUs result is N/A]
          else if (this.workOrderProducts.filter((p) => p.workOrderProductResults.pkgCheckResult == 3).length == this.workOrderProducts.length) {
            this.packageCheckObj.pkgCheckOverallRslt = "3";
          }
          // Conform [If All SKUs result is PASS or with N/A]
          else {
            this.packageCheckObj.pkgCheckOverallRslt = "1";
          }
          break;
        case "tab-2":
          // Non-Conformed [If 1 SKU result is FAIL]
          this.packingAssortmentResultCode = null;
          if (this.workOrderProducts.filter((p) => p.workOrderProductResults.packingAssortmentResult == 0).length > 0) {
            this.resultForPackingAndAssortment = "0";
          }
          // PENDING [If 1 SKU result is PENDING and no FAIL]
          else if (this.workOrderProducts.filter((p) => p.workOrderProductResults.packingAssortmentResult == 2).length > 0) {
            this.resultForPackingAndAssortment = "2";
          }
          //  N/A [If All SKUs result is N/A]
          else if (this.workOrderProducts.filter((p) => p.workOrderProductResults.packingAssortmentResult == 3).length == this.workOrderProducts.length) {
            this.resultForPackingAndAssortment = "3";
          }
          // Conform [If All SKUs result is PASS or with N/A]
          else {
            this.resultForPackingAndAssortment = "1";
          }
          break;
        case "tab-3":
          // Non-Conformed [If 1 SKU result is FAIL]
          this.packagingMarkingResultCode = null;
          if (this.workOrderProducts.filter((p) => p.workOrderProductResults.packagingMarkingResult == 0).length > 0) {
            this.resultForPackingAndMarking = "0";
          }
          // PENDING [If 1 SKU result is PENDING and no FAIL]
          else if (this.workOrderProducts.filter((p) => p.workOrderProductResults.packagingMarkingResult == 2).length > 0) {
            this.resultForPackingAndMarking = "2";
          }
          //  N/A [If All SKUs result is N/A]
          else if (this.workOrderProducts.filter((p) => p.workOrderProductResults.packagingMarkingResult == 3).length == this.workOrderProducts.length) {
            this.resultForPackingAndMarking = "3";
          }
          // Conform [If All SKUs result is PASS or with N/A]
          else {
            this.resultForPackingAndMarking = "1";
          }
          break;
      }
    },

    // calculate overall result for the specified row
    calculateRowResult(item) {
      if (!item) return

      const index = this.workOrderProducts.findIndex(x => x.id === item.id);
      if (index === -1) return
     
      const row = this.workOrderProducts[index].workOrderProductResults;

      //if all values are null then just return
      if( Number(row.pkgCheckAqlCriticalFound) == 0
          && Number(row.pkgCheckAqlCriticalReject)  == 0
          && Number(row.pkgCheckAqlMajorFound)  == 0
          && Number(row.pkgCheckAqlMajorReject)  == 0
          && Number(row.pkgCheckAqlMinorFound)  == 0
          && Number(row.pkgCheckAqlMinorReject)  == 0
     )
      return;

      const isPass = Number(row.pkgCheckAqlCriticalFound) < Number(row.pkgCheckAqlCriticalReject)
                    && Number(row.pkgCheckAqlMajorFound) < Number(row.pkgCheckAqlMajorReject)
                    &&  Number(row.pkgCheckAqlMinorFound) < Number(row.pkgCheckAqlMinorReject);
                   
      this.workOrderProducts[index].workOrderProductResults.pkgCheckResult = isPass ? "1" : "0";

      // if all row has a value for the pkcCheckResult, call the method to calculate the overall result
      if (this.workOrderProducts.filter(x => x.workOrderProductResults.pkgCheckResult != null).length === this.workOrderProducts.length) {
        this.calculatePackageCheckResult('tab-1');
      }
    },

    async saveDataTableValues() {
      const patchData = [];
      // map only the object to be used in the cm
      const woProducts = this.workOrderProducts.map((wop) => ({ id: wop.id, ...wop.workOrderProductResults }));
      const woProductsClone = this.workOrderProductsClone.map((wop) => ({ id: wop.id, ...wop.workOrderProductResults }));

      woProducts.forEach((currentItem) => {
        // check if row/item has been modified
        const originalValue = woProductsClone.find((x) => x.id == currentItem.id);
        if (!_.isEqual(currentItem, originalValue)) {
          const packageCheckTabValues = [
            { op: "replace", path: "/pkgCheckResult", value: currentItem.pkgCheckResult },
            { op: "replace", path: "/pkgCheckFindings", value: currentItem.pkgCheckFindings },
          ];
          if (this.packageCheckObj.isVaryingAQL) {
            packageCheckTabValues.push(
              ...[
                { op: "replace", path: "/pkgCheckAqlCriticalAccept", value: currentItem.pkgCheckAqlCriticalAccept },
                { op: "replace", path: "/pkgCheckAqlCriticalReject", value: currentItem.pkgCheckAqlCriticalReject },

                { op: "replace", path: "/pkgCheckAqlMajorAccept", value: currentItem.pkgCheckAqlMajorAccept },
                { op: "replace", path: "/pkgCheckAqlMajorReject", value: currentItem.pkgCheckAqlMajorReject },

                { op: "replace", path: "/pkgCheckAqlMinorAccept", value: currentItem.pkgCheckAqlMinorAccept },
                { op: "replace", path: "/pkgCheckAqlMinorReject", value: currentItem.pkgCheckAqlMinorReject },
              ]
            );
          }

          const data = [
            // Package Check Tab Data
            ...packageCheckTabValues,

            // Packing & Assortment Tab Data
            { op: "replace", path: "/packingAssortmentResult", value: currentItem.packingAssortmentResult },
            { op: "replace", path: "/packingAssortmentFindings", value: currentItem.packingAssortmentFindings },

            // Packing & Marking Tab Data
            { op: "replace", path: "/packagingMarkingResult", value: currentItem.packagingMarkingResult },
            { op: "replace", path: "/packagingMarkingFindings", value: currentItem.packagingMarkingFindings },
          ];

          patchData.push({ id: currentItem.id, patch: data, sectionId: 3 });
        }
      });

      if (patchData.length > 0) {
        await this.utilityFn.getServerDataAsync("patch", this.apiList.bulkUpsertWorkOrderProductResult, patchData);
      }
    },

    requiredAcceptRejectValue(v) {
      if (this.packageCheckObj.isVaryingAQL && (this.packageCheckObj.ilPkgCheck == "Fixed" || this.packageCheckObj.ilPkgCheck == "Square Root") && this.packageCheckObj.samplingMethodology == "Separated Sample Size") {
        return !!v;
      }
      return true;
    },

    copyAboveRowPackageCheck(index) {
      const aboveRow = this.workOrderProducts.find((x) => x.indexMarkerPackageCheck == index - 1);
      const currentRow = this.workOrderProducts.find((x) => x.indexMarkerPackageCheck == index);

      currentRow.workOrderProductResults.pkgCheckResult = aboveRow.workOrderProductResults.pkgCheckResult;
      currentRow.workOrderProductResults.pkgCheckFindings = aboveRow.workOrderProductResults.pkgCheckFindings;

      if (this.packageCheckObj.isVaryingAQL) {
        currentRow.workOrderProductResults.pkgCheckAqlMinorAccept = aboveRow.workOrderProductResults.pkgCheckAqlMinorAccept;
        currentRow.workOrderProductResults.pkgCheckAqlMinorReject = aboveRow.workOrderProductResults.pkgCheckAqlMinorReject;
        currentRow.workOrderProductResults.pkgCheckAqlMajorAccept = aboveRow.workOrderProductResults.pkgCheckAqlMajorAccept;
        currentRow.workOrderProductResults.pkgCheckAqlMajorReject = aboveRow.workOrderProductResults.pkgCheckAqlMajorReject;
        currentRow.workOrderProductResults.pkgCheckAqlCriticalAccept = aboveRow.workOrderProductResults.pkgCheckAqlCriticalAccept;
        currentRow.workOrderProductResults.pkgCheckAqlCriticalReject = aboveRow.workOrderProductResults.pkgCheckAqlCriticalReject;
      }
    },

    copyAboveRowPackingAssortment(index) {
      const aboveRow = this.workOrderProducts.find((x) => x.indexMarkerPackingAssortment == index - 1);
      const currentRow = this.workOrderProducts.find((x) => x.indexMarkerPackingAssortment == index);

      currentRow.workOrderProductResults.packingAssortmentResult = aboveRow.workOrderProductResults.packingAssortmentResult;
      currentRow.workOrderProductResults.packingAssortmentFindings = aboveRow.workOrderProductResults.packingAssortmentFindings;
    },

    copyAboveRowPackagingMarking(index) {
      const aboveRow = this.workOrderProducts.find((x) => x.indexMarkerPackagingMarking == index - 1);
      const currentRow = this.workOrderProducts.find((x) => x.indexMarkerPackagingMarking == index);

      currentRow.workOrderProductResults.packagingMarkingResult = aboveRow.workOrderProductResults.packagingMarkingResult;
      currentRow.workOrderProductResults.packagingMarkingFindings = aboveRow.workOrderProductResults.packagingMarkingFindings;
    },

    resetPackageCheckIndexMarkers(e) {
      if (e.page == 1) {
        this.workOrderProducts.forEach((x) => (x.indexMarkerPackageCheck = null));
      }
    },

    resetPackingAssortmentIndexMarkers(e) {
      if (e.page == 1) {
        this.workOrderProducts.forEach((x) => (x.indexMarkerPackingAssortment = null));
      }
    },

    resetPackagingMarkingIndexMarkers(e) {
      if (e.page == 1) {
        this.workOrderProducts.forEach((x) => (x.indexMarkerPackagingMarking = null));
      }
    },

    // only save when the isFormDirty is true - means user has changed the value in the UI
    // user can still force saving even if nothing has been changed in UI if "forceSave" is true
    // "forceSave" value will come from the Footer Component. The footer component has a Save button
    // that calls this method and will force this method to call the API to save the values
    async saveData(forceSave = false) {
      if (!forceSave && !this.isFormDirty)
        return false;

      try {
        console.log(this.workOrder);
        await Promise.all([this.saveDataTableValues(), this.saveLogoLabelTagsSection(), this.updatePackageCheck()]);
        return true;
      } catch (ex) {
        console.log("saveData error", ex);
        return false;
      }
    },

    async loadWorkOrderRatingOptions() {
      const result = await  this.utilityFn.getServerDataAsync("get", this.apiList.GetCompanyWOResultTypeAliases + "?companyId=" + this.workOrder.clientId, {});
      this.workOrderRatingOptions = result.data.filter((x) => x.description && x.isActive);
    },

    getResultCodes(resultId) {
      const result = this.select_result.find(x => x.value == resultId);
      const woRating = this.workOrderRatingOptions.find(x => x.aimsSectionRating?.toLowerCase() == result?.text?.toLowerCase());

      if (woRating?.companyWorkOrderResultTypeCodes.length > 0) {
        this.isGenericCodes = false;
        return woRating.companyWorkOrderResultTypeCodes?.filter(x => x.isActive).sort((a,b) => (a.order < b.order) ? -1 : 1) || [];
      }
      this.isGenericCodes = true;
      return this.defaultFailCodeOptions.map(x => ({ code: x }));
    },

    updateAQL(isInitialLoad = false) {
      if(!this.packageCheckObj.ilPkgCheck) return;
      if(this.packageCheckObj.ilPkgCheck === 'Square Root') return;

      const aqlCriticalSelected = this.getAQL(this.packageCheckObj.aqlCriticalId);
      const aqlMinorSelected = this.getAQL(this.packageCheckObj.aqlMinorId);
      const aqlMajorSelected = this.getAQL(this.packageCheckObj.aqlMajorId);
      const aqlCriticalVal = +aqlCriticalSelected;
      const aqlMinorVal = +aqlMinorSelected;
      const aqlMajorVal = +aqlMajorSelected;

      let aqlCriticalSamplingPlan = 0;
      let aqlMinorSamplingPlan = 0;
      let aqlMajorSamplingPlan = 0;
      let zeroAQL = 0;
      let hasNoAQL = false;

      if(aqlCriticalVal !== 0 && !isNaN(aqlCriticalVal)) {
        aqlCriticalSamplingPlan = this.samPlan.getVaryingAQL(this.totalCartonQty, this.packageCheckObj.ilPkgCheck, this.workOrder.inspectionLevel, aqlCriticalVal)?.sampleSize;
      }

      if(aqlMinorVal !== 0 && !isNaN(aqlMinorVal)) {
        aqlMinorSamplingPlan = this.samPlan.getVaryingAQL(this.totalCartonQty, this.packageCheckObj.ilPkgCheck, this.workOrder.inspectionLevel, aqlMinorVal)?.sampleSize;
      }

      if(aqlMajorVal !== 0 && !isNaN(aqlMajorVal)) {
        aqlMajorSamplingPlan = this.samPlan.getVaryingAQL(this.totalCartonQty, this.packageCheckObj.ilPkgCheck, this.workOrder.inspectionLevel, aqlMajorVal)?.sampleSize;
      }

      if(aqlCriticalSelected === null && aqlMinorSelected === null && aqlMajorSelected === null) {
        hasNoAQL = true;
      }
      
      zeroAQL = this.samPlan.getVaryingAQL(this.totalCartonQty, this.packageCheckObj.ilPkgCheck, this.packageCheckObj.inspectionLevel1, 0, 0).sampleSize;
      var changeToMax = true;

      if (aqlCriticalSelected != null) {
        const selectedValue = aqlCriticalSelected.toLowerCase();
        if(isInitialLoad && this.packageCheckObj.sampleSizeCritical) {
          changeToMax = false;
          //Do not change the value of this.packageCheckObj.sampleSizeCritical
        } else if (selectedValue === 'n/a' || selectedValue === 'not allowed') {
            this.packageCheckObj.sampleSizeCritical = 0;
        } else if (aqlCriticalSamplingPlan === 0) {
          this.packageCheckObj.sampleSizeCritical = zeroAQL;
        } else {
          this.packageCheckObj.sampleSizeCritical = aqlCriticalSamplingPlan;
        }
      } else {
        this.packageCheckObj.sampleSizeCritical = null;
      }

      if (aqlMinorSelected != null) {
        const selectedValue = aqlMinorSelected.toLowerCase();
        if(isInitialLoad && this.packageCheckObj.sampleSizeMinor) {
          changeToMax = false;
          //Do not change the value of this.packageCheckObj.sampleSizeMinor
        } else if (selectedValue === 'n/a' || selectedValue === 'not allowed') {
          this.packageCheckObj.sampleSizeMinor = 0;
        } else if (aqlMinorSamplingPlan === 0) {
          this.packageCheckObj.sampleSizeMinor = zeroAQL;
        } else {
          this.packageCheckObj.sampleSizeMinor = aqlMinorSamplingPlan;
        }
      } else {
        this.packageCheckObj.sampleSizeMinor = null;
      }

      if (aqlMajorSelected != null) {
        const selectedValue = aqlMajorSelected.toLowerCase();
        if(isInitialLoad && this.packageCheckObj.sampleSizeMajor) {
          changeToMax = false;
          //Do not change the value of this.packageCheckObj.sampleSizeMajor
        } else if (selectedValue === 'n/a' || selectedValue === 'not allowed') {
          this.packageCheckObj.sampleSizeMajor = 0;
        } else if (aqlMajorSamplingPlan === 0) {
          this.packageCheckObj.sampleSizeMajor = zeroAQL;
        } else {
          this.packageCheckObj.sampleSizeMajor = aqlMajorSamplingPlan;
        }
      } else {
        this.packageCheckObj.sampleSizeMajor = null;
      }

      /*if (changeToMax){
        var list = [];
        list.push(this.packageCheckObj.sampleSizeCritical);
        list.push(this.packageCheckObj.sampleSizeMinor);
        list.push(this.packageCheckObj.sampleSizeMajor);
        var maxValue = Math.max(...(list));

        const aqlCritical = aqlCriticalSelected.toLowerCase();
        const aqlMinor = aqlMinorSelected.toLowerCase();
        const aqlMajor = aqlMajorSelected.toLowerCase();

        if (aqlCriticalSelected != null && aqlCritical !== 'n/a' && aqlCritical !== 'not allowed'){
          this.packageCheckObj.sampleSizeCritical = maxValue;
        }
        if (aqlMinorSelected != null && aqlMinor !== 'n/a' && aqlMinor !== 'not allowed'){
          this.packageCheckObj.sampleSizeMinor = maxValue;
        }
        if (aqlMajorSelected != null && aqlMajor !== 'n/a' && aqlMajor !== 'not allowed'){
          this.packageCheckObj.sampleSizeMajor = maxValue;
        }
      }*/

      if (this.packageCheckObj.isVaryingAQL) {
        if(isInitialLoad && this.packageCheckObj.crtnSampleSize) {
          //Do not change the value of this.packageCheckObj.crtnSampleSize
        } else if (hasNoAQL) {
          this.packageCheckObj.crtnSampleSize = zeroAQL;
        } else {
          this.packageCheckObj.crtnSampleSize = Math.max(this.packageCheckObj.sampleSizeCritical, this.packageCheckObj.sampleSizeMinor, this.packageCheckObj.sampleSizeMajor);
        }
      } else {
        this.packageCheckObj.crtnSampleSize = zeroAQL;
      }
    },
    changeFormToDirty(){
      this.isFormDirty = true;
    },
    isWalmartOrAsda() {
      return this._workOrderDetails?.clientName.toLowerCase() === 'walmart global sourcing' || this._workOrderDetails?.clientName.toLowerCase() === 'asda stores limited'
    }
  },
  computed: {
    ...mapState("inspection", {
      store_token: (state) => state.token,
      myAuthorized: (state) => state.myAuthorized,
      _sectionData: (state) => state.sectionData,
      isQVListUpdated: (state) => state.qvListUpdated,
      _workOrderDetails: (state) => state.workOrderDetails,
      inspectionSummarySectionResult: (state) => state.inspectionSummarySectionResult,
      _samplingPlanLists: (state) => state.samplingPlanLists,
    }),

     showHeadersPackageCheck() {
      if(this.packageCheckObj.isVaryingAQL && this.packageCheckObj.samplingMethodology  == "Combined Sample Size" ){
        return this.packageHeaderAQLCombined;
      }
      if (this.packageCheckObj.isVaryingAQL) {
        return this.packageHeaderAQL;
      }
      return this.packageHeader;
    },
    overallResultIndicator() {
      let productsCount = this.workOrderProducts.length;
      let packageCheck = this.workOrderProducts.filter((x) => x.workOrderProductResults && x.workOrderProductResults.pkgCheckResult).map((x) => x.workOrderProductResults.pkgCheckResult);

      if (packageCheck === null) return null;
      return packageCheck.filter((x) => +x === 1).length === productsCount ? 1 : 0;
    },

    isSectionDisabled() {
      return this.workOrder.resources.length == 0 || (this.workOrder.resources.length > 1 && this.sectionDetail.resourceId.toLowerCase() != this.utilityFn.getResourceId().toLowerCase()) || this.sectionDetail.isHidden || (!!this.sectionDetail.submittedDate && this.workOrder.resources.length > 1);
    },

    showButton() {
      return this.workOrder.resources.length > 1 && this.sectionDetail && this.sectionDetail.resourceId == this.utilityFn.getResourceId().toLowerCase();
    },

    _productQuantityVerificationList() {
      const list = [];
      this.productQuantityVerificationList.forEach((item) => {
        const index = list.findIndex((x) => x.productID === item.productID);
        // check if product is already in the list
        if (index === -1) {
          list.push(item);
        } else if (index !== -1) {
          if (list[index].po === item.po) {
            list[index].availableQuantityProduct = list[index].availableQuantityProduct + item.availableQuantityProduct;
            list[index].qty = list[index].qty + item.qty;
            list[index].availableQuantityCarton = list[index].availableQuantityCarton + item.availableQuantityCarton;
          } else {
            list.push(item);
          }
        }
      });

      return list;
    },

    keepLogoLabelTabActive() {
      let sectionHasValues = false;

      for (let subSection of this.logoLabelSubSections) {
        if (subSection.reason != null || subSection.remarks != null || subSection.result != null) {
          sectionHasValues = true;
          break;
        }
      }

      return this.tab === "tab-4" && this.logoLabelShowHide && (!this.logoLabelValid || this.logoLabelResult == null) && sectionHasValues;
    },

    sectionDetail() {
      let section = this._workOrderDetails.workOrderInspectionSections.find(x => x.sectionId == this.sectionId);
        if (section) {
            section.submitterId = this.utilityFn.getResourceId().toLowerCase();
        } else {
            section = {
                workOrderId: this.workOrder.workOrderId,
                sectionId: this.sectionId,
                isHidden: false,
                submitterId: this.utilityFn.getResourceId().toLowerCase()
            };
        }

        return section;
    },

    sectionOverAllResult() {
      const packageResult = this.select_result.find((x) => x.value == this.packageCheckObj.pkgCheckOverallRslt);
      const packingAndAssormentResult = this.select_result.find((x) => x.value == this.resultForPackingAndAssortment);
      const packingAndMarkingResult = this.select_result.find((x) => x.value == this.resultForPackingAndMarking);
      const logoLabelResult = this.select_result.find((x) => x.value == this.logoLabelResult);
      console.log(packageResult);
      let result = "";
      if (packageResult?.text == null && packingAndAssormentResult?.text == null && packingAndMarkingResult?.text == null && logoLabelResult?.text == null) {
            result = "";
          } else {
            if (packageResult?.text == "Fail" || packingAndAssormentResult?.text == "Fail" || packingAndMarkingResult?.text == "Fail" || logoLabelResult?.text == "Fail") {
              result = "Non-Conformed";
            } else {
              if (packageResult?.text == "Pending" || packingAndAssormentResult?.text == "Pending" || packingAndMarkingResult?.text == "Pending" || logoLabelResult?.text == "Pending") {
                result = "Pending";
              } else {
                if (packageResult?.text == "Pass" || packingAndAssormentResult?.text == "Pass" || packingAndMarkingResult?.text == "Pass" || logoLabelResult?.text == "Pass") {
                  result = "Conformed";
                } else {
                  if (packageResult?.text == "N/A" || packingAndAssormentResult?.text == "N/A" || packingAndMarkingResult?.text == "N/A" || logoLabelResult?.text == "N/A") {
                    result = "N/A";
                  } else {
                    result = "";
                  }
                }
              }
            }
          }
          console.log(result);
      return result == "" ? null : result;
    },

    /**
     * @returns List of errors base on the conditions
     */
    errorsPackageCheckTab() {
      const _errors = [];
      this.workOrderProducts.forEach((wop, index) => {
        const atIndex = " at index " + (index + 1);
        // Package Check
        if (wop.workOrderProductResults.pkgCheckResult == null) {
          _errors.push("Invalid Result " + atIndex);
        }

        if (this.packageCheckObj.isVaryingAQL && (this.packageCheckObj.ilPkgCheck == "Fixed" || this.packageCheckObj.ilPkgCheck == "Square Root") && this.packageCheckObj.samplingMethodology == "Separated Sample Size") {
          if (!wop.workOrderProductResults.pkgCheckAqlCriticalAccept) {
            _errors.push("Invalid Critical Accept" + atIndex);
          }
          if (!wop.workOrderProductResults.pkgCheckAqlMajorAccept) {
            _errors.push("Invalid Major Accept" + atIndex);
          }
          if (!wop.workOrderProductResults.pkgCheckAqlMinorAccept) {
            _errors.push("Invalid Minor Accept" + atIndex);
          }
          if (!wop.workOrderProductResults.pkgCheckAqlCriticalReject) {
            _errors.push("Invalid Critical Reject" + atIndex);
          }
          if (!wop.workOrderProductResults.pkgCheckAqlMajorReject) {
            _errors.push("Invalid Major Reject" + atIndex);
          }
          if (!wop.workOrderProductResults.pkgCheckAqlMinorReject) {
            _errors.push("Invalid Minor Reject" + atIndex);
          }
        }
      });

      return _errors;
    },
    errorsPackingAssortmentTab() {
      const _errors = [];
      this.workOrderProducts.forEach((wop, index) => {
        const atIndex = " at index " + (index + 1);
        // Packing and Assortment
        if (wop.workOrderProductResults.packingAssortmentResult == null) {
          _errors.push("Invalid Result " + atIndex);
        }
      });

      return _errors;
    },
    errorsPackagingMarkingTab() {
      const _errors = [];
      this.workOrderProducts.forEach((wop, index) => {
        const atIndex = " at index " + (index + 1);
        // Packaging and Marking
        if (wop.workOrderProductResults.packagingMarkingResult == null) {
          _errors.push("Invalid Result " + atIndex);
        }
      });

      return _errors;
    },

    // will be a flag for the submit form in the footer
    isDataLoaded() {
      return this.workOrderProducts.length > 0 && !this.isLoadingItems;
    },

    resultLabel(){
      return this.isWalmartOrAsda() && this._workOrderDetails.isPreShipOrFinalRandom ? "Result *" : "Result";
    },

    inspectionLevelLabel(){
      return this.isWalmartOrAsda() && this._workOrderDetails.isPreShipOrFinalRandom ? "Inspection Level *" : "Inspection Level";
    },
    cartonSampleSizeLabel(){
      return this.isWalmartOrAsda() && this._workOrderDetails.isPreShipOrFinalRandom ? "Carton Sample SIze *" : "Carton Sample SIze";
    },
    
    
  },

  watch: {
    logoLabelSubSections: {
      handler(value) {
        this.$nextTick(() => {
          this.triggerFormValidation();
          this.isFormDirty = this.isFormReadyCheck;
        });
      },
      deep: true,
    },
    tab(val) {
      if (val === "tab-4") {
        this.$nextTick(() => {
          this.triggerFormValidation();
        });
      }
    },

    workOrderProducts: {
      handler(val) {
        if (this.$refs.pkgCheckAqlMajorAccept) {
          this.$refs.pkgCheckAqlMajorAccept[0].validate();
        }

        // compute total for found in aql summary
        let totalFoundCritical = 0;
        let totalFoundMajor = 0;
        let totalFoundMinor = 0;  
        if (this.packageCheckObj.isVaryingAQL && this.packageCheckObj.samplingMethodology === "Combined Sample Size") {
          totalFoundCritical = this.workOrderProducts.reduce(function(tot, item) {
            return tot + item.workOrderProductResults.pkgCheckAqlCriticalFound;
          }, 0);

          totalFoundMajor = this.workOrderProducts.reduce(function(tot, item) {
            return tot + item.workOrderProductResults.pkgCheckAqlMajorFound;
          }, 0);

          totalFoundMinor = this.workOrderProducts.reduce(function(tot, item) {
            return tot + item.workOrderProductResults.pkgCheckAqlMinorFound;
          }, 0);
        }

        this.packageCheckObj.foundCritical = totalFoundCritical;
        this.packageCheckObj.foundMajor = totalFoundMajor;
        this.packageCheckObj.foundMinor = totalFoundMinor;


        this.isFormDirty = this.isFormReadyCheck;
      },
      deep: true,
      immediate: true
    },

    isDataLoaded(val1, val2) {
      setTimeout(() => {
        this.isFormReadyCheck = true;
      });
    },

    packageCheckObj: {
      handler(val1, val2) {
        this.isFormDirty = this.isFormReadyCheck;
      },
      deep: true
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.keepLogoLabelTabActive) {
      next(false);
    } else if (!this.isDateEntryValid) {
      this.snackbar = true;
      this.message = "Must be a number";
      next(false);
    } else {
      if(to.path !== '/error')
      {
        if(!this.loadingDf) {
          this.saveData().then(() => {});
        }
      }
     
      this.logChanges(false);

      next(true);
    }
  },
};
</script>

<style lang="stylus" scoped>
.workOrderInspectionPackageId
  padding 15px 10px

.tab_item
  padding 25px 15px
  overflow hidden
  position relative

.form_box
  padding 25px 15px
  background #fff

.error--text
  color #ff5252 !important
  caret-color #ff5252 !important
>>>.number_text input {
  text-align: center;
}
.submit-btn
  color: #fff !important
.del_button
  position: absolute;
  right: -3px;
  top: -5px;

.code-container {
  display: flex;
  flex-direction: column;
}

.helper-container {
  margin-bottom: -18px;
  margin-top: -18px;
  margin-left: 75px;
}
</style>