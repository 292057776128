<template>
  <div>
    <v-overlay :value="loadingDf"></v-overlay>
    <v-snackbar class="snackbar-loading" v-model="loadingDf" top :timeout="0" color="#4caf50">
      <v-progress-circular :size="15" indeterminate color="primary"></v-progress-circular>
      <span style="padding-left:10px;">Loading</span>
    </v-snackbar>
    <div class="workOrderDetailsId">
      <v-tabs v-model="tab" dark grow background-color="#00518A" style="position: fixed;z-index: 20;top: 50px;" >
        <v-tab v-for="(item, index) in dynamicForm.formTabs" :key="index" :href="'#tab-' + index" :auto-id="'General'+index">{{ item.name }}</v-tab>
        <v-tab href="#tab-3" auto-id="Onsite Observatin">Onsite Observation</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" style="width: 96%; margin: 70px auto; background: transparent;">
        <v-tab-item v-for="(item, index) in dynamicForm.formTabs" :key="index" :value="'tab-' + index" eager>
          <v-expansion-panels multiple v-model="generalPanels[index]">
            <v-expansion-panel v-for="(tabItem, index) in formTabSections" :key="index">
              <!-- <v-expansion-panel-header v-slot="{ open }" :class="isDynamicSectionAuthorized(tabItem.name)? 'isAssigned': 'isNotAssigned'"> -->
              <!-- <v-expansion-panel-header v-slot="{ open }" :class="tabItem.isAuthorized? 'isAssigned': 'isNotAssigned'"> -->
              <v-expansion-panel-header v-slot="{ open }" :class="isDynamicSectionAuthorized(tabItem.name) ? 'isAssigned' : 'isNotAssigned'">
                <v-row no-gutters>
                    <v-col cols="5" class="tab_title" :auto-id = "tabItem.name">{{ tabItem.name }}</v-col>
                    <v-col v-if="fromCanyon && tabItem.name == 'Inspection Time'" cols="3"  @click.stop>
                    <v-select :items="generalData.resources" v-model="inspectionTimeForResourceModel" item-text="fullName" item-value="resourceId" @change="resetInspectionTimeCount()" :auto-id="'selectModel'+index">

                    </v-select>
                  </v-col>
                  <v-col v-if="!fromCanyon || (fromCanyon && tabItem.name != 'Inspection Time')" cols=3></v-col>
                    <v-col cols="2" style="text-align:right;padding-right:10px;">
                    <span v-if="isDynamicSectionEnabled(tabItem.name) && tabItem.showSave" @click.stop="dynamicSectionSaveClick('section'+tabItem.id)" :auto-id="'dynamic section save'+index">
                      <icon name="save"></icon>
                    </span>
                  </v-col>
                  <v-col cols="2" style="text-align:right;padding-right:10px;">
                    <span v-if="isDynamicSectionEnabled(tabItem.name)" @click.stop="go_upload_photo(tabItem.name, tabItem.id)" :auto-id="'dynamic section camera'+index">
                      <icon name="camera"></icon>
                    </span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <div style="position: relative;">
                  <div
                    :style="
                      !isDynamicSectionEnabled(tabItem.name)
                        ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop'
                        : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                    "
                  ></div>
                  <v-row>
                    <v-col v-if="generalData.resources.length > 1 && tabItem.name != 'Inspection Time' && tabItem.name != 'Reference Documents / Client Technical Documents' && isDynamicSectionAuthorized(tabItem.name)" cols="12 " sm="12" md="12" style="text-align:right;">
                      <v-btn :disabled="!isDynamicSectionSubmitted(tabItem.name)" class="submit-btn" @click="submit_item(tabItem.name)" small depressed color="#1867c0" :auto-id="'dynamic submit button'+index">Submit</v-btn>
                    </v-col>
                  </v-row>
                  <v-col v-if="tabItem.name == 'Inspection Time' && generalData.workOrderId">
                      <InspectionTime 
                      v-for="(count,index) in InspectionTimeCount" :key="count" 
                      @UpdateInspectionTimeCount="updateInspectionTimeCount"
                      :workOrderId="generalData.workOrderId" 
                      :clientName="generalData.clientName" 
                      :resourceId="resourceIdForInspectionTime()"
                      :day="`Day ${index+1}`"
                      :inspectionTimeCount="InspectionTimeCount.length"
                      :indexNumber= count>
                      </InspectionTime>
                  </v-col>
                  <v-col v-if="tabItem.name == 'Additional Information' && isAdditionalInformationTrue">
                    <AdditionalInformation :hideButton="Sections.hideButton" :isAuthorized="isSectionAuthorized(Sections.AdditionalInformation.id)" :isSectionSubmitted="isSectionSubmitted(Sections.AdditionalInformation.id)"></AdditionalInformation>
                  </v-col>
                  <div v-if="tabItem.name != 'Inspection Time' && tabItem.name != 'Additional Information'">
                    <v-row v-for="(rowItem, index) in tabItem.rows" :key="index">
                      <v-col v-for="(item, index) in rowItem.formAtoms" :key="index" cols="12" :sm="item.columnSize" v-if="item.columnSize > 0 || item.controlType == 'hidden'">
                        <field :tabItemRows="tabItem.rows" :msgData="item" :msgFormAtoms="rowItem.formAtoms" :msgConditionFieldData="conditionalFieldValues" @childToParentConditionalField="onchildToParentConditionalField" :sectionName="tabItem.name" :ref="'section' + tabItem.id"></field>
                      </v-col>
                    </v-row>
                  </div>
                  <v-row>
                    <v-col cols="12" sm="12" md="12" v-if="tabItem.name == 'Supplier/Site' && generalData.siteCountry == 'China'">
                      <baidu-map class="map" :center="generalData.localName" @ready="handler" :zoom="12" :scroll-wheel-zoom="true">
                        <bm-local-search :keyword="generalData.localName" :auto-viewport="true" zoom="12.8" style="display: none"></bm-local-search>
                      </baidu-map>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="tabItem.name == 'Supplier/Site' && generalData.siteCountry != 'China'">
                      <div id="allmap" ref="allmap" style="width:100%; height:400px;"></div>
                    </v-col>
                  </v-row>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-tab-item>
        <v-tab-item value="tab-3">
          <v-expansion-panels multiple v-model="onsitePanels">
            <v-expansion-panel>
              <v-expansion-panel-header id="onsite_observation_product" :class="isSectionAuthorized(Sections.Product.id) ? 'isAssigned' : 'isNotAssigned'" auto-id="onsite observation product">
                <v-row no-gutters>
                  <v-col cols="10" class="tab_title">Product</v-col>
                  <v-col cols="2" style="text-align:right;padding-right:10px;">
                    <div style="position: relative;">
                      <span v-if="isSectionAuthorized(Sections.Product.id)" @click.stop="go_upload_photo_generic('Product', 10, isSectionSubmitted(Sections.Product.id))" :auto-id="'onsite observation product camera'">
                        <icon name="camera"></icon>
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div style="position: relative;">
                  <div
                    :style="
                      !isSectionEnabled(Sections.Product.id)
                        ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop'
                        : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                    "
                  ></div>
                  <product :generalData="generalData" :isAuthorized="isSectionAuthorized(Sections.Product.id)"></product>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header id="onsite_observation_stockinspected" :class="isSectionAuthorized(Sections.StockInspected.id) ? 'isAssigned' : 'isNotAssigned'" auto-id="onsite observation stock inspected">
                <v-row no-gutters>
                  <v-col cols="10" class="tab_title">Stock Inspected</v-col>
                  <v-col cols="2" style="text-align:right;padding-right:10px;">
                    <div style="position: relative;">
                      <span v-if="isSectionAuthorized(Sections.StockInspected.id)" @click.stop="go_upload_photo_generic('Stock Inspected', Sections.StockInspected.id, isSectionSubmitted(Sections.StockInspected.id))" auto-id="onsite observation stock inspected camera">
                        <icon name="camera"></icon>
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div style="position: relative;">
                  <div
                    :style="
                      !isSectionEnabled(Sections.StockInspected.id)
                        ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop'
                        : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                    "
                  ></div>
                  <stock-inspected :generalData="generalData" :isAuthorized="isSectionAuthorized(Sections.StockInspected.id)"></stock-inspected>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header id="onsite_observation_remarksandcomments" :class="isSectionAuthorized(Sections.RemarksComments.id) ? 'isAssigned' : 'isNotAssigned'" auto-id="onsite observation remarks and comments">
                <v-row no-gutters>
                  <v-col cols="10" class="tab_title">Remarks & Comments</v-col>
                  <v-col cols="2" style="text-align:right;padding-right:10px;">
                    <div style="position: relative;">
                      <span v-if="isSectionAuthorized(Sections.RemarksComments.id)" @click.stop="go_upload_photo_generic('Remarks and Comments', Sections.RemarksComments.id, isSectionSubmitted(Sections.RemarksComments.id))" auto-id="onsite observation remarks and comments camera">
                        <icon name="camera"></icon>
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div style="position: relative;">
                  <div
                    :style="
                      !isSectionEnabled(Sections.RemarksComments.id)
                        ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop'
                        : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                    "
                  ></div>
                  <dynamic-form-remarks :generalData="generalData" :isAuthorized="isSectionAuthorized(Sections.RemarksComments.id)"> </dynamic-form-remarks>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header :class="isSectionAuthorized(Sections.InspectionSummary.id, true) ? 'isAssigned' : 'isNotAssigned'" auto-id="onsite observation inspection summary">
                <v-row no-gutters>
                  <v-col cols="10" class="tab_title">Inspection Summary</v-col>
                  <v-col cols="2" style="text-align:right;padding-right:10px;">
                    <div style="position: relative;">
                      <span v-if="isSectionAuthorized(Sections.InspectionSummary.id, true)" @click.stop="go_upload_photo_generic('Inspection Summary', Sections.InspectionSummary.id, isSectionSubmitted(Sections.InspectionSummary.id, true))" auto-id="onsite observation inspection summary camera">
                        <icon name="camera"></icon>
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <inspection-summary :generalData="generalData" :isAuthorized="get_inspection_item_authorized()" :SectionListValue="sectionList" :inspectors="generalData.resources" :sectionData="Sections"></inspection-summary>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header :class="isSectionAuthorized(Sections.Others.id, true) ? 'isAssigned' : 'isNotAssigned'" auto-id="onsite observation others">
                <v-row no-gutters>
                  <v-col cols="10" class="tab_title">Others</v-col>
                  <v-col cols="2" style="text-align:right;padding-right:10px;">
                    <div style="position: relative;">
                      <span v-if="isSectionAuthorized(Sections.Others.id, true)" @click.stop="go_upload_photo_generic('Others', Sections.Others.id, isSectionSubmitted(Sections.Others.id, true))" auto-id="onsite observation others camera">
                        <icon name="camera"></icon>
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div style="position: relative;">
                  <div
                    :style="
                      !isSectionAuthorized(Sections.Others.id, true)
                        ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop'
                        : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                    "
                  ></div>
                  <other v-if="generalData.workOrderId" :generalData="generalData"></other>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header auto-id="onsite observation overall result">
                <v-row no-gutters>
                  <v-col cols="10" class="tab_title">Overall Result</v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <overall-result v-if="generalData.workOrderId" :workOrder="generalData"></overall-result>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <v-snackbar v-model="snackbarError" :timeout="timeoutError" :color="colorError">
      {{ messageError }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import InspectionTime from "../../components/inspection/InspectionTime";
import Field from "../../components/forms/FormBuild";
import Product from "../../components/workorder/Product";
import StockInspected from "../../components/workorder/StockInspected";
import DynamicFormRemarks from "../../components/forms/DynamicFormRemarks";
import InspectionSummary from "../../components/workorder/WoInspectionSummary";
import Other from "../../components/workorder/Other";
import auditLog from "../../shared/request/auditLogs";
import OverallResult from "@/components/workorder/OverallResult";
import AdditionalInformation from '@/components/workorder/AdditionalInformation.vue';

let state = {
  appHeaderState: {
    show: true,
    title: "Work Order:8911",
    showMenu: true,
    showBack: true,
    showLogo: false,
    showTitle: true,
    showSearch_job: false,
    showSearch_work: false,
    showCalendar: true,
  },
};

function setState(store) {
  store.dispatch("navigation/setAppHeader", state.appHeaderState);
}

export default {
  name: "workOrderDetailsId",
  components: {
    InspectionTime,
    Field,
    Product,
    StockInspected,
    DynamicFormRemarks,
    InspectionSummary,
    Other,
    OverallResult,
    AdditionalInformation
  },
  data() {
    return {
      snackbarError: false,
      messageError: "Please select the Sampling Methodology.",
      colorError: "red darken-2",
      timeoutError: 3000,
      loadingDf: false,
      isSelfInspection: false,
      conditionalFieldValues: [],
      tab: null,
      tabSection: 0,
      // 动态表单部分数据
      dynamicForm: [],

      // onsite 部分的数据
      generalData: {
        clientName: "",
        workOrderDisplayID: "",
        destination: "",
        shipmentQty: "",
        sampleSize: "",
        authority: {},
        resources: [],
      },
      productQuantityVerificationList: [],
      workOrderProducts: [],
      samplingPlan: {
        SKUSamplingList: [
          {
            id: "",
            workOrderID: "",
            productId: "",
            SKUDisplay: "",
            PO: "",
            productQty: 0,
            cartonQty: 0,
            productProRata: 0,
            cartonProRata: 0,
            productSampleSize: 0,
            cartonSampleSize: 0,
            actualProductSampleSize: 0,
            actualCartonSampleSize: 0,
            AC: 0,
            RE: 0,
          },
        ],
        totalProductPOQty: 0,
        totalProductSampleSize: 0,
        totalCartonPOQty: 0,
        totalCartonSampleSize: 0,
      },

      // 默认地图地址
      supplierSiteAddress: "深圳",

      Sections: {
        QuantityVerification: { id: 1, name: "Quantity Verification", isAuthorized: false, isSectionSubmitted: false },
        SizeMeasurement: { id: 2, name: "Functional Check Measurement", isAuthorized: false, isSectionSubmitted: false },
        PackageCheck: { id: 3, name: "Package Check", isAuthorized: false, isSectionSubmitted: false },
        BarcodeVerification: { id: 4, name: "Barcode Verification", isAuthorized: false, isSectionSubmitted: false },
        Workmanship: { id: 5, name: "Workmanship", isAuthorized: false, isSectionSubmitted: false },
        OnsiteTests: { id: 6, name: "Onsite Tests", isAuthorized: false, isSectionSubmitted: false },
        General: { id: 7, name: "General", isAuthorized: false, isSectionSubmitted: false },
        SupplierSite: { id: 8, name: "Supplier/Site", isAuthorized: false, isSectionSubmitted: false },
        Sampling: { id: 9, name: "Sampling", isAuthorized: false, isSectionSubmitted: false },
        Product: { id: 10, name: "Product", isAuthorized: false, isSectionSubmitted: false },
        StockInspected: { id: 11, name: "Stock Inspected", isAuthorized: false, isSectionSubmitted: false },
        RemarksComments: { id: 12, name: "Remarks and Comments", isAuthorized: false, isSectionSubmitted: false },
        CDF: { id: 13, name: "CDF Listed Component Verification Report", isAuthorized: false, isSectionSubmitted: false },
        SerialNumberSampled: { id: 14, name: "Serial Number Sampled", isAuthorized: false, isSectionSubmitted: false },
        SampleCartonNumber: { id: 15, name: "Sample Carton Number", isAuthorized: false, isSectionSubmitted: false },
        OtherAttachments: { id: 20, name: "Other Attachments", isAuthorized: false },
        InspectionSummary: { id: 16, name: "Inspection Summary" },
        Others: { id: 17, name: "Others" },
        AdditionalInformation: {id: 25, name: "Additional Information", isAuthorized: false, isSectionSubmitted:false}        
      },

      onsiteSections: {
        Product: 0,
        StockInspected: 1,
        RemarksComments: 2,
        InspectionSummary: 3,
        Others: 4,
      },
      onsitePanels: [5],

      generalPanels: [],
      InspectionTimeCount: [],
      requiredFieldList: [],
      fromCanyon: false,
      inspectionTimeForResourceModel: null,
      isAdditionalInformationTrue: false
    };
  },
  computed: {
    ...mapState("inspection", {
      // 我的权限列表
      myAuthorized: (state) => state.myAuthorized,
      workOrderDetails: (state) => state.workOrderDetails,
      _samplingPlanLists: (state) => state.samplingPlanLists,
      formAtomsList: (state) => state.formAtomsList,
      currentActiveWorkOrder: (state) => state.currentActiveWorkOrder,
      dynamicFormForceRefresh: (state) => state.dynamicFormForceRefresh,
      retailMarketCdf: (state) => state.retailMarketCdf
    }),

    assignedSections() {
      return this.sectionList.filter((x) => x.resourceId && x.resourceId?.toLowerCase() === this.utilityFn.getResourceId().toLowerCase());
    },

    sectionList() {
      return this.workOrderDetails ? this.workOrderDetails.workOrderInspectionSections : []; 
    },

    formTabSections(){
      let hasInspectionTime= false;
      let copyFormTabSections = [];
      this.dynamicForm.formTabs.forEach(tab => {
        tab.formTabSections.filter((x) => {
          if (x.name =='Inspection Time'){
            hasInspectionTime = true;
          }
          copyFormTabSections.push(x);
          
          if (x.name == 'Additional Information' && !this.isAdditionalInformationTrue){
            copyFormTabSections.pop();
          }

          if (x.name == 'General'){
            x.rows.forEach(row => {
              row.formAtoms.forEach(formAtom => {
                  if (formAtom.label == 'Retail Market'){
                    formAtom.values.forEach(value => {
                      this.$store.dispatch("inspection/setRetailMarketCDF", value.value);
                    })
                  }
              })
            });
          }
        });
      });

      if (!hasInspectionTime)
      {
        copyFormTabSections.unshift({id:0,name:'Inspection Time'});
      }

      return copyFormTabSections
    }

  },
  activated() {
    // check if current opened workorder is the same from the previous loaded workorder,
    // if not then call api to load the correct workorder details
    if (!this.currentActiveWorkOrder || this.currentActiveWorkOrder.workOrderId !== this.$route.params.id || this.dynamicFormForceRefresh) {  
      this.$store.dispatch("inspection/setDynamicFormForceRefresh", false);
      this.dynamicForm = [];
      this.loadData();
      this.InspectionTimeCount = [];
      if (this.InspectionTimeCount.length == 0){
        this.InspectionTimeCount.push(1);
      }
    }
    setState(this.$store);
  },

  mounted() {
    this.loadData();
    if (this.$route.query.fromCanyon !== undefined){
      localStorage.setItem('fromCanyon',true);
      this.fromCanyon = true;
    }
  },
  methods: {
    async loadData() {
      if (this.loadingDf)
        return

      try {
        this.loadingDf = true;
        await Promise.all ([this.getAndSetCompanySubscriptionByWorkOrderId()]);
        await Promise.all([this.loadDynamicFormData(), this.loadSelfInspection(), this.loadWorkOrderDetails()]);
        
        // load only after dynamic form and work order data has been loaded properly
        this.search_map(this.generalData.localAddress);
      } catch (e) {
        console.log('An error occured during loading of workOrder details', e);
      } finally {
        this.loadingDf = false;
      }
    },

    async loadDynamicFormData() {
      const result = await this.utilityFn.getServerDataAsync("get", this.apiList.dynamicFormData + "/" + this.$route.params.id, {});
      this.dynamicForm = result.data;

      let requiredFields = []
      result.data.formTabs.forEach(tab => {
        tab.formTabSections.forEach(section => {
          section.rows.forEach(row => {            
            row.formAtoms.forEach(atom => {
              if(atom.isRequired || atom.label === 'Failure Reason' || atom.label === 'Pending Reason') {
                requiredFields.push(atom);
                this.requiredFieldList.push({
                  id: atom.formAtomID,
                  sectionName: section.name,
                  label: atom.label,
                  requiredErrorText: atom.requiredErrorText
                })
              }
            });
          });
        });
      });
      this.$store.dispatch("inspection/setFormAtomsList", requiredFields);
      this.setDefaultLandingSectionAndTab();
      //this.loadGeneralSections();
    },

    async loadSelfInspection() {
      const result = await this.utilityFn.getServerDataAsync("get", this.apiList.isSelfInspection + "/" + this.$route.params.id, {});
      this.isSelfInspection = result.data;
    },

    async loadWorkOrderDetails() {
      const result = await this.utilityFn.getServerDataAsync("get", this.apiList.workOrderDynamicForm + "/" + this.$route.params.id, {});
      this.generalData = result.data;

      if (!this.isAdditionalInformationTrue){
          var addInfoSectionIndex = this.generalData.workOrderInspectionSections.findIndex(x => x.sectionName == 'Additional Information');
          if (addInfoSectionIndex != -1){
            this.generalData.workOrderInspectionSections.splice(addInfoSectionIndex,1);
          }
      }

      this.$store.dispatch("inspection/setWorkOrderDetails", this.generalData);
      this.$store.dispatch("inspection/setCurrentActiveWorkOrder", this.generalData);
      this.$store.dispatch("inspection/setInspectionSummaryResult", this.generalData.workOrderInspectionSections);
      
      // If work instruction is still null, redirect to the assignment/workinstruction page
      if (!this.generalData.workInstruction) {
        this.$router.push({ name: "assignmentId", params: { id: this.generalData.workOrderId, workOrderDisplayId: this.generalData.workOrderDisplayId, isDynamic: !!this.generalData.formId, workInstruction: this.generalData.workInstruction } });
      }

      this.workOrderProducts = this.generalData.products;

      this.skuDisplayList = this.generalData.products.map((x) => {
        return {
          workOrderProductId: x.id,
          skuDisplay: x.skuDisplay,
          po: x.po,
          cartonQty: x.cartonQty,
          qty: x.qty,
          caseCountProduct: x.casePackage,
          productID: x.productID,
        };
      });

      this.initQuantityVerification();
      // this.sectionList = this.generalData.workOrderInspectionSections;

      this.get_authorized_list(this.generalData.workOrderInspectionSections);

      this.$store.dispatch("inspection/setClientDefectsLoadingStatus", 0);
      const resultDefect = await this.utilityFn.getServerDataAsync("get", this.apiList.getClientDefectList + "/" + this.generalData.clientId, { dclTypeId: null, isActive: true })
      this.clientDefectList = resultDefect.data;
      this.$store.dispatch("inspection/setClientDefectList", this.clientDefectList);
      this.$store.dispatch("inspection/setClientDefectsLoadingStatus", 1);

      this.loadSections();

      state.appHeaderState.showSubmitFormButton = this.generalData.status === "Rework";
      state.appHeaderState.title = "Work Order:" + this.generalData.workOrderDisplayId;
      setState(this.$store);
  
      this.setLoggingInfo();
      if (window.navigator.onLine !== true) {
        this.tab = "tab-0";
        this.tabSection = 0;
      }
    },


    clickOnsiteObservation() {
      if(this._samplingPlanLists.samplingMethodology=="Combined Sample Size"){
        this.snackbarError=true;
      }
    },
    onchildToParentConditionalField({ formAtomID, value }) {
      if (this.conditionalFieldValues && this.conditionalFieldValues.length > 0) {
        this.conditionalFieldValues = this.conditionalFieldValues.filter((x) => x.formAtomID != formAtomID);
      }
      this.conditionalFieldValues.push({ formAtomID, value });
    },
    go_upload_photo_generic(type, sectionid, isSectionSubmitted) {
      if (this.generalData.resources.length === 1 || !isSectionSubmitted) {
        this.$router.push({ name: "uploadPhotoGalleryId", params: { id: this.$route.params.id, name: type, sectionId: sectionid } });
      }
    },
    go_upload_photo(type, sectionid, isSectionSubmitted) {
      if (type == "General" && !this.Sections.General.isSectionSubmitted) {
        this.$router.push({ name: "uploadPhotoGalleryId", params: { id: this.$route.params.id, name: type, sectionId: this.Sections.General.id } });
        return;
      } else if (type.indexOf("Supplier") !== -1 && !this.Sections.SupplierSite.isSectionSubmitted) {
        this.$router.push({ name: "uploadPhotoGeneralId", params: { id: this.$route.params.id } });
        return;
      } else if (type == "Sampling" && !this.Sections.Sampling.isSectionSubmitted) {
        this.$router.push({ name: "uploadPhotoGalleryId", params: { id: this.$route.params.id, name: type, sectionId: this.Sections.Sampling.id } });
        return;
      } else {
        this.$router.push({ name: "uploadPhotoGalleryId", params: { id: this.$route.params.id, name: type, sectionId: sectionid } });
        return;
      }
    },
    // 获取当前用户的权限列表
    get_authorized_list(authorizedLis) {
      authorizedLis.forEach((x) => {
        var data = {};
        if (x.resourceId == this.utilityFn.getResourceId().toLowerCase()) {
          data.id = x.sectionId;
          data.read = true;
          if (x.submittedDate == null) {
            data.write = true;
          } else {
            data.write = false;
          }
          this.$store.dispatch("inspection/setMyAuthorized", data);
        }
      });
    },
    // 判断当前模块是否被授权
    is_authorized(id) {
      if (this.generalData && this.generalData.resources.length === 1) return true;

      // 如果id等于1001,代表获取inspection summary的授权,要进行多项授权判断后才能确定是否授权
      if (id == 1001) {
        return this.is_authorized(1) && this.is_authorized(2) && this.is_authorized(3) && this.is_authorized(4) && this.is_authorized(5) && this.is_authorized(6);
      }

      // 如果id等于1002,代表获取other的授权,要进行多项授权判断后才能确定是否授权
      if (id == 1002) {
        return this.is_authorized(13) || this.is_authorized(14) || this.is_authorized(15) || this.is_authorized(20);
      }

      // 已经授权的项目
      if (this.myAuthorized.findIndex((x) => x.id == id) != -1) {
        return true;
      } else {
        return false;
      }
    },
    // 获取inspection summary所有子项的授权数组
    get_inspection_item_authorized() {
      return {
        QuantityVerification: this.is_authorized(1),
        SizeMeasurement: this.is_authorized(2),
        PackageCheck: this.is_authorized(3),
        BarcodeVerification: this.is_authorized(4),
        Workmanship: this.is_authorized(5),
        OnsiteTests: this.is_authorized(6),
      };
    },
    // 获取inspection summary所有子项的授权数组
    get_other_item_authorized() {
      return {
        CDF: this.is_authorized(13),
        SerialNumberSampled: this.is_authorized(14),
        SampleCartonNumber: this.is_authorized(15),
        OtherAttachments: this.is_authorized(20),
      };
    },

    loadSections() {
      Object.keys(this.Sections).forEach((keyName) => {
        const index = this.sectionList.findIndex((x) => x.sectionId == this.Sections[keyName].id);
        if (index !== -1 && typeof this.Sections[keyName] === 'object') {
          this.Sections[keyName].isAuthorized = this.sectionList[index].resourceId == this.utilityFn.getResourceId().toLowerCase() || this.generalData.resources.length == 1;
          this.Sections[keyName].isSectionSubmitted = this.sectionList[index].submittedDate != null;

          if (keyName == 3 || keyName == "InspectionSummary"){
              this.onsitePanels.push(this.onsiteSections[keyName]);
          }
          //if (this.Sections[keyName].isAuthorized && this.onsiteSections.hasOwnProperty(keyName)) {
            
          //}
        }
      });

      this.Sections.hidebutton = false;
      this.$store.dispatch("inspection/setSectionData", this.Sections);
    },

    loadGeneralSections() {
      this.dynamicForm.formTabs.forEach((tab) => {
        let panel = [];
        tab.formTabSections.forEach((section, index) => {
          if (this.isDynamicSectionAuthorized(section.name)) {
            panel.push(index);
          }
        });

        this.generalPanels.push(panel);
      });
    },

    // for dynamic section
    genericSectionData(sectionName) {
      if (sectionName.indexOf("General") !== -1) {
        return this.Sections.General;
      } else if (sectionName.indexOf("Supplier") !== -1) {
        return this.Sections.SupplierSite;
      } else if (sectionName.indexOf("Sampling") !== -1) {
        return this.Sections.Sampling;
      }

      return null;
    },

    validationPerSection(sectionName, sectionSubmitted) {
      let isEnable = false;
      if (this.workOrderDetails.workOrderInspectionSections != undefined) {
        let getNotNull = this.workOrderDetails.workOrderInspectionSections.filter((x) => x.dynamicSection != null);
        let getLeadInspector = this.workOrderDetails.resources.find((x) => x.isLeadInspector == true);
        let getValue = getNotNull.find((x) => x.dynamicSection.name == sectionName);
        if (getValue && getLeadInspector) {
          if (sectionSubmitted == "") {
            isEnable = getValue.dynamicSection.name == sectionName && getValue.resourceId == this.utilityFn.getResourceId().toLowerCase(); //(this.workOrderDetails.status == 'Rework' && getValue.resourceId == this.utilityFn.getResourceId().toLowerCase() && getLeadInspector.resourceId == getValue.resourceId) //((getValue.resourceId == this.utilityFn.getResourceId().toLowerCase() && this.workOrderDetails.status != 'Rework') || (getLeadInspector.resourceId == this.utilityFn.getResourceId().toLowerCase() && this.workOrderDetails.status == 'Rework'))
          }
          if (sectionSubmitted == "Section Submitted") {
            isEnable = getValue.dynamicSection.name == sectionName && getValue.submittedDate == null && getValue.resourceId == this.utilityFn.getResourceId().toLowerCase(); // || ((getValue.resourceId == this.utilityFn.getResourceId().toLowerCase() && this.workOrderDetails.status != 'Rework') ||(getLeadInspector.resourceId == this.utilityFn.getResourceId().toLowerCase() && this.workOrderDetails.status == 'Rework'))
          }
        }
        if (sectionName == "Inspection Time") {
          isEnable = true;
        }
        if(sectionName == "Reference Documents / Client Technical Documents") {
          isEnable = true;
        }
        return this.workOrderDetails.resources.length === 1 || (this.workOrderDetails.resources.length >= 1 && isEnable);
      }
    },

    isDynamicSectionAuthorized(sectionName) {
      return this.validationPerSection(sectionName, "");
    },

    isDynamicSectionEnabled(sectionName) {
      return this.validationPerSection(sectionName, "Section Submitted");
    },

    isDynamicSectionSubmitted(sectionName) {
      return this.validationPerSection(sectionName, "Section Submitted");
    },

    isSectionAuthorized(sectionId, isParent = false) {
      if (this.generalData.resources.length === 1) return true;

      // If is parent section like 'Inspection Summary' and 'Others', check sub sections if assigned to logged in user
      if (isParent) {
        const allChildSections = this.sectionList.filter((x) => x.section && !!x.section.parentSectionId);
        const childSections = allChildSections.filter((x) => x.section && x.section.parentSectionId == sectionId);

        // check if direct child section is assigned
        if (childSections.filter((x) => x.resourceId && x.resourceId?.toLowerCase() === this.utilityFn.getResourceId().toLowerCase()).length > 0) {
          return true;
        }

        // check if child of child section is assigned
        let hasAssignedChildSection = false;
        childSections.forEach((section) => {
          const assignedSection = allChildSections.find((x) => x.section.parentSectionId == section.sectionId);
          if (assignedSection) {
            hasAssignedChildSection = true;
            return;
          }
        });
        return hasAssignedChildSection;
      } else {
        const section = this.assignedSections.find((x) => x.sectionId === sectionId);
        return !!section;
      }
    },

    isSectionEnabled(sectionId, isParent = false) {
      if (this.generalData.resources.length === 1) return true;

      // If is parent section like 'Inspection Summary' and 'Others', check sub sections if assigned to logged in user
      if (isParent) {
        return this.assignedSections.filter((x) => x.section && x.section.parentSectionId == sectionId && !x.submittedDate).length > 0;
      } else {
        const section = this.assignedSections.find((x) => x.sectionId === sectionId && !x.submittedDate);
        return !!section;
      }
    },

    isSectionSubmitted(sectionId, isParent = false) {
      if (isParent) {
        const childSections = this.sectionList.filter((x) => x.section && x.section.parentSectionId == sectionId);
        return childSections.length === childSections.filter((x) => !!x.submittedDate).length;
      } else {
        const section = this.sectionList.find((x) => x.sectionId === sectionId && !!x.submittedDate);
        return !!section;
      }
    },

    submit_item(sectionName) {
      // const hasError = this.validateSection(sectionName);
      // if(hasError) {
      //   this.snackbarError = true;
      //   this.messageError = "Missing Input Required Fields";
      //   return;
      // }


      if(!this.validateSection(sectionName)) 
        return;

      const index = this.sectionList.findIndex((x) => x.dynamicSection && x.dynamicSection.name == sectionName);
      if (index !== -1) {
        const section = {
          dynamicSectionId: this.sectionList[index].dynamicSectionId,
          workOrderId: this.generalData.workOrderId,
          submitterId: this.utilityFn.getResourceId(),
        };
        this.utilityFn.getServerData("post", this.apiList.submitInspectionSection, section, (res) => {
          this.workOrderDetails.workOrderInspectionSections[index].submittedDate = new Date();

          this.$store.dispatch("inspection/setWorkOrderDetails", { ...this.workOrderDetails });
        });
      }
    },

    validateSection(sectionName) {

      const requiredFields = this.requiredFieldList.filter(f => f.sectionName === sectionName);

      if(requiredFields.length > 0) {

        const requiredMissing = this.formAtomsList.filter(x => x.values && (x.values[0].value == "" || x.values[0].value == null) && requiredFields.some(y => y.id == x.formAtomID));

        for(let i = 0; i < requiredMissing.length; i++) {
          if(requiredFields.find(f => f.label === requiredMissing[i].label && f.label !== 'Failure Reason' && f.label !== 'Pending Reason')) {
            this.snackbarError = true;
            this.messageError = requiredMissing[i].requiredErrorText;
            return false;
          }
        }

        const failPendingFields = this.conditionalFieldValues.filter(c => c.value === 'Fail' || c.value === 'Pending');
        if(failPendingFields.length > 0) {
            for(let i = 0; i < failPendingFields.length; i++) {
              if(requiredMissing.find(r => r.fieldReference?.includes(failPendingFields[i].formAtomID) && r.fieldReference?.includes(failPendingFields[i].value))) {
                this.snackbarError = true;
                this.messageError = "Missing Input Required Fields";
                return false;
              }
            }
        }
      }

      return true;
    },

    setLoggingInfo() {
      window.sessionStorage.setItem("EditDataPage", "WorkOrderDetails");
    },

    setDefaultLandingSectionAndTab() {
      const beforeRouteName = window.sessionStorage.getItem("beforeRouteName");
      if (beforeRouteName === "workOrderId" || beforeRouteName === "assignmentId" || beforeRouteName === "index") {
        this.tab = "tab-0";
        this.tabSection = 0;
      }
    },

    initQuantityVerification() {
      let existingQuantityVerificationList = [];
      this.workOrderProducts.forEach((x) => {
        if (x.workOrderProductQuantityVerificationResults) {
          if (x.workOrderProductQuantityVerificationResults.length > 0) {
            x.workOrderProductQuantityVerificationResults.forEach((item) => {
              existingQuantityVerificationList.push(item);
            });
          }
        }
      });
      if (existingQuantityVerificationList.length > 0) {
        this.loadQuantityVerificationProductDetails(existingQuantityVerificationList);
      }
      this.loadSamplingSize();
    },

    loadQuantityVerificationProductDetails(quantityVerificationProductDetails) {
      this.productQuantityVerificationList = [];
      if (quantityVerificationProductDetails) {
        if (quantityVerificationProductDetails.length > 0) {
          let quantityVerificationList = [];
          quantityVerificationProductDetails.forEach((res) => {
            const quantityVerification = {
              id: res.id,
              workOrderProductId: res.workOrderProductId,
              productID: this.getSKUDisplay(res.workOrderProductId).productID,
              skuDisplay: this.getSKUDisplay(res.workOrderProductId).skuDisplay,
              po: this.getSKUDisplay(res.workOrderProductId).po,
              cartonQty: this.getSKUDisplay(res.workOrderProductId).cartonQty,
              qty: this.getSKUDisplay(res.workOrderProductId).qty,
              caseCountProduct: res.caseCountProduct,
              availableQuantityCarton: res.availableQuantityCarton,
              sampleSizeCarton: res.sampleSizeCarton,
              sampleSizeCarton2: res.sampleSizeCarton,
              sampleSizeCartonTotal: res.sampleSizeCartonTotal,
              excessShortQuantity: res.excessShortQuantity,
              sampleSizeProduct: res.sampleSizeProduct,
              sampleSizeProduct2: res.sampleSizeProduct,
              sampleSizeProductTotal: res.sampleSizeProductTotal,
              exportCartonNumbersOpened: res.exportCartonNumbersOpened,
              remarks: res.remarks,
              packedQty: res.packedQty,
              underPackingQty: res.underPackingQty,
              underProductionFinishingQty: res.underProductionFinishingQty,
              availableQuantityProduct: res.availableQuantityProduct,
              orderNumber: res.orderNumber,
            };
            this.productQuantityVerificationList.push(quantityVerification);
          });
        }
      }
    },
    getSKUDisplay(productId) {
      if (!productId || productId == "" || productId == null) {
        return null;
      }

      let skuDisplayData = this.skuDisplayList.find((x) => x.workOrderProductId === productId);
      return skuDisplayData;
    },

    loadSamplingSize() {
      let samplingMethodology = this.generalData.quantityVerification == null || this.generalData.quantityVerification.productSamplingMethodology == null ? this.generalData.samplingMethodology : this.generalData.quantityVerification.productSamplingMethodology;
      let inspectionLevel = this.generalData.quantityVerification == null || this.generalData.quantityVerification.productInspectionLevel == null ? this.generalData.aqlLevels : this.generalData.quantityVerification.productInspectionLevel;
      let samplingPlan1 = this.generalData.inspectionLevel;
      let round = this.generalData.samplingPlan;
      let productType = this.generalData.productType;    
      let aqlMinor = this.generalData.aqlMinor;
      let aqlMajor = this.generalData.aqlMajor;
      let aqlCritical = this.generalData.aqlCritical;

      let workorderSamplingParameters = {
        samplingMethodology : samplingMethodology,
        inspectionLevel : inspectionLevel,
        samplingPlan1 : samplingPlan1,
        round : round,
        productType : productType,
        aqlMinor:aqlMinor,
        aqlMajor: aqlMajor,
        aqlCritical: aqlCritical,
        workorderId: this.generalData.workOrderId,
        changedSamplingMethodology: null
      }

      if(!samplingMethodology || samplingMethodology == null ){
        return
      }
      this.samplingPlan.SKUSamplingList = this.workOrderProducts.map((x) => {
        return {
          id: x.id,
          workOrderID: x.workOrderID,
          productId: x.productID,
          SKUDisplay: x.skuDisplay,
          PO: x.po,
          productQty: this.getTotalAvailableQuantityProduct(x.id),
          cartonQty: this.getTotalAvailableQuantityCarton(x.id),
          productSampleSize: 0,
          cartonSampleSize: 0,
          actualProductSampleSize: 0,
          actualCartonSampleSize: 0,
          aqlMinor:aqlMinor,
          aqlMajor: aqlMajor,
          aqlCritical: aqlCritical,
          minorAC: 0,
          minorRE: 0,
          majorAC: 0,
          majorRE: 0,
          criticalAC: 0,
          criticalRE: 0,
        };
      });
      let returnedSampleSizePlan = this.samPlan.getAutoComputeSampleSize(samplingMethodology, this.samplingPlan.SKUSamplingList, inspectionLevel, samplingPlan1, round, productType, "General", false);
      let samplinPlan = {...workorderSamplingParameters, ...returnedSampleSizePlan};
      this.$store.dispatch("inspection/setSamplingPlanList", samplinPlan);

      return returnedSampleSizePlan;
    },

    getTotalAvailableQuantityProduct(workOrderProductId) {
      let totalProduct = null;
      let productofackedQtyAndUnderPackingQty = this.productQuantityVerificationList.reduce(function(tot, arr) {
        if (arr.workOrderProductId == workOrderProductId && arr.availableQuantityCarton == null && arr.caseCountProduct == null && arr.underPackingQty == null) {
          return totalProduct;
        }
        if (arr.workOrderProductId == workOrderProductId && arr.availableQuantityCarton == -1) {
          return (totalProduct = -1);
        }

        if (arr.workOrderProductId == workOrderProductId && (arr.availableQuantityCarton != null || arr.caseCountProduct != null || arr.underPackingQty != null)) {
          if (arr.packedQty == null && arr.availableQuantityProduct != null) {
            totalProduct += Number(arr.availableQuantityProduct);
          } else {
            totalProduct += Number(arr.packedQty) + Number(arr.underPackingQty == -1 ? 0 : arr.underPackingQty);
          }
        }
        return totalProduct;
      }, null);

      return productofackedQtyAndUnderPackingQty;
    },

    getTotalAvailableQuantityCarton(workOrderProductId) {
      let total = null;
      let sumAvailableQuantityCarton = this.productQuantityVerificationList.reduce(function(tot, arr) {
        if (arr.workOrderProductId == workOrderProductId) {
          if (arr.availableQuantityCarton == null) {
            return total;
          }
          total += Number(arr.availableQuantityCarton);
        }
        return total;
      }, null);
      return sumAvailableQuantityCarton;
    },
    search_map(address) {
      if (this.generalData.siteCountry == "China") return;

      if (address == undefined) {
        address = "美国";
      }

      if (window.google) {
        var geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: address }, (results, status) => {
          if (status == window.google.maps.GeocoderStatus.OK) {
            var lat = results[0].geometry.location.lat();
            var lng = results[0].geometry.location.lng();
            this.maps = new window.google.maps.Map(document.getElementById("allmap"), {
              zoom: 15,
              center: { lat: lat, lng: lng },
              disableDefaultUI: false,
              zoomControl: false,
            });
            var marker = new window.google.maps.Marker({ position: { lat: lat, lng: lng }, map: this.maps });
          } else {
            console.log("Geocode was not successful for the following reason: " + status);
          }
        });
      }
    },
    dynamicSectionSaveClick(sectionId) {
      this.$refs[sectionId].forEach((x,i) => {
        x.saveFieldValueFromSection()
      });
    },
    handler({ BMap, map }) {
      //this.baiduCenter.lng = 116.404
      //this.baiduCenter.lat = 39.915
    },
    updateInspectionTimeCount(count,isDelete){
      if (isDelete == 'delete'){
        this.InspectionTimeCount.splice(this.InspectionTimeCount.indexOf(count),1);
      }
      else{
        this.InspectionTimeCount.push(Math.max.apply(Math,this.InspectionTimeCount)+1);
      }
    },
    resourceIdForInspectionTime(){
      if (this.inspectionTimeForResourceModel == null && this.fromCanyon){
        return null
      }else{
        return this.inspectionTimeForResourceModel == null ? this.utilityFn.getResourceId() : this.inspectionTimeForResourceModel
      }
    },
    resetInspectionTimeCount(){
      this.InspectionTimeCount = [];
      this.InspectionTimeCount.push(1);
    },
    async getAndSetCompanySubscriptionByWorkOrderId(){
      const result = await this.utilityFn.getServerDataAsync("get", this.apiList.getCompanySubscriptionByWorkOrderId + "/" + this.$route.params.id, {});
      this.isAdditionalInformationTrue = result.data.additionalInformationSectionInAIMS;
    }
  },
  beforeRouteEnter(to, from, next) {
    window.sessionStorage.setItem("beforeRouteName", from.name);

    if (from.name === "workOrderId" || from.name === "assignmentId") {
      auditLog.setAuditHeaders({ transactionCode: auditLog.getUniqueIdentifier(), entityKey: null });
    }
    next();
  },
};
</script>

<style lang="stylus" scoped>
.workOrderDetailsId
    background #e8e8e8
.tab_title
    font-weight 700
.map
    width: 100%;
    height: 300px;
.submit-btn
    color: #fff !important
.isAssigned
    background: #c2e0fd !important
.isNotAssigned
    background: #eee !important
 .snackbar-loading
        align-items center
        display flex
        justify-content center
</style>
