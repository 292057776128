<template>
    <div>
        <div class="workOrderDefectEditId">
            <v-row>
                <v-col id="edit_inspection_photos" cols="12" sm="12"  class="photoIcon_box" @click="go_upload_photo()">
                    <span style="color:#1989fa; float: right;">Edit Inspection Photo(s)</span>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field id="defect_sku" label="SKU" readonly  v-model="formData.sku"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field id="defect_description" label="Product Description" readonly :value="formData.description"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field id="defect_po" label="PO Number" readonly v-model="formData.po"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field id="defect_batchnumber" label="Batch Number" v-model="formData.batchNumber" append-icon="edit"></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field id="defect_actual_inspection_qty" label="Actual Inspection Qty" v-model="formData.actualInspectQty" @keyup="returnNumber(formData, 'actualInspectQty')"  @change="returnNumber(formData, 'actualInspectQty')" append-icon="edit"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field id="defect_actual_packs_qty" label="Actual Packs Qty" v-model="formData.actualPacksQty" @keyup="returnNumber(formData, 'actualPacksQty')" @change="returnNumber(formData, 'actualPacksQty')" append-icon="edit"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field id="defect_packed" label="Packed" v-model="formData.packedQty" @keyup="returnNumber(formData, 'packedQty')" @change="returnNumber(formData, 'packedQty')" append-icon="edit"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field id="defect_not_packed" label="Not Packed" v-model="formData.notPackedQty" @keyup="returnNumber(formData, 'notPackedQty')" @change="returnNumber(formData, 'notPackedQty')" append-icon="edit"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field id="defect_taken_packed_qty" label="Taken Packed Qty" v-model="formData.takenPackedQty" @keyup="returnNumber(formData, 'takenPackedQty')" @change="returnNumber(formData, 'takenPackedQty')" append-icon="edit"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field id="defect_sample_no_of_packs" label="Sample # of Packs" v-model="formData.samplePacksQty" @keyup="returnNumber(formData, 'samplePacksQty')" @change="returnNumber(formData, 'samplePacksQty')" append-icon="edit"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field id="defect_sample_not_packed" label="Sample Not Packed" v-model="formData.sampleNotPackQty" @keyup="returnNumber(formData, 'sampleNotPackQty')" @change="returnNumber(formData, 'sampleNotPackQty')" append-icon="edit"></v-text-field>
                </v-col>

                <!-- Work Order Product Defects -->
                <v-container v-for="(item, index) in workOrderProductDefectList" :key = index class="">
                  

                <v-col :id="'work_order_product_defect_list_type' + index" cols="12" sm="12" v-if="showDclType">
                      <v-select 
                          :items="filteredDclTypeAliases" 
                          item-text="description"
                          item-value="id" 
                          v-model="item.dclTypeAliasId"
                          @change="onDefectCategoryChange(index)"
                          label="Select Defect Type">
                        </v-select>
                </v-col>
          
                <v-col v-if="isDCLOrGeneric()" :id="'work_order_product_defect_list' + index" cols="12" sm="12">
                      <v-select 
                          :items="clientDefectListSection" 
                          item-text="productCategory"
                          item-value="productCategoryId" 
                          v-model="item.clientDefect.productCategoryId"
                          @change="onDefectCategoryChange(index)"
                          label="Select Defect Category">
                        </v-select>
                </v-col>

                <v-col v-else :id="'work_order_product_defect_list' + index" cols="12" sm="12">
                      <v-select 
                          :items="defectCategorylist" 
                          item-text="name"
                          item-value="id" 
                          v-model="item.defectCategoryId"
                          @change="onDefectCategoryChange(index)"
                          label="Select Defect Category">
                        </v-select>
                </v-col>

                <v-col cols="12" sm="12">
                      <v-autocomplete :id="'work_order_product_defect_list_name_' + index"  
                      label="Enter defect"  
                      :items="filteredDefectNameList(index)"  
                      :item-text="isDCLOrGeneric()?'description':'name'" 
                      item-value='id'  
                      @change="onDefectNameChange(index)" 
                      v-model='item.defectNameId' ></v-autocomplete>
                </v-col>
    
                <v-col cols="12" sm="6" md="4" v-show="isDCLOrGeneric()">
                  <v-autocomplete :id="'work_order_product_defect_list_code_' + index" 
                      label="Defect Code" 
                      :items="filteredDefectCodeList(index)" 
                      item-text="defectCode" 
                      item-value="defectCode" 
                      v-model="item.defectCode"
                      @change="onDefectCodeChange(index)">
                  </v-autocomplete>     
                </v-col>

                <v-col cols="12" sm="12">
                    <v-text-field :id="'work_order_product_defect_list_description_' + index" label="Defect Description" v-model="item.defectDetails" @change="onChange"></v-text-field>
                  </v-col>

                  <v-col cols="6" sm="6">
                    <v-text-field :id="'work_order_product_defect_list_qty_' + index" class="number_text" label="Defect Qty" prepend-inner-icon="mdi-minus" append-icon="mdi-plus" v-model="item.qty" @change="onDefectQtyChange(item, 'qty')"  @click:prepend-inner="minus(item, 'qty')" @click:append="plus(item, 'qty')"></v-text-field>
                  </v-col>

                  <v-col cols="6" sm="6">
                      <v-radio-group :id="'work_order_product_defect_list_classification_' + index" v-model="item.defectClassificationId" :disabled="!item.defectNameId" row style="padding-top: 0;">
                          <v-radio id="defect_classification" v-for="(item, index) in filterDefectClassification(index)" :key="index" :label="item.name" :value="item.id" ></v-radio>  
                      </v-radio-group>
                  </v-col>

                </v-container>


              <!-- <v-btn class="submit" dark color="#B14C4C" @click="submit" :disabled="!isFormValid">Submit</v-btn> -->

            </v-row>
        </div>


        <v-snackbar v-model="snackbar" :timeout="3000" color="red darken-2" >
            {{ message }} <v-btn text @click="snackbar = false" > Close</v-btn>
        </v-snackbar>
    </div>
</template>

<script>

    let state = {
        appHeaderState: {
            show: true,
            title: 'Stock Inspected:',
            showMenu: true,
            showBack: true,
            showLogo: false,
            showTitle: true,
            showSearch: false,
            showCalendar: true
        }
    };

    function setState(store) {
        store.dispatch('navigation/setAppHeader', state.appHeaderState);
    }

    import {mapActions, mapState} from 'vuex';

    export default {
        name: 'workOrderDefectEditId',
        data () {
            return {
                // snackbar
                snackbar: false,
                message: '',

                value: '',
                number: 0,

                row: null,
                // select
                e1: '1',
                sortType: [
                  { text: 'Date' },
                  { text: 'Time' },
                ],

                //
                formData: {
                    batchNumber: null,
                    actualInspectQty: 0,
                    actualPacksQty: 0,
                    packedQty: 0,
                    notPackedQty: 0,
                    takenPackedQty: 0,
                    samplePacksQty: 0,
                    sampleNotPackQty: 0,
                    standard: '',
                },
                defectCategorylist: [],
                defectNameList: [],
                defectClassifications: [],
                workOrderProductDefectList: [],
                workOrderProduct: {},
                clientDefectListSection: [],
                dclTypeAliases: [],
                showDclType: false,

                isDateEntryValid: true,
                changeValuedefect:[]
            };
        },
        mounted () {
    
          this.clientDefectListSection = this.clientDefectList.filter(x => x.dclType == "Workmanship")
           .map(x => x.clientDefects)[0];
       

            this.utilityFn.getServerData('get', this.apiList.woDetailsToEditWoDefect + '/' + this.$route.params.id, {}, result => {
                this.workOrderProduct = result.data.data[0].products.find(x => x.id === this.$route.query.id);
               
                if(this.isDCLOrGeneric()){
                  this.workOrderProductDefectList = this.workOrderProduct.workOrderProductDefects.filter(x => x.clientDefectId!= null && (x.sectionId == 11 || x.sectionId == 5));
                  this.workOrderProductDefectList.map(x => x.defectNameId = x.clientDefectId)       
               
                }
                else{
                    this.workOrderProductDefectList = this.workOrderProduct.workOrderProductDefects.filter(x => x.clientDefectId== null && (x.sectionId == 11 || x.sectionId == 5));
                }         
                this.populate();
                this.initState();
            });
            this.utilityFn.getServerData('get', this.apiList.getDefectNames, {}, result => {
                this.defectNameList = result.data.data;
            });
            this.utilityFn.getServerData('get', this.apiList.getDefectCategories, {}, result => {
                 this.defectCategorylist = result.data.data.filter(x => x.name != 'Functional Check Measurement' && x.name != 'Package Check');
            });
            this.utilityFn.getServerData('get', this.apiList.getDefectClassifications, {}, result => {
                this.defectClassifications = result.data.data;
            });
            this.utilityFn.getServerData("get", `${this.apiList.getCompanyDclTypeAliases}/${this.workOrderDetails.clientId}/0`, {}, (result) => {
              this.dclTypeAliases = result.data && result.data.data.filter(x => x.isActive);
            });
            this.utilityFn.getServerData("get", this.apiList.getIsApplyToDclTypeAlias + '/' + this.workOrderDetails.clientId, {  }, (result) => {
              this.showDclType = result.data.data;
            });
            
        },
        methods: {
            filteredDefectNameList(index) {     
              if(this.isDCLOrGeneric()){
                const item = this.workOrderProductDefectList[index];
                return this.clientDefectListSection.filter(x => x.productCategoryId == item.clientDefect.productCategoryId && (!item.dclTypeAliasId || x.companyDclTypeAliasId == item.dclTypeAliasId));
              }
              else{
                return this.defectNameList.filter(x => x.defectCategoryId == this.workOrderProductDefectList[index].defectCategoryId);
              }
            },
            filterDefectClassification(index) {  
              if(this.isDCLOrGeneric()){    
                if (this.workOrderProductDefectList[index].defectNameId) {
                  const clientDefect = this.clientDefectListSection.find(x => x.id ==   this.workOrderProductDefectList[index].defectNameId);
                  return clientDefect ? clientDefect.defectSeverities : [];
                }           
              }
              else{
                  return this.defectClassifications 
              }
              return this.defectClassifications 

            },
            filteredDefectCodeList(index) {
                if(this.isDCLOrGeneric()){
                  return this.clientDefectListSection.filter(x => x.productCategoryId == this.workOrderProductDefectList[index].clientDefect.productCategoryId);
                }
                else{
                   return this.defectNameList.filter(x => x.defectCategoryId == this.workOrderProductDefectList[index].defectCategoryId);
                }
          },

            onDefectCategoryChange(index) {

                this.workOrderProductDefectList[index].defectCode = null;            
                this.workOrderProductDefectList[index].defectName = null;
                this.workOrderProductDefectList[index].defectNameId = null;
           
                this.onChange();
            },

            onDefectNameChange(index) {
              if (this.workOrderProductDefectList[index].defectNameId) {
                  if(this.isDCLOrGeneric()){
                      this.workOrderProductDefectList[index].defectCode = this.clientDefectListSection.find(x => x.id == this.workOrderProductDefectList[index].defectNameId).defectCode;   
                  }
                  else{
                      this.workOrderProductDefectList[index].defectCode = this.defectNameList.find(x => x.id == this.workOrderProductDefectList[index].defectNameId).code;
                  }
               }                    
            },

            onDefectCodeChange(index) {
            if (this.workOrderProductDefectList[index].defectCode) {
                  if(this.isDCLOrGeneric()){
                    if(this.clientDefectListSection.find(x => x.defectCode == this.workOrderProductDefectList[index].defectCode).id){
                        this.workOrderProductDefectList[index].defectNameId = this.clientDefectListSection.find(x => x.defectCode == this.workOrderProductDefectList[index].defectCode).id;
                    }
                    else{
                       this.workOrderProductDefectList[index].defectNameId = this.defectNameList.find(x => x.defectCode == this.workOrderProductDefectList[index].defectCode).id;
                    }  
                }              
            }
        },

            isDCLOrGeneric(){
                    if(this.clientDefectListSection && this.clientDefectListSection.length > 0){     
                        return true
                    }
                    else{  
                        return false    
                    }                  
                    return false
            },
            minus(item, prop) {
                if (!item[prop]) return;

                item[prop] = +(item[prop].toString().replace(/[^\d+(\.\d+)]/g,''));
                item[prop]? item[prop]-- : ''


                this.onChange();
            },
            plus(item, prop) {
                if (!item[prop]) return;

                item[prop] = +(item[prop].toString().replace(/[^\d+(\.\d+)]/g,''));
                item[prop]++


                this.onChange();
            },
            onDefectQtyChange(item, prop) {
                let currentValue = +item[prop];
                if (currentValue <= 0) {
                    item[prop] = 0;
                }
            },
            initState() {
                state.appHeaderState.title = 'Stock Inspected: SKU ' + this.workOrderProduct.skuDisplay;
                setState(this.$store);
            },
            calculated_quantity (num) {
                if (num == -1) {
                    if (this.number == 0) {
                        return;
                    }
                    this.number--;
                } else {
                    this.number++;
                }
            },

            go_upload_photo (type) {
                this.$router.push({name: 'uploadPhotoInspectionId',params:{headerTitle:' Stock Inspected '},query:{id: this.$route.query.id,headerTitle:"Stock Inspected ",type:'Stock Inspected'}});
            },
            submit () {
                const formPatch =     [
                    { "op": "replace", "path": "/batchNumber", "value":  this.formData.batchNumber},
                    { "op": "replace", "path": "/actualInspectQty", "value": this.formData.actualInspectQty },
                    { "op": "replace", "path": "/actualPacksQty", "value": this.formData.actualPacksQty },
                    { "op": "replace", "path": "/packedQty", "value": this.formData.packedQty },
                    { "op": "replace", "path": "/notPackedQty", "value": this.formData.notPackedQty },
                    { "op": "replace", "path": "/takenPackedQty", "value": this.formData.takenPackedQty },
                    { "op": "replace", "path": "/samplePacksQty", "value": this.formData.samplePacksQty },
                    { "op": "replace", "path": "/sampleNotPackQty", "value": this.formData.sampleNotPackQty },
                ];

                if (window.navigator.onLine != true) {
                  /* console.log(JSON.stringify(formPatch))
                  var x = '"'+formPatch+'"'
                  console.log('formPatch X',x)
                  var that = this
                  this.utilityFn.db.transaction(function(tx) {
                    tx.executeSql('INSERT INTO OfflineData ( dataId, jobId, workId, type, data, updataUrl, pageName) VALUES (?, ?, ?, ?, ?, ?, ?)', [1, 1, 1, 'patch', x, that.apiList.upsertProductResult + '/' + this.workOrderProduct.id+ '/2', 'defectEdit']);
                  });
                   */
                   this.utilityFn.getServerData('patch', this.apiList.upsertProductResult + '/' + this.workOrderProduct.id+ '/2', formPatch, result => {}, 
                   errres=> {
                      this.workOrderProductDefectList = this.workOrderProductDefectList.map(defect => { 
                      defect.sectionId = 11; 
                      defect.clientDefectId =this.isDCLOrGeneric() ? defect.defectNameId :null;
                      defect.defectNameId = this.isDCLOrGeneric() ? null :defect.defectNameId;
                      defect.category = this.isDCLOrGeneric() ? null : defect.category;
                      return defect; });
                   });
                   
                  
                   this.utilityFn.getServerData('post', this.apiList.updateWorkOrderProductDefects, [...this.workOrderProductDefectList], result => {}, 
                   errres=> {
                     this.snackbar = true;
                     this.message = this.msgList.NoConnection;
                   });
                   
              
                } else {
                  this.utilityFn.getServerData('patch', this.apiList.upsertProductResult + '/' + this.workOrderProduct.id+ '/2', formPatch, result => {}, errres=> {});
                  this.workOrderProductDefectList = this.workOrderProductDefectList.map(defect => { 
                    defect.sectionId = 11; 
                     defect.clientDefectId =this.isDCLOrGeneric() ? defect.defectNameId :null;
                     defect.defectNameId = this.isDCLOrGeneric() ? null :defect.defectNameId;
                     defect.category = this.isDCLOrGeneric() ? null : defect.category;
                    return defect; });
                  
                  this.utilityFn.getServerData('post', this.apiList.updateWorkOrderProductDefects, [...this.workOrderProductDefectList], result => {}, errres=> {});
                }
            },
            populate() {
              this.formData.sku = this.workOrderProduct.skuDisplay;
              this.formData.po = this.workOrderProduct.po;
              this.formData.description = this.workOrderProduct.productDescription || '';
              if (this.workOrderProduct.workOrderProductResults) {
                this.formData.batchNumber = this.workOrderProduct.workOrderProductResults.batchNumber;
                this.formData.actualInspectQty = this.workOrderProduct.workOrderProductResults.actualInspectQty;
                this.formData.actualPacksQty = this.workOrderProduct.workOrderProductResults.actualPacksQty;
                this.formData.packedQty = this.workOrderProduct.workOrderProductResults.packedQty;
                this.formData.notPackedQty = this.workOrderProduct.workOrderProductResults.notPackedQty;
                this.formData.takenPackedQty = this.workOrderProduct.workOrderProductResults.takenPackedQty;
                this.formData.samplePacksQty = this.workOrderProduct.workOrderProductResults.samplePacksQty;
                this.formData.sampleNotPackQty = this.workOrderProduct.workOrderProductResults.sampleNotPackQty;
              }
            },

            

            returnNumber (obj, name) {

                if (obj[name] != '') {
                    obj[name] = obj[name].toString().replace(/[^\d.]/g,"");  //清除“数字”和“.”以外的字符
                    obj[name] = obj[name].replace(/\.{2,}/g,"."); //只保留第一个. 清除多余的
                    obj[name] = obj[name].replace(".","$#$").replace(/\./g,"").replace("$#$",".");
                    obj[name] = obj[name].replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');//只能输入两个小数

                }

                this.onChange();
            },
            onChange() {
                this.logChanges();
            },
            logChanges() {
                // flag that data has been updated, this is to prevent logging activity even though no value has been change
                // this is because when clicking on section headers/navigating out, api is always called
                window.sessionStorage.setItem('EditDataLogging', true);
            }
        },
        computed: {
          ...mapState('inspection', {
            clientDefectList: state => state.clientDefectList,
            workOrderDetails: (state) => state.workOrderDetails
        }),
        isFormValid () {
            if (this.formData.actualInspectQty && +this.formData.actualInspectQty > 0
                && this.formData.actualPacksQty && +this.formData.actualPacksQty > 0
                && this.formData.packedQty && +this.formData.packedQty > 0
                && this.formData.notPackedQty && +this.formData.notPackedQty > 0
                && this.formData.takenPackedQty && +this.formData.takenPackedQty > 0
                && this.formData.samplePacksQty && +this.formData.samplePacksQty > 0
                && this.formData.sampleNotPackQty && +this.formData.sampleNotPackQty > 0)
                {
                  return true;
                }

            return false;
          },
          isDefectsFormValid () {
            let validCount = 0;
            for (let i=0; i<this.workOrderProductDefectList.length;i++) {
              let defectItem = this.workOrderProductDefectList[i];
              if (defectItem.defectClassificationId
                  && defectItem.defectNameId
                  && (!this.showDclType || defectItem.dclTypeAliasId)
                  && defectItem.qty && +defectItem.qty > 0) {
                    validCount++;
                  }
            }

            return validCount === this.workOrderProductDefectList.length;
          },

          isDefectDuplicate () {
            for (let i=0; i<this.workOrderProductDefectList.length;i++) {
              const currentDefect = this.workOrderProductDefectList[i];
              const otherDefects = this.workOrderProductDefectList.filter(x => x.id !== currentDefect.id);

              const index = otherDefects.findIndex(defect => {
                    return defect.defectCategoryId === currentDefect.defectCategoryId &&
                            defect.defectNameId === currentDefect.defectNameId &&
                            defect.defectClassificationId === currentDefect.defectClassificationId &&
                            defect.defectDetails === currentDefect.defectDetails &&
                            defect.workOrderProductId === currentDefect.workOrderProductId; });

              if (index !== -1) return true;
            }

            return false;
          },
          
          filteredDclTypeAliases() {
            return this.dclTypeAliases.filter(x => x.dclType.name === 'Workmanship');
          },
        },
        beforeRouteLeave (to, from, next) {
          let valid = true;
          if (!this.isDateEntryValid) {
            this.snackbar = true;
            this.message = 'Must be a number';
            valid = false;
          }

          else if (!this.isDefectsFormValid) {
            this.snackbar = true;
            this.message = 'Defect invalid!';
            valid = false;
          }

          else if (this.isDefectDuplicate) {
            this.snackbar = true;
            this.message = 'Cannot save duplicate defect!';
            valid = false;
          }


          next(valid);
        },
        beforeDestroy() {
            this.submit();
        },
    };
</script>


<style lang="stylus" scoped>
    .workOrderDefectEditId        
        padding 15px 30px
        background #fff

    .row
        background #fff
        margin 0px

    .submit
        display block
        margin 15px auto
        width 60%
    >>>.number_text input
        text-align center
</style>
