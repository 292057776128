<template>
  <div>
    <div class="workOrderInspectionQuantityId">
      <div style="position: relative;">
        <v-row>
          <v-col cols="12" sm="2" style="text-align:left;">
            <v-switch :disabled="isSectionDisabled" id="isTolerance" color="green" v-model="isTolerance" inset label="Tolerance" @change="onChangeTolerance()"></v-switch>
          </v-col>
          <v-col cols="12" sm="7" style="text-align:left;">
            <v-radio-group v-if="isTolerance" class="tolerance-option" v-model="toleranceOption" @change="onToleranceOptionChange()" row>
              <v-radio id="applyToCarton" label="Apply to Carton" value="carton"></v-radio>
              <v-radio id="applyToProduct" label="Apply to Product" value="product"></v-radio>
            </v-radio-group>
            <v-radio-group v-if="toleranceOption === 'product'" class="product-option" v-model="productOption" @change="onProductOptionChange()" row>
              <v-radio id="overallProduct" label="Overall Product SKU(s)" value="overall"></v-radio>
              <v-radio id="individualProduct" label="Individual Product SKU" value="individual"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" sm="3" style="text-align:right;">
            <v-btn v-if="showButton" id="QV_btnSubmit" :disabled="isSectionDisabled" class="submit-btn" @click="submit_item(true)" small depressed color="#1867c0">Submit</v-btn>
            <v-btn id="QV_btnUploadPhoto" :disabled="isSectionDisabled" class="submit-btn" small depressed color="#1867c0" @click="go_upload_photo()">
              <!-- <v-icon>camera_alt</v-icon> -->
              <icon name="camera"></icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-tabs v-model="tab" background-color="rgb(0, 81, 138)" dark>
          <v-tab id="QV_tab-1" href="#tab-1" @click="getProductQuantityVerificationList()">Carton</v-tab>
          <v-tab id="QV_tab-2" href="#tab-2" @click="getProductQuantityVerificationList()">Product</v-tab>
        </v-tabs>

        <v-tabs-items touchless v-model="tab">
          <v-tab-item class="tab_item" :value="'tab-1'" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field id="txtCartonAvailablePercent" hide-details :value="cartonAvailablePercent + '%'" label="Available(%)" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field ref="cartonRequired" id="txtCartonRequired" hide-details="auto" v-model="workOrderList.cartonRequired" :label="requiredLabel" type="number" :rules="[(v) => +v >= -1 || 'Invalid']" @change="UpdateProductCartonRequired('carton')"></v-text-field>
                <span :style="{ color: '#ff0000' }" class="font-italic">Note: If Required is not applicable, enter "-1"</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field v-if="productOption === 'overall'" id="txtOverallProductSKU" v-model="overallProductSKU" label="Overall Product SKUs Available(%)" type="number" readonly></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field ref="upperTolerance" v-if="isTolerance" id="txtUpperTolerance" v-model="upperTolerance" label="Upper+(%)" type="number" :rules="[(v) => (v !== undefined && v !== null && v !== '') || 'Required', (v) => +v >= 0 || 'Invalid']"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field ref="lowerTolerance" v-if="isTolerance" id="txtLowerTolerance" v-model="lowerTolerance" label="Lower-(%)" type="number" :rules="[(v) => (v !== undefined && v !== null && v !== '') || 'Required', (v) => +v >= 0 || 'Invalid']"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  id="ddlCartonInspectionLevel"
                  hide-details
                  v-model="workOrderQuantityVerificationObj.cartonInspectionLevel"
                  :items="inspectionLevel1Options"
                  item-value="name"
                  item-text="name"
                  label="Inspection Level"
                  @change="onChangeInspectionLevel($event, 'carton')"
                >
                </v-select>
              </v-col>

              <v-col cols="12" sm="6" >
                <v-select
                  id="ddlCartonSamplingMethodology"
                  hide-details
                  v-model="workOrderQuantityVerificationObj.cartonSamplingMethodology"
                  :items="sampleSizeTypeOptions"
                  item-text="name"
                  item-value="name"
                  label="Sampling Methodology"
                  @change="onChangeSamplingMethodology($event, 'carton')"
                  readonly
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <b>Grand Total</b>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field id="txtTotalCartonPoQty" hide-details v-model="totalCartonPoQty" label="PO Qty" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field id="txtTotalCartonAvailablePoQty" hide-details v-model="totalCartonAvailablePoQty" label="Available PO Qty" readonly></v-text-field>
              </v-col>
            </v-row>
            <v-row class="case-count-assign-container">
              <v-col cols="12" sm="6">
                <v-row>
                  <label class="case-count-label">Case Count</label>
                  <v-text-field class="case-count-assign-input" hide-details v-model="assignCaseCountToAll" v-on:keypress="isNumber($event)" @paste="onPasteToAssignAll($event)" outlined dense></v-text-field>
                  <v-btn class="ml-2" @click="assignCaseCount()">Assign</v-btn>
                </v-row> 
              </v-col>
              <v-col cols="12" sm="6">
                <v-btn @click="applyPOQty()">Apply PO Qty (Unit)</v-btn>
              </v-col>
            </v-row>

            <v-data-table
              id="tblCartonQuantityVerificationList"
              v-model="selectedClientProduct"
              v-bind:headers="showHeadersCarton"
              :items="productQuantityVerificationList"
              :hide-default-footer="false"
              :sort-by.sync="cartonSortBy"
              class="elevation-1 textAlignCenter"
              style="margin-top: 30px;"
              :loading="loadingCartonQuantityVerificationList"
              loading-text="Loading... Please wait"
              item-key="id"
              @update:options="resetCartonIndexMarkers"
              show-select
              :items-per-page="-1"
            >
              <template v-slot:progress>
                <v-progress-linear indeterminate></v-progress-linear>
              </template>
              <template v-slot:body="{ items, pagination, isSelected, select }">
                <tbody>
                  <tr v-for="(item, index) in items" :key="item.id">
                    <td>
                      <v-checkbox
                        :input-value="isSelected(item)"
                        style="margin:0px;padding:0px"
                        hide-details
                        @click="select(item,!isSelected(item))"
                      >                   
                      </v-checkbox>
                    </td>
                    <td>
                      <span style="display: none;">{{ (item.indexMarkerCarton = index + pagination.pageStart) }}</span>
                      <span :id="'txtCartonClientProductID_' + item.countIndex"> {{ item.clientProductId }} </span>
                    </td>
                    <td>
                      <span :id="'txtCartonSKU_' + item.countIndex"> {{ item.skuDisplay }} </span>
                    </td>
                    <td>
                      <span :id="'txtCartonPo_' + item.countIndex"> {{ item.po }} </span>
                    </td>
                    <td>
                      <span :id="'itemCartonQty_' + item.countIndex" v-show="item.orderNumber == 1"> {{ item.cartonQty }} </span>
                    </td>
                    <td v-if="shipQtyCartonCDFId" class="w-sm">
                      <v-text-field :id="'itemCartonShipqty_' + item.countIndex" class="number_text" v-show="item.orderNumber == 1" v-on:keypress="isNumber($event)" @paste="onPaste($event, item, 'shipqty')" @change ="onChangeShipQtyCarton(item)" hide-details v-model="item.shipqty" type="text" outlined dense></v-text-field>
                    </td>
                    <td class="w-sm">
                      <v-text-field :id="'txtCartonCaseCountProduct_' + item.countIndex" hide-details v-model="item.caseCountProduct" v-on:keypress="isNumber($event)" @paste="onPaste($event, item, 'caseCountProduct')" @change ="onChangeShipQtyCarton(item)" v-on:keyup="onKeyUpAvailableQuantityCarton(item, false)" type="text" outlined dense></v-text-field>
                    </td>
                    <td class="w-sm">
                      <v-text-field :id="'txtCartonAvailableQuantityCarton_' + item.countIndex" hide-details v-on:keypress="isNumber($event)" @paste="onPaste($event, item, 'availableQuantityCarton')" v-model="item.availableQuantityCarton" @change="onKeyUpAvailableQuantityCarton(item, false)" type="text" outlined dense></v-text-field>
                    </td>
                    <td class="w-sm">
                      <v-text-field :id="'txtCartonSampleSizeCarton_' + item.countIndex" hide-details v-model="item.sampleSizeCarton" type="text"  outlined dense @keyup="returnNumberSampleSize(item,'sampleSizeCarton')"></v-text-field>
                    </td>
                    <td class="w-md">
                      <v-textarea
                        maxlength="2500"
                        :id="'txtCartonExportCartonNumbersOpened_' + item.countIndex"
                        rows="1px"
                        v-show="item.orderNumber == 1"
                        v-model="item.exportCartonNumbersOpened"
                        type="text"
                        :disabled="item.orderNumber == 1 ? false : true"
                        hide-details
                        :rules="[cartonNumbersOpenedRules]"
                        outlined
                        dense
                      ></v-textarea>
                    </td>
                    <td class="w-md">
                      <v-textarea
                        maxlength="2500"
                        :counter="2500"
                        :id="'txtCartonRemarks_' + item.countIndex"
                        rows="1px"
                        v-show="item.orderNumber == 1"
                        hide-details
                        v-model="item.remarks"
                        type="text"
                        :disabled="item.orderNumber == 1 ? false : true"
                        outlined
                        dense
                      ></v-textarea>
                    </td>
                    <td v-show="workOrderList.inspectionLevel == 'Double'">
                      <span :id="'itemSampleSizeCarton2_' + item.countIndex"> {{ item.sampleSizeCarton2 }} </span>
                    </td>
                    <td v-show="workOrderList.inspectionLevel == 'Double'">
                      <span :id="'itemSampleSizeCartonTotal_' + item.countIndex"> {{ item.sampleSizeCartonTotal }} </span>
                    </td>
                    <td>
                      <template v-if="item.orderNumber == 1">
                        <v-btn id="itemCartonAdd" icon dark>
                          <v-icon color="black" @click.stop="addChild(item)">add</v-icon>
                        </v-btn>
                      </template>
                      <template v-else-if="item.orderNumber > 1">
                        <v-btn id="itemCartonDelete" icon dark>
                          <v-icon color="black" @click.stop="deleteChild(item)" :disabled="buttonDisable">delete</v-icon>
                        </v-btn>
                      </template>
                      <template v-if="item.indexMarkerCarton >= 1">
                        <v-btn icon title="Copy From Above" @click="copyAboveRowCarton(item.indexMarkerCarton)">
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>           
            <v-dialog v-model="showToleranceDialogCarton" width="450">
              <v-card>
                <v-card-title class="headline">Required Confirmation Window</v-card-title>
                <v-card-text>Is it not applicable due to non-availablilty of required from client?</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn id="btnCartonToleranceConfirmationYes" color="blue darken-1" text @click="UpdateTolerance('Carton', 'Yes')">Yes</v-btn>
                  <v-btn id="btnCartonToleranceConfirmationNo" color="blue darken-1" text @click="UpdateTolerance('Carton', 'No')">No</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-tab-item>

          <v-tab-item class="tab_item" :value="'tab-2'" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
            <pulse-validator v-if="errorsProductTab.length > 0" :errors="errorsProductTab"></pulse-validator>
            <v-row v-if="false">
              <v-col cols="12" sm="6">
                <v-text-field id="txtProductAvailablePercent" hide-details :value="productAvailablePercent + '%'" label="Available %" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field id="txtProductRequired" hide-details v-model="workOrderList.productRequired" label="Required(%)" type="number" @change="UpdateProductCartonRequired('product')"></v-text-field>
                <span :style="{ color: '#ff0000' }" class="font-italic">Note: If Required is not applicable, enter "-1"</span>
              </v-col>
              <!-- <v-col cols="12" sm="6">
                <v-text-field id="txtProductRequiredMax" hide-details v-model="workOrderList.productRequiredMax" label="Maximum Required(%)" type="number" @change="UpdateProductCartonRequired('productMax')"></v-text-field>
                <span :style="{ color: '#ff0000' }" class="font-italic">Note: If Required is not applicable, enter "-1"</span>
              </v-col> -->
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" v-show="false">
                <v-text-field id="txtProductInspectionLevel" hide-details v-model="workOrderQuantityVerificationObj.productInspectionLevel" label="Inspection Level" readonly></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" >
                <v-select
                  id="ddlProductSamplingMethodology"
                  hide-details
                  v-model="workOrderQuantityVerificationObj.productSamplingMethodology"
                  :items="sampleSizeTypeOptions"
                  item-text="name"
                  item-value="name"
                  label="Sampling Methodology"
                  @change="onChangeSamplingMethodology($event, 'product')"
                  readonly
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row v-show="false">
              <v-col cols="12" sm="6">
                <v-select
                  v-model="workOrderQuantityVerificationObj.productResultId"
                  :items="workOrderResultList"
                  item-value="id"
                  item-text="name"
                  label="Result"
                  @change="changeQuantityVerificationResult('productPendingFailCodeId ')"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-if="checkProductResult(workOrderQuantityVerificationObj.productResultId) == 'Pending'"
                  v-model="workOrderQuantityVerificationObj.productPendingFailCodeId"
                  :items="workOrderPendingCode"
                  item-value="id"
                  :item-text="(item) => item.details ? `${item.description} - ${item.details}` : `${item.description}`"
                  :rules="[(v) => !!v || 'Required']"
                  label="Pending Code"
                >
                </v-select>
                <v-select
                  v-if="checkProductResult(workOrderQuantityVerificationObj.productResultId) == 'Fail'"
                  v-model="workOrderQuantityVerificationObj.productPendingFailCodeId"
                  :items="workOrderFailCode"
                  item-value="id"
                  :item-text="(item) => item.details ? `${item.description} - ${item.details}` : `${item.description}`"
                  :rules="[(v) => !!v || 'Required']"
                  label="Fail Code"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <b>Grand Total</b>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field id="txtTotalProductPoQty" hide-details v-model="totalProductPoQty" label="PO Qty" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field id="txtTotalProductAvailablePoQty" hide-details v-model="totalProductAvailablePoQty" label="Available PO Qty" readonly></v-text-field>
              </v-col>
            </v-row>

            <v-data-table
              id="tblProductQuantityVerificationList"
              :sort-by.sync="productSortBy"
              :headers="showHeadersProduct"
              :items="productQuantityVerificationList"
              :hide-default-footer="false"
              item-key="id"
              class="elevation-1 textAlignCenter"
              style="margin-top: 30px;"
              :loading="loadingCartonQuantityVerificationList"
              loading-text="Loading... Please wait"
              @update:options="resetProductIndexMarkers"
              :items-per-page="-1"
            >
              <template v-slot:progress>
                <v-progress-linear indeterminate></v-progress-linear>
              </template>

              <template v-slot:header.underProductionFinishingQty="{ header }">
                {{ header.text }}
                <v-btn class="header-btn calculate" @click="calculateUnderProductionFinishingQty">Calculate</v-btn> 
                <v-btn class="header-btn" @click="clearUnderProductionFinishingQty">Clear</v-btn>               
              </template>

              <template v-slot:body="{ items, pagination }">
                <tbody class="alignToCenter">
                  <tr :id="'row_' + item.skuDisplay" v-for="(item, index) in items" v-show="item.orderNumber == '1'" :key="item.name">
                    <td>
                      <span style="display: none;">{{ (item.indexMarkerProduct = index + pagination.pageStart) }}</span>
                      <span :id="'index_' + item.countIndex"> {{ item.countIndex }} </span>
                    </td>
                    <td>
                      <span :id="'itemProductClientProductID_' + item.countIndex"> {{ item.clientProductId }} </span>
                    </td>
                    <td>
                      <span :id="'itemProductSKU_' + item.countIndex"> {{ item.skuDisplay }} </span>
                    </td>
                    <td>
                      <span :id="'itemProductPo_' + item.countIndex"> {{ item.po }} </span>
                    </td>
                    <td class="w-sm">
                      <v-text-field :id="'txtProductQtyUnit_' + item.countIndex" type="text" hide-details v-model="item.qty" :rules="[rules.required]" @keyup="returnNumber(item, 'qty')" outlined dense></v-text-field>
                    </td>
                    <td class="w-sm">
                      <v-text-field :id="'txtProductShipqty_' + item.countIndex" class="number_text" v-show="item.orderNumber == 1" hide-details v-model="item.shipqtyUnit" type="text" @paste="onPaste($event, item, 'shipqtyUnit')" v-on:keypress="isNumber($event)" outlined dense></v-text-field>
                    </td>
                    <td>
                      <span :id="'itemProductPackedQty_' + item.countIndex"> {{ getTotalPackedQuantityProduct(item.workOrderProductId) }} </span>
                    </td>
                    <td class="w-sm">
                      <v-text-field :id="'txtProductUnderPackingQty_' + item.countIndex"  hide-details v-model="item.underPackingQty" type="text" @paste="onPaste($event, item, 'underPackingQty')" @keyup="onKeyUpAvailableQuantityCarton(item, false)" v-on:keypress="isNumber($event)" outlined dense></v-text-field>
                    </td>
                    <td class="w-sm">
                      <v-text-field :id="'txtProductFinishingQty_' + item.countIndex" type="text" hide-details v-model="item.underProductionFinishingQty" @paste="onPaste($event, item, 'underProductionFinishingQty')" v-on:keypress="isNumber($event)" outlined dense></v-text-field>
                    </td>
                    <td>
                      <span :id="'itemProductAvailableQuantityProduct_' + item.countIndex"> {{ getTotalAvailableQuantityProduct(item.workOrderProductId) }} </span>
                    </td>
                    <td>
                      <span :id="'itemProductExcessShortQuantity_' + item.countIndex"> {{ getExcessShortQtyProduct(item.workOrderProductId, item.qty) }} </span>
                    </td>
                    <td>
                      <span :id="'itemProductPercentAvailable_' + item.countIndex"> {{ getPercentAvailableProduct(item.workOrderProductId, item.qty) }} </span>
                    </td>
                    <td class="w-sm">
                      <v-text-field :id="'txtProductSampleSizeProduct_' + item.countIndex" class="number_text" hide-details v-model="item.sampleSizeProduct" type="text" outlined dense @keyup="returnNumberSampleSize(item,'sampleSizeProduct')"></v-text-field>
                    </td>
                    <td v-show="workOrderList.inspectionLevel == 'Double'">
                      <span :id="'itemSampleSizeCarton2_' + item.countIndex"> {{ item.sampleSizeProduct2 }} </span>
                    </td>
                    <td v-show="workOrderList.inspectionLevel == 'Double'">
                      <span :id="'itemSampleSizeCartonTotal_' + item.countIndex"> {{ item.sampleSizeProductTotal }} </span>
                    </td>
                    <td>
                      <template v-if="item.indexMarkerProduct >= 1">
                        <v-btn icon title="Copy From Above" @click="copyAboveRowProduct(item.indexMarkerProduct)">
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-dialog v-model="showToleranceDialogProduct" width="450">
            <v-card>
              <v-card-title class="headline">Required Confirmation Window</v-card-title>
              <v-card-text>Is it not applicable due to non-availablilty of required from client?</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn id="btnProductToleranceConfirmationYes" color="blue darken-1" text @click="UpdateTolerance('Product', 'Yes')">Yes</v-btn>
                <v-btn id="btnProductToleranceConfirmationNo" color="blue darken-1" text @click="UpdateTolerance('Product', 'No')">No</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-tabs-items>
      </div>
    </div>
    <v-dialog v-model="deleteDialog" max-width="300px">
      <v-card>
        <v-toolbar flat color="pink" dark>
          <v-card-title>
            <span class="headline">Delete Confirmation</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              Are you sure you want to delete?
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn id="btnDeleteDialogCancel" text @click="close">Cancel</v-btn>
          <v-btn id="btnDeleteDialogDelete" color="red" text @click="deleteQuantity()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="remarksDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Remarks & Carton Number Opened</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  disabled
                  readonly
                  id="txtCartonDialogRemarks"
                  v-model="cartonEditedItem.remarks"
                  label="Remarks"
                  auto-grow
                  maxlength="2500"
                  outlined
                  :rules="[(v) => (v || '').length < 2500 || 'Findings must be 2500 characters or less']"
                  rows="2"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  disabled
                  readonly
                  id="txtCartonDialogExportCartonNumberOpened"
                  v-model="cartonEditedItem.exportCartonNumbersOpened"
                  label="Carton numbered opened"
                  auto-grow
                  maxlength="2500"
                  outlined
                  :rules="[(v) => (v || '').length < 2500 || 'Findings must be 2500 characters or less']"
                  rows="2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn id="btnremarksDialogCLose" text @click="close">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar id="quantityVerification_snackbar" v-model="snackbar" :timeout="timeout" :color="color">
      {{ message }}
      <v-btn id="quantityVerification_snackbar_close" text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
    <v-snackbar v-model="delImgSnackbar.isShow">
      {{ delImgSnackbar.text }}
      <v-progress-circular indeterminate color="#ccc"></v-progress-circular>
    </v-snackbar>
  </div>
</template>

<script>
let state = {
  appHeaderState: {
    show: true,
    title: "Quantity Verification",
    showMenu: true,
    showBack: true,
    showLogo: false,
    showTitle: true,
    showSearch: false,
    showCalendar: true,
  },
};

function setState(store) {
  store.dispatch("navigation/setAppHeader", state.appHeaderState);
}

import { mapActions, mapState } from "vuex";
import apiDetails from "../../../shared/request/apiDetails";
import FloatingButton from "../../../components/common/FloatingButton";
import * as _ from "lodash";
import PulseValidator from "@/components/common/PulseValidator";

export default {
  name: "workOrderInspectionQuantityId",
  components: {
    FloatingButton,
    PulseValidator,
  },
  data() {
    return {
      loadingDf: false,
      InspectionLevel: [
        {
          text: "Fixed",
          value: "Fixed",
        },
        {
          text: "Square Root",
          value: "Square Root",
        },
      ],
      snackbar: false,
      color: "red darken-2",
      message: "",
      timeout: 3000,
      SamplingMethodology: [
        {
          text: "Separated Sample Size",
          value: "Separated",
        },
        {
          text: "Combined Sample Size",
          value: "Combined",
        },
      ],
      cartonTabResult: "",
      productTabResult: "",
      showToleranceDialogProduct: false,
      showToleranceDialogCarton: false,
      // 提示框
      delImgSnackbar: {
        isShow: false,
        text: "",
      },
      tab: null,
      showFloatingButton: false,
      samplingPlanLocal: [],
      formTitle: "",
      editedIndex: -1,
      deletedIndex: -1,
      // carton table data

      cartonDialog: false,
      cartonHeader1: [
        {
          text: "Client Product ID",
          align: "center",
          sortable: true,
          value: "clientProductId",
        },
        {
          text: "SKU#",
          align: "left",
          sortable: false,
          value: "skuDisplay",
        },
        {
          text: "PO No.",
          align: "center",
          sortable: false,
          value: "po",
        },
        {
          text: "PO Qty",
          align: "center",
          sortable: false,
          value: "cartonQty",
        },
        {
          text: "Case Count",
          align: "center",
          sortable: false,
          value: "workOrderProductResults.caseCountProduct",
        },
        {
          text: "Available Cartons ",
          align: "center",
          sortable: false,
          value: "workOrderProductResults.availQtyCrtn",
        },
        {
          text: "Sample Size",
          align: "center",
          sortable: false,
          value: "workOrderProductResults.sampleSizeCrtn",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          width: 110,
          value: "menu",
        },
      ],
      cartonHeader: [
        {
          text: "Client Product ID",
          align: "center",
          sortable: true,
          value: "clientProductId",
        },
        {
          text: "SKU#",
          align: "center",
          sortable: true,
          value: "skuDisplay",
        },
        {
          text: "PO No.",
          align: "center",
          sortable: true,
          value: "po",
        },
        {
          text: "PO Qty(Unit)",
          align: "center",
          sortable: false,
          value: "cartonQty",
        },
        {
          text: "Ship Qty(Unit)",
          align: "center",
          sortable: false,
          value: "shipqty",
        },
        {
          text: "Case Count",
          align: "center",
          sortable: false,
          value: "caseCountProduct",
        },
        {
          text: "Available Cartons",
          align: "center",
          sortable: false,
          value: "availableQuantityCarton",
        },
        {
          text: "Sample Size",
          align: "center",
          sortable: false,
          value: "sampleSizeCarton",
        },
        {
          text: "Carton Numbers Opened",
          align: "center",
          sortable: false,
          value: "exportCartonNumbersOpened",
        },
        {
          text: "Remarks",
          align: "center",
          sortable: false,
          value: "remarks",
        },
        // {
        //   text: "Remarks",
        //   align: "center",
        //   sortable: false,
        //   value: "exportCartonNumbersOpened",
        // },

        //  {
        //     text: "Total Sample Size",
        //     align: "center",
        //     sortable: false,
        //     value: "sampleSizeCartonTotal"
        // },

        {
          text: "",
          align: "center",
          sortable: false,
          width: 110,
          value: "menu",
        },
      ],
      cartonEditedItem: {
        productID: "",
        workOrderProductId: "",
        po: "",
        cartonQty: 0,
        caseCountProduct: 0,
        availableQuantityCarton: 0,
        sampleSizeCarton: 0,
        sampleSizeCarton2: 0,
        sampleSizeCartonTotal: 0,
        availableQuantityProduct: 0,
        percentAvailable:0,
        sampleSizeProduct: 0,
        sampleSizeProduct2: 0,
        sampleSizeProductTotal: 0,
        orderNumber: 0,
        fileInput: [],
        uploadPhoto: [],
        serverPhoto: [],
        uploadLoading: false,
        exportCartonNumbersOpened: "",
        remarks: "",
        packedQty: 0,
        underPackingQty: 0,
        underProductionFinishingQty: 0,
      },
      cloneCartonEditedItem: {},
      cartonEditedItem1: {
        productId: "",
        po: "",
        qty: 0,
        cartonQty: 0,
        caseCount: 0,
        actualQty: 0,
        sampleSize: 0,
        fileInput: [],
        uploadPhoto: [],
        serverPhoto: [],
        uploadLoading: false,
      },

      // paroduct table data
      productDialog: false,
      productHeader: [
        {
          text: "#",
          align: "center",
          sortable: true,
          value: "index",
        },
        {
          text: "Client Product ID",
          align: "center",
          sortable: true,
          value: "clientProductId",
        },
        {
          text: "SKU#",
          align: "center",
          sortable: true,
          value: "skuDisplay",
        },
        {
          text: "PO No.",
          align: "center",
          sortable: true,
          value: "po",
        },
        {
          text: "PO Qty(Unit)",
          align: "center",
          sortable: false,
          value: "qty",
        },
        {
          text: "Ship Qty(Unit)",
          align: "center",
          sortable: false,
          value: "shipqtyUnit",
        },
        {
          text: "Packed(Qty)",
          align: "center",
          sortable: false,
          value: "packedQty",
        },
        {
          text: "Under Packing(Qty)",
          align: "center",
          sortable: false,
          value: "underPackingQty",
        },
        {
          text: "Under Production/Finishing(Qty)",
          align: "center",
          sortable: false,
          value: "underProductionFinishingQty",
        },
        {
          text: "Avail Qty(Unit)",
          align: "center",
          sortable: false,
          value: "availableQuantityProduct",
        },
        {
          text: "Excess/-Short Qty",
          align: "center",
          sortable: false,
          value: "excessShortQuantity",
        },
        {
          text: "%",
          align: "center",
          sortable: false,
          value: "percentAvailable",
        },
        {
          text: "Sample Size",
          align: "center",
          sortable: false,
          value: "sampleSizeProduct",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          width: 80,
          value: "menu",
        },
        //   {
        //     text: "Total Sample Size",
        //     align: "center",
        //     sortable: false,
        //     value: "sampleSizeProductTotal"
        // },
      ],

      productHeader1: [
        // { text: 'Product Id', align: 'left',sortable: false,value: 'id'},
        {
          text: "Client Product ID",
          align: "center",
          sortable: true,
          value: "clientProductId",
        },
        {
          text: "SKU#",
          align: "left",
          sortable: false,
          value: "skuDisplay",
        },
        {
          text: "PO No.",
          align: "center",
          sortable: false,
          value: "po",
        },
        {
          text: "PO Qty(Unit)",
          align: "center",
          sortable: false,
          value: "qty",
        },
        {
          text: "Avail Qty(Unit)",
          align: "center",
          sortable: false,
          value: "workOrderProductResults.availQtyPrd",
        },
        {
          text: "Sample Size",
          align: "center",
          sortable: false,
          value: "workOrderProductResults.sampleSizePrd",
        },
      ],

      productEditedItem: {
        skuDisplay: "",
        workOrderProductId: "",
        po: "",
        cartonQty: 0,
        caseCountProduct: 0,
        availableQuantityCarton: 0,
        sampleSizeCarton: 0,
        sampleSizeCarton2: 0,
        sampleSizeCartonTotal: 0,
        availableQuantityProduct: 0,
        percentAvailable:0,
        sampleSizeProduct: 0,
        sampleSizeProduct2: 0,
        sampleSizeProductTotal: 0,
        orderNumber: 0,
        fileInput: [],
        uploadPhoto: [],
        serverPhoto: [],
        uploadLoading: false,
        exportCartonNumbersOpened: "",
        remarks: "",
        packedQty: 0,
        underPackingQty: 0,
        underProductionFinishingQty: 0,
      },
      cloneProductEditedItem: {},
      productEditedItem1: {
        productId: "",
        po: "",
        qty: 0,
        cartonQty: 0,
        actualQty: 0,
        sampleSize: 0,
        fileInput: [],
        uploadPhoto: [],
        serverPhoto: [],
        uploadLoading: false,
      },
      deleteDialog: false,
      deletedItem: {
        workOrderProductId: "",
        po: "",
        cartonQty: 0,
        caseCountProduct: 0,
        availableQuantityCarton: 0,
        sampleSizeCarton: 0,
        sampleSizeCarton2: 0,
        sampleSizeCartonTotal: 0,
        availableQuantityProduct: 0,
        sampleSizeProduct: 0,
        sampleSizeProduct2: 0,
        sampleSizeProductTotal: 0,
        orderNumber: 0,
        fileInput: [],
        uploadPhoto: [],
        serverPhoto: [],
        uploadLoading: false,
      },
      // workOrderProducts: [],
      products: [],
      files: [],
      workOrderId: "",
      workOrder: {
        resources: [],
        workOrderInspectionSections: [],
      },
      workOrderList: [],
      availableQuantityChanges: false,
      samplingPlan: {
        SKUSamplingList: [
          {
            id: "",
            workOrderID: "",
            productId: "",
            SKUDisplay: "",
            PO: "",
            productQty: 0,
            cartonQty: 0,
            productProRata: 0,
            cartonProRata: 0,
            productSampleSize: 0,
            cartonSampleSize: 0,
            actualProductSampleSize: 0,
            actualCartonSampleSize: 0,
            AC: 0,
            RE: 0,
          },
        ],
        totalProductPOQty: 0,
        totalProductSampleSize: 0,
        totalCartonPOQty: 0,
        totalCartonSampleSize: 0,
      },

      inspectionLevelOptions: [],
      inspectionLevel1Options: [],
      sampleSizeTypeOptions: [],
      workOrderProducts: [],

      productQuantityVerificationList: [],
      cloneProductQuantityVerificationList: [],

      workOrderQuantityVerificationObj: {
        workOrderId: null,
        cartonInspectionLevel: null,
        productInspectionLevel: null,
        cartonSamplingMethodology: null,
        productSamplingMethodology: null,
        productResultId: null,
        productPendingFailCodeId: null,
        sectionId: 1,
      },
      workOrderDetail: {
        resources: [],
      },
      initialLogging: true,
      logTransactionCode: null,
      sectionId: 1,
      buttonDisable: false,
      //   showButton: false,

      sectionData: {
        QuantityVerification: {
          id: 1,
          name: "Quantity Verification",
          isAuthorized: false,
          isSectionSubmitted: false,
        },
      },
      OnsiteTests: [],
      remarksDialog: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      isManualSampleSizeProduct: false,
      manualSampleSizeProductId: null,
      shipQtyCartonCDFId: null,
      shipQtyUnitCDFId: null,
      loadingCartonQuantityVerificationList: true,
      isManualSampleSizeCarton: false,
      manualSampleSizeCartonId: null,
      workOrderResultList:[],
      workOrderPendingCode:[],
      workOrderFailCode:[],

      // true if submit button is clicked
      sectionSubmitted: false,
      getDeleteRecord: [],
      deleteRecordList: [],
      workmanshipSampleSize:null,
      cloneOPQVList: [],
      isFormDirty: false,
      isFormReadyCheck: false,

      assignCaseCountToAll: null,
      selectedClientProduct: [],
      productSortBy: ['clientProductId', 'po', 'skuDisplay', 'orderNumber'],
      cartonSortBy: ['clientProductId', 'po', 'skuDisplay', 'orderNumber'],
      isTolerance: false,
      toleranceOption: null,
      productOption: null,
      upperTolerance: 0,
      lowerTolerance: 5,
      overallProductSKU: 0,
      isInvalidTolerance: false,
      reCompute: false
    };
  },
  async activated() {
    if (window.navigator.onLine !== true) {
      let response = await caches.match(this.apiList.workOrderDetailsQuantity + "/" + this.$route.params.id);
      if (!response) {
        this.$router.replace({
          path: "/error",
          name: "error",
        });
      }
    }
  },
  async mounted() {
    if (window.navigator.onLine !== true) {
      let response = await caches.match(this.apiList.workOrderDetailsQuantity + "/" + this.$route.params.id);
      if (!response) {
        this.$router.replace({
          path: "/error",
          name: "error",
        });
      }
    }
    this.utilityFn.getServerData("get", this.apiList.getAllProductSizeResultList, {}, (result) => {
      this.workOrderResultList = result.data.data;
    });
    this.utilityFn.getServerData("get", this.apiList.getAllWorkOrderPendingFailCode, {}, (res) => {
      this.workOrderPendingCode = res.data.data.filter((x) => x.type == "Pending");
      this.workOrderFailCode = res.data.data.filter((x) => x.type == "Fail");
      // this.workOrderFailAndPendingCode()
    });
    setState(this.$store);
    //start loading
    this.loadingDf = true;
    this.$store.dispatch("navigation/setLoadingPage",{show: this.loadingDf });
    //    this.samplingPlanLocal = JSON.parse(localStorage.getItem('samplingPlanList'))
    this.showFloatingButton = this.samplingPlanLocal == undefined ? false : this.samplingPlanLocal.showFabButton == false ? false : true;

    /*  this.utilityFn.getServerData("get", this.apiList.getLookupAsync, {}, (res) => {
      this.inspectionLevelOptions = res.data.data.inspectionClass.filter((x) => x.inspectionClassFieldName.name === "Inspection Level 2");
      this.inspectionLevel1Options = this.inspectionLevelOptions.filter((x) => x.name == "Square Root" || x.name == "Fixed");
      this.sampleSizeTypeOptions = res.data.data.inspectionClass.filter((x) => x.inspectionClassFieldName.name === "Sample Size Type");
    });

    this.utilityFn.getServerData("get", this.apiList.getProductCompanyClientDefinedField + "/" + (this._workOrderDetails.clientId || this._workOrderDetails.companyId), {}, (res) => {
      let getData = res.data.data;
      if (res != undefined && getData.length > 0) {
        let ShipQtyCarton = getData.find((x) => x.label === "Ship Qty Carton");
        let ShipQtyUnit = getData.find((x) => x.label === "Ship Qty Unit");
        this.shipQtyUnitCDFId = ShipQtyUnit ? ShipQtyUnit.id : null
        this.shipQtyCartonCDFId = ShipQtyCarton ? ShipQtyCarton.id :null
      }
    }); */

    let lookupres = await this.utilityFn.getServerDataAsync("get", this.apiList.getLookupAsync, {});
    this.inspectionLevelOptions = lookupres.data.inspectionClass.filter((x) => x.inspectionClassFieldName.name === "Inspection Level 2");
    this.inspectionLevel1Options = this.inspectionLevelOptions.filter((x) => x.name == "Square Root" || x.name == "Fixed");
    this.sampleSizeTypeOptions = lookupres.data.inspectionClass.filter((x) => x.inspectionClassFieldName.name === "Sample Size Type");

    let companycdfres = await this.utilityFn.getServerDataAsync("get", this.apiList.getProductCompanyClientDefinedField + "/" + (this._workOrderDetails.clientId || this._workOrderDetails.companyId), {});
    
    if (companycdfres != undefined && companycdfres.data.length > 0) {
      let getData = companycdfres.data;
      let ShipQtyCarton = getData.find((x) => x.label === "Ship Qty Carton");
      let ShipQtyUnit = getData.find((x) => x.label === "Ship Qty Unit");
      this.shipQtyUnitCDFId = ShipQtyUnit ? ShipQtyUnit.id : null;
      this.shipQtyCartonCDFId = ShipQtyCarton ? ShipQtyCarton.id : null;
    }

    //Get OnsiteTest

    this.workOrderId = this.$route.params.id;


    this.loadWorkOrderList(this.workOrderId);

    this.sectionData = this._sectionData.QuantityVerification ? this._sectionData : this.sectionData;
  },

  computed: {
    ...mapState("inspection", {
      store_token: (state) => state.token,
      _sectionData: (state) => state.sectionData,
      _samplingPlanLists: (state) => state.samplingPlanLists,
      _workOrderDetails: (state) => state.workOrderDetails,
      inspectionSummarySectionResult: (state) => state.inspectionSummarySectionResult,
    }),

    showHeadersCarton() {
      if (this.shipQtyCartonCDFId == null) {
        return this.cartonHeader.filter((item) => item.text != "Ship Qty(Unit)");
      }
      return this.cartonHeader;
    },

    showHeadersProduct() {
      // if (this.shipQtyUnitCDFId == null) {
      //   return this.productHeader.filter((item) => item.text != "Ship Qty(Unit)");
      // }
      return this.productHeader;
    },

    totalCartonPoQty: function() {
      let total = this.products.reduce(function(prev, cur) {
        return Number(prev) + Number(cur.cartonQty);
      }, 0);
      return total;
    },

    totalCartonAvailablePoQty: function() {
      let productQuantityVerificationList = this.productQuantityVerificationList.filter((x) => x.availableQuantityCarton != -1);
      let total = productQuantityVerificationList.reduce(function(prev, cur) {
        return Number(prev) + Number(cur.availableQuantityCarton);
      }, 0);
      return total;
    },

    cartonAvailablePercent: function() {
      let val = 0;
      if (this.totalCartonPoQty != 0) {
        val = (this.totalCartonAvailablePoQty / this.totalCartonPoQty) * 100;
      }
      return Math.round(val);
    },
    totalProductPoQty: function() {
      let productQuantityVerificationList = this.productQuantityVerificationList.filter((x) => x.availableQuantityCarton != -1 && x.orderNumber == 1);
      let total = null;
      let sumPoQty = productQuantityVerificationList.reduce(function(tot, arr) {
        total += Number(arr.qty);
        return total;
      }, null);
      return sumPoQty;
    },

    totalProductAvailablePoQty: function() {
      let productQuantityVerificationList = this.productQuantityVerificationList.filter((x) => x.availableQuantityCarton != -1 && x.orderNumber == 1);
      let total = 0;
      productQuantityVerificationList.forEach((item) => {
        total += Number(this.getTotalAvailableQuantityProduct(item.workOrderProductId));
      });
      return total;
    },

    mappedProductQuantityVerificationList: function() {
      return this.productQuantityVerificationList.map((x) => {
        x.packedQty = x.availableQuantityCarton * x.caseCountProduct;
        return x;
      });
    },

    productAvailablePercent: function() {
      let val = 0;
      if (this.totalProductPoQty != 0) {
        val = (this.totalProductAvailablePoQty / this.totalProductPoQty) * 100;
      }
      return Math.round(val);
    },

    sectionDetail() {
      let section = this._workOrderDetails.workOrderInspectionSections.find((x) => x.sectionId == this.sectionId);
      if (section) {
        section.submitterId = this.utilityFn.getResourceId().toLowerCase();
      } else {
        section = {
          workOrderId: this.workOrder.workOrderId,
          sectionId: this.sectionId,
          isHidden: false,
          submitterId: this.utilityFn.getResourceId().toLowerCase(),
        };
      }

      return section;
    },

    isSectionDisabled() {
      return this.workOrder.resources.length == 0 || (this.workOrder.resources.length > 1 && this.sectionDetail.resourceId.toLowerCase() != this.utilityFn.getResourceId().toLowerCase()) || this.sectionDetail.isHidden || (!!this.sectionDetail.submittedDate && this.workOrder.resources.length > 1);
    },

    showButton() {
      return this.workOrder.resources.length > 1;
    },

    /**
     * @returns List of errors base on the conditions
     */
    errorsProductTab() {
      const _errors = [];
      this.productQuantityVerificationList.forEach((wop, index) => {
        if(wop.orderNumber == 1){
          const atIndex = " at index " + (index + 1);
          if (!wop.qty) {
            _errors.push("Invalid PO Qty(Unit) " + atIndex);
          }
          if (!wop.sampleSizeProduct) {
            _errors.push("Invalid Sample Size " + atIndex);
          }
        }
      });
      return _errors;
    },

    // will be a flag for the submit form in the footer
    isDataLoaded() {
      return this.products.length > 0 && !this.loadingCartonQuantityVerificationList;
    },
    requiredLabel() {
      return (this._workOrderDetails.clientName.toLowerCase() === 'walmart global sourcing' || this._workOrderDetails.clientName.toLowerCase() === 'asda stores limited') && this._workOrderDetails.isPreShipOrFinalRandom ? 'Required(%) *' : 'Required(%)';
    }
  },
  filters: {
    percent(val) {
      return val * 100 + "%";
    },
  },
  beforeRouteLeave(to, from, next) {
    this.validateTolerance();

    if(this.isInvalidTolerance) {
      next(false);
      return;
    }

    if (to.path !== "/error") {
      if(!this.loadingDf) {
        this.saveData();
      }
    }

    if (window.navigator.onLine != true) {
      this.message = "No Connection ! Data Will be Synced once Network Connectivity is established";
      this.snackbar = true;
      this.close();
    }
    next();
  },

  beforeDestroy(to, form, next) {
    // let section = this.workOrder.workOrderInspectionSections.find((x) => x.sectionId == this.sectionId);
    // let result = "";
    // //Carton
    // if (this.workOrderList.cartonRequired == "" || this.workOrderList.cartonRequired == null) {
    //   this.cartonTabResult = "";
    // } else {
    //   if (this.workOrderList.cartonRequired == -1) {
    //     this.cartonTabResult = "N/A";
    //     if (this.workOrderList.qvToleranceCrt == true) {
    //       this.cartonTabResult = "Pending";
    //     }
    //   } else {
    //     let product = this.products.filter((x) => x.workOrderProductResults.availQtyCrtn == -1);
    //     if (product.length == 0) {
    //       this.cartonTabResult = this.quantityVerResultCarton() ? "Pass" : "Fail";
    //     } else {
    //       this.cartonTabResult = "N/A";
    //     }
    //   }
    // }
    // //Product
    // if (this.workOrderList.productRequired == "" || this.workOrderList.productRequired == null) {
    //   this.productTabResult = "";
    // } else {
    //   if (this.workOrderList.productRequired == -1) {
    //     this.productTabResult = "N/A";
    //     if (this.workOrderList.qvTolerancePrd == true) {
    //       this.productTabResult = "Pending";
    //     }
    //   } else {
    //     let product = this.products.filter((x) => x.workOrderProductResults.availQtyPrd == -1);
    //     if (product.length == 0) {
    //       this.productTabResult = this.quantityVerResultProduct() ? "Pass" : "Fail";
    //     } else {
    //       this.productTabResult = "N/A";
    //     }
    //   }
    // }
    // // this.SaveCarton()
    // switch (this.cartonTabResult) {
    //   case "Pass":
    //     switch (this.productTabResult) {
    //       case "Pass":
    //         result = "Conformed";
    //         break;
    //       case "Fail":
    //         result = "Non-Conformed";
    //         break;
    //       case "Pending":
    //         result = "Pending";
    //         break;
    //       case "N/A":
    //         result = "Conformed";
    //         break;
    //     }
    //     break;
    //   case "Fail":
    //     switch (this.productTabResult) {
    //       case "Pass":
    //         result = "Non-Conformed";
    //         break;
    //       case "Fail":
    //         result = "Non-Conformed";
    //         break;
    //       case "Pending":
    //         result = "Non-Conformed";
    //         break;
    //       case "N/A":
    //         result = "Non-Conformed";
    //         break;
    //     }
    //     break;
    //   case "Pending":
    //     switch (this.productTabResult) {
    //       case "Pass":
    //         result = "Pending";
    //         break;
    //       case "Fail":
    //         result = "Non-Conformed";
    //         break;
    //       case "Pending":
    //         result = "Pending";
    //         break;
    //       case "N/A":
    //         result = "Pending";
    //         break;
    //     }
    //     break;
    //   case "N/A":
    //     switch (this.productTabResult) {
    //       case "Pass":
    //         result = "Conformed";
    //         break;
    //       case "Fail":
    //         result = "Non-Conformed";
    //         break;
    //       case "Pending":
    //         result = "Pending";
    //         break;
    //       case "N/A":
    //         result = "N/A";
    //         break;
    //     }
    //     break;
    // }
    // if (!this.isSectionDisabled || this.sectionSubmitted) {
    //   const section = this.sectionDetail;
    //   section.result = result;
    //   this.utilityFn.getServerData("post", this.apiList.sectionUpdate, section, (response) => {
    //     const index = this._workOrderDetails.workOrderInspectionSections.findIndex((x) => x.sectionId == this.sectionId);
    //     if (index !== -1) {
    //       this._workOrderDetails.workOrderInspectionSections[index] = section;
    //       this.$store.dispatch("inspection/setWorkOrderDetails", _.cloneDeep(this._workOrderDetails));
    //     }
    //   });
    // }
    // if (this.workOrder.resources.length === 1) {
    //   this.submit_item();
    // }
    // this.logChanges(false);
    // next();
  },
  methods: {
     isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    onPaste(event, obj, key) {
      obj[key] = (event.clipboardData.getData('text'))?.trim().replace(/\D/g,'');
      event.preventDefault();
    },

    onPasteToAssignAll(event) {
      this.assignCaseCountToAll = (event.clipboardData.getData('text'))?.trim().replace(/\D/g,'');
      event.preventDefault();
    },

    cartonNumbersOpenedRules(v) {
      if (!v)
        return true

      if (!(/^([\d.\s,]+)$/i.test(v)))
        return 'Invalid Carton Number'; 

      if (!(/^(\s*-?\d+(\.\d+)?)(\s*,\s*-?\d+(\.\d+)?)*$/i.test(v)))
        return 'Please separate carton number by a comma'

      return true
    },

    cartonNumbersKeyDown(event) {
      console.log(event);
    },

    checkProductResult(productResult) {
      let value = "";
      if (this.workOrderResultList.filter((x) => x.id == productResult).length != 0) {
        value = this.workOrderResultList.filter((x) => x.id == productResult)[0].name;
      }
      return value;
    },
    changeQuantityVerificationResult(key) {
      this.workOrderQuantityVerificationObj[key] = null;
    },
    changeAvailableCartons(actualQty) {
      if (actualQty == -1) {
        this.cartonEditedItem.sampleSizeCarton = -1;
      }
    },
    changeAvailableProduct(actualQty) {
      if (actualQty == -1) {
        this.productEditedItem.sampleSizeProduct = -1;
      }
    },
    UpdateTolerance(type, res) {
      switch (type) {
        case "Carton":
          var other = {
            headers: {
              wresult: this.store_token,
              "Content-Type": "application/json",
            },
          };
          const data = [
            {
              op: "replace",
              path: "/qvtolerancecrt",
              value: res == "Yes" ? true : false,
            },
          ];
          this.utilityFn.getServerData("patch", this.apiList.patchWorkOrderResults + "/" + this.workOrderId + "/1", data, (res) => {}, other);
          this.workOrderList.qvToleranceCrt = res == "Yes" ? true : false;
          this.showToleranceDialogCarton = false;

          break;
        case "Product":
          var other = {
            headers: {
              wresult: this.store_token,
              "Content-Type": "application/json",
            },
          };
          const data1 = [
            {
              op: "replace",
              path: "/qvTolerancePrd",
              value: res == "Yes" ? true : false,
            },
          ];
          this.utilityFn.getServerData("patch", this.apiList.patchWorkOrderResults + "/" + this.workOrderId + "/1", data1, (res) => {}, other);
          this.workOrderList.qvTolerancePrd = res == "Yes" ? true : false;
          this.showToleranceDialogProduct = false;
          break;
      }
    },
    loadSamplingSize() {

   

      let samplingMethodology = this._samplingPlanLists.samplingMethodology;
      let inspectionLevel = this._samplingPlanLists.inspectionLevel
      let samplingPlan1 = this._samplingPlanLists.samplingPlan1;
      let round =this._samplingPlanLists.round;
      let productType = this._samplingPlanLists.productType;    
      let aqlMinor = this._samplingPlanLists.aqlMinor;
      let aqlMajor = this._samplingPlanLists.aqlMajor;
      let aqlCritical = this._samplingPlanLists.aqlCritical;
      let workorderId = this._samplingPlanLists.workorderId

      let workorderSamplingParameters = {
        samplingMethodology : samplingMethodology,
        inspectionLevel : inspectionLevel,
        samplingPlan1 : samplingPlan1,
        round : round,
        productType : productType,
        aqlMinor:aqlMinor,
        aqlMajor: aqlMajor,
        aqlCritical: aqlCritical,
        workorderId : workorderId,
        changedSamplingMethodology: null
      }

      this.samplingPlan.SKUSamplingList = this.products.map((x) => {
        return {
          id: x.id,
          workOrderID: x.workOrderID,
          productId: x.productID,
          SKUDisplay: x.skuDisplay,
          PO: x.po,
          productQty: this.getTotalAvailableQuantityProduct(x.id),
          cartonQty: this.getTotalAvailableQuantityCarton(x.id),
          productSampleSize: 0,
          cartonSampleSize: 0,
          actualProductSampleSize: 0,
          actualCartonSampleSize: 0,
          aqlMinor:aqlMinor,
          aqlMajor: aqlMajor,
          aqlCritical: aqlCritical,
          minorAC: 0,
          minorRE: 0,
          majorAC: 0,
          majorRE: 0,
          criticalAC: 0,
          criticalRE: 0,
        };
      });

      let returnedSampleSizePlan = this.samPlan.getAutoComputeSampleSize( samplingMethodology, this.samplingPlan.SKUSamplingList, inspectionLevel, samplingPlan1, round, productType,"QuantityVerification", true);

      let samplinPlan = {...workorderSamplingParameters, ...returnedSampleSizePlan};
      this.$store.dispatch("inspection/setSamplingPlanList", samplinPlan);
      this.$emit("updateShowFloating", this.showFloatingButton);
      this.upsertWorkOrderProductResultSampling(returnedSampleSizePlan);
      this.availableQuantityChanges = false;

      return returnedSampleSizePlan;
    },

    upsertWorkOrderProductResultSampling(returnedSampleSizePlan) {
      returnedSampleSizePlan.SKUSamplingList.forEach((element) => {
        let workOrderProductID = element.id;
        let sampleSizePrd = element.productSampleSize == undefined ? null : element.productSampleSize;
        let sampleSizeProductWorkmanship = element.productSampleSize == undefined ? null : element.productSampleSize;
        let sampleSizeCrtn = element.cartonSampleSize == undefined ? null : element.cartonSampleSize;
        // let sampleSizeCartonSizeMeasurement = element.cartonSampleSize == undefined ? null : element.cartonSampleSize;
        // let samlpleSizeCartonWeightMeasurement = element.cartonSampleSize == undefined ? null : element.cartonSampleSize;
        // let allowedMinorPO = element.minorAC == undefined ? null : element.minorAC;
        // let allowedMajorPO = element.majorAC == undefined ? null : element.majorAC;
        // let allowedCriticalPO = element.criticalAC == undefined ? null : element.criticalAC;

        const formPatch = [
          {
            op: "replace",
            path: "/sampleSizePrd",
            value: sampleSizePrd,
          },
          {
            op: "replace",
            path: "/sampleSizeProductWorkmanship",
            value: sampleSizeProductWorkmanship,
          },
          {
            op: "replace",
            path: "/sampleSizeCrtn",
            value: sampleSizeCrtn,
          },
          // {
          //   op: "replace",
          //   path: "/allowedMinorPO",
          //   value: allowedMinorPO,
          // },
          // {
          //   op: "replace",
          //   path: "/allowedMajorPO",
          //   value: allowedMajorPO,
          // },
          // {
          //   op: "replace",
          //   path: "/allowedCriticalPO",
          //   value: allowedCriticalPO,
          // },
        ];

        this.apiFn.upsertProductResult(workOrderProductID, formPatch).then((result) => {
          //this.loadWorkOrderList(this.workOrderId);
        });
      });

      {
      }
    },

    quantityVerResultCarton() {
      let result = true;
      let productLength = this.products.length;
      let workOrderProductResults = this.products.filter((x) => x.workOrderProductResults.length != 0);
      if (productLength == workOrderProductResults.length) {
        let productResultCompare = workOrderProductResults.filter((x) => {
          let productQuantityVerificationList = this.productQuantityVerificationList.filter((y) => y.workOrderProductId == x.id);
          let availQtyCrtn = productQuantityVerificationList.reduce(function(prev, cur) {
            return Number(prev) + Number(cur.availableQuantityCarton);
          }, 0);

          if (availQtyCrtn >= (x.cartonQty * this.workOrderList.cartonRequired) / 100) {
            return x;
          }
        });
        if (productResultCompare.length == productLength) {
          result = true;
        } else {
          result = false;
        }
      } else {
        result = false;
      }
      return result;
    },
    quantityVerResultProduct() {
      let result = true;
      let productLength = this.products.length;
      let workOrderProductResults = this.products.filter((x) => x.workOrderProductResults.length != 0);
      if (productLength == workOrderProductResults.length) {
        let productResultCompare = workOrderProductResults.filter((x) => {
          let productQuantityVerificationList = this.productQuantityVerificationList.filter((y) => y.workOrderProductId == x.id);
          let availQtyPrd = productQuantityVerificationList.reduce(function(prev, cur) {
            return Number(prev) + Number(cur.availableQuantityProduct);
          }, 0);

          if (availQtyPrd >= (x.qty * this.workOrderList.productRequired) / 100) {
            return x;
          }
        });
        if (productResultCompare.length == productLength) {
          result = true;
        } else {
          result = false;
        }
      } else {
        result = false;
      }
      return result;
    },

    updateworkOrderQuantityVerificationObj() {
      this.workOrderQuantityVerificationObj.workOrderId = this.workOrderId;

      this.workOrderQuantityVerificationObj.cartonInspectionLevel = this.workOrderQuantityVerificationObj.cartonInspectionLevel == null ? "Square Root" : this.workOrderQuantityVerificationObj.cartonInspectionLevel;
      this.workOrderQuantityVerificationObj.productInspectionLevel = this.workOrderQuantityVerificationObj.productInspectionLevel == null ? this.workOrder.aqlLevels : this.workOrderQuantityVerificationObj.productInspectionLevel;

      // this.workOrderQuantityVerificationObj.cartonSamplingMethodology = this.workOrderQuantityVerificationObj.cartonSamplingMethodology == null ? this.workOrder.samplingMethodology : this.workOrderQuantityVerificationObj.cartonSamplingMethodology;
      // this.workOrderQuantityVerificationObj.productSamplingMethodology = this.workOrderQuantityVerificationObj.productSamplingMethodology == null ? this.workOrder.samplingMethodology : this.workOrderQuantityVerificationObj.productSamplingMethodology;
      this.workOrderQuantityVerificationObj.cartonSamplingMethodology = this.workOrder.samplingMethodology;
      this.workOrderQuantityVerificationObj.productSamplingMethodology = this.workOrder.samplingMethodology ;

         this.workOrderQuantityVerificationObj.productResultId = this.workOrderQuantityVerificationObj.productResultId == null ? null : this.workOrderQuantityVerificationObj.productResultId;
      this.workOrderQuantityVerificationObj.productPendingFailCodeId  = this.workOrderQuantityVerificationObj.productPendingFailCodeId  == null ? null : this.workOrderQuantityVerificationObj.productPendingFailCodeId ;

      // //update back in store
      // let workDetails = {
      //   ...this._workOrderDetails,
      //   quantityVerification: this.workOrderQuantityVerificationObj,
      // };

      // this.$store.dispatch("inspection/setWorkOrderDetails", workDetails);

      this._workOrderDetails.quantityVerification = this.workOrderQuantityVerificationObj;

      this.$store.dispatch("inspection/setWorkOrderDetails", this._workOrderDetails);

      this.onChange();

      return new Promise((resolve, reject) => {
        this.utilityFn.getServerData("post", this.apiList.upsertWorkOrderQuantityVerification, this.workOrderQuantityVerificationObj, (result) => {
          resolve();
        });
      });
    },

    populate(workOrderQuantityVerificationDetails) {
      if (!workOrderQuantityVerificationDetails) return;
      this.workOrderQuantityVerificationObj.cartonInspectionLevel = workOrderQuantityVerificationDetails.cartonInspectionLevel == null ? "Square Root" : workOrderQuantityVerificationDetails.cartonInspectionLevel;
      this.workOrderQuantityVerificationObj.productInspectionLevel = workOrderQuantityVerificationDetails.productInspectionLevel == null ? this.workOrder.aqlLevels : workOrderQuantityVerificationDetails.productInspectionLevel;
      this.workOrderQuantityVerificationObj.cartonSamplingMethodology = this._samplingPlanLists.samplingMethodology ?  this._samplingPlanLists.samplingMethodology : this.workOrder.samplingMethodology ;
      this.workOrderQuantityVerificationObj.productSamplingMethodology =  this._samplingPlanLists.samplingMethodology ?  this._samplingPlanLists.samplingMethodology : this.workOrder.samplingMethodology ;
      //this.workOrderQuantityVerificationObj.productResultId =  workOrderQuantityVerificationDetails.productResultId  == null ? productResult : workOrderQuantityVerificationDetails.productResultId      
      this.workOrderQuantityVerificationObj.productResultId  = workOrderQuantityVerificationDetails.productResultId ;    
    
      this.workOrderQuantityVerificationObj.productPendingFailCodeId  = workOrderQuantityVerificationDetails.productPendingFailCodeId ;    
          
    },

    getSquareRootCarton(quantity, totalCartons, samplingMethodology) {
      let squareRootCarton = null;
      if (quantity == null || quantity == '' ) {
        return squareRootCarton;
      }

      if (this.products.length == 1) {
        return (squareRootCarton = Math.ceil(Math.sqrt(quantity)));
      }

      if (this.products.length > 1 && samplingMethodology == "Combined Sample Size") {
        return (squareRootCarton = Math.ceil((Math.sqrt(totalCartons) / totalCartons) * quantity));
      }

      if (this.products.length > 1) {
        return (squareRootCarton = Math.ceil(Math.sqrt(quantity)));
      }
    },

    onChangeInspectionLevel(value, tab) {
      this.updateworkOrderQuantityVerificationObj().then(() => {});

      if (tab == "carton") {
        this.calculateSampleSize(value, this.workOrderQuantityVerificationObj.cartonSamplingMethodology, tab);
      }
    },

    onChangeSamplingMethodology(value, tab) {
      this.updateworkOrderQuantityVerificationObj().then(() => {});

      if (tab == "carton") {
        this.calculateSampleSize(this.workOrderQuantityVerificationObj.cartonInspectionLevel, value, tab);
      } else {
        this.calculateSampleSize(this.workOrderQuantityVerificationObj.productInspectionLevel, value, tab);
      }
    },


    calculateUnderProductionFinishingQty() {
      this.productQuantityVerificationList.forEach(x => {
        if (x.orderNumber == 1){
          x.underProductionFinishingQty = parseFloat(x.shipqtyUnit, 0) 
                                            - parseFloat(this.getTotalPackedQuantityProduct(x.workOrderProductId), 0) 
                                            - parseFloat((x.underPackingQty =x.underPackingQty || 0 ), 0)
        }else{
          x.underProductionFinishingQty = 0;
        }
      });
    },
    
    clearUnderProductionFinishingQty() {
      this.productQuantityVerificationList.forEach(x => {
        x.underProductionFinishingQty = 0
      });
    },

    distributeFractionCarton(){
      if( this.totalCartonAvailablePoQty > 0 &&this.workOrderQuantityVerificationObj.cartonInspectionLevel == "Square Root" && this.workOrderQuantityVerificationObj.cartonSamplingMethodology =="Combined Sample Size"){
        let totalSampleSizeCarton = Math.ceil(Math.sqrt(this.totalCartonAvailablePoQty)) 
        let sumSampleSizeCarton =  Number(this.productQuantityVerificationList.reduce((prev, cur) => prev + Math.ceil(cur.sampleSizeCarton), 0));   
         sumSampleSizeCarton = isNaN(sumSampleSizeCarton) ? 0 : sumSampleSizeCarton  
        
        
        //add sample size if sumSampleSizeProduct  is lower than totalSampleSizeProduct
        if(sumSampleSizeCarton < totalSampleSizeCarton){
            for(let i=0; i < this.productQuantityVerificationList.length; ){
                if(sumSampleSizeCarton != totalSampleSizeCarton ){   
                    if(this.productQuantityVerificationList[i].sampleSizeCarton < this.productQuantityVerificationList[i].availableQuantityCarton ){
                      this.productQuantityVerificationList[i].sampleSizeCarton++      
                      sumSampleSizeCarton++    
                    }            
                    i++ 
                    if(sumSampleSizeCarton != totalSampleSizeCarton && i == this.productQuantityVerificationList.length){
                      i=0;
                    }
                }
                else{            
                    break;
                }
            }
        }
        //subtract sample size if sumSampleSizeProduct  is greater than totalSampleSizeProduct
        else{
          for(let i = 0; i < this.productQuantityVerificationList.length;){
            if(sumSampleSizeCarton != totalSampleSizeCarton && sumSampleSizeCarton != this.productQuantityVerificationList.length){   
              if(this.productQuantityVerificationList[i].sampleSizeCarton > 0 ){
                if (this.productQuantityVerificationList[i].sampleSizeCarton > 1){
                  this.productQuantityVerificationList[i].sampleSizeCarton--            
                  sumSampleSizeCarton--
                }
              }

              i++;

              if(sumSampleSizeCarton != totalSampleSizeCarton && i == this.productQuantityVerificationList.length && this.productQuantityVerificationList.some(x => x.sampleSizeCarton > 1)){
                i=0;
              }
            }
            else {
              break;
            }
          }   
        }
      }
    },


    calculateSampleSize(inspectionLevel, samplingMethodology, tab) {
      this.onChange();
      let workOrderProductQuantityVerificationResultObjList = [];
      let samplingPlanByInspectionLevelAndSamplingMethodology = [];
      let SKUSamplingList = this.products.map((x) => {
        return {
          id: x.id,
          workOrderID: x.workOrderID,
          productId: x.productID,
          SKUDisplay: x.skuDisplay,
          PO: x.po,
          productQty: this.getTotalAvailableQuantityProduct(x.id),
          cartonQty: this.getTotalAvailableQuantityCarton(x.id),
          productSampleSize: 0,
          cartonSampleSize: 0,
          actualProductSampleSize: 0,
          actualCartonSampleSize: 0,
          aqlMinor: this.workOrder.aqlMinor,
          aqlMajor: this.workOrder.aqlMajor,
          aqlCritical: this.workOrder.aqlCritical,
          minorAC: 0,
          minorRE: 0,
          majorAC: 0,
          majorRE: 0,
          criticalAC: 0,
          criticalRE: 0,
        };
      });

      if (tab == "product") {
      let inspectionLevel = this._samplingPlanLists.inspectionLevel
      let samplingPlan1 = this._samplingPlanLists.samplingPlan1;
      let round =this._samplingPlanLists.round;
      let productType = this._samplingPlanLists.productType;    
      let aqlMinor = this._samplingPlanLists.aqlMinor;
      let aqlMajor = this._samplingPlanLists.aqlMajor;
      let aqlCritical = this._samplingPlanLists.aqlCritical;
      let workorderId = this._samplingPlanLists.workorderId

      let workorderSamplingParameters = {
        samplingMethodology : samplingMethodology,
        inspectionLevel : inspectionLevel,
        samplingPlan1 : samplingPlan1,
        round : round,
        productType : productType,
        aqlMinor:aqlMinor,
        aqlMajor: aqlMajor,
        aqlCritical: aqlCritical,
        workorderId : workorderId,
        changedSamplingMethodology: true
      }

          samplingPlanByInspectionLevelAndSamplingMethodology = this.samPlan.getQVSamplingPlanByInspectionLevelAndSamplingMethodology(
          SKUSamplingList,
          inspectionLevel,
          samplingMethodology,
          samplingPlan1,
          productType,
          round,
          true
        );

      let samplinPlan = {...workorderSamplingParameters, ...samplingPlanByInspectionLevelAndSamplingMethodology};
      this.$store.dispatch("inspection/setSamplingPlanList", samplinPlan)   

      }
      this.productQuantityVerificationList.forEach((item) => {
        let cartonSampleSize = null;
        let productSampleSize = null;

        if (tab == "carton") {
          if (inspectionLevel == "Fixed") {
            cartonSampleSize = null;
          } else if (inspectionLevel == "Square Root") {
            cartonSampleSize = this.getSquareRootCarton(item.availableQuantityCarton, this.totalCartonAvailablePoQty, samplingMethodology);
          }
        } else {
          let filteredSKUSamplingList = samplingPlanByInspectionLevelAndSamplingMethodology.SKUSamplingList.filter((x) => x.id == item.workOrderProductId);
          if (filteredSKUSamplingList.length > 0) {
            productSampleSize = filteredSKUSamplingList[0].productSampleSize;
          }
        }

        const addedRowQuantity = {
          id: item.id,
          workOrderProductId: item.workOrderProductId,
          caseCountProduct: item.caseCountProduct,
          availableQuantityCarton: item.availableQuantityCarton,
          sampleSizeCarton: item.availableQuantityCarton == -1 ? -1 : tab == "carton" ? cartonSampleSize : item.sampleSizeCarton,
          availableQuantityProduct: item.availableQuantityCarton == -1 ? -1 : item.availableQuantityProduct,
          excessShortQuantity: item.availableQuantityCarton == -1 ? -1 : item.excessShortQuantity,
          percentAvailable: item.availableQuantityCarton == -1 ? -1 : item.percentAvailable,
          sampleSizeProduct: item.availableQuantityCarton == -1 ? -1 : tab == "product" ? productSampleSize : item.sampleSizeProduct,
          orderNumber: item.orderNumber,
          remarks: item.remarks,
          exportCartonNumbersOpened: item.exportCartonNumbersOpened,
          packedQty: item.packedQty,
          underPackingQty: item.underPackingQty,
          underProductionFinishingQty: item.underProductionFinishingQty,
          sectionId: 1,
        };

        workOrderProductQuantityVerificationResultObjList.push(addedRowQuantity);
        item.sampleSizeCarton = item.availableQuantityCarton == -1 ? -1 : tab == "carton" ? cartonSampleSize : item.sampleSizeCarton;
        item.sampleSizeProduct = item.availableQuantityCarton == -1 ? -1 : tab == "product" ? productSampleSize : item.sampleSizeProduct;
      }); // end forEach item in productQuantityVerificationList
      
     
      
      if (workOrderProductQuantityVerificationResultObjList.length > 0) {
        this.utilityFn.getServerData("post", this.apiList.upsertWorkOrderProductQuantityVerificationResult, workOrderProductQuantityVerificationResultObjList, (res) => {});
      }

      if (tab == "product") {
        this.workmanshipSampleSize = samplingPlanByInspectionLevelAndSamplingMethodology.totalProductSampleSize
        let skuCount = samplingPlanByInspectionLevelAndSamplingMethodology.SKUSamplingList.length;
        let overallCriticalAC =
          this.workOrderQuantityVerificationObj.productSamplingMethodology == "Separated Sample Size"
            ? null
            : skuCount > 1 && this.workOrderQuantityVerificationObj.productSamplingMethodology == null
            ? null
            : samplingPlanByInspectionLevelAndSamplingMethodology.overallCriticalAC;
        let overallMajorAC =
          this.workOrderQuantityVerificationObj.productSamplingMethodology == "Separated Sample Size"
            ? null
            : skuCount > 1 && this.workOrderQuantityVerificationObj.productSamplingMethodology == null
            ? null
            : samplingPlanByInspectionLevelAndSamplingMethodology.overallMajorAC;
        let overallMinorAC =
          this.workOrderQuantityVerificationObj.productSamplingMethodology == "Separated Sample Size"
            ? null
            : skuCount > 1 && this.workOrderQuantityVerificationObj.productSamplingMethodology == null
            ? null
            : samplingPlanByInspectionLevelAndSamplingMethodology.overallMinorAC;

        let overallCriticalRE =
          this.workOrderQuantityVerificationObj.productSamplingMethodology == "Separated Sample Size"
            ? null
            : skuCount > 1 && this.workOrderQuantityVerificationObj.productSamplingMethodology == null
            ? null
            : samplingPlanByInspectionLevelAndSamplingMethodology.overallCriticalRE;
        let overallMajorRE =
          this.workOrderQuantityVerificationObj.productSamplingMethodology == "Separated Sample Size"
            ? null
            : skuCount > 1 && this.workOrderQuantityVerificationObj.productSamplingMethodology == null
            ? null
            : samplingPlanByInspectionLevelAndSamplingMethodology.overallMajorRE;
        let overallMinorRE =
          this.workOrderQuantityVerificationObj.productSamplingMethodology == "Separated Sample Size"
            ? null
            : skuCount > 1 && this.workOrderQuantityVerificationObj.productSamplingMethodology == null
            ? null
            : samplingPlanByInspectionLevelAndSamplingMethodology.overallMinorRE;

        var data = [
          {
            op: "replace",
            path: "/sampleSize",
            value: samplingPlanByInspectionLevelAndSamplingMethodology.totalProductSampleSize,
          },
          {
            op: "replace",
            path: "/workmanshipSampleSize",
            value: this.workmanshipSampleSize,
          },
          {
            op: "replace",
            path: "/samplingMethodology",
            value: this.workOrderQuantityVerificationObj.productSamplingMethodology,
          },

          // {
          //   op: "replace",
          //   path: "/allowedCritical",
          //   value: overallCriticalAC,
          // },
          // {
          //   op: "replace",
          //   path: "/allowedMajor",
          //   value: overallMajorAC,
          // },
          // {
          //   op: "replace",
          //   path: "/allowedMinor",
          //   value: overallMinorAC,
          // },

          // {
          //   op: "replace",
          //   path: "/rejectedCritical",
          //   value: overallCriticalRE,
          // },
          // {
          //   op: "replace",
          //   path: "/rejectedMajor",
          //   value: overallMajorRE,
          // },
          // {
          //   op: "replace",
          //   path: "/rejectedMinor",
          //   value: overallMinorRE,
          // },
        ];

        this.utilityFn.getServerData("patch", this.apiList.patchWorkOrderResults + "/" + this.workOrderId + "/1", data, (res) => {});
        // this.distributeFractionCarton()
      }

       this.distributeFractionCarton();
        this.$store.dispatch("inspection/setProductQuantityVerificationList", _.cloneDeep(this.productQuantityVerificationList));
    },

    calculateSampleSizeCartonProduct(cartonInspectionLevel, productInspectionLevel, cartonSamplingMethodology, productSamplingMethodology) {
      this.onChange();
      let workOrderProductQuantityVerificationResultObjList = [];
      let SKUSamplingList = this.products.map((x) => {
        return {
          id: x.id,
          workOrderID: x.workOrderID,
          productId: x.productID,
          SKUDisplay: x.skuDisplay,
          PO: x.po,
          productQty: this.getTotalAvailableQuantityProduct(x.id),
          cartonQty: this.getTotalAvailableQuantityCarton(x.id),
          productSampleSize: 0,
          cartonSampleSize: 0,
          actualProductSampleSize: 0,
          actualCartonSampleSize: 0,
          aqlMinor: this.workOrder.aqlMinor,
          aqlMajor: this.workOrder.aqlMajor,
          aqlCritical: this.workOrder.aqlCritical,
          minorAC: 0,
          minorRE: 0,
          majorAC: 0,
          majorRE: 0,
          criticalAC: 0,
          criticalRE: 0,
        };
      });

      let samplingPlanByInspectionLevelAndSamplingMethodology = this.samPlan.getQVSamplingPlanByInspectionLevelAndSamplingMethodology(
        SKUSamplingList,
        this.workOrderQuantityVerificationObj.productInspectionLevel,
        this.workOrderQuantityVerificationObj.productSamplingMethodology,
        this.workOrder.inspectionLevel,
        this.workOrder.productType,
        this.workOrder.samplingPlan,
        true
      );

      this.workmanshipSampleSize = samplingPlanByInspectionLevelAndSamplingMethodology.totalProductSampleSize

      this.productQuantityVerificationList.forEach((item) => {
        let cartonSampleSize = null;
        let productSampleSize = null;

        if (cartonInspectionLevel == "Fixed") {
          cartonSampleSize = item.sampleSizeCarton == -1 ? null : item.sampleSizeCarton;
        } else if (cartonInspectionLevel == "Square Root") {
          cartonSampleSize = this.getSquareRootCarton(item.availableQuantityCarton, this.totalCartonAvailablePoQty, cartonSamplingMethodology);
        }

        let filteredSKUSamplingList = samplingPlanByInspectionLevelAndSamplingMethodology.SKUSamplingList.filter((x) => x.id == item.workOrderProductId);
        if (filteredSKUSamplingList.length > 0) {
          productSampleSize = filteredSKUSamplingList[0].productSampleSize;
        }

        // if manual input of sample size
        if (this.isManualSampleSizeProduct && this.manualSampleSizeProductId == item.id) {
          productSampleSize = item.sampleSizeProduct;

        }
        
        if (this.isManualSampleSizeCarton && this.manualSampleSizeCartonId == item.id) {
          cartonSampleSize = item.sampleSizeCarton;
        }

        const addedRowQuantity = {
          id: item.id,
          workOrderProductId: item.workOrderProductId,
          caseCountProduct: item.caseCountProduct,
          availableQuantityCarton: item.availableQuantityCarton,
          sampleSizeCarton: item.availableQuantityCarton == -1 ? -1 : cartonSampleSize,
          availableQuantityProduct: item.availableQuantityCarton == -1 ? -1 : item.availableQuantityProduct,
          excessShortQuantity: item.availableQuantityCarton == -1 ? -1 : item.excessShortQuantity,
          percentAvailable: item.availableQuantityCarton == -1 ? -1 : item.percentAvailable,
          sampleSizeProduct: item.availableQuantityCarton == -1 ? -1 : productSampleSize,
          orderNumber: item.orderNumber,
          remarks: item.remarks,
          exportCartonNumbersOpened: item.exportCartonNumbersOpened,
          packedQty: item.packedQty,
          underPackingQty: item.underPackingQty,
          underProductionFinishingQty: item.underProductionFinishingQty,
          sectionId: 1,
        };

        workOrderProductQuantityVerificationResultObjList.push(addedRowQuantity);
        item.sampleSizeCarton = item.availableQuantityCarton == -1 ? -1 : cartonSampleSize;
        item.sampleSizeProduct = item.availableQuantityCarton == -1 ? -1 : productSampleSize;
      }); // end forEach item in productQuantityVerificationList

      if (workOrderProductQuantityVerificationResultObjList.length > 0) {
        this.utilityFn.getServerData("post", this.apiList.upsertWorkOrderProductQuantityVerificationResult, workOrderProductQuantityVerificationResultObjList, (res) => {});
      }
      this.CalculateOnsiteTestSampleSize();

      let workmanshipResult = this.workOrderList.workmanshipResult;
      let workmanshipSampleSize =
        (workmanshipResult == "1" || workmanshipResult == "2") && this.workOrderList.aqlLevels != "Fixed" ? samplingPlanByInspectionLevelAndSamplingMethodology.totalProductSampleSize : this.workOrderList.workmanshipSampleSize;

      let skuCount = samplingPlanByInspectionLevelAndSamplingMethodology.SKUSamplingList.length;
      let overallCriticalAC =
        this.workOrderQuantityVerificationObj.productSamplingMethodology == "Separated Sample Size"
          ? null
          : skuCount > 1 && this.workOrderQuantityVerificationObj.productSamplingMethodology == null
          ? null
          : samplingPlanByInspectionLevelAndSamplingMethodology.overallCriticalAC;
      let overallMajorAC =
        this.workOrderQuantityVerificationObj.productSamplingMethodology == "Separated Sample Size"
          ? null
          : skuCount > 1 && this.workOrderQuantityVerificationObj.productSamplingMethodology == null
          ? null
          : samplingPlanByInspectionLevelAndSamplingMethodology.overallMajorAC;
      let overallMinorAC =
        this.workOrderQuantityVerificationObj.productSamplingMethodology == "Separated Sample Size"
          ? null
          : skuCount > 1 && this.workOrderQuantityVerificationObj.productSamplingMethodology == null
          ? null
          : samplingPlanByInspectionLevelAndSamplingMethodology.overallMinorAC;

      let overallCriticalRE =
        this.workOrderQuantityVerificationObj.productSamplingMethodology == "Separated Sample Size"
          ? null
          : skuCount > 1 && this.workOrderQuantityVerificationObj.productSamplingMethodology == null
          ? null
          : samplingPlanByInspectionLevelAndSamplingMethodology.overallCriticalRE;
      let overallMajorRE =
        this.workOrderQuantityVerificationObj.productSamplingMethodology == "Separated Sample Size"
          ? null
          : skuCount > 1 && this.workOrderQuantityVerificationObj.productSamplingMethodology == null
          ? null
          : samplingPlanByInspectionLevelAndSamplingMethodology.overallMajorRE;
      let overallMinorRE =
        this.workOrderQuantityVerificationObj.productSamplingMethodology == "Separated Sample Size"
          ? null
          : skuCount > 1 && this.workOrderQuantityVerificationObj.productSamplingMethodology == null
          ? null
          : samplingPlanByInspectionLevelAndSamplingMethodology.overallMinorRE;
      var data = [
        {
          op: "replace",
          path: "/sampleSize",
          value: samplingPlanByInspectionLevelAndSamplingMethodology.totalProductSampleSize,
        },
        {
          op: "replace",
          path: "/samplingMethodology",
          value: this.workOrderQuantityVerificationObj.productSamplingMethodology,
        },
        {
          op: "replace",
          path: "/workmanshipsamplesize",
          value: workmanshipSampleSize,
        },

        // {
        //   op: "replace",
        //   path: "/allowedCritical",
        //   value: overallCriticalAC,
        // },
        // {
        //   op: "replace",
        //   path: "/allowedMajor",
        //   value: overallMajorAC,
        // },
        // {
        //   op: "replace",
        //   path: "/allowedMinor",
        //   value: overallMinorAC,
        // },

        // {
        //   op: "replace",
        //   path: "/rejectedCritical",
        //   value: overallCriticalRE,
        // },
        // {
        //   op: "replace",
        //   path: "/rejectedMajor",
        //   value: overallMajorRE,
        // },
        // {
        //   op: "replace",
        //   path: "/rejectedMinor",
        //   value: overallMinorRE,
        // },
      ];

      this.utilityFn.getServerData("patch", this.apiList.patchWorkOrderResults + "/" + this.workOrderId + "/1", data, (res) => {});
      this.distributeFractionCarton()
      this.$store.dispatch("inspection/setProductQuantityVerificationList", _.cloneDeep(this.productQuantityVerificationList));
      
      this.$store.dispatch("inspection/setQVListUpdated", true);

      return;
    },
    CalculateOnsiteTestSampleSize() {
      this.OnsiteTests.forEach((x) => {
        let forDataOnSiteTest = new FormData();
        forDataOnSiteTest.append("id", x.id);
        forDataOnSiteTest.append("WorkOrderId", this.workOrderId);
        forDataOnSiteTest.append("TestConducted", x.testConducted);
        forDataOnSiteTest.append("Criteria", x.criteria);
        forDataOnSiteTest.append("SampleSize", x.numberOfSamples == "Fixed" ? x.sampleSize : this.samPlan.getOverallProductSampleSizeByInspectionLevel(x.numberOfSamples));
        forDataOnSiteTest.append("NumberOfSamples", x.numberOfSamples);
        forDataOnSiteTest.append("Others", x.others);
        forDataOnSiteTest.append("Result", x.result);
        forDataOnSiteTest.append("IsHidden", x.isHidden);
        forDataOnSiteTest.append("Comments", x.comments);
        forDataOnSiteTest.append("FailReason", x.failReason);
        forDataOnSiteTest.append("Findings", x.findings);
        forDataOnSiteTest.append("Remarks", x.remarks);
        // forDataOnSiteTest.append("image", x.image);
        this.utilityFn.getServerData("post", this.apiList.upsertWorkOrderOnSiteTests, forDataOnSiteTest, (result) => {});
      });
    },

    assignCaseCount() {
      if(this.selectedClientProduct.length === 0) return;
      if(this.assignCaseCountToAll === null || this.assignCaseCountToAll === "") return;

      this.selectedClientProduct.forEach(s => {
        var index = this.productQuantityVerificationList.indexOf(s);
        if(this.productQuantityVerificationList[index].caseCountProduct !== "" && this.productQuantityVerificationList[index].caseCountProduct !== null) return;
        this.productQuantityVerificationList[index].caseCountProduct = this.assignCaseCountToAll;
        if(this.shipQtyCartonCDFId) {
          this.productQuantityVerificationList[index].shipqtyUnit = this.productQuantityVerificationList[index].shipqty * this.productQuantityVerificationList[index].caseCountProduct;
        }
      })

      this.productQuantityVerificationList = this.productQuantityVerificationList.slice();
    },

    applyPOQty() {
      if(this.selectedClientProduct.length === 0) return;

      this.selectedClientProduct.forEach(s => {
        var index = this.productQuantityVerificationList.indexOf(s);
        if(this.productQuantityVerificationList[index].cartonQty) {
          this.productQuantityVerificationList[index].availableQuantityCarton = this.productQuantityVerificationList[index].cartonQty;
          this.onKeyUpAvailableQuantityCarton(this.productQuantityVerificationList[index]);
        }
      })

      this.productQuantityVerificationList = this.productQuantityVerificationList.slice();
    },

    async loadWorkOrderList(workOrderId) {
      if(this.isInvalidTolerance) return;

      this.editedIndex = -1;
      this.deletedIndex = -1;
      const result = await this.utilityFn.getServerDataAsync("get", this.apiList.workOrderDetailsQuantity + "/" + workOrderId, {});
      if (result && result.data) {
        this.workOrder = result.data;
        this.products = result.data.products;
        this.workOrderDetail = result.data;
        this.OnsiteTests = result.data.onSiteTests;
        this.isTolerance = result.data.isTolerance;
        this.toleranceOption = result.data.toleranceOption;
        this.productOption = result.data.productOption;
        this.upperTolerance = result.data.upperTolerance;
        this.lowerTolerance = result.data.lowerTolerance;
        this.overallProductSKU = result.data.overallProductSKU;

        const quantityVerificationObj = this.workOrder.quantityVerification;

        if (!quantityVerificationObj) {
          this.updateworkOrderQuantityVerificationObj().then(() => {
            this.populate(this.workOrderDetail.quantityVerification);
          });
        } else {
          this.populate(this.workOrderDetail.quantityVerification);
        }

        this.skuDisplayList = result.data.products.map((x) => {
          return {
            workOrderProductId: x.id,
            skuDisplay: x.skuDisplay,
            po: x.po,
            cartonQty: x.cartonQty,
            qty: x.qty,
            caseCountProduct: x.casePackage,
            productID: x.productID,
            clientProductId: x.clientProductId,
            shipQty: x.shipQty,
          };
        });

        this.productQuantityVerificationList = [];

        this.loadExistingQuantityVerificationDetails();
        this.workOrderList = result.data;
        if (this.availableQuantityChanges) {
          this.loadSamplingSize();
        }

        //end loading
        this.loadingDf = false;
        this.$store.dispatch("navigation/setLoadingPage",{show: this.loadingDf });
      }
    },

    loadExistingQuantityVerificationDetails() {
      let existingQuantityVerificationList = [];
      this.products.forEach((x) => {
        if (x.workOrderProductQuantityVerificationResults.length > 0) {
          x.workOrderProductQuantityVerificationResults.forEach((item) => {
            existingQuantityVerificationList.push(item);
          });
        }
      });
      if (existingQuantityVerificationList.length > 0) {
        this.loadQuantityVerificationProductDetails(existingQuantityVerificationList);
        this.cloneProductQuantityVerificationList = _.cloneDeep(this.productQuantityVerificationList);
        this.addCountIndexSorted();
        this.calculateEachSKUSampleSize(
          this.workOrderQuantityVerificationObj.cartonInspectionLevel,
          this.workOrderQuantityVerificationObj.productInspectionLevel,
          this.workOrderQuantityVerificationObj.cartonSamplingMethodology,
          this.workOrderQuantityVerificationObj.productSamplingMethodology
      );
      }
    },

    loadQuantityVerificationProductDetails(quantityVerificationProductDetails) {    
      if (quantityVerificationProductDetails) {
        if (quantityVerificationProductDetails.length > 0) {
          let quantityVerificationList = [];
          let count = 1;
          quantityVerificationProductDetails.forEach((res) => {
            let productAttachments = [];
            productAttachments = this.products.reduce((accumulator, currentValue) => {
              if (currentValue.id == res.workOrderProductId) {
                const Attachments = currentValue.workOrderProductAttachments;
                accumulator.push(Attachments);
              }
              return accumulator;
            }, [])[0];
            const cartonShipQty = this.getShipQty(res.workOrderProductId);
            const woProduct = this.getSKUDisplay(res.workOrderProductId);
            const quantityVerification = {
              id: res.id,
              workOrderProductId: res.workOrderProductId,
              productID: woProduct?.productID,
              orderNumber: res.orderNumber,
              clientProductId: woProduct?.clientProductId,
              skuDisplay: woProduct?.skuDisplay,
              po: woProduct?.po,
              cartonQty: woProduct?.cartonQty,
              caseCountProduct: res.caseCountProduct,
              availableQuantityCarton: res.availableQuantityCarton,
              qty: woProduct?.qty,
              packedQty: res.packedQty,
              underPackingQty: res.underPackingQty,
              underProductionFinishingQty: res.underProductionFinishingQty,
              availableQuantityProduct: res.availableQuantityProduct,
              excessShortQuantity: res.excessShortQuantity,
              percentAvailable: Math.round( ((Number(res.packedQty) + Number(res.underPackingQty == -1 ? 0 : res.underPackingQty))/this.getSKUDisplay(res.workOrderProductId).qty)*100),
              sampleSizeCarton: res.sampleSizeCarton,
              sampleSizeCarton2: res.sampleSizeCarton,
              sampleSizeCartonTotal: res.sampleSizeCartonTotal == null ? res.sampleSizeCarton + res.sampleSizeCarton : res.sampleSizeCartonTotal,
              sampleSizeProduct: res.sampleSizeProduct,
              sampleSizeProduct2: res.sampleSizeProduct,
              sampleSizeProductTotal: res.sampleSizeProductTotal == null ? res.sampleSizeProduct + res.sampleSizeProduct : res.sampleSizeProductTotal,
              exportCartonNumbersOpened: res.exportCartonNumbersOpened,
              remarks: res.remarks,
              workOrderProductAttachments: productAttachments,
              shipqty: cartonShipQty?.value,
              shipqtyId: cartonShipQty?.id,
              shipqtyUnit: woProduct?.shipQty,
              countIndex: null,
              isManualSampleSizeCarton: res.isManualSampleSizeCarton,
              isManualSampleSizeProduct: res.isManualSampleSizeProduct
            };
            count++;

            if (this.editedIndex > -1) {
              Object.assign(this.productQuantityVerificationList[this.editedIndex], quantityVerification);
            } else {
              this.productQuantityVerificationList.push(quantityVerification);
            }
          });
          this.editedIndex = -1;
        }
      }
      this.loadingCartonQuantityVerificationList = false;
     // console.log('loadQuantityVerificationProductDetails')
      //this.distributeFractionCarton()
      // this.calculateBarcodeSampleSize();
      // this.calculateWorkmanshipSampleSize();
      this.$store.dispatch("inspection/setProductQuantityVerificationList", _.cloneDeep(this.productQuantityVerificationList));

    },

    addCountIndexSorted(){
      this.productQuantityVerificationList.sort((a, b) =>   a.orderNumber - b.orderNumber );
      this.productQuantityVerificationList.sort((a, b) => a.skuDisplay - b.skuDisplay  );
      this.productQuantityVerificationList.sort((a, b) =>   a.po - b.po );
      let counter = 1;
      for(let i=0; i < this.productQuantityVerificationList.length; i++){
        if(this.productQuantityVerificationList[i].orderNumber == 1){
           this.productQuantityVerificationList[i].countIndex = counter;
           counter++
        }
      }
    },
    getProductQuantityVerificationList() {
      this.productQuantityVerificationList.forEach(x => {
        this.onKeyUpAvailableQuantityCarton(x, null, true);
      });

      this.calculateSampleSizeCartonProductNew(
          this.workOrderQuantityVerificationObj.cartonInspectionLevel,
          this.workOrderQuantityVerificationObj.cartonSamplingMethodology
        );
    },

    async calculateSizeMeasurementSampleSize() {
      if (!this.workOrder.sizeMeasurement) return;

      const inspectionLevel = this.workOrder.sizeMeasurement.productSizeInspectionLevel;
      const samplingMethodology = this.workOrder.sizeMeasurement.productSizeSamplingMethodology;
      const isInitialCalculate = false;
      let wopSizeMeasurementResult = [];

      if(!inspectionLevel || (this.products.length > 1 && !samplingMethodology))return

      let samplingPlanByInspectionLevelAndSamplingMethodology = this.samPlan.getSamplingPlanByInspectionLevelAndSamplingMethodology(inspectionLevel, samplingMethodology);

      this.products.forEach((wop) => {
        if (wop.workOrderProductSizeMeasurementResults.length == 0) return;

        wop.workOrderProductSizeMeasurementResults.forEach((item) => {
          if (!item.isCarton) {
            let productSampleSize = null;
            if (inspectionLevel != "Fixed") {
              let filteredSKUSamplingList = samplingPlanByInspectionLevelAndSamplingMethodology.SKUSamplingList.filter((x) => x.id == wop.id);
              if (filteredSKUSamplingList.length > 0) {
                productSampleSize = filteredSKUSamplingList[0].productSampleSize;
              }
            }

            const sampleSize = productSampleSize == item.sampleSize ? item.sampleSize : productSampleSize;
            wopSizeMeasurementResult.push({
              id: item.id,
              patch: [{ op: "replace", path: "/sampleSize", value: sampleSize }],
            });
          }
        });
      });

      if (wopSizeMeasurementResult.length > 0) {
        await this.utilityFn.getServerDataAsync("patch", this.apiList.patchWorkOrderProductSizeMeasurementResult, wopSizeMeasurementResult);
        /*this.utilityFn.getServerData("post", this.apiList.updateWorkOrderSizeMeasurementRecompute + "/" + this.workOrderId + "/true", (result) => {
          console.log(result);
        });*/
      }
    },

    async calculateBarcodeSampleSize() {
      if (!this.workOrder.barcodeVerification) return;

      // carton
      if (this.workOrder.barcodeVerification.cartonInspectionLevel == "Square Root") {
        const totalAvailCartonQty = this.productQuantityVerificationList.reduce((prev, cur) => prev + cur.availableQuantityCarton, 0);
        const sumAllSqrtPerSkuCartonQty = this.productQuantityVerificationList.reduce((prev, cur) => prev + Math.ceil(Math.sqrt(cur.availableQuantityCarton)), 0);

        if (this.products.length == 1) {
          this.workOrder.barcodeVerification.cartonSampleSize = Math.ceil(Math.sqrt(totalAvailCartonQty));
        } else if (this.products.length > 1 && this.workOrder.barcodeVerification.cartonSamplingMethodology == "Combined Sample Size") {
          //const sqrtTotal = this.productQuantityVerificationList.reduce((prev, cur) => Number(prev) + this.getSquareRootCarton(cur.availableQuantityCarton || 0, totalAvailCartonQty), 0);
         const sqrtTotal = Math.ceil(Math.sqrt(this.totalCartonPoQty))
         this.workOrder.barcodeVerification.cartonSampleSize = sqrtTotal;
        } else if (this.products.length > 1) {
          this.workOrder.barcodeVerification.cartonSampleSize = sumAllSqrtPerSkuCartonQty;
        }
      }

      // product
        if(!this.workOrder.barcodeVerification.productInspectionLevel || (this.products.length > 1 && !this.workOrder.barcodeVerification.productSamplingMethodology))return
      const sampling = this.samPlan.getSamplingPlanByInspectionLevelAndSamplingMethodology(this.workOrder.barcodeVerification.productInspectionLevel, this.workOrder.barcodeVerification.productSamplingMethodology);
      if (sampling) {
        this.workOrder.barcodeVerification.productSampleSize = sampling.totalProductSampleSize;
      }

      this.workOrder.barcodeVerification.sectionId = this.sectionId;
      await this.utilityFn.getServerDataAsync("post", this.apiList.upsertWorkOrderBarcodeVerification, this.workOrder.barcodeVerification);
    },

    async calculateWorkmanshipSampleSize() {
      if (this.workmanshipSampleSize) {
         const data = [
        {
          op: "replace",
          path: "/workmanshipSampleSize",
          value: this.workmanshipSampleSize
        }/*,
        {
          op: "replace",
          path: "/recompute",
          value: this.reCompute
        }*/
      ];

        await this.utilityFn.getServerDataAsync("patch", this.apiList.patchWorkOrderResults + "/" + this.workOrderId + "/5",data)
      }
    },


    async calculatePackageCheckSampleSize() {
      if (!this.workOrder.packageCheck || this.workOrder.packageCheck.ilPkgCheck == "Fixed") return;

      if (this.workOrder.packageCheck.ilPkgCheck == "Square Root") {
        const totalAvailCartonQty = this.productQuantityVerificationList.reduce((prev, cur) => prev + cur.availableQuantityCarton, 0);
        const sumAllSqrtPerSkuCartonQty = this.productQuantityVerificationList.reduce((prev, cur) => prev + Math.ceil(Math.sqrt(cur.availableQuantityCarton)), 0);

        if (this.products.length == 1) {
          return (this.workOrder.packageCheck.crtnSampleSize = Math.ceil(Math.sqrt(totalAvailCartonQty)));
        }

        if (this.products.length > 1 && this.workOrder.packageCheck.samplingMethodology == "Combined Sample Size") {
          const sqrtTotal = this.productQuantityVerificationList.reduce((prev, cur) => Number(prev) + this.getSquareRootCarton(cur.availableQuantityCarton || 0, totalAvailCartonQty), 0);
          return (this.workOrder.packageCheck.crtnSampleSize = Math.ceil(sqrtTotal));
        }

        if (this.products.length > 1) {
          return (this.workOrder.packageCheck.crtnSampleSize = sumAllSqrtPerSkuCartonQty);
        }
      } else {
        const sampling = this.samPlan.getSamplingPlanByInspectionLevelSamplingMethodologyInspectionLevel1doubleSamplingPlanIteration(
          this.workOrder.packageCheck.ilPkgCheck,
          this.workOrder.packageCheck.samplingMethodology,
          this.workOrder.packageCheck.inspectionLevel1,
          this.workOrder.packageCheck.doubleSamplingPlan
        );
        if (sampling) {
          this.workOrder.packageCheck.crtnSampleSize = sampling.totalCartonSampleSize;
        }
      }

      this.workOrder.packageCheck.sectionId = this.sectionId;
      //this.workOrder.packageCheck.recompute = this.reCompute;
      await this.utilityFn.getServerDataAsync("post", this.apiList.upsertWorkOrderPackageCheck, this.workOrder.packageCheck);
    },

    getTotalQunatityPerSKUPerPO(workOrderProductId) {},

    getShipQty(productId) {
      if (!productId || productId == "" || productId == null) {
        return null;
      }

      let workOrderProductCompanyClientDefinedFields = this.products.find((x) => x.id === productId).workOrderProductCompanyClientDefinedFields;

      if (workOrderProductCompanyClientDefinedFields) {
        let shipqty = workOrderProductCompanyClientDefinedFields.find((x) => x.productCompanyClientDefinedFieldId == this.shipQtyCartonCDFId);
        return shipqty;
      }
      return null;
    },

    getShipQtyUnit(productId) {
      if (!productId || productId == "" || productId == null) {
        return null;
      }

      let workOrderProductCompanyClientDefinedFields = this.products.find((x) => x.id === productId).workOrderProductCompanyClientDefinedFields;

      if (workOrderProductCompanyClientDefinedFields) {
        let shipqtyUnit = workOrderProductCompanyClientDefinedFields.find((x) => x.productCompanyClientDefinedFieldId == this.shipQtyUnitCDFId);
        return shipqtyUnit;
      }
    },

    getSKUDisplay(productId) {
      if (!productId || productId == "" || productId == null) {
        return null;
      }

      let skuDisplayData = this.skuDisplayList.find((x) => x.workOrderProductId === productId);
      return skuDisplayData;
    },

    async submit_item(sectionSubmitted = false) {
      this.validateTolerance();

      if(this.isInvalidTolerance) return;

      const errorMessage = await this.validateForm();
      if(errorMessage !== "") {
        this.snackbar = true;
        this.message = errorMessage;
        return;
      }
      
      this.sectionSubmitted = sectionSubmitted;

      const section = {
        sectionId: this.sectionId,
        workOrderId: this.$route.params.id,
      };
      await this.utilityFn.getServerDataAsync("post", this.apiList.submitInspectionSection, section);
      this.sectionData.QuantityVerification.isSectionSubmitted = true;
      this.$store.dispatch("inspection/setSectionData", this.sectionData);

      const index = this._workOrderDetails.workOrderInspectionSections.findIndex((x) => x.sectionId == this.sectionId);
      if (index !== -1) {
        this._workOrderDetails.workOrderInspectionSections[index].submittedDate = new Date();
        this.$store.dispatch("inspection/setWorkOrderDetails", _.cloneDeep(this._workOrderDetails));
      }
    },
    UpdateProductCartonRequired(type) {
      switch (type) {
        case "carton":
          if (this.workOrderList.cartonRequired == -1) {
            this.showToleranceDialogCarton = true;
          }
          break;

        case "product":
          if (this.workOrderList.productRequired == -1) {
            this.showToleranceDialogProduct = true;
          }
        //    else if( parseInt(this.workOrderList.productRequiredMax) < parseInt(this.workOrderList.productRequired) ){
        //     this.workOrderList.productRequired = null
        //     this.message = "Maximum Required (%) shall be equal or greater than Minimum Required (%)";
        //     this.snackbar = true;   
            
        //   }
        //   break;

        // case "productMax":
        //   if (this.workOrderList.productRequiredMax == -1) {
        //     this.showToleranceDialogProduct = true;
        //   }
        //   else if(parseInt(this.workOrderList.productRequiredMax) < parseInt(this.workOrderList.productRequired) ){
        //     this.workOrderList.productRequiredMax = null
        //     this.message = "Maximum Required (%) shall be equal or greater than Minimum Required (%)";
        //     this.snackbar = true;   
            
        //   }
          break;
      }

      var other = {
        headers: {
          wresult: this.store_token,
          "Content-Type": "application/json",
        },
      };
      const data = [
        {
          op: "replace",
          path: "/productRequired",
          value: this.workOrderList.productRequired,
        },
        {
          op: "replace",
          path: "/productRequiredMax",
          value: this.workOrderList.productRequiredMax,
        },
        {
          op: "replace",
          path: "/cartonRequired",
          value: this.workOrderList.cartonRequired,
        },
      ];

      this.utilityFn.getServerData("patch", this.apiList.patchWorkOrderResults + "/" + this.workOrderId + "/1", data, (res) => {}, other);
      //update back in store
      this._workOrderDetails.productRequired = this.workOrderList.productRequired;
      this._workOrderDetails.productRequiredMax = this.workOrderList.productRequiredMax;
      this._workOrderDetails.cartonRequired = this.workOrderList.cartonRequired;
      this.$store.dispatch("inspection/setWorkOrderDetails", this._workOrderDetails);

      this.onChange();
    },
    close() {
      this.cartonDialog = false;
      this.productDialog = false;
      this.deleteDialog = false;
      this.buttonDisable = false;
      this.remarksDialog = false;
      this.isManualSampleSizeProduct = false;
      this.manualSampleSizeProductId = null;
      this.isManualSampleSizeCarton = false;
      this.manualSampleSizeCartonId = null;

      this.getDeleteRecord = [];
    },

    save() {
      this.close();
    },
    onImageChange(event, screenId, editItem, desc) {
      event.forEach((item) => {
        let formData = new FormData();
        formData.append("image", item);
        formData.append("description", desc);
        formData.append("workOrderProductId", editItem.id);
        formData.append("screen", screenId);

        editItem.uploadLoading = true;
        this.utilityFn.uploadImg(
          this.apiList.uploadWorkOrderProductImage,
          {
            formData: formData,
          },
          (progress) => {
            // 获得上传进度
          },
          (res) => {
            editItem.uploadLoading = false;
            editItem.serverPhoto.push({
              fileUploadId: res.data.fileUploadId,
              img: window.URL.createObjectURL(item),
            });
          }
        );
      });
    },

    deleteCartonRow() {
      this.buttonDisable = true;
      this.utilityFn.getServerData("delete", this.apiList.deleteWorkOrderProductQuantityVerificationResult + "/" + this.deletedItem.id, {}, (result) => {
        this.productQuantityVerificationList.splice(this.deletedIndex, 1);
        this.calculateSampleSizeCartonProduct(
          this.workOrderQuantityVerificationObj.cartonInspectionLevel,
          this.workOrderQuantityVerificationObj.productInspectionLevel,
          this.workOrderQuantityVerificationObj.cartonSamplingMethodology,
          this.workOrderQuantityVerificationObj.productSamplingMethodology
        );
        this.close();
      });
      if (window.navigator.onLine != true) {
        this.productQuantityVerificationList.splice(this.deletedIndex, 1);
        this.calculateSampleSizeCartonProduct(
          this.workOrderQuantityVerificationObj.cartonInspectionLevel,
          this.workOrderQuantityVerificationObj.productInspectionLevel,
          this.workOrderQuantityVerificationObj.cartonSamplingMethodology,
          this.workOrderQuantityVerificationObj.productSamplingMethodology
        );
        this.message = this.msgList.NoConnection;
        this.snackbar = true;
        this.close();
      }
    },

    del_temp_img(fileId, fileArray, index) {
      this.delImgSnackbar.isShow = true;
      this.delImgSnackbar.text = "Deleting picture";
      this.utilityFn.deleteImage(fileId, (res) => {
        fileArray.splice(index, 1);
        this.delImgSnackbar.isShow = false;
        // 删除成功后重新获取列表数据
        this.loadWorkOrderList(this.workOrderId);
      });
    },

    show_remarks(item) {
      this.cartonEditedItem.exportCartonNumbersOpened = item.exportCartonNumbersOpened;
      this.cartonEditedItem.remarks = item.remarks;
      this.remarksDialog = true;
    },

    carton_edit_row(item) {
      // 赋值item的信息到表格的输入框中

      this.resetDialog();
      this.formTitle = item.id == null ? "Add" : "Edit";
      this.editedIndex = item.id == null ? -1 : this.productQuantityVerificationList.indexOf(item);

      this.availableQuantityChanges = false;
      this.cartonDialog = true;

      this.cartonEditedItem.clientProductId = item.clientProductId;
      this.cartonEditedItem.skuDisplay = item.skuDisplay;
      this.cartonEditedItem.productID = item.productID;
      this.cartonEditedItem.workOrderProductId = item.workOrderProductId;
      this.cartonEditedItem.id = item.id;
      this.cartonEditedItem.po = item.po;
      this.cartonEditedItem.cartonQty = item.cartonQty;
      this.cartonEditedItem.qty = item.qty;
      this.cartonEditedItem.caseCountProduct = item.caseCountProduct;
      this.cartonEditedItem.availableQuantityCarton = item.availableQuantityCarton;
      this.cartonEditedItem.sampleSizeCarton = item.sampleSizeCarton;
      this.cartonEditedItem.availableQuantityProduct = item.availableQuantityProduct;
      this.cartonEditedItem.excessShortQuantity = item.excessShortQuantity;
      this.cartonEditedItem.percentAvailable = item.percentAvailable;
      this.cartonEditedItem.sampleSizeProduct = item.sampleSizeProduct;
      this.cartonEditedItem.orderNumber = item.orderNumber;
      this.cartonEditedItem.exportCartonNumbersOpened = item.exportCartonNumbersOpened;

      this.cartonEditedItem.packedQty = item.packedQty;
      this.cartonEditedItem.underPackingQty = item.underPackingQty;
      this.cartonEditedItem.underProductionFinishingQty = item.underProductionFinishingQty;
      this.cartonEditedItem.shipqty = item.shipqty;
      this.cartonEditedItem.shipqtyId = item.shipqtyId;
      this.cartonEditedItem.shipqtyUnit = item.shipqtyUnit;
      this.cartonEditedItem.shipqtyUnitId = item.shipqtyUnitId;

      this.cartonEditedItem.remarks = item.remarks;

      // 打开弹窗时清空文件框
      this.cartonEditedItem.fileInput = [];
      this.cartonEditedItem.serverPhoto = [];

      // 在所有图片中获取当前部分的图片
      if (this.cartonEditedItem.serverPhoto.length == 0) {
        item.workOrderProductAttachments.forEach((x) => {
          if (x.sectionId == 1 && x.description == "carton") {
            // 获取图片的base64编码用于显示
            this.utilityFn.getImage(x.fileUploadId, (res) => {
              x.img = "data:" + item.type + ";base64," + res.data.data;
              this.cartonEditedItem.serverPhoto.push(x);
            });
          }
        });
      }

      // copy object for checking of changes for sample size recalculation purposes
      this.cloneCartonEditedItem = Object.assign({}, this.cartonEditedItem);
    },

    show_dialog(item) {
      let newItem = [];

      newItem.id = null;
      newItem.workOrderProductId = item.workOrderProductId;

      newItem.clientProductId = item.clientProductId;
      newItem.skuDisplay = item.skuDisplay;

      newItem.po = item.po;
      newItem.cartonQty = null;
      newItem.qty = null;
      newItem.caseCountProduct = null;
      newItem.availableQuantityCarton = null;
      newItem.sampleSizeCarton = null;
      newItem.availableQuantityProduct = null;
      newItem.excessShortQuantity = null;
      newItem.percentAvailable = null;
      newItem.sampleSizeProduct = null;
      (newItem.exportCartonNumbersOpened = null), (newItem.remarks = null);

      (newItem.packedQty = null), (newItem.underPackingQty = null), (newItem.underProductionFinishingQty = null), (newItem.orderNumber = this.getMaxValue(item.workOrderProductId) + 1);
      newItem.workOrderProductAttachments = item.workOrderProductAttachments;
      this.carton_edit_row(newItem);
    },

    product_edit_row(item) {
      this.resetDialog();
      this.editedIndex = item.id == null ? -1 : this.productQuantityVerificationList.indexOf(item);
      this.availableQuantityChanges = false;
      this.productDialog = true;
      this.productEditedItem.workOrderProductId = item.workOrderProductId;

      this.productEditedItem.clientProductId = item.clientProductId;
      this.productEditedItem.skuDisplay = this.getSKUDisplay(item.workOrderProductId).skuDisplay;
      this.productEditedItem.id = item.id;
      this.productEditedItem.po = item.po;
      this.productEditedItem.cartonQty = item.cartonQty;
      this.productEditedItem.qty = item.qty;
      this.productEditedItem.caseCountProduct = item.caseCountProduct;
      this.productEditedItem.availableQuantityCarton = item.availableQuantityCarton;
      this.productEditedItem.sampleSizeCarton = item.sampleSizeCarton;
      this.productEditedItem.availableQuantityProduct = item.availableQuantityProduct;
      this.productEditedItem.excessShortQuantity = item.excessShortQuantity;
      this.productEditedItem.percentAvailable = item.percentAvailable;
      this.productEditedItem.sampleSizeProduct = item.sampleSizeProduct;
      this.productEditedItem.orderNumber = item.orderNumber;
      this.productEditedItem.exportCartonNumbersOpened = item.exportCartonNumbersOpened;
      this.productEditedItem.remarks = item.remarks;

      this.productEditedItem.packedQty = item.packedQty == null ? this.getTotalPackedQuantityProduct(item.workOrderProductId) : item.packedQty;
      this.productEditedItem.underPackingQty = item.underPackingQty;
      this.productEditedItem.underProductionFinishingQty = item.underProductionFinishingQty;
      this.productEditedItem.shipqty = item.shipqty;
      this.productEditedItem.shipqtyId = item.shipqtyId;
      this.productEditedItem.shipqtyUnit = item.shipqtyUnit;
      this.productEditedItem.shipqtyUnitId = item.shipqtyUnitId;

      // 打开弹窗时清空文件框
      this.productEditedItem.fileInput = [];
      this.productEditedItem.serverPhoto = [];
      // 在所有图片中获取当前部分的图片
      if (this.productEditedItem.serverPhoto.length == 0) {
        item.workOrderProductAttachments.forEach((x) => {
          if (x.sectionId == 1 && x.description == "product") {
            // 获取图片的base64编码用于显示
            this.utilityFn.getImage(x.fileUploadId, (res) => {
              x.img = "data:" + item.type + ";base64," + res.data.data;
              this.productEditedItem.serverPhoto.push(x);
            });
          }
        });
      }

      // copy object for checking of changes for sample size recalculation purposes
      this.cloneProductEditedItem = Object.assign({}, this.productEditedItem);
    },
    delete_item(item) {
      this.resetDialog();
      this.deletedIndex = this.productQuantityVerificationList.indexOf(item);
      (this.deletedItem.id = item.id), (this.deletedItem.skuDisplay = item.skuDisplay);
      (this.deletedItem.workOrderProductID = item.workOrderProductID),
        (this.deletedItem.po = item.po),
        (this.deletedItem.caseCountProduct = item.caseCountProduct),
        (this.deletedItem.availableQuantityCarton = item.availableQuantityCarton),
        (this.deleteDialog = true);
    },

    resetDialog() {
      this.cartonEditedItem.id = null;
      this.cartonEditedItem.workOrderProductId = null;
      this.cartonEditedItem.po = null;
      this.cartonEditedItem.cartonQty = null;
      this.cartonEditedItem.caseCountProduct = null;
      this.cartonEditedItem.availableQuantityCarton = null;
      this.cartonEditedItem.sampleSizeCarton = null;
      this.cartonEditedItem.availableQuantityProduct = null;
      this.cartonEditedItem.excessShortQuantity = null;
      this.cartonEditedItem.percentAvailable = null;
      this.cartonEditedItem.sampleSizeProduct = null;
      this.cartonEditedItem.orderNumber = null;
      this.cartonEditedItem.fileInput = null;
      this.cartonEditedItem.uploadPhoto = null;
      this.cartonEditedItem.serverPhoto = null;
      this.cartonEditedItem.uploadLoading = null;

      this.cartonEditedItem.exportCartonNumbersOpened = null;
      this.cartonEditedItem.remarks = null;

      this.productEditedItem.shipqty = null;
      this.productEditedItem.shipqtyUnit = null;
      this.cartonEditedItem.shipqty = null;
      this.cartonEditedItem.shipqtyUnit = null;

      this.productEditedItem.id = null;
      this.productEditedItem.workOrderProductId = null;
      this.productEditedItem.po = null;
      this.productEditedItem.cartonQty = null;
      this.productEditedItem.caseCountProduct = null;
      this.productEditedItem.availableQuantityCarton = null;
      this.productEditedItem.sampleSizeCarton = null;
      this.productEditedItem.availableQuantityProduct = null;
      this.productEditedItem.excessShortQuantity = null;
      this.productEditedItem.percentAvailable = null;
      this.productEditedItem.sampleSizeProduct = null;
      this.productEditedItem.orderNumber = null;
      this.productEditedItem.fileInput = null;
      this.productEditedItem.uploadPhoto = null;
      this.productEditedItem.serverPhoto = null;
      this.productEditedItem.uploadLoading = null;

      this.productEditedItem.exportCartonNumbersOpened = null;
      this.productEditedItem.remarks = null;

      this.deletedItem.id = null;
      this.deletedItem.workOrderProductId = null;
      this.deletedItem.po = null;
      this.deletedItem.cartonQty = null;
      this.deletedItem.caseCountProduct = null;
      this.deletedItem.availableQuantityCarton = null;
      this.deletedItem.sampleSizeCarton = null;
      this.deletedItem.availableQuantityProduct = null;
      this.deletedItem.excessShortQuantity = null;
      this.deletedItem.percentAvailable = null;
      this.deletedItem.sampleSizeProduct = null;
      this.deletedItem.orderNumber = null;
      this.deletedItem.fileInput = null;
      this.deletedItem.uploadPhoto = null;
      this.deletedItem.serverPhoto = null;
      this.deletedItem.uploadLoading = null;
      this.deletedItem.shipqty = null;
      this.deletedItem.shipqtyUnit = null;

      this.deletedItem.exportCartonNumbersOpened = null;
      this.deletedItem.remarks = null;
    },

    getMaxValue(workOrderProductId) {
      let filteredquantityVerificationList = this.productQuantityVerificationList.filter((x) => x.workOrderProductId == workOrderProductId);
      return Math.max.apply(
        Math,
        filteredquantityVerificationList.map((x) => {
          return x.orderNumber;
        })
      );
    },

    getWorkorderProductIdTotalCount(workOrderProductId) {
      let filteredquantityVerificationList = this.productQuantityVerificationList.filter((x) => x.workOrderProductId == workOrderProductId);
      return filteredquantityVerificationList.length;
    },

    getTotalAvailableQuantityProduct(workOrderProductId) {

      // = underPackingQty + TotalPackedQuantityProduct
      let totalProduct = 0;
      this.productQuantityVerificationList
        .filter((x) => x.workOrderProductId == workOrderProductId)
        .forEach((item) => {
            totalProduct += Number(item.packedQty == -1 ? 0 : item.packedQty) + Number(item.underPackingQty == -1 ? 0 : item.underPackingQty);
        });

      return totalProduct;
    },

    getTotalPackedQuantityProduct(workOrderProductId) {

            let totalProduct = null;
           const productofCaseCountProductAvailableQuantityCarton = this.productQuantityVerificationList
                                            .filter((x) => x.workOrderProductId == workOrderProductId)
                                            .reduce(function(tot, arr) {
                                              if ( arr.availableQuantityCarton == null) {
                                                return totalProduct;
                                              }
                                              if (arr.availableQuantityCarton == -1) {
                                                return (totalProduct = -1);
                                              }
                                              if ( arr.availableQuantityCarton != null && arr.caseCountProduct != null) {
                                                totalProduct += arr.packedQty;

                                              }
                                              return totalProduct;
                                            }, 0);
                                                  return productofCaseCountProductAvailableQuantityCarton

      // let totalProduct = null;
      // let productofCaseCountProductAvailableQuantityCarton = this.productQuantityVerificationList.reduce(function(tot, arr) {
      //   if (arr.workOrderProductId == workOrderProductId && arr.availableQuantityCarton == null) {
      //     return totalProduct;
      //   }
      //   if (arr.workOrderProductId == workOrderProductId && arr.availableQuantityCarton == -1) {
      //     return (totalProduct = -1);
      //   }
      //   if (arr.workOrderProductId == workOrderProductId && arr.availableQuantityCarton != null && arr.caseCountProduct != null) {
      //     totalProduct += arr.packedQty;

      //   }
      //   return totalProduct;
      // }, null);

      // return productofCaseCountProductAvailableQuantityCarton;
    },

    getTotalAvailableQuantityCarton(workOrderProductId) {
      let total = null;
      let sumAvailableQuantityCarton = this.productQuantityVerificationList.reduce(function(tot, arr) {
        if (arr.workOrderProductId == workOrderProductId) {
          if (arr.availableQuantityCarton == null) {
            return total;
          }
          total += Number(arr.availableQuantityCarton);
        }
        return total;
      }, 0);

      return sumAvailableQuantityCarton;
    },

    getAllCountQuantityCarton() {
      let total = null;
      let sumAvailableQuantityCarton = this.productQuantityVerificationList.reduce(function(tot, arr) {
        total += Number(arr.availableQuantityCarton);
        return total;
      }, 0);
      return sumAvailableQuantityCarton;
    },

    getExcessShortQtyProduct(workOrderProductId, qty) {
      let totalAvailableQuantityProduct = this.getTotalAvailableQuantityProduct(workOrderProductId);
      if (totalAvailableQuantityProduct == null) {
        return null;
      } else if (totalAvailableQuantityProduct == -1) {
        return -1;
      } else {
        return totalAvailableQuantityProduct - qty;
      }
    },

    getPercentAvailableProduct(workOrderProductId, qty) {
      let val = 0;
      let totalAvailableQuantityProduct = this.getTotalAvailableQuantityProduct(workOrderProductId);
      if (totalAvailableQuantityProduct == null) {
        return null;
      } else if (totalAvailableQuantityProduct == -1) {
        val = -1;
      } else if(qty == 0 || qty == null){
          return 0;
        }
        else{
           val = (totalAvailableQuantityProduct / Number(qty)) * 100;
        }  
      return  val.toFixed(2)
      // return Math.round(val).toFixed(2);
    },

    returnNumber(obj, name) {
      obj[name] = obj[name]?.toString().replace(/[^-\d]/g, "");
      if ((name == "availableQuantityCarton" || name == "caseCountProduct") && obj[name] != "") {
        this.availableQuantityChanges = true;
      } else {
        this.availableQuantityChanges = false;
      }
    },
    returnNumberSampleSize(obj, name) {
      if (obj[name] != "") {
        obj[name] = obj[name]?.toString().replace(/[^-\d]/g, ""); //清除“数字”和“.”以外的字符
      }

      if (name == "sampleSizeProduct") {
        this.isManualSampleSizeProduct = true;
        obj.isManualSampleSizeProduct = true;
        this.manualSampleSizeProductId = obj.productID;
      } else if (name == "sampleSizeCarton" && obj.productID != null) {
        this.isManualSampleSizeCarton = true;
        obj.isManualSampleSizeCarton = true;
        this.manualSampleSizeCartonId = obj.productID;
      }
    },
    go_upload_photo() {
      this.$router.push({
        name: "uploadPhotoGalleryId",
        params: {
          id: this.$route.params.id,
          name: this.sectionData.QuantityVerification.name,
          sectionId: this.sectionData.QuantityVerification.id,
        },
        query: {
          name: this.sectionData.QuantityVerification.name,
          sectionId: this.sectionData.QuantityVerification.id,
        },
      });
    },

    onChange() {
      this.logChanges();
    },

    logChanges(log = true) {
      window.sessionStorage.setItem("EditDataLogging", log);
    },

    addChild(item) {
      let newItem = Object.assign({}, item);
      newItem.id = 'child_' + this.productQuantityVerificationList.length + item.id;
      newItem.countIndex = this.getMaxValue(item.workOrderProductId) + 1;
      newItem.orderNumber = this.getMaxValue(item.workOrderProductId) + 1;
      newItem.caseCountProduct = null;
      newItem.availableQuantityCarton = null;
      newItem.sampleSizeCarton = null;
      newItem.sampleSizeProduct = null;
      newItem.cartonQty = null;
      newItem.qty = null;
      newItem.caseCountProduct = null;
      newItem.availableQuantityCarton = null;
      newItem.sampleSizeCarton = null;
      newItem.availableQuantityProduct = null;
      newItem.excessShortQuantity = null;
      newItem.percentAvailable = null;
      newItem.sampleSizeProduct = null;
      newItem.exportCartonNumbersOpened = null;
      newItem.remarks = null;
      newItem.packedQty = null;
      newItem.underPackingQty = null;
      newItem.underProductionFinishingQty = null;
      newItem.workorderProductId = item.workorderProductId
      this.productQuantityVerificationList.push(newItem);
    },

    async deleteRecord() {
        if (this.deleteRecordList.length > 0) {
            let getGuidIdList = [];
            this.deleteRecordList.forEach((e) => {
                getGuidIdList.push(e);
            });
            await this.utilityFn.getServerDataAsync("post", this.apiList.bulkDeleteWorkOrderProductQuantityVerificationResults, getGuidIdList);
            this.calculateSampleSizeCartonProduct(
                this.workOrderQuantityVerificationObj.cartonInspectionLevel,
                this.workOrderQuantityVerificationObj.productInspectionLevel,
                this.workOrderQuantityVerificationObj.cartonSamplingMethodology,
                this.workOrderQuantityVerificationObj.productSamplingMethodology
            );
        }

        this.deleteDialog = false;
    },

    deleteChild(item) {
      if(item.id){
        this.deletedItem.id = item.id
      }
      this.deletedItem.deleteIndex = this.productQuantityVerificationList.indexOf(item);
      (this.deleteDialog = true);
    },

    deleteQuantity() {
        this.productQuantityVerificationList.splice(this.deletedItem.deleteIndex, 1)
        if (this.deletedItem.id) {
            this.deleteRecordList.push(this.deletedItem.id)
        }
    
        this.deleteDialog = false;
    },

    onChangeShipQtyCarton(item){
      let index =  this.productQuantityVerificationList.indexOf(item);
          if (index != -1) {
            let cartonCaseCount = this.productQuantityVerificationList[index].caseCountProduct
            if(cartonCaseCount == null || cartonCaseCount <=0){
              this.productQuantityVerificationList[index].shipqtyUnit =this.productQuantityVerificationList[index].qty
            }
            else{
              if (this.shipQtyCartonCDFId) {
                this.productQuantityVerificationList[index].shipqtyUnit = this.productQuantityVerificationList[index].shipqty * cartonCaseCount
              }
            }
          }

      
    },

    onKeyUpAvailableQuantityCarton(item, isManual, isFromGetProductQuantityVerificationList = false) {
      this.editedIndex = this.productQuantityVerificationList.indexOf(item);
      this.cartonEditedItem.clientProductId = item.clientProductId;
      this.cartonEditedItem.skuDisplay = item.skuDisplay;
      this.cartonEditedItem.productID = item.productID;
      this.cartonEditedItem.workOrderProductId = item.workOrderProductId;
      this.cartonEditedItem.id = item.id;
      this.cartonEditedItem.po = item.po;
      this.cartonEditedItem.cartonQty = item.cartonQty;
      this.cartonEditedItem.qty = item.qty;
      this.cartonEditedItem.caseCountProduct = item.caseCountProduct;
      this.cartonEditedItem.availableQuantityCarton = item.availableQuantityCarton;
      this.cartonEditedItem.sampleSizeCarton = item.sampleSizeCarton;
      this.cartonEditedItem.availableQuantityProduct = item.availableQuantityProduct;
      this.cartonEditedItem.excessShortQuantity = item.excessShortQuantity;
      this.cartonEditedItem.percentAvailable = item.percentAvailable;
      this.cartonEditedItem.sampleSizeProduct = item.sampleSizeProduct;
      this.cartonEditedItem.orderNumber = item.orderNumber;
      this.cartonEditedItem.exportCartonNumbersOpened = item.exportCartonNumbersOpened;
      this.cartonEditedItem.packedQty = item.packedQty;
      this.cartonEditedItem.underPackingQty = item.underPackingQty;
      this.cartonEditedItem.underProductionFinishingQty = item.underProductionFinishingQty;
      this.cartonEditedItem.shipqty = item.shipqty;
      this.cartonEditedItem.shipqtyId = item.shipqtyId;
      this.cartonEditedItem.shipqtyUnit = item.shipqtyUnit;
      this.cartonEditedItem.shipqtyUnitId = item.shipqtyUnitId;
      this.cartonEditedItem.remarks = item.remarks;

      let totalAvailableQuantityProduct = 0;
      let totalExcessShortQuantity = 0;

      let filteredProductQuantityVerificationList = this.productQuantityVerificationList.filter((x) => x.workOrderProductId == this.cartonEditedItem.workOrderProductId && x.orderNumber == 1);

      if (Number(this.cartonEditedItem.caseCountProduct == -1) || Number(this.cartonEditedItem.availableQuantityCarton == -1)) {
        totalExcessShortQuantity = -1;
        this.cartonEditedItem.availableQuantityCarton = -1;
        totalAvailableQuantityProduct = -1;
      } else {
        this.cartonEditedItem.packedQty = Number(this.cartonEditedItem.caseCountProduct) * Number(this.cartonEditedItem.availableQuantityCarton);
        this.cartonEditedItem.availableQuantityProduct = Number(this.cartonEditedItem.underPackingQty == -1 ? 0 : this.cartonEditedItem.underPackingQty) + Number(this.cartonEditedItem.caseCountProduct) * Number(this.cartonEditedItem.availableQuantityCarton);
        totalExcessShortQuantity = totalAvailableQuantityProduct - filteredProductQuantityVerificationList[0].qty;
      }

      let workOrderProductQuantityVerificationResultObjList = [];

      const addedRowQuantity = {
        id: this.cartonEditedItem.id,
        workOrderProductId: this.cartonEditedItem.workOrderProductId,
        caseCountProduct: this.cartonEditedItem.caseCountProduct,
        availableQuantityCarton: this.cartonEditedItem.availableQuantityCarton,
        sampleSizeCarton: this.cartonEditedItem.availableQuantityCarton  == 0 ? 0 : this.cartonEditedItem.sampleSizeCarton,
        packedQty: this.cartonEditedItem.packedQty,
        excessShortQuantity: totalExcessShortQuantity,
        percentAvailable: Math.round( ((Number(this.cartonEditedItem.packedQty) + Number(this.cartonEditedItem.underPackingQty == -1 ? 0 : this.cartonEditedItem.underPackingQty))/this.getSKUDisplay(this.cartonEditedItem.workOrderProductId).qty)*100),
        sampleSizeProduct: this.cartonEditedItem.availableQuantityProduct == 0 ? 0 : this.cartonEditedItem.sampleSizeProduct,
        orderNumber: this.cartonEditedItem.orderNumber,
        exportCartonNumbersOpened: this.cartonEditedItem.exportCartonNumbersOpened,
        remarks: this.cartonEditedItem.remarks,
        underPackingQty: this.cartonEditedItem.underPackingQty,
        availableQuantityProduct:  this.cartonEditedItem.availableQuantityProduct,
        underProductionFinishingQty: this.cartonEditedItem.underProductionFinishingQty,
        sectionId: 1,
      };

      if (this.editedIndex > -1) {

        this.productQuantityVerificationList[this.editedIndex].availableQuantityCarton = this.cartonEditedItem.availableQuantityCarton;
        this.productQuantityVerificationList[this.editedIndex].shipqty = this.cartonEditedItem.shipqty;
        this.productQuantityVerificationList[this.editedIndex].caseCountProduct = this.cartonEditedItem.caseCountProduct;
        this.productQuantityVerificationList[this.editedIndex].remarks = this.cartonEditedItem.remarks;
        this.productQuantityVerificationList[this.editedIndex].packedQty = this.cartonEditedItem.packedQty;
        this.productQuantityVerificationList[this.editedIndex].exportCartonNumbersOpened = this.cartonEditedItem.exportCartonNumbersOpened;
        this.productQuantityVerificationList[this.editedIndex].excessShortQuantity = this.cartonEditedItem.excessShortQuantity;
        this.productQuantityVerificationList[this.editedIndex].percentAvailable = this.cartonEditedItem.percentAvailable;
        this.productQuantityVerificationList[this.editedIndex].availableQuantityProduct =  this.cartonEditedItem.availableQuantityProduct;
      }

      this.productQuantityVerificationList.forEach((p) => {
        p.isManualSampleSizeCarton = isManual === false ? false : p.isManualSampleSizeCarton;
        p.isManualSampleSizeProduct = isManual === false ? false : p.isManualSampleSizeProduct;
      });

      workOrderProductQuantityVerificationResultObjList.push(addedRowQuantity);

      if (this.shipQtyCartonCDFId && this.cartonEditedItem.orderNumber == 1) {
        let indexProduct = this.products.findIndex((x) => x.id == this.cartonEditedItem.workOrderProductId);
        if (indexProduct != -1) {
          let indexCDF = this.products[indexProduct].workOrderProductCompanyClientDefinedFields.findIndex((x) => x.productCompanyClientDefinedFieldId == this.shipQtyCartonCDFId);
          if (indexCDF != -1) {
            this.products[indexProduct].workOrderProductCompanyClientDefinedFields[indexCDF].value = this.cartonEditedItem.shipqty;
          }
        }
      }

      if (workOrderProductQuantityVerificationResultObjList.length > 0 && !isFromGetProductQuantityVerificationList) {
        this.calculateSampleSizeCartonProductNew(
          this.workOrderQuantityVerificationObj.cartonInspectionLevel,
          this.workOrderQuantityVerificationObj.cartonSamplingMethodology
        );

        //this.reCompute = true;
      }
    },

    calculateSampleSizeCartonProductNew(cartonInspectionLevel, cartonSamplingMethodology) {
      this.onChange();
      let workOrderProductQuantityVerificationResultObjList = [];

      let SKUSamplingList = this.products.map((x) => {
        return {
          id: x.id,
          workOrderID: x.workOrderID,
          productId: x.productID,
          SKUDisplay: x.skuDisplay,
          PO: x.po,
          productQty: this.getTotalAvailableQuantityProduct(x.id),
          cartonQty: this.getTotalAvailableQuantityCarton(x.id),
          productSampleSize: 0,
          cartonSampleSize: 0,
          actualProductSampleSize: 0,
          actualCartonSampleSize: 0,
          aqlMinor: this.workOrder.aqlMinor,
          aqlMajor: this.workOrder.aqlMajor,
          aqlCritical: this.workOrder.aqlCritical,
          minorAC: 0,
          minorRE: 0,
          majorAC: 0,
          majorRE: 0,
          criticalAC: 0,
          criticalRE: 0,
        };
      });

      let samplingMethodology = this.workOrderQuantityVerificationObj.productSamplingMethodology;
      let inspectionLevel = this.workOrderQuantityVerificationObj.productInspectionLevel
      let samplingPlan1 = this._samplingPlanLists.samplingPlan1;
      let round =this._samplingPlanLists.round;
      let productType = this._samplingPlanLists.productType;    
      let aqlMinor = this._samplingPlanLists.aqlMinor;
      let aqlMajor = this._samplingPlanLists.aqlMajor;
      let aqlCritical = this._samplingPlanLists.aqlCritical;
      let workorderId = this._samplingPlanLists.workorderId

      let workorderSamplingParameters = {
        samplingMethodology : samplingMethodology,
        inspectionLevel : inspectionLevel,
        samplingPlan1 : samplingPlan1,
        round : round,
        productType : productType,
        aqlMinor:aqlMinor,
        aqlMajor: aqlMajor,
        aqlCritical: aqlCritical,
        workorderId : workorderId,
        changedSamplingMethodology: true
      }


      let samplingPlanByInspectionLevelAndSamplingMethodology = this.samPlan.getQVSamplingPlanByInspectionLevelAndSamplingMethodology(
        SKUSamplingList,
        inspectionLevel,
        samplingMethodology,
        samplingPlan1,
        productType,
        round,
        true
      );

        

      let samplinPlan = {...workorderSamplingParameters, ...samplingPlanByInspectionLevelAndSamplingMethodology};
      this.$store.dispatch("inspection/setSamplingPlanList", samplinPlan)   


      this.workmanshipSampleSize = samplingPlanByInspectionLevelAndSamplingMethodology.totalProductSampleSize

      this.productQuantityVerificationList.forEach((item) => {
        let cartonSampleSize = null;
        let productSampleSize = null;

        if (cartonInspectionLevel == "Fixed") {
          cartonSampleSize = item.sampleSizeCarton == -1 ? null : item.sampleSizeCarton;
        } else if (cartonInspectionLevel == "Square Root") {
          let squareRootCarton = this.getSquareRootCarton(item.availableQuantityCarton, this.totalCartonAvailablePoQty, cartonSamplingMethodology);
          cartonSampleSize =  isNaN(squareRootCarton) ? 0 : squareRootCarton;
        }

        let filteredSKUSamplingList = samplingPlanByInspectionLevelAndSamplingMethodology.SKUSamplingList.filter((x) => x.id == item.workOrderProductId);
        if (filteredSKUSamplingList.length > 0) {
          productSampleSize = isNaN(filteredSKUSamplingList[0].productSampleSize) ? 0 : filteredSKUSamplingList[0].productSampleSize;
        }

        if (this.isManualSampleSizeProduct && this.manualSampleSizeProductId == item.id) {
          productSampleSize = isNaN(item.sampleSizeProduct) ? 0 : item.sampleSizeProduct

          this.isManualSampleSizeProduct = false;
          this.manualSampleSizeProductId = null;
        }

        const addedRowQuantity = {
          id: item.id,
          workOrderProductId: item.workOrderProductId,
          caseCountProduct: item.caseCountProduct,
          availableQuantityCarton: item.availableQuantityCarton,
          sampleSizeCarton: item.availableQuantityCarton == -1 ? -1 :  item.availableQuantityCarton == 0 ? 0 : cartonSampleSize,
          availableQuantityProduct: item.availableQuantityCarton == -1 ? -1 : item.availableQuantityProduct,
          excessShortQuantity: item.availableQuantityCarton == -1 ? -1 : item.excessShortQuantity,
          percentAvailable: Math.round( ((Number(item.packedQty) + Number(item.underPackingQty == -1 ? 0 : item.underPackingQty))/this.getSKUDisplay(item.workOrderProductId).qty)*100),
          sampleSizeProduct: item.availableQuantityCarton == -1 ? -1 :   item.availableQuantityProduct == 0 ? 0 : productSampleSize,
          orderNumber: item.orderNumber,
          remarks: item.remarks,
          exportCartonNumbersOpened: item.exportCartonNumbersOpened,
          packedQty: item.packedQty,
          underPackingQty: item.underPackingQty,
          underProductionFinishingQty: item.underProductionFinishingQty,
          sectionId: 1,
        };

        workOrderProductQuantityVerificationResultObjList.push(addedRowQuantity);
        if(!item.isManualSampleSizeCarton) {
          item.sampleSizeCarton = item.availableQuantityCarton == -1 ? -1 : cartonSampleSize;
        }

        if(!item.isManualSampleSizeProduct) {
          item.sampleSizeProduct = item.availableQuantityCarton == -1 ? -1 : productSampleSize;
        }
      });

      const hasManualSampleSize = this.productQuantityVerificationList.some(p => p.isManualSampleSizeCarton === true);
      if(!hasManualSampleSize) {
        this.distributeFractionCarton();
      }
      this.$store.dispatch("inspection/setProductQuantityVerificationList", _.cloneDeep(this.productQuantityVerificationList));
      this.$store.dispatch("inspection/setQVListUpdated", true);
      return;
    },

    calculateEachSKUSampleSize(cartonInspectionLevel, productInspectionLevel, cartonSamplingMethodology, productSamplingMethodology) {
      this.onChange();

      let SKUSamplingList = this.products.map((x) => {
        return {
          id: x.id,
          workOrderID: x.workOrderID,
          productId: x.productID,
          SKUDisplay: x.skuDisplay,
          PO: x.po,
          productQty: this.getTotalAvailableQuantityProduct(x.id),
          cartonQty: this.getTotalAvailableQuantityCarton(x.id),
          productSampleSize: 0,
          cartonSampleSize: 0,
          actualProductSampleSize: 0,
          actualCartonSampleSize: 0,
          aqlMinor: this.workOrder.aqlMinor,
          aqlMajor: this.workOrder.aqlMajor,
          aqlCritical: this.workOrder.aqlCritical,
          minorAC: 0,
          minorRE: 0,
          majorAC: 0,
          majorRE: 0,
          criticalAC: 0,
          criticalRE: 0,
        };
      });
      //Sample Size on Product Tab
      let samplingPlanByInspectionLevelAndSamplingMethodology = this.samPlan.getQVSamplingPlanByInspectionLevelAndSamplingMethodology(
        SKUSamplingList,
        productInspectionLevel,
        productSamplingMethodology,
        this.workOrder.inspectionLevel,
        this.workOrder.productType,
        this.workOrder.samplingPlan,
        true
      );

      this.workmanshipSampleSize = samplingPlanByInspectionLevelAndSamplingMethodology.totalProductSampleSize
      this.productQuantityVerificationList.forEach((item) => {
        let cartonSampleSize = null;
        let productSampleSize = null;

        //Sample Size on  Carton Tab
        if (cartonInspectionLevel == "Fixed") {
          cartonSampleSize = item.sampleSizeCarton == -1 ? null : item.sampleSizeCarton;
        } else if (cartonInspectionLevel == "Square Root") {
          let squareRootCarton = this.getSquareRootCarton(item.availableQuantityCarton, this.totalCartonAvailablePoQty, cartonSamplingMethodology);
          cartonSampleSize =  isNaN(squareRootCarton) ? 0 : squareRootCarton;
        }

        let filteredSKUSamplingList = samplingPlanByInspectionLevelAndSamplingMethodology.SKUSamplingList.filter((x) => x.id == item.workOrderProductId);
        if (filteredSKUSamplingList.length > 0) {
          productSampleSize = filteredSKUSamplingList[0].productSampleSize;
        }

        if (this.isManualSampleSizeProduct && this.manualSampleSizeProductId == item.id) {
          productSampleSize = item.sampleSizeProduct;
          this.isManualSampleSizeProduct = false;
          this.manualSampleSizeProductId = null;
        }


        if(!item.isManualSampleSizeCarton) {
          item.sampleSizeCarton = item.availableQuantityCarton == -1 ? -1 :  item.availableQuantityCarton == 0 ? 0 : item.sampleSizeCarton == null ? cartonSampleSize : this._samplingPlanLists.changedSamplingMethodology? cartonSampleSize : item.sampleSizeCarton;
        }

        if(!item.isManualSampleSizeProduct) {
          item.sampleSizeProduct = item.availableQuantityCarton == -1 ? -1 :  item.availableQuantityProduct == 0 ? 0 : item.sampleSizeProduct == null ? productSampleSize : this._samplingPlanLists.changedSamplingMethodology? productSampleSize : item.sampleSizeProduct;
        }
        this.workmanshipSampleSize = samplingPlanByInspectionLevelAndSamplingMethodology.totalProductSampleSize
      });  

      
      // this.calculateWorkmanshipSampleSize();
      const hasManualSampleSize = this.productQuantityVerificationList.some(p => p.isManualSampleSizeCarton === true);
      if(!hasManualSampleSize) {
        this.distributeFractionCarton();
      }
    },

    /**
     * Copy the values of the row before the selected row for carton tab
     */
    copyAboveRowCarton(index) {
      const aboveRow = this.productQuantityVerificationList.find((x) => x.indexMarkerCarton == index - 1);
      const currentRow = this.productQuantityVerificationList.find((x) => x.indexMarkerCarton == index);
      // if current row is a child row, only update the child editable fields
      if (currentRow.orderNumber > 1) {
        currentRow.caseCountProduct = aboveRow.caseCountProduct;
        currentRow.availableQuantityCarton = aboveRow.availableQuantityCarton;
        currentRow.sampleSizeCarton = aboveRow.sampleSizeCarton;
      } else {
        // if the row to be copied is a child row, only update the child editable fields
        if (aboveRow.orderNumber > 1) {
          currentRow.caseCountProduct = aboveRow.caseCountProduct;
          currentRow.availableQuantityCarton = aboveRow.availableQuantityCarton;
          currentRow.sampleSizeCarton = aboveRow.sampleSizeCarton;
        }
        // if the row to be copied is also a parent row, update all editable fields
        else {
          currentRow.shipqty = aboveRow.shipqty;
          currentRow.caseCountProduct = aboveRow.caseCountProduct;
          currentRow.availableQuantityCarton = aboveRow.availableQuantityCarton;
          currentRow.sampleSizeCarton = aboveRow.sampleSizeCarton;
          currentRow.exportCartonNumbersOpened = aboveRow.exportCartonNumbersOpened;
          currentRow.remarks = aboveRow.remarks;
        }
      }
      this.onKeyUpAvailableQuantityCarton(aboveRow);
      this.onKeyUpAvailableQuantityCarton(currentRow);
    },

    /**
     * Copy function for the product tab
     */
    copyAboveRowProduct(index) {
      // filter only the parent rows and sort by ascending order to quickly get the row before the current row
      const orderedFilteredList = _.orderBy(
        this.productQuantityVerificationList.filter((x) => x.orderNumber == 1),
        ["indexMarkerProduct"],
        ["asc"]
      );
      const currentRowIndex = orderedFilteredList.findIndex((x) => x.indexMarkerProduct == index);
      const currentRow = orderedFilteredList[currentRowIndex];
      const aboveRow = orderedFilteredList.filter((x) => x.orderNumber == 1)[currentRowIndex - 1];

      currentRow.qty = aboveRow.qty;
      currentRow.shipqtyUnit = aboveRow.shipqtyUnit;
      currentRow.underPackingQty = aboveRow.underPackingQty;
      currentRow.underProductionFinishingQty = aboveRow.underProductionFinishingQty;
      currentRow.sampleSizeProduct = aboveRow.sampleSizeProduct;
    },

    resetCartonIndexMarkers(e) {
      if (e.page == 1) {
        this.productQuantityVerificationList.forEach((x) => (x.indexMarkerCarton = null));
      }
    },

    resetProductIndexMarkers(e) {
      if (e.page == 1) {
        this.productQuantityVerificationList.forEach((x) => (x.indexMarkerProduct = null));
      }
    },

    /**
     * Generate index for each row - will be used for the copy function
     */
    getRowIndex(index, pagination) {
      return index + (pagination.page - 1) * pagination.itemsPerPage;
    },

    async loadData() {
      await this.loadWorkOrderList(this.workOrderId);
    },

    // only save when the isFormDirty is true - means user has changed the value in the UI
    // user can still force saving even if nothing has been changed in UI if "forceSave" is true
    // "forceSave" value will come from the Footer Component. The footer component has a Save button
    // that calls this method and will force this method to call the API to save the values
    async saveData(forceSave = false) {
      if (!forceSave && !this.isFormDirty)
        return

      this.validateTolerance();

      if(this.isInvalidTolerance) return;

      try {
        this.$store.dispatch("inspection/setProductQuantityVerificationList", _.cloneDeep(this.productQuantityVerificationList));
        const saveValues = async () => {
          const mappedList = this.mappedProductQuantityVerificationList.map((x) => _.omit(x, ["indexMarkerCarton", "indexMarkerProduct"]));
          const mappedCloneList = this.cloneProductQuantityVerificationList.map((x) => _.omit(x, ["indexMarkerCarton", "indexMarkerProduct"]));

          // check auto calculate for other sections
          let updateSampleSizeCalculation = false;
          let filteredproductQuantityVerificationList = mappedList.filter((x) => {
            const originalValue = mappedCloneList.find((item) => item.id == x.id);

            updateSampleSizeCalculation =
              updateSampleSizeCalculation ||
              (!!originalValue &&
                (x.caseCount != originalValue.caseCount ||
                  x.availableQuantityCarton != originalValue.availableQuantityCarton ||
                  x.availableQuantityProduct != originalValue.availableQuantityProduct ||
                  x.sampleSizeCarton != originalValue.sampleSizeCarton ||
                  x.sampleSizeProduct != originalValue.sampleSizeProduct));

            if (_.isEqual(x, originalValue) || (x.caseCount == null && x.availableQuantityCarton == null && x.orderNumber != 1)) return false;
            return true;
          });

          let workOrderProductCompanyClientDefinedFieldObj = [];
          let upsertWorkOrderProductObj = [];
          let getShipQtyCartonCDFId = this.shipQtyCartonCDFId;
          let getShipQtyUnitCDFId = this.shipQtyUnitCDFId;

          filteredproductQuantityVerificationList.forEach((e) => {
            e.sectionId = 1;
            e.id = e.id.indexOf('child_') === -1 ? e.id : null;

            if (getShipQtyCartonCDFId && e.orderNumber == 1) {
              // Carton
              let iterateWorkOrderProductCompanyClientDefinedFieldCartonObj = {
                id: e.shipqtyId,
                workOrderProductId: e.workOrderProductId,
                productCompanyClientDefinedFieldId: getShipQtyCartonCDFId,
                value: e.shipqty,
              };
              workOrderProductCompanyClientDefinedFieldObj.push(iterateWorkOrderProductCompanyClientDefinedFieldCartonObj);
            }

            // if (getShipQtyUnitCDFId && e.orderNumber == 1) {
            //   // Product
            //   let iterateWorkOrderProductCompanyClientDefinedFieldObj = {
            //     id: e.shipqtyUnitId,
            //     workOrderProductId: e.workOrderProductId,
            //     productCompanyClientDefinedFieldId: getShipQtyUnitCDFId,
            //     value: e.shipqtyUnit,
            //   };
            //   workOrderProductCompanyClientDefinedFieldObj.push(iterateWorkOrderProductCompanyClientDefinedFieldObj);
            // }
            if(e.orderNumber ==1){
              let iterateUpsertWorkOrderProductObj = {
                Id: e.workOrderProductId,
                PO: e.po,
                Qty: e.qty,
                CartonQty: e.cartonQty,
                ShipQty: e.shipqtyUnit
              };

              upsertWorkOrderProductObj.push(iterateUpsertWorkOrderProductObj);
            }
          });

          const saveSampleSizeAsync = async () => {
            if (updateSampleSizeCalculation) {
              await Promise.all([this.calculateSizeMeasurementSampleSize(), this.calculateBarcodeSampleSize(), this.calculatePackageCheckSampleSize(), this.calculateWorkmanshipSampleSize()]);
            }
          };

          if (filteredproductQuantityVerificationList.length > 0) {
            await Promise.all([
              this.utilityFn.getServerDataAsync("post", this.apiList.upsertWorkOrderProductCompanyClientDefinedField, workOrderProductCompanyClientDefinedFieldObj),
              this.utilityFn.getServerDataAsync("put", this.apiList.bulkupdateWorkOrderProductResult, upsertWorkOrderProductObj),
              this.utilityFn.getServerDataAsync("post", this.apiList.upsertWorkOrderProductQuantityVerificationResult, filteredproductQuantityVerificationList),
              saveSampleSizeAsync(),
              this.upsertWorkOrderProductResultSamplingForWorkmanship(filteredproductQuantityVerificationList),
            ]);
          }
        };

        //await Promise.all([ this.updateworkOrderQuantityVerificationObj()]);

        const updateSection = async () => {
          let section = this.workOrder.workOrderInspectionSections.find((x) => x.sectionId == this.sectionId);
          let result = "";
        //  let productResult = this.workOrderQuantityVerificationObj.productResultId ? this.workOrderResultList.find((x) => x.id == this.workOrderQuantityVerificationObj.productResultId).name : null;

          //carton only for overall result QV
          if (this.workOrderList.cartonRequired == "" || this.workOrderList.cartonRequired == null) {
            result = "";
          } else {
            if (this.workOrderList.cartonRequired == -1) {
              result= "N/A";
              if (this.workOrderList.qvToleranceCrt == true) {
                result = "Pending";
              }
            } else {
              let product = this.products.filter((x) => x.workOrderProductResults.availQtyPrd == -1);
              if (product.length == 0) {
                if(this.isTolerance && this.toleranceOption === 'carton') {
                  if((Number(this.cartonAvailablePercent) <= (Number(this.workOrderList.cartonRequired) + Number(this.upperTolerance))) && (Number(this.cartonAvailablePercent) >= (Number(this.workOrderList.cartonRequired) - Number(this.lowerTolerance)))) {
                    result = "Conformed";
                  } else {
                    result = "Non-Conformed";
                  }
                } else if(this.isTolerance && this.toleranceOption === 'product' && this.productOption === 'overall') {
                  if((Number(this.overallProductSKU) <= (Number(this.workOrderList.cartonRequired) + Number(this.upperTolerance))) && (Number(this.overallProductSKU) >= (Number(this.workOrderList.cartonRequired) - Number(this.lowerTolerance)))) {
                    result = "Conformed";
                  } else {
                    result = "Non-Conformed";
                  }
                } else if(this.isTolerance && this.toleranceOption === 'product' && this.productOption === 'individual') {
                  const isConformed = this.productQuantityVerificationList.every((p) => {
                    const percentage = this.getPercentAvailableProduct(p.workOrderProductId, p.qty);
                    if((Number(percentage) <= (Number(this.workOrderList.cartonRequired) + Number(this.upperTolerance))) && (Number(percentage) >= (Number(this.workOrderList.cartonRequired) - Number(this.lowerTolerance)))) {
                      return true;
                    } else {
                      return false;
                    }
                  });

                  result = isConformed ? "Conformed" : "Non-Conformed";
                } else {
                  result = Number(this.cartonAvailablePercent) >= Number(this.workOrderList.cartonRequired) ? "Conformed" : "Non-Conformed";
                }
              } else {
                result = "N/A";
              }
            }
          }

          //Carton
          // if (this.workOrderList.cartonRequired == "" || this.workOrderList.cartonRequired == null) {
          //   this.cartonTabResult = "";
          // } else {
          //   if (this.workOrderList.cartonRequired == -1) {
          //     this.cartonTabResult = "N/A";
          //     if (this.workOrderList.qvToleranceCrt == true) {
          //       this.cartonTabResult = "Pending";
          //     }
          //   } else {
          //     let product = this.products.filter((x) => x.workOrderProductResults.availQtyPrd == -1);
          //     if (product.length == 0) {
          //       this.cartonTabResult = Number(this.cartonAvailablePercent) >= Number(this.workOrderList.cartonRequired) ? "Pass" : "Fail";
          //     } else {
          //       this.productTabResult = "N/A";
          //     }
          //   }
          // }
          // Product
          // if (this.workOrderList.productRequired == "" || this.workOrderList.productRequired == null) {
          //   this.productTabResult = "";
          // } else {
          //   if (this.workOrderList.productRequired == -1) {
          //     this.productTabResult = "N/A";
          //     if (this.workOrderList.qvTolerancePrd == true) {
          //       this.productTabResult = "Pending";
          //     }
          //   } else {
          //     let product = this.products.filter((x) => x.workOrderProductResults.availQtyPrd == -1);
          //     if (product.length == 0) {
          //       this.productTabResult = Number(this.productAvailablePercent) >= Number(this.workOrderList.productRequired) ? "Pass" : "Fail";
          //     } else {
          //       this.productTabResult = "N/A";
          //     }
          //   }
          // }

          //this.SaveCarton()

          // if (this.workOrderQuantityVerificationObj.productResultId != null) {
          //   if (productResult == "Fail") {
          //     result = "Non-Conformed";
          //   } else {
          //     if (productResult == "Pending") {
          //       result = "Pending";
          //     } else {
          //       if (productResult == "Pass") {
          //         result = "Conformed";
          //       } else {
          //         if (productResult == "N/A") {
          //           result = "N/A";
          //         } else {
          //           result = "";
          //         }
          //       }
          //     }
          //   }
          // }

          // switch (this.cartonTabResult) {
          //   case "Pass":
          //     switch (this.productTabResult) {
          //       case "Pass":
          //         result = "Conformed";
          //         break;

          //       case "Fail":
          //         result = "Non-Conformed";
          //         break;

          //       case "Pending":
          //         result = "Pending";
          //         break;

          //       case "N/A":
          //         result = "Conformed";
          //         break;
          //     }
          //     break;

          //   case "Fail":
          //     switch (this.productTabResult) {
          //       case "Pass":
          //         result = "Non-Conformed";
          //         break;

          //       case "Fail":
          //         result = "Non-Conformed";
          //         break;

          //       case "Pending":
          //         result = "Non-Conformed";
          //         break;

          //       case "N/A":
          //         result = "Non-Conformed";
          //         break;
          //     }
          //     break;

          //   case "Pending":
          //     switch (this.productTabResult) {
          //       case "Pass":
          //         result = "Pending";
          //         break;

          //       case "Fail":
          //         result = "Non-Conformed";
          //         break;

          //       case "Pending":
          //         result = "Pending";
          //         break;

          //       case "N/A":
          //         result = "Pending";
          //         break;
          //     }
          //     break;

          //   case "N/A":
          //     switch (this.productTabResult) {
          //       case "Pass":
          //         result = "Conformed";
          //         break;

          //       case "Fail":
          //         result = "Non-Conformed";
          //         break;

          //       case "Pending":
          //         result = "Pending";
          //         break;

          //       case "N/A":
          //         result = "N/A";
          //         break;
          //     }
          //     break;
          // }
          this.inspectionSummarySectionResult.forEach(x => {
            if(x.sectionId==1){
              x.result = result;
            }
          })
          this.$store.dispatch("inspection/setInspectionSummaryResult", this.inspectionSummarySectionResult);
          if (!this.isSectionDisabled || this.sectionSubmitted) {
            const section = this.sectionDetail;
            section.result = result;

            const index = this._workOrderDetails.workOrderInspectionSections.findIndex((x) => x.sectionId == this.sectionId);
            if (index !== -1) {
              this._workOrderDetails.workOrderInspectionSections[index] = section;
              this.$store.dispatch("inspection/setWorkOrderDetails", _.cloneDeep(this._workOrderDetails));
            }
            
            await this.utilityFn.getServerDataAsync("post", this.apiList.sectionUpdate, section);
          }

          this.logChanges(false);
        };

        const saveTolerance = async () => {
          const tolerance = {
            isTolerance: this.isTolerance,
            toleranceOption: this.toleranceOption,
            productOption: this.productOption,
            upperTolerance: this.upperTolerance,
            lowerTolerance: this.lowerTolerance,
            overallProductSKU: this.overallProductSKU
          }
          this.utilityFn.getServerDataAsync("post", this.apiList.updateTolerance + "/" + this.workOrderId, tolerance);
        }

        // const request3 = async () => {
        //   if (this.workOrder.resources.length === 1) {
        //     await this.submit_item();

        //     console.log('async saveData request3 done')
        //   }
        // }

        await Promise.all([this.deleteRecord(), saveValues(), updateSection(), saveTolerance()]);
        return true;
      } catch (ex) {
        console.log("saveData error", ex);
        return false;
      }
    },
    upsertWorkOrderProductResultSamplingForWorkmanship(filteredQuantityVerificationList) {
      filteredQuantityVerificationList.forEach((element) => {
      var workOrderProductID = element.workOrderProductId;

        const formPatch = [
          {
            op: "replace",
            path: "/sampleSizeProductWorkmanship",
            value: element.sampleSizeProduct,
          }
        ];

        this.apiFn.upsertProductResult(workOrderProductID, formPatch).then((result) => {
          //this.loadWorkOrderList(this.workOrderId);
        });
      });
    },
    onChangeTolerance() {
      this.toleranceOption = null;
      this.productOption = null;
      this.upperTolerance = 0;
      this.lowerTolerance = 5;
      this.overallProductSKU = 0;
    },
    onToleranceOptionChange() {
      this.productOption = null;
    },
    onProductOptionChange() {
      if(this.productOption === 'overall') {
        this.overallProductSKU = Math.round((this.totalProductAvailablePoQty/this.totalProductPoQty) * 100);
      } else {
        this.overallProductSKU = 0;
      }
    },
    async validateForm() {
      if(this._workOrderDetails.clientName.toLowerCase() !== 'walmart global sourcing' && this._workOrderDetails.clientName.toLowerCase() !== 'asda stores limited') return '';
      if(this._workOrderDetails.isPreShipOrFinalRandom !== true) return '';
      if(!this.workOrderList.cartonRequired) return 'Missing Carton Required (%) in Quantity Verification';

      for (let i = 0; i < this.productQuantityVerificationList.length; i++) {
          if(!this.productQuantityVerificationList[i].shipqty) return 'Missing Ship Qty(Unit) in Carton Quantity Verification';

          if(this.productQuantityVerificationList[i].sampleSizeCarton && this.productQuantityVerificationList[i].orderNumber === 1) {
            if(+this.productQuantityVerificationList[i].sampleSizeCarton > 0 && !this.productQuantityVerificationList[i].exportCartonNumbersOpened) {
              return 'Missing Carton Number Opened in Quantity Verification';
            }
          }

          if(!this.productQuantityVerificationList[i].shipqtyUnit) return 'Missing Ship Qty(Unit) in Product Quantity Verification';
      }
  
      return '';
    },
    validateTolerance() {
      if(this.$refs['upperTolerance']?.errorBucket[0] === 'Required' || this.$refs['lowerTolerance']?.errorBucket[0] === 'Required') {
        this.message = "Missing Input Required Fields";
        this.snackbar = true;
        this.isInvalidTolerance = true;
        return;
      }
      
      if(this.$refs['cartonRequired']?.errorBucket[0] === 'Invalid') {
        this.message = "Invalid Required (%)";
        this.snackbar = true;
        this.isInvalidTolerance = true;
        return;
      }

      if(this.$refs['upperTolerance']?.errorBucket[0] === 'Invalid' && this.$refs['lowerTolerance']?.errorBucket[0] === 'Invalid') {
        this.message = "Invalid Upper(+)% and Lower(-)%";
        this.snackbar = true;
        this.isInvalidTolerance = true;
        return;
      }

      if(this.$refs['upperTolerance']?.errorBucket[0] === 'Invalid') {
        this.message = "Invalid Upper(+)%";
        this.snackbar = true;
        this.isInvalidTolerance = true;
        return;
      }

      if(this.$refs['lowerTolerance']?.errorBucket[0] === 'Invalid') {
        this.message = "Invalid Lower(-)%";
        this.snackbar = true;
        this.isInvalidTolerance = true;
        return;
      }

      if(this.isTolerance && this.toleranceOption === null ) {
        this.message = "Please select Apply to Carton/Apply to Product";
        this.snackbar = true;
        this.isInvalidTolerance = true;
        return;
      }

      if(this.isTolerance && this.toleranceOption === 'product' && this.productOption === null) {
        this.message = "Please select Overall Product SKU(s)/Individual Product SKU";
        this.snackbar = true;
        this.isInvalidTolerance = true;
        return;
      }

      this.isInvalidTolerance = false;
    }
  },
  watch: {
    isDataLoaded(val1, val2) {
      setTimeout(() => {
        this.isFormReadyCheck = true;
      });
    },
    workOrderList: {
      handler(val1, val2) {
        this.isFormDirty = this.isFormReadyCheck; // && val1 != val 2;
      },
      deep: true
    },
    workOrderQuantityVerificationObj: {
      handler(val1, val2) {
        this.isFormDirty = this.isFormReadyCheck; // && val1 != val 2;
      },
      deep: true
    },
    productQuantityVerificationList: {
      handler(val1, val2) {
        this.isFormDirty = this.isFormReadyCheck; // && val1 != val 2;
      },
      deep: true
    },
    isTolerance: {
      handler(val1, val2) {
        this.isFormDirty = this.isFormReadyCheck; // && val1 != val 2;
      },
      deep: true
    },
    toleranceOption: {
      handler(val1, val2) {
        this.isFormDirty = this.isFormReadyCheck; // && val1 != val 2;
      },
      deep: true
    },
    productOption: {
      handler(val1, val2) {
        this.isFormDirty = this.isFormReadyCheck; // && val1 != val 2;
      },
      deep: true
    },
    totalProductPoQty: {
      handler(newVal) {
        this.overallProductSKU = Math.round((this.totalProductAvailablePoQty/newVal) * 100);
      },
      deep: true
    },
    totalProductAvailablePoQty: {
      handler(newVal) {
        this.overallProductSKU = Math.round((newVal/this.totalProductPoQty) * 100);
      },
      deep: true
    }
  }
};
</script>
<style lang="stylus" scoped>

.workOrderInspectionQuantityId {
  padding: 15px 10px;
}

  .tab_item
    padding 25px 15px
    overflow hidden
    position relative

  .submit-btn
    color: #fff !important
    margin-right 2px

	.del_button
		position: absolute;
		right: -3px;
		top: -5px;

 .alignToCenter {
    text-align: center;
  }

  button.header-btn {
    font-size: x-small;
    margin: 5px;
  }

  button.header-btn.calculate {
    background-color: rgb(194, 224, 253) !important
  }

  .case-count-assign-container {
    padding-left: 13px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .case-count-assign-input {
    max-width: 192px;
  }

  /deep/ .case-count-assign-input .v-input__control{
    max-width: 192px;

    input {
      text-align: center;
    }
  }

  /deep/ .tolerance-option .v-input__slot {
    margin-bottom: 0;
  }

  .product-option {
    margin-top: 0;
    margin-left: 158px;
  }

  .case-count-label {
    margin-top: 10px;
    margin-right: 5px;
  }
</style>
