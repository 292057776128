<template>
  <v-expand-transition>
    <v-footer color="#c02032" padless fixed>
      <v-row no-gutters>
        <v-col class="d-flex px-2 justify-end">
          <span class="pa-5">&nbsp;</span>
          <v-btn color="white" text class="my-2" v-show="showSaveForm" :disabled="saveInProgress" @click="saveForm()">Save Form</v-btn>
          <v-btn color="white" text class="my-2" v-show="isOnlyInspectorAssignedToAllSections" :disabled="!workOrderDetails || !workOrderDetails.status || saveInProgress" @click="submitForm()">Submit Form</v-btn>
        </v-col>
      </v-row>

      <v-overlay :value="saveInProgress">
         <v-snackbar class="snackbar-loading" v-model="saveInProgress" top :timeout="-1" color="#4caf50">
            <v-progress-circular :size="15" indeterminate color="primary"></v-progress-circular>
            <span style="padding-left:10px;">{{ message }}</span>
          </v-snackbar>
      </v-overlay>
      <v-snackbar v-model="snackbarError" :timeout="timeoutError" :color="colorError">
              {{ messageError }}
              <v-btn text @click="snackbarError = false"> Close </v-btn>
            </v-snackbar>
    </v-footer>    
  </v-expand-transition>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data () {
    return {
      saveInProgress: false,
      message: 'Saving...',
      snackbarError: false,
      messageError: "",
      timeoutError: 3000,
      colorError: "red darken-2",
    };
  },
  computed: {
    ...mapState('inspection', { 
      workOrderDetails: (state) => state.workOrderDetails,
      formAtomsList: (state) => state.formAtomsList
    }),

    // check if current active component has the saveData function
    showSaveForm() {
      return typeof this.activeComponent.saveData == 'function' && this.activeComponent.isDataLoaded && this.sectionHelper.isSectionEnabled(this.activeComponent.sectionId) && this.isOnlyInspectorAssignedToAllSections;
    }, 

    // check if single inspector or if only inspector assigned to all sections
    isOnlyInspectorAssignedToAllSections() {
      if (this.workOrderDetails && this.workOrderDetails.resources && this.workOrderDetails.workOrderInspectionSections) {
        if (this.workOrderDetails.resources.length === 1) return true

        const sections = this.workOrderDetails.workOrderInspectionSections.filter(w => w.isAssignable);
        if (sections && sections.length > 0) {
          const assignedResource = sections[0].resourceId;
          return sections.filter(s => s.resourceId == assignedResource).length == sections.length && assignedResource == this.utilityFn.getResourceId().toLowerCase();
        }
      }

      return false;
    },

    canLoadData() {
      return typeof this.activeComponent.loadData == 'function';
    },

    isWalmart() {
      const clientName = this.workOrderDetails?.client || this.workOrderDetails?.clientName || this.workOrderDetails?.companyName;
      return clientName?.toLowerCase() == "walmart global sourcing" || clientName?.toLowerCase() == "asda stores limited";
    }
  },
  methods: {
    async saveForm() {
      if (!this.showSaveForm) return

      this.saveInProgress = true;
      this.message = 'Saving...';

      await this.activeComponent
              .saveData(true)
              .catch((error => {
                console.log('An error occured during save', error);
                this.saveInProgress = false;
              }));
      if (this.canLoadData && window.navigator.onLine === true) {
        this.message = 'Refreshing data...';
        await this.activeComponent
                  .loadData()
                  .catch((error => {
                    console.log('An error occured during refresh', error);
                    this.saveInProgress = false;
                  }));
      }

      this.saveInProgress = false;
    },

    async submitForm() {
      if (typeof this.activeComponent.validateForm == 'function') {
        const validationMsg = await this.activeComponent.validateForm();
          if (validationMsg) {
            this.messageError = validationMsg;
            this.snackbarError = true;
            return;
          }
      }

      if(this.isWalmart && this.workOrderDetails.isPreShipOrFinalRandom === true) {
        //validate barcode verification
        var barcodeVerificationErrorMessage = await this.utilityFn.getServerDataAsync('get', `${this.apiList.validateBarcodeVerification}/${this.workOrderDetails.workOrderId}`,{});
        if (barcodeVerificationErrorMessage.data){
          this.snackbarError = true;
          this.messageError = barcodeVerificationErrorMessage.data;
          return;
        }

        //validate quantity verification
        var quantityVerificationErrorMessage = await this.utilityFn.getServerDataAsync('get', `${this.apiList.validateQuantityVerification}/${this.workOrderDetails.workOrderId}`,{});
        if (quantityVerificationErrorMessage.data){
          this.snackbarError = true;
          this.messageError = quantityVerificationErrorMessage.data;
          return;
        }

        //validate FCM
        var validateFCM = await this.utilityFn.getServerDataAsync('get',`${this.apiList.validateFCM}/${this.workOrderDetails.workOrderId}`,{});
        if (validateFCM.data){
          this.snackbarError = true;
          this.messageError = validateFCM.data;
          return;
        }
      }

      // if current page/component is in Oniste Test, skip server side validation
      // remove because of user story 267329 need to check sample size if has value.
      // if current page/component is in Oniste Test, skip server side validation
      if (this.activeComponent.$options?.name != 'workOrderInspectionOnsiteId') {
        // validate onsite test result
        const result = await this.utilityFn.getServerDataAsync('get', `${this.apiList.validateOnsiteTestResults}/${this.workOrderDetails.workOrderId}`, { });
        let validationMsg = !result.data.item1 ? 'Please assign the Onsite Test Result' : !result.data.item2 ? 'Please fill in Onsite Test Sample Size' : '';
        if (validationMsg) {
          this.messageError = validationMsg;
          this.snackbarError = true;
          return;
        }
      }


      //check for required fields
      var requiredMissing = this.formAtomsList.filter(x => x.isRequired && x.values && (x.values[0].value == "" || x.values[0].value == null));
      if(requiredMissing && requiredMissing.length > 0) {
        this.snackbarError = true;
        this.messageError = requiredMissing[0].requiredErrorText;
        return false;
      }
        
      var missingClassification = await this.utilityFn.getServerDataAsync('get', `${this.apiList.validateRemarksAndCommentClassification}/${this.workOrderDetails.workOrderId}`,{});
      if (missingClassification.data){
        this.snackbarError = true;
        this.messageError = "Missing Classification Type in Remarks/Comments";
        return;
      }

      if (this.isWalmart && this.workOrderDetails.isPreShipOrFinalRandom === true){
        var validatePackageCheck = await this.utilityFn.getServerDataAsync('get',`${this.apiList.validatePackageCheck}/${this.workOrderDetails.workOrderId}`,{});
        if (validatePackageCheck.data){
          if (validatePackageCheck.data.item1){
            this.snackbarError = true;
            this.messageError = validatePackageCheck.data.item2;
            return;
          }
        }

        var validateWorksmanship = await this.utilityFn.getServerDataAsync('get',`${this.apiList.validateWorksmanship}/${this.workOrderDetails.workOrderId}`,{});
        if (validateWorksmanship.data){
          if (validateWorksmanship.data.item1){
            this.snackbarError = true;
            this.messageError = validateWorksmanship.data.item2;
            return;
          }
        }
      }

      if (this.workOrderDetails.status == "Rework") {
        this.$router.push({ 
          name: "pastInspectionsResubmit",
          params: { id: this.workOrderDetails.workOrderId, edit: true }, 
          query: { id: this.workOrderDetails.workOrderId, displayId: this.workOrderDetails.workOrderDisplayId, },
        });
      } else {
        this.$router.push({ 
          name: 'pastInspectionsSignOff', 
          params: { id: this.$route.params.id }, 
          query: { id: this.$route.params.id, displayId: this.$route.query.displayId, returnComponent: this.$route.name } 
        });
      }
    }
  },
  props: {
    activeComponent: {
      type: Object,
      default: function () {
        return {}
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
  .snackbar-loading
    align-items center
    display flex
    justify-content center
</style>
