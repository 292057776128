<template>
  <div>
    <div class="workOrderInspectionProductConformityId">
      <div style="position: relative;">
        <v-row>
          <v-col cols="12 " sm="12" md="12" style="text-align:right;">
            <v-btn v-if="showButton" :disabled="isSectionDisabled" class="submit-btn" @click="submit_item" small depressed color="#1867c0">Submit</v-btn>
            <v-btn :disabled="isSectionDisabled" class="submit-btn" small depressed color="#1867c0" @click="go_upload_photo()">
              <v-icon>camera_alt</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-form ref="form" v-model="valid" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
          <div class="form_box">
            <div v-for="(item, index) in subSections" :key="index" style="margin-bottom: 15px;">
              <h3>{{ item.subSection.name }}</h3>
              <v-row>
                <v-col cols="6" sm="6">
                  <v-select v-model="item.result" :items="resultOptions" :rules="resultRules" item-value="id" item-text="text" label="Result" @change="resultChange(item)"> </v-select>
                </v-col>
                <v-col cols="6" sm="6">
                  <div class="code-container">
                    <div v-if="(item.result == 2 || item.result == 0) && isGenericCodes" class="helper-container">
                      <GenericCodeHelper></GenericCodeHelper>
                    </div>
                    <v-select 
                      v-if="item.result == 2 || item.result == 0" 
                      v-model="item.reason" 
                      :items="getResultCodes(item.result)" 
                      :item-text="(item) => item.description ? `${item.code} - ${item.description}` : `${item.code}`"
                      item-value="code"
                      :rules="reasonRules"
                      :label="item.result == 2 ? 'Pending Code' : 'Fail Code'"> 
                    </v-select>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="6">
                  <v-select v-model="item.selectedSKUs" :items="workOrderProducts" item-value="id" :item-text="(item) => `${item.skuDisplay} - ${item.po}`" multiple label="SKU-PO"> </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field label="Remarks" v-model="item.remarks" :counter="maxLength" :rules="remarksRules"></v-text-field>
                </v-col>
              </v-row>
            </div>

            <v-row>
              <v-col cols="12">
                <small class="error--text">*Required Field</small>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </div>
    </div>

    <v-snackbar v-model="snackbar" :timeout="timeout" :color="color"> {{ message }} <v-btn text @click="snackbar = false"> Close</v-btn> </v-snackbar>

    <div v-if="showFloatingButton">
      <FloatingButton></FloatingButton>
    </div>
  </div>
</template>
<script>
let state = {
  appHeaderState: {
    show: true,
    title: "Product Conformity",
    showMenu: true,
    showBack: true,
    showLogo: false,
    showTitle: true,
    showSearch: false,
    showCalendar: true,
  },
};

function setState(store) {
  store.dispatch("navigation/setAppHeader", state.appHeaderState);
}

import { mapState } from "vuex";
import FloatingButton from "../../../components/common/FloatingButton";
import * as _ from "lodash";
import GenericCodeHelper from "../../../views/common/GenericCodeHelper.vue"

export default {
  name: "workOrderInspectionProductConformityId",
  components: {
    FloatingButton,
    GenericCodeHelper
  },
  data() {
    return {
      loadingDf: false,
      sectionId: 21,
      sectionName: "Product Conformity",
      resultOptions: [
        { id: 1, text: "Pass" },
        { id: 0, text: "Fail" },
        { id: 2, text: "Pending" },
        { id: 3, text: "N/A" },
      ],
      // failResultOptions: ["F0", "F1", "F2", "F3", "F4", "F5", "F6", "F7", "F8", "F9"],
      // pendingResultOptions: ["P1", "P2", "P3", "P4", "P5", "P6", "P7", "P8", "P9"],
      
      failResultOptions: [],
      pendingResultOptions: [],
      subSections: [
        { sectionId: 21, subSectionid: 2, subSection: { id: 2, name: "Style" } },
        { sectionId: 21, subSectionid: 3, subSection: { id: 3, name: "Material" } },
        { sectionId: 21, subSectionid: 4, subSection: { id: 4, name: "Color" } },
      ],
      workOrder: {
        workOrderInspectionSections: [],
        resources: [],
      },
      workOrderProducts: [],

      valid: true,
      resultRules: [(v) => v != null || "Please choose a result"],
      reasonRules: [(v) => v != null || "Required"],
      remarksRules: [(v) => !v || v.length <= 200 || "Maximum 200 characters only"],
      maxLength: 200,

      showFloatingButton: false,

      // true if submit button is clicked
      sectionSubmitted: false,

      workOrderRatingOptions: [],
      defaultFailCodeOptions: ['UL0', 'UL1', 'UL2', 'UL3', 'UL4', 'UL5', 'UL6', 'UL7', 'UL8', 'UL9', 'UL10'],
      isFormDirty: false,
      isFormReadyCheck: false,
      isGenericCodes: false,
      snackbar: false,
      timeout: 3000,
      color: "red darken-2",
      message: "",
    };
  },
  async activated() {
    if (window.navigator.onLine !== true) {
      let response = await caches.match(this.apiList.workOrderConfirmity + "/" + this.$route.params.id);
      if (!response) {
        this.$router.replace({
          path: "/error",
          name: "error",
        });
      }
    }
  },
  async mounted() {
    setState(this.$store); 
    if (window.navigator.onLine !== true) {
      let response = await caches.match(this.apiList.workOrderConfirmity + "/" + this.$route.params.id);
      if (!response) {
        this.$router.replace({
          path: "/error",
          name: "error",
        });
      }
    }

    this.loadSamplingPlan();
    await this.loadData();
    await this.loadWorkOrderRatingOptions();
  },

  methods: {
    async loadData() {
      //start loading
    this.loadingDf = true;
    this.$store.dispatch("navigation/setLoadingPage",{show: this.loadingDf  });
      const result = await this.utilityFn.getServerDataAsync("get", this.apiList.workOrderConfirmity + "/" + this.$route.params.id, {});
      this.workOrder = result.data[0];
      this.workOrderProducts = this.workOrder.products;

      this.utilityFn.getServerData("get", this.apiList.getAllWorkOrderPendingFailCode, {}, (res) => {
        this.failResultOptions = res.data.data.filter((x) => x.type == "Fail");
        this.pendingResultOptions = res.data.data.filter((x) => x.type == "Pending");
      });

      const woSubSections = this.workOrder.workOrderSubSections.filter((ws) => ws.sectionId === this.sectionId);
      this.subSections.forEach((currentItem, index, subSections) => {
        currentItem.selectedSKUs = [];

        const item = woSubSections.find((ws) => ws.subSectionId === currentItem.subSection.id);
        if (item) {
          item.result = item.result != null ? +item.result : null;
          item.selectedSKUs = item.workOrderProductWorkOrderSubSections.map((wops) => wops.workOrderProductId);
          this.subSections.splice(index, 1, item);
        }
      });
      
    //end loading
    this.loadingDf = false;
     this.$store.dispatch("navigation/setLoadingPage",{show:  this.loadingDf });
    },

    async upsert() {
      if (this.isSectionDisabled) return;

      this.subSections.forEach((subSection) => {
        const newWorkOrderProductWorkOrderSubSections = [];
        subSection.selectedSKUs.forEach((wopId) => {
          const existingWorkOrderProductWorkOrderSubSection = (subSection.workOrderProductWorkOrderSubSections || []).find((x) => x.workOrderProductId === wopId);
          newWorkOrderProductWorkOrderSubSections.push({ id: existingWorkOrderProductWorkOrderSubSection ? existingWorkOrderProductWorkOrderSubSection.id : null, workOrderProductId: wopId, workOrderSubSectionId: subSection.id || null });
        });
        subSection.workOrderProductWorkOrderSubSections = newWorkOrderProductWorkOrderSubSections;
        subSection.workOrderId = this.workOrder.workOrderId;
      });

      await this.utilityFn.getServerDataAsync("post", this.apiList.upsertWorkOrderSubSections, this.subSections);
      this.sectionHelper.updateSectionModifiedDate(this.sectionId);
    },

    async updateSection() {
      const index = this._workOrderDetails.workOrderInspectionSections.findIndex((x) => x.sectionId == this.sectionId);
      if (index !== -1) {
        this._workOrderDetails.workOrderInspectionSections[index] = this.sectionDetail;
        this.$store.dispatch("inspection/setWorkOrderDetails", _.cloneDeep(this._workOrderDetails));
        this.inspectionSummarySectionResult.forEach(x => {
          if(x.sectionId==21){
            x.result = this.sectionDetail.result;
          }
        })
        this.$store.dispatch("inspection/setInspectionSummaryResult", this.inspectionSummarySectionResult);
      }
      
      await this.utilityFn.getServerDataAsync("post", this.apiList.sectionUpdate, this.sectionDetail);
    },

    submit_item(sectionSubmitted = false) {
      if(!this.$refs.form.validate()) {
        this.snackbar = true;
        this.message = "Missing Input Required Fields";
        return;
      }
      this.sectionSubmitted = sectionSubmitted;
      this.saveData().then(() => {
        this.utilityFn.getServerData("post", this.apiList.submitInspectionSection, this.sectionDetail, (res) => {
          const index = this._workOrderDetails.workOrderInspectionSections.findIndex((x) => x.sectionId == this.sectionId);
          if (index !== -1) {
            this._workOrderDetails.workOrderInspectionSections[index].submittedDate = new Date();
            this.$store.dispatch("inspection/setWorkOrderDetails", _.cloneDeep(this._workOrderDetails));
          }
        });
        console.log("saveData using Submit button");
      });
    },

    resultChange(item) {
      item.reason = null;
    },

    go_upload_photo() {
      this.$router.push({ name: "uploadPhotoGalleryId", params: { id: this.workOrder.workOrderId, name: this.sectionName, sectionId: this.sectionId }, query: { name: this.sectionName, sectionId: this.sectionId } });
    },

    loadSamplingPlan() {
      this.samplingPlan = JSON.parse(localStorage.getItem("samplingPlanList"));
      this.showFloatingButton = this.samplingPlan && this.samplingPlan.showFabButton;
    },

    // only save when the isFormDirty is true - means user has changed the value in the UI
    // user can still force saving even if nothing has been changed in UI if "forceSave" is true
    // "forceSave" value will come from the Footer Component. The footer component has a Save button
    // that calls this method and will force this method to call the API to save the values
    async saveData(forceSave = false) {
      if (!forceSave && !this.isFormDirty)
        return

      try {
        if (this.valid && (!this.isSectionDisabled || this.sectionSubmitted)) {
          await Promise.all([this.upsert(), this.updateSection()]);
        }

        return true;
      } catch (ex) {
        console.log("saveData error", ex);
        return false;
      }
    },

    async loadWorkOrderRatingOptions() {
      const result = await  this.utilityFn.getServerDataAsync("get", this.apiList.GetCompanyWOResultTypeAliases + "?companyId=" + this.workOrder.clientId, {});
      this.workOrderRatingOptions = result.data.filter((x) => x.description && x.isActive);
    },

    getResultCodes(resultId) {
      const result = this.resultOptions.find(x => x.id == resultId);
      const woRating = this.workOrderRatingOptions.find(x => x.aimsSectionRating?.toLowerCase() == result?.text?.toLowerCase());
      if (woRating?.companyWorkOrderResultTypeCodes.length > 0) {
        this.isGenericCodes = false;
        return woRating.companyWorkOrderResultTypeCodes?.filter(x => x.isActive).sort((a,b) => (a.order < b.order) ? -1 : 1) || [];
      }
      this.isGenericCodes = true;
      return this.defaultFailCodeOptions.map(x => ({ code: x }));
    }
  },

  computed: {
    ...mapState("inspection", {
      _workOrderDetails: (state) => state.workOrderDetails,
      inspectionSummarySectionResult: (state) => state.inspectionSummarySectionResult,
    }),

    sectionOverAllResult() {
      // if at least 1 fail
      if (this.subSections.filter((s) => s.result == 0).length > 0) {
        return "Non-Conformed";
      }
      // if no fail, but at least 1 pending
      else if (this.subSections.filter((s) => s.result == 2).length > 0) {
        return "Pending";
      }
      // if all N/A
      else if (this.subSections.filter((s) => s.result == 3).length === this.subSections.length) {
        return "N/A";
      }
      // If all Pass or N/A
      else {
        return "Conformed";
      }
    },

    sectionDetail() {
      let section = this._workOrderDetails.workOrderInspectionSections.find((x) => x.sectionId == this.sectionId);
      if (section) {
        section.result = this.sectionOverAllResult;
        section.submitterId = this.utilityFn.getResourceId().toLowerCase();
      } else {
        section = {
          workOrderId: this.workOrder.workOrderId,
          sectionId: this.sectionId,
          isHidden: false,
          result: this.sectionOverAllResult,
          submitterId: this.utilityFn.getResourceId().toLowerCase(),
        };
      }

      return section;
    },

    isSectionDisabled() {
      return this.workOrder.resources.length == 0 || (this.workOrder.resources.length > 1 && this.sectionDetail.resourceId.toLowerCase() != this.utilityFn.getResourceId().toLowerCase()) || this.sectionDetail.isHidden || (!!this.sectionDetail.submittedDate && this.workOrder.resources.length > 1);
    },

    showButton() {
      return this.workOrder.resources.length > 1;
    },

    isDataLoaded() {
      return this.workOrder.workOrderInspectionSections.length > 0;
    },
  },
  watch: {
    subSections: {
      handler(value) {
        this.$nextTick(() => {
          this.$refs.form.validate();

          this.isFormDirty = this.isFormReadyCheck;
        });
      },
      deep: true,
    },
    isDataLoaded(val1, val2) {
      setTimeout(() => {
        this.isFormReadyCheck = true;
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$refs.form.validate();
    if (to.path !== '/error' && this.valid && (!this.isSectionDisabled || this.sectionSubmitted)) {
      if(!this.loadingDf) {
        this.saveData().then(() => {
          console.log("saveData");
        });
      }
      next(true);
      return;
    }

    if (this.isSectionDisabled) {
      next(true);
    } else {
      next(false);
    }
  },

  beforeDestroy() {
    // if (this.workOrder.resources.length === 1) {
    //     this.submit_item()
    // }
  },
};
</script>
<style lang="stylus" scoped>
.workOrderInspectionProductConformityId
    padding 15px 10px

.form_box
    padding 15px 15px
    background #fff

.error--text
    color #ff5252 !important
    caret-color #ff5252 !important;

.form_box .col-6, .form_box .col-12
    padding-top 0
    padding-bottom 0

.submit-btn
    color #fff !important

.code-container {
    display: flex;
    flex-direction: column;
}

.helper-container {
    margin-bottom: -18px;
    margin-top: -18px;
    margin-left: 75px;
}
</style>
