<template>
  <div name="General">
    <v-row>
      <v-col v-if="check || showButton" cols="12 " sm="12" md="12" style="text-align:right;">
        <v-btn id="btnSubmit" v-if="showButton && sectionData.isAuthorized" :disabled="sectionData.isSectionSubmitted" class="submit-btn" @click="submit_item" small depressed color="#1867c0" auto-id="general submit">Submit</v-btn>
      </v-col>
      <v-col cols="6" sm="6" md="6">
        <v-text-field id="txtWorkOrderDisplayId" hide-details :value="generalData.workOrderDisplayId" label="Work Order ID" readonly></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field id="txtInspectionDate" hide-details :value="generalData.inspectionRequestDate" label="Inspection Date" readonly></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-text-field id="txtProductCategory" hide-details :value="generalData.productCategory" label="Product Category" readonly></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-text-field id="txtProductType" hide-details :value="generalData.productType" label="Product Type" readonly></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-text-field id="txtProductSubCategory" hide-details :value="generalData.productSubCategory" label="Product Sub-Category" readonly></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-autocomplete
          id="txtDestination"
          hide-details
          v-bind:items="filteredCountries"
          v-model="destinationIds"
          item-text="name"
          item-value="id"
          label="Destination"
          :search-input.sync="search"
          small-chips
          @change="onChangeDestinations"
          multiple
          append-icon="edit"
          auto-id="general text destination"
        >
          <template v-slot:selection="data">
            <v-chip close @click:close="remove(data.item)" :auto-id="'general chip'">
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-text-field id="txtServicePerformed" hide-details :value="generalData.workOrderClassDescription" label="Service Performed" readonly></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-text-field id="txtCoordinationOffice" hide-details :value="generalData.coordinationOffice" label="Coordination Office" readonly></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field id="txtClientContact" hide-details :value="generalData.clientContact" label="Client Contact" readonly></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field id="txtFactoryContact" hide-details :value="generalData.factoryContact" label="Factory Contact" readonly></v-text-field>
      </v-col>

      <v-col cols="6" sm="6" md="6">
        <v-select id="selTechnicalFileAvailable" hide-details v-bind:items="select_state" v-model="generalData.technicalFileAvailable" label="Technical File Available" append-icon="edit" auto-id="general technical file available"></v-select>
      </v-col>

      <v-col cols="6" sm="6" md="6">
        <!-- <v-text-field id="txtTechnicalFileProvidedBy" hide-details v-model="generalData.technicalFileProvidedBy" label="Provided by" append-icon="edit" @change="onChange"></v-text-field> -->
        <v-select id="txtTechnicalFileProvidedBy" hide-details v-bind:items="providedByOptions" v-model="generalData.technicalFileProvidedBy" label="Provided by" append-icon="edit"  @change="onChange" auto-id="general technical provided by"></v-select>
      </v-col>

      <v-col cols="6" sm="6" md="6">
        <v-select id="selApprovedSample" hide-details v-bind:items="selectApproveSample" v-model="generalData.approvedSample" label="Approved Sample" append-icon="edit" @change="onChangeSample" auto-id="general approved sample"></v-select>
      </v-col>

      <v-col cols="6" sm="6" md="6">
        <v-select id="txtApproveSampleBy" hide-details v-bind:items="providedByOptions" v-model="generalData.approvedSampleBy" label="Provided by" append-icon="edit" auto-id="general approved provided by"></v-select>
      </v-col>

      <v-col cols="6" sm="6" md="6">
        <v-select id="selRefSample" hide-details v-bind:items="select_state" v-model="generalData.refSample" label="Ref Sample" append-icon="edit" @change="onChangeSample" auto-id="general ref sample"></v-select>
      </v-col>

      <v-col cols="6" sm="6" md="6">
        <!-- <v-text-field id="txtRefSampleProvidedBy" hide-details v-model="generalData.refSampleProvidedBy" label="Provided by" append-icon="edit" @change="onChange"></v-text-field> -->
        <v-select id="txtRefSampleProvidedBy" hide-details v-bind:items="providedByOptions" v-model="generalData.refSampleProvidedBy" label="Provided by" append-icon="edit"  @change="onChange" auto-id="general ref provided by"></v-select>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-text-field id="txtOrderQty" hide-details :value="generalData.orderQty" label="Order Qty" readonly></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field id="txtShipmentQty" hide-details v-model="generalData.shipmentQty" label="Shipment Qty" type="number" append-icon="edit" @change="onChange2" auto-id="general shipment qty"></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <referenceReportNumber :msgData="null" :referenceReportNumber="generalData.previousReportNumber"></referenceReportNumber>
      </v-col>
    </v-row>
    <v-dialog v-model="showProgressDialog" :persistent="true">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-progress-linear :value="uploadProgress" color="light-blue" height="25" reactive>
            <template v-slot="{ value }">
              <strong>{{ uploadFileName }} {{ Math.ceil(value) }}%</strong>
            </template>
          </v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showSampleDialog" :persistent="true" width="500">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          Please check the status of Approved Sample and Reference Sample
        </v-card-text>
        <v-card-actions>
          <v-btn id="closeSampleDialog" text @click="closeSampleDialog">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as moment from "moment";
import apiDetails from "../../shared/request/apiDetails";
import ReferenceReportNumber from "../forms/ReferenceReportNumber";

export default {
  name: "General",
  components:{
    referenceReportNumber: ReferenceReportNumber
  },
  data() {
    return {
      test: "angelo",
      showProgressDialog: false,
      showSampleDialog: false,
      uploadProgress: 0,
      workOrderSectionList: [],

      filteredCountries: [],
      countries: [],
      search: null,
      destinationIds: [],

      sectionId: 7,
      showButton: false,
      samplingToPatch: [],
      // Sampling
      select_state: [
        { text: "N/A", value: "N/A" },
        { text: "No", value: "0" },
        { text: "Yes", value: "1" }
      ],
      providedByOptions: ["Client", "Factory", "Vendor", "N/A"]
      ,selectApproveSample: ["N/A","No", "Yes"]
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    returnNumber(obj, name) {
      if (obj[name] != "") {
        obj[name] = obj[name].replace(/[^\d.]/g, "");
        obj[name] = obj[name].replace(/\.{2,}/g, ".");
        obj[name] = obj[name]
          .replace(".", "$#$")
          .replace(/\./g, "")
          .replace("$#$", ".");
        obj[name] = obj[name].replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
      }
    },
    loadData() {
      var other = {
        headers: {
          wresult: this.store_token,
          "Content-Type": "application/json",
        },
      };
      this.utilityFn.getServerData("get", this.apiList.getGeoCountires, {}, (res) => {
        this.countries = res.data.data;
        this.filteredCountries = this.countries;

        if (this.generalData.workOrderDestinations) {
          this.destinationIds = this.generalData.workOrderDestinations.map((x) => ({ id: x.destinationId }));
        }
      });
    },
    go_upload_photo() {
      this.$router.push({ name: "uploadPhotoInspectionId" });
    },
    submit_item() {
      const section = {
        sectionId: this.sectionId,
        workOrderId: this.$route.params.id
      };
      this.utilityFn.getServerData("post", this.apiList.submitInspectionSection, section, (res) => {
        this.sectionData.isSectionSubmitted = true;
      });
    },
    remove(e) {
      const index = this.destinationIds.findIndex((dest) => dest.id.toLowerCase() == e.id.toLowerCase());
      this.destinationIds.splice(index, 1);

      this.onChangeDestinations();
    },
    onChangeDestinations() {
      if (this.destinationIds.length > 20) {
        this.destinationIds.splice(this.destinationIds.length - 1, 1);
      }
      this.generalData.workOrderDestinations = this.destinationIds.map((dest) => ({ destinationId: dest, workOrderId: this.generalData.workOrderId }));

      window.sessionStorage.setItem("EditDataLoggingGeneral", true);
    },
    onChange() {
      this.logChanges();
    },
    onChangeSample() {
      if(this.generalData.refSample === "1" && this.generalData.approvedSample.toLowerCase() === "yes") {
        this.showSampleDialog = true;
      }
      this.logChanges();
    },
    closeSampleDialog() {
      this.showSampleDialog = false;
    },
    onChange2() {
      window.sessionStorage.setItem("EditDataLoggingGeneral", true);
    },
    logChanges() {
      // flag that data has been updated, this is to prevent logging activity even though no value has been change
      // this is because when clicking on section headers/navigating out, api is always called
      window.sessionStorage.setItem("EditDataLogging", true);
    },
  },
  watch: {
    search(val) {
    
    },
    generalData(newValue, oldValue) {
      if (!oldValue.workOrderDestinations && newValue.workOrderDestinations) {
        this.destinationIds = this.generalData.workOrderDestinations.map((x) => ({ id: x.destinationId }));
      }
    },
  },
  computed: {
    check() {
      this.showButton = this.generalData.hideButton
    },
  },
  props: {
    generalData: Object,
    sectionData: Object,
  },
};
</script>

<style lang="stylus" scoped>
.submit-btn
    color: #fff !important
</style>
