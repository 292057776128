<template>
	<div>
		<div class="pastInspectionsResubmitReworkReason">
			<v-overlay :value="isCurrentlyLoading"></v-overlay>
			<v-snackbar
				class="snackbar-loading"
				v-model="isCurrentlyLoading"
				top
				:timeout="-1"
				color="#4caf50"
			>
				<v-progress-circular
					:size="15"
					indeterminate
					color="primary"
				></v-progress-circular>
				<span style="padding-left:10px;">Loading</span>
			</v-snackbar>
			<v-row>
				<v-col cols="12">
					<v-select
						hide-details
						v-model="details.reworkReasons"
						:items="details.reworkReasons"
						label="Rework Reason"
						multiple
						append-icon=""
						readonly
					>
						<template v-slot:selection="data">
							<v-chip> {{ data.item }} </v-chip>
						</template>
					</v-select>
				</v-col>
				<v-col cols="12">
					<v-textarea
						readonly
						label="Rework Comment or Feedback"
						:value="details.feedback"
					></v-textarea>
				</v-col>
			</v-row>
			<v-row justify="center">
				<div>
					<v-btn
						class="submit"
						color="#B14C4C"
						@click="goToDetails"
						:disabled="isCurrentlyLoading"
					>Edit</v-btn>
				</div>
			</v-row>
		</div>
	</div>
</template>

<script>
	let state = {
		appHeaderState: {
			show: true,
			title: "Work Order: ",
			showMenu: true,
			showBack: true,
			showLogo: false,
			showTitle: true,
			showSearch_job: false,
			showSearch_work: false
		}
	};

	export default {
		name: "pastInspectionsResubmitReworkReason",
		data() {
			return {
				details: {
					workOrderId: null,
					workOrderDisplayId: null,
					workInstruction: "",
					reworkReasons: [],
					feedback: ""
				},
				isCurrentlyLoading: false
			};
		},
		async mounted() {
			try {
				this.isCurrentlyLoading = true;
				const result = await this.utilityFn.getServerDataAsync(
					"get",
					`${this.apiList.workOrderReworkFeedback}/${this.$route.params.id}`,
					{}
				);
				if (result?.data) {
					this.details = result.data;
					this.$store.dispatch("navigation/setAppHeader", {
						...state.appHeaderState,
						title: `${state.appHeaderState.title} ${result.data.workOrderDisplayId}`
					});
				}
			} catch (e) {
				console.log(e);
			} finally {
				this.isCurrentlyLoading = false;
			}
		},
		methods: {
			async goToDetails() {
				try {
					this.isCurrentlyLoading = true;
					const result = await this.utilityFn.getServerDataAsync(
						"get",
						`${this.apiList.checkIfWorkOrderDetailsExists}/${this.$route.params.id}`,
						{}
					);

					if (!result?.data) {
						this.isCurrentlyLoading = false;
						return;
					}

					if (!this.details.workInstruction) {
						this.$router.push({
							name: "assignmentId",
							params: {
								id: this.details.workOrderId,
								workOrderDisplayId: this.details.workOrderDisplayId,
								isDynamic: !!this.details.formId,
								workInstruction: this.details.workInstruction
							}
						});
					} else if (this.details.formId) {
						this.$router.push({
							name: "dynamicFormId",
							params: { id: this.details.workOrderId },
							query: { displayId: this.details.workOrderDisplayId }
						});
					} else {
						this.$router.push({
							name: "workOrderDetailsId",
							params: { iid: this.details.workOrderId },
							query: { displayId: this.details.workOrderDisplayId }
						});
					}
				} catch (e) {
					console.log(e);
				} finally {
					this.isCurrentlyLoading = false;
				}
			}
		}
	};
</script>

<style lang="stylus">
	.pastInspectionsResubmitReworkReason {
		background: #fff;
		padding: 15px;
	}
</style>
