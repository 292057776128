<template>
  <div class="box">
    <div class="download">
      <v-data-table :search="search_value" :sort-by="['inspectionDate']" :headers="downloadHeaders" :items="downloadList" :items-per-page="-1" class="elevation-1" style="margin:30px auto 0; width:96%;" @click:row="go_download_details">
        <template v-slot:item.inspectionDate="{ item }">
          <span>{{ item.inspectionDate | dateFormat("MM/DD/YYYY") }}</span>
        </template>
        <template v-slot:item.menu="{ item }">
          <!-- <v-btn small @click.stop="upload_draft(item)">Upload as Draft</v-btn> -->

          <v-btn small @click.stop="upload_submit(item)">Signoff</v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

let state = {
  appHeaderState: {
    show: true,
    showMenu: true,
    showBack: true,
    showLogo: false,
    showTitle: true,
    title: "download",
    showSearch_job: false,
    showSearch_work: true,
    showCalendar: true,
  },
};

function setState(store) {
  store.dispatch("navigation/setAppHeader", state.appHeaderState);
}
export default {
  name: "download",
  data() {
    return {
      downloadHeaders: [
        { text: "work order #", align: "center", value: "workOrderDisplayId" },
        { text: "Inspection Date", sortable: true, align: "center", value: "inspectionDate" },
        { text: "Factory Name", align: "center", value: "factoryName" },
        { text: "Status", align: "center", value: "workOrderStatus" },
        { text: "", align: "center", value: "menu" },
      ],
      downloadList: [],
    };
  },
  computed: {
    ...mapState("inspection", {
      search_value: (state) => state.searchValue,
    }),
  },
  mounted() {
    setState(this.$store);

    // this.utilityFn.db.transaction((tx) => {
    //   tx.executeSql("SELECT * FROM downloadList Where resourceId=?", [this.utilityFn.getResourceId()], (tx, results) => {
    //     var len = results.rows.length;
    //     for (var i = 0; i < len; i++) {
    //       this.downloadList.push({
    //         workOrderDisplayId: results.rows.item(i).displayId,
    //         workOrderId: results.rows.item(i).id,
    //         inspectionDate: results.rows.item(i).inspection,
    //         factoryName: results.rows.item(i).FactoryName,
    //         workOrderStatus: results.rows.item(i).workOrderStatus,
    //         menu: 0,
    //         formId:results.rows.item(i).formId
    //       });
    //     }
    //   });
    // });
  },
  methods: {
    go_download_details(item) {
      // this.$router.push({name: 'workOrderDetailsId', params:{id: item.workOrderId},
      // query: {displayId: item.workOrderDisplayId}})
      if (!!item.formId) {
        this.$router.push({ name: "dynamicFormId", params: { id: item.workOrderId, resources: item.resources }, query: { displayId: item.workOrderDisplayId } });
      } else {
        this.$router.push({ name: "workOrderDetailsId", params: { id: item.workOrderId }, query: { displayId: item.workOrderDisplayId } });
      }
    },
    upload_draft(item) {
      if (window.navigator.onLine == true) {
        // this.utilityFn.db.transaction((tx) => {
        //   tx.executeSql("DELETE FROM downloadList WHERE displayId=?", [item.workOrderDisplayId]);

        //   tx.executeSql("SELECT * FROM downloadList Where resourceId=?", [this.utilityFn.getResourceId()], (tx, results) => {
        //     console.log(results);
        //     let len = results.rows.length;
        //     let arr = [];
        //     this.downloadList = [];
        //     for (let i = 0; i < len; i++) {
        //       this.downloadList.push({
        //         workOrderDisplayId: results.rows.item(i).displayId,
        //         workOrderId: results.rows.item(i).id,
        //         inspectionDate: results.rows.item(i).inspection,
        //         factoryName: results.rows.item(i).FactoryName,
        //         workOrderStatus: results.rows.item(i).workOrderStatus,
        //         menu: 0,
        //         formId:results.rows.item(i).formId
        //       });
        //     }
        //     this.$store.dispatch("inspection/setDownloadNumber", len);
        //   });
        // });
      } else {
        alert("The device is currently offline, cannot upload the form");
      }
    },
    upload_submit(item) {
      if (window.navigator.onLine == true) {
        this.$router.push({ name: "pastInspectionsSignOff", query: { id: item.workOrderId, displayId: item.workOrderDisplayId } });
      } else {
        alert("The device is currently offline, cannot upload the form.");
      }
    },
  },
};
</script>

<style lang="stylus" scoped></style>
