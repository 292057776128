<template>
  <div>
    <div class="content">
      <!-- Expense remind dialog -->
      <v-dialog v-model="expenseDialog" width="300">
        <v-card>
          <v-card-title class="headline">Expense Reminder</v-card-title>
          <v-card-text> You have {{ expenseCount }} jobs with pending expense submission </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="openExpenseTab">
              Open
            </v-btn>
            <v-btn color="green darken-1" text @click="expenseDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-tabs v-model="tab" dark grow background-color="#c02032" icons-and-text style="position: fixed;z-index: 20;top: 50px;">
        <v-tab id="tab_Schedules" href="#tab-1" @click="clear_search_value">
          Schedules
          <v-icon>access_time</v-icon>
        </v-tab>
        <v-tab id="tab_PastInspections" href="#tab-2" @click="clear_search_value">
          Past Inspections
          <v-icon>history</v-icon>
        </v-tab>
        <v-tab id="tab_Expenses" href="#tab-3" v-if="internal === 'true'" @click="clear_search_value">
          Expense({{ expenseCount }})
          <v-icon>payment</v-icon>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" style="width: 96%; margin: 30px auto; background: transparent;">
        <v-tab-item value="tab-1">
          <!-- Today & Upcoming Schedule tabs -->
          <v-tabs v-model="tab1" centered grow background-color="#00518A" dark style="width:95.7%; position: fixed;z-index: 20;top: 122px;padding-top:18px;background:#e8e8e8">
            <v-tab href="#tab-1">Today({{ today_list.length }})</v-tab>
            <v-tab href="#tab-2">Upcoming Schedule({{ upcoming_list.length }})</v-tab>
            <v-tab href="#tab-3">Past Due({{ past_list.length }})</v-tab>
          </v-tabs>

          <v-row style="background: #fff;width: 95.8%;margin: 70px auto 0;position: fixed;z-index: 20;top: 118px;padding-top:20px;background:#e8e8e8;border-bottom:1px #e8e8e8 solid;">
            <v-col cols="12" sm="12" md="12" style="background:#fff">
              <v-select id="selectSort" style="padding-top: 0;" v-bind:items="selectSort" v-model="selectSortValue" label="Select Sort" single-line :hide-details="true" @change="select_sort(selectSortValue)"></v-select>
            </v-col>
          </v-row>

          <v-tabs-items v-model="tab1">
            <!-- item.jobDisplayId !=  -->
            <v-tab-item value="tab-1" style="margin-top: 230px;">
              <v-row
                :id="'today_' + item.jobDisplayId"
                style="background: #fff;margin: 10px auto;"
                v-for="(item, index) in displayTodayList"
                :key="index"
                class="pb-3"
                @click="openWorkOrder(item)"
              >
                <v-col v-if="item.dataCaptureModifiedDate" cols="12" sm="10" md="10" class="state_tag_In-progress">
                  <span>In-progress</span>
                </v-col>
                <v-col v-else cols="12" sm="10" md="10" class="state_tag_New">
                  <span>New</span>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-2">
                  <label>Job ID</label>
                  <span id="JobID">{{ item.jobDisplayId }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-2">
                  <label>Job Start Date</label>
                  <span id="JobStartDate">{{ item.jobStartDate | dateFormat("MM/DD/YYYY") }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-2">
                  <label>Work Order ID</label>
                  <span id="WorkOrder">{{ item.workOrderDisplayIds }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-2">
                  <label>Job End Date</label>
                  <span id="JobEndDate">{{ item.jobEndDate | dateFormat("MM/DD/YYYY") }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-2">
                  <label>Service Type</label>
                  <span id="ServiceType">{{ item.serviceType }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Work Order Class</label>
                  <span id="WorkOrderClass">{{ item.workOrderClasses }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Industry</label>
                  <span id="Industry">{{ item.workOrderIndustries }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Client Name</label>
                  <span id="ClientName">{{ item.clientName }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-2">
                  <label>Inspection Date</label>
                  <span id="InspectionDate">{{ item.inspectionDate | dateFormat("MM/DD/YYYY") }}</span>
                </v-col>
                <v-col cols="12" sm="8" md="8" style="overflow: hidden;" class="py-2">
                  <label>Phone Number</label>
                  <span id="PhoneNumber">{{ item.factoryContactPhoneNumber }}</span>
                  <span id="PhoneNumberButton" @click.stop="call(item.factoryContactPhoneNumber)" style="float: right;margin-right:30px;">
                    <v-icon color="blue">phone</v-icon>
                  </span>
                </v-col>
                <v-col cols="12" sm="8" md="8" class="py-2">
                  <label>Email</label>
                  <span id="Email">{{ item.factoryContactEmail }}</span>

                  <span id="EmailButton" @click.stop="email(item.factoryContactEmail)" style="float: right;margin-right:30px;">
                    <v-icon color="blue">email</v-icon>
                  </span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Company Contact</label>
                  <span id="CompanyContact">{{ item.factoryContactName }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Factory Name</label>
                  <span id="FactoryName">{{ item.factoryName }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Product Description</label>
                  <span id="ProductDescription">{{ item.productDescription }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Factory Address</label>
                  <span id="FactoryAddress">{{ item.factoryAddress }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-show="item.isLeadInspector" class="py-2">
                  <label>Lead Inspector</label>
                  <span id="LeadInspector">{{ item.isLeadInspector }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Assigned Inspectors</label>
                  <span id="AssignedInspectors">{{ item.displayResources }}</span>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-2" style="margin-top: 230px;">
              <v-row
                :id="'upcomingSchedule_' + item.jobDisplayId"
                style="background: #fff;width: 100%;margin: 10px auto;"
                v-for="(item, index) in displayUpcomingList"
                :key="index"
                class="pb-3"
                @click="openWorkOrder(item)"
              >
                <v-col v-if="item.dataCaptureModifiedDate" cols="12" sm="10" md="10" class="state_tag_In-progress">
                  <span>In-progress</span>
                </v-col>
                <v-col v-else cols="12" sm="10" md="10" class="state_tag_New">
                  <span>New</span>
                </v-col>
                <!-- <v-col cols="2" sm="2" md="2">
                                    <span style="float: right;margin-right:10px;">Online</span>
                                </v-col> -->
                <v-col cols="12" sm="6" md="6" class="py-2">
                  <label>Job ID</label>
                  <span id="JobID">{{ item.jobDisplayId }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-2">
                  <label>Job Start Date</label>
                  <span id="JobStartDate">{{ item.jobStartDate | dateFormat("MM/DD/YYYY") }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-2">
                  <label>Work Order ID</label>
                  <span id="WorkOrder">{{ item.workOrderDisplayIds }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-2">
                  <label>Job End Date</label>
                  <span id="JobEndDate">{{ item.jobEndDate | dateFormat("MM/DD/YYYY") }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-2">
                  <label>Service Type</label>
                  <span id="ServiceType">{{ item.serviceType }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Work Order Class</label>
                  <span id="WorkOrderClass">{{ item.workOrderClasses }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Industry</label>
                  <span id="Industry">{{ item.workOrderIndustries }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Client Name</label>
                  <span id="ClientName">{{ item.clientName }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Inspection Date</label>
                  <span id="InspectionDate">{{ item.inspectionDate | dateFormat("MM/DD/YYYY") }}</span>
                </v-col>

                <v-col cols="12" sm="8" md="8" style="overflow: hidden;" class="py-2">
                  <label>Phone Number</label>
                  <span id="PhoneNumber">{{ item.factoryContactPhoneNumber }}</span>
                  <span id="PhoneNumberButton" @click.stop="call(item.factoryContactPhoneNumber)" style="float: right;margin-right:30px;">
                    <v-icon color="blue">phone</v-icon>
                  </span>
                </v-col>
                <v-col cols="12" sm="8" md="8" class="py-2">
                  <label>Email</label>
                  <span id="Email">{{ item.factoryContactEmail }}</span>

                  <span id="EmailButton" @click.stop="email(item.factoryContactEmail)" style="float: right;margin-right:30px;">
                    <v-icon color="blue">email</v-icon>
                  </span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Company Contact</label>
                  <span id="CompanyContact">{{ item.factoryContactName }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Factory Name</label>
                  <span id="FactoryName">{{ item.factoryName }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Product Description</label>
                  <span id="ProductDescription">{{ item.productDescription }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Factory Address</label>
                  <span id="FactoryAddress">{{ item.factoryAddress }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-show="item.isLeadInspector" class="py-2">
                  <label>Lead Inspectors</label>
                  <span id="LeadInspector">{{ item.isLeadInspector }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Assigned Inspectors</label>
                  <span id="AssignedInspectors">{{ item.displayResources }}</span>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-3" style="margin-top: 230px;">
              <v-row
                :id="'pastDue_' + item.jobDisplayId"
                style="background: #fff;width: 100%;margin: 10px auto;"
                v-for="(item, index) in displayPastList"
                :key="index"
                class="pb-3"
                @click="openWorkOrder(item)"
              >
                <v-col v-if="item.dataCaptureModifiedDate" cols="12" sm="10" md="10" class="state_tag_In-progress">
                  <span>In-progress</span>
                </v-col>
                <v-col v-else cols="12" sm="10" md="10" class="state_tag_New">
                  <span>New</span>
                </v-col>
                <!-- <v-col cols="2" sm="2" md="2">
                                    <span style="float: right;margin-right:10px;">Online</span>
                                </v-col> -->
                <v-col cols="12" sm="6" md="6" class="py-2">
                  <label>Job ID</label>
                  <span id="JobID">{{ item.jobDisplayId }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-2">
                  <label>Job Start Date</label>
                  <span id="JobStartDate">{{ item.jobStartDate | dateFormat("MM/DD/YYYY") }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-2">
                  <label>Work Order ID</label>
                  <span id="WorkOrder">{{ item.workOrderDisplayIds }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-2">
                  <label>Job End Date</label>
                  <span id="JobEndDate">{{ item.jobEndDate | dateFormat("MM/DD/YYYY") }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-2">
                  <label>Service Type</label>
                  <span id="ServiceType">{{ item.serviceType }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Work Order Class</label>
                  <span id="WorkOrderClass">{{ item.workOrderClasses }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Industry</label>
                  <span id="Industry">{{ item.workOrderIndustries }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Client Name</label>
                  <span id="ClientName">{{ item.clientName }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Inspection Date</label>
                  <span id="InspectionDate">{{ item.inspectionDate | dateFormat("MM/DD/YYYY") }}</span>
                </v-col>

                <v-col cols="12" sm="8" md="8" style="overflow: hidden;" class="py-2">
                  <label>Phone Number</label>
                  <span id="PhoneNumber">{{ item.factoryContactPhoneNumber }}</span>
                  <span id="PhoneNumberButton" @click.stop="call(item.factoryContactPhoneNumber)" style="float: right;margin-right:30px;">
                    <v-icon color="blue">phone</v-icon>
                  </span>
                </v-col>
                <v-col cols="12" sm="8" md="8" class="py-2">
                  <label>Email</label>
                  <span id="Email">{{ item.factoryContactEmail }}</span>

                  <span id="EmailButton" @click.stop="email(item.factoryContactEmail)" style="float: right;margin-right:30px;">
                    <v-icon color="blue">email</v-icon>
                  </span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Company Contact</label>
                  <span id="CompanyContact">{{ item.factoryContactName }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Factory Name</label>
                  <span id="FactoryName">{{ item.factoryName }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Product Description</label>
                  <span id="ProductDescription">{{ item.productDescription }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Factory Address</label>
                  <span id="FactoryAddress">{{ item.factoryAddress }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-show="item.isLeadInspector" class="py-2">
                  <label>Lead Inspectors</label>
                  <span id="LeadInspector">{{ item.isLeadInspector }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-2">
                  <label>Assigned Inspectors</label>
                  <span id="AssignedInspectors">{{ item.displayResources }}</span>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
          <!-- Today & Upcoming Schedule tabs  End-->
        </v-tab-item>
        <v-tab-item value="tab-2">
          <!-- Past Inspections | PendingSubmission table data -->
          <past-inspection></past-inspection>
        </v-tab-item>
        <v-tab-item value="tab-3" eager>
          <!-- Expense table data  -->
          <expense @msgExpenseCount="setExpenseCount"></expense>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import apiDetails from "../../shared/request/apiDetails";
import * as _ from "lodash";
import PastInspection from "../../components/inspection/PastInspection";
import Expense from "../../components/inspection/Expense";

function setState(store) {
  store.dispatch("navigation/setAppHeader", {
    show: true,
    showTitle: false,
    showMenu: true,
    showBack: false,
    showLogo: false,
    showSearch_job: true,
    showSearch_work: false,
    onlineDownload: true,
    onlineIsShow: true,
    cost: false,
    showCalendar: true,
    showResource: true,
  });
}
export default {
  name: "index",
  components: {
    PastInspection,
    Expense,
  },
  data() {
    return {
      // Schedules & Past Inspections tabs
      tab: null,
      // Today & Upcoming Schedule & Expense tabs
      tab1: null,

      // today job order list
      today_list: [],
      upcoming_list: [],
      past_list: [],

      // sort select
      selectSortValue: "",
      selectSort: [
        { text: "Date", value: "inspectionDate" },
        { text: "Location", value: "factoryAddress" },
        { text: "Factory Name", value: "factoryName" },
      ],

      // pendig Submission table data
      pendingHeaders: [
        { text: "Work Order", align: "center", value: "workOrderDisplayId" },
        { text: "Inspection Date", align: "center", value: "inspectionRequestDate" },
        { text: "Online Form", align: "center", sortable: false, value: "onlineForm" },
        { text: "Status", align: "center", value: "status" },
        { text: "", align: "center", value: "menu" },
      ],
      pendingList: [],

      // Past Inspections | PendingSubmission Menu
      showSheet: false,
      sheetList: [
        { title: "Edit", url_name: "workOrderDetailsId" },
        { title: "Preview Draft Summary", url_name: "pastInspectionsPreview" },
        { title: "Supplier Sign-off", url_name: "" },
      ],
      pending_menu: [
        { title: "Edit", url_name: "workOrderDetailsId", id: 1 },
        { title: "Preview Draft Summary", url_name: "pastInspectionsPreview" },
        { title: "Supplier Sign-off", url_name: "pastInspectionsSignOff" },
      ],

      jobList: [],
      // Completed table data
      completedHeaders: [
        { text: "Supplier/Site", align: "center", value: "supplier" },
        { text: "Work Order", align: "center", value: "workOrderDisplayId" },
        { text: "Date Inspected", align: "center", sortable: false, value: "inspectionRequestDate" },
        { text: "Date Submitted", align: "center", value: "dateSubmitted" },
      ],
      completedList: [
        {
          supplier: "0",
          workOrderDisplayId: 0,
          inspectionRequestDate: 0,
          dateSubmitted: 0,
        },
      ],
      loggedIn: false,
      expenseDialog: false,
      expenseCount: 0,
      internal: false,
      isCurrentlyLoading: false,
    };
  },
  activated() {
    // set head content
    // setState(this.$store);
    // this.getData();
    // this.setActiveTab();
    // this.getResourceType();
  },
  mounted() {
    // this.utilityFn.db.transaction((tx) => {
    //   tx.executeSql("SELECT * FROM downloadList Where resourceId=?", [this.utilityFn.getResourceId()], (tx, results) => {
    //     const len = results.rows.length;
    //     this.$store.dispatch("inspection/setDownloadNumber", len);
    //   });
    // });
    setState(this.$store);

    this.getData();
    // get the resoucetype if internal or external
    this.getResourceType();
    this.setActiveTab();
  },
  methods: {
    displayResourceName(resources) {
      return _.uniq(_.map(resources, "fullName")).join(", ");
    },
    getData() {
      if (this.isCurrentlyLoading) return

      this.isCurrentlyLoading = true;
      this.utilityFn.getServerDataAsync("get", `${this.apiList.JobList}`, {})
        .then(response => {
          this.isCurrentlyLoading = false;

          var todayTime = new Date(new Date().toLocaleDateString()).getTime();
          this.jobList = response.data.data;
          for (let i = 0; i < this.jobList.length; i++) {
            let job = this.jobList[i];
            let jobEndDate = new Date(new Date(job.jobEndDate).toLocaleDateString()).getTime();
            let resources = job.resources.map((res) => {
              res.day = new Date(new Date(res.day).toLocaleDateString());
              return res;
            });
            let workOrders = job.workOrders;
            for (let j = 0; j < resources.length; j++) {
            //   if (job.jobDisplayId == 1973 || job.jobDisplayId == 1978) {
            //   }
              if (apiDetails.resourceId().toLowerCase() != resources[j].resourceId.toLowerCase()) continue;

              // resources[j].schedules = _.orderBy(resources[j].schedules, ["day"], ["asc"]);
              resources[j].schedules.forEach((schedule) => {
              let inspectionDate = new Date(new Date(schedule).toLocaleDateString()).getTime();
              let completedWorkOrders = workOrders.filter(x => x.status == "Pending Results" || x.status == "Approved")
                if (inspectionDate == todayTime) {
                  let index = this.today_list.findIndex((x) => x.jobDisplayId == job.jobDisplayId);
                  if (index === -1) {
                   
                    job.inspectionDate = new Date(inspectionDate);
                    if(workOrders.length != completedWorkOrders.length){
                      this.today_list.push({ ...job });
                    }
                  }
                } else if (inspectionDate > todayTime) {
                  let index = this.upcoming_list.findIndex((x) => x.jobDisplayId == job.jobDisplayId);
                  if (index === -1) {
                    
                    job.inspectionDate = new Date(inspectionDate);
                    if(workOrders.length != completedWorkOrders.length){
                      this.upcoming_list.push({ ...job });
                    }
                  }
                }
                 if (jobEndDate < todayTime) {
                  let index = this.past_list.findIndex((x) => x.jobDisplayId == job.jobDisplayId);
                  if (index === -1) {
                    job.inspectionDate = new Date(inspectionDate);
                    let pastWorkOrders = workOrders.filter(x => x.status == "Pending Execution")
                     if(pastWorkOrders.length > 0){
                      this.past_list.push({ ...job });
                    }
                  }
                }
              });
            }
           

          }
          this.today_list = _.orderBy(this.today_list, ["inspectionDate"], ["asc"]);
          this.upcoming_list = _.orderBy(this.upcoming_list, ["inspectionDate"], ["asc"]);
          this.past_list = _.orderBy(this.past_list, ["inspectionDate"], ["asc"]);
          this.$store.dispatch("inspection/setJobList", response.data.data);
        })
        .catch(e => {
          console.log('Error loading resource schedules', e);
          this.isCurrentlyLoading = false;
        })
    },
    openWorkOrder(item) {
      this.$router.push({ name: "workOrderId", params: { id: item.jobId }, query: { id: item.jobDisplayId } });
      this.$store.dispatch("inspection/setWorkList", item.workOrders);
      this.$store.dispatch("inspection/setSearchValue", "");
    },
    call(number) {
      window.location.href = "tel:" + number;
    },
    email(address) {
      window.location.href = "mailto:" + address;
    },
    select_sort(type) {
      this.today_list = _.orderBy(this.today_list, [type], ["asc"]);
      this.upcoming_list = _.orderBy(this.upcoming_list, [type], ["asc"]);
      this.past_list = _.orderBy(this.past_list, [type], ["asc"]);
    },
    openExpenseTab() {
      this.expenseDialog = false;
      this.tab = "tab-3";
    },
    setExpenseCount(val) {
      this.expenseCount = val;
    },
    getResourceType() {
      this.internal = localStorage.getItem("isInternal");
      this.$store.dispatch("inspection/setResourceName", this.utilityFn.getResourceName());
    },
    clear_search_value() {
      this.$store.dispatch("inspection/setSearchValue", "");
    },
    setActiveTab() {
      if (this.$route.query.tab == 2) {
        this.tab = "tab-2";
      }
    },
  },
  computed: {
    ...mapState("inspection", {
      store_jobList: (state) => state.jobList,
      store_token: (state) => state.token,
      search_value: (state) => state.searchValue,
    }),
    displayTodayList() {
      return this.today_list
        .filter(x => x.jobDisplayId.toString().includes(this.search_value) || x.workOrders.findIndex(y => y.workOrderDisplayId.toString().includes(this.search_value)) !== -1)
        .map((x) => {
          x.displayResources = "";
          x.workOrderDisplayIds = x.workOrders.map(y => y.workOrderDisplayId).join(', ');
          x.workOrderClasses = [...new Set(x.workOrders.map(y => y.workOrderClass))].join(', ');
          x.workOrderIndustries = [...new Set(x.workOrders.map(y => y.workOrderIndustry))].join(', ');
          
          if (x.resources.length > 1) {
            x.resources.forEach((y) => {
              if (y.isLeadInspector === false) {
                x.displayResources = x.displayResources === undefined || x.displayResources === "" ? y.fullName : x.displayResources + ", " + y.fullName;
              } else {
                x.isLeadInspector = y.fullName;
              }
            });
          } else {
            x.resources.forEach((y) => {
              x.displayResources = y.fullName;
            });
          }
        return x;
      });
    },
    displayUpcomingList() {
      return this.upcoming_list
        .filter(x => x.jobDisplayId.toString().includes(this.search_value) || x.workOrders.findIndex(y => y.workOrderDisplayId.toString().includes(this.search_value)) !== -1)
        .map((x) => {
          x.displayResources = "";
          x.workOrderDisplayIds = x.workOrders.map(y => y.workOrderDisplayId).join(', ');
          x.workOrderClasses = [...new Set(x.workOrders.map(y => y.workOrderClass))].join(', ');
          x.workOrderIndustries = [...new Set(x.workOrders.map(y => y.workOrderIndustry))].join(', ');

          if (x.resources.length > 1) {
            x.resources.forEach((y) => {
              if (y.isLeadInspector === false) {
                x.displayResources = x.displayResources === undefined || x.displayResources === "" ? y.fullName : x.displayResources + ", " + y.fullName;
              } else {
                x.isLeadInspector = y.fullName;
              }
            });
          } else {
            x.resources.forEach((y) => {
              x.displayResources = y.fullName;
            });
        }
        return x;
      });
    },
    displayPastList() {
      return this.past_list
        .filter(x => x.jobDisplayId.toString().includes(this.search_value) || x.workOrders.findIndex(y => y.workOrderDisplayId.toString().includes(this.search_value)) !== -1)
        .map((x) => {
          x.displayResources = "";
          x.workOrderDisplayIds = x.workOrders.map(y => y.workOrderDisplayId).join(', ');
          x.workOrderClasses = [...new Set(x.workOrders.map(y => y.workOrderClass))].join(', ');
          x.workOrderIndustries = [...new Set(x.workOrders.map(y => y.workOrderIndustry))].join(', ');

          if (x.resources.length > 1) {
            x.resources.forEach((y) => {
              if (y.isLeadInspector === false) {
                x.displayResources = x.displayResources === undefined || x.displayResources === "" ? y.fullName : x.displayResources + ", " + y.fullName;
              } else {
                x.isLeadInspector = y.fullName;
              }
            });
          } else {
            x.resources.forEach((y) => {
              x.displayResources = y.fullName;
            });
          }
          return x;
      });
    },
  },
};
</script>
<style lang="stylus" scoped>

    .content
        background #e8e8e8


    // New & In-progress
    // job order state

    .state_tag_In-progress
        margin-bottom 10px
        padding 0
        line-height 30px

    .state_tag_In-progress span
        background #8BC34A
        color #fff
        text-align center
        display:inline-block
        width 100px

    .state_tag_New
        margin-bottom 10px
        padding 0

    .state_tag_New span
        background #FF9800
        color #fff
        text-align center
        display:inline-block
        width 80px

    .v-tabs-items
        background transparent !important

    label
        display inline-block
        width 140px
        color: #666666
        font-size: 13px
    span
        color #0C0C0C
        font-size: 14px
</style>
