<template>
  <div name="OverallResult">
    <v-row>
      <v-col>
        <v-container class="px-0 mt-n8" fluid>
          <v-radio-group v-model="workOrder.workOrderRatingId" @change="onRatingChange()" auto-id="overall result rating">
            <div class="my-1" v-for="(item, index) in workOrderRatingOptions" :key="index">
              <div class="d-flex">
                <v-icon v-if="rating && item.id == rating.id && showFailCodes" medium>mdi-menu-down</v-icon>
                <v-icon v-else medium>mdi-menu-right</v-icon>
                <v-radio :label="item.description" :value="item.id"> </v-radio>
                <div v-if="rating && item.id == rating.id && showFailCodes && isGenericCodes" class="helper-container">
                  <GenericCodeHelper></GenericCodeHelper>
                </div>
              </div>

              <v-expand-transition>
                <div class="fail-codes-wrapper" v-if="rating && item.id == rating.id && showFailCodes">
                  <v-form ref="form">
                    <v-checkbox
                      v-model="workOrder.failCodes"
                      v-for="(item, index) in resultCodes"
                      :key="index"
                      :label="item.code ? item.code + ' - ' + item.description : item"
                      :value="item.code ? item.code : item"
                      :rules="listRules"
                      hide-details
                      @change="onFailCodeChange()"
                      auto-id="overall result result code"
                    ></v-checkbox>
                  </v-form>
                </div>
              </v-expand-transition>
            </div>
          </v-radio-group>
        </v-container>
      </v-col>
    </v-row>
    <v-snackbar class="snackbar-loading" v-model="snackbar" :timeout="3000" :color="color">
      {{ message }}
      <v-btn text @click="snackbar = false"> Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as _ from "lodash";
import GenericCodeHelper from "../../views/common/GenericCodeHelper.vue"

export default {
  name: "OverallResult",
  components: {
    GenericCodeHelper
  },
  data() {
    return {
      workOrderClone: {},
      workOrderRatingOptions: [],
      defaultFailCodes: ["UL0", "UL1", "UL2", "UL3", "UL4", "UL5", "UL6", "UL7", "UL8", "UL9", "UL10"],
      listRules: [(v) => v.length > 0 || "Required"],
      snackbar: false,
      color: "",
      message: ""
    };
  },
  mounted() {
    // get initial value of workOrder object - will use this if api call fails
    this.workOrderClone = Object.assign({}, this.workOrder);
    this.getWorkOrderRatingOptions();
    console.log("Mounted OverallResult", this.workOrder);
  },
  computed: {
    ...mapState("inspection", {
      workOrderDetails: (state) => state.workOrderDetails,
    }),

    rating() {
      return this.workOrderRatingOptions.find((x) => x.id === this.workOrder.workOrderRatingId);
    },

    resultCodes() {
      const resultTypeCodes = this.rating.companyWorkOrderResultTypeCodes.filter(x => x.isActive);
      const codes = this.rating ? _.orderBy(resultTypeCodes, ["order"], ["asc"]).map((c) => ({ code: c.code, description: c.description })) : [];
      return codes.length > 0 ? codes : this.defaultFailCodes;
    },

    isGenericCodes() {
      const resultTypeCodes = this.rating.companyWorkOrderResultTypeCodes.filter(x => x.isActive);
      const codes = this.rating ? _.orderBy(resultTypeCodes, ["order"], ["asc"]).map((c) => ({ code: c.code, description: c.description })) : [];
      return codes.length > 0 ? false : true;
    },

    resultCodeLabel() {
      const rating = this.workOrderRatingOptions.find((x) => x.id === this.workOrder.workOrderRatingId);
      if (rating && rating.companyWorkOrderResultTypeCodes.length > 0) {
        if (rating.description.toLowerCase().indexOf("abort") != -1) {
          return "Abortive Code";
        } else if (rating.description.toLowerCase().indexOf("pending") != -1) {
          return "Pending Code";
        }
      }
      return "Fail Code";
    },

    showFailCodes() {
      const rating = this.workOrderRatingOptions.find((x) => x.id === this.workOrder.workOrderRatingId);
      return (
        rating &&
        (rating.workOrderResultType.description.toLowerCase() == "fail" || ((rating.description.toLowerCase().indexOf("abort") != -1 || rating.description.toLowerCase().indexOf("pending") != -1) && rating.companyWorkOrderResultTypeCodes.length > 0))
      );
    },
  },
  methods: {
    ...mapActions('navigation', ['setSnackbar']),

    isSectionSubmitted() {
      const section = this.workOrder.workOrderInspectionSections.find((x) => x.sectionId === this.sectionId);
      return section && !!section.submittedDate;
    },

    getWorkOrderRatingOptions() {
      this.utilityFn.getServerData("get", this.apiList.GetCompanyWOResultTypeAliases + "?companyId=" + this.workOrder.clientId, {}, (res) => {
        this.workOrderRatingOptions = res.data.data.filter((x) => x.description && x.isActive);
      });
    },

    clearFailCodes() {
      this.workOrder.failCodes = [];
    },

    saveWorkOrderRating: _.debounce(function() {
      console.log("SaveWorkOrderRating");
      const workOrderResult = {
        workOrderId: this.workOrder.workOrderId,
        workOrderRatingId: this.workOrder.workOrderRatingId,
        failCodes: this.workOrder.failCodes,
      };
      this.utilityFn.getServerData(
        "post",
        this.apiList.updateWorkOrderOverallResult,
        workOrderResult,
        (res) => {
          const workOrder = {  
            ...this.workOrderDetails, 
            workOrderRatingId: this.workOrder.workOrderRatingId,
            failCodes: this.workOrder.failCodes,
            workOrderRatingDescription: this.rating.description
          }
          this.workOrderClone = Object.assign({}, workOrder);
          this.$store.dispatch("inspection/setWorkOrderDetails", this.workOrderClone);

          this.showSnackbar("Overall Result Update Successful!", "success");
        },
        (err) => {
          if (window.navigator.onLine !== true) {
            const workOrder = {  
              ...this.workOrderDetails, 
              workOrderRatingId: this.workOrder.workOrderRatingId,
              failCodes: this.workOrder.failCodes,
              workOrderRatingDescription: this.rating.description
            }
            this.workOrderClone = Object.assign({}, workOrder);
            this.$store.dispatch("inspection/setWorkOrderDetails", this.workOrderClone);

            this.showSnackbar(this.msgList.NoConnection, "error");
          } else {
            console.log("An error occured", err);
            // revert back to previous state
            this.workOrder.workOrderRatingId = this.workOrderClone.workOrderRatingId;
            this.workOrder.workOrderRatingDescription = this.workOrderClone.workOrderRatingDescription;
            this.workOrder.failCodes = this.workOrderClone.failCodes;

            this.showSnackbar("Overall Result Update Unsuccessful!", "error");
          }
        }
      );
    }, 1000),

    onRatingChange() {
      this.clearFailCodes();
      
      if (this.showFailCodes) {
        // trigger the checkbox error validation
        if (this.$refs.form && this.$refs.form[0]) {
          setTimeout(() => this.$refs.form[0].validate());
        }
      } else {
        this.saveWorkOrderRating();
      }
    },

    onFailCodeChange() {
      this.saveWorkOrderRating();
    },

    showSnackbar(message, color) {
      this.setSnackbar({
        text: message,
        color
      });
    },
  },
  props: {
    workOrder: {
      type: Object,
      default: function() {
        return {
          workOrderRatingId: null,
          failCodes: [],
        };
      },
    },
  },
};
</script>

<style lang="stylus" scoped>
.fail-codes-wrapper
    margin-left 100px
    margin-bottom 20px
.snackbar-loading
    margin-bottom 60px

.helper-container {
    margin-top: -6px;
}
</style>
