<template>
    <div name="FormBuildAdd">
        <span style="display:none">{{other}}</span>
        <p v-if="this.msgData.atom == ''" style="margin:10px 0;padding: 35px 0;" draggable="false" @click="aaa">
            <v-icon rigth color="rgb(0, 83, 138)">add_circle</v-icon>
            <span style="vertical-align: bottom;color:rgb(0, 83, 138);">Drag Field Here</span>
        </p>
        <div v-if="this.msgData.atom == 'Lookup'" style="padding: 20px 0;">
            <span class="edit_field">
                <v-icon rigth color="rgb(0, 83, 138)" @click="edit_field(1)">edit</v-icon>
            </span>
            <p>{{msgData.label}}</p>
            <select name="" id="">
                <option value="1">1</option>
                <option value="2">2</option>
            </select>
        </div>
        <div v-if="this.msgData.atom == 'dropdown'" style="padding: 20px 0;">
            <span class="edit_field">
                <v-icon rigth color="rgb(0, 83, 138)" @click="edit_field(1)">edit</v-icon>
            </span>
            <p>{{msgData.label}}</p>
            <select name="" id="">
                <option value="1">1</option>
                <option value="2">2</option>
            </select>
        </div>
        <div v-if="this.msgData.atom == 'date'" style="padding: 20px 0;">
            <span class="edit_field">
                <v-icon rigth color="rgb(0, 83, 138)" @click="edit_field(1)">edit</v-icon>
            </span>
            <p>{{msgData.label}}</p>
            <input type="date">
        </div>
        <div v-if="this.msgData.atom == 'time'" style="padding: 20px 0;">
            <span class="edit_field">
                <v-icon rigth color="rgb(0, 83, 138)" @click="edit_field(1)">edit</v-icon>
            </span>
            <p>{{msgData.label}}</p>
            <input type="time">
        </div>
        <div v-if="this.msgData.atom == 'textbox'" style="padding: 20px 0;">
            <span class="edit_field">
                <v-icon rigth color="rgb(0, 83, 138)" @click="edit_field(1)">edit</v-icon>
            </span>
            <p>{{msgData.label}}</p>
            <input type="text">
        </div>
     </div>
</template>

<script>

export default {
    name: 'FormBuild',
    data (){
        return {
        }
    },
    computed: {
    },
    mounted () {
        console.log(this.msgData)
    },
    methods: {
        edit_field (type) {
            this.$emit('msgFnEdit',type);
        }
    },
    props: ['msgData', 'other']
};
</script>

<style lang="stylus" scoped>
    select
        border 1px #ccc solid
        height 35px
        width 84%
        appearance button
    
    input 
        border 1px #ccc solid
        height 35px
        width 84%

    .edit_field
        position absolute
        right 5px
        top 3px

</style>
