<template>
  <div>
    <v-btn id="toolTip" icon dark @click="show_tooltips = true" auto-id="generic code tooltip">
      <v-icon color="rgb(115, 115, 115)"
        >help</v-icon
      >
    </v-btn>
    <v-dialog
      v-model="show_tooltips"
      width="500px"
    >
      <v-card>
        <v-card-content>
          <table class="fail_code" auto-id="generic code table">
            <tr>
              <td valign="top">UL0</td>
              <td>
                <p>
                  Finding of issue on product that was not covered by below
                  reasons
                </p>
              </td>
            </tr>
            <tr>
              <td valign="top">UL1</td>
              <td>
                <p><strong>Product Deviation</strong></p>
                <p>
                  Product is not conform with specification/ gold seal sample
                </p>
                <p>- Dimension</p>
                <p>- Color and style</p>
                <p>- Accessory</p>
                <p>- Required function and feature</p>
                <p>- Material</p>
              </td>
            </tr>
            <tr>
              <td valign="top">UL2</td>
              <td>
                <p><strong>Retail Packaging Deviation</strong></p>
                <p>
                  Labeling / Manuals / Hang tag / Markings / Artwork / Barcode
                </p>
              </td>
            </tr>
            <tr>
              <td valign="top">UL3</td>
              <td>
                <p><strong>Shipping Carton Deviation</strong></p>
                <p>Export carton dimension / weight / shipping marks</p>
              </td>
            </tr>
            <tr>
              <td valign="top">UL4</td>
              <td>
                <p><strong>Onsite test Issues</strong></p>
                <p>- No equipment to conduct test</p>
                <p>- Factory refuse to conduct functional test</p>
                <p>- Functional test failed</p>
              </td>
            </tr>
            <tr>
              <td valign="top">UL5</td>
              <td>
                <p><strong>Carton Drop Test</strong></p>
                <p>- Factory refuse to conduct carton drop test</p>
                <p>- Carton drop test failed</p>
              </td>
            </tr>
            <tr>
              <td valign="top">UL6</td>
              <td>
                <p>
                  Client specification/ Approval Sample not available during
                  inspection
                </p>
              </td>
            </tr>
            <tr>
              <td valign="top">UL7</td>
              <td>
                <p>Exceed AQL</p>
              </td>
            </tr>
            <tr>
              <td valign="top">UL8</td>
              <td>
                <p>Shortage of Shipment Quantity</p>
              </td>
            </tr>
            <tr>
              <td valign="top">UL9</td>
              <td>
                <p><strong>Missing Documents</strong></p>
                <p>- CDF not available</p>
                <p>- Client POs</p>
                <p>- Product specification requirement</p>
              </td>
            </tr>
            <tr>
              <td valign="top">UL10</td>
              <td>
                <p>All issues related for Sample Collection</p>
              </td>
            </tr>
          </table>
        </v-card-content>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="btn_Close"
            color="blue darken-1"
            text
            @click="show_tooltips = false"
            auto-id="generic code close"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "GenericCodeHelper",
  data() {
    return {
      show_tooltips: false,
    };
  },
};
</script>

<style lang="stylus" scoped>
</style>
