import axios from 'axios'
import apiDetails from '../request/apiDetails';
import apiList from "../request/apiList";
import * as _ from 'lodash'

let mainResult = {}

mainResult.getServerData = function(type, url, params, fn) {
        if (type == 'post') {
            axios.post(url, params)
                .then((response) => {
                    fn(response)
                }).catch((response) => {
                    fn(response)
                })
        } else if (type == 'get') {
            axios.get(url, {
                headers: apiDetails.header.headersDetail,
                params: params
            }).then((response) => {
                fn(response)
            }).catch((response) => {
                fn(response)
            })
        } else if (type == 'delete') {
            axios.delete(url + params, {
                headers: apiDetails.header.headersDetail
            }).then((response) => {
                fn(response)
            }).catch((response) => {
                fn(response)
            })
        }
    }
mainResult.getData = async function(obj) {
    await axios.get(apiList.baseUrl + '/api/AIMS/GetResourceSchedules', {
        headers: apiDetails.header.headersDetail
    }).then((response) => {
        let jobList = _.uniqBy(response.data, (o) => {
            return o.jobDisplayId
        });

        var todayTime = new Date(new Date().toLocaleDateString()).getTime();
        var today_list = [];
        var upcoming_list = [];
        var past_list = [];

        for (let i = 0; i < jobList.length; i++) {
            let job = jobList[i];
            let resources = job.resources.map(res => {
                res.day = new Date(new Date(res.day).toLocaleDateString())
                return res;
            });

            resources = _.orderBy(resources, ['day'], ['asc']);
            for (let j = 0; j < resources.length; j++) {
                if (apiDetails.resourceId().toLowerCase() != resources[j].resourceId.toLowerCase()) continue;

                resources[j].schedules.forEach(schedule => {
                    let inspectionDate = new Date(new Date(schedule.day).toLocaleDateString()).getTime();

                    if (inspectionDate == todayTime) {

                        let index = today_list.findIndex(x => x.jobDisplayId == job.jobDisplayId);
                        if (index === -1) {
                            job.inspectionDate = new Date(inspectionDate);
                            today_list.push({...job });
                        }
                    } else if (inspectionDate > todayTime) {
                        let index = upcoming_list.findIndex(x => x.jobDisplayId == job.jobDisplayId);
                        if (index === -1) {
                            job.inspectionDate = new Date(inspectionDate);
                            upcoming_list.push({...job });
                        }
                    }
                });
            }
            
            past_list.push(...job.workOrders)
            
        }
        today_list = _.orderBy(today_list, ['inspectionDate'], ['asc']);
        upcoming_list = _.orderBy(upcoming_list, ['inspectionDate'], ['asc']);

        obj.today_list = today_list
        obj.upcoming_list = upcoming_list
        mainResult.joblist = jobList
        mainResult.worklist = past_list
    }).catch((response) => {
        console.log(response);
    })
}

mainResult.getAllGeoContries = async function(countryName) {
    let data;
    await axios.get(apiList.baseUrl + '/api/AIMS/GetAllGeoCountriesLookups?searchtext=' + countryName, {
        headers: apiDetails.header.headersDetail,
    }).then((response) => {
        data = response.data
    }).catch((response) => {
        console.log(response);
    })
    return data;
}
export default mainResult