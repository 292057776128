import {
    SET_JOB_LIST, SET_DOWNLOAD_NUMBER, SET_RESOURCE_NAME, SET_SEARCH_VALUE,
    SET_WORKORDER_DETAILS, SET_ONLINE, SET_WORK_LIST, SET_CLIENT_DEFECT_LIST, SET_SECTION_DATA,
    SET_SAMPLING_PLAN_LIST, SET_FORM_ATOM_LIST, EDIT_MY_AUTHORIZED,SET_REMARKS_LIST,SET_MY_AUTHORIZED,SET_UPDATED_REMARKS_ENTRY,
    SET_ENABLED_WO_SEARCH,SET_QV_LIST,SET_TOKEN,SET_PROD_QUAN_VERF_LIST,SET_CHILD_WO_SIZE_MEASURE,SET_INSPECTION_SUMMARY_SECTION_RESULT,
    SET_PROD_QUANT_CERF_LIST_FLAG,SET_LOGGEDIN, SET_CLIENT_DEFECTS_LOADING_STATUS, SET_CURRENT_ACTIVE_WORKORDER, SET_DYNAMIC_FORM_FORCE_REFRESH,
    SET_CRITICAL_POM_LIST,
    SET_RETAIL_MARKET_CDF
} from './mutation.types'


export default {
    setWorkOrderDetails({ commit, rootState }, payload) {
        commit(SET_WORKORDER_DETAILS, { ...rootState.inspection.workOrderDetails, ...payload});
    },
    setDownloadNumber({ commit }, payload) {
        commit(SET_DOWNLOAD_NUMBER, payload);
    },
    setWorkList({ commit }, payload) {
        commit(SET_WORK_LIST, payload);
    },
    setResourceName({ commit }, payload) {
        commit(SET_RESOURCE_NAME, payload);
    },
    setSearchValue({ commit }, payload) {
        commit(SET_SEARCH_VALUE, payload);
    },
    setJobList({ commit }, payload) {
        commit(SET_JOB_LIST, payload);
    },
    setClientDefectList({ commit }, payload) {
        commit(SET_CLIENT_DEFECT_LIST, payload);
    },
    setSectionData({ commit }, paylod) {
        commit(SET_SECTION_DATA, paylod);
    },
    setSamplingPlanList({ commit }, payload) {
        commit(SET_SAMPLING_PLAN_LIST, payload);
    },
    setFormAtomsList({ commit }, payload) {
        commit(SET_FORM_ATOM_LIST, payload);
    },
    editMyAuthorized({ commit }, payload) {
        commit(EDIT_MY_AUTHORIZED, payload);
    },
    setRemarksList({ commit }, payload) {
        commit(SET_REMARKS_LIST,payload);
    },
    setMyAuthorized({ commit }, payload) {
        commit(SET_MY_AUTHORIZED,payload)
    },
    setUpdatedRemarksEntry({ commit }, payload) {
       commit(SET_UPDATED_REMARKS_ENTRY,payload);
    },
    setEnabledWorkOrderSearch({commit},payload) {
        commit(SET_ENABLED_WO_SEARCH,payload);
    },
    setQVListUpdated({commit},payload) {
       commit(SET_QV_LIST,payload)
    },
    setToken({commit},payload) {
        commit(SET_TOKEN,payload);
    },
    setProductQuantityVerificationList({commit},payload) {
       commit(SET_PROD_QUAN_VERF_LIST,payload);
    },
    setChildWorkOrderSizeMeasurement({commit},payload) {
       commit(SET_CHILD_WO_SIZE_MEASURE,payload);
    },
    setLoggedIn({commit},payload) {
        commit(SET_LOGGEDIN,payload);
    },
    setClientDefectsLoadingStatus({commit}, payload) {
        commit(SET_CLIENT_DEFECTS_LOADING_STATUS, payload);
    },
    setInspectionSummaryResult({commit},payload) {
        commit(SET_INSPECTION_SUMMARY_SECTION_RESULT,payload);
    },
    setCurrentActiveWorkOrder({commit}, payload) {
        commit(SET_CURRENT_ACTIVE_WORKORDER, payload);
    },
    // setOnline({commit},payload) {
    //    commit(SET_ONLINE,payload);
    // },
    setDynamicFormForceRefresh({commit}, payload) {
        commit(SET_DYNAMIC_FORM_FORCE_REFRESH, payload);
    },
    setCriticalPOMList({commit}, payload) {
        commit(SET_CRITICAL_POM_LIST, payload);
    },
    setRetailMarketCDF({commit}, payload) {
        commit(SET_RETAIL_MARKET_CDF, payload);
    },
};