import { render, staticRenderFns } from "./DynamicFormTable.vue?vue&type=template&id=235c3725&scoped=true&"
import script from "./DynamicFormTable.vue?vue&type=script&lang=js&"
export * from "./DynamicFormTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "235c3725",
  null
  
)

export default component.exports