<!-- Used for Dynamic Form -->

<template>
  <div name="Other">
    <v-tabs v-model="tab1" fixed-tabs background-color="#399dff" class="elevation-2" dark style="margin-top:30px;" show-arrows auto-id="others tabs">
      <v-tab href="#tab-1" :style="!sectionData.CDF.isAuthorized || sectionData.CDF.isHidden ? 'background-color: #eee; color: #000;' : 'background-color: #399dff'" auto-id="cdf listed compnent verification report">
        CDF Listed Component Verification Report
      </v-tab>
      <v-tab href="#tab-2" :style="!sectionData.SerialNumberSampled.isAuthorized || sectionData.SerialNumberSampled.isHidden ? 'background-color: #eee; color: #000;' : 'background-color: #399dff'" auto-id="serial number sampled">
        Serial Number Sampled
      </v-tab>

      <v-tab href="#tab-4" :style="!sectionData.InspectionEnvironment.isAuthorized || sectionData.InspectionEnvironment.isHidden ? 'background-color: #eee; color: #000;' : 'background-color: #399dff'" auto-id="inspection environment">
          Inspection Environment
      </v-tab>

      <v-tab href="#tab-5" :style="!sectionData.DrawnSamples.isAuthorized || sectionData.DrawnSamples.isHidden ? 'background-color: #eee; color: #000;' : 'background-color: #399dff'" auto-id="drawn samples">
          Drawn Samples
      </v-tab>

      <v-tab href="#tab-6" @click="clickOtherAttachment()" :style="!sectionData.OtherAttachments.isAuthorized || sectionData.OtherAttachments.isHidden ? 'background-color: #eee; color: #000;' : 'background-color: #399dff'" auto-id="other attachments">
        Other Attachments
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab1">
      <v-tab-item :value="'tab-1'">
        <div :style="!sectionData.CDF.isEnabled ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop' : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'"></div>

        <v-card flat tile style="padding: 15px">
          <v-row v-if="sectionData.CDF.isAuthorized">
            <v-col cols="12" sm="12">
              <v-btn
                v-if="generalData.resources.length > 1 && sectionData.CDF.isAuthorized"
                :disabled="sectionData.CDF.isSubmitted || sectionData.CDF.isHidden"
                class="submit-btn ma-2 float-right"
                @click="submit_item(13)"
                small
                depressed
                color="#1867c0"
                auto-id="other submit button"
                >Submit</v-btn
              >
              <v-switch v-model="otherSwitch1" color="green" class="ma-2 float-right" @change="onSectionToggle(sectionData.CDF.id)"></v-switch>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn :disabled="sectionData.CDF.isHidden" icon v-on="on" @click="go_gallery(sectionData.CDF.name, sectionData.CDF.id)" auto-id="other mdi-camera">
                    <v-icon color="rgba(0,0,0,.87)">mdi-camera</v-icon>
                  </v-btn>
                </template>
              </v-menu>
            </v-col>
          </v-row>

          <v-data-table :headers="otherTab1Header" :items="otherTab1List" :items-per-page="-1"
          :hide-default-footer="true" class="elevation-1" style="margin-top: 30px;" auto-id="other table">
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-spacer></v-spacer>
                <v-dialog v-model="otherDialog1" max-width="500px" @click:outside="close_other('otherTab1List')" auto-id="other dialog 1">
                  <template v-slot:activator="{ on }">
                    <v-btn v-if="sectionData.CDF.isAuthorized"  color="#B14C4C" v-on="on" :disabled="sectionData.CDF.isHidden" auto-id="other add new entry">Add New Entry</v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Add New Entry</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-text-field v-model="otherTab1TableComponentDescription" label="Component Description*" auto-id="other component description"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field v-model="otherTab1TableSpecification" label="Specification*" auto-id="other specification"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field v-model="otherTab1TableActualObserved" label="Actual Observed*" auto-id="other actual observed"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-select :items="resultSelect" v-model="otherTab1TableResult" label="Result*" auto-id="other result"></v-select>
                          </v-col>
                          <v-col cols="12" sm="12">
                            <v-text-field value="1" v-model="otherTab1TableRemarks" label="Remark(s)*" auto-id="other remarks"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close_other('otherTab1List')" auto-id="other close">Close</v-btn>
                      <v-btn color="blue darken-1" text @click="save_other('otherTab1List')" auto-id="other save">Save</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.menu="{ item }">
              <v-btn :disabled="!sectionData.CDF.isAuthorized" icon @click="select_other('otherTab1List', item)"
               v-show="otherSwitch1 && !item.offline" auto-id="other edit">
                <v-icon color="rgba(0,0,0,.87)">edit</v-icon>
              </v-btn>
              <v-btn :disabled="!sectionData.CDF.isAuthorized" icon @click="other_table_del(item, 'otherTab1List')"
               v-show="otherSwitch1  && !item.offline" auto-id="other delete">
                <v-icon color="rgba(0,0,0,.87)" style="margin-left: 15px;">delete</v-icon>
              </v-btn>
            
              <v-btn icon dark v-show="!otherSwitch1" auto-id="other edit 2">
                <v-icon color="#cccccc">edit</v-icon>
              </v-btn>
              <v-btn icon dark v-show="!otherSwitch1" auto-id="other delete 2">
                <v-icon color="#cccccc" style="margin-left: 15px;">delete</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.result="{ item }">
              <span>{{ item.result ? "Pass" : "Fail" }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item :value="'tab-2'">
        <div
          :style="
            !sectionData.SerialNumberSampled.isEnabled ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop' : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
          "
        ></div>

        <v-card flat tile style="padding: 15px">
          <v-row v-if="sectionData.SerialNumberSampled.isAuthorized">
            <v-col cols="12" sm="12">
              <v-btn
                v-if="generalData.resources.length > 1 && sectionData.SerialNumberSampled.isAuthorized"
                :disabled="sectionData.SerialNumberSampled.isSubmitted || sectionData.SerialNumberSampled.isHidden"
                class="submit-btn ma-2 float-right"
                @click="submit_item(14)"
                small
                depressed
                color="#1867c0"
                auto-id="other submit 2"
                >Submit</v-btn
              >
              <v-switch v-model="otherSwitch2" color="green" class="ma-2 float-right" @change="onSectionToggle(sectionData.SerialNumberSampled.id)" auto-id="other switch 2"></v-switch>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn :disabled="sectionData.SerialNumberSampled.isHidden" icon v-on="on" @click="go_gallery(sectionData.SerialNumberSampled.name, sectionData.SerialNumberSampled.id)" auto-id="other mdi-camera 2">
                    <v-icon color="rgba(0,0,0,.87)">mdi-camera</v-icon>
                  </v-btn>
                </template>
              </v-menu>
            </v-col>
          </v-row>

          <v-data-table :headers="otherTab2Header" :items="otherTab2List" :items-per-page="-1"
          :hide-default-footer="true" class="elevation-1" style="margin-top: 30px;">
             <template v-slot:top>
              <v-toolbar flat color="white">
                <v-spacer></v-spacer>
                <v-dialog v-model="otherDialog2" max-width="500px">
                  <template v-slot:activator="{ on }">
                    <v-btn v-if="sectionData.SerialNumberSampled.isAuthorized" color="#B14C4C" v-on="on" :disabled="sectionData.SerialNumberSampled.isHidden" auto-id="other add new entry 2">Add New Entry</v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Add New Entry</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-text-field v-model="otherTab2TableNumber" label="No." type="number" auto-id="other no."></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field v-model="otherTab2TableSerialNumber" label="Serial Number" auto-id="other serial number"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field v-model="otherTab2TableNoOfCartons" label="# of Carton(s)" type="number" auto-id="other # of cartons"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close_other('otherTab2List')" auto-id="other close 2">Close</v-btn>
                      <v-btn color="blue darken-1" text @click="save_other('otherTab2List')" auto-id="other save 2">Save</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.menu="{ item }">
              <v-btn :disabled="!sectionData.SerialNumberSampled.isAuthorized" icon @click="select_other('otherTab2List', item)"
               v-show="otherSwitch2  && !item.offline" auto-id="other edit 2">
                <v-icon color="rgba(0,0,0,.87)">edit</v-icon>
              </v-btn>
              <v-btn :disabled="!sectionData.SerialNumberSampled.isAuthorized" icon @click="other_table_del(item, 'otherTab2List')"
               v-show="otherSwitch2 && !item.offline" auto-id="other delete 2">
                <v-icon color="rgba(0,0,0,.87)" style="margin-left: 15px;">delete</v-icon>
              </v-btn>
              <v-btn icon dark v-show="!otherSwitch2" auto-id="other edit 3">
                <v-icon color="#cccccc">edit</v-icon>
              </v-btn>
              <v-btn icon dark v-show="!otherSwitch2">
                <v-icon color="#cccccc" style="margin-left: 15px;" auto-id="other delete 3">delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>

      <v-tab-item value="tab-4">
        <div
          :style="
            !sectionData.InspectionEnvironment.isEnabled ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop' : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
          "
        ></div>
        <v-card flat tile style="padding: 0 24px 16px;">
          <v-row v-if="sectionData.InspectionEnvironment.isAuthorized">
            <v-col cols="12" sm="12">
              <v-btn
                v-if="generalData.resources.length > 1 && sectionData.InspectionEnvironment.isAuthorized"
                :disabled="sectionData.InspectionEnvironment.isSubmitted || sectionData.InspectionEnvironment.isHidden"
                class="submit-btn ma-2 float-right"
                @click="submit_item(sectionData.InspectionEnvironment.id)"
                small
                depressed
                color="#1867c0"
                auto-id="other submit 4"
                >Submit</v-btn
              >
              <v-switch v-model="otherSwitch4" color="green" class="ma-2 float-right" @change="onSectionToggle(sectionData.InspectionEnvironment.id)"></v-switch>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" :disabled="sectionData.InspectionEnvironment.isHidden" @click="go_gallery('Inspection Environment', sectionData.InspectionEnvironment.id)" auto-id="other mdi-camera 4">
                    <v-icon color="rgba(0,0,0,.87)">mdi-camera</v-icon>
                  </v-btn>
                </template>
              </v-menu>
            </v-col>
          </v-row>

          <div style="position: relative;">
            <div
              :style="
                sectionData.InspectionEnvironment.isHidden
                  ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop'
                  : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
              "
            ></div>
            <v-row>
              <v-col cols="6" sm="6" align-self="center">
                <v-select v-model="formOtherTab4.result" label="Result" :items="satisfactoryOptions" item-value="value" item-text="text" @change="upsertInspectionEnvironment" auto-id="other result 4"> </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-radio-group v-model="formOtherTab4.lightingResult" label="Lighting" :mandatory="false" row @change="upsertInspectionEnvironment" auto-id="other lightning 4">
                  <v-radio label="Satisfactory" :value="1"></v-radio>
                  <v-radio label="Unsatisfactory" :value="2"></v-radio>
                  <v-radio label="NA" :value="0"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-radio-group v-model="formOtherTab4.cleanlinessResult" label="Cleanliness" :mandatory="false" row @change="upsertInspectionEnvironment" auto-id="other cleanliness 4">
                  <v-radio label="Satisfactory" :value="1"></v-radio>
                  <v-radio label="Unsatisfactory" :value="2"></v-radio>
                  <v-radio label="NA" :value="0"></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-radio-group v-model="formOtherTab4.humidityResult" label="Humidity" :mandatory="false" row @change="upsertInspectionEnvironment" auto-id="other humidity 4">
                  <v-radio label="Satisfactory" :value="1"></v-radio>
                  <v-radio label="Unsatisfactory - Humid" :value="3"></v-radio>
                  <v-radio label="Unsatisfactory - Wet" :value="4"></v-radio>
                  <v-radio label="NA" :value="0"></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="6" sm="6" md="6">
                <v-radio-group v-model="formOtherTab4.odorResult" label="Odor" :mandatory="false" row @change="upsertInspectionEnvironment" auto-id="other odor 4">
                  <v-radio label="Observed" :value="5"></v-radio>
                  <v-radio label="Not Observed" :value="6"></v-radio>
                  <v-radio label="NA" :value="0"></v-radio>
                  <v-text-field v-show="formOtherTab4.odorResult == 5" v-model="formOtherTab4.odorDetails" hide-details placeholder="Details" @change="upsertInspectionEnvironment" auto-id="other odor details 4"></v-text-field>
                </v-radio-group>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-radio-group v-model="formOtherTab4.hygieneResult" label="Hygiene Issue" :mandatory="false" row @change="upsertInspectionEnvironment" auto-id="other hygine issue 4">
                  <v-radio label="Observed" :value="5"></v-radio>
                  <v-radio label="Not Observed" :value="6"></v-radio>
                  <v-radio label="NA" value="0"></v-radio>
                  <v-text-field v-show="formOtherTab4.hygieneResult == 5" v-model="formOtherTab4.hygieneDetails" hide-details placeholder="Details" @change="upsertInspectionEnvironment" auto-id="other hygine issue details 4"></v-text-field>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h4>Others</h4>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="formOtherTab4.othersSummary" hide-details placeholder="Enter other inspection summary" @change="upsertInspectionEnvironment" auto-id="other enter other inspection summry 4"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-radio-group v-model="formOtherTab4.othersResult" :mandatory="false" row auto-id="other other result 4">
                  <v-radio label="Conformed" value="Conformed"></v-radio>
                  <v-radio label="Non-Conformed" value="Non-Conformed"></v-radio>
                  <v-radio label="NA" value="N/A"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="formOtherTab4.othersFindings" hide-details placeholder="Enter other inspection summary findings" @change="upsertInspectionEnvironment" auto-id="other other inspection summary findings 4"> </v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-tab-item>
      <v-tab-item value="tab-5">
        <div
          :style="!sectionData.DrawnSamples.isEnabled ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop' : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'"
        ></div>
        <v-card flat tile style="padding: 0 24px 16px;">
          <v-row v-if="sectionData.DrawnSamples.isAuthorized">
            <v-col cols="12" sm="12">
              <v-btn
                v-if="generalData.resources.length > 1 && sectionData.DrawnSamples.isAuthorized"
                :disabled="sectionData.DrawnSamples.isSubmitted || sectionData.DrawnSamples.isHidden"
                class="submit-btn ma-2 float-right"
                @click="submit_item(sectionData.DrawnSamples.id)"
                small
                depressed
                color="#1867c0"
                auto-id="other submit 5"
                >Submit</v-btn
              >
              <v-switch v-model="otherSwitch5" color="green" class="ma-2 float-right" @change="onSectionToggle(sectionData.DrawnSamples.id)" auto-id="other switch 5"></v-switch>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn :disabled="sectionData.DrawnSamples.isHidden" icon v-on="on" @click="go_gallery(sectionData.DrawnSamples.name, sectionData.DrawnSamples.id)" auto-id="other mdi-camera 5">
                    <v-icon color="rgba(0,0,0,.87)">mdi-camera</v-icon>
                  </v-btn>
                </template>
              </v-menu>
            </v-col>
          </v-row>

          <div style="position: relative; ">
            <div
              :style="sectionData.DrawnSamples.isHidden ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop' : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'"
            ></div>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-radio-group v-model="formOtherTab5.sampleResult" label="Drawn Samples" :mandatory="false" row @change="upsertDrawnSample" auto-id="other drawn samples 5">
                  <v-radio label="Yes" :value="true"></v-radio>
                  <v-radio label="No" :value="false"></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="4" sm="4" md="4">
                <v-text-field v-model="formOtherTab5.sampleDescription" hide-details placeholder="Enter # of pc(s) here" @change="upsertDrawnSample" auto-id="other enter # of pcs here 5"></v-text-field>
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-text-field v-model="formOtherTab5.courier" hide-details label="Courier" @change="upsertDrawnSample" auto-id="other courier 5"></v-text-field>
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-text-field v-model="formOtherTab5.awb" hide-details label="AWB" @change="upsertDrawnSample" auto-id="other awb 5"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-tab-item>
      <v-tab-item :value="'tab-6'">
        <div
          :style="
            !sectionData.OtherAttachments.isEnabled ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop' : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
          "
        ></div>
        <v-card flat tile>
          <br />
          <v-row>
            <v-col cols="12" sm="12">
              <v-btn
                v-if="generalData.resources.length > 1 && sectionData.OtherAttachments.isAuthorized"
                :disabled="sectionData.OtherAttachments.isSubmitted || sectionData.OtherAttachments.isHidden"
                class="submit-btn ma-2 float-right"
                @click="submit_item(20)"
                small
                depressed
                color="#1867c0"
                auto-id="other submit 6"
                >Submit</v-btn
              >
              <v-switch v-model="otherSwitch6" color="green" class="ma-2 float-right" @change="onSectionToggle(sectionData.OtherAttachments.id)" auto-id="other switch 6"></v-switch>
              <van-button type="default" :disabled="sectionData.OtherAttachments.isHidden" color="#B14C4C" @click="showDefaultDescription('Add Attachment')" auto-id="other add attachment 6">Add Attachment(s)</van-button>
            </v-col>
          </v-row>
          <v-row v-for="(groupAttachment, gaIndex) in groupedAttachments" :key="gaIndex" v-show="groupAttachment.attachments.length > 0">
            <v-col cols="12" sm="12">
              <draggable class="imglist select_img" v-model="groupAttachment.attachments" @change="onImageDragChange(groupAttachment.description)" auto-id="other dragable 6">
                <transition-group tag="ul">
                  <li v-for="(attachment, aIndex) in groupAttachment.attachments" :key="aIndex" class="item" auto-id="other group attachment 6">
                    <div v-if="attachment.loadingInProgress">Loading...</div>
                     <div v-if="!attachment.loadingInProgress" class="holder">
                      <span class="badge-number">{{ aIndex + 1 }}</span>
                      <span class="del_button" @click="deleteImage(attachment.fileUploadId)" auto-id="other delete 6">
                        <icon name="times-circle" class="icon"></icon>
                      </span>
                      <img class="imgItem" :class="{ updateSelected: attachment.updateSelected }" :src="attachment.src" @click="add_img_update(gaIndex, aIndex)" auto-id="other add image 6"/>
                    </div>
                  </li>
                </transition-group>
              </draggable>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field v-model="groupAttachment.description" label="Attachment Label Name" @keyup="changeDescription($event, gaIndex)" auto-id="other attachment label name 6"></v-text-field>
                <v-btn style="width: 150px; color: #000"   @click="applyFileName" auto-id="other apply file name 6">Apply File Name</v-btn>
            </v-col>
            <v-col cols="12" sm="6">
              <van-button type="default" @click="onImageSelection(groupAttachment.description)" auto-id="other mdi-camera 6">
                <v-icon color="rgba(0,0,0,.87)">mdi-camera</v-icon>
              </van-button>
              <van-button type="default" @click="uploadImages(groupAttachment.description)" :disabled="groupAttachment.description == ''" color="#B14C4C" auto-id="other update 6">Update</van-button>
              <van-button type="default" @click="deleteAllImages(groupAttachment)" :disabled="groupAttachment.attachments.length < 1" auto-id="other delete all 6">Delete All</van-button>
            </v-col>
          </v-row>
          <v-row v-show="isDefaultDescription">
            <v-col cols="12" sm="6">
              <div class="d-flex flex-column">
                <v-text-field v-model="defaultDescription" label="Attachment Label Name" auto-id="other attachment label name 6"></v-text-field>
                <v-btn style="width: 150px; color: #000" @click="applyFileName" auto-id="other apply file name 6">Apply File Name</v-btn>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <van-button type="default" @click="onImageSelection(defaultDescription)" auto-id="other mdi-camera 6 2">
                <v-icon color="rgba(0,0,0,.87)">mdi-camera</v-icon>
              </van-button>
              <van-button type="default" @click="uploadImages" :disabled="!isEnable" color="#B14C4C" auto-id="other update 6 2">Update</van-button>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-snackbar class="others_snackbar" v-model="snackbar" :timeout="timeout" :color="color">
      {{ message }}
      <v-btn id="others_snackbar_close" text @click="snackbar = false" auto-id="other close 6">
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="showImageProgress" :persistent="true">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-container v-for="(image, index) in imagesUploadInProgress" :key="index">
            <v-progress-linear :value="image.uploadProgress" color="light-blue" height="25" reactive>
              <template v-slot="{ value }">
                <strong>{{ image.fileName }} {{ Math.ceil(image.uploadProgress) }}%</strong>
              </template>
            </v-progress-linear>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import * as _ from "lodash";
import apiDetails from "../../shared/request/apiDetails";
import {mapState} from 'vuex';

export default {
  name: "Other",
  components: {
    draggable,
  },
  data() {
    return {
      snackbar: false,
      color: "red darken-2",
      message: "",
      timeout: 3000,
      tab1: null,
      //
      otherSwitch1: true,
      otherSwitch2: true,
      otherSwitch3: true,
      otherSwitch4: true,
      otherSwitch5: true,
      otherSwitch6: true,

      //
      otherDialog1: false,
      otherDialog2: false,
      otherDialog3: false,

      otherTab1Header: [
        { text: "No.", align: "left", sortable: false, value: "no" },
        { text: "Component Description", align: "center", sortable: false, value: "componentDescription" },
        { text: "Specification", align: "center", sortable: false, value: "specification" },
        { text: "Actual Observed", align: "center", sortable: false, value: "actualObserved" },
        { text: "Result", align: "center", sortable: false, value: "result" },
        { text: "Remark(s)", align: "center", sortable: false, value: "remarks" },
        { text: "", align: "center", sortable: false, width: "110", value: "menu" },
      ],
      otherTab1List: [],

      otherTab2Header: [
        { text: "No.", align: "left", sortable: false, value: "number" },
        { text: "Serial Number", align: "center", sortable: false, value: "serialNumber" },
        { text: "# of Carton(s)", align: "center", sortable: false, value: "noOfCartons" },
        { text: "", align: "center", sortable: false, value: "menu" },
      ],
      otherTab2List: [],

      otherTab3Header: [
        { text: "No.", align: "left", sortable: false, value: "number" },
        { text: "Serial Number", align: "center", sortable: false, value: "serialNumber" },
        { text: "# of Carton(s)", align: "center", sortable: false, value: "noOfCartons" },
        { text: "", align: "center", sortable: false, value: "menu" },
      ],
      otherTab3List: [],

      SectionsValue: {
        CDF: { id: 13, name: "CDF Listed Component Verification Report", isAuthorized: false },
        SerialNumberSampled: { id: 14, name: "Serial Number Sampled", isAuthorized: false },
        SampleCartonNumber: { id: 15, name: "Sample Carton Number", isAuthorized: false },
        InspectionEnvironment: { id: 18, name: "Inspection Environment", isAuthorized: false },
        DrawnSamples: { id: 19, name: "Drawn Samples", isAuthorized: false },
        OtherAttachments: { id: 20, name: "Other Attachments", isAuthorized: false },
      },

      //
      otherTab1TableComponentDescription: "",
      otherTab1TableSpecification: "",
      otherTab1TableActualObserved: null,
      otherTab1TableResult: null,
      otherTab1TableRemarks: "",
      otherTab1TableId: null,

      //
      otherTab2TableId: null,
      otherTab2TableNumber: "",
      otherTab2TableSerialNumber: "",
      otherTab2TableNoOfCartons: null,

      //
      otherTab3TableId: null,
      otherTab3TableNumber: null,
      otherTab3TableSerialNumber: "",
      otherTab3TableNoOfCartons: null,

      //
      resultSelect: [
        {
          text: "Pass",
          value: true,
        },
        {
          text: "Fail",
          value: false,
        },
      ],
      getResourceId: "",
      isEnableAttachment: true,
      attachments: [],
      groupedDescriptions: [],
      groupedAttachments: [],
      addedImages: [],
      toBeUpdatedImages: [],
      isDefaultDescription: false,
      description: [""],
      otherDescription: "",
      defaultDescription: [],
      sectionId: 20,
      showProgressDialog: false,
      isEnable: false,
      isEnableDefault: false,

      formOtherTab4: {
        id: null,
        workOrderId: null,
        isHidden: true,
        lightingResult: null,
        cleanlinessResult: null,
        humidityResult: null,
        odorResult: null,
        odorDetails: null,
        hygieneResult: null,
        hygieneDetails: null,
        othersSummary: null,
        othersResult: null,
        othersFindings: null,
      },
      formOtherTab5: {
        isHidden: true,
        courier: null,
        awb: null,
        sampleResult: null,
        sampleDescription: null,
      },
      satisfactoryOptions: [
        { text: "Satisfactory", value: "Satisfactory" },
        { text: "Unsatisfactory", value: "Unsatisfactory" },
        { text: "N/A", value: "NA" },
      ],

      fileNamesApplied: false,
      otherAttachmentsTabClicked: false,
      selectedGroupDescription: ''
    };
  },
  mounted() {
    this.otherTab1List = _.orderBy(
      this.generalData.workOrderCDFs.map((x, i) => {
        x.no = 1 + i++;
        x.offline = false;
        return x;
      }),
      ["no"],
      ["asc"]
    );
    this.otherTab2List = _.orderBy(
      this.generalData.workOrderSerialNumbersSampled.map((x, i) => {
        x.offline = false;
        return x;
      }), ["number"], ["asc"]);
    this.otherTab3List = _.orderBy(this.generalData.workOrderSampledCartonNumbers, ["number"], ["asc"]);
    // this.loadImages();
  },
  methods: {
     /**
     * Toggles the switch to make the tab active or inactive
     */
    onSectionToggle(sectionId) {
      const index = this.assignedSections.findIndex((x) => x.sectionId == sectionId);
      if (index == -1) return;

      const section = this.assignedSections[index];
      section.isHidden = !section.isHidden;
      section.result = "";
      section.findings = "";
      this.showProgressDialog = true;
      this.apiFn.UpsertInspectionSummarySections([section]).then((result) => {
        this.assignedSections[index].isHidden = section.isHidden;
        this.showProgressDialog = false;
      });
    },

    go_gallery(name, sectionId) {
      this.$router.push({ name: "uploadPhotoGalleryId", params: { id: this.$route.params.id, name, sectionId }, query: { sectionId, name } });
    },
    save_other(type) {
      switch (type) {
        case "otherTab1List":
          //Make Call to API Then Push
          let cdf = {
            componentDescription: this.otherTab1TableComponentDescription,
            specification: this.otherTab1TableSpecification,
            actualObserved: this.otherTab1TableActualObserved,
            result: this.otherTab1TableResult,
            remarks: this.otherTab1TableRemarks,
            workOrderId: this.$route.params.id,
            sectionId: this.sectionData.CDF.id,
          };

          if (!this.otherTab1TableId) {
            this.utilityFn.getServerData(
              "post",
              this.apiList.createWorkOrderCDFReport,
              cdf,
              res => {
                this.otherTab1List.push({
                  no: this.otherTab1List.length + 1,
                  id: res.data.id,
                  componentDescription: res.data.componentDescription,
                  specification: res.data.specification,
                  actualObserved: res.data.actualObserved,
                  result: res.data.result,
                  remarks: res.data.remarks,
                  offline: false,
                });
                this.otherTab1List = _.orderBy(this.otherTab1List, ["no"], ["asc"]);

                this.updateSectionModifiedDate(this.SectionsValue.CDF.id);
              },
              errFn => {
                if (window.navigator.onLine != true) {
                  
                  let offline_cdf = {...cdf,no:this.otherTab1List.length + 1,
                  no: this.otherTab1List.length + 1,
                  id: new Date().getTime(),offline: true};
                  this.otherTab1List.push( offline_cdf);
                  this.otherTab1List = _.orderBy(this.otherTab1List, ["no"], ["asc"]);

                  // this.message = this.msgList.NoConnection;
                  // this.snackbar = true;

                  this.updateSectionModifiedDate(this.SectionsValue.CDF.id);
                }
              }
            );
          } else {
            let that =  this;
            this.utilityFn.getServerData(
              "put",
              this.apiList.updateWorkOrderCDF + "/" 
              + this.otherTab1TableId,
              cdf,
              res => {
                if (res && res.data && res.data.data) {
                  let getData = res.data.data
                  let index = this.otherTab1List.findIndex((x) => x.id == getData.id);
                  if (index >= 0) {
                    this.otherTab1List.splice(index, 1, {
                      no: index + 1,
                      id: getData.id,
                      componentDescription: getData.componentDescription,
                      specification: getData.specification,
                      actualObserved: getData.actualObserved,
                      result: getData.result,
                      remarks: getData.remarks,
                    });
                  }
                  this.otherTab1List = _.orderBy(this.otherTab1List, ["no"], ["asc"]);

                  this.updateSectionModifiedDate(this.SectionsValue.CDF.id);
                  this.otherTab1TableId = null;
                }
              },
              errFn => {
                if (window.navigator.onLine !== true) {
                  let index = this.otherTab1List.findIndex((x) => x.id == this.otherTab1TableId);
                  if (index >= 0) {
                 
                    let offline_cdf = {...cdf};
                    offline_cdf = {...offline_cdf,no:index+1};
                    this.otherTab1List.splice(index, 1, offline_cdf );
                  }

                  this.updateSectionModifiedDate(this.SectionsValue.CDF.id);
                }
                this.otherTab1TableId = null;
              }
            );
          }
          this.otherTab1List.map((prop, index) => {
            prop.no = index + 1;
            return prop;
          });
         // this.otherTab1TableId = null;
          this.otherTab1TableComponentDescription = "";
          this.otherTab1TableSpecification = "";
          this.otherTab1TableActualObserved = null;
          this.otherTab1TableResult = null;
          this.otherTab1TableRemarks = "";
          this.otherDialog1 = false;

          this.logChanges();
          break;
        case "otherTab2List":
          let sample = {
            number: this.otherTab2TableNumber,
            serialNumber: this.otherTab2TableSerialNumber,
            noOfCartons: this.otherTab2TableNoOfCartons,
            workOrderId: this.$route.params.id,
            sectionId: this.sectionData.SerialNumberSampled.id,
          };
          if (!this.otherTab2TableId) {
            this.utilityFn.getServerData(
              "post",
              this.apiList.createWorkOrderSerialNumberSampled,
              sample,
              (res) => {
                this.otherTab2List.push({
                  id: res.data.id,
                  number: res.data.number,
                  serialNumber: res.data.serialNumber,
                  noOfCartons: res.data.noOfCartons,
                  offline: false,
                });
                this.otherTab2List = _.orderBy(this.otherTab2List, ["number"], ["asc"]);

                this.updateSectionModifiedDate(this.SectionsValue.SerialNumberSampled.id);
              },
              (errFn) => {
                if (window.navigator.onLine != true) {
                 
                  let offline_sample = {...sample,
                  id: new Date().getTime(),offline: true};
                  this.otherTab2List.push(offline_sample);
                
                  this.otherTab2List = _.orderBy(this.otherTab2List, ["number"], ["asc"]);

                  this.updateSectionModifiedDate(this.SectionsValue.SerialNumberSampled.id);
                }
              }
            );
          } else {
            let that = this;
            this.utilityFn.getServerData(
              "put",
              this.apiList.updateWorkOrderSerialNumberSampled + "/" + this.otherTab2TableId,
              sample,
              (res) => {
                if (res && res.data && res.data.data) {
                  let getData = res.data.data
                  let index = this.otherTab2List.findIndex((x) => x.id == getData.id);
                  if (index >= 0) {
                    this.otherTab2List.splice(index, 1, {
                      id: getData.id,
                      number: getData.number,
                      serialNumber: getData.serialNumber,
                      noOfCartons: getData.noOfCartons,
                    });
                  }
                  this.otherTab2List = _.orderBy(this.otherTab2List, ["number"], ["asc"]);

                  this.updateSectionModifiedDate(this.SectionsValue.SerialNumberSampled.id);
                  this.otherTab2TableId = null;
                }
              },
              (errFn) => {
                if (window.navigator.onLine != true) {
                  let index = this.otherTab2List.findIndex((x) => x.id == this.otherTab2TableId);
                  if (index >= 0) {
                    let offline_sample = {...sample};
                    offline_sample = {...offline_sample,no:index+1};
                    this.otherTab2List.splice(index, 1, offline_sample);

                  }
                  this.otherTab2List = _.orderBy(this.otherTab2List, ["number"], ["asc"]);
                  
                  this.updateSectionModifiedDate(this.SectionsValue.SerialNumberSampled.id);
                }
                this.otherTab2TableId = null;
              }
            );
          }
          //this.otherTab2TableId = null;
          this.otherTab2TableNumber = "";
          this.otherTab2TableSerialNumber = "";
          this.otherTab2TableNoOfCartons = null;
          this.otherDialog2 = false;

          this.logChanges();
          break;
        case "otherTab3List":
          let number = {
            number: this.otherTab3TableNumber,
            serialNumber: this.otherTab3TableSerialNumber,
            noOfCartons: this.otherTab3TableNoOfCartons,
            workOrderId: this.$route.params.id,
            sectionId: this.sectionData.SampleCartonNumber.id,
          };
          if (!this.otherTab3TableId) {
            this.utilityFn.getServerData(
              "post",
              this.apiList.createWorkOrderSampledCartonNumber,
              number,
              (res) => {
                this.otherTab3List.push({
                  id: res.data.id,
                  number: res.data.number,
                  serialNumber: res.data.serialNumber,
                  noOfCartons: res.data.noOfCartons,
                  offline: false,
                });
                this.otherTab3List = _.orderBy(this.otherTab3List, ["number"], ["asc"]);

                this.updateSectionModifiedDate(this.SectionsValue.SampleCartonNumber.id);
              },
              (errFn) => {
                if (window.navigator.onLine != true) {
                  
                  let offline_number = {...number, id: new Date().getTime(),
                  offline: true};
                  this.otherTab3List.push(offline_number);
                 
                  this.otherTab3List = _.orderBy(this.otherTab3List, ["number"], ["asc"]);
                  this.updateSectionModifiedDate(this.SectionsValue.SampleCartonNumber.id);
                }
              }
            );
          } else {
            this.utilityFn.getServerData(
              "put",
              this.apiList.updateWorkOrderSampledCartonNumber + "/" + this.otherTab3TableId,
              number,
              (res) => {
                let index = this.otherTab3List.findIndex((x) => x.id == res.data.id);
                if (index >= 0) {
                  this.otherTab3List.splice(index, 1, {
                    id: res.data.id,
                    number: res.data.number,
                    serialNumber: res.data.serialNumber,
                    noOfCartons: res.data.noOfCartons,
                  });
                }
                this.otherTab3List = _.orderBy(this.otherTab3List, ["number"], ["asc"]);

                this.updateSectionModifiedDate(this.SectionsValue.SampleCartonNumber.id);
                this.otherTab3TableId = null;
              },
              (errFn) => {
                if (window.navigator.onLine != true) {
                  let index = this.otherTab3List.findIndex((x) => x.id == this.otherTab3TableId);
                  if (index >= 0) {
                    let offline_number = {...number};
                    offline_numbe = {...offline_numbe,no:index+1};
                    this.otherTab3List.splice(index, 1, offline_number);
                  }
                  this.otherTab3List = _.orderBy(this.otherTab3List, ["number"], ["asc"]);
                 
                  this.updateSectionModifiedDate(this.SectionsValue.SampleCartonNumber.id);
                }
                this.otherTab3TableId = null;
              }
            );
          }
          //this.otherTab3TableId = null;
          this.otherTab3TableNumber = "";
          this.otherTab3TableSerialNumber = "";
          this.otherTab3TableNoOfCartons = null;
          this.otherDialog3 = false;

          this.logChanges();
          break;
      }
    },
    select_other(type, item) {
      switch (type) {
        case "otherTab1List":
          this.otherTab1TableId = item.id;
          this.otherTab1TableComponentDescription = item.componentDescription;
          this.otherTab1TableSpecification = item.specification;
          this.otherTab1TableActualObserved = item.actualObserved;
          this.otherTab1TableResult = item.result;
          this.otherTab1TableRemarks = item.remarks;
          this.otherDialog1 = true;
          break;
        case "otherTab2List":
          this.otherTab2TableId = item.id;
          this.otherTab2TableNumber = item.number;
          this.otherTab2TableSerialNumber = item.serialNumber;
          this.otherTab2TableNoOfCartons = item.noOfCartons;
          this.otherDialog2 = true;
          break;
        case "otherTab3List":
          this.otherTab3TableId = item.id;
          this.otherTab3TableNumber = item.number;
          this.otherTab3TableSerialNumber = item.serialNumber;
          this.otherTab3TableNoOfCartons = item.noOfCartons;
          this.otherDialog3 = true;
          break;
      }
    },
    close_other(type) {
      switch (type) {
        case "otherTab1List":
          this.otherDialog1 = false;
          this.otherTab1TableId = null;
          this.otherTab1TableComponentDescription = "";
          this.otherTab1TableSpecification = "";
          this.otherTab1TableActualObserved = null;
          this.otherTab1TableResult = null;
          this.otherTab1TableRemarks = "";
          break;
        case "otherTab2List":
          this.otherTab2TableId = null;
          this.otherTab2TableNumber = "";
          this.otherTab2TableSerialNumber = "";
          this.otherTab2TableNoOfCartons = null;
          this.otherDialog2 = false;
          break;
        case "otherTab3List":
          this.otherTab3TableId = null;
          this.otherTab3TableNumber = "";
          this.otherTab3TableSerialNumber = "";
          this.otherTab3TableNoOfCartons = null;
          this.otherDialog3 = false;
          break;
      }
    },
    other_table_del(item, array) {
      switch (array) {
        case "otherTab1List":
          let tab1Index = this.otherTab1List.indexOf(item);
          if (confirm("Are you sure you want to delete this item?")) {
            this.apiFn
              .deleteWorkOrderCDF(item.id)
              .then(
                (result) => {
                  this.otherTab1List.splice(tab1Index, 1);
                  this.updateSectionModifiedDate(this.SectionsValue.CDF.id);
                },
                (errFn) => {
                  if (window.navigator.onLine != true) {
                    this.otherTab1List.splice(tab1Index, 1);
                    
                    this.updateSectionModifiedDate(this.SectionsValue.CDF.id);
                  }
                }
              )
              .catch((error) => {
                console.log("deleteWorkOrderCDF error", error);
              });
          }
          break;
        case "otherTab2List":
          let tab2Index = this.otherTab2List.indexOf(item);
          if (confirm("Are you sure you want to delete this item?")) {
            this.apiFn
              .deleteWorkOrderSerialNumberSampled(item.id)
              .then(
                (result) => {
                  this.otherTab2List.splice(tab2Index, 1);

                  this.updateSectionModifiedDate(this.SectionsValue.SerialNumberSampled.id);
                },
                (errFn) => {
                  if (window.navigator.onLine != true) {
                    this.otherTab2List.splice(tab2Index, 1);
                   
                    this.updateSectionModifiedDate(this.SectionsValue.SerialNumberSampled.id);
                  }
                }
              )
              .catch((error) => {
                console.log("deleteWorkOrderCDF error", error);
              });
          }

          break;
        case "otherTab3List":
          let index = this.otherTab3List.indexOf(item);
          if (confirm("Are you sure you want to delete this item?")) {
            this.apiFn
              .DeleteWorkOrderSampledCartonNumber(item.id)
              .then(
                (result) => {
                  this.otherTab3List.splice(index, 1);

                  this.updateSectionModifiedDate(this.SectionsValue.SampleCartonNumber.id);
                },
                (errFn) => {
                  if (window.navigator.onLine != true) {
                    this.otherTab3List.splice(index, 1);
                   
                    this.updateSectionModifiedDate(this.SectionsValue.SampleCartonNumber.id);
                  }
                }
              )
              .catch((error) => {
                console.log("deleteWorkOrderCDF error", error);
              });
          }
          break;
      }
    },
    submit_item(sectionId) {
      const index = this.workOrderDetails.workOrderInspectionSections.findIndex((x) => x.sectionId === sectionId);
      if (index !== -1) {
        const section = {
          sectionId: sectionId,
          workOrderId: this.$route.params.id
        };
        this.utilityFn.getServerData("post", this.apiList.submitInspectionSection, section, (res) => {
          this.workOrderDetails.workOrderInspectionSections[index].submittedDate = new Date();
          this.$store.dispatch("inspection/setWorkOrderDetails", this.workOrderDetails);
        });
      }
    },

    loadSections() {
      this.otherSwitch1 = this.generalData.workOrderInspectionSections.findIndex((x) => x.resourceId && x.resourceId?.toLowerCase() == this.utilityFn.getResourceId().toLowerCase() && x.sectionId == this.SectionsValue.CDF.id && !x.isHidden) !== -1;
      this.otherSwitch2 =
        this.generalData.workOrderInspectionSections.findIndex((x) => x.resourceId && x.resourceId?.toLowerCase() == this.utilityFn.getResourceId().toLowerCase() && x.sectionId == this.SectionsValue.SerialNumberSampled.id && !x.isHidden) !== -1;
      this.otherSwitch3 =
        this.generalData.workOrderInspectionSections.findIndex((x) => x.resourceId && x.resourceId?.toLowerCase() == this.utilityFn.getResourceId().toLowerCase() && x.sectionId == this.SectionsValue.SampleCartonNumber.id && !x.isHidden) !== -1;
      this.otherSwitch4 =
        this.generalData.workOrderInspectionSections.findIndex((x) => x.resourceId && x.resourceId?.toLowerCase() == this.utilityFn.getResourceId().toLowerCase() && x.sectionId == this.SectionsValue.InspectionEnvironment.id && !x.isHidden) !== -1;
      this.otherSwitch5 =
        this.generalData.workOrderInspectionSections.findIndex((x) => x.resourceId && x.resourceId?.toLowerCase() == this.utilityFn.getResourceId().toLowerCase() && x.sectionId == this.SectionsValue.DrawnSamples.id && !x.isHidden) !== -1;
      this.otherSwitch6 =
        this.generalData.workOrderInspectionSections.findIndex((x) => x.resourceId && x.resourceId?.toLowerCase() == this.utilityFn.getResourceId().toLowerCase() && x.sectionId == this.SectionsValue.OtherAttachments.id && !x.isHidden) !== -1;
    },

    logChanges(enable = true) {
      window.sessionStorage.setItem("EditDataLogging", enable);
    },

    async loadImages() {
      if (this.otherAttachmentsTabClicked) return

      this.otherAttachmentsTabClicked = true;
      //call the new endpoint to get only attachments

      if (this.forProductUpload) {
        this.utilityFn.getServerData("get", this.apiList.getProductAttachment + "?workOrderId=" + this.$route.params.id + "&productId=" + this.$route.query.workOrderProductId.toLowerCase() + "&sectionId=20", {}, (res) => {
          if (res && res.data) {
            if(res.data.hasOwnProperty('data')) {
              this.attachments = res.data.data;
              this.groupTheAttachments();
            }
          } else {
            throw("Getting attachment failed");
          }
        });
      } else {
        const res = await this.utilityFn.getServerDataAsync("get", this.apiList.getWorkOrderAttachment + "?workOrderId=" + this.$route.params.id + "&sectionId=" + this.sectionId, {});
        if (res && res.data) {
          this.attachments = res.data.map((attachment) => {
              attachment.src = null;
              attachment.loadingInProgress = true;
              return attachment;
            });
            this.attachments = _.orderBy(this.attachments, ["order"], ["asc"]);

            this.attachments.forEach(v => {
              this.loadingImg(v)
            })

            this.groupTheAttachments();
        } 
        else{
          throw("Getting attachment failed");
        }
      }
    },

    groupTheAttachments(keepPendingUploadImages = false) {
      // group attachments by description
      if (!keepPendingUploadImages) {
        this.addedImages = [];
      }
      this.groupedAttachments = [];
      this.groupedDescriptions = _.uniq(_.map(this.attachments, "description"));
      //
      this.groupedDescriptions.forEach((description) => {
        const filteredAttachments = this.attachments.filter((x) => x.description === description);
        this.groupedAttachments.push({
          description,
          attachments: _.orderBy(filteredAttachments, ["order"], ["asc"]),
        });
      });
    },

    // when updating the order via the draggable panel
    // always update the original list so the grouped attachments will order the inner attachments properly
    onImageDragChange(description) {
      this.groupedAttachments.forEach((g, gIndex) => {
        const base = (gIndex + 1) * 100;
        if (g.description === description) {
          g.attachments.forEach((a, aIndex) => {
            a.order = base + aIndex + 1;

            // update the order from the main attachment list
            const i = this.attachments.findIndex(x => x.fileUploadId === a.fileUploadId);
            if (i !== -1) {
              this.attachments[i].order = a.order;
            }
          });
        }
      });
    },

    async loadingImg(item) {
      this.utilityFn.getImage(item.fileUploadId, (res) => {
        item.src = "data:" + res.fileType + ";base64," + res.data.data;
        item.loadingInProgress = false;

        const index = this.attachments.findIndex(x => x.fileUploadId == item.fileUploadId);
        if (index !== -1) {
          this.attachments[index] = item;
        }
      });
    },

    onImageSelection(description) {
      var inputObj = document.createElement("input");
      inputObj.setAttribute("accept", "image/*");
      inputObj.setAttribute("multiple", "multiple");
      inputObj.setAttribute("id", "_ef");
      inputObj.setAttribute("type", "file");
      inputObj.setAttribute("style", "visibility:hidden");
      document.body.appendChild(inputObj);
      inputObj.click();
      inputObj.onchange = (res) => {
        this.isUpdate = false;
        // this.clearUpdateSelection();

        const loadImage = async () => {
          for (let i = 0; i < inputObj.files.length; i++) {
            let file = inputObj.files[i];
            var sizeInfo = this.utilityFn.formatBytes(file.size)
            if(sizeInfo.sizeKB > 500){
              this.$store.dispatch("navigation/setSnackbar",{
                color :'error',
                text:`Each upload only accepts image size less than 500KB. Please resize/compact the image with size greater than 500KB. Filename: ${file.name}.`});
            }
            else{
              const reader = new FileReader();
              const tempId = "temp_" + this.attachments.filter(x => x.fileUploadId?.includes('temp_')).length;

              const attachment = {
                file,
                uploadProgress: 0,
                fileUploadId: tempId, // add a marker/flag to determine if this image is not yet in the database
                src: await returnBase64(file),
                isNew: true,
                description: description,
                fileName: file.name.replace(/\.[^/.]+$/, "")
              };
              this.attachments.push(attachment);
              this.addedImages.push({
                file,
                uploadProgress: 0,
                id: tempId,
                src: null,
                description: description,
                isModified: false,
                isUploadButtonEnable: true,
              });
            }
            
          }
          this.groupTheAttachments();
          this.showDefaultDescription("");
        };
        const returnBase64 = (file) => {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              var reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            }, 500);
          });
        };
        loadImage().then(() => {
          // this.isEnable =  this.isAddPhotoEnabled()
        });

        this.logChanges();
        
        inputObj.remove();
      };
    },

    // will generate a file object
    getImage(index) {
      return URL.createObjectURL(this.imageList[index]);
    },

    uploadImages(groupDescription) {
      this.selectedGroupDescription = groupDescription;

      // 弹出上传进度
      if (window.navigator.onLine) {
        this.showProgressDialog = true;
      } else {
        alert("Photo saved offline, will auto update when device is online");
      }
      //
      const _addedImages = this.attachments.filter(x => x.fileUploadId?.includes('temp_'));
      // const _addedImages = this.addedImages.slice();
      for (let i = 0; i < _addedImages.length; i++) {
        const addedImage = { ..._addedImages[i] };

        let formData = new FormData();
        formData.append("image", addedImage.file);
        formData.append("screen", this.sectionId);
        formData.append("order", (this.groupedAttachments.length + 1) * 100 + (i + 1));

        let url = this.apiList.uploadWorkOrderImage;
        if (this.forProductUpload) {
          formData.append("workOrderProductId", this.workOrderProduct.id);
          formData.append("description", _addedImages[i].description);
          url = this.apiList.uploadWorkOrderProductImage;
        } else {
          formData.append("workOrderId", this.$route.params.id);
          formData.append("description", _addedImages[i].description);
        }

        var data = { formData: formData, base64: addedImage.src };
        if (!addedImage.uploadInProgress && addedImage.description == groupDescription) {
          const index = this.attachments.findIndex((x) => x.fileUploadId === addedImage.fileUploadId);
          this.attachments[index] = { ...this.attachments[index], uploadInProgress: true };

          this.utilityFn.uploadImg(
            url,
            data,
            (progress) => {
              const index = this.attachments.findIndex((x) => x.fileUploadId === addedImage.fileUploadId);
              this.attachments[index] = { ...this.attachments[index], uploadProgress: progress };
              this.attachments = this.attachments.slice();
            },
            (result) => {
              const index = this.attachments.findIndex((x) => x.fileUploadId === addedImage.fileUploadId);
              this.attachments[index] = { ...this.attachments[index], fileUploadId: result?.data?.fileUploadId, uploadInProgress: false };
              this.attachments = this.attachments.slice();
              this.checkUploadStatus();
            },
            (errFn) => {
              this.attachments = this.attachments.filter((x) => x.fileUploadId !== addedImage.fileUploadId);
            }
          );
        }
      }

      if (_addedImages.length === 0) {
        this.updateImageOrderAndDescription();
      }
      
      this.logChanges();
    },

    deleteImage(id) {
      this.attachments = this.attachments.filter(x => x.fileUploadId != id);
      this.groupedAttachments.forEach(ga => {
        ga.attachments = ga.attachments.filter(x => x.fileUploadId != id);
      })
      
      if (id.indexOf("temp_") === -1) {
        this.apiFn.deleteImage(id).then((result) => {},(errFn) => {
          if(window.navigator.onLine != true) {
             this.message = this.msgList.NoConnection;
             this.snackbar = true;
          }
        });
        this.logChanges();
      }
    },

    deleteAllImages(groupAttachment) {
      if(confirm("Are you sure to delete photo(s)?")) {
        let attachmentsToDelete = groupAttachment.attachments.map(x => x.fileUploadId);
        _.forEach(attachmentsToDelete, (x) => {        
          this.deleteImage(x);
        });
      }
    },

    //
    loadImage(groupedAttachmentIndex, attachmentIndex, imageId) {
      this.groupedAttachments[groupedAttachmentIndex].attachments[attachmentIndex].src = "loading";
      this.groupedAttachments[groupedAttachmentIndex].attachments = [...this.groupedAttachments[groupedAttachmentIndex].attachments];

      if (imageId != "temp_0") {
        const getImage = async () => {
          this.apiFn.getImage(imageId).then((result) => {
            let src = "data:" + result.fileType + ";base64," + result.data;
            this.groupedAttachments[groupedAttachmentIndex].attachments[attachmentIndex].src = src;
            this.groupedAttachments[groupedAttachmentIndex].attachments = [...this.groupedAttachments[groupedAttachmentIndex].attachments];
          });
        };

        getImage().then(() => {});
      }
    },

    loadTempImage(index, file) {
      this.getBase64(file).then((base64) => {
        this.addedImages[index].src = base64;
      });
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    checkUploadStatus() {
      this.showProgressDialog = this.attachments.filter(x => x.fileUploadId?.includes('temp_')).length !== 0;
      if (!this.showProgressDialog) {
        this.description = "";
        this.otherDescription = "";
        this.groupTheAttachments();
        this.updateImageOrderAndDescription();
        this.updateSectionModifiedDate(this.SectionsValue.OtherAttachments.id);
      }
    },

    // check if the image being displayed is a newly added image
    isImageTemporary(attachment) {
      return attachment.fileUploadId.indexOf("temp_") !== -1;
    },

    add_img_update(gaIndex, aIndex) {
      this.isUpdate = true;
      const attachment = this.groupedAttachments[gaIndex].attachments[aIndex];
      const index = this.toBeUpdatedImages.findIndex((x) => x.fileUploadId === attachment.fileUploadId);

      if (index === -1) {
        this.toBeUpdatedImages.push(attachment);
        this.groupedAttachments[gaIndex].attachments[aIndex].updateSelected = true;
      } else {
        this.toBeUpdatedImages.splice(index, 1);
        this.groupedAttachments[gaIndex].attachments[aIndex].updateSelected = false;
      }

      this.groupedAttachments[gaIndex].attachments = [...this.groupedAttachments[gaIndex].attachments];
    },

    // clearUpdateSelection() {
    //   this.toBeUpdatedImages.forEach((img) => {
    //     const index = this.attachments.findIndex((a) => a.fileUploadId === img.fileUploadId);
    //     this.attachments[index].updateSelected = false;
    //   });
    //   this.toBeUpdatedImages = [];
    // },

    toggleSwitch(event) {
      this.isEnableAttachment = event;
    },

    changeDescription(event, gaIndex) {
      let valueToValidate = this.groupedDescriptions[gaIndex];
      this.attachments.forEach((e) => {
        if (e.description == valueToValidate) {
          e.description = event.target.value;
          e.isModified = true;
        }
      });

      this.groupedDescriptions[gaIndex] = event.target.value;
    },

    showDefaultDescription(getValue) {
      if (!this.isDefaultDescription && getValue == "Add Attachment") {
        this.isDefaultDescription = true;
        this.defaultDescription = [""];
      } else if (this.isDefaultDescription && getValue == "") {
        this.isDefaultDescription = false;
      }
    },

    
    applyFileName() {
      const pendingUploadImages = this.attachments.filter(x => x.fileUploadId?.includes('temp_'));
      const separator = '-';
      pendingUploadImages.forEach(img => {
        const index = img.fileName.indexOf(separator);
        if (index !== -1) {
          img.description = img.fileName.substring(index + separator.length, img.fileName.length).trim();
        } else {
          img.description = img.fileName;
        }
      });
      this.groupTheAttachments(true);
      this.fileNameApplied = true;
      this.showDefaultDescription("");
    },


    updateImageOrderAndDescription() {
      const imagesToUpdate = [];
      this.groupedAttachments.forEach((g, gIndex) => {
        const base = (gIndex + 1) * 100;
        g.attachments.forEach((a, aIndex) => {
          a.order = base + aIndex + 1;
          a.isIncludeInCFR = true;
          a.sectionId = this.sectionId;

          if (a.description == this.selectedGroupDescription) {
            imagesToUpdate.push({...a, src: undefined, sectionId: this.sectionId});
          }
        });
      });

      if (imagesToUpdate.length > 0) {
        this.utilityFn.getServerData("put", this.apiList.updateWorkOrderImages, imagesToUpdate, (result) => {});
      }

    },


    /**
     * Upsert WorkOrderInspectionEnvironment
     */
    upsertInspectionEnvironment() {
      if (
        this.formOtherTab4.lightingResult == 1 &&
        this.formOtherTab4.cleanlinessResult == 1 &&
        this.formOtherTab4.humidityResult == 1 &&
        this.formOtherTab4.odorResult == 6 &&
        this.formOtherTab4.hygieneResult == 6 &&
        this.formOtherTab4.othersResult == "Conformed"
      ) {
        this.formOtherTab4.result = "Satisfactory";
      } else if (
        this.formOtherTab4.lightingResult == 0 &&
        this.formOtherTab4.cleanlinessResult == 0 &&
        this.formOtherTab4.humidityResult == 0 &&
        this.formOtherTab4.odorResult == 0 &&
        this.formOtherTab4.hygieneResult == 0 &&
        this.formOtherTab4.othersResult == "N/A"
      ) {
        this.formOtherTab4.result = "NA";
      } else if (
        this.formOtherTab4.lightingResult == 2 ||
        this.formOtherTab4.cleanlinessResult == 2 ||
        this.formOtherTab4.humidityResult == 3 ||
        this.formOtherTab4.humidityResult == 4 ||
        this.formOtherTab4.odorResult == 5 ||
        this.formOtherTab4.hygieneResult == 5 ||
        this.formOtherTab4.othersResult == "Non-Conformed"
      ) {
        this.formOtherTab4.result = "Unsatisfactory";
      }
      this.formOtherTab4.workOrderId = this.generalData.workOrderId;
      this.utilityFn.getServerData("post", this.apiList.upsertWorkOrderInspectionEnvironment, this.formOtherTab4, (res) => {
        this.updateSectionModifiedDate(this.SectionsValue.InspectionEnvironment.id);
      },(errFn) => {
          if(window.navigator.onLine != true){
             this.message = this.msgList.NoConnection;
             this.snackbar = true;
          }

        });
    },
    /**
     * Upsert WorkOrderDrawnSample
     */
    upsertDrawnSample() {
      this.formOtherTab5.workOrderId = this.generalData.workOrderId;
      this.utilityFn.getServerData("post", this.apiList.upsertWorkOrderDrawnSample, this.formOtherTab5, (res) => {
        this.updateSectionModifiedDate(this.SectionsValue.DrawnSamples.id);
      },(errFn) => {
          if(window.navigator.onLine != true){
             this.message = this.msgList.NoConnection;
             this.snackbar = true;
          }
        });
    },

    isSectionAuthorized(sectionId) {
      if (this.workOrderDetails.resources.length === 1) return true;

      const section = this.assignedSections.find((x) => x.sectionId === sectionId);
      return !!section;
    },

    isSectionEnabled(sectionId) {
      if (this.workOrderDetails.resources.length === 1) return true;

      const section = this.assignedSections.find((x) => x.sectionId === sectionId && !x.submittedDate);
      return !!section;
    },

    isSectionSubmitted(sectionId) {
      const section = this.workOrderDetails.workOrderInspectionSections.find((x) => x.sectionId === sectionId);
      return section && !!section.submittedDate;
    },

    isSectionHidden(sectionId) {
      const section = this.workOrderDetails.workOrderInspectionSections.find((x) => x.sectionId === sectionId);
      return section && !!section.isHidden;
    },
    clickOtherAttachment() {
      // this.groupedAttachments = [];
      this.loadImages();
    },

    updateSectionModifiedDate(sectionId) {
      this.utilityFn.getServerData("post", this.apiList.updateWorkOrderDataCaptureModifiedDate + "/" + this.generalData.workOrderId + "?sectionId=" + sectionId, 
      {},
       (res) => {
       
        },
        (errFn) => {
          if(window.navigator.onLine != true){
             this.message = this.msgList.NoConnection;
             this.snackbar = true;
          }
        });
    },
  },
  computed: {
    ...mapState('inspection', {
        workOrderDetails: (state) => state.workOrderDetails,
    }),
    showDescription() {
      return this.sectionId === 1 || this.sectionId === 2 || this.sectionId === 3 || this.sectionId === 4 || this.sectionId === 5 || this.sectionId === 6;
    },
    showOtherDescription() {
      return this.description != "Others";
    },
    finalDescription() {
      return this.description != "Others" ? this.otherDescription : this.description;
    },

    isUpdatePhotoEnabled() {
      return this.finalDescription && this.toBeUpdatedImages.length > 0 && this.isUpdate;
    },

    assignedSections() {
      return this.workOrderDetails.workOrderInspectionSections.filter((x) => x.resourceId?.toLowerCase() === this.utilityFn.getResourceId().toLowerCase());
    },

    sectionData() {
      const sectionObject = Object.assign({}, this.SectionsValue);
      Object.keys(sectionObject).forEach((key) => {
        sectionObject[key].isAuthorized = this.isSectionAuthorized(sectionObject[key].id);
        sectionObject[key].isEnabled = this.isSectionEnabled(sectionObject[key].id);
        sectionObject[key].isSubmitted = this.isSectionSubmitted(sectionObject[key].id);
        sectionObject[key].isHidden = this.isSectionHidden(sectionObject[key].id);
      });
      return sectionObject;
    },

    imagesUploadInProgress() {
      return this.attachments.filter(x => x.uploadInProgress && x.description == this.selectedGroupDescription) || [];      
    },

    showImageProgress() {
      return this.imagesUploadInProgress.length > 0;
    }
  },
  watch: {
    generalData: {
      immediate: true,
      handler(newVal, oldVal) {
        if (!oldVal && newVal) {
          this.loadSections();
        }
        if (!oldVal && newVal.workOrderInspectionEnvironment && newVal.workOrderInspectionEnvironment.id) {
          this.formOtherTab4.id = newVal.workOrderInspectionEnvironment.id;
          this.formOtherTab4.workOrderId = newVal.workOrderInspectionEnvironment.workOrderId;
          this.formOtherTab4.isHidden = newVal.workOrderInspectionEnvironment.isHidden;
          this.formOtherTab4.lightingResult = newVal.workOrderInspectionEnvironment.lightingResult;
          this.formOtherTab4.cleanlinessResult = newVal.workOrderInspectionEnvironment.cleanlinessResult;
          this.formOtherTab4.humidityResult = newVal.workOrderInspectionEnvironment.humidityResult;
          this.formOtherTab4.odorResult = newVal.workOrderInspectionEnvironment.odorResult;
          this.formOtherTab4.odorDetails = newVal.workOrderInspectionEnvironment.odorDetails;
          this.formOtherTab4.hygieneResult = newVal.workOrderInspectionEnvironment.hygieneResult;
          this.formOtherTab4.hygieneDetails = newVal.workOrderInspectionEnvironment.hygieneDetails;
          this.formOtherTab4.othersSummary = newVal.workOrderInspectionEnvironment.othersSummary;
          this.formOtherTab4.othersResult = newVal.workOrderInspectionEnvironment.othersResult;
          this.formOtherTab4.othersFindings = newVal.workOrderInspectionEnvironment.othersFindings;
          this.formOtherTab4.result = newVal.workOrderInspectionEnvironment.result;
        }

        if (!oldVal && newVal.workOrderDrawnSample && newVal.workOrderDrawnSample.id) {
          this.formOtherTab5.id = newVal.workOrderDrawnSample.id;
          this.formOtherTab5.workOrderId = newVal.workOrderDrawnSample.workOrderId;
          this.formOtherTab5.courier = newVal.workOrderDrawnSample.courier;
          this.formOtherTab5.awb = newVal.workOrderDrawnSample.awb;
          this.formOtherTab5.sampleResult = newVal.workOrderDrawnSample.sampleResult;
          this.formOtherTab5.sampleDescription = newVal.workOrderDrawnSample.sampleDescription;
        }
      },
    },
  },
  props: ["generalData"],
};
</script>

<style lang="stylus" scoped>
.tab_item
    padding 25px 15px
    overflow hidden
    position relative
.submit-btn
    color: #fff !important

ul
    padding 0
.row
    background: #fff
    margin: 15px

.item
    display: inline-block
    width: 10%;
    margin: 1.14%;

.holder
    position: relative

.imglist .holder .imgItem
    width: 100%
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.3)


.imgItem.updateSelected
    border: 2px solid #c02032

.badge-number
    position: absolute
    bottom: 0
    right: 0
    padding: 8px
    background-color: #fff
    box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.3)
    border-radius: 100px
    font-weight: bold
    font-size: 10px

.public_img, .select_img
    text-align: left
    // border 1px #ccc solid
    background: #ffffff
    padding: 10px 0

.public_img .imgItem
    width: 12%;
    margin: 1.14%;

.del_button
    position: absolute
    right: -3px;
    top: -5px

.v-btn
    color: #fff;

.others_snackbar
    z-index: 10;
    position: absolute;
    bottom: 50px;
.v-snack__wrapper 
    align-items: center;
    border-color: currentColor!important;
    display: flex;
    margin: 8px;
    max-width: 672px;
    min-height: 48px;
    min-width: 344px;
    padding: 0;
    pointer-events: auto;
    position: absolute;
    transition-duration: .15s;
    transition-property: opacity,transform;
    transition-timing-function: cubic-bezier(0,0,.2,1);
    bottom: 50px;
</style>
