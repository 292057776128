<template>
  <div>
    <div class="OTPAuthentication">
      <v-row class="formDate">
        <v-col cols="12" sm="12" md="12" v-show="!OtpSection">
          <v-select v-bind:items="FactoryResourceList" item-text="name" item-value="email" v-model="selected" label="- Select Factory Resource -" single-line bottom v-on:change="selectedResource($event)"></v-select>
          <v-btn class="submit" dark color="#B14C4C" @click="GetOTP" height="35" width="160">Generate OTP</v-btn>
        </v-col>
      </v-row>
      <v-row class="formDate">
        <div class="otpSection">
          <v-col cols="12" sm="12" md="12" v-show="OtpSection">
            <p>
              A one-time password has been sent to {{ email }},<br />
              valid for 10 mins
            </p>
            <p>Enter OTP</p>
            <div class="otpSection">
              <vue-otp-2 length="4" join-character="-" @onChange="handleOnChange" @onComplete="handleOnComplete" />
            </div>
            <br />
            <p @click="GetOTP" style="text-align:right; font-size:15px"><a>Re-Send OTP</a></p>
            <p v-show="InvalidOTP" style="color:red">The OTP you've entered is incorrect. Please try again.</p>
            <div style="margin:0; padding:0">
              <v-col class="text-right" cols="12" sm="12" md="12">
                <v-btn class="submit" dark color="#B14C4C" @click="ValidateOTP" height="35" width="180">Verify & Proceed</v-btn>
                <v-btn class="submit" dark color="#B14C4C" @click="Cancel" height="35" width="180">Cancel</v-btn>
              </v-col>
            </div>
          </v-col>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import apiDetails from "../../shared/request/apiDetails";
import * as _ from "lodash";
import VueOtp2 from "vue-otp-2";

Vue.use(VueOtp2);

function setState(store) {
  store.dispatch("navigation/setAppHeader", {
    show: true,
    title: "AIMS",
    showTitle: true,
    showMenu: false,
    showBack: false,
    showLogo: false,
    showSearch_job: false,
    showSearch_work: false,
    showCalendar: false,
    onlineDownload: true,
    showResource: false,
  });
}

export default {
  name: "OTPAuthentication",
  data() {
    return {
      FactoryResourceList: [],
      OtpSection: true,
      email: "",
      factoryResource: "",
      refNo: "",
      code: "",
      selected: "",
      InvalidOTP: false,
    };
  },
  computed: {
    environment: {
      get() {
        return process.env.VUE_APP_HOST_ENVIRONMENT;
      },
      set() {

      }
    }
  },
  mounted() {
    setState(this.$store);

    this.FactoryResourceList.push({ name: localStorage.getItem("resourceName"), email: localStorage.getItem("username") });
    //get otp send email to users
    this.GetOTP();
  },
  methods: {
    Cancel() {
      this.$router.push({ name: "login" });
    },
    selectedResource(value) {
      this.email = value;
      const item = this.FactoryResourceList.find((x) => x.email === this.email);
      this.factoryResource = item.name;
    },
    GetOTP() {
      this.OtpSection = true;
      this.email = localStorage.getItem("username");
      this.factoryResource = localStorage.getItem("resourceName");
      this.apiFn.GetOTP(this.email, this.factoryResource).then((result) => {
         this.refNo = result.referenceNo;
      });
    },
    ValidateOTP() {
      
      var val = "";
      for (let index = 0; index < this.code.length; index++) {
        val = val + this.code[index];
      }
      
      if(this.environment == 'qa') {
        if (val == "5555")
        {
            localStorage.setItem("isOTPValid", "true");
            localStorage.setItem("isOTPNeeded", "false");
            localStorage.setItem("authDataRequest", false);
            this.$router.push({ name: "index" });
        }
        else
          this.InvalidOTP = true;
      }
      else {
        this.apiFn.ValidateOTP(val, this.refNo, this.email).then((result) => {
          if (result) {
            localStorage.setItem("isOTPValid", "true");
            localStorage.setItem("isOTPNeeded", "false");
            localStorage.setItem("authDataRequest", false);
            this.$router.push({ name: "index" });
          } else {
            this.InvalidOTP = true;
          }
        });
      }      
    },
    handleOnComplete(value) {
      console.log("OTP completed: ", value);
    },
    handleOnChange(value) {
      console.log("OTP changed: ", value);
      this.code = value;
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
  },
};
</script>

<style lang="stylus" scoped>
.OTPAuthentication
    background #fff
    padding-top 10%
    padding-left 40%
    padding-right 40%

.title
    font-size 20px !important;
    width 600px;
    margin auto;
    text-align  justify ;
    line-height 30px;
    font-weight 100;
    color #fff;

.submit
    display block
    margin 10% auto 0
    font-size 18px

.contentTitle
    font-size 24px !important;
    line-height 70px;

.otpSection p{
    text-align : center;
}

.vue-otp-2 {
    display: flex;
    justify-content: space-between;

    div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        input {
        max-width: 50px !important;
        width:100%;
        padding: 10px 8px;
        font-size: 20px;
        border-radius: 3px;
        border: 1px solid #cecece;
        text-align: center;
        }

        span {
        display: block;
        flex: 1;
        text-align: center;
        }
    }
}
</style>
