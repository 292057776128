<template>
  <div name="Sampling">
    <div style="position: relative;">
      <div
        :style="sectionData.isSectionSubmitted ? 
        'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop' : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'"
      ></div>

      <v-row>
        <v-col v-if="check || showButton" cols="12 " sm="12" md="12" style="text-align:right;">
          <v-btn v-if="showButton && sectionData.isAuthorized" :disabled="sectionData.isSectionSubmitted" class="submit-btn" @click="submit_item" small depressed color="#1867c0" auto-id="sampling submit">Submit</v-btn>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field hide-details :value="generalData.aqlLevels" label="Inspection Level" readonly></v-text-field>
        </v-col>

        <v-col cols="6" sm="6" md="6">
          <v-text-field hide-details :value="generalData.inspectionLevel" label="Sampling Plan" readonly></v-text-field>
        </v-col>

        <v-col cols="2" sm="2" md="2">
          <v-text-field hide-details :value="generalData.aqlCritical" label="Critical AQL" readonly></v-text-field>
        </v-col>

        <v-col cols="2" sm="2" md="2">
          <v-text-field hide-details :value="generalData.aqlMajor" label="Major AQL" readonly></v-text-field>
        </v-col>
        <v-col cols="2" sm="2" md="2">
          <v-text-field hide-details :value="generalData.aqlMinor" label="Minor AQL" readonly></v-text-field>
        </v-col>

        <!-- Additional Control Sampling -->
        <v-col cols="6" sm="6" md="3" >
          <v-select hide-details @change="onChange" v-model="generalData.samplingMethodology" :items="sampleSizeTypeOptions" item-text="name" item-value="name" label="Sampling Methodology" append-icon="edit" auto-id="sampling sampling methodology"> </v-select>
        </v-col>
        <v-col cols="6" sm="6" md="3" v-if="generalData.inspectionLevel == 'Double'">
          <v-select hide-details @change="onChange" v-model="generalData.samplingPlan" :items="inspectionRoundOptions" item-text="name" item-value="name" label="Double Sampling Plan Iteration" append-icon="edit" auto-id="sampling double sampling plan iteration"> </v-select>
        </v-col>
        <v-col cols="6" sm="6" md="3" v-else> </v-col>
        <!-- 301509 - Hide Sample Size and Cartons Related Values -->
        <!-- <v-col cols="6" sm="6" md="6">
          <v-text-field hide-details v-model="generalData.totalExportCrtns" label="Ordered Qty of Cartons" append-icon="edit" @change="onChange"></v-text-field>
        </v-col>

        <v-col cols="6" sm="6" md="6">
          <v-text-field hide-details v-model="generalData.pkgAvailable" label="# of Cartons Available" append-icon="edit" @change="onChange"></v-text-field>
        </v-col>

        <v-col cols="6" sm="6" md="6">
          <v-text-field hide-details v-model="generalData.sampleSize" label="Sample Size" append-icon="edit" @change="onChange"></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field hide-details v-model="generalData.crtns" label="# of Cartons Inspected" append-icon="edit" @change="onChange"></v-text-field>
        </v-col> -->
      </v-row>
    </div>
  </div>
</template>

<script>
import MatrixSamplingPlan from "../../assets/data/AllSamplingPlan.json";
import * as moment from "moment";
import apiDetails from "../../shared/request/apiDetails";

export default {
  name: "Sampling",
  data() {
    return {
      dialog: false,
      showFloatingButton: false,
      // Sampling
      select_state: [{ text: "None" }, { text: "Yes" }, { text: "No" }],
      sampleSizeTypeOptions: [],

      inspectionRoundOptions: [],

      sampleSizeTypeSelected: {},

      matrixSamplingPlans: MatrixSamplingPlan,
      sampleSizeCodeLetter: [],
      singleSamplingPlan: [],
      doubleSamplingPlan: [],
      packageCheckObj: {
        workOrderId: null,
        crtnSampleSize: null,
        ilPkgCheck: null,
        pkgCheckOverallRslt: null,
      },

      samplingPlan: {
        SKUSamplingList: [
          {
            id: "",
            workOrderID: "",
            productId: "",
            SKUDisplay: "",
            PO: "",
            productQty: 0,
            cartonQty: 0,
            productProRata: 0,
            cartonProRata: 0,
            productSampleSize: 0,
            cartonSampleSize: 0,
            actualProductSampleSize: 0,
            actualCartonSampleSize: 0,
            AC: 0,
            RE: 0,
          },
        ],
        totalProductPOQty: 0,
        totalProductSampleSize: 0,
        totalCartonPOQty: 0,
        totalCartonSampleSize: 0,
      },

      sectionId: 9,
      showButton: false,
      isFormDirty: false,
    };
  },

  components: {},
  computed: {
     check() {
      this.showButton = this.generalData.hideButton;
    },
    getSamplingSection() {
      let val = this.workOrderSectionList.find((x) => x.sectionId === 9);
      val.submittedDate = this.formatDate(new Date());
      return val;
    },
  },
  activated() {},
  mounted() {
    this.LoadData();
    let header = {
      headers: {
        wresult: this.store_token,
      },
    };
    this.utilityFn.getServerData(
      "get",
      this.apiList.getLookupAsync,
      {},
      (res) => {
        this.sampleSizeTypeOptions = res.data.data.inspectionClass.filter((x) => x.inspectionClassFieldName.name === "Sample Size Type");
        this.inspectionRoundOptions = res.data.data.inspectionClass.filter((x) => x.inspectionClassFieldName.name === "Inspection Round");
      },
      header
    );
  },
  methods: {
    getnumberCartonsAvailable(workorderProduct) {
      let existingQuantityVerificationList = [];
      workorderProduct.forEach((x) => {
        if (x.workOrderProductQuantityVerificationResults.length > 0) {
          x.workOrderProductQuantityVerificationResults.forEach((item) => {
            existingQuantityVerificationList.push(item);
          });
        }
      });

      this.numberCartonsAvailable = existingQuantityVerificationList.reduce(function(tot, arr) {
        return tot + arr.availableQuantityCarton;
      }, 0);

      this.samplingPlanList = JSON.parse(localStorage.getItem("samplingPlanList"));
      if (this.samplingPlanList) {
        this.numberCartonsAvailable = this.samplingPlanList.totalCartonPOQty;
      }
    },

    LoadData() {
      this.workOrderSectionList = [];
      var other = {
        headers: {
          wresult: this.store_token,
          "Content-Type": "application/json",
        },
      };
      this.utilityFn.getServerData(
        "get",
        this.apiList.sectionList,
        {},
        (response) => {
          this.sections = response.data.data;

          this.utilityFn.getServerData(
            "get",
            this.apiList.workOrderSectionList + "/" + this.$route.params.id,
            {},
            (response2) => {
              const woSections = response2.data.data;

              this.sections.forEach((section) => {
                const index = woSections.findIndex((x) => x.sectionId == section.id);
                if (index != -1) {
                 this.workOrderSectionList.push({
                    id: woSections[index].id,
                    workOrderId: woSections[index].workOrderId,
                    sectionId: woSections[index].sectionId,
                    sectionName: section.sectionName,
                    isHidden: woSections[index].isHidden,
                    resourceId: woSections[index].resourceId,
                    lastModifiedDate: woSections[index].lastModifiedDate,
                    result: woSections[index].result,
                    workInstructionName: woSections[index].workInstructionName,
                    findings: woSections[index].findings,
                    submittedDate: woSections[index].submittedDate,
                    isResourceReAssign: woSections[index].isResourceReAssign,
                  });
                } else {
                  this.workOrderSectionList.push({
                    id: null,
                    workOrderId: this.$route.params.id,
                    sectionId: section.id,
                    sectionName: section.sectionName,
                    isHidden: false,
                    resourceId: null,
                    lastModifiedDate: null,
                    result: null,
                    workInstructionName: null,
                    findings: null,
                    submittedDate: null,
                    isResourceReAssign: null,
                  });
                }
              });
            },
            other
          );
        },
        other
      );
    },
    formatDate: function(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    returnNumber(obj, name) {
      obj[name] = obj[name].replace(/[^\d]/g, "");
    },
    submit_item() {
      var other = {
        headers: {
          wresult: this.store_token,
          "Content-Type": "application/json",
        },
      };
      const section = {
        sectionId: this.sectionId,
        workOrderId: this.$route.params.id,
        totalExportCrtns: this.generalData.totalExportCrtns,
        pkgAvailable: this.generalData.pkgAvailable,
        crtns: this.generalData.crtns,
        sampleSize: this.generalData.sampleSize,
      };
      this.utilityFn.getServerData("post", this.apiList.submitInspectionSection, section, (res) => {
        this.sectionData.isSectionSubmitted = true;
      });
    },
    show_double_dialog() {
      this.dialog = true;
    },
    loadSamplingSize(data) {
      this.samplingPlan.SKUSamplingList = data.products.map((x) => {
        return {
          id: x.id,
          workOrderID: x.workOrderID,
          productId: x.productID,
          SKUDisplay: x.skuDisplay,
          PO: x.po,
          productQty: x.workOrderProductResults.availQtyPrd,
          cartonQty: x.workOrderProductResults.availQtyCrtn,
          productSampleSize: x.workOrderProductResults.sampleSizePrd,
          cartonSampleSize: x.workOrderProductResults.sampleSizeCrtn,
          actualProductSampleSize: 0,
          actualCartonSampleSize: 0,
          aqlMinor: data.aqlMinor,
          aqlMajor: data.aqlMajor,
          aqlCritical: data.aqlCritical,
          minorAC: 0,
          minorRE: 0,
          majorAC: 0,
          majorRE: 0,
          criticalAC: 0,
          criticalRE: 0,
        };
      });

      let returnedSampleSizePlan = this.samPlan.getAutoComputeSampleSize(
        this.generalData.samplingMethodology,
        this.samplingPlan.SKUSamplingList,
        this.generalData.aqlLevels,
        this.generalData.inspectionLevel,
        this.generalData.samplingPlan,
        this.generalData.productType,
        "Sampling",
        false
      );
      this.showFloatingButton = returnedSampleSizePlan == undefined ? false : returnedSampleSizePlan.showFabButton == false ? false : true;
      this.$emit("updateShowFloating", this.showFloatingButton);
      this.generalData.sampleSize = returnedSampleSizePlan.totalProductSampleSize;
      this.upsertWorkOrderProductResultSampling(returnedSampleSizePlan.SKUSamplingList);

      this.updatePackageCheck(returnedSampleSizePlan.totalCartonSampleSize);
    },
    UpsertSampling() {
      this.loadSamplingSize(this.generalData);
      if (
        this.generalData.workOrderId !== null ||
        this.generalData.totalExportCrtns !== null ||
        this.generalData.pkgAvailable !== null ||
        this.generalData.crtns !== null ||
        this.generalData.sampleSize !== null ||
        this.generalData.samplingMethodology !== null ||
        this.generalData.samplingPlan !== null
      ) {
        this.logChanges();
        var data = [
          { op: "replace", path: "/workOrderId", value: this.generalData.workOrderId },
          { op: "replace", path: "/totalExportCrtns", value: this.generalData.totalExportCrtns },
          { op: "replace", path: "/pkgAvailable", value: this.generalData.pkgAvailable },
          { op: "replace", path: "/crtns", value: this.generalData.crtns },
          { op: "replace", path: "/samplingMethodology", value: this.generalData.samplingMethodology },
          { op: "replace", path: "/samplingPlan", value: this.generalData.samplingPlan },
          { op: "replace", path: "/sampleSize", value: this.generalData.sampleSize },
        ];

        this.utilityFn.getServerData("patch", this.apiList.patchWorkOrderResults + "/" + this.generalData.workOrderId + "/9", data, (res) => {});
      }
    },
    updatePackageCheck(crtnSampleSize) {
      this.packageCheckObj = this.generalData.workOrderPackageCheck || {
        workOrderId: this.generalData.workOrderId,
        crtnSampleSize: null,
        samplingMethodology: null,
        doubleSamplingPlan: null,
        ilPkgCheck: null,
        pkgCheckOverallRslt: null,
      };

      (this.packageCheckObj.workOrderId = this.generalData.workOrderId), (this.packageCheckObj.crtnSampleSize = crtnSampleSize);
      this.packageCheckObj.ilPkgCheck = this.generalData.aqlLevels;
      this.packageCheckObj.samplingMethodology = this.generalData.samplingMethodology;
      this.packageCheckObj.doubleSamplingPlan = this.generalData.inspectionLevel;
      this.packageCheckObj.pkgCheckOverallRslt = this.packageCheckObj.pkgCheckOverallRslt == null ? 0 : this.packageCheckObj.pkgCheckOverallRslt;
      this.apiFn.UpsertWorkOrderPackageCheck(this.packageCheckObj).then((result) => {});
    },
    upsertWorkOrderProductResultSampling(SKUSamplingList) {
      SKUSamplingList.forEach((element) => {
        let workOrderProductID = element.id;
        let sampleSizePrd = element.productSampleSize == undefined ? null : element.productSampleSize;
        let sampleSizeProductWorkmanship = element.productSampleSize == undefined ? null : element.productSampleSize;
        let sampleSizeProductSizeMeasurement = element.productSampleSize == undefined ? null : element.productSampleSize;
        let sampleSizeProductWeightMeasurement = element.productSampleSize == undefined ? null : element.productSampleSize;
        let sampleSizeCrtn = element.cartonSampleSize == undefined ? null : element.cartonSampleSize;
        let sampleSizeCartonSizeMeasurement = element.cartonSampleSize == undefined ? null : element.cartonSampleSize;
        let samlpleSizeCartonWeightMeasurement = element.cartonSampleSize == undefined ? null : element.cartonSampleSize;
        let allowedMinorPO = element.minorAC == undefined ? null : element.minorAC;
        let allowedMajorPO = element.majorAC == undefined ? null : element.majorAC;
        let allowedCriticalPO = element.criticalAC == undefined ? null : element.criticalAC;

        const formPatch = [
          { op: "replace", path: "/sampleSizePrd", value: sampleSizePrd },
          { op: "replace", path: "/sampleSizeProductWorkmanship", value: sampleSizeProductWorkmanship },
          { op: "replace", path: "/sampleSizeProductSizeMeasurement", value: sampleSizeProductSizeMeasurement },
          { op: "replace", path: "/sampleSizeProductWeightMeasurement", value: sampleSizeProductWeightMeasurement },
          { op: "replace", path: "/sampleSizeCrtn", value: sampleSizeCrtn },
          { op: "replace", path: "/sampleSizeCartonSizeMeasurement", value: sampleSizeCartonSizeMeasurement },
          { op: "replace", path: "/samlpleSizeCartonWeightMeasurement", value: samlpleSizeCartonWeightMeasurement },
          { op: "replace", path: "/allowedMinorPO", value: allowedMinorPO },
          { op: "replace", path: "/allowedMajorPO", value: allowedMajorPO },
          { op: "replace", path: "/allowedCriticalPO", value: allowedCriticalPO },
        ];

        this.apiFn.upsertProductResult(workOrderProductID, formPatch).then((result) => {
          console.log(result);
        });
      });
    },
    onChange() {
      this.isFormDirty = true;
      this.logChanges();
    },
    logChanges() {
      // flag that data has been updated, this is to prevent logging activity even though no value has been change
      // this is because when clicking on section headers/navigating out, api is always called
      window.sessionStorage.setItem("EditDataLogging", true);
    },
  },
  // computed: {
  //   check() {
  //     this.showButton = this.generalData.hideButton;
  //   },
  // },
  beforeRouteLeave(to, form, next) {
    if (this.isFormDirty) {
      this.UpsertSampling();
    }
    next();
  },

  props: {
    generalData: Object,
    sectionData: Object,
  },
};
</script>

<style lang="stylus" scoped>
.submit-btn
    color: #fff !important
</style>
