<template>
    <div class="uploadPhotoId">

        <div class="public_img" >
            <img class="imgItem" v-for="(item, index) in imageList" :key="index"  v-lazy="item" />
        </div>

        <div style="text-align:left;margin:0 2%;">
            <select style="border: 1px #ccc solid;background: #fff;padding: 10px;width: 250px;">
                <option value="">Barcode on gift box</option>
                <option value="">Barcode on inner box</option>
                <option value="">Barcode on master carton</option>
                <option value="">Barcode on pallet</option>
                <option value="">Carton open view</option>
                <option value="">Gift box packing</option>
            </select>
            <van-button type="default">Update</van-button>
        </div>

        <draggable class="imglist select_img" v-model="imageList">
            <transition-group tag="ul">
                <li v-for="(item, index) in imageList" :key="index" class="item">
                    <div class="holder">
                        <span class="del_button" @click="del_img(index)">
                            <icon name="times-circle" class="icon"></icon>
                        </span>
                        <!-- v-lazy="item" -->
                        <img class="imgItem" :src="item"/>
                    </div>
                </li>
            </transition-group>
        </draggable>

    </div>
</template>

<script>

    import draggable from 'vuedraggable'

    let state = {
        appHeaderState: {
            show: true,
            title: 'Stock Inspeted 54322',
            showMenu: true,
            showBack: true,
            showLogo: false,
            showTitle: true,
            showSearch: false,
            showCalendar: true
        }
    };

    function setState(store) {
        store.dispatch('navigation/setAppHeader', state.appHeaderState);
    }
    export default {
        name: 'uploadPhotoId',
        
        components: {
            draggable
        },

        data () {
            return {
                imageList: [
                    'https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1',
                    'https://cn.bing.com/th?id=OIP.m2xhGEIwS2d5EJZEU9XLBwAAAA&pid=Api&rs=1',
                    'https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1',
                    'https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1',
                    'https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1',
                    'https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1',
                    'https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1',
                    'https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1',
                    'https://cn.bing.com/th?id=OIP.m2xhGEIwS2d5EJZEU9XLBwAAAA&pid=Api&rs=1',
                    'https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1',
                    'https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1',
                    'https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1',
                    'https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1',
                    'https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1',
                ]
            };
        },
        mounted () {
            setState(this.$store);
        },
        methods: {
            del_img (index) {
                this.imageList.splice(index, 1)
            }
        }
    };
</script>

<style lang="stylus" scoped>
    .item
        display inline-block
        width 12%
        margin 1.14%
    .holder
        position relative 
    .imglist
        position absolute
        bottom 1px
        left 0
        right 0
    .imglist .holder .imgItem
        width 100%
    
    .public_img, .select_img
        text-align left 
        border 1px #ccc solid
        width 96%
        margin 20px auto
        background #ffffff
        padding 10px 0
    .public_img .imgItem
        width 12%
        margin 1.14%
    
    .del_button
        position absolute
        right -3px
        top -5px
</style>
