
<template>
    <div>
    <v-btn class="submit-btn" small depressed color="red" v-if="showSpecialInstruction" @click="openCloseDialog()"> 
        Special Instruction
    </v-btn>

    <v-dialog v-model="openDialog" :persistent="true" width="70%">
    <v-card>
        <v-card-title>Special Instructions</v-card-title>
            <v-card-text>
                <v-textarea variant="outlined" v-model="this.specialInstruction" readonly>

                </v-textarea>
            </v-card-text>
        <v-card-actions>
            <v-btn id="closeDialog" class="btn" small depressed color="red" style="margin-left:45%; text-align: center; " @click="openCloseDialog()">Close</v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>

    </div>
</template>



<script>

export default {
    name: 'SpecialInstructions',
    data (){
        return {
            showSpecialInstruction: false,
            openDialog: false,
            specialInstruction: ""
        }
    },
    computed: {
        
    },
    mounted() {
        this.loadData();
    },
    methods: {
        loadData() {
            this.utilityFn.getServerData("get",this.apiList.getConcatenatedSpecialInstructions +'/'+ this.$route.params.id,{}
                ,(res) => {
                if (res.data.data){
                    this.specialInstruction = res.data.data;
                    this.showSpecialInstruction = true;
                }
            });
        },

        openCloseDialog(){
            this.openDialog = !this.openDialog;
        }
    },
    props: {
        workOrderId :{
            type: String
        },
    }
};
</script>

<style lang="stylus" scoped>
</style>
