<template>
  <div>
    <v-btn style="margin-top: 100px" fixed dark fab top right color="red" small @click="show_double_dialog">
      <v-icon>mdi-file-find</v-icon>
    </v-btn>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title>
          <span class="headline">Sample size per SKU</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-tabs v-model="tab" background-color="#0076FF" dark>
                <v-tab href="#tab-1">Product</v-tab>
                <v-tab href="#tab-2">Carton</v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item class="tab_item" :value="'tab-1'">
                  <v-data-table v-bind:headers="productHeader"  :sort-by="['countIndex', 'po', 'skuDisplay', 'orderNumber']" :items="_productQuantityVerificationList" :items-per-page="-1" class="elevation-1" style="margin-top: 30px;"></v-data-table>
                </v-tab-item>

                <v-tab-item class="tab_item" :value="'tab-2'">
                  <v-data-table v-bind:headers="cartonHeader" :sort-by="['countIndex', 'po', 'skuDisplay', 'orderNumber']" :items="productQuantityVerificationList" :items-per-page="-1" class="elevation-1" style="margin-top: 30px;"></v-data-table>
                </v-tab-item>
              </v-tabs-items>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="close">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {},
  data() {
    return {
      dialog: false,
      tab: null,
  
      samplingPlan: {
        SKUSamplingList: [
          {
            id: "",
            workOrderID: "",
            productId: "",
            SKUDisplay: "",
            PO: "",
            productQty: 0,
            cartonQty: 0,
            productProRata: 0,
            cartonProRata: 0,
            productSampleSize: 0,
            cartonSampleSize: 0,
            actualProductSampleSize: 0,
            actualCartonSampleSize: 0,
            AC: 0,
            RE: 0,
          },
        ],
        totalProductPOQty: 0,
        totalProductSampleSize: 0,
        totalCartonPOQty: 0,
        totalCartonSampleSize: 0,
      },

      productHeader: [
        {
          text: "SKU#",
          align: "left",
          sortable: true,
          value: "skuDisplay",
        },
        {
          text: "PO No.",
          align: "center",
          sortable: true,
          value: "po",
        },
        {
          text: "PO Qty(Unit)",
          align: "center",
          sortable: false,
          value: "qty",
        },
        {
          text: "Lot Qty",
          align: "center",
          sortable: false,
          value: "availableQuantityProduct",
        },
        {
          text: "Sample Size",
          align: "center",
          sortable: false,
          value: "sampleSizeProduct",
        },
      ],

      cartonHeader: [
        {
          text: "SKU#",
          align: "left",
          sortable: true,
          value: "skuDisplay",
        },
        {
          text: "PO No.",
          align: "center",
          sortable: true,
          value: "po",
        },
        {
          text: "Available Carton",
          align: "center",
          sortable: false,
          value: "availableQuantityCarton",
        },
        {
          text: "Case Count",
          align: "center",
          sortable: false,
          value: "caseCountProduct",
        },
        {
          text: "Sample Size",
          align: "center",
          sortable: false,
          value: "sampleSizeCarton",
        },
      ],

      productQuantityVerificationList: [],
    };
  },

  mounted() {
    console.log('his.workOrder=>',this.workOrder)
    if (this.workOrder && this.workOrder.products) {
      this.initQuantityVerification();
    }
  },

  methods: {
    show_double_dialog() {
      this.dialog = true;
      this.samplingPlan = JSON.parse(localStorage.getItem("samplingPlanList"));
    },

    close() {
      this.dialog = false;
    },

    initQuantityVerification() {
      this.productQuantityVerificationList = [];
      let existingQuantityVerificationList = [];
      this.workOrder.products.forEach((x) => {
        if (x.workOrderProductQuantityVerificationResults.length > 0) {
          x.workOrderProductQuantityVerificationResults.forEach((item) => {
            existingQuantityVerificationList.push(item);
          });
        }
      });
      if (existingQuantityVerificationList.length > 0) {
        this.loadQuantityVerificationProductDetails(existingQuantityVerificationList);
      }
    },

    loadQuantityVerificationProductDetails(quantityVerificationProductDetails) {
      console.log('quantityVerificationProductDetails=>',quantityVerificationProductDetails)
      if (quantityVerificationProductDetails) {
        if (quantityVerificationProductDetails.length > 0) {
          let quantityVerificationList = [];
          quantityVerificationProductDetails.forEach((res) => {
            const skuItem = this.workOrder.products.find((x) => x.id === res.workOrderProductId) || {};

            const quantityVerification = {
              id: res.id,
              workOrderProductId: res.workOrderProductId,
              productID: skuItem.productID,
              skuDisplay: skuItem.skuDisplay,
              po: skuItem.po,
              cartonQty: skuItem.cartonQty,
              qty: skuItem.qty,
              caseCountProduct: res.caseCountProduct,
              availableQuantityCarton: res.availableQuantityCarton,
              sampleSizeCarton: res.sampleSizeCarton,
              sampleSizeCarton2: res.sampleSizeCarton,
              sampleSizeCartonTotal: res.sampleSizeCartonTotal,
              excessShortQuantity: res.excessShortQuantity,
              sampleSizeProduct: res.sampleSizeProduct,
              sampleSizeProduct2: res.sampleSizeProduct,
              sampleSizeProductTotal: res.sampleSizeProductTotal,
              exportCartonNumbersOpened: res.exportCartonNumbersOpened,
              remarks: res.remarks,
              packedQty: res.packedQty,
              underPackingQty: res.underPackingQty,
              underProductionFinishingQty: res.underProductionFinishingQty,
              availableQuantityProduct: res.availableQuantityProduct,
              orderNumber: res.orderNumber,
            };
            this.productQuantityVerificationList.push(quantityVerification);
          });
        }
      }
    },
  },

  computed: {
    ...mapState("inspection", {
      workOrder: (state) => state.workOrderDetails,
      updatedProductQuantityVerificationList: (state) => state.productQuantityVerificationList,
    }),

    _productQuantityVerificationList() {
      const list = [];
      this.productQuantityVerificationList.forEach((item) => {
        const index = list.findIndex((x) => x.productID === item.productID);
        // check if product is already in the list
        if (index === -1) {
          list.push(item);
        } else if (index !== -1) {
          if (list[index].po === item.po) {
            list[index].availableQuantityProduct = list[index].availableQuantityProduct + item.availableQuantityProduct;
            //list[index].qty = (list[index].qty + item.qty);
          } else {
            list.push(item);
          }
        }
      });

      return list;
    },
  },

  watch: {
    workOrder(newValue, oldValue) {
      if (newValue && newValue.products) {
        this.initQuantityVerification();
      }
    },

    updatedProductQuantityVerificationList(newValue, oldValue) {
      if (newValue && newValue.length > 0) {
        this.productQuantityVerificationList = newValue;
      }
    },
  },
};
</script>

<style lang="stylus" scoped></style>
