<template>
  <div>
    <div class="uploadPhotoGalleryId">
      <v-row v-if="!forProductUpload && sectionId == 10">
        <v-col cols="12">
          <div class="p-4">Maximum {{ workOrderDetails.maximumProductPhoto ?? 99 }} product photos in the report</div>
        </v-col>
      </v-row>
      <v-row v-for="(groupAttachment, gaIndex) in groupedAttachments" :key="gaIndex" v-show="groupAttachment.attachments.length > 0">
        <v-col cols="12" sm="12" style="padding-bottom:0">
          <h3>{{ groupAttachment.description }}</h3>
        </v-col>
        <v-col cols="12" sm="12" v-if="groupAttachment.description">
          <v-btn depressed class="delete_selected" v-if="groupAttachment.attachments.length > 0" @click="delete_all_images(gaIndex, groupAttachment.attachments)">Delete All</v-btn>
        </v-col>
        <v-col cols="12" sm="12" v-else>          
          <v-btn depressed @click="toggleAttachmentSelect(groupAttachment)">{{ groupAttachment.attachments.some(x => x.selected) ? 'Unselect All' : 'Select All'  }}</v-btn>
          <v-btn depressed class="delete_selected" v-if="groupAttachment.attachments.some(x => x.selected)" @click="delete_selected_images(gaIndex, groupAttachment.attachments)">Delete</v-btn>
        </v-col>
        <v-col cols="12" sm="12">
          <draggable class="imglist select_img" v-model="groupAttachment.attachments">
            <transition-group tag="ul">
              <li v-for="(attachment, aIndex) in groupAttachment.attachments" :key="aIndex" class="item">
                 <div v-if="!attachment.src">
                  Loading...
                  {{ loadImage(gaIndex, aIndex, attachment.fileUploadId) }}
                </div>
                <div v-if="attachment.src === 'loading'">
                  Loading...
                </div>
                <div v-else class="holder">                                    
                  <span class="badge-number">{{ aIndex + 1 }}</span>
                  <span v-if="!isImageTemporary(attachment) || !showProgressDialog" class="del_button" @click="del_img(gaIndex, aIndex, attachment.fileUploadId)">
                    <icon name="times-circle" class="icon"></icon>
                  </span>
                  <span class="checkbox_img" v-if="!groupAttachment.description">
                    <v-checkbox color="black" v-model="attachment.selected"></v-checkbox>
                  </span>                
                  <img class="imgItem" :class="{ updateSelected: attachment.updateSelected }" :src="attachment.src" @click="add_img_update(gaIndex, aIndex)" />                                    
                </div>                
              </li>
            </transition-group>
          </draggable>
        </v-col>
      </v-row>

      <v-row v-show="addedImages.length > 0">
        <v-col cols="12" sm="12">
          <draggable class="imglist select_img" v-model="addedImages">
            <transition-group tag="ul">
              <li v-for="(attachment, index) in addedImages" :key="index" class="item">
                <div v-if="!attachment.src">
                  Loading...
                  {{ loadTempImage(index, attachment.file) }}
                </div>
                <div v-else class="holder">
                  <span class="badge-number">{{ index + 1 }}</span>
                  <span class="del_button" @click="del_temp_img(attachment.id)">
                    <icon name="times-circle" class="icon"></icon>
                  </span>
                  <img class="imgItem" :src="attachment.src" />
                </div>
              </li>
            </transition-group>
          </draggable>
        </v-col>
      </v-row>

      <v-row style="padding-top: 15px; padding-bottom:15px;">
        <div style="text-align:left; margin:0 2%;">
          <!-- Remove this dropdwon bug 105941 -->
          <div class="d-flex flex-column">
            <v-text-field v-model="description" label="Description" v-show="showDescription"> </v-text-field>
          </div>
          <van-button type="default" @click="uploadImages" v-show="toBeUpdatedImages.length === 0" :disabled="!isAddPhotoEnabled">{{ submitButtonText }}</van-button>
          <van-button type="default" @click="onImageSelection">Add Photo from Gallery</van-button>
        </div>
      </v-row>

    </div>
    <v-dialog v-model="showProgressDialog" :persistent="true">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-container v-for="(image, index) in addedImages" :key="index">
            <v-progress-linear :value="image.uploadProgress" color="light-blue" height="25" reactive>
              <template v-slot="{ value }">
                <strong>{{ image.fileName }} {{ Math.ceil(image.uploadProgress) }}%</strong>
              </template>
            </v-progress-linear>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import * as _ from "lodash";
import { mapState } from "vuex";

let state = {
  appHeaderState: {
    show: true,
    title: "",
    showMenu: true,
    showBack: true,
    showLogo: false,
    showTitle: true,
    showSearch: false,
    sectionId: "",
    showCalendar: true,
  },
};

function setState(store) {
   store.dispatch('navigation/setAppHeader', state.appHeaderState);
}
export default {
  name: "uploadPhotoGalleryId",
  components: {
    draggable,
  },
  data() {
    return {
      imageList: [],
      descriptionList: [
        "Accessories",
        "Barcode on color card",
        "Barcode on giftbox",
        "Barcode on inner box",
        "Barcode on master carton",
        "Barcode on pallet",
        "Barcode on polybag",
        "Blister",
        "Blister packing",
        "Carton open view",
        "Color card",
        "Gift box",
        "Gift box packing",
        "Inner box",
        "Inner box packing",
        "Instruction manual",
        "Label on inner box",
        "Label on master carton",
        "Label on pallet",
        "Label on product",
        "Label on sample",
        "Master carton",
        "Master carton packing",
        "Marking on color card",
        "Marking on gift box",
        "Marking on polybag",
        "Others",
        "Pallet",
        "Polybag",
        "Product",
        "Product details",
        "Product dimension check",
        "Sample vs product",
        "Scanning test for color card",
        "Scanning test for gift box",
        "Scanning test for inner box",
        "Scanning test for label",
        "Scanning test for master carton",
        "Scanning test for polybag",
        "Shipping Mark",
        "Shipping Marks Front & Back Side - 1 side only",
        "Shipping Marks Front & Back Side - both opposite sides",
        "Shipping Marks Sides - 1 side only",
        "Shipping Marks Sides - both opposite sides",
        "Warranty card",
        "Weight check",
        "UL VS chop",
      ],
      // 备注
      description: "",
      // 选择other需要输入的备注
      otherDescription: "",
      // 所有产品列表
      workOrderProduct: {},
      // 所有图片列表
      attachments: [],
      groupedDescriptions: [],
      groupedAttachments: [], // group by attachment description
      addedImages: [],
      toBeUpdatedImages: [],
      showProgressDialog: false,

      // 提交按钮，如果是添加为Add to photo 如果为编辑为Update
      submitButtonText: "Add to photo",
      isUpdate: false,
      workOrderId: "",
      sectionId: "",
      forProductUpload: false,
      workOrderProductId:null
    };
  },
  mounted() {
    // 获取当前woId
    this.workOrderId = this.$route.params.id;
    if(this.$route.query.workOrderProductId)
      this.workOrderProductId = this.$route.query.workOrderProductId.toLowerCase();

    // 获取模块id
    this.sectionId = this.$route.params.sectionId || this.$route.query.sectionId;

    state.appHeaderState.title = "Photo Upload：" + this.$route.params.name;

    if (this.$route.query.uploadType === "ProductUpload") {
      this.forProductUpload = true;
      state.appHeaderState.title = "Photo Upload：" + this.$route.query.productName;
    } else {
      this.forProductUpload = false;
    }
    // 设置头部
    setState(this.$store);

    this.loadImages();
    
    this.setSessionDetails();
  },
  methods: {
    loadImages() {
     
       if (this.forProductUpload) {
        this.utilityFn.getServerData("get", this.apiList.getProductAttachment 
        + "?workOrderId=" + this.$route.params.id + 
        "&productId=" + this.$route.query.workOrderProductId.toLowerCase() + "&sectionId=10", {}, (res) => {
          if (res) {
            this.attachments = res.data.data;
            this.groupTheAttachments();
          }
        });
      } else {
        this.utilityFn.getServerData("get", this.apiList.getWorkOrderAttachment + 
        "?workOrderId=" + this.$route.params.id + "&sectionId=" + this.sectionId, {}, (res) => {
          if (res) {
            this.attachments = res.data.data
               .map((attachment) => {
                attachment.src = null;
                attachment.added = true;
                return attachment;
              });
           
            this.groupTheAttachments();
          }
        });
      }
   
    },

    groupTheAttachments() {
      // group attachments by description
      this.addedImages = [];
      this.groupedAttachments = [];
      // 筛选出共有多少种描述类型
      this.groupedDescriptions = _.uniq(_.map(this.attachments, "description"));
      // 将同一种描述类型的图片放入一个数组中
      this.groupedDescriptions.forEach((description) => {
        const filteredAttachments = this.attachments.filter((x) => x.description === description).map(x => ({...x, selected: false}));
        //if(description===null){
        if (this.sectionId === 12) {
          if (description === null) {
            this.groupedAttachments.push({
              description,
              attachments: _.orderBy(filteredAttachments, ["order"], ["asc"]),
            });
          }
        } else {
          this.groupedAttachments.push({
            description,
            attachments: _.orderBy(filteredAttachments, ["order"], ["asc"]),
          });
        }

        //}
      });
    },

    loadingImg(item) {
      this.utilityFn.getImage(item.fileUploadId, (res) => {
        item.src = "data:" + res.fileType + ";base64," + res.data.data;
      });
    },

    onImageSelection(event) {
      var inputObj = document.createElement("input");
      inputObj.setAttribute("accept", "image/*");
      inputObj.setAttribute("multiple", "multiple");
      inputObj.setAttribute("id", "_ef");
      inputObj.setAttribute("type", "file");
      inputObj.setAttribute("style", "visibility:hidden");
      document.body.appendChild(inputObj);
      inputObj.click();
      inputObj.onchange = (res) => {
        this.isUpdate = false;
        this.clearUpdateSelection();

        const loadImage = async () => {
          for (let i = 0; i < inputObj.files.length; i++) {
            let file = inputObj.files[i];
            var sizeInfo = this.utilityFn.formatBytes(file.size)    
            console.log('sizeInfo=>',sizeInfo)
            if(sizeInfo.sizeKB > 500){
              this.$store.dispatch("navigation/setSnackbar",{
                color :'error',
                text:`Each upload only accepts image size less than 500KB. Please resize/compact the image with size greater than 500KB. Filename: ${file.name}.`});
            }
            else{
              const reader = new FileReader();
              const tempId = "temp_" + this.addedImages.length;
              const attachment = {
                fileUploadId: tempId, // add a marker/flag to determine if this image is not yet in the database
                src: await returnBase64(file),
                added: false,
                description: this.finalDescription,
                workOrderProductId: this.workOrderProductId,
                selected: false
              };
              this.attachments.push(attachment);
              this.addedImages.push({
                file,
                uploadProgress: 0,
                id: tempId,
                src: null,
              });
              }
          }
        };
        const returnBase64 = (file) => {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              var reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            }, 500);
          });
        };
        loadImage().then(() => {
          console.log("done");
        });

        this.logChanges();
      };
    },

    // will generate a file object
    getImage(index) {
      return URL.createObjectURL(this.imageList[index]);
    },

    uploadImages() {
      // 弹出上传进度
      if (window.navigator.onLine) {
        this.showProgressDialog = true;
      } else {
        alert("Photo saved offline, will auto update when device is online");
      }
      // 不知道啥意思
      const _addedImages = [...this.addedImages];
     
      for (let i = 0; i < _addedImages.length; i++) {
        const addedImage = { ..._addedImages[i] };
        let formData = new FormData();
        formData.append("image", addedImage.file);
        formData.append("screen", this.sectionId);
        formData.append("order", (this.groupedAttachments.length + 1) * 100 + (i + 1));

        let url = this.apiList.uploadWorkOrderImage;
        if (this.forProductUpload) {
          formData.append("workOrderProductId", this.workOrderProductId);
          formData.append("description", this.finalDescription);
          url = this.apiList.uploadWorkOrderProductImage;
        } else {
          formData.append("workOrderId", this.workOrderId);
          formData.append("description", this.finalDescription);
        }

        var data = { formData: formData, base64: addedImage.src };

        this.utilityFn.uploadImg(
          url,
          data,
          (progress) => {
            const index = _addedImages.findIndex((x) => x.id === addedImage.id);
            _addedImages[index].uploadProgress = progress;
          },
          (result) => {
            const index = this.attachments.findIndex((x) => x.fileUploadId === addedImage.id);
            this.attachments[index].fileUploadId = result.data.fileUploadId;
            this.attachments[index].description = result.data.description;
            this.attachments[index].order = result.data.order;
            this.attachments[index].selected = false;

            // remove the image from the newly added images array
            const imgIndex = this.addedImages.findIndex((x) => x.id === addedImage.id);
            this.addedImages.splice(imgIndex, 1);
            this.checkUploadStatus();
          }
        );
      }
      
      
      this.logChanges();
    },

    del_temp_img(tempImgId) {
      let index = this.addedImages.findIndex((x) => x.id === tempImgId);
      this.addedImages.splice(index, 1);
      this.addedImages = this.addedImages.map((a, i) => {
        a.id = "temp_" + i;
        return a;
      });

      index = this.attachments.findIndex((x) => x.id === tempImgId);
      this.attachments.splice(index, 1);
    },

    del_img(groupedAttachmentIndex, attachmentIndex, imageId) {
      let index = this.attachments.findIndex((x) => x.fileUploadId === imageId);
      this.attachments.splice(index, 1);
      this.groupedAttachments[groupedAttachmentIndex].attachments.splice(attachmentIndex, 1);

      // check if the image being deleted is a newly added image (which is not yet uploaded)
      // if not, then call the api
      if (imageId.indexOf("temp_") === -1) {
        this.apiFn.deleteImage(imageId).then((result) => {});

        this.logChanges();
      }
    },

    // 参数,第几行，第几个，图片id
    loadImage(groupedAttachmentIndex, attachmentIndex, imageId) {
      this.groupedAttachments[groupedAttachmentIndex].attachments[attachmentIndex].src = "loading";
      this.groupedAttachments[groupedAttachmentIndex].attachments = [...this.groupedAttachments[groupedAttachmentIndex].attachments];

      const getImage = async () => {
        this.apiFn.getImage(imageId).then((result) => {
          let src = "data:" + result.fileType + ";base64," + result.data;
          this.groupedAttachments[groupedAttachmentIndex].attachments[attachmentIndex].src = src;
          this.groupedAttachments[groupedAttachmentIndex].attachments = [...this.groupedAttachments[groupedAttachmentIndex].attachments];
        });
      };
      getImage().then(() => {});
    },

    loadTempImage(index, file) {
      this.getBase64(file).then((base64) => {
        this.addedImages[index].src = base64;
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    checkUploadStatus() {
      this.showProgressDialog = this.addedImages.length !== 0;
      if (!this.showProgressDialog) {
        this.description = "";
        this.otherDescription = "";
        this.groupTheAttachments();
      }
    },
    // check if the image being displayed is a newly added image
    isImageTemporary(attachment) {
      return attachment.fileUploadId.indexOf("temp_") !== -1;
    },

    saveImageOrder() {
      this.groupedAttachments.forEach((g, gIndex) => {
        const base = (gIndex + 1) * 100;
        g.attachments.forEach((a, aIndex) => {
          a.order = base + aIndex + 1;
          a.isIncludeInCFR = true;
          a.sectionId = this.sectionId;
          a.src = null;
        });
        this.utilityFn.getServerData("put", this.apiList.updateWorkOrderImages, g.attachments, (result) => {});
        // this.apiFn.updateWorkOrderImages(g.attachments);
      });
    },

    add_img_update(gaIndex, aIndex) {
      this.isUpdate = true;
      const attachment = this.groupedAttachments[gaIndex].attachments[aIndex];
      const index = this.toBeUpdatedImages.findIndex((x) => x.fileUploadId === attachment.fileUploadId);

      if (index === -1) {
        this.toBeUpdatedImages.push(attachment);
        this.groupedAttachments[gaIndex].attachments[aIndex].updateSelected = true;
      } else {
        this.toBeUpdatedImages.splice(index, 1);
        this.groupedAttachments[gaIndex].attachments[aIndex].updateSelected = false;
      }

      this.groupedAttachments[gaIndex].attachments = [...this.groupedAttachments[gaIndex].attachments];
    },

    clearUpdateSelection() {
      this.toBeUpdatedImages.forEach((img) => {
        const index = this.attachments.findIndex((a) => a.fileUploadId === img.fileUploadId);
        this.attachments[index].updateSelected = false;
      });
      this.toBeUpdatedImages = [];
    },

    logChanges() {
      window.sessionStorage.setItem("EditDataLogging", true);
      if (this.forProductUpload && this.$route.query.workOrderProductId) {
        window.sessionStorage.setItem("WorkOrderProductId", this.$route.query.workOrderProductId);
      }
    },

    setSessionDetails() {
      window.sessionStorage.removeItem("WorkOrderProductId");
      window.sessionStorage.setItem("SectionId", this.sectionId);
    },

    toggleAttachmentSelect(groupedAttachment) {
      //if any attachment is already selected then deselect them all, otherwise select them all
      if(groupedAttachment.attachments.some(x => x.selected)) {
        groupedAttachment.attachments = groupedAttachment.attachments.map((x) => {
          x.selected = false;
          return x;
        });
      }
      else {
        groupedAttachment.attachments = groupedAttachment.attachments.map((x) => {
          x.selected = true;
          return x;
        });
      }      
    },

    delete_selected_images(gaIndex, attachments) {
      if(confirm("Are you sure to delete photo(s)?")) {
        let attachmentsToDelete = attachments.filter(x => x.selected);
        _.forEach(attachmentsToDelete, (x) => {        
          let index = attachments.findIndex((y) => y.fileUploadId === x.fileUploadId);
          this.del_img(gaIndex, index, x.fileUploadId);
        });
      }
    },

    delete_all_images(gaIndex, attachments) {
      if(confirm("Are you sure to delete photo(s)?")) {
        let attachmentsToDelete = attachments.map(x => x.fileUploadId);
        _.forEach(attachmentsToDelete, (x) => {        
          let index = attachments.findIndex((y) => y.fileUploadId === x);
          this.del_img(gaIndex, index, x);
        });
      }
    },
  },
  computed: {
    ...mapState("inspection", {
      workOrderDetails: (state) => state.workOrderDetails,
    }),
    showDescription() {
      return this.sectionId === 1 || this.sectionId === 2 || this.sectionId === 3 || this.sectionId === 4 || this.sectionId === 5 || this.sectionId === 6;
    },
    showOtherDescription() {
      return this.description === "Others";
    },
    finalDescription() {
      return this.description === "Others" ? this.otherDescription : this.description;
    },

    isAddPhotoEnabled() {
      let val = false;
      if (this.sectionId === 1 || this.sectionId === 2 || this.sectionId === 3 || this.sectionId === 4 || this.sectionId === 5 || this.sectionId === 6) {
        if (this.finalDescription != "" && this.addedImages.length > 0 && !this.showProgressDialog) {
          val = true;
        }
      } else {
        if (this.addedImages.length > 0 && !this.showProgressDialog) {
          val = true;
        }
      }
      return val;
    },

    isUpdatePhotoEnabled() {
      return this.finalDescription && this.toBeUpdatedImages.length > 0 && this.isUpdate;
    },
  },
  beforeDestroy() {
    this.saveImageOrder();
  },
};
</script>

<style lang="stylus" scoped>
ul
	padding 0
.row
	background: #fff
	margin: 15px

.item
	display: inline-block
	width: 10%;
	margin: 1.14%;

.holder
	position: relative

.imglist .holder .imgItem
	width: 100%
	box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.3)


.imgItem.updateSelected
	border: 2px solid #c02032

.badge-number
	position: absolute
	bottom: 0
	right: 0
	padding: 8px
	background-color: #fff
	box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.3)
	border-radius: 100px
	font-weight: bold
	font-size: 10px

.public_img, .select_img
	text-align: left
	// border 1px #ccc solid
	background: #ffffff
	padding: 10px 0

.public_img .imgItem
	width: 12%;
	margin: 1.14%;

.del_button
	position: absolute
	right: -3px;
	top: -5px;

.checkbox_img
  position: absolute;
  bottom: -45px;
  left: -5px;

.delete_selected
  margin-left:16px;

</style>
