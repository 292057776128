<template>
    <div class="app-search-page">
        <header style="background:#fff;">
            <v-btn light icon class="search-btn" @click.native="$router.go(-1)">
                <v-icon class="search-icon">arrow_back</v-icon>
            </v-btn>
            <form @submit.prevent="get_data">
                <input v-if="search_type == 'job'" class="search-input" v-model="query" type="search" autocomplete="off" placeholder="Job ID / Work Order Search" autocapitalize="off" />
                <input v-else class="search-input" v-model="query" type="search" autocomplete="off" placeholder="Work Order / Product" autocapitalize="off" />
            </form>
            <v-btn light icon class="search-btn" @click.native="query = ''">
                <v-icon class="search-icon">close</v-icon>
            </v-btn>
        </header>
        <div v-if="loading" class="search-loading">
            <v-progress-circular indeterminate v-bind:size="70" class="primary--text"></v-progress-circular>
        </div>
        <div v-if="data && data.length && search_type == 'job'" class="search-content" style="width:96%; margin:30px auto;">
            <v-row style="background: #fff;width: 100%;margin: 10px auto; " v-for="(item, index) in displayResourceName" :key = index class="job_list" @click="go_WorkOrder(item)">
             
                <v-col cols="12" sm="6" md="6">
                    <v-text-field :value="item.jobDisplayId" label="Job ID" readonly></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-text-field :value="item.clientName" label="Client Name" readonly></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-text-field :value="item.factoryName" label="Factory Name" readonly></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-text-field :value="item.serviceType" label="Service Type Test" readonly></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" style="overflow: hidden;">
                    <v-text-field :value="item.factoryContactPhoneNumber" label="Phone Number" append-icon="phone" readonly @click:append="call(item.factoryContactPhoneNumber)"></v-text-field>
                    </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-text-field :value="item.jobStartDate" label="Inspection Date" readonly></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-text-field :value="item.factoryContactEmail" label="Email" append-icon="email" readonly @click:append="email(item.factoryContactEmail)"></v-text-field>
                    </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-text-field :value="item.factoryAddress" label="Factory Address" readonly></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-text-field :value="item.factoryContactName" label="Company Contact" readonly></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" v-show="item.isLeadInspector">
                    <v-text-field :value="item.isLeadInspector" label="Lead Inspector" readonly></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <v-text-field :value="item.displayResources" label="Assigned Inspectors" readonly></v-text-field>
                </v-col>
            </v-row>
        </div>

        <div v-if="data && data.length && search_type != 'job'" class="search-content" style="width:96%; margin:30px auto;">
            <v-list>
                <v-list-item v-for="(item, index) in data" :key="index" @click="go_WorkOrder_details(item)">
                    <v-list-item-icon @click.stop="option_dialog(item)">
                        <v-icon>search</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Work Order：{{item.workOrderDisplayId}}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-avatar width="150">
                        <v-list-item-title style="color: rgb(146, 146, 146);">{{item.workOrderStatus}}</v-list-item-title>
                    </v-list-item-avatar>
                </v-list-item>
            </v-list>
        </div>

        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-card-title>
                    <span >The Job ID/Work Order ID is neither not <br/> assigned to you nor existing.</span>
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import * as _ from 'lodash'

let state = {
    appHeaderState: {
        show: false
    }
};
function setState(store) {
    store.dispatch('navigation/setAppHeader', state.appHeaderState);
}

export default {
    name: 'search',
    metaInfo: {
        title: 'Search',
        titleTemplate: '%s - Lavas',
        meta: [
            {name: 'keywords', content: 'lavas PWA'},
            {name: 'description', content: '基于 Vue 的 PWA 解决方案，帮助开发者快速搭建 PWA 应用，解决接入 PWA 的各种问题'}
        ]
    },
    data() {
        return {
            query: '',
            loading: false,
            data: [],
            search_type: this.$route.params.id,
            snackbar: false,
            dialog: false,
            // color: 'red darken-2',
            // message: 'The Job ID/Work Order ID is neither not assigned to you nor existing.',
            // timeout: 3000,
        };
    },
    mounted () {
    },
    methods: {
        async search() {

            // 把数据清空
            this.data = [];

            // 显示加载动画
            this.loading = true;

            // 让当前输入框失去焦点
            this.$el.querySelector('.search-input').blur();

            // 等待 1s，模拟加载中的效果
            await new Promise(resolve => {
                setTimeout(resolve, 1000);
            });

            // 设置搜索结果数据
            this.data = [
                {
                    title: 'Ali Connors',
                    headline: 'Brunch this weekend?',
                    subtitle: 'I\'ll be in your neighborhood doing errands this weekend. Do you want to hang out?',
                    action: '15 min'
                }
            ];

            this.loading = false;
        },
        get_data () {

            this.data = [];

            this.dialog = false;

            if (this.search_type == 'job') {
                if (!this.query) {
                    this.data = this.store_jobList
                } else {
                    this.data = this.store_jobList.filter(x => x.jobDisplayId.toString().indexOf(this.query.trim()) !== -1);
                }
            } else {
                if (!this.query) {
                    this.data = this.store_workOrderList
                }else{
                    this.data = this.store_workOrderList.filter(x => x.workOrderDisplayId.toString().indexOf(this.query.trim()) !== -1);
                }
            }
            if (this.data.length === 0) {
                this.dialog = true;
            }
        },
        go_WorkOrder_details (item) {
            this.$router.push({name: 'workOrderDetailsId', params:{id: item.workOrderId}, query: {displayId: item.workOrderDisplayId}})
        },
        go_WorkOrder (item) {
            this.$router.push({name: 'workOrderId', params:{id: item.jobId}, query:{id: item.jobDisplayId}})
            this.apiFn.worklist = item.workOrders;
        },
    },
    computed: {
        ...mapState('inspection', {
            store_token: state => state.setJobList,
            store_jobList: state => state.jobList,
            store_workOrderList: state => state.workList
        }),
        displayResourceName() {
            return this.data.map(x => {
                x.displayResources = ''
                if (x.resources.length > 1) {
                    x.resources.forEach(y => {
                        if (y.isLeadInspector === false) {
                            x.displayResources = x.displayResources === undefined || x.displayResources === '' ? y.fullName :  x.displayResources + ', ' + y.fullName
                        }else{
                            x.isLeadInspector = y.fullName
                        }
                    })
                }else {                       
                    x.resources.forEach(y => {
                        x.displayResources = y.fullName
                    })
                }

                return x;                    
            })

        },
    },
    async asyncData({store, route}) {
        setState(store);
    },
    activated() {
        setState(this.$store);
    }
};
</script>

<style lang="stylus" scoped>
header
    display flex
    align-items center
    height 52px
    box-shadow 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px rgba(0,0,0,.14), 0 1px 10px rgba(0,0,0,.12)

form
    flex 1

.search-input
    width 100%
    outline none
    font-size 16px
    height 50px

.search-btn
    color #959595

.search-loading
    margin-top 30%
    display flex
    justify-content center

.search-content
    margin-top 20px

li
    list-style-type none
</style>
