<template>
  <div>
    <v-dialog v-model="showDialog" persistent max-width="900">
      <v-card id="preview-dialog">
        <v-card-title>
          <span class="headline">Photos</span>
          <v-btn depressed class="toggle_selected" @click="toggleAttachmentSelect()">{{ attachments.some(x => x.selected) ? 'Unselect All' : 'Select All'  }}</v-btn>
          <v-btn depressed class="delete_selected" v-if="attachments.some(x => x.selected)" @click="delete_selected_images()">Delete</v-btn>
        </v-card-title>
        <v-card-text v-if="orderUpdateInProgress">
          {{ closingProcessMessage }}
        </v-card-text>
        <v-card-text v-else>
          <div
            v-if="loadingInProgress"
            class="d-flex justify-center align-center"
            style="height: 100%;"
          >
            <v-progress-circular
              :size="50"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <div
            v-if="!loadingInProgress && attachments.length === 0"
            class="d-block text-center"
          >
            No images to show.
          </div>

          <div class="d-flex justify-end">
            <v-btn
              small
              class="white--text"
              color="primary darken-1"
              @click="triggerUpload()"
            >
              <v-icon>camera_alt</v-icon>
            </v-btn>
          </div>
          <draggable
            class="imglist select_img"
            v-model="attachments"
            @end="onDragChange()"
          >
            <transition-group tag="ul">
              <li
                v-for="(attachment, index) in attachments"
                :key="index"
                class="item"
              >
                <div v-if="attachment.loadingInProgress">
                  Loading...
                </div>
                <div v-else class="holder">
                  <span class="badge-number">{{ index + 1 }}</span>
                  <span
                    class="del_button"
                    @click="deleteImage(attachment.fileUploadId)"
                  >
                    <icon name="times-circle" class="icon"></icon>
                  </span>
                  <span class="checkbox_img">
                    <v-checkbox color="black" v-model="attachment.selected"></v-checkbox>
                  </span> 
                  <img class="imgItem" :src="attachment.src" />
                </div>
              </li>
            </transition-group>
          </draggable>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="btnClose"
            color="blue darken-1"
            text
            @click="closePreview()"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showProgressDialog" :persistent="true">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-container v-for="(image, index) in addedImages" :key="index">
            <v-progress-linear
              :value="image.uploadProgress"
              color="light-blue"
              height="25"
              reactive
            >
              <template v-slot="{ value }">
                <strong
                  >{{ image.fileName }}
                  {{ Math.ceil(image.uploadProgress) }}%</strong
                >
              </template>
            </v-progress-linear>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import * as _ from "lodash";

export default {
  name: "WorkOrderImageFormDialog",
  components: {
    draggable,
  },
  props: {
    showDialog: {
      type: Boolean,
    },
    workOrderId: {
      type: String,
      required: true,
    },
    sectionId: {
      type: Number,
      required: true,
    },
    descriptionFilter: String,
  },
  data() {
    return {
      attachments: [],
      addedImages: [],
      hasUpdate: false,
      showProgressDialog: false,
      orderUpdateInProgress: false,
      loadingInProgress: true,
      closingProcessMessage: "Please wait a moment...",
    };
  },
  methods: {
    loadImages() {
      this.loadingInProgress = true;
      this.utilityFn.getServerData(
        "get",
        this.apiList.getWorkOrderAttachment +
          "?workOrderId=" +
          this.workOrderId +
          "&sectionId=" +
          this.sectionId,
        {},
        (res) => {
          if (res) {
            // filter by description if descriptionFilter is provided
            this.attachments = res.data.data
              .filter(
                (x) =>
                  !this.descriptionFilter ||
                  x.description?.toLowerCase() ==
                    this.descriptionFilter?.toLowerCase()
              )
              .map((attachment) => {
                attachment.src = null;
                attachment.loadingInProgress = false;
                attachment.selected = false;
                return attachment;
              });

            // Sort the image
            this.attachments = _.orderBy(this.attachments, ["order"], ["asc"]);
            this.attachments = this.attachments.map(x => ({...x, selected: false}));
            this.loadingInProgress = false;

            // Load the images
            this.attachments.forEach((v, i) => {
              this.loadImage(i, v.fileUploadId);
            });
          }
        }
      );
    },
    loadImage(index, id) {
      if (this.attachments[index].loadingInProgress) return;

      this.attachments[index].loadingInProgress = true;
      this.apiFn.getImage(id).then((result) => {
        let src = "data:" + result.fileType + ";base64," + result.data;
        this.attachments[index].src = src;
        this.attachments[index].loadingInProgress = false;
      });
    },
    deleteImage(id) {
      this.hasUpdate = true;
      this.attachments = this.attachments.filter((x) => x.fileUploadId != id);
      this.apiFn.deleteImage(id).then((result) => {});
    },
    toggleAttachmentSelect() {
      if(this.attachments.some(x => x.selected)) {
        this.attachments = this.attachments.map((x) => {
          x.selected = false;
          return x;
        });
      }
      else {
        this.attachments = this.attachments.map((x) => {
          x.selected = true;
          return x;
        });
      }      
    },
    delete_selected_images() {
      if(confirm("Are you sure to delete photo(s)?")) {
        let attachmentsToDelete = this.attachments.filter(x => x.selected).map(x => x.fileUploadId);
        _.forEach(attachmentsToDelete, (x) => {        
          this.deleteImage(x);
        });
      }
    },
    triggerUpload(e) {
      let filesToUpload = []; // files under 500 kb will be uploaded
      let filesToExclude = []; // files over 500 kb will not be uploaded
      var inputObj = document.createElement("input");
      inputObj.setAttribute("accept", "image/*");
      inputObj.setAttribute("multiple", "multiple");
      inputObj.setAttribute("id", "_ef");
      inputObj.setAttribute("type", "file");
      inputObj.setAttribute("style", "visibility:hidden");
      document.body.appendChild(inputObj);
      inputObj.click();
      inputObj.onchange = (res) => {
        this.hasUpdate = true;
        for (let i = 0; i < inputObj.files.length; i++) {
          const file = inputObj.files[i];
          var sizeInfo = this.utilityFn.formatBytes(file.size);
          if (sizeInfo.sizeKB > 500) {
            filesToExclude.push(file);
          } else {
            filesToUpload.push(file);
          }
        }

        if (filesToExclude.length > 0) {
          const filesNames = filesToExclude.map((x) => x.name).join(", ");
          this.$store.dispatch("navigation/setSnackbar", {
            color: "error",
            timeout: 10000,
            text: `Each upload only accepts image size less than 500KB. Please resize/compact the image with size greater than 500KB. Filename(s): ${filesNames}.`,
          });
        }

        if (filesToUpload.length > 0) {
          this.uploadFiles(filesToUpload);
        }

        inputObj.remove();
      };
    },
    uploadFiles(files) {
      this.showProgressDialog = true;
      const that = this;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const addedImage = {
          id: new Date().getT,
          uploadProgress: 0,
          fileName: file.name,
        };
        this.addedImages.push(addedImage);

        const form = new FormData();
        form.append("image", file);
        form.append("order", this.attachments.length + 1);
        form.append("description", this.descriptionFilter);
        form.append("workOrderId", this.workOrderId);
        form.append("screen", this.sectionId);

        this.utilityFn.uploadImg(
          this.apiList.uploadWorkOrderImage,
          {
            formData: form,
          },
          (progress) => {
            const imgIndex = this.addedImages.findIndex(
              (x) => x.id === addedImage.id
            );
            this.addedImages[imgIndex].uploadProgress = progress;
          },
          (res) => {
            const imgIndex = this.addedImages.findIndex(
              (x) => x.id === addedImage.id
            );
            this.addedImages.splice(imgIndex, 1);
            this.showProgressDialog = this.addedImages.length !== 0;

            var reader = new FileReader();
            reader.onload = function(event) {
              if (
                that.attachments.findIndex(
                  (x) => x.fileUploadId == res.fileUploadId
                ) === -1
              ) {
                that.attachments.push({
                  ...res.data,
                  selected: false,
                  src: event.target.result,
                });
              }
            };
            reader.readAsDataURL(file);
          },
          (errRes) => {
            this.showProgressDialog = false;
            alert(
              "Photo saved offline, will auto update when device is online"
            );
          }
        );
      }
    },
    onDragChange() {
      this.hasUpdate = true;
    },
    closePreview() {
      if (this.attachments.length === 0) {
        this.$emit("update:showDialog", false);
        return;
      }

      if (this.orderUpdateInProgress) return;

      if (this.hasUpdate) {
        this.orderUpdateInProgress = true;
        // Before closing the dialog, save the order of the image
        this.attachments.forEach((v, index) => {
          v.order = index + 1;
          v.isIncludeInCFR = true;
          v.sectionId = this.sectionId;
          v.src = null;
        });
        this.utilityFn.getServerData(
          "put",
          this.apiList.updateWorkOrderImages,
          this.attachments,
          () => {
            this.orderUpdateInProgress = false;
            this.$emit("update:showDialog", false);
          }
        );
      } else {
        this.$emit("update:showDialog", false);
      }
    },
  },
  watch: {
    showDialog(val) {
      this.attachments = [];
      this.addedImages = [];
      this.hasUpdate = false;
      this.showProgressDialog = false;
      this.orderUpdateInProgress = false;

      this.$nextTick(() => {
        if (val) {
          this.loadImages();
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
ul
	padding 0
.row
	background: #fff
	margin: 15px

.item
	display: inline-block
	width: 10%
	margin: 1.14%
 margin-bottom: 25px

.holder
	position: relative

.imglist .holder .imgItem
	width: 100%
	box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.3)


.imgItem.updateSelected
	border: 2px solid #c02032

.badge-number
	position: absolute
	bottom: 0
	right: 0
	padding: 8px
	background-color: #fff
	box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.3)
	border-radius: 100px
	font-weight: bold
	font-size: 10px

.public_img, .select_img
	text-align: left
	// border 1px #ccc solid
	background: #ffffff
	padding: 10px 0

.public_img .imgItem
	width: 12%;
	margin: 1.14%;

.del_button
	position: absolute
	right: -3px;
	top: -5px;

.checkbox_img
  position: absolute;
  bottom: -45px;
  left: -5px;

.delete_selected
  margin-left:16px;

.toggle_selected
  margin-left:16px;
</style>
