import { SET_APP_HEADER, SET_PAGE_SWITCHING, SET_PAGE_SCROLL_POSITION,SET_PAGE_TRANSITION_TYPE,
    SET_PAGE_TRANSITION_EFFECT,SET_SIDEBAR_VISIBILITY,SET_SNACKBAR,SET_LOADING_PAGE,
    SET_ONLINE } from './mutation.types'

export default {
    [SET_APP_HEADER](state, payload) {
        state.header = Object.assign(state.header, payload);
    },
    [SET_PAGE_SWITCHING](state, isPageSwitching) {
        state.common.isPageSwitching = isPageSwitching;
    },

    [SET_PAGE_SCROLL_POSITION](state, { pageId, scrollPosition }) {
        state.common.scrollPostionMap = {
            ...state.common.scrollPostionMap,
            [pageId]: scrollPosition
        };
    },
    [SET_PAGE_TRANSITION_TYPE](state, type) {
        state.pageTransition.type = type;
    },
    [SET_PAGE_TRANSITION_EFFECT](state, effect) {
        state.pageTransition.effect = effect;
    },
    [SET_SIDEBAR_VISIBILITY](state, sidebarVisibility) {
        state.sidebar.show = sidebarVisibility;
    },
    [SET_SNACKBAR](state, snackbar) {
        state.snackbars = state.snackbars.concat(snackbar);
    },
    [SET_LOADING_PAGE](state, loadingPage) {
        state.loadingPage = loadingPage
    },
    [SET_ONLINE](state, payload) {
        state.header.online = payload;
    },
	
   
};