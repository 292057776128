<template>
  <div>
    <div class="uploadPhotoId">
        <v-col cols="12" sm="12" v-if="groupedAttachments.length > 0">
          <v-btn depressed class="delete_selected"  @click="delete_all_images()">Delete All Inspection Photos</v-btn>
        </v-col>
      <v-row v-for="(groupAttachment, gaIndex) in groupedAttachments" :key="gaIndex">
        <v-col cols="12" sm="12">
          <span class="section_header">{{ groupAttachment.description }}</span>
          <v-btn depressed class="delete_selected" v-if="groupAttachment.attachments.length > 0" @click="delete_all_images_per_group(gaIndex, groupAttachment.attachments)">Delete All</v-btn>
        </v-col>
        <v-col cols="12" sm="12">
          <draggable class="imglist select_img" v-model="groupAttachment.attachments">
            <transition-group tag="ul">
              <li v-for="(attachment, aIndex) in groupAttachment.attachments" :key="aIndex" class="item">
                <div v-if="!attachment.src">
                  Loading...
                  {{ loadImage(gaIndex, aIndex, attachment.fileUploadId) }}
                </div>
                <div v-if="attachment.src === 'loading'">
                  Loading...
                </div>
                <div v-else class="holder">
                  <span class="badge-number">{{ aIndex + 1 }}</span>
                  <span v-if="!isImageTemporary(attachment) || !showProgressDialog" class="del_button" @click="del_img(gaIndex, aIndex, attachment.fileUploadId)">
                    <icon name="times-circle" class="icon"></icon>
                  </span>
                  <img class="imgItem" :class="{ updateSelected: attachment.updateSelected, unsavedImage: attachment.fileUploadId.includes('temp_') }" :src="attachment.src" @click="add_img_update(gaIndex, aIndex)" />
                </div>
              </li>
            </transition-group>
          </draggable>
        </v-col>
      </v-row>

      <v-row>
        <div v-show="addedImages.length > 0 && !fileNameApplied">
          <draggable class="imglist select_img" v-model="addedImages">
            <transition-group tag="ul">
              <li v-for="(attachment, index) in addedImages" :key="index" class="item">
                <div v-if="!attachment.src">
                  Loading...
                  {{ loadTempImage(index, attachment.file) }}
                </div>
                <div v-else class="holder">
                  <span class="badge-number">{{ index + 1 }}</span>
                  <span class="del_button" @click="del_temp_img(attachment.id)">
                    <icon name="times-circle" class="icon"></icon>
                  </span>
                  <img class="imgItem unsavedImage" :src="attachment.src" />
                </div>
              </li>
            </transition-group>
          </draggable>
        </div>
      </v-row>

      <v-row>
        <!-- <div style="text-align:left; margin:0 2%; margin-top: 40px;"> -->
          <!-- <select v-model="description" style="border: 1px #ccc solid;background: #fff;padding: 10px;width: 250px;" :disabled="addedImages.length == 0">
            <option v-for="(item, index) in descriptionList" :key="index" :value="item">{{ item }}</option>
          </select> -->
        <v-col cols="12" sm="5" md="4">
          <v-autocomplete v-model="description" style="border: 1px #ccc solid;background: #fff;padding: 1px 5px 1px 5px" :items="descriptionList" auto-select-first clearable :disabled="addedImages.length == 0"></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="2" md="1">
          <van-button type="default" @click="uploadImages" v-show="toBeUpdatedImages.length === 0" :disabled="!isAddPhotoEnabled">{{ submitButtonText }}</van-button>
        </v-col>     
        <v-col cols="12" sm="3" md="2">
          <van-button type="default" @click="onImageSelection">
            Add Photo from Gallery
          </van-button>
        </v-col>
        <!-- <v-col cols="12" sm="3" md="2">
          <van-button type="default" @click="updateImageDescription" v-if="toBeUpdatedImages.length > 0" :disabled="!isUpdatePhotoEnabled">Update</van-button>
        </v-col> -->
        
        <!-- </div> -->
      </v-row>

      <v-row style="margin: 7px;" v-show="showOtherDescription">
        <v-col cols="12" sm="12">
          <div class="d-flex flex-column">
            <v-text-field v-model="otherDescription" label="Decription" placeholder="Enter Other Photo Description here"></v-text-field>
            <v-btn style="width: 150px" @click="applyFileName">Apply File Name</v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="showProgressDialog" :persistent="true">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-container v-for="(image, index) in addedImages" :key="index">
            <v-progress-linear :value="image.uploadProgress" color="light-blue" height="25" reactive>
              <template v-slot="{ value }">
                <strong>{{ image.fileName }} {{ Math.ceil(image.uploadProgress) }}%</strong>
              </template>
            </v-progress-linear>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showIndeterminateProgressDialog" :persistent="true">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <div class="text-center">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import * as _ from "lodash";
import { mapActions, mapState } from "vuex";
let state = {
  appHeaderState: {
    show: true,
    title: "Stock Inspected:",
    showMenu: true,
    showBack: true,
    showLogo: false,
    showTitle: true,
    showSearch: false,
    showCalendar: true,
  },
};

function setState(store) {
  store.dispatch("navigation/setAppHeader", state.appHeaderState);
}
export default {
  name: "uploadPhotoId",

  components: {
    draggable,
  },

  data() {
    return {
      imageList: [],
      getRecord: [],
      descriptionList: [],
      description: "",
      otherDescription: "",
      selectedWorkOrder: {},
      workOrderProduct: {},
      attachments: [],
      groupedDescriptions: [],
      groupedAttachments: [], // group by attachment description
      addedImages: [],
      toBeUpdatedImages: [],
      showProgressDialog: false,
      showIndeterminateProgressDialog: false,

      // 提交按钮，如果是添加为Add to photo 如果为编辑为Update
      submitButtonText: "Add to photo",
      isUpdate: false,
      isWalmart: false,
      fileNameApplied: false
    };
  },
  mounted() {
    if (this.$route.query.type) {
      this.submitButtonText = "Update";
    }
    this.validateIfWalmart();

    this.utilityFn.getServerData("get", this.apiList.woDetailsToUploadPhotoAttch + "?workOrderId=" + this.$route.params.id + "&productId=" + this.$route.query.id + "&sectionId=11", {}, (res) => {
      this.workOrderProduct = res.data.data[0].products.find((x) => x.id === this.$route.query.id);
      this.attachments = this.workOrderProduct.workOrderProductAttachments
        .filter((attachment) => attachment.sectionId === 11)
        .map((attachment) => {
          attachment.src = null;
          attachment.added = true;
          attachment.selected = false;
          return attachment;
        });

      this.groupTheAttachments();
      this.initState();
    });

    if (this.$route.params.name) {
      state.appHeaderState.title = this.$route.params.name + " > Add Defect Photo";
    }
    setState(this.$store);
    this.setSessionDetails();
  },
  methods: {
    testLog(item) {
      console.log("testLog", item);
    },
    initState() {
      state.appHeaderState.title = (this.$route.params.headerTitle || this.$route.query.headerTitle) + ": " + this.workOrderProduct.skuDisplay;
      setState(this.$store);
    },
    onImageSelection(event) {
      var inputObj = document.createElement("input");
      inputObj.setAttribute("accept", "image/*");
      inputObj.setAttribute("multiple", "multiple");
      inputObj.setAttribute("id", "_ef");
      inputObj.setAttribute("type", "file");
      inputObj.setAttribute("style", "visibility:hidden");
      document.body.appendChild(inputObj);
      inputObj.click();
      inputObj.onchange = (res) => {
        this.isUpdate = false;
        this.clearUpdateSelection();
        const loadImage = async () => {
          for (let i = 0; i < inputObj.files.length; i++) {
            let file = inputObj.files[i];
            var sizeInfo = this.utilityFn.formatBytes(file.size)    
            if(sizeInfo.sizeKB > 500){
              this.$store.dispatch("navigation/setSnackbar",{
                color :'error',
                text:`Each upload only accepts image size less than 500KB. Please resize/compact the image with size greater than 500KB. Filename: ${file.name}.`});
            }
            else{
              //this.getBase64(file).then(base64 => {
              const tempId = "temp_" + this.addedImages.length;
              const attachment = {
                fileUploadId: tempId, // add a marker/flag to determine if this image is not yet in the database
                src: await returnBase64(file),
                added: false,
                description: this.finalDescription,
                fileName: file.name.replace(/\.[^/.]+$/, ""),
                workOrderProductId: this.workOrderProduct.id,
                file
              };
              this.attachments.push(attachment);
              this.addedImages.push({
                file,
                uploadProgress: 0,
                id: tempId,
                src: null,
              });
            }         
            // })
          }
        };

        const returnBase64 = (file) => {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              var reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            }, 500);
          });
        };

        loadImage().then(() => {
          console.log("done");
        });

        this.logChanges();
        
        inputObj.remove();
      };
    },

    // will generate a file object
    getImage(index) {
      return URL.createObjectURL(this.imageList[index]);
    },

    uploadImages() {
      // 弹出上传进度
      const _addedImages = this.attachments.filter(x => x.fileUploadId?.includes('temp_')).map(x => ({...x, id: x.fileUploadId}));
      if (_addedImages.length == 0){
        alert('No Photo to add');
        return;
      }
      if (window.navigator.onLine) {
        this.showProgressDialog = true;
      } else {
        alert("Photo saved offline, will auto update when device is online");
      }
      // const uploadImage = async () => {
      for (let i = 0; i < _addedImages.length; i++) {
        const addedImage = { ..._addedImages[i] };
        let description = this.fileNameApplied ? addedImage.description: this.finalDescription;
        var formData = new FormData();
        formData.append("image", addedImage.file);
        formData.append("description", description);
        formData.append("workOrderProductId", this.workOrderProduct.id);
        formData.append("screen", 11);
        formData.append("order", (this.groupedAttachments.length + 1) * 100 + (i + 1));
        formData.append("selectedDescription",this.description);

        // await uploadImg(formData,_addedImages,addedImage)
        var data = { formData: formData, base64: addedImage.src };

        this.utilityFn.uploadImg(
          this.apiList.uploadWorkOrderProductImage,
          data,
          (progress) => {
            const index = _addedImages.findIndex((x) => x.id === addedImage.id);
            _addedImages[index].uploadProgress = progress;
          },
          (result) => {
            // update the file upload id of the newly added image
            const index = this.attachments.findIndex((x) => x.fileUploadId === addedImage.id);
            if (index !== -1) {
              this.attachments[index].fileUploadId = result.data.fileUploadId;
              this.attachments[index].description = result.data.description;
              this.attachments[index].order = result.data.order;
            }

            const imgIndex = this.addedImages.findIndex((x) => x.id === addedImage.id);
            this.addedImages.splice(imgIndex, 1);
            this.checkUploadStatus();
          },
          (errRes) => {
            if (window.navigator.onLine != true) {
              this.addedImages = [];
              this.groupedAttachments = [];
              this.description = "";
              this.otherDescription = "";
            }
          }
        );
      }

      this.logChanges();
    },

    del_temp_img(tempImgId) {
      let index = this.addedImages.findIndex((x) => x.id === tempImgId);
      this.addedImages.splice(index, 1);
      this.addedImages = this.addedImages.map((a, i) => {
        a.id = "temp_" + i;
        return a;
      });

      index = this.attachments.findIndex((x) => x.id === tempImgId);
      this.attachments.splice(index, 1);
    },

    del_img(groupedAttachmentIndex, attachmentIndex, imageId) {
      const attIndex = this.attachments.findIndex(x => x.fileUploadId == imageId)
      const delImg = this.groupedAttachments[groupedAttachmentIndex].attachments[attachmentIndex];
      this.groupedAttachments[groupedAttachmentIndex].attachments.splice(attachmentIndex, 1);
      if (this.groupedAttachments[groupedAttachmentIndex].attachments.length <= 0){
        this.groupedAttachments.splice(groupedAttachmentIndex,1);
        this.attachments.splice(attIndex,1)
      }
      // check if the image being deleted is a newly added image (which is not yet uploaded)
      // if not, then call the api
      if (imageId.indexOf("temp_") === -1) {
        this.apiFn.deleteImage(imageId).then((result) => {});

        this.logChanges(delImg.description);
      }
    },

    delete_all_images_per_group(gaIndex, attachments) {
      if(confirm("Are you sure to delete photo(s)?")) {
        var del_img_ids = attachments.map(x => x.fileUploadId);

        //delete each image
        _.forEach(del_img_ids, (x) => {        

          let index = attachments.findIndex((y) => y.fileUploadId === x);
          this.groupedAttachments[gaIndex].attachments.splice(index, 1);
    
          const attIndex = this.attachments.findIndex(y => y.fileUploadId == x)
          this.attachments.splice(attIndex,1)

          this.apiFn.deleteImage(x).then((result) => {});

          //remove section if it has no attachments
          if (this.groupedAttachments[gaIndex].attachments.length <= 0){
            this.groupedAttachments.splice(gaIndex,1);
            this.attachments.splice(attIndex,1)
          }
        });
        this.logChanges();
      }      
    },

    go_gallery() {
      this.$router.push({ name: "uploadPhotoGallery" });
    },

    loadImage(groupedAttachmentIndex, attachmentIndex, imageId) {
      this.groupedAttachments[groupedAttachmentIndex].attachments[attachmentIndex].src = "loading";
      this.groupedAttachments[groupedAttachmentIndex].attachments = [...this.groupedAttachments[groupedAttachmentIndex].attachments];

      this.apiFn.getImage(imageId).then((result) => {
        let src = "data:" + result.fileType + ";base64," + result.data;
        this.groupedAttachments[groupedAttachmentIndex].attachments[attachmentIndex].src = src;
        this.groupedAttachments[groupedAttachmentIndex].attachments = [...this.groupedAttachments[groupedAttachmentIndex].attachments];
      });
    },

    loadTempImage(index, file) {
      this.getBase64(file).then((base64) => {
        this.addedImages[index].src = base64;
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    checkUploadStatus() {
      this.showProgressDialog = this.addedImages.length !== 0;
      if (!this.showProgressDialog) {
        this.description = "";
        this.otherDescription = "";
        this.groupTheAttachments();
        this.fileNameApplied = false;
      }
    },
    // check if the image being displayed is a newly added image
    isImageTemporary(attachment) {
      return attachment.fileUploadId.indexOf("temp_") !== -1;
    },

    getFilteredAttachments(description) {
      return this.attachments.filter((x) => x.description === description);
    },

    upperCase(description) {
      return _.startCase(_.toLower(description));
    },

    groupTheAttachments(keepPendingUploadImages = false) {
      // group attachments by description
      if (!keepPendingUploadImages) {
        this.addedImages = [];
      }
      this.groupedAttachments = [];

      // this.groupedDescriptions = _.uniq(_.map(this.attachments, 'description'));
      this.groupedDescriptions = this.getUniqueDescription();
      this.groupedDescriptions.forEach((description) => {
        const filteredAttachments = this.attachments.filter((x) => x.description === description);
        this.groupedAttachments.push({
          description,
          attachments: _.orderBy(filteredAttachments, ["order"], ["asc"]),
        });
      });
    },

    saveImageOrder() {
     

      this.groupedAttachments.forEach((g, gIndex) => {
        const base = (gIndex + 1) * 100;
        g.attachments.forEach((a, aIndex) => {
          a.order = base + aIndex + 1;
          a.isIncludeInCFR = true;
          a.sectionId = 11;
          a.src = null;
        });
        this.utilityFn.getServerData("put", this.apiList.updateWorkOrderProductImages, g.attachments,
         (result) => {},(errFn) => {});
        // this.apiFn.updateWorkOrderProductImages(g.attachments);
      });
    },

    updateImageDescription() {
      this.toBeUpdatedImages.map((img, i) => {
        img.description = this.finalDescription;
        img.order = (this.groupedDescriptions.length + 1) * 100 + (i + 1);
        return img;
      });

      this.showIndeterminateProgressDialog = true;
      this.apiFn.updateWorkOrderProductImages(this.toBeUpdatedImages).then((x) => {
        this.toBeUpdatedImages.forEach((img) => {
          const index = this.attachments.findIndex((a) => a.fileUploadId === img.fileUploadId);
          this.attachments[index].description = img.description;
        });

        this.clearUpdateSelection();
        this.isUpdate = false;
        this.description = "";
        this.otherDescription = "";
        this.toBeUpdatedImages = [];
        this.groupTheAttachments();
        this.showIndeterminateProgressDialog = false;
      });

      // this.logChanges();
    },

    add_img_update(gaIndex, aIndex) {
      this.isUpdate = true;
      const attachment = this.groupedAttachments[gaIndex].attachments[aIndex];
      const index = this.toBeUpdatedImages.findIndex((x) => x.fileUploadId === attachment.fileUploadId);

      if (index === -1) {
        this.toBeUpdatedImages.push(attachment);
        this.groupedAttachments[gaIndex].attachments[aIndex].updateSelected = true;
      } else {
        this.toBeUpdatedImages.splice(index, 1);
        this.groupedAttachments[gaIndex].attachments[aIndex].updateSelected = false;
      }

      this.groupedAttachments[gaIndex].attachments = [...this.groupedAttachments[gaIndex].attachments];
    },

    clearUpdateSelection() {
      this.toBeUpdatedImages.forEach((img) => {
        const index = this.attachments.findIndex((a) => a.fileUploadId === img.fileUploadId);
        if (index !== -1)
          this.attachments[index].updateSelected = false;
      });
      this.toBeUpdatedImages = [];
    },

    getUniqueDescription() {
      const descriptions = [];
      this.attachments = _.orderBy(this.attachments, ["order"], ["asc"]);
      this.attachments.forEach((att) => {
        if (descriptions.indexOf(att.description) === -1) {
          descriptions.push(att.description);
        }
      });
      return descriptions;
    },

    logChanges(description) {
      window.sessionStorage.setItem("EditDataLogging", true);
      window.sessionStorage.setItem("WorkOrderProductId", this.workOrderProduct.id);
      window.sessionStorage.setItem("ImageDescription", description || this.finalDescription);
    },

    setSessionDetails() {
      window.sessionStorage.setItem("SectionId", 11);
    },

    validateIfWalmart() {
      let val = this._workOrderDetails
      if (val == null) {
        this.descriptionList = []
      }
      else {      
        this.isWalmart= val.clientName == 'WALMART GLOBAL SOURCING' || val.clientName.toUpperCase() == 'ASDA STORES LIMITED' ? true : false
        if (this.isWalmart) {
          // validate if the Industry is exist
          this.getListDescription(val.industryName)
        } 
        else {
          this.utilityFn.getServerData("get", this.apiList.getAllStockInspectedOriginalList, {}, (result) => {
            this.defaultList = result.data.data;
            this.defaultList.forEach(e => {
              this.descriptionList.push(e.description)
            });
          });
        }
      }

    },
    getListDescription(val) {
      this.utilityFn.getServerData("get", this.apiList.getApplicableIndustryList + "/" + val, {}, (result) => {
       
        this.getRecord = result.data.data;
        for (let index = 0; index < this.getRecord.length; index++) {          
          // populate to descriptionList
          this.descriptionList.push(this.getRecord[index].description)
        }
       
      });
    },
    applyFileName() {
      const pendingUploadImages = this.attachments.filter(x => x.fileUploadId?.includes('temp_'));
      const separator = '-';
      pendingUploadImages.forEach(img => {
        const index = img.fileName.indexOf(separator);
        if (index !== -1) {
          img.description = img.fileName.substring(index + separator.length, img.fileName.length).trim();
        } else {
          img.description = img.fileName;
        }
      });
      this.groupTheAttachments(true);
      this.fileNameApplied = true;
      this.otherDescription = '';
    },
    delete_all_images() {
      if(confirm("Do you want to delete ALL Inspection photos?")) {
        if (confirm("Are you sure?")){

          this.groupedAttachments.forEach((groupedAttachment,gaIndex) => {
              groupedAttachment.attachments.forEach((attachement,index) => {
                this.apiFn.deleteImage(attachement.fileUploadId).then((result) => {});
              })
          });

          this.groupedAttachments.forEach(groupedAttachement => {
            groupedAttachement.attachments = [];
          });
          this.groupedAttachments = [];
          this.attachments = [];
          this.logChanges();
        }
      }      
    }
  },
  computed: { 
    ...mapState("inspection", {
      _workOrderDetails: (state) => state.workOrderDetails
    }),

    showOtherDescription() {
      return this.description.toLowerCase().includes("others");
    },
    finalDescription() {
      return this.description.toLowerCase ().includes("others") ? this.otherDescription : this.description;
    },

    isAddPhotoEnabled() {
      return this.addedImages.length > 0 && !this.showProgressDialog && (this.finalDescription || this.fileNameApplied);
    },

    isUpdatePhotoEnabled() {
      return this.finalDescription && this.toBeUpdatedImages.length > 0 && this.isUpdate;
    },

  },
  watch: {
    finalDescription(val) {
      this.logChanges();
    },
  },
  beforeDestroy() {
    window.sessionStorage.removeItem("ImageDescription");
    window.sessionStorage.removeItem("WorkOrderProductId");
    this.saveImageOrder();
  },
};
</script>

<style lang="stylus" scoped>
.row
    background #fff
    margin 15px
    padding 15px 0
.item
    display inline-block
    width 100px
    margin 1.14%
.holder
    position relative
.imglist .holder .imgItem
    width 100%
    box-shadow: -1px 1px 5px rgba(0,0,0,.3);
.imgItem.updateSelected
    border: 2px solid #c02032;
.badge-number
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 8px;
    background-color: #fff;
    box-shadow: -1px 1px 3px rgba(0,0,0,.3);
    border-radius: 10px;
    font-weight: bold;
    font-size: 12px;
.public_img, .select_img
    text-align left
    // border 1px #ccc solid
    width 96%
    margin 20px auto
    background #ffffff
    padding 10px 0
.public_img .imgItem
    width 12%
    margin 1.14%

.del_button
    position absolute
    right -3px
    top -5px

.checkbox_img
  position: absolute;
  bottom: -45px;
  left: -5px;
    
.delete_selected
  margin-left:16px;

.section_header
  font-size: 1.17em;
  font-weight: bold;

.unsavedImage
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(255,0,0) 0px 0px 0px 3px !important;
</style>
