<template>
  <div>
    <div class="workOrderDefectId">
      <v-snackbar class="snackbar-loading" v-model="loading" top :timeout="-1" color="#4caf50">
        <v-progress-circular :size="15" indeterminate color="primary"></v-progress-circular>
        <span style="padding-left:10px;">Loading</span>
      </v-snackbar>
      <v-snackbar id="remarks_snackbar" v-model="snackbar" :timeout="timeout" :color="color">
        {{ message }}
        <v-btn text id="remarks_snackbar_close" @click="snackbar = false">Close</v-btn>
      </v-snackbar>

      <v-row>
        <v-col sm="8" md="10">
          <AutocompleteSearch :url="getStatementGlossaryUrl" @onSelect="onSelect"></AutocompleteSearch>
        </v-col>
        <v-col sm="2" md="2">
          <v-btn @click="addStatement()">Add</v-btn>
        </v-col>
      </v-row>
      <div class="showWysiwyg">
        <wysiwyg v-model="myHTML" />
      </div>

      <div class="hideFroala">
        <froalaView id="myHTMLId" v-model="myHTML" />
      </div>
      <!-- <froala v-model="myHTML" :config="config" /> -->
      <br />
      <div v-if="isWalmart">{{ characterCount }} characters</div>
      <!-- <v-textarea filled name="input-7-1" placeholder="Description" v-model="formData.description"></v-textarea> -->
      <v-select v-bind:items="remarkClassificationList" item-text="description" item-value="id" v-model="formData.classification" label="Select Classification" single-line bottom></v-select>

      <v-btn :disabled="isDisableSubmitButton() || isSubmitted" class="submit" color="#B14C4C" @click="submit()">Submit</v-btn>
      <!-- <v-btn class="submit" dark color="#B14C4C" @click="submit">Submit</v-btn> -->
      <!-- <v-btn class="submit" dark color="#B14C4C" @click="submit">Submit</v-btn> -->
    </div>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ msg }}</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import html2canvas from "html2canvas";
import AutocompleteSearch from "../../../components/common/AutocompleteSearch";

let state = {
  appHeaderState: {
    show: true,
    title: "Remarks & Comments",
    showMenu: true,
    showBack: true,
    showLogo: false,
    showTitle: true,
    showSearch: false,
    showCalendar: true,
  },
};

function setState(store) {
  store.dispatch("navigation/setAppHeader", state.appHeaderState);
}

export default {
  name: "workOrderDefectId",
  components: {
    AutocompleteSearch,
  },
  data() {
    return {
      getStatementGlossaryUrl: "",
      // data : '',
      myHTML: "",
      id: -1,
      imageList2: [],
      colNumber: 0,
      rowNumber: 0,
      dialog: false,
      msg: "",
      tableList: [],
      // remarkClassificationList: ['Fail', 'Pending', 'Informative'],
      remarkClassificationList: [
        { id: 1, description: "Fail" },
        { id: 2, description: "Pending" },
        { id: 3, description: "Informative" },
        { id: 4, description: "Abort" },
        { id: 5, description: "Pass"}, 
        { id: 6, description: "CAPA"},
        { id: 7, description: "Not Ready"}
      ],
      formData: {
        id: null,
        workOrderId: null,
        description: null,
        classification: null,
        SectionId: 12,
        fileUploadId: "",
      },
      config: {
        placeholderText: "Description",
      },
      getWorkOrderId: "",
      testGetCanvas: [],
      loading: false,
      existingRecord: [],
      isSubmitted: false,
      snackbar: false,
      message: "",
      timeout: 3000,
      color: "red darken-2",
      selectedStatement: null
    };
  },
  activated() {
      this.isSubmitted = false;
      this.loading = false;
  },
  mounted() {
    this.getStatementGlossaryUrl = this.apiList.getStatementGlossary;
    this.formData.id = this.$route.params.data.id;
    this.formData.workOrderId = this.$route.params.id;
    this.existingRecord = [...this.remarksLists];
    this.utilityFn.getServerData("get", this.apiList.getWorkOrderRemarks + "/" + this.formData.workOrderId, {}, (result) => {
      this.existingRecord = result.data.data;
      if (this.$route.params.action == "edit") {
        const index = result.data.data.findIndex((x) => x.id == this.formData.id);
        if (index !== -1) {
          this.formData.description = result.data.data[index].description;
          this.formData.classification = result.data.data[index].classification;
          this.formData.order = result.data.data[index].order;
          this.myHTML = result.data.data[index].description;
        }
      }
    });

    this.id = this.$route.params.id;

    if (this.id == 1 || this.id == 2) {
      this.imageList2.push("https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1");
      this.imageList2.push("https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1");
    }

    if (this.id == 1) {
      this.tableList.push([1321, 233, 3213], ["sasa", "www", "ddd"]);
    }
    setState(this.$store);
  },
  methods: {
    isDisableSubmitButton() {
      return this.myHTML == "" || this.formData.classification == "" || this.myHTML == null || this.formData.classification == null ? true : false;
    },
    convertFileToImage() {
      let apiUrl = this.apiList.uploadWorkOrderImage;
      let apiRemarks = this.apiList.upsertWorkOrderRemarks;
      let utilityFnLink = this.utilityFn;
      let getCanvas;
      let wOId = this.$route.params;
      let ListOfForm = this.formData;
      let store_token = this.store_token;
      let showSnackbar = this.loading;
      let goBack = this.$router;
      let store = this.$store;
      let that = this;
      html2canvas(document.getElementById("myHTMLId")).then(async function(canvas) {
        var screenshot = canvas.toDataURL("image/png");

        const url = screenshot;
        fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], "File name", {
              type: "image/png",
            });
            getCanvas = file;

            that.getBase64(file).then((base64) => {
              getCanvas.base64 = base64;

              let formData = new FormData();

              formData.append("image", getCanvas);
              formData.append("description", "Remarks");
              formData.append("workOrderId", wOId.id);
              formData.append("screen", 12);

              let data = {
                workOrderId: wOId.id,
                description: ListOfForm.description,
                classification: ListOfForm.classification,
                sectionId: 12,
                screen: 12,
                image: getCanvas.base64,
              };

              if (that.$route.query.action == "edit") {
                data.id = that.$route.query.data;
              }
              utilityFnLink.getServerData(
                "post",
                apiRemarks,
                {
                  id: that.$route.params.data.id,
                  workOrderId: wOId.id,
                  description: ListOfForm.description,
                  classification: ListOfForm.classification,
                  sectionId: 12,
                  screen: 12,
                  image: getCanvas.base64,
                  validateCharCount: that.isWalmart,
                  order: ListOfForm.order
                },
                (res) => {
                  store.dispatch("inspection/setUpdatedRemarksEntry", res.data);
                  that.loading = false;
                  goBack.go(-1);
                },
                async (error) => {
                  if (window.navigator.onLine != true) {
                    that.message = that.msgList.NoConnection;
                    that.snackbar = true;
                  } 
                  else {
                    const validationMessage = error?.response?.data;
                    if (validationMessage) {
                      that.message = validationMessage;
                      that.snackbar = true;
                      that.loading = false;
                      that.isSubmitted = false;
                      return;
                    }
                  }

                  if (that.$route.params.data.id) {
                    store.dispatch("inspection/setUpdatedRemarksEntry", {
                      id: that.$route.params.data.id,
                      workOrderId: wOId.id,
                      description: ListOfForm.description,
                      classification: ListOfForm.classification,
                      sectionId: 12,
                      screen: 12,
                      image: getCanvas.base64,
                    });
                  } else {
                    store.dispatch("inspection/setUpdatedRemarksEntry", {
                      id: new Date().getTime(),
                      workOrderId: wOId.id,
                      description: ListOfForm.description,
                      classification: ListOfForm.classification,
                      sectionId: 12,
                      screen: 12,
                      image: getCanvas.base64,
                      isOffline: 1,
                      order: ListOfForm.order
                    });
                  }
                  that.loading = false;
                  //let url = 'https://qaulcanyon.azurewebsites.net/api/workorderdata/GetWorkOrderRemarks/' + wOId.id;
                  let url = that.apiList.getWorkOrderRemarks + "/" + wOId.id;
                  let requestBody = {
                    id: new Date().getTime(),
                    workOrderId: wOId.id,
                    description: ListOfForm.description,
                    classification: ListOfForm.classification,
                    sectionId: 12,
                    screen: 12,
                    isOffline:1,
                    order: ListOfForm.order
                  }
                  //not working need to recheck
                  await that.utilityFn.updateCache('canyon-api',url,'application/json; charset=utf-8',requestBody)
                  goBack.go(-1);
                }
              );

              that.logChanges();
            });
          });
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    submit_item() {},
    del_img(index, obj) {
      obj.splice(index, 1);
    },
    add_new_photo_label() {
      this.newLabelList.push({
        value: "",
        imageList: [],
      });
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
    },
    add_table_dialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    add_table() {
      for (var i = 0; i < Number(this.rowNumber); i++) {
        var arr = [];
        for (var x = 0; x < Number(this.colNumber); x++) {
          arr.push("");
        }
        this.tableList.push(arr);
      }
      console.log(this.tableList);
      this.dialog = false;
    },
    delete_row(table, index) {
      table.splice(index, 1);
    },
    submit() {
      this.isSubmitted = true;
      this.loading = true;
      this.snackbar = false;
      this.message = '';
      let valid = true;
      this.formData.description = this.myHTML;

      let duplicateValue = this.existingRecord.find(x => x.id != this.formData.id && x.description.toLowerCase() === this.formData.description.toLowerCase() && x.classification === this.formData.classification);

      // error message
      if (duplicateValue) {
        this.msg = "Record already exists";
        valid = false;
      } else if (!this.formData.description && !this.formData.classification) {
        this.msg = "Please fill-up the description and select the classification";
        valid = false;
      } else if (!this.formData.description) {
        this.msg = "Please fill-up the description";
        valid = false;
      } else if (!this.formData.classification) {
        this.msg = "Please select the classification";
        valid = false;
      }

      if (valid) {
        this.formData.id = this.$route.params.data === undefined ? null : this.$route.params.data.id;
        this.formData.workOrderId = this.$route.params.id;
        this.loading = true;
        this.convertFileToImage();
      } else {
        this.isSubmitted = false;
        this.dialog = true;
        this.loading = false;
      }
    },
    logChanges() {
      window.sessionStorage.setItem("EditDataLogging", true);
    },
    onSelect(value) {
      this.selectedStatement = value;
    },
    addStatement() {
      if(!this.selectedStatement) return;
      this.myHTML = this.selectedStatement.statement;
      this.formData.classification = this.remarkClassificationList.find(r => r.description === this.selectedStatement.classification)?.id;
    }
  },
  computed: {
    ...mapState('inspection', {
        _workOrderDetails: (state) => state.workOrderDetails,
        remarksLists: state => state.remarksLists
    }),

    characterCount() {
      const div = document.createElement("div");
      div.innerHTML = this.myHTML;
      const count = div.innerText?.length || 0;
      div.remove();

      return count;
    },

    isWalmart() {
      const clientName = this._workOrderDetails?.clientName || this._workOrderDetails?.companyName;
      return clientName?.toLowerCase() == "walmart global sourcing" || clientName?.toLowerCase() == "asda stores limited";
    }
  }
};
</script>

<style lang="stylus" scoped>

.workOrderDefectId
    padding 15px 30px
    background #fff

.list_item
    margin-bottom 25px
.title
    margin 15px 0

.imgBox
    position relative
    display inline-block
    margin-right 7px
.imgBox .del_button
    position absolute
    right 0
    top 0
.imgBox img
    width 78px

.table_box
    width 100%
    overflow-x scroll

.submit
    display block
    margin 15px auto
    margin-top 50px
    width 60%
    color: #cccac6 !important
.showWysiwyg
    position: relative !important
.hideFroala
    position: absolute !important
    z-index: -1 !important
.snackbar-loading
    margin-top 50%
    display flex
    justify-content center
 #remarks_snackbar
    z-index 10
</style>
