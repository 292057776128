<template>
  <div class="box">
    <div class="auditlogs">
      <v-row style="background:#fff;margin:auto; padding:30px" justify="center">
        <v-col cols="12" sm="12" md="12">
          <v-row id="firstrow">
            <v-col id="actionName" class="d-flex" cols="2" sm="2">
              <v-autocomplete v-model="activityCode" :items="actionNames" item-text="name" item-value="activityCode" placeholder="Action Name" :disabled="loading"> </v-autocomplete>
            </v-col>
            <v-col id="modifiedBy" class="d-flex" cols="2" sm="2">
              <v-autocomplete
                v-model="selectedUser"
                :items="filteredUsers"
                item-text="name"
                item-value="id"
                return-object
                placeholder="Modified By"
                :search-input.sync="searchUser"
                no-data-text="No Data Available"
                :hide-no-data="!showNoDataText"
                hide-selected=""
                :loading="searchUserLoading"
                :disabled="loading"
              >
                <!-- <template v-slot:item="{ item }">
                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.name + '(' + item.type + ')'"></v-list-item-title>
                                        </v-list-item-content>
                                    </template> -->
              </v-autocomplete>
            </v-col>
            <v-col id="dateFromFormatted" class="d-flex" cols="2" sm="2">
              <v-menu v-model="dateFromMenu" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on }">
                  <v-text-field v-model="dateFromFormatted" persistent-hint prepend-icon="event" readonly v-on="on" :disabled="loading"></v-text-field>
                </template>
                <v-date-picker v-model="dateFrom" @change="dateFromMenu = false" :max="maxDateTo" :disabled="loading" color="blue darken-1"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col id="dateToFormatted" class="d-flex" cols="2" sm="2">
              <v-menu v-model="dateToMenu" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on }">
                  <v-text-field v-model="dateToFormatted" persistent-hint prepend-icon="event" readonly v-on="on" :disabled="loading"></v-text-field>
                </template>
                <v-date-picker v-model="dateTo" @change="dateToMenu = false" :min="minDateFrom" :disabled="loading" color="blue darken-1"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col id="searchValue" class="d-flex" cols="4" sm="4">
              <v-text-field v-model="searchValue" prepend-inner-icon="search" label="Search old value or new value" @change="filterAuditLogs()" :disabled="loading"></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-row justify="start">
            <v-col cols="12">
              <v-btn id="btn_Search" class="submit mr-1" @click="search" :disabled="!isSearchBtnEnabled">
                Search
              </v-btn>
              <v-btn id="btn_Reset" class="submit" @click="reset" :disabled="!isSearchBtnEnabled">
                Reset
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <!-- <v-data-table
                                :sort-by="['no','dateTime','selectedUser','status',]"
                                v-bind:headers="header"
                                :items="filteredAuditLogs"
                                class="elevation-1"
                                style="margin-top: 10px;"
                                :loading="loading"
                                loading-text="Loading data..."
                                @update:page="updateRowAlignment"
                                @update:sort-by="updateRowAlignment"
                                @update:sort-desc="updateRowAlignment"
                                @update:items-per-page="updateRowAlignment"
                                >
                        <template v-slot:item.activityName = "{ item }">
                            <div v-if="item.addedActionText">
                                {{ item.activityName }} - {{ item.addedActionText }}
                            </div>
                            <div v-else>
                                {{ item.activityName }}
                            </div>
                        </template>
                        <template v-slot:item.oldValues = "{ item }">
                            <div class="cell-big-text">
                                <div class="value-wrapper" v-for="(_field, i) in item.fields" :key=i>
                                    <div class="input-wrapper" v-for="(field, j) in _field.fields" :key=j>
                                        <div :ref="getClassName(field,item.no,i,j)" :class="getClassName(field,item.no,i,j)" v-if="!item.hideOldValues && getValue(item.oldValues[i].logs, field, item.newValues[i].logs)"  class="input-text" v-html="capitalize(field) + ' = ' + getValue(item.oldValues[i].logs, field)"></div>
                                        <div :ref="getClassName(field,item.no,i,j)" class="input-text-hidden" :class="getClassName(field,item.no,i,j)" v-else> </div>

                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-slot:item.newValues = "{ item }">
                            <div class="cell-big-text">
                                <div class="value-wrapper" v-for="(_field, i) in item.fields" :key=i>
                                    <div class="input-wrapper" v-for="(field, j) in _field.fields" :key=j>
                                        <div :ref="getClassName(field,item.no,i,j)" :class="getClassName(field,item.no,i,j)" v-if="getValue(item.newValues[i].logs, field, item.oldValues[i].logs, false)" class="input-text" v-html="capitalize(field) + ' = ' + getValue(item.newValues[i].logs, field, item.oldValues[i].logs, false)"></div>
                                        <div :ref="getClassName(field,item.no,i,j)" class="input-text-hidden" :class="getClassName(field,item.no,i,j)" v-else > </div>

                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-slot:item.dateTime = "{ item }">
                            <div>{{ item.dateTime | dateFormat('MM/DD/YYYY h:mm A')}}</div>
                        </template>
                        <template v-slot:item.user = "{ item }">
                            <div>{{ item.resourceFullName ? item.resourceFullName : item.userFullName }}</div>
                        </template>
                    </v-data-table> -->

          <v-data-table
            :sort-by="['no', 'dateTime', 'selectedUser', 'status']"
            v-bind:headers="header"
            :items="filteredAuditLogs"
            :items-per-page="-1"
            class="elevation-1"
            style="margin-top: 10px;"
            :loading="loading"
            loading-text="Loading data..."
            @update:page="updateRowAlignment"
            @update:sort-by="updateRowAlignment"
            @update:sort-desc="updateRowAlignment"
            @update:items-per-page="updateRowAlignment"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  :id="
                    'row_' +
                      addIndex(
                        filteredAuditLogs
                          .map(function(x) {
                            return x.no;
                          })
                          .indexOf(item.no)
                      )
                  "
                  v-for="item in items"
                  :key="item.name"
                >
                  <td style="text-align: center;">
                    {{
                      addIndex(
                        filteredAuditLogs
                          .map(function(x) {
                            return x.no;
                          })
                          .indexOf(item.no)
                      )
                    }}
                  </td>
                  <td style="text-align: center;">
                    <div v-if="item.addedActionText">{{ item.activityName }} - {{ item.addedActionText }}</div>
                    <div v-else>
                      {{ item.activityName }}
                    </div>
                  </td>
                  <td style="text-align: center;">
                    <div class="cell-big-text">
                      <div class="value-wrapper" v-for="(_field, i) in item.fields" :key="i">
                        <div class="input-wrapper" v-for="(field, j) in _field.fields" :key="j">
                          <div
                            :ref="getClassName(field, item.no, i, j)"
                            :class="getClassName(field, item.no, i, j)"
                            v-if="!item.hideOldValues && getValue(item.oldValues[i].logs, field, item.newValues[i].logs)"
                            class="input-text"
                            v-html="capitalize(field) + ' = ' + getValue(item.oldValues[i].logs, field)"
                          ></div>
                          <div :ref="getClassName(field, item.no, i, j)" class="input-text-hidden" :class="getClassName(field, item.no, i, j)" v-else></div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td style="text-align: center;">
                    <div class="cell-big-text">
                      <div class="value-wrapper" v-for="(_field, i) in item.fields" :key="i">
                        <div class="input-wrapper" v-for="(field, j) in _field.fields" :key="j">
                          <div
                            :ref="getClassName(field, item.no, i, j)"
                            :class="getClassName(field, item.no, i, j)"
                            v-if="getValue(item.newValues[i].logs, field, item.oldValues[i].logs, false)"
                            class="input-text"
                            v-html="capitalize(field) + ' = ' + getValue(item.newValues[i].logs, field, item.oldValues[i].logs, false)"
                          ></div>
                          <div :ref="getClassName(field, item.no, i, j)" class="input-text-hidden" :class="getClassName(field, item.no, i, j)" v-else></div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td style="text-align: center;">
                    <div>{{ item.dateTime | dateFormat("MM/DD/YYYY h:mm A") }}</div>
                  </td>
                  <td style="text-align: center;">
                    <div>{{ item.resourceFullName ? item.resourceFullName : item.userFullName }}</div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>

    <v-snackbar v-model="snackbar" top :timeout="-1" color="#4caf50">
      <v-progress-circular :size="15" indeterminate color="primary"></v-progress-circular>
      <span style="padding-left:10px;">Loading</span>
    </v-snackbar>
  </div>
</template>

<script>
import * as _ from "lodash";
import * as moment from "moment";
import * as he from "he";

let state = {
  appHeaderState: {
    show: true,
    showMenu: true,
    showBack: true,
    showLogo: false,
    showTitle: true,
    title: "Work Order: 000000 Audit Logs",
    showSearch_job: false,
    showSearch_work: false,
    showCalendar: true,
  },
};

function setState(store) {
  store.dispatch("navigation/setAppHeader", state.appHeaderState);
}
export default {
  name: "AuditLogs",
  data() {
    return {
      header: [
        { text: "Id", align: "center", value: "no" },
        { text: "Action", align: "center", value: "activityName" },
        { text: "Old Value", align: "center", sortable: false, value: "oldValues", width: "300px" },
        { text: "New Value", align: "center", value: "newValues", width: "500px" },
        { text: "Date Modified", align: "center", value: "dateTime" },
        { text: "Modified By", align: "center", value: "user" },
      ],
      snackbar: false,

      filteredAuditLogs: [],
      auditLogs: [],

      // search options
      actionNames: [
        { activityCode: "CFR-AR", name: "Approve Report" },
        { activityCode: "CFR-CR", name: "Cancel Report" },
        { activityCode: "CFR-DR", name: "Disapprove Report" },
        { activityCode: "CFR-EDR", name: "Email Draft Report" },
        { activityCode: "CFR-EFR", name: "Email Final Report" },
        { activityCode: "CFR-ER", name: "Edit Report" },
        { activityCode: "CFR-RR", name: "Reopen Report" },
        { activityCode: "CFR-SR", name: "Started Review" },
        { activityCode: "DC-ED", name: "Edit Data" },
        // { activityCode: 'DC-ISD', name: 'Inspection Start Date' },
        { activityCode: "DC-SFR", name: "Submit Form for Review" },
        { activityCode: "DC-WSC", name: "WO Status Changed" },
        { activityCode: "J-CJ", name: "Created Job" },
        { activityCode: "J-EJ", name: "Edit Job" },
        { activityCode: "S-NS", name: "New Schedule" },
        { activityCode: "S-URA", name: "Updated Resource Assignment" },
        { activityCode: "S-US", name: "Updated Schedule" },
        { activityCode: "WO-CW", name: "Created Work Order" },
        { activityCode: "WO-EW", name: "Edit Work Order" },
        { activityCode: "CFR-URN", name: "Update Report No" },
        { activityCode: "CFR-SRP", name: "Save Report" },
        { activityCode: "CFR-RRP", name: "Resend Report" },
        { activityCode: "DC-A", name: "Assignee" },
      ],
      filteredUsers: [],
      dateFrom: null,
      dateFromMenu: false,
      dateTo: null,
      dateToMenu: false,
      activityCode: null,
      selectedUser: null,
      searchValue: null,
      searchUser: null,
      searchUserLoading: false,
      showNoDataText: false,
      loading: true,
      excludedFields: [],
      includedFields: [],
      auditFieldFormat: [
        { field: "StartDate", format: "MM/DD/YYYY" },
        { field: "EndDate", format: "MM/DD/YYYY" },
        { field: "InspectionRequestDate", format: "MM/DD/YYYY" },
        { field: "ShipDate", format: "MM/DD/YYYY" },
        { field: "CreateDate", format: "MM/DD/YYYY" },
        { field: "Start Date", format: "MM/DD/YYYY" },
        { field: "End Date", format: "MM/DD/YYYY" },
        { field: "Inspection Request Date", format: "MM/DD/YYYY" },
        { field: "Ship Date", format: "MM/DD/YYYY" },
        { field: "Create Date", format: "MM/DD/YYYY" },
      ],
      specialFields: ["Special Equipment", "Oracle Work Order Id"],
      // Show specific fields if value alongside with it has changed
      displayFields: [
        {
          showField: "SKU",
          conditionFields: [
            "Defect Classification",
            "Qty",
            "Actual Carton Weight",
            "Actual Carton Height",
            "Actual Carton Length",
            "Package Check Findings",
            "Package Check Result",
            "Product Barcode Label",
            "Product Barcode Type",
            "Product Code on Label",
            "Product Code Scanned",
            "Product Code Specified",
            "Carton Barcode Label",
            "Carton Barcode Type",
            "Carton Code on Label",
            "Carton Code Scanned",
            "Carton Code Specified",
            "Carton Size Result",
            "Carton Weight Result",
            "Product Size Result",
            "Product Weight Result",
          ],
        },
        {
          showField: "Sub-Section Tab",
          conditionFields: ["BarcodeLabel", "CodeOnLabel", "CodeScanned", "CodeSpecified"],
        },
        {
          showField: "TestConducted",
          conditionFields: ["Criteria", "Sample Size", "Number of Samples", "Result", "Remarks", "Comments", "Findings", "Fail Reason", "Others"],
        },
      ],
      classList: [],
    };
  },
  mounted() {
    this.actionNames = _.orderBy(this.actionNames, ["name"], ["asc"]);

    this.setTitle();
    this.getAuditLogs();
  },
  methods: {
    setTitle() {
      state.appHeaderState.title = "Work Order: " + this.$route.query.displayId + " Audit Logs";
      setState(this.$store);
    },
    getAuditLogs() {
      const option = {
        tableName: "workOrder",
        // tableKey: '460b1791-c635-43be-2bdf-08d7a47ae9ac',
        tableKey: this.$route.params.id || this.$route.query.id,
        rowsperpage: 10000,
        pageNumber: 1,
      };

      this.loading = true;
      this.utilityFn.getServerData("post", this.apiList.getActivityLogs, option, (response) => {
        const logs = response.data.map((item, index) => {
          item.no = index + 1;
          item.action = item.activityCode;
          item.dateTime = item.dateTime;
          item.user = item.selectedUser;
          item.hideOldValues = item.activityCode == "WO-CW" || item.activityCode == "J-CJ" || item.activityCode == "S-NS";

          item.oldValues = item.oldValues ? he.decode(item.oldValues) : "";
          item.newValues = item.newValues ? he.decode(item.newValues) : "";

          // for the activity Schedule - Update Resource or Update Schedule
          // make sure to match the count of the arrays of custom values
          // this is to have a corret alignment of old and new values in the table
          if (item.activityCode == "S-URA" || item.activityCode == "S-US" || item.activityCode == "CFR-AR" || item.activityCode == "CFR-DR") {
            // if (item.no == 1 || true) debugger
            let customOldValue = "";
            let customNewValue = "";

            // split each items and then parse
            let customNewValueArray = item.customNewValue
              .split("||")
              .map((obj) => JSON.parse(obj || null))
              .filter((x) => x != null);
            let customOldValueArray = item.customOldValue
              .split("||")
              .map((obj) => JSON.parse(obj || null))
              .filter((x) => x != null);

            // get all the unique day value, this is to get assignments in each day
            const newDays = customNewValueArray.map((x) => x.day);
            const oldDays = customOldValueArray.map((x) => x.day);
            const uniqueDays = _.uniq([...newDays, ...oldDays]);

            // for each day, we make sure to have the same amount of items in the values,
            // e.g. on day 04/14/2020, for customOldValue there are 2 assginments but in the customNewValue there are 5 assignments
            // then we just have to add empty items in the customOldValue to match the total 5 items in customNewValue
            // this is necessary for showing the values for old and new to make it aligned in the ui correctly
            uniqueDays.forEach((day) => {
              const newDayValues = customNewValueArray.filter((x) => x.day == day);
              const oldDayValues = customOldValueArray.filter((x) => x.day == day);

              if (item.no === 1) {
                console.log("Log TEST", {
                  newDayValues,
                  oldDayValues,
                });
              }
              if (newDayValues.length > oldDayValues.length) {
                // get unique value from the old array and new array and put it in the end of the new value array
                // this is to make sure that the alinment of values are correct for comparison
                const uniqueValues = newDayValues.filter((x) => oldDayValues.findIndex((y) => y["Assignee"] == x["Assignee"] && y["On Site Effort"] == x["On Site Effort"]) == -1);
                const newNewDayValues = newDayValues.filter((x) => uniqueValues.findIndex((y) => y["Assignee"] == x["Assignee"] && y["On Site Effort"] == x["On Site Effort"]) == -1);

                const fillerLength = newDayValues.length - oldDayValues.length;
                const fillterItems = new Array(fillerLength + 1).join("||");
                customOldValue += oldDayValues.map((x) => JSON.stringify(x)).join("||") + fillterItems + "||";
                customNewValue += newNewDayValues.map((x) => JSON.stringify(x)).join("||") + "||" + uniqueValues.map((x) => JSON.stringify(x)).join("||") + "||";
              } else if (oldDayValues.length > newDayValues.length) {
                // get unique value from the old array and new array and put it in the end of the old value array
                // this is to make sure that the alinment of values are correct for comparison
                const uniqueValues = oldDayValues.filter((x) => newDayValues.findIndex((y) => y["Assignee"] == x["Assignee"] && y["On Site Effort"] == x["On Site Effort"]) == -1);
                const newOldDayValues = oldDayValues.filter((x) => uniqueValues.findIndex((y) => y["Assignee"] == x["Assignee"] && y["On Site Effort"] == x["On Site Effort"]) == -1);

                const fillerLength = oldDayValues.length - newDayValues.length;
                const fillterItems = new Array(fillerLength + 1).join("||");
                customNewValue += newDayValues.map((x) => JSON.stringify(x)).join("||") + fillterItems + "||";
                customOldValue += newOldDayValues.map((x) => JSON.stringify(x)).join("||") + "||" + uniqueValues.map((x) => JSON.stringify(x)).join("||") + "||";
              } else {
                customNewValue += newDayValues.map((x) => JSON.stringify(x)).join("||") + "||";
                customOldValue += oldDayValues.map((x) => JSON.stringify(x)).join("||") + "||";
              }
            });

            item.oldValues = customOldValue;
            item.newValues = customNewValue;
          }

          let useDescription = item.description != null && item.activityCode != "DC-URA";
          if (useDescription) {
            let hasCustomDescription = false;
            // // split the descriptions to array
            let arrayDescription = item.description.split("||").map((obj) => JSON.parse(obj || null));

            // section image
            if (item.description.toLowerCase().indexOf("uploadedphotos") !== -1 && item.description.toLowerCase().indexOf("sectionname") !== -1) {
              arrayDescription = arrayDescription.filter((obj) => obj != null);

              // filter item with UploadedPhotos
              const uploadedPhotoArray = arrayDescription.filter(
                (obj) =>
                  Object.keys(obj)
                    .map((k) => k.toLowerCase())
                    .indexOf("uploadedphotos") !== -1
              );
              // filter out the unique sectionNames
              const sectionNames = _.uniq(uploadedPhotoArray.map((x) => x.SectionName));
              const newUploadArray = [];
              // count all uploaded photos in each sections
              sectionNames.forEach((sectionName) => {
                const items = uploadedPhotoArray.filter((x) => x.SectionName == sectionName);
                newUploadArray.push({ UploadedPhotosSectionName: items.length + " " + sectionName });
                // newUploadArray.push({ UploadedPhotos: items.length, SectionName: sectionName });
              });
              // remove all items from the array with
              arrayDescription = arrayDescription.filter(
                (obj) =>
                  Object.keys(obj)
                    .map((k) => k.toLowerCase())
                    .indexOf("uploadedphotos") === -1
              );
              // add the new items in description array
              arrayDescription.push(...newUploadArray);
              hasCustomDescription = true;
            }
            if (item.description.toLowerCase().indexOf("deletedphotos") !== -1 && item.description.toLowerCase().indexOf("sectionname") !== -1) {
              arrayDescription = arrayDescription.filter((obj) => obj != null);

              // filter item with DeletedPhotos
              const deletedPhotoArray = arrayDescription.filter(
                (obj) =>
                  Object.keys(obj)
                    .map((k) => k.toLowerCase())
                    .indexOf("deletedphotos") !== -1
              );
              // filter out the unique sectionNames
              const sectionNames = _.uniq(deletedPhotoArray.map((x) => x.SectionName));
              const newUploadArray = [];
              // count all deleted photos in each sections
              sectionNames.forEach((sectionName) => {
                const items = deletedPhotoArray.filter((x) => x.SectionName == sectionName);
                newUploadArray.push({ DeletedPhotosSectionName: items.length + " " + sectionName });
                // newUploadArray.push({ DeletedPhotos: items.length, SectionName: sectionName });
              });
              // remove all items from the array with
              arrayDescription = arrayDescription.filter(
                (obj) =>
                  Object.keys(obj)
                    .map((k) => k.toLowerCase())
                    .indexOf("deletedphotos") === -1
              );
              // add the new items in description array
              arrayDescription.push(...newUploadArray);
              hasCustomDescription = true;
            }

            // inspection image
            if (item.description.toLowerCase().indexOf("uploadedphotos") !== -1 && item.description.toLowerCase().indexOf("sku") !== -1 && item.description.toLowerCase().indexOf("description") !== -1) {
              arrayDescription = arrayDescription.filter((obj) => obj != null);

              // filter item with UploadedPhotos
              const uploadedPhotoArray = arrayDescription.filter(
                (obj) =>
                  Object.keys(obj)
                    .map((k) => k.toLowerCase())
                    .indexOf("uploadedphotos") !== -1
              );
              // filter out the unique skus
              const skus = _.uniqWith(
                uploadedPhotoArray.map((x) => {
                  return { SKU: x.SKU, Description: x.Description };
                }),
                _.isEqual
              );
              const newUploadArray = [];
              // count all uploaded photos in each sections
              skus.forEach((sku) => {
                const items = uploadedPhotoArray.filter((x) => x.SKU == sku.SKU && x.Description == sku.Description);
                newUploadArray.push({ UploadedPhotosSKUDescription: items.length + " " + sku.SKU + " - " + sku.Description });
                // newUploadArray.push({ UploadedPhotos: items.length, SKU: sku });
              });
              // remove all items from the array with
              arrayDescription = arrayDescription.filter(
                (obj) =>
                  Object.keys(obj)
                    .map((k) => k.toLowerCase())
                    .indexOf("uploadedphotos") === -1
              );
              // add the new items in description array
              arrayDescription.push(...newUploadArray);
              hasCustomDescription = true;
            }
            if (item.description.toLowerCase().indexOf("deletedphotos") !== -1 && item.description.toLowerCase().indexOf("sku") !== -1 && item.description.toLowerCase().indexOf("description") !== -1) {
              arrayDescription = arrayDescription.filter((obj) => obj != null);

              // filter item with DeletedPhotos
              const deletedPhotoArray = arrayDescription.filter(
                (obj) =>
                  Object.keys(obj)
                    .map((k) => k.toLowerCase())
                    .indexOf("deletedphotos") !== -1
              );
              // filter out the unique skus
              const skus = _.uniqWith(
                deletedPhotoArray.map((x) => {
                  return { SKU: x.SKU, Description: x.Description };
                }),
                _.isEqual
              );
              const newUploadArray = [];
              // count all deleted photos in each sections
              skus.forEach((sku) => {
                const items = deletedPhotoArray.filter((x) => x.SKU == sku.SKU && x.Description == sku.Description);
                newUploadArray.push({ DeletedPhotosSKUDescription: items.length + " " + sku.SKU + " - " + sku.Description });
                // newUploadArray.push({ DeletedPhotos: items.length, SKU: sku });
              });
              // remove all items from the array with
              arrayDescription = arrayDescription.filter(
                (obj) =>
                  Object.keys(obj)
                    .map((k) => k.toLowerCase())
                    .indexOf("deletedphotos") === -1
              );
              // add the new items in description array
              arrayDescription.push(...newUploadArray);
              hasCustomDescription = true;
            }

            // Defect Image
            if (item.description.toLowerCase().indexOf("uploadedphotos") !== -1 && item.description.toLowerCase().indexOf("defect") !== -1) {
              arrayDescription = arrayDescription.filter((obj) => obj != null);

              // filter item with UploadedPhotos
              const uploadedPhotoArray = arrayDescription.filter(
                (obj) =>
                  Object.keys(obj)
                    .map((k) => k.toLowerCase())
                    .indexOf("uploadedphotos") !== -1
              );
              // filter out the unique defects
              // const defects = _.uniq(uploadedPhotoArray.map(x => x.Defect));
              const defects = _.uniqWith(
                uploadedPhotoArray.map((x) => {
                  return { SKU: x.SKU, Defect: x.Defect };
                }),
                _.isEqual
              );

              const newUploadArray = [];
              // count all uploaded photos in each sections
              defects.forEach((defect) => {
                const items = uploadedPhotoArray.filter((x) => x.Defect == defect.Defect && x.SKU == defect.SKU);
                newUploadArray.push({ UploadedPhotosSKUDefect: items.length + " " + defect.SKU + " - " + defect.Defect });
                // newUploadArray.push({ UploadedPhotos: items.length, Defect: defect });
              });
              // remove all items from the array with
              arrayDescription = arrayDescription.filter(
                (obj) =>
                  Object.keys(obj)
                    .map((k) => k.toLowerCase())
                    .indexOf("uploadedphotos") === -1
              );
              // add the new items in description array
              arrayDescription.push(...newUploadArray);
              hasCustomDescription = true;
            }
            if (item.description.toLowerCase().indexOf("deletedphotos") !== -1 && item.description.toLowerCase().indexOf("defect") !== -1) {
              arrayDescription = arrayDescription.filter((obj) => obj != null);

              // filter item with DeletedPhotos
              const deletedPhotoArray = arrayDescription.filter(
                (obj) =>
                  Object.keys(obj)
                    .map((k) => k.toLowerCase())
                    .indexOf("deletedphotos") !== -1
              );
              // filter out the unique defects
              // const defects = _.uniq(deletedPhotoArray.map(x => x.Defect));
              const defects = _.uniqWith(
                deletedPhotoArray.map((x) => {
                  return { SKU: x.SKU, Defect: x.Defect };
                }),
                _.isEqual
              );

              const newDeleteArray = [];
              // count all deleted photos in each sections
              defects.forEach((defect) => {
                const items = deletedPhotoArray.filter((x) => x.Defect == defect.Defect && x.SKU == defect.SKU);
                newDeleteArray.push({ DeletedPhotosSKUDefect: items.length + " " + defect.SKU + " - " + defect.Defect });
                // newDeleteArray.push({ DeletedPhotos: items.length, Defect: defect });
              });
              // remove all items from the array with
              arrayDescription = arrayDescription.filter(
                (obj) =>
                  Object.keys(obj)
                    .map((k) => k.toLowerCase())
                    .indexOf("deletedphotos") === -1
              );
              // add the new items in description array
              arrayDescription.push(...newDeleteArray);
              hasCustomDescription = true;
            }

            // product image
            if (item.description.toLowerCase().indexOf("uploadedphotos") !== -1 && item.description.toLowerCase().indexOf("sku") !== -1) {
              arrayDescription = arrayDescription.filter((obj) => obj != null);

              // filter item with UploadedPhotos
              const uploadedPhotoArray = arrayDescription.filter(
                (obj) =>
                  Object.keys(obj)
                    .map((k) => k.toLowerCase())
                    .indexOf("uploadedphotos") !== -1
              );
              // filter out the unique skus
              const skus = _.uniq(uploadedPhotoArray.map((x) => x.SKU));
              const newUploadArray = [];
              // count all uploaded photos in each sections
              skus.forEach((sku) => {
                const items = uploadedPhotoArray.filter((x) => x.SKU == sku);
                newUploadArray.push({ UploadedPhotosSKU: items.length + " " + sku });
                // newUploadArray.push({ UploadedPhotos: items.length, SKU: sku });
              });
              // remove all items from the array with
              arrayDescription = arrayDescription.filter(
                (obj) =>
                  Object.keys(obj)
                    .map((k) => k.toLowerCase())
                    .indexOf("uploadedphotos") === -1
              );
              // add the new items in description array
              arrayDescription.push(...newUploadArray);
              hasCustomDescription = true;
            }
            if (item.description.toLowerCase().indexOf("deletedphotos") !== -1 && item.description.toLowerCase().indexOf("sku") !== -1) {
              arrayDescription = arrayDescription.filter((obj) => obj != null);

              // filter item with DeletedPhotos
              const deletedPhotoArray = arrayDescription.filter(
                (obj) =>
                  Object.keys(obj)
                    .map((k) => k.toLowerCase())
                    .indexOf("deletedphotos") !== -1
              );
              // filter out the unique skus
              const skus = _.uniq(deletedPhotoArray.map((x) => x.SKU));
              const newUploadArray = [];
              // count all deleted photos in each sections
              skus.forEach((sku) => {
                const items = deletedPhotoArray.filter((x) => x.SKU == sku);
                newUploadArray.push({ DeletedPhotosSKU: items.length + " " + sku });
                // newUploadArray.push({ DeletedPhotos: items.length, SKU: sku });
              });
              // remove all items from the array with
              arrayDescription = arrayDescription.filter(
                (obj) =>
                  Object.keys(obj)
                    .map((k) => k.toLowerCase())
                    .indexOf("deletedphotos") === -1
              );
              // add the new items in description array
              arrayDescription.push(...newUploadArray);
              hasCustomDescription = true;
            }

            if (hasCustomDescription) {
              item.description = arrayDescription.map((s) => JSON.stringify(s || null)).join("||");
              const descriptionFill = arrayDescription.map((s) => null).join("||");
              item.newValues = item.newValues + "||" + item.description;
              item.oldValues = item.oldValues + "||" + descriptionFill;
            } else {
              item.newValues = item.newValues + "||" + item.description;
              item.oldValues = item.oldValues + "||";
            }
          }
          item.newValues = item.newValues.replace(/(\r\n|\n|\r)/gm, "");
          item.oldValues = item.oldValues.replace(/(\r\n|\n|\r)/gm, "");
          const _oldValuesArray = [];
          const _newValuesArray = [];
          const _fieldsArray = [];

          let oldValuesArray = item.oldValues.split("||").map((x) => JSON.parse(x || null));
          let newValuesArray = item.newValues.split("||").map((x) => JSON.parse(x || null));

          // for special fields
          // we have to make this process to have the fields align correctly when being display in the table
          // since the list in db is being delete and created, for eg. Special Equipment -- When we add an equipment to the job,
          //      if existing, we delete that items and add the items back to db. And when we get the audit logs, they are not aligned
          // this process will append the special fields in the end of the array
          this.specialFields.forEach((specialField) => {
            // filter out array get oracle items
            const oldSpecialFieldValues = _.cloneDeep(oldValuesArray.filter((x) => x && Object.keys(x).indexOf(specialField) != -1));
            const newSpecialFieldValues = _.cloneDeep(newValuesArray.filter((x) => x && Object.keys(x).indexOf(specialField) != -1));
            const _oldSpecialFieldValues = [];
            const _newSpecialFieldValues = [];

            // make sure to have their length the same
            if (newSpecialFieldValues.length > oldSpecialFieldValues.length) {
              const fillerLength = newSpecialFieldValues.length - oldSpecialFieldValues.length;
              const fillterItems = new Array(fillerLength).fill(null);

              oldSpecialFieldValues.push(...fillterItems);
            } else if (oldSpecialFieldValues.length > newSpecialFieldValues.length) {
              const fillerLength = oldSpecialFieldValues.length - newSpecialFieldValues.length;
              const fillterItems = new Array(fillerLength).fill(null);

              newSpecialFieldValues.push(...fillterItems);
            }

            // clear out the special field's value in it's original position
            for (let i = 0; i < oldValuesArray.length; i++) {
              if (Object.keys(oldValuesArray[i] || {}).indexOf(specialField) != -1) {
                oldValuesArray[i] = null;
              }
            }
            for (let i = 0; i < newValuesArray.length; i++) {
              if (Object.keys(newValuesArray[i] || {}).indexOf(specialField) != -1) {
                newValuesArray[i] = null;
              }
            }

            // push the specifal field's value to the end of the array
            oldValuesArray.push(...oldSpecialFieldValues);
            newValuesArray.push(...newSpecialFieldValues);
          });

          // get the fields that will be use to display the values in the table
          const valueLength = newValuesArray.length >= oldValuesArray.length ? newValuesArray.length : oldValuesArray.length;
          for (let i = 0; i < valueLength; i++) {
            let _oldValue = oldValuesArray[i] || {};
            let _newValue = newValuesArray[i] || {};

            let _oldValueFields = Object.keys(_oldValue);
            let _newValueFields = Object.keys(_newValue);

            let _oldValues = [];
            let _newValues = [];
            let _fields = [];

            if (_newValueFields.length > _oldValueFields.length) {
              const fillerLength = _newValueFields.length - _oldValueFields.length;
              const fillterItems = new Array(fillerLength).fill({ key: null, value: null });

              _newValueFields.forEach((key) => {
                _newValues.push({ key, value: this.returnValueIfInFieldFormat(key, _newValue[key]) });
              });
              _oldValueFields.forEach((key) => {
                _oldValues.push({ key, value: this.returnValueIfInFieldFormat(key, _oldValue[key]) });
              });
              _oldValues.push(...fillterItems);

              _oldValuesArray.push({ logs: _oldValues });
              _newValuesArray.push({ logs: _newValues });
              // _fieldsArray.push({ fields: _newValueFields });
            } else if (_oldValueFields.length > _newValueFields.length) {
              const fillerLength = _oldValueFields.length - _newValueFields.length;
              const fillterItems = new Array(fillerLength).fill({ key: null, value: null });

              _oldValueFields.forEach((key) => {
                _oldValues.push({ key, value: this.returnValueIfInFieldFormat(key, _oldValue[key]) });
              });
              _newValueFields.forEach((key) => {
                _newValues.push({ key, value: this.returnValueIfInFieldFormat(key, _newValue[key]) });
              });
              _newValues.push(...fillterItems);

              _oldValuesArray.push({ logs: _oldValues });
              _newValuesArray.push({ logs: _newValues });
              // _fieldsArray.push({ fields: _newValueFields });
            } else {
              _oldValueFields.forEach((key) => {
                _oldValues.push({ key, value: this.returnValueIfInFieldFormat(key, _oldValue[key]) });
              });
              _newValueFields.forEach((key) => {
                _newValues.push({ key, value: this.returnValueIfInFieldFormat(key, _newValue[key]) });
              });

              _oldValuesArray.push({ logs: _oldValues });
              _newValuesArray.push({ logs: _newValues });
              // _fieldsArray.push({ fields: _newValueFields });
            }

            _fields.push(..._newValueFields);
            _oldValueFields.forEach((field) => {
              if (_fields.indexOf(field) === -1) {
                _fields.push(field);
              }
            });
            _fieldsArray.push({ fields: _fields });
          }

          _fieldsArray.forEach((_item, index) => {
            _item.fields = _item.fields.filter((key) => {
              const oldValue = _oldValuesArray[index].logs.find((x) => x.key == key) || {};
              const newValue = _newValuesArray[index].logs.find((x) => x.key == key) || {};

              // hard coded condition for showing field for update resources
              if (item.activityCode === "DC-URA" && key === "Section") {
                const _oldValueIndex = _oldValuesArray[index].logs.findIndex((x) => x.key == "Assignee");
                const _newValueIndex = _newValuesArray[index].logs.findIndex((x) => x.key == "Assignee");

                const _oldValue = _oldValuesArray[index].logs[_oldValueIndex] || {};
                const _newValue = _newValuesArray[index].logs[_newValueIndex] || {};

                if (_oldValue.value != _newValue.value) {
                  return true;
                }
              }

              // hard coded condition for showing field for update resources
              if ((item.activityCode === "S-URA" || item.activityCode === "S-US") && (key === "resource" || key === "day" || key === "billable" || key === "onSiteEffort" || key === "isLeadInspector")) {
                const _oldValueIndex = _oldValuesArray[index].logs.findIndex((x) => x.key == "resource");
                const _newValueIndex = _newValuesArray[index].logs.findIndex((x) => x.key == "resource");
                const _oldValue = _oldValuesArray[index].logs[_oldValueIndex] || {};
                const _newValue = _newValuesArray[index].logs[_newValueIndex] || {};

                const _oldValueIndex2 = _oldValuesArray[index].logs.findIndex((x) => x.key == "day");
                const _newValueIndex2 = _newValuesArray[index].logs.findIndex((x) => x.key == "day");
                const _oldValue2 = _oldValuesArray[index].logs[_oldValueIndex2] || {};
                const _newValue2 = _newValuesArray[index].logs[_newValueIndex2] || {};

                const _oldValueIndex3 = _oldValuesArray[index].logs.findIndex((x) => x.key == "billable");
                const _newValueIndex3 = _newValuesArray[index].logs.findIndex((x) => x.key == "billable");
                const _oldValue3 = _oldValuesArray[index].logs[_oldValueIndex3] || {};
                const _newValue3 = _newValuesArray[index].logs[_newValueIndex3] || {};

                const _oldValueIndex4 = _oldValuesArray[index].logs.findIndex((x) => x.key == "onSiteEffort");
                const _newValueIndex4 = _newValuesArray[index].logs.findIndex((x) => x.key == "onSiteEffort");
                const _oldValue4 = _oldValuesArray[index].logs[_oldValueIndex4] || {};
                const _newValue4 = _newValuesArray[index].logs[_newValueIndex4] || {};

                const _oldValueIndex5 = _oldValuesArray[index].logs.findIndex((x) => x.key == "isLeadInspector");
                const _newValueIndex5 = _newValuesArray[index].logs.findIndex((x) => x.key == "isLeadInspector");
                const _oldValue5 = _oldValuesArray[index].logs[_oldValueIndex5] || {};
                const _newValue5 = _newValuesArray[index].logs[_newValueIndex5] || {};

                if (_oldValue.value != _newValue.value || _oldValue2.value != _newValue2.value || _oldValue3.value != _newValue3.value || _oldValue4.value != _newValue4.value || _oldValue5.value != _newValue5.value) {
                  return true;
                }
              }

              // Show specific field if field along side with it has values changed
              if (this.displayFields.map((x) => x.showField.toLowerCase()).indexOf(key.toLowerCase()) !== -1) {
                let displayValue = false;
                this.displayFields.forEach((displayField) => {
                  if (key.toLowerCase() === displayField.showField.toLowerCase()) {
                    let _oldValue = new Array(displayField.conditionFields.length).fill();
                    let _newValue = new Array(displayField.conditionFields.length).fill();

                    displayField.conditionFields.forEach((key, keyIndex) => {
                      const _oldValueKeyIndex = _oldValuesArray[index].logs.findIndex((x) => x.key && x.key.toLowerCase() == key.toLowerCase());
                      const _newValueKeyIndex = _newValuesArray[index].logs.findIndex((x) => x.key && x.key.toLowerCase() == key.toLowerCase());
                      _oldValue[keyIndex] = _oldValuesArray[index].logs[_oldValueKeyIndex] || {};
                      _newValue[keyIndex] = _newValuesArray[index].logs[_newValueKeyIndex] || {};
                    });

                    displayValue = displayField.conditionFields.filter((key, keyIndex) => _oldValue[keyIndex].value != _newValue[keyIndex].value).length != 0;

                    // if true, break the loop immediately
                    if (displayValue) return;
                  }
                });

                return displayValue;
              }
           
              return oldValue.value != newValue.value;
            });
          });

          item.oldValues = _oldValuesArray;
          item.newValues = _newValuesArray;
          item.fields = _fieldsArray;

          return item;
        });

        this.auditLogs = logs;
        this.filteredAuditLogs = logs;
        this.loading = false;
      });
    },
    getValue(currentLogArray, key, otherLogArray, isOldValue = true) {
      currentLogArray = _.cloneDeep(currentLogArray);
      otherLogArray = otherLogArray || [];
      if (currentLogArray.length === 0) return null;

      // check if current field is to use custom format for dates
      // const auditLogFieldFormat = this.auditFieldFormat.find(x => x.field == key);

      const index = currentLogArray.findIndex((x) => x.key === key);
      const currentValue = currentLogArray[index] && currentLogArray[index].value != null && currentLogArray[index].value != "null" ? currentLogArray[index].value : null;

      if (key === "Section" || key === "resource" || key === "day" || key === "billable" || key === "onSiteEffort" || key === "isLeadInspector") {
        return currentValue ? he.decode(currentValue.toString()) : currentValue;
      }

      // Hide Display fields in old value column if other value is null or value has not changed
      if (isOldValue) {
        const keyIndex = this.displayFields.findIndex((x) => x.showField === key);
        if (keyIndex !== -1) {
          const currentDisplayFields = this.displayFields[keyIndex].conditionFields.filter((f) => currentLogArray.findIndex((c) => c.key && c.key === f) !== -1);
          const length = currentDisplayFields
            .map((f) => {
              const i = currentLogArray.findIndex((c) => c.key === f);
              const k = otherLogArray.findIndex((c) => c.key === f);
              return {
                key: f,
                value: i !== -1 ? currentLogArray[i].value : null,
                otherValue: k !== -1 ? currentLogArray[k].value : null,
              };
            })
            .filter((x) => x.value && x.value != x.otherValue).length;

          if (length === 0) return "";
        }
      }

      let returnValue = currentValue;
      return returnValue ? he.decode(returnValue.toString()) : returnValue;
    },
    search() {
      this.snackbar = true;
      setTimeout(() => {
        this.filterAuditLogs();
        this.snackbar = false;
      }, 500);
    },
    reset() {
      this.dateFrom = null;
      this.dateTo = null;
      this.activityCode = null;
      this.selectedUser = null;
      this.searchValue = null;
      this.filterAuditLogs();
    },
    filterAuditLogs() {
      this.filteredAuditLogs = [...this.auditLogs];
      if (this.activityCode) {
        this.filteredAuditLogs = this.filteredAuditLogs.filter((x) => x.activityCode === this.activityCode);
      }
      if (this.selectedUser) {
        this.filteredAuditLogs = this.filteredAuditLogs.filter((x) => x.selectedUser === this.selectedUser.id || x.resourceId === this.selectedUser.id);
      }
      if (this.dateFrom) {
        this.filteredAuditLogs = this.filteredAuditLogs.filter(
          (x) =>
            moment
              .utc(x.dateTime)
              .local()
              .format("YYYY-MM-DD") >= this.dateFrom
        );
      }
      if (this.dateTo) {
        this.filteredAuditLogs = this.filteredAuditLogs.filter(
          (x) =>
            moment
              .utc(x.dateTime)
              .local()
              .format("YYYY-MM-DD") <= this.dateTo
        );
      }
      if (this.searchValue && this.searchValue.trim().length > 0) {
        let _searchValue = this.searchValue.trim().toLowerCase();
        this.filteredAuditLogs = this.filteredAuditLogs.filter((x) => {
          let found = false;
          x.newValues.forEach(function(n) {
            n.logs.forEach(function(l) {
              let value = l.value != null ? l.value.toString() : l.value;
              if (value != null) {
                if (value.trim().toLowerCase() === _searchValue.trim().toLowerCase()) {
                  found = true;
                }
              }

              console.log(value, _searchValue);
            });
          });
          x.oldValues.forEach(function(o) {
            o.logs.forEach(function(l) {
              let value = l.value != null ? l.value.toString() : l.value;
              if (value != null) {
                if (value.trim().toLowerCase() === _searchValue.trim().toLowerCase()) {
                  found = true;
                }
              }

              console.log(value, _searchValue);
            });
          });

          if (found) {
            return x;
          }
        });
      }
      return this.filteredAuditLogs;
    },

    capitalize(value) {
      return value ? value.charAt(0).toUpperCase() + value.slice(1) : "";
    },

    returnValueIfInFieldFormat(field, originalValue) {
      const index = this.auditFieldFormat.findIndex((x) => x.field.toLowerCase() == field.toLowerCase());
      if (index !== -1) {
        return moment
          .utc(originalValue)
          .local()
          .format(this.auditFieldFormat[index].format);
      }

      return originalValue;
    },

    // generate className for each pair of old and new value
    getClassName(fieldName, tblIndex, pFieldIndex, fieldIndex) {
      let className = fieldName + "-" + tblIndex + "-" + pFieldIndex + "-" + fieldIndex;

      if (this.classList.indexOf(className) === -1) {
        this.classList.push(className);
      }

      return className;
    },

    // align the element properly, set the max height for the element
    alignTableRows() {
      this.classList.forEach((className) => {
        let el = document.getElementsByClassName(className);
        if (el.length === 0) return;

        let maxHeight = el[0].offsetHeight > el[1].offsetHeight ? el[0].offsetHeight : el[1].offsetHeight;
        el[0].style.height = maxHeight + "px";
        el[1].style.height = maxHeight + "px";
        el[0].parentNode.style.height = maxHeight + "px";
        el[1].parentNode.style.height = maxHeight + "px";
      });
    },

    updateRowAlignment() {
      setTimeout(() => this.alignTableRows(), 300);
    },
    addIndex(num) {
      return (num = num + 1);
    },
  },
  computed: {
    dateFromFormatted() {
      return this.dateFrom ? moment(this.dateFrom).format("MM/DD/YYYY") : "";
    },
    dateToFormatted() {
      return this.dateTo ? moment(this.dateTo).format("MM/DD/YYYY") : "";
    },
    minDateFrom() {
      return this.dateFrom ? moment(this.dateFrom).format("YYYY-MM-DD") : "";
    },
    maxDateTo() {
      return this.dateTo ? moment(this.dateTo).format("YYYY-MM-DD") : "";
    },

    isSearchBtnEnabled() {
      return this.activityCode || this.selectedUser || this.dateFrom || this.dateTo || (this.searchValue && this.searchValue.trim().length > 0);
    },
  },
  watch: {
    searchUser(val) {
      this.searchUserLoading = true;
      if (!val || val.trim().length < 3) {
        this.selectedUser = null;
        this.filteredUsers = [];
        this.showNoDataText = false;
        this.searchUserLoading = false;
        return;
      }

      if (this.selectedUser && this.selectedUser.id) {
        this.filteredUsers = this.filteredUsers.filter((x) => x.id === this.selectedUser.id);
      } else {
        this.utilityFn.getServerData("get", this.apiList.getResourceOrEmployees, { searchtext: val }, (res) => {
          this.showNoDataText = true;
          this.filteredUsers = res.data.data;
        });
      }
    },

    loading(val) {
      if (!val) {
        setTimeout(() => this.alignTableRows(), 300);
      }
    },
  },
  filters: {
    formatValue(value) {
      return value.map((s) => s);
    },
  },
};
</script>

<style lang="stylus" scoped>
.cell-big-text
    word-break break-word
    width 400px
    text-align left
.input-text
    width: 100%
.value-wrapper
    margin-bottom: 10px
.input-text>>>
    max-width: 200px
.input-text>>>table
    border: 1px solid rgba(0,0,0,.12)
.input-text>>>table td
    border: 1px solid rgba(0,0,0,.12)
.submit
    max-width 120px
</style>
