<template>
  <div id="app">
    <v-app>      
      <v-overlay :value="loadingPage.show" :z-index="10"></v-overlay>
      <v-snackbar class="snackbar-loading" v-model="loadingPage.show" centered :timeout="0" color="#4caf50">
        <v-progress-circular :size="15" indeterminate color="primary"></v-progress-circular>
        <span style="padding-left:10px;">Loading</span>
      </v-snackbar>
      <app-header class="app-shell-header" @click-menu="handleClickHeaderMenu" @click-back="handleClickHeaderBack"></app-header>
      <app-sidebar @hide-sidebar="handleHideSidebar" @show-sidebar="handleShowSidebar"></app-sidebar>
      <FloatingButton v-show="showFloatingButton"></FloatingButton>
      <transition :name="pageTransitionEffect" @before-enter="handleBeforeEnter" @after-enter="handleAfterEnter" @before-leave="handleBeforeLeave">


        <keep-alive :include="[...keepAlivePages]" > 
          <router-view v-if="isLoggedIn" ref="activeComponent" :key="$route.fullPath" class="app-view" :class="[{ 'app-view-with-header': appHeaderShow, 'pb-13': showFooter }, pageTransitionClass]" :data-page-id="$route.fullPath"></router-view>
          <router-view v-else ref="activeComponent" name="login"  class="app-view" :class="[{ 'app-view-with-header': appHeaderShow, 'pb-13': showFooter }, pageTransitionClass]" :data-page-id="$route.fullPath"></router-view>
        </keep-alive>
        <!-- <router-view ref="activeComponent" v-else :key="$route.fullPath" class="app-view" :class="[{ 'app-view-with-header': appHeaderShow, 'pb-13': showFooter }, pageTransitionClass]" :data-page-id="$route.fullPath"></router-view> -->
         <!-- <keep-alive :include="[...keepAliveOfflinePages]" v-if="!navonline"> 
          <router-view ref="activeComponent" :key="$route.fullPath" class="app-view" :class="[{ 'app-view-with-header': appHeaderShow, 'pb-13': showFooter }, pageTransitionClass]" :data-page-id="$route.fullPath"></router-view>
        </keep-alive> -->
      </transition>
      
      <update-toast></update-toast>
      <v-snackbar
        v-for="(snackbar, index) in snackbars.filter(s => s.showing)"
        :key = "snackbar.text + Math.random()"
        v-model="snackbar.showing"     
        :multi-line="false"
        :timeout = "snackbar.timeout"
        :color = "snackbar.color"
        :style="`bottom: ${(index * 90)+8}px`"
      >
        {{snackbar.text}}
        <v-btn text @click="snackbar.showing = false">
          CLOSE
        </v-btn>
      </v-snackbar>     
      <app-footer v-show="showFooter" :activeComponent="activeComponent"></app-footer>
    </v-app>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import authFn from "./shared/request/auth";
import AppSidebar from "./components/layout/AppSidebar";
import UpdateToast from "./components/common/UpdateToast";
import auditLog from "./shared/request/auditLogs";
import AppHeader from "./components/layout/AppHeader";
import FloatingButton from "./components/common/FloatingButton";
import AppFooter from './components/layout/AppFooter.vue';
import { PublicClientApplication } from '@azure/msal-browser';
const ENABLE_SCROLL_CLASS = "app-view-scroll-enabled";

export default {
  name: "app",
  components: {
    AppHeader,
    UpdateToast,
    AppSidebar,
    FloatingButton,
    AppFooter
  },
  beforeCreate() {
    localStorage.setItem('VUE_APP_VERSION', process.env.VUE_APP_VERSION);
  },  
  async created() {
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig,
    );

    this.$msalInstance.addEventCallback((event) => {
      // set active account after redirect
      if (event.eventType === 'msal:loginSuccess' && event.payload.account) {
        const account = event.payload.account;
        this.$msalInstance.setActiveAccount(account);
      }
    }, error => {
      console.log('error', error);
    });

    this.$msalInstance.handleRedirectPromise()
      .then((response) => {                
        const account = this.$msalInstance.getAllAccounts()[0];
        if (!account) {
          
          //this section is checking if the user is directly accessing a workorder
          //happens because canyon can direct to a specific work order
          //show popup id if directly going to workorder
          //otherwise go to login page 
          var currentPage = this.$router.currentRoute.name;   
          if(currentPage == "workOrderDetailsId" || currentPage == 'dynamicFormId') {
            authFn.loginPopup()
              .then((result) => {

              if(result) {
                this.isLoggedIn = authFn.isLoggedIn;
              }
            });
          }
          else {
            //go to login page
            this.$router.push({name: 'login'}).catch(()=>{});            
          }
        }
        else {        
          let token = JSON.parse(localStorage.getItem("tokenInfo"));
          if(!token || !token.token || token.expDate < new Date().toISOString()) {         
            authFn.authenticate()
            .then((result) => {
              if(result) {
                this.isLoggedIn = authFn.isLoggedIn;
              }
            });
          }
          else {
            this.isLoggedIn = authFn.isLoggedIn;
          }
        }
      })
      .catch(err => { console.log(err) });

  },
  mounted() {
    this.keepAlivePages.push("workOrderDetailsId",  "workOrderId",
    "dynamicFormId");
    this.keepAliveOfflinePages.push("workOrderDetailsId", "index", "workOrderId",
    "dynamicFormId","workOrderInspectionBarcodeId","workOrderInspectionOnsiteId",
    "workOrderInspectionQuantityId","workOrderInspectionSizeId",
    "workOrderInspectionProductConformityId","workOrderInspectionPackageId",
    "workOrderInspectionWorkmanshipId"
    );
    // this.getAimsConfigurations();

    // assign active component
    this.$nextTick(() => {
      this.activeComponent = this.$refs.activeComponent;
    });
  },
  data() {
    return {
      //online:true,
      isLoggedIn: false,
      keepAliveOfflinePages:[],
      keepAlivePages: ["workOrderDetailsId", "index", "workOrderId","dynamicFormId"],
      routesForFloatingButton: [
        "dynamicFormId",
        "workOrderDetailsId",
        "workOrderInspectionQuantityId",
        "workOrderInspectionSizeId",
        "workOrderInspectionPackageId",
        "workOrderInspectionBarcodeId",
        "workOrderInspectionWorkmanshipId",
        "workOrderInspectionOnsiteId",
      ],
      configurations: [],
      activeComponent: {},
      routesFooterVisibility: [
        "dynamicFormId",
        "workOrderDetailsId",
        "workOrderInspectionQuantityId",
        "workOrderInspectionSizeId",
        "workOrderInspectionPackageId",
        "workOrderInspectionBarcodeId",
        "workOrderInspectionWorkmanshipId",
        "workOrderInspectionOnsiteId",
        "workOrderInspectionProductConformityId"
      ]
    };
  },    
  mounted() {
  },
  computed: {
    ...mapState("navigation", {
      pageTransitionType: (state) => state.pageTransition.type,
      pageTransitionEffect: (state) => state.pageTransition.effect
    }),
    ...mapState("navigation", {
      appHeaderShow: (state) => state.header.show,
      appHeaderState: (state) => state.header,
   
    }),

    ...mapState("navigation", {
      scrollPostionMap: (state) => state.common.scrollPostionMap,
      navonline: (state) => state.header.online
    }),

      ...mapState("navigation", {
      snackbars: (state) => state.snackbars,
    }),

      ...mapState("navigation", {
      loadingPage: (state) => state.loadingPage,
    }),
    ...mapState("inspection", {
      workOrder: (state) => state.workOrderDetails,
     //  online: (state) => state.online
    }),

    pageTransitionClass() {
      return `transition-${this.pageTransitionType}`;
    },

    showFloatingButton() {
      return this.routesForFloatingButton.indexOf(this.$route.name) !== -1 && this.workOrder && this.workOrder.products && this.workOrder.products.length > 1;
    },

    routerViewKey() {
      this.toggleSubmitForm();
      this.setAuditLog();

      let { name, params } = this.$route;
      let paramKeys = Object.keys(params);
      if (paramKeys.length) {
        return name + paramKeys.reduce((prev, cur) => prev + params[cur], "");
      }

      return null;
    },

    showFooter() {
      return this.routesFooterVisibility.indexOf(this.$route.name) !== -1;
    }
  },
  methods: {
    ...mapActions("navigation", ["setSideBarVisibility", "setPageSwitching", "savePageScrollPosition"]),
   
    /**
     * make current page container scrollable,
     * and restore its scroll position.
     */
    restoreContainerScrollPosition(containerEl, scrollTop) {
      containerEl.classList.add(ENABLE_SCROLL_CLASS);
      containerEl.scrollTop = scrollTop;
    },

    /**
     * make body scrollable,
     * and restore its scroll position.
     */
    restoreBodyScrollPosition(containerEl, scrollTop) {
      containerEl.classList.remove(ENABLE_SCROLL_CLASS);
      document.body.scrollTop = document.documentElement.scrollTop = scrollTop;
    },

    handleBeforeEnter(el) {
      let pageId = el.dataset.pageId;
      let { y: scrollTop = 0 } = this.scrollPostionMap[pageId] || {};

      this.setPageSwitching(true);
      Vue.nextTick(() => {
        this.restoreContainerScrollPosition(el, scrollTop);
      });
    },
    handleAfterEnter(el) {
      let pageId = el.dataset.pageId;
      let { y: scrollTop = 0 } = this.scrollPostionMap[pageId] || {};

      this.setPageSwitching(false);
      this.restoreBodyScrollPosition(el, scrollTop);
    },
    handleBeforeLeave(el) {
      let pageId = el.dataset.pageId;
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

      this.restoreContainerScrollPosition(el, scrollTop);
      // save current scroll position in a map
      this.savePageScrollPosition({
        pageId,
        scrollPosition: { y: scrollTop },
      });
    },
    handleClickHeaderBack() {
      this.$router.go(-1);
    },
    handleClickHeaderMenu() {
      //this.showSidebar();
      this.setSideBarVisibility(true);
    },
    handleHideSidebar() {
      //this.hideSidebar();
      this.setSideBarVisibility(false);
    },
    handleShowSidebar() {
      // this.showSidebar();
      this.setSideBarVisibility(true);
    },
    setAuditLog() {
      window.sessionStorage.setItem("currentRoute", this.$route.name);
      if (auditLog.isInResetRoute(this.$route.name) || auditLog.isInFormRoute(this.$route.name)) {
        const entityKey = this.$route.params.id || this.$route.query.workOrderId;
        auditLog.setAuditHeaders({ transactionCode: auditLog.getUniqueIdentifier(), entityKey: entityKey });
      } else {
        this.$store.dispatch("inspection/setWorkOrderDetails", null);
        auditLog.removeAuditHeader();
      }
    },
    toggleSubmitForm() {
      // routes that will show the submit form button
      const routeNames = ["dynamicFormId", "workOrderDetailsId"];
      this.appHeaderState.showSubmitFormButton = routeNames.includes(this.$route.name) && this.workOrder && this.workOrder.status === "Rework";
      this.$store.dispatch("navigation/setAppHeader", this.appHeaderState);
    },
    getAimsConfigurations() {
      this.utilityFn.getServerData('get', this.apiList.getAimsConfigurations, { }, res => {
          this.configurations = res.data.data;
          this.configurations.forEach(config => {
            localStorage.setItem(config.config, config.value);
          });
      })
    }
  },
  watch: {
    $route(newVal, oldVal) {

      this.toggleSubmitForm();
      this.setAuditLog();

      // assign active component
      this.$nextTick(() => {
        this.activeComponent = this.$refs.activeComponent;
        console.log('ActiveComponent', this.activeComponent)
      });
    }
  }
};
</script>

<style lang="stylus">
@import '~@/assets/stylus/variable';
@import "~vue-wysiwyg/dist/vueWysiwyg.css";

$page-transition-duration = 0.35s;

  * {
    // max-width 768px !important;
  }
  html {
    border: #ccc 1px solid;
  }
  .v-btn, .v-tab {
    text-transform: none  !important;
  }
  .snackbar-loading
        align-items center
        display flex
        justify-content center
#app {
  width 100%;
  overflow-x hidden;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  color: #2c3e50;
//   height: 100%;

  .application--wrap {
    height: 100%;
    min-height: 100%;
  }

  .app-shell-header {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 6;
  }

  .app-view {
    padding-top 50px;
    // position: absolute;
    // top: 0;
    // right: 0;
    // bottom: 0;
    // left: 0;
    -webkit-overflow-scrolling: touch;
    background: #e8e8e8;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    &.app-view-with-header {
      top: $app-header-height;
    }

    &.transition-slide {
      transition: transform $page-transition-duration cubic-bezier(0, 0, 0.2, 1);

      &.slide-left-enter {
        transform: translate(100%, 0);
      }

      &.slide-left-enter-active {
        box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.3);
      }

      &.slide-right-enter {
        transform: translate(-30%, 0);
        transition-timing-function: linear;
      }

      &.slide-right-leave-active {
        transform: translate(100%, 0);
        box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.3);
        z-index: 99;
      }

      &.slide-left-leave-active {
        transform: translate(-30%, 0);
        transition-timing-function: linear;
      }

      &.app-view-scroll-enabled, &.slide-left-enter-active, &.slide-left-leave-active, &.slide-right-enter-active, &.slide-right-leave-active {
        overflow-y: auto;
      }
    }

    &.transition-fade {
      opacity: 1;
      transition: opacity 1s ease;

      &.fade-enter {
        opacity: 0;
      }

      &.fade-leave-active {
        opacity: 0;
      }
    }
  }
}
</style>
