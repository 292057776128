<template>
  <div>
    <div class="login">
      <div class="loginBox">
        <img src="../../assets/images/ulsolutions_logo_horz_grad_rgb.png" style="width:10%; height:10%;"/>
      </div>
      <p class="title">
        <b class="contentTitle">Welcome to AIMS!</b><br /><b>AIMS,</b> is an <b>A</b>udit and <b>I</b>nspection <b>M</b>obile <b>S</b>oftware that supports both online and offline in-field capture. It organizes the technical elements into a database,
        feed the data to generate client specific reports.
      </p>

      <v-row class="formDate">
        <v-col cols="12" sm="12" md="12"> </v-col>
      </v-row>

      <v-btn class="submit" dark color="#B14C4C" @click="loginb2c" height="45" width="130">Login</v-btn>
    </div>
  </div>
</template>

<script>
function setState(store) {
  store.dispatch("navigation/setAppHeader", {
    show: true,
    title: "AIMS",
    showTitle: true,
    showMenu: false,
    showBack: false,
    showLogo: false,
    showSearch_job: false,
    showSearch_work: false,
    showCalendar: false,
    onlineDownload: true,
    showResource: false
  });
}
import { PublicClientApplication } from '@azure/msal-browser';
import authFn from "../../shared/request/auth";

export default {
  name: "login",
  data() {
    return {
      account: undefined,
      userName: "",
      password: "",
    };
  },
  computed: {
    environment: {
      get() {
        return process.env.VUE_APP_HOST_ENVIRONMENT;
      },
      set() {

      }
    }
  },
  async created() {
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig,
    );    
  },
  mounted() {
    setState(this.$store);
  },
  methods: {
    async loginb2c() {

      

      authFn.login();
      
    }
  },
};
</script>

<style lang="stylus" scoped>
.login {
  width: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  background: url('../../assets/images/login_bg.jpg') 100% 100%;
}

.loginBox {
  background: #fff;
  margin: 0 auto 10%;
  padding: 10px 0;
  padding-top: 60px;
  text-align: center;
}

.brand-logo {
  width: 55px;
  height: 55px;
  margin: auto;
  font-size: 50px;
  fill: #c02032;
  display: block;
}

.title {
  font-size: 20px !important;
  width: 600px;
  margin: auto;
  text-align: justify;
  line-height: 30px;
  font-weight: 100;
  color: #fff;
}

.submit {
  display: block;
  margin: 10% auto 0;
  font-size: 18px;
}

.contentTitle {
  font-size: 24px !important;
  line-height: 70px;
}
</style>
