import Vue from "vue";
import VueRouter from "vue-router";
import * as routerFn from "../shared/router/routerFunctions";
import authFn from "../shared/request/auth";
import VueCookies from "vue-cookies";
//import Home from "../views/Home.vue";
import InspectionIndex from "../views/inspection/InspectionIndex";
import Login from "../views/authentication/Login.vue";
import Authentication from "../views/authentication/Authentication.vue";
import ExpenseDetail from "../views/inspection/ExpenseDetail";
import Assignment from "../views/workorder/Assignment";
import WorkOrderDetails from "../views/workorder/WorkOrderDetails";
import DynamicForm from "../views/forms/DynamicForm";
import DynamicFormBuilder from "../views/forms/DynamicFormBuilder";
import AuditLogs from "../views/common/AuditLogs.vue";
import Download from "../views/workorder/Download";
import OTPAuthentication from "../views/authentication/OTPAuthentication.vue";
import PastInspectionPreview from "../views/inspection/PastInspectionPreview.vue";
import Resubmit from "../views/inspection/Resubmit.vue";
import SignOff from "../views/inspection/SignOff.vue";
import ReworkReason from "../views/inspection/ReworkReason.vue";
import WorkOrderPreview from "../views/workorder/WorkOrderPreview.vue";
import Profile from "../views/user/Profile.vue";
import Search from "../views/workorder/Search.vue";
import SearchById from "../views/workorder/SearchById.vue";
import Settings from "../views/user/Settings.vue";
import SplitWorkOrderForm from "../views/workorder/SplitWorkOrderForm.vue";
import UploadPhoto from "../views/upload/UploadPhoto.vue";
import UploadDefectPhoto from "../views/upload/UploadDefectPhoto.vue";
import UploadPhotoGallery from "../views/upload/UploadPhotoGallery.vue";
import UploadPhotoGalleryQuestion from "../views/upload/UploadPhotoGalleryQuestion.vue";
import UploadPhotoGeneral from "../views/upload/UploadPhotoGeneral.vue";
import UploadPhotoInspection from "../views/upload/UploadPhotoInspection.vue";
import WorkOrderIndex from "../views/workorder/WorkOrderIndex.vue";
import WorkOrderDefect from "../views/workorder/defects/WoDefect.vue";
import AddDefectPhoto from "../views/workorder/defects/AddDefectPhoto.vue";
import EditDefect from "../views/workorder/defects/EditDefect.vue";
import BarCode from "../views/workorder/inspect/Barcode.vue";
import Onsite from "../views/workorder/inspect/Onsite.vue";
import Package from "../views/workorder/inspect/Package.vue";
import ProductConfirmity from "../views/workorder/inspect/ProductConfirmity.vue";
import Quantity from "../views/workorder/inspect/Quantity.vue";
import Size from "../views/workorder/inspect/Size.vue";
import Workmanship from "../views/workorder/inspect/Workmanship.vue";
import Remarks from "../views/workorder/inspect/Remarks.vue";
import Error from "../views/common/Error.vue";
import Unauthorized from "../views/common/Unauthorized.vue";
import { PublicClientApplication } from '@azure/msal-browser';
import store from "../store/index";
import axios from "axios";
import apiList from "../shared/request/apiList";
import WorkOrderPreviewReferenceNumber from "../views/workorder/WorkOrderPreviewReferenceNumber.vue"

Vue.use(VueRouter);
Vue.use(VueCookies);

const routes = [
  {
    path: "/authentication",
    component: Authentication,
    name: "authentication",
  },
  {
    path: "/login",
    components: {
      default: Login,
      login: Login
    },
    name: "login",
  },
  {
    path: "/",
    name: "index",
    component: InspectionIndex,
  },
  {
    path: "/audit-logs",
    component: AuditLogs,
    name: "auditLogs",
  },
  {
    path: "/download",
    component: Download,
    name: "download",
  },
  {
    path: "/expenseDetail",
    name: "expenseDetail",
    component: ExpenseDetail,
  },
  {
    path: "/assignment/:id",
    component: Assignment,
    name: "assignmentId",
  },
  {
    path: "/dynamic-form/:id",
    component: DynamicForm,
    name: "dynamicFormId",
  },
  {
    path: "/dynamic-form-builder/:id",
    component: DynamicFormBuilder,
    name: "dynamicFormBuilderId",
  },
  {
    path: "/otpauthentication",
    component: OTPAuthentication,
    name: "otpauthentication",
  },
  {
    path: "/past-inspections/preview",
    component: PastInspectionPreview,
    name: "pastInspectionsPreview",
  },
  {
    path: "/past-inspections/resubmit",
    component: Resubmit,
    name: "pastInspectionsResubmit",
  },
  {
    path: "/past-inspections/sign-off",
    component: SignOff,
    name: "pastInspectionsSignOff",
  },
  {
    path: "/preview/:id",
    component: WorkOrderPreview,
    name: "previewId",
  },
  {
    path: "/previewReferenceNumber/:id",
    component: WorkOrderPreviewReferenceNumber,
    name: "previewReferenceNumber",
  },
  {
    path: "/profile",
    component: Profile,
    name: "profile",
  },
  {
    path: "/search",
    component: Search,
    name: "search",
    children: [
      {
        path: ":id",
        component: SearchById,
        name: "searchId",
      },
    ],
  },
  {
    path: "/setting",
    component: Settings,
    name: "setting",
  },
  {
    path: "/split-work-order-form/:id",
    component: SplitWorkOrderForm,
    name: "splitWorkOrderForm",
  },
  {
    path: "/upload-photo/:id",
    component: UploadPhoto,
    name: "uploadPhotoId",
  },
  {
    path: "/upload-photo/defect/:id",
    component: UploadDefectPhoto,
    name: "uploadPhotoDefectId",
  },
  {
    path: "/upload-photo/gallery/:id",
    component: UploadPhotoGallery,
    name: "uploadPhotoGalleryId",
  },
  {
    path: "/upload-photo/gallery/question",
    component: UploadPhotoGalleryQuestion,
    name: "uploadPhotoGalleryQuestion",
  },
  {
    path: "/upload-photo/general/:id",
    component: UploadPhotoGeneral,
    name: "uploadPhotoGeneralId",
  },
  {
    path: "/upload-photo/inspection/:id",
    component: UploadPhotoInspection,
    name: "uploadPhotoInspectionId",
  },
  {
    path: "/work-order/:id",
    component: WorkOrderIndex,
    name: "workOrderId",
  },
  {
    path: "/work-order/defect/:id",
    component: WorkOrderDefect,
    name: "workOrderDefectId",
  },
  {
    path: "/work-order/defect/add-defect-photo/:id",
    component: AddDefectPhoto,
    name: "workOrderDefectAddDefectPhotoId",
  },
  {
    path: "/work-order/defect/edit/:id",
    component: EditDefect,
    name: "workOrderDefectEditId",
  },
  {
    path: "/work-order/details/:id",
    component: WorkOrderDetails,
    name: "workOrderDetailsId",
  },
  {
    path: "/work-order/inspection/barcode/:id",
    component: BarCode,
    name: "workOrderInspectionBarcodeId",
  },
  {
    path: "/work-order/inspection/onsite/:id",
    component: Onsite,
    name: "workOrderInspectionOnsiteId",
  },
  {
    path: "/work-order/inspection/package/:id",
    component: Package,
    name: "workOrderInspectionPackageId",
  },
  {
    path: "/work-order/inspection/product-conformity/:id",
    component: ProductConfirmity,
    name: "workOrderInspectionProductConformityId",
  },
  {
    path: "/work-order/inspection/quantity/:id",
    component: Quantity,
    name: "workOrderInspectionQuantityId",
  },
  {
    path: "/work-order/inspection/size/:id",
    component: Size,
    name: "workOrderInspectionSizeId",
  },
  {
    path: "/work-order/inspection/workmanship/:id",
    component: Workmanship,
    name: "workOrderInspectionWorkmanshipId",
  },
  {
    path: "/work-order/remarks/:id",
    component: Remarks,
    name: "workOrderRemarksId",
  },
  {
    path: "/error",
    component: Error,
    name: "error",
  },
  {
    path: "/unauthorized",
    components: {
      default: Unauthorized,
      login: Unauthorized
    },
    name: "unauthorized"
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting AddDefectPhoto
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/past-inspections/rework-reason/:id",
    component: ReworkReason,
    name: "pastInspectionsResubmitReworkReason",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  routerFn.setPageTransitions(to, from);
  routerFn.initiaLizeLocalStorage();

  if (
    to.name &&
    to.name == "workOrderInspectionWorkmanshipId" &&
    from.name == null
  ) {
    router.push({
      name: "dynamicFormId",
      params: { id: to.params.id },
      query: { displayId: to.query.displayId },
    });
  }

  next();


  // let token = JSON.parse(localStorage.getItem("tokenInfo"));

  // // let msal = new PublicClientApplication(
  // //   store.state.msalConfig,
  // // );   
  // // var account = msal.getAllAccounts()[0];

  // // console.log(account);
  // // console.log(!account);

  // if(to.name === "error" || to.name === "unauthorized") {
  //   next();
  //   return;
  // }

  // if (to.name === "login") {
  //   routerFn.LoginSettings();
  //   next();
  //   return;
  // } 
  // if(to.name === "logout" ) {
  //   next('/login');
  //   return;
  // }

  // //If token from Canyon is null or expired
  // //get new token by calling authenticate api  
  // //if b2c token is also expired, user will be asked to log back in to get new token
  // // if (!token || token.expDate < new Date().toISOString()) 
  // // {
  // //   //Need to get new token from api
  // //   await authFn.authenticate().then(function(response) {
  // //     console.log(response);
  // //     if(response)
  // //       next();
  // //     else {
  // //       next('/login');
  // //       //authFn.login();
  // //     }
  // //   });
  // // }

  // if (
  //   to.name &&
  //   to.name == "workOrderInspectionWorkmanshipId" &&
  //   from.name == null
  // ) {
  //   router.push({
  //     name: "dynamicFormId",
  //     params: { id: to.params.id },
  //     query: { displayId: to.query.displayId },
  //   });
  // }

  // next();

});
export default router;
