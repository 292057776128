<template>
  <div name="SamplingMethodology">
    <v-select v-model="msgData.values[0].value" :items="items"   @change="calculateSampleSize($event)" @blur="sava_field_value(msgData.formAtomID, msgData.values[0].value, msgData.values[0].referenceKey)" auto-id="sampling methodolgy select list">
      <template v-slot:label>
        {{msgData.label}}<span v-if="msgData.isRequired" class="red--text"> *</span>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as _ from "lodash";
export default {
  name: "SamplingMethodology",
  data() {
    return {
      items: [],
      samplingPlan:[],
      fieldReference: {
        label: "",
        formAtomID: "",
      },
      numberOfProducts: 0,
      workOrder: {
        resources: [],
        workOrderInspectionSections: [],
      },
      products:[],
      selectedSamplingMethodology:'',
      sectionId: 1,
      skuList :[]
    };
  },
  computed: {
        ...mapState("inspection", {
      _samplingPlanLists: (state) => state.samplingPlanLists,
    
    }),
  },
  async mounted() {
    await this.loadData()

    this.fieldReference.label = this.msgData.fieldReference.split(":")[0];
    this.fieldReference.formAtomID = this.msgData.fieldReference.split(":")[1];

    if (document.getElementById(this.fieldReference.formAtomID.toString()) != null) {
      this.numberOfProducts = document.getElementById(this.fieldReference.formAtomID.toString()).innerHTML;
    }

    this.items = this.msgData.fieldLookupValues.result.map((x) => {
      return { text: x.value, value: x.key };
    });
    if(this.numberOfProducts == 1 && this.msgData.values[0].value == null){
        this.msgData.values[0].value = "Separated Sample Size";
        this.calculateSampleSize(this.msgData.values[0].value)
    }
    else if(this.numberOfProducts > 1 && this.msgData.values[0].value == null){
        this.msgData.values[0].value = "Combined Sample Size";
        this.calculateSampleSize(this.msgData.values[0].value)
    }
  },


   async activated() {
    this.loadData()
  },
  methods: {
    async loadData(){
      let workOrderId = this._workOrderDetails.workOrderId 

      const result1 = await this.utilityFn.getServerDataAsync("get", this.apiList.workOrderSamplingParameterAllSectionDTO + "/" + workOrderId, {});
        if (result1 && result1.data) {
          console.log('result1',result1)
          this.workOrder  =  result1.data[0];
          this.skuList = result1.data[0].skuLists
        }

      const result = await this.utilityFn.getServerDataAsync("get", this.apiList.workOrderDetailsQuantity + "/" + workOrderId, {});
        if (result && result.data) {
          this.products = result.data.products;  
        }
    },
    sava_field_value(id, val, key) {
      // console.log(item)
      var data = [
        {
          formAtomID: id,
          value: val,
          referenceKey: key,
        },
      ];
      this.utilityFn.getServerData("post", this.apiList.baseUrl + "/api/AIMS/UpsertFormValue/" + this.$route.params.id, data, (res) => {
        console.log(res);

        this.sectionHelper.updatedDynamicSectionModifiedDate(this.sectionName);
      });
    },

    calculateSampleSize(value) {
 
      this.selectedSamplingMethodology = value;
      let samplingMethodology = value;
      let inspectionLevel = this.workOrder.inspectionLevel
      let samplingPlan1 = this.workOrder.samplingPlan1;
      let round = this.workOrder.round;
      let productType = this.workOrder.productType;    
      let aqlMinor = this.workOrder.aqlMinor;
      let aqlMajor = this.workOrder.aqlMajor;
      let aqlCritical = this.workOrder.aqlCritical;
      let workorderId = this.workOrder.workOrderId

      let workorderSamplingParameters = {
        samplingMethodology : samplingMethodology,
        inspectionLevel : inspectionLevel,
        samplingPlan1 : samplingPlan1,
        round : round,
        productType : productType,
        aqlMinor:aqlMinor,
        aqlMajor: aqlMajor,
        aqlCritical: aqlCritical,
        workorderId : workorderId,
        changedSamplingMethodology: true
      }


      let returnedSampleSizePlan = this.samPlan.getAutoComputeSampleSize(samplingMethodology, this.skuList, inspectionLevel, samplingPlan1, round, productType, "General", false);
      let samplinPlan = {...workorderSamplingParameters, ...returnedSampleSizePlan};
      console.log('samplinPlan sampling methodology=> samplingMethodology',samplinPlan)
      this.$store.dispatch("inspection/setSamplingPlanList", samplinPlan)     
        var data = [
          {
            op: "replace",
            path: "/sampleSize",
            value: samplinPlan.totalProductSampleSize
          },
          {
            op: "replace",
            path: "/workmanshipSampleSize",
            value: samplinPlan.totalProductSampleSize
          },
          {
            op: "replace",
            path: "/samplingMethodology",
            value: samplingMethodology,
          },
        ];

        this.utilityFn.getServerData("patch", this.apiList.patchWorkOrderResults + "/" + workorderId + "/null", data, (res) => {});  

        this.calculateSizeMeasurementSampleSize();
        this.calculateBarcodeSampleSize();
        this.calculatePackageCheckSampleSize();

    },

      async calculateSizeMeasurementSampleSize() {
      if (!this.workOrder.sizeMeasurement) return;

      const inspectionLevel = this.workOrder.inspectionLevel;
      const samplingMethodology = this.selectedSamplingMethodology;
      const isInitialCalculate = false;
      let wopSizeMeasurementResult = [];

      if(!inspectionLevel || (this.products.length > 1 && !samplingMethodology))return

      let samplingPlanByInspectionLevelAndSamplingMethodology = this.samPlan.getSamplingPlanByInspectionLevelAndSamplingMethodology(inspectionLevel, samplingMethodology);

      this.products.forEach((wop) => {
        if (wop.workOrderProductSizeMeasurementResults.length == 0) return;

        wop.workOrderProductSizeMeasurementResults.forEach((item) => {
          if (!item.isCarton) {
            let productSampleSize = null;
            if (inspectionLevel != "Fixed") {
              let filteredSKUSamplingList = samplingPlanByInspectionLevelAndSamplingMethodology.SKUSamplingList.filter((x) => x.id == wop.id);
              console.log("SizeMeasurement 1", filteredSKUSamplingList);
              if (filteredSKUSamplingList.length > 0) {
                productSampleSize = filteredSKUSamplingList[0].productSampleSize;
              }
            }

            const sampleSize = productSampleSize == item.sampleSize ? item.sampleSize : productSampleSize;
            wopSizeMeasurementResult.push({
              id: item.id,
              patch: [{ op: "replace", path: "/sampleSize", value: sampleSize }],
              workOrderProductId: item.workOrderProductId,
              patchWOP: [{ op: "replace", path: "/isManualSampleSizeProduct", value: false },
              { op: "replace", path: "/isManualSampleSizeCarton", value: false }]
            });
          }
        });
      });

      if (wopSizeMeasurementResult.length > 0) {
        await this.utilityFn.getServerDataAsync("patch", this.apiList.patchWorkOrderProductSizeMeasurementResult, wopSizeMeasurementResult);
      }
    },

    async calculateBarcodeSampleSize() {
      if (!this.workOrder.barcodeVerification) return;

      // carton
      if (this.workOrder.barcodeVerification.cartonInspectionLevel == "Square Root") {
        const totalAvailCartonQty = this.skuList.reduce((prev, cur) => prev + cur.cartonQty, 0);
        const sumAllSqrtPerSkuCartonQty = this.skuList.reduce((prev, cur) => prev + Math.ceil(Math.sqrt(cur.cartonQty)), 0);

        if (this.products.length == 1) {
          this.workOrder.barcodeVerification.cartonSampleSize = Math.ceil(Math.sqrt(totalAvailCartonQty));
        } else if (this.products.length > 1 && this.selectedSamplingMethodology == "Combined Sample Size") {
          // const sqrtTotal = this._samplingPlanLists.SKUSamplingList.reduce((prev, cur) => Number(prev) + this.getSquareRootCarton(cur.cartonQty || 0, totalAvailCartonQty), 0);
          const sqrtTotal = Math.ceil(Math.sqrt(this._samplingPlanLists.totalCartonPOQty))
          this.workOrder.barcodeVerification.cartonSampleSize = sqrtTotal;
          console.log(sqrtTotal,'sqrtTotal')
        } else if (this.products.length > 1) {
          this.workOrder.barcodeVerification.cartonSampleSize = sumAllSqrtPerSkuCartonQty;
        }
      }

      // product
      if(!this.workOrder.barcodeVerification.productInspectionLevel || (this.products.length > 1 && !this.selectedSamplingMethodology))return;

      const aql = {
          aqlCritical: "0",
          aqlMinor: "0",
          aqlMajor: "0"
      };
      const sampling = this.samPlan.getSamplingPlanByInspectionLevelAndSamplingMethodology(this.workOrder.barcodeVerification.productInspectionLevel, this.selectedSamplingMethodology,aql);
      if (sampling) {
        this.workOrder.barcodeVerification.productSampleSize = sampling.totalProductSampleSize;
      }

      this.workOrder.barcodeVerification.sectionId = null;
      await this.utilityFn.getServerDataAsync("post", this.apiList.upsertWorkOrderBarcodeVerification, this.workOrder.barcodeVerification);
    },


    async calculatePackageCheckSampleSize() {
      if (!this.workOrder.packageCheck || this.workOrder.packageCheck.ilPkgCheck == "Fixed") return;

      if (this.workOrder.packageCheck.ilPkgCheck == "Square Root") {
        const totalAvailCartonQty = this.skuList.reduce((prev, cur) => prev + cur.cartonQty, 0);
        const sumAllSqrtPerSkuCartonQty = this.skuList.reduce((prev, cur) => prev + Math.ceil(Math.sqrt(cur.cartonQty)), 0);

        if (this.products.length == 1) {
          return (this.workOrder.packageCheck.crtnSampleSize = Math.ceil(Math.sqrt(totalAvailCartonQty)));
        }

        if (this.products.length > 1 && this.selectedSamplingMethodology == "Combined Sample Size") {
          const sqrtTotal = this.skuList.reduce((prev, cur) => Number(prev) + this.getSquareRootCarton(cur.cartonQty || 0, totalAvailCartonQty), 0);
          return (this.workOrder.packageCheck.crtnSampleSize = Math.ceil(sqrtTotal));
        }

        if (this.products.length > 1) {
          return (this.workOrder.packageCheck.crtnSampleSize = sumAllSqrtPerSkuCartonQty);
        }
      } else {
        const sampling = this.samPlan.getSamplingPlanByInspectionLevelSamplingMethodologyInspectionLevel1doubleSamplingPlanIteration(
          this.workOrder.packageCheck.ilPkgCheck,
          this.selectedSamplingMethodology,
          this.workOrder.packageCheck.inspectionLevel1,
          this.workOrder.packageCheck.doubleSamplingPlan
        );
        if (sampling) {
          this.workOrder.packageCheck.crtnSampleSize = sampling.totalCartonSampleSize;
        }
      }

      this.workOrder.packageCheck.sectionId = null;
      await this.utilityFn.getServerDataAsync("post", this.apiList.upsertWorkOrderPackageCheck, this.workOrder.packageCheck);
    },
    getSquareRootCarton(quantity, totalCartons, samplingMethodology) {
      let squareRootCarton = null;
      if (quantity == null || quantity == '' ) {
        return squareRootCarton;
      }

      if (this.products.length == 1) {
        return (squareRootCarton = Math.ceil(Math.sqrt(quantity)));
      }

      if (this.products.length > 1 && samplingMethodology == "Combined Sample Size") {
        return (squareRootCarton = Math.ceil((Math.sqrt(totalCartons) / totalCartons) * quantity));
      }

      if (this.products.length > 1) {
        return (squareRootCarton = Math.ceil(Math.sqrt(quantity)));
      }
    },



  },

  computed: {
    ...mapState("inspection", {
      _samplingPlanLists: (state) => state.samplingPlanLists,
       _workOrderDetails: (state) => state.workOrderDetails,
    }),
  },

  props: {
    msgData: {
      type: Object,
    },
    msgFormAtom: {
      type: Array,
    },
    sectionName: {
      type: String,
    },
  },
};
</script>

<style lang="stylus" scoped></style>
