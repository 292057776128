import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
    namespaced: true,
    state() {
        return {
            token: '',
            jobList: [],
            workList: [],
            downLoadNumber: 0,
           // online: true,
            myAuthorized: [],
            searchValue: '',
            sectionData: JSON.parse(window.sessionStorage.sectionData || null),
            resourceName: window.localStorage.resourceName,
            workOrderDetails: JSON.parse(window.sessionStorage.workOrderDetails || null),
            updatedRemarksEntry: null,
            remarksLists: [],
            enabledWorkOrderSearch: false,
            samplingPlanLists: [],
            formAtomsList: [],
            productQuantityVerificationList: [],
            qvListUpdated: window.sessionStorage.qvListUpdated === 'true',
            clientDefectList: [],
            childWorkOrderSizeMeasurement: {},
            inspectionSummarySectionResult: [],
            isLoggedIn : window.localStorage.IsLoggedIn,
            clientDefectsLoadingStatus: 1,
            currentActiveWorkOrder: {},
            dynamicFormForceRefresh: false,
            criticalPOMList: [],
            retailMarketCdf: ''
        };
    },
    mutations,
    actions,
    getters
};
