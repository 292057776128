import { SET_JOB_LIST, SET_DOWNLOAD_NUMBER, SET_RESOURCE_NAME, SET_SEARCH_VALUE, 
         SET_WORKORDER_DETAILS,SET_ONLINE,SET_WORK_LIST,SET_CLIENT_DEFECT_LIST,SET_SECTION_DATA,
         EDIT_MY_AUTHORIZED,SET_REMARKS_LIST,SET_MY_AUTHORIZED,SET_UPDATED_REMARKS_ENTRY,
         SET_SAMPLING_PLAN_LIST, SET_FORM_ATOM_LIST,SET_ENABLED_WO_SEARCH,SET_QV_LIST,SET_TOKEN,
         SET_PROD_QUAN_VERF_LIST,SET_CHILD_WO_SIZE_MEASURE,SET_LOGGEDIN, SET_CLIENT_DEFECTS_LOADING_STATUS,SET_INSPECTION_SUMMARY_SECTION_RESULT,
         SET_CURRENT_ACTIVE_WORKORDER,
         SET_DYNAMIC_FORM_FORCE_REFRESH, SET_CRITICAL_POM_LIST,SET_RETAIL_MARKET_CDF
        } from './mutation.types'

export default {
    [SET_JOB_LIST](state, arr) {
        state.jobList = arr;
    },

    [SET_SEARCH_VALUE](state, str) {
        state.searchValue = str;
    },

    [SET_TOKEN](state, str) {
        state.token = str;
    },
    [SET_WORK_LIST](state, arr) {
        state.workList = arr;
    },
    [SET_DOWNLOAD_NUMBER](state, num) {
        state.downLoadNumber = num;
    },
    // [SET_ONLINE](state, payload) {
    //     state.online = payload;
    // },
    [SET_SECTION_DATA](state, data) {
        window.sessionStorage['sectionData'] = JSON.stringify(data);
        state.sectionData = data;
    },
    [SET_RESOURCE_NAME](state, data) {
        window.localStorage['resourceName'] = data;
        state.resourceName = data;
    },
    [SET_MY_AUTHORIZED](state, obj) {
        state.myAuthorized.push(obj);
    },
    [EDIT_MY_AUTHORIZED](state, obj) {
        var index = state.myAuthorized.findIndex(x => x.id == obj.id)
        state.myAuthorized[index][obj.key] = obj.value;
    },
    [SET_WORKORDER_DETAILS](state, data) {
        window.sessionStorage['workOrderDetails'] = data ? JSON.stringify(data) : '';
        state.workOrderDetails = data;
    },
    [SET_UPDATED_REMARKS_ENTRY](state, data) {
        state.updatedRemarksEntry = data;
    },
    [SET_REMARKS_LIST](state, data) {
        state.remarksLists = data;
    },
    [SET_ENABLED_WO_SEARCH](state, data) {
        state.enabledWorkOrderSearch = data;
    },
    [SET_SAMPLING_PLAN_LIST](state, data) {
        state.samplingPlanLists = data;
    },
    [SET_FORM_ATOM_LIST](state, data) {
        state.formAtomsList = data;
    },
    [SET_PROD_QUAN_VERF_LIST](state, data) {
        state.productQuantityVerificationList = data;
    },
    [SET_QV_LIST](state, data) {
        window.sessionStorage['qvListUpdated'] = data
        state.qvListUpdated = data;
    },

    [SET_CLIENT_DEFECT_LIST](state, data) {
        state.clientDefectList = data;
    },
    [SET_CHILD_WO_SIZE_MEASURE](state, data) {
        state.childWorkOrderSizeMeasurement = data;
    },
    [SET_INSPECTION_SUMMARY_SECTION_RESULT](state,data) {
        state.inspectionSummarySectionResult = data
    },
    [SET_LOGGEDIN](state,data) {
        state.isLoggedIn = data;
    },
    [SET_CLIENT_DEFECTS_LOADING_STATUS](state, data) {
        state.clientDefectsLoadingStatus = data;
    },
    [SET_CURRENT_ACTIVE_WORKORDER](state, data) {
        state.currentActiveWorkOrder = data;
    },
    [SET_DYNAMIC_FORM_FORCE_REFRESH](state, data) {
        state.dynamicFormForceRefresh = data;
    },
    [SET_CRITICAL_POM_LIST](state, data) {
        state.criticalPOMList = data;
    },
    [SET_RETAIL_MARKET_CDF](state, data) {
        state.retailMarketCdf = data;
    }
};