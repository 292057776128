<template>
  <v-autocomplete
    v-model="selected"
    :items="items"
    :label="label"
    :item-text="itemText"
    :item-value="itemValue"
    no-filter
    clearable
    :search-input.sync="handleSearch"
    :hide-selected="hideSelected"
    :outlined="isOutlined"
  ></v-autocomplete>
</template>

<script>
import * as _ from "lodash";

export default {
  name: "AutocompleteSearch",
  props: {
    url: String,
    itemText: {
      default: "value",
      type: String,
    },
    itemValue: {
      default: "id",
      type: String,
    },
    isOutlined: {
      default: true,
      type: Boolean,
    },
    label: {
      default: "Search",
      type: String,
    },
    allowManualInput: {
      default: false,
      type: Boolean,
    },
    hideSelected: {
      default: false,
      type: Boolean,
    },
    existingValue: String,
  },
  data() {
    return {
      items: [],
      handleSearch: null,
      selected: null,
      input: null,
    };
  },
  methods: {
    getData: _.debounce(async function(searchText) {
      const res = await this.utilityFn.getServerDataAsync("get", this.url, {
        searchText: searchText,
      });
      if(this.input === searchText) {
        this.items = res.data;
        if (this.allowManualInput) {
          this.selected = searchText;
        }
      } else {
        this.items = [];
      }
    }, 1000),
  },
  watch: {
    handleSearch(searchText) {
      this.input = searchText;
      if (!searchText || searchText.length < 3) {
        this.items = [];
        return;
      }
      if (this.items.find((i) => i[this.itemText] === searchText)) return;
      this.getData(searchText);
    },
    selected(newVal) {
      const value = this.items.find(
        (i) => i[this.itemValue] === newVal && !i.isManual
      );
      if (this.allowManualInput && !value && newVal) {
        const addedItem = { [this.itemValue]: newVal, isManual: true };
        this.items.push(addedItem);
        this.$emit("onSelect", addedItem);
      } else {
        this.items = this.items.filter((i) => !i.isManual);
        this.$emit("onSelect", value);
      }
    },
    existingValue: {
      handler(newVal) {
        this.items = [];
        const addedItem = { [this.itemValue]: newVal, isManual: true };
        this.items.push(addedItem);
        this.selected = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
