<template>
  <div>
    <!-- Need to create table with data from msgData -->
    <v-simple-table auto-id="table question">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">{{ msgData.questionCategory.name }}</th>
            <th class="text-left" v-for="(header,idx) in atoms" :key="idx">{{ header.label }}</th>
            <th class="text-left">Not Applicable</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <!-- <tr v-for="item in msgData.questionCategory.questions" :key="item.questionId"> -->
             <tr v-for="(item,idx) in msgData.questionCategory.questions" :key="idx">
            <td @click.stop="openDialog(item)" auto-id="question text">{{ item.questionText }}</td>
            <!-- <td @click.stop="openDialog(item)" v-for="col in atoms" :key="col">{{ getDisplayValue(item, col) }}</td> -->
               <td @click.stop="openDialog(item)" v-for="(col,idx) in atoms" :key="idx" auto-id="question display value">{{ getDisplayValue(item, col) }}</td>
            <td><v-switch v-model="item.notApplicable" color="green" @change="toggleNA(item)" auto-id="question not applicable"></v-switch></td>
            <td @click.stop="go_to_upload_photo(item)" auto-id="question camera">
              <span>
                <icon name="camera"></icon>
              </span>
            </td>
          </tr>
          <tr>
            <h5>Note: The above item is needed to provide the photo in the report.</h5>
          </tr>
          <v-dialog v-model="dialog" persistent max-width="600px">
            <v-form v-model="valid">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ header }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col v-for="(item,idx) in atoms" cols="12" :sm="item.colSize" :key="idx">
                        <v-text-field v-if="item.controlType == 'textbox'" v-model="getValue(item.id).response" :label="checkLabel(item)" :rules="getRules(item)" auto-id="question response"></v-text-field>
                        <v-textarea maxlength="200" :counter="200" v-if="item.controlType == 'textarea'" v-model="getValue(item.id).response" :label="checkLabel(item)" :rows="4" :rules="getRules(item)" auto-id="question response text area"></v-textarea>
                        <v-radio-group row v-if="item.controlType == 'radio button'" :label="checkLabel(item)" v-model="getValue(item.id).response" :rules="getRules(item)" auto-id="question radio button">
                          <v-radio label="Yes" value="Yes" auto-id="question yes"></v-radio>
                          <v-radio label="No" value="No" auto-id="question no"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <van-button type="button" @click.stop="onImageSelection" auto-id="question on image selection">
                          <icon name="camera"></icon>
                        </van-button>
                      </v-col>
                      <v-col>
                        <div class="imglist select_img">
                          <transition-group tag="ul">
                            <!--images go here-->
                            <li v-for="(attachment, aindex) in attachments" :key="aindex" class="item">
                              <div v-if="attachment.src == null || attachment.src == ''">
                                <!--Load image-->
                                Loading...
                                <!--{{ load_image(attachment) }}-->
                              </div>
                              <div v-else class="holder">
                                <span class="del_button" @click="del_img(aindex)" auto-id="question delete button">
                                  <v-icon name="times-circle" class="icon"/>
                                </span>
                                <img class="imgItem" :src="attachment.src" />
                              </div>
                            </li>
                          </transition-group>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                  <small>*indicates required field</small>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDialog()" auto-id="question close dialog">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="save()" :disabled="!valid || saveInProgress" auto-id="question save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
export default {
  name: "Question",
  data() {
    return {
      items: [],
      atoms: [],
      attachments: [],
      dialog: false,
      header: "",
      activeQuestionResults: [],
      questionToSave: null,
      valid: true,
      saveInProgress: false,
    };
  },
  computed: {},
  mounted() {
    this.atoms = this.msgData.questionCategory.questionAtoms;
  },
  methods: {
    save_question(question, closeDialog) {
      var data = {};
      var atoms = [];

      data.questionId = question.questionId;
      data.workOrderId = this.$route.params.id;
      data.notApplicable = question.notApplicable;
      data.attachments = question.attachments;

      question.results.forEach((x) => {
        atoms.push({
          questionAtomId: x.questionAtomId,
          response: x.response,
        });
      });

      data.atomResults = atoms;

      this.utilityFn.getServerData("post", this.apiList.baseUrl + "/api/AIMS/SaveQuestionResult", data, (res) => {});

      if (this.dialog) this.closeDialog();
    },
    getDisplayValue(question, atom) {
      let questionAtomId = atom.id;
      //Get the value from the question.questionresult array where questionatomid matches
      if (question.results == null || question.results.length < 1) return "";
      if (question.notApplicable && atom.controlType == "textbox") return "N/A";

      const value = question.results.find((x) => x.questionAtomId == questionAtomId);
      return value && value.response ? value.response : "";
    },
    getValue(questionAtomId) {
      if (this.activeQuestionResults == null || this.activeQuestionResults.length < 1)
        return {
          response: "",
        };
      const result = this.activeQuestionResults.find((x) => x.questionAtomId == questionAtomId);

      return result;
    },
    openDialog(question) {
      if (question.notApplicable) return;
      this.header = this.msgData.questionCategory.name + ": " + question.questionText;
      this.activeQuestionResults = JSON.parse(JSON.stringify(question.results));
      this.questionToSave = question;
      if (question.attachments) {
        question.attachments.forEach((x) => {
          //need to begin loading images
          if (x.src == null || x.src == "") this.load_image(x);
        });
        this.attachments = question.attachments;
      }

      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.activeQuestionResults = [];
      this.attachments = [];
      this.questionToSave = null;
      this.saveInProgress = false;
    },
    save() {
      this.saveInProgress = true;
      this.questionToSave.results = this.activeQuestionResults;
      this.questionToSave.attachments = this.attachments.filter((x) => x.src != null || x.src != "");

      this.save_question(this.questionToSave, true);
    },
    toggleNA(question) {
      //loop through question results and null out values
      question.results.forEach((x) => {
        x.response = null;
      });

      this.save_question(question, false);
    },
    checkLabel(item) {
      return item.isRequired ? item.label + " *" : item.label;
    },
    getRules(item) {
      let rules = [(v) => !item.isRequired || !!v || "This is required!"];
      return rules;
    },
    go_to_upload_photo(question) {
      this.$router.push({ name: "uploadPhotoGalleryQuestion", params: { id: this.$route.params.id, question: question } });
    },
    del_img(index) {
      this.attachments.splice(index, 1);
    },
    load_image(attachment) {
      //load attachment from API
      this.utilityFn.getImage(attachment.id, (res) => {
        attachment.src = "data:" + res.fileType + ";base64," + res.data.data;
      });
    },
    onImageSelection(event) {
      event.preventDefault();
      var inputObj = document.createElement("input");
      inputObj.setAttribute("accept", "image/*");
      inputObj.setAttribute("multiple", "multiple");
      inputObj.setAttribute("id", "_ef");
      inputObj.setAttribute("type", "file");
      inputObj.setAttribute("style", "visibility:hidden");
      document.body.appendChild(inputObj);
      inputObj.click();
      inputObj.onchange = (res) => {
        //this.isUpdate = false;
        //this.clearUpdateSelection();

        const loadImage = async () => {
          for (let i = 0; i < inputObj.files.length; i++) {
            let file = inputObj.files[i];
            var sizeInfo = this.utilityFn.formatBytes(file.size)    
            console.log('sizeInfo=>',sizeInfo)
            if(sizeInfo.sizeKB > 500){
                this.$store.dispatch("navigation/setSnackbar",{
                  color :'error',
                  text:`Each upload only accepts image size less than 500KB. Please resize/compact the image with size greater than 500KB. Filename: ${file.name}.`});
            }
            else{
              const reader = new FileReader();
              const attachment = {
                id: null,
                src: await returnBase64(file),
              };
              this.attachments.push(attachment);
            }         
          }
        };
        const returnBase64 = (file) => {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              var reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            }, 500);
          });
        };
        loadImage().then(() => {
          console.log("done");
        });
      };
    },
  },
  props: {
    msgData: {
      type: Object,
    },
    msgFormAtom: {
      type: Array,
    },
  },
};
</script>

<style lang="stylus" scoped>
ul
    padding 0
.row
    background: #fff
    margin: 15px

.item
    display: inline-block
    width: 20%;
    margin: 1.14%;

.holder
    position: relative

.imglist .holder .imgItem
    width: 100%
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.3)


.imgItem.updateSelected
    border: 2px solid #c02032

.badge-number
    position: absolute
    bottom: 0
    right: 0
    padding: 8px
    background-color: #fff
    box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.3)
    border-radius: 100px
    font-weight: bold
    font-size: 10px

.public_img, .select_img
    text-align: left
    // border 1px #ccc solid
    background: #ffffff
    padding: 10px 0

.public_img .imgItem
    width: 12%;
    margin: 1.14%;

.del_button
    position: absolute
    right: -3px;
    top: -5px;
</style>
