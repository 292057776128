import store from '../../store/index.js';
import axios from 'axios'
import apiList from '../request/apiList';
import apiDetails from "../request/apiDetails";

const def = {}

function getDynamicSectionId (sectionName) {
    const workOrder = store.getters['inspection/getWorkOrderDetails'];
    let section = null;
    if (workOrder && workOrder.workOrderId) {
        section = workOrder.workOrderInspectionSections
        .find(x => x.dynamicSection && x.dynamicSection.name == sectionName);
    }
    return !!section ? section.dynamicSectionId : null;
}

function updateModifiedDate(sectionId) {
    if (!sectionId) return

    const workOrder = store.getters['inspection/getWorkOrderDetails'];
    if (workOrder && workOrder.workOrderId) {
        axios.post(apiList.updateWorkOrderDataCaptureModifiedDate + '/'
         + workOrder.workOrderId + '?sectionId=' + sectionId, {}, {
            headers:  apiDetails.header.headersDetail,
        }).then(() => {});
    }
}

def.updatedDynamicSectionModifiedDate = function(sectionName) {
    if (!sectionName) return

    const sectionId = getDynamicSectionId(sectionName);
    updateModifiedDate(sectionId);
}

def.updateSectionModifiedDate = function(sectionId) {
    if (!sectionId) return

    updateModifiedDate(sectionId);
}

def.isSectionEnabled = function(sectionId) {
    const workOrder = store.getters['inspection/getWorkOrderDetails'];
    const resourceId = localStorage.getItem('resourceID');

    if (!workOrder || !workOrder.resources) return false

    // if only one assigned resource, section is always enabled
    if (workOrder.resources.length == 1) return true;

    let section = null;
    if (workOrder && workOrder.workOrderId) {
        section = workOrder.workOrderInspectionSections.find(x => x.sectionId == sectionId && x.resourceId && x?.resourceId.toLowerCase() == resourceId.toLowerCase());
    }
    return !!section && !section.submittedDate;
}


export default def
