<template>
  <div class="preview">
    <div class="preview_headers">
      <span @click="go_back">
        <v-icon color="white" class="app-header-icon">arrow_back</v-icon>
      </span>
      <span @click.stop="drawer = !drawer" style="float: right;">
        <v-icon color="white" class="app-header-icon">menu</v-icon>
      </span>
    </div>
    <div class="content" ref="content">
      <v-card>
        <v-row>
          <v-col cols="12" sm="12" md="12" class="py-2"
            ><label>Job ID</label><span>{{ WorkOrderList.jobDisplayId }}</span></v-col
          >
          <v-col cols="12" sm="12" md="12" class="py-2"
            ><label>Work Order：</label><span>{{ WorkOrderList.workOrderDisplayId }}</span></v-col
          >
          <v-col cols="12" sm="12" md="12" class="py-2"
            ><label>Company：</label><span>{{ WorkOrderList.client }}</span></v-col
          >
          <v-col cols="12" sm="12" md="12" class="py-2"
            ><label>Supplier：</label><span>{{ WorkOrderList.supplier }}</span></v-col
          >
          <v-col cols="12" sm="12" md="12" class="py-2"
            ><label>Factory：</label><span>{{ WorkOrderList.factory }}</span></v-col
          >
          <v-col cols="6" sm="6" md="6" class="py-2"
            ><label>Service Performed：</label><span>{{ WorkOrderList.workOrderClass }}</span></v-col
          >
          <v-col cols="6" sm="6" md="6" class="py-2"
            ><label>Shipment Qty：</label><span>{{ WorkOrderList.shipmentQty }}</span></v-col
          >
          <v-col cols="12" sm="12" md="12" class="py-2"
            ><label>Order Qty：</label><span>{{ WorkOrderList.orderQty }}</span></v-col
          >
          <!-- <v-col cols="6" sm="6" md="6" class="py-2"><label>Sample Size：</label><span>{{WorkOrderList.sampleSize}}</span></v-col> -->
          <!-- <v-col cols="6" sm="6" md="6" class="py-2"><label>Inspection Level 1：</label><span>{{WorkOrderList.inspectionLevel}}</span></v-col>
		            <v-col cols="12" sm="12" md="12" class="py-2"><label>AQL Level：</label><span>{{WorkOrderList.aqlLevels}}</span></v-col> -->
          <v-col cols="6" sm="6" md="6" class="py-2"
            ><label>Technical File Available：</label><span>{{ WorkOrderList.technicalFileAvailable == null ? "None" : WorkOrderList.technicalFileAvailable == true ? "Yes" : "No" }}</span></v-col
          >
          <v-col cols="6" sm="6" md="6" class="py-2"
            ><label>Provided by：</label><span>{{ WorkOrderList.technicalFileProvidedBy }}</span></v-col
          >
          <v-col cols="6" sm="6" md="6" class="py-2"
            ><label>Ref Sample：</label><span>{{ WorkOrderList.refSample == null ? "None" : WorkOrderList.refSample == true ? "Yes" : "No" }}</span></v-col
          >
          <v-col cols="6" sm="6" md="6" class="py-2"
            ><label>Provided by：</label><span>{{ WorkOrderList.refSampleProvidedBy }}</span></v-col
          >
        </v-row>
      </v-card>
      <v-card>
        <v-row style="margin-top: 20px;" ref="sampling">
          <v-col cols="12" sm="12" md="12" class="big_title">SAMPLING</v-col>

          <v-col cols="6" sm="6" md="6" class="py-2"
            ><label>Inspection Level：</label><span>{{ WorkOrderList.aqlLevels }}</span></v-col
          >
          <v-col cols="6" sm="6" md="6" class="py-2"
            ><label>Sampling Plan：</label><span>{{ WorkOrderList.inspectionLevel }}</span></v-col
          >
          <v-col cols="2" sm="2" md="2" class="py-2"
            ><label>Critical AQL: </label><span>{{ WorkOrderList.aqlCritical }}</span></v-col
          >
          <v-col cols="2" sm="2" md="2" class="py-2"
            ><label>Major AQL: </label><span>{{ WorkOrderList.aqlMajor }}</span></v-col
          >
          <v-col cols="2" sm="2" md="2" class="py-2"
            ><label>Minor AQL: </label><span>{{ WorkOrderList.aqlMinor }}</span></v-col
          >
          <v-col cols="6" sm="6" md="6" class="py-2"
            ><label>Sampling Methodology:</label><span>{{ WorkOrderList.samplingMethodology }}</span></v-col
          >
          <v-col cols="6" sm="6" md="6" class="py-2"
            ><label>Ordered Qty of Cartons:</label><span>{{ WorkOrderList.totalExportCrtns }}</span></v-col
          >
          <v-col cols="6" sm="6" md="6" class="py-2"
            ><label># of Cartons Available:</label><span>{{ WorkOrderList.pkgAvailable }}</span></v-col
          >
          <v-col cols="6" sm="6" md="6" class="py-2"
            ><label>Sample Size:</label><span>{{ WorkOrderList.sampleSize }}</span></v-col
          >
          <v-col cols="6" sm="6" md="6" class="py-2"
            ><label># of Cartons Inspected:</label><span>{{ WorkOrderList.crtns }}</span></v-col
          >
        </v-row>
      </v-card>
      <v-card>
        <v-row style="margin-top: 20px;">
          <v-col cols="12" sm="12" md="12" class="big_title" ref="product">PRODUCT</v-col>
        </v-row>
        <v-data-table v-bind="datatableRowsPerPage" :headers="productHeaders" :items="Products" :items-per-page="-1" class="elevation-1">
          <template v-slot:item.length="{ item }">
            <span>{{ item.length }} x {{ item.width }} x {{ item.height }} </span>
          </template>
        </v-data-table>
      </v-card>
      <v-card v-show="showInspectionSummary()">
        <v-row style="margin-top: 20px;">
          <v-col cols="12" sm="12" md="12" class="big_title" ref="inspectionSummary">INSPECTION SUMMARY</v-col>
        </v-row>
      </v-card>
      <v-card v-show="showResultSection(5)">
        <v-row style="margin-top: 20px;">
          <v-col cols="12" sm="12" md="12" class="big_title" ref="workmanship">Workmanship</v-col>
        </v-row>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left"></th>
                <th class="text-left">Found</th>
                <th class="text-left">Allowed</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Critical</td>
                <td>{{ WorkOrderList.foundCritical }}</td>
                <td>{{ WorkOrderList.allowedCritical }}</td>
              </tr>
              <tr>
                <td>Major</td>
                <td>{{ WorkOrderList.foundMajor }}</td>
                <td>{{ WorkOrderList.allowedMajor }}</td>
              </tr>
              <tr>
                <td>Minor</td>
                <td>{{ WorkOrderList.foundMinor }}</td>
                <td>{{ WorkOrderList.allowedMinor }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-data-table v-bind="datatableRowsPerPage" :headers="workmanshipHeaders" :items="WorkOrderProductDefects" :items-per-page="-1" :hide-default-footer="false" class="elevation-1"> </v-data-table>
      </v-card>

      <v-card v-show="showResultSection(1)">
        <v-row style="margin-top: 20px;">
          <v-col cols="12" sm="12" md="12" class="big_title" ref="quantityVerification">Quantity Verification</v-col>
          <v-col cols="12" sm="12" md="12" class="samll_title">Carton</v-col>
        </v-row>
        <v-data-table v-bind="datatableRowsPerPage" :headers="quantityCartonHeaders" :items="productQuantityVerificationList" :items-per-page="-1" :hide-default-footer="false" class="elevation-1"> </v-data-table>

        <v-row>
          <v-col span="24" class="samll_title">Product</v-col>
        </v-row>
        <!-- <v-data-table  v-bind="datatableRowsPerPage" :headers="quantityProductHeaders" :items="Products" :hide-default-footer="false" class="elevation-1">
		        </v-data-table> -->
        <v-data-table
          :sort-by="['po', 'skuDisplay', 'orderNumber']"
          :headers="quantityProductHeaders"
          :items="productQuantityVerificationList"
          :items-per-page="-1"
          :hide-default-footer="false"
          item-key="id"
          class="elevation-1"
          style="margin-top: 30px;"
          loading
          loading-text="Loading... Please wait"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" v-show="item.orderNumber == '1'" :key="item.name" @click="product_edit_row(item)">
                <td>{{ item.skuDisplay }}</td>
                <td>
                  <v-layout justify-center>{{ item.po }}</v-layout>
                </td>
                <td>
                  <v-layout justify-center>{{ item.qty }}</v-layout>
                </td>
                <td>
                  <v-layout justify-center>{{ getTotalAvailableQuantityProduct(item.workOrderProductId) }}</v-layout>
                </td>
                <td>
                  <v-layout justify-center>{{ getExcessShortQtyProduct(item.workOrderProductId, item.qty) }}</v-layout>
                </td>
                <td>
                  <v-layout justify-center>{{ item.sampleSizeProduct }}</v-layout>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>

      <v-card v-show="showResultSection(2)">
        <v-row style="margin-top: 20px;">
          <v-col cols="12" sm="12" md="12" class="big_title" ref="sizeMeasurement">Functional Check Measurement</v-col>
          <v-col cols="12" sm="12" md="12" class="samll_title">Carton - Size</v-col>
        </v-row>

        <!-- <v-row>
		        	<v-col cols="12" sm="12" md="12" style="padding-left: 23.5%;">Specified</v-col>
		        </v-row> -->
        <v-data-table v-bind="datatableRowsPerPage" :headers="specifiedHeaders" :items="cartonSizeMeasurementList" :items-per-page="-1" :hide-default-footer="false" class="elevation-1">
          <template v-slot:header.specified="{ header }">
            <v-row>
              <v-col cols="12">Specified</v-col>
              <v-col cols="4">L</v-col>
              <v-col cols="4">W</v-col>
              <v-col cols="4">H</v-col>
            </v-row>
          </template>
          <template v-slot:header.actual="{ header }">
            <v-row>
              <v-col cols="12">Actual</v-col>
              <v-col cols="4">L</v-col>
              <v-col cols="4">W</v-col>
              <v-col cols="4">H</v-col>
            </v-row>
          </template>
          <template v-slot:item.specified="{ item }">
            <v-row>
              <v-col cols="4">{{ item.specifiedLength }}</v-col>
              <v-col cols="4">{{ item.specifiedWidth }}</v-col>
              <v-col cols="4">{{ item.specifiedHeight }}</v-col>
            </v-row>
          </template>
          <template v-slot:item.actual="{ item }">
            <v-row>
              <v-col cols="4">{{ item.actualLength }}</v-col>
              <v-col cols="4">{{ item.actualWidth }}</v-col>
              <v-col cols="4">{{ item.actualHeight }}</v-col>
            </v-row>
          </template>
          <template v-slot:item.dimensionalUnit="{ item }">
            <span>{{ getUnitOfMeasurementsType(item.dimensionalUnit) }} </span>
          </template>
          <!-- <template v-slot:item.dimensionalResult="{ item }">
						<span>{{ resultDescription(item.dimensionalResult) }} </span>
					</template> -->
          <template v-slot:item.dimensionalResult="{ item }">
            <span>{{ resultDescription(item.dimensionalResult) }} </span>
          </template>
        </v-data-table>
        <v-row style="margin-top: 20px;line-height: 35px;">
          <v-col cols="12" sm="12" md="12" class="samll_title">Carton - Weight</v-col>
        </v-row>
        <v-data-table v-bind="datatableRowsPerPage" :headers="cartonWeightHeaders" :items="cartonSizeMeasurementList" :items-per-page="-1" :hide-default-footer="false" class="elevation-1">
          <template v-slot:item.weightUnit="{ item }">
            <span>{{ getUnitOfMeasurementsType(item.weightUnit) }} </span>
          </template>
          <template v-slot:item.weightResult="{ item }">
            <span>{{ resultDescription(item.weightResult) }} </span>
          </template>
        </v-data-table>

        <v-row style="margin-top: 20px;line-height: 35px;">
          <v-col cols="12" sm="12" md="12" class="samll_title">Product - Size</v-col>
        </v-row>
        <!-- <v-row>
		        	<v-col cols="12" sm="12" md="12" style="padding-left: 27.5%;">Specified</v-col>
		        </v-row> -->

        <div v-for="(item, index) in criticalPOMList" v-show="IsPOM()" :key="index">
          <v-expansion-panels class="mb-6">
            <v-expansion-panel>
              <v-expansion-panel-header disable-icon-rotate>
                <v-toolbar-title>
                  <v-data-table :headers="criticalPOMHeader" :items="filterCriticalPOM(item.id)" :items-per-page="-1" style="margin-top: 30px;" hide-default-footer :hide-default-header="false">
                    <template v-slot:item.criticalPomId="{ item }">
                      <span>{{ getCriticalPom(item.criticalPomId) }}</span>
                    </template>
                    <template v-slot:item.outOfPomPlus="{ item }">
                      <span>{{ (item.outOfPomPlus = getOutOfPomPlus(item.sampleDetailPointOfMeasurements)) }}</span>
                    </template>
                    <template v-slot:item.outOfPomMinus="{ item }">
                      <span>{{ (item.outOfPomMinus = getOutOfPomMinus(item.sampleDetailPointOfMeasurements)) }}</span>
                    </template>
                  </v-data-table>
                </v-toolbar-title>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-data-table :headers="skuDetailsCriticalHeader" hide-default-header v-bind="datatableRowsPerPage" :items="item.sampleDetailPointOfMeasurements" :items-per-page="-1" :hide-default-footer="false" class="elevation-1">
                  <template v-slot:item.workOrderProductSampleDetail.stockNo="{ item }">
                    <span>{{ getStockNo(item.workOrderProductSampleDetail.stockNo) }}</span>
                  </template>
                  <!-- <template v-slot:item.actualSize="{ item }">
                                    <v-text-field
                                        style="width: 100px;margin: auto;"
                                        class="number_text"
                                        v-model="item.actualSize"
                                        readonly>
                                        </v-text-field>
                                </template> -->
                </v-data-table>
                <!-- <template>
                            <h1>
                                <p v-show="noDefect(item.id)">No Defect</p>
                            </h1>
                            </template> -->
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <v-data-table v-bind="datatableRowsPerPage" v-show="!IsPOM()" :headers="productSizetHeaders" :items="productSizeMeasurementList" :items-per-page="-1" :hide-default-footer="false" class="elevation-1">
          <template v-slot:header.specified="{ header }">
            <v-row>
              <v-col cols="12">Specified</v-col>
              <v-col cols="4">L/Diam</v-col>
              <v-col cols="4">W</v-col>
              <v-col cols="4">H</v-col>
            </v-row>
          </template>
          <template v-slot:header.actual="{ header }">
            <v-row>
              <v-col cols="12">Actual</v-col>
              <v-col cols="4">L/Diam</v-col>
              <v-col cols="4">W</v-col>
              <v-col cols="4">H</v-col>
            </v-row>
          </template>
          <template v-slot:item.specified="{ item }">
            <v-row>
              <v-col cols="4">{{ item.specifiedLength }}</v-col>
              <v-col cols="4">{{ item.specifiedWidth }}</v-col>
              <v-col cols="4">{{ item.specifiedHeight }}</v-col>
            </v-row>
          </template>
          <template v-slot:item.actual="{ item }">
            <v-row>
              <v-col cols="4">{{ item.actualLength }}</v-col>
              <v-col cols="4">{{ item.actualWidth }}</v-col>
              <v-col cols="4">{{ item.actualHeight }}</v-col>
            </v-row>
          </template>
          <template v-slot:item.dimensionalUnit="{ item }">
            <span>{{ getUnitOfMeasurementsType(item.dimensionalUnit) }} </span>
          </template>
          <template v-slot:item.dimensionalResult="{ item }">
            <span>{{ resultDescription(item.dimensionalResult) }} </span>
          </template>
        </v-data-table>

        <v-row style="margin-top: 20px;line-height: 35px;">
          <v-col cols="12" sm="12" md="12" class="samll_title">Product - Weight</v-col>
        </v-row>
        <v-data-table v-bind="datatableRowsPerPage" :headers="productWeightHeaders" :items="productSizeMeasurementList" :items-per-page="-1" :hide-default-footer="false" class="elevation-1">
          <template v-slot:item.weightUnit="{ item }">
            <span>{{ getUnitOfMeasurementsType(item.weightUnit) }} </span>
          </template>
          <template v-slot:item.weightResult="{ item }">
            <span>{{ resultDescription(item.weightResult) }} </span>
          </template>
        </v-data-table>
      </v-card>

      <v-card v-show="showResultSection(3)">
        <v-row style="margin-top: 20px;">
          <v-col cols="12" sm="12" md="12" class="big_title" ref="packageCheck">Package Check</v-col>
        </v-row>
        <v-data-table v-bind="datatableRowsPerPage" :headers="cartonSampleHeaders" :items="Products" :items-per-page="-1" :hide-default-footer="false" class="elevation-1">
          <template v-slot:item.workOrderProductResults.pkgCheckResult="{ item }">
            <span>{{ resultDescription(item.workOrderProductResults.pkgCheckResult) }} </span>
          </template>
        </v-data-table>
      </v-card>

      <v-card v-show="showResultSection(4)">
        <v-row style="margin-top: 20px;">
          <v-col cols="12" sm="12" md="12" class="big_title" ref="barcodeVerification">Barcode Verification</v-col>
          <v-col cols="12" sm="12" md="12" class="small_title">Carton</v-col>
        </v-row>
        <v-data-table v-bind="datatableRowsPerPage" :headers="cartonHeaders" :items="cartonBarcodeList" :items-per-page="-1" :hide-default-footer="false" class="elevation-1">
          <template v-slot:item.scannable="{ item }">
            <span>{{ scannableDescription(item.scannable) }} </span>
          </template>
          <template v-slot:item.result="{ item }">
            <span>{{ resultDescription(item.result) }} </span>
          </template>
          <template v-slot:item.barcodeTypeId="{ item }">
            <span>{{ getTypeBarcode(item.barcodeTypeId) }} </span>
          </template>
        </v-data-table>
        <v-row style="margin-top: 20px;">
          <v-col cols="12" sm="12" md="12" class="small_title">Product</v-col>
        </v-row>
        <v-data-table v-bind="datatableRowsPerPage" :headers="productHeaders1" :items="barcodeList" :items-per-page="-1" :hide-default-footer="false" class="elevation-1">
          <template v-slot:item.scannable="{ item }">
            <span>{{ scannableDescription(item.scannable) }} </span>
          </template>
          <template v-slot:item.result="{ item }">
            <span>{{ resultDescription(item.result) }} </span>
          </template>
          <template v-slot:item.barcodeTypeId="{ item }">
            <span>{{ getTypeBarcode(item.barcodeTypeId) }} </span>
          </template>
        </v-data-table>
      </v-card>

      <v-card v-show="showResultSection(6)">
        <v-row style="margin-top: 20px;">
          <v-col cols="12" sm="12" md="12" class="big_title" ref="onsiteTests">Onsite Tests</v-col>
        </v-row>
        <v-data-table v-bind="datatableRowsPerPage" :headers="showOnsiteHeaders" :items="onsiteTestIsVisible" :items-per-page="-1" :hide-default-footer="false" class="elevation-1">
          <template v-slot:item.result="{ item }">
            <span>{{ resultDescriptionOnsite(item.result) }} </span>
          </template>
        </v-data-table>
      </v-card>

      <v-card>
        <v-row style="margin-top: 20px;line-height: 35px;">
          <v-col cols="12" sm="12" md="12" class="big_title" ref="remarksComments">REMARK & COMMENTS</v-col>
        </v-row>
        <v-data-table v-bind="datatableRowsPerPage" :headers="remarksHeader" :items="workOrderRemarks" :items-per-page="-1" :hide-default-footer="false" class="elevation-1" style="margin-top: 30px;">
          <template v-slot:item.description="{ item }">
            <div class="remarks-description" v-html="item.description"></div>
          </template>
          <template v-slot:item.classification="{ item }">
            <span>{{ remarkClassification(item.classification) }}</span>
          </template>
          <template v-slot:item.hasTable="{ item }"> </template>
        </v-data-table>
      </v-card>
      <v-card>
        <v-row style="margin-top: 20px;" v-show="showOtherSection()">
          <v-col cols="12" sm="12" md="12" class="big_title" ref="other">OTHERS</v-col>
        </v-row>

        <v-row v-show="showResultSection(13)">
          <v-col cols="12" sm="12" md="12" class="small_title" ref="CDFListed">CDF Listed Component Verification Report</v-col>
          <v-col cols="12" sm="12" md="12">
            <v-data-table v-bind="datatableRowsPerPage" :headers="CDFHeaders" :items="CDFList" :items-per-page="-1" :hide-default-footer="false" class="elevation-1">
              <template v-slot:item.result="{ item }">
                <span>{{ item.result ? "Pass" : "Fail" }}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-row style="margin-top: 20px;" v-show="showResultSection(14)">
          <v-col cols="12" sm="12" md="12" class="small_title" ref="serialNumberSampled">Serial Number Sampled</v-col>
          <v-col cols="12" sm="12" md="12">
            <v-data-table v-bind="datatableRowsPerPage" :headers="serialNumberHeaders" :items="serialNumberList" :items-per-page="-1" :hide-default-footer="false" class="elevation-1"> </v-data-table>
          </v-col>
        </v-row>

        <!-- <v-row style="margin-top: 20px;"  v-show="showResultSection(15)">
				<v-col cols="12" sm="12" md="12" class="small_title" ref="sampleCartonNumber">Serial Carton Number</v-col>
				<v-col cols="12" sm="12" md="12">
					<v-data-table  v-bind="datatableRowsPerPage" :headers="serialCartonHeaders" :items="serialCartonList" :hide-default-footer="false" class="elevation-1">
					</v-data-table>
				</v-col>
			</v-row> -->
      </v-card>

      <v-card>
        <v-row class="img_box" style="margin-top: 20px;">
          <v-col cols="12" sm="12" md="12" class="big_title" ref="photos">PHOTOS</v-col>
        </v-row>

        <v-row class="img_box" style="margin-top: 20px;">
          <v-col cols="12" sm="12" md="12" class="big_title" ref="">General</v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="4" v-for="(attachment, genIndex) in generalAttachments" :key="genIndex">
            <div v-if="!attachment.src">
              Loading...
              {{ loadGeneralImage(genIndex, attachment.fileUploadId) }}
            </div>
            <div v-if="attachment.src === 'loading'">
              Loading...
            </div>
            <img class="imgItem" v-else :src="attachment.src" alt="123" />
            <input type="text" />
          </v-col>
        </v-row>

        <v-row class="img_box" style="margin-top: 20px;">
          <v-col cols="12" sm="12" md="12" class="big_title" ref="">Supplier/Site</v-col>
        </v-row>

        <v-row class="img_box" style="margin-top: 20px;" v-show="factorywarehouse()">
          <v-col cols="12" sm="12" md="12" class="small_title" ref="factorygate">Factory Gate</v-col>
        </v-row>
        <v-row class="img_box" style="margin-top: 20px;" v-show="factorywarehouse()">
          <v-col cols="12" sm="12" md="12" class="small_title" ref="warehouse">Warehouse</v-col>
        </v-row>

        <v-row v-for="(groupAttachment, factoryIndex) in groupedSupplierAttachments" :key="factoryIndex">
          <v-col cols="12" sm="12" md="12" class="small_title" ref="factorygate">{{ groupAttachment.description }}</v-col>

          <v-col cols="4" sm="4" md="4" v-for="(attachment, factory1Index) in groupAttachment.attachments" :key="factory1Index">
            <div v-if="!attachment.src">
              Loading...
              {{ loadSupplierImage(factoryIndex, factory1Index, attachment.fileUploadId) }}
            </div>
            <div v-if="attachment.src === 'loading'">
              Loading...
            </div>
            <img class="imgItem" v-else :src="attachment.src" alt="123" />
            <input type="text" />
          </v-col>
        </v-row>

        <v-row class="img_box" style="margin-top: 20px;">
          <v-col cols="12" sm="12" md="12" class="big_title" ref="">Sampling</v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="4" v-for="(attachment, samIndex) in samplingAttachments" :key="samIndex">
            <div v-if="!attachment.src">
              Loading...
              {{ loadSamplingImage(samIndex, attachment.fileUploadId) }}
            </div>
            <div v-if="attachment.src === 'loading'">
              Loading...
            </div>
            <img class="imgItem" v-else :src="attachment.src" alt="123" />
            <input type="text" />
          </v-col>
        </v-row>
        <v-row class="img_box" style="margin-top: 20px;">
          <v-col cols="12" sm="12" md="12" class="big_title" ref="">Product</v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="4" v-for="(attachment, proIndex) in productAttachments" :key="proIndex">
            <div v-if="!attachment.src">
              Loading...
              {{ loadProductImage(proIndex, attachment.fileUploadId) }}
            </div>
            <div v-if="attachment.src === 'loading'">
              Loading...
            </div>
            <img class="imgItem" v-else :src="attachment.src" alt="123" />
            <input type="text" />
          </v-col>
        </v-row>

        <v-row class="img_box" style="margin-top: 20px;">
          <v-col cols="12" sm="12" md="12" class="big_title" ref="">Stock Inspected</v-col>
        </v-row>
        <v-row>
          <v-col cols="3" sm="3" md="3" v-for="(attachment, stockIndex) in stockinspectedAttachments" :key="stockIndex">
            <div v-if="!attachment.src">
              Loading...
              {{ loadStockInspectedImage(stockIndex, attachment.fileUploadId) }}
            </div>
            <div v-if="attachment.src === 'loading'">
              Loading...
            </div>
            <img class="imgItem" v-else :src="attachment.src" alt="123" />
            <input type="text" />
          </v-col>
        </v-row>

        <v-row class="img_box" style="margin-top: 20px;">
          <v-col cols="12" sm="12" md="12" class="big_title" ref="inspectionPhotos">Inspection Photos</v-col>
        </v-row>
        <v-row v-for="(product, index) in workOrderProducts" :key="product.id">
          <v-col cols="12" sm="12" md="12" class="small_title" ref="factoryGate">{{ product.skuDisplay }}</v-col>
          <v-col cols="4" sm="4" md="4" v-for="(attachment, aIndex) in product.attachments" :key="aIndex">
            <div v-if="!attachment.src">
              Loading...
              {{ loadWorkOrderProductAttachment(index, aIndex, attachment.fileUploadId) }}
            </div>
            <div v-if="attachment.src === 'loading'">
              Loading...
            </div>
            <img class="imgItem" v-else :src="attachment.src" alt="123" />
            <span>{{ attachment.description }}</span>
          </v-col>
        </v-row>

        <v-row class="img_box" style="margin-top: 20px;">
          <v-col cols="12" sm="12" md="12" class="big_title" ref="defectPhotos">Defect Photos</v-col>
        </v-row>
        <v-row v-for="(product, pIndex) in workOrderProducts" :key="pIndex">
          <v-col cols="12" sm="12" md="12" class="small_title">{{ product.skuDisplay }}</v-col>
          <v-col>
            <v-row v-for="(defect, dIndex) in product.defects" :key="dIndex">
              <v-col cols="12" sm="12" md="12" class="small_title">{{ defect.defectName == null ? defect.defectCategory.name : defect.defectName.name }}</v-col>
              <v-col cols="4" sm="4" md="4" v-for="(attachment, aIndex) in defect.attachments" :key="aIndex">
                <div v-if="!attachment.src">
                  Loading...
                  {{ loadDefectAttachment(pIndex, dIndex, aIndex, attachment.fileUploadId) }}
                </div>
                <div v-if="attachment.src === 'loading'">
                  Loading...
                </div>
                <img class="imgItem" v-else :src="attachment.src" alt="123" />
                <input type="text" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="img_box" style="margin-top: 20px;">
          <v-col cols="12" sm="12" md="12" class="big_title" ref="">Remarks and Comments</v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="4" v-for="(attachment, remIndex) in remarkscommentsAttachments" :key="remIndex">
            <div v-if="!attachment.src">
              Loading...
              {{ loadRemarksCommentsImage(remIndex, attachment.fileUploadId) }}
            </div>
            <div v-if="attachment.src === 'loading'">
              Loading...
            </div>
            <img class="imgItem" v-else :src="attachment.src" alt="123" />
            <input type="text" />
          </v-col>
        </v-row>
        <v-row class="img_box" style="margin-top: 20px;">
          <v-col cols="12" sm="12" md="12" class="big_title" ref="onsitePhotos">Onsite Tests Photos</v-col>
        </v-row>
        <v-row v-for="(test, onsiteindex) in onSiteTests" :key="onsiteindex">
          <v-col cols="4" sm="4" md="4" v-if="test.fileUploadId">
            <div v-if="!test.src">
              Loading...
              {{ loadOnSiteTestImage(onsiteindex, test.fileUploadId) }}
            </div>
            <div v-if="test.src === 'loading'">
              Loading...
            </div>
            <img class="imgItem" v-else :src="test.src" alt="123" />
            <label>{{ test.testConducted }}</label>
          </v-col>
        </v-row>

        <v-row class="img_box" style="margin-top: 20px;">
          <v-col cols="12" sm="12" md="12" class="big_title" ref="cdfPhotos">CDF Photos</v-col>
        </v-row>

        <v-row v-show="showResultSection(7)">
          <v-row class="img_box" style="margin-top: 20px;">
            <v-col cols="12" sm="12" md="12" class="small_title" ref="">CDF Listed Component Verification Report</v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="4" md="4" v-for="(attachment, cdfIndex) in cdfAttachments" :key="cdfIndex">
              <div v-if="!attachment.src">
                Loading...
                {{ loadCDFImage(cdfIndex, attachment.fileUploadId) }}
              </div>
              <div v-if="attachment.src === 'loading'">
                Loading...
              </div>
              <img class="imgItem" v-else :src="attachment.src" alt="123" />
              <input type="text" />
            </v-col>
          </v-row>
        </v-row>

        <v-row v-show="showResultSection(8)">
          <v-row class="img_box" style="margin-top: 20px;">
            <v-col cols="12" sm="12" md="12" class="small_title" ref="">Serial Number Sampled</v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="4" md="4" v-for="(attachment, serIndex) in serialAttachments" :key="serIndex">
              <div v-if="!attachment.src">
                Loading...
                {{ loadSerialImage(serIndex, attachment.fileUploadId) }}
              </div>
              <div v-if="attachment.src === 'loading'">
                Loading...
              </div>
              <img class="imgItem" v-else :src="attachment.src" alt="123" />
              <input type="text" />
            </v-col>
          </v-row>
        </v-row>

        <v-row v-show="showResultSection(20)">
          <v-row class="img_box" style="margin-top: 20px;">
            <v-col cols="12" sm="12" md="12" class="big_title" ref="">Other Attachments</v-col>
          </v-row>
          <v-row v-for="(otherAttachment, factoryIndex) in groupedOtherAttachments" :key="factoryIndex">
            <v-col cols="12" sm="12" md="12" class="small_title" ref="">{{ otherAttachment.description }}</v-col>
            <v-col cols="4" sm="4" md="4" v-for="(attachment, factory1Index) in otherAttachment.attachments" :key="factory1Index">
              <div v-if="!attachment.src">
                Loading...
                {{ loadOtherAttachmentImage(factoryIndex, factory1Index, attachment.fileUploadId) }}
              </div>
              <div v-if="attachment.src === 'loading'">
                Loading...
              </div>
              <img class="imgItem" v-else :src="attachment.src" alt="123" />
              <input type="text" />
            </v-col>
          </v-row>
        </v-row>
        <!-- <v-row v-show="showResultSection(9)">
					<v-row class="img_box" style="margin-top: 20px;">
						<v-col cols="12" sm="12" md="12" class="small_title" ref="">Sample Carton Number</v-col>
					</v-row>
					<v-row>
						<v-col cols="4" sm="4" md="4" v-for="(attachment, sampleIndex) in sampleAttachments" :key="sampleIndex">
							<div v-if="!attachment.src">
								Loading...
								{{ loadSampleImage(sampleIndex, attachment.fileUploadId) }}
							</div>
							<div v-if="attachment.src === 'loading'">
								Loading...
							</div>
							<img class="imgItem" v-else   :src="attachment.src" alt="123"/>
							<input type="text">
						</v-col>
					</v-row>
				</v-row> -->
      </v-card>

      <!-- <v-navigation-drawer v-model="drawer" width="400" fixed temporary>
					<ul class="nav_list">
						<li class="nav_big_title" @click="go_targe('sampling')">
							Sampling
						</li>
						<li class="nav_big_title" @click="go_targe('product')">
							Product
						</li>
						<li class="nav_big_title" @click="go_targe('inspectionSummary')">
							Inspection Summary
						</li>
						<li class="nav_small_title" @click="go_targe('workmanship')">
							Workmanship
						</li>
						<li class="nav_small_title"  @click="go_targe('quantityVerification')">
							Quantity Verification
						</li>
						<li class="nav_small_title" @click="go_targe('sizeMeasurement')">
							Size Measurement
						</li>
						<li class="nav_small_title" @click="go_targe('packageCheck')">
							Package Check
						</li>
						<li class="nav_small_title" @click="go_targe('barcodeVerification')">
							Barcode Verification
						</li>
						<li class="nav_small_title" @click="go_targe('onsiteTests')">
							Onsite Tests
						</li>
						<li class="nav_big_title" @click="go_targe('remarksComments')">
							Remarks & Comments
						</li>
						<li class="nav_big_title" @click="go_targe('other')">
							Others
						</li>
						<li class="nav_small_title" @click="go_targe('CDFListed')">
							CDF Listed Component Verification Report
						</li>
						<li class="nav_small_title" @click="go_targe('serialNumberSampled')">
							Serial Number Sampled
						</li>
						<li class="nav_small_title" @click="go_targe('sampleCartonNumber')">
							Sample Carton Number
						</li>
						<li class="nav_big_title" @click="go_targe('photos')">
							Photos
						</li>
						<li class="nav_small_title" @click="go_targe('photos')">
							Factory Gate
						</li>
						<li class="nav_small_title" @click="go_targe('photos')">
							Warehouse
						</li>
						<li class="nav_small_title" @click="go_targe('inspectionPhotos')">
							Inspection Photos
						</li>
						<li class="nav_small_title" @click="go_targe('defectPhotos')">
							Defect photos
						</li>
						<li class="nav_small_title" @click="go_targe('onsitePhotos')">
							Onsite Test Photos
						</li>
						<li class="nav_small_title" @click="go_targe('remarksphotos')">
							Remark or Non-Conformance Photos
						</li>
						<li class="nav_small_title" @click="go_targe('cdfPhotos')">
							CDF Photos
						</li>
					</ul>
				</v-navigation-drawer> -->
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";

let state = {
  appHeaderState: {
    show: true,
    title: "Work Order:8911",
    showMenu: false,
    showBack: true,
    showLogo: false,
    showTitle: true,
    showSearch_job: false,
    showSearch_work: false,
  },
};

function setState(store) {
  store.dispatch("navigation/setAppHeader", state.appHeaderState);
}

export default {
  name: "preview",
  data() {
    return {
      //PointOfMeasurement
      skuDetailsCriticalHeader: [
        {
          text: "Size",
          align: "center",
          sortable: false,
          value: "workOrderProductSampleDetail.size",
        },
        {
          text: "Color",
          align: "center",
          sortable: false,
          value: "workOrderProductSampleDetail.color",
        },
        {
          text: "Sample Size #",
          align: "center",
          sortable: false,
          value: "workOrderProductSampleDetail.sampleNo",
        },
        {
          text: "Stock #",
          align: "center",
          sortable: false,
          value: "workOrderProductSampleDetail.stockNo",
        },
        {
          text: "Actual Size",
          align: "center",
          sortable: false,
          value: "actualSize",
        },
      ],
      criticalPOM: [],
      criticalPOMHeader: [
        {
          text: "#",
          align: "center",
          sortable: false,
          value: "number",
        },
        {
          text: "Critical POM",
          align: "center",
          sortable: false,
          value: "criticalPomId",
        },
        {
          text: "Specific POM",
          align: "center",
          sortable: false,
          value: "specificPom",
        },
        {
          text: "Upper Tolerance",
          align: "center",
          sortable: false,
          value: "upperTol",
        },
        {
          text: "Lower Tolerance",
          align: "center",
          sortable: false,
          value: "lowerTol",
        },
        {
          text: "Out of POM(+)",
          align: "center",
          sortable: false,
          value: "outOfPomPlus",
        },
        {
          text: "Out of POM(-)",
          align: "center",
          sortable: false,
          value: "outOfPomMinus",
        },
      ],
      criticalPOMList: [],
      stockNumber: [],

      //sizemeasurement
      unitOfMeasurementOptions: [],
      cartonSizeMeasurementList: [],
      productSizeMeasurementList: [],
      datatableRowsPerPage: {
        "footer-props": {
          "items-per-page-options": [-1],
        },
      },
      photos: [
        {
          name: "FactoryGate",
          data: [
            {
              imgUrl: "",
              description: "",
            },
            {
              imgUrl: "",
              description: "",
            },
          ],
        },
        {
          name: "Warehouse",
          data: [
            {
              imgUrl: "",
              description: "",
            },
            {
              imgUrl: "",
              description: "",
            },
          ],
        },
      ],

      generalAttachments: [],
      samplingAttachments: [],
      productAttachments: [],
      stockinspectedAttachments: [],
      remarkscommentsAttachments: [],
      cdfAttachments: [],
      serialAttachments: [],
      sampleAttachments: [],
      detailsData: {},

      productHeaders: [
        { text: " Product Description", align: "left", sortable: false, value: "productDescription" },
        { text: "SKU", sortable: false, value: "skuDisplay" },
        { text: "PO#", sortable: false, value: "po" },
        { text: "Style/Model/Item", sortable: false, value: "style" },
        { text: "Designs/Color(s)", sortable: false, value: "color" },
        { text: "Size Range", sortable: false, value: "length", width: 280 },
        { text: "Unit", sortable: false, value: "dimensionalUnit" },
      ],
      productList: [
        {
          description: "0",
          sku: 0,
          po: 0,
          style: 0,
          designs: 0,
          sizeRange: 0,
          unit: 0,
        },
      ],

      workmanshipHeaders: [
        { text: "#", align: "left", sortable: false, value: "skuDisplay" },
        { text: "Defect Name", sortable: false, value: "defectName.name" },
        { text: "Qty", sortable: false, value: "qty" },
        { text: "SKUs Defect Classification", sortable: false, value: "defectClassification.name" },
      ],
      workmanshipList: [
        {
          id: 0,
          defectName: "Scratch on mirror",
          Qty: 10,
          deefectClassification: 0,
        },
      ],
      quantityCartonHeaders: [
        //   { text: 'SKU#',align: 'left',sortable: false,value: 'skuDisplay'},
        //   { text: 'PO No.',sortable: false, value: 'po' },
        //   { text: 'PO Qty',sortable: false, value: 'cartonQty' },
        //   {text: "Case Count",align: "center",sortable: false,value: "workOrderProductResults.caseCountProduct"},
        //   { text: 'Avail Qty',sortable: false, value: 'workOrderProductResults.availQtyCrtn' },
        //   { text: 'Sample Size',sortable: false, value: 'workOrderProductResults.sampleSizeCrtn' },
        { text: "SKU#", align: "left", sortable: true, value: "skuDisplay" },
        { text: "PO No.", align: "center", sortable: true, value: "po" },
        { text: "PO Qty(Unit)", align: "center", sortable: false, value: "cartonQty" },
        { text: "Case Count", align: "center", sortable: false, value: "caseCountProduct" },
        { text: "Available Cartons", align: "center", sortable: false, value: "availableQuantityCarton" },
        { text: "Sample Size", align: "center", sortable: false, value: "sampleSizeCarton" },
      ],
      quantityCartonList: [
        {
          sku: 69652,
          poNo: 46,
          no: 2019,
          poQty: 22896,
          availQty: 22896,
          sampleSize: 0,
        },
        {
          sku: 69652,
          poNo: 46,
          no: 2019,
          poQty: 22896,
          availQty: 22896,
          sampleSize: 0,
        },
      ],

      quantityProductHeaders: [
        //   { text: 'SKU#',align: 'left',sortable: false,value: 'skuDisplay'},
        //   { text: 'PO No.',sortable: false, value: 'po' },
        //   { text: 'PO Qty(Unit)',sortable: false, value: 'qty' },
        //   { text: 'Avail Qty(Unit)',sortable: false, value: 'workOrderProductResults.availQtyPrd' },
        //   { text: 'Sample Size',sortable: false, value: 'workOrderProductResults.sampleSizePrd' }
        { text: "SKU#", align: "left", sortable: true, value: "skuDisplay" },
        { text: "PO No.", align: "center", sortable: true, value: "po" },
        { text: "PO Qty(Unit)", align: "center", sortable: false, value: "qty" },
        { text: "Avail Qty(Unit)", align: "center", sortable: false, value: "availableQuantityProduct" },
        { text: "Excess/-Short Qty", align: "center", sortable: false, value: "excessShortQuantity" },
        { text: "Sample Size", align: "center", sortable: false, value: "sampleSizeProduct" },
      ],
      quantityProductList: [
        {
          sku: 69652,
          poNo: 46,
          poQty: 22896,
          availQty: 22896,
          caseCount: 22896,
          sampleSize: 0,
        },
      ],
      specifiedHeaders: [
        { text: "SKUs", align: "center", sortable: false, value: "skuDisplay" },
        { text: "PO No.", align: "center", sortable: false, value: "po" },
        { text: "Specified", align: "center", sortable: false, value: "specified" },
        { text: "Actual", align: "center", sortable: false, value: "actual" },
        // {text: 'L',align: 'center',sortable: false,value: 'cartonLength'},
        // {text: 'W',align: 'center',sortable: false,value: 'cartonWidth'},
        // {text: 'H',align: 'center',sortable: false,value: 'cartonHeight'},
        // {text: 'L',align: 'center',sortable: false,value: 'workOrderProductResults.lAvailableSizeCrtn'},
        // {text: 'W',align: 'center',sortable: false,value: 'workOrderProductResults.wAvailableSizeCrtn'},
        // {text: 'H',align: 'center',sortable: false,value: 'workOrderProductResults.hAvailableSizeCrtn'},
        { text: "Sample Size", align: "center", sortable: false, value: "sampleSize" },
        { text: "Unit", align: "center", sortable: false, value: "dimensionalUnit" },
        { text: "Result", align: "center", sortable: false, value: "dimensionalResult" },
      ],
      specifiedList: [
        {
          SKU: "69652",
          L: "46",
          W: "12.5",
          H: "19.3",
          L1: "16.2",
          W1: "12.5",
          H1: "19.3",
          Unit: "cm",
          Result: "Pass",
        },
        {
          SKU: "69652",
          L: "46",
          W: "12.5",
          H: "19.3",
          L1: "16.2",
          W1: "12.5",
          H1: "19.3",
          Unit: "cm",
          Result: "Pass",
        },
      ],
      cartonWeightHeaders: [
        { text: "SKU#", align: "center", sortable: false, value: "skuDisplay" },
        { text: "PO No.", align: "center", sortable: false, value: "po" },
        { text: "Weight Spec", align: "center", sortable: false, value: "specifiedWeight" },
        { text: "Weight Measure", align: "center", sortable: false, value: "actualWeight" },
        { text: "Sample Size", align: "center", sortable: false, value: "sampleSize" },
        { text: "Unit", align: "center", sortable: false, value: "weightUnit" },
        { text: "Result", align: "center", sortable: false, value: "weightResult" },
      ],
      cartonWeightList: [
        {
          SKU: "69652",
          weightSpec: "0.159",
          weightMeasure: "0.159",
          Unit: "500",
          Result: "Pass",
        },
        {
          SKU: "69653",
          weightSpec: "0.159",
          weightMeasure: "0.159",
          Unit: "10",
          Result: "Pass",
        },
      ],

      productSizetHeaders: [
        { text: "SKU#", align: "center", value: "skuDisplay" },
        { text: "PO No.", align: "center", value: "po" },
        { text: "Specified", align: "center", sortable: false, value: "specified" },
        { text: "Actual", align: "center", sortable: false, value: "actual" },
        // {text: 'L',align: 'center',sortable: false,value: 'length'},
        // {text: 'W',align: 'center',sortable: false,value: 'width'},
        // {text: 'H',align: 'center',sortable: false,value: 'height'},
        // {text: 'L',align: 'center',sortable: false,value: 'workOrderProductResults.lAvailableSizePrd'},
        // {text: 'W',align: 'center',sortable: false,value: 'workOrderProductResults.wAvailableSizePrd'},
        // {text: 'H',align: 'center',sortable: false,value: 'workOrderProductResults.hAvailableSizePrd'},
        { text: "Sample Size", align: "center", sortable: false, value: "sampleSize" },
        { text: "Unit", align: "center", sortable: false, value: "dimensionalUnit" },
        { text: "Result", align: "center", sortable: false, value: "dimensionalResult" },
      ],
      productSizetList: [
        {
          sku: "69652",
          l: "0.159",
          w: "0.159",
          h: "500",
          l1: "0.159",
          w1: "0.159",
          h1: "500",
          unit: "cm",
          result: "Pass",
        },
        {
          sku: "69652",
          l: "0.159",
          w: "0.159",
          h: "500",
          l1: "0.159",
          w1: "0.159",
          h1: "500",
          unit: "cm",
          result: "Pass",
        },
      ],

      productWeightHeaders: [
        { text: "SKU#", align: "center", value: "skuDisplay" },
        { text: "PO No.", align: "center", value: "po" },
        { text: "Weight Spec", align: "center", sortable: false, value: "specifiedWeight" },
        { text: "Weight Measure", align: "center", sortable: false, value: "actualWeight" },
        { text: "Sample Size", align: "center", sortable: false, value: "sampleSize" },
        { text: "Unit", align: "center", sortable: false, value: "weightUnit" },
        { text: "Result", align: "center", sortable: false, value: "weightResult" },
      ],
      productWeightList: [
        {
          sku: "69652",
          weightSpec: "0.159",
          weightMeasure: "0.159",
          Unit: "500",
          Result: "Pass",
        },
        {
          sku: "69653",
          weightSpec: "0.159",
          weightMeasure: "0.159",
          Unit: "10",
          Result: "Pass",
        },
      ],

      cartonSampleHeaders: [
        { text: "SKU", align: "center", sortable: false, value: "skuDisplay" },
        { text: "PO No.", align: "center", sortable: false, value: "po" },
        { text: "Result", align: "center", sortable: false, value: "workOrderProductResults.pkgCheckResult" },
        { text: "Finding", align: "center", sortable: false, value: "workOrderProductResults.pkgCheckFindings" },
      ],
      cartonSampleList: [
        {
          SKU: "69652",
          Result: "Yes",
          Finding: "180340343119",
        },
      ],
      cartonBarcodeList: [],
      barcodeList: [],
      cartonHeaders: [
        { text: "SKU", align: "center", sortable: false, value: "skuDisplay" },
        { text: "PO No.", align: "center", sortable: false, value: "po" },
        { text: "Scannable", align: "center", sortable: false, value: "scannable" },
        { text: "Code on Label", align: "center", sortable: false, value: "codeOnLabel" },
        { text: "Code Scanned", align: "center", sortable: false, value: "codeScanned" },
        { text: "Code Specified", align: "center", sortable: false, value: "codeSpecified" },
        { text: "Barcode Type", align: "center", sortable: false, value: "barcodeTypeId" },
        { text: "Barcode Label", align: "center", sortable: false, value: "barcodeLabel" },
        { text: "Result", align: "center", sortable: false, value: "result" },
      ],
      cartonList: [
        {
          SKU: "69652",
          Scannable: "Yes",
          CodeLabel: "180340343119",
          CodeScanned: "180340343119",
          Codespecified: "1231",
          BarcodeType: "Code 128",
          result: "Pass",
        },
        {
          SKU: "69652",
          Scannable: "Yes",
          CodeLabel: "180340343119",
          CodeScanned: "180340343119",
          BarcodeType: "Code 128",
          result: "Pass",
        },
      ],
      productHeaders1: [
        { text: "SKU", align: "center", sortable: false, value: "skuDisplay" },
        { text: "PO No.", align: "center", sortable: false, value: "po" },
        { text: "Scannable", align: "center", sortable: false, value: "scannable" },
        { text: "Code on Label", align: "center", sortable: false, value: "codeOnLabel" },
        { text: "Code Scanned", align: "center", sortable: false, value: "codeScanned" },
        { text: "Code Specified", align: "center", sortable: false, value: "codeSpecified" },
        { text: "Barcode Type", align: "center", sortable: false, value: "barcodeTypeId" },
        { text: "Barcode Label", align: "center", sortable: false, value: "barcodeLabel" },
        { text: "Result", align: "center", sortable: false, value: "result" },
      ],
      productList1: [
        {
          SKU: "69652",
          Scannable: "Yes",
          CodeLabel: "180340343119",
          CodeScanned: "180340343119",
          Codespecified: "1231",
          BarcodeType: "Code 128",
          result: "Pass",
        },
        {
          SKU: "69652",
          Scannable: "Yes",
          CodeLabel: "180340343119",
          CodeScanned: "180340343119",
          BarcodeType: "Code 128",
          result: "Pass",
        },
      ],
      onsiteHeaders: [
        { text: "Onsite Tests Conducted", align: "center", sortable: false, value: "testConducted" },
        { text: "Criteria", align: "center", sortable: false, value: "criteria" },
        { text: "Others", align: "center", sortable: false, value: "others" },
        { text: "Sampling Level", align: "center", sortable: false, value: "numberOfSamples" },
        { text: "Sample Size", align: "center", sortable: false, value: "sampleSize" },
        { text: "Result", align: "center", sortable: false, value: "result" },
      ],

      onsiteHeadersAQL: [
        { text: "Onsite Tests Conducted", align: "center", sortable: false, value: "testConducted" },
        { text: "Criteria", align: "center", sortable: false, value: "criteria" },
        { text: "Others", align: "center", sortable: false, value: "others" },
        { text: "Sampling Level", align: "center", sortable: false, value: "numberOfSamples" },
        { text: "Sample Size", align: "center", sortable: false, value: "sampleSize" },
        { text: "AQL", align: "center", sortable: false, value: "aql" },
        { text: "Found", align: "center", sortable: false, value: "found" },
        { text: "Acc", align: "center", sortable: false, value: "accepted" },
        { text: "Rej", align: "center", sortable: false, value: "rejected" },
        { text: "Result", align: "center", sortable: false, value: "result" },
      ],

      isOnsiteTestVaryingAQL: false,
      onsiteList: [
        {
          Conducted: "Base Level Test",
          Criteria: "G1",
          Samples: "10",
          Result: "Pass",
        },
        {
          Conducted: "Battery Valtage Check",
          Criteria: "G1",
          Samples: "8",
          Result: "Pending",
        },
        {
          Conducted: "Card Ancharage Test",
          Criteria: "G1",
          Samples: "7",
          Result: "Pending",
        },
      ],
      remarkHeaders: [
        { text: "Index", align: "center", sortable: false, value: "Index" },
        { text: "Description", align: "center", sortable: false, value: "Description" },
        { text: "Classification", align: "center", sortable: false, value: "Classification" },
      ],
      remarkList: [
        {
          Index: "1",
          Description: "Sample desc",
          Classification: "1",
        },
      ],
      CDFHeaders: [
        { text: "No.", align: "left", sortable: false, value: "no" },
        { text: "Component Description", align: "center", sortable: false, value: "componentDescription" },
        { text: "Specification", align: "center", sortable: false, value: "specification" },
        { text: "Actual Observed", align: "center", sortable: false, value: "actualObserved" },
        { text: "Result", align: "center", sortable: false, value: "result" },
        { text: "Remark(s)", align: "center", sortable: false, value: "remarks" },
      ],
      CDFList: [],
      barcodeTypeOptions: [],
      serialNumberHeaders: [
        { text: "No.", align: "left", sortable: false, value: "number" },
        { text: "Serial Number", align: "center", sortable: false, value: "serialNumber" },
        { text: "# of Carton(s)", align: "center", sortable: false, value: "noOfCartons" },
      ],
      serialNumberList: [],

      serialCartonHeaders: [
        { text: "No.", align: "left", sortable: false, value: "number" },
        { text: "Serial Number", align: "center", sortable: false, value: "serialNumber" },
        { text: "# of Carton(s)", align: "center", sortable: false, value: "noOfCartons" },
      ],
      serialCartonList: [],
      othersAttachments: [],
      groupedOthersDescriptions: [],
      groupedOthersAttachments: [],
      groupedOthersAttachmentsCDF: [],
      groupedOthersAttachmentsSerial: [],
      groupedOthersAttachmentsSample: [],

      drawer: false,
      items: [
        { title: "Home", icon: "dashboard" },
        { title: "About", icon: "question_answer" },
      ],

      workOrderId: "",
      WorkOrderList: [],
      Products: [],
      WorkOrderProductDefects: [],
      resultOptions: [
        {
          id: 1,
          text: "Pass",
        },
        {
          id: 0,
          text: "Fail",
        },
        {
          id: 2,
          text: "Pending",
        },
        {
          id: 3,
          text: "N/A",
        },
      ],
      scannableOptions: [
        { id: 1, text: "Yes" },
        { id: 0, text: "No" },
      ],
      onSiteTests: [],
      onsiteItems: [
        {
          text: "Pending",
          id: 1,
        },
        {
          text: "Pass",
          id: 2,
        },
        {
          text: "Fail",
          id: 3,
        },
      ],

      // attachments
      // supplier attachments
      staticDescriptions: ["Factory Gate", "Warehouse"],
      staticDescriptionsFactory: ["Factory Gate"],
      staticDescriptionsWarehouse: ["Warehouse"],

      supplierAttachments: [],
      groupedSupplierDescriptions: [
        { description: "Others - CDF", title: "CDF" },
        { description: "Others - Serial Number Sampled", title: "Serial Number Sampled" },
        { description: "Others - Sample Carton Number", title: "Sample Carton Number" },
      ],
      groupedOthersCDF: [{ description: "Others - CDF", title: "CDF" }],
      groupedOthersSerial: [{ description: "Others - Serial Number Sampled", title: "Serial Number Sampled" }],
      groupedOthersSample: [{ description: "Others - Sample Carton Number", title: "Sample Carton Number" }],
      groupedSupplierAttachments: [],
      groupedSupplierAttachmentsFactory: [],
      groupedSupplierAttachmentsWarehouse: [],

      // inspection photos
      workOrderProducts: [],

      // remarks and comments
      workOrderRemarks: [],
      remarkClassificationList: [
        { id: 1, description: 'Fail'}, //Fail
        { id: 2, description: 'Pending'}, // Pending
        { id: 3, description: 'Informative'}, // Informative
        { id: 4, description: "Abort" }, // Abort
        { id: 5, description: "Pass"}, // Pass
        { id: 6, description: "CAPA"},
        { id: 7, description: "Not Ready"}
      ],
      remarksHeader: [
        { text: "Index", align: "left", sortable: false, value: "index" },
        { text: "Description", align: "center", sortable: false, value: "description" },
        // { text: 'Containing form', align: 'center',sortable: false, value: 'hasTable' },
        // { text: 'Description Photo', align: 'center',sortable: false, value: 'descriptionPhoto' },
        { text: "Classification", align: "center", sortable: false, value: "classification" },
      ],

      inspectionSummarySections: [],
      workOrder: {},
      workOrderProducts: [],
      skuDisplayList: [],
      productQuantityVerificationList: [],
      editedIndex: -1,
      samplingPlan: {
        SKUSamplingList: [
          {
            id: "",
            workOrderID: "",
            productId: "",
            SKUDisplay: "",
            PO: "",
            productQty: 0,
            cartonQty: 0,
            productProRata: 0,
            cartonProRata: 0,
            productSampleSize: 0,
            cartonSampleSize: 0,
            actualProductSampleSize: 0,
            actualCartonSampleSize: 0,
            AC: 0,
            RE: 0,
          },
        ],
        totalProductPOQty: 0,
        totalProductSampleSize: 0,
        totalCartonPOQty: 0,
        totalCartonSampleSize: 0,
      },
      // other attachments
      otherAttachments: [],
      groupedOtherAttachments: [],
      groupedOtherDescriptions: [{ description: "Others - Other Attachments", title: "Other Attachments" }],
      productSizeMeasurementId:""
    };
  },
  activated() {
    state.appHeaderState.title = "Work Order:" + this.$route.query.id;
    setState(this.$store);
    this.getWorkOrderDetails();
  },
  mounted() {
    state.appHeaderState.title = "Work Order:" + this.$route.query.id;
    setState(this.$store);

    this.getWorkOrderDetails();
  },
  beforeMount() {
    this.utilityFn.getServerData("get", this.apiList.GetBarcodeTypes, {}, (result) => {
      this.barcodeTypeOptions = result.data.data;
    });

    // this.apiFn.getBarcodeTypes()
    //                         .then(result=>{
    //                             this.barcodeTypeOptions = result;
    //                         });
  },
  methods: {
    //PointOfMeasurement
    IsPOM() {
      let isPOM = false;
      if (this.WorkOrderList.length != 0) {
        if (this.WorkOrderList.sizeMeasurement.isPOM) {
          isPOM = true;
        }
        return isPOM;
      }
    },
    getCriticalPom(id) {
      let criticalPom = this.criticalPOM.filter((x) => x.id === id);
      return criticalPom[0].name;
    },
    getOutOfPomPlus(sampleDetailPointOfMeasurements) {
      console.log(sampleDetailPointOfMeasurements);
      let total = sampleDetailPointOfMeasurements.filter((x) => {
        if (x.actualSize != "" || x.actualSize != null) {
          if (!x.actualSize.includes("-")) {
            return x;
          }
        }
      });
      return total.length;
    },
    getOutOfPomMinus(sampleDetailPointOfMeasurements) {
      let total = sampleDetailPointOfMeasurements.filter((x) => {
        if (x.actualSize != "" || x.actualSize != null) {
          if (x.actualSize.includes("-")) {
            return x;
          }
        }
      });
      return total.length;
    },
    filterCriticalPOM(id) {
      let criticalPOMList = this.criticalPOMList.filter((x) => x.id === id);
      return criticalPOMList;
    },
    getStockNo(stockNoId) {
      let stockNo = this.stockNumber.filter((x) => x.id === stockNoId);
      return stockNo[0].stockNo;
    },
    loadSamplingSize(data) {
      this.samplingPlan.SKUSamplingList = data.products.map((x) => {
        return {
          id: x.id,
          workOrderID: x.workOrderID,
          productId: x.productID,
          SKUDisplay: x.skuDisplay,
          PO: x.po,
          productQty: x.workOrderProductResults.availQtyPrd,
          cartonQty: x.workOrderProductResults.availQtyCrtn,
          productSampleSize: x.workOrderProductResults.sampleSizePrd,
          cartonSampleSize: x.workOrderProductResults.sampleSizeCrtn,
          actualProductSampleSize: 0,
          actualCartonSampleSize: 0,
          aqlMinor: data.aqlMinor,
          aqlMajor: data.aqlMajor,
          aqlCritical: data.aqlCritical,
          minorAC: 0,
          minorRE: 0,
          majorAC: 0,
          majorRE: 0,
          criticalAC: 0,
          criticalRE: 0,
        };
      });

      let returnedSampleSizePlan = this.samPlan.getAutoComputeSampleSize(
        this.WorkOrderList.samplingMethodology,
        this.samplingPlan.SKUSamplingList,
        this.WorkOrderList.aqlLevels,
        this.WorkOrderList.inspectionLevel,
        this.WorkOrderList.samplingPlan,
        this.WorkOrderList.productType,
        "Preview",
        false
      );
    },
    getWorkOrderDetails() {
      // Get preview data
      this.workOrderId = this.$route.params.id;
      this.apiFn.getUnitsOfMeasurements().then((result) => {
        this.unitOfMeasurementLengthOptions = result.filter((x) => x.measureClasses == "Length");
        this.unitOfMeasurementWeightOptions = result.filter((x) => x.measureClasses == "Weight");
        this.unitOfMeasurementOptions = result;
      });
      this.utilityFn.getServerData("get", this.apiList.workOrderDetails + "/" + this.$route.params.id, {}, (result) => {
        this.workOrder = result.data.data[0];
        this.isOnsiteTestVaryingAQL = this.workOrder.isOnsiteTestVaryingAQL;
        this.otherAttachments = this.workOrder.workOrderAttachments.filter((x) => x.sectionId == 20);
        if (this.otherAttachments.length > 0) this.groupOtherAttachments();

        this.Products = this.workOrder.products;
        this.Products.map((x) => {
          x.workOrderProductQuantityVerificationResults = x.workOrderProductQuantityVerificationResults || {};
          return x;
        });

        this.workOrderProducts = this.workOrder.products.map((x) => {
          x.workOrderProductResults = x.workOrderProductResults || {};
          x.workOrderProductBarcodeVerificationResults = x.workOrderProductBarcodeVerificationResults || {};
          return x;
        });
        this.skuDisplayList = this.workOrder.products.map((x) => {
          return {
            // workOrderProductID: x.id,
            // skuDisplay: x.skuDisplay
            workOrderProductId: x.id,
            skuDisplay: x.skuDisplay,
            po: x.po,
            cartonQty: x.cartonQty,
            qty: x.qty,
            caseCountProduct: x.casePackage,
            productID: x.productID,
          };
        });

        this.insertQuantityVerification();
        this.loadExistingQuantityVerificationDetails();

        this.insertworkOrderProductSizeMeasurementResult();
        this.loadExistingSizeMeasurementDetails();

        this.loadCartonProductBarcodeDetails(this.workOrderProducts);

        this.onSiteTests = this.workOrder.onSiteTests;
        let totalProductQuantity = this.workOrder.products.reduce(function(tot, arr) {
          return tot + arr.qty;
        }, 0);

        this.WorkOrderList = { ...this.workOrder, orderQty: totalProductQuantity };
        //this.loadSamplingSize(this.WorkOrderList);
        this.CDFList = this.workOrder.workOrderCDFs.map((x, i) => {
          x.no = 1 + i++;
          return x;
        });
        this.serialNumberList = this.workOrder.workOrderSerialNumbersSampled;
        this.serialCartonList = this.workOrder.workOrderSampledCartonNumbers;

        // supplier attachments
        const _workOrderAttachments = this.WorkOrderList.workOrderAttachments.map((attachment) => {
          attachment.src = null;
          attachment.added = true;
          return attachment;
        });

        //this.othersAttachments = _.orderBy(_workOrderAttachments.filter(attachment => attachment.sectionId === 7), ['order'], ['asc']);

        this.generalAttachments = _.orderBy(
          _workOrderAttachments.filter((attachment) => attachment.sectionId === 7),
          ["order"],
          ["asc"]
        );

        this.supplierAttachments = _.orderBy(
          _workOrderAttachments.filter((attachment) => attachment.sectionId === 8),
          ["order"],
          ["asc"]
        );

        this.samplingAttachments = _.orderBy(
          _workOrderAttachments.filter((attachment) => attachment.sectionId === 9),
          ["order"],
          ["asc"]
        );

        this.productAttachments = _.orderBy(
          _workOrderAttachments.filter((attachment) => attachment.sectionId === 10),
          ["order"],
          ["asc"]
        );

        this.stockinspectedAttachments = _.orderBy(
          _workOrderAttachments.filter((attachment) => attachment.sectionId === 11),
          ["order"],
          ["asc"]
        );

        this.remarkscommentsAttachments = _.orderBy(
          _workOrderAttachments.filter((attachment) => attachment.sectionId === 12 && attachment.description == null),
          ["order"],
          ["asc"]
        );

        this.cdfAttachments = _.orderBy(
          _workOrderAttachments.filter((attachment) => attachment.sectionId === 13),
          ["order"],
          ["asc"]
        );

        this.serialAttachments = _.orderBy(
          _workOrderAttachments.filter((attachment) => attachment.sectionId === 14),
          ["order"],
          ["asc"]
        );

        this.sampleAttachments = _.orderBy(
          _workOrderAttachments.filter((attachment) => attachment.sectionId === 15),
          ["order"],
          ["asc"]
        );
        if (this.supplierAttachments.length > 0) this.groupSupplierAttachments();

        if (this.othersAttachments.length > 0) this.groupOthersAttachments();
        this.workOrderProducts = this.WorkOrderList.products.map((p) => {
          const defects = p.workOrderProductDefects.map((d) => {
            const attachments = d.workOrderDefectAttachments.map((a) => {
              a.src = null;
              return a;
            });
            return {
              ...d,
              attachments: _.orderBy(attachments, ["order"], ["asc"]),
            };
          });
          const attachments = p.workOrderProductAttachments
            .filter((attachment) => attachment.sectionId === 11)
            .map((a) => {
              a.src = null;
              return a;
            });
          return {
            ...p,
            defects: defects,
            attachments: _.orderBy(attachments, ["order"], ["asc"]),
          };
        });
      });

      this.apiFn.GetWorkOrderProductDefects(this.workOrderId).then((result) => {
        this.WorkOrderProductDefects = result.filter((x) => x.defectCategoryId != 7 && x.defectCategoryId != 8);
      });

      this.apiFn.api.remarksApi.getWorkOrderRemarks(this.workOrderId).then((result) => {
        this.workOrderRemarks = result.map((x, i) => {
          x.index = 1 + i++;
          return x;
        });
      });

      this.apiFn.GetInspectionSummarySections(this.workOrderId).then((result) => {
        console.log("inspectionSummarySEctions", result);
        this.inspectionSummarySections = result;
      });

     
    },
    getPointOfMeasurement(){
       //PointOfMeasurement
      this.utilityFn.getServerData("get", this.apiList.getStylePerWorkOrderId + "/" + this.workOrderId, {}, (result) => {
        this.stockNumber = result.data.data;
      });
      this.utilityFn.getServerData("get", this.apiList.getAllCriticalPomList, {}, (result) => {
        this.criticalPOM = result.data.data;
      });
      
      this.utilityFn.getServerData("get", this.apiList.getWOPPOM + "/" + this.workOrderId + '/' + this.productSizeMeasurementId, {}, (result) => {
        this.criticalPOMList = result.data.data.workOrderProductPointOfMeasurements;
        let number = 1;
        this.criticalPOMList.forEach((x) => {
          x.number = number++;
          return x;
        });
        this.showProgressDialog = false;
      });
    },
    getIdUnitOfMeasurementsType(description) {
      if (!description || description == "") {
        return null;
      }
      let unitOfMeasurementTypeId = this.unitOfMeasurementOptions.find((x) => x.description === description).id;
      return unitOfMeasurementTypeId;
    },
    loadExistingSizeMeasurementDetails() {
      let existingSizeMeasurementList = [];
      this.workOrderProducts.forEach((x) => {
        if (x.workOrderProductSizeMeasurementResults.length > 0) {
          x.workOrderProductSizeMeasurementResults.forEach((item) => {
            existingSizeMeasurementList.push(item);
          });
        }
      });
      if (existingSizeMeasurementList.length > 0) {
        this.loadWorkOrderSizeMeasurementDetails(existingSizeMeasurementList);
      }
    },
    insertworkOrderProductSizeMeasurementResult() {
      let workOrderProductSizeMeasurementResultObjList = [];
      this.workOrderProducts.forEach((item) => {
        if (item.workOrderProductSizeMeasurementResults) {
          if (item.workOrderProductSizeMeasurementResults.length <= 0) {
            const addedCartonSizeMeasurement = {
              id: null,
              workOrderProductId: item.id,
              isCarton: true,
              specifiedLength: item.cartonLength,
              specifiedWidth: item.cartonWidth,
              specifiedHeight: item.cartonHeight,
              actualLength: null,
              actualWidth: null,
              actualHeight: null,
              dimensionalUnit: this.getIdUnitOfMeasurementsType(item.cartonUnit) == null ? null : this.getIdUnitOfMeasurementsType(item.cartonUnit),
              dimensionalResult: null,
              specifiedWeight: item.cartonWeight,
              actualWeight: null,
              weightUnit: this.getIdUnitOfMeasurementsType(item.cartonWeightUnit) == null ? null : this.getIdUnitOfMeasurementsType(item.cartonWeightUnit),
              weightResult: null,
              sampleSize: null,
              orderNumber: 1,
              sectionId: 2,
            };
            workOrderProductSizeMeasurementResultObjList.push(addedCartonSizeMeasurement);

            const addedProductSizeMeasurement = {
              id: null,
              workOrderProductId: item.id,
              isCarton: false,
              specifiedLength: item.length,
              specifiedWidth: item.width,
              specifiedHeight: item.height,
              actualLength: null,
              actualWidth: null,
              actualHeight: null,
              dimensionalUnit: this.getIdUnitOfMeasurementsType(item.dimensionalUnit) == null ? null : this.getIdUnitOfMeasurementsType(item.dimensionalUnit),
              dimensionalResult: null,
              specifiedWeight: item.weight,
              actualWeight: null,
              weightUnit: this.getIdUnitOfMeasurementsType(item.weightUnit) == null ? null : this.getIdUnitOfMeasurementsType(item.weightUnit),
              weightResult: null,
              sampleSize: null,
              orderNumber: 1,
              sectionId: 2,
            };
            workOrderProductSizeMeasurementResultObjList.push(addedProductSizeMeasurement);
          }
        }
      });

      if (workOrderProductSizeMeasurementResultObjList.length > 0) {
        this.utilityFn.getServerData("post", this.apiList.upsertWorkOrderProductSizeMeasurementResult, workOrderProductSizeMeasurementResultObjList, (res) => {
          this.loadWorkOrderSizeMeasurementDetails(res.data);
        });
      }
    },
    loadWorkOrderSizeMeasurementDetails(workorderProductsizeMeasurementDetails) {
      if (workorderProductsizeMeasurementDetails) {
        if (workorderProductsizeMeasurementDetails.length > 0) {
          let sizeMeasurementList = [];
          workorderProductsizeMeasurementDetails.forEach((res) => {
            let samplingPlanIndex = null;
            let productSkUDisplay = this.getSKUDisplay(res.workOrderProductId).skuDisplay;

            if (this.samplingPlan.SKUSamplingList != null) {
              samplingPlanIndex = this.samplingPlan.SKUSamplingList.filter((y) => y.SKUDisplay === productSkUDisplay);
            }

            let productAttachments = [];
            productAttachments = this.workOrderProducts.reduce((accumulator, currentValue) => {
              if (currentValue.id == res.workOrderProductId) {
                const Attachments = currentValue.workOrderProductAttachments;
                accumulator.push(Attachments);
              }
              return accumulator;
            }, [])[0];
            if (res.isCarton) {
              const addedCartonSizeMeasurement = {
                id: res.id,
                skuDisplay: this.getSKUDisplay(res.workOrderProductId).skuDisplay,
                workOrderProductId: res.workOrderProductId,
                po: this.getSKUDisplay(res.workOrderProductId).po,
                isCarton: res.isCarton,
                specifiedLength: res.specifiedLength,
                specifiedWidth: res.specifiedWidth,
                specifiedHeight: res.specifiedHeight,
                actualLength: res.actualLength,
                actualWidth: res.actualWidth,
                actualHeight: res.actualHeight,
                dimensionalUnit: res.dimensionalUnit,
                dimensionalResult: res.dimensionalResult,
                specifiedWeight: res.specifiedWeight,
                actualWeight: res.actualWeight,
                weightUnit: res.weightUnit,
                weightResult: res.weightResult,
                sampleSize: res.sampleSize,
                orderNumber: res.orderNumber,
                workOrderProductAttachments: productAttachments,
              };

              if (this.cartonEditedIndex > -1) {
                Object.assign(this.cartonSizeMeasurementList[this.cartonEditedIndex], addedCartonSizeMeasurement);
              } else {
                this.cartonSizeMeasurementList.push(addedCartonSizeMeasurement);
              }
            } else {
              const addedProductSizeMeasurement = {
                id: res.id,
                skuDisplay: this.getSKUDisplay(res.workOrderProductId).skuDisplay,
                po: this.getSKUDisplay(res.workOrderProductId).po,
                workOrderProductId: res.workOrderProductId,
                isCarton: res.isCarton,
                specifiedLength: res.specifiedLength,
                specifiedWidth: res.specifiedWidth,
                specifiedHeight: res.specifiedHeight,
                actualLength: res.actualLength,
                actualWidth: res.actualWidth,
                actualHeight: res.actualHeight,
                dimensionalUnit: res.dimensionalUnit,
                dimensionalResult: res.dimensionalResult,
                specifiedWeight: res.specifiedWeight,
                actualWeight: res.actualWeight,
                weightUnit: res.weightUnit,
                weightResult: res.weightResult,
                sampleSize: res.sampleSize,
                //sampleSize: res.sampleSize == null ? samplingPlanIndex[0].productSampleSize : res.sampleSize,
                orderNumber: res.orderNumber,
                workOrderProductAttachments: productAttachments,
              };

              if (this.productEditedIndex > -1) {
                Object.assign(this.productSizeMeasurementList[this.productEditedIndex], addedProductSizeMeasurement);
              } else {
                this.productSizeMeasurementList.push(addedProductSizeMeasurement);
              }
            }
          });
          this.cartonEditedIndex = -1;
          this.productEditedIndex = -1;
          console.log("  this.cartonSizeMeasurementList", this.cartonSizeMeasurementList);
          console.log("this.productSizeMeasurementList", this.productSizeMeasurementList);
          this.productSizeMeasurementId = this.productSizeMeasurementList.filter(x => x.isCarton == false)[0].id
          this.getPointOfMeasurement();
          
        }
      }
    },
    getTotalAvailableQuantityProduct(workOrderProductId) {
      let totalProduct = null;
      let productofackedQtyAndUnderPackingQty = this.productQuantityVerificationList.reduce(function(tot, arr) {
        if (arr.workOrderProductId == workOrderProductId && arr.availableQuantityCarton == null && arr.caseCountProduct == null && arr.underPackingQty == null) {
          return totalProduct;
        }
        if (arr.workOrderProductId == workOrderProductId && arr.availableQuantityCarton == -1) {
          return (totalProduct = -1);
        }

        if (arr.workOrderProductId == workOrderProductId && (arr.availableQuantityCarton != null || arr.caseCountProduct != null || arr.underPackingQty != null)) {
          if (arr.packedQty == null && arr.availableQuantityProduct != null) {
            totalProduct += Number(arr.availableQuantityProduct);
          } else {
            totalProduct += Number(arr.packedQty) + Number(arr.underPackingQty == -1 ? 0 : arr.underPackingQty);
          }
        }
        return totalProduct;
      }, null);

      return productofackedQtyAndUnderPackingQty;
    },

    getExcessShortQtyProduct(workOrderProductId, qty) {
      let totalAvailableQuantityProduct = this.getTotalAvailableQuantityProduct(workOrderProductId);
      if (totalAvailableQuantityProduct == null) {
        return null;
      } else {
        return totalAvailableQuantityProduct - qty;
      }
    },
    insertQuantityVerification() {
      console.log("insertQuantityVerification");
      let workOrderProductQuantityVerificationResultObjList = [];
      this.Products.forEach((item) => {
        if (item.workOrderProductQuantityVerificationResults) {
          if (item.workOrderProductQuantityVerificationResults.length <= 0) {
            const addedRowQuantity = {
              id: null,
              workOrderProductId: item.id,
              caseCountProduct: item.casePackage,
              availableQuantityCarton: null,
              sampleSizeCarton: null,
              sampleSizeCarton2: null,
              sampleSizeCartonTotal: null,
              availableQuantityProduct: null,
              excessShortQuantity: null,
              sampleSizeProduct: null,
              sampleSizeProduct2: null,
              sampleSizeProductTotal: null,
              exportCartonNumbersOpened: null,
              remarks: null,
              packedQty: null,
              underPackingQty: null,
              underProductionFinishingQty: null,

              orderNumber: 1,
              sectionId: 1,
            };
            workOrderProductQuantityVerificationResultObjList.push(addedRowQuantity);
          }
        }
      });

      if (workOrderProductQuantityVerificationResultObjList.length > 0) {
        this.utilityFn.getServerData("post", this.apiList.upsertWorkOrderProductQuantityVerificationResult, workOrderProductQuantityVerificationResultObjList, (res) => {
          this.loadQuantityVerificationProductDetails(res.data);
        });
      }
    },
    loadExistingQuantityVerificationDetails() {
      let existingQuantityVerificationList = [];
      this.Products.forEach((x) => {
        if (x.workOrderProductQuantityVerificationResults.length > 0) {
          x.workOrderProductQuantityVerificationResults.forEach((item) => {
            existingQuantityVerificationList.push(item);
          });
        }
      });
      if (existingQuantityVerificationList.length > 0) {
        this.loadQuantityVerificationProductDetails(existingQuantityVerificationList);
      }
    },

    loadQuantityVerificationProductDetails(quantityVerificationProductDetails) {
      console.log("quantityVerificationProductDetails", quantityVerificationProductDetails);
      if (quantityVerificationProductDetails) {
        if (quantityVerificationProductDetails.length > 0) {
          // let quantityVerificationList = [];
          quantityVerificationProductDetails.forEach((res) => {
            let productAttachments = [];
            productAttachments = this.Products.reduce((accumulator, currentValue) => {
              if (currentValue.id == res.workOrderProductId) {
                const Attachments = currentValue.workOrderProductAttachments;
                accumulator.push(Attachments);
              }
              return accumulator;
            }, [])[0];
            const quantityVerification = {
              id: res.id,
              workOrderProductId: res.workOrderProductId,
              productID: this.getSKUDisplay(res.workOrderProductId).productID,
              skuDisplay: this.getSKUDisplay(res.workOrderProductId).skuDisplay,
              po: this.getSKUDisplay(res.workOrderProductId).po,
              cartonQty: this.getSKUDisplay(res.workOrderProductId).cartonQty,
              qty: this.getSKUDisplay(res.workOrderProductId).qty,
              //caseCountProduct: this.getSKUDisplay(res.workOrderProductId).caseCountProduct,
              caseCountProduct: res.caseCountProduct,
              availableQuantityCarton: res.availableQuantityCarton,
              sampleSizeCarton: res.sampleSizeCarton,
              sampleSizeCarton2: res.sampleSizeCarton,
              sampleSizeCartonTotal: res.sampleSizeCartonTotal,
              excessShortQuantity: res.excessShortQuantity,
              sampleSizeProduct: res.sampleSizeProduct,
              sampleSizeProduct2: res.sampleSizeProduct,
              sampleSizeProductTotal: res.sampleSizeProductTotal,
              exportCartonNumbersOpened: res.exportCartonNumbersOpened,
              remarks: res.remarks,
              packedQty: res.packedQty,
              underPackingQty: res.underPackingQty,
              underProductionFinishingQty: res.underProductionFinishingQty,
              availableQuantityProduct: res.availableQuantityProduct,
              orderNumber: res.orderNumber,
              workOrderProductAttachments: productAttachments,
            };

            if (this.editedIndex > -1) {
              Object.assign(this.productQuantityVerificationList[this.editedIndex], quantityVerification);
            } else {
              this.productQuantityVerificationList.push(quantityVerification);
            }
          });
          this.editedIndex = -1;
        }
      }
    },
    countLenght(item) {
      let count = 0;
      count = item.length.length();
      return count;
    },
    factorywarehouse() {
      let val = false;
      if (this.groupedSupplierAttachments.length === 0) {
        val = true;
      }
      return val;
    },
    scannableDescription(scannableId) {
      if (scannableId == null || scannableId == undefined) {
        return "";
      }
      const item = this.scannableOptions.find((x) => x.id === +scannableId);
      return item ? item.text : "";
    },
    getUnitOfMeasurementsType(typeId) {
      if (!typeId || typeId == "") {
        return null;
      }
      let unitOfMeasurementTypeDescription = this.unitOfMeasurementOptions.find((x) => x.id === typeId).description;
      return unitOfMeasurementTypeDescription;
    },
    resultDescription(resultId) {
      if (resultId == null || resultId == undefined) {
        return "";
      }

      const item = this.resultOptions.find((x) => x.id === +resultId);
      return item ? item.text : "";
    },
    getTypeBarcode(typeId) {
      if (!typeId || typeId == "") {
        return null;
      }
      let barcodetype = this.barcodeTypeOptions.find((x) => x.id === typeId).description;
      return barcodetype;
    },
    loadCartonProductBarcodeDetails(workorderProduct) {
      workorderProduct.forEach((x) => {
        if (x.workOrderProductBarcodeVerificationResults) {
          if (x.workOrderProductBarcodeVerificationResults.length > 0) {
            this.loadBarcodeDetails(x.workOrderProductBarcodeVerificationResults);
          }
        }
      });
    },

    getSKUDisplay(productId) {
      if (!productId || productId == "" || productId == null) {
        return null;
      }
      let skuDisplay = this.skuDisplayList.find((x) => x.workOrderProductId === productId);
      return skuDisplay;
    },
    loadBarcodeDetails(workOrderProductBarcodeVerificationResults) {
      if (workOrderProductBarcodeVerificationResults) {
        if (workOrderProductBarcodeVerificationResults.length > 0) {
          workOrderProductBarcodeVerificationResults.forEach((res) => {
            if (res.isCarton) {
              const addedCartonBarcode = {
                id: res.id,
                po: this.getSKUDisplay(res.workOrderProductId).po,
                skuDisplay: this.getSKUDisplay(res.workOrderProductId).skuDisplay,
                workOrderProductId: res.workOrderProductId,
                isCarton: res.isCarton,
                result: res.result,
                scannable: res.scannable,
                codeOnLabel: res.codeOnLabel,
                codeScanned: res.codeScanned,
                codeSpecified: res.codeSpecified,
                barcodeTypeId: res.barcodeTypeId,
                barcodeLabel: res.barcodeLabel,
              };
              this.cartonBarcodeList.push(addedCartonBarcode);
            } else {
              const addedBarcode = {
                id: res.id,
                po: this.getSKUDisplay(res.workOrderProductId).po,
                skuDisplay: this.getSKUDisplay(res.workOrderProductId).skuDisplay,
                workOrderProductId: res.workOrderProductId,
                isCarton: res.isCarton,
                result: res.result,
                scannable: res.scannable,
                codeOnLabel: res.codeOnLabel,
                codeScanned: res.codeScanned,
                codeSpecified: res.codeSpecified,
                barcodeTypeId: res.barcodeTypeId,
                barcodeLabel: res.barcodeLabel,
              };
              this.barcodeList.push(addedBarcode);
            }
          });
        }
      }
    },
    resultDescriptionOnsite(resultId) {
      console.log("@@@resultDescription", resultId);

      if (!resultId) {
        return "";
      }

      const item = this.onsiteItems.find((x) => x.id === +resultId);
      return item ? item.text : "";
    },
    remarkClassification(classification) {
      return this.remarkClassificationList.find((x) => x.id == classification).description;
    },
    go_WorkOrder(id) {
      this.$router.push({ name: "workOrderId", params: { id: 1 } });
    },
    go_targe(position) {
      window.scrollTo({
        top: this.$refs[position].getBoundingClientRect().top - this.$refs.content.getBoundingClientRect().top,
        behavior: "smooth",
      });
      this.drawer = false;
    },
    go_back() {
      this.$store.dispatch("navigation/setAppHeader", {
        show: true,
      });
      this.$router.go(-1);
    },
    groupSupplierAttachments() {
      this.groupedSupplierAttachments = [];
      this.groupedSupplierAttachmentsFactory = [];
      this.groupedSupplierAttachmentsWarehouse = [];

      this.groupedSupplierDescriptions = _.uniq(_.map(this.supplierAttachments, "description"));
      this.staticDescriptions.forEach((desc) => {
        let index = this.groupedSupplierDescriptions.indexOf(desc);
        if (index !== -1) this.groupedSupplierDescriptions.splice(index, 1);
      });
      this.groupedSupplierDescriptions.unshift(...this.staticDescriptions);
      this.groupedSupplierDescriptions.forEach((description) => {
        const filteredAttachments = this.supplierAttachments.filter((x) => x.description === description);
        this.groupedSupplierAttachments.push({
          description,
          attachments: filteredAttachments,
        });
      });
    },
    // loadSupplierImageFactory(groupedAttachmentIndex, attachmentIndex, imageId) {
    //     this.groupedSupplierAttachments[groupedAttachmentIndex].attachments[attachmentIndex].src = 'loading';
    //     this.groupedSupplierAttachments[groupedAttachmentIndex].attachments  = [...this.groupedSupplierAttachments[groupedAttachmentIndex].attachments ];

    //      this.apiFn.getImage(imageId).then(result => {
    //         let src = "data:" + result.fileType + ";base64," + result.data;
    //         this.groupedSupplierAttachments[groupedAttachmentIndex].attachments[attachmentIndex].src = src;
    //         this.groupedSupplierAttachments[groupedAttachmentIndex].attachments = [...this.groupedSupplierAttachments[groupedAttachmentIndex].attachments];
    //     });
    // },
    loadSupplierImage(groupedAttachmentIndex, attachmentIndex, imageId) {
      this.groupedSupplierAttachments[groupedAttachmentIndex].attachments[attachmentIndex].src = "loading";
      this.groupedSupplierAttachments[groupedAttachmentIndex].attachments = [...this.groupedSupplierAttachments[groupedAttachmentIndex].attachments];
      const getImage = async () => {
        this.apiFn.getImage(imageId).then((result) => {
          let src = "data:" + result.fileType + ";base64," + result.data;
          this.groupedSupplierAttachments[groupedAttachmentIndex].attachments[attachmentIndex].src = src;
          this.groupedSupplierAttachments[groupedAttachmentIndex].attachments = [...this.groupedSupplierAttachments[groupedAttachmentIndex].attachments];
        });
      };
      getImage().then(() => {});
    },

    //Other Attachment
    groupOtherAttachments() {
      this.groupedOtherAttachments = [];
      this.groupedOtherDescriptions = _.uniq(_.map(this.otherAttachments, "description"));
      this.staticDescriptions.forEach((desc) => {
        let index = this.groupedOtherDescriptions.indexOf(desc);

        if (index !== -1) this.groupedOtherDescriptions.splice(index, 1);
      });
      this.groupedOtherDescriptions.forEach((description) => {
        const filteredAttachments = this.otherAttachments.filter((x) => x.description === description);
        this.groupedOtherAttachments.push({
          description,
          attachments: filteredAttachments,
        });
      });
    },

    loadOtherAttachmentImage(groupedAttachmentIndex, attachmentIndex, imageId) {
      this.groupedOtherAttachments[groupedAttachmentIndex].attachments[attachmentIndex].src = "loading";
      this.groupedOtherAttachments[groupedAttachmentIndex].attachments = [...this.groupedOtherAttachments[groupedAttachmentIndex].attachments];
      const getImage = async () => {
        this.apiFn.getImage(imageId).then((result) => {
          let src = "data:" + result.fileType + ";base64," + result.data;
          this.groupedOtherAttachments[groupedAttachmentIndex].attachments[attachmentIndex].src = src;
          this.groupedOtherAttachments[groupedAttachmentIndex].attachments = [...this.groupedOtherAttachments[groupedAttachmentIndex].attachments];
        });
      };
      getImage().then(() => {});
    },

    //Loading Issue of Onsite Tests
    loadOnSiteTestImage(index, imageId) {
      this.onSiteTests[index].src = "loading";
      this.onSiteTests = [...this.onSiteTests];
      const getImage = async () => {
        this.apiFn.getImage(imageId).then((result) => {
          let src = "data:" + result.fileType + ";base64," + result.data;
          this.onSiteTests[index].src = src;
          this.onSiteTests = [...this.onSiteTests];
        });
      };
      getImage().then(() => {});
    },

    loadWorkOrderProductAttachment(pIndex, aIndex, imageId) {
      this.workOrderProducts[pIndex].attachments[aIndex].src = "loading";
      this.workOrderProducts[pIndex].attachments = [...this.workOrderProducts[pIndex].attachments];
      const getImage = async () => {
        this.apiFn.getImage(imageId).then((result) => {
          let src = "data:" + result.fileType + ";base64," + result.data;
          this.workOrderProducts[pIndex].attachments[aIndex].src = src;
          this.workOrderProducts[pIndex].attachments = [...this.workOrderProducts[pIndex].attachments];
        });
      };
      getImage().then(() => {});
    },
    loadDefectAttachment(pIndex, dIndex, aIndex, imageId) {
      this.workOrderProducts[pIndex].defects[dIndex].attachments[aIndex].src = "loading";
      this.workOrderProducts[pIndex].defects[dIndex].attachments = [...this.workOrderProducts[pIndex].defects[dIndex].attachments];
      const getImage = async () => {
        this.apiFn.getImage(imageId).then((result) => {
          let src = "data:" + result.fileType + ";base64," + result.data;
          this.workOrderProducts[pIndex].defects[dIndex].attachments[aIndex].src = src;
          this.workOrderProducts[pIndex].defects[dIndex].attachments = [...this.workOrderProducts[pIndex].defects[dIndex].attachments];
        });
      };
      getImage().then(() => {});
    },
    loadGeneralImage(index, imageId) {
      this.generalAttachments[index].src = "loading";
      this.generalAttachments = [...this.generalAttachments];
      const getImage = async () => {
        this.apiFn.getImage(imageId).then((result) => {
          let src = "data:" + result.fileType + ";base64," + result.data;
          this.generalAttachments[index].src = src;
          this.generalAttachments = [...this.generalAttachments];
        });
      };
      getImage().then(() => {});
    },
    loadSamplingImage(index, imageId) {
      this.samplingAttachments[index].src = "loading";
      this.samplingAttachments = [...this.samplingAttachments];
      const getImage = async () => {
        this.apiFn.getImage(imageId).then((result) => {
          let src = "data:" + result.fileType + ";base64," + result.data;
          this.samplingAttachments[index].src = src;
          this.samplingAttachments = [...this.samplingAttachments];
        });
      };
      getImage().then(() => {});
    },
    loadProductImage(index, imageId) {
      this.productAttachments[index].src = "loading";
      this.productAttachments = [...this.productAttachments];
      const getImage = async () => {
        this.apiFn.getImage(imageId).then((result) => {
          let src = "data:" + result.fileType + ";base64," + result.data;
          this.productAttachments[index].src = src;
          this.productAttachments = [...this.productAttachments];
        });
      };
      getImage().then(() => {});
    },
    loadStockInspectedImage(index, imageId) {
      this.stockinspectedAttachments[index].src = "loading";
      this.stockinspectedAttachments = [...this.stockinspectedAttachments];
      const getImage = async () => {
        this.apiFn.getImage(imageId).then((result) => {
          let src = "data:" + result.fileType + ";base64," + result.data;
          this.stockinspectedAttachments[index].src = src;
          this.stockinspectedAttachments = [...this.stockinspectedAttachments];
        });
      };
      getImage().then(() => {});
    },
    loadRemarksCommentsImage(index, imageId) {
      this.remarkscommentsAttachments[index].src = "loading";
      this.remarkscommentsAttachments = [...this.remarkscommentsAttachments];
      const getImage = async () => {
        this.apiFn.getImage(imageId).then((result) => {
          let src = "data:" + result.fileType + ";base64," + result.data;
          this.remarkscommentsAttachments[index].src = src;
          this.remarkscommentsAttachments = [...this.remarkscommentsAttachments];
        });
      };
      getImage().then(() => {});
    },
    loadCDFImage(index, imageId) {
      this.cdfAttachments[index].src = "loading";
      this.cdfAttachments = [...this.cdfAttachments];
      const getImage = async () => {
        this.apiFn.getImage(imageId).then((result) => {
          let src = "data:" + result.fileType + ";base64," + result.data;
          this.cdfAttachments[index].src = src;
          this.cdfAttachments = [...this.cdfAttachments];
        });
      };
      getImage().then(() => {});
    },
    loadSerialImage(index, imageId) {
      this.serialAttachments[index].src = "loading";
      this.serialAttachments = [...this.serialAttachments];
      const getImage = async () => {
        this.apiFn.getImage(imageId).then((result) => {
          let src = "data:" + result.fileType + ";base64," + result.data;
          this.serialAttachments[index].src = src;
          this.serialAttachments = [...this.serialAttachments];
        });
      };
      getImage().then(() => {});
    },
    loadSampleImage(index, imageId) {
      this.sampleAttachments[index].src = "loading";
      this.sampleAttachments = [...this.sampleAttachments];
      const getImage = async () => {
        this.apiFn.getImage(imageId).then((result) => {
          let src = "data:" + result.fileType + ";base64," + result.data;
          this.sampleAttachments[index].src = src;
          this.sampleAttachments = [...this.sampleAttachments];
        });
      };
      getImage().then(() => {});
    },
    groupOthersAttachments() {
      this.groupedOthersAttachments = [];
      this.groupedOthersAttachmentsCDF = [];
      this.groupedOthersAttachmentsSerial = [];
      this.groupedOthersAttachmentsSample = [];
      this.groupedOthersCDF.forEach((obj) => {
        const filteredAttachments = this.othersAttachments.filter((x) => x.description === obj.description);
        this.groupedOthersAttachmentsCDF.push({
          description: obj.title,
          attachments: filteredAttachments,
        });
      });
      this.groupedOthersSerial.forEach((obj) => {
        const filteredAttachments = this.othersAttachments.filter((x) => x.description === obj.description);
        this.groupedOthersAttachmentsSerial.push({
          description: obj.title,
          attachments: filteredAttachments,
        });
      });
      this.groupedOthersSample.forEach((obj) => {
        const filteredAttachments = this.othersAttachments.filter((x) => x.description === obj.description);
        this.groupedOthersAttachmentsSample.push({
          description: obj.title,
          attachments: filteredAttachments,
        });
      });
    },
    loadOthersImageCDF(groupedAttachmentIndex, attachmentIndex, imageId) {
      this.groupedOthersAttachmentsCDF[groupedAttachmentIndex].attachments[attachmentIndex].src = "loading";
      this.groupedOthersAttachmentsCDF[groupedAttachmentIndex].attachments = [...this.groupedOthersAttachmentsCDF[groupedAttachmentIndex].attachments];
      const getImage = async () => {
        this.apiFn.getImage(imageId).then((result) => {
          let src = "data:" + result.fileType + ";base64," + result.data;
          this.groupedOthersAttachmentsCDF[groupedAttachmentIndex].attachments[attachmentIndex].src = src;
          this.groupedOthersAttachmentsCDF[groupedAttachmentIndex].attachments = [...this.groupedOthersAttachmentsCDF[groupedAttachmentIndex].attachments];
        });
      };
      getImage().then(() => {});
    },
    loadOthersImageSerial(groupedAttachmentIndex, attachmentIndex, imageId) {
      this.groupedOthersAttachmentsSerial[groupedAttachmentIndex].attachments[attachmentIndex].src = "loading";
      this.groupedOthersAttachmentsSerial[groupedAttachmentIndex].attachments = [...this.groupedOthersAttachmentsSerial[groupedAttachmentIndex].attachments];
      const getImage = async () => {
        this.apiFn.getImage(imageId).then((result) => {
          let src = "data:" + result.fileType + ";base64," + result.data;
          this.groupedOthersAttachmentsSerial[groupedAttachmentIndex].attachments[attachmentIndex].src = src;
          this.groupedOthersAttachmentsSerial[groupedAttachmentIndex].attachments = [...this.groupedOthersAttachmentsSerial[groupedAttachmentIndex].attachments];
        });
      };
      getImage().then(() => {});
    },
    loadOthersImageSample(groupedAttachmentIndex, attachmentIndex, imageId) {
      this.groupedOthersAttachmentsSample[groupedAttachmentIndex].attachments[attachmentIndex].src = "loading";
      this.groupedOthersAttachmentsSample[groupedAttachmentIndex].attachments = [...this.groupedOthersAttachmentsSample[groupedAttachmentIndex].attachments];
      const getImage = async () => {
        this.apiFn.getImage(imageId).then((result) => {
          let src = "data:" + result.fileType + ";base64," + result.data;
          this.groupedOthersAttachmentsSample[groupedAttachmentIndex].attachments[attachmentIndex].src = src;
          this.groupedOthersAttachmentsSample[groupedAttachmentIndex].attachments = [...this.groupedOthersAttachmentsSample[groupedAttachmentIndex].attachments];
        });
      };
      getImage().then(() => {});
    },
    loadOthersImage(groupedAttachmentIndex, attachmentIndex, imageId) {
      this.groupedOthersAttachments[groupedAttachmentIndex].attachments[attachmentIndex].src = "loading";
      this.groupedOthersAttachments[groupedAttachmentIndex].attachments = [...this.groupedOthersAttachments[groupedAttachmentIndex].attachments];
      const getImage = async () => {
        this.apiFn.getImage(imageId).then((result) => {
          let src = "data:" + result.fileType + ";base64," + result.data;
          this.groupedOthersAttachments[groupedAttachmentIndex].attachments[attachmentIndex].src = src;
          this.groupedOthersAttachments[groupedAttachmentIndex].attachments = [...this.groupedOthersAttachments[groupedAttachmentIndex].attachments];
        });
      };
      getImage().then(() => {});
    },

    showResultSection(sectionId) {
      const index = this.inspectionSummarySections.findIndex((x) => x.sectionId === sectionId);
      if (index !== -1) return !this.inspectionSummarySections[index].isHidden;

      return true;
    },
    showInspectionSummary() {
      let val = false;
      val = this.showResultSection(1) || this.showResultSection(2) || this.showResultSection(3) || this.showResultSection(4) || this.showResultSection(5) || this.showResultSection(6) ? true : false;

      return val;
    },
    showOtherSection() {
      let val = false;
      val = this.showResultSection(13) || this.showResultSection(14) || this.showResultSection(15) ? true : false;

      return val;
    },
  },
  computed: {
    onsiteTestIsVisible() {
      let onsite = this.onSiteTests.filter((x) => x.isHidden === true);

      return onsite.map((x) => {
        (x.criteria = x.criteria == "null" ? "" : x.criteria), (x.others = x.others == "null" ? "" : x.others), (x.numberOfSamples = x.numberOfSamples == "null" ? "" : x.numberOfSamples), (x.sampleSize = x.sampleSize == "null" ? "" : x.sampleSize);
        x.aql = x.aql == "null" ? "" : x.aql;
        x.found = x.found == "null" ? "" : x.found;
        x.accepted = x.accepted == "null" ? "" : x.accepted;
        x.rejected = x.rejected == "null" ? "" : x.rejected;
        return x;
      });
      console.log(this.onSiteTests);
    },

    showOnsiteHeaders() {
      if (this.isOnsiteTestVaryingAQL) {
        return this.onsiteHeadersAQL;
      }
      return this.onsiteHeaders;
    },
    foundCritical: function() {
      let defects = this.WorkOrderProductDefects.filter((defect) => {
        return defect.defectClassificationId === 1;
      });
      let total = defects.reduce(function(prev, cur) {
        return Number(prev) + Number(cur.qty);
      }, 0);
      return total;
    },
    foundMajor: function() {
      let defects = this.WorkOrderProductDefects.filter((defect) => {
        return defect.defectClassificationId === 2;
      });
      let total = defects.reduce(function(prev, cur) {
        return Number(prev) + Number(cur.qty);
      }, 0);
      return total;
    },
    foundMinor: function() {
      let defects = this.WorkOrderProductDefects.filter((defect) => {
        return defect.defectClassificationId === 3;
      });
      let total = defects.reduce(function(prev, cur) {
        return Number(prev) + Number(cur.qty);
      }, 0);
      return total;
    },
  },
};
// alert(1)
</script>

<style lang="stylus" scoped>
.content
	background #eee
	margin-top 7px

label
	display inline-block
	width 140px
	color: #666666
	font-size: 13px
span
	color #0C0C0C
	font-size: 14px
.preview_headers
	height 50px
	background #1989fa
	position fixed
	top 0
	width 100%
	z-index 2

.preview_headers > span
	margin 15px
	display inline-block
	color #fff
.content > div
	padding 0 15px
	padding-bottom 15px

.big_title
	font-weight 700
	font-size 18px
	text-align center
	margin 10px 0

.samll_title
	font-weight 700
	font-size 15px
	color: #676767;

.imgItem
	width 100%
	box-shadow: -1px 1px 5px rgba(0,0,0,.3)

.img_box input
	border-bottom 1px #ccc solid
	width 100%
>>>.img_box .v-responsive__content
	display none
//菜单样式
.nav_list
	margin-top 30px
.nav_big_title
	font-weight 900
	font-size 20
	margin 10px 0
.nav_small_title
	margin-left 30px
	margin 10px 30px
   >>>.remarks-description img {
       max-width: 50%;
   }
</style>
