<template>
  <div id="notificationTemplate">
    <v-dialog v-model="showDialog" max-width="900" persistent @click:outside="closePreview()">
      <v-card id="preview-dialog">
        <v-card-title>
          <span class="headline">Template</span>
        </v-card-title>
        <v-card-text v-if="loadingInProgress">
          <div class="d-flex justify-center">
            <v-progress-circular
              :size="50"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </v-card-text>
        <v-card-text v-else>
          <v-row>
            <v-col cols="12">
              <div class="d-flex align-center">
                <label class="mr-1">Subject</label>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="copy('subject')">
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>Copy</span>
                </v-tooltip>
              </div>
              <div class="d-block">
                <v-text-field v-model="dataTemplate.subject" type="text">
                </v-text-field>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="d-flex align-center">
                <label class="mr-1">Body</label>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="copy('body')">
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>Copy</span>
                </v-tooltip>
              </div>
              <div class="d-block">
                <wysiwyg v-model="dataTemplate.body" />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="btnClose"
            color="blue darken-1"
            text
            @click="closePreview()"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    showDialog: {
      type: Boolean,
    },
    workOrderId: {
      type: Number,
    },
  },

  data() {
    return {
      loadingInProgress: false,
      dataTemplate: {
        subject: "",
        body: "",
      },
    };
  },

  methods: {
    closePreview() {
      this.$emit("update:showDialog", false);
    },

    async loadTemplate() {
      this.loadingInProgress = true;
      const result = await this.utilityFn.getServerDataAsync(
        "get",
        this.apiList.getWorkOrderClientRetailerNotificationTemplate +
          "/" +
          this.workOrderId
      );
      this.dataTemplate = {
        subject: result?.data?.subject,
        body: result?.data?.body,
      };
      this.loadingInProgress = false;
    },

    copy(part) {
      const selBox = document.createElement("div");
      selBox.innerHTML = this.dataTemplate[part];
      document.body.appendChild(selBox);

      window.getSelection().removeAllRanges();
      let range = document.createRange();
      range.selectNode(selBox);
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();

      document.body.removeChild(selBox);
    },
  },

  watch: {
    showDialog(val) {
      this.dataTemplate = { subject: "", body: "" };
      if (val) {
        this.$nextTick().then(() => {
          this.loadTemplate();
        });
      }
    },
  },
};
</script>

<style lang="stylus" scoped></style>
