<template>
  <div name="sectionDefectFormDialog">
    <div v-if="clientDefectsLoadingStatus === 0" class="d-flex flex-column align-center mx-auto pa-10 sectionDefectFormDialog">
      <v-progress-circular :size="50" indeterminate color="primary"></v-progress-circular>
      <span >Loading Client Defects...</span>
    </div>
    <div v-else class="sectionDefectFormDialog">
      <v-row>
        <v-col cols="12" sm="2" md="2">
          <v-checkbox class="ml-2" label="Apply All SKUs" :input-value="formData.workOrderProductIds.length === skuList.length" value :indeterminate="formData.workOrderProductIds.length > 0 && formData.workOrderProductIds.length < skuList.length" @change="onToggleProductSelection(formData, $event, skuList)"></v-checkbox>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-select id="defect_sku" v-bind:items="skuList" v-model="formData.workOrderProductIds" item-text="skupo" item-value="workOrderProductId" label="SKU" single-line bottom multiple></v-select>
        </v-col>        
      </v-row>
      <v-row style="margin-bottom: 20px">
        <v-col cols="12" sm="6" md="4" v-if="showDclType">
          <v-select 
            id="defect_type" 
            v-bind:items="filteredDclTypeAliases" 
            item-text="description"
            item-value="id"
            v-model="formData.dclTypeAliasId"
            @change="onDclTypeAliasChange()"
            label="Defect Type" 
            single-line bottom ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-select
            id="defect_category"
            v-bind:items="this.clientDefectListSection"
            item-text="productCategory"
            item-value="productCategoryId"
            v-model="formData.defectCategoryId"
            label="Select Defect Category"
            single-line
            bottom
            @change="onDefectCategoryChange()"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-autocomplete id="defect_name" label="Defect Name" :items="filteredDefectNameList" item-text="description" item-value="id" v-model="formData.defectNameId" @change="onDefectNameChange()"></v-autocomplete>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-autocomplete id="defect_code" label="Defect Code" :items="filteredDefectCodeList" item-text="defectCode" item-value="defectCode" v-model="formData.defectCode" @change="onDefectCodeChange()"></v-autocomplete>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            id="defect_number"
            class="number_text"
            label="Qty"
            type="number"
            prepend-inner-icon="mdi-minus"
            append-icon="mdi-plus"
            v-model="formData.qty"
            @change="onDefectQtyChange()"
            @click:prepend-inner="minus('qty')"
            @click:append="plus('qty')"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="8">
          <v-radio-group v-model="formData.defectClassificationId" row>
            <v-radio id="defect_classification" v-for="(item, index) in filteredDefectClassificationList" :key="index" :label="item.name" :value="item.id" @change="onChangeDefectClassification(item.id)"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <AutocompleteSearch :url="getInspectionPhotoDescriptionUrl" @onSelect="onSelect" :itemText="itemText" :itemValue="itemValue" :isOutlined="isOutlined" :label="label" :allowManualInput="allowManualInput" :hideSelected="hideSelected" :existingValue="existingValue"></AutocompleteSearch>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-btn id="defect_add_button" color="red darken-1" text @click="go_add_defect('new')" :disabled="!isFormValid">Add to Defect List</v-btn>
          <v-btn id="defect_update_button" v-if="selectedDefectId" color="red darken-1" text @click="go_add_defect" :disabled="!isFormValid">Update Defect</v-btn>
        </v-col>
      </v-row>
      <v-data-table
        id="defect_table"
        :sort-by="['defectCategory.name', 'defectName.name', 'skuDisplay', 'po', 'qty', 'defectClassification.name']"
        v-bind="datatableRowsPerPage"
        :headers="defectHeaders"
        :items="filteredDefects"
        :items-per-page="-1"
        class="elevation-1"
      >
        <template v-slot:item.clientDefect.productCategoryId="{ item }">
          <span id="productCategoryId"> {{ getProductCategoryName(item.clientDefect.productCategoryId) }} </span>
        </template>
        
        <template v-slot:item.dclTypeAliasId="{ item }">
          <span :id="'productCategoryId_' + item.id"> {{ getDclTypeAlias(item.dclTypeAliasId) }} </span>
        </template>

        <template v-slot:item.menu="{ item }">
          <v-btn :id="'defect_edit_' + item.id" icon dark @click="edit_item(item)" :disabled="item.isOffline">
            <v-icon color="rgba(0,0,0,.87)">edit</v-icon>
          </v-btn>
          <v-btn
            icon
            dark
            @click="
              toBeDeletedItem = item;
              showConfirmDialog = true;
            "
            :disabled="item.isOffline"
          >
            <v-icon :id="'defect_delete_' + item.id" color="rgba(0,0,0,.87)" style="margin-left: 15px;">delete</v-icon>
          </v-btn>

          <!-- <v-btn icon dark >
                    <v-icon :id="'defect_addPhoto_' + item.id" color="rgba(0,0,0,.87)" style="margin-left: 15px;">camera_alt</v-icon>
                </v-btn> -->
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="showProgressDialog" :persistent="true">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <div class="text-center">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showConfirmDialog" width="300">
      <v-card>
        <v-card-title class="headline"></v-card-title>
        <v-card-text>Are you sure you want to delete this item?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="delete_item">Yes</v-btn>
          <v-btn color="blue darken-1" text @click="showConfirmDialog = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout" :color="color">
      {{ message }}
      <v-btn text @click="snackbar = false"> Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
let state = {
  appHeaderState: {
    show: true,
    title: "Work Order:",
    showMenu: true,
    showBack: true,
    showLogo: false,
    showTitle: true,
    showSearch: false,
    showCalendar: true,
  },
};

function setState(store) {
  store.dispatch("navigation/setAppHeader", state.appHeaderState);
}
import { mapActions, mapState } from "vuex";
import * as _ from 'lodash';
import AutocompleteSearch from "../../components/common/AutocompleteSearch"

export default {
  name: "sectionDefectFormDialog",
  components: {
    AutocompleteSearch,
  },
  data() {
    return {
      datatableRowsPerPage: {
        "footer-props": {
          "items-per-page-options": [10],
        },
      },
      showDefectFormDialog: true,
      dialog: false,
      snackbar: false,
      timeout: 3000,
      color: "red darken-2",
      message: "",

      Headers: [
        {
          text: "Defect Code",
          align: "center",
          value: "defectCode",
        },
        {
          text: "Defect Type",
          align: "center",
          value: "dclTypeAliasId",
        },
        {
          text: "Defect Category",
          align: "center",
          value: "clientDefect.productCategoryId",
        },
        {
          text: "Defect Name",
          align: "center",
          value: "clientDefect.description",
        },
        {
          text: "SKU",
          align: "center",
          value: "skuDisplay",
        },
        {
          text: "PO",
          align: "center",
          value: "po",
        },
        {
          text: "QTY",
          align: "center",
          value: "qty",
        },
        {
          text: "Description",
          align: "center",
          value: "defectDetails",
        },
        {
          text: "Defect Classification",
          align: "center",
          value: "defectClassification.name",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "menu",
        },
      ],
      selectedDefectId: null,
      formData: {
        id: null,
        dclTypeAliasId: null,
        clientDefectId: null,
        workOrderProductId: null,
        workOrderProductIds: [],
        defectCategoryId: null,
        defectCategory: {
          name: null,
        },
        defectNameId: null,
        defectClassificationId: null,
        defectClassification: {
          name: null,
        },
        defectDetails: null,
        defectCode: null,
        qty: 0,
        sectionId: null,
        clientDefect: {
          description: null,
        },
      },
      textSupplementList: [],
      skuList: [],
      defectCategorylist: [],
      defectNameList: [],
      defectClassificationList: [],
      workOrderProductDefectList: [],
      sectionDclTypeId: null,
      dclTypeAliases: [],
      showDclType: false,

      workOrder: {},
      toBeDeletedItem: {},
      clientDefectListSection: [],
      showProgressDialog: false,
      showConfirmDialog: false,
      defectToEdit: null,
      getInspectionPhotoDescriptionUrl: "",
      itemText: "description",
      itemValue: "description",
      isOutlined: false,
      label: "Enter defect details",
      allowManualInput: true,
      hideSelected: true,
      existingValue: null
    };
  },

  mounted() {
    this.showDefectFormDialog = true;
    const clientDefectList = this.clientDefectList.filter((x) => x.dclType == this.sectionData.sectionName);
    this.clientDefectListSection = clientDefectList.length > 0 ? clientDefectList.map((x) => x.clientDefects)[0] : [];
    this.clientDefectListSection = this.clientDefectListSection.filter(x => x.isProductCategoryActive);
    this.sectionDclTypeId = clientDefectList.length > 0 ? clientDefectList[0].dclTypeId : null;

    const woProductId = this.sectionData.workorderProductId;
    this.getInspectionPhotoDescriptionUrl = this.apiList.getInspectionPhotoDescriptionUrl + "/" + this.$route.params.id;

    this.utilityFn.getServerData("get", this.apiList.woDetailsToAddWoDefect + "/" + this.$route.params.id, {}, (res) => {
      this.workOrder = res.data.data[0];
      this.skuList = res.data.data[0].products.map((x) => {
        return {
          workOrderProductId: x.id,
          productId: x.productID,
          skupo: x.skuDisplay + " - " + x.po,
          sku: x.skuDisplay,
          po: x.po,
        };
      });
      if (woProductId) {
        this.formData.workOrderProductIds = [woProductId];
      }
      this.initState();
    });

    this.loadData();
  },
  methods: {
    loadData() {
      this.utilityFn.getServerData(
        "get",
        this.apiList.getWorkOrderProductDefects,
        {
          workOrderId: this.$route.params.id,
        },
        (result) => {
          if (this.sectionData.sectionId == 11) {
            this.workOrderProductDefectList = result.data.data.filter((x) => x.clientDefectId != null && (x.sectionId == 11 || x.sectionId == 5));
          } else {
            this.workOrderProductDefectList = result.data.data.filter((x) => x.clientDefectId != null && x.sectionId == this.sectionData.sectionId);
          }

          this.$emit("changeValue", this.workOrderProductDefectList);

          var that = this;
          // this.utilityFn.db.transaction(function(tx) {
          //   tx.executeSql("SELECT * FROM OfflineData WHERE pageName=defect", [], (tx, results) => {
          //     var len = results.rows.length;
          //     for (var i = 0; i < len; i++) {
          //       that.workOrderProductDefectList.push(JSON.parse(results.rows.item(i).data));
          //     }
          //   });
          // });
        }
      );
    
      this.utilityFn.getServerData("get", `${this.apiList.getCompanyDclTypeAliases}/${this.workOrderDetails.clientId}/0`, {}, (result) => {
        this.dclTypeAliases = result.data && result.data.data.filter(x => x.isActive);
      });

      this.utilityFn.getServerData("get", this.apiList.getIsApplyToDclTypeAlias + '/' + this.workOrderDetails.clientId, {  }, (result) => {
        this.showDclType = result.data.data;
      });
    },

    getProductCategoryName(productCategoryId) {
      return this.clientDefectListSection.find((x) => x.productCategoryId == productCategoryId).productCategory;
    },

    getDclTypeAlias(dclTypeAliasId) {
      const alias = this.dclTypeAliases.find((x) => x.id == dclTypeAliasId);
      return alias && alias.description;
    },

    getDefectData(item) {
      return this.clientDefectList.find((x) => x.id == item.defectNameId);
    },
    getDefectDataSeverityName(item) {
      let defectSeverities = this.clientDefectList.find((x) => x.id == item.defectNameId).defectSeverities;
      let defectClassificationName = defectSeverities.find((x) => x.id == item.defectClassificationId).name;

      return defectClassificationName;
    },
    initState() {
      state.appHeaderState.title = "Work Order: " + this.workOrder.workOrderDisplayId;
      setState(this.$store);
    },
    showDialog() {
      this.formData = {
        id: null,
        workOrderProductId: null,
        workOrderProductIds: [],
        defectCategoryId: null,
        defectNameId: null,
        defectClassificationId: null,
        defectDetails: null,
        defectCode: null,
        qty: 0,
        sectionId: null,
        clientDefectId: null,
      };
    },
    go_add_defect(status) {
      if (status === "new") {
        this.defectToEdit = null;
        this.formData.id = null;
      }
      let selectedCLientDefect = this.clientDefectListSection.find((x) => x.id == this.formData.defectNameId);
      this.formData.defectClassification = {
        name: null,
      };
      this.formData.clientDefect = {
        description: null,
      };
      this.formData.sectionId = this.sectionData.sectionId;
      this.formData.clientDefectId = selectedCLientDefect.id;
      this.formData.defectClassification.name = selectedCLientDefect.defectSeverities.find((x) => x.id === this.formData.defectClassificationId).name;
      this.formData.clientDefect.description = selectedCLientDefect.description;

      this.existingValue = this.formData.defectDetails;
      const data = this.formData.workOrderProductIds.map(workOrderProductId => {
        const sku = this.skuList.find((x) => x.workOrderProductId == workOrderProductId).sku;
        const id = this.workOrderProductDefectList.find((x) => x.workOrderProductId === workOrderProductId &&
         x.clientDefect.productCategoryId === this.defectToEdit?.defectCategoryId &&
         x.clientDefectId === this.defectToEdit?.defectNameId &&
         x.defectClassificationId === this.defectToEdit?.defectClassificationId &&
         x.defectDetails === this.defectToEdit?.defectDetails
         )?.id;
        
        return {
          clientDefectId: this.formData.clientDefectId,
          defectCategoryId: null,
          defectClassificationId: this.formData.defectClassificationId,
          defectCode: this.formData.defectCode,
          defectDetails: this.formData.defectDetails,
          defectName: null,
          defectNameId: null,
          dclTypeAliasId: this.formData.dclTypeAliasId,
          qty: this.formData.qty,
          sectionId: this.formData.sectionId,
          sku: sku,
          skuDisplay: sku,
          workOrderProductId: workOrderProductId,
          id: id
        }
      })

      if (this.isDefectDuplicate()) {
        this.message = "Defect already exist!";
        this.snackbar = true;
        this.formData.id = this.selectedDefectId;
      } else {
        if (window.navigator.onLine === true) {
          this.showProgressDialog = true;
        }

        this.utilityFn.getServerData(
          "post",
          this.apiList.updateWorkOrderProductDefects,
          data,
          (result) => {
            const isNew = !this.formData.id;

            this.formData.id = result.data[0].id;

            this.loadData();
            this.defectToEdit = null;
            this.existingValue = null;
            this.selectedDefectId = null;
            this.formData = {
              id: null,
              workOrderProductId: this.formData.workOrderProductId,
              workOrderProductIds: this.formData.workOrderProductIds,
              defectCategoryId: null,
              defectNameId: null,
              defectClassificationId: null,
              defectDetails: null,
              qty: 0,
              clientDefectId: null,

              defectCode: null,
              sectionId: this.formData.sectionId,
              defectCategory: {
                name: null,
              },
              defectClassification: {
                name: null,
              },
              clientDefect: {
                description: null,
              },
            };

            this.showProgressDialog = false;
          },
          (errRes) => {
            if (window.navigator.onLine !== true) {
              if (this.formData.id != null) {
                this.formData = {
                  id: null,
                  workOrderProductId: this.formData.workOrderProductId,
                  workOrderProductIds: this.formData.workOrderProductIds,
                  defectCategoryId: null,
                  defectNameId: null,
                  defectClassificationId: null,
                  defectDetails: null,
                  qty: 0,
                  clientDefectId: null,

                  defectCode: null,
                  sectionId: this.formData.sectionId,
                  defectCategory: {
                    name: null,
                  },
                  defectClassification: {
                    name: null,
                  },
                  clientDefect: {
                    description: null,
                  },
                };
              } else {
                this.formData = {
                  id: null,
                  workOrderProductId: this.formData.workOrderProductId,
                  workOrderProductIds: this.formData.workOrderProductIds,
                  defectCategoryId: null,
                  defectNameId: null,
                  defectClassificationId: null,
                  defectDetails: null,
                  qty: 0,
                  clientDefectId: null,

                  defectCode: null,
                  sectionId: this.formData.sectionId,
                  defectCategory: {
                    name: null,
                  },
                  defectClassification: {
                    name: null,
                  },
                  clientDefect: {
                    description: null,
                  },
                };
              }

              this.message = "No Connection ! Data Will be Synced once Network Connectivity is established";
              this.snackbar = true;
              this.showProgressDialog = false;
            }
          }
        );

        this.logChanges();
      }
    },
    delete_item() {
      this.showConfirmDialog = false;
      this.showProgressDialog = true;

      if (this.toBeDeletedItem.id) {
        this.utilityFn.getServerData(
          "delete",
          this.apiList.deleteWorkOrderProductDefect + "/" + this.toBeDeletedItem.id,
          {},
          (result) => {
            let index = this.workOrderProductDefectList.findIndex((x) => x.id === this.toBeDeletedItem.id);
            this.workOrderProductDefectList.splice(index, 1);
            this.$emit("changeValue", this.workOrderProductDefectList);
            this.showProgressDialog = false;
          },
          (errRes) => {
            this.showProgressDialog = false;
            if (window.navigator.onLine != true) {
              let index = this.workOrderProductDefectList.findIndex((x) => x.id === this.toBeDeletedItem.id);
              this.workOrderProductDefectList.splice(index, 1);
              this.$emit("changeValue", this.workOrderProductDefectList);
              this.message = "No Connection ! Data Will be Synced once Network Connectivity is established";
              this.snackbar = true;
              this.showProgressDialog = false;
            }
          }
        );

        this.logChanges();
      }
    },
    edit_item(item) {
      this.selectedDefectId = item.id;
      this.formData.clientDefectId = item.clientDefectId;
      this.formData.id = item.id;
      this.formData.sku = item.sku;
      this.formData.skuDisplay = item.sku;
      this.formData.workOrderProductId = item.workOrderProductId;
      this.formData.workOrderProductIds = [item.workOrderProductId];
      this.formData.defectCode = item.defectCode;
      this.formData.defectName = item.clientDefect.description;
      this.formData.qty = item.qty;
      this.formData.defectCategoryId = item.clientDefect.productCategoryId;
      this.formData.defectNameId = item.clientDefectId;
      this.formData.defectClassificationId = item.defectClassificationId;
      this.formData.defectDetails = item.defectDetails;
      this.formData.sectionId = item.sectionId;
      this.formData.dclTypeAliasId = item.dclTypeAliasId;
      this.defectToEdit = {
        defectCategoryId: item.clientDefect.productCategoryId,
        defectNameId: item.clientDefectId,
        defectClassificationId: item.defectClassificationId,
        defectDetails: item.defectDetails
      };
      this.existingValue = item.defectDetails;
    },
    close() {
      this.showDefectFormDialog = false;
    },
    minus(prop) {
      let currentValue = +this.formData[prop];
      if (currentValue > 0) this.formData[prop] = currentValue - 1;
      else this.formData[prop] = 0;
    },
    plus(prop) {
      this.formData[prop] = +this.formData[prop] + 1;
    },
    onDefectQtyChange() {
      let currentValue = +this.formData.qty;
      if (currentValue <= 0) {
        this.formData.qty = 0;
      }
    },

    onChangeDefectClassification(event) {
      this.formData.defectClassificationId = event;
    },
    onDefectCategoryChange() {
      this.formData.defectCode = null;
      this.formData.defectNameId = null;
    },
    onDefectNameChange() {
      if (this.formData.defectNameId) {
        this.formData.defectCode = this.clientDefectListSection.find((x) => x.id == this.formData.defectNameId).defectCode;
      }
    },
    onDefectCodeChange() {
      if (this.formData.defectCode) {
        this.formData.defectNameId = this.filteredDefectNameList.find((x) => x.defectCode == this.formData.defectCode).id;
      }
    },
    onDclTypeAliasChange() {
      const defectCount = this.filteredDefectNameList.filter(x => x.defectCode == this.formData.defectCode && x.companyDclTypeAliasId == this.formData.dclTypeAliasId).length;
      if (defectCount == 0) {
        this.formData.defectCode = null;
        this.formData.defectNameId = null;
      }
    },
    isDefectDuplicate() {
      let defectList = [...this.workOrderProductDefectList];
      if (this.formData.id) {
        defectList = defectList.filter((defect) => defect.id !== this.formData.id);
      }
      const index = defectList.findIndex((defect) => {
        if (
          defect.clientDefect.productCategoryId === this.formData.defectCategoryId &&
          defect.clientDefectId === this.formData.clientDefectId &&
          defect.defectClassificationId === this.formData.defectClassificationId &&
          defect.defectDetails === this.formData.defectDetails &&
          defect.defectCode === this.formData.defectCode &&
          this.formData.workOrderProductIds.includes(defect.workOrderProductId)
        ) {
          return true;
        }
        return false;
      });

      return index !== -1;
    },

    logChanges() {
      window.sessionStorage.setItem("EditDataLogging", true);
    },

    getSkuPo(workOrderProductId) {
      if (this.skuList.length > 0) {
        return this.skuList.find((x) => x.workOrderProductId == workOrderProductId).po;
      }
    },
    onToggleProductSelection(form, currentValue, options) {
      if(currentValue) {
        form['workOrderProductIds'] = options.map(x => x.workOrderProductId);
      } else {
        form['workOrderProductIds'] = [];
      }
    },
    onSelect(value) {
      this.formData.defectDetails = value?.description;
    },
  },
  computed: {
    ...mapState("inspection", {
      clientDefectList: (state) => state.clientDefectList,
      workOrderDetails: (state) => state.workOrderDetails,
      clientDefectsLoadingStatus: (state) => state.clientDefectsLoadingStatus
    }),

    filteredWorkOrderProductDefectList: function() {
      if (this.sectionData.sectionId == 11) {
        return this.workOrderProductDefectList.filter((x) => x.clientDefectId != null && (x.sectionId == 11 || x.sectionId == 5) && ((this.formData.workOrderProductIds.length === 0) || this.formData.workOrderProductIds.includes(x.workOrderProductId)));
      }

      return this.workOrderProductDefectList.filter((x) => x.sectionId == this.sectionData.sectionId && ((this.formData.workOrderProductIds.length === 0) || this.formData.workOrderProductIds.includes(x.workOrderProductId)));
    },

    filteredDefectNameList: function() {
      return this.clientDefectListSection.filter((x) => x.productCategoryId == this.formData.defectCategoryId && (!this.formData.dclTypeAliasId || this.formData.dclTypeAliasId == x.companyDclTypeAliasId ));
    },

    filteredDefectCodeList: function() {
      return this.clientDefectListSection.filter((x) => x.productCategoryId == this.formData.defectCategoryId && (!this.formData.dclTypeAliasId || this.formData.dclTypeAliasId == x.companyDclTypeAliasId ));
    },

    filteredDefectClassificationList: function() {
      if (this.formData.defectNameId) {
        return this.clientDefectListSection.find((x) => x.id == this.formData.defectNameId).defectSeverities;
      }
      return null;
    },

    filteredDclTypeAliases() {
      return this.dclTypeAliases.filter(x => x.dclTypeId == this.sectionDclTypeId);
    },

    isFormValid() {
      if (
        this.formData.workOrderProductIds.length > 0 &&
        this.formData.defectCategoryId &&
        this.formData.defectNameId &&
        this.formData.defectClassificationId &&
        this.formData.defectDetails &&
        this.formData.defectCode &&
        (!this.showDclType || this.formData.dclTypeAliasId) &&
        this.formData.qty &&
        this.formData.qty > 0
      ) {
        return true;
      }

      return false;
    },

    // show only defects related to the selected sku
    filteredDefects() {
      let filteredDefectList = this.filteredWorkOrderProductDefectList;
      filteredDefectList = filteredDefectList.filter((x) => {
        x.po = this.getSkuPo(x.workOrderProductId);
        return x;
      });
      return filteredDefectList;
    },

    defectHeaders() {
      if (!this.showDclType) {
        return this.Headers.filter(x => x.text !== 'Defect Type');
      }

      return this.Headers;
    }
  },

  props: {
    sectionData: {
      type: Array,
    },
  },
  beforeDestroy() {},
};
</script>
<style lang="stylus" scoped>
.sectionDefectFormDialog
       padding 15px 30px
       background #fff

   >>>.number_text input
       text-align center
</style>
