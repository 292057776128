import {SET_APP_HEADER, SET_PAGE_SWITCHING,
  SET_PAGE_SCROLL_POSITION,SET_PAGE_TRANSITION_TYPE,
  SET_PAGE_TRANSITION_EFFECT,SET_SIDEBAR_VISIBILITY,
  SET_SNACKBAR, SET_LOADING_PAGE, SET_ONLINE
} from "./mutation.types";

export default {
  setAppHeader({ commit }, payload) {
    commit(SET_APP_HEADER, payload);
  },
  setPageSwitching({ commit }, isPageSwitching) {
    commit(SET_PAGE_SWITCHING, isPageSwitching);
  },
  savePageScrollPosition({ commit }, { pageId, scrollPosition }) {
    commit(SET_PAGE_SCROLL_POSITION, { pageId, scrollPosition });
  },
  setPageTransitionType({ commit }, payload) {
    commit(SET_PAGE_TRANSITION_TYPE, payload);
  },
  setPageTransitionEffect({ commit }, payload) {
    commit(SET_PAGE_TRANSITION_EFFECT, payload);
  },
  setSideBarVisibility({commit},payload) {
    commit(SET_SIDEBAR_VISIBILITY,payload);
  },
  setSnackbar({commit},payload) {
    payload.showing = true;
    payload.color = payload.color || 'success';
    payload.timeout = payload.timeout || '3000';
    commit(SET_SNACKBAR, payload)
  },
  setLoadingPage({commit},payload) {
    payload.show = payload.show || false;
    commit(SET_LOADING_PAGE, payload)
  },
  setOnline({commit},payload) {
    commit(SET_ONLINE,payload);
 },
};
