<template>
  <div id="reportPreview">
    <v-dialog v-model="showDialog" persistent max-width="900">
      <v-card id="preview-dialog">
        <v-card-title>
          <span class="headline">Preview</span>
        </v-card-title>
        <v-card-text v-if="showDialog">
          <div v-if="showPreviewContainer" id="preview-container"></div>
          <div v-if="!showPreviewContainer && !previewUnavailable" class="d-flex justify-center align-center" style="height: 100%;">
            <v-progress-circular :size="50" indeterminate color="primary"></v-progress-circular>
          </div>
          <v-snackbar id="test" v-model="previewUnavailable" top :timeout="-1" absolute centered color="blue-grey"  outlined center>
            <span style="padding: 15px; width: 100%; text-align: center;">Report Unavailable</span>
          </v-snackbar>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn id="btnClose" color="blue darken-1" text @click="closePreview()">Close</v-btn>
        </v-card-actions>
      </v-card>

      <!-- <v-snackbar id="test" v-model="previewUnavailable" top :timeout="-1" absolute bottom color="success" outlined center>
        <span style="padding: 15px; width: 100%; text-align: center;">Report Unavailable</span>
      </v-snackbar> -->
    </v-dialog>
  </div>
</template>

<script>
import * as pbi from "powerbi-client";

export default {
  props: {
    showDialog: {
      type: Boolean,
    },
    reportData: {
      type: Object,
    },
  },

  data() {
    return {
      showReport: false,
      showPreviewContainer: false,
      previewUnavailable: false,
      reworkAbortReportConfig: null
    };
  },

  methods: {
    async getClientConfigs() {
        const result = await this.utilityFn.getServerDataAsync('get', this.apiList.getClientCustomConfigs, { clientId: this.reportData.clientId });
        if (result.data) {
          this.reworkAbortReportConfig = result.data.find(x => x.configKey === 'SHOW_REWORK_ABORT_SHORT_REPORT' && x.isActive);
          if (this.reworkAbortReportConfig) {
              this.reworkAbortReportConfig.configValue = JSON.parse(this.reworkAbortReportConfig.configValue);
              this.reportData.reportTypeId = this.reportData.reportTypeId || this.reworkAbortReportConfig.configValue.reportType;
          }
        }
    },

    async getAccessToken() {
      let reportAccessToken = JSON.parse(localStorage.getItem('reportAccessToken'));
      if (reportAccessToken && (new Date()) < new Date(reportAccessToken.expireTime)) {
        return reportAccessToken.accessToken;
      }

      const result = await this.utilityFn.getServerDataAsync("get", this.apiList.GetReportAccessToken);
      if (result?.data) {
        reportAccessToken = {
          accessToken: result.data.accessToken,
          expiresIn: result.data.expiresIn,
          expireTime: new Date((new Date()).getTime() + 1000 * result.data.expiresIn).getTime()
        }
        localStorage.setItem('reportAccessToken', JSON.stringify(reportAccessToken));
      }

      return reportAccessToken?.accessToken
    },

    async getGenericTemplates() {
      const result = await this.utilityFn.getServerDataAsync("get", this.apiList.getGenericReportTemplates);
      return result?.data || [];
    },


    async getReportTemplates() {
      const result = await this.utilityFn.getServerDataAsync("get", this.apiList.getCompanyReportTemplates + "/" + this.reportData.clientId);
     return result?.data || [];
    },

    async previewReport() {
      this.previewUnavailable = false;
      this.showPreviewContainer = true;
      
      // await this.getClientConfigs();
      const [accessToken, generticTemplates, clientTemplates] =  await Promise.all([this.getAccessToken(), this.getGenericTemplates(), this.getReportTemplates()]);


      const genericTemplate = generticTemplates.find((x) => x.industryId == this.reportData.industryId) || {};
      let template = null;
      
      let templates = clientTemplates.filter(x => x.industryId === this.reportData.industryId
          && (x.workOrderClassIds.length === 0 || x.workOrderClassIds.includes(this.reportData.workOrderClassId)));

      if (this.reportData.reportTypeId != null) {
        template = templates.find(x => !x.resultTypeName || (x.resultTypeName == this.reportData.rating) && x.reportTypeId === this.reportData.reportTypeId);
      } else {
        if (!this.reportData.rating && templates.length > 1) {
          templates = templates.filter(x => !x.resultTypeName?.toLowerCase()?.includes('abort'));
        } 
        
        template = templates.find(x => !x.resultTypeName || !this.reportData.rating || (x.resultTypeName == this.reportData.rating));
      }

      const url = template?.templateLink || genericTemplate?.reportLink;
      if (url) {
        const baseUrl = this.report.buildBaseEmbeddedUrl(url);
        this.pbiEmbedConfig = this.getPbiEmbedConfig(baseUrl, accessToken);
        this.loadPowerBiReport();
      } else {
        this.previewUnavailable = true;
      }
    },
    
    loadPowerBiReport() {
      const pbiContainerElement = document.getElementById("preview-container");
      const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
      const report = powerbi.embed(pbiContainerElement, this.pbiEmbedConfig);
      report.off('loaded');
      report.on('loaded', (event) => {
        console.log('loaded: ' + event);
      });
    },

    getPbiEmbedConfig(url, accessToken) {
      const params = new URLSearchParams(url);
      const reportNumber = this.reportData.reportNumber ? `&rp:ReportNo=${this.reportData.reportNumber}` : '';
      const draftVersion = '&rp:ReportVersion=DRAFT';
      let embedUrl = url + `&rp:ClientName=${this.reportData.clientName}&rp:WorkOrderID=${this.reportData.workOrderDisplayId}${reportNumber}${draftVersion}`;

      return {
        type: 'report',
        tokenType: pbi.models.TokenType.Aad,
        accessToken: accessToken,
        embedUrl: embedUrl,
        id: params.get('reportId'),
        permissions: pbi.models.Permissions.All,
        viewMode: pbi.models.ViewMode.Edit,
        settings: {
          filterPaneEnabled: false, // this will show/hide filter options
          navContentPaneEnabled: false
        }
      };
    },


    closePreview() {
      this.previewUnavailable = false;
      this.showPreviewContainer = false;
      this.$emit("update:showDialog", false);
    },
    async getEmailReportNumber(){
      console.log(this.apiList.getAndSaveEmailReportDialogReportNumber);
      const reportNumber = await this.utilityFn.getServerDataAsync("get", this.apiList.getAndSaveEmailReportDialogReportNumber + "/" + this.reportData.workOrderId, {});
      
      this.previewReport();
      
      return true;
    },
  },

  watch: {
    showDialog(val) {
      console.log("Preview Component showDialog", val);
    },
    reportData(val) {
      this.getEmailReportNumber();
      console.log("Preview Component reportData", val);
    },
  },
};
</script>

<style lang="stylus" scoped>
#preview-container
    height 100%

#preview-dialog
    display flex
    flex-direction column
    height 98vh

#preview-dialog .v-card__text
    flex-grow 1
    height 85%

>>> .v-dialog
    max-height 100%
</style>
