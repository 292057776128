<template>
  <div name="TotalMajorMinorAql">
    <v-select v-if="toggleOn" v-model="msgData.values[0].value" :items="items"  @blur="sava_field_value(msgData.formAtomID, msgData.values[0].value, msgData.values[0].referenceKey)">
      <template v-slot:label>
        {{msgData.label}}<span v-if="msgData.isRequired" class="red--text"> *</span>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: "TotalMajorMinorAql",
  data() {
    return {
      items: [],
      fieldReference: {
        label: "",
        formAtomID: "",
      },
      toggleOn: "",
    };
  },
  computed: {},
  mounted() {
    var self = this;
    this.fieldReference.formAtomID = this.msgData.fieldReference;

    const toggle = document.getElementById(this.fieldReference.formAtomID.toString());
    this.toggleOn = toggle.checked;

    this.items = this.msgData.fieldLookupValues.result.map((x) => {
      return { text: x.value, value: x.key };
    });

    this.$root.$on(this.fieldReference.formAtomID, (param) => {
      this.toggleOn = param == "true";
    });
  },
  methods: {
    sava_field_value(id, val, key) {
      var data = [
        {
          formAtomID: id,
          value: val,
          referenceKey: key,
        },
      ];
      this.utilityFn.getServerData("post", this.apiList.baseUrl + "/api/AIMS/UpsertFormValue/" + this.$route.params.id, data, (res) => {
        this.sectionHelper.updatedDynamicSectionModifiedDate(this.sectionName);
      });
    },
  },
  props: {
    msgData: {
      type: Object,
    },
    msgFormAtom: {
      type: Array,
    },
    sectionName: {
      type: String,
    },
  },
};
</script>

<style lang="stylus" scoped></style>
