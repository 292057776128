import { render, staticRenderFns } from "./WoDefect.vue?vue&type=template&id=6ba1d522&scoped=true&"
import script from "./WoDefect.vue?vue&type=script&lang=js&"
export * from "./WoDefect.vue?vue&type=script&lang=js&"
import style0 from "./WoDefect.vue?vue&type=style&index=0&id=6ba1d522&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ba1d522",
  null
  
)

export default component.exports