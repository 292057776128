import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from '@/plugins/vuetify'
import * as moment from 'moment';
//import { MsalPlugin, MsalPluginOptions } from './plugins/msal-plugin';

import wysiwyg from "vue-wysiwyg-fixed";
Vue.use(wysiwyg, {
  forcePlainTextOnPaste: true,
}); // config is optional. more below

//Import Froala Editor
import 'froala-editor/js/plugins.pkgd.min.js';
//Import third party plugins
import 'froala-editor/js/third_party/embedly.min';
import 'froala-editor/js/third_party/font_awesome.min';
import 'froala-editor/js/third_party/spell_checker.min';
import 'froala-editor/js/third_party/image_tui.min';
// Import Froala Editor css files.
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Import and use Vue Froala lib.
import VueFroala from 'vue-froala-wysiwyg'
Vue.use(VueFroala)

import picker from 'vue-3d-picker';
Vue.component(picker.name, picker);

Vue.config.productionTip = false;
import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios)

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
    ak: 'XNXb6GVa4M6Bn8MrFKdKwHRNxDcAgzNG' //key
})

import 'vue-googlemaps/dist/vue-googlemaps.css'
import VueGoogleMaps from 'vue-googlemaps'

Vue.use(VueGoogleMaps, {
    load: {
        //
        apiKey: 'AIzaSyAEkQD4eFoY22jPQOgJYt-elgixjRHv3ww',
        libraries: ['places'],
        useBetaRenderer: false
    },
})



import apiFn from './shared/request/apiFunctionCalls';
Vue.prototype.apiFn = apiFn
// all public function
import utilityFn from './shared/utilityFunctions';
Vue.prototype.utilityFn = utilityFn

// samplin Plan functions
import samPlan from './shared/workorder/sampling-plan';
Vue.prototype.samPlan = samPlan

import report from './shared/workorder/report';
Vue.prototype.report = report

// all api url addressnpm
import apiList from './shared/request/apiList.js'
Vue.prototype.apiList = apiList

import msgList from './shared/messages/messages';
Vue.prototype.msgList = msgList;

import sectionHelper from './shared/workorder/section-helper';
Vue.prototype.sectionHelper = sectionHelper
// http interceptor
import interceptorsSetup from  './shared/request/interceptor';
interceptorsSetup();

import signature from 'vue-fast-signature'
Vue.use(signature)

import VueSignaturePad from 'vue-signature-pad';
Vue.use(VueSignaturePad);
//vant library
import Vant from 'vant';

import { Lazyload } from 'vant';

import 'vant/lib/index.css';
Vue.use(Vant);
//vue-awesome

import 'vue-awesome/icons';
import Icon from 'vue-awesome/components/Icon.vue';

// globally (in your main .js file)
Vue.component('icon', Icon)
// filter need to move it to seperate file
Vue.filter('dateFormat', function(value, format) {
  if (!value) return '';
  return moment.utc(value).local().format(format);
})


Vue.prototype.$msalInstance = {};
//Vue.prototype.$emitter = new Emitter();


// eslint-disable-next-line
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

