<template>
    <div name="Product">
        <v-row>
            <v-col cols="6" sm="6" md="6">
                <v-btn id="product_emailButton" small @click="email(workOrderDetails.workOrderOwnerEmail)" auto-id="product email button">
                    <v-icon >email</v-icon>
                </v-btn>
                <v-btn id="product_callButton" small @click="call(workOrderDetails.workOrderOwnerPhone)" style="margin-left: 10px;" auto-id="product call button">
                    <v-icon >phone</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="6 " sm="6" md="6" style="text-align:right;"  v-if="workOrderDetails.resources.length > 1 && isAuthorized">
                <v-btn id="product_submitButton" :disabled="isSectionSubmitted()" @click="submit_item()" class="submit-btn" small depressed color="#1867c0" auto-id="product submit button">Submit</v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6" sm="6" md="6">
                <v-text-field v-model="workOrderDetails.colors" @change="changeProductColorOrSizeRange()" label="Color(s)" maxLength="100" auto-id="product colors"></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="6">
                <v-text-field v-model="workOrderDetails.sizeRange" @change="changeProductColorOrSizeRange()" label="Size Range" maxLength="100" auto-id="product size range"></v-text-field>
            </v-col>
        </v-row>

        <v-expansion-panels :style="isAuthorized ? '':'margin:24px 0 8px'">
            <v-expansion-panel v-for="(item, index) in workOrderDetails.products"  :key = index>
                <v-expansion-panel-header :id="'product_' + index" expand-icon="mdi-menu-down" :auto-id="'product'+index">{{ item.productDescription }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                        <v-col cols="6" sm="6" md="6">
                            <v-text-field :id="'product_sku_' + index" :value="item.skuDisplay" label="SKU" readonly></v-text-field>
                        </v-col>
                        <v-col cols="5" sm="5" md="5">
                            <v-text-field :id="'product_ponumber_' + index" :value="item.po" label="PO#" readonly></v-text-field>
                        </v-col>
                        <v-col cols="1" sm="1" md="1">
                            <label style="cursor: pointer;" :id="'product_camerabutton_' + index" @click.stop="go_upload_product_photo(item)" :auto-id="'product camera button'+index">
                                    <icon name="camera"></icon>
                            </label>
                        </v-col>
                        <v-col cols="6" sm="6" md="6">
                            <v-text-field :id="'product_category' + index" :value="item.productCategory" label="Product Category" readonly></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="6" md="6">
                            <v-text-field :id="'product_subcategory' + index" :value="item.productSubCategory" label="Product Sub-Category" readonly></v-text-field>
                        </v-col>                        
                        <v-col cols="6" sm="6" md="6">
                            <v-text-field :id="'product_stylemodelitem_' + index" :value="combineModelStyle(item.model,item.style)" label="Style/Model/Item" :auto-id="'Style/Model/Item'+index"></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="6" md="6">
                            <v-text-field :id="'product_designcolors_' + index" :value="item.color" label="Design/Color(s)" :auto-id="'Design/Colors'+index"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            Size Range：
                            <input :id="'product_length_' + index" :value="item.length" style="width:70px;margin-right:15px;border:1px #ccc solid;" type="text" :auto-id="'product length'+index"/>
                            <input :id="'product_width_' + index" :value="item.width" style="width:70px;margin-right:15px;border:1px #ccc solid;" type="text" :auto-id="'product width'+index"/>
                            <input :id="'product_height_' + index" :value="item.height" style="width:70px;margin-right:15px;border:1px #ccc solid;" type="text" :auto-id="'product height'+index"/>
                            <input :id="'product_dimensionalunit_' + index" :value="item.dimensionalUnit" style="width:70px;margin-right:15px;border:1px #ccc solid;" placeholder="Unit" type="text" :auto-id="'product dimensional unit'+index"/>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-dialog v-model="showProgressDialog" :persistent="true">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                    <v-progress-linear
                        :value="uploadProgress"
                        color="light-blue"
                        height="25"
                        reactive
                        >
                            <template v-slot="{ value }">
                                <strong>{{ uploadFileName }} {{ Math.ceil(value) }}%</strong>
                            </template>
                    </v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import {mapState} from 'vuex';
import apiDetails from '../../shared/request/apiDetails';

export default {
    name: 'Product',
    data (){
        return {
            showProgressDialog: false,
            uploadProgress: 0,
            uploadFileName: '',
            sectionId: 10
        }
    },
    computed: {
        ...mapState('inspection', {
            myAuthorized: state => state.myAuthorized,
            sectionData: state => state.sectionData,
            workOrderDetails: (state) => state.workOrderDetails,
        }),
    },
    mounted () {
    },
    methods: {
        getProductIndex(index){
            return "product_sku_" + index
        },
        go_upload_photo () {
            this.$router.push({name: 'uploadPhotoInspectionId'})
        },
        call (number) {
            window.location.href = "tel:" + number;
        },
        email (address) {
            window.location.href = "mailto:" + address;
        },
        // 计算product中的 style/model/item
        combineModelStyle(model, style) {
            let tempStyle = style == null ? "" : style;
            let tempModel = model == null ? "" : model;
            return tempStyle + ' ' + tempModel;
        },
        // 提交模块的数据
        submit_item() {
            const index = this.workOrderDetails.workOrderInspectionSections.findIndex(x => x.sectionId === this.sectionId);
            if (index !== -1) {
                const section = {
                    sectionId: 10,
                    workOrderId: this.$route.params.id
                }
                this.utilityFn.getServerData('post', this.apiList.submitInspectionSection, section, res => {
                    this.workOrderDetails.workOrderInspectionSections[index].submittedDate = new Date();

                    this.sectionData.Product.isSectionSubmitted = true;
                    this.$store.dispatch("inspection/setWorkOrderDetails", this.workOrderDetails);
                    this.$store.dispatch('inspection/setSectionData', this.sectionData);
                    this.$store.dispatch('inspection/editMyAuthorized', {id:10, key:'write', value:false});
                });
            }
        },
        go_upload_product_photo(item) {
            this.$router.push({ name: 'uploadPhotoGalleryId', params: { id: this.$route.params.id }, query: { workOrderProductId: item.id, uploadType: 'ProductUpload', name: 'Product', sectionId: 10 ,productName:item.productDescription} })
        },
        isSectionSubmitted() {
            const section = this.workOrderDetails.workOrderInspectionSections.find(x => x.sectionId === this.sectionId);
            return section && !!section.submittedDate;
        },
        changeProductColorOrSizeRange(){
            var data = {
                WorkOrderId: this.$route.params.id,
                Colors: this.workOrderDetails.colors,
                SizeRange: this.workOrderDetails.sizeRange
            };
            this.utilityFn.getServerData('post', this.apiList.workOrderDetailsColorAndSizeRangeUpdate, data, result => {
                if (result){
                    console.log('color size range success save');
                }
            });
        }
    },
    props: ['workOrderDetails', 'isAuthorized']
};
</script>

<style lang="stylus" scoped>
    .submit-btn
        color: #fff !important
</style>
