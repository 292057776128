
import axios from 'axios'
import apiDetails from '../request/apiDetails';
import apiList from "../request/apiList";

let onSiteTestResult = {}

onSiteTestResult.upsertWorkOrderOnSiteTests = async function (data) {
	let dataResult
	await axios.post(apiList.baseUrl + '/api/AIMS/UpsertWorkOrderOnSiteTests/', data	
	,{
		headers:apiDetails.header.headersDetail,
	}).then((response)=>{
		dataResult = response.data;
	}).catch((error)=>{
		console.log(error);
	})

	return dataResult;
}
 
onSiteTestResult.GetWorkInstructionOnsiteTests = async function(wiid) {
    let dataResult;
    await axios.get(apiList.baseUrl + '/api/AIMS/GetWorkInstructionOnsiteTests/' + wiid , {
		headers:apiDetails.header.headersDetail,
	}).then((response)=>{
		dataResult = response.data;
	}).catch((error)=>{
		console.log(error);
	})

	return dataResult;
}
onSiteTestResult.GetAllWorkInstructionOnsiteTests = async function(wiid) {
    let dataResult;
    await axios.get(apiList.baseUrl + '/api/AIMS/GetAllWorkInstructionOnsiteTests/' , {
		headers:apiDetails.header.headersDetail,
	}).then((response)=>{
		dataResult = response.data;
	}).catch((error)=>{
		console.log(error);
	})

	return dataResult;
}
onSiteTestResult.GetAllWorkInstruction = async function(workInstruction) {
    let dataResult;
    // await axios.get(apiList.baseUrl + '/api/AIMS/GetAllWorkInstruction/?searchtext=' + workInstruction , {
	await axios.get(apiList.baseUrl + '/api/AIMS/GetAllWorkInstruction/?searchtext=' + workInstruction , {
		headers:apiDetails.header.headersDetail,
	}).then((response)=>{
		dataResult = response.data;
	}).catch((error)=>{
		console.log(error);
	})

	return dataResult;
}
onSiteTestResult.UpsertWorkInstruction = async function(workOrderId,workinstruction,sectionId) {
    await axios.patch(apiList.baseUrl + '/api/AIMS/PatchWorkOrderResults/' + workOrderId + '/' + sectionId, [
        { "op": "replace", "path": "/workinstruction", "value": workinstruction }
    ], {
        headers:apiDetails.header.headersDetail,
    }).then((response) => {
        console.log(response)
    }).catch((error) => {
        console.log(error);
    })

}
export default onSiteTestResult