<template>
  <div name="Supplier">
    <v-row>
      <v-col v-if="check || showButton" cols="12 " sm="12" md="12" style="text-align:right;">
        <v-btn v-if="showButton && sectionData.isAuthorized" :disabled="sectionData.isSectionSubmitted" class="submit-btn" @click="submit_item" small depressed color="#1867c0">Submit</v-btn>
      </v-col>
      <v-col cols="6" sm="6" md="6">
        <v-text-field hide-details :value="generalData.supplier" label="Supplier Name" readonly></v-text-field>
      </v-col>
      <v-col cols=6 sm="6" md="6">
        <v-text-field hide-details :value="generalData.supplierCode" label="Supplier Code" readonly></v-text-field>
      </v-col>
      <v-col cols=12 sm="12" md="12">
        <v-text-field hide-details :value="generalData.supplierAddress" label="Supplier Address" readonly></v-text-field>
      </v-col>
      <v-col cols="6" sm="6" md="6">
        <v-text-field hide-details :value="generalData.factory" label="Manufacturer/Site" readonly></v-text-field>
      </v-col>
      <v-col cols=6 sm="6" md="6">
        <v-text-field hide-details :value="generalData.factoryCode" label="Manufacturer/Site Code" readonly></v-text-field>
      </v-col>
      <v-col cols=12 sm="12" md="12">
        <v-text-field hide-details :value="generalData.factoryAddress" label="Manufacturer/Site Address" readonly></v-text-field>
      </v-col>
      <v-col cols="6" sm="6" md="6">
        <v-text-field hide-details :value="generalData.siteState" label="Province/State" readonly></v-text-field>
      </v-col>
      <v-col cols="6" sm="6" md="6">
        <v-text-field hide-details :value="generalData.siteCity" label="City" readonly></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-text-field hide-details :value="generalData.siteCountry" label="Country" readonly></v-text-field>
      </v-col>

      <v-col cols="12" sm="12" md="12">
        <v-text-field hide-details :value="generalData.siteAddress" label="Inspection Location" readonly></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12" v-if="online">
        <v-text-field hide-details v-model="searchAddress" label="" append-icon="search" placeholder="search or pin address here" @click:append="search_map(searchAddress)"></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12" v-if="generalData.siteCountry == 'China'">
         <baidu-map class="map" :center="generalData.localName" @ready="handler" :zoom="12" :scroll-wheel-zoom="true">
            <bm-local-search :keyword="generalData.localName" :auto-viewport="true" zoom="12.8" style="display: none"></bm-local-search>
          </baidu-map>
      </v-col>
      <v-col cols="12" sm="12" md="12" v-if="generalData.siteCountry != 'China'">
        <div id="allmap" ref="allmap" style="width:100%; height:400px;"></div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState } from "vuex";
import * as moment from "moment";
import apiDetails from "../../shared/request/apiDetails";

export default {
  name: "Supplier",
  data() {
    return {
      // 当前模块id
      sectionId: 8,

      workOrderSectionList: [],
      baiduCenter: {
        lng: 0,
        lat: 0,
      },
      markers: [
        {
          position: { lat: 18.9969046, lng: 72.8162497 },
        },
      ],
      place: null,
     // sectionId: 8,
      showButton: false,
      //online: true,
      searchAddress: "",
    };
  },
  computed: {
    ...mapState("inspection", {
      online: (state) => state.online,
    }),
     check() {
      this.showButton = this.generalData.hideButton;
    },
  },
  mounted() {
    this.searchAddress = this.generalData.siteAddress;
    this.loadData();
  },
  methods: {
    loadData() {
      this.workOrderSectionList = [];
      var other = {
        headers: {
          wresult: this.store_token,
          "Content-Type": "application/json",
        },
      };
      this.utilityFn.getServerData("get", this.apiList.sectionList, {}, (response) => {
        this.sections = response.data.data;
        this.utilityFn.getServerData("get", this.apiList.workOrderSectionList + "/" + this.$route.params.id, {}, (response2) => {
          const woSections = response2.data.data;
          this.sections.forEach((section) => {
            // this.submit_item()
            const index = woSections.findIndex((x) => x.sectionId == section.id);
            if (index != -1) {
              this.workOrderSectionList.push({
                id: woSections[index].id,
                workOrderId: woSections[index].workOrderId,
                sectionId: woSections[index].sectionId,
                sectionName: section.sectionName,
                isHidden: woSections[index].isHidden,
                resourceId: woSections[index].resourceId,
                lastModifiedDate: woSections[index].lastModifiedDate,
                result: woSections[index].result,
                workInstructionName: woSections[index].workInstructionName,
                findings: woSections[index].findings,
                submittedDate: woSections[index].submittedDate,
                isResourceReAssign: woSections[index].isResourceReAssign,
              });
            } else {
              this.workOrderSectionList.push({
                id: null,
                workOrderId: this.$route.params.id,
                sectionId: section.id,
                sectionName: section.sectionName,
                isHidden: false,
                resourceId: null,
                lastModifiedDate: null,
                result: null,
                workInstructionName: null,
                findings: null,
                submittedDate: null,
                isResourceReAssign: null,
              });
            }
          });
        });
      });
    },
    // 谷歌地图搜索位子
    search_map(address) {

      console.log('[DEBUG] search_map', {
        address,
        google: window.google
      })
      if (address == undefined) {
        address = "美国";
      }

      if (window.google) {
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: address }, (results, status) => {
          if (status == google.maps.GeocoderStatus.OK) {
            var lat = results[0].geometry.location.lat();
            var lng = results[0].geometry.location.lng();
            this.maps = new google.maps.Map(document.getElementById("allmap"), {
              zoom: 15,
              center: { lat: lat, lng: lng },
              disableDefaultUI: false,
              zoomControl: false,
            });
            var marker = new google.maps.Marker({ position: { lat: lat, lng: lng }, map: this.maps });
          } else {
            console.log("Geocode was not successful for the following reason: " + status);
          }
        });
      }
    },
    handler({ BMap, map }) {
      this.baiduCenter.lng = 116.404;
      this.baiduCenter.lat = 39.915;
    },
    //
    formatDate: function(date) {
      return moment(date).format("YYYY-MM-DD");
    },

    //
    submit_item() {
      var other = {
        headers: {
          wresult: this.store_token,
          "Content-Type": "application/json",
        },
      };
      const section = {
        sectionId: this.sectionId,
        workOrderId: this.$route.params.id,
      };
      this.utilityFn.getServerData(
        "post",
        this.apiList.submitInspectionSection,
        section,
        (res) => {
          this.sectionData.isSectionSubmitted = true;
        },
        other
      );
    },
  },
  // computed: {
  //   check() {
  //     this.showButton = this.generalData.hideButton;
  //   },
  // },
  props: {
    generalData: Object,
    sectionData: Object,
  },

  watch: {
    generalData: {
      immediate: true,
      deep: true,
      handler(val) {
        if (this.generalData.siteCountry != 'China') {
          this.search_map(this.generalData.siteAddress);
        }
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.map
    width: 100%;
    height: 450px;
.submit-btn
    color: #fff !important
</style>
