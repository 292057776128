<template>
    <sidebar v-model="sidebarStatus" :enable="enableSidebar">
        <!-- sidebar 内容部分 -->
        <div class="app-sidebar-content">
            <!-- 头部 -->
            <div v-if="title" class="app-sidebar-title" @click.stop="closeAndGo('/')">
                <span class="app-sidebar-title-left-icon">
                    <img v-if="title.imageLeft" :src="title.imageLeft" :alt="title.altLeft" />
                    <v-icon color="white" v-else-if="title.iconLeft">{{ title.iconLeft }}</v-icon>
                </span>
                <span>{{ title.text }}</span>
                <slot name="logo" class="app-sidebar-title-right-logo">
                    <span class="app-sidebar-title-right-logo">
                        <img v-if="title.imageRight" :src="title.imageRight" :alt="title.altRight" />
                        <v-icon v-else-if="title.iconRight">{{ title.iconRight }}</v-icon>
                    </span>
                </slot>
            </div>
            <!-- 用户信息 -->
            <div v-if="user" class="app-sidebar-user">
                <div class="user-avatar">
                    <v-icon class="user-avatar-icon">face</v-icon>
                </div>
                <div class="user-info">
                    <div class="user-name">
                        <v-icon>person</v-icon>{{user.name}}
                    </div>
                    <div class="user-location">
                        <v-icon>room</v-icon>{{user.location}}
                    </div>
                    <div class="user-email">
                        <v-icon>email</v-icon>{{user.email}}
                    </div>
                </div>
            </div>

            <!-- 导航列表分区块 -->
            <div v-if="blocks" class="app-sidebar-blocks">
                <ul>
                    <!-- 单个区块 -->
                    <li v-for="(block, index) in blocks" :key="index" class="app-sidebar-block">
                        <div v-if="block.sublistTitle" class="sub-list-title">{{ block.sublistTitle }}</div>
                        <ul v-if="block.list">
                            <li v-for="item in block.list" 
                                :key="item.text" 
                                @click.stop="closeAndGo(item.route)" 
                                v-show="!(item.text == 'Preview Form' || item.text == 'Abort' || item.text == 'Split Work Order' || item.text == 'CFR Draft Report - Detail Summary' || item.text == 'CFR Draft / Short Report' || item.text == 'Email Draft Report' || item.text == 'WEEE Data' || item.text == 'Retailer Notification Template') || $route.name == 'workOrderDetailsId'
                            || $route.name == 'workOrderInspectionQuantityId'
                            || $route.name == 'workOrderInspectionSizeId'
                            || $route.name == 'workOrderInspectionPackageId'
                            || $route.name == 'workOrderInspectionBarcodeId'
                            || $route.name == 'workOrderInspectionWorkmanshipId'
                            || $route.name == 'workOrderInspectionOnsiteId'
                            || $route.name == 'workOrderRemarksId'
                            || $route.name == 'uploadPhotoGeneralId'
                            || $route.name == 'workOrderDefectId'
                            || $route.name == 'workOrderDefectEditId'
                            || $route.name == 'workOrderDefectAddDefectPhoto'
                            || $route.name == 'uploadPhotoInspectionId'
                            || $route.name == 'dynamicFormId'
                            ">
                                <span v-if="item.icon || item.image" class="app-sidebar-block-left-icon">
                                    <img v-if="item.image" :src="item.image" :alt="item.alt" />
                                    <v-icon v-else-if="item.icon">{{ item.icon }}</v-icon>
                                </span>
                                <span :id="item.id" v-if="item.text" class="app-sidebar-block-text">{{ item.text
                                }}</span>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <report-preview :showDialog.sync="showReportPreview" :reportData="reportData"></report-preview>
            <email-report-dialog :showDialog.sync="showemailReportDialog" :data="emailReportData"></email-report-dialog>
            <notification-template-dialog :showDialog.sync="showNotificationTemplateDialog" :workOrderId="workOrderDetails?.workOrderDisplayId || workOrderDetails?.workOrderDisplayID"></notification-template-dialog>
            <!-- <report-view :showDialog.sync="showReportview" :reportData="reportDraftShortData" ></report-view> -->


        </div>
    </sidebar>
</template>

<script>
import { mapState } from 'vuex';
import Sidebar from './Sidebar';
import ReportPreview from "@/components/reports/ReportPreview";
import EmailReportDialog from "@/components/reports/EmailReportDialog";
import CfrDraftShortReport from "@/components/workorder/CfrDraftShortReport";
import ReportView from "@/components/reports/ReportView";
import * as FileSaver from 'file-saver-es';
import { PublicClientApplication } from '@azure/msal-browser';
import NotificationTemplateDialog from "@/components/reports/NotificationTemplateDialog";

export default {
    components: {
        Sidebar,
        ReportPreview,
        EmailReportDialog,
        NotificationTemplateDialog
    },
    data() {
        return {
            showReportPreview: false,
            showReportview: false,
            reportData: {},
            reportDraftShortData: {},

            showemailReportDialog: false,
            emailReportData: {},
            blocksItemRemoveSplit: [
                {
                    sublistTitle: 'Menu',
                    list: [
                        {
                            id: 'profile',
                            text: 'Profile',
                            icon: 'account_circle',
                            route: '/profile'
                        },
                        {
                            id: 'cftDraftReport',
                            text: 'CFR Draft Report - Detail Summary',
                            icon: 'mdi-file-eye',
                            route: '/cfr-preview-report'
                        },
                        {
                            id: 'cftDraftShortReport',
                            text: 'CFR Draft / Short Report',
                            icon: 'mdi-file-eye',
                            route: '/cfr-draft-short-report'
                        },
                        {
                            id: 'WEEEData',
                            text: 'WEEE Data',
                            icon: 'mdi-file-excel',
                            route: '/wee-data-download'
                        },
                        {
                            id: 'cftEmailDraftReport',
                            text: 'Email Draft Report',
                            icon: 'mdi-email',
                            route: '/email-draft-report'
                        },
                        {
                            id: 'notificationTemplate',
                            text: 'Retailer Notification Template',
                            icon: 'event',
                            alt: 'mood-bad',
                            route: '/notification-template'
                        },
                        {
                            id: 'previewForm',
                            text: 'Preview Form',
                            icon: 'event',
                            alt: 'mood-bad',
                            route: '/preview'
                        },
                        {
                            id: 'abort',
                            text: 'Abort',
                            icon: 'assignment_return',
                            alt: 'mood-bad',
                            route: '/abort'
                        },
                        {
                            id: 'settings',
                            text: 'Settings',
                            icon: 'settings',
                            route: '/setting'
                        },
                        {
                            id: 'logout',
                            text: 'Logout',
                            icon: 'power_settings_new',
                            route: '/login'
                        }
                    ]
                }
            ],
            blocksItemWithSplit: [
                {
                    sublistTitle: 'Menu',
                    list: [
                        {
                            id: 'profile',
                            text: 'Profile',
                            icon: 'account_circle',
                            route: '/profile'
                        },
                        {
                            id: 'cftDraftReport',
                            text: 'CFR Draft Report - Detail Summary',
                            icon: 'mdi-file-eye',
                            route: '/cfr-preview-report'
                        },
                        {
                            id: 'cftDraftShortReport',
                            text: 'CFR Draft / Short Report',
                            icon: 'mdi-file-eye',
                            route: '/cfr-draft-short-report'
                        },
                        {
                            id: 'WEEEData',
                            text: 'WEEE Data',
                            icon: 'mdi-file-excel',
                            route: '/wee-data-download'
                        },
                        {
                            id: 'cftEmailDraftReport',
                            text: 'Email Draft Report',
                            icon: 'mdi-email',
                            route: '/email-draft-report'
                        },
                        {
                            id: 'notificationTemplate',
                            text: 'Retailer Notification Template',
                            icon: 'event',
                            alt: 'mood-bad',
                            route: '/notification-template'
                        },
                        {
                            id: 'previewForm',
                            text: 'Preview Form',
                            icon: 'event',
                            alt: 'mood-bad',
                            route: '/preview'
                        },
                        {
                            id: 'splitWorkOrderForm',
                            text: 'Split Work Order',
                            icon: 'call_split',
                            route: '/split-work-order-form'
                        },
                        {
                            id: 'abort',
                            text: 'Abort',
                            icon: 'assignment_return',
                            alt: 'mood-bad',
                            route: '/abort'
                        },
                        {
                            id: 'settings',
                            text: 'Settings',
                            icon: 'settings',
                            route: '/setting'
                        },
                        {
                            id: 'logout',
                            text: 'Logout',
                            icon: 'power_settings_new',
                            route: '/login'
                        }
                    ]
                }
            ],
            blocks: [],

            showNotificationTemplateDialog: false
        }
    },
    computed: {
        ...mapState('navigation', {
            show: (state) => state.sidebar.show,
            title: (state) => state.sidebar.title,
            user: (state) => state.sidebar.user,
            //blocks:(state) => state.sidebar.blocks
        }),
        sidebarStatus: {
            get() {
                return this.show;
            },
            set(val) {
                this.splitWorkOrderHideAndShow();
                this.removeNonWalmartWEEData();
                this.removeNonWalmartNotificationTemplate();
                if (val) {
                    this.$emit('show-sidebar');
                }
                else {
                    this.$emit('hide-sidebar');
                }
            }
        },
        enableSidebar() {
            return this.$store.getters['navigation/showSideBar'];
        },
        ...mapState('inspection', {
            _getJobList: state => state.jobList,
            workOrderDetails: state => state.workOrderDetails
        }),
    },
    async created() {
        this.$msalInstance = new PublicClientApplication(
            this.$store.state.msalConfig,
        );
    },
    mounted() {
        if (this.blocks.length == 0) {
            this.blocks = this.blocksItemWithSplit
        }
    },
    methods: {
        async logout() {


            localStorage.removeItem("tokenInfo");
            //force set to true to refresh dynamic work order if logout
            this.$store.dispatch("inspection/setDynamicFormForceRefresh", true);

            //await this.$msalInstance.handleRedirectPromise();

            await this.$msalInstance
                .logoutRedirect({})
                .then(() => {
                    //this.$emitter.emit('logout', 'logging out');
                })
                .catch(error => {
                    console.error(error);
                });


        },
        isWalmartAbortedReport() {
            let val = false;
            var clientName = this.workOrderDetails.clientName; 
            if (clientName.toUpperCase() == "WALMART GLOBAL SOURCING" || clientName.toUpperCase() == "WALMART GLOBAL SOURCING" || clientName.toLowerCase() == "asda stores limited" || clientName.toLowerCase() == "asda stores limited") {
                if (this.workOrderDetails.workOrderRatingDescription.includes("Abort")) {
                    return true;
                }
            }
            return false;
        },
        downloadWEEEDataExcel() {
            if (this.isWalmartWEEData) {
                let workOrderId = this.workOrderDetails.workOrderId

                this.utilityFn.downloadExcel(
                    this.apiList.downloadWEEEExcel + "/" + workOrderId,
                    (res) => {
                        var blob = new Blob([res.data], { type: 'application/octet-stream' });
                        FileSaver.saveAs(blob, "WEEE Data.xlsx");
                    },
                    {}
                );
            }

        },
        isWalmartWEEData() {
            let val = false;
            if (this.workOrderDetails.retailMarket && (this.workOrderDetails.retailMarket.fieldValue == "WAL-MART ASDA" || this.workOrderDetails.retailMarket.fieldValue == "ASDA")) {
                return true;
            }
            return false;
        },
        splitWorkOrderHideAndShow() {
            let hideShow = false
            hideShow = this.workOrderDetails.dateSubmitted && this.workOrderDetails.status != "Rework" ? false : true;
            if (!hideShow) {
                this.blocks = this.blocksItemRemoveSplit
            } else {
                this.blocks = this.blocksItemWithSplit
            }
            return hideShow


        },
        removeNonWalmartWEEData() {
            let hide = false
            const index = this.blocks[0].list.findIndex((x) => x.id === "WEEEData");
            hide = !this.isWalmartWEEData();
            if (hide) {
                if (index != -1) {
                    this.blocks[0].list.splice(index, 1)
                }
            } else {
                if (index == -1) {
                    const wee = {
                        id: 'WEEEData',
                        text: 'WEEE Data',
                        icon: 'mdi-file-excel',
                        route: '/wee-data-download'
                    }
                    this.blocks[0].list.splice(3, 0, wee);
                }
            }
        },
        removeNonWalmartNotificationTemplate() {
            const index = this.blocks[0].list.findIndex((x) => x.id === "notificationTemplate");
            var clientName = this.workOrderDetails.clientName;
            let isWalmartOrAsda = clientName.toUpperCase() == "WALMART GLOBAL SOURCING" || clientName.toUpperCase() == "WALMART GLOBAL SOURCING" || clientName.toUpperCase() == "ASDA STORES LIMITED" || clientName.toUpperCase() == "ASDA STORES LIMITED";
            if (!isWalmartOrAsda) {
                if (index != -1) {
                    this.blocks[0].list.splice(index, 1)
                }
            } 
            else {
                if (index == -1) {
                    const notificationTemplate = {
                        id: 'notificationTemplate',
                        text: 'Retailer Notification Template',
                        icon: 'event',
                        route: '/notification-template'
                    };
                    this.blocks[0].list.splice(5, 0, notificationTemplate);
                }
            }
        },

        close() {
            this.sidebarStatus = false;
        },
        closeAndGo(route) {
            if (route === '/login') {
                this.logout();

                this.$store.dispatch('inspection/setLoggedIn', false);
                this.$store.dispatch('inspection/setResourceName', '');
            }
            if (route == '/preview' || route == '/abort' || route == '/split-work-order-form') {
                if (route == '/preview') {
                    this.$router.push({ name: 'previewId', params: { id: this.$route.params.id }, query: { id: this.$route.query.displayId } });
                }
                else if (route == '/split-work-order-form') {
                    this.$router.push({ name: 'splitWorkOrderForm', params: { id: this.$route.params.id }, query: { id: this.$route.query.displayId } });
                }
                else {
                    this.$router.push({ name: 'pastInspectionsSignOff', params: { failCode: 'abort' }, query: { id: this.$route.params.id, displayId: this.$route.query.displayId } });
                }
            }
            else if (route == '/cfr-preview-report') {
                if (this.workOrderDetails) {
                    this.reportData = {
                        workOrderId: this.workOrderDetails.workOrderId,
                        workOrderDisplayId: this.workOrderDetails.workOrderDisplayId,
                        clientId: this.workOrderDetails.clientId || this.workOrderDetails.companyId,
                        clientName: this.workOrderDetails.clientName || this.workOrderDetails.companyName,
                        reportNumber: this.workOrderDetails.reportNumber,
                        workOrderClassId: this.workOrderDetails.workOrderClassId,
                        industryId: this.workOrderDetails.industryId,
                        workOrderDestinations: this.workOrderDetails.workOrderDestinations,
                        status: this.workOrderDetails.status || this.workOrderDetails.workOrderStatus,
                        rating: this.workOrderDetails.workOrderRatingDescription,
                        reportTypeId: this.isWalmartAbortedReport() ? 2 : null
                    };
                    this.showReportPreview = true;
                }
            }
            else if (route == '/cfr-draft-short-report') {
                if (this.workOrderDetails && this.isWalmartAbortedReport()) {
                    this.reportData = {
                        workOrderId: this.workOrderDetails.workOrderId,
                        workOrderDisplayId: this.workOrderDetails.workOrderDisplayId,
                        clientId: this.workOrderDetails.clientId || this.workOrderDetails.companyId,
                        clientName: this.workOrderDetails.clientName || this.workOrderDetails.companyName,
                        reportNumber: this.workOrderDetails.reportNumber,
                        workOrderClassId: this.workOrderDetails.workOrderClassId,
                        industryId: this.workOrderDetails.industryId,
                        rating: this.workOrderDetails.workOrderRatingDescription,
                        reportTypeId: 1
                    };
                    this.showReportPreview = true;
                }
            }
            else if (route == '/email-draft-report') {
                if (this.workOrderDetails) {
                    this.emailReportData = {
                        workOrderId: this.workOrderDetails.workOrderId,
                        workOrderDisplayId: this.workOrderDetails.workOrderDisplayId,
                        email: localStorage.getItem('username')
                    }
                    this.showemailReportDialog = true;
                }
            }
            else if (route == '/wee-data-download') {
                if (this.workOrderDetails) {
                    this.downloadWEEEDataExcel();
                }
            }
            else if (route == '/contact-us') {
                const factoryName = localStorage.getItem('personCompanyFactoryName');
                const coordinationOffice = localStorage.getItem('coordinationOfficeName');
                const userName = localStorage.getItem('username');
                const subject = [factoryName, coordinationOffice, userName].filter(x => x).join(' | ');
                const recepient = localStorage.getItem('ContactEmail');
                var mailToLink = `mailto:${recepient}?subject=${subject}`;
                window.location.href = mailToLink;
            }
            else if (route == '/notification-template') {
                if (this.workOrderDetails) {
                    this.showNotificationTemplateDialog = true;
                }
            }
            else {
                this.$router.push(route);
            }

            this.close();
        },
    },
    watch: {
        $route(to, from) {
            // if navigating to a different page, close the preview dialog if open
            if (this.showReportPreview) {
                this.showReportPreview = false;
            }
        }
    }
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/stylus/variable'
// 左侧触发滑动宽度
$swipe-width = 20px

ul,li
    padding 0
    margin 0
    list-style none
a
    text-decoration none
    color #333

.app-sidebar-content
    &.app-sidebar-content-right
        box-shadow -3px 0 8px 1px rgba(0, 0, 0, 0.4)

        &.app-sidebar-title,
        &.app-sidebar-blocks
            text-align right

    .app-sidebar-title-left-icon,
    .app-sidebar-block-left-icon
        display inline-block
        width ($app-sidebar-left-icon-size + 10)px
        height 100%

        img
            vertical-align middle
            width ($app-sidebar-left-icon-size)px
            height ($app-sidebar-left-icon-size)px

        .material-icons
            font-size ($app-sidebar-left-icon-size)px

    .app-sidebar-block-text
        display inline-block
        height 100%
        vertical-align middle

    .app-sidebar-title-right-logo,
    .app-sidebar-block-right-logo
        float right

        img
            width 20px
            height 20px
            margin-right 10px


    .app-sidebar-title
        color #fff
        padding 0 10px
        font-size 16px
        font-weight bold
        height $app-sidebar-title-height
        line-height $app-sidebar-title-height
        background: $colorPrimaryBlue
        text-align left

    .app-sidebar-user
        padding 0 10px
        font-size 16px
        .user-avatar
            margin 30px auto 0 auto
            height 100px
            width 100px
            i
                font-size 100px
                line-height 100px
                color #666
        .user-info
            padding 20px 0
            text-align center
            border-bottom 1px solid #e0e0e0
            >div
                margin 5px 0
                i
                    font-size 18px
                    margin-right 5px

    .app-sidebar-blocks
        text-align left

        .app-sidebar-block
            padding 10px 0
            border-bottom 1px solid #e0e0e0
            color #333

            .sub-list-title
                height $app-sidebar-nav-height
                line-height $app-sidebar-nav-height
                text-indent ($app-sidebar-left-icon-size)px
                font-weight bold
                color #888

            li
                padding-left 15px
                height $app-sidebar-nav-height
                line-height $app-sidebar-nav-height


                &:last-child
                    border none

            &:last-child
                border-bottom none
</style>
