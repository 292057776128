import MatrixSamplingPlan from '../../assets/data/AllSamplingPlan.json';

var matrixSamplingPlans = MatrixSamplingPlan;

var data = {}

var sampleSizeCodeLetter = matrixSamplingPlans.SampleSizeCodeLetter;
var singleSamplingPlanMatrix = matrixSamplingPlans.SingleSamplingPlan;
var doubleSamplingPlanMatrix = matrixSamplingPlans.DoubleSamplingPlan;

function returnInspectionLevel(aqlLevel) {
    let inspectionLevel = aqlLevel;
    switch (aqlLevel) {
        case 'I':
            inspectionLevel = 'G1'
            break;
        case 'Level I':
            inspectionLevel = 'G1'
            break;
        case 'II':
            inspectionLevel = 'G2'
            break;
        case 'III':
            inspectionLevel = 'G3'
            break;
        default:
            inspectionLevel = aqlLevel
        // code block
    }

    return inspectionLevel
}

function returnSampleSizeCodeLetter(quantity, inspectionLevel) {
    let sampleLetter = null

    if (quantity <= 500000) {
        sampleLetter = sampleSizeCodeLetter.filter(x =>
            (x.Lower <= quantity && quantity <= x.Higher)
            && (x.InspectionLevel == inspectionLevel))
    }

    else {
        sampleLetter = sampleSizeCodeLetter.filter(x =>
            (x.Lower == 500001)
            && (x.InspectionLevel == inspectionLevel))
    }


    if (sampleLetter.length > 0) {
        sampleLetter = sampleLetter[0].Letter
    }
    else {
        sampleLetter = null;
    }

    return sampleLetter;
}

function returnSingleSamplingPlan(letter, aql, quantity,nextLevel = false,totalSkuCount = 0) {
    let singleSamplingPlan = [];
    let singleSampleSize = 0;
    let samplingPlan = {
        sampleSize: 0,
        aC: 0,
        rE: 0
    };

    singleSamplingPlan = singleSamplingPlanMatrix.filter(x => x.BandLetter == letter && x.AQL == aql);
    
    if (nextLevel){
        var nextLetter = letter;
        var index = 1;
        while (nextLetter.charCodeAt(0) < 'R'.charCodeAt(0)){
            nextLetter = String.fromCharCode(singleSamplingPlan[0].BandLetter.charCodeAt(0)+index);
            var nextLevelSampleSize = singleSamplingPlanMatrix.filter(x => x.BandLetter == nextLetter && x.AQL == aql && x.SampleSize != singleSamplingPlan[0].SampleSize);
            if (nextLevelSampleSize.length > 0){
                if (nextLevelSampleSize[0].SampleSize >= totalSkuCount || nextLevelSampleSize[0].BandLetter == 'R'){
                    nextLetter = 'R';
                    singleSamplingPlan = nextLevelSampleSize;
                }
            }
            index++;
        }
    }

    if (singleSamplingPlan.length <= 0) {
        samplingPlan.sampleSize = quantity
        return samplingPlan
    }
    singleSampleSize = singleSamplingPlan[0].SampleSize

    if (singleSampleSize == 0 || singleSampleSize >= quantity) {
        samplingPlan.sampleSize = quantity
        samplingPlan.aC = singleSamplingPlan[0].AC == undefined ? null : singleSamplingPlan[0].AC
        samplingPlan.rE = singleSamplingPlan[0].RE == undefined ? null : singleSamplingPlan[0].RE

        return samplingPlan;
    }

    samplingPlan.sampleSize = singleSamplingPlan[0].SampleSize
    samplingPlan.aC = singleSamplingPlan[0].AC == undefined ? null : singleSamplingPlan[0].AC
    samplingPlan.rE = singleSamplingPlan[0].RE == undefined ? null : singleSamplingPlan[0].RE
    //console.log(samplingPlan);
    return samplingPlan;
}

function returnDoubleSamplingPlan(letter, aql, quantity, round,nextLevel,totalSkuCount = 0) {
    let doubleSamplingPlan = [];
    let samplingPlan = {
        sampleSize: 0,
        aC: 0,
        rE: 0
    };

    doubleSamplingPlan = doubleSamplingPlanMatrix.filter(x => x.BandLetter == letter && x.AQL == aql);

    if (nextLevel){
        var nextLetter = letter;
        var index = 1;
        while (nextLetter.charCodeAt(0) < 'R'.charCodeAt(0)){
            nextLetter = String.fromCharCode(doubleSamplingPlan[0].BandLetter.charCodeAt(0)+index);
            var nextLevelSampleSize = doubleSamplingPlanMatrix.filter(x => x.BandLetter == nextLetter && x.AQL == aql && x.SampleSize != doubleSamplingPlan[0].SampleSize);
            if (nextLevelSampleSize.length > 0){
                if (round == "Second Inspection"){
                    if (nextLevelSampleSize[0].SecondSampleSize >= totalSkuCount){
                        nextLetter = 'R';
                        doubleSamplingPlan = nextLevelSampleSize;
                    }
                }
                if (nextLevelSampleSize[0].FirstSampleSize > totalSkuCount){
                    nextLetter = 'R';
                    doubleSamplingPlan = nextLevelSampleSize;
                }
            }
            index++;
        }
    }
    if (doubleSamplingPlan[0].FirstSampleSize <= 0) {
        doubleSamplingPlan = returnSingleSamplingPlan(letter, aql, quantity,nextLevel,totalSkuCount)
        return doubleSamplingPlan
    }

    if (round == "Second Inspection") {
        samplingPlan.sampleSize = doubleSamplingPlan[0].SecondSampleSize
        samplingPlan.aC = doubleSamplingPlan[0].SecondAC
        samplingPlan.rE = doubleSamplingPlan[0].SecondRE
        return samplingPlan;
    }


    samplingPlan.sampleSize = doubleSamplingPlan[0].FirstSampleSize
    samplingPlan.aC = doubleSamplingPlan[0].FirstAC
    samplingPlan.rE = doubleSamplingPlan[0].FirstRE
    return samplingPlan;

}

function getSampleSizeAcRe(quantity, inspectionLevel, samplingPlan1, aql, round,nextLevel = false, totalSkuCount = 0) {
    let sampleSizeAcRe = {
        sampleSize: 0,
        aC: 0,
        rE: 0
    };
    aql = aql == null ? 0 : aql;

    if (quantity <= 1) {
        sampleSizeAcRe.sampleSize = quantity
        return sampleSizeAcRe;
    }

    inspectionLevel = returnInspectionLevel(inspectionLevel)
    let sampleSizeCodeLetter = returnSampleSizeCodeLetter(quantity, inspectionLevel)

    if (samplingPlan1 == "Double") {
        sampleSizeAcRe = returnDoubleSamplingPlan(sampleSizeCodeLetter, aql, quantity, round,nextLevel,totalSkuCount)
    }
    else {
        sampleSizeAcRe = returnSingleSamplingPlan(sampleSizeCodeLetter, aql, quantity,nextLevel,totalSkuCount)
    }
    return sampleSizeAcRe;
}

function returnProRata(totalSampleSize, totalLotQty, sKULotQty) {
    let proRata = 0;
    proRata = (totalSampleSize / totalLotQty) * sKULotQty
    return proRata;
}

function isShowFloatingButton(samplingMethodology, skuList, samplingPlan1, round) {


    let visibleFabButton = false

    if (skuList.length <= 1) {
        return visibleFabButton = false
    }
    if (samplingMethodology != null) {
        if (samplingPlan1 == "Double" && round != null) {
            return true
        }
        else if (samplingPlan1 == "Single") {
            return visibleFabButton = true
        }
    }
    return visibleFabButton;
}


function storeWorkOrderSampleSizeParameter(samplingMethodology, skuList, samplingPlan1, round, productType) {

    let workOrderSamplingParameter = {}
    workOrderSamplingParameter.skuList = skuList
    workOrderSamplingParameter.samplingMethodology = samplingMethodology
    workOrderSamplingParameter.samplingPlan1 = samplingPlan1
    workOrderSamplingParameter.round = round,
        workOrderSamplingParameter.productType = productType
    localStorage.removeItem('workOrderSamplingParameter');
    localStorage.setItem('workOrderSamplingParameter', JSON.stringify(workOrderSamplingParameter))
}

//get sample size for Separated sampling methodology. Single SKU also uses this since there is no sampling methodology option for single sku
function separatedSampleSize(skuList, inspectionLevel, samplingPlan1, round, aql) {

    let isMultipleSKUMultiplePO = false;
    const uniquePoList = _.uniq(skuList.map(x => x.PO));

    isMultipleSKUMultiplePO = uniquePoList.length > 1 ? true : false

    let separatedSamplingPlanList = {
        SKUSamplingList: [
            {
                id: null,
                workOrderID: null,
                productId: null,
                SKUDisplay: null,
                PO: null,
                productQty: null,
                cartonQty: null,
                productSampleSize: null,
                cartonSampleSize: null,
                proRataProduct: null,
                proRataCarton: null,
                minorAC: null,
                minorRE: null,
                majorAC: null,
                majorRE: null,
                criticalAC: null,
                criticalRE: null,
            }
        ],
        totalProductPOQty: null,
        totalProductSampleSize: null,
        totalCartonPOQty: null,
        totalCartonSampleSize: null,
        showFabButton: false,
        overWriteSampleSizes: false,
        overallMinorAC: null,
        overallMinorRE: null,
        overallMajorAC: null,
        overallMajorRE: null,
        overallCriticalAC: null,
        overallCriticalRE: null,
        totalSampleSizePerSKU: null
    }

    let totalLotQtyProduct = 0
    let totalLotQtyCarton = 0
    let totalSampleSizeProduct = 0
    let totalSampleSizeCarton = 0



    totalLotQtyProduct = skuList.reduce(function (tot, arr) {
        return Number(tot) + Number(arr.productQty);
    }, 0);

    totalLotQtyCarton = skuList.reduce(function (tot, arr) {
        return Number(tot) + Number(arr.cartonQty);
    }, 0);


    skuList.forEach(element => {

        let productQty = element.productQty;
        let cartonQty = element.cartonQty;
        let aqlMinor = element.aqlMinor;
        let aqlMajor = element.aqlMajor;
        let aqlCritical = element.aqlCritical;
        let productSamplingPlan = null
        let cartonSamplingPlan = null

        if (isMultipleSKUMultiplePO == true) {
            let totalProductQuantityPerSKU = getTotalProductQuantityPerSKU(skuList, element.SKUDisplay)
            let totalProductQuantityPerSKUSampleSize = getSampleSizeAcRe(totalProductQuantityPerSKU, inspectionLevel, samplingPlan1, null, round)
            productSamplingPlan = productQty == null ? null : Math.round((parseInt(totalProductQuantityPerSKUSampleSize.sampleSize) / totalProductQuantityPerSKU) * productQty)

            let totalCartonQuantityPerSKU = getTotalCartonQuantityPerSKU(skuList, element.SKUDisplay)
            let totalCartonQuantityPerSKUSampleSize = getSampleSizeAcRe(totalCartonQuantityPerSKU, inspectionLevel, samplingPlan1, null, round)
            cartonSamplingPlan = cartonQty == null ? null : Math.round((parseInt(totalCartonQuantityPerSKUSampleSize.sampleSize) / totalCartonQuantityPerSKU) * cartonQty)

        }
        else {
            const aqlCriticalSelected = aql !== undefined ? aql.aqlCritical : aqlCritical;
            const aqlMinorSelected = aql !== undefined ? aql.aqlMinor : aqlMinor;
            const aqlMajorSelected = aql !== undefined ? aql.aqlMajor : aqlMajor;
            const aqlCriticalVal = +aqlCriticalSelected;
            const aqlMinorVal = +aqlMinorSelected;
            const aqlMajorVal = +aqlMajorSelected;

            let aqlCriticalSamplingPlan = 0;
            let aqlMinorSamplingPlan = 0;
            let aqlMajorSamplingPlan = 0;
            let zeroAQL = 0;
            let isZeroSampleSize = false;

            if(aqlCriticalVal !== 0 && !isNaN(aqlCriticalVal)) {
                aqlCriticalSamplingPlan = productQty == null ? null : (getSampleSizeAcRe(productQty, inspectionLevel, samplingPlan1, aqlCriticalVal, round)).sampleSize;
            }

            if(aqlMinorVal !== 0 && !isNaN(aqlMinorVal)) {
                aqlMinorSamplingPlan = productQty == null ? null : (getSampleSizeAcRe(productQty, inspectionLevel, samplingPlan1, aqlMinorVal, round)).sampleSize;
            }

            if(aqlMajorVal !== 0 && !isNaN(aqlMajorVal)) {
                aqlMajorSamplingPlan = productQty == null ? null : (getSampleSizeAcRe(productQty, inspectionLevel, samplingPlan1, aqlMajorVal, round)).sampleSize;
            }
            
            if((aqlCriticalSelected.toLowerCase() === 'n/a' || aqlCriticalSelected.toLowerCase() === 'not allowed')
            && (aqlMinorSelected.toLowerCase() === 'n/a' || aqlMinorSelected.toLowerCase() === 'not allowed')
            && (aqlMajorSelected.toLowerCase() === 'n/a' || aqlMajorSelected.toLowerCase() === 'not allowed')) {
                isZeroSampleSize = true;
            }
        
            if(aqlCriticalSamplingPlan === 0 && aqlMinorSamplingPlan === 0 && aqlMajorSamplingPlan === 0 && !isZeroSampleSize) {
                zeroAQL = getSampleSizeAcRe(totalLotQtyProduct, inspectionLevel, samplingPlan1, 0, round).sampleSize;
            }
            
            productSamplingPlan = isZeroSampleSize ? 0 : Math.max(aqlCriticalSamplingPlan, aqlMinorSamplingPlan, aqlMajorSamplingPlan, zeroAQL);
            cartonSamplingPlan = cartonQty == null ? null : (getSampleSizeAcRe(cartonQty, inspectionLevel, samplingPlan1, null, round)).sampleSize
        }



        let productSamplingPlanMinor = getSampleSizeAcRe(productQty, inspectionLevel, samplingPlan1, aqlMinor, round)
        let productSamplingPlanMajor = getSampleSizeAcRe(productQty, inspectionLevel, samplingPlan1, aqlMajor, round)

        let productSamplingCritical = getSampleSizeAcRe(productQty, inspectionLevel, samplingPlan1, aqlCritical, round)
        if (aqlCritical == 0) {
            productSamplingCritical.aC = 0;
            productSamplingCritical.rE = 1;
        }




        separatedSamplingPlanList.SKUSamplingList.push({
            id: element.id,
            workOrderID: element.workOrderID,
            productId: element.productId,
            SKUDisplay: element.SKUDisplay,
            PO: element.PO,
            productQty: element.productQty,
            cartonQty: element.cartonQty,
            productSampleSize: productSamplingPlan,
            cartonSampleSize: cartonSamplingPlan,
            proRataProduct: productSamplingPlan,
            proRataCarton: cartonSamplingPlan,
            minorAC: parseInt(productSamplingPlanMinor.aC),
            minorRE: parseInt(productSamplingPlanMinor.rE),
            majorAC: parseInt(productSamplingPlanMajor.aC),
            majorRE: parseInt(productSamplingPlanMajor.rE),
            criticalAC: parseInt(productSamplingCritical.aC),
            criticalRE: parseInt(productSamplingCritical.rE),
        })




    })

    let totalSampleSizePerSKU = getTotalSampleSizePerSKU(separatedSamplingPlanList.SKUSamplingList, inspectionLevel, samplingPlan1, null, round)
    separatedSamplingPlanList.totalSampleSizePerSKU = totalSampleSizePerSKU

    if (isMultipleSKUMultiplePO == true) {
        totalSampleSizeProduct = totalSampleSizePerSKU.reduce(function (tot, arr) {
            return Number(tot) + Math.ceil(Number(arr.totalProductSampleSize));
        }, 0) || 0;

        totalSampleSizeCarton = totalSampleSizePerSKU.reduce(function (tot, arr) {
            return Number(tot) + Math.ceil(Number(arr.totalCartonSampleSize));
        }, 0) || 0;
    }
    else {

        totalSampleSizeProduct = separatedSamplingPlanList.SKUSamplingList.reduce(function (tot, arr) {
            return Number(tot) + Math.ceil(Number(arr.productSampleSize));
        }, 0) || 0;

        totalSampleSizeCarton = separatedSamplingPlanList.SKUSamplingList.reduce(function (tot, arr) {
            return Number(tot) + Math.ceil(Number(arr.cartonSampleSize));
        }, 0) || 0;

    }





    separatedSamplingPlanList.totalProductPOQty = totalLotQtyProduct
    separatedSamplingPlanList.totalCartonPOQty = totalLotQtyCarton
    separatedSamplingPlanList.totalProductSampleSize = totalSampleSizeProduct
    separatedSamplingPlanList.totalCartonSampleSize = totalSampleSizeCarton

    let aqlMinorWorkorder = skuList[0].aqlMinor;
    let aqlMajorWorkorder = skuList[0].aqlMajor;
    let aqlCriticalWorkorder = skuList[0].aqlCritical;

    let overallMinor = getSampleSizeAcRe(totalLotQtyProduct, inspectionLevel, samplingPlan1, aqlMinorWorkorder, round)
    let overallMajor = getSampleSizeAcRe(totalLotQtyProduct, inspectionLevel, samplingPlan1, aqlMajorWorkorder, round)
    let overallCritical = getSampleSizeAcRe(totalLotQtyProduct, inspectionLevel, samplingPlan1, aqlCriticalWorkorder, round)
    if (aqlCriticalWorkorder == 0) {
        overallCritical.aC = 0;
        overallCritical.rE = 1;
    }


    separatedSamplingPlanList.overallMinorAC = overallMinor.aC
    separatedSamplingPlanList.overallMinorRE = overallMinor.rE
    separatedSamplingPlanList.overallMajorAC = overallMajor.aC
    separatedSamplingPlanList.overallMajorRE = overallMajor.rE
    separatedSamplingPlanList.overallCriticalAC = overallCritical.aC
    separatedSamplingPlanList.overallCriticalRE = overallCritical.rE


    separatedSamplingPlanList.SKUSamplingList = separatedSamplingPlanList.SKUSamplingList.slice(1);




    return separatedSamplingPlanList

}


//get sample Size for Combined Sampling Methodology
function combinedSampleSize(skuList, inspectionLevel, samplingPlan1, productType, round, aql = undefined,isFromQv = false) {
    let isMultipleSKUMultiplePO = false;
    const uniquePoList = _.uniq(skuList.map(x => x.PO));

    isMultipleSKUMultiplePO = uniquePoList.length > 1 ? true : false

    let combinedSamplingPlanList = {
        SKUSamplingList: [
            {
                id: null,
                workOrderID: null,
                productId: null, SKUDisplay: null,
                PO: null,
                productQty: null,
                cartonQty: null,
                productSampleSize: null,
                cartonSampleSize: null,
                proRataProduct: null,
                proRataCarton: null,
                minorAC: null,
                minorRE: null,
                majorAC: null,
                majorRE: null,
                criticalAC: null,
                criticalRE: null,
            }
        ],
        totalProductPOQty: null,
        totalProductSampleSize: null,
        totalCartonPOQty: null,
        totalCartonSampleSize: null,
        showFabButton: false,
        overWriteSampleSizes: false,
        overallMinorAC: null,
        overallMinorRE: null,
        overallMajorAC: null,
        overallMajorRE: null,
        overallCriticalAC: null,
        overallCriticalRE: null,
        totalSampleSizePerSKU: null
    }

    let totalLotQtyProduct = 0
    let totalLotQtyCarton = 0
    let totalSampleSizeProduct = 0
    let totalSampleSizeCarton = 0
    let minimumPrincipleProduct = 0
    let minimumPrincipleCarton = 0
    let SKUCount = 0


    totalLotQtyProduct = skuList.reduce(function (tot, arr) {
        return Number(tot) + Number(arr.productQty);
    }, 0);

    totalLotQtyCarton = skuList.reduce(function (tot, arr) {
        return Number(tot) + Number(arr.cartonQty);
    }, 0);



    totalSampleSizeProduct = getProductSampleSize(aql,skuList,totalLotQtyProduct,inspectionLevel,round,samplingPlan1);
    totalSampleSizeCarton = getSampleSizeAcRe(totalLotQtyCarton, inspectionLevel, samplingPlan1, null, round).sampleSize
    SKUCount = skuList.length;

    //Product
    minimumPrincipleProduct = productType == 'Hardlines' ? 10 : productType == 'Softlines' ? 5 : minimumPrincipleProduct

    if (productType == 'Hardlines' && (SKUCount * 10) > totalSampleSizeProduct) {
        minimumPrincipleProduct = 5
    }
    if ((productType == 'Hardlines' || productType == 'Softlines') && (SKUCount * 5) > totalSampleSizeProduct) {
        minimumPrincipleProduct = 3
    }
    if ((productType == 'Hardlines' || productType == 'Softlines') && (SKUCount * 3) > totalSampleSizeProduct) {
        minimumPrincipleProduct = 0
    }

    //Carton
    minimumPrincipleCarton = productType == 'Hardlines' ? 10 : productType == 'Softlines' ? 5 : minimumPrincipleCarton

    if (productType == 'Hardlines' && (SKUCount * 10) > totalSampleSizeCarton) {
        minimumPrincipleCarton = 5
    }
    if ((productType == 'Hardlines' || productType == 'Softlines') && (SKUCount * 5) > totalSampleSizeCarton) {
        minimumPrincipleCarton = 3
    }
    if ((productType == 'Hardlines' || productType == 'Softlines') && (SKUCount * 3) > totalSampleSizeCarton) {
        minimumPrincipleCarton = 0
    }

    let totalQtyNonMinimumSampleSizeSKUProduct = 0
    let totalQtyNonMinimumSampleSizeSKUCarton = 0
    let SKUApplyingMinimumProduct = 0
    let SKUApplyingMinimumCarton = 0

    //ProRata each item
    skuList.forEach(element => {
        let productQty = element.productQty;
        let cartonQty = element.cartonQty;

        let productProRata = returnProRata(totalSampleSizeProduct, totalLotQtyProduct, productQty)
        let cartonProRata = returnProRata(totalSampleSizeCarton, totalLotQtyCarton, cartonQty)


        let productSampleSize = 0
        let cartonSampleSize = 0

        if (productProRata <= minimumPrincipleProduct) {
            productSampleSize = minimumPrincipleProduct
            SKUApplyingMinimumProduct = SKUApplyingMinimumProduct + 1
        }
        else {
            productSampleSize = productProRata
            totalQtyNonMinimumSampleSizeSKUProduct = totalQtyNonMinimumSampleSizeSKUProduct + productQty

        }

        if (cartonProRata <= minimumPrincipleProduct) {
            cartonSampleSize = minimumPrincipleCarton
            SKUApplyingMinimumCarton = SKUApplyingMinimumCarton + 1
        }
        else {
            cartonSampleSize = cartonProRata
            totalQtyNonMinimumSampleSizeSKUCarton = totalQtyNonMinimumSampleSizeSKUCarton + cartonQty
        }
        combinedSamplingPlanList.SKUSamplingList.push({
            id: element.id,
            workOrderID: element.workOrderID,
            productId: element.productId,
            SKUDisplay: element.SKUDisplay,
            PO: element.PO,
            productQty: element.productQty,
            cartonQty: element.cartonQty,
            productSampleSize: Number(productSampleSize),
            cartonSampleSize: Number(cartonSampleSize),
            proRataProduct: Number(productProRata),
            proRataCarton: Number(cartonProRata),
            minorAC: null,
            minorRE: null,
            majorAC: null,
            majorRE: null,
            criticalAC: null,
            criticalRE: null,
        })
    })


    if (isMultipleSKUMultiplePO) {
        combinedSamplingPlanList.SKUSamplingList.forEach(element => {
            if (element.id != null) {
                element.productSampleSize = element.productQty == null ? null : Math.ceil(totalSampleSizeProduct / totalLotQtyProduct * element.productQty)
                element.cartonSampleSize = element.cartonQty == null ? null : Math.round(totalSampleSizeCarton / totalLotQtyCarton * element.cartonQty)
            }
        })
    }
    else {
        if (productType == 'Hardlines' || productType == 'Softlines') {
            combinedSamplingPlanList.SKUSamplingList.forEach(element => {
                if (element.id != null) {
                    let productQty = element.productQty;
                    let cartonQty = element.cartonQty;

                    let sampleSizeNonMinimumSKUProduct = 0
                    let sampleSizeNonMinimumSKUCarton = 0

                    sampleSizeNonMinimumSKUProduct = (totalSampleSizeProduct - (minimumPrincipleProduct * SKUApplyingMinimumProduct)) * (productQty / totalQtyNonMinimumSampleSizeSKUProduct)
                    sampleSizeNonMinimumSKUCarton = (totalSampleSizeCarton - (minimumPrincipleCarton * SKUApplyingMinimumCarton)) * (cartonQty / totalQtyNonMinimumSampleSizeSKUCarton)

                    element.productSampleSize = element.productSampleSize == minimumPrincipleProduct ? minimumPrincipleProduct : sampleSizeNonMinimumSKUProduct
                    element.cartonSampleSize = element.cartonSampleSize == minimumPrincipleCarton ? minimumPrincipleCarton : sampleSizeNonMinimumSKUCarton
                    element.productSampleSize = element.productQty == null ? null : Math.ceil(element.productSampleSize)
                    element.cartonSampleSize = element.cartonQty == null ? null : Math.round(element.cartonSampleSize)
                }
            })

        }

        else {
            combinedSamplingPlanList.SKUSamplingList.forEach(element => {
                if (element.id != null) {
                    element.productSampleSize = element.productQty == null ? null : Math.ceil(element.productSampleSize)
                    element.cartonSampleSize = element.cartonQty == null ? null : Math.round(element.cartonSampleSize)
                }
            })
        }

    }

    let aqlMinorWorkorder = skuList[0].aqlMinor;
    let aqlMajorWorkorder = skuList[0].aqlMajor;
    let aqlCriticalWorkorder = skuList[0].aqlCritical;


    let overallMinor = getSampleSizeAcRe(totalLotQtyProduct, inspectionLevel, samplingPlan1, aqlMinorWorkorder, round)
    let overallMajor = getSampleSizeAcRe(totalLotQtyProduct, inspectionLevel, samplingPlan1, aqlMajorWorkorder, round)
    let overallCritical = getSampleSizeAcRe(totalLotQtyProduct, inspectionLevel, samplingPlan1, aqlCriticalWorkorder, round)
    if (aqlCriticalWorkorder == 0) {
        overallCritical.aC = 0;
        overallCritical.rE = 1;
    }

    combinedSamplingPlanList.totalProductPOQty = totalLotQtyProduct
    combinedSamplingPlanList.totalCartonPOQty = totalLotQtyCarton
    combinedSamplingPlanList.totalProductSampleSize = totalSampleSizeProduct
    combinedSamplingPlanList.totalCartonSampleSize = totalSampleSizeCarton

    combinedSamplingPlanList.overallMinorAC = overallMinor.aC
    combinedSamplingPlanList.overallMinorRE = overallMinor.rE
    combinedSamplingPlanList.overallMajorAC = overallMajor.aC
    combinedSamplingPlanList.overallMajorRE = overallMajor.rE
    combinedSamplingPlanList.overallCriticalAC = overallCritical.aC
    combinedSamplingPlanList.overallCriticalRE = overallCritical.rE

    combinedSamplingPlanList.SKUSamplingList = combinedSamplingPlanList.SKUSamplingList.slice(1);
    let totalSampleSizePerSKU = getTotalSampleSizePerSKU(combinedSamplingPlanList.SKUSamplingList, inspectionLevel, samplingPlan1, null, round)
    combinedSamplingPlanList.totalSampleSizePerSKU = totalSampleSizePerSKU

    let sumSampleSizeProduct = combinedSamplingPlanList.SKUSamplingList.reduce(function (tot, arr) {
        return Number(tot) + Number(arr.productSampleSize);
    }, 0);

    sumSampleSizeProduct = isNaN(sumSampleSizeProduct) ? 0 : sumSampleSizeProduct
    if (totalSampleSizeProduct < combinedSamplingPlanList.SKUSamplingList.length && isFromQv){
        totalSampleSizeProduct = getProductSampleSize(aql,skuList,totalLotQtyProduct,inspectionLevel,samplingPlan1,round,totalSampleSizeProduct,combinedSamplingPlanList.SKUSamplingList.length);
    }
    combinedSamplingPlanList.SKUSamplingList = sumSampleSizeProduct != totalSampleSizeProduct ? distributeFraction(combinedSamplingPlanList.SKUSamplingList,sumSampleSizeProduct,totalSampleSizeProduct,isFromQv) : combinedSamplingPlanList.SKUSamplingList

    return combinedSamplingPlanList
}

function distributeFraction(SKUSamplingList,sumSampleSizeProduct,totalSampleSizeProduct,isFromQv = false){

    let newSKUSamplingList = []

    SKUSamplingList.sort((a, b) => (a.PO > b.PO) ? 1 : (a.PO === b.PO) ? ((a.SKUDisplay > b.SKUDisplay) ? 1 : -1) : -1 )

    //add sample size if sumSampleSizeProduct  is lower than totalSampleSizeProduct
    if(sumSampleSizeProduct < totalSampleSizeProduct){
        for(let i=0; i < SKUSamplingList.length; ){
            if(sumSampleSizeProduct != totalSampleSizeProduct ){   
                if(SKUSamplingList[i].productSampleSize <SKUSamplingList[i].productQty){
                    SKUSamplingList[i].productSampleSize++      
                    sumSampleSizeProduct++
                }         
                i++
                console.log('added sumSampleSizeProduct',sumSampleSizeProduct, 'totalSampleSizeProduct',totalSampleSizeProduct)
                if(sumSampleSizeProduct != totalSampleSizeProduct && i == SKUSamplingList.length){
                    i=0;
                }
            }
            else{
                newSKUSamplingList = SKUSamplingList
                
                break;
            }
        }
    }
    //subtract sample size if sumSampleSizeProduct  is greater than totalSampleSizeProduct
    else{
        var zeroOrOne = isFromQv ? 1 : 0;
        for(let i=0; i < SKUSamplingList.length; ){
            if(sumSampleSizeProduct != totalSampleSizeProduct){
                // if (sumSampleSizeProduct == SKUSamplingList.length && isFromQv){
                //     break;
                // }
                if ((SKUSamplingList.filter(x => x.productSampleSize == 1).length == SKUSamplingList.length || totalSampleSizeProduct < SKUSamplingList.length) && isFromQv){
                    zeroOrOne = 0;
                }
                if((SKUSamplingList[i].productSampleSize > zeroOrOne)){    
                    SKUSamplingList[i].productSampleSize--            
                    sumSampleSizeProduct--
                }
                i++
                console.log('subtracted sumSampleSizeProduct',sumSampleSizeProduct, 'totalSampleSizeProduct',totalSampleSizeProduct);
                if(sumSampleSizeProduct != totalSampleSizeProduct && i == SKUSamplingList.length){
                        i=0;
                    }

            }
            else{
                newSKUSamplingList = SKUSamplingList
                break;
            }
        }
    }

    newSKUSamplingList = SKUSamplingList;
    
    return newSKUSamplingList
}


data.getAutoComputeSampleSize = function (samplingMethodology, skuList, inspectionLevel, samplingPlan1, round, productType, tab, overWriteSampleSizes) {

    storeWorkOrderSampleSizeParameter(samplingMethodology, skuList, samplingPlan1, round, productType)

    let samplingPlanList = [];
    let totalSampleSizePerSKU = [];

    let visibleFabButton = isShowFloatingButton(samplingMethodology, skuList, samplingPlan1, round)
    localStorage.removeItem('samplingPlanList');

    console.log('samplingMethodology, skuList, inspectionLevel, samplingPlan1,productType, round',samplingMethodology, skuList, inspectionLevel, samplingPlan1, productType, round)

    if (samplingMethodology == "Combined Sample Size" && skuList.length > 1) {
        samplingPlanList = combinedSampleSize(skuList, inspectionLevel, samplingPlan1, productType, round)
    }
    else {
        samplingPlanList = separatedSampleSize(skuList, inspectionLevel, samplingPlan1, round)
    }
    samplingPlanList.showFabButton = visibleFabButton

    if (tab == 'QuantityVerification' && overWriteSampleSizes == true) {
        samplingPlanList.overWriteSampleSizes = true
    }



    let sample = samplingPlanList;
    localStorage.setItem('samplingPlanList', JSON.stringify(sample))
    return sample

}

function getTotalSampleSizePerSKU(SKUList, inspectionLevel, samplingPlan1, aql, round) {
    let sampleSizePerSKUList = [];

    SKUList.forEach(res => {

        let existSampleSizePerSKUList = sampleSizePerSKUList.some(x => x.SKU == res.SKUDisplay)
        if (!existSampleSizePerSKUList) {

            let totalProductQuantityPerSKU = getTotalProductQuantityPerSKU(SKUList, res.SKUDisplay)
            let totalProductSampleSizePerSKU = Number(getSampleSizeAcRe(totalProductQuantityPerSKU, inspectionLevel, samplingPlan1, aql, round).sampleSize)
            let totalCartonQuantityPerSKU = getTotalCartonQuantityPerSKU(SKUList, res.SKUDisplay)
            let totalCartonSampleSizePerSKU = Number(getSampleSizeAcRe(totalCartonQuantityPerSKU, inspectionLevel, samplingPlan1, aql, round).sampleSize)

            const totalPerSKUList = {
                SKU: res.SKUDisplay,
                totalProductQuantity: totalProductQuantityPerSKU,
                totalProductSampleSize: totalProductSampleSizePerSKU,
                totalCartonQuantity: totalCartonQuantityPerSKU,
                totalCartonSampleSize: totalCartonSampleSizePerSKU,

            };
            sampleSizePerSKUList.push(totalPerSKUList)
        }
    })

    return sampleSizePerSKUList;
}

function getTotalProductQuantityPerSKU(SKUList, SKUDisplay) {
    let total = 0
    let sumPerSKU = SKUList.reduce(function (tot, arr) {
        if (arr.SKUDisplay == SKUDisplay) {
            total += Number(arr.productQty)
        }
        return total;
    }, null);
    return sumPerSKU
}

function getTotalCartonQuantityPerSKU(SKUList, SKUDisplay) {
    let total = 0
    let sumPerSKU = SKUList.reduce(function (tot, arr) {
        if (arr.SKUDisplay == SKUDisplay) {
            total += Number(arr.cartonQty)
        }
        return total;
    }, null);
    return sumPerSKU
}

function getTotalCartonSampleSizePerSKU(SKUList, SKUDisplay, inspectionLevel, samplingPlan1, aql, round) {

    let total = 0
    let sumPerSKU = SKUList.reduce(function (tot, arr) {
        if (arr.SKUDisplay == SKUDisplay) {
            total += Number(arr.cartonSampleSize)
        }
        return total;
    }, null);
    return sumPerSKU
}

function getTotalProductSampleSizePerSKU(SKUList, SKUDisplay, inspectionLevel, samplingPlan1, aql, round) {

    let total = 0
    let sumPerSKU = SKUList.reduce(function (tot, arr) {
        if (arr.SKUDisplay == SKUDisplay) {
            total += Number(arr.productSampleSize)

        }
        return total;
    }, null);
    return sumPerSKU
}


data.getOverallProductSampleSizeByInspectionLevel = function (inspectionLevel, aql) {

    let samplingPlanList = [];

    let workOrderSamplingParameter = JSON.parse(localStorage.getItem("workOrderSamplingParameter"));
    let skuList = workOrderSamplingParameter.skuList
    let samplingMethodology = workOrderSamplingParameter.samplingMethodology
    let samplingPlan1 = workOrderSamplingParameter.samplingPlan1
    let productType = workOrderSamplingParameter.productType
    let round = workOrderSamplingParameter.round

    if (samplingMethodology == "Combined Sample Size" && skuList.length > 1) {
        samplingPlanList = combinedSampleSize(skuList, inspectionLevel, samplingPlan1, productType, round, aql)
    }
    else {
        samplingPlanList = separatedSampleSize(skuList, inspectionLevel, samplingPlan1, round, aql)
    }

    let sampleSizeByInspectionLevel = samplingPlanList.totalProductSampleSize;



    return sampleSizeByInspectionLevel

}


data.getSamplingPlanByInspectionLevel = function (inspectionLevel) {

    let samplingPlanList = [];

    let workOrderSamplingParameter = JSON.parse(localStorage.getItem("workOrderSamplingParameter"));
    let skuList = workOrderSamplingParameter.skuList
    let samplingMethodology = workOrderSamplingParameter.samplingMethodology
    let samplingPlan1 = workOrderSamplingParameter.samplingPlan1
    let productType = workOrderSamplingParameter.productType
    let round = workOrderSamplingParameter.round

    if (inspectionLevel == 'Fixed' || inspectionLevel == 'Square Root') {
        let totalLotQtyProduct = skuList.reduce(function (tot, arr) {
            return Number(tot) + Number(arr.productQty);
        }, 0) || 0;

        let totalLotQtyCarton = skuList.reduce(function (tot, arr) {
            return NUmber(tot) + Number(arr.cartonQty);
        }, 0) || 0;

        samplingPlanList.SKUSamplingList = skuList
        samplingPlanList.totalProductPOQty = totalLotQtyProduct
        samplingPlanList.totalCartonPOQty = totalLotQtyCarton
        return samplingPlanList
    }


    if (samplingMethodology == "Combined Sample Size" && skuList.length > 1) {
        samplingPlanList = combinedSampleSize(skuList, inspectionLevel, samplingPlan1, productType, round)
    }
    else {
        samplingPlanList = separatedSampleSize(skuList, inspectionLevel, samplingPlan1, round)
    }

    return samplingPlanList

}


data.getSamplingPlanByInspectionLevelAndSamplingMethodology = function (inspectionLevel, samplingMethodology, aql) {

    let samplingPlanList = [];



    let workOrderSamplingParameter = JSON.parse(localStorage.getItem("workOrderSamplingParameter"));
    let skuList = workOrderSamplingParameter.skuList
    let samplingPlan1 = workOrderSamplingParameter.samplingPlan1
    let productType = workOrderSamplingParameter.productType
    let round = workOrderSamplingParameter.round



    if (samplingMethodology == "Combined Sample Size" && skuList.length > 1) {
        samplingPlanList = combinedSampleSize(skuList, inspectionLevel, samplingPlan1, productType, round, aql)
    }
    else {
        samplingPlanList = separatedSampleSize(skuList, inspectionLevel, samplingPlan1, round, aql)
    }

    return samplingPlanList

}

data.getQVSamplingPlanByInspectionLevelAndSamplingMethodology = function (SKUSamplingList, inspectionLevel, samplingMethodology, samplingPlan1, productType, round, isFromQv = false) {

    let samplingPlanList = [];
    let workOrderSamplingParameter = JSON.parse(localStorage.getItem("workOrderSamplingParameter"));
    let skuList = SKUSamplingList == null ? workOrderSamplingParameter.skuList : SKUSamplingList
    samplingPlan1 = workOrderSamplingParameter == null ? samplingPlan1 : workOrderSamplingParameter.samplingPlan1
    productType = workOrderSamplingParameter == null ? null : workOrderSamplingParameter.productType
    round = workOrderSamplingParameter == null ? null : workOrderSamplingParameter.round

    storeWorkOrderSampleSizeParameter(samplingMethodology, skuList, samplingPlan1, round, productType)

    let visibleFabButton = isShowFloatingButton(samplingMethodology, skuList, samplingPlan1, round)
    localStorage.removeItem('samplingPlanList');

    if (samplingMethodology == "Combined Sample Size" && skuList.length > 1) {
        samplingPlanList = combinedSampleSize(skuList, inspectionLevel, samplingPlan1, productType, round,undefined, isFromQv)
    }
    else {
        samplingPlanList = separatedSampleSize(skuList, inspectionLevel, samplingPlan1, round)
    }

    samplingPlanList.showFabButton = visibleFabButton
    samplingPlanList.overWriteSampleSizes = true
    let sample = samplingPlanList;
    localStorage.setItem('samplingPlanList', JSON.stringify(sample))

    return sample
}

data.getSamplingPlanByInspectionLevelSamplingMethodologyInspectionLevel1doubleSamplingPlanIteration = function (inspectionLevel, samplingMethodology, inspectionLevel1, doubleSamplingPlanIteration) {

    let samplingPlanList = [];

    let workOrderSamplingParameter = JSON.parse(localStorage.getItem("workOrderSamplingParameter"));
    let skuList = workOrderSamplingParameter.skuList
    let samplingPlan1 = inspectionLevel1
    let productType = workOrderSamplingParameter.productType
    let round = doubleSamplingPlanIteration


    if (samplingMethodology == "Combined Sample Size" && skuList.length > 1) {
        samplingPlanList = combinedSampleSize(skuList, inspectionLevel, samplingPlan1, productType, round)
    }
    else {
        samplingPlanList = separatedSampleSize(skuList, inspectionLevel, samplingPlan1, round)
    }



    return samplingPlanList

}

data.getVaryingAQL = function (quantity, inspectionLevel, samplingPlan1, aql, round) {
    let varyingAQL = getSampleSizeAcRe(quantity, inspectionLevel, samplingPlan1, aql, round);
    return varyingAQL
}

function getProductSampleSize(aql,skuList,totalLotQtyProduct,inspectionLevel,samplingPlan1,round,lessToQuantity = 0, totalSkuCount = 0){
    const aqlCriticalSelected = aql !== undefined ? aql.aqlCritical : skuList.map(s => s.aqlCritical)[0];
    const aqlMinorSelected = aql !== undefined ? aql.aqlMinor : skuList.map(s => s.aqlMinor)[0];
    const aqlMajorSelected = aql !== undefined ? aql.aqlMajor : skuList.map(s => s.aqlMajor)[0];
    const aqlCriticalVal = +aqlCriticalSelected;
    const aqlMinorVal = +aqlMinorSelected;
    const aqlMajorVal = +aqlMajorSelected;

    let aqlCriticalSamplingPlan = 0;
    let aqlMinorSamplingPlan = 0;
    let aqlMajorSamplingPlan = 0;
    let zeroAQL = 0;
    let isZeroSampleSize = false;
    console.log('lesstoquan'+lessToQuantity)
    totalLotQtyProduct = totalLotQtyProduct - lessToQuantity;
    var nextLevel = false;
    if (lessToQuantity != 0){
        nextLevel = true;
    }
    if(aqlCriticalVal !== 0 && !isNaN(aqlCriticalVal)) {
        aqlCriticalSamplingPlan = getSampleSizeAcRe(totalLotQtyProduct, inspectionLevel, samplingPlan1, aqlCriticalVal, round, nextLevel,totalSkuCount).sampleSize
    }

    if(aqlMinorVal !== 0 && !isNaN(aqlMinorVal)) {
        aqlMinorSamplingPlan = getSampleSizeAcRe(totalLotQtyProduct, inspectionLevel, samplingPlan1, aqlMinorVal, round, nextLevel,totalSkuCount).sampleSize
    }

    if(aqlMajorVal !== 0 && !isNaN(aqlMajorVal)) {
        aqlMajorSamplingPlan = getSampleSizeAcRe(totalLotQtyProduct, inspectionLevel, samplingPlan1, aqlMajorVal, round, nextLevel,totalSkuCount).sampleSize
    }

    if((aqlCriticalSelected.toLowerCase() === 'n/a' || aqlCriticalSelected.toLowerCase() === 'not allowed')
    && (aqlMinorSelected.toLowerCase() === 'n/a' || aqlMinorSelected.toLowerCase() === 'not allowed')
    && (aqlMajorSelected.toLowerCase() === 'n/a' || aqlMajorSelected.toLowerCase() === 'not allowed')) {
      isZeroSampleSize = true;
    }


    if(aqlCriticalSamplingPlan === 0 && aqlMinorSamplingPlan === 0 && aqlMajorSamplingPlan === 0 && !isZeroSampleSize) {
        zeroAQL = getSampleSizeAcRe(totalLotQtyProduct, inspectionLevel, samplingPlan1, 0, round, nextLevel,totalSkuCount).sampleSize;
    }
    console.log(aqlCriticalSamplingPlan+':'+aqlMinorSamplingPlan+':'+aqlMajorSamplingPlan+':'+zeroAQL);
    return isZeroSampleSize ? 0 : Math.max(aqlCriticalSamplingPlan, aqlMinorSamplingPlan, aqlMajorSamplingPlan, zeroAQL);
}

export default data