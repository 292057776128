<template>
  <div>
    <div class="pastInspectionsSignOff">
      <!-- Supplier/ Factory Representative -->
      <v-row>
        <v-col cols="9" sm="7" md="10">
          <p class="top_title">*Supplier/ Factory representative</p>
        </v-col>
        <v-col cols="1" sm="1" md="1">
          <div id="btns">
            <button @click="onImageSelection"  id="uploadSignature">
              <span style="vertical-align: middle;">Upload</span>
            </button>
          </div>
        </v-col>
        <v-col cols="1" sm="1" md="1">
          <div id="btns">
            <button id="reset" @click="reset('Supplier')">
              <v-icon color="#fff">refresh</v-icon>
              <span style="vertical-align: middle;">Reset</span>
            </button>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12">
         <span v-show="isUploadSignature" > {{uploadImageFileName}}</span>
        </v-col>
      </v-row>
       <div v-show="isUploadSignature" id="div_forUploadSignature" style="border:1px #ccc solid;">
          <img id="imageUpload" :key="url" v-if="url" :src="url"  />

      </div>


      <div v-show="!isUploadSignature" id="div_forSignature" style="border:1px #ccc solid;">
        <!-- <signature ref="signature" background="#fff"></signature> -->
        <VueSignaturePad width="100%" height="500px" ref="signaturePad" :options="signatureOptions" />
      </div>

      <!-- Supplier/Factory Representative Name -->
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-text-field maxlength="30" id="txtRepresentativeName" v-model="representativeName" label="* Supplier/Factory Representative Name (English Only)"></v-text-field>
        </v-col>
      </v-row>
    
      

      <!-- Supplier/Factory Comment -->
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-text-field maxlength="500" id="txtSupplierFactoryComment" v-model="factoryComment" label="Supplier/Factory Comment"></v-text-field>
        </v-col>
      </v-row>

      <!-- Inspector Signature -->
      <v-row>
        <v-col cols="7" sm="7" md="7">
          <p class="top_title">*Inspector Signature</p>
        </v-col>
        <v-col cols="5" sm="5" md="5">
          <div id="btns">
            <button id="reset" @click="reset('Inspector')">
              <v-icon color="#fff">refresh</v-icon>
              <span style="vertical-align: middle;">Reset</span>
            </button>
          </div>
        </v-col>
      </v-row>

      <div id="div_inspectorForSignature" style="border:1px #ccc solid;">
        <VueSignaturePad width="100%" height="500px" ref="inspectorSignaturePad" :options="signatureOptions" />
      </div>

      <br />


      <br />

      <v-row>
        <div class="d-block">Report No: {{ signOffReportNumber }}</div>
      </v-row>
 
      <!-- Email Draft -->
      <v-row id="chk_EmailDraft">
        <v-checkbox v-model="formData.emailToSupplier" @change="emailToggle($event)" :label="'Email Draft Summary'"></v-checkbox>
      </v-row>

      <v-row>
        <v-btn id="preview-report" color="#07f" class="ma-2 white--text" width="200" :disabled="!workOrder.workOrderId" @click="previewReport()">
          Preview Draft Report
          <v-icon right dark>
            mdi-file-eye
          </v-icon>
        </v-btn>
        <!-- <v-btn
                    id="upload-attachment"
                    color="#07f"
                    class="ma-2 white--text"
                    width="200"
                    @click="uploadAttachment()">
                    Attach Draft Report
                    <v-icon right dark>
                        mdi-file-upload
                    </v-icon>
                </v-btn>
                <span id="display-attachment-name" class="d-flex align-center">
                    {{ displayAttachmentName() }}
                </span> -->
      </v-row>

      <!-- <v-row id="forEmailSupplier">
                <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="formData.emailAddress" :disabled="!formData.emailToSupplier" type="email" :rules="emailRules" label="Enter Supplier Email Address" @blur="onBlur"></v-text-field>
                </v-col>
            </v-row> -->
      <v-row v-if="formData.emailToSupplier">
        <v-col>
          <label>Select Export Format</label>
          <v-radio-group v-model="formData.reportFormat" row :rules="[(v) => !!v || 'Required']">
            <v-radio label="PDF" value="pdf"></v-radio>
            <v-radio label="Microsoft Word" value="docx"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row id="forOverAllResult">
        <v-col cols="12" sm="12" md="12">
          <v-select v-bind:items="ratings" item-text="description" item-value="id" v-model="formData.ratingId" @change="onRatingSelectChange()" :rules="ratingRules" label="*Overall Result"></v-select>
        </v-col>
      </v-row>
      <!-- <v-row v-show="formData.ratingId === getRatingId(overallResultCodes.FAIL)">
        <v-col cols="12" sm="12" md="12">
          <v-textarea v-model="formData.failReason" v-bind:placeholder="getReasonPlaceholder()" rows="3" label="Reason *" single-line bottom></v-textarea>
        </v-col>
      </v-row>
      <v-row v-show="formData.ratingId === getRatingId(overallResultCodes.PENDING)">
        <v-col cols="12" sm="12" md="12">
          <v-textarea v-model="formData.pendingReason" v-bind:placeholder="getReasonPlaceholder()" rows="3" label="Reason *" single-line bottom></v-textarea>
        </v-col>
      </v-row> -->
      <v-row v-show="formData.ratingId === getRatingId(overallResultCodes.ABORT)">
        <v-col cols="6" sm="6" md="6">
          <span class="required">Meet Quality/Performance Expectation*</span>
          <br />
          <span class="required">(Note: Any non-conformance, fail or pending point will tick No)</span>
        </v-col>
        <v-col cols="3" sm="3" md="3">
          <input type="radio" value="true" v-model="formData.abortMeetExpectations" />
          <label for="true">Yes</label>
        </v-col>
        <v-col cols="3" sm="3" md="3">
          <input type="radio" value="false" v-model="formData.abortMeetExpectations" />
          <label for="false">No</label>
        </v-col>
      </v-row>
      <!-- <v-row v-show="formData.ratingId === getRatingId(overallResultCodes.ABORT)">
        <v-col cols="12" sm="12" md="12">
          <v-textarea v-model="formData.abortReason" v-bind:placeholder="getReasonPlaceholder()" rows="3" label="Reason *" single-line bottom></v-textarea>
        </v-col>
      </v-row> -->
      
      <v-row v-show="showFailCodes">
        <v-col cols="12">
          <v-textarea v-model="formData.failReason" :label="failReasonDescription" :counter="maxLength" :rules="remarksRules" :rows="2"></v-textarea>
        </v-col>
      </v-row>
      <v-row v-if="showFailCodes">
        <v-col cols="12">
          <v-container fluid>
            <span class="required">{{ resultCodeLabel }} *</span>
            <v-btn id="toolTip" v-show="!isCustomResultCodes" icon dark @click="show_toopltips = true">
              <v-icon color="rgb(115, 115, 115)" style="margin-left: 15px;">help</v-icon>
            </v-btn>
            <v-checkbox v-for="(item, index) in resultCodes" :key="index" v-model="formData.failCodes" :label="resultCodeDescription(item)" :value="resultCodeValue(item)"> </v-checkbox>
          </v-container>
          <v-dialog v-model="show_toopltips" width="500px">
            <v-card>
              <v-card-content>
                <table class="fail_code">
                  <tr>
                    <td valign="top">UL0</td>
                    <td>
                      <p>Finding of issue on product that was not covered by below reasons</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">UL1</td>
                    <td>
                      <p><strong>Product Deviation</strong></p>
                      <p>Product is not conform with specification/ gold seal sample</p>
                      <p>- Dimension</p>
                      <p>- Color and style</p>
                      <p>- Accessory</p>
                      <p>- Required function and feature</p>
                      <p>- Material</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">UL2</td>
                    <td>
                      <p><strong>Retail Packaging Deviation</strong></p>
                      <p>Labeling / Manuals / Hang tag / Markings / Artwork / Barcode</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">UL3</td>
                    <td>
                      <p><strong>Shipping Carton Deviation</strong></p>
                      <p>Export carton dimension / weight / shipping marks</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">UL4</td>
                    <td>
                      <p><strong>Onsite test Issues</strong></p>
                      <p>- No equipment to conduct test</p>
                      <p>- Factory refuse to conduct functional test</p>
                      <p>- Functional test failed</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">UL5</td>
                    <td>
                      <p><strong>Carton Drop Test</strong></p>
                      <p>- Factory refuse to conduct carton drop test</p>
                      <p>- Carton drop test failed</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">UL6</td>
                    <td>
                      <p>Client specification/ Approval Sample not available during inspection</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">UL7</td>
                    <td>
                      <p>Exceed AQL</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">UL8</td>
                    <td>
                      <p>Shortage of Shipment Quantity</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">UL9</td>
                    <td>
                      <p><strong>Missing Documents</strong></p>
                      <p>- CDF not available</p>
                      <p>- Client POs</p>
                      <p>- Product specification requirement</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">UL10</td>
                    <td>
                      <p>All issues related for Sample Collection</p>
                    </td>
                  </tr>
                </table>
              </v-card-content>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn id="btn_Close" color="blue darken-1" text @click="show_toopltips = false">Close</v-btn>
                <!-- <v-btn color="blue darken-1" text @click="save">Save</v-btn> -->
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <!-- <v-col v-show="formData.ratingId === getRatingId(overallResultCodes.PENDING)">
          <v-container fluid>
            <span class="required">Pending Code *</span>
            <v-checkbox v-for="(item, index) in resultCodes" :key="index" v-model="formData.pendingCodes" :label="resultCodeDescription(item)" :value="resultCodeValue(item)"></v-checkbox>
          </v-container>
        </v-col>
        <v-col v-show="formData.ratingId === getRatingId(overallResultCodes.ABORT)">
          <v-container fluid>
            <span class="required">Abortive Code *</span>
            <v-checkbox v-for="(item, index) in resultCodes" :key="index" v-model="formData.abortCodes" :label="resultCodeDescription(item)" :value="resultCodeValue(item)"></v-checkbox>
          </v-container>
        </v-col> -->
      </v-row>
      <v-row v-if="missingRequiredFields">
        <v-col cols="12" sm="12" md="12">
          <span style="color:red">*Missing Required Fields</span>
        </v-col>
      </v-row>
    <!--  -->
      <v-btn id="btn_Submit" class="submit" color="#B14C4C" :disabled="!isFormValid || missingRequiredFields"  @click="showPopupMessage">Submit</v-btn>
    </div>

    <v-dialog v-model="noticeDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Note</v-card-title>
        <v-card-text>Email Notification will be sent to the representative Service Specialist</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="btn_Dialog"
            color="green darken-1"
            text
            @click="
              noticeDialog = false;
              confirmDialog = true;
            "
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDialog" width="240">
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn id="btn_Dialog_Confirm" color="green darken-1" text @click="submit">Confirm</v-btn>
          <v-btn id="btn_Dialog_Close" color="green darken-1" text @click="confirmDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showProgressDialog" :persistent="true">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <div class="text-center">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div id="summary-section-wrapper" style="display: none; padding: 5px; background-color: #fff;">
      <div id="summary-section">
        <h3 style="text-align: center; padding: 20px;">Draft Summary</h3>
        <div class="card" style="padding: 10px; margin-bottom: 20px; box-shadow: 1px 1px 5px rgba(0,0,0,.1);">
          <h4 style="text-align: left;">General Summary</h4>
          <table style="border-collapse: collapse; width: 100%; margin-top: 20px;">
            <tbody>
              <tr id="rowId">
                <td id="client_name" style="padding: 8px;">Client Name: {{ workOrder.client }}</td>
                <td id="overall_Result" style="padding: 8px;">Overall Result: {{ overallResult() }}</td>
              </tr>
              <tr id="supplier_Name">
                <td style="padding: 8px;" colspan="2">Supplier Name: {{ workOrder.supplier }}</td>
              </tr>
              <tr id="inspection_Level">
                <td style="padding: 8px;" colspan="2">Inspection Level: {{ workOrder.inspectionLevel }}</td>
              </tr>
              <tr id="sample_Size">
                <td style="padding: 8px;" colspan="2">Sample Size: {{ workOrder.sampleSize }}</td>
              </tr>
              <tr id="product_Type">
                <td style="padding: 8px;" colspan="2">Product Type: {{ workOrder.productType }}</td>
              </tr>
            </tbody>
          </table>
          <table style="border-collapse: collapse; width: 100%; margin-top: 20px;">
            <thead>
              <tr>
                <td style="padding: 8px;">PO Number</td>
                <td style="padding: 8px;">SKU Number</td>
                <td style="padding: 8px;">Product Description</td>
                <td style="padding: 8px;">Quantity</td>
              </tr>
            </thead>
            <tbody>
              <tr :id="'row_' + item.po" v-for="(item, index) in workOrder.products" :key="index">
                <td style="padding: 8px; border-bottom: 1px solid rgba(0,0,0,.12);">{{ item.po }}</td>
                <td style="padding: 8px; border-bottom: 1px solid rgba(0,0,0,.12);">{{ item.skuDisplay }}</td>
                <td style="padding: 8px; border-bottom: 1px solid rgba(0,0,0,.12);">{{ item.productDescription }}</td>
                <td style="padding: 8px; border-bottom: 1px solid rgba(0,0,0,.12);">{{ item.qty }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="card" style="padding: 10px; margin-bottom: 20px; box-shadow: 1px 1px 5px rgba(0,0,0,.1);">
          <h4 style="text-align: left;">Inspection Summary</h4>
          <table style="border-collapse: collapse; width: 100%; margin-top: 20px;">
            <thead>
              <tr>
                <td style="padding: 8px;">Section</td>
                <td style="padding: 8px;">Result</td>
                <td style="padding: 8px;">Findings</td>
              </tr>
            </thead>
            <tbody>
              <tr :id="'row_' + item.sectionId" v-for="(item, index) in InspectionList" :key="index">
                <td style="padding: 8px; border-bottom: 1px solid rgba(0,0,0,.12);">{{ item.section }}</td>
                <td style="padding: 8px; border-bottom: 1px solid rgba(0,0,0,.12);">{{ item.result }}</td>
                <td style="padding: 8px; border-bottom: 1px solid rgba(0,0,0,.12);">{{ item.findings }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="card" style="padding: 10px; margin-bottom: 20px; box-shadow: 1px 1px 5px rgba(0,0,0,.1);">
          <h4 style="text-align: left;">Finding Summary</h4>
          <table style="border-collapse: collapse; width: 100%; margin-top: 20px;">
            <thead>
              <tr>
                <td style="padding: 8px;"></td>
                <td style="padding: 8px;">Found</td>
                <td style="padding: 8px;">Allowed</td>
              </tr>
            </thead>
            <tbody>
              <tr id="row_Critical">
                <td style="padding: 8px; border-bottom: 1px solid rgba(0,0,0,.12); border-bottom: 1px solid rgba(0,0,0,.12);">Critical</td>
                <td style="padding: 8px; border-bottom: 1px solid rgba(0,0,0,.12); border-bottom: 1px solid rgba(0,0,0,.12);">{{ workOrder.foundCritical }}</td>
                <td style="padding: 8px; border-bottom: 1px solid rgba(0,0,0,.12); border-bottom: 1px solid rgba(0,0,0,.12);">{{ workOrder.allowedCritical }}</td>
              </tr>
              <tr id="row_Major">
                <td style="padding: 8px; border-bottom: 1px solid rgba(0,0,0,.12); border-bottom: 1px solid rgba(0,0,0,.12);">Major</td>
                <td style="padding: 8px; border-bottom: 1px solid rgba(0,0,0,.12); border-bottom: 1px solid rgba(0,0,0,.12);">{{ workOrder.foundMajor }}</td>
                <td style="padding: 8px; border-bottom: 1px solid rgba(0,0,0,.12); border-bottom: 1px solid rgba(0,0,0,.12);">{{ workOrder.allowedMajor }}</td>
              </tr>
              <tr id="row_Minor">
                <td style="padding: 8px; border-bottom: 1px solid rgba(0,0,0,.12); border-bottom: 1px solid rgba(0,0,0,.12);">Minor</td>
                <td style="padding: 8px; border-bottom: 1px solid rgba(0,0,0,.12); border-bottom: 1px solid rgba(0,0,0,.12);">{{ workOrder.foundMinor }}</td>
                <td style="padding: 8px; border-bottom: 1px solid rgba(0,0,0,.12); border-bottom: 1px solid rgba(0,0,0,.12);">{{ workOrder.allowedMinor }}</td>
              </tr>
            </tbody>
          </table>

          <table style="border-collapse: collapse; width: 100%; margin-top: 20px;">
            <thead>
              <tr>
                <td style="padding: 8px;">#</td>
                <td style="padding: 8px;">Defect Category</td>
                <td style="padding: 8px;">Defect Name</td>
                <td style="padding: 8px;">Defect Classification</td>
                <td style="padding: 8px;">Qty</td>
                <td style="padding: 8px;">SKUs</td>
              </tr>
            </thead>
            <tbody>
              <tr :id="'row_' + index + 1" v-for="(item, index) in workOrderProductDefects" :key="index">
                <td style="padding: 8px; border-bottom: 1px solid rgba(0,0,0,.12);">{{ index + 1 }}</td>
                <td style="padding: 8px; border-bottom: 1px solid rgba(0,0,0,.12);">{{ item.defectCategory != null ? item.defectCategory.name : "" }}</td>
                <td style="padding: 8px; border-bottom: 1px solid rgba(0,0,0,.12);">{{ item.defectName != null ? item.defectName.name : "" }}</td>
                <td style="padding: 8px; border-bottom: 1px solid rgba(0,0,0,.12);">{{ item.defectClassification != null ? item.defectClassification.name : "" }}</td>
                <td style="padding: 8px; border-bottom: 1px solid rgba(0,0,0,.12);">{{ item.qty }}</td>
                <td style="padding: 8px; border-bottom: 1px solid rgba(0,0,0,.12);">{{ item.skuDisplay }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- <style>
                    #summary-section {
                        padding: 5px;
                        background-color: #fff;
                    }
                    table {
                        border-collapse: collapse;
                        width: 100%;
                        margin-top: 20px;
                    }
                    table td {
                        padding: 8px;
                    }
                    table tbody tr:not(:last-child) td{
                        border-bottom: 1px solid rgba(0,0,0,.12);
                    }
                    .card {
                        padding: 10px;
                        margin-bottom: 20px;
                        box-shadow: 1px 1px 5px rgba(0,0,0,.1);
                    }
                    .card h4 {
                        text-align: left;
                    }
                </style> -->
      </div>
    </div>

    <v-snackbar v-model="snackbarError" :timeout="timeoutError" :color="colorError">
      {{ messageError }}
      <v-btn text @click="snackbarError = false"> Close </v-btn>
    </v-snackbar>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="color">
      {{ message }}
      <v-btn text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
    <report-preview :showDialog.sync="showReportPreview" :reportData="reportData"></report-preview>
  </div>
</template>

<script>
import { mapState } from "vuex";
import auditLog from "@/shared/request/auditLogs";
import * as moment from "moment";
import * as _ from "lodash";
import * as pbi from "powerbi-client";
import ReportPreview from "@/components/reports/ReportPreview";

let state = {
  appHeaderState: {
    show: true,
    title: "Work Order: ",
    showMenu: true,
    showBack: true,
    showLogo: false,
    showTitle: true,
    showSearch_job: false,
    showSearch_work: false,
  },
};

const overallResultCodes = {
  PASS: "Pass",
  FAIL: "Fail",
  PENDING: "Pending",
  ABORT: "Abort",
};

function setState(store) {
  store.dispatch("navigation/setAppHeader", state.appHeaderState);
}

export default {
  name: "pastInspectionsSignOff",
  components: {
    ReportPreview,
  },
  data() {
    return {
      url: null,
      isUploadSignature: false,
      hasImage: false,
      image: null,
      imageType: null,
      uploadImageFileName: null,
      checkbox: true,
      emailAddress: "",
      selected: "",
      items: [
        { text: "Pass", value: 1 },
        { text: "Fail", value: 2 },
        { text: "Pending", value: 3 },
      ],
      failCodes: ["UL0", "UL1", "UL2", "UL3", "UL4", "UL5", "UL6", "UL7", "UL8", "UL9", "UL10"],
      pendingCodes: ["P1", "P2", "P3", "P4", "P5", "P6", "P7", "P8", "P9"],
      //abortCodes: ['AIC1','AIC5', 'AIC6', 'AIC7', 'AIC8', 'AIC9', 'AIC10'],
      abortCodes: [],
      overallResultCodes,
      select_value: 1,
      show_toopltips: false,
      formData: {
        emailToSupplier: true,
        emailAddress: "",
        ratingId: null,
        failCodes: [],
        pendingCodes: [],
        abortCodes: [],
        failReason: "",
        pendingReason: "",
        abortReason: "",
        abortMeetExpectations: null,
        reportFormat: null,
      },
      workOrder: {},
      ratings: [],
      noticeDialog: false,
      confirmDialog: false,
      showProgressDialog: false,
      signatureOptions: {
        penColor: "black",
      },
      submitData: {},
      workOrderDraft: {},
      emailRules: [(v) => !!v || "E-mail is required", (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid"],
      ratingRules: [(v) => !!v || "Required"],
      remarksRules: [(v) => (!!v && v.length <= 200) || "Maximum 200 characters only"],
      maxLength: 200,

      // Preview
      workOrderProducts: [],
      workOrderProductDefects: [],
      InspectionList: [
        {
          sectionId: 1,
          section: "Quantity Verification",
          result: "Conformed",
          findings: 0,
          show: true,
        },
        {
          sectionId: 2,
          section: "Functional Check Measurement",
          result: "Conformed",
          findings: 0,
          show: true,
        },
        {
          sectionId: 3,
          section: "Package Check",
          result: "Conformed",
          findings: 0,
          show: true,
        },
        {
          sectionId: 4,
          section: "Barcode Verification",
          result: "Conformed",
          findings: 0,
          show: true,
        },
        {
          sectionId: 5,
          section: "Workmanship",
          result: "Conformed",
          findings: 0,
          show: true,
        },
        {
          sectionId: 6,
          section: "Onsite Tests",
          result: "Conformed",
          findings: 0,
          show: true,
        },
      ],
      // Product list
      generalSummaryHeader: [
        { text: "PO Number", sortable: false, value: "po" },
        { text: "SKU Number", sortable: false, value: "skuDisplay" },
        {
          text: "Product Description",
          sortable: false,
          value: "productDescription",
        },
        { text: "Quantity", sortable: false, value: "qty" },
      ],
      generalSummaryList: [
        {
          po: "a",
          skuDisplay: "a",
          productDescription: "a",
          qty: 10,
        },
      ],

      InspectionHeader: [
        { text: "Section", sortable: false, value: "section" },
        { text: "Result", sortable: false, value: "result" },
        { text: "Findings", sortable: false, value: "findings" },
      ],
      count: 0,
      FindingHeader: [
        { text: "#", sortable: false, value: "id" },
        {
          text: "Defect Category",
          sortable: false,
          value: "defectCategory.name",
        },
        { text: "Defect Name", sortable: false, value: "defectName.name" },
        { text: "Qty", sortable: false, value: "qty" },
        { text: "SKUs", sortable: false, value: "skuDisplay" },
      ],
      FindingList: [
        {
          number: "a",
          sku: "a",
          po: "a",
          sku: 10,
        },
      ],
      change_type: 1,
      select_value: 1,
      show_toopltips: false,

      items: [
        { text: "Pass", value: 1 },
        { text: "Fail", value: 2 },
        { text: "Pending", value: 3 },
      ],
      datatableRowsPerPage: {
        "footer-props": {
          "items-per-page-options": [10],
        },
      },
      ratings: [],
      representativeName: "",
      factoryComment: "",
      factoryTimeIn: "00",
      factoryTimeInMin: "00",
      factoryTimeOut: "00",
      factoryTimeOutMin: "00",
      inspectionStartTime: "00",
      inspectionStartTimeMin: "00",
      inspectionEndTime: "00",
      inspectionEndTimeMin: "00",
      getFactoryTimeIn: null,
      getFactoryTimeOut: null,
      getInspectionStartTime: null,
      getInspectionEndTime: null,
      getFactoryTimeInMin: null,
      getFactoryTimeOutMin: null,
      getInspectionStartTimeMin: null,
      getInspectionEndTimeMin: null,
      timeInAm: true,
      timeInPm: false,
      timeOutAm: true,
      timeOutPm: false,
      startTimeAm: true,
      startTimePm: false,
      endTimeAm: true,
      endTimePm: false,
      snackbarError: false,
      messageError: "",
      missingRequiredFields: false,
      timeoutError: 3000,
      colorError: "red darken-2",
      errorMessage1: "Factory Time Out should not be earlier than the factory time in",
      errorMessage2: "Inspection start time should not be earlier than the factory time in",
      errorMessage3: "Inspection end time should not be less than or equal to inspection start time",
      errorMessage4: "Please input valid minute(s)",
      errorMessage5: "Please input valid hour(s)",
      errorMessage: "",
      isMinuteValid: true,
      isValueNull: true,
      snackbar: false,
      color: "red darken-2",
      message: "",
      timeout: 3000,
      // power bi preview
      showReportPreview: false,
      reportAttachment: null,
      reportData: {},
      submitInProgress: false,
      signOffReportNumber: ""
    };
  },
  beforeRouteLeave(to, form, next) {
    this.showReportPreview = false;
    next();
  },
  async mounted() {
    // 设置头部
    state.appHeaderState.title = "Work Order:" + this.$route.query.displayId;
    setState(this.$store);

    this.utilityFn.getServerData("get", this.apiList.getAllWorkOrderAbortiveCode, {}, (res) => {
      this.abortCodes = res.data.data;
    });

    // 在订单详情中获取clientId来获取选项
    this.utilityFn.getServerData("get", this.apiList.workOrderSignOffDetails + "/" + this.$route.query.id, {}, (res) => {
      this.workOrder = res.data.data;
      var clientId = this.workOrder.clientId;
      this.formData.ratingId = this.workOrder.overAllResult ? this.workOrder.overAllResult.id : this.workOrder.workOrderRatingId;
      this.formData.failCodes = this.workOrder.failCodes;
      /*   this.utilityFn.getServerData('post', this.apiList.GetCompanyWorkOrderResultTypeAliases, {id: clientId}, res => {
                    this.ratings = res.data.filter(x => x.description && x.isActive);

                    if (this.$route.params && this.$route.params.failCode === 'abort') {
                        //console.log( ' this.ratings',this.ratings)
                        this.formData.ratingId = this.ratings.find(x => x.description.toLowerCase().includes('abort')).id;
                        this.formData.abortMeetExpectations = false;
                        this.formData.abortCodes = [];
                    }
                })
 */
      this.utilityFn.getServerData("get", this.apiList.GetCompanyWOResultTypeAliases
       + "?companyId=" + clientId, {},(res) => {
        this.ratings = res.data.data.filter((x) => x.description && x.isActive);
        if (this.$route.params && this.$route.params.failCode === "abort") {
          //console.log( ' this.ratings',this.ratings)
          this.formData.ratingId = this.ratings.find((x) => x.description.toLowerCase().includes("abort")).id;
          this.formData.abortMeetExpectations = false;
          this.formData.abortCodes = [];
        }
      });

      this.$store.dispatch("inspection/setWorkOrderDetails", this.workOrder);

      this.workOrderProducts = this.workOrder.products;
      this.computeInspectionResults();
    });

    this.getLatestWorkOrderDraft();

    this.checkRequiredFields();

    this.utilityFn.getServerData("get", this.apiList.getWorkOrderProductDefects, { workOrderId: this.$route.query.id }, (res) => {
      this.workOrderProductDefects = res.data.data;
      //console.log("workOrderProductDefects");
      //console.log(this.workOrderProductDefects)
    });
    this.utilityFn.getServerData("get", this.apiList.workOrderSectionList + "/" + this.$route.query.id, {}, (res) => {
      this.inspectionSummarySections = res.data.data;
      //console.log("inspectionSummarySections");
      //console.log(this.inspectionSummarySections)
    });

    this._getJobList.forEach((x) => {
      x.workOrders.forEach((y) => {
        if (y.id == this.$route.query.id) {
          let getDataCaptureModifiedDate = moment(x.dataCaptureModifiedDate).format("HH") == "Invalid date" ? "00" : moment(x.dataCaptureModifiedDate).format("HH");
          this.inspectionStartTime = getDataCaptureModifiedDate;
          this.getInspectionStartTime = Number(moment(x.dataCaptureModifiedDate).format("H") == "Invalid date" ? "0" : moment(x.dataCaptureModifiedDate).format("H"));
          if (getDataCaptureModifiedDate <= 11) {
            this.startTimeAm = true;
            this.startTimePm = false;
          } else {
            this.startTimeAm = false;
            this.startTimePm = true;
          }
        }
      });
    });
    this.getRemarksandComments()
    // this.apiFn.GetWorkOrderProductDefects(this.workOrderId).then(result => {
    //     this.WorkOrderProductDefects = result;
    // });
    // this.apiFn.GetInspectionSummarySections(this.workOrderId).then(result => {
    //     this.inspectionSummarySections = result;
    // });

    await this.getEmailReportNumber();
  },
  methods: {
    onImageSelection(event) {
      event.preventDefault();
      var inputObj = document.createElement("input");
      inputObj.setAttribute("accept", "image/*");
      inputObj.setAttribute("id", "_ef");
      inputObj.setAttribute("type", "file");
      inputObj.setAttribute("style", "visibility:hidden");
      document.body.appendChild(inputObj);
      inputObj.click();
      inputObj.onchange = (res) => {
        const loadImage = async () => {
          let file = inputObj.files[0]
          this.resize(file);    
          this.hasImage = true;
          this.uploadImageFileName = file.name
          this.isUploadSignature = true    
        };
        loadImage().then(() => {     
          console.log("done");   
        });
      };
    },

    resize(item){

      //create a FileReader
      var reader = new FileReader();
      const that = this;

      //image turned to base64-encoded Data URI.
      reader.readAsDataURL(item);
      reader.name = item.name;//get the image's name
      reader.size = item.size; //get the image's size
      reader.type = item.type; //get the image's type
      reader.onload = function(event) {
        var img = new Image();//create a image
        img.src = event.target.result;//result is base64-encoded Data URI
        img.name = event.target.name;//set name (optional)
        img.size = event.target.size;//set size (optional)

        img.onload = function(el) {
          var elem = document.createElement('canvas');//create a canvas

          var maxWidth = el.target.width;//without px
          var maxHeight = 500;//without px
          var ratio = Math.min(maxWidth / el.target.width, maxHeight / el.target.height);
          elem.height = el.target.height*ratio
          elem.width = el.target.width*ratio

          //draw in canvas
          var ctx = elem.getContext('2d');
          ctx.drawImage(el.target, 0, 0, elem.width, elem.height);

          //get the base64-encoded Data URI from the resize image
          var srcEncoded = ctx.canvas.toDataURL( reader.type, 1);

          //assign it to thumb src
          that.imageType = reader.type
          that.image = srcEncoded
          that.url = srcEncoded
          //document.querySelector('#imageUpload').src = srcEncoded;

          /*Now you can send "srcEncoded" to the server and
          convert it to a png o jpg. Also can send
          "el.target.name" that is the file's name.*/

        }
      }
    },
    removeImage () {
      this.url1 = null
    },

    reset(val) {  
      if (val == "Supplier" && this.isUploadSignature){
        this.url = null
        this.hasImage = false;
        this.uploadImageFileName = null
        this.imageType = null
        this.isUploadSignature = false
      }

      if (this.$refs.signaturePad && val == "Supplier") {
        this.$refs.signaturePad.clearSignature();
      }

      if (this.$refs.inspectorSignaturePad && val == "Inspector") {
        this.$refs.inspectorSignaturePad.clearSignature();
      }
    },
    getRemarksandComments(){
        this.utilityFn.getServerData("get", this.apiList.getWorkOrderRemarks + "/" + this.$route.query.id, {}, (result) => {
        this.$store.dispatch("inspection/setRemarksList", result.data.data);
      });
    },
    emailToggle() {
      if (!this.formData.emailToSupplier) {
        this.formData.emailAddress = "";
      }
    },
    onRatingSelectChange() {
      //codes
      // this.formData.failCodes = []; // (this.formData.ratingId != this.getRatingId(overallResultCodes.FAIL)) ? [] : this.formData.failCodes;
      // this.formData.pendingCodes = []; // (this.formData.ratingId != this.getRatingId(overallResultCodes.PENDING)) ? [] : this.formData.pendingCodes;
      // this.formData.abortCodes = []; // (this.formData.ratingId != this.getRatingId(overallResultCodes.ABORT)) ? [] : this.formData.abortCodes;
      // //reason
      // this.formData.failReason = this.formData.ratingId != this.getRatingId(overallResultCodes.FAIL) ? "" : this.formData.failReason;
      // this.formData.pendingReason = this.formData.ratingId != this.getRatingId(overallResultCodes.PENDING) ? "" : this.formData.pendingReason;
      // this.formData.abortReason = this.formData.ratingId != this.getRatingId(overallResultCodes.ABORT) ? "" : this.formData.abortReason;
      
      this.formData.failCodes = [];
    },
    getRatingId(description) {
      let rating = this.ratings.find((x) => x.description.toLowerCase().indexOf(description.toLowerCase()) !== -1);
      return rating != null ? rating.id : "";
    },
    getReasonPlaceholder() {
      if (this.ratings.length != 0) {
        if (this.formData.ratingId) {
          const description = this.ratings.find((x) => x.id === this.formData.ratingId).description;
          return `Please provide a descriptive ${description != "Aborted" ? description.toLowerCase() : "abort"} reason. *`;
        }
      }
    },
    getData() {
      console.log(this.$refs["signature"].getFile());
    },
    // 阻止IOS设备，签名时页面会产生下拉问题。
    stopTouchmove(e) {
      e.preventDefault();
    },

    isValidRemarksCommentSection(){
      if( this._workorderDetails.client == "WALMART GLOBAL SOURCING" || this._workorderDetails.client.toLowerCase() === 'asda stores limited'){
        let ratingValue = this.ratings.filter(x => x.id == this.formData.ratingId)[0].description

        if(ratingValue.includes("Pass")){
          return true;
        }

        if(this._remarksLists.length == 0){
            this.snackbar = true;
            this.message = "Missing Remark/Comment";
            return false;
        }
        
        if(ratingValue.includes("Fail")) {
          let remarksListsFail = this._remarksLists.filter(x => x.classification == 1)
          if(remarksListsFail.length == 0) {
            this.snackbar = true;
            this.message = "Please provide the Fail Comment(s)";
          return false;
          }
        } else if(ratingValue.includes("Pending")) {
          let remarksListsPending = this._remarksLists.filter(x => x.classification == 2)
            if(remarksListsPending.length == 0) {
              this.snackbar = true;
              this.message = "Please provide the Pending Comment(s)";
            return false;
            }
        } else if(ratingValue.includes("Abort")) {
          let remarksListsAbort = this._remarksLists.filter(x => x.classification == 4)
            if(remarksListsAbort.length == 0) {
              this.snackbar = true;
              this.message = "Please provide the Abort Comment(s)";
          return false;
            }
        }
      }
      return true
    },

    showPopupMessage() {
      if(this.isValidRemarksCommentSection()){
          if (this.formData.emailToSupplier) {
            this.noticeDialog = true;
          } else {
            this.confirmDialog = true;
          }
      }
    },

    async submit() {
      if (this.submitInProgress)
        return

      // mark this method as in-progress to prevent double trigger
      this.submitInProgress = true;

      if((this._workorderDetails.client.toLowerCase() === 'walmart global sourcing' || this.workOrderDetails?.clientName.toLowerCase() === 'asda stores limited') && this._workorderDetails.isPreShipOrFinalRandom === true) {
        //validate barcode verification
        var barcodeVerificationErrorMessage = await this.utilityFn.getServerDataAsync('get', `${this.apiList.validateBarcodeVerification}/${this.$route.workOrderId}`,{});
        if (barcodeVerificationErrorMessage.data){
          this.snackbarError = true;
          this.messageError = barcodeVerificationErrorMessage.data;
          this.submitInProgress = false;
          return;
        }

        //validate quantity verification
        var quantityVerificationErrorMessage = await this.utilityFn.getServerDataAsync('get', `${this.apiList.validateQuantityVerification}/${this.$route.workOrderId}`,{});
        if (quantityVerificationErrorMessage.data){
          this.snackbarError = true;
          this.messageError = quantityVerificationErrorMessage.data;
          this.submitInProgress = false;
          return;
        }

        //validate FCM
        var validateFCM = await this.utilityFn.getServerDataAsync('get',`${this.apiList.validateFCM}/${this.$route.workOrderId}`,{});
        if (validateFCM.data){
          this.snackbarError = true;
          this.messageError = validateFCM.data;
          this.submitInProgress = false;
          return;
        }
      }

      const result = await this.utilityFn.getServerDataAsync('get', `${this.apiList.validateOnsiteTestResults}/${this.$route.query.id}`, { });
      let validationMsg = !result.data.item1 ? 'Please assign the Onsite Test Result' : !result.data.item2 ? 'Please fill in Onsite Test Sample Size' : '';
      if (validationMsg) {
        this.messageError = validationMsg;
        this.snackbarError = true;
        this.confirmDialog = false;
        this.submitInProgress = false;
        return;
      }

      this.showProgressDialog = true;
      if (window.navigator.onLine == false) {
        alert("Please submit data when internet is available");
        this.submitInProgress = false;
        return false;
      }

      // this.utilityFn.db.transaction((tx) => {
      //   tx.executeSql("DELETE FROM downloadList WHERE displayId=?", [this.$route.query.displayId]);

      //   tx.executeSql("SELECT * FROM downloadList Where resourceId=?", [this.utilityFn.getResourceId()], (tx, results) => {
      //     var len = results.rows.length;
      //     this.$store.dispatch("inspection/setDownloadNumber", len);
      //   });
      // });

      // if (this.formData.emailToSupplier) {
      //     this.noticeDialog = true;
      // }



      let form = new FormData();
      let getWorkOrderId = this.$route.query.id;

      

      let signatureFile = null
      if(this.isUploadSignature){
         signatureFile = await this.getFileUpload(this.image, "SignatureUpload");
      }
      else{
         signatureFile = await this.getFile(this.$refs.signaturePad.saveSignature().data, "Signature");
      }
     
      form.append("Signature", signatureFile);
      form.append("SupplierEmailAddress", this.formData.emailAddress);
      form.append("WorkOrderResultId", this.formData.ratingId);

      const inspectorSignatureFile = await this.getFile(this.$refs.inspectorSignaturePad.saveSignature().data, "InspectorSignature");
      form.append("InspectorSignature", inspectorSignatureFile);
      form.append("RepresentativeName", this.representativeName);
      
      if (this.reportAttachment) {
        form.append("ReportAttachment", this.reportAttachment);
      }

      form.append("FailReason", this.formData.failReason);
      form.append("FactoryComment", this.factoryComment);
      this.formData.failCodes.forEach((code) => form.append("FailCodes", code));
      if (this.formData.abortMeetExpectations != null) {
          form.append("AbortMeetExpectations", this.formData.abortMeetExpectations);
      }

      if (this.formData.emailAddress) {
        const emailContent = {
          // subject: 'WorkOrder ' + this.$route.query.displayId + ' Summary',
          subject: "DRAFT Inspection Report - " + this.workOrder.client + " - " + this.workOrder.workOrderClass + " - " + moment(this.workOrder.inspectionRequestDate).format("MMDDYYYY"),
          to: this.formData.emailAddress,
          htmlContent: document.getElementById("summary-section").innerHTML,
        };

        form.append("HtmlContent", emailContent.htmlContent);
        form.append("PlainTextContent", emailContent.plainTextContent);
        form.append("EmailSubject", emailContent.subject);
      }


      this.utilityFn.getServerData("post", this.apiList.submitWorkOrderReport + "/" + getWorkOrderId, form, (result) => {
        if (result.data) {
          this.showProgressDialog = false;
          this.submitInProgress = false;

          localStorage.setItem("signedOffWorkOrder", JSON.stringify(getWorkOrderId));

          if (this.formData.emailToSupplier) {
            const token = this.report.getExportAuth();
            if (!token) {
              this.authenticate();
            } else {
              this.invokeExport(token);
            }
          }

          const data = result;
          const transactionCode = sessionStorage.getItem("transactionCode");
          const isNewVersion = true; //this.workOrder.status!='Rework'
          const draft = {
            ...this.workOrderDraft,
            dateSubmitted: data.dateSubmitted,
            lastModifiedDate: data.dateSubmitted,
            workOrderId: getWorkOrderId,
            workOrderRatingId: this.formData.ratingId,
            isNewVersion,
            resourceId: this.utilityFn.getResourceId(),
            transactionCode,
          };
          this.utilityFn.getServerData("post", this.apiList.upsertWorkOrderDraftVersion, draft, (res) => {});

          this.$router.push({ name: "index", query: { tab: 2 } });
        }
      });
    },

    async getFile(dataUrl, fileName) {
      const res = await fetch(dataUrl);      
      const blob = await res.blob();
      return new File([blob], fileName + " - " + this.$route.query.displayId, { type: "image/png" });
    },

    async getFileUpload(dataUrl, fileName) {
      const res = await fetch(dataUrl);
      const blob = await res.blob();
      return new File([blob], fileName + " - " + this.$route.query.displayId, { type: this.imageType });
    },

    getLatestWorkOrderDraft() {
      this.utilityFn.getServerData("get", this.apiList.getWorkOrderDraftVersion + this.$route.query.id, {}, (result) => {
        this.workOrderDraft = result.data.data || {};
      });
    },

    checkRequiredFields() {
      this.utilityFn.getServerData("get", this.apiList.dynamicFormData + "/" + this.$route.query.id, {}, (result) => {
        let requiredFields = [];
        result.data.data.formTabs.forEach(tab => {
          tab.formTabSections.forEach(section => {
            section.rows.forEach(row => {
              row.formAtoms.forEach(atom => {
                if(atom.isRequired && atom.values && (atom.values[0].value == "" || atom.values[0].value == null))
                  requiredFields.push(atom);
              });
            });
          });
        });

        this.missingRequiredFields = requiredFields.length > 0;     
      });      
    },

    onBlur() {
      let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.formData.emailAddress && !pattern.test(this.formData.emailAddress)) {
        this.formData.emailAddress = null;
      }
    },

    // PREVIEW
    computeInspectionResults() {
      let workordersection = this.workOrder.workOrderInspectionSections;
      this.InspectionList.map((x) => {
        let section = workordersection.filter((y) => y.sectionId === x.sectionId);
        (x.result = section[0].result), (x.findings = section[0].findings);
        return x;
      });
    },

    quantityVerResult() {
      let result = true;
      let productLength = this.workOrderProducts.length;
      let workOrderProductResults = this.workOrderProducts.filter((x) => x.workOrderProductResults.length != 0);
      if (productLength == workOrderProductResults.length) {
        let productResultCompare = workOrderProductResults.filter((x) => x.qty == x.workOrderProductResults.availQtyPrd && x.cartonQty == x.workOrderProductResults.availQtyCrtn);
        if (productResultCompare.length == productLength) {
          result = true;
        } else {
          result = false;
        }
      } else {
        result = false;
      }
      return result;
    },

    sizeMeasurementResult() {
      let productsCount = this.workOrderProducts.length;
      let cartonSize = this.workOrderProducts.filter((x) => x.workOrderProductResults && x.workOrderProductResults.rsltSizeCrtn != null);
      let cartonWeight = this.workOrderProducts.filter((x) => x.workOrderProductResults && x.workOrderProductResults.rsltWCrtn != null);
      let productSize = this.workOrderProducts.filter((x) => x.workOrderProductResults && x.workOrderProductResults.rsltSizePrd != null);
      let productWeight = this.workOrderProducts.filter((x) => x.workOrderProductResults && x.workOrderProductResults.rsltWPrd != null);

      return (
        cartonSize.filter((x) => +x.workOrderProductResults.rsltSizeCrtn === 1).length === productsCount &&
        cartonWeight.filter((x) => +x.workOrderProductResults.rsltWCrtn === 1).length === productsCount &&
        productSize.filter((x) => +x.workOrderProductResults.rsltSizePrd === 1).length === productsCount &&
        productWeight.filter((x) => +x.workOrderProductResults.rsltWPrd === 1).length === productsCount
      );
    },

    packageCheckResult() {
      let productsCount = this.workOrderProducts.length;
      let packageCheck = this.workOrderProducts.filter((x) => x.workOrderProductResults && x.workOrderProductResults.pkgCheckResult != null);

      if (!packageCheck) return null;

      return (packageCheck.filter((x) => +x.workOrderProductResults.pkgCheckResult === 1) || []).length === productsCount;
    },

    barcodeVerificationResult() {
      let productsCount = this.workOrderProducts.length;
      let bvCarton = this.workOrderProducts.filter((x) => x.workOrderProductResults && x.workOrderProductResults.bvOverallRsltCrtn != null);
      let bvProduct = this.workOrderProducts.filter((x) => x.workOrderProductResults && x.workOrderProductResults.bvOverallRsltPrd != null);

      if (!bvCarton && !bvProduct) return null;

      return bvProduct.filter((x) => x.workOrderProductResults.bvOverallRsltCrtn === 1).length === productsCount && bvProduct.filter((x) => x.workOrderProductResults.bvOverallRsltPrd === 1).length === productsCount;
    },

    workmanshipResult() {
      let result = false;
      if (this.workOrder.allowedCritical < this.workOrder.foundCritical || this.workOrder.allowedMajor < this.workOrder.foundMajor || this.workOrder.allowedMinor < this.workOrder.foundMinor) {
        result = false;
      } else {
        result = true;
      }
      return result;
    },

    onSiteTestResult() {
      return this.workOrder.onSiteTests.filter((x) => +x.result === 2).length === this.workOrder.onSiteTests.length;
    },

    change_type1(type) {
      if (type == "Critical") {
        this.change_type = 1;
      } else if (type == "Major") {
        this.change_type = 2;
      } else if (type == "Minor") {
        this.change_type = 3;
      }
    },

    addIndex(num) {
      return (num = num + 1);
    },

    overallResult() {
      const rating = this.ratings.find((x) => x.id == this.formData.ratingId);
      return rating ? rating.description : "";
    },

    resultCodeDescription(item) {
      return item.code ? item.code + " - " + item.description : item;
    },

    resultCodeValue(item) {
      return item.code ? item.code : item;
    },

    getTheResult(value, isAM) {
      let valueReturn;
      this.isMinuteValid = true;
      value = parseInt(value);
      if (isAM) {
        if (value < 10) {
          valueReturn = "0" + value;
        } else {
          if (value == 12) {
            valueReturn = "00";
          } else {
            valueReturn = value;
          }
        }
      } else {
        if (value <= 11) {
          valueReturn = value + 12;
        } else {
          valueReturn = 12;
        }
      }
      return valueReturn;
    },

    convertTime(val, isAM) {
        if (val== 'TimeInAM' || val== 'TimeInPM') {
          this.factoryTimeIn =  this.getFactoryTimeIn != null ? this.getTheResult(this.getFactoryTimeIn, isAM) : this.factoryTimeIn
          this.isValueNull = this.getFactoryTimeIn == null ? true : false
        }
        if (val== 'TimeOutAM' || val== 'TimeOutPM') {
          this.factoryTimeOut =  this.getFactoryTimeOut != null ? this.getTheResult(this.getFactoryTimeOut, isAM) : this.factoryTimeOut
          this.isValueNull = this.getFactoryTimeOut == null ? true : false
        }
        if (val== 'StartTimeAM' || val== 'StartTimePM') {
          this.inspectionStartTime =  this.getInspectionStartTime != null ? this.getTheResult(this.getInspectionStartTime, isAM) : this.inspectionStartTime
          this.isValueNull = this.getInspectionStartTime == null ? true : false
        }
        if (val== 'EndTimeAM' || val== 'EndTimePM') {
          this.inspectionEndTime =  this.getInspectionEndTime != null ? this.getTheResult(this.getInspectionEndTime, isAM) : this.inspectionEndTime
          this.isValueNull = this.getInspectionEndTime == null ? true : false
        }

        if (!this.isValueNull) {
          if (this.isMinuteValid) {
              if (val == 'TimeInAM' || val == 'TimeInPM') {
                  if ((this.factoryTimeOut == '00' || this.factoryTimeOut == null) || (Number(this.factoryTimeOut) >= Number(this.factoryTimeIn))) {
                      if (
                          Number(this.inspectionStartTime) > Number(this.factoryTimeIn)
                              || (Number(this.inspectionStartTime) == Number(this.factoryTimeIn) && Number(this.inspectionStartTimeMin) >= Number(this.factoryTimeInMin))
                              || this.inspectionStartTime == '00'
                              || this.inspectionStartTime == null) {
                          this.factoryTimeIn = this.factoryTimeIn
                          this.factoryTimeInMin = this.factoryTimeInMin
                          if (val == 'TimeInAM') {
                              this.timeInAm = true
                              this.timeInPm = false
                          }
                          if (val == 'TimeInPM') {
                              this.timeInAm = false
                              this.timeInPm = true
                          }
                      }
                      else {
                          this.factoryTimeIn = this.getFactoryTimeIn
                          this.messageError = this.errorMessage2
                          this.timeInAm = false
                          this.timeInPm = false
                          this.snackbarError = true;
                      }
                  }
                  else {
                      this.factoryTimeIn = this.getFactoryTimeIn
                      this.messageError = this.errorMessage1
                      this.timeInAm = false
                      this.timeInPm = false
                      this.snackbarError = true;
                  }
              }
              if (val == 'TimeOutAM' || val == 'TimeOutPM') {
                  if (Number(this.factoryTimeOut) > Number(this.factoryTimeIn)
                      || (Number(this.factoryTimeOut) == Number(this.factoryTimeIn) && Number(this.factoryTimeOutMin) >= Number(this.factoryTimeInMin))) {
                      this.factoryTimeOut = this.factoryTimeOut
                      this.factoryTimeOutMin = this.factoryTimeOutMin
                      if (val == 'TimeOutAM') {
                          this.timeOutAm = true
                          this.timeOutPm = false
                      }
                      if (val == 'TimeOutPM') {
                          this.timeOutAm = false
                          this.timeOutPm = true
                      }
                  }
                  else {
                      this.factoryTimeOut = this.getFactoryTimeOut
                      this.messageError = this.errorMessage1
                      this.timeOutAm = false
                      this.timeOutPm = false
                      this.snackbarError = true;
                  }
              }
              if (val == 'StartTimeAM' || val == 'StartTimePM') {
                  if (Number(this.inspectionStartTime) > Number(this.factoryTimeIn)
                      || (Number(this.inspectionStartTime) == Number(this.factoryTimeIn) && Number(this.inspectionStartTimeMin) >= Number(this.factoryTimeInMin))) {
                      if (Number(this.inspectionEndTime) > Number(this.inspectionStartTime)
                          || (Number(this.inspectionEndTime) == Number(this.inspectionStartTime) && Number(this.inspectionEndTimeMin) > Number(this.inspectionStartTimeMin))
                          || this.inspectionEndTime == null
                          || this.inspectionEndTime == '00') {
                          this.inspectionStartTime = this.inspectionStartTime
                          this.inspectionStartTimeMin = this.inspectionStartTimeMin
                          if (val == 'StartTimeAM') {
                              this.startTimeAm = true
                              this.startTimePm = false
                          }
                          if (val == 'StartTimePM') {
                              this.startTimeAm = false
                              this.startTimePm = true
                          }
                      }
                      else {
                          this.inspectionStartTime = this.getInspectionStartTime
                          this.messageError = this.errorMessage3
                          this.startTimeAm = false
                          this.startTimePm = false
                          this.snackbarError = true;
                      }
                  }
                  else {
                      this.inspectionStartTime = this.getInspectionStartTime
                      this.messageError = this.errorMessage2
                      this.startTimeAm = false
                      this.startTimePm = false
                      this.snackbarError = true;
                  }
              }
              if (val == 'EndTimeAM' || val == 'EndTimePM') {
                  if (Number(this.inspectionEndTime) > Number(this.inspectionStartTime)
                      || (Number(this.inspectionEndTime) == Number(this.inspectionStartTime) && Number(this.inspectionEndTimeMin) > Number(this.inspectionStartTimeMin))
                      || this.inspectionStartTime == null
                      || this.inspectionStartTime == '00') {
                      this.inspectionEndTime = this.inspectionEndTime
                      this.inspectionEndTimeMin = this.inspectionEndTimeMin
                      if (val == 'EndTimeAM') {
                          this.endTimeAm = true
                          this.endTimePm = false
                      }
                      if (val == 'EndTimePM') {
                          this.endTimeAm = false
                          this.endTimePm = true
                      }
                  }
                  else {
                      this.inspectionEndTime = this.getInspectionEndTime
                      this.messageError = this.errorMessage3
                      this.endTimeAm = false
                      this.endTimePm = false
                      this.snackbarError = true;
                  }
              }
          }
          else {
              this.messageError = this.errorMessage
              this.snackbarError = true;
          }
        }
    },
    keyUpValue(val, input) {
      let checkIfNumbers = /^[0-9]+$/;
      let isNumber = checkIfNumbers.test(val.target.value);

      if (isNumber && val.target.value != "" && (val.target.value == 0 || Number(val.target.value) <= 12)) {
        if (input == "FactoryTimeIn") {
          this.getFactoryTimeIn = val.target.value;
        }
        if (input == "FactoryTimeOut") {
          this.getFactoryTimeOut = val.target.value;
        }
        if (input == "InspectionStartTime") {
          this.getInspectionStartTime = val.target.value;
        }
        if (input == "InspectionEndTime") {
          this.getInspectionEndTime = val.target.value;
        }
      } else {
        if (val.target.value.length != 0) {
          if (input == "FactoryTimeIn") {
            this.factoryTimeIn = this.getFactoryTimeIn;
          }
          if (input == "FactoryTimeOut") {
            this.factoryTimeOut = this.getFactoryTimeOut;
          }
          if (input == "InspectionStartTime") {
            this.inspectionStartTime = this.getInspectionStartTime;
          }
          if (input == "InspectionEndTime") {
            this.inspectionEndTime = this.getInspectionEndTime;
          }
        }
      }
    },

    keyUpMinValue(val, input) {
      let checkIfNumbers = /^[0-9]+$/;
      let isNumber = checkIfNumbers.test(val.target.value);
      if (isNumber && val.target.value != "" && (val.target.value == 0 || Number(val.target.value) <= 59)) {
        if (input == "FactoryTimeInMin") {
          this.getFactoryTimeInMin = val.target.value;
        }
        if (input == "FactoryTimeOutMin") {
          this.getFactoryTimeOutMin = val.target.value;
        }
        if (input == "InspectionStartTimeMin") {
          this.getInspectionStartTimeMin = val.target.value;
        }
        if (input == "InspectionEndTimeMin") {
          this.getInspectionEndTimeMin = val.target.value;
        }
      } else {
        if (val.target.value.length != 0) {
          if (input == "FactoryTimeInMin") {
            this.factoryTimeInMin = this.getFactoryTimeInMin;
          }
          if (input == "FactoryTimeOutMin") {
            this.factoryTimeOutMin = this.getFactoryTimeOutMin;
          }
          if (input == "InspectionStartTimeMin") {
            this.inspectionStartTimeMin = this.getInspectionStartTimeMin;
          }
          if (input == "InspectionEndTimeMin") {
            this.inspectionEndTimeMin = this.getInspectionEndTimeMin;
          }
        }
      }
    },
    // preview
    previewReport() {
      this.reportData = {
        workOrderId: this.workOrder.workOrderId,
        workOrderDisplayId: this.workOrder.workOrderDisplayId,
        clientId: this.workOrder.clientId,
        clientName: this.workOrder.client,
        reportNumber: this.workOrder.reportNumber,
        workOrderClassId: this.workOrder.workOrderClassId,
        industryId: this.workOrder.industryId,
        workOrderDestinations: this.workOrder.workOrderDestinations,
        status: this.workOrder.status,
        rating: this.workOrder.overallResultDescription,
      };
      this.showReportPreview = true;
    },

    uploadAttachment() {
      const that = this;
      const inputObj = document.createElement("input");
      inputObj.setAttribute("id", "_ef");
      inputObj.setAttribute("type", "file");
      inputObj.setAttribute("style", "visibility:hidden");
      document.body.appendChild(inputObj);
      inputObj.click();
      inputObj.onchange = (res) => {
        if (inputObj.files[0]) {
          that.reportAttachment = inputObj.files[0];
        }
      };
    },

    displayAttachmentName() {
      return this.reportAttachment ? this.reportAttachment.name : "";
    },

    authenticate() {
      this.utilityFn.getServerData("get", this.apiList.getCFRExportAuthentication, {}, (res) => {
        this.report.storeExportAuth(res.data.data);
        this.invokeExport(res.data.data.access_token);
      });
    },

    invokeExport(token) {
      const data = {
        emailAddress: localStorage.getItem("username"),
        reportFormat: this.formData.reportFormat,
        workorderId: this.workOrder.workOrderDisplayId,
      };
      this.utilityFn.cfrExport(this.apiList.cfrExport, data, token, (success, result) => {
        console.log("invokeExport", result);
      });
    },

    // validate time in and time out, return a message if has an error or empty if valid
    validateInspectionTime() {
      const factoryTimeIn = Number(this.padDigit(this.factoryTimeIn) + this.padDigit(this.factoryTimeInMin));
      const factoryTimeOut = Number(this.padDigit(this.factoryTimeOut) + this.padDigit(this.factoryTimeOutMin));
      const inspectionStartTime = Number(this.padDigit(this.inspectionStartTime) + this.padDigit(this.inspectionStartTimeMin));
      const inspectionEndTime = Number(this.padDigit(this.inspectionEndTime) + this.padDigit(this.inspectionEndTimeMin));

      // factory time out should not be before the factory time in
      if (factoryTimeOut < factoryTimeIn) {
        return this.errorMessage1;
      }

      // inspection start time should not be before the factory time in
      if (inspectionStartTime != 0 && inspectionStartTime < factoryTimeIn) {
        return this.errorMessage2;
      }

      // inspection end time should not be equal or before the inspection start time
      if (inspectionEndTime != 0 && inspectionEndTime <= inspectionStartTime) {
        return this.errorMessage3;
      }

      // return empty string if all input times are valid
      return '';
    },
    
    // add padding 0 to single digit
    padDigit(value) {
      const digit = 2;
      return (new Array(digit).fill(0).join('') + value).slice(-digit);
    },
    async getEmailReportNumber(){
        const reportNumber = await this.utilityFn.getServerDataAsync("get", this.apiList.getAndSaveEmailReportDialogReportNumber + "/" + this.$route.params.id, {});
        this.signOffReportNumber = reportNumber.data.item1;
        return true;
    },
  },
  watch: {
    // formData: function (value) {
    //     console.log('Watc FormData', value);
    // }
    formData: {
      handler(value) {
        if (value.emailToSupplier) {
          this.emailRules = [(v) => !!v || "E-mail is required", (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid"];
        } else {
          this.emailRules = [];
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("inspection", {
      _getJobList: (state) => state.jobList,
      _remarksLists: state => state.remarksLists,
      _workorderDetails: state => state.workOrderDetails
    }),

    isFormValid() {
      let isEmailValid = true;
      let isRatingValid = true;
      let isSignatureValid = this.isUploadSignature ? this.image != null :  this.$refs.signaturePad && this.$refs.signaturePad.toData().length > 0;
      let isInspectorSignatureValid = this.$refs.inspectorSignaturePad && this.$refs.inspectorSignaturePad.toData().length > 0;
      let isRepresentativeNameValid = this.representativeName != "";

      let isFactoryTimeInValid = false;
      let isFactoryTimeOutValid = false;
      let isInspectorStartTimeValid = false;
      let isInspectorEndTimeValid = false;

      if (this.factoryTimeIn != null && this.factoryTimeOut != null && this.inspectionStartTime != null && this.inspectionEndTime != null) {
        isFactoryTimeInValid = this.timeInAm == false && this.timeInPm == false ? false : true;
        isFactoryTimeOutValid = this.timeOutAm == false && this.timeOutPm == false ? false : true;
        isInspectorStartTimeValid = this.startTimeAm == false && this.startTimePm == false ? false : true;
        isInspectorEndTimeValid = this.endTimeAm == false && this.endTimePm == false ? false : true;
      }

      // if fail - will be updated later base on the business rules
      if (this.formData.emailToSupplier) {
        isEmailValid = !!this.formData.reportFormat;
      }

      isRatingValid = this.formData.ratingId != null;
      const rating = this.ratings.find((x) => x.id === this.formData.ratingId);

      //this is to check and validate if the client don't  have customResultCode and showFailCOde
      if(!this.isCustomResultCodes && this.showFailCodes){
        if(rating.description == overallResultCodes.ABORT ){
          isRatingValid = this.formData.failCodes.length > 0 && this.formData.failReason && !_.isNull(this.formData.abortMeetExpectations);
        }
        else{
          isRatingValid =  this.formData.failCodes.length > 0 && this.formData.failReason;
        }
      }

      else{
        if (rating) {
          const ratingDescription = rating.description.toLowerCase()
           if(ratingDescription.includes("pass")) {
           isRatingValid =  true
          } else if(ratingDescription.includes("fail")) {
           isRatingValid =  this.formData.failCodes.length > 0;
          } else if(ratingDescription.includes("pending")) {
            isRatingValid =  this.formData.failCodes.length > 0 ;
          } else if(ratingDescription.includes("abort")) {
            isRatingValid = this.formData.failCodes.length > 0  && !_.isNull(this.formData.abortMeetExpectations);
          }
        }
        // if (rating) {
        //   switch (rating.description) {
        //     case overallResultCodes.PASS:
        //       isRatingValid = true;
        //       break;
        //     case overallResultCodes.FAIL:
        //       isRatingValid =  this.formData.failCodes.length > 0;
        //       break;
        //     case overallResultCodes.PENDING:
        //       isRatingValid =  this.formData.pendingCodes.length > 0 ;
        //       break;
        //     case overallResultCodes.ABORT:
        //       isRatingValid = this.formData.abortCodes.length > 0  && !_.isNull(this.formData.abortMeetExpectations);
        //       break;
        //     default:
        //   }
        // }
      }
      return isEmailValid && isRatingValid && isSignatureValid && isInspectorSignatureValid && isRepresentativeNameValid && isFactoryTimeInValid && isFactoryTimeOutValid && isInspectorStartTimeValid && isInspectorEndTimeValid;
    },

    findingSummary() {
      return this.workOrderProductDefects.filter((defect) => {
        return defect.defectClassificationId === this.change_type;
      });
    },

    rating() {
      return this.ratings.find((x) => x.id === this.formData.ratingId);
    },

    showFailCodes() {
      return (
        this.rating &&
        (this.rating.workOrderResultType.description === overallResultCodes.FAIL ||
          ((this.rating.description.toLowerCase().indexOf(overallResultCodes.ABORT.toLowerCase()) != -1 || this.rating.description.toLowerCase().indexOf(overallResultCodes.PENDING.toLowerCase()) != -1) &&
            this.rating.companyWorkOrderResultTypeCodes.length > 0))
      );
    },


    resultCodes() {
      const codes = this.rating ? _.orderBy(this.rating.companyWorkOrderResultTypeCodes.filter(x => x.isActive), ["order"], ["asc"]).map((c) => ({ code: c.code, description: c.description })) : [];
      return codes.length > 0 ? codes : this.failCodes;
    },

    isCustomResultCodes() {
      return !!this.rating && this.rating.companyWorkOrderResultTypeCodes.length > 0;
    },

    resultCodeLabel() {
      // const this.rating = this.ratings.find(x => x.id === this.formData.ratingId);
      if (this.rating && this.rating.companyWorkOrderResultTypeCodes.length > 0) {
        if (this.rating.description.toLowerCase().indexOf(overallResultCodes.ABORT.toLowerCase()) != -1) {
          return "Abortive Code";
        } else if (this.rating.description.toLowerCase().indexOf(overallResultCodes.PENDING.toLowerCase()) != -1) {
          return "Pending Code";
        }
      }
      return "Fail Code";
    },

    failReasonDescription() {
      if (this.showFailCodes && this.rating) {
        return `Please provide a descriptive ${this.rating.description} reason. *`;
      }
      return '';
    },
  },
};
</script>

<style lang="stylus" scoped>
.mr-right-onept7
   margin-right 1.7rem
.mr-right-pt7
   margin-right 0.7rem
.mr-right-pt5
    margin-right 0.5rem
.mr-right-3
    margin-right 3rem
.pastInspectionsSignOff
    background #fff
    padding 15px

.top_title
    color red
    font-size 16px

.required
    color red


.submit
    color #fff
    display block
    margin 40px auto
    width 60%

 #btns  {
    float: right;
    background: rgb(0, 118, 255);
    color: #fff;
}

button {
    height: 40px;
    width: 100px;
}

.fail_code
    padding 20px;
    padding-bottom 0px;
.fail_code p
    margin-bottom 5px;

.btnSize
    height: 30px;
    width: 1%;
    color: #000;

#preview-container
    height 100%

#preview-dialog
    display flex
    flex-direction column
    height 98vh

#preview-dialog .v-card__text
    flex-grow 1
    height 85%

>>> .v-dialog
    max-height 100%

>>> .v-input input
    max-height: 80px
    right: 2px;
    position: relative;

.hrClass
    text-align: center;
    width: 107px;
    border-radius: 10px;
    height: 74px;
    font-size: 70px;

.colonClass
    font-size: 100px;
    font-family: 'Material Design Icons';
    position: relative;
    bottom: 31px;

.divClass
    top: 25px;
    position: relative;
    right: 20px;

.hintClass
    color: red;
    font-style: italic;
    position: relative;
    padding-left: 30px;
    text-align: center;
    top: 15px;

.rowClass
    text-align: center;

.colClass
    text-align: left;

.colPadding
    padding-left: 30px;

.btnSpacing
    bottom: 5px;

>>> .fileInputSignature
    display: none;

>>> .fileInputSignature fileInputSignature--loaded
    display: none;

</style>
