<template>
  <div>
    <div class="page-error">
      <img src="../../assets/images/top.png" />
      <img class="logo_img" src="../../assets/images/logo.png" />
      <v-row class="formDate">
        <v-col cols="12" sm="12" md="12">
          <p>You do not have access to this application. Please contact your respective coordination office Service Specialist to assist you with your queries or requirement.</p>
        </v-col>
      </v-row>
      <v-btn class="submit" dark color="#B14C4C" @click="submit">Logout</v-btn>
    </div>
  </div>
</template>

<script>
  import { PublicClientApplication } from '@azure/msal-browser';
export default {
  name: "unauthorized",
  computed: {
  },
  methods: {
    submit() {
      this.$msalInstance = new PublicClientApplication (
        this.$store.state.msalConfig,
      );

      //remove token since user user don't have access
      localStorage.removeItem("tokenInfo");

      this.$msalInstance.logoutRedirect();
    },
  }
};
</script>

<style lang="stylus" scoped>
.page-error
    background #fff
    position absolute
    top 0
    bottom 0
.logo_img
    display block
    margin 10% auto 5%
.formDate
    width 80%
    margin auto

.submit
    display block
    margin 7% auto 0;
    width 30%
</style>
