import axios from 'axios'
import apiDetails from '../request/apiDetails';
import apiList from "../request/apiList";

let remarksAPI = {}


remarksAPI.upsertWorkOrderRemark = async function (formData) {
	let data;
	await axios.post(apiList.baseUrl + '/api/AIMS/UpsertWorkOrderRemark/', formData , 
	{
		headers:apiDetails.header.headersDetail,
	}).then((response)=>{
		data = response.data
	}).catch((response)=>{
	    console.log(response);
	})
	return data;
}

remarksAPI.getWorkOrderRemarks = async function (workOrderId) {
	let data;
	await axios.get(apiList.baseUrl + '/api/AIMS/GetWorkOrderRemarks/' + workOrderId, 
	{
		headers:apiDetails.header.headersDetail,
	}).then((response)=>{
		data = response.data
	}).catch((response)=>{
	    console.log(response);
	})
	return data;
}

remarksAPI.deleteWorkOrderRemark = async function (id) {
	let data;
	await axios.delete(apiList.baseUrl + '/api/AIMS/DeleteWorkOrderRemark/' + id,
	{
		headers:apiDetails.header.headersDetail,
	}).then((response)=>{
		data = response.data
	}).catch((response)=>{
	    console.log(response);
	})
	return data;
}


export default remarksAPI