#DataTable

<template>
    <div>
        <v-data-table
        :headers="columns"
        :items="datasource"
        :items-per-page="-1"        
        class="elevation-1">
            <template v-slot:body="props">
                <tbody>
                    <tr v-for="(item, rowIndex) in props.items">
                        <td v-for="(column, colIndex) in columns">
                            <div v-if="column.value === 'action'" style="display: flex">
                                <v-btn icon v-if="atLeastOneValue(rowIndex)" @click="deleteRow(rowIndex)"><v-icon color="rgba(0,0,0,.87)">delete</v-icon></v-btn>    
                                <v-btn icon v-if="atLeastOneValue(rowIndex)" @click="copyRow(rowIndex)"><v-icon color="rgba(0,0,0,.87)">content_copy</v-icon></v-btn>                            
                            </div>               
                            <div v-else>
                                <!--Textbox-->
                                <v-text-field v-model="item[column.text]" v-if="column.type == 'textbox'" :readonly="!column.editable" :maxlength="column.maxLength"></v-text-field>                                

                                <!--Number-->
                                <v-text-field v-model="item[column.text]" v-if="column.type == 'number'" type="number" :readonly="!column.editable"></v-text-field>   

                                <!--Date Picker-->
                                <template v-if="column.type == 'date'">
                                    <v-menu 
                                        ref=dateMenu[rowIndex][colIndex]
                                        v-model="dateMenu[rowIndex][colIndex]" 
                                        :close-on-content-click="true"
                                        transition="scale-transition"
                                        min-width="auto"
                                        offset-y
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field 
                                            append-icon="event" 
                                            v-on="on"                                             
                                            v-bind="attrs"
                                            v-model="item[column.text]"
                                            readonly></v-text-field>
                                        </template>
                                        <v-date-picker v-model="item[column.text]" no-title color="blue darken-1"></v-date-picker>
                                    </v-menu>
                                </template>
                            </div>                                                        
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
        <br/>
        <v-btn elevation="2" @click="addRow()">Add Row</v-btn>
        <v-btn elevation="2" style="float:right" @click="saveTable()">Save</v-btn>
    </div>
</template>

<script>

export default {
    name: 'DataTable',
    data (){
        return {
            datasource: [],
            columns: [],
            dateMenu: [[]],         
        }
    },
    computed: {
    },
        mounted() {
            //datasource will come from columnatoms value property           
            if(this.formAtom.values[0].value) {
                this.datasource = JSON.parse(this.formAtom.values[0].value);

                this.datasource.forEach(x=> {
                    this.dateMenu.push([]);
                });
            }

            console.log(this.columnAtoms);

            if(this.columnAtoms) {
                this.columnAtoms.forEach(column => {
                    if(column.columnSize === 0)
                        this.columns.push({
                                text: column.label, 
                                value: column.label, 
                                type: column.controlType, 
                                editable: column.isEditable,
                                maxLength: column.maxLength ? column.maxLength : 1000
                            });
                });

                this.columns.push({text: '', value: 'action', editable: false});
            }

            if(this.datasource.length < 1)
                this.addRow();
    },
    methods: {
        addRow() {
            //need to create new row for dateMenu to display menu
            this.dateMenu.push([]);

            let newRow = {};

            this.columns.forEach(col => {
                if(col.text)
                    newRow[col.text] = null;
            });
            this.datasource.push(newRow);
        },
        saveTable() {
            let value = JSON.stringify(this.datasource);

            this.storeConditionalKeyValue(this.formAtom.formAtomID, value);
                       
            var data = [
            {
                formAtomID: this.formAtom.formAtomID,
                value: value,
                referenceKey: null,
            }];

            this.utilityFn.getServerData("post", this.apiList.baseUrl + "/api/AIMS/UpsertFormValue/" + this.$route.params.id, data, (res) => {
                //console.log(res);
            });
        },
        atLeastOneValue(index) {
            //check if at least one of the cells in this row has a value

            let row = this.datasource[index];

            for (let key in row) {
                if(row[key])
                    return true;
            }

            return false;
        },
        deleteRow(index) {
            let retVal = confirm('Delete Row #'+(index+1)+'?');
            if(retVal)
                this.datasource.splice(index, 1);
        },
        copyRow(index) {
            let copy = {...this.datasource[index]};

            //need to create new row for dateMenu to display menu
            this.dateMenu.push([]);
            this.datasource.splice(index, 0, copy);
        },
        storeConditionalKeyValue(id, val) {
            //if fieldreference contains conditional key store it
            if (this.conditionalkey) {
                const value = this.msgData.fieldLookupValues ? this.msgData.fieldLookupValues.result.find((x) => x.key == val) : null;
                if (value) {
                var keyvalue = {
                    formAtomID: String(id),
                    value: String(value.value),
                };
                this.$emit("childToParentConditionalField", keyvalue);
                }
            }
        }
    },
    props: {
        formAtom:{
            type: Object
        },
        columnAtoms:{
            type: Array
        }
    }
};
</script>

<style scoped>
    >>> .v-input__slot::before {
        border-style: none !important;
    }
</style>
