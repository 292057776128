<template>
  <div>
    <div class="FormBuildAdd">
      <div style="background:#D5001F;width:100;height:50px;">
        <img src="../../assets/images/logo.png" style="width:40px;margin-left:15px;margin-top:5px;" />
        <span style="float:right;color:#fff;margin-right:15px;margin-top:10px;font-size:18px;">menu</span>
      </div>
      <div style="background:#fff;">
        <v-row>
          <v-col cols="3" sm="3" md="3" style="padding:5px 0 20px;">
            <v-tabs v-model="tab1" background-color="#fff" color="#538DB2" style="padding-left:25px;">
              <v-tab href="#tab-1">Form Fields</v-tab>
              <v-tab href="#tab-2">Settings</v-tab>
            </v-tabs>
          </v-col>
          <v-col cols="9" sm="9" md="9" style="padding-top:25px;">
            <span>Form Confirguration Name</span>
            <input v-model="formBuilderData.name" type="text" style="border:1px #ccc solid;" />
            <span style="margin-left:25px;">Form Page Title</span>
            <input v-model="formBuilderData.pageTitle" type="text" style="border:1px #ccc solid;" />
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col cols="5" sm="5" md="5" style="padding-top:0" class="content_menu">
          <v-tabs-items v-model="tab1">
            <v-tab-item :value="'tab-1'">
              <v-expansion-panels accordion dark>
                <v-expansion-panel>
                  <v-expansion-panel-header>Preset Fields</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list v-for="(item, index) in fieldNameList" :key="index">
                      <div :id="item.id" fieldType="preset" draggable="true" @dragstart="drag($event)">{{ item.name }}</div>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Custom Fields</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list v-for="(item, index) in fieldTypeList" :key="index">
                      <!-- <div :id="'custom' + item.id" dataMode="custom" :fieldType="item.name" draggable="true" @dragstart="drag($event)">{{item.name}}</div> -->
                      <div :id="item.id" fieldType="custom" draggable="true" @dragstart="drag($event)">{{ item.name }}</div>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- <v-expansion-panel>
                                    <v-expansion-panel-header>Lists and Tables</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-list>Table</v-list>
                                        <v-list>List</v-list>
                                    </v-expansion-panel-content>
                                </v-expansion-panel> -->
              </v-expansion-panels>
            </v-tab-item>
            <v-tab-item :value="'tab-2'" class="fileEditBox">
              <v-row v-show="settingTpe == 'text'">
                <v-col cols="7" sm="7" md="7">
                  <p>Label</p>
                  <input type="text" value="County" />
                </v-col>
                <v-col cols="5" sm="5" md="5">
                  <p>Layout Width</p>
                  <select type="text">
                    <option value="1">33% of row</option>
                  </select>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-0">
                  <v-checkbox style="margin-left:3.8%;" value="true" label="required" dark color="rgb(54, 148, 251)"></v-checkbox>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-0">
                  <p style="margin-left:4%;">Label Display</p>
                  <v-radio-group class="mt-0" style="margin-left:3.8%;" hide-details>
                    <v-radio color="rgb(54, 148, 251)" dark v-for="n in 4" :key="n" :label="`Radio ${n}`" :value="n"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-checkbox class="mb-3" color="rgb(54, 148, 251)" style="margin-left:3.8%;" value="true" label="Show Tooltip Icon" dark hide-details></v-checkbox>
                  <textarea></textarea>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-checkbox class="mb-3" color="rgb(54, 148, 251)" style="margin-left:3.8%;" value="true" label="Instructions for users(Below)" dark hide-details></v-checkbox>
                  <textarea></textarea>
                </v-col>
              </v-row>
              <v-row v-show="settingTpe == 'table'">
                <v-col cols="7" sm="7" md="7">
                  <p>Table Header</p>
                  <input type="text" value="CAPA" />
                </v-col>
                <v-col cols="5" sm="5" md="5">
                  <p>Layout Width</p>
                  <select type="text">
                    <option value="1">100% of row</option>
                  </select>
                </v-col>
                <v-col cols="7" sm="7" md="7" style="padding-bottom:0px;">
                  <p style="margin-top:0px;margin-bottom:0px;">Table Builder</p>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-row style="color:#fff;background:rgb(29, 29, 29);margin:0;">
                    <v-col cols="7" sm="7" md="7" class="pl-2 pr-0">
                      <div style="height:30px;font-size:14px;padding-left:5px;">
                        <span>Column Items</span>
                      </div>
                      <ul class="body-2" style="border:1px #eee solid;line-height:35px;height:250px;padding-left:0px;">
                        <li style="background:rgb(54, 148, 251);padding-left:5px;">(1) Text Column</li>
                        <li style="padding-left:5px;">(2) Text Column</li>
                        <li style="padding-left:5px;">(3) Dropdown Column</li>
                        <li style="padding-left:5px;">(4) Checkbox Column</li>
                        <li style="padding-left:5px;">(5) Hyperlink Column</li>
                      </ul>
                      <div>
                        <v-btn x-small tile>
                          <v-icon small dark>delete</v-icon>
                        </v-btn>
                        <v-btn x-small tile>
                          <v-icon small dark>arrow_upward</v-icon>
                        </v-btn>
                        <v-btn x-small tile>
                          <v-icon small dark>arrow_downward</v-icon>
                        </v-btn>
                        <select name="" id="" style="padding: 0;width: 90px;display: inline-block;height: 21px;">
                          <option value="">Text Column</option>
                        </select>

                        <v-btn x-small tile>
                          ADD
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col cols="5" sm="5" md="5" class="style_box">
                      <div style="height:30px;font-size:14px;">
                        <span class="active_style">Properties</span>
                        <span class="" style="margin-left:15px;">Data</span>
                      </div>
                      <div style="border:1px #eee solid;height:250px;">
                        <div>
                          <label class="body-2" style="padding-left: 10%;">Name</label>
                          <input style="width: 90px;height: 20px;display: inline;background:transparent;" type="text" value="CODE" />
                        </div>
                        <div>
                          <label class="body-2" style="padding-left: 10%;">Editable</label>
                          <input style="width: 40px;display: inline-block;margin: 0; vertical-align: middle;" type="checkbox" value="CODE" />
                        </div>
                        <div>
                          <div class="body-2" style="margin: 7px 5px 0;">Layout</div>
                        </div>
                        <div>
                          <label class="body-2" style="padding-left: 10%;">width</label>
                          <input class="body-2" style="width: 90px;height: 20px;display: inline;" type="text" value="Auto" />
                        </div>
                        <div>
                          <label class="body-2" style="padding-left: 10%;vertical-align: super;">Align</label>
                          <span class="active-text-layout" style="width:21px;height:21px;display:inline-block;background:#fff;line-height:20px;padding-top:1px;margin-left:3%;">
                            <p style="width:70%;border:1px #ccc solid;margin:2px;"></p>
                            <p style="width:50%;border:1px #ccc solid;margin:2px;"></p>
                            <p style="width:70%;border:1px #ccc solid;margin:2px;"></p>
                            <p style="width:50%;border:1px #ccc solid;margin:2px;"></p>
                          </span>
                          <span style="width:21px;height:21px;display:inline-block;background:#fff;line-height:20px;padding-top:1px;">
                            <p style="width:70%;border:1px #ccc solid;margin:2px auto;"></p>
                            <p style="width:50%;border:1px #ccc solid;margin:2px auto;"></p>
                            <p style="width:70%;border:1px #ccc solid;margin:2px auto;"></p>
                            <p style="width:50%;border:1px #ccc solid;margin:2px auto;"></p>
                          </span>
                          <span style="width:21px;height:21px;display:inline-block;background:#fff;line-height:20px;padding-top:1px;padding-right:3px;">
                            <p style="width:70%;border:1px #ccc solid;margin:2px 0 0 30%;"></p>
                            <p style="width:50%;border:1px #ccc solid;margin:2px 0 0 50%;;"></p>
                            <p style="width:70%;border:1px #ccc solid;margin:2px 0 0 30%;;"></p>
                            <p style="width:50%;border:1px #ccc solid;margin:2px 0 0 50%;;"></p>
                          </span>
                        </div>
                        <div>
                          <label class="body-2" style="padding-left:5px;">Col Order</label>
                          <input type="text" style="width: 90px;height: 20px;display: inline;" value="01" />
                        </div>
                        <div>
                          <label class="body-2" style="width:80px;padding-left:5px;">Contro Type</label>
                          <select name="" id="" style="height: 20px;display: inline;">
                            <option>input</option>
                          </select>
                        </div>
                      </div>
                      <div style="padding-top:7px;text-align:right;">
                        <v-btn x-small tile>
                          OK
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-checkbox class="mb-3" color="rgb(54, 148, 251)" style="margin-left:3.8%;margin-top:0;" value="true" label="Instructions for users(Above)" dark hide-details></v-checkbox>
                  <textarea style="widht:100%;"></textarea>
                </v-col>
                <v-col cols="12" sm="12" md="12" style="text-align:right;padding-right:8%;">
                  <v-btn outlined dark style="border-color:#8c8c8c">Cancel</v-btn>
                  <v-btn outlined dark style="margin-left:15px;background:#000;border-color:#8c8c8c">Apply</v-btn>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <v-col cols="7" sm="7" md="7">
          <v-row no-gutters>
            <v-col cols="4" sm="4" md="4">
              <v-btn color="#00538A" tile depressed dark @click="addTab"> <v-icon left dark>add</v-icon>Add New Tab </v-btn>
            </v-col>
            <v-col cols="8" sm="8" md="8" style="text-align:right;">
              <v-btn color="#0BA04C" tile depressed dark @click="submit_save_form">
                Save Form
              </v-btn>
              <v-btn color="#0A0A0A" tile depressed dark style="margin-right:20px;" @click="quick_preview">
                Quick Preview
              </v-btn>
            </v-col>
          </v-row>
          <div style="background:#fff;margin-right:20px;margin-top:10px;padding:15px;" class="content_box">
            <v-expansion-panels>
              <v-expansion-panel v-for="(item, index) in formBuilderData.formTabs[0].formTabSections" :key="index">
                <v-expansion-panel-header style="background:#eee;" v-slot="{ open }">
                  <v-row no-gutters>
                    <v-col cols="2" style="padding-top:15px;"
                      ><strong>Tab{{ index + 1 }}</strong></v-col
                    >
                    <v-col cols="4" style="padding-top:15px;">Tab Name <input type="text" style="border:1px #ccc solid;width:50%;background:#fff;" v-model="item.name" @click.stop="return false;"/></v-col>
                    <v-col cols="3">
                      <v-btn class="ma-2" tile text color="#538DB2" @click.stop="showSidebar(item)"> <v-icon left>add_circle</v-icon> Add Row in Tab {{ index + 1 }} </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn class="ma-2" tile text color="#538DB2" @click.stop="removeTab(item)"> <v-icon left>remove_circle</v-icon> Remove Tab </v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list v-for="(row_item, index) in item.rows" :key="index">
                    <v-row no-gutters style="border:1px #62BEE8 solid;padding: 20px 5px 10px;box-shadow:inset 0px 20px 0px -10px rgba(98, 190, 232, .3);">
                      <v-col cols="12" style="text-align:right;">
                        <span @click="delete_row(item.rows, row_item)" style="margin-right:15px;">
                          <v-icon rigth color="#538DB2">remove_circle</v-icon>
                        </span>
                        <span @click="editRow(row_item)" style="margin-right:15px;">
                          <v-icon rigth color="#538DB2">edit</v-icon>
                        </span>
                      </v-col>
                      <v-col v-for="(row_item_childs, index) in row_item.formAtoms" :key="index" :cols="row_item_childs.columnSize">
                        <div :class="row_item_childs.fieldType ? 'filedBox activeFiledBox' : 'filedBox'" @drop="drop($event, row_item_childs)" @dragover="allowDrop($event)">
                          <Field :msgData="row_item_childs" :other="row_item_childs.fieldType" @msgFnEdit="edit_field"></Field>
                        </div>
                      </v-col>
                    </v-row>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>
      </v-row>
      <v-navigation-drawer v-model="drawer" absolute temporary right width="400">
        <v-list-item class="layout_example_header">
          <v-list-item-content>
            <v-list-item-title>Choose the layout for Tab 1 Row 3</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list dense class="layout_example_list" style="border-radius: 0;">
          <v-list-item>
            <v-list-item-content @click="addRow(1)">
              <div>
                <span style="border:1px #ccc solid;display:inline-block;width:100%;height:35px;"></span>
              </div>
              <p>One Column</p>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content @click="addRow(2)">
              <div>
                <span style="border:1px #ccc solid;display:inline-block;width:50%;height:35px;border-left-style:dashed;"></span>
                <span style="border:1px #ccc solid;display:inline-block;width:50%;height:35px;float:left;border-right:none;"></span>
              </div>
              <p>Two Column</p>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content @click="addRow(3)">
              <div>
                <span style="border:1px #ccc solid;display:inline-block;width:33.33%;height:35px;border-left-style:dashed;"></span>
                <span style="border:1px #ccc solid;display:inline-block;width:33.33%;height:35px;float:left;border-right:none;"></span>
                <span style="border:1px #ccc solid;display:inline-block;width:33.33%;height:35px;float:left;border-left-style:dashed;border-right:none;"></span>
              </div>
              <p>Three Column</p>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content @click="addRow(4)">
              <div>
                <span style="border:1px #ccc solid;display:inline-block;width:25%;height:35px;border-left-style:dashed;"></span>
                <span style="border:1px #ccc solid;display:inline-block;width:25%;height:35px;float:left;border-right:none;"></span>
                <span style="border:1px #ccc solid;display:inline-block;width:25%;height:35px;float:left;border-right:none;border-left-style:dashed;"></span>
                <span style="border:1px #ccc solid;display:inline-block;width:25%;height:35px;float:left;border-right:none;border-left-style:dashed;"></span>
              </div>
              <p>Four Column</p>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content @click="addRow(6)">
              <div>
                <span style="border:1px #ccc solid;display:inline-block;width:16.66%;height:35px;border-left-style:dashed;"></span>
                <span style="border:1px #ccc solid;display:inline-block;width:16.66%;height:35px;float:left;border-right:none;"></span>
                <span style="border:1px #ccc solid;display:inline-block;width:16.66%;height:35px;float:left;border-right:none;border-left-style:dashed;"></span>
                <span style="border:1px #ccc solid;display:inline-block;width:16.66%;height:35px;float:left;border-right:none;border-left-style:dashed;"></span>
                <span style="border:1px #ccc solid;display:inline-block;width:16.66%;height:35px;float:left;border-right:none;border-left-style:dashed;"></span>
                <span style="border:1px #ccc solid;display:inline-block;width:16.66%;height:35px;float:left;border-right:none;border-left-style:dashed;"></span>
              </div>
              <p>Six Column</p>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>
      </v-navigation-drawer>

      <v-dialog v-model="quick_preview_dialog" max-width="900px" class="quick_preview_dialog">
        <v-card>
          <v-card-title>
            <span style="color:rgb(0, 83, 138);margin-top:20px;">Food Safety Inspection</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col offset-sm="3" cols="9" sm="9" md="9">
                  <v-tabs color="rgb(0, 83, 138)">
                    <v-tab>General Information</v-tab>
                    <v-tab>Violation Form</v-tab>
                  </v-tabs>
                </v-col>
                <v-col cols="3" sm="3" md="3">
                  <h3>Blackout Dates</h3>
                </v-col>
                <v-col cols="9" sm="9" md="9">
                  <!-- <Field :msgData="row_item_childs" :other="row_item_childs.fieldType"></Field> -->
                  <p>Date</p>
                  <input style="border:1px #ccc solid;width:100%;" type="date" />
                </v-col>
                <v-col cols="3" sm="3" md="3">
                  <h3>Submit Date</h3>
                </v-col>
                <v-col cols="9" sm="9" md="9">
                  <v-checkbox value="true" label="First audit of the day?(please check if this is your first audit of the day)"></v-checkbox>
                </v-col>
                <v-col offset-sm="3" cols="3" sm="3" md="3">
                  <p>Time left home</p>
                  <input style="border:1px #ccc solid;width:100%;" type="date" />
                </v-col>
                <v-col cols="3" sm="3" md="3">
                  <p>Time got home</p>
                  <input style="border:1px #ccc solid;width:100%;" type="date" />
                </v-col>
                <v-col offset-sm="3" cols="3" sm="3" md="3">
                  <p>Audit Time In</p>
                  <input style="border:1px #ccc solid;width:100%;" type="date" />
                </v-col>
                <v-col cols="3" sm="3" md="3">
                  <p>Audit Time Out</p>
                  <input style="border:1px #ccc solid;width:100%;" type="date" />
                </v-col>
                <v-col offset-sm="3" cols="9" sm="9" md="9">
                  <v-checkbox value="true" label="Last Audit of the day?(please check if this is your first audit of the day)"></v-checkbox>
                </v-col>
                <v-col offset-sm="3" cols="6" sm="6" md="6">
                  <p>Specialist Name</p>
                  <input style="border:1px #ccc solid;width:100%;" type="test" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="quick_preview_dialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- <ul>
                <li id="aa">
                    <div style="background:#ccc;width:100px;height:100px">123</div>
                </li>
                <li id="bb">
                    <div style="background:#4848;width:100px;height:100px">2313</div>   </li>
            </ul> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dragula from "dragula";
import Field from "../../components/forms/FormBuildAdd";

function setState(store) {
  store.dispatch("navigation/setAppHeader", {
    show: false,
    showTitle: false,
    showMenu: true,
    showBack: false,
    showLogo: false,
    showSearch_job: true,
    showSearch_work: false,
    showCalendar: true,
  });
}

export default {
  name: "FormBuildAdd",
  components: {
    Field,
  },
  data() {
    return {
      //
      tab1: null,
      //
      settingTpe: "",
      // tabl 列表
      formBuilderData: {
        id: "",
        displayID: "",
        industryID: "",
        industry: "",
        clientID: "",
        client: "",
        workOrderClassId: "",
        workOrderClass: "",
        name: "",
        pageTitle: "",
        template: "",
        createDate: "",
        lastModifiedDate: "",
        isActive: "",
        formTabs: [
          {
            formTabSections: [],
          },
        ],
      },

      // 字段类型列表
      fieldTypeList: [
        {
          id: "",
          name: "",
          isActive: true,
        },
      ],

      // 字段名称列表
      fieldNameList: [],

      // 侧边滑动栏
      drawer: false,
      thisSelect: {},

      quick_preview_dialog: false,
      dialogForm: {},
    };
  },
  mounted() {
    // set head content
    setState(this.$store);

    // 获取详情数据
    this.utilityFn.getServerData("get", this.apiList.getForm + "/" + this.$route.params.id, {}, (res) => {
      this.formBuilderData = res.data.data;
      console.log("getFormData", this.formBuilderData);
    });

    // 获取字段库详情数据
    this.utilityFn.getServerData("get", this.apiList.getFieldLibraries, {}, (res) => {
      this.fieldNameList = res.data.data;
      // this.get_authorized_list(res.data.data[0].workOrderInspectionSections);
    });

    // 获取控件类型详情数据
    this.utilityFn.getServerData("get", this.apiList.getAtoms, {}, (res) => {
      this.fieldTypeList = res.data.data;
    });
  },
  methods: {
    showSidebar(item) {
      this.thisSelect = item;
      this.drawer = !this.drawer;
    },
    addTab() {
      if (this.formBuilderData.formTabs.length == 0) {
        this.formBuilderData.formTabs.push({ formTabSections: [] });
      }

      this.formBuilderData.formTabs[0].formTabSections.push({
        id: "",
        name: "",
        order: "",
        isActive: true,
        rows: [],
      });
    },
    removeTab(item) {
      var index = this.formBuilderData.formTabs[0].formTabSections.indexOf(item);
      if (confirm("Are you sure you want to delete this item?")) {
        this.formBuilderData.formTabs[0].formTabSections.splice(index, 1);
      }
    },
    delete_row(arr, item) {
      var index = arr.indexOf(item);
      if (confirm("Are you sure you want to delete this item?")) {
        arr.splice(index, 1);
      }
    },
    editRow(item) {
      this.drawer = !this.drawer;
    },
    addRow(number) {
      var arr = [];
      for (var i = 0; i < number; i++) {
        arr.push({
          id: "",
          label: "",
          atomID: "",
          atom: "",
          isCreatable: "",
          isEditable: "",
          saveAsValue: "",
          order: "",
          columnSize: 12 / number,
          isActive: "",
          validations: "",
          style: "",
        });
      }

      this.thisSelect.rows.push({
        formAtoms: [...arr],
      });

      this.thisSelect = {};
      this.drawer = !this.drawer;
    },
    // 拖拽的方法
    drag(ev) {
      ev.dataTransfer.setData("Text", ev.target.id);
    },
    // 放入方法
    drop(ev, item) {
      ev.preventDefault();

      // 获取拖入数据信息
      var data = ev.dataTransfer.getData("Text");
      var id = document.getElementById(data).getAttribute("id");
      var fieldType = document.getElementById(data).getAttribute("fieldType");

      // 查找数据并添加
      this.addCustomField(fieldType, id, item);

      // ev.target.appendChild(document.getElementById(data));
    },
    // 用于组织托转的默认移动事件只执行一次
    allowDrop(ev) {
      ev.preventDefault();
    },

    // 根据不同类型添加数据
    addCustomField(fieldType, id, item) {
      var index = "";
      switch (fieldType) {
        case "custom":
          index = this.fieldTypeList.findIndex((x) => {
            return x.id == id;
          });
          item.atom = this.fieldTypeList[index].name;
          break;
        case "preset":
          index = this.fieldNameList.findIndex((x) => {
            return x.id == id;
          });
          item.atom = this.fieldNameList[index].atom.name;
          item.label = this.fieldNameList[index].defaultLabel;
          break;
      }
      // this.$forceUpdate()
    },
    // 预览表单
    quick_preview() {
      this.quick_preview_dialog = true;
    },
    submit_save_form() {
      this.utilityFn.getServerData("patch", this.apiList.addDynamicFormBuilderApi, this.formBuilderData, (res) => {
        alert("Saved successfully");
      });
    },
    // 编辑字段样式等详细信息
    edit_field(type) {
      // alert(type)
      this.tab1 = "tab-2";
      switch (type) {
        case 1:
          this.settingTpe = "text";
          break;
        case 2:
          this.settingTpe = "table";
          break;
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.FormBuildAdd
    margin-top -50px
.v-expansion-panel:before
    box-shadow none;
.content_menu .v-expansion-panels
    border-radius 0;

.content_menu .v-expansion-panel-header
    background #272727;
    // color: #fff;
>>>.content_box .v-expansion-panel-content > div
    padding 10px 0 0;

.layout_example_header
    background #151515;
.layout_example_header .v-list-item__title
    color #A7A7A7 !important;
.layout_example_list
    background #393939;
.layout_example_list p
    font-size 14px;
    margin-top 10px;
    color #A7A7A7;
.filedBox
    border:1px #ccc dashed;
    margin:15px 7px 7px 7px;
    text-align:center;
    position:relative;
    background:rgba(98, 190, 232, .3);
    border-color:rgba(98, 190, 232, .3);
.activeFiledBox
    background: #fff;
    border-color: #ccc;

// 自定义表单数据样式编辑菜单
.fileEditBox
    background: #424242;

.fileEditBox p
    color:#fff;
    width 90%;
    margin 10px auto;
    font-size 15px;

.fileEditBox input
    background #fff;
    width 90%;
    margin 10px auto;
    padding 7px 10px;
    display block;
    font-size 14px

.fileEditBox select
    width 90%;
    height 35px;
    background #fff;
    margin 10px auto;
    padding 7px 10px;
    display block;
    font-size 14px;
    border 1px #ccc solid;
    appearance button;

.fileEditBox textarea
    width 92%;
    height 100px;
    background #fff;
    display block;
    margin-left: 4%;
    resize: none;
.active-text-layout p
    border-color rgb(87, 180, 241) !important;

.style_box label
    font-size:12px !important;
    width: 65px;
    display: inline-block;

.style_box input
    background: transparent;
    border: 1px #616161 solid;
    margin-left: 3%;
    font-size:12px;
.style_box select
    background: transparent;
    border: 1px #616161 solid;
    vertical-align: middle;
    padding: 0;
    width:70px;

.active_style
    position relative;

.active_style::before
    content: '';
    position absolute;
    background: #fff;
    height:2px;
    left: 0;
    right: 0;
    bottom: -5px;
</style>
