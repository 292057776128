export const SET_JOB_LIST = 'SET_JOB_LIST';
export const SET_DOWNLOAD_NUMBER = 'SET_DOWNLOAD_NUMBER';
export const SET_RESOURCE_NAME = 'SET_RESOURCE_NAME';
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const SET_WORKORDER_DETAILS = 'SET_WORKORDER_DETAILS';
export const SET_ONLINE = 'SET_ONLINE';
export const SET_WORK_LIST = 'SET_WORK_LIST';
export const SET_CLIENT_DEFECT_LIST = 'SET_CLIENT_DEFECT_LIST';
export const SET_SECTION_DATA = 'SET_SECTION_DATA';
export const SET_SAMPLING_PLAN_LIST = 'SET_SAMPLING_PLAN_LIST';
export const SET_FORM_ATOM_LIST = 'SET_FORM_ATOM_LIST';
export const EDIT_MY_AUTHORIZED = 'EDIT_MY_AUTHORIZED';
export const SET_REMARKS_LIST = 'SET_REMARKS_LIST';
export const SET_MY_AUTHORIZED = 'SET_MY_AUTHORIZED';
export const SET_UPDATED_REMARKS_ENTRY = 'SET_UPDATED_REMARKS_ENTRY';
export const SET_ENABLED_WO_SEARCH ='SET_ENABLED_WO_SEARCH';
export const SET_QV_LIST = 'SET_QV_LIST';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_PROD_QUAN_VERF_LIST = 'SET_PROD_QUAN_VERF_LIST';
export const SET_CHILD_WO_SIZE_MEASURE = 'SET_CHILD_WO_SIZE_MEASURE';
export const SET_INSPECTION_SUMMARY_SECTION_RESULT = 'SET_INSPECTION_SUMMARY_SECTION_RESULT'
export const SET_LOGGEDIN = 'SET_LOGGEDIN';
export const SET_CLIENT_DEFECTS_LOADING_STATUS = 'SET_CLIENT_DEFECTS_LOADING_STATUS';
export const SET_CURRENT_ACTIVE_WORKORDER = 'SET_CURRENT_ACTIVE_WORKORDER';
//export const SET_EXPENSELIST = 'SET_EXPENSELIST';
export const SET_DYNAMIC_FORM_FORCE_REFRESH = 'SET_DYNAMIC_FORM_FORCE_REFRESH';
export const SET_CRITICAL_POM_LIST = 'SET_CRITICAL_POM_LIST';
export const SET_RETAIL_MARKET_CDF = 'SET_RETAIL_MARKET_CDF';