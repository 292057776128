import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
    namespaced: true,
    state() {
        return {
            header: {
                show: true,
                title: 'UL',
                showTitle: false,
                showSearch_job: false,
                showSearch_work: false,
                logoIcon: {
                    src: '',
                    alt: ''
                },
                showMenu: true,
                showCalendar: true,
                showBack: true,
                showLogo: false,
                actions: [],
                online: true,
                onlineIsShow: true,
                onlineDownload: true,
                cost: false,
                pageName: '',
                showSubmitFormButton: false,
                showResource: true
            },
            pageTransition:{
                enable: true,
                type: 'none',
                effect: 'none'
            },
            loadingPage:{show :false}, 
            common: {
                isPageSwitching: false,
                scrollPostionMap: {}
            },
            sidebar:{
                show: false, 
                title: {
                    imageLeft: '',
                    altLeft: '',
                    iconLeft: 'home',
                    text: 'Home',
                    imageRight: '',
                    altRight: '',
                    iconRight: '',
                    params:{}
                },
                blocks: [
                    {
                        sublistTitle: 'Menu',
                        list: [
                            {
                                id: 'profile',
                                text: 'Profile',
                                icon: 'account_circle',
                                route: '/profile'
                            },
                            {
                                id: 'cftDraftReport',
                                text: 'CFR Draft Report - Detail Summary',
                                icon: 'mdi-file-eye',
                                route: '/cfr-preview-report'
                            },
                            {
                                id: 'cftDraftReport1',
                                text: 'CFR Draft / Short Report',
                                icon: 'mdi-file-eye',
                                route: '/cfr-preview-report'
                            },
                            {
                                id: 'cftEmailDraftReport',
                                text: 'Email Draft Report',
                                icon: 'mdi-email',
                                route: '/email-draft-report'
                            },
                            {
                                id: 'previewForm',
                                text: 'Preview Form',
                                icon: 'event',
                                alt: 'mood-bad',
                                route: '/preview'
                            },
                            {
                                id: 'splitWorkOrderForm',
                                text: 'Split Work Order',
                                icon: 'call_split',
                                route: '/split-work-order-form'
                            },
                            {
                                id: 'abort',
                                text: 'Abort',
                                icon: 'assignment_return',
                                alt: 'mood-bad',
                                route: '/abort'
                            },
                            // {
                            //     id: 'contactUs',
                            //     text: 'Contact Us',
                            //     icon: 'mdi-send',
                            //     alt: 'mood-bad',
                            //     route: '/contact-us'
                            // },
                            {
                                id: 'settings',
                                text: 'Settings',
                                icon: 'settings',
                                route: '/setting'
                            },
                            {
                                id: 'logout',
                                text: 'Logout',
                                icon: 'power_settings_new',
                                route: '/login'
                            }
                        ]
                    }
                ]
            },
            snackbars: []


        };
    },
    mutations,
    actions,
    getters
};
