<template>
  <div name="PastInspection">
    <v-tabs v-model="tab2" centered grow background-color="#00518A" dark style="margin-top: 90px;">
      <v-tab id="tab_Pending_Submission" href="#tab-1">Pending Submission</v-tab>
      <v-tab id="tab_Completed" href="#tab-2" v-if="internal === 'true'">Completed</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab2">
      <v-tab-item value="tab-1">
        <v-data-table id="pendingSubmissionId" :sort-by="['workOrderDisplayId', 'inspectionRequestDate', 'status']" :search="search_value" v-bind:headers="pendingHeaders" :items="pendingList" :items-per-page="-1" class="elevation-1" style="margin-top: 10px;">
          <template v-slot:body="{ items }">
            <tbody>
              <tr :id="'row_' + item.workOrderDisplayId" v-for="item in items" :key="item.name">
                <td style="text-align: center;">{{ item.workOrderDisplayId }}</td>
                <td style="text-align: center;">{{ item.jobDisplayId }}</td>
                <td style="text-align: center;">{{ item.inspectionRequestDate }}</td>
                <td style="text-align: center;">{{ item.isOnline ? "Y" : "N" }}</td>
                <td style="text-align: center;">{{ item.status }}</td>
                <td style="text-align: center;">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn icon dark v-on="on" @click="isLeadValidation(item.workOrderDisplayId, item.status)">
                        <v-icon color="rgba(0,0,0,.87)">menu</v-icon>
                      </v-btn>
                    </template>
                    <v-list v-if="item.status != 'Rework'">
                      <v-list-item :id="'ps_Edit_' + item.workOrderDisplayId">
                        <v-list-item @click="EditPastInspection(item)">
                          <span class="edit-link">Edit</span>
                        </v-list-item>
                      </v-list-item>
                      <v-list-item :id="'ps_' + item.workOrderDisplayId + '_' + x.url_name" v-for="(x, index) in pending_menu" :key="index">
                        <v-list-item v-if="x.title == 'CFR Draft Report'" @click="previewReport(item)">
                          <span class="edit-link">{{ x.title }}</span>
                        </v-list-item>
                        <v-list-item v-else-if="x.title == 'Email Draft Report'" @click="emailReport(item)">
                          <span class="edit-link">{{ x.title }}</span>
                        </v-list-item>
                        <v-list-item v-else-if="x.title == 'Supplier Sign-off'" @click="supplierSignOff(item)">
                          <span class="edit-link">{{ x.title }}</span>
                        </v-list-item>
                        <v-list-item v-else-if="x.title == 'Split Work Order'">
                            <router-link :to="{ name: x.url_name, params: { id: item.workOrderId }, query: { id: item.workOrderDisplayId } }">
                              {{ x.title }}
                            </router-link>
                        </v-list-item>
                        <v-list-item v-else>
                          <router-link :to="{ name: x.url_name, params: { id: item.workOrderId, edit: true }, query: { id: item.workOrderId, displayId: item.workOrderDisplayId, jobId: item.jobId, jobDisplayId: item.jobDisplayId } }">
                            {{ x.title }}
                          </router-link>
                        </v-list-item>
                      </v-list-item>
                    </v-list>

                    <v-list v-if="item.status == 'Rework'">
                      <v-list-item :id="'ps_' + x.url_name" v-for="(x, index) in pending_menu_rework_list" :key="index">
                        <v-list-item v-if="x.title == 'CFR Draft Report'" @click="previewReport(item)">
                          <span class="edit-link">{{ x.title }}</span>
                        </v-list-item>
                        <v-list-item  v-else-if="x.title == 'Split Work Order'" >
                            <router-link :to="{ name: x.url_name, params: { id: item.workOrderId }, query: { id: item.workOrderDisplayId } }">
                              {{ x.title }}
                            </router-link>
                        </v-list-item>
                        <v-list-item v-else-if="x.title == 'Email Draft Report'" @click="emailReport(item)">
                          <span class="edit-link">{{ x.title }}</span>
                        </v-list-item>
                        <v-list-item v-else-if="x.title == 'Resubmit'" @click="reSubmit(item)">
                          <span class="edit-link">{{ x.title }}</span>
                        </v-list-item>
                        <v-list-item v-else>
                          <router-link :to="{ name: x.url_name, params: { id: item.workOrderId, edit: true }, query: { id: item.workOrderId, displayId: item.workOrderDisplayId, jobId: item.jobId, jobDisplayId: item.jobDisplayId } }">
                            {{ x.title }}
                          </router-link>
                        </v-list-item>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>

          <template v-slot:item.onlineForm="{ item }">
            <span>{{ item.isOnline ? "Y" : "N" }}</span>
          </template>

          <template v-slot:item.menu="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn icon dark v-on="on">
                  <v-icon color="rgba(0,0,0,.87)">menu</v-icon>
                </v-btn>
              </template>
              <!-- <v-list>
                                <v-list-item>
                                    <v-list-item-title @click="EditPastInspection(item)">
                                       Edit
                                     </v-list-item-title>
                                 </v-list-item>
                                <v-list-item v-for="(x, index) in pending_menu" :key="index" > -->

              <!-- <v-list>
                                <v-list-item>
                                    <v-list-item-title @click="EditPastInspection(item)">
                                       Edit
                                     </v-list-item-title>
                                 </v-list-item>
                                <v-list-item v-for="(x, index) in pending_menu" :key="index" >
                                    <v-list-item-title>
                                        <router-link :to="{name: x.url_name ,params:{id:item.workOrderId, edit: true}, query:{id: item.workOrderId, displayId: item.workOrderDisplayId, jobId: item.jobId, jobDisplayId: item.jobDisplayId}}">
                                            {{ x.title }}
                                        </router-link>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list> -->
              <!-- START -->
              <!-- TODO: Enable this for FINAL RELEASE  -->
              <v-list v-if="item.status == 'Rework'">
                <v-list-item v-for="(x, index) in pending_menu_rework_list" :key="index">
                  <v-list-item-title>
                    <router-link :to="{ name: x.url_name, params: { id: item.workOrderId, edit: true }, query: { id: item.workOrderId, displayId: item.workOrderDisplayId, jobId: item.jobId, jobDisplayId: item.jobDisplayId } }">
                      {{ x.title }}
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
              <v-list v-if="item.status != 'Rework'">
                <v-list-item>
                  <v-list-item-title @click="EditPastInspection(item)">
                    <span class="edit-link">Edit</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-for="(x, index) in pending_menu_list" :key="index">
                  <v-list-item-title>
                    <router-link :to="{ name: x.url_name, params: { id: item.workOrderId, edit: true }, query: { id: item.workOrderId, displayId: item.workOrderDisplayId, jobId: item.jobId, jobDisplayId: item.jobDisplayId } }">
                      {{ x.title }}
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
              <!-- END -->
            </v-menu>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <v-data-table id="CompletedId" :sort-by="['inspectionRequestDate']" :search="search_value" sort-desc :headers="completedHeaders" :items="completedList" :items-per-page="-1" class="elevation-1" style="margin-top: 30px;">
          <!-- <template v-slot:item.auditLogLink="{ item }">
                        <v-btn text small color="primary" @click.stop="goToAuditLogs(item)">
                            <u>View Audit Logs</u>
                        </v-btn>
                    </template> -->
          <template v-slot:body="{ items }">
            <tbody>
              <tr :id="'row_' + item.workOrderDisplayId" v-for="item in items" :key="item.name" @click="go_form_preview(item)">
                <td style="text-align: center;">{{ item.supplier }}</td>
                <td style="text-align: center;">{{ item.workOrderDisplayId }}</td>
                <td style="text-align: center;">{{ item.jobDisplayId }}</td>
                <td style="text-align: center;">{{ item.inspectionRequestDate }}</td>
                <td style="text-align: center;">{{ item.dateSubmitted }}</td>
                <td style="text-align: center;">
                  <v-btn text small color="primary" @click.stop="goToAuditLogs(item)">
                    <u>View Audit Logs</u>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
    <!-- Pending Submission & Completed tabs End-->

    <report-preview :showDialog.sync="showReportPreview" :reportData="reportData"></report-preview>
    <email-report-dialog :showDialog.sync="showemailReportDialog" :data="emailReportData"></email-report-dialog>

    <v-snackbar v-model="snackbarError" :timeout="timeoutError" :color="colorError">
      {{ messageError }}
      <v-btn text @click="snackbarError = false"> Close </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import apiDetails from "../../shared/request/apiDetails";
import * as pbi from "powerbi-client";
import ReportPreview from "@/components/reports/ReportPreview";
import EmailReportDialog from "@/components/reports/EmailReportDialog";

export default {
  name: "PastInspection",
  components: {
    ReportPreview,
    EmailReportDialog,
  },
  data() {
    return {
      tab2: null,
      snackbarError: false,
      messageError: "",
      timeoutError: 3000,
      colorError: "red darken-2",
      // Past Inspections | PendingSubmission Menu
      pending_menu: [
        // { title: 'Edit', url_name: 'workOrderDetailsId', id: 1},
        { id: "SplitWorkOrderForm", title: "Split Work Order", url_name: "splitWorkOrderForm" },
        { id: "CFR Draft Report", title: "CFR Draft Report" },
        { id: "Email Draft Report", title: "Email Draft Report" },
        { id: "PreviewDraftSummary", title: "Preview Draft Summary", url_name: "pastInspectionsPreview" },
        { id: "SupplierSignOff", title: "Supplier Sign-off", url_name: "pastInspectionsSignOff" },
        { id: "ViewAuditLogs", title: "View Audit Logs", url_name: "auditLogs" },
      ],

      // Past Inspections | PendingSubmission Menu with Status of 'Rework'
      pending_menu_rework: [
        { title: 'Rework', url_name: 'pastInspectionsResubmitReworkReason' },
        { title: "Resubmit", url_name: "pastInspectionsResubmit" },
        { title: "Split Work Order", url_name: "splitWorkOrderForm" },
        { title: "CFR Draft Report" },
        { title: "Email Draft Report" },
        { title: "View Audit Logs", url_name: "auditLogs" },
      ],

      // pendig Submission table data
      pendingHeaders: [
        { text: "Work Order", align: "center", value: "workOrderDisplayId" },
        { text: "Job Id", align: "center", value: "jobDisplayId" },
        { text: "Inspection Date", align: "center", value: "inspectionRequestDate" },
        { text: "Online Form", align: "center", sortable: false, value: "onlineForm" },
        { text: "Status", align: "center", value: "status" },
        { text: "", align: "center", value: "menu" },
      ],
      pendingList: [],

      // Completed table data
      completedHeaders: [
        { text: "Supplier/Site", align: "center", value: "supplier" },
        { text: "Work Order", align: "center", value: "workOrderDisplayId" },
        { text: "Job Id", align: "center", value: "jobDisplayId" },
        { text: "Date Inspected", align: "center", sortable: true, value: "inspectionRequestDate" },
        { text: "Date Submitted", align: "center", value: "dateSubmitted" },
        { text: "", align: "center", value: "auditLogLink" },
      ],
      completedList: [
        {
          supplier: "0",
          workOrderDisplayId: 0,
          inspectionRequestDate: 0,
          dateSubmitted: 0,
        },
      ],
      selectedWorkorder: {},
      internal: "",
      pending_menu_list: [],
      pending_menu_rework_list: [],

      showReportPreview: false,
      genericTemplates: [],
      reportData: {},

      showemailReportDialog: false,
      emailReportData: {},
    };
  },
  activated() {
    this.InitialReload();
  },
  computed: {
    ...mapState("inspection", {
      search_value: (state) => state.searchValue,
    }),
    ...mapState("inspection", {
      _getJobList: (state) => state.jobList,
    }),
  },
  mounted() {
    this.InitialReload();

    // get the resoucetype if internal or external
    this.resouce_type();
  },
  methods: {
    EditPastInspection(item) {
     
      this.utilityFn.getServerData("get", this.apiList.checkIfWorkOrderDetailsExists + "/" + item.workOrderId, {}, (result) => {
        /* if (window.navigator.onLine != true && 
         (result.data.headers['content-type'] === 'text/html; charset=utf-8')) {
          this.$router.push({
            path: "/error",
            query: {
              error: this.msgList.NoCached,
              noAccess: "false",
            },
          });
          return;
        } */
        //this.selectedWorkorder = result.data.data[0];
        this.selectedWorkorder = result.data.data;
        if (!this.selectedWorkorder) {
          console.log("Edit Past Inspection NO WORKORDER DETAILS RETURN");
          return;
        }
       

        // If work instruction is still null, redirect to the assignment/workinstruction page
         if (!this.selectedWorkorder.workInstruction) {
          this.$router.push({ name: "assignmentId", params: { id: this.selectedWorkorder.workOrderId, workOrderDisplayId: item.workOrderDisplayId, isDynamic: !!this.selectedWorkorder.formId, workInstruction: item.workInstruction } });
        } else if (this.selectedWorkorder.formId == null) {
          this.$router.push({ name: "workOrderDetailsId", params: { id: item.workOrderId, resources: item.resources }, query: { displayId: item.workOrderDisplayId } });
        } else {
          this.$router.push({ name: "dynamicFormId", params: { id: this.selectedWorkorder.workOrderId, resources: this.selectedWorkorder.resources }, query: { displayId: this.selectedWorkorder.workOrderDisplayId } });
        }
        // console.log(this.selectedWorkorder.formId)
      },(err) => {
        console.log(`Fetch Error for Work order details :  ${err}`);
        //throw new Error(err);
      });
    },
    go_form_preview(item) {
      this.$router.push({ name: "previewId", params: { id: item.workOrderId }, query: { id: item.workOrderDisplayId } });
    },
    InitialReload() {
      this.pending_menu_list = this.pending_menu;
      this.pending_menu_rework_list = this.pending_menu_rework;

      // Get Past Inspections data
      var other1 = {
        headers: {
          wresult: this.store_token,
        },
      };

      this.utilityFn.getServerData("get", this.apiList.pastInspection, {}, (res) => {
        this.completedList = res.data.data.filter((x) => x.dateSubmitted != null && (x.status != "Rework" || x.reworkStatusId != 1));
        this.pendingList = res.data.data.filter((x) => x.dateSubmitted == null || (x.status == "Rework" && (!x.reworkStatusId || x.reworkStatusId == 1)));

        // this.utilityFn.db.transaction((tx) => {
        //   tx.executeSql("SELECT * FROM downloadList Where resourceId=?", [this.utilityFn.getResourceId()], (tx, results) => {
        //     var arr = [];
        //     for (var i = 0; i < results.rows.length; i++) {
        //       arr.push(results.rows.item(i).displayId);
        //     }
        //     console.log(arr);
        //     this.pendingList.forEach((x) => {
        //       if (arr.indexOf(x.workOrderDisplayId) == -1) {
        //         x.isOnline = true;
        //       } else {
        //         x.isOnline = false;
        //       }
        //     });
        //     this.pendingList = this.pendingList;
        //   });

        //   this.$store.dispatch("inspection/setWorkList", this.downloadList);
        // });
      });
    },
    goToAuditLogs(item) {
      console.log("GoToAuditLogs", item);
      // this.$router.push({name: 'auditLogs', params:{id: item.workOrderId}, query:{id: item.workOrderId, displayId: item.workOrderDisplayId }})
      this.$router.push({ name: "auditLogs", params: { id: item.workOrderId }, query: { displayId: item.workOrderDisplayId } });
    },
    resouce_type() {
      this.internal = localStorage.getItem("isInternal");
      //console.log(this.utilityFn.getResourceName());
      //this.$store.commit('appData/setResourceName', this.utilityFn.getResourceName());
    },
    isLeadValidation(woid, status) {
      this.pending_menu_list = this.pending_menu;
      this.pending_menu_rework_list = this.pending_menu_rework;
      let hideShow = false;
      this._getJobList.forEach((x) => {
        x.workOrders.forEach((y) => {
          if (y.displayId == woid) {
            x.resources.forEach((z) => {
              if (z.resourceId.toLowerCase() == this.utilityFn.getResourceId().toLowerCase()) {
                hideShow = z.isLeadInspector;
                if (!hideShow && x.resources.length >= 2) {
                  this.pending_menu_list = this.pending_menu_list.filter((x) => x.title != "Split Work Order");
                  this.pending_menu_rework_list = this.pending_menu_rework_list.filter((x) => x.title != "Split Work Order");
                }
              }
            });
          }
        });
      });
      return status != "Rework" ? this.pending_menu_list : this.pending_menu_rework_list;
    },

    previewReport(item) {
      this.reportData = {
        workOrderId: item.workOrderId,
        workOrderDisplayId: item.workOrderDisplayId,
        clientId: item.clientId,
        clientName: item.client,
        reportNumber: item.reportNumber,
        workOrderClassId: item.workOrderClassId,
        industryId: item.industryId,
        workOrderDestinations: item.workOrderDestinations,
        status: item.status,
        rating: item.overallResultDescription,
      };

      this.showReportPreview = true;
    },

    emailReport(item) {
      this.emailReportData = {
        workOrderId: item.workOrderId,
        workOrderDisplayId: item.workOrderDisplayId,
        email: localStorage.getItem("username"),
      };
      this.showemailReportDialog = true;
    },

    async supplierSignOff(item) {
      if(item.client.toLowerCase() === 'walmart global sourcing' || item.client.toLowerCase() === 'asda stores limited') {
        //validate barcode verification
        var barcodeVerificationErrorMessage = await this.utilityFn.getServerDataAsync('get', `${this.apiList.validateBarcodeVerification}/${item.workOrderId}`,{});
        if (barcodeVerificationErrorMessage.data){
          this.snackbarError = true;
          this.messageError = barcodeVerificationErrorMessage.data;
          return;
        }

        //validate quantity verification
        var quantityVerificationErrorMessage = await this.utilityFn.getServerDataAsync('get', `${this.apiList.validateQuantityVerification}/${item.workOrderId}`,{});
        if (quantityVerificationErrorMessage.data){
          this.snackbarError = true;
          this.messageError = quantityVerificationErrorMessage.data;
          return;
        }

        //validate FCM
        var validateFCM = await this.utilityFn.getServerDataAsync('get',`${this.apiList.validateFCM}/${item.workOrderId}`,{});
        if (validateFCM.data){
          this.snackbarError = true;
          this.messageError = validateFCM.data;
          return;
        }
      }

      // validate onsite test
      const result = await this.utilityFn.getServerDataAsync('get', `${this.apiList.validateOnsiteTestResults}/${item.workOrderId}`, { });
      let validationMsg = !result.data.item1 ? 'Please assign the Onsite Test Result' : !result.data.item2 ? 'Please fill in Onsite Test Sample Size' : '';
      if (validationMsg) {
        this.messageError = validationMsg;
        this.snackbarError = true;
        return;
      }

      var requiredFields = await this.utilityFn.getServerDataAsync("get", this.apiList.dynamicGetMissingRequiredFields + "/" + item.workOrderId, {});
      if(requiredFields.data.length > 0) {
          //display snackbar message
          this.snackbarError = true;
          this.messageError = requiredFields.data[0];
          return;
        }

      var missingClassification = await this.utilityFn.getServerDataAsync('get', `${this.apiList.validateRemarksAndCommentClassification}/${item.workOrderId}`,{});
      if (missingClassification.data){
        this.snackbarError = true;
        this.messageError = "Missing Classification Type in Remarks/Comments";
        return;
      }

      if(item.client.toLowerCase() === 'walmart global sourcing' || item.client.toLowerCase() === 'asda stores limited') {
      
        var validatePackageCheck = await this.utilityFn.getServerDataAsync('get',`${this.apiList.validatePackageCheck}/${item.workOrderId}`,{});
        if (validatePackageCheck.data){
          if (validatePackageCheck.data.item1){
            this.snackbarError = true;
            this.messageError = validatePackageCheck.data.item2;
            return;
          }
        }

        var validateWorksmanship = await this.utilityFn.getServerDataAsync('get',`${this.apiList.validateWorksmanship}/${item.workOrderId}`,{});
        if (validateWorksmanship.data){
          if (validateWorksmanship.data.item1){
            this.snackbarError = true;
            this.messageError = validateWorksmanship.data.item2;
            return;
          }
        }
      } 

      //route to supplier sign off
      this.$router.push({ 
        name: 'pastInspectionsSignOff', 
        params: { id: item.workOrderId, edit: true }, 
        query: { id: item.workOrderId, displayId: item.workOrderDisplayId, jobId: item.jobId, jobDisplayId: item.jobDisplayId }
      });

    },
    async reSubmit(item){
      if(item.client.toLowerCase() === 'walmart global sourcing' || item.client.toLowerCase() === 'asda stores limited') {
        //validate barcode verification
        var barcodeVerificationErrorMessage = await this.utilityFn.getServerDataAsync('get', `${this.apiList.validateBarcodeVerification}/${item.workOrderId}`,{});
        if (barcodeVerificationErrorMessage.data){
          this.messageError = barcodeVerificationErrorMessage.data;
          this.snackbarError = true;
          return;
        }

        //validate quantity verification
        var quantityVerificationErrorMessage = await this.utilityFn.getServerDataAsync('get', `${this.apiList.validateQuantityVerification}/${item.workOrderId}`,{});
        if (quantityVerificationErrorMessage.data){
          this.messageError = quantityVerificationErrorMessage.data;
          this.snackbarError = true;
          return;
        }

        //validate FCM
        var validateFCM = await this.utilityFn.getServerDataAsync('get',`${this.apiList.validateFCM}/${item.workOrderId}`,{});
        if (validateFCM.data){
          this.snackbarError = true;
          this.messageError = validateFCM.data;
          return;
        }

        var requiredFields = await this.utilityFn.getServerDataAsync("get", this.apiList.dynamicGetMissingRequiredFields + "/" + item.workOrderId, {});
        if(requiredFields.data.length > 0) {
            //display snackbar message
            this.snackbarError = true;
            this.messageError = requiredFields.data[0];
            return;
          }
      }

      const result = await this.utilityFn.getServerDataAsync('get', `${this.apiList.validateOnsiteTestResults}/${item.workOrderId}`, { });
      let validationMsg = !result.data.item1 ? 'Please assign the Onsite Test Result' : !result.data.item2 ? 'Please fill in Onsite Test Sample Size' : '';
      if (validationMsg) {
        this.messageError = validationMsg;
        this.snackbarError = true;
        return;
      }
      else{
        if(item.client.toLowerCase() === 'walmart global sourcing' || item.client.toLowerCase() === 'asda stores limited') {
          var validatePackageCheck = await this.utilityFn.getServerDataAsync('get',`${this.apiList.validatePackageCheck}/${item.workOrderId}`,{});
            if (validatePackageCheck.data){
            if (validatePackageCheck.data.item1){
              this.snackbarError = true;
              this.messageError = validatePackageCheck.data.item2;
              return;
            }
          }

          var validateWorksmanship = await this.utilityFn.getServerDataAsync('get',`${this.apiList.validateWorksmanship}/${item.workOrderId}`,{});
            if (validateWorksmanship.data){
            if (validateWorksmanship.data.item1){
              this.snackbarError = true;
              this.messageError = validateWorksmanship.data.item2;
              return;
            }
          }

        }

        this.$router.push({ 
            name: 'pastInspectionsResubmit', 
            params: { id: item.workOrderId, edit: true }, 
            query: { id: item.workOrderId, displayId: item.workOrderDisplayId, jobId: item.jobId, jobDisplayId: item.jobDisplayId }
          });
      }
    }
  },

  //  <router-link :to="{ name: x.url_name, params: { id: item.workOrderId, edit: true }, query: { id: item.workOrderId, displayId: item.workOrderDisplayId, jobId: item.jobId, jobDisplayId: item.jobDisplayId } }">
  //                           {{ x.title }}
  //                         </router-link>

  beforeRouteLeave(to, form, next) {
    this.showReportPreview = false;
    next();
  },

 
};
</script>

<style lang="stylus" scoped>
.edit-link
    cursor pointer
    display block
    color: #2b81d5;
#preview-container
    height 100%

#preview-dialog
    display flex
    flex-direction column
    height 98vh

#preview-dialog .v-card__text
    flex-grow 1
    height 85%

>>> .v-dialog
    max-height 100%
</style>
