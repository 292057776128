import axios from "axios";
import apiDetails from "./request/apiDetails";
import apiList from "./request/apiList";
import config from "./request/config";
import apiFunctionCalls from "./request/apiFunctionCalls";

// var db = openDatabase("canyonOfflineDB", "1.0", "offline data DB", 50 * 1024 * 1024);

// db.transaction(function(tx) {
//   tx.executeSql("CREATE TABLE IF NOT EXISTS LOGS (id INTEGER PRIMARY KEY AUTOINCREMENT, url, requestType, data, state, dataType)");

//   tx.executeSql("CREATE TABLE IF NOT EXISTS downloadList (id, displayId, inspection, FactoryName, state, resourceId, workOrderStatus,formId)");

//   tx.executeSql("CREATE TABLE IF NOT EXISTS draft (id INTEGER PRIMARY KEY AUTOINCREMENT, displayId, data, resourceId)");
// });

var def = {};
//def.db = db;
def.login = function(url, params, fn) {
  axios
    .get(url, params)
    .then((response) => {
      fn(response);
    })
    .catch((error) => {
      fn("error");
    });
};
def.updateCache = async function(cacheName, url, contentType, requestBody) {
  const cache = await caches.open(cacheName);

  let newResponse = new Response(JSON.stringify(requestBody), {
    headers: {
      "content-type": contentType,
      "reponse-type": "cors",
    },
  });
  let existingResponse = await cache.match(url);
  let finalResponse = new Array();

  if (existingResponse) {
    let resp = await existingResponse.clone().json();

    resp.forEach((element) => {
      finalResponse.push(element);
    });
  }
  finalResponse.push(requestBody);
  //console.log(finalResponse);
  finalResponse = new Response(JSON.stringify(finalResponse), {
    headers: {
      "content-type": contentType,
      "reponse-type": "cors",
    },
  });
  //console.log("after" + finalResponse);
  caches.open(cacheName).then((cache) => cache.put(url, finalResponse));
};
def.getServerDataAsync = async function(type, url, params) {
  try {
    if (type == "get") {
      let response = await axios.get(url, {
        headers: apiDetails.header.headersDetail,
        params: params,
      });
      return response;
    } else if (type == "post") {
      let response = await axios.post(url, params, {
        headers: apiDetails.header.headersDetail,
      });
      return response;
    } else if (type == "delete") {
      let response = await axios.delete(url, {
        headers: apiDetails.header.headersDetail,
      });
      return response;
    } else if (type == "patch") {
      let response = await axios.patch(url, params, {
        headers: apiDetails.header.headersDetail,
      });
      return response;
    } else if (type == "put") {
      let response = await axios.put(url, params, {
        headers: apiDetails.header.headersDetail,
      });
      return response;
    }
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};
const errLogFn = (e) => {
  console.log("Error", e);
};
// get server data fn
def.getServerData = function(type, url, params, fn, errFn) {
  if (!(errFn instanceof Function)) {
    errFn = errLogFn;
  }
  if (type == "post") {
    axios
      .post(url, params, {
        headers: apiDetails.header.headersDetail,
      })
      .then((response) => {
        fn(response);
      })
      .catch((error) => {
        console.log(error);
        errFn(error);
      });
  } else if (type == "get") {
    axios
      .get(url, {
        headers: apiDetails.header.headersDetail,
        params: params,
      })
      .then((response) => {
        var res = { data: response };
        fn(res);
      })
      .catch((error) => {
        console.log(error);
        errFn(error);
      });
  } else if (type == "patch") {
    axios
      .patch(url, params, {
        headers: apiDetails.header.headersDetail,
      })
      .then((response) => {
        var res = { data: response };
        fn(res);
      })
      .catch((error) => {
        console.log(error);
        errFn(error);
      });
  } else if (type == "put") {
    axios
      .put(url, params, {
        headers: apiDetails.header.headersDetail,
      })
      .then((response) => {
        var res = { data: response };
        fn(res);
      })
      .catch((error) => {
        console.log(error);
        errFn(error);
      });
  } else if (type == "delete") {
    axios
      .delete(url, {
        headers: apiDetails.header.headersDetail,
      })
      .then((res) => {
        fn(res);
      })
      .catch((error) => {
        console.log(error);
        errFn(error);
      });
  }
};
def.getResourceApplication = function(url, params, fn) {
  axios
    .get(url, {
      headers: apiDetails.header.headersDetail,
      params: params,
    })
    .then((response) => {
      var res = { data: response };
      console.log(res);
      fn(res);
    })
    .catch((error) => {
      console.log(error);
      fn(error);
    });
};
def.uploadImg = async function(url, params, uploadProgressFn, fn, errfn) {
  axios({
    method: "post",
    //use for multi header
    headers: apiDetails.headerMulti.headersDetail,
    url: url,
    data: params.formData,
    onUploadProgress: function(progressEvent) {
      const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
      if (uploadProgressFn) {
        uploadProgressFn(uploadPercentage);
      }
    },
  })
    .then(function(response) {
      fn(response);
    })
    .catch((error) => {
      console.log(error);
      errfn(error);
    });
};

def.downloadExcel = async function(url, fn,) {
  axios({
    method: 'GET',
    headers: apiDetails.header.headersDetail,
    responseType: 'blob', // important
    url: url,
  })
    .then(function(response) {
     fn(response);
    })
    .catch((error) => {
      console.log(error);
    });
};
//
def.deleteImage = async function(imageId, fn) {
  let deleted;
  await axios
    .delete(apiList.baseUrl + "/api/AIMS/DeleteImage/" + imageId, { headers: apiDetails.headerMulti.headersDetail })
    .then((response) => {
      fn(response);
    })
    .catch((error) => {});
  return deleted;
};
// imageId = fileUploadId
def.getImage = async function(imageId, fn) {
  let imageData;
  await axios
    .get(apiList.baseUrl + "/api/AIMS/GetImage/" + imageId, { headers: apiDetails.headerMulti.headersDetail })
    .then((response) => {
      fn(response);
    })
    .catch((error) => {
      // console.log('error', error);
    });
  return imageData;
};
// def.delDupIndexDBEntr = function(reqUrl, searchString, fnCallBack, fnCallBackErr) {
//   var connection = window.indexedDB.open("workbox-background-sync");

//   connection.onsuccess = (e) => {
//     var database = e.target.result;
//     var transaction = database.transaction(["requests"], "readwrite");
//     var objectStore = transaction.objectStore("requests");
//     var request = objectStore.openCursor();

//     request.onsuccess = (e) => {
//       let cursor = e.target.result;
//       if (cursor) {
//         let key = cursor.primaryKey;
//         let value = cursor.value;

//         if (value.requestData.url === reqUrl) {
//           let stringBody = new TextDecoder().decode(value.requestData.body);
//           if (stringBody.includes(searchString)) {
//             const request = cursor.delete();
//             request.onsuccess = function() {
//               console.log("Deleted duplicate entry for Wo " + searchString);
//             };
//           }
//         }
//         cursor.continue();
//       }
//       // Clean up: close connection
//       transaction.oncomplete = () => {
//         database.close();
//         fnCallBack();
//       };
//     };
//     request.onerror = (e) => {
//       console.log(e.target.result);
//       database.close();
//       fnCallBackErr(e);
//     };
//   };
//   connection.onerror = (e) => {
//     console.log(e);
//     fnCallBackErr(e);
//   };
// };
def.doloadList = async function(item, fn) {
  item.isDownload = 0;
  let downloadNumber = 0;
  let downloadUrlList = [
    apiList.sectionList,
    apiList.workOrderSectionList + "/" + item.workOrderId,
    apiList.getWorkOrderRemarks + "/" + item.workOrderId,
    apiList.upsertWorkOrderDataCaptureFields + "/" + item.workOrderId,
    apiList.upsertProductResult + "/" + item.workOrderId + "/2",
    apiList.upsertWorkOrderPackageCheck,
    apiList.patchWorkOrderResults + "/" + item.workOrderId + "/9",
    apiList.upsertWorkOrderSizeMeasurement,
    apiList.getUnitsOfMeasurement,
    apiList.getGeoCountires,
    apiList.getLookupAsync,
    apiList.getWorkOrderProductDefects + "?workOrderId=" + item.workOrderId,
    apiList.getDefectNames,
    apiList.GetBarcodeTypes,
    apiList.getDefectCategories,
    apiList.getDefectClassifications,
    //apiList.getWorkInstructionOnsiteTests + "/" + item.workInstruction,
    //end points added
    apiList.workOrderDetailsById + "/" + item.workOrderId,
    apiList.getClientDefectList + "/" + item.clientId + "?isActive=true",
    apiList.workOrderAssignment + "/" + item.workOrderId,

    apiList.woDetailsToAddWoDefect + "/" + item.workOrderId,

    //stock inspected edit wo defect
    apiList.woDetailsToEditWoDefect + "/" + item.workOrderId,
    //quantity in work order details inspection summary
    //product confirmity in inspection summary
    apiList.workOrderConfirmity + "/" + item.workOrderId,
    //functional check measurement in inspection summary
    apiList.getAllWorkOrderPendingFailCode,
    apiList.workOrderDetailsSize + "/" + item.workOrderId,
    apiList.getAllProductSizeResultList,
    //package check
    //barcode verification
    apiList.workOrderDetailsBarCode + "/" + item.workOrderId,
    //workmanship
    apiList.workOrderDetailsWorkmanship + "/" + item.workOrderId,
    //onsite test
    apiList.workOrderDetailsOT + "/" + item.workOrderId,
    apiList.getWorkInstructionOnsiteTests + "/" + item.workInstruction,

    //dynamic form end points
    apiList.isSelfInspection + "/" + item.workOrderId,

    //quantity in df inspection summary
    apiList.getProductCompanyClientDefinedField + "/" + (item.clientId || item.companyId),
    apiList.workOrderDetailsQuantity + "/" + item.workOrderId,

    apiList.getIsApplyToDclTypeAlias + "/" + item.clientId,
    apiList.getCompanyDclTypeAliases + "/" + item.clientId + "/0",
    //stock inspected get details to add wodefect photo

    //pom in functional check
    apiList.getWOPSD + "/" + item.workOrderId,

    apiList.getAllCriticalPomList,
    apiList.getStylePerWorkOrderId + "/" + item.workOrderId,
    apiList.getClientCustomConfigs + "?clientId=" + item.clientId,
    apiList.GetCompanyWOResultTypeAliases + "?companyId=" + item.clientId,
  ];
  let noOfItemsToDownload = downloadUrlList.length;
  if (item.formId != null) {
    let dfDataList = await def.getServerDataAsync("get", apiList.dynamicFormData + "/" + item.workOrderId, {});
    /* let dfFormTabs = dfDataList.data.formTabs;
    if (dfFormTabs) {
      noOfItemsToDownload = noOfItemsToDownload + dfFormTabs.length;
    }
    for (let dfData in dfFormTabs) {
      let dynamicAttach = await def.getServerDataAsync("get", apiList.getWorkOrderAttachment + "?workOrderId=" + item.workOrderId + "&sectionId=" + dfData.id, {});

      item.isDownload = item.isDownload + 100 / noOfItemsToDownload;

      let dynamicAttachResult = dynamicAttach.data;
      if (dynamicAttachResult) {
        noOfItemsToDownload = noOfItemsToDownload + dynamicAttachResult.length;
      }
      for (let dynamicAttach of dynamicAttachResult) {
        await apiFunctionCalls.getImageAsync(dynamicAttach.fileUploadId);

        item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
      }
    } */

    let ft = dfDataList.data.formTabs;
    
    let ftarr = Object.keys(ft).map((i) => ft[i]);
    ftarr.forEach(async (x) => {
      if (x.id)  {
        noOfItemsToDownload += 1;
        //console.log('ft' + x.id);
        let dynamicAttach = await def.getServerDataAsync("get", apiList.getWorkOrderAttachment + "?workOrderId="
         + item.workOrderId + "&sectionId=" + x.id, {});

        item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
  
        let dynamicAttachResult = dynamicAttach.data;
        if (dynamicAttachResult) {
          noOfItemsToDownload = noOfItemsToDownload + dynamicAttachResult.length;
        }
        for (let dynamicAttach of dynamicAttachResult) {
          await apiFunctionCalls.getImageAsync(dynamicAttach.fileUploadId);
  
          item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
        }
      }
      if (x.formTabSections) {
        x.formTabSections.forEach(async (y) => {
          if (y.id) {
            noOfItemsToDownload += 1;
            //console.log("ftsec" + y.id);
            let dynamicAttach = await def.getServerDataAsync("get", apiList.getWorkOrderAttachment + "?workOrderId="
            + item.workOrderId + "&sectionId=" + y.id, {});
   
           item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
     
           let dynamicAttachResult = dynamicAttach.data;
           if (dynamicAttachResult) {
             noOfItemsToDownload = noOfItemsToDownload + dynamicAttachResult.length;
           }
           for (let dynamicAttach of dynamicAttachResult) {
             await apiFunctionCalls.getImageAsync(dynamicAttach.fileUploadId);
     
             item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
           }
          }
        });
      }
    });
  
    downloadUrlList.push(apiList.workOrderDynamicForm + "/" + item.workOrderId);

    downloadUrlList.push(apiList.getInspectionTime + "/" + item.workOrderId  + "/" + "Day 1");

    downloadUrlList.push(apiList.getInspectionTime + "/" + item.workOrderId  + "/" + "Day 2");
  }
  item.isDownloadBg = "#0097a7";

  //get wo details
  try {
    let woDetails = await def.getServerDataAsync("get", apiList.workOrderDetails + "/" + item.workOrderId, {});
    let productList = woDetails.data[0].products;
    if (productList && productList.length > 0) {
      noOfItemsToDownload = noOfItemsToDownload + productList.length;

      for (let prod of productList) {
        //pom size measurement
        if (prod.workOrderProductSizeMeasurementResults.length > 0) {
          let sizeMeasurementId = prod.workOrderProductSizeMeasurementResults.filter((x) => x.isCarton == false)[0].id;
          if (sizeMeasurementId) {
            await def.getServerDataAsync("get", apiList.getWOPPOM + "/" + item.workOrderId + "/" + sizeMeasurementId, {});
          }
        }
        //product photo upload
        let prodattch = await def.getServerDataAsync("get", apiList.getProductAttachment + "?workOrderId=" + item.workOrderId + "&productId=" + prod.id.toLowerCase() + "&sectionId=10", {});

        //GetDetailsToAddDefectPhoto
        let resultProd = await def.getServerDataAsync("get", apiList.woDetailsToAddWoDefectPhoto + "?workOrderId=" + item.workOrderId + "&productId=" + prod.id, {});

        item.isDownload = item.isDownload + 100 / noOfItemsToDownload;

        let woUploadPhotoInspect = await def.getServerDataAsync("get", apiList.woDetailsToUploadPhotoAttch + "?workOrderId=" + item.workOrderId + "&productId=" + prod.id + "&sectionId=11", {});
        let woUploadPhotoInspectProd = woUploadPhotoInspect.data[0].products.find((x) => x.id === prod.id);

        if (woUploadPhotoInspectProd) {
          noOfItemsToDownload = noOfItemsToDownload + woUploadPhotoInspectProd.workOrderProductAttachments.length;

          for (let prodAttach of woUploadPhotoInspectProd.workOrderProductAttachments) {
            await apiFunctionCalls.getImageAsync(prodAttach.fileUploadId);

            item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
          }
        }

        let workOrderProduct = resultProd.data[0].products.find((x) => x.id === prod.id);

        if (workOrderProduct) {
          let defects = workOrderProduct.workOrderProductDefects;

          noOfItemsToDownload = noOfItemsToDownload + defects.length;

          for (let defect of defects) {
            for (let defectAttach of defect.workOrderDefectAttachments) {
              await apiFunctionCalls.getImageAsync(defectAttach.fileUploadId);

              item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
            }
          }
        }
      }
    }

    let attachment20 = await def.getServerDataAsync("get", apiList.getWorkOrderAttachment + "?workOrderId=" + item.workOrderId + "&sectionId=20", {});

    let attachment20Result = attachment20.data;

    if (attachment20Result) {
      noOfItemsToDownload = noOfItemsToDownload + attachment20Result.length;
    }
    for (let attch20 of attachment20Result) {
      await apiFunctionCalls.getImageAsync(attch20.fileUploadId);
      item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
    }

    let attachment21 = await def.getServerDataAsync("get", apiList.getWorkOrderAttachment + "?workOrderId=" + item.workOrderId + "&sectionId=21", {});

    let attachment21Result = attachment21.data;
    if (attachment21Result) {
      noOfItemsToDownload = noOfItemsToDownload + attachment21Result.length;
    }
    for (let attch21 of attachment21Result) {
      await apiFunctionCalls.getImageAsync(attch21.fileUploadId);
      item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
    }

    let attachment2 = await def.getServerDataAsync("get", apiList.getWorkOrderAttachment + "?workOrderId=" + item.workOrderId + "&sectionId=2", {});

    let attachment2Result = attachment2.data;
    if (attachment2Result) {
      noOfItemsToDownload = noOfItemsToDownload + attachment2Result.length;
    }
    for (let attch2 of attachment2Result) {
      await apiFunctionCalls.getImageAsync(attch2.fileUploadId);
      item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
    }

    let attachment3 = await def.getServerDataAsync("get", apiList.getWorkOrderAttachment + "?workOrderId=" + item.workOrderId + "&sectionId=3", {});

    let attachment3Result = attachment3.data;
    if (attachment3Result) {
      noOfItemsToDownload = noOfItemsToDownload + attachment3Result.length;
    }
    for (let attch3 of attachment3Result) {
      await apiFunctionCalls.getImageAsync(attch3.fileUploadId);
      item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
    }

    let attachment4 = await def.getServerDataAsync("get", apiList.getWorkOrderAttachment + "?workOrderId=" + item.workOrderId + "&sectionId=4", {});

    let attachment4Result = attachment4.data;
    if (attachment4Result) {
      noOfItemsToDownload = noOfItemsToDownload + attachment4Result.length;
    }
    for (let attch4 of attachment4Result) {
      await apiFunctionCalls.getImageAsync(attch4.fileUploadId);
      item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
    }

    let attachment5 = await def.getServerDataAsync("get", apiList.getWorkOrderAttachment + "?workOrderId=" + item.workOrderId + "&sectionId=5", {});

    let attachment5Result = attachment5.data;
    if (attachment5Result) {
      noOfItemsToDownload = noOfItemsToDownload + attachment5Result.length;
    }
    for (let attch5 of attachment5Result) {
      await apiFunctionCalls.getImageAsync(attch5.fileUploadId);
      item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
    }

    let attachment1 = await def.getServerDataAsync("get", apiList.getWorkOrderAttachment + "?workOrderId=" + item.workOrderId + "&sectionId=1", {});

    let attachment1Result = attachment1.data;
    if (attachment1Result) {
      noOfItemsToDownload = noOfItemsToDownload + attachment1Result.length;
    }
    for (let attch1 of attachment1Result) {
      await apiFunctionCalls.getImageAsync(attch1.fileUploadId);
      item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
    }

    let attachment8 = await def.getServerDataAsync("get", apiList.getWorkOrderAttachment + "?workOrderId=" + item.workOrderId + "&sectionId=8", {});

    let attachment8Result = attachment8.data;
    if (attachment8Result) {
      noOfItemsToDownload = noOfItemsToDownload + attachment8Result.length;
    }
    for (let attch8 of attachment8Result) {
      await apiFunctionCalls.getImageAsync(attch8.fileUploadId);
      item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
    }

    let attachment7 = await def.getServerDataAsync("get", apiList.getWorkOrderAttachment + "?workOrderId=" + item.workOrderId + "&sectionId=7", {});

    let attachment7Result = attachment7.data;
    if (attachment7Result) {
      noOfItemsToDownload = noOfItemsToDownload + attachment7Result.length;
    }
    for (let attch7 of attachment7Result) {
      await apiFunctionCalls.getImageAsync(attch7.fileUploadId);
      item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
    }

    let attachment9 = await def.getServerDataAsync("get", apiList.getWorkOrderAttachment + "?workOrderId=" + item.workOrderId + "&sectionId=9", {});

    let attachment9Result = attachment9.data;
    if (attachment9Result) {
      noOfItemsToDownload = noOfItemsToDownload + attachment9Result.length;
    }
    for (let attch9 of attachment9Result) {
      await apiFunctionCalls.getImageAsync(attch9.fileUploadId);
      item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
    }

    let attachment10 = await def.getServerDataAsync("get", apiList.getWorkOrderAttachment + "?workOrderId=" + item.workOrderId + "&sectionId=10", {});

    let attachment10Result = attachment10.data;
    if (attachment10Result) {
      noOfItemsToDownload = noOfItemsToDownload + attachment10Result.length;
    }
    for (let attch10 of attachment10Result) {
      await apiFunctionCalls.getImageAsync(attch10.fileUploadId);
      item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
    }

    let attachment11 = await def.getServerDataAsync("get", apiList.getWorkOrderAttachment + "?workOrderId=" + item.workOrderId + "&sectionId=11", {});

    let attachment11Result = attachment11.data;
    if (attachment11Result) {
      noOfItemsToDownload = noOfItemsToDownload + attachment11Result.length;
    }
    for (let attch11 of attachment11Result) {
      await apiFunctionCalls.getImageAsync(attch11.fileUploadId);
      item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
    }

    let attachment12 = await def.getServerDataAsync("get", apiList.getWorkOrderAttachment + "?workOrderId=" + item.workOrderId + "&sectionId=12", {});

    let attachment12Result = attachment12.data;
    if (attachment12Result) {
      noOfItemsToDownload = noOfItemsToDownload + attachment12Result.length;
    }
    for (let attch12 of attachment12Result) {
      await apiFunctionCalls.getImageAsync(attch12.fileUploadId);
      item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
    }

    let attachment13 = await def.getServerDataAsync("get", apiList.getWorkOrderAttachment + "?workOrderId=" + item.workOrderId + "&sectionId=13", {});

    let attachment13Result = attachment13.data;
    if (attachment13Result) {
      noOfItemsToDownload = noOfItemsToDownload + attachment13Result.length;
    }
    for (let attch13 of attachment13Result) {
      await apiFunctionCalls.getImageAsync(attch13.fileUploadId);
      item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
    }

    let attachment14 = await def.getServerDataAsync("get", apiList.getWorkOrderAttachment + "?workOrderId=" + item.workOrderId + "&sectionId=14", {});

    let attachment14Result = attachment14.data;
    if (attachment14Result) {
      noOfItemsToDownload = noOfItemsToDownload + attachment14Result.length;
    }
    for (let attch14 of attachment14Result) {
      await apiFunctionCalls.getImageAsync(attch14.fileUploadId);
      item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
    }

    let attachment16 = await def.getServerDataAsync("get", apiList.getWorkOrderAttachment + "?workOrderId=" + item.workOrderId + "&sectionId=16", {});

    let attachment16Result = attachment16.data;
    if (attachment16Result) {
      noOfItemsToDownload = noOfItemsToDownload + attachment16Result.length;
    }
    for (let attch16 of attachment16Result) {
      await apiFunctionCalls.getImageAsync(attch16.fileUploadId);
      item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
    }

    let attachment17 = await def.getServerDataAsync("get", apiList.getWorkOrderAttachment + "?workOrderId=" + item.workOrderId + "&sectionId=17", {});

    let attachment17Result = attachment17.data;
    if (attachment17Result) {
      noOfItemsToDownload = noOfItemsToDownload + attachment17Result.length;
    }
    for (let attch17 of attachment17Result) {
      await apiFunctionCalls.getImageAsync(attch17.fileUploadId);
      item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
    }
  } catch (err) {
    alert(`error during offline dynamic form\attachment download ${err}`);
    item.isDownload = 0;
    item.isDownloadBg = "red";
    return;
  }

  item.isDownloadBg = "#0097a7";

  downloadUrlList.forEach((x, index) => {
    def.getServerData("get", x, {}, (res) => {
      if (res == "error") {
        alert(`error during offline form download ${res}`);
        item.isDownloadBg = "red";
        return;
      }
      //
      //Each time you complete an interface call to increase the download progress and increase the number of completed
      //item.isDownload = item.isDownload + 100 / downloadUrlList.length;
      item.isDownload = item.isDownload + 100 / noOfItemsToDownload;
      downloadNumber++;

      //
      //Reaching 100% means the download is complete
      if (downloadNumber == downloadUrlList.length) {
        item.isDownload = 100;
        item.isDownloadBg = "rgba(0,0,0,.54)";
        // db.transaction(function(tx) {
        //   tx.executeSql("INSERT INTO downloadList (id, displayId, inspection, FactoryName, state, resourceId, workOrderStatus,formId) VALUES ( ?, ?, ?, ?, ?, ?, ?,?)", [
        //     item.workOrderId,
        //     item.workOrderDisplayId,
        //     item.inspectionRequestDate,
        //     item.factoryName,
        //     2,
        //     def.getResourceId(),
        //     item.workOrderStatus,
        //     item.formId,
        //   ]);
        // });
        fn();
      }
    });
  });
};

//
def.submitList = function() {
  // db.transaction((tx) => {
  //   tx.executeSql("SELECT * FROM LOGS", [], (tx, results) => {
  //     var len = results.rows.length;

  //     for (var i = 0; i < len; i++) {
  //       var fn = function() {
  //         return (function(i) {
  //           if (results.rows.item(i).dataType == "1") {
  //             def.getServerData(results.rows.item(i).requestType, results.rows.item(i).url, JSON.parse(results.rows.item(i).data), (res) => {
  //               db.transaction((tx) => {
  //                 tx.executeSql("DELETE FROM LOGS WHERE id=?", [results.rows.item(i).id]);
  //               });
  //             });
  //           } else {
  //             var data = JSON.parse(results.rows.item(i).data);
  //             let formData = new FormData();

  //             formData.append("image", def.dataURLtoFile(data.image));
  //             formData.append("description", data.description);
  //             formData.append("workOrderId", data.workOrderId);
  //             formData.append("screen", data.screen);
  //             formData.append("order", data.order);
  //             var sqlId = results.rows.item(i).id;
  //             def.delSqlLog(results.rows.item(i).url, results.rows.item(i).id, { formData: formData, base64: data.image });
  //           }
  //         })(i);
  //       };
  //       fn(i);
  //     }
  //   });
  // });
};
def.delSqlLog = function(url, id, data) {
  def.uploadImg(
    url,
    data,
    (pos) => {},
    (res) => {
      // def.db.transaction((tx) => {
      //   tx.executeSql("DELETE FROM LOGS WHERE id=?", [id]);
      // });
    }
  );
};
def.dataURLtoFile = function(dataurl, filename = "file") {
  let arr = dataurl.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let suffix = mime.split("/")[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime,
  });
};

def.formatBytes = function(bytes) {
  let sizeInfo = {
    size: 0,
    unit: "Bytes",
    sizeIteration: 0,
    sizeKB: 0,
  };

  if (bytes === 0) return sizeInfo;

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  sizeInfo.size = Math.ceil(parseFloat(bytes / Math.pow(k, i)));
  sizeInfo.unit = sizes[i];
  sizeInfo.sizeIteration = i;
  sizeInfo.sizeKB = Math.ceil(parseFloat(bytes / k));

  return sizeInfo;
};

def.getResourceId = function() {
  let token = JSON.parse(localStorage.getItem("tokenInfo"));

  if(!token)
    return "";

  let resourceID = token.resourceId;
  return resourceID === "" ? "" : resourceID;
};
def.getResourceIdByName = function() {
  const userName = localStorage.getItem("userName");
  let index = config.userResources.findIndex((x) => x.userName == userName);
  return index === -1 ? "" : config.userResources[index].resourceId;
};
def.getResourceName = function() {
  let resourceName = localStorage.getItem("resourceName") === null ? "" : localStorage.getItem("resourceName"); //resources.findIndex(x => x.userName == userName);
  return resourceName === "" ? "" : resourceName;
};
(def.getOTP = function(url, params, fn) {
  axios
    .get(url, {
      headers: apiDetails.header.headersDetail,
      params: params,
    })
    .then((response) => {
      var res = { data: response };
      console.log(res);
      fn(res);
    })
    .catch((error) => {
      fn("error");
    });
}),
  (def.cfrExport = function(url, params, token, fn) {
    const uninterceptedAxiosInstance = axios.create();
    uninterceptedAxiosInstance
      .post(url, params, {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
      })
      .then((response) => {
        var res = { data: response };
        fn(true, res);
      })
      .catch((error) => {
        fn(false, error);
      });
  });
export default def;
