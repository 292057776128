<template>
  <div>
    <div class="uploadPhotoGalleryQuestion">
      <v-row>
        <v-col cols="12" sm="12">
          <v-btn depressed @click="toggleAttachmentSelect()" v-if="attachments.length > 0">{{ attachments.some(x => x.selected) ? 'Unselect All' : 'Select All'  }}</v-btn>
          <v-btn depressed class="delete_selected" v-if="attachments.some(x => x.selected)" @click="delete_selected_images()">Delete</v-btn>
        </v-col>
        <v-col cols="12" sm="12">
          <div class="imglist select_img">
            <transition-group tag="ul">
              <li v-for="(attachment, aIndex) in attachments" :key="aIndex" class="item">
                <div v-if="attachment.src == null || attachment.src == ''">
                  Loading...
                </div>
                <div v-else class="holder">
                  <span class="badge-number">{{ aIndex + 1 }}</span>
                  <span class="del_button" @click="del_img(aIndex)">
                    <icon name="times-circle" class="icon"></icon>
                  </span>
                  <span class="checkbox_img">
                    <v-checkbox color="black" v-model="attachment.selected"></v-checkbox>
                  </span>
                  <img class="imgItem" :class="{ updateSelected: attachment.updateSelected }" :src="attachment.src" @click="add_img_update(gaIndex, aIndex)" />
                </div>
              </li>
            </transition-group>
          </div>
        </v-col>
      </v-row>

      <v-row v-show="addedImages.length > 0">
        <v-col cols="12" sm="12">
          <draggable class="imglist select_img" v-model="addedImages">
            <transition-group tag="ul">
              <li v-for="(attachment, index) in addedImages" :key="index" class="item">
                <div v-if="!attachment.src">
                  Loading...
                  {{ loadTempImage(index, attachment.file) }}
                </div>
                <div v-else class="holder">
                  <span class="badge-number">{{ index + 1 }}</span>
                  <span class="del_button" @click="del_temp_img(index)">
                    <icon name="times-circle" class="icon"></icon>
                  </span>
                  <img class="imgItem" :src="attachment.src" />
                </div>
              </li>
            </transition-group>
          </draggable>
        </v-col>
      </v-row>

      <v-row style="padding-top: 15px; padding-bottom:15px;">
        <div style="text-align:left; margin:0 2%;">
          <!-- Remove this dropdwon bug 105941 -->
          <select v-model="description" style="border: 1px #ccc solid;background: #fff;padding: 10px;width: 250px;" v-show="showDescription">
            <option v-for="(item, index) in descriptionList" :key="index" :value="item">{{ item }}</option>
          </select>

          <van-button type="default" @click="uploadImages" v-show="toBeUpdatedImages.length === 0" :disabled="!isAddPhotoEnabled">{{ submitButtonText }}</van-button>
          <van-button type="default" @click="onImageSelection">Add Photo from Gallery</van-button>
        </div>
      </v-row>

      <v-row style="margin: 7px;" v-show="showOtherDescription">
        <v-col cols="12" sm="12">
          <v-text-field v-model="otherDescription" label="Decription" placeholder="Enter Other Photo Description here"></v-text-field>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="showProgressDialog" :persistent="true">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-container v-for="(image, index) in addedImages" :key="index">
            <v-progress-linear :value="image.uploadProgress" color="light-blue" height="25" reactive>
              <template v-slot="{ value }">
                <strong>{{ image.fileName }} {{ Math.ceil(image.uploadProgress) }}%</strong>
              </template>
            </v-progress-linear>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import * as _ from "lodash";
import apiDetails from "../../shared/request/apiDetails";

let state = {
  appHeaderState: {
    show: true,
    title: "",
    showMenu: true,
    showBack: true,
    showLogo: false,
    showTitle: true,
    showSearch: false,
    sectionId: "",
    showCalendar: true,
  },
};

function setState(store) {
   store.dispatch('navigation/setAppHeader', state.appHeaderState);
}
export default {
  name: "uploadPhotoGalleryQuestion",
  components: {
    draggable,
  },
  data() {
    return {
      imageList: [],
      descriptionList: [
        "Accessories",
        "Barcode on color card",
        "Barcode on giftbox",
        "Barcode on inner box",
        "Barcode on master carton",
        "Barcode on pallet",
        "Barcode on polybag",
        "Blister",
        "Blister packing",
        "Carton open view",
        "Color card",
        "Gift box",
        "Gift box packing",
        "Inner box",
        "Inner box packing",
        "Instruction manual",
        "Label on inner box",
        "Label on master carton",
        "Label on pallet",
        "Label on product",
        "Label on sample",
        "Master carton",
        "Master carton packing",
        "Marking on color card",
        "Marking on gift box",
        "Marking on polybag",
        "Others",
        "Pallet",
        "Polybag",
        "Product",
        "Product details",
        "Product dimension check",
        "Sample vs product",
        "Scanning test for color card",
        "Scanning test for gift box",
        "Scanning test for inner box",
        "Scanning test for label",
        "Scanning test for master carton",
        "Scanning test for polybag",
        "Shipping Mark",
        "Shipping Marks Front & Back Side - 1 side only",
        "Shipping Marks Front & Back Side - both opposite sides",
        "Shipping Marks Sides - 1 side only",
        "Shipping Marks Sides - both opposite sides",
        "Warranty card",
        "Weight check",
        "UL VS chop",
      ],
      // 备注
      description: "",
      // 选择other需要输入的备注
      otherDescription: "",
      // 所有产品列表
      workOrderProduct: {},
      // 所有图片列表
      attachments: [],
      groupedDescriptions: [],
      groupedAttachments: [], // group by attachment description
      addedImages: [],
      toBeUpdatedImages: [],
      showProgressDialog: false,
      question: null,

      // 提交按钮，如果是添加为Add to photo 如果为编辑为Update
      submitButtonText: "Add to photo",
      isUpdate: false,
      workOrderId: "",
      sectionId: "",
      forProductUpload: false,
    };
  },
  mounted() {
    // 获取当前woId
    this.workOrderId = this.$route.params.id;

    // 获取模块id
    this.question = this.$route.params.question;

    state.appHeaderState.title = "Photo Upload：" + this.question.questionText;

    setState(this.$store);

    this.question.attachments.forEach((x) => {
      let attachment = { id: x.id, src: x.src, selected: false };
      this.attachments.push(attachment);
      this.loadImage(attachment);
    });
    this.addedImages = [];

    //this.setSessionDetails();
  },
  methods: {
    groupTheAttachments() {
      // group attachments by description
      this.addedImages = [];
      this.groupedAttachments = [];
      // 筛选出共有多少种描述类型
      this.groupedDescriptions = _.uniq(_.map(this.attachments, "description"));
      // 将同一种描述类型的图片放入一个数组中
      this.groupedDescriptions.forEach((description) => {
        const filteredAttachments = this.attachments.filter((x) => x.description === description);
        //if(description===null){
        this.groupedAttachments.push({
          description,
          attachments: _.orderBy(filteredAttachments, ["order"], ["asc"]),
        });
        //}
      });
    },

    loadingImg(item) {
      this.utilityFn.getImage(item.id, (res) => {
        console.log(res);
        item.src = "data:" + res.fileType + ";base64," + res.data.data;
      });
    },

    onImageSelection(event) {
      var inputObj = document.createElement("input");
      inputObj.setAttribute("accept", "image/*");
      inputObj.setAttribute("multiple", "multiple");
      inputObj.setAttribute("id", "_ef");
      inputObj.setAttribute("type", "file");
      inputObj.setAttribute("style", "visibility:hidden");
      document.body.appendChild(inputObj);
      inputObj.click();
      inputObj.onchange = (res) => {
        this.isUpdate = false;
        //	this.clearUpdateSelection();

        const loadImage = async () => {
          for (let i = 0; i < inputObj.files.length; i++) {
            let file = inputObj.files[i];
            var sizeInfo = this.utilityFn.formatBytes(file.size)    
            console.log('sizeInfo=>',sizeInfo)
            if(sizeInfo.sizeKB > 500){
              this.$store.dispatch("navigation/setSnackbar",{
                color :'error',
                text:`Each upload only accepts image size less than 500KB. Please resize/compact the image with size greater than 500KB. Filename: ${file.name}.`});
            }
            else{
              const reader = new FileReader();
              const attachment = {
                id: null,
                src: await returnBase64(file),
                fileName: file.name,
                fileSize: file.size
              };
              //this.attachments.push(attachment);
              this.addedImages.push(attachment);
            }
          
          }
        };
        const returnBase64 = (file) => {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              var reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            }, 500);
          });
        };
        loadImage().then(() => {
          console.log("done");
        });

        this.logChanges();
      };
    },

    // will generate a file object
    getImage(index) {
      return URL.createObjectURL(this.imageList[index]);
    },

    uploadImages() {
      // 弹出上传进度
      if (window.navigator.onLine) {
        //this.showProgressDialog = true;
      } else {
        //alert('Photo saved offline, will auto update when device is online');
      }
      // 不知道啥意思
      const _addedImages = [...this.addedImages];

      for (let i = 0; i < _addedImages.length; i++) {
        const addedImage = { ..._addedImages[i] };

        this.attachments.push(addedImage);
        this.question.attachments = this.attachments;
        this.addedImages = [];
        var data = {};

        data.questionId = this.question.questionId;
        data.workOrderId = this.$route.params.id;
        data.notApplicable = this.question.notApplicable;
        data.attachments = this.question.attachments;

        this.utilityFn.getServerData("post", this.apiList.baseUrl + "/api/AIMS/SaveQuestionResult", data, (res) => {});
      }

      this.logChanges();
    },

    del_temp_img(index) {
      this.addedImages.splice(index, 1);
    },

    del_img(index) {
      this.attachments.splice(index, 1);

      this.question.attachments = this.attachments;

      var data = {};

      data.questionId = this.question.questionId;
      data.workOrderId = this.$route.params.id;
      data.notApplicable = this.question.notApplicable;
      data.attachments = this.question.attachments;

      this.utilityFn.getServerData("post", this.apiList.baseUrl + "/api/AIMS/SaveQuestionResult", data, (res) => {});
    },

    toggleAttachmentSelect() {
      //if any attachment is already selected then deselect them all, otherwise select them all
      if(this.attachments.some(x => x.selected)) {
        this.attachments = this.attachments.map((x) => {
          x.selected = false;
          return x;
        });
      }
      else {
        this.attachments = this.attachments.map((x) => {
          x.selected = true;
          return x;
        });
      }      
    },

    delete_selected_images() {
      if(confirm("Are you sure you wish to delete photo(s)?")) {
        let attachmentsToDelete = this.attachments.filter(x => x.selected);
        _.forEach(attachmentsToDelete, (x) => {        
          let index = this.attachments.findIndex((y) => y.id === x.id);
          this.del_img(index);
        });
      }
    },

    // 参数,第几行，第几个，图片id
    loadImage(attachment) {
      this.utilityFn.getImage(attachment.id, (res) => {
        attachment.src = "data:" + res.fileType + ";base64," + res.data.data;
      });
    },

    loadTempImage(index, file) {
      this.getBase64(file).then((base64) => {
        this.addedImages[index].src = base64;
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    checkUploadStatus() {
      this.showProgressDialog = this.addedImages.length !== 0;
      if (!this.showProgressDialog) {
        this.description = "";
        this.otherDescription = "";
        this.groupTheAttachments();
      }
    },
    // check if the image being displayed is a newly added image
    isImageTemporary(attachment) {
      return attachment.fileUploadId.indexOf("temp_") !== -1;
    },

    logChanges() {
      window.sessionStorage.setItem("EditDataLogging", true);
      if (this.forProductUpload) {
        window.sessionStorage.setItem("WorkOrderProductId", this.$route.query.workOrderProductId);
      }
    },

    setSessionDetails() {
      window.sessionStorage.removeItem("WorkOrderProductId");
      window.sessionStorage.setItem("SectionId", this.sectionId);
    },
  },
  computed: {
    showDescription() {
      return this.sectionId === 1 || this.sectionId === 2 || this.sectionId === 3 || this.sectionId === 4 || this.sectionId === 5 || this.sectionId === 6;
    },
    showOtherDescription() {
      return this.description === "Others";
    },
    finalDescription() {
      return this.description === "Others" ? this.otherDescription : this.description;
    },

    isAddPhotoEnabled() {
      let val = false;
      if (this.sectionId === 1 || this.sectionId === 2 || this.sectionId === 3 || this.sectionId === 4 || this.sectionId === 5 || this.sectionId === 6) {
        if (this.finalDescription != "") {
          val = true;
        }
      } else {
        if (this.addedImages.length > 0 && !this.showProgressDialog) {
          val = true;
        }
      }
      return val;
    },

    isUpdatePhotoEnabled() {
      return this.finalDescription && this.toBeUpdatedImages.length > 0 && this.isUpdate;
    },
  },
};
</script>

<style lang="stylus" scoped>
ul
    padding 0
.row
    background: #fff
    margin: 15px

.item
    display: inline-block
    width: 10%;
    margin: 1.14%;

.holder
    position: relative

.imglist .holder .imgItem
    width: 100%
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.3)


.imgItem.updateSelected
    border: 2px solid #c02032

.badge-number
    position: absolute
    bottom: 0
    right: 0
    padding: 8px
    background-color: #fff
    box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.3)
    border-radius: 100px
    font-weight: bold
    font-size: 10px

.public_img, .select_img
    text-align: left
    // border 1px #ccc solid
    background: #ffffff
    padding: 10px 0

.public_img .imgItem
    width: 12%;
    margin: 1.14%;

.del_button
    position: absolute
    right: -3px;
    top: -5px;

.checkbox_img
  position: absolute;
  bottom: -45px;
  left: -5px;

.delete_selected
  margin-left:16px;
</style>
