<template>
  <div v-if="showDCLDefectForm">
    <section-defect-form-dialog :sectionData="dclSectionData"></section-defect-form-dialog>
  </div>
  <div v-else>
    <div class="workOrderDefectId">
      <v-row style="margin-bottom: 20px" no-gutters>
        <v-col cols="12" sm="2" md="2">
          <v-checkbox class="ml-2" label="Apply All SKUs" :input-value="formData.workOrderProductIds.length === skuList.length" value :indeterminate="formData.workOrderProductIds.length > 0 && formData.workOrderProductIds.length < skuList.length" @change="onToggleProductSelection(formData, $event, skuList)"></v-checkbox>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-select id="defect_sku" v-bind:items="skuList" v-model="formData.workOrderProductIds" item-text="skupo" item-value="workOrderProductId" label="SKU" single-line bottom multiple></v-select>
        </v-col>
      </v-row>        
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-select id="defect_category" v-bind:items="defectCategorylist" item-text="name" item-value="id" v-model="formData.defectCategoryId" label="Select Defect Category" single-line bottom @change="onDefectCategoryChange()"></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-autocomplete id="defect_name" label="Defect Name" :items="filteredDefectNameList" item-text="name" item-value="id" v-model="formData.defectNameId"></v-autocomplete>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            id="defect_number"
            class="number_text"
            label="Qty"
            type="number"
            prepend-inner-icon="mdi-minus"
            append-icon="mdi-plus"
            v-model="formData.qty"
            @change="onDefectQtyChange()"
            @click:prepend-inner="minus('qty')"
            @click:append="plus('qty')"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="8">
          <v-radio-group v-model="formData.defectClassificationId" row>
            <v-radio id="defect_classification" v-for="(item, index) in defectClassificationList" :key="index" :label="item.name" :value="item.id"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <AutocompleteSearch :url="getInspectionPhotoDescriptionUrl" @onSelect="onSelect" :itemText="itemText" :itemValue="itemValue" :isOutlined="isOutlined" :label="label" :allowManualInput="allowManualInput" :hideSelected="hideSelected" :existingValue="existingValue"></AutocompleteSearch>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-btn id="defect_add_button" color="red darken-1" text @click="go_add_defect('new')" :disabled="!isFormValid">Add to Defect List</v-btn>
          <v-btn id="defect_update_button" v-if="selectedDefectId" color="red darken-1" text @click="go_add_defect" :disabled="!isFormValid">Update Defect</v-btn>
        </v-col>
      </v-row>
      <v-data-table
        id="defect_table"
        :sort-by="['defectCategory.name', 'defectName.name', 'skuDisplay', 'po', 'qty', 'defectClassification.name']"
        v-bind="datatableRowsPerPage"
        :headers="filteredHeaders"
        :items="filteredDefects"
        :items-per-page="-1"
        class="elevation-1"
      >
        <template v-slot:item.menu="{ item }">
          <v-btn :id="'defect_edit_' + item.id" icon dark @click="edit_item(item)" :disabled="item.isOffline">
            <v-icon color="rgba(0,0,0,.87)">edit</v-icon>
          </v-btn>
          <v-btn
            icon
            dark
            @click="
              toBeDeletedItem = item;
              showConfirmDialog = true;
            "
          >
            <v-icon :id="'defect_delete_' + item.id" color="rgba(0,0,0,.87)" style="margin-left: 15px;" :disabled="item.isOffline">delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="showProgressDialog" :persistent="true">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <div class="text-center">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showConfirmDialog" width="300">
      <v-card>
        <v-card-title class="headline"></v-card-title>
        <v-card-text>Are you sure you want to delete this item?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="delete_item">Yes</v-btn>
          <v-btn color="blue darken-1" text @click="showConfirmDialog = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout" :color="color"> {{ message }} <v-btn text @click="snackbar = false"> Close</v-btn> </v-snackbar>
  </div>
</template>

<script>
let state = {
  appHeaderState: {
    show: true,
    title: "Work Order:",
    showMenu: true,
    showBack: true,
    showLogo: false,
    showTitle: true,
    showSearch: false,
    showCalendar: true,
  },
};

function setState(store) {
  store.dispatch("navigation/setAppHeader", state.appHeaderState);
}
import { mapActions, mapState } from "vuex";
import SectionDefectFormDialog from "../../../components/workorder/SectionDefectFormDialog";
import AutocompleteSearch from "../../../components/common/AutocompleteSearch"

export default {
  name: "workOrderDefectId",
  components: {
    "section-defect-form-dialog": SectionDefectFormDialog,
    AutocompleteSearch,
  },
  data() {
    return {
      datatableRowsPerPage: {
        "footer-props": {
          "items-per-page-options": [-1],
        },
      },
      dialog: false,
      snackbar: false,
      timeout: 3000,
      color: "red darken-2",
      message: "",

      headers: [
        { text: "Defect Type", align: "center", value: "defectType" },
        { text: "Defect Category", align: "center", value: "defectCategory.name" },
        { text: "Defect Name", align: "center", value: "defectName.name" },
        { text: "SKU", align: "center", value: "skuDisplay" },
        { text: "PO", align: "center", value: "po" },
        { text: "QTY", align: "center", value: "qty" },
        { text: "Description", align: "center", value: "defectDetails" },
        { text: "Defect Classification", align: "center", value: "defectClassification.name" },
        { text: "", align: "center", sortable: false, value: "menu" },
      ],
      selectedDefectId: null,
      formData: {
        id: null,
        defectType: null,
        workOrderProductIds: [],
        workOrderProductId: null,
        defectCategoryId: null,
        defectNameId: null,
        defectClassificationId: null,
        defectDetails: null,
        qty: 0,
        sectionId: 11,
      },
      textSupplementList: [],
      skuList: [],
      defectCategorylist: [],
      defectNameList: [],
      defectClassificationList: [],
      workOrderProductDefectList: [],
      workOrder: {},
      toBeDeletedItem: {},
      sectionData: {
        sectionId: 11,
      },
      dclSectionData: [],
      showDCLDefectForm: false,

      showProgressDialog: false,
      showConfirmDialog: false,
      showDclType: false,
      defectToEdit: null,
      getInspectionPhotoDescriptionUrl: "",
      itemText: "description",
      itemValue: "description",
      isOutlined: false,
      label: "Enter defect details",
      allowManualInput: true,
      hideSelected: true,
      existingValue: null
    };
  },
  mounted() {
    const woProductId = this.$route.query.id;
    this.getInspectionPhotoDescriptionUrl = this.apiList.getInspectionPhotoDescriptionUrl + "/" + this.$route.params.id;
    this.showDCLDefectForm = this.isDCLOrGeneric();

    if (!this.showDCLDefectForm) {
      this.utilityFn.getServerData("get", this.apiList.woDetailsToAddWoDefect + "/" + this.$route.params.id, {}, (res) => {
        this.workOrder = res.data.data[0];
        this.skuList = res.data.data[0].products.map((x) => {
          return {
            workOrderProductId: x.id,
            productId: x.productID,
            skupo: x.skuDisplay + " - " + x.po,
            sku: x.skuDisplay,
            po: x.po
          };
        });

        if (woProductId) {
        this.formData.workOrderProductIds = [woProductId];
        }
        this.initState();
      });

      this.utilityFn.getServerData("get", this.apiList.getDefectNames, {}, (result) => {
        this.defectNameList = result.data.data;
      });
      this.utilityFn.getServerData("get", this.apiList.getDefectCategories, {}, (result) => {
        this.defectCategorylist = result.data.data.filter((x) => x.name != "Functional Check Measurement" && x.name != "Package Check");
      });
      this.utilityFn.getServerData("get", this.apiList.getDefectClassifications, {}, (result) => {
        this.defectClassificationList = result.data.data;
      });

      this.utilityFn.getServerData("get", this.apiList.getWorkOrderProductDefects, { workOrderId: this.$route.params.id }, (result) => {
        if (this.isDCLOrGeneric()) {
          //DCL Defect
          this.workOrderProductDefectList = result.data.data.filter((x) => x.clientDefectId != null && x.defectCategoryId != 7 && x.defectCategoryId != 8);
        } else {
          this.workOrderProductDefectList = result.data.data.filter((x) => x.defectNameId != null && x.defectCategoryId != 7 && x.defectCategoryId != 8);
        }

        /*  var that = this;
        this.utilityFn.db.transaction(function(tx) {
          tx.executeSql("SELECT * FROM OfflineData WHERE pageName=defect", [], (tx, results) => {
            var len = results.rows.length;
            for (var i = 0; i < len; i++) {
              // JSON.parse(results.rows.item(i).data)
              that.workOrderProductDefectList.push(JSON.parse(results.rows.item(i).data));
            }
          });
        }); */
      });
    }

    this.utilityFn.getServerData("get", this.apiList.getIsApplyToDclTypeAlias + '/' + this.workOrderDetails.clientId, {  }, (result) => {
      this.showDclType = result.data.data;
    });

  },
  methods: {
    loadData() {
      this.utilityFn.getServerData("get", this.apiList.getWorkOrderProductDefects, { workOrderId: this.$route.params.id }, (result) => {
        if (this.isDCLOrGeneric()) {
          //DCL Defect
          this.workOrderProductDefectList = result.data.data.filter((x) => x.clientDefectId != null && x.defectCategoryId != 7 && x.defectCategoryId != 8);
        } else {
          this.workOrderProductDefectList = result.data.data.filter((x) => x.defectNameId != null && x.defectCategoryId != 7 && x.defectCategoryId != 8);
        }
      });
    },
    isDCLOrGeneric() {
      this.dclSectionData.clientId = this.workOrder.clientId;
      this.dclSectionData.dclType = 1;
      this.dclSectionData.sectionId = this.sectionData.sectionId;
      this.dclSectionData.sectionName = "Workmanship";
      this.dclSectionData.workorderProductId = this.$route.query.id;
      if (this.clientDefectList && this.clientDefectList.length > 0) {
        return true;
      } else {
        return false;
      }
    },

    initState() {
      state.appHeaderState.title = "Work Order: " + this.workOrder.workOrderDisplayId;
      setState(this.$store);
    },
    showDialog() {
      this.formData = {
        id: null,
        workOrderProductIds: [],
        workOrderProductId: null,
        defectCategoryId: null,
        defectNameId: null,
        defectClassificationId: null,
        defectDetails: null,
        qty: 0,
      };
    },
    go_add_defect(status) {
      if (status === "new") {
        this.defectToEdit = null;
        this.formData.id = null;
      }

      this.existingValue = null;

      this.formData.skuDisplay = this.formData.sku;
      this.formData.sectionId = 11;

      
      this.existingValue = this.formData.defectDetails;
      const data = this.formData.workOrderProductIds.map(workOrderProductId => {
        const sku = this.skuList.find((x) => x.workOrderProductId == workOrderProductId).sku;
        const id = this.workOrderProductDefectList.find((x) => x.workOrderProductId === workOrderProductId &&
         x.defectCategoryId === this.defectToEdit?.defectCategoryId &&
         x.defectNameId === this.defectToEdit?.defectNameId &&
         x.defectClassificationId === this.defectToEdit?.defectClassificationId &&
         x.defectDetails === this.defectToEdit?.defectDetails
         )?.id;
        
        return {
          clientDefectId: this.formData.clientDefectId,
          defectCategoryId: this.formData.defectCategoryId,
          defectClassificationId: this.formData.defectClassificationId,
          defectType: null,
          defectCode: this.formData.defectCode,
          defectDetails: this.formData.defectDetails,
          defectName: null,
          defectNameId: this.formData.defectNameId,
          dclTypeAliasId: this.formData.dclTypeAliasId,
          qty: this.formData.qty,
          sectionId: this.formData.sectionId,
          sku: sku,
          skuDisplay: sku,
          workOrderProductId: workOrderProductId,
          id: id
        }
      })

      if (this.isDefectDuplicate()) {
        this.message = "Defect already exist!";
        this.snackbar = true;
        this.formData.id = this.selectedDefectId;
      } else {
        if (window.navigator.onLine === true) {
          this.showProgressDialog = true;
        }
        this.utilityFn.getServerData(
          "post",
          this.apiList.updateWorkOrderProductDefects,
          data,
          (result) => {
            this.loadData();
            this.defectToEdit = null;
            this.existingValue = null;
            this.selectedDefectId = null;
            this.formData = {
              id: null,
              workOrderProductId: this.formData.workOrderProductId,
              workOrderProductIds: this.formData.workOrderProductIds,
              defectCategoryId: null,
              defectNameId: null,
              defectClassificationId: null,
              defectDetails: null,
              qty: 0,
            };

            this.showProgressDialog = false;
          },
          (errRes) => {
            this.showProgressDialog = false;
            if (window.navigator.onLine !== true) {
              if (this.formData.id != null) {
                const index = this.workOrderProductDefectList.findIndex((x) => x.id === this.formData.id);

                this.formData = {
                  id: this.formData.id,
                  skuDisplay: this.formData.skuDisplay,
                  workOrderProductId: this.formData.workOrderProductId,
                  workOrderProductIds: this.formData.workOrderProductIds,
                  defectNameId: this.formData.defectNameId,
                  defectClassificationId: this.formData.defectClassificationId,
                  defectClassification: this.defectClassificationList.find((x) => x.id === this.formData.defectClassificationId),
                  defectName: this.defectNameList.find((x) => x.id === this.formData.defectNameId),
                  defectDetails: this.formData.defectDetails,
                  defectCategoryId: this.formData.defectCategoryId,
                  defectCategory: this.defectCategorylist.find((x) => x.id === this.formData.defectCategoryId),
                  po: this.getSkuPo(this.formData.workOrderProductId),
                  qty: this.formData.qty,
                  sectionId: 11,
                  //  isOffline: 1,
                };

                this.workOrderProductDefectList[index] = { ...this.formData };
                this.workOrderProductDefectList = [...this.workOrderProductDefectList];

                this.formData = {
                  id: null,
                  workOrderProductId: this.formData.workOrderProductId,
                  defectCategoryId: null,
                  defectNameId: null,
                  defectClassificationId: null,
                  defectDetails: null,
                  qty: 0,
                };
                 this.selectedDefectId = null;
              } else {
                const offlineData = {
                  id: new Date().getTime(),
                  skuDisplay: this.formData.skuDisplay,
                  workOrderProductId: this.formData.workOrderProductId,
                  workOrderProductIds: this.formData.workOrderProductIds,
                  defectNameId: this.formData.defectNameId,
                  defectClassificationId: this.formData.defectClassificationId,
                  defectClassification: this.defectClassificationList.find((x) => x.id === this.formData.defectClassificationId),
                  defectName: this.defectNameList.find((x) => x.id === this.formData.defectNameId),
                  defectDetails: this.formData.defectDetails,
                  defectCategoryId: this.formData.defectCategoryId,
                  defectCategory: this.defectCategorylist.find((x) => x.id === this.formData.defectCategoryId),
                  po: this.getSkuPo(this.formData.workOrderProductId),
                  qty: this.formData.qty,
                  sectionId: 11,
                  isOffline: 1,
                };
                //console.log(JSON.stringify(this.workOrderProductDefectList));
                this.formData.sku = this.workOrderProductDefectList.push({ ...offlineData });
                this.workOrderProductDefectList = [...this.workOrderProductDefectList];
                //this.workOrderProductDefectList.push(offlineData);

                this.formData = {
                  id: null,
                  workOrderProductId: this.formData.workOrderProductId,
                  workOrderProductIds: this.formData.workOrderProductIds,
                  defectCategoryId: null,
                  defectNameId: null,
                  defectClassificationId: null,
                  defectDetails: null,
                  qty: 0,
                };
                 this.selectedDefectId = null;
              }
              this.showProgressDialog = false;
              this.message = this.msgList.NoConnection;
              this.snackbar = true;
            }
          }
        );

        this.logChanges();
        //}
      }
    },
    delete_item() {
      if (window.navigator.onLine != true) {
        // this.utilityFn.db.transaction(tx => {
        //     tx.executeSql('DELETE FROM OfflineData WHERE dataId=?', [this.toBeDeletedItem.id])
        // })
        this.utilityFn.getServerData(
          "delete",
          this.apiList.deleteWorkOrderProductDefect + "/" + this.toBeDeletedItem.id,
          {},
          (result) => {},
          (errRes) => {
            var index = this.workOrderProductDefectList.findIndex((x) => x.id === this.toBeDeletedItem.id);
            this.workOrderProductDefectList.splice(index, 1);
            this.showConfirmDialog = false;
          }
        );
      } else {
        this.showConfirmDialog = false;
        this.showProgressDialog = true;

        if (this.toBeDeletedItem.id) {
          this.utilityFn.getServerData("delete", this.apiList.deleteWorkOrderProductDefect + "/" + this.toBeDeletedItem.id, {}, (result) => {
            var index = this.workOrderProductDefectList.findIndex((x) => x.id === this.toBeDeletedItem.id);
            this.workOrderProductDefectList.splice(index, 1);
            this.showProgressDialog = false;
          });

          this.logChanges();
        }
      }
    },
    edit_item(item) {
      this.selectedDefectId = item.id;
      this.formData.id = item.id;
      this.formData.sku = item.sku;
      this.formData.skuDisplay = item.sku;
      this.formData.workOrderProductId = item.workOrderProductId;
      this.formData.workOrderProductIds = [item.workOrderProductId];
      this.formData.qty = item.qty;
      this.formData.defectCategoryId = item.defectCategoryId;
      this.formData.defectNameId = item.defectNameId;
      this.formData.defectClassificationId = item.defectClassificationId;
      this.formData.defectDetails = item.defectDetails;
      this.defectToEdit = {
        defectCategoryId: item.defectCategoryId,
        defectNameId: item.defectNameId,
        defectClassificationId: item.defectClassificationId,
        defectDetails: item.defectDetails
      };
      this.existingValue = item.defectDetails;
    },
    close() {
      this.dialog = false;
    },
    minus(prop) {
      let currentValue = +this.formData[prop];
      if (currentValue > 0) this.formData[prop] = currentValue - 1;
      else this.formData[prop] = 0;
    },
    plus(prop) {
      this.formData[prop] = +this.formData[prop] + 1;
    },
    onDefectQtyChange() {
      let currentValue = +this.formData.qty;
      if (currentValue <= 0) {
        this.formData.qty = 0;
      }
    },
    onDefectCategoryChange() {
      this.formData.defectName = null;
      this.formData.defectNameId = null;
    },
    isDefectDuplicate() {
      let defectList = [...this.workOrderProductDefectList];
      if (this.formData.id) {
        defectList = defectList.filter((defect) => defect.id !== this.formData.id);
      }
      const index = defectList.findIndex((defect) => {
        if (
          defect.defectCategoryId === this.formData.defectCategoryId &&
          defect.defectNameId === this.formData.defectNameId &&
          defect.defectClassificationId === this.formData.defectClassificationId &&
          defect.defectDetails === this.formData.defectDetails &&
          this.formData.workOrderProductIds.includes(defect.workOrderProductId)
        ) {
          return true;
        }
        return false;
      });

      return index !== -1;
    },

    logChanges() {
      window.sessionStorage.setItem("EditDataLogging", true);
    },
    getSkuPo(workOrderProductId) {
      return this.skuList.find((x) => x.workOrderProductId == workOrderProductId).po;
    },
    onToggleProductSelection(form, currentValue, options) {
      if(currentValue) {
        form['workOrderProductIds'] = options.map(x => x.workOrderProductId);
      } else {
        form['workOrderProductIds'] = [];
      }
    },
    onSelect(value) {
      this.formData.defectDetails = value?.description;
    },
  },
  computed: {
    ...mapState("inspection", {
      clientDefectList: (state) => state.clientDefectList,
      workOrderDetails: (state) => state.workOrderDetails,
    }),

    filteredHeaders() {
      return !this.showDclType ? this.headers.filter(x => x.value !== 'defectType') : this.headers;
    },

    filteredDefectNameList: function() {
      return this.defectNameList.filter((x) => x.defectCategoryId == this.formData.defectCategoryId && (!this.formData.defectType || x.defectType == this.formData.defectType));
    },

    filteredWorkOrderProductDefectList: function() {
      // Show Defects from Stock Inspected Sections and Workmanship Section
      return this.workOrderProductDefectList.filter((x) => x.sectionId == 5 || x.sectionId == 11);
    },

    isFormValid() {
      if (
        this.formData.workOrderProductIds.length > 0 &&
        this.formData.defectCategoryId &&
        this.formData.defectNameId &&
        this.formData.defectClassificationId &&
        this.formData.defectDetails &&
        this.formData.qty && this.formData.qty > 0) {
        return true;
      }

      return false;
    },

    // show only defects related to the selected sku
    filteredDefects() {
      return this.filteredWorkOrderProductDefectList
                  .filter(x => ((this.formData.workOrderProductIds.length === 0) || this.formData.workOrderProductIds.includes(x.workOrderProductId)))
                  .map(x => ({ ...x, po: this.getSkuPo(x.workOrderProductId) }))
    },
  },
};
</script>

<style lang="stylus" scoped>

.workOrderDefectId
    padding 15px 30px
    background #fff

>>>.number_text input
    text-align center
</style>
