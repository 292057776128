<template>
  <div>
    <div class="workOrderInspectionSizeId section">
      <div style="position: relative;">
        <v-row>
          <v-col cols="12" sm="2" style="text-align:left;">
            <v-switch :disabled="isSectionDisabled" id="isVaryingAQL" color="green" v-model="workOrderSizeMeasurementObj.isVaryingAQL" inset label="Apply Varying AQL" @change="onChangeVaryingAQL()"></v-switch>
          </v-col>
          <v-col cols="12" sm="2" style="text-align:left;">
            <SpecialInstructions style="margin-top:7%;" :workOrderId="this.$route.params.id"></SpecialInstructions>
          </v-col>
          <v-col cols="12" sm="3" style="text-align:left;">
            <v-switch :disabled="isSectionDisabled" id="isPOM" color="green" v-model="workOrderSizeMeasurementObj.isPOM" inset label="POM/OML" @change="onChangePOM()"></v-switch>
          </v-col>
          <v-col cols="12" sm="4" style="text-align:right;">
            <v-btn v-if="showButton" :disabled="isSectionDisabled" class="submit-btn" @click="submit_item(true)" small depressed color="#1867c0">Submit</v-btn>
            <!-- <v-btn :disabled="sectionData.SizeMeasurement.isSectionSubmitted" class="submit-btn" small depressed color="#1867c0" @click="go_upload_photo('SizeMeasurement',2)"> -->
            <v-btn :disabled="isSectionDisabled" class="submit-btn" small depressed color="#1867c0" @click="go_to_gallery">
              <v-icon>camera_alt</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-tabs v-model="tab" background-color="rgb(0, 81, 138)" dark>
          <v-tab href="#tab-1" @click="clearMassUpdate()">
            Carton
          </v-tab>
          <v-tab href="#tab-3" @click="clearMassUpdate()">
            Product <!-- (Size) -->
          </v-tab>
          <v-tab href="#tab-4" @click="clearMassUpdate()"  v-if="isPomOml">
            <!-- Product(Weight) -->
            POM/OML
          </v-tab>
        </v-tabs>

        <v-tabs-items touchless v-model="tab">
          <v-tab-item class="tab_item" :value="'tab-1'" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
            <pulse-validator v-if="errorsCartonTab.length > 0" :errors="errorsCartonTab"></pulse-validator>

            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="workOrderSizeMeasurementObj.cartonSizeResultId"
                  :items="workOrderResultList"
                  item-value="id"
                  item-text="name"
                  :label="isFinalRandomShippingAndWalmartOrAsda() ? 'Result*' : 'Result'"
                  @change="changeSizeMeasurementResult('cartonSizeResultCode')"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <div class="code-container">
                  <div v-if="(cartonSizeResultValue == 'Pending' || cartonSizeResultValue == 'Fail') && isGenericCodes" class="helper-container">
                      <GenericCodeHelper></GenericCodeHelper>
                  </div>
                  <v-form v-model="isCartonResultValid">
                    <v-select v-if="cartonSizeResultValue == 'Pending' || cartonSizeResultValue == 'Fail'"
                        v-model="workOrderSizeMeasurementObj.cartonSizeResultCode" 
                        :item-text="(item) => item.description ? `${item.code} - ${item.description}` : `${item.code}`"
                        item-value="code"
                        :items="cartonSizeFailCodeOptions"
                        :rules="[(v) => !!v || 'Required']"
                        :label="cartonSizeResultValue == 'Pending' ? 'Pending Code' : 'Fail Code'">
                    </v-select>
                  </v-form>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field hide-details label="Remarks" v-model="workOrderSizeMeasurementObj.cartonSizeRemarks"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  hide-details
                  v-model="workOrderSizeMeasurementObj.cartonSizeInspectionLevel"
                  :items="inspectionLevel1Options"
                  item-value="name"
                  item-text="name"
                  :label="isFinalRandomShippingAndWalmartOrAsda() ? 'Inspection Level*' : 'Inspection Level'"
                  @change="onChangeInspectionLevel($event, 'cartonSize')"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select hide-details v-bind:items="unitOfMeasurementLengthOptions" v-model="workOrderSizeMeasurementObj.cartonSizeUnitOfMeasureId" item-text="description" item-value="id"  @change="onChangeUnitofMeasure($event, 'cartonSize')" :label="isFinalRandomShippingAndWalmartOrAsda() ? 'Unit*' : 'Unit'"></v-select>
              </v-col>
            </v-row>
            <v-row> </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field hide-details type="number" label="Size Tolerancel(upper)(%)" v-model="workOrderSizeMeasurementObj.cartonSizeToleranceUpper" @change="updateAllSKUResultCartonSize()"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field hide-details type="number" label="Size Tolerancel(lower)(%)" v-model="workOrderSizeMeasurementObj.cartonSizeToleranceLower" @change="updateAllSKUResultCartonSize()"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <span :style="{ color: '#ff0000' }" class="font-italic">Note: If tolerance is not applicable, enter "-1" in both upper and lower size tolerance.</span>
            </v-row>
            <v-row v-show="workOrderSizeMeasurementObj.isVaryingAQL" style="margin-bottom:1%;margin-top:1%">
              <v-col cols="12" sm="3" md="2">
                <v-select id="cartonSizeAqlCritical" hide-details v-model="workOrderSizeMeasurementObj.cartonAqlCriticalID" :items="aqlOptions" item-value="id" item-text="name" label="AQL - Critical"> </v-select>
              </v-col>
              <v-col cols="12" sm="3" md="2">
                <v-select id="cartonSizeAqlMajor" hide-details v-model="workOrderSizeMeasurementObj.cartonAqlMajorID" :items="aqlOptions" item-value="id" item-text="name" label="AQL - Major"> </v-select>
              </v-col>
              <v-col cols="12" sm="3" md="2">
                <v-select id="cartonSizeAqlMinor" hide-details v-model="workOrderSizeMeasurementObj.cartonAqlMinorID" :items="aqlOptions" item-value="id" item-text="name" label="AQL - Minor"> </v-select>
              </v-col>
              <v-col cols="12" sm="3" md="2">
                <v-select id="cartonSizeAqlOther" hide-details v-model="workOrderSizeMeasurementObj.cartonAqlOtherID" :items="aqlOptions" item-value="id" item-text="name" label="AQL - Others"> </v-select>
              </v-col>
            </v-row>

            <!-- Mass Update of WorkorderProductSizeMeasurement CartonSize -->
            <v-row>
              <v-col cols="12" md="3">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-expansion-panels class="mb-6">
                      <v-expansion-panel>
                        <v-expansion-panel-header disable-icon-rotate>
                          <v-text-field hide-details label="<SKU> - <PO>" @click.stop v-model="cartonSizeSearchSKUPO"></v-text-field>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-data-table v-bind="datatableRowsPerPage" height="500px" :items-per-page="-1" v-model="selectedPO" :sort-by="['skuDisplay', 'po']" :headers="skuHeader" :items="filterWorkOrderProducts" item-key="id" show-select>
                            <template v-slot:item.sku_po="{ item }">
                              <span>{{ item.skuDisplay + " - " + item.po }}</span>
                            </template>
                          </v-data-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="8">
                <v-row>
                  <v-col cols="6" md="2">
                    <v-text-field type="text" v-model="cartonProductMassUpdate.actualLength" @keyup="returnNumber(cartonProductMassUpdate, 'actualLength')" label="Actual L"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2">
                    <v-text-field type="text" v-model="cartonProductMassUpdate.actualWidth" @keyup="returnNumber(cartonProductMassUpdate, 'actualWidth')" label="Actual W"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2">
                    <v-text-field type="text" v-model="cartonProductMassUpdate.actualHeight" @keyup="returnNumber(cartonProductMassUpdate, 'actualHeight')" label="Actual H"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2">
                    <v-text-field type="text" v-model="cartonProductMassUpdate.actualWeight" @keyup="returnNumber(cartonProductMassUpdate, 'actualWeight')" label="Actual Weight"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2">
                    <v-text-field type="text" v-model="cartonProductMassUpdate.sampleSize" @keyup="returnNumber(cartonProductMassUpdate, 'sampleSize')" label="Sample Size"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2">
                    <v-card-actions>
                      <v-btn class="submit-btn" color="#1867c0" @click="showConfirmDialog('cartonsize')" :disabled="disabledApplyButton">Apply</v-btn>
                      <v-btn class="submit-btn" color="#D3D3D3" @click="clearMassUpdate()">Clear</v-btn>
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-dialog v-model="showConfirmApplyDialog" width="300">
              <v-card>
                <v-card-title class="headline"></v-card-title>
                <v-card-text>Apply changes?</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="applyMassUpdate(showConfirmTabName)">Yes</v-btn>
                  <v-btn color="blue darken-1" text @click="showConfirmApplyDialog = false">No</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-data-table
              :sort-by="['po', 'skuDisplay', 'orderNumber']"
              v-bind:headers="showHeadersCartonSize"
              :items="cartonSizeMeasurementList"
              class="elevation-1 textAlignCenter"
              style="margin-top: 10px;"
              :loading="cartonSizeLoading"
              loading-text="Loading... Please wait"
              @update:options="resetCartonSizeIndexMarkers"
              :items-per-page="-1"
            >
              <template v-slot:progress>
                <v-progress-linear indeterminate></v-progress-linear>
              </template>

              <template v-slot:header.specified="{ header }">
                <v-row>
                  <v-col cols="12">Specified</v-col>
                  <v-col cols="4">L</v-col>
                  <v-col cols="4">W</v-col>
                  <v-col cols="4">H</v-col>
                </v-row>
              </template>
              <template v-slot:header.actual="{ header }">
                <v-row>
                  <v-col cols="12">Actual</v-col>
                  <v-col cols="4">L</v-col>
                  <v-col cols="4">W</v-col>
                  <v-col cols="4">H</v-col>
                </v-row>
              </template>
              <template v-slot:header.critical="{ header }">
                <v-row>
                  <v-col cols="12">Critical</v-col>
                  <v-col cols="4">F</v-col>
                  <v-col cols="4">A</v-col>
                  <v-col cols="4">R</v-col>
                </v-row>
              </template>
              <template v-slot:header.major="{ header }">
                <v-row>
                  <v-col cols="12">Major</v-col>
                  <v-col cols="4">F</v-col>
                  <v-col cols="4">A</v-col>
                  <v-col cols="4">R</v-col>
                </v-row>
              </template>

              <template v-slot:header.minor="{ header }">
                <v-row>
                  <v-col cols="12">Minor</v-col>
                  <v-col cols="4">F</v-col>
                  <v-col cols="4">A</v-col>
                  <v-col cols="4">R</v-col>
                </v-row>
              </template>

              <template v-slot:body="{ items, pagination }">
                <tbody>
                  <tr v-for="(item, index) in items" :key="index">                   
                    <td><span style="display: none;">{{ (item.indexMarkerCarton = index + pagination.pageStart) }}</span>
                      <span :id="'itemCartonClientProductID_' + item.clientProductId"> {{ item.clientProductId }} </span>
                    </td>
                    <td>
                      <span :id="'itemCartonSKU_' + item.clientProductId"> {{ item.skuDisplay }} </span>
                    </td>
                    <td>
                      <span :id="'itemCartonPo_' + item.clientProductId"> {{ item.po }} </span>
                    </td>
                    <td class="w-lg">
                      <v-row>
                        <v-col cols="4"
                          ><v-text-field
                            :id="'txtCartonSpecifiedLength_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.specifiedLength"
                            type="text"
                            :rules="[rules.required]"
                            outlined
                            dense
                            @change="ChangeCartonSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                        <v-col cols="4"
                          ><v-text-field
                            :id="'txtCartonSpecifiedWidth_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.specifiedWidth"
                            type="text"
                            :rules="[rules.required]"
                            outlined
                            dense
                            @change="ChangeCartonSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                        <v-col cols="4"
                          ><v-text-field
                            :id="'txtCartonSpecifiedHeight_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.specifiedHeight"
                            type="text"
                            :rules="[rules.required]"
                            outlined
                            dense
                            @change="ChangeCartonSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                      </v-row>
                    </td>
                    <td class="w-sm">
                      <v-text-field
                        :id="'txtCartonSpecifiedWeight_' + item.clientProductId"
                        class="number_text"
                        hide-details
                        v-model="item.specifiedWeight"
                        type="text"
                        :rules="[rules.required]"
                        outlined
                        dense
                        @change="ChangeCartonSizeLengthWidthHeight(item)"
                      ></v-text-field>
                    </td>
                    <td class="w-lg">
                      <v-row>
                        <v-col cols="4"
                          ><v-text-field
                            :id="'txtCartonActualLength_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.actualLength"
                            type="text"
                            :rules="[rules.required]"
                            outlined
                            dense
                            @change="ChangeCartonSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                        <v-col cols="4"
                          ><v-text-field
                            :id="'txtCartonActualWidth_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.actualWidth"
                            type="text"
                            :rules="[rules.required]"
                            outlined
                            dense
                            @change="ChangeCartonSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                        <v-col cols="4"
                          ><v-text-field
                            :id="'txtCartonActualHeight_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.actualHeight"
                            type="text"
                            :rules="[rules.required]"
                            outlined
                            dense
                            @change="ChangeCartonSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                      </v-row>
                    </td>
                    <td class="w-sm">
                      <v-text-field
                        :id="'txtCartonActualWeight_' + item.clientProductId"
                        class="number_text"
                        hide-details
                        v-model="item.actualWeight"
                        type="text"
                        outlined
                        dense
                        @change="ChangeCartonSizeLengthWidthHeight(item)"
                      ></v-text-field>
                    </td>
                    <td>
                      <span>{{ getUnitOfMeasurementsType(item.dimensionalUnit) }} </span>
                    </td>
                    <td class="w-sm">
                      <v-text-field :id="'txtCartonSampleSize_' + item.clientProductId" class="number_text" hide-details  v-if="item.orderNumber == 1" v-model="item.sampleSize" type="text" outlined dense></v-text-field>
                    </td>
                     
                    <td v-show="workOrderSizeMeasurementObj.isVaryingAQL" class="w-lg">
                      <v-row>
                        <v-col cols="4"
                          ><v-text-field
                            :id="'txtCartonFoundCritical_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.foundCritical"
                            type="text"
                            outlined
                            dense
                            @keyup="returnNumber(item, 'foundCritical')"
                            @change="ChangeCartonSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                        <v-col cols="4"
                          ><v-text-field
                            :id="'txtCartonAcceptCritical_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.acceptCritical"
                            type="text"
                            outlined
                            dense
                            @keyup="returnNumber(item, 'acceptCritical')"
                            @change="ChangeCartonSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                        <v-col cols="4"
                          ><v-text-field
                            :id="'txtCartonRejectCritical_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.rejectCritical"
                            type="text"
                            outlined
                            dense
                            @keyup="returnNumber(item, 'rejectCritical')"
                            @change="ChangeCartonSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                      </v-row>
                    </td>
                    <td v-show="workOrderSizeMeasurementObj.isVaryingAQL" class="w-lg">
                      <v-row>
                        <v-col cols="4"
                          ><v-text-field
                            :id="'txtCartonFoundMajor_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.foundMajor"
                            type="text"
                            outlined
                            dense
                            @keyup="returnNumber(item, 'foundMajor')"
                            @change="ChangeCartonSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                        <v-col cols="4"
                          ><v-text-field
                            :id="'txtCartonAcceptMajor_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.acceptMajor"
                            type="text"
                            outlined
                            dense
                            @keyup="returnNumber(item, 'acceptMajor')"
                            @change="ChangeCartonSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                        <v-col cols="4"
                          ><v-text-field
                            :id="'txtCartonRejectMajor_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.rejectMajor"
                            type="text"
                            outlined
                            dense
                            @keyup="returnNumber(item, 'rejectMajor')"
                            @change="ChangeCartonSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                      </v-row>
                    </td>
                    <td v-show="workOrderSizeMeasurementObj.isVaryingAQL" class="w-lg">
                      <v-row>
                        <v-col cols="4"
                          ><v-text-field
                            :id="'txtCartonFoundMinor_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.foundMinor"
                            type="text"
                            outlined
                            dense
                            @keyup="returnNumber(item, 'foundMinor')"
                            @change="ChangeCartonSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                        <v-col cols="4"
                          ><v-text-field
                            :id="'txtCartonAcceptMinor_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.acceptMinor"
                            type="text"
                            outlined
                            dense
                            @keyup="returnNumber(item, 'acceptMinor')"
                            @change="ChangeCartonSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                        <v-col cols="4"
                          ><v-text-field
                            :id="'txtCartonRejectMinor_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.rejectMinor"
                            type="text"
                            outlined
                            dense
                            @keyup="returnNumber(item, 'rejectMinor')"
                            @change="ChangeCartonSizeLengthWidthHeight(item)"
                            >></v-text-field
                          ></v-col
                        >
                      </v-row>
                    </td>
                    <td>
                      <v-select v-bind:items="resultList" item-text="description" item-value="id" v-model="item.dimensionalResult" single-line bottom hide-details :rules="[rules.required]" @change="calculateFCMResult('tab-1')"></v-select>
                    </td>
                    <td>
                      <template v-if="item.orderNumber == 1 && item.sampleSize > getWorkorderProductIdTotalCount(item.workOrderProductId, item.isCarton)">
                        <v-btn icon dark>
                          <v-icon color="black" @click.stop="addChild(item, 'cartonSize')">add</v-icon>
                        </v-btn>
                      </template>
                      <template v-else-if="item.orderNumber > 1">
                        <v-btn icon dark>
                          <v-icon color="black" @click.stop="deleteChild(item, 'cartonSize')">delete</v-icon>
                        </v-btn>
                      </template>
                      <template v-if="item.indexMarkerCarton >= 1">
                        <v-btn icon title="Copy From Above" @click="copyAboveRowCarton(item.indexMarkerCarton)">
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>

            <v-dialog v-model="showToleranceDialogCartonSize" width="450">
              <v-card>
                <v-card-title class="headline">Tolerance Confirmation Window</v-card-title>
                <v-card-text>Is it not applicable due to non-availablilty of tolerance from client?</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="UpdateTolerance('CartonSize', 'Yes')">Yes</v-btn>
                  <v-btn color="blue darken-1" text @click="UpdateTolerance('CartonSize', 'No')">No</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="cartonSizeDialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field id="txtCartonSizeDialogClientProductID" hide-details v-model="cartonSizeEditedItem.clientProductId" label="Client Product ID" type="text" disabled></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field id="txtCartonSizeDialogSKU" hide-details v-model="cartonSizeEditedItem.skuDisplay" label="SKU" type="text" disabled></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="cartonSizeEditedItem.specifiedLength"
                          type="text"
                          @keyup="returnNumber(cartonSizeEditedItem, 'specifiedLength')"
                          @change="ChangeCartonSizeLengthWidthHeight(cartonSizeEditedItem)"
                          label="Specified L"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="cartonSizeEditedItem.specifiedWidth"
                          type="text"
                          @keyup="returnNumber(cartonSizeEditedItem, 'specifiedWidth')"
                          @change="ChangeCartonSizeLengthWidthHeight(cartonSizeEditedItem)"
                          label="Specified W"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="cartonSizeEditedItem.specifiedHeight"
                          type="text"
                          @keyup="returnNumber(cartonSizeEditedItem, 'specifiedHeight')"
                          @change="ChangeCartonSizeLengthWidthHeight(cartonSizeEditedItem)"
                          label="Specified H"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="cartonSizeEditedItem.specifiedWeight"
                          type="text"
                          @keyup="returnNumber(cartonSizeEditedItem, 'specifiedWeight')"
                          @change="ChangeCartonSizeLengthWidthHeight(cartonSizeEditedItem)"
                          label="Weight Spec"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <span :style="{ color: '#ff0000' }" class="font-italic">Note: If size is not applicable, enter "-1".</span>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="cartonSizeEditedItem.actualLength" type="text" @keyup="returnNumber(cartonSizeEditedItem, 'actualLength')" label="L" @change="ChangeCartonSizeLengthWidthHeight(cartonSizeEditedItem)"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="cartonSizeEditedItem.actualWidth" type="text" @keyup="returnNumber(cartonSizeEditedItem, 'actualWidth')" @change="ChangeCartonSizeLengthWidthHeight(cartonSizeEditedItem)" label="W"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="cartonSizeEditedItem.actualHeight" type="text" @keyup="returnNumber(cartonSizeEditedItem, 'actualHeight')" @change="ChangeCartonSizeLengthWidthHeight(cartonSizeEditedItem)" label="H"></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- Varying AQL -->
                    <v-row v-show="workOrderSizeMeasurementObj.isVaryingAQL">
                      <v-col cols="12" sm="12" md="12">
                        <span>Critical</span>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="cartonSizeEditedItem.foundCritical"
                          type="text"
                          @keyup="returnNumber(cartonSizeEditedItem, 'foundCritical')"
                          label="Found"
                          @change="ChangeCartonSizeLengthWidthHeight(cartonSizeEditedItem)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="cartonSizeEditedItem.acceptCritical"
                          type="text"
                          @keyup="returnNumber(cartonSizeEditedItem, 'acceptCritical')"
                          @change="ChangeCartonSizeLengthWidthHeight(cartonSizeEditedItem)"
                          label="Acceptable"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="cartonSizeEditedItem.rejectCritical"
                          type="text"
                          @keyup="returnNumber(cartonSizeEditedItem, 'rejectCritical')"
                          @change="ChangeCartonSizeLengthWidthHeight(cartonSizeEditedItem)"
                          label="Reject"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <span>Major</span>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="cartonSizeEditedItem.foundMajor" type="text" @keyup="returnNumber(cartonSizeEditedItem, 'foundMajor')" label="Found" @change="ChangeCartonSizeLengthWidthHeight(cartonSizeEditedItem)"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="cartonSizeEditedItem.acceptMajor"
                          type="text"
                          @keyup="returnNumber(cartonSizeEditedItem, 'acceptMajor')"
                          @change="ChangeCartonSizeLengthWidthHeight(cartonSizeEditedItem)"
                          label="Acceptable"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="cartonSizeEditedItem.rejectMajor" type="text" @keyup="returnNumber(cartonSizeEditedItem, 'rejectMajor')" @change="ChangeCartonSizeLengthWidthHeight(cartonSizeEditedItem)" label="Reject"></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <span>Minor</span>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="cartonSizeEditedItem.foundMinor" type="text" @keyup="returnNumber(cartonSizeEditedItem, 'foundMinor')" label="Found" @change="ChangeCartonSizeLengthWidthHeight(cartonSizeEditedItem)"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="cartonSizeEditedItem.acceptMinor"
                          type="text"
                          @keyup="returnNumber(cartonSizeEditedItem, 'acceptMinor')"
                          @change="ChangeCartonSizeLengthWidthHeight(cartonSizeEditedItem)"
                          label="Acceptable"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="cartonSizeEditedItem.rejectMinor" type="text" @keyup="returnNumber(cartonSizeEditedItem, 'rejectMinor')" @change="ChangeCartonSizeLengthWidthHeight(cartonSizeEditedItem)" label="Reject"></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-if="cartonSizeEditedItem.orderNumber == 1" type="text" @keyup="returnNumberSampleSize(cartonSizeEditedItem, 'sampleSize')" v-model="cartonSizeEditedItem.sampleSize" label="Sample Size"></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-radio-group v-model="cartonSizeEditedItem.dimensionalResult" label="Result" row>
                        <v-radio label="Pass" value="1"></v-radio>
                        <v-radio label="Fail" value="0"></v-radio>
                        <v-radio label="Pending" value="2"></v-radio>
                        <v-radio label="N/A" value="3"></v-radio>
                      </v-radio-group>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="saveCartonSize('cartonSize')" :disabled="buttonDisable">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-tab-item>

          <v-tab-item class="tab_item" :value="'tab-3'" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="workOrderSizeMeasurementObj.productSizeResultId"
                  :items="workOrderResultList"
                  item-value="id"
                  item-text="name"
                  :label="isFinalRandomShippingAndWalmartOrAsda() ? 'Result*' : 'Result'"
                  @change="changeSizeMeasurementResult('productSizeResultCode')"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="5">
                <div class="code-container">
                  <div v-if="(productSizeResultValue == 'Pending' || productSizeResultValue == 'Fail') && isGenericCodes" class="helper-container">
                      <GenericCodeHelper></GenericCodeHelper>
                  </div>
                  <v-form v-model="isProductResultValid">
                    <v-select v-if="productSizeResultValue == 'Pending' || productSizeResultValue == 'Fail'"
                        v-model="workOrderSizeMeasurementObj.productSizeResultCode" 
                        :item-text="(item) => item.description ? `${item.code} - ${item.description}` : `${item.code}`"
                        item-value="code"
                        :items="productSizeFailCodeOptions"
                        :rules="[(v) => !!v || 'Required']"
                        :label="productSizeResultValue == 'Pending' ? 'Pending Code' : 'Fail Code'">
                    </v-select>
                  </v-form>
                </div>
              </v-col>
              <v-col cols="12" sm="1">
                <v-btn small class="white--text" color="#1867c0" @click="showImageDialog = true" style="float: right; bottom: 20px;" :disabled="isSectionDisabled">Upload Measurement Findings
                  <v-icon>camera_alt</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field hide-details label="Remarks" v-model="workOrderSizeMeasurementObj.productSizeRemarks" @keyup="onKeyUp(workOrderSizeMeasurementObj.productSizeRemarks)"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" >
                <v-select hide-details v-model="workOrderSizeMeasurementObj.productSizeSamplingMethodology" :items="sampleSizeTypeOptions" item-text="name" item-value="name" readonly label="Sampling Methodology" @change="onChangeSamplingMethodology($event)">
                </v-select>
              </v-col>
              <v-col cols="12" sm="5">
                <v-select hide-details v-model="workOrderSizeMeasurementObj.productSizeInspectionLevel" :items="inspectionLevelOptions" item-value="name" item-text="name" :label="isFinalRandomShippingAndWalmartOrAsda() ? 'Inspection Level*' : 'Inspection Level'" @change="onChangeInspectionLevel($event)"> </v-select>
              </v-col>
              <v-col cols="12" sm="1">
                <pulse-validator v-if="errorsProductSizeTab.length > 0" :errors="errorsProductSizeTab"></pulse-validator>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select hide-details v-bind:items="unitOfMeasurementLengthOptions" v-model="workOrderSizeMeasurementObj.productSizeUnitOfMeasureId" item-text="description" item-value="id"   @change="onChangeUnitofMeasure($event, 'productSize')"  :label="isFinalRandomShippingAndWalmartOrAsda() ? 'Dimensional Unit*' : 'Dimensional Unit'"> </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select hide-details v-bind:items="unitOfMeasurementWeightOptions" v-model="workOrderSizeMeasurementObj.productWeightUnitOfMeasureId" item-text="description" item-value="id"  @change="onChangeUnitofMeasure($event, 'productWeight')"  :label="isFinalRandomShippingAndWalmartOrAsda() ? 'Weight Unit*' : 'Weight Unit'"> </v-select>
              </v-col>
            </v-row>

            <!-- <div v-show="!IsPOM()"> -->

            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field hide-details type="number" v-model="workOrderSizeMeasurementObj.productSizeToleranceUpper" label="Size Tolerancel(upper)(%)" @change="updateAllSKUResultProductSize()"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field hide-details type="number" v-model="workOrderSizeMeasurementObj.productSizeToleranceLower" label="Size Tolerancel(lower)(%)" @change="updateAllSKUResultProductSize()"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <span :style="{ color: '#ff0000' }" class="font-italic">Note: If tolerance is not applicable, enter "-1" in both upper and lower size tolerance.</span>
            </v-row>
            <div v-show="workOrderSizeMeasurementObj.isVaryingAQL">
              <v-row style="margin-bottom:1%;margin-top:1%">
                <v-col cols="12" sm="3" md="2">
                  <v-select hide-details v-model="workOrderSizeMeasurementObj.productAqlCriticalID" :items="aqlOptions" item-value="id" item-text="name" label="AQL - Critical" @change="onChangeAQL()"> </v-select>
                </v-col>
                <v-col cols="12" sm="3" md="2">
                  <v-select hide-details v-model="workOrderSizeMeasurementObj.productAqlMajorID" :items="aqlOptions" item-value="id" item-text="name" label="AQL - Major" @change="onChangeAQL()"> </v-select>
                </v-col>
                <v-col cols="12" sm="3" md="2">
                  <v-select hide-details v-model="workOrderSizeMeasurementObj.productAqlMinorID" :items="aqlOptions" item-value="id" item-text="name" label="AQL - Minor" @change="onChangeAQL()"> </v-select>
                </v-col>
                <v-col cols="12" sm="3" md="2">
                  <v-select hide-details v-model="workOrderSizeMeasurementObj.productAqlOtherID" :items="aqlOptions" item-value="id" item-text="name" label="AQL - Others"> </v-select>
                </v-col>
                <v-col cols="12" sm="3" md="2" style="text-align:right;" class="add_defect_button">
                  <v-btn color="red" @click="openDefectFormDialog()" class="ma-2 white--text"> <v-icon left dark> mdi-plus-circle </v-icon> Defect </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="3" md="2">
                  <v-text-field hide-details v-model="workOrderSizeMeasurementObj.productSZCritical" label="SZ -Critical"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="2">
                  <v-text-field hide-details v-model="workOrderSizeMeasurementObj.productSZMajor" label="SZ -Major"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="2">
                  <v-text-field hide-details v-model="workOrderSizeMeasurementObj.productSZMinor" label="SZ -Minor"></v-text-field>
                </v-col>
              </v-row>
            </div>

            <!-- Mass Update of WorkorderProductSizeMeasurement ProductSize -->
            <v-row>
              <v-col cols="12" md="3">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-expansion-panels class="mb-6">
                      <v-expansion-panel>
                        <v-expansion-panel-header disable-icon-rotate>
                          <v-text-field hide-details label="<SKU> - <PO>" @click.stop v-model="cartonSizeSearchSKUPO"></v-text-field>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-data-table v-model="selectedPO" v-bind="datatableRowsPerPage" height="500px" :items-per-page="-1" :sort-by="['skuDisplay', 'po']" :headers="skuHeader" :items="filterWorkOrderProducts" item-key="id" show-select>
                            <template v-slot:item.sku_po="{ item }">
                              <span>{{ item.skuDisplay + " - " + item.po }}</span>
                            </template>
                          </v-data-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="8">
                <v-row>
                  <v-col cols="6" md="2">
                    <v-text-field type="text" v-model="cartonProductMassUpdate.actualLength" @keyup="returnNumber(cartonProductMassUpdate, 'actualLength')" label="Actual L"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2">
                    <v-text-field type="text" v-model="cartonProductMassUpdate.actualWidth" @keyup="returnNumber(cartonProductMassUpdate, 'actualWidth')" label="Actual W"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2">
                    <v-text-field type="text" v-model="cartonProductMassUpdate.actualHeight" @keyup="returnNumber(cartonProductMassUpdate, 'actualHeight')" label="Actual H"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2">
                    <v-text-field type="text" v-model="cartonProductMassUpdate.sampleSize" @keyup="returnNumber(cartonProductMassUpdate, 'sampleSize')" label="Sample Size"></v-text-field>
                  </v-col>                  
                  <v-col cols="6" md="2">
                    <v-text-field type="text" v-model="cartonProductMassUpdate.actualWeight" @keyup="returnNumber(cartonProductMassUpdate, 'actualWeight')" label="Actual Weight"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2">
                    <v-card-actions>
                      <v-btn class="submit-btn" color="#1867c0" @click="showConfirmDialog('productsize')" :disabled="disabledApplyProductSizeButton">Apply</v-btn>
                      <v-btn class="submit-btn" color="#D3D3D3" @click="clearMassUpdate()">Clear</v-btn>
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-simple-table v-if="ShowAQLinSumarry()">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"></th>
                    <th class="text-center">Found</th>
                    <th class="text-center">Acc</th>
                    <th class="text-center">Rej</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Critical</td>
                    <td>
                      <v-text-field id="FCM_ProductSizeTab_foundCritical" class="number_text" :value="countFoundSummaryTable.totalFoundCritical" type="number" readonly></v-text-field>
                    </td>
                    <td>
                      <v-text-field id="FCM_ProductSizeTab_AllowedCritical" class="number_text" v-model="workOrderSizeMeasurementObj.allowedCritical" type="number"></v-text-field>
                    </td>
                    <td>
                      <v-text-field id="FCM_ProductSizeTab_RejectedCritical" class="number_text" v-model="workOrderSizeMeasurementObj.rejectedCritical" @change="onRejectValueChange('productweight')"></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td>Major</td>
                    <td>
                      <v-text-field id="FCM_ProductSizeTab_foundMajor" class="number_text" :value="countFoundSummaryTable.totalFoundMajor" type="number" readonly></v-text-field>
                    </td>
                    <td>
                      <v-text-field id="FCM_ProductSizeTab_AllowedMajor" class="number_text" v-model="workOrderSizeMeasurementObj.allowedMajor" type="number"></v-text-field>
                    </td>
                    <td>
                      <v-text-field id="FCM_ProductSizeTab_RejectedMajor" class="number_text" v-model="workOrderSizeMeasurementObj.rejectedMajor" @change="onRejectValueChange('productweight')"></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td>Minor</td>
                    <td>
                      <v-text-field id="FCM_ProductSizeTab_foundMinor" class="number_text" :value="countFoundSummaryTable.totalFoundMinor" type="number" readonly></v-text-field>
                    </td>
                    <td>
                      <v-text-field id="FCM_ProductSizeTab_AllowedMinor" class="number_text" v-model="workOrderSizeMeasurementObj.allowedMinor" type="number"></v-text-field>
                    </td>
                    <td>
                      <v-text-field id="FCM_ProductSizeTab_RejectedMinor" class="number_text" v-model="workOrderSizeMeasurementObj.rejectedMinor" @change="onRejectValueChange('productweight')"></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-data-table
              :sort-by="['po', 'skuDisplay', 'orderNumber']"
              v-bind:headers="showHeadersProductSize"
              :items="productSizeMeasurementList"
              class="elevation-1 textAlignCenter"
              style="margin-top: 10px;"
              :loading="cartonSizeLoading"
              loading-text="Loading... Please wait"
              @update:options="resetProductSizeIndexMarkers"
              :items-per-page="-1"
            >
              <template v-slot:progress>
                <v-progress-linear indeterminate></v-progress-linear>
              </template>

              <template v-slot:header.specified="{ header }">
                <v-row>
                  <v-col cols="12">Specified</v-col>
                  <v-col cols="4">L/Diam</v-col>
                  <v-col cols="4">W</v-col>
                  <v-col cols="4">H</v-col>
                </v-row>
              </template>
              <template v-slot:header.actual="{ header }">
                <v-row>
                  <v-col cols="12">Actual</v-col>
                  <v-col cols="4">L/Diam</v-col>
                  <v-col cols="4">W</v-col>
                  <v-col cols="4">H</v-col>
                </v-row>
              </template>
              <template v-slot:header.sz="{ header }" v-if="isVaryingSeparated()">
                <v-row>
                  <v-col cols="12">SZ</v-col>
                  <v-col cols="4">Critical</v-col>
                  <v-col cols="4">Major</v-col>
                  <v-col cols="4">Minor</v-col>
                </v-row>
              </template>
              <template v-slot:header.critical="{ header }" v-if="isVaryingSeparated()">
                <v-row>
                  <v-col cols="12">Critical</v-col>
                  <v-col cols="4">F</v-col>
                  <v-col cols="4">A</v-col>
                  <v-col cols="4">R</v-col>
                </v-row>
              </template>
              <template v-slot:header.major="{ header }" v-if="isVaryingSeparated()">
                <v-row>
                  <v-col cols="12">Major</v-col>
                  <v-col cols="4">F</v-col>
                  <v-col cols="4">A</v-col>
                  <v-col cols="4">R</v-col>
                </v-row>
              </template>
              <template v-slot:header.minor="{ header }" v-if="isVaryingSeparated()">
                <v-row>
                  <v-col cols="12">Minor</v-col>
                  <v-col cols="4">F</v-col>
                  <v-col cols="4">A</v-col>
                  <v-col cols="4">R</v-col>
                </v-row>
              </template>

              <!-- isVaryingCombined -->
                 <template v-slot:header.found_defect="{ header }" v-if="isVaryingCombined()">
                <v-row>
                  <v-col cols="12">Found</v-col>
                  <v-col cols="4">Critical</v-col>
                  <v-col cols="4">Major</v-col>
                  <v-col cols="4">Minor</v-col>
                </v-row>
              </template>
           

              <template v-slot:body="{ items, pagination }">
                <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>
                      <span style="display: none;">{{ (item.indexMarkerProductSize = index + pagination.pageStart) }}</span>
                      <span :id="'itemProductSizeClientProductID_' + item.clientProductId"> {{ item.clientProductId }} </span>
                    </td>
                    <td>
                      <span :id="'itemProductSizeSKU_' + item.clientProductId"> {{ item.skuDisplay }} </span>
                    </td>
                    <td>
                      <span :id="'itemProductSizePo_' + item.clientProductId"> {{ item.po }} </span>
                    </td>
                    <td class="w-lg">
                      <v-row>
                        <v-col cols="4"
                          ><v-text-field
                            :id="'txtProductSizeSpecifiedLength_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.specifiedLength"
                            type="text"
                            outlined
                            dense
                            @keyup="returnNumber(item, 'actualLength')"
                            @change="ChangeProductSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                        <v-col cols="4"
                          ><v-text-field
                            :id="'txtProductSizeSpecifiedWidth_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.specifiedWidth"
                            type="text"
                            outlined
                            dense
                            @keyup="returnNumber(item, 'actualWidth')"
                            @change="ChangeProductSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                        <v-col cols="4"
                          ><v-text-field
                            :id="'txtProductSizeSpecifiedHeight_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.specifiedHeight"
                            type="text"
                            outlined
                            dense
                            @keyup="returnNumber(item, 'actualHeight')"
                            @change="ChangeProductSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                      </v-row>
                    </td>
                    
                    <td class="w-sm">
                      <v-text-field
                        :id="'txtProductWeightSpecifiedWeight_' + item.clientProductId"
                        class="number_text"
                        hide-details
                        v-model="item.specifiedWeight"
                        type="text"
                        outlined
                        dense
                        @change="ChangeProductSizeLengthWidthHeight(item)"
                        @keyup="returnNumber(item, 'specifiedWeight')"
                      ></v-text-field>
                    </td>

                    <td class="w-lg">
                      <v-row>
                        <v-col cols="4"
                          ><v-text-field
                            :id="'txtProductSizeActualLength_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.actualLength"
                            type="text"
                            :rules="[rules.required]"
                            outlined
                            dense
                            @keyup="returnNumber(item, 'actualLength')"
                            @change="ChangeProductSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                        <v-col cols="4"
                          ><v-text-field
                            :id="'txtProductSizeActualWidth_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.actualWidth"
                            type="text"
                            :rules="[rules.required]"
                            outlined
                            dense
                            @keyup="returnNumber(item, 'actualWidth')"
                            @change="ChangeProductSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                        <v-col cols="4"
                          ><v-text-field
                            :id="'txtProductSizeActualHeight_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.actualHeight"
                            type="text"
                            :rules="[rules.required]"
                            outlined
                            dense
                            @keyup="returnNumber(item, 'actualHeight')"
                            @change="ChangeProductSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                      </v-row>
                    </td>
                    <td>
                      <span>{{ getUnitOfMeasurementsType(item.dimensionalUnit) }} </span>
                    </td>

                    <td class="w-sm">
                      <v-text-field
                        :id="'txtProductWeightActualWeight_' + item.clientProductId"
                        class="number_text"
                        hide-details
                        v-model="item.actualWeight"
                        type="text"
                        :rules="[rules.required]"
                        outlined
                        dense
                       @change="ChangeProductSizeLengthWidthHeight(item)"
                        @keyup="returnNumber(item, 'actualWeight')"
                      ></v-text-field>
                    </td>
                    <td>
                      <span :id="'itemProductWeightWeightUnit_' + item.clientProductId"> {{ getUnitOfMeasurementsType(item.weightUnit) }} </span>
                    </td>
                    <td class="w-sm">
                      <v-text-field :id="'txtProductSizeSampleSize_' + item.clientProductId" v-show="item.orderNumber == 1" class="number_text" hide-details v-model="item.sampleSize" type="text" outlined dense></v-text-field>
                    </td>

                   
                    <td v-if="isVaryingSeparated()" class="w-lg">
                      <v-row>
                        <v-col cols="4"
                          ><v-text-field :id="'txtProductSizeSzCritical_' + item.clientProductId" class="number_text" hide-details v-model="item.szCritical" type="text" outlined dense @keyup="returnNumber(item, 'szCritical')"></v-text-field
                        ></v-col>
                        <v-col cols="4"
                          ><v-text-field :id="'txtProductSizeSzMajor_' + item.clientProductId" class="number_text" hide-details v-model="item.szMajor" type="text" outlined dense @keyup="returnNumber(item, 'szMajor')"></v-text-field
                        ></v-col>
                        <v-col cols="4"
                          ><v-text-field :id="'txtProductSizeSzMinor_' + item.clientProductId" class="number_text" hide-details v-model="item.szMinor" type="text" outlined dense @keyup="returnNumber(item, 'szMinor')"></v-text-field
                        ></v-col>
                      </v-row>
                    </td>
                    <td v-if="isVaryingSeparated()" class="w-lg">
                      <v-row>
                        <v-col cols="4" class="text-center">          
                          <!-- <span :id="'txtProductSizeFoundCritical_' + item.clientProductId">{{ item.foundCritical }}</span> -->
                          <v-text-field
                            :id="'txtProductSizeFoundCritical_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.foundCritical"
                            type="text"
                            outlined
                            dense
                            disabled>
                          </v-text-field> 
                        </v-col>
                        <v-col cols="4" 
                          ><v-text-field
                            :id="'txtProductSizeAcceptCritical_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.acceptCritical"
                            type="text"
                            outlined
                            dense
                            @keyup="returnNumber(item, 'acceptCritical')"
                            @change="ChangeProductSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                        <v-col cols="4" 
                          ><v-text-field
                            :id="'txtProductSizeRejectCritical_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.rejectCritical"
                            type="text"
                            outlined
                            dense
                            @keyup="returnNumber(item, 'rejectCritical')"
                            @change="ChangeProductSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                      </v-row>
                    </td>
                    <td  v-if="isVaryingSeparated()" class="w-lg">
                      <v-row>
                        <v-col cols="4" class="text-center">
                          <!-- <span :id="'txtProductSizeFoundMajor_' + item.clientProductId">{{ item.foundMajor }}</span> -->
                          <v-text-field
                            :id="'txtProductSizeFoundMajor_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.foundMajor"
                            type="text"
                            outlined
                            dense
                            disabled>
                          </v-text-field> 
                        </v-col>
                        <v-col cols="4"   
                          ><v-text-field
                            :id="'txtProductSizeAcceptMajor_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.acceptMajor"
                            type="text"
                            outlined
                            dense
                            @keyup="returnNumber(item, 'acceptMajor')"
                            @change="ChangeProductSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                        <v-col cols="4"   
                          ><v-text-field
                            :id="'txtProductSizeRejectMajor_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.rejectMajor"
                            type="text"
                            outlined
                            dense
                            @keyup="returnNumber(item, 'rejectMajor')"
                            @change="ChangeProductSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                      </v-row>
                    </td>
                    <td  v-if="isVaryingSeparated()" class="w-lg">
                      <v-row>
                        <v-col cols="4" class="text-center">
                          <!-- <span :id="'txtProductSizeFoundMinor_' + item.clientProductId">{{ item.foundMinor }}</span> -->
                          <v-text-field
                            :id="'txtProductSizeFoundMinor_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.foundMinor"
                            type="text"
                            outlined
                            dense
                            disabled>
                          </v-text-field> 
                        </v-col>
                        <v-col cols="4"
                          ><v-text-field 
                            :id="'txtProductSizeAcceptMinor_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.acceptMinor"
                            type="text"
                            outlined
                            dense
                            @keyup="returnNumber(item, 'acceptMinor')"
                            @change="ChangeProductSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                        <v-col cols="4" 
                          ><v-text-field
                            :id="'txtProductSizeRejectMinor_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.rejectMinor"
                            type="text"
                            outlined
                            dense
                            @keyup="returnNumber(item, 'rejectMinor')"
                            @change="ChangeProductSizeLengthWidthHeight(item)"
                          ></v-text-field
                        ></v-col>
                      </v-row>
                    </td>
                    <td v-if="isVaryingCombined()" class="w-lg">
                      <v-row>
                        <v-col cols="4">
                         <v-text-field
                            :id="'txtProductSizeFoundCritical_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.foundCritical"
                            type="text"
                            outlined
                            dense
                            disabled>
                          </v-text-field> 
                        </v-col>
                        <v-col cols="4" >
                         <v-text-field
                            :id="'txtProductSizeFoundMajor_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.foundMajor"
                            type="text"
                            outlined
                            dense
                            disabled>
                          </v-text-field> 
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            :id="'txtProductSizeFoundMinor_' + item.clientProductId"
                            class="number_text"
                            hide-details
                            v-model="item.foundMinor"
                            type="text"
                            outlined
                            dense
                            disabled>
                          </v-text-field> 
                        </v-col>
                      </v-row>
                    </td>
                    <td  v-if="!(workOrderSizeMeasurementObj.isVaryingAQL && workOrderSizeMeasurementObj.productSizeSamplingMethodology == 'Combined Sample Size')">
                      <v-select v-bind:items="resultList" item-text="description" item-value="id" v-model="item.dimensionalResult" single-line bottom hide-details @change="calculateFCMResult('tab-3')"></v-select>
                    </td>
                    <td>
                      <template v-if="item.orderNumber == 1 && item.sampleSize > getWorkorderProductIdTotalCount(item.workOrderProductId, item.isCarton)">
                        <v-btn icon dark>
                          <v-icon color="black" @click.stop="addChild(item, 'productSize')">add</v-icon>
                        </v-btn>
                      </template>
                      <template v-else-if="item.orderNumber > 1">
                        <v-btn icon dark>
                          <v-icon color="black" @click.stop="deleteChild(item, 'productSize')">delete</v-icon>
                        </v-btn>
                      </template>
                      <template v-if="item.indexMarkerProductSize >= 1">
                        <v-btn icon title="Copy From Above" @click="copyAboveRowProductSize(item.indexMarkerProductSize)">
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>

            <v-dialog v-model="showToleranceDialogProductSize" width="450">
              <v-card>
                <v-card-title class="headline">Tolerance Confirmation Window</v-card-title>
                <v-card-text>Is it not applicable due to non-availablilty of tolerance from client?</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="UpdateTolerance('ProductSize', 'Yes')">Yes</v-btn>
                  <v-btn color="blue darken-1" text @click="UpdateTolerance('ProductSize', 'No')">No</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="productSizeDialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field id="txtProductSizeDialogClientProductID" hide-details v-model="productSizeEditedItem.clientProductId" label="Client Product ID" type="text" disabled></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field id="txtProductSizeDialogSKU" hide-details v-model="productSizeEditedItem.skuDisplay" label="SKU" type="text" disabled></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="productSizeEditedItem.specifiedLength"
                          type="text"
                          @keyup="returnNumber(productSizeEditedItem, 'specifiedLength')"
                          @change="ChangeProductSizeLengthWidthHeight(productSizeEditedItem)"
                          label="Specified L/Diam"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="productSizeEditedItem.specifiedWidth"
                          type="text"
                          @keyup="returnNumber(productSizeEditedItem, 'specifiedWidth')"
                          @change="ChangeProductSizeLengthWidthHeight(productSizeEditedItem)"
                          label="Specified W"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="productSizeEditedItem.specifiedHeight"
                          type="text"
                          @keyup="returnNumber(productSizeEditedItem, 'specifiedHeight')"
                          @change="ChangeProductSizeLengthWidthHeight(productSizeEditedItem)"
                          label="Specified H"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="productSizeEditedItem.specifiedWeight"
                          type="text"
                          @keyup="returnNumber(productSizeEditedItem, 'specifiedWeight')"
                          @change="ChangeProductSizeLengthWidthHeight(productSizeEditedItem)"
                          label="Weight Spec"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <span :style="{ color: '#ff0000' }" class="font-italic">Note: If size is not applicable, enter "-1".</span>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="productSizeEditedItem.actualLength"
                          type="text"
                          @keyup="returnNumber(productSizeEditedItem, 'actualLength')"
                          label="L/Diam"
                          @change="ChangeProductSizeLengthWidthHeight(productSizeEditedItem)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="productSizeEditedItem.actualWidth" type="text" @keyup="returnNumber(productSizeEditedItem, 'actualWidth')" @change="ChangeProductSizeLengthWidthHeight(productSizeEditedItem)" label="W"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="productSizeEditedItem.actualHeight" type="text" @keyup="returnNumber(productSizeEditedItem, 'actualHeight')" @change="ChangeProductSizeLengthWidthHeight(productSizeEditedItem)" label="H"></v-text-field>
                      </v-col>
                    </v-row>

                    <!-- Varying AQL -->
                    <v-row v-show="workOrderSizeMeasurementObj.isVaryingAQL">
                      <v-col cols="12" sm="12" md="12">
                        <span>Critical</span>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="productSizeEditedItem.foundCritical"
                          type="text"
                          @keyup="returnNumber(productSizeEditedItem, 'foundCritical')"
                          label="Found"
                          readonly
                          @change="ChangeProductSizeLengthWidthHeight(productSizeEditedItem)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="productSizeEditedItem.acceptCritical"
                          type="text"
                          @keyup="returnNumber(productSizeEditedItem, 'acceptCritical')"
                          @change="ChangeProductSizeLengthWidthHeight(productSizeEditedItem)"
                          label="Acceptable"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="productSizeEditedItem.rejectCritical"
                          type="text"
                          @keyup="returnNumber(productSizeEditedItem, 'rejectCritical')"
                          @change="ChangeProductSizeLengthWidthHeight(productSizeEditedItem)"
                          label="Reject"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <span>Major</span>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="productSizeEditedItem.foundMajor"
                          type="text"
                          @keyup="returnNumber(productSizeEditedItem, 'foundMajor')"
                          label="Found"
                          readonly
                          @change="ChangeProductSizeLengthWidthHeight(productSizeEditedItem)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="productSizeEditedItem.acceptMajor"
                          type="text"
                          @keyup="returnNumber(productSizeEditedItem, 'acceptMajor')"
                          @change="ChangeProductSizeLengthWidthHeight(productSizeEditedItem)"
                          label="Acceptable"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="productSizeEditedItem.rejectMajor"
                          type="text"
                          @keyup="returnNumber(productSizeEditedItem, 'rejectMajor')"
                          @change="ChangeProductSizeLengthWidthHeight(productSizeEditedItem)"
                          label="Reject"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <span>Minor</span>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="productSizeEditedItem.foundMinor"
                          type="text"
                          @keyup="returnNumber(productSizeEditedItem, 'foundMinor')"
                          label="Found"
                          readonly
                          @change="ChangeProductSizeLengthWidthHeight(productSizeEditedItem)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="productSizeEditedItem.acceptMinor"
                          type="text"
                          @keyup="returnNumber(productSizeEditedItem, 'acceptMinor')"
                          @change="ChangeProductSizeLengthWidthHeight(productSizeEditedItem)"
                          label="Acceptable"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="productSizeEditedItem.rejectMinor"
                          type="text"
                          @keyup="returnNumber(productSizeEditedItem, 'rejectMinor')"
                          @change="ChangeProductSizeLengthWidthHeight(productSizeEditedItem)"
                          label="Reject"
                        ></v-text-field>
                      </v-col>

                      <!-- SZ Critical & Major & Minor -->
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="productSizeEditedItem.szCritical" type="text" @keyup="returnNumber(productSizeEditedItem, 'szCritical')" label="SZ Critical"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="productSizeEditedItem.szMajor" type="text" @keyup="returnNumber(productSizeEditedItem, 'szMajor')" label="SZ Major"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="productSizeEditedItem.szMinor" type="text" @keyup="returnNumber(productSizeEditedItem, 'szMinor')" label="SZ Minor"></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-if="productSizeEditedItem.orderNumber == 1" v-model="productSizeEditedItem.sampleSize" @keyup="returnNumberSampleSize(productSizeEditedItem, 'sampleSize')" label="Sample Size"></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-radio-group v-model="productSizeEditedItem.dimensionalResult" label="Result" row>
                        <v-radio label="Pass" value="1"></v-radio>
                        <v-radio label="Fail" value="0"></v-radio>
                        <v-radio label="Pending" value="2"></v-radio>
                        <v-radio label="N/A" value="3"></v-radio>
                      </v-radio-group>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="saveProductSize('productSize')" :disabled="buttonDisable">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- </div>  -->
          </v-tab-item>

          <v-tab-item class="tab_item" :value="'tab-4'"  v-if="isPomOml" eager>
            <!-- <pulse-validator v-if="errorsProductTab.length > 0" :errors="errorsProductTab"></pulse-validator> -->
            <div :style="isSectionDisabled ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop' : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'"></div>

              <v-col cols="12" sm="12" md="12">
                <sizemeasurement-pom :workOrderId="workOrderId" :productSizeMeasurementList="productSizeMeasurementList" :workOrder="workOrderDetail"></sizemeasurement-pom>
              </v-col>


          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="color">
      {{ message }}
      <v-btn text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
    <v-dialog v-model="showProgressApplyDialog" :persistent="true">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <div class="text-center">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showProgressDialog" :persistent="true">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-container v-for="(image, index) in addedImages" :key="index">
            <v-progress-linear :value="image.uploadProgress" color="light-blue" height="25" reactive>
              <template v-slot="{ value }">
                <strong>{{ image.fileName }} {{ Math.ceil(image.uploadProgress) }}%</strong>
              </template>
            </v-progress-linear>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-toolbar flat color="pink" dark>
          <v-card-title>
            <span class="headline">Delete Confirmation</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="3" md="6">
                <v-text-field readonly v-model="deletedItem.specifiedLength" label="Specified L"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="6">
                <v-text-field readonly v-model="deletedItem.specifiedWidth" label="Specified W"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="6">
                <v-text-field readonly v-model="deletedItem.specifiedHeight" label="Specified H"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" md="6">
                <v-text-field readonly v-model="deletedItem.actualLength" label="L"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="6">
                <v-text-field readonly v-model="deletedItem.actualWidth" label="W"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="6">
                <v-text-field readonly v-model="deletedItem.actualHeight" label="H"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-radio-group readonly v-model="deletedItem.dimensionalResult" label="Result" row>
                <v-radio label="Pass" value="1"></v-radio>
                <v-radio label="Fail" value="0"></v-radio>
                <v-radio label="Pending" value="2"></v-radio>
                <v-radio label="N/A" value="3"></v-radio>
              </v-radio-group>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field readonly v-model="deletedItem.specifiedWeight" label="Weight Spec"></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field readonly v-model="deletedItem.actualWeight" label="Weight Measure"></v-text-field>
              </v-col>
            </v-row>

            <v-radio-group readonly v-model="deletedItem.weightResult" label="Result" row>
              <v-radio label="Pass" value="1"></v-radio>
              <v-radio label="Fail" value="0"></v-radio>
              <v-radio label="Pending" value="2"></v-radio>
              <v-radio label="N/A" value="3"></v-radio>
            </v-radio-group>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">Cancel</v-btn>
          <v-btn color="red" text @click="deleteRow()" :disabled="buttonDisable">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDefectFormDialog" width="100%">
      <v-card>
        <v-card-title>Add Defect</v-card-title>
        <section-defect-form-dialog v-if="showDefectFormDialog" :sectionData="dclSectionData" @changeValue="workorderProductDefectSectionValue($event)"></section-defect-form-dialog>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeSave">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="showFloatingButton">
      <FloatingButton></FloatingButton>
    </div>

    <v-snackbar v-model="delImgSnackbar.isShow">
      {{ delImgSnackbar.text }}
      <v-progress-circular indeterminate color="#ccc"></v-progress-circular>
    </v-snackbar>

    <work-order-image-form-dialog :showDialog.sync="showImageDialog" :workOrderId="_workOrderDetails?.workOrderId" :sectionId="sectionId" :descriptionFilter="productTabMeasurementFindingsImageDescription">
    </work-order-image-form-dialog>
  </div>
</template>

<script>
let state = {
  appHeaderState: {
    show: true,
    title: "Functional Check Measurement",
    showMenu: true,
    showBack: true,
    showLogo: false,
    showTitle: true,
    showSearch: false,
    showCalendar: true,
  },
};

function setState(store) {
  store.dispatch("navigation/setAppHeader", state.appHeaderState);
}

import { mapActions, mapState } from "vuex";
import apiDetails from "@/shared/request/apiDetails";
import FloatingButton from "@/components/common/FloatingButton";
import auditLog from "@/shared/request/auditLogs";
import * as _ from "lodash";
import SizeMeasurementPOM from "@/components/workorder/SizeMeasurementPOM";
import SectionDefectFormDialog from "@/components/workorder/SectionDefectFormDialog";
import PulseValidator from "@/components/common/PulseValidator";
import WorkOrderImageFormDialog from "@/components/workorder/WorkOrderImageFormDialog";
import GenericCodeHelper from "../../../views/common/GenericCodeHelper.vue"
import { defineAsyncComponent } from 'vue'

export default {
  name: "workOrderInspectionSizeId",
  components: {
    "sizemeasurement-pom": SizeMeasurementPOM,
    FloatingButton,
    "section-defect-form-dialog": SectionDefectFormDialog,
    PulseValidator,
    WorkOrderImageFormDialog,
    GenericCodeHelper,
    "SpecialInstructions": defineAsyncComponent(() => import('../../../components/forms/SpecialInstructions')) 
  },
  data() {
    return {
      datatableRowsPerPage: {
        "footer-props": {
          "items-per-page-options": [10, 25, 50, 100, -1],
        },
      },
      loadingDf: false,
      selectedPO: [],
      isPomOml: null,
      showProgressApplyDialog: false,
      showConfirmApplyDialog: false,
      showConfirmTabName: "",
      cartonSizeSearchSKUPO: null,
      cartonProductMassUpdate: {
        actualLength: null,
        actualWidth: null,
        actualHeight: null,
        actualWeight: null,
        sampleSize: null,
      },
      cartonSizeResult: "",
      cartonWeightResult: "",
      productSizeResult: "",
      productWeighResult: "",
      showToleranceDialogProductWeight: false,
      showToleranceDialogProductSize: false,
      showToleranceDialogCartonWeight: false,
      showToleranceDialogCartonSize: false,

      // 提示框
      delImgSnackbar: {
        isShow: false,
        text: "",
      },
      inspectionLevel1Options: [],
      inspectionLevelOptions: [],
      showFloatingButton: false,
      samplingPlan: [],
      snackbar: false,
      color: "red darken-2",
      message: "",
      timeout: 3000,
      showProgressDialog: false,
      addedImages: [],
      tab: null,
      items: ["Carton", "Product"],
      resultOptions: [
        {
          id: 1,
          text: "Pass",
        },
        {
          id: 0,
          text: "Fail",
        },
        {
          id: 2,
          text: "Pending",
        },
        {
          id: 3,
          text: "N/A",
        },
      ],
      cartonEditedIndex: -1,
      productEditedIndex: -1,
      // carton size table data
      showDefectFormDialog: false,
      cartonSizeDialog: false,
      skuHeader: [{ text: "ALL", align: "left", value: "sku_po" }],
      cartonSizeHeader: [
        { text: "Client Product ID", align: "left", sortable: true, value: "clientProductId" },
        { text: "SKU#", align: "left", sortable: true, value: "skuDisplay" },
        { text: "PO No.", align: "center", sortable: true, value: "po" },
        { text: "Specified", align: "center", sortable: false, width: 220, value: "specified" },
        { text: "Weight Spec", align: "center", sortable: false, width: 75, value: "specifiedWeight" },
        { text: "Actual", align: "center", sortable: false, width: 220, value: "actual" },
        { text: "Measured Carton Weight", align: "center", sortable: false, value: "actualWeight" },
        { text: "Unit", align: "center", sortable: false, value: "dimensionalUnit" },
        { text: "Sample Size", align: "center", sortable: false, width: 75, value: "sampleSize" },
        { text: "Result", align: "center", sortable: false, width: 150, value: "dimensionalResult" },
        { text: "", align: "center", sortable: false, width: 110, value: "menu" },
      ],
      cartonSizeLoading: false,
      cartonSizeHeaderAQL: [
        { text: "Client Product ID", align: "left", sortable: true, value: "clientProductId" },
        { text: "SKU#", align: "left", sortable: true, value: "skuDisplay" },
        { text: "PO No.", align: "center", sortable: true, value: "po" },
        { text: "Specified", align: "center", sortable: false, width: 220, value: "specified" },
        { text: "Weight Spec", align: "center", sortable: false, width: 75, value: "specifiedWeight" },
        { text: "Actual", align: "center", sortable: false, width: 220, value: "actual" },
        { text: "Measured Carton Weight", align: "center", sortable: false, value: "actualWeight" },
        { text: "Unit", align: "center", sortable: false, value: "dimensionalUnit" },
        { text: "Sample Size", align: "center", sortable: false, width: 75, value: "sampleSize" },
        { text: "Critical", align: "center", sortable: false, width: 220, value: "critical" },
        { text: "Major", align: "center", sortable: false, width: 220, value: "major" },
        { text: "Minor", align: "center", sortable: false, width: 220, value: "minor" },
        { text: "Result", align: "center", sortable: false, width: 150, value: "dimensionalResult" },
        { text: "", align: "center", sortable: false, width: 110, value: "menu" },
      ],
      // carton weight table data
      cartonWeightDialog: false,
      cartonWeightHeader: [
        { text: "Client Product ID", align: "left", sortable: true, value: "clientProductId" },
        { text: "SKU#", align: "left", sortable: true, value: "skuDisplay" },
        { text: "PO No.", align: "center", sortable: true, value: "po" },
        { text: "Weight Spec", align: "center", sortable: false, value: "specifiedWeight" },
        { text: "Weight Measure", align: "center", sortable: false, value: "actualWeight" },
        { text: "Unit", align: "center", sortable: false, value: "weightUnit" },
        { text: "Sample Size", align: "center", sortable: false, value: "sampleSize" },
        { text: "Result", align: "center", sortable: false, value: "weightResult" },
        { text: "", align: "center", sortable: false, width: 110, value: "menu" },
      ],
      cartonWeightHeaderAQL: [
        { text: "Client Product ID", align: "left", sortable: true, value: "clientProductId" },
        { text: "SKU#", align: "left", sortable: true, value: "skuDisplay" },
        { text: "PO No.", align: "center", sortable: true, value: "po" },
        { text: "Weight Spec", align: "center", sortable: false, value: "specifiedWeight" },
        { text: "Weight Measure", align: "center", sortable: false, value: "actualWeight" },
        { text: "Unit", align: "center", sortable: false, value: "weightUnit" },
        { text: "Sample Size", align: "center", sortable: false, value: "sampleSize" },
        { text: "Critical", align: "center", sortable: false, value: "critical" },
        { text: "Major", align: "center", sortable: false, value: "major" },
        { text: "Minor", align: "center", sortable: false, value: "minor" },
        { text: "Result", align: "center", sortable: false, value: "weightResult" },
        { text: "", align: "center", sortable: false, width: 110, value: "menu" },
      ],
      // product size table data
      productSizeDialog: false,
      productSizeHeader: [
        { text: "Client Product ID", align: "left", sortable: true, value: "clientProductId" },
        { text: "SKU#", align: "left", sortable: true, value: "skuDisplay" },
        { text: "PO No.", align: "center", sortable: true, value: "po" },
        { text: "Specified", align: "center", sortable: false, width: 220, value: "specified" },
        { text: "Weight Spec", align: "center", sortable: false, width: 75, value: "specifiedWeight" },
        { text: "Actual", align: "center", sortable: false, width: 220, value: "actual" },
        { text: "Unit", align: "center", sortable: false, value: "dimensionalUnit" },
        { text: "Weight Measure", align: "center", sortable: false, width: 75, value: "actualWeight" },
        { text: "Weight Unit", align: "center", sortable: false, value: "weightUnit" },
        { text: "Sample Size", align: "center", sortable: false, width: 75, value: "sampleSize" },
        { text: "Result", align: "center", sortable: false, width: 150, value: "dimensionalResult" },
        { text: "", align: "center", sortable: false, width: 110, value: "menu" },
      ],
      productSizeHeaderAQL: [
        {text: "Client Product ID", align: "left", sortable: true, value: "clientProductId"},
        {text: "SKU#", align: "left", sortable: true, value: "skuDisplay"},
        {text: "PO No.", align: "center", sortable: true, value: "po"},
        { text: "Specified", align: "center", sortable: false, width: 220, value: "specified" },
        { text: "Weight Spec", align: "center", sortable: false, width: 75, value: "specifiedWeight" },
        { text: "Actual", align: "center", sortable: false, width: 220, value: "actual" },
        { text: "Unit", align: "center", sortable: false, value: "dimensionalUnit" },
        { text: "Weight Measure", align: "center", sortable: false, width: 75, value: "actualWeight"},
        { text: "Weight Unit", align: "center", sortable: false, value: "weightUnit"},
        { text: "Sample Size", lign: "center", sortable: false, width: 75, value: "sampleSize"},
        { text: "SZ", align: "center", sortable: false, width: 220, value: "sz"},
        { text: "Critical", align: "center", sortable: false, width: 220, value: "critical"},
        { text: "Major", align: "center", sortable: false, width: 220, value: "major" },
        { text: "Minor", align: "center", sortable: false, width: 220, value: "minor" },
        { text: "Result",align: "center", sortable: false, width: 150, value: "dimensionalResult" },
        { text: "",  align: "center",  sortable: false, width: 110,  value: "menu"},
      ],
      productSizeHeaderAQLCombined: [
        { text: "Client Product ID", align: "left", sortable: true, value: "clientProductId"},
        { text: "SKU#", align: "left", sortable: true, value: "skuDisplay"},
        { text: "PO No.", align: "center", sortable: true, value: "po"},
        { text: "Specified", align: "center", sortable: false, width: 220, value: "specified" },
        { text: "Weight Spec", align: "center", sortable: false, width: 75, value: "specifiedWeight" },
        { text: "Actual", align: "center", sortable: false, width: 220, value: "actual" },
        { text: "Unit", align: "center", sortable: false, value: "dimensionalUnit" },
        { text: "Weight Measure", align: "center", sortable: false, width: 75, value: "actualWeight"},
        { text: "Weight Unit", align: "center", sortable: false, value: "weightUnit"},
        { text: "Sample Size", lign: "center", sortable: false, width: 75, value: "sampleSize"},
        { text: "Found", align: "center", sortable: false, width: 220, value: "found_defect"},
        // { text: "Result",  align: "center", sortable: false, width: 150, value: "dimensionalResult" },
        { text: "",  align: "center",  sortable: false, width: 110,  value: "menu"},
      ],
      // product weight table data
      productWeightDialog: false,
      productWeightHeader: [
        {
          text: "Client Product ID",
          align: "left",
          sortable: true,
          value: "clientProductId",
        },
        {
          text: "SKU#",
          align: "left",
          sortable: true,
          value: "skuDisplay",
        },
        {
          text: "PO No.",
          align: "center",
          sortable: true,
          value: "po",
        },
        {
          text: "Weight Spec",
          align: "center",
          sortable: false,
          width: 75,
          value: "specifiedWeight",
        },
        {
          text: "Weight Measure",
          align: "center",
          sortable: false,
          width: 75,
          value: "actualWeight",
        },
        {
          text: "Unit",
          align: "center",
          sortable: false,
          value: "weightUnit",
        },
        {
          text: "Sample Size",
          align: "center",
          sortable: false,
          width: 75,
          value: "sampleSize",
        },
        {
          text: "Result",
          align: "center",
          sortable: false,
          width: 150,
          value: "weightResult",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          width: 110,
          value: "menu",
        },
      ],
      productWeightHeaderAQL: [
        {
          text: "Client Product ID",
          align: "left",
          sortable: true,
          value: "clientProductId",
        },
        {
          text: "SKU#",
          align: "left",
          sortable: true,
          value: "skuDisplay",
        },
        {
          text: "PO No.",
          align: "center",
          sortable: true,
          value: "po",
        },
        {
          text: "Weight Spec",
          align: "center",
          sortable: false,
          width: 75,
          value: "specifiedWeight",
        },
        {
          text: "Weight Measure",
          align: "center",
          sortable: false,
          width: 75,
          value: "actualWeight",
        },
        {
          text: "Unit",
          align: "center",
          sortable: false,
          value: "weightUnit",
        },
        {
          text: "Sample Size",
          align: "center",
          sortable: false,
          width: 75,
          value: "sampleSize",
        },
        { text: "Critical", align: "center", sortable: false, width: 220, value: "critical" },
        { text: "Major", align: "center", sortable: false, width: 220, value: "major" },
        { text: "Minor", align: "center", sortable: false, width: 220, value: "minor" },
        {
          text: "Result",
          align: "center",
          sortable: false,
          width: 150,
          value: "weightResult",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          width: 110,
          value: "menu",
        },
      ],
      formTitle: "",
      workOrderId: null,
      workOrder: null,
      workOrderDetail: {
        resources: [],
        products: [],
        workOrderInspectionSections: [],
      },
      workOrderProductResultDetails: null,
      workOrderProductID: null,
      workOrderProducts: [],
      unitOfMeasurementLengthOptions: [],
      unitOfMeasurementWeightOptions: [],
      unitOfMeasurementOptions: [],
      productID: null,
      productIDList: [],
      productType: null,

      //Workorder Objects

      workOrderSizeMeasurementObj: {
        workOrderId: null,
        overallSizeMeasurementResult: null,

        cartonSizeToleranceUpper: 5,
        cartonSizeToleranceLower: -5,
        cartonSizeUnitOfMeasureId: null,

        cartonWeightToleranceUpper: 5,
        cartonWeightToleranceLower: -5,
        cartonWeightUnitOfMeasureId: null,

        productSizeToleranceUpper: 5,
        productSizeToleranceLower: -5,
        productSizeUnitOfMeasureId: null,

        productWeightToleranceUpper: 5,
        productWeightToleranceLower: -5,
        productWeightUnitOfMeasureId: null,
        cartonSizeInspectionLevel: null,
        cartonWeightInspectionLevel: null,
        productSizeInspectionLevel: null,
        productWeightInspectionLevel: null,

        cartonSizeSamplingMethodology: null,
        cartonWeightSamplingMethodology: null,
        productSizeSamplingMethodology: null,
        productWeightSamplingMethodology: null,

        cartonAqlCriticalID: null,
        cartonAqlMajorID: null,
        cartonAqlMinorID: null,
        cartonAqlOtherID: null,
        productAqlCriticalID: null,
        productAqlMajorID: null,
        productAqlMinorID: null,
        productAqlOtherID: null,
        productSZCritical: null,
        productSZMajor: null,
        productSZMinor: null,

        isVaryingAQL: null,

        cartonSizeResultId: null,
        cartonWeightResultId: null,
        productWeightResultId: null,
        productSizeResultId: null,

        cartonSizePendingCodeId: null,
        cartonWeightPendingCodeId: null,
        productSizePendingCodeId: null,
        productWeightPendingCodeId: null,

        cartonSizeRemarks: null,
        cartonWeightRemarks: null,
        productSizeRemarks: null,
        productWeightRemarks: null,

        totalOfCritical: null,
        totalNoOfSamplesMeasured: null,
        totalFoundPomPlus: null,
        totalFoundPomMinus: null,
        totalNoOfSampleOutOfPom: null,
        percentageSampleOutOfPom: null,
        percentageFoundPomPlus: null,
        percentageFoundPomMinus: null,
        sectionId: 2,
        allowedCritical: null,
        allowedMajor: null,
        allowedMinor: null,
        rejectedCritical: null,
        rejectedMajor: null,
        rejectedMinor: null,
      },
      //Edited Item Object
      cartonSizeEditedItem: {
        workOrderProductId: null,
        id: null,
        isCarton: true,
        specifiedLength: null,
        specifiedWidth: null,
        specifiedHeight: null,
        actualLength: null,
        actualWidth: null,
        actualHeight: null,
        dimensionalUnit: null,
        dimensionalResult: null,
        specifiedWeight: null,
        actualWeight: null,
        weightUnit: null,
        weightResult: null,
        sampleSize: null,
        orderNumber: null,
        uploadPhoto: [],
        serverPhoto: [],
        uploadLoading: false,
        fileInput: [],
        uploadPhoto: {},
        acceptCritical: null,
        acceptMajor: null,
        acceptMinor: null,
        foundCritical: null,
        foundMajor: null,
        foundMinor: null,
        rejectCritical: null,
        rejectMajor: null,
        rejectMinor: null,
        szCritical: null,
        szMajor: null,
        szMinor: null,
      },
      productSizeEditedItem: {
        workOrderProductId: null,
        id: null,
        isCarton: false,
        specifiedLength: null,
        specifiedWidth: null,
        specifiedHeight: null,
        actualLength: null,
        actualWidth: null,
        actualHeight: null,
        dimensionalUnit: null,
        dimensionalResult: null,
        sampleSize: null,
        orderNumber: null,
        uploadPhoto: [],
        serverPhoto: [],
        uploadLoading: false,
        fileInput: [],
        uploadPhoto: {},
        acceptCritical: null,
        acceptMajor: null,
        acceptMinor: null,
        foundCritical: null,
        foundMajor: null,
        foundMinor: null,
        rejectCritical: null,
        rejectMajor: null,
        rejectMinor: null,
        szCritical: null,
        szMajor: null,
        szMinor: null,
      },
      cartonWeightEditedItem: {
        workOrderProductId: null,
        id: null,
        isCarton: true,
        specifiedLength: null,
        specifiedWidth: null,
        specifiedHeight: null,
        actualLength: null,
        actualWidth: null,
        actualHeight: null,
        dimensionalUnit: null,
        dimensionalResult: null,
        specifiedWeight: null,
        actualWeight: null,
        weightUnit: null,
        weightResult: null,
        sampleSize: null,
        orderNumber: null,
        uploadPhoto: [],
        serverPhoto: [],
        uploadLoading: false,
        fileInput: [],
        uploadPhoto: {},
        acceptCritical: null,
        acceptMajor: null,
        acceptMinor: null,
        foundCritical: null,
        foundMajor: null,
        foundMinor: null,
        rejectCritical: null,
        rejectMajor: null,
        rejectMinor: null,
        szCritical: null,
        szMajor: null,
        szMinor: null,
      },
      productWeightEditedItem: {
        workOrderProductId: null,
        id: null,
        isCarton: false,
        specifiedWeight: null,
        actualWeight: null,
        weightUnit: null,
        weightResult: null,
        sampleSize: null,
        orderNumber: null,
        uploadPhoto: [],
        serverPhoto: [],
        uploadLoading: false,
        fileInput: [],
        uploadPhoto: {},
        acceptCritical: null,
        acceptMajor: null,
        acceptMinor: null,
        foundCritical: null,
        foundMajor: null,
        foundMinor: null,
        rejectCritical: null,
        rejectMajor: null,
        rejectMinor: null,
        szCritical: null,
        szMajor: null,
        szMinor: null,
      },
      buttonDisable: false,
      deleteDialog: false,
      deletedItem: {
        workOrderProductId: null,
        id: null,
        isCarton: true,
        specifiedLength: null,
        specifiedWidth: null,
        specifiedHeight: null,
        actualLength: null,
        actualWidth: null,
        actualHeight: null,
        dimensionalUnit: null,
        dimensionalResult: null,
        specifiedWeight: null,
        actualWeight: null,
        weightUnit: null,
        weightResult: null,
        sampleSize: null,
        orderNumber: null,
        acceptCritical: null,
        acceptMajor: null,
        acceptMinor: null,
        foundCritical: null,
        foundMajor: null,
        foundMinor: null,
        rejectCritical: null,
        rejectMajor: null,
        rejectMinor: null,
        szCritical: null,
        szMajor: null,
        szMinor: null,
      },
      sampleSizeTypeOptions: [],
      sectionId: 2,
      // showButton: false,
      sectionData: {
        SizeMeasurement: {
          id: 2,
          name: "Functional Check Measurement",
          isAuthorized: false,
          isSectionSubmitted: false,
        },
      },

      initialLogging: true,
      logTransactionCode: null,

      cartonSizeMeasurementList: [],
      productSizeMeasurementList: [],
      cloneCartonSizeMeasurementList: [],
      cloneProductSizeMeasurementList: [],
      productQuantityVerificationList: [],
      aqlOptions: [],
      workOrderPendingCode: [],
      workOrderFailCode: [],
      getWorkOrderPendingCode: [],
      getWorkOrderFailCode: [],
      workOrderResultList: [],
      dclSectionData: [],

      // true if submit button is clicked
      sectionSubmitted: false,
      resultList: [
        { id: "1", description: "Pass" },
        { id: "0", description: "Fail" },
        { id: "2", description: "Pending" },
        { id: "3", description: "N/A" },
      ],
      rules: {
        required: (value) => !!value || "Required.",
      },

      workOrderRatingOptions: [],
      defaultFailCodeOptions: ['UL0', 'UL1', 'UL2', 'UL3', 'UL4', 'UL5', 'UL6', 'UL7', 'UL8', 'UL9', 'UL10'],
      isFormDirty: false,
      isFormReadyCheck: false,

      showImageDialog: false,
      productTabMeasurementFindingsImageDescription: 'Product Tab - Measurement Findings',
      isGenericCodes: false,
      isCartonResultValid: true,
      isProductResultValid: true,
      savedProductWeightSamplingMethodology: null
    };
  },
  async activated() {
    if (window.navigator.onLine !== true) {
      let response = await caches.match(this.apiList.workOrderDetailsSize + "/" + this.$route.params.id);
      if (!response) {
        this.$router.replace({
          path: "/error",
          name: "error",
        });
      }
    }
  },
  async mounted() {
    if (window.navigator.onLine !== true) {
      let response = await caches.match(this.apiList.workOrderDetailsSize + "/" + this.$route.params.id);
      if (!response) {
        this.$router.replace({
          path: "/error",
          name: "error",
        });
      }
    }
    setState(this.$store);

    //start loading
    this.loadingDf = true;
    this.$store.dispatch("navigation/setLoadingPage",{show: this.loadingDf  });

    // Get Header for Token
    let header = {
      headers: {
        wresult: this.store_token,
      },
    };

    // this.showButton = this.$route.params.showButton
    this.workOrderId = window.workOrderId;

    this.utilityFn.getServerData("get", this.apiList.getUnitsOfMeasurement, {}, (res) => {
      this.unitOfMeasurementLengthOptions = res.data.data.filter((x) => x.measureClasses == "Length");
      this.unitOfMeasurementWeightOptions = res.data.data.filter((x) => x.measureClasses == "Weight");
      this.unitOfMeasurementOptions = res.data.data;
    });
    this.utilityFn.getServerData("get", this.apiList.getAllWorkOrderPendingFailCode, {}, (res) => {
      this.workOrderPendingCode = res.data.data.filter((x) => x.type == "Pending");
      this.workOrderFailCode = res.data.data.filter((x) => x.type == "Fail");
    });
    this.utilityFn.getServerData("get", this.apiList.getAllProductSizeResultList, {}, (result) => {
      this.workOrderResultList = result.data.data;
    });
    var aqlOptions = await this.utilityFn.getServerData(
      "get",
      this.apiList.getLookupAsync,
      {},
      (res) => {
        this.aqlOptions = res.data.data.aql.filter((x) => x.isActive === true);
        let tempInspection = res.data.data.inspectionClass.filter((x) => x.inspectionClassFieldName.name === "Inspection Level 2" || x.name == "Fixed");
        this.inspectionLevelOptions = tempInspection.filter((x) => x.name != "Square Root");
        this.inspectionLevel1Options = this.inspectionLevelOptions.filter((x) => x.name == "Fixed");
        this.sampleSizeTypeOptions = res.data.data.inspectionClass.filter((x) => x.inspectionClassFieldName.name === "Sample Size Type");
      },
      header
    );

    this.samplingPlan = JSON.parse(localStorage.getItem("samplingPlanList"));

    this.showFloatingButton = this.samplingPlan == undefined ? false : this.samplingPlan.showFabButton == false ? false : true;
    this.sectionData = this._sectionData.SizeMeasurement ? this._sectionData : this.sectionData;

    await this.populateWorkorderList();
    await this.loadWorkOrderRatingOptions();

//end loading
    this.loadingDf = false;
     this.$store.dispatch("navigation/setLoadingPage",{show:  this.loadingDf });
  },

  computed: {
    ...mapState("inspection", {
      store_token: (state) => state.token,
      _sectionData: (state) => state.sectionData,
      childWorkOrderSizeMeasurement: (state) => state.childWorkOrderSizeMeasurement,
      inspectionSummarySectionResult: (state) => state.inspectionSummarySectionResult,
      _workOrderDetails: (state) => state.workOrderDetails,
      _samplingPlanLists: (state) => state.samplingPlanLists,
      _criticalPOMList: (state) => state.criticalPOMList
    }),
    IsPOMValid(){
      if(this.isPomOml && (this._workOrderDetails.clientName?.toUpperCase() === "WALMART GLOBAL SOURCING" || this._workOrderDetails.clientName?.toUpperCase() === "ASDA STORES LIMITED"))
        if(this.childWorkOrderSizeMeasurement && Object.keys(this.childWorkOrderSizeMeasurement).length !== 0){
          return  !(this.childWorkOrderSizeMeasurement.totalOfCritical=="0"  ||  this.childWorkOrderSizeMeasurement.totalNoOfSamplesMeasured=="0" 
           ||   this.childWorkOrderSizeMeasurement.totalOfCritical==0  ||  this.childWorkOrderSizeMeasurement.totalNoOfSamplesMeasured==0 
           ||  this.childWorkOrderSizeMeasurement.totalOfCritical==null || this.childWorkOrderSizeMeasurement.totalNoOfSamplesMeasured==null)
        } else {
          return !(this.workOrderSizeMeasurementObj.totalOfCritical=="0" || this.workOrderSizeMeasurementObj.totalOfCritical==0 || this.workOrderSizeMeasurementObj.totalOfCritical==null ||
          this.workOrderSizeMeasurementObj.totalNoOfSamplesMeasured=="0" ||  this.workOrderSizeMeasurementObj.totalNoOfSamplesMeasured==0 || this.workOrderSizeMeasurementObj.totalNoOfSamplesMeasured==null);
        }
      else
        return true;
      
     
    },
    IsCriticalPOMValid() {
      if(!this.isPomOml) return true;
      const totalOfCritical = this.childWorkOrderSizeMeasurement?.totalOfCritical || this.workOrderSizeMeasurementObj.totalOfCritical;
      if(totalOfCritical > 0 && this._criticalPOMList.length > 0) {
        return true;
      }
      return false;
    },
    countFoundSummaryTable() {
      let totalFoundCritical = null;
      let totalFoundMajor = null;
      let totalFoundMinor = null;

      if (this.workOrderSizeMeasurementObj.isVaryingAQL && this.workOrderSizeMeasurementObj.productSizeSamplingMethodology === "Combined Sample Size") {
        totalFoundCritical += this.productSizeMeasurementList
          .filter((x) => !x.isCarton)
          .reduce(function(tot, arr) {
            return tot + arr.foundCritical;
          }, 0);

        totalFoundMajor += this.productSizeMeasurementList
          .filter((x) => !x.isCarton)
          .reduce(function(tot, arr) {
            return tot + arr.foundMajor;
          }, 0);

        totalFoundMinor += this.productSizeMeasurementList
          .filter((x) => !x.isCarton)
          .reduce(function(tot, arr) {
            return tot + arr.foundMinor;
          }, 0);
      }

      return { totalFoundCritical, totalFoundMajor, totalFoundMinor };
    },

    disabledApplyWeightButton() {
      let val = true;
      //let workOrderProducts = this.workOrderProducts.filter(x=> x.selected == true)
      let workOrderProducts = this.selectedPO;
      if (workOrderProducts.length > 0 && this.cartonProductMassUpdate.actualWeight != null && this.cartonProductMassUpdate.actualWeight != "") {
        val = false;
      }
      return val;
    },
    disabledApplyButton() {
      let val = true;
      //let workOrderProducts = this.workOrderProducts.filter(x=> x.selected == true)
      let workOrderProducts = this.selectedPO;
      if (
        workOrderProducts.length > 0 &&
        this.cartonProductMassUpdate.actualLength != null &&
        this.cartonProductMassUpdate.actualLength != "" &&
        this.cartonProductMassUpdate.actualWidth != null &&
        this.cartonProductMassUpdate.actualWidth != "" &&
        this.cartonProductMassUpdate.actualHeight != null &&
        this.cartonProductMassUpdate.actualHeight != ""
      ) {
        val = false;
      }
      return val;
    },
    disabledApplyProductSizeButton() {
      let val = true;
      //let workOrderProducts = this.workOrderProducts.filter(x=> x.selected == true)
      let workOrderProducts = this.selectedPO;
      if (
        workOrderProducts.length > 0 &&
        this.cartonProductMassUpdate.actualLength != null &&
        this.cartonProductMassUpdate.actualLength != "" &&
        this.cartonProductMassUpdate.actualWidth != null &&
        this.cartonProductMassUpdate.actualWidth != "" &&
        this.cartonProductMassUpdate.actualHeight != null &&
        this.cartonProductMassUpdate.actualHeight != ""
      ) {
        val = false;
      }
      return val;
    },
    filterWorkOrderProducts: function() {
      if (this.cartonSizeSearchSKUPO != null || this.cartonSizeSearchSKUPO != "") {
        return this.workOrderProducts.filter((x) => {
          return (x.skuDisplay || "").toLowerCase().indexOf((this.cartonSizeSearchSKUPO || "").toLowerCase()) > -1 || (x.po || "").toLowerCase().indexOf((this.cartonSizeSearchSKUPO || "").toLowerCase()) > -1;
        });
      } else {
        return this.workOrderProducts;
      }
    },

    sectionDetail() {
      let section = this._workOrderDetails.workOrderInspectionSections.find((x) => x.sectionId == this.sectionId);
      if (section) {
        section.submitterId = this.utilityFn.getResourceId().toLowerCase();
      } else {
        section = {
          workOrderId: this.workOrderDetail.workOrderId,
          sectionId: this.sectionId,
          isHidden: false,
          submitterId: this.utilityFn.getResourceId().toLowerCase(),
        };
      }

      return section;
    },

    isSectionDisabled() {
      return this.workOrderDetail.resources.length == 0 || (this.workOrderDetail.resources.length > 1 && this.sectionDetail.resourceId.toLowerCase() != this.utilityFn.getResourceId().toLowerCase()) || this.sectionDetail.isHidden  || (!!this.sectionDetail.submittedDate && this.workOrderDetail.resources.length > 1);
    },

    isSectionAuthorized() {
      return this.workOrderDetail.resources.length == 0 || (this.workOrderDetail.resources.length > 1 && this.sectionDetail.resourceId.toLowerCase() != this.utilityFn.getResourceId().toLowerCase());
    },

    showButton() {
      return this.workOrderDetail.resources.length > 1;
    },

    //Done to get the ordered headers
    showHeadersCartonSize() {
      if (this.workOrderSizeMeasurementObj.isVaryingAQL) {
        return this.cartonSizeHeaderAQL;
      }
      return this.cartonSizeHeader;
    },

    showHeadersCartonWeight() {
      if (this.workOrderSizeMeasurementObj.isVaryingAQL) {
        return this.cartonWeightHeaderAQL;
      }
      return this.cartonWeightHeader;
    },
    showHeadersProductSize() {
      if (this.workOrderSizeMeasurementObj.isVaryingAQL && this.workOrderSizeMeasurementObj.productSizeSamplingMethodology == "Combined Sample Size") {
        return this.productSizeHeaderAQLCombined;
      }
      if(this.workOrderSizeMeasurementObj.isVaryingAQL){
        return this.productSizeHeaderAQL;
      }
      return this.productSizeHeader;
    },

    showHeadersProductWeight() {
      if (this.workOrderSizeMeasurementObj.isVaryingAQL) {
        return this.productWeightHeaderAQL;
      }
      return this.productWeightHeader;
    },

    /**
     * @returns List of errors base on the conditions
     */
    errorsCartonTab() {
      const _errors = [];
      this.cartonSizeMeasurementList.forEach((wop, index) => {
        const atIndex = " at index " + (index + 1);

        if (!wop.specifiedLength) {
          _errors.push("Invalid Specified Length " + atIndex);
        }
        if (!wop.specifiedWidth) {
          _errors.push("Invalid Specified Width " + atIndex);
        }
        if (!wop.specifiedHeight) {
          _errors.push("Invalid Specified Height " + atIndex);
        }
        if (!wop.actualLength) {
          _errors.push("Invalid Actual Length " + atIndex);
        }
        if (!wop.actualWidth) {
          _errors.push("Invalid Actual Width " + atIndex);
        }
        if (!wop.actualHeight) {
          _errors.push("Invalid Actual Height " + atIndex);
        }
        if (!wop.dimensionalResult) {
          _errors.push("Invalid Result " + atIndex);
        }
      });

      return _errors;
    },

    /**
     * @returns List of errors base on the conditions
     */
    errorsProductTab() {
      const _errors = [];
      this.productSizeMeasurementList.forEach((wop, index) => {
        const atIndex = " at index " + (index + 1);

        if (!wop.actualWeight) {
          _errors.push("Invalid Weight Measure " + atIndex);
        }
      });

      return _errors;
    },

    /**
     * @returns List of errors base on the conditions
     */
    errorsProductSizeTab() {
          const _errors = [];
       if(this.isPomOml){
        return _errors;
      } 
     else{
        this.productSizeMeasurementList.forEach((wop, index) => {
        const atIndex = " at index " + (index + 1);

        if (!wop.actualLength) {
          _errors.push("Invalid Actual Length " + atIndex);
        }
        if (!wop.actualWidth) {
          _errors.push("Invalid Actual Width " + atIndex);
        }
        if (!wop.actualHeight) {
          _errors.push("Invalid Actual Height " + atIndex);
        }
        if (!wop.actualWeight) {
          _errors.push("Invalid Actual Weight " + atIndex);
        }
      });

      return _errors;
     }
    },

    isDataLoaded() {
      return this.workOrderProducts.length > 0 && !this.cartonSizeLoading;
    },

    cartonSizeResultValue() {
      return this.checkSizeMeasurementResult(this.workOrderSizeMeasurementObj.cartonSizeResultId);
    },

    cartonSizeFailCodeOptions() {
      return this.getResultCodes(this.workOrderSizeMeasurementObj.cartonSizeResultId);
    },

    productSizeResultValue() {
      return this.checkSizeMeasurementResult(this.workOrderSizeMeasurementObj.productSizeResultId);
    },

    productSizeFailCodeOptions() {
      return this.getResultCodes(this.workOrderSizeMeasurementObj.productSizeResultId);
    }
  },
  methods: {
    isFinalRandomShippingAndWalmartOrAsda() {
      return this._workOrderDetails.isPreShipOrFinalRandom && (this._workOrderDetails.clientName?.toUpperCase() === 'WALMART GLOBAL SOURCING' || this._workOrderDetails.clientName?.toUpperCase() === 'ASDA STORES LIMITED')
    },

    workOrderFailAndPendingCode() {

      let getF0 = ''
      this.getWorkOrderFailCode.forEach(e => {
        if (e.details != null) {
          this.workOrderFailCode.push(e.description +' - '+ e.details)
        }
        else {
          if (e.description.toLowerCase() == 'f0') {
            getF0 = e.description
          }
          else {
            this.workOrderFailCode.push(e.description)
          }
        }
      });

      // put F0 to the end of the array
      this.workOrderFailCode.push(getF0)

      this.getWorkOrderPendingCode.forEach(e => {
        if (e.details != null) {
          this.workOrderPendingCode.push(e.description +' - '+ e.details)
        }
        else {
          this.workOrderPendingCode.push(e.description)
        }
      });


    },

    ShowAQLinSumarry() {
      let val = false;
      if (this.workOrderSizeMeasurementObj.isVaryingAQL && this.workOrderSizeMeasurementObj.productSizeSamplingMethodology === "Combined Sample Size") {
        val = true;
      } else {
        val = false;
      }
      return val;
    },

    isVaryingCombined(){
     let val = false;
      if (this.workOrderSizeMeasurementObj.isVaryingAQL && this.workOrderSizeMeasurementObj.productSizeSamplingMethodology === "Combined Sample Size") {
        val = true;
      } else {
        val = false;
      }
      return val;
    },
    isVaryingSeparated(){
     let val = false;
      if (this.workOrderSizeMeasurementObj.isVaryingAQL && this.workOrderSizeMeasurementObj.productSizeSamplingMethodology === "Separated Sample Size") {
        val = true;
      } else {
        val = false;
      }
      return val;
    },
    changeAQLBySamplingMethodolgy() {
      let criticalAc = null;
      let criticalRe = null;
      let majorAc = null;
      let majorRe = null;
      let minorAc = null;
      let minorRe = null;

      if (this.workOrderSizeMeasurementObj.isVaryingAQL && this.workOrderSizeMeasurementObj.productSizeSamplingMethodology === "Combined Sample Size") {
        criticalAc = 0;
        criticalRe = 0;
        majorAc = 0;
        majorRe = 0;
        minorAc = 0;
        minorRe = 0;

        if (this.workOrderSizeMeasurementObj.productSizeInspectionLevel != "Fixed" && this.workOrderSizeMeasurementObj.productSizeInspectionLevel != "Square Root") {
          //Critical
          let varyingAQLCritical = this.samPlan.getVaryingAQL(
            this.samplingPlan.totalProductPOQty,
            this.workOrderSizeMeasurementObj.productSizeInspectionLevel,
            this.workOrderDetail.inspectionLevel,
            this.getAQL(this.workOrderSizeMeasurementObj.productAqlCriticalID),
            this.workOrderDetail.samplingPlan
          );

          criticalAc = this.getAQL(this.workOrderSizeMeasurementObj.productAqlCriticalID) == "0" ? "0" : varyingAQLCritical.aC;
          criticalRe = this.getAQL(this.workOrderSizeMeasurementObj.productAqlCriticalID) == "0" ? "1" : varyingAQLCritical.rE;

          //Major
          let varyingAQLMajor = this.samPlan.getVaryingAQL(
            this.samplingPlan.totalProductPOQty,
            this.workOrderSizeMeasurementObj.productSizeInspectionLevel,
            this.workOrderDetail.inspectionLevel,
            this.getAQL(this.workOrderSizeMeasurementObj.productAqlMajorID),
            this.workOrderDetail.samplingPlan
          );
          majorAc = this.getAQL(this.workOrderSizeMeasurementObj.productAqlMajorID) == "0" ? "0" : varyingAQLMajor.aC;
          majorRe = this.getAQL(this.workOrderSizeMeasurementObj.productAqlMajorID) == "0" ? "1" : varyingAQLMajor.rE;

          //Minor
          let varyingAQLMinor = this.samPlan.getVaryingAQL(
            this.samplingPlan.totalProductPOQty,
            this.workOrderSizeMeasurementObj.productSizeInspectionLevel,
            this.workOrderSizeMeasurementObj.inspectionLevel,
            this.getAQL(this.workOrderSizeMeasurementObj.productAqlMinorID),
            this.workOrderDetail.samplingPlan
          );
          minorAc = this.getAQL(this.workOrderSizeMeasurementObj.productAqlMinorID) == "0" ? "0" : varyingAQLMinor.aC;
          minorRe = this.getAQL(this.workOrderSizeMeasurementObj.productAqlMinorID) == "0" ? "1" : varyingAQLMinor.rE;
        }
      }
    },

    closeSave() {
      let workOrderProductSizeMeasurementResultObjList = [];
      this.productSizeMeasurementList.forEach((csml) => {
        if (csml.orderNumber == 1) {
          const addedCartonSizeMeasurement = {
            id: csml.id,
            workOrderProductId: csml.workOrderProductId,
            isCarton: false,
            specifiedLength: csml.specifiedLength,
            specifiedWidth: csml.specifiedWidth,
            specifiedHeight: csml.specifiedHeight,
            actualLength: csml.actualLength,
            actualWidth: csml.actualWidth,
            actualHeight: csml.actualHeight,
            dimensionalUnit: csml.dimensionalUnit,
            dimensionalResult: csml.dimensionalResult,
            specifiedWeight: csml.specifiedWeight,
            actualWeight: csml.actualWeight,
            weightUnit: csml.weightUnit,
            weightResult: csml.weightResult,
            sampleSize: csml.sampleSize,
            orderNumber: csml.orderNumber,
            acceptCritical: csml.acceptCritical,
            acceptMajor: csml.acceptMajor,
            acceptMinor: csml.acceptMinor,
            foundCritical: csml.foundCritical,
            foundMajor: csml.foundMajor,
            foundMinor: csml.foundMinor,
            rejectCritical: csml.rejectCritical,
            rejectMajor: csml.rejectMajor,
            rejectMinor: csml.rejectMinor,
            szCritical: csml.szCritical,
            szMajor: csml.szMajor,
            szMinor: csml.szMinor,
            sectionId: 2,
          };
          workOrderProductSizeMeasurementResultObjList.push(addedCartonSizeMeasurement);
        }
      });

      if (workOrderProductSizeMeasurementResultObjList.length > 0) {
        this.utilityFn.getServerData("post", this.apiList.upsertWorkOrderProductSizeMeasurementResult, workOrderProductSizeMeasurementResultObjList, (res) => {});
      }

      this.showDefectFormDialog = false;
    },

    workorderProductDefectSectionValue(workorderProductDefectSection) {
      this.productSizeMeasurementList.forEach((element) => {
      
        const index = this.productSizeMeasurementList.findIndex((x) => x.workOrderProductId === element.workOrderProductId);

        let foundCritical = null;
        let foundMajor = null;
        let foundMinor = null;

        // Critical
        this.productSizeMeasurementList[index].foundCritical = workorderProductDefectSection.reduce(function(tot, arr) {
          if (arr.workOrderProductId == element.workOrderProductId && arr.defectClassificationId == 1) {
            foundCritical += Number(arr.qty);
          }
          return foundCritical;
        }, null);

        //Major
        this.productSizeMeasurementList[index].foundMajor = workorderProductDefectSection.reduce(function(tot, arr) {
          if (arr.workOrderProductId == element.workOrderProductId && arr.defectClassificationId == 2) {
            foundMajor += Number(arr.qty);
          }
          return foundMajor;
        }, null);

        //Minor
        this.productSizeMeasurementList[index].foundMinor = workorderProductDefectSection.reduce(function(tot, arr) {
          if (arr.workOrderProductId == element.workOrderProductId && arr.defectClassificationId == 3) {
            foundMinor += Number(arr.qty);
          }
          return foundMinor;
        }, null);
          this.ChangeProductSizeLengthWidthHeight(element)
          this.ChangeProductWeightMeasure(element)
      });

      this.calculateFCMResult('tab-3');
    },

    showConfirmDialog(tab) {
      this.showConfirmApplyDialog = true;
      switch (tab) {
        case "cartonsize":
          this.showConfirmTabName = "cartonsize";
          break;
        case "cartonweight":
          this.showConfirmTabName = "cartonweight";
          break;
        case "productsize":
          this.showConfirmTabName = "productsize";
          break;
        case "productweight":
          this.showConfirmTabName = "productweight";
          break;
      }
    },

    clearMassUpdate() {
      this.cartonSizeSearchSKUPO = null;
      this.cartonProductMassUpdate.actualLength = null;
      this.cartonProductMassUpdate.actualWidth = null;
      this.cartonProductMassUpdate.actualHeight = null;
      this.cartonProductMassUpdate.actualWeight = null;
      this.cartonProductMassUpdate.sampleSize = null;
      this.selectedPO = [];
      // this.workOrderProducts.forEach(x => {
      //     x.selected = false
      // })
    },

    applyMassUpdate(tab) {
      if (window.navigator.onLine === true) {
        this.showProgressApplyDialog = true;
      }
      let workOrderProductSizeMeasurementResultObjList = [];
      //let  workOrderProducts = this.workOrderProducts.filter(x => x.selected == true)
      let workOrderProducts = this.selectedPO;
      switch (tab) {
        case "cartonsize":
          workOrderProducts.forEach((wop) => {
            let cartonSizeMeasurementList = this.cartonSizeMeasurementList.filter((x) => x.workOrderProductId == wop.id);
            cartonSizeMeasurementList.forEach((csml) => {
              this.cartonSizeMeasurementList.map((x) => {
                if (x.id == csml.id) {
                  (x.actualLength = +this.cartonProductMassUpdate.actualLength),
                    (x.actualWidth = +this.cartonProductMassUpdate.actualWidth),
                    (x.actualHeight = +this.cartonProductMassUpdate.actualHeight),
                    (x.actualWeight = this.cartonProductMassUpdate.actualWeight ? +this.cartonProductMassUpdate.actualWeight : x.actualWeight),
                    //(x.actualWeight = +this.cartonProductMassUpdate.actualWeight),
                    (x.sampleSize = this.cartonProductMassUpdate.sampleSize ? +this.cartonProductMassUpdate.sampleSize : x.sampleSize);
                }
                return x;
              });
              this.ChangeCartonSizeLengthWidthHeight(csml);
              const addedCartonSizeMeasurement = {
                id: csml.id,
                workOrderProductId: csml.workOrderProductId,
                isCarton: true,
                specifiedLength: csml.specifiedLength,
                specifiedWidth: csml.specifiedWidth,
                specifiedHeight: csml.specifiedHeight,
                actualLength: +this.cartonProductMassUpdate.actualLength,
                actualWidth: +this.cartonProductMassUpdate.actualWidth,
                actualHeight: +this.cartonProductMassUpdate.actualHeight,
                dimensionalUnit: csml == null ? null : csml.dimensionalUnit,
                dimensionalResult: csml.dimensionalResult,
                specifiedWeight: csml.specifiedWeight,
                actualWeight: this.cartonProductMassUpdate.actualWeight ? +this.cartonProductMassUpdate.actualWeight : csml.actualWeight,
                // actualWeight: +this.cartonProductMassUpdate.actualWeight,
                weightUnit: csml.weightUnit == null ? null : csml.weightUnit,
                weightResult: csml.weightResult,
                sampleSize: this.cartonProductMassUpdate.sampleSize ? +this.cartonProductMassUpdate.sampleSize : csml.sampleSize,
                orderNumber: csml.orderNumber,
                acceptCritical: csml.acceptCritical,
                acceptMajor: csml.acceptMajor,
                acceptMinor: csml.acceptMinor,
                foundCritical: csml.foundCritical,
                foundMajor: csml.foundMajor,
                foundMinor: csml.foundMinor,
                rejectCritical: csml.rejectCritical,
                rejectMajor: csml.rejectMajor,
                rejectMinor: csml.rejectMinor,
                szCritical: csml.szCritical,
                szMajor: csml.szMajor,
                szMinor: csml.szMinor,
                sectionId: 2,
              };
              workOrderProductSizeMeasurementResultObjList.push(addedCartonSizeMeasurement);
            });
          });
          break;

        case "productsize":
          workOrderProducts.forEach((wop) => {
            let productSizeMeasurementList = this.productSizeMeasurementList.filter((x) => x.workOrderProductId == wop.id);
            productSizeMeasurementList.forEach((csml) => {
              this.productSizeMeasurementList.map((x) => {
                if (x.id == csml.id) {
                  (x.actualLength = +this.cartonProductMassUpdate.actualLength),
                    (x.actualWidth = +this.cartonProductMassUpdate.actualWidth),
                    (x.actualHeight = +this.cartonProductMassUpdate.actualHeight),
                    (x.actualWeight = this.cartonProductMassUpdate.actualWeight ? +this.cartonProductMassUpdate.actualWeight : x.actualWeight),
                    (x.sampleSize = this.cartonProductMassUpdate.sampleSize ? +this.cartonProductMassUpdate.sampleSize : x.sampleSize);
                }
                return x;
              });
              this.ChangeProductSizeLengthWidthHeight(csml);
              const addedCartonSizeMeasurement = {
                id: csml.id,
                workOrderProductId: csml.workOrderProductId,
                isCarton: false,
                specifiedLength: csml.specifiedLength,
                specifiedWidth: csml.specifiedWidth,
                specifiedHeight: csml.specifiedHeight,
                actualLength: +this.cartonProductMassUpdate.actualLength,
                actualWidth: +this.cartonProductMassUpdate.actualWidth,
                actualHeight: +this.cartonProductMassUpdate.actualHeight,
                dimensionalUnit: csml == null ? null : csml.dimensionalUnit,
                dimensionalResult: csml.dimensionalResult,
                specifiedWeight: csml.specifiedWeight,
                actualWeight: +this.cartonProductMassUpdate.actualWeight,
                weightUnit: csml.weightUnit == null ? null : csml.weightUnit,
                weightResult: csml.weightResult,
                sampleSize: this.cartonProductMassUpdate.sampleSize ? +this.cartonProductMassUpdate.sampleSize : csml.sampleSize,
                orderNumber: csml.orderNumber,
                acceptCritical: csml.acceptCritical,
                acceptMajor: csml.acceptMajor,
                acceptMinor: csml.acceptMinor,
                foundCritical: csml.foundCritical,
                foundMajor: csml.foundMajor,
                foundMinor: csml.foundMinor,
                rejectCritical: csml.rejectCritical,
                rejectMajor: csml.rejectMajor,
                rejectMinor: csml.rejectMinor,
                szCritical: csml.szCritical,
                szMajor: csml.szMajor,
                szMinor: csml.szMinor,
                sectionId: 2,
              };
              workOrderProductSizeMeasurementResultObjList.push(addedCartonSizeMeasurement);
            });
          });
          break;

        case "cartonweight":
          workOrderProducts.forEach((wop) => {
            let cartonSizeMeasurementList = this.cartonSizeMeasurementList.filter((x) => x.workOrderProductId == wop.id);
            cartonSizeMeasurementList.forEach((csml) => {
              this.cartonSizeMeasurementList.map((x) => {
                if (x.id == csml.id) {
                  x.actualWeight = +this.cartonProductMassUpdate.actualWeight;
                }
                return x;
              });
              this.changeCartonWeightMeasure(csml);
              const addedCartonSizeMeasurement = {
                id: csml.id,
                workOrderProductId: csml.workOrderProductId,
                isCarton: true,
                specifiedLength: csml.specifiedLength,
                specifiedWidth: csml.specifiedWidth,
                specifiedHeight: csml.specifiedHeight,
                actualLength: csml.actualLength,
                actualWidth: csml.actualWidth,
                actualHeight: csml.actualHeight,
                dimensionalUnit: csml == null ? null : csml.dimensionalUnit,
                dimensionalResult: csml.dimensionalResult,
                specifiedWeight: csml.specifiedWeight,
                actualWeight: +this.cartonProductMassUpdate.actualWeight,
                weightUnit: csml.weightUnit == null ? null : csml.weightUnit,
                weightResult: csml.weightResult,
                sampleSize: csml.sampleSize,
                orderNumber: csml.orderNumber,
                acceptCritical: csml.acceptCritical,
                acceptMajor: csml.acceptMajor,
                acceptMinor: csml.acceptMinor,
                foundCritical: csml.foundCritical,
                foundMajor: csml.foundMajor,
                foundMinor: csml.foundMinor,
                rejectCritical: csml.rejectCritical,
                rejectMajor: csml.rejectMajor,
                rejectMinor: csml.rejectMinor,
                szCritical: csml.szCritical,
                szMajor: csml.szMajor,
                szMinor: csml.szMinor,
                sectionId: 2,
              };
              workOrderProductSizeMeasurementResultObjList.push(addedCartonSizeMeasurement);
            });
          });
          break;

        case "productweight":
          workOrderProducts.forEach((wop) => {
            let productSizeMeasurementList = this.productSizeMeasurementList.filter((x) => x.workOrderProductId == wop.id);
            productSizeMeasurementList.forEach((csml) => {
              this.productSizeMeasurementList.map((x) => {
                if (x.id == csml.id) {
                  x.actualWeight = +this.cartonProductMassUpdate.actualWeight;
                }
                return x;
              });
              this.ChangeProductWeightMeasure(csml);
              const addedCartonSizeMeasurement = {
                id: csml.id,
                workOrderProductId: csml.workOrderProductId,
                isCarton: false,
                specifiedLength: csml.specifiedLength,
                specifiedWidth: csml.specifiedWidth,
                specifiedHeight: csml.specifiedHeight,
                actualLength: csml.actualLength,
                actualWidth: csml.actualWidth,
                actualHeight: csml.actualHeight,
                dimensionalUnit: csml == null ? null : csml.dimensionalUnit,
                dimensionalResult: csml.dimensionalResult,
                specifiedWeight: csml.specifiedWeight,
                actualWeight: +this.cartonProductMassUpdate.actualWeight,
                weightUnit: csml.weightUnit == null ? null : csml.weightUnit,
                weightResult: csml.weightResult,
                sampleSize: csml.sampleSize,
                orderNumber: csml.orderNumber,
                acceptCritical: csml.acceptCritical,
                acceptMajor: csml.acceptMajor,
                acceptMinor: csml.acceptMinor,
                foundCritical: csml.foundCritical,
                foundMajor: csml.foundMajor,
                foundMinor: csml.foundMinor,
                rejectCritical: csml.rejectCritical,
                rejectMajor: csml.rejectMajor,
                rejectMinor: csml.rejectMinor,
                szCritical: csml.szCritical,
                szMajor: csml.szMajor,
                szMinor: csml.szMinor,
                sectionId: 2,
              };
              workOrderProductSizeMeasurementResultObjList.push(addedCartonSizeMeasurement);
            });
          });
          break;
      }
      this.clearMassUpdate();
      if (workOrderProductSizeMeasurementResultObjList.length > 0) {
        this.utilityFn.getServerData(
          "post",
          this.apiList.upsertWorkOrderProductSizeMeasurementResult,
          workOrderProductSizeMeasurementResultObjList,
          (res) => {
            this.calculateSizeMeasurementResult(tab);
            this.showConfirmApplyDialog = false;
            this.showProgressApplyDialog = false;
          },
          (errRes) => {
            this.calculateSizeMeasurementResult(tab);
            this.showConfirmApplyDialog = false;
            this.showProgressApplyDialog = false;
            if (window.navigator.onLine != true) {
              this.message = this.msgList.NoConnection;
              this.snackbar = true;
            }
          }
        );
      }
    },

    getFCMResultId(resultName) {
      let value = "";
      if (this.workOrderResultList.filter((x) => x.name == resultName).length != 0) {
        value = this.workOrderResultList.filter((x) => x.name == resultName)[0].id;
      }
      return value;
    },

    calculateFCMResult(tab) {
      switch (tab) {
        case "tab-1":
          // Non-Conformed [If 1 SKU result is null]
          if (this.cartonSizeMeasurementList.filter((p) => p.dimensionalResult == null).length > 0) {
            this.workOrderSizeMeasurementObj.cartonSizeResultId = null;
          }

          // Non-Conformed [If 1 SKU result is FAIL]
          else if (this.cartonSizeMeasurementList.filter((p) => p.dimensionalResult == 0).length > 0) {
            this.workOrderSizeMeasurementObj.cartonSizeResultId = this.getFCMResultId("Fail");
          }
          // PENDING [If 1 SKU result is PENDING and no FAIL]
          else if (this.cartonSizeMeasurementList.filter((p) => p.dimensionalResult == 2).length > 0) {
            this.workOrderSizeMeasurementObj.cartonSizeResultId = this.getFCMResultId("Pending");
          }
          //  N/A [If All SKUs result is N/A]
          else if (this.cartonSizeMeasurementList.filter((p) => p.dimensionalResult == 3).length == this.cartonSizeMeasurementList.length) {
            this.workOrderSizeMeasurementObj.cartonSizeResultId = this.getFCMResultId("N/A");
          }
          // Conform [If All SKUs result is PASS or with N/A]
          else {
            this.workOrderSizeMeasurementObj.cartonSizeResultId = this.getFCMResultId("Pass");
          }
          break;
        case "tab-3":
          // Non-Conformed [If 1 SKU result is null]
          if (this.productSizeMeasurementList.filter((p) => p.dimensionalResult == null).length > 0) {
            this.workOrderSizeMeasurementObj.productSizeResultId = null;
            this.workOrderSizeMeasurementObj.productWeightResultId = this.workOrderSizeMeasurementObj.productSizeResultId
          }

          // Non-Conformed [If 1 SKU result is FAIL]
          else if (this.productSizeMeasurementList.filter((p) => p.dimensionalResult == 0).length > 0) {
            this.workOrderSizeMeasurementObj.productSizeResultId = this.getFCMResultId("Fail");
            this.workOrderSizeMeasurementObj.productWeightResultId = this.workOrderSizeMeasurementObj.productSizeResultId
          }
          // PENDING [If 1 SKU result is PENDING and no FAIL]
          else if (this.productSizeMeasurementList.filter((p) => p.dimensionalResult == 2).length > 0) {
            this.workOrderSizeMeasurementObj.productSizeResultId = this.getFCMResultId("Pending");
            this.workOrderSizeMeasurementObj.productWeightResultId = this.workOrderSizeMeasurementObj.productSizeResultId
          }
          //  N/A [If All SKUs result is N/A]
          else if (this.productSizeMeasurementList.filter((p) => p.dimensionalResult == 3).length == this.productSizeMeasurementList.length) {
            this.workOrderSizeMeasurementObj.productSizeResultId = this.getFCMResultId("N/A");
            this.workOrderSizeMeasurementObj.productWeightResultId = this.workOrderSizeMeasurementObj.productSizeResultId
          }
          // Conform [If All SKUs result is PASS or with N/A]
          else {
            this.workOrderSizeMeasurementObj.productSizeResultId = this.getFCMResultId("Pass");
            this.workOrderSizeMeasurementObj.productWeightResultId = this.workOrderSizeMeasurementObj.productSizeResultId
          }
          break;
        case "tab-4":
          // Non-Conformed [If 1 SKU result is null]
          if (this.productSizeMeasurementList.filter((p) => p.weightResult == null).length > 0) {
            this.workOrderSizeMeasurementObj.productWeightResultId = null;
            this.workOrderSizeMeasurementObj.productSizeResultId = this.workOrderSizeMeasurementObj.productWeightResultId
          }

          // Non-Conformed [If 1 SKU result is FAIL]
          else if (this.productSizeMeasurementList.filter((p) => p.weightResult == 0).length > 0) {
            this.workOrderSizeMeasurementObj.productWeightResultId = this.getFCMResultId("Fail");
            this.workOrderSizeMeasurementObj.productSizeResultId = this.workOrderSizeMeasurementObj.productWeightResultId
          }
          // PENDING [If 1 SKU result is PENDING and no FAIL]
          else if (this.productSizeMeasurementList.filter((p) => p.weightResult == 2).length > 0) {
            this.workOrderSizeMeasurementObj.productWeightResultId = this.getFCMResultId("Pending");
            this.workOrderSizeMeasurementObj.productSizeResultId = this.workOrderSizeMeasurementObj.productWeightResultId
          }
          //  N/A [If All SKUs result is N/A]
          else if (this.productSizeMeasurementList.filter((p) => p.weightResult == 3).length == this.productSizeMeasurementList.length) {
            this.workOrderSizeMeasurementObj.productWeightResultId = this.getFCMResultId("N/A");
            this.workOrderSizeMeasurementObj.productSizeResultId = this.workOrderSizeMeasurementObj.productWeightResultId
          }
          // Conform [If All SKUs result is PASS or with N/A]
          else {
            this.workOrderSizeMeasurementObj.productWeightResultId = this.getFCMResultId("Pass");
            this.workOrderSizeMeasurementObj.productSizeResultId = this.workOrderSizeMeasurementObj.productWeightResultId
          }
          break;
      }
    },

    calculateSizeMeasurementResult(tab) {
      this.cartonSizeResult = null;
      this.cartonWeightResult = null;
      this.productSizeResult = null;
      this.productWeightResult = null;

      switch (tab) {
        case "cartonsize":
          let cartonSize = this.cartonSizeMeasurementList.filter((x) => x.dimensionalResult != null);
          let cartonSizeFail = this.cartonSizeMeasurementList.filter((x) => x.dimensionalResult == "0");
          let cartonSizePass = this.cartonSizeMeasurementList.filter((x) => x.dimensionalResult == "1");
          let cartonSizePending = this.cartonSizeMeasurementList.filter((x) => x.dimensionalResult == "2");
          let cartonSizeNA = this.cartonSizeMeasurementList.filter((x) => x.dimensionalResult == "3");

          if (
            (this.workOrderSizeMeasurementObj.cartonSizeToleranceUpper == -1 && this.workOrderSizeMeasurementObj.cartonSizeToleranceLower == -1) ||
            (this.workOrderSizeMeasurementObj.cartonSizeToleranceUpper == null && this.workOrderSizeMeasurementObj.cartonSizeToleranceLower == null) ||
            (this.workOrderSizeMeasurementObj.cartonSizeToleranceUpper == "" && this.workOrderSizeMeasurementObj.cartonSizeToleranceLower == "")
          ) {
            if (this.workOrderDetail.smToleranceCrtSize == true) {
              if (cartonSize.length == cartonSizeNA.length && cartonSize.length != 0) {
                this.cartonSizeResult = "N/A";
              } else {
                this.cartonSizeResult = "Pending";
              }
            } else {
              this.cartonSizeResult = "N/A";
            }
          } else {
            if (cartonSizeFail.length > 0) {
              this.cartonSizeResult = "Fail";
            } else {
              if (cartonSizePending.length > 0) {
                this.cartonSizeResult = "Pending";
              } else {
                if (cartonSizeNA.length == cartonSize.length) {
                  if (cartonSize.length == 0) {
                    this.cartonSizeResult = "Pass";
                  } else {
                    this.cartonSizeResult = "N/A";
                  }
                } else {
                  this.cartonSizeResult = "Pass";
                }
              }
            }
          }
          this.workOrderSizeMeasurementObj.cartonSizeResultId = this.cartonSizeResult == null ? this.workOrderSizeMeasurementObj.cartonSizeResultId : this.workOrderResultList.find((x) => x.name == this.cartonSizeResult).id;
          break;

        case "cartonweight":
          let cartonWeight = this.cartonSizeMeasurementList.filter((x) => x.weightResult != null);
          let cartonWeightFail = this.cartonSizeMeasurementList.filter((x) => x.weightResult == "0");
          let cartonWeightPass = this.cartonSizeMeasurementList.filter((x) => x.weightResult == "1");
          let cartonWeightPending = this.cartonSizeMeasurementList.filter((x) => x.weightResult == "2");
          let cartonWeightNA = this.cartonSizeMeasurementList.filter((x) => x.weightResult == "3");

          if (
            (this.workOrderSizeMeasurementObj.cartonWeightToleranceUpper == -1 && this.workOrderSizeMeasurementObj.cartonWeightToleranceLower == -1) ||
            (this.workOrderSizeMeasurementObj.cartonWeightToleranceUpper == null && this.workOrderSizeMeasurementObj.cartonWeightToleranceLower == null) ||
            (this.workOrderSizeMeasurementObj.cartonWeightToleranceUpper == "" && this.workOrderSizeMeasurementObj.cartonWeightToleranceLower == "")
          ) {
            if (this.workOrderDetail.smToleranceCrtWeight == true) {
              if (cartonWeight.length == cartonWeightNA.length && cartonWeight.length != 0) {
                this.cartonWeightResult = "N/A";
              } else {
                this.cartonWeightResult = "Pending";
              }
            } else {
              this.cartonWeightResult = "N/A";
            }
          } else {
            if (cartonWeightFail.length > 0) {
              this.cartonWeightResult = "Fail";
            } else {
              if (cartonWeightPending.length > 0) {
                this.cartonWeightResult = "Pending";
              } else {
                if (cartonWeightNA.length == cartonWeight.length) {
                  if (cartonWeight.length == 0) {
                    this.cartonWeightResult = "Pass";
                  } else {
                    this.cartonWeightResult = "N/A";
                  }
                } else {
                  this.cartonWeightResult = "Pass";
                }
              }
            }
          }
          this.workOrderSizeMeasurementObj.cartonWeightResultId = this.cartonWeightResult == null ? this.workOrderSizeMeasurementObj.cartonWeightResultId : this.workOrderResultList.find((x) => x.name == this.cartonWeightResult).id;
          break;

        case "productsize":
          let productSize = this.productSizeMeasurementList.filter((x) => x.dimensionalResult != null);
          let productSizeFail = this.productSizeMeasurementList.filter((x) => x.dimensionalResult == "0");
          let productSizePass = this.productSizeMeasurementList.filter((x) => x.dimensionalResult == "1");
          let productSizePending = this.productSizeMeasurementList.filter((x) => x.dimensionalResult == "2");
          let productSizeNA = this.productSizeMeasurementList.filter((x) => x.dimensionalResult == "3");

          if (
            (this.workOrderSizeMeasurementObj.productSizeToleranceLower == -1 && this.workOrderSizeMeasurementObj.productSizeToleranceUpper == -1) ||
            (this.workOrderSizeMeasurementObj.productSizeToleranceLower == null && this.workOrderSizeMeasurementObj.productSizeToleranceUpper == null) ||
            (this.workOrderSizeMeasurementObj.productSizeToleranceLower == "" && this.workOrderSizeMeasurementObj.productSizeToleranceUpper == "")
          ) {
            if (this.workOrderDetail.smTolerancePrdSize == true) {
              if (productSize.length == productSizeNA.length && productSize.length != 0) {
                this.productSizeResult = "N/A";
              } else {
                this.productSizeResult = "Pending";
              }
            } else {
              this.productSizeResult = "N/A";
            }
          } else {
            if (productSizeFail.length > 0) {
              this.productSizeResult = "Fail";
            } else {
              if (productSizePending.length > 0) {
                this.productSizeResult = "Pending";
              } else {
                if (productSizeNA.length == productSize.length) {
                  if (productSize.length == 0) {
                    this.productSizeResult = null;
                  } else {
                    this.productSizeResult = "Pass";
                  }
                } else {
                  this.productSizeResult = "Pass";
                }
              }
            }
          }

          this.workOrderSizeMeasurementObj.productSizeResultId = this.productSizeResult == null ? this.workOrderSizeMeasurementObj.productSizeResultId : this.workOrderResultList.find((x) => x.name == this.productSizeResult).id;
          this.workOrderSizeMeasurementObj.productWeightResultId = this.workOrderSizeMeasurementObj.productSizeResultId;
          break;

        case "productweight":
          let productWeight = this.productSizeMeasurementList.filter((x) => x.weightResult != null);
          let productWeightFail = this.productSizeMeasurementList.filter((x) => x.weightResult == "0");
          let productWeightPass = this.productSizeMeasurementList.filter((x) => x.weightResult == "1");
          let productWeightPending = this.productSizeMeasurementList.filter((x) => x.weightResult == "2");
          let productWeightNA = this.productSizeMeasurementList.filter((x) => x.weightResult == "3");

          if (
            (this.workOrderSizeMeasurementObj.productWeightToleranceUpper == -1 && this.workOrderSizeMeasurementObj.productWeightToleranceLower == -1) ||
            (this.workOrderSizeMeasurementObj.productWeightToleranceUpper == null && this.workOrderSizeMeasurementObj.productWeightToleranceLower == null) ||
            (this.workOrderSizeMeasurementObj.productWeightToleranceUpper == "" && this.workOrderSizeMeasurementObj.productWeightTolerancelower == "")
          ) {
            if (this.workOrderDetail.smTolerancePrdWeight == true) {
              if (productWeight.length == productWeightNA.length && productWeight.length != 0) {
                this.productWeightResult = "N/A";
              } else {
                this.productWeightResult = "Pending";
              }
            } else {
              this.productWeightResult = "N/A";
            }
          } else {
            if (productWeightFail.length > 0) {
              this.productWeightResult = "Fail";
            } else {
              if (productWeightPending.length > 0) {
                this.productWeightResult = "Pending";
              } else {
                if (productWeightNA.length == productWeight.length) {
                  if (productWeight.length == 0) {
                    this.productWeightResult = "Pass";
                  } else {
                    this.productWeightResult = "N/A";
                  }
                } else {
                  this.productWeightResult = "Pass";
                }
              }
            }
          }
          this.workOrderSizeMeasurementObj.productWeightResultId = this.productWeightResult == null ? this.workOrderSizeMeasurementObj.productWeightResultId : this.workOrderResultList.find((x) => x.name == this.productWeightResult).id;
          break;
      }
    },

    openDefectFormDialog() {
      this.dclSectionData.clientId = this.workOrderDetail.clientId;
      this.dclSectionData.dclType = 1;
      this.dclSectionData.sectionId = this.sectionData.SizeMeasurement.id;
      this.dclSectionData.sectionName = this.sectionData.SizeMeasurement.name;
      this.dclSectionData.workorderProductId = null;
      this.showDefectFormDialog = true;
    },

    changeSizeMeasurementResult(key) {
      this.workOrderSizeMeasurementObj[key] = null;
    },

    checkSizeMeasurementResult(cartonSizeResultId) {
      let value = "";
      if (this.workOrderResultList.filter((x) => x.id == cartonSizeResultId).length != 0) {
        value = this.workOrderResultList.filter((x) => x.id == cartonSizeResultId)[0].name;
      }
      return value;
    },

    getPendingCodeIdResult(pendingCodeId) {
      this.workOrderSizeMeasurementObj.productWeightPendingCodeId =  pendingCodeId
      this.workOrderSizeMeasurementObj.productSizePendingCodeId =  pendingCodeId
    },

    onKeyUp(remark) {
      this.workOrderSizeMeasurementObj.productSizeRemarks =  remark
      this.workOrderSizeMeasurementObj.productWeightRemarks =  remark
    },

    ChangeProductWeightMeasure(item) {
      let weightResult = null
      let aqlResult = null
      let itemResult = null

      if (this.workOrderSizeMeasurementObj.productWeightToleranceUpper == -1 && this.workOrderSizeMeasurementObj.productWeightToleranceLower == -1) {
        if (this.workOrderDetail.smTolerancePrdWeight == true) {
          if (item.actualWeight == -1) {
            weightResult = "3";
          } else {
            weightResult = "2";
          }
        } else {
          weightResult = "3";
        }
      } else {
        if (item.actualWeight == -1 || item.specifiedWeight == -1) {
          weightResult = "2";
        } else {
          weightResult = this.IsTolerance(item.specifiedWeight, item.actualWeight, this.workOrderSizeMeasurementObj.productWeightToleranceUpper, this.workOrderSizeMeasurementObj.productWeightToleranceLower);

          if (this.workOrderSizeMeasurementObj.isVaryingAQL) {
            aqlResult = this.getResultAQL(item.foundCritical, item.rejectCritical, item.foundMajor, item.rejectMajor, item.foundMinor, item.rejectMinor);

            if (aqlResult != null && weightResult != null) {
              itemResult = weightResult == "1" && aqlResult == "1" ? "1" : "0";
            }
          }
        }
      }
      
      item.weightResult = itemResult || weightResult;
      this.calculateSizeMeasurementResult("productweight");
    },

    ChangeProductSizeLengthWidthHeight(item) {
      let sizeResult = null
      let aqlResult = null
      let itemResult = null

      if (this.workOrderSizeMeasurementObj.productSizeToleranceLower == -1 && this.workOrderSizeMeasurementObj.productSizeToleranceUpper == -1) {
        if (this.workOrderDetail.smTolerancePrdSize == true) {
          if (item.actualLength == -1 || item.actualWidth == -1 || item.actualHeight == -1 || item.actualWeight == -1) {
           sizeResult = "3";
          } else {
            sizeResult = "2";
          }
        } else {
         sizeResult = "3";
        }
      } else {
        if (item.actualLength == -1 || item.actualWidth == -1 || item.actualHeight == -1 || item.specifiedLength == -1 || item.specifiedWidth == -1 || item.specifiedHeight == -1 || item.specifiedWeight == -1) {
          sizeResult = "2";
        } else {

          sizeResult = this.getResultProuctSizeWeight(
            item.specifiedLength,
            item.actualLength,
            item.specifiedWidth,
            item.actualWidth,
            item.specifiedHeight,
            item.actualHeight,
            item.specifiedWeight,
            item.actualWeight,
            this.workOrderSizeMeasurementObj.productSizeToleranceUpper,
            this.workOrderSizeMeasurementObj.productSizeToleranceLower
          );
          
          if (this.workOrderSizeMeasurementObj.isVaryingAQL) {
            const rejectedCritical = this.workOrderSizeMeasurementObj.productSizeSamplingMethodology == 'Combined Sample Size' ? this.workOrderSizeMeasurementObj.rejectedCritical : item.rejectCritical; 
            const rejectedMajor = this.workOrderSizeMeasurementObj.productSizeSamplingMethodology == 'Combined Sample Size' ? this.workOrderSizeMeasurementObj.rejectedMajor : item.rejectMajor; 
            const rejectedMinor = this.workOrderSizeMeasurementObj.productSizeSamplingMethodology == 'Combined Sample Size' ? this.workOrderSizeMeasurementObj.rejectedMinor : item.rejectMinor; 
            aqlResult = this.getResultAQL(item.foundCritical, rejectedCritical, item.foundMajor, rejectedMajor, item.foundMinor, rejectedMinor);
            
            if (aqlResult != null && sizeResult != null) {
              itemResult = sizeResult == "1" && aqlResult == "1" ? "1" : "0";
            }
          }
        }
      }

      item.dimensionalResult = itemResult || sizeResult;
      this.calculateSizeMeasurementResult("productsize");
    },

    changeCartonWeightMeasure(item) {
      let weightResult = null
      let aqlResult = null
      let itemResult = null

      if (this.workOrderSizeMeasurementObj.cartonWeightToleranceLower == -1 && this.workOrderSizeMeasurementObj.cartonWeightToleranceUpper == -1) {
        if (this.workOrderDetail.smToleranceCrtWeight == true) {
          if (item.actualWeight == -1) {
            weightResult = "3";
          } else {
            weightResult = "2";
          }
        } else {
          weightResult = "3";
        }
      } else {
        if (item.actualWeight == -1 || item.specifiedWeight == -1) {
          weightResult = "2";
        } else {
          weightResult = this.IsTolerance(item.specifiedWeight, item.actualWeight, this.workOrderSizeMeasurementObj.cartonWeightToleranceUpper, this.workOrderSizeMeasurementObj.cartonWeightToleranceLower);

          if (this.workOrderSizeMeasurementObj.isVaryingAQL) {
            aqlResult = this.getResultAQL(item.foundCritical, item.rejectCritical, item.foundMajor, item.rejectMajor, item.foundMinor, item.rejectMinor);

            if (aqlResult != null && weightResult != null) {
              itemResult = weightResult == "1" && aqlResult == "1" ? "1" : "0";
            }
          }
        }
      }
      
      item.weightResult = itemResult || weightResult;
    },

    ChangeCartonSizeLengthWidthHeight(item) {
      let sizeResult = null
      let aqlResult = null
      let itemResult = null
    
      if (this.workOrderSizeMeasurementObj.cartonSizeToleranceLower == -1 && this.workOrderSizeMeasurementObj.cartonSizeToleranceUpper == -1) {
        if (this.workOrderDetail.smToleranceCrtSize == true) {
          if (item.actualLength == -1 || item.actualWidth == -1 || item.actualHeight == -1 || item.actualWeight == -1) {
           sizeResult = "3";
          } else {
            sizeResult = "2";
          }
        } else {
         sizeResult = "3";
        }
      } else {
        if (item.actualLength == -1 || item.actualWidth == -1 || item.actualHeight == -1 || item.specifiedLength == -1 || item.specifiedWidth == -1 || item.specifiedHeight == -1 || item.specifiedWeight == -1) {
          sizeResult = "2";
        } else {
          sizeResult = this.getResultProuctSizeWeight(
            item.specifiedLength,
            item.actualLength,
            item.specifiedWidth,
            item.actualWidth,
            item.specifiedHeight,
            item.actualHeight,
            item.specifiedWeight,
            item.actualWeight,
            this.workOrderSizeMeasurementObj.cartonSizeToleranceUpper,
            this.workOrderSizeMeasurementObj.cartonSizeToleranceLower
          );

          if (this.workOrderSizeMeasurementObj.isVaryingAQL) {
            aqlResult = this.getResultAQL(item.foundCritical, item.rejectCritical, item.foundMajor, item.rejectMajor, item.foundMinor, item.rejectMinor);

            if (aqlResult != null && sizeResult != null) {
              itemResult = sizeResult == "1" && aqlResult == "1" ? "1" : "0";
            }
          }
        }
      }

      item.dimensionalResult = itemResult || sizeResult;
      this.calculateSizeMeasurementResult("cartonsize");
    },

    UpdateTolerance(type, res) {
      switch (type) {
        case "CartonSize":
          var other = {
            headers: {
              wresult: this.store_token,
              "Content-Type": "application/json",
            },
          };
          const data = [
            {
              op: "replace",
              path: "/smToleranceCrtSize",
              value: res == "Yes" ? true : false,
            },
          ];
          this.utilityFn.getServerData("patch", this.apiList.patchWorkOrderResults + "/" + this.workOrderId + "/2", data, (res) => {}, other);
          this.workOrderDetail.smToleranceCrtSize = res == "Yes" ? true : false;
          this.showToleranceDialogCartonSize = false;
          this.cartonSizeMeasurementList.forEach((element) => {
            element.dimensionalResult = res == "Yes" ? "2" : "3";

            let workOrderProductSizeMeasurementResultObjList = [];
            let addedCartonSizeMeasurement = {
              id: element.id,
              workOrderProductId: element.workOrderProductId,
              isCarton: true,
              specifiedLength: element.specifiedLength,
              specifiedWidth: element.specifiedWidth,
              specifiedHeight: element.specifiedHeight,
              actualLength: element.actualLength,
              actualWidth: element.actualWidth,
              actualHeight: element.actualHeight,
              dimensionalUnit: element.dimensionalUnit == null ? null : element.dimensionalUnit,
              dimensionalResult: element.dimensionalResult,
              specifiedWeight: element.specifiedWeight,
              actualWeight: element.actualWeight,
              weightUnit: element.weightUnit == null ? null : element.weightUnit,
              weightResult: element.weightResult,
              sampleSize: element.sampleSize,
              orderNumber: element.orderNumber,
              acceptCritical: element.acceptCritical,
              acceptMajor: element.acceptMajor,
              acceptMinor: element.acceptMinor,
              foundCritical: element.foundCritical,
              foundMajor: element.foundMajor,
              foundMinor: element.foundMinor,
              rejectCritical: element.rejectCritical,
              rejectMajor: element.rejectMajor,
              rejectMinor: element.rejectMinor,
              szCritical: element.szCritical,
              szMajor: element.szMajor,
              szMinor: element.szMinor,
              sectionId: 2,
            };
            workOrderProductSizeMeasurementResultObjList.push(addedCartonSizeMeasurement);
            this.utilityFn.getServerData("post", this.apiList.upsertWorkOrderProductSizeMeasurementResult, workOrderProductSizeMeasurementResultObjList, (res) => {});
          });
         
          break;
        case "CartonWeight":
          var other = {
            headers: {
              wresult: this.store_token,
              "Content-Type": "application/json",
            },
          };
          const data1 = [
            {
              op: "replace",
              path: "/smToleranceCrtWeight",
              value: res == "Yes" ? true : false,
            },
          ];
          this.utilityFn.getServerData("patch", this.apiList.patchWorkOrderResults + "/" + this.workOrderId + "/2", data1, (res) => {}, other);
          this.workOrderDetail.smToleranceCrtWeight = res == "Yes" ? true : false;
          this.showToleranceDialogCartonWeight = false;
          this.cartonSizeMeasurementList.forEach((element) => {
            element.weightResult = res == "Yes" ? "2" : "3";

            let workOrderProductSizeMeasurementResultObjList = [];
            let addedCartonSizeMeasurement = {
              id: element.id,
              workOrderProductId: element.workOrderProductId,
              isCarton: true,
              specifiedLength: element.specifiedLength,
              specifiedWidth: element.specifiedWidth,
              specifiedHeight: element.specifiedHeight,
              actualLength: element.actualLength,
              actualWidth: element.actualWidth,
              actualHeight: element.actualHeight,
              dimensionalUnit: element.dimensionalUnit == null ? null : element.dimensionalUnit,
              dimensionalResult: element.dimensionalResult,
              specifiedWeight: element.specifiedWeight,
              actualWeight: element.actualWeight,
              weightUnit: element.weightUnit == null ? null : element.weightUnit,
              weightResult: element.weightResult,
              sampleSize: element.sampleSize,
              orderNumber: element.orderNumber,
              acceptCritical: element.acceptCritical,
              acceptMajor: element.acceptMajor,
              acceptMinor: element.acceptMinor,
              foundCritical: element.foundCritical,
              foundMajor: element.foundMajor,
              foundMinor: element.foundMinor,
              rejectCritical: element.rejectCritical,
              rejectMajor: element.rejectMajor,
              rejectMinor: element.rejectMinor,
              szCritical: element.szCritical,
              szMajor: element.szMajor,
              szMinor: element.szMinor,
              sectionId: 2,
            };
            workOrderProductSizeMeasurementResultObjList.push(addedCartonSizeMeasurement);
            this.utilityFn.getServerData("post", this.apiList.upsertWorkOrderProductSizeMeasurementResult, workOrderProductSizeMeasurementResultObjList, (res) => {});
          });
         
          break;
        case "ProductSize":
          var other = {
            headers: {
              wresult: this.store_token,
              "Content-Type": "application/json",
            },
          };
          const data2 = [
            {
              op: "replace",
              path: "/smTolerancePrdSize",
              value: res == "Yes" ? true : false,
            },
          ];
          this.utilityFn.getServerData("patch", this.apiList.patchWorkOrderResults + "/" + this.workOrderId + "/2", data2, (res) => {}, other);
          this.workOrderDetail.smTolerancePrdSize = res == "Yes" ? true : false;
          this.showToleranceDialogProductSize = false;

          this.productSizeMeasurementList.forEach((element) => {
            let workOrderProductSizeMeasurementResultObjList = [];
            element.dimensionalResult = res == "Yes" ? "2" : "3";

            let addedProductSizeMeasurement = {
              id: element.id,
              workOrderProductId: element.workOrderProductId,
              isCarton: false,
              specifiedLength: element.specifiedLength,
              specifiedWidth: element.specifiedWidth,
              specifiedHeight: element.specifiedHeight,
              actualLength: element.actualLength,
              actualWidth: element.actualWidth,
              actualHeight: element.actualHeight,
              dimensionalUnit: element.dimensionalUnit == null ? null : element.dimensionalUnit,
              dimensionalResult: element.dimensionalResult,
              specifiedWeight: element.specifiedWeight,
              actualWeight: element.actualWeight,
              weightUnit: element.weightUnit == null ? null : element.weightUnit,
              weightResult: element.weightResult,
              sampleSize: element.sampleSize,
              orderNumber: element.orderNumber,
              acceptCritical: element.acceptCritical,
              acceptMajor: element.acceptMajor,
              acceptMinor: element.acceptMinor,
              foundCritical: element.foundCritical,
              foundMajor: element.foundMajor,
              foundMinor: element.foundMinor,
              rejectCritical: element.rejectCritical,
              rejectMajor: element.rejectMajor,
              rejectMinor: element.rejectMinor,
              szCritical: element.szCritical,
              szMajor: element.szMajor,
              szMinor: element.szMinor,
              sectionId: 2,
            };
            workOrderProductSizeMeasurementResultObjList.push(addedProductSizeMeasurement);

            this.utilityFn.getServerData("post", this.apiList.upsertWorkOrderProductSizeMeasurementResult, workOrderProductSizeMeasurementResultObjList, (res) => {});
          });

          // this.workOrderProducts.forEach(element => {
          //     element.workOrderProductResults.rsltSizePrd = res == 'Yes' ? '2' : '3'
          //     const formPatch2 = [{
          //         "op": "replace",
          //         "path": "/rsltSizePrd",
          //         "value": res == 'Yes' ? '2' : '3'
          //     }, ];

          //     this.utilityFn.getServerData('patch', this.apiList.upsertProductResult + '/' + element.id + '/2', formPatch2, result => {

          //     });
          // })

          break;
        case "ProductWeight":
          var other = {
            headers: {
              wresult: this.store_token,
              "Content-Type": "application/json",
            },
          };
          const data3 = [
            {
              op: "replace",
              path: "/smTolerancePrdWeight",
              value: res == "Yes" ? true : false,
            },
          ];
          this.utilityFn.getServerData("patch", this.apiList.patchWorkOrderResults + "/" + this.workOrderId + "/2", data3, (res) => {}, other);
          this.workOrderDetail.smTolerancePrdWeight = res == "Yes" ? true : false;
          this.showToleranceDialogProductWeight = false;
          this.productSizeMeasurementList.forEach((element) => {
            let workOrderProductSizeMeasurementResultObjList = [];
            element.weightResult = res == "Yes" ? "2" : "3";

            let addedProductSizeMeasurement = {
              id: element.id,
              workOrderProductId: element.workOrderProductId,
              isCarton: false,
              specifiedLength: element.specifiedLength,
              specifiedWidth: element.specifiedWidth,
              specifiedHeight: element.specifiedHeight,
              actualLength: element.actualLength,
              actualWidth: element.actualWidth,
              actualHeight: element.actualHeight,
              dimensionalUnit: element.dimensionalUnit == null ? null : element.dimensionalUnit,
              dimensionalResult: element.dimensionalResult,
              specifiedWeight: element.specifiedWeight,
              actualWeight: element.actualWeight,
              weightUnit: element.weightUnit == null ? null : element.weightUnit,
              weightResult: element.weightResult,
              sampleSize: element.sampleSize,
              orderNumber: element.orderNumber,
              acceptCritical: element.acceptCritical,
              acceptMajor: element.acceptMajor,
              acceptMinor: element.acceptMinor,
              foundCritical: element.foundCritical,
              foundMajor: element.foundMajor,
              foundMinor: element.foundMinor,
              rejectCritical: element.rejectCritical,
              rejectMajor: element.rejectMajor,
              rejectMinor: element.rejectMinor,
              szCritical: element.szCritical,
              szMajor: element.szMajor,
              szMinor: element.szMinor,
              sectionId: 2,
            };
            workOrderProductSizeMeasurementResultObjList.push(addedProductSizeMeasurement);

            this.utilityFn.getServerData("post", this.apiList.upsertWorkOrderProductSizeMeasurementResult, workOrderProductSizeMeasurementResultObjList, (res) => {});
          });
          // this.workOrderProducts.forEach(element => {
          //     element.workOrderProductResults.rsltWPrd = res == 'Yes' ? '2' : '3'
          //     const formPatch3 = [{
          //         "op": "replace",
          //         "path": "/rsltWPrd",
          //         "value": res == 'Yes' ? '2' : '3'
          //     }, ];

          //     this.utilityFn.getServerData('patch', this.apiList.upsertProductResult + '/' + element.id + '/2', formPatch3, result => {

          //     });
          // })

          break;
      }
    },

    sizeMeasurementResult() {
      let result = {
        result: null,
        findings: null,
      };
      let productsCount = this.workOrderProducts.length;
      let cartonSize = this.workOrderProducts.filter((x) => x.workOrderProductResults && x.workOrderProductResults.rsltSizeCrtn != null);
      let cartonWeight = this.workOrderProducts.filter((x) => x.workOrderProductResults && x.workOrderProductResults.rsltWCrtn != null);
      let productSize = this.workOrderProducts.filter((x) => x.workOrderProductResults && x.workOrderProductResults.rsltSizePrd != null);
      let productWeight = this.workOrderProducts.filter((x) => x.workOrderProductResults && x.workOrderProductResults.rsltWPrd != null);

      if (cartonSize && cartonSize.length > 0 && cartonWeight && cartonWeight.length > 0 && productSize && productSize.length > 0 && productWeight && productWeight.length > 0) {
        result.result =
          cartonSize.filter((x) => +x.workOrderProductResults.rsltSizeCrtn === 1).length === productsCount &&
          cartonWeight.filter((x) => +x.workOrderProductResults.rsltWCrtn === 1).length === productsCount &&
          productSize.filter((x) => +x.workOrderProductResults.rsltSizePrd === 1).length === productsCount &&
          productWeight.filter((x) => +x.workOrderProductResults.rsltWPrd === 1).length === productsCount;
        result.findings =
          +cartonSize.filter((x) => +x.workOrderProductResults.rsltSizeCrtn !== 1).length +
          +cartonWeight.filter((x) => +x.workOrderProductResults.rsltWCrtn !== 1).length +
          +productSize.filter((x) => +x.workOrderProductResults.rsltSizePrd !== 1).length +
          +productWeight.filter((x) => +x.workOrderProductResults.rsltWPrd !== 1).length;
      }
      return result;
    },

    resultDescription(resultId) {
      if (!resultId) {
        return "";
      }
      const item = this.resultOptions.find((x) => x.id === +resultId);
      return item ? item.text : "";
    },

    IsTolerance(ev, av, tu, tl) {
      if (!ev || !av || !tu || !tl) {
        return null;
      }

      let expectedValue = parseFloat(ev);
      let actualValue = parseFloat(av);
      let toleranceUpper = parseFloat(tu);
      let toleranceLower = parseFloat(tl);

      let highTolerance = expectedValue * (toleranceUpper / 100) + expectedValue;
      let lowTolerance = expectedValue * (toleranceLower / 100) + expectedValue;

      if (actualValue <= highTolerance && actualValue >= lowTolerance) {
        return "1";
      }
      return "0";
    },

    populateData() {
      this.workOrder = result.data.data.map((x) => {
        x.sizeMeasurement = x.sizeMeasurement || {};
        return x;
      });
      this.productType = this.workOrderDetail.productType;

      this.productIDList = this.workOrderDetail.products.map((x) => {
        return {
          productID: x.productID,
        };
      });
      this.workOrderProducts = this.workOrderDetail.products.map((x) => {
        x.workOrderProductResults = x.workOrderProductResults || {};
        x.workOrderProductSizeMeasurementResults = x.workOrderProductSizeMeasurementResults || {};
        return x;
      });
    },

    async populateWorkorderList() {
      this.cartonSizeLoading = true;
      this.workOrderId = this.$route.params.id;
      const result = await this.utilityFn.getServerDataAsync("get", this.apiList.workOrderDetailsSize + "/" + this.workOrderId, {});
      if (result && result.data) {
        this.cartonSizeLoading = false;
        this.workOrderDetail = result.data;
        this.skuDisplayList = result.data.products.map((x) => {
          return {
            workOrderProductId: x.id,
            skuDisplay: x.skuDisplay,
            po: x.po,
            clientProductId: x.clientProductId,
          };
        });
        const sizeMeasurementObj = this.workOrderDetail.sizeMeasurement;
        this.savedProductWeightSamplingMethodology = this.workOrderDetail.sizeMeasurement?.productWeightSamplingMethodology;
        if (!sizeMeasurementObj) {
          this.initialLogging = true;
          this.updateworkOrderSizeMeasurementObj().then(() => {
            this.workorder = result.data;
            this.workorder.sizeMeasurement = this.workorder.sizeMeasurement || {};

            // this.workOrder = result.data.map((x) => {
            //   x.sizeMeasurement = x.sizeMeasurement || {};
            //   return x;
            // });
            this.productType = this.workOrderDetail.productType;
            this.productIDList = this.workOrderDetail.products.map((x) => {
              return {
                productID: x.productID,
              };
            });
            this.workOrderProducts = this.workOrderDetail.products.map((x) => {
              x.workOrderProductResults = x.workOrderProductResults || {};
              x.workOrderProductSizeMeasurementResults = x.workOrderProductSizeMeasurementResults || {};
              return x;
            });
            this.populate(this.workOrderDetail.sizeMeasurement,this.workOrderDetail.isVaryingAQL);
            this.initialLogging = false;
            this.loadExistingSizeMeasurementDetails();
          });
        } else {
          this.workorder = result.data;
          this.workorder.sizeMeasurement = this.workorder.sizeMeasurement || {};
          this.productType = this.workOrderDetail.productType;
          this.productIDList = this.workOrderDetail.products.map((x) => {
            return {
              productID: x.productID,
            };
          });
          this.workOrderProducts = this.workOrderDetail.products.map((x) => {
            x.workOrderProductResults = x.workOrderProductResults || {};
            x.workOrderProductSizeMeasurementResults = x.workOrderProductSizeMeasurementResults || {};
            return x;
          });
          this.populate(this.workOrderDetail.sizeMeasurement,this.workOrderDetail.isVaryingAQL);
          this.initialLogging = false;
          this.loadExistingSizeMeasurementDetails();
          var isRecompute = true;
          if (this.workOrderDetail.sizeMeasurement.recompute){
              isRecompute = false;
          }
          this.calculateSampleSize(this.workOrderSizeMeasurementObj.productSizeInspectionLevel, this.workOrderSizeMeasurementObj.productSizeSamplingMethodology, isRecompute);
          
        }
        this.onChangeUnitofMeasure(this.workOrderSizeMeasurementObj.cartonSizeUnitOfMeasureId,'cartonSize');
        this.onChangeUnitofMeasure(this.workOrderSizeMeasurementObj.productSizeUnitOfMeasureId,'productSize');
        this.onChangeUnitofMeasure(this.workOrderSizeMeasurementObj.productWeightUnitOfMeasureId ,'productWeight');
        this.$store.dispatch("inspection/setCriticalPOMList", this.workOrderDetail.workOrderProductPointOfMeasurements);
      }
     
    },

    // initialize insert in workOrderProductQuantityVerificationResults table

    loadExistingSizeMeasurementDetails() {
      let existingSizeMeasurementList = [];
      this.workOrderProducts.forEach((x) => {
        if (x.workOrderProductSizeMeasurementResults.length > 0) {
          x.workOrderProductSizeMeasurementResults.forEach((item) => {
            existingSizeMeasurementList.push(item);
          });
        }
      });
      if (existingSizeMeasurementList.length > 0) {
        this.loadWorkOrderSizeMeasurementDetails(existingSizeMeasurementList);
      }
    },

    loadWorkOrderSizeMeasurementDetails(workorderProductsizeMeasurementDetails) {
      this.cartonSizeMeasurementList = [];
      this.productSizeMeasurementList = [];
      if (workorderProductsizeMeasurementDetails) {
        if (workorderProductsizeMeasurementDetails.length > 0) {
          let sizeMeasurementList = [];
          let setCartonIndexInterate = 1;
          let setProductIndexInterate = 1;
          workorderProductsizeMeasurementDetails.forEach((res) => {
            let samplingPlanIndex = null;
            let productSkUDisplay = this.getSKUDisplay(res.workOrderProductId).skuDisplay;

            if (this.samplingPlan.SKUSamplingList != null) {
              samplingPlanIndex = this.samplingPlan.SKUSamplingList.filter((y) => y.SKUDisplay === productSkUDisplay);
            }

            let productAttachments = [];
            productAttachments = this.workOrderProducts.reduce((accumulator, currentValue) => {
              if (currentValue.id == res.workOrderProductId) {
                const Attachments = currentValue.workOrderProductAttachments;
                accumulator.push(Attachments);
              }
              return accumulator;
            }, [])[0];
            if (res.isCarton) {
              const addedCartonSizeMeasurement = {
                id: res.id,
                clientProductId: this.getSKUDisplay(res.workOrderProductId).clientProductId,
                skuDisplay: this.getSKUDisplay(res.workOrderProductId).skuDisplay,
                workOrderProductId: res.workOrderProductId,
                po: this.getSKUDisplay(res.workOrderProductId).po,
                isCarton: res.isCarton,
                specifiedLength: res.specifiedLength,
                specifiedWidth: res.specifiedWidth,
                specifiedHeight: res.specifiedHeight,
                actualLength: res.actualLength,
                actualWidth: res.actualWidth,
                actualHeight: res.actualHeight,
                dimensionalUnit: res.dimensionalUnit,
                dimensionalResult: res.dimensionalResult,
                specifiedWeight: res.specifiedWeight,
                actualWeight: res.actualWeight,
                weightUnit: res.weightUnit,
                weightResult: res.weightResult,
                sampleSize: res.sampleSize,
                orderNumber: res.orderNumber,
                acceptCritical: res.acceptCritical,
                acceptMajor: res.acceptMajor,
                acceptMinor: res.acceptMinor,
                foundCritical: res.foundCritical,
                foundMajor: res.foundMajor,
                foundMinor: res.foundMinor,
                rejectCritical: res.rejectCritical,
                rejectMajor: res.rejectMajor,
                rejectMinor: res.rejectMinor,
                szCritical: res.szCritical,
                szMajor: res.szMajor,
                szMinor: res.szMinor,
                workOrderProductAttachments: productAttachments,
                index: setCartonIndexInterate++,
              };

              if (this.cartonEditedIndex > -1) {
                Object.assign(this.cartonSizeMeasurementList[this.cartonEditedIndex], addedCartonSizeMeasurement);
              } else {
                this.cartonSizeMeasurementList.push(addedCartonSizeMeasurement);
              }
            } else {
              const addedProductSizeMeasurement = {
                id: res.id,
                clientProductId: this.getSKUDisplay(res.workOrderProductId).clientProductId,
                skuDisplay: this.getSKUDisplay(res.workOrderProductId).skuDisplay,
                po: this.getSKUDisplay(res.workOrderProductId).po,
                workOrderProductId: res.workOrderProductId,
                isCarton: res.isCarton,
                specifiedLength: res.specifiedLength,
                specifiedWidth: res.specifiedWidth,
                specifiedHeight: res.specifiedHeight,
                actualLength: res.actualLength,
                actualWidth: res.actualWidth,
                actualHeight: res.actualHeight,
                dimensionalUnit: res.dimensionalUnit,
                dimensionalResult: res.dimensionalResult,
                specifiedWeight: res.specifiedWeight,
                actualWeight: res.actualWeight,
                weightUnit: res.weightUnit,
                weightResult: res.weightResult,
                sampleSize: res.sampleSize,
                //sampleSize: res.sampleSize == null ? samplingPlanIndex[0].productSampleSize : res.sampleSize,
                orderNumber: res.orderNumber,
                acceptCritical: res.acceptCritical,
                acceptMajor: res.acceptMajor,
                acceptMinor: res.acceptMinor,
                foundCritical: res.foundCritical,
                foundMajor: res.foundMajor,
                foundMinor: res.foundMinor,
                rejectCritical: res.rejectCritical,
                rejectMajor: res.rejectMajor,
                rejectMinor: res.rejectMinor,
                szCritical: res.szCritical,
                szMajor: res.szMajor,
                szMinor: res.szMinor,
                workOrderProductAttachments: productAttachments,
                index: setProductIndexInterate++,
              };

              if (this.productEditedIndex > -1) {
                Object.assign(this.productSizeMeasurementList[this.productEditedIndex], addedProductSizeMeasurement);
              } else {
                this.productSizeMeasurementList.push(addedProductSizeMeasurement);
              }
            }
          });
          this.cartonEditedIndex = -1;
          this.productEditedIndex = -1;
        }
      }

      this.cloneCartonSizeMeasurementList = _.cloneDeep(this.cartonSizeMeasurementList);
      this.cloneProductSizeMeasurementList = _.cloneDeep(this.productSizeMeasurementList);
    },

    getSKUDisplay(productId) {
      if (!productId || productId == "" || productId == null) {
        return null;
      }

      let skuDisplayData = this.skuDisplayList.find((x) => x.workOrderProductId === productId);
      return skuDisplayData;
    },

    getUnitOfMeasurementsType(typeId) {
      if (!typeId || typeId == "" || typeId == undefined) {
        return null;
      }
      let unitOfMeasurementTypeDescription = this.unitOfMeasurementOptions.find((x) => x.id === typeId)?.description;
      return unitOfMeasurementTypeDescription;
    },

    getIdUnitOfMeasurementsType(description) {
      if (!description || description == "") {
        return null;
      }
      let unitOfMeasurementTypeId = this.unitOfMeasurementOptions.find((x) => x.description === description).id;
      return unitOfMeasurementTypeId;
    },

    getResultSize(l, aL, w, aW, h, aH, wT, aWT, tU, tL) {
      let lengthResult = this.IsTolerance(l, aL, tU, tL);
      let widthResult = this.IsTolerance(w, aW, tU, tL);
      let heightResult = this.IsTolerance(h, aH, tU, tL);
      let weightResult = this.IsTolerance(wT, aWT, tU, tL);

      if (lengthResult == null || widthResult == null || heightResult == null || weightResult == null) return null;

      if (lengthResult == "0" || widthResult == "0" || heightResult == "0" || weightResult == "0") return "0";

      if (lengthResult == "1" || widthResult == "1" || heightResult == "1" || weightResult == "1") return "1";
    },

    getResultProuctSizeWeight(l, aL, w, aW, h, aH, wt, aWt, tU, tL) {
      let lengthResult = this.IsTolerance(l, aL, tU, tL);
      let widthResult = this.IsTolerance(w, aW, tU, tL);
      let heightResult = this.IsTolerance(h, aH, tU, tL);
      let weightResult = this.IsTolerance(wt, aWt, tU, tL);

      if (lengthResult == null || widthResult == null || heightResult == null || weightResult == null) return null;

      if (lengthResult == "0" || widthResult == "0" || heightResult == "0" || weightResult == "0" ) return "0";

      if (lengthResult == "1" || widthResult == "1" || heightResult == "1" || weightResult == "1" ) return "1";
    },

    getResultProuctSize(l, aL, w, aW, h, aH, tU, tL) {
      let lengthResult = this.IsTolerance(l, aL, tU, tL);
      let widthResult = this.IsTolerance(w, aW, tU, tL);
      let heightResult = this.IsTolerance(h, aH, tU, tL);

      if (lengthResult == null || widthResult == null || heightResult == null) return null;

      if (lengthResult == "0" || widthResult == "0" || heightResult == "0") return "0";

      if (lengthResult == "1" || widthResult == "1" || heightResult == "1") return "1";
    },

    getResultAQL(foundCritical, rejectCritical, foundMajor, rejectMajor, foundMinor, rejectMinor) {
      foundCritical = foundCritical == "" ? null : foundCritical;
      foundMajor = foundMajor == "" ? null : foundMajor;
      foundMinor = foundMinor == "" ? null : foundMinor;

      if (foundCritical == null && foundMajor == null && foundMinor == null) return "1";

      if ((foundCritical && foundCritical >= rejectCritical) 
          || (foundMajor && foundMajor >= rejectMajor )
          || (foundMinor && foundMinor >= rejectMinor)) return "0";

      return "1";
    },
    async updateworkOrderSizeMeasurementObj() {
      this.workOrderSizeMeasurementObj.workOrderId = this.workOrderId;
      this.workOrderSizeMeasurementObj.cartonSizeUnitOfMeasureId = this.workOrderSizeMeasurementObj.cartonSizeUnitOfMeasureId == null ? null : this.workOrderSizeMeasurementObj.cartonSizeUnitOfMeasureId;
      this.workOrderSizeMeasurementObj.cartonWeightUnitOfMeasureId = this.workOrderSizeMeasurementObj.cartonWeightUnitOfMeasureId == null ? null : this.workOrderSizeMeasurementObj.cartonWeightUnitOfMeasureId;
      this.workOrderSizeMeasurementObj.productSizeUnitOfMeasureId = this.workOrderSizeMeasurementObj.productSizeUnitOfMeasureId == null ? null : this.workOrderSizeMeasurementObj.productSizeUnitOfMeasureId;
      this.workOrderSizeMeasurementObj.productWeightUnitOfMeasureId = this.workOrderSizeMeasurementObj.productWeightUnitOfMeasureId == null ? null : this.workOrderSizeMeasurementObj.productWeightUnitOfMeasureId;


      this.workOrderSizeMeasurementObj.cartonSizeInspectionLevel = this.workOrderSizeMeasurementObj.cartonSizeInspectionLevel == null ? null : this.workOrderSizeMeasurementObj.cartonSizeInspectionLevel;
      this.workOrderSizeMeasurementObj.cartonWeightInspectionLevel = this.workOrderSizeMeasurementObj.cartonWeightInspectionLevel == null ? this.workOrderDetail.aqlLevels : this.workOrderSizeMeasurementObj.cartonWeightInspectionLevel;
      this.workOrderSizeMeasurementObj.productSizeInspectionLevel = this.workOrderSizeMeasurementObj.productSizeInspectionLevel == null ? null : this.workOrderSizeMeasurementObj.productSizeInspectionLevel;
      this.workOrderSizeMeasurementObj.productWeightInspectionLevel = this.workOrderSizeMeasurementObj.productWeightInspectionLevel == null ? null : this.workOrderSizeMeasurementObj.productWeightInspectionLevel;

      this.workOrderSizeMeasurementObj.cartonSizeSamplingMethodology = this.workOrderSizeMeasurementObj.cartonSizeSamplingMethodology == null ? this.workOrderDetail.samplingMethodology : this.workOrderSizeMeasurementObj.cartonSizeSamplingMethodology;
      this.workOrderSizeMeasurementObj.cartonWeightSamplingMethodology =
      this.workOrderSizeMeasurementObj.cartonWeightSamplingMethodology == null ? this.workOrderDetail.samplingMethodology : this.workOrderSizeMeasurementObj.cartonWeightSamplingMethodology;

      this.workOrderSizeMeasurementObj.productSizeSamplingMethodology =this._samplingPlanLists.samplingMethodology ?  this._samplingPlanLists.samplingMethodology :   this.workOrderDetail.samplingMethodology ;
      this.workOrderSizeMeasurementObj.productWeightSamplingMethodology = this._samplingPlanLists.samplingMethodology ?  this._samplingPlanLists.samplingMethodology : this.workOrderDetail.samplingMethodology ;
      
      this.workOrderSizeMeasurementObj.cartonAqlCriticalID = this.workOrderSizeMeasurementObj.cartonAqlCriticalID == null ? null : this.workOrderSizeMeasurementObj.cartonAqlCriticalID;
      this.workOrderSizeMeasurementObj.cartonAqlMajorID = this.workOrderSizeMeasurementObj.cartonAqlMajorID == null ? null : this.workOrderSizeMeasurementObj.cartonAqlMajorID;
      this.workOrderSizeMeasurementObj.cartonAqlMinorID = this.workOrderSizeMeasurementObj.cartonAqlMinorID == null ? null : this.workOrderSizeMeasurementObj.cartonAqlMinorID;
      this.workOrderSizeMeasurementObj.cartonAqlOtherID = this.workOrderSizeMeasurementObj.cartonAqlOtherID == null ? null : this.workOrderSizeMeasurementObj.cartonAqlOtherID;

      this.workOrderSizeMeasurementObj.productAqlCriticalID = this.workOrderSizeMeasurementObj.productAqlCriticalID == null ? null : this.workOrderSizeMeasurementObj.productAqlCriticalID;
      this.workOrderSizeMeasurementObj.productAqlMajorID = this.workOrderSizeMeasurementObj.productAqlMajorID == null ? null : this.workOrderSizeMeasurementObj.productAqlMajorID;
      this.workOrderSizeMeasurementObj.productAqlMinorID = this.workOrderSizeMeasurementObj.productAqlMinorID == null ? null : this.workOrderSizeMeasurementObj.productAqlMinorID;
      this.workOrderSizeMeasurementObj.productAqlOtherID = this.workOrderSizeMeasurementObj.productAqlOtherID == null ? null : this.workOrderSizeMeasurementObj.productAqlOtherID;

      // this.workOrderSizeMeasurementObj.allowedCritical = this.workOrderSizeMeasurementObj.allowedCritical;
      // this.workOrderSizeMeasurementObj.allowedMajor = this.workOrderSizeMeasurementObj.allowedMajor;
      // this.workOrderSizeMeasurementObj.allowedMinor = this.workOrderSizeMeasurementObj.allowedMinor;

      // this.workOrderSizeMeasurementObj.rejectedCritical = this.workOrderSizeMeasurementObj.rejectedCritical;
      // this.workOrderSizeMeasurementObj.rejectedMajor = this.workOrderSizeMeasurementObj.rejectedMajor;
      // this.workOrderSizeMeasurementObj.rejectedMinor = this.workOrderSizeMeasurementObj.rejectedMinor;

      this.workOrderSizeMeasurementObj.productSZCritical = this.workOrderSizeMeasurementObj.productSZCritical == null ? null : this.workOrderSizeMeasurementObj.productSZCritical;
      this.workOrderSizeMeasurementObj.productSZMajor = this.workOrderSizeMeasurementObj.productSZMajor == null ? null : this.workOrderSizeMeasurementObj.productSZMajor;
      this.workOrderSizeMeasurementObj.productSZMinor = this.workOrderSizeMeasurementObj.productSZMinor == null ? null : this.workOrderSizeMeasurementObj.productSZMinor;

      this.workOrderSizeMeasurementObj.isVaryingAQL = this.workOrderSizeMeasurementObj.isVaryingAQL == null ? false : this.workOrderSizeMeasurementObj.isVaryingAQL;

      this.workOrderSizeMeasurementObj.cartonSizeResultId = this.workOrderSizeMeasurementObj.cartonSizeResultId == null ? null : this.workOrderSizeMeasurementObj.cartonSizeResultId;
      this.workOrderSizeMeasurementObj.cartonSizePendingCodeId = this.workOrderSizeMeasurementObj.cartonSizePendingCodeId == null ? null : this.workOrderSizeMeasurementObj.cartonSizePendingCodeId;
      this.workOrderSizeMeasurementObj.cartonSizeRemarks = this.workOrderSizeMeasurementObj.cartonSizeRemarks == null ? null : this.workOrderSizeMeasurementObj.cartonSizeRemarks;
      this.workOrderSizeMeasurementObj.cartonSizeResultCode = this.workOrderSizeMeasurementObj.cartonSizeResultCode == null ? null : this.workOrderSizeMeasurementObj.cartonSizeResultCode;

      this.workOrderSizeMeasurementObj.cartonWeightResultId = this.workOrderSizeMeasurementObj.cartonWeightResultId == null ? null : this.workOrderSizeMeasurementObj.cartonWeightResultId;
      this.workOrderSizeMeasurementObj.cartonWeightPendingCodeId = this.workOrderSizeMeasurementObj.cartonWeightPendingCodeId == null ? null : this.workOrderSizeMeasurementObj.cartonWeightPendingCodeId;
      this.workOrderSizeMeasurementObj.cartonWeightRemarks = this.workOrderSizeMeasurementObj.cartonWeightRemarks == null ? null : this.workOrderSizeMeasurementObj.cartonWeightRemarks;
      this.workOrderSizeMeasurementObj.cartonWeightResultCode = this.workOrderSizeMeasurementObj.cartonWeightResultCode == null ? null : this.workOrderSizeMeasurementObj.cartonWeightResultCode;

      this.workOrderSizeMeasurementObj.productSizeResultId = this.workOrderSizeMeasurementObj.productSizeResultId == null ? null : this.workOrderSizeMeasurementObj.productSizeResultId;
      this.workOrderSizeMeasurementObj.productSizePendingCodeId = this.workOrderSizeMeasurementObj.productSizePendingCodeId == null ? null : this.workOrderSizeMeasurementObj.productSizePendingCodeId;
      this.workOrderSizeMeasurementObj.productSizeRemarks = this.workOrderSizeMeasurementObj.productSizeRemarks == null ? null : this.workOrderSizeMeasurementObj.productSizeRemarks;
      this.workOrderSizeMeasurementObj.productSizeResultCode = this.workOrderSizeMeasurementObj.productSizeResultCode == null ? null : this.workOrderSizeMeasurementObj.productSizeResultCode;

      this.workOrderSizeMeasurementObj.productWeightResultId = this.workOrderSizeMeasurementObj.productWeightResultId == null ? null : this.workOrderSizeMeasurementObj.productWeightResultId;
      this.workOrderSizeMeasurementObj.productWeightPendingCodeId = this.workOrderSizeMeasurementObj.productWeightPendingCodeId == null ? null : this.workOrderSizeMeasurementObj.productWeightPendingCodeId;
      this.workOrderSizeMeasurementObj.productWeightRemarks = this.workOrderSizeMeasurementObj.productWeightRemarks == null ? null : this.workOrderSizeMeasurementObj.productWeightRemarks;
      
      // apply productSizeResultCode to productWeightResultCode 
      this.workOrderSizeMeasurementObj.productWeightResultCode = this.workOrderSizeMeasurementObj.productSizeResultCode == null ? null : this.workOrderSizeMeasurementObj.productSizeResultCode;


      this.workOrderSizeMeasurementObj.isPOM = this.workOrderSizeMeasurementObj.isPOM == null ? false : this.workOrderSizeMeasurementObj.isPOM;
      //size pom
      if (this.childWorkOrderSizeMeasurement && Object.keys(this.childWorkOrderSizeMeasurement).length !== 0) {
        this.workOrderSizeMeasurementObj.totalOfCritical= this.childWorkOrderSizeMeasurement.totalOfCritical? +this.childWorkOrderSizeMeasurement.totalOfCritical : 0;
        this.workOrderSizeMeasurementObj.totalNoOfSamplesMeasured = this.childWorkOrderSizeMeasurement.totalNoOfSamplesMeasured? +this.childWorkOrderSizeMeasurement.totalNoOfSamplesMeasured : 0;
        this.workOrderSizeMeasurementObj.totalFoundPomPlus = this.childWorkOrderSizeMeasurement.totalFoundPomPlus? +this.childWorkOrderSizeMeasurement.totalFoundPomPlus :0;
        this.workOrderSizeMeasurementObj.totalFoundPomMinus = this.childWorkOrderSizeMeasurement.totalFoundPomMinus? +this.childWorkOrderSizeMeasurement.totalFoundPomMinus : 0;
        this.workOrderSizeMeasurementObj.totalNoOfSampleOutOfPom = this.childWorkOrderSizeMeasurement.totalNoOfSampleOutOfPom;
        this.workOrderSizeMeasurementObj.percentageSampleOutOfPom = this.childWorkOrderSizeMeasurement.percentageSampleOutOfPom;
        this.workOrderSizeMeasurementObj.percentageFoundPomPlus = this.childWorkOrderSizeMeasurement.percentageFoundPomPlus;
        this.workOrderSizeMeasurementObj.percentageFoundPomMinus = this.childWorkOrderSizeMeasurement.percentageFoundPomMinus;
      }

      this.workOrderSizeMeasurementObj.recompute = false; 
  
      if (this._workOrderDetails.clientName?.toUpperCase() === "WALMART GLOBAL SOURCING" || this._workOrderDetails.clientName?.toUpperCase() === "ASDA STORES LIMITED"){
        this.workOrderSizeMeasurementObj.cartonSizeUnitOfMeasureId = this.workOrderSizeMeasurementObj.cartonSizeUnitOfMeasureId == null ? 18 : this.workOrderSizeMeasurementObj.cartonSizeUnitOfMeasureId;
        this.workOrderSizeMeasurementObj.productSizeUnitOfMeasureId = this.workOrderSizeMeasurementObj.productSizeUnitOfMeasureId == null ? 19 : this.workOrderSizeMeasurementObj.productSizeUnitOfMeasureId;
        this.onChangeUnitofMeasure(this.workOrderSizeMeasurementObj.cartonSizeUnitOfMeasureId,'cartonSize');
        this.onChangeUnitofMeasure(this.workOrderSizeMeasurementObj.productSizeUnitOfMeasureId,'productSize');
        var naId = this.aqlOptions.filter((x) => x.name == "N/A")[0].id;
        this.workOrderSizeMeasurementObj.cartonSizeInspectionLevel = this.workOrderSizeMeasurementObj.cartonSizeInspectionLevel == null ? this.inspectionLevelOptions.filter((x) => x.name == "Fixed")[0].name : this.workOrderSizeMeasurementObj.cartonSizeInspectionLevel;
        this.workOrderSizeMeasurementObj.cartonAqlCriticalID = this.workOrderSizeMeasurementObj.cartonAqlCriticalID == null ? naId : this.workOrderSizeMeasurementObj.cartonAqlCriticalID;
        this.workOrderSizeMeasurementObj.cartonAqlMajorID = this.workOrderSizeMeasurementObj.cartonAqlMajorID == null ? naId : this.workOrderSizeMeasurementObj.cartonAqlMajorID;
        this.workOrderSizeMeasurementObj.cartonAqlMinorID = this.workOrderSizeMeasurementObj.cartonAqlMinorID == null ? naId : this.workOrderSizeMeasurementObj.cartonAqlMinorID;
        this.workOrderSizeMeasurementObj.productWeightUnitOfMeasureId = this.workOrderSizeMeasurementObj.productWeightUnitOfMeasureId == null ? 4 : this.workOrderSizeMeasurementObj.productWeightUnitOfMeasureId;
        this.onChangeUnitofMeasure(this.workOrderSizeMeasurementObj.productWeightUnitOfMeasureId ,'productWeight');
      }


      
      await this.utilityFn.getServerDataAsync("post", this.apiList.upsertWorkOrderSizeMeasurement, this.workOrderSizeMeasurementObj);
      this.onChange();
    },

    getAQL(id) {
      if (!id || id == "") {
        return null;
      }
      let aqlName = this.aqlOptions.find((x) => x.id === id)?.name;
      return aqlName;
    },

    getIdAQL(name) {
      if (!name || name == "") {
        return null;
      }
      let aqlId = this.aqlOptions.find((x) => x.name === name)?.id;
      return aqlId;
    },

    updateAllSKUResultCartonSize() {
      if (this.workOrderSizeMeasurementObj.cartonSizeToleranceUpper == -1 && this.workOrderSizeMeasurementObj.cartonSizeToleranceLower == -1) {
        this.showToleranceDialogCartonSize = true;
      }
      //Comment this because the result is Editable, Auto compute on editing
      // this.workOrderProducts.forEach(element =>{
      //     element.workOrderProductResults.rsltSizeCrtn  = this.getResultSize(element.cartonLength,
      //         element.workOrderProductResults.lAvailableSizeCrtn,
      //         element.cartonWidth,
      //         element.workOrderProductResults.wAvailableSizeCrtn,
      //         element.cartonHeight,
      //         element.workOrderProductResults.hAvailableSizeCrtn,
      //         this.workOrderSizeMeasurementObj.cartonSizeToleranceUpper,
      //         this.workOrderSizeMeasurementObj.cartonSizeToleranceLower);
      // })

      this.onChange();
    },

    updateAllSKUResultCartonWeight() {
      if (this.workOrderSizeMeasurementObj.cartonWeightToleranceUpper == -1 && this.workOrderSizeMeasurementObj.cartonWeightToleranceLower == -1) {
        this.showToleranceDialogCartonWeight = true;
      }
      //Comment this because the result is Editable, Auto compute on editing
      // this.workOrderProducts.forEach(element =>{
      //     element.workOrderProductResults.rsltWCrtn =this.IsTolerance(element.cartonWeight,
      //         element.workOrderProductResults.actualWCrtn,
      //         this.workOrderSizeMeasurementObj.cartonWeightToleranceUpper,
      //         this.workOrderSizeMeasurementObj.cartonWeightToleranceLower);
      // })

      this.onChange();
    },

    updateAllSKUResultProductSize() {
      if (this.workOrderSizeMeasurementObj.productSizeToleranceLower == -1 && this.workOrderSizeMeasurementObj.productSizeToleranceUpper == -1) {
        this.showToleranceDialogProductSize = true;
      }
      //Comment this because the result is Editable, Auto compute on editing
      // this.workOrderProducts.forEach(element =>{
      //     element.workOrderProductResults.rsltSizePrd  = this.getResultSize(element.length,
      //         element.workOrderProductResults.lAvailableSizePrd,
      //         element.width,
      //         element.workOrderProductResults.wAvailableSizePrd,
      //         element.height,
      //         element.workOrderProductResults.hAvailableSizePrd,
      //         this.workOrderSizeMeasurementObj.productSizeToleranceUpper,
      //         this.workOrderSizeMeasurementObj.productSizeToleranceLower);
      // })

      this.onChange();
    },

    updateAllSKUResultProductWeight() {
      if (this.workOrderSizeMeasurementObj.productWeightToleranceUpper == -1 && this.workOrderSizeMeasurementObj.productWeightToleranceLower == -1) {
        this.showToleranceDialogProductWeight = true;
      }
      //Comment this because the result is Editable, Auto compute on editing
      // this.workOrderProducts.forEach(element =>{
      //     element.workOrderProductResults.rsltWPrd =this.IsTolerance(element.weight,
      //         element.workOrderProductResults.actWPrd,
      //         this.workOrderSizeMeasurementObj.productWeightToleranceUpper,
      //         this.workOrderSizeMeasurementObj.productWeightToleranceLower);
      // })

      this.onChange();
    },

    saveAllSKUResult() {
      this.workOrderProducts.forEach((element) => {
        const formPatch = [];
        if (element.workOrderProductResults.rsltSizeCrtn) {
          formPatch.push({
            op: "replace",
            path: "/rsltSizeCrtn",
            value: element.workOrderProductResults.rsltSizeCrtn,
          });
        }
        if (element.workOrderProductResults.rsltWCrtn) {
          formPatch.push({
            op: "replace",
            path: "/rsltWCrtn",
            value: element.workOrderProductResults.rsltWCrtn,
          });
        }
        if (element.workOrderProductResults.rsltSizePrd) {
          formPatch.push({
            op: "replace",
            path: "/rsltSizePrd",
            value: element.workOrderProductResults.rsltSizePrd,
          });
        }
        if (element.workOrderProductResults.rsltWPrd) {
          formPatch.push({
            op: "replace",
            path: "/rsltWPrd",
            value: element.workOrderProductResults.rsltWPrd,
          });
        }

        this.onChange();

        this.utilityFn.getServerData("patch", this.apiList.upsertProductResult + "/" + element.workOrderProductResults.workOrderProductID + "/2", formPatch, (result) => {});
      });
    },

    //API Call Methods,cartonSize popup
    saveCartonSize(type) {
      this.onChange();

      if (this.cartonSizeEditedItem.actualLength == undefined || this.cartonSizeEditedItem.actualLength === "") {
        this.snackbar = true;
        this.message = "Actual L is required";
        return;
      }
      if (this.cartonSizeEditedItem.actualWidth == undefined || this.cartonSizeEditedItem.actualWidth === "") {
        this.snackbar = true;
        this.message = "Actual W is required";
        return;
      }
      if (this.cartonSizeEditedItem.actualHeight == undefined || this.cartonSizeEditedItem.actualHeight === "") {
        this.snackbar = true;
        this.message = "Actual H is required";
        return;
      }

      let workOrderProductSizeMeasurementResultObjList = [];

      const addedCartonSizeMeasurement = {
        id: this.cartonSizeEditedItem.id,
        workOrderProductId: this.cartonSizeEditedItem.workOrderProductId,
        isCarton: true,
        specifiedLength: this.cartonSizeEditedItem.specifiedLength,
        specifiedWidth: this.cartonSizeEditedItem.specifiedWidth,
        specifiedHeight: this.cartonSizeEditedItem.specifiedHeight,
        actualLength: this.cartonSizeEditedItem.actualLength,
        actualWidth: this.cartonSizeEditedItem.actualWidth,
        actualHeight: this.cartonSizeEditedItem.actualHeight,
        dimensionalUnit: this.cartonSizeEditedItem.dimensionalUnit == null ? null : this.cartonSizeEditedItem.dimensionalUnit,
        dimensionalResult: this.cartonSizeEditedItem.dimensionalResult,
        specifiedWeight: this.cartonSizeEditedItem.specifiedWeight,
        actualWeight: this.cartonSizeEditedItem.actualWeight,
        weightUnit: this.cartonSizeEditedItem.weightUnit == null ? null : this.cartonSizeEditedItem.weightUnit,
        weightResult: this.cartonSizeEditedItem.weightResult,
        sampleSize: this.cartonSizeEditedItem.sampleSize,
        orderNumber: this.cartonSizeEditedItem.orderNumber,
        acceptCritical: this.cartonSizeEditedItem.acceptCritical,
        acceptMajor: this.cartonSizeEditedItem.acceptMajor,
        acceptMinor: this.cartonSizeEditedItem.acceptMinor,
        foundCritical: this.cartonSizeEditedItem.foundCritical,
        foundMajor: this.cartonSizeEditedItem.foundMajor,
        foundMinor: this.cartonSizeEditedItem.foundMinor,
        rejectCritical: this.cartonSizeEditedItem.rejectCritical,
        rejectMajor: this.cartonSizeEditedItem.rejectMajor,
        rejectMinor: this.cartonSizeEditedItem.rejectMinor,
        szCritical: this.cartonSizeEditedItem.szCritical,
        szMajor: this.cartonSizeEditedItem.szMajor,
        szMinor: this.cartonSizeEditedItem.szMinor,
        sectionId: 2,
      };
      workOrderProductSizeMeasurementResultObjList.push(addedCartonSizeMeasurement);

      this.buttonDisable = true;
      if (workOrderProductSizeMeasurementResultObjList.length > 0) {
        this.utilityFn.getServerData("post", this.apiList.upsertWorkOrderProductSizeMeasurementResult, workOrderProductSizeMeasurementResultObjList, (res) => {
          this.loadWorkOrderSizeMeasurementDetails(res.data);
          this.calculateSizeMeasurementResult("cartonsize");
          this.close();
        },(errRes) => {
            this.loadWorkOrderSizeMeasurementDetails(workOrderProductSizeMeasurementResultObjList);
            this.calculateSizeMeasurementResult("cartonsize");
            this.close();
            if(window.navigator.onLine != true){
             this.message = this.msgList.NoConnection;
             this.snackbar = true;
            }
        });
      }

      // if(cartonSizeEditedItem.result !=null){

      // } else {
      // this.cartonSizeEditedItem.result = this.getResultSize(this.cartonSizeEditedItem.length,
      //     this.cartonSizeEditedItem.actualLength,
      //     this.cartonSizeEditedItem.width,
      //     this.cartonSizeEditedItem.actualWidth,
      //     this.cartonSizeEditedItem.height,
      //     this.cartonSizeEditedItem.actualHeight,
      //     this.workOrderSizeMeasurementObj.cartonSizeToleranceUpper,
      //     this.workOrderSizeMeasurementObj.cartonSizeToleranceLower
      // );
      // }

      // this.utilityFn.getServerData('patch', this.apiList.upsertProductResult + '/' + this.workOrderProductID + '/2', formPatch, result => {
      //     this.populateWorkorderList();
      //     this.close();
      // });
      // this.apiFn.upsertProductResult( this.workOrderProductID, formPatch).then(result => {
      //     this.populateWorkorderList();
      //     this.close();
      //     });
    },

    saveCartonWeight() {
      this.onChange();

      if (this.cartonWeightEditedItem.actualWeight == undefined || this.cartonWeightEditedItem.actualWeight === "") {
        this.snackbar = true;
        this.message = "Measure is required";
        return;
      }

      // this.cartonWeightEditedItem.result = this.IsTolerance(this.cartonWeightEditedItem.weight,
      //     this.cartonWeightEditedItem.actualWeight,
      //     this.workOrderSizeMeasurementObj.cartonWeightToleranceUpper,
      //     this.workOrderSizeMeasurementObj.cartonWeightToleranceLower);

      let workOrderProductSizeMeasurementResultObjList = [];

      const addedCartonWeightMeasurement = {
        id: this.cartonWeightEditedItem.id,
        workOrderProductId: this.cartonWeightEditedItem.workOrderProductId,
        isCarton: true,
        specifiedLength: this.cartonWeightEditedItem.specifiedLength,
        specifiedWidth: this.cartonWeightEditedItem.specifiedWidth,
        specifiedHeight: this.cartonWeightEditedItem.specifiedHeight,
        actualLength: this.cartonWeightEditedItem.actualLength,
        actualWidth: this.cartonWeightEditedItem.actualWidth,
        actualHeight: this.cartonWeightEditedItem.actualHeight,
        dimensionalUnit: this.cartonWeightEditedItem.dimensionalUnit == null ? null : this.cartonWeightEditedItem.dimensionalUnit,
        dimensionalResult: this.cartonWeightEditedItem.dimensionalResult,
        specifiedWeight: this.cartonWeightEditedItem.specifiedWeight,
        actualWeight: this.cartonWeightEditedItem.actualWeight,
        weightUnit: this.cartonWeightEditedItem.weightUnit == null ? null : this.cartonWeightEditedItem.weightUnit,
        weightResult: this.cartonWeightEditedItem.weightResult,
        sampleSize: this.cartonWeightEditedItem.sampleSize,
        orderNumber: this.cartonWeightEditedItem.orderNumber,
        acceptCritical: this.cartonWeightEditedItem.acceptCritical,
        acceptMajor: this.cartonWeightEditedItem.acceptMajor,
        acceptMinor: this.cartonWeightEditedItem.acceptMinor,
        foundCritical: this.cartonWeightEditedItem.foundCritical,
        foundMajor: this.cartonWeightEditedItem.foundMajor,
        foundMinor: this.cartonWeightEditedItem.foundMinor,
        rejectCritical: this.cartonWeightEditedItem.rejectCritical,
        rejectMajor: this.cartonWeightEditedItem.rejectMajor,
        rejectMinor: this.cartonWeightEditedItem.rejectMinor,
        szCritical: this.cartonWeightEditedItem.szCritical,
        szMajor: this.cartonWeightEditedItem.szMajor,
        szMinor: this.cartonWeightEditedItem.szMinor,

        sectionId: 2,
      };
      workOrderProductSizeMeasurementResultObjList.push(addedCartonWeightMeasurement);
      this.buttonDisable = true;
      if (workOrderProductSizeMeasurementResultObjList.length > 0) {
        this.utilityFn.getServerData("post", this.apiList.upsertWorkOrderProductSizeMeasurementResult, workOrderProductSizeMeasurementResultObjList, (res) => {
          this.loadWorkOrderSizeMeasurementDetails(res.data);
          this.calculateSizeMeasurementResult("cartonweight");
          this.close();
        },(errRes)=> {
          this.loadWorkOrderSizeMeasurementDetails(workOrderProductSizeMeasurementResultObjList);
          this.calculateSizeMeasurementResult("cartonweight");
          this.close();
          if(window.navigator.onLine != true){
             this.message = this.msgList.NoConnection;
             this.snackbar = true;
          }
        });
      }
    },

    saveProductSize() {
      this.onChange();

      if (this.productSizeEditedItem.actualLength == undefined || this.productSizeEditedItem.actualLength === "") {
        this.snackbar = true;
        this.message = "Actual L is required";
        return;
      }
      if (this.productSizeEditedItem.actualWidth == undefined || this.productSizeEditedItem.actualWidth === "") {
        this.snackbar = true;
        this.message = "Actual W is required";
        return;
      }
      if (this.productSizeEditedItem.actualHeight == undefined || this.productSizeEditedItem.actualHeight === "") {
        this.snackbar = true;
        this.message = "Actual H is required";
        return;
      }

      // this.productSizeEditedItem.result = this.getResultSize(this.productSizeEditedItem.length,
      //     this.productSizeEditedItem.actualLength,
      //     this.productSizeEditedItem.width,
      //     this.productSizeEditedItem.actualWidth,
      //     this.productSizeEditedItem.height,
      //     this.productSizeEditedItem.actualHeight,
      //     this.workOrderSizeMeasurementObj.productSizeToleranceUpper,
      //     this.workOrderSizeMeasurementObj.productSizeToleranceLower
      // );

      let workOrderProductSizeMeasurementResultObjList = [];

      const addedProductSizeMeasurement = {
        id: this.productSizeEditedItem.id,
        workOrderProductId: this.productSizeEditedItem.workOrderProductId,
        isCarton: false,
        specifiedLength: this.productSizeEditedItem.specifiedLength,
        specifiedWidth: this.productSizeEditedItem.specifiedWidth,
        specifiedHeight: this.productSizeEditedItem.specifiedHeight,
        actualLength: this.productSizeEditedItem.actualLength,
        actualWidth: this.productSizeEditedItem.actualWidth,
        actualHeight: this.productSizeEditedItem.actualHeight,
        dimensionalUnit: this.productSizeEditedItem.dimensionalUnit == null ? null : this.productSizeEditedItem.dimensionalUnit,
        dimensionalResult: this.productSizeEditedItem.dimensionalResult,
        specifiedWeight: this.productSizeEditedItem.specifiedWeight,
        actualWeight: this.productSizeEditedItem.actualWeight,
        weightUnit: this.productSizeEditedItem.weightUnit == null ? null : this.productSizeEditedItem.weightUnit,
        weightResult: this.productSizeEditedItem.weightResult,
        sampleSize: this.productSizeEditedItem.sampleSize,
        orderNumber: this.productSizeEditedItem.orderNumber,
        acceptCritical: this.productSizeEditedItem.acceptCritical,
        acceptMajor: this.productSizeEditedItem.acceptMajor,
        acceptMinor: this.productSizeEditedItem.acceptMinor,
        foundCritical: this.productSizeEditedItem.foundCritical,
        foundMajor: this.productSizeEditedItem.foundMajor,
        foundMinor: this.productSizeEditedItem.foundMinor,
        rejectCritical: this.productSizeEditedItem.rejectCritical,
        rejectMajor: this.productSizeEditedItem.rejectMajor,
        rejectMinor: this.productSizeEditedItem.rejectMinor,
        szCritical: this.productSizeEditedItem.szCritical,
        szMajor: this.productSizeEditedItem.szMajor,
        szMinor: this.productSizeEditedItem.szMinor,
        sectionId: 2,
      };
      workOrderProductSizeMeasurementResultObjList.push(addedProductSizeMeasurement);
      this.buttonDisable = true;
      if (workOrderProductSizeMeasurementResultObjList.length > 0) {
        this.utilityFn.getServerData("post", this.apiList.upsertWorkOrderProductSizeMeasurementResult, workOrderProductSizeMeasurementResultObjList, (res) => {
          this.loadWorkOrderSizeMeasurementDetails(res.data);
          this.calculateSizeMeasurementResult("productsize");
          this.close();
        },(errRes)=> {
          this.loadWorkOrderSizeMeasurementDetails(workOrderProductSizeMeasurementResultObjList);
          this.calculateSizeMeasurementResult("productsize");
          this.close();
          if(window.navigator.onLine != true){
             this.message = this.msgList.NoConnection;
             this.snackbar = true;
          }
        });
      }
    },

    saveProductWeight() {
      this.onChange();

      if (this.productWeightEditedItem.actualWeight == undefined || this.productWeightEditedItem.actualWeight === "") {
        this.snackbar = true;
        this.message = "Measure is required";
        return;
      }

      // this.productWeightEditedItem.result = this.IsTolerance(this.productWeightEditedItem.weight,
      //     this.productWeightEditedItem.actualWeight,
      //     this.workOrderSizeMeasurementObj.productWeightToleranceUpper,
      //     this.workOrderSizeMeasurementObj.productWeightToleranceLower);

      let workOrderProductSizeMeasurementResultObjList = [];

      const addedProductWeightMeasurement = {
        id: this.productWeightEditedItem.id,
        workOrderProductId: this.productWeightEditedItem.workOrderProductId,
        isCarton: false,
        specifiedLength: this.productWeightEditedItem.specifiedLength,
        specifiedWidth: this.productWeightEditedItem.specifiedWidth,
        specifiedHeight: this.productWeightEditedItem.specifiedHeight,
        actualLength: this.productWeightEditedItem.actualLength,
        actualWidth: this.productWeightEditedItem.actualWidth,
        actualHeight: this.productWeightEditedItem.actualHeight,
        dimensionalUnit: this.productWeightEditedItem.dimensionalUnit == null ? null : this.productWeightEditedItem.dimensionalUnit,
        dimensionalResult: this.productWeightEditedItem.dimensionalResult,
        specifiedWeight: this.productWeightEditedItem.specifiedWeight,
        actualWeight: this.productWeightEditedItem.actualWeight,
        weightUnit: this.productWeightEditedItem.weightUnit == null ? null : this.productWeightEditedItem.weightUnit,
        weightResult: this.productWeightEditedItem.weightResult,
        sampleSize: this.productWeightEditedItem.sampleSize,
        orderNumber: this.productWeightEditedItem.orderNumber,
        acceptCritical: this.productWeightEditedItem.acceptCritical,
        acceptMajor: this.productWeightEditedItem.acceptMajor,
        acceptMinor: this.productWeightEditedItem.acceptMinor,
        foundCritical: this.productWeightEditedItem.foundCritical,
        foundMajor: this.productWeightEditedItem.foundMajor,
        foundMinor: this.productWeightEditedItem.foundMinor,
        rejectCritical: this.productWeightEditedItem.rejectCritical,
        rejectMajor: this.productWeightEditedItem.rejectMajor,
        rejectMinor: this.productWeightEditedItem.rejectMinor,
        szCritical: this.productWeightEditedItem.szCritical,
        szMajor: this.productWeightEditedItem.szMajor,
        szMinor: this.productWeightEditedItem.szMinor,
        sectionId: 2,
      };
      workOrderProductSizeMeasurementResultObjList.push(addedProductWeightMeasurement);
      this.buttonDisable = true;
      if (workOrderProductSizeMeasurementResultObjList.length > 0) {
        this.utilityFn.getServerData("post", this.apiList.upsertWorkOrderProductSizeMeasurementResult, workOrderProductSizeMeasurementResultObjList, (res) => {
          this.loadWorkOrderSizeMeasurementDetails(res.data);
          this.calculateSizeMeasurementResult("productweight");
          this.close();
        },(errRes)=> {
          this.loadWorkOrderSizeMeasurementDetails(workOrderProductSizeMeasurementResultObjList);
          this.calculateSizeMeasurementResult("productweight");
          this.close();
          if(window.navigator.onLine != true){
             this.message = this.msgList.NoConnection;
             this.snackbar = true;
          }
        });
      }
      // this.apiFn.upsertProductResult( this.workOrderProductID, formPatch).then(result => {
      // this.populateWorkorderList();
      // this.close();
      // });
    },
    go_add_defect() {
      this.$router.push({ name: "workOrderDefectId", params: { id: this.$route.params.id } });
    },
    close() {
      this.cartonSizeDialog = false;
      this.cartonWeightDialog = false;
      this.productSizeDialog = false;
      this.productWeightDialog = false;
      this.deleteDialog = false;
      this.buttonDisable = false;
      this.showDefectFormDialog = false;
    },
    populate(workOrderSizeMeasureDetails,isVaryingAQL) {
      if (!workOrderSizeMeasureDetails) return;
      //inspectionLevel
      this.workOrderSizeMeasurementObj.cartonSizeInspectionLevel = workOrderSizeMeasureDetails.cartonSizeInspectionLevel;
      this.workOrderSizeMeasurementObj.cartonWeightInspectionLevel = workOrderSizeMeasureDetails.cartonWeightInspectionLevel;
      this.workOrderSizeMeasurementObj.productSizeInspectionLevel = workOrderSizeMeasureDetails.productSizeInspectionLevel;
      this.workOrderSizeMeasurementObj.productWeightInspectionLevel = workOrderSizeMeasureDetails.productWeightInspectionLevel;
      //sampleSize
      this.workOrderSizeMeasurementObj.cartonSizeSampleSize = workOrderSizeMeasureDetails.cartonSizeSampleSize == null ? null : workOrderSizeMeasureDetails.cartonSizeSampleSize;
      this.workOrderSizeMeasurementObj.cartonWeightSampleSize = workOrderSizeMeasureDetails.cartonWeightSampleSize == null ? null : workOrderSizeMeasureDetails.cartonWeightSampleSize;
      this.workOrderSizeMeasurementObj.productSizeSampleSize = workOrderSizeMeasureDetails.productSizeSampleSize == null ? null : workOrderSizeMeasureDetails.productSizeSampleSize;
      this.workOrderSizeMeasurementObj.productWeightSampleSize = workOrderSizeMeasureDetails.productWeightSampleSize == null ? null : workOrderSizeMeasureDetails.productWeightSampleSize;
      //toleranceUpper
      this.workOrderSizeMeasurementObj.cartonSizeToleranceUpper = workOrderSizeMeasureDetails.cartonSizeToleranceUpper == null ? 5 : workOrderSizeMeasureDetails.cartonSizeToleranceUpper;
      this.workOrderSizeMeasurementObj.productSizeToleranceUpper = workOrderSizeMeasureDetails.productSizeToleranceUpper == null ? 5 : workOrderSizeMeasureDetails.productSizeToleranceUpper;
      this.workOrderSizeMeasurementObj.cartonWeightToleranceUpper = workOrderSizeMeasureDetails.cartonWeightToleranceUpper == null ? 5 : workOrderSizeMeasureDetails.cartonWeightToleranceUpper;
      this.workOrderSizeMeasurementObj.productWeightToleranceUpper = workOrderSizeMeasureDetails.productWeightToleranceUpper == null ? 5 : workOrderSizeMeasureDetails.productWeightToleranceUpper;
      //toleranceLower
      this.workOrderSizeMeasurementObj.cartonSizeToleranceLower = workOrderSizeMeasureDetails.cartonSizeToleranceLower == null ? -5 : workOrderSizeMeasureDetails.cartonSizeToleranceLower;
      this.workOrderSizeMeasurementObj.cartonWeightToleranceLower = workOrderSizeMeasureDetails.cartonWeightToleranceLower == null ? -5 : workOrderSizeMeasureDetails.cartonWeightToleranceLower;
      this.workOrderSizeMeasurementObj.productSizeToleranceLower = workOrderSizeMeasureDetails.productSizeToleranceLower == null ? -5 : workOrderSizeMeasureDetails.productSizeToleranceLower;
      this.workOrderSizeMeasurementObj.productWeightToleranceLower = workOrderSizeMeasureDetails.productWeightToleranceLower == null ? -5 : workOrderSizeMeasureDetails.productWeightToleranceLower;
      //unit
      this.workOrderSizeMeasurementObj.cartonSizeUnitOfMeasureId = workOrderSizeMeasureDetails.cartonSizeUnitOfMeasureId == null ? null : workOrderSizeMeasureDetails.cartonSizeUnitOfMeasureId;
      this.workOrderSizeMeasurementObj.cartonWeightUnitOfMeasureId = workOrderSizeMeasureDetails.cartonWeightUnitOfMeasureId == null ? null : workOrderSizeMeasureDetails.cartonWeightUnitOfMeasureId;
      this.workOrderSizeMeasurementObj.productSizeUnitOfMeasureId = workOrderSizeMeasureDetails.productSizeUnitOfMeasureId == null ? null : workOrderSizeMeasureDetails.productSizeUnitOfMeasureId;
      this.workOrderSizeMeasurementObj.productWeightUnitOfMeasureId = workOrderSizeMeasureDetails.productWeightUnitOfMeasureId == null ? null : workOrderSizeMeasureDetails.productWeightUnitOfMeasureId;
      //sampling Methodology

      this.workOrderSizeMeasurementObj.cartonSizeSamplingMethodology = workOrderSizeMeasureDetails.cartonSizeSamplingMethodology == null ? this.workorder.samplingMethodology : workOrderSizeMeasureDetails.cartonSizeSamplingMethodology;
      this.workOrderSizeMeasurementObj.cartonWeightSamplingMethodology = workOrderSizeMeasureDetails.cartonWeightSamplingMethodology == null ? this.workorder.samplingMethodology : workOrderSizeMeasureDetails.cartonWeightSamplingMethodology;
      // this.workOrderSizeMeasurementObj.productSizeSamplingMethodology = workOrderSizeMeasureDetails.productSizeSamplingMethodology == null ? this.workorder.samplingMethodology : workOrderSizeMeasureDetails.productSizeSamplingMethodology;
      // this.workOrderSizeMeasurementObj.productWeightSamplingMethodology = workOrderSizeMeasureDetails.productWeightSamplingMethodology == null ? this.workorder.samplingMethodology : workOrderSizeMeasureDetails.productWeightSamplingMethodology;
      this.workOrderSizeMeasurementObj.productSizeSamplingMethodology =   this._samplingPlanLists.samplingMethodology ?  this._samplingPlanLists.samplingMethodology :  this.workorder.samplingMethodology ;
      this.workOrderSizeMeasurementObj.productWeightSamplingMethodology = this._samplingPlanLists.samplingMethodology ?  this._samplingPlanLists.samplingMethodology :  this.workorder.samplingMethodology ;


      //aqlLevels
      this.workOrderSizeMeasurementObj.cartonAqlCriticalID = workOrderSizeMeasureDetails.cartonAqlCriticalID == null ? null : workOrderSizeMeasureDetails.cartonAqlCriticalID;
      this.workOrderSizeMeasurementObj.cartonAqlMajorID = workOrderSizeMeasureDetails.cartonAqlMajorID == null ? null : workOrderSizeMeasureDetails.cartonAqlMajorID;
      this.workOrderSizeMeasurementObj.cartonAqlMinorID = workOrderSizeMeasureDetails.cartonAqlMinorID == null ? null : workOrderSizeMeasureDetails.cartonAqlMinorID;
      this.workOrderSizeMeasurementObj.cartonAqlOtherID = workOrderSizeMeasureDetails.cartonAqlOtherID == null ? null : workOrderSizeMeasureDetails.cartonAqlOtherID;

      this.workOrderSizeMeasurementObj.productAqlCriticalID = workOrderSizeMeasureDetails.productAqlCriticalID == null ? null : workOrderSizeMeasureDetails.productAqlCriticalID;
      this.workOrderSizeMeasurementObj.productAqlMajorID = workOrderSizeMeasureDetails.productAqlMajorID == null ? null : workOrderSizeMeasureDetails.productAqlMajorID;
      this.workOrderSizeMeasurementObj.productAqlMinorID = workOrderSizeMeasureDetails.productAqlMinorID == null ? null : workOrderSizeMeasureDetails.productAqlMinorID;
      this.workOrderSizeMeasurementObj.productAqlOtherID = workOrderSizeMeasureDetails.productAqlOtherID == null ? null : workOrderSizeMeasureDetails.productAqlOtherID;

      this.workOrderSizeMeasurementObj.productSZCritical = workOrderSizeMeasureDetails.productSZCritical == null ? null : workOrderSizeMeasureDetails.productSZCritical;
      this.workOrderSizeMeasurementObj.productSZMajor = workOrderSizeMeasureDetails.productSZMajor == null ? null : workOrderSizeMeasureDetails.productSZMajor;
      this.workOrderSizeMeasurementObj.productSZMinor = workOrderSizeMeasureDetails.productSZMinor == null ? null : workOrderSizeMeasureDetails.productSZMinor;

      this.workOrderSizeMeasurementObj.isVaryingAQL = workOrderSizeMeasureDetails.isVaryingAQL == null ? isVaryingAQL : workOrderSizeMeasureDetails.isVaryingAQL;

      this.workOrderSizeMeasurementObj.cartonSizeResultId = workOrderSizeMeasureDetails.cartonSizeResultId == null ? null : workOrderSizeMeasureDetails.cartonSizeResultId;
      this.workOrderSizeMeasurementObj.cartonSizePendingCodeId = workOrderSizeMeasureDetails.cartonSizePendingCodeId == null ? null : workOrderSizeMeasureDetails.cartonSizePendingCodeId;
      this.workOrderSizeMeasurementObj.cartonSizeRemarks = workOrderSizeMeasureDetails.cartonSizeRemarks == null ? null : workOrderSizeMeasureDetails.cartonSizeRemarks;
      this.workOrderSizeMeasurementObj.cartonSizeResultCode = workOrderSizeMeasureDetails.cartonSizeResultCode == null ? null : workOrderSizeMeasureDetails.cartonSizeResultCode;

      this.workOrderSizeMeasurementObj.cartonWeightResultId = workOrderSizeMeasureDetails.cartonWeightResultId == null ? null : workOrderSizeMeasureDetails.cartonWeightResultId;
      this.workOrderSizeMeasurementObj.cartonWeightPendingCodeId = workOrderSizeMeasureDetails.cartonWeightPendingCodeId == null ? null : workOrderSizeMeasureDetails.cartonWeightPendingCodeId;
      this.workOrderSizeMeasurementObj.cartonWeightRemarks = workOrderSizeMeasureDetails.cartonWeightRemarks == null ? null : workOrderSizeMeasureDetails.cartonWeightRemarks;
      this.workOrderSizeMeasurementObj.cartonWeightResultCode = workOrderSizeMeasureDetails.cartonWeightResultCode == null ? null : workOrderSizeMeasureDetails.cartonWeightResultCode;

      this.workOrderSizeMeasurementObj.productSizeResultId = workOrderSizeMeasureDetails.productSizeResultId == null ? null : workOrderSizeMeasureDetails.productSizeResultId;
      this.workOrderSizeMeasurementObj.productSizePendingCodeId = workOrderSizeMeasureDetails.productSizePendingCodeId == null ? null : workOrderSizeMeasureDetails.productSizePendingCodeId;
      this.workOrderSizeMeasurementObj.productSizeRemarks = workOrderSizeMeasureDetails.productSizeRemarks == null ? null : workOrderSizeMeasureDetails.productSizeRemarks;
      this.workOrderSizeMeasurementObj.productSizeResultCode = workOrderSizeMeasureDetails.productSizeResultCode == null ? null : workOrderSizeMeasureDetails.productSizeResultCode;

      this.workOrderSizeMeasurementObj.productWeightResultId = workOrderSizeMeasureDetails.productWeightResultId == null ? null : workOrderSizeMeasureDetails.productWeightResultId;
      this.workOrderSizeMeasurementObj.productWeightPendingCodeId = workOrderSizeMeasureDetails.productWeightPendingCodeId == null ? null : workOrderSizeMeasureDetails.productWeightPendingCodeId;
      this.workOrderSizeMeasurementObj.productWeightRemarks = workOrderSizeMeasureDetails.productWeightRemarks == null ? null : workOrderSizeMeasureDetails.productWeightRemarks;
      this.workOrderSizeMeasurementObj.productWeightResultCode = workOrderSizeMeasureDetails.productWeightResultCode == null ? null : workOrderSizeMeasureDetails.productWeightResultCode;

      //size pom
      if (this.childWorkOrderSizeMeasurement && Object.keys(this.childWorkOrderSizeMeasurement).length !== 0) {
        this.workOrderSizeMeasurementObj.totalOfCritical= this.childWorkOrderSizeMeasurement.totalOfCritical? +this.childWorkOrderSizeMeasurement.totalOfCritical : 0;
        this.workOrderSizeMeasurementObj.totalNoOfSamplesMeasured = this.childWorkOrderSizeMeasurement.totalNoOfSamplesMeasured? +this.childWorkOrderSizeMeasurement.totalNoOfSamplesMeasured : 0;
        this.workOrderSizeMeasurementObj.totalFoundPomPlus = this.childWorkOrderSizeMeasurement.totalFoundPomPlus? +this.childWorkOrderSizeMeasurement.totalFoundPomPlus :0;
        this.workOrderSizeMeasurementObj.totalFoundPomMinus = this.childWorkOrderSizeMeasurement.totalFoundPomMinus? +this.childWorkOrderSizeMeasurement.totalFoundPomMinus : 0;
        this.workOrderSizeMeasurementObj.totalNoOfSampleOutOfPom = workOrderSizeMeasureDetails.totalNoOfSampleOutOfPom;
        this.workOrderSizeMeasurementObj.percentageSampleOutOfPom = workOrderSizeMeasureDetails.percentageSampleOutOfPom;
        this.workOrderSizeMeasurementObj.percentageFoundPomPlus = workOrderSizeMeasureDetails.percentageFoundPomPlus;
        this.workOrderSizeMeasurementObj.percentageFoundPomMinus = workOrderSizeMeasureDetails.percentageFoundPomMinus;
      }
      else {
        this.workOrderSizeMeasurementObj.totalOfCritical= workOrderSizeMeasureDetails.totalOfCritical;
        this.workOrderSizeMeasurementObj.totalNoOfSamplesMeasured = workOrderSizeMeasureDetails.totalNoOfSamplesMeasured
        this.workOrderSizeMeasurementObj.totalFoundPomPlus = workOrderSizeMeasureDetails.totalFoundPomPlus;
        this.workOrderSizeMeasurementObj.totalFoundPomMinus = workOrderSizeMeasureDetails.totalFoundPomMinus;
        this.workOrderSizeMeasurementObj.totalNoOfSampleOutOfPom = workOrderSizeMeasureDetails.totalNoOfSampleOutOfPom;
        this.workOrderSizeMeasurementObj.percentageSampleOutOfPom = workOrderSizeMeasureDetails.percentageSampleOutOfPom;
        this.workOrderSizeMeasurementObj.percentageFoundPomPlus = workOrderSizeMeasureDetails.percentageFoundPomPlus;
        this.workOrderSizeMeasurementObj.percentageFoundPomMinus = workOrderSizeMeasureDetails.percentageFoundPomMinus;
      }
      this.workOrderSizeMeasurementObj.isPOM = workOrderSizeMeasureDetails.isPOM == null ? false : workOrderSizeMeasureDetails.isPOM;

      this.isPomOml = workOrderSizeMeasureDetails.isPOM == null ? false : workOrderSizeMeasureDetails.isPOM;

      //summaryAQL

      this.workOrderSizeMeasurementObj.allowedCritical = workOrderSizeMeasureDetails.allowedCritical;
      this.workOrderSizeMeasurementObj.allowedMajor = workOrderSizeMeasureDetails.allowedMajor;
      this.workOrderSizeMeasurementObj.allowedMinor = workOrderSizeMeasureDetails.allowedMinor;

      this.workOrderSizeMeasurementObj.rejectedCritical = workOrderSizeMeasureDetails.rejectedCritical;
      this.workOrderSizeMeasurementObj.rejectedMajor = workOrderSizeMeasureDetails.rejectedMajor;
      this.workOrderSizeMeasurementObj.rejectedMinor = workOrderSizeMeasureDetails.rejectedMinor;

      if (this._workOrderDetails.clientName?.toUpperCase() === "WALMART GLOBAL SOURCING" || this._workOrderDetails.clientName?.toUpperCase() === "ASDA STORES LIMITED"){
        this.workOrderSizeMeasurementObj.cartonSizeUnitOfMeasureId = this.workOrderSizeMeasurementObj.cartonSizeUnitOfMeasureId == null ? 18 : this.workOrderSizeMeasurementObj.cartonSizeUnitOfMeasureId;
        this.workOrderSizeMeasurementObj.productSizeUnitOfMeasureId = this.workOrderSizeMeasurementObj.productSizeUnitOfMeasureId == null ? 19 : this.workOrderSizeMeasurementObj.productSizeUnitOfMeasureId;
        this.onChangeUnitofMeasure(this.workOrderSizeMeasurementObj.cartonSizeUnitOfMeasureId,'cartonSize');
        this.onChangeUnitofMeasure(this.workOrderSizeMeasurementObj.productSizeUnitOfMeasureId,'productSize');
        var naId = this.aqlOptions.filter((x) => x.name == "N/A")[0].id;
        this.workOrderSizeMeasurementObj.cartonSizeInspectionLevel = this.workOrderSizeMeasurementObj.cartonSizeInspectionLevel == null ? this.inspectionLevelOptions.filter((x) => x.name == "Fixed")[0].name : this.workOrderSizeMeasurementObj.cartonSizeInspectionLevel;
        this.workOrderSizeMeasurementObj.cartonAqlCriticalID = this.workOrderSizeMeasurementObj.cartonAqlCriticalID == null ? naId : this.workOrderSizeMeasurementObj.cartonAqlCriticalID;
        this.workOrderSizeMeasurementObj.cartonAqlMajorID = this.workOrderSizeMeasurementObj.cartonAqlMajorID == null ? naId : this.workOrderSizeMeasurementObj.cartonAqlMajorID;
        this.workOrderSizeMeasurementObj.cartonAqlMinorID = this.workOrderSizeMeasurementObj.cartonAqlMinorID == null ? naId : this.workOrderSizeMeasurementObj.cartonAqlMinorID;
        this.workOrderSizeMeasurementObj.productWeightUnitOfMeasureId = this.workOrderSizeMeasurementObj.productWeightUnitOfMeasureId == null ? 4 : this.workOrderSizeMeasurementObj.productWeightUnitOfMeasureId;
        this.onChangeUnitofMeasure(this.workOrderSizeMeasurementObj.productWeightUnitOfMeasureId ,'productWeight');

      }
    },

    deleteRow() {
      this.buttonDisable = true;
      this.utilityFn.getServerData("delete", this.apiList.deleteWorkOrderProductSizeMeasurementResult + "/" + this.deletedItem.id, {}, (result) => {
        if (this.deletedItem.isCarton) {
          this.cartonSizeMeasurementList.splice(this.deletedIndex, 1);
        } else {
          this.productSizeMeasurementList.splice(this.deletedIndex, 1);
        }
        this.close();
      });
    },

    save() {
      this.close();
    },

    resetSizeDialog() {
      this.workOrderProductID = null;
      this.productID = null;
      this.cartonWeightEditedItem.weight = 0;
      this.cartonWeightEditedItem.actualWeight = 0;
      this.productWeightEditedItem.weight = 0;
      this.productWeightEditedItem.actualWeight = 0;
      this.cartonSizeEditedItem.length = 0;
      this.cartonSizeEditedItem.width = 0;
      this.cartonSizeEditedItem.height = 0;
      this.cartonSizeEditedItem.actualLength = 0;
      this.cartonSizeEditedItem.actualWidth = 0;
      this.cartonSizeEditedItem.actualHeight = 0;
      this.productSizeEditedItem.length = 0;
      this.productSizeEditedItem.width = 0;
      this.productSizeEditedItem.height = 0;
      this.productSizeEditedItem.actualLength = 0;
      this.productSizeEditedItem.actualWidth = 0;
      this.productSizeEditedItem.actualHeight = 0;
      this.cartonSizeEditedItem.uploadPhoto = null;
      this.productSizeEditedItem.uploadPhoto = null;
      this.cartonWeightEditedItem.uploadPhoto = null;
      this.productWeightEditedItem.uploadPhoto = null;

      this.cartonSizeEditedItem.acceptCritical = null;
      this.cartonSizeEditedItem.acceptMajor = null;
      this.cartonSizeEditedItem.acceptMinor = null;
      this.cartonSizeEditedItem.foundCritical = null;
      this.cartonSizeEditedItem.foundMajor = null;
      this.cartonSizeEditedItem.foundMinor = null;
      this.cartonSizeEditedItem.rejectCritical = null;
      this.cartonSizeEditedItem.rejectMajor = null;
      this.cartonSizeEditedItem.rejectMinor = null;
      this.cartonSizeEditedItem.szCritical = null;
      this.cartonSizeEditedItem.szMajor = null;
      this.cartonSizeEditedItem.szMinor = null;

      this.productSizeEditedItem.acceptCritical = null;
      this.productSizeEditedItem.acceptMajor = null;
      this.productSizeEditedItem.acceptMinor = null;
      this.productSizeEditedItem.foundCritical = null;
      this.productSizeEditedItem.foundMajor = null;
      this.productSizeEditedItem.foundMinor = null;
      this.productSizeEditedItem.rejectCritical = null;
      this.productSizeEditedItem.rejectMajor = null;
      this.productSizeEditedItem.rejectMinor = null;
      this.productSizeEditedItem.szCritical = null;
      this.productSizeEditedItem.szMajor = null;
      this.productSizeEditedItem.szMinor = null;
    },

    del_temp_img(fileId, fileArray, index) {
      this.delImgSnackbar.isShow = true;
      this.delImgSnackbar.text = "Deleting picture";
      this.utilityFn.deleteImage(fileId, (res) => {
        fileArray.splice(index, 1);
        this.delImgSnackbar.isShow = false;
        // 删除成功后重新获取列表数据
        // this.populateWorkorderList()
      });
    },

    getMaxValue(workOrderProductId, isCarton) {
      if (isCarton) {
        let filteredcartonSizeMeasurementList = this.cartonSizeMeasurementList.filter((x) => x.workOrderProductId == workOrderProductId);
        return Math.max.apply(
          Math,
          filteredcartonSizeMeasurementList.map((x) => {
            return x.orderNumber;
          })
        );
      } else {
        let filteredproductSizeMeasurementList = this.productSizeMeasurementList.filter((x) => x.workOrderProductId == workOrderProductId);
        return Math.max.apply(
          Math,
          filteredproductSizeMeasurementList.map((x) => {
            return x.orderNumber;
          })
        );
      }
    },

    getWorkorderProductIdTotalCount(workOrderProductId, isCarton) {
      if (isCarton) {
        let filteredcartonSizeMeasurementList = this.cartonSizeMeasurementList.filter((x) => x.workOrderProductId == workOrderProductId);
        return filteredcartonSizeMeasurementList.length;
      } else {
        let filteredproductSizeMeasurementList = this.productSizeMeasurementList.filter((x) => x.workOrderProductId == workOrderProductId);
        return filteredproductSizeMeasurementList.length;
      }
    },

    delete_item(item, tab) {
      if (tab == "cartonSize" || tab == "cartonWeight") {
        this.deletedIndex = this.cartonSizeMeasurementList.indexOf(item);
      } else {
        this.deletedIndex = this.productSizeMeasurementList.indexOf(item);
      }

      this.deletedItem.id = item.id;
      this.deletedItem.workOrderProductId = item.workOrderProductId;
      this.deletedItem.isCarton = item.isCarton;
      this.deletedItem.specifiedLength = item.specifiedLength;
      this.deletedItem.specifiedWidth = item.specifiedWidth;
      this.deletedItem.specifiedHeight = item.specifiedHeight;
      this.deletedItem.actualLength = item.actualLength;
      this.deletedItem.actualWidth = item.actualWidth;
      this.deletedItem.actualHeight = item.actualHeight;
      this.deletedItem.dimensionalUnit = item.dimensionalUnit;
      this.deletedItem.dimensionalResult = item.dimensionalResult;
      this.deletedItem.specifiedWeight = item.specifiedWeight;
      this.deletedItem.actualWeight = item.actualWeight;
      this.deletedItem.weightUnit = item.weightUnit;
      this.deletedItem.weightResult = item.weightResult;
      this.deletedItem.sampleSize = item.sampleSize;
      this.deletedItem.orderNumber = item.orderNumber;
      this.deletedItem.acceptCritical = item.acceptCritical;
      this.deletedItem.acceptMajor = item.acceptMajor;
      this.deletedItem.acceptMinor = item.acceptMinor;
      this.deletedItem.foundCritical = item.foundCritical;
      this.deletedItem.foundMajor = item.foundMajor;
      this.deletedItem.foundMinor = item.foundMinor;
      this.deletedItem.rejectCritical = item.rejectCritical;
      this.deletedItem.rejectMajor = item.rejectMajor;
      this.deletedItem.rejectMinor = item.rejectMinor;
      this.deletedItem.szCritical = item.szCritical;
      this.deletedItem.szMajor = item.szMajor;
      this.deletedItem.szMinor = item.szMinor;
      this.deleteDialog = true;
    },

    show_dialog(item, tab) {
      let newItem = [];

      newItem.id = null;
      newItem.workOrderProductId = item.workOrderProductId;
      newItem.clientProductId = item.clientProductId;
      newItem.skuDisplay = item.skuDisplay;
      newItem.isCarton = item.isCarton;
      newItem.specifiedLength = item.specifiedLength;
      newItem.specifiedWidth = item.specifiedWidth;
      newItem.specifiedHeight = item.specifiedHeight;
      newItem.actualLength = null;
      newItem.actualWidth = null;
      newItem.actualHeight = null;
      newItem.dimensionalUnit = item.dimensionalUnit;
      newItem.dimensionalResult = null;
      newItem.specifiedWeight = item.specifiedWeight;
      newItem.actualWeight = null;
      newItem.weightUnit = item.weightUnit;
      newItem.weightResult = null;
      newItem.sampleSize = null;

      newItem.acceptCritical = null;
      newItem.acceptMajor = null;
      newItem.acceptMinor = null;
      newItem.foundCritical = null;
      newItem.foundMajor = null;
      newItem.foundMinor = null;
      newItem.rejectCritical = null;
      newItem.rejectMajor = null;
      newItem.rejectMinor = null;
      newItem.szCritical = null;
      newItem.szMajor = null;
      newItem.szMinor = null;

      newItem.workOrderProductAttachments = item.workOrderProductAttachments;

      switch (tab) {
        case "cartonSize":
          newItem.orderNumber = this.getMaxValue(item.workOrderProductId, true) + 1;
          this.carton_size_edit_row(newItem);
          break;
        case "cartonWeight":
          newItem.orderNumber = this.getMaxValue(item.workOrderProductId, true) + 1;
          this.carton_weight_edit_row(newItem);
          break;
        case "productSize":
          newItem.orderNumber = this.getMaxValue(item.workOrderProductId, false) + 1;
          this.product_size_edit_row(newItem);
          break;
        case "productWeight":
          newItem.orderNumber = this.getMaxValue(item.workOrderProductId, false) + 1;
          this.product_weight_edit_row(newItem);
          break;
        default:
      }
    },

    carton_size_edit_row(item) {
      // 验证数值不能为空
      if (!this.workOrderSizeMeasurementObj.cartonSizeInspectionLevel) {
        this.snackbar = true;
        this.message = "Inspection Level is required";
        return;
      } else if (!this.workOrderSizeMeasurementObj.cartonSizeToleranceUpper) {
        this.snackbar = true;
        this.message = "Tolerance(Upper) is required";
        return;
      } else if (!this.workOrderSizeMeasurementObj.cartonSizeToleranceLower) {
        this.snackbar = true;
        this.message = "Tolerance(Lower) is required";
        return;
      }

      this.updateworkOrderSizeMeasurementObj();

      this.formTitle = item.id == null ? "Add Actual Size Measure" : "Edit Actual Size Measured";
      this.cartonEditedIndex = item.id == null ? -1 : this.cartonSizeMeasurementList.indexOf(item);
      this.cartonSizeEditedItem.id = item.id;
      this.cartonSizeEditedItem.workOrderProductId = item.workOrderProductId;
      this.cartonSizeEditedItem.clientProductId = item.clientProductId;
      this.cartonSizeEditedItem.skuDisplay = item.skuDisplay;
      this.cartonSizeEditedItem.isCarton = item.isCarton;
      this.cartonSizeEditedItem.specifiedLength = item.specifiedLength;
      this.cartonSizeEditedItem.specifiedWidth = item.specifiedWidth;
      this.cartonSizeEditedItem.specifiedHeight = item.specifiedHeight;
      this.cartonSizeEditedItem.actualLength = item.actualLength;
      this.cartonSizeEditedItem.actualWidth = item.actualWidth;
      this.cartonSizeEditedItem.actualHeight = item.actualHeight;
      this.cartonSizeEditedItem.dimensionalUnit = item.dimensionalUnit;
      this.cartonSizeEditedItem.dimensionalResult = item.dimensionalResult;
      this.cartonSizeEditedItem.specifiedWeight = item.specifiedWeight;
      this.cartonSizeEditedItem.actualWeight = item.actualWeight;
      this.cartonSizeEditedItem.weightUnit = item.weightUnit;
      this.cartonSizeEditedItem.weightResult = item.weightResult;
      this.cartonSizeEditedItem.sampleSize = item.sampleSize;
      this.cartonSizeEditedItem.orderNumber = item.orderNumber;
      this.cartonSizeEditedItem.acceptCritical = item.acceptCritical;
      this.cartonSizeEditedItem.acceptMajor = item.acceptMajor;
      this.cartonSizeEditedItem.acceptMinor = item.acceptMinor;
      this.cartonSizeEditedItem.foundCritical = item.foundCritical;
      this.cartonSizeEditedItem.foundMajor = item.foundMajor;
      this.cartonSizeEditedItem.foundMinor = item.foundMinor;
      this.cartonSizeEditedItem.rejectCritical = item.rejectCritical;
      this.cartonSizeEditedItem.rejectMajor = item.rejectMajor;
      this.cartonSizeEditedItem.rejectMinor = item.rejectMinor;
      this.cartonSizeEditedItem.szCritical = item.szCritical;
      this.cartonSizeEditedItem.szMajor = item.szMajor;
      this.cartonSizeEditedItem.szMinor = item.szMinor;
      this.cartonSizeEditedItem.workOrderProductAttachments = item.workOrderProductAttachments;
      this.cartonSizeDialog = true;

      // 打开弹窗时清空文件框
      this.cartonSizeEditedItem.fileInput = [];
      this.cartonSizeEditedItem.serverPhoto = [];

      item.workOrderProductAttachments.forEach((x) => {
        if (x.sectionId == 2 && x.description == "Actual Carton Size Photo") {
          // 获取图片的base64编码用于显示
          this.utilityFn.getImage(x.fileUploadId, (res) => {
            x.img = "data:" + item.type + ";base64," + res.data.data;
            this.cartonSizeEditedItem.serverPhoto.push({
              fileUploadId: res.data.id,
              img: "data:" + item.type + ";base64," + res.data.data,
            });
          });
        }
      });

      // if (this.cartonSizeEditedItem.serverPhoto.length == 0) {
      //     console.log('Edit Images', this.cartonSizeEditedItem.serverPhoto.length)
      //     item.workOrderProductAttachments.forEach(x => {
      //         if (x.sectionId == 2 && x.description == 'Actual Carton Size Photo') {
      //             // 获取图片的base64编码用于显示
      //             this.utilityFn.getImage(x.fileUploadId, res => {
      //                 x.img = 'data:' + item.type + ';base64,' + res.data.data
      //                 this.cartonSizeEditedItem.serverPhoto.push({
      //                     fileUploadId: res.data.id,
      //                     img: 'data:' + item.type + ';base64,' + res.data.data
      //                 })
      //             });
      //         }
      //     })
      // }
    },

    carton_weight_edit_row(item) {
      if (!this.workOrderSizeMeasurementObj.cartonWeightInspectionLevel) {
        this.snackbar = true;
        this.message = "Inspection Level is required";
        return;
      } else if (!this.workOrderSizeMeasurementObj.cartonWeightToleranceUpper) {
        this.snackbar = true;
        this.message = "Tolerance(Upper) is required";
        return;
      } else if (!this.workOrderSizeMeasurementObj.cartonWeightToleranceLower) {
        this.snackbar = true;
        this.message = "Tolerance(Lower) is required";
        return;
      }

      this.updateworkOrderSizeMeasurementObj();
      this.resetSizeDialog();
      this.cartonWeightDialog = true;

      // this.productID = item.productID;
      // this.workOrderProductID = item.id;
      // this.cartonWeightEditedItem.id = item.id;
      // this.cartonWeightEditedItem.weight = item.cartonWeight;
      // if (item.workOrderProductResults) {
      //     this.cartonWeightEditedItem.actualWeight = item.workOrderProductResults.actualWCrtn;
      //     this.cartonWeightEditedItem.cartonWeightSampleSize = item.workOrderProductResults.samlpleSizeCartonWeightMeasurement;
      //     this.cartonWeightEditedItem.result = item.workOrderProductResults.rsltWCrtn;

      // }

      this.formTitle = item.id == null ? "Add Actual Weight Measure" : "Edit Actual Weight Measured";
      this.cartonEditedIndex = item.id == null ? -1 : this.cartonSizeMeasurementList.indexOf(item);
      this.cartonWeightEditedItem.id = item.id;
      this.cartonWeightEditedItem.workOrderProductId = item.workOrderProductId;
      this.cartonWeightEditedItem.clientProductId = item.clientProductId;
      this.cartonWeightEditedItem.skuDisplay = item.skuDisplay;
      this.cartonWeightEditedItem.isCarton = item.isCarton;
      this.cartonWeightEditedItem.specifiedLength = item.specifiedLength;
      this.cartonWeightEditedItem.specifiedWidth = item.specifiedWidth;
      this.cartonWeightEditedItem.specifiedHeight = item.specifiedHeight;
      this.cartonWeightEditedItem.actualLength = item.actualLength;
      this.cartonWeightEditedItem.actualWidth = item.actualWidth;
      this.cartonWeightEditedItem.actualHeight = item.actualHeight;
      this.cartonWeightEditedItem.dimensionalUnit = item.dimensionalUnit;
      this.cartonWeightEditedItem.dimensionalResult = item.dimensionalResult;
      this.cartonWeightEditedItem.specifiedWeight = item.specifiedWeight;
      this.cartonWeightEditedItem.actualWeight = item.actualWeight;
      this.cartonWeightEditedItem.weightUnit = item.weightUnit;
      this.cartonWeightEditedItem.weightResult = item.weightResult;
      this.cartonWeightEditedItem.sampleSize = item.sampleSize;
      this.cartonWeightEditedItem.orderNumber = item.orderNumber;
      this.cartonWeightEditedItem.acceptCritical = item.acceptCritical;
      this.cartonWeightEditedItem.acceptMajor = item.acceptMajor;
      this.cartonWeightEditedItem.acceptMinor = item.acceptMinor;
      this.cartonWeightEditedItem.foundCritical = item.foundCritical;
      this.cartonWeightEditedItem.foundMajor = item.foundMajor;
      this.cartonWeightEditedItem.foundMinor = item.foundMinor;
      this.cartonWeightEditedItem.rejectCritical = item.rejectCritical;
      this.cartonWeightEditedItem.rejectMajor = item.rejectMajor;
      this.cartonWeightEditedItem.rejectMinor = item.rejectMinor;
      this.cartonWeightEditedItem.szCritical = item.szCritical;
      this.cartonWeightEditedItem.szMajor = item.szMajor;
      this.cartonWeightEditedItem.szMinor = item.szMinor;
      this.cartonWeightEditedItem.workOrderProductAttachments = item.workOrderProductAttachments;
      this.cartonWeightDialog = true;

      // 打开弹窗时清空文件框
      this.cartonWeightEditedItem.fileInput = [];
      this.cartonWeightEditedItem.serverPhoto = [];

      item.workOrderProductAttachments.forEach((x) => {
        if (x.sectionId == 2 && x.description == "Actual Carton Weight Photo") {
          // 获取图片的base64编码用于显示
          this.utilityFn.getImage(x.fileUploadId, (res) => {
            x.img = "data:" + item.type + ";base64," + res.data.data;
            this.cartonWeightEditedItem.serverPhoto.push({
              fileUploadId: res.data.id,
              img: "data:" + item.type + ";base64," + res.data.data,
            });
          });
        }
      });
    },

    product_size_edit_row(item) {
      // 验证数值不能为空
      if (!this.workOrderSizeMeasurementObj.productSizeInspectionLevel) {
        this.snackbar = true;
        this.message = "Inspection Level is required";
        return;
      } else if (!this.workOrderSizeMeasurementObj.productSizeToleranceUpper) {
        this.snackbar = true;
        this.message = "Tolerance(Upper) is required";
        return;
      } else if (!this.workOrderSizeMeasurementObj.productSizeToleranceLower) {
        this.snackbar = true;
        this.message = "Tolerance(Lower) is required";
        return;
      }

      this.updateworkOrderSizeMeasurementObj();
      this.resetSizeDialog();

      this.formTitle = item.id == null ? "Add Actual Size Measure" : "Edit Actual Size Measured";
      this.productEditedIndex = item.id == null ? -1 : this.productSizeMeasurementList.indexOf(item);
      this.productSizeEditedItem.id = item.id;
      this.productSizeEditedItem.workOrderProductId = item.workOrderProductId;
      this.productSizeEditedItem.clientProductId = item.clientProductId;
      this.productSizeEditedItem.skuDisplay = item.skuDisplay;
      this.productSizeEditedItem.isCarton = item.isCarton;
      this.productSizeEditedItem.specifiedLength = item.specifiedLength;
      this.productSizeEditedItem.specifiedWidth = item.specifiedWidth;
      this.productSizeEditedItem.specifiedHeight = item.specifiedHeight;
      this.productSizeEditedItem.actualLength = item.actualLength;
      this.productSizeEditedItem.actualWidth = item.actualWidth;
      this.productSizeEditedItem.actualHeight = item.actualHeight;
      this.productSizeEditedItem.dimensionalUnit = item.dimensionalUnit;
      this.productSizeEditedItem.dimensionalResult = item.dimensionalResult;
      this.productSizeEditedItem.specifiedWeight = item.specifiedWeight;
      this.productSizeEditedItem.actualWeight = item.actualWeight;
      this.productSizeEditedItem.weightUnit = item.weightUnit;
      this.productSizeEditedItem.weightResult = item.weightResult;
      this.productSizeEditedItem.sampleSize = item.sampleSize;
      this.productSizeEditedItem.orderNumber = item.orderNumber;
      this.productSizeEditedItem.acceptCritical = item.acceptCritical;
      this.productSizeEditedItem.acceptMajor = item.acceptMajor;
      this.productSizeEditedItem.acceptMinor = item.acceptMinor;
      this.productSizeEditedItem.foundCritical = item.foundCritical;
      this.productSizeEditedItem.foundMajor = item.foundMajor;
      this.productSizeEditedItem.foundMinor = item.foundMinor;
      this.productSizeEditedItem.rejectCritical = item.rejectCritical;
      this.productSizeEditedItem.rejectMajor = item.rejectMajor;
      this.productSizeEditedItem.rejectMinor = item.rejectMinor;
      this.productSizeEditedItem.szCritical = item.szCritical;
      this.productSizeEditedItem.szMajor = item.szMajor;
      this.productSizeEditedItem.szMinor = item.szMinor;

      this.productSizeEditedItem.workOrderProductAttachments = item.workOrderProductAttachments;
      this.productSizeDialog = true;

      // 打开弹窗时清空文件框
      this.productSizeEditedItem.fileInput = [];
      this.productSizeEditedItem.serverPhoto = [];

      item.workOrderProductAttachments.forEach((x) => {
        if (x.sectionId == 2 && x.description == "Actual Product Size Photo") {
          // 获取图片的base64编码用于显示
          this.utilityFn.getImage(x.fileUploadId, (res) => {
            x.img = "data:" + item.type + ";base64," + res.data.data;
            this.productSizeEditedItem.serverPhoto.push({
              fileUploadId: res.data.id,
              img: "data:" + item.type + ";base64," + res.data.data,
            });
          });
        }
      });
    },

    product_weight_edit_row(item) {
      // 验证数值不能为空
      if (!this.workOrderSizeMeasurementObj.productSizeInspectionLevel) {
        this.snackbar = true;
        this.message = "Inspection Level is required";
        return;
      } else if (!this.workOrderSizeMeasurementObj.productWeightToleranceUpper) {
        this.snackbar = true;
        this.message = "Tolerance(Upper) is required";
        return;
      } else if (!this.workOrderSizeMeasurementObj.productWeightToleranceLower) {
        this.snackbar = true;
        this.message = "Tolerance(Lower) is required";
        return;
      }

      this.updateworkOrderSizeMeasurementObj();

      this.formTitle = item.id == null ? "Add Actual Weight Measure" : "Edit Actual Weight Measured";
      this.productEditedIndex = item.id == null ? -1 : this.productSizeMeasurementList.indexOf(item);
      this.productWeightEditedItem.id = item.id;
      this.productWeightEditedItem.workOrderProductId = item.workOrderProductId;
      this.productWeightEditedItem.clientProductId = item.clientProductId;
      this.productWeightEditedItem.skuDisplay = item.skuDisplay;
      this.productWeightEditedItem.isCarton = item.isCarton;
      this.productWeightEditedItem.specifiedLength = item.specifiedLength;
      this.productWeightEditedItem.specifiedWidth = item.specifiedWidth;
      this.productWeightEditedItem.specifiedHeight = item.specifiedHeight;
      this.productWeightEditedItem.actualLength = item.actualLength;
      this.productWeightEditedItem.actualWidth = item.actualWidth;
      this.productWeightEditedItem.actualHeight = item.actualHeight;
      this.productWeightEditedItem.dimensionalUnit = item.dimensionalUnit;
      this.productWeightEditedItem.dimensionalResult = item.dimensionalResult;
      this.productWeightEditedItem.specifiedWeight = item.specifiedWeight;
      this.productWeightEditedItem.actualWeight = item.actualWeight;
      this.productWeightEditedItem.weightUnit = item.weightUnit;
      this.productWeightEditedItem.weightResult = item.weightResult;
      this.productWeightEditedItem.sampleSize = item.sampleSize;
      this.productWeightEditedItem.orderNumber = item.orderNumber;
      this.productWeightEditedItem.acceptCritical = item.acceptCritical;
      this.productWeightEditedItem.acceptMajor = item.acceptMajor;
      this.productWeightEditedItem.acceptMinor = item.acceptMinor;
      this.productWeightEditedItem.foundCritical = item.foundCritical;
      this.productWeightEditedItem.foundMajor = item.foundMajor;
      this.productWeightEditedItem.foundMinor = item.foundMinor;
      this.productWeightEditedItem.rejectCritical = item.rejectCritical;
      this.productWeightEditedItem.rejectMajor = item.rejectMajor;
      this.productWeightEditedItem.rejectMinor = item.rejectMinor;
      this.productWeightEditedItem.szCritical = item.szCritical;
      this.productWeightEditedItem.szMajor = item.szMajor;
      this.productWeightEditedItem.szMinor = item.szMinor;
      this.productWeightEditedItem.workOrderProductAttachments = item.workOrderProductAttachments;
      this.productWeightDialog = true;

      // 打开弹窗时清空文件框
      this.productWeightEditedItem.fileInput = [];
      this.productWeightEditedItem.serverPhoto = [];

      item.workOrderProductAttachments.forEach((x) => {
        if (x.sectionId == 2 && x.description == "Actual Product Weight Photo") {
          // 获取图片的base64编码用于显示
          this.utilityFn.getImage(x.fileUploadId, (res) => {
            x.img = "data:" + item.type + ";base64," + res.data.data;
            this.productWeightEditedItem.serverPhoto.push({
              fileUploadId: res.data.id,
              img: "data:" + item.type + ";base64," + res.data.data,
            });
          });
        }
      });
    },

    onChangeVaryingAQL() {
      this.updateworkOrderSizeMeasurementObj().then(() => {});
      this.calculateSampleSize(this.workOrderSizeMeasurementObj.productSizeInspectionLevel, this.workOrderSizeMeasurementObj.productSizeSamplingMethodology, false);
    },

    onChangePOM() {
      this.updateworkOrderSizeMeasurementObj();
      this.isPomOml = this.workOrderSizeMeasurementObj.isPOM;
    },

    onChangeAQLLevel(aqlValue, aqlLevel) {
      let aC = 0;
      let rE = 0;
      let sz = 0;

      this.changeAQLBySamplingMethodolgy();
      if (this.workOrderSizeMeasurementObj.productSizeInspectionLevel == "Fixed" || this.workOrderSizeMeasurementObj.productSizeInspectionLevel == null || this.getAQL(aqlValue) == "N/A" || this.getAQL(aqlValue) == "Not Allowed") {
        aC = null;
        rE = null;
        sz = null;

        if (aqlLevel == "Critical") {
          // this.workOrderSizeMeasurementObj.productSZCritical = sz
        } else if (aqlLevel == "Major") {
          // this.workOrderSizeMeasurementObj.productSZMajor = sz
        } else if (aqlLevel == "Minor") {
          // this.workOrderSizeMeasurementObj.productSZMinor = sz
        }
      } else {
        let varyingAQL = this.samPlan.getVaryingAQL(this.samplingPlan.totalProductPOQty, this.workOrderSizeMeasurementObj.productSizeInspectionLevel, this.workOrderDetail.inspectionLevel, this.getAQL(aqlValue), this.workOrderDetail.samplingPlan);

        aC = this.getAQL(aqlValue) == "0" ? "0" : varyingAQL.aC;
        rE = this.getAQL(aqlValue) == "0" ? "1" : varyingAQL.rE;
        sz = varyingAQL.sampleSize;

        if (aqlLevel == "Critical") {
          // this.workOrderSizeMeasurementObj.productSZCritical = sz
        } else if (aqlLevel == "Major") {
          // this.workOrderSizeMeasurementObj.productSZMajor = sz
        } else if (aqlLevel == "Minor") {
          // this.workOrderSizeMeasurementObj.productSZMinor = sz
        }
      }

      let workOrderProductSizeMeasurementResultObjList = [];
      this.productSizeMeasurementList.forEach((element) => {
        const addedProductSizeMeasurement = {
          id: element.id,
          workOrderProductId: element.workOrderProductId,
          isCarton: element.isCarton,
          specifiedLength: element.specifiedLength,
          specifiedWidth: element.specifiedWidth,
          specifiedHeight: element.specifiedHeight,
          actualLength: element.actualLength,
          actualWidth: element.actualWidth,
          actualHeight: element.actualHeight,
          dimensionalUnit: element.dimensionalUnit,
          dimensionalResult: element.dimensionalResult,
          specifiedWeight: element.specifiedWeight,
          actualWeight: element.actualWeight,
          weightUnit: element.weightUnit,
          weightResult: element.weightResult,
          sampleSize: element.sampleSize,
          orderNumber: element.orderNumber,
          acceptCritical: aqlLevel == "Critical" ? aC : element.acceptCritical,
          acceptMajor: aqlLevel == "Major" ? aC : element.acceptMajor,
          acceptMinor: aqlLevel == "Minor" ? aC : element.acceptMinor,
          foundCritical: element.foundCritical,
          foundMajor: element.foundMajor,
          foundMinor: element.foundMinor,
          rejectCritical: aqlLevel == "Critical" ? rE : element.rejectCritical,
          rejectMajor: aqlLevel == "Major" ? rE : element.rejectMajor,
          rejectMinor: aqlLevel == "Minor" ? rE : element.rejectMinor,
          szCritical: element.szCritical,
          szMajor: element.szMajor,
          szMinor: element.szMinor,
          sectionId: 2,
        };
        workOrderProductSizeMeasurementResultObjList.push(addedProductSizeMeasurement);
      });


      this.productSizeMeasurementList.map((x) => {
        if (aqlLevel == "Critical") {
          x.acceptCritical = aC;
          x.rejectCritical = rE;
        }
        if (aqlLevel == "Major") {
          x.acceptMajor = aC;
          x.rejectMajor = rE;
        }
        if (aqlLevel == "Minor") {
          x.acceptMinor = aC;
          x.rejectMinor = rE;
        }

        return x;
      });

      if (workOrderProductSizeMeasurementResultObjList.length > 0) {
        this.utilityFn.getServerData(
          "post",
          this.apiList.upsertWorkOrderProductSizeMeasurementResult,
          workOrderProductSizeMeasurementResultObjList,
          (res) => {
            //  this.loadWorkOrderSizeMeasurementDetails(res.data);
          },
          (errRes) => {}
        );
      }
      this.updateworkOrderSizeMeasurementObj();
    },

    onChangeUnitofMeasure(value,tab) {
      if (tab == "cartonSize") {
        this.cartonSizeMeasurementList.map((x) => {
          x.dimensionalUnit = value;
          return x;
        });

      }
       if (tab == "productSize") {
        this.productSizeMeasurementList.map((x) => {
          x.dimensionalUnit = value;
          return x;
        });
      } 
      if(tab=="productWeight"){
        this.productSizeMeasurementList.map((x) => {
          x.weightUnit = value;
          return x;
        });
      }

  
    },

    onImageChange(event, screenId, editItem, desc) {
      var description = "";
      var loading = editItem;

      editItem.uploadLoading = event.length > 0;

      event.forEach((item) => {
        let formData = new FormData();
        formData.append("image", item);
        formData.append("description", desc);
        formData.append("workOrderProductId", editItem.workOrderProductId);
        formData.append("screen", screenId);

        this.utilityFn.uploadImg(
          this.apiList.uploadWorkOrderProductImage,
          {
            formData: formData,
          },
          (progress) => {
            // 获得上传进度
          },
          (res) => {
            // 显示上传进度
            editItem.uploadLoading = false;
            editItem.serverPhoto.push({
              fileUploadId: res.data.fileUploadId,
              img: window.URL.createObjectURL(item),
            });
            editItem.workOrderProductAttachments.push(res.data);
          }
        );
      });
    },

    returnNumber(obj, name) {
      if (obj[name] == null) {
        //TAB was pressed
        return;
      }
      if (obj[name] != "") {
        obj[name] = obj[name].toString().replace(/[^-\d.]/g, ""); //清除“数字”和“.”以外的字符
        obj[name] = obj[name].toString().replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
        obj[name] = obj[name]
          .toString()
          .replace(".", "$#$")
          .replace(/\./g, "")
          .replace("$#$", ".");
        obj[name] = obj[name].toString().replace(/^(\-)*(\d+)\.(\d\d\d\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      }
    },

    returnNumberSampleSize(obj, name) {
      if (obj[name] != "") {
        obj[name] = obj[name].toString().replace(/[^-\d]/g, ""); //清除“数字”和“.”以外的字符
        // obj[name] = obj[name].replace(/\.{2,}/g,"."); //只保留第一个. 清除多余的
        // obj[name] = obj[name].replace(".","$#$").replace(/\./g,"").replace("$#$",".");
        // obj[name] = obj[name].replace(/^(\-)*(\d+)\.(\d\d\d\d\d).*$/,'$1$2.$3');//只能输入两个小数
      }
    },

    go_upload_photo(type) {
      let screenId;
      let description;
      var inputObj = document.createElement("input");
      switch (type) {
        case "cartonSize":
          screenId = 2;
          description = "Carton Size Tab";
          break;
        case "cartonWeight":
          screenId = 2;
          description = "Carton Weight Tab";
          break;
        case "productSize":
          screenId = 2;
          description = "Product Size Tab";
          break;
        case "productWeight":
          screenId = 2;
          description = "Product Weight Tab";
          break;
      }

      inputObj.setAttribute("multiple", "multiple");
      inputObj.setAttribute("id", "_ef");
      inputObj.setAttribute("type", "file");
      inputObj.setAttribute("style", "visibility:hidden");
      document.body.appendChild(inputObj);
      inputObj.click();
      inputObj.onchange = (res) => {
        this.showProgressDialog = true;
        for (let i = 0; i < inputObj.files.length; i++) {
          const file = inputObj.files[i];
          var sizeInfo = this.utilityFn.formatBytes(file.size);
          if (sizeInfo.sizeKB > 500) {
            this.$store.dispatch("navigation/setSnackbar", {
              color: "error",
              text: `Each upload only accepts image size less than 500KB. Please resize/compact the image with size greater than 500KB. Filename: ${file.name}.`,
            });
          } else {
            const addedImage = {
              id: i,
              uploadProgress: 0,
              fileName: file.name,
            };
            this.addedImages.push(addedImage);

            const form = new FormData();
            form.append("image", file);
            form.append("description", description);
            form.append("workOrderId", this.workOrderId);
            form.append("screen", screenId);

            this.utilityFn.uploadImg(
              this.apiList.uploadWorkOrderImage,
              {
                formData: form,
              },
              (progress) => {
                const imgIndex = this.addedImages.findIndex((x) => x.id === addedImage.id);
                this.addedImages[imgIndex].uploadProgress = progress;
              },
              (res) => {
                const imgIndex = this.addedImages.findIndex((x) => x.id === addedImage.id);
                this.addedImages.splice(imgIndex, 1);

                this.showProgressDialog = this.addedImages.length !== 0;
              },
              (errRes) => {
                  this.showProgressDialog = false;
                   alert("Photo saved offline, will auto update when device is online");
              }
            );
          }
        }
      };
    },

    async submit_item(sectionSubmitted = false) {
      //dont show snackbar validations if sectionSubmitted = false

      if(sectionSubmitted) {
        if(this.IsPOMValid) {
          if((this._workOrderDetails.clientName?.toUpperCase() === "WALMART GLOBAL SOURCING" || this._workOrderDetails.clientName?.toUpperCase() === "ASDA STORES LIMITED") && !this.IsCriticalPOMValid) {
            this.snackbar = true;
            this.message = "Please add at least one Critical POM(s)";
            return;
          }
        } else {
          this.snackbar = true;
          this.message = "Please input the Total # of Critical POM and Total No of Samples Measured.";
          return;
        }

        //await this.saveData(true);

        if((this._workOrderDetails.clientName?.toUpperCase() === "WALMART GLOBAL SOURCING" || this._workOrderDetails.clientName?.toUpperCase() === "ASDA STORES LIMITED") && this._workOrderDetails.isPreShipOrFinalRandom) {
          var validateFCM = await this.utilityFn.getServerDataAsync('get',`${this.apiList.validateFCM}/${this.$route.params.id}`,{});
          if (validateFCM.data){
            this.snackbar = true;
            this.message = validateFCM.data;
            return;
          } 
        }      
        
        if((!this.isCartonResultValid || !this.isProductResultValid)) {
          this.snackbar = true;
          this.message = "Missing Input Required Fields";
          return;
        }
      }

      this.sectionSubmitted = sectionSubmitted;

      const section = {
        sectionId: this.sectionId,
        workOrderId: this.$route.params.id,
      };
      this.utilityFn.getServerData("post", this.apiList.submitInspectionSection, section, (res) => {
        this.sectionData.SizeMeasurement.isSectionSubmitted = true;
        this.$store.dispatch("inspection/setSectionData", this.sectionData);

        const index = this._workOrderDetails.workOrderInspectionSections.findIndex((x) => x.sectionId == this.sectionId);
        if (index !== -1) {
          this._workOrderDetails.workOrderInspectionSections[index].submittedDate = new Date();
          this.$store.dispatch("inspection/setWorkOrderDetails", _.cloneDeep(this._workOrderDetails));
        }
      });
    },

    go_to_gallery() {
      this.$router.push({
        name: "uploadPhotoGalleryId",
        params: {
          id: this.$route.params.id,
          name: this.sectionData.SizeMeasurement.name,
          sectionId: this.sectionData.SizeMeasurement.id,
        },
        query: {
          name: this.sectionData.SizeMeasurement.name,
          sectionId: this.sectionData.SizeMeasurement.id,
        },
      });
    },

    populateSampleSizeByInspectionLevel(inspectionLevel, tab) {
      this.onChangeInspectionLevel(inspectionLevel, tab);
    },

    onChange() {
      this.logChanges();
    },

    logChanges(log = true) {
      const logTransactionCode = this.logTransactionCode || window.sessionStorage.getItem("transactionCode");

      if (this.initialLogging || !log) {
        log = false;
        window.sessionStorage.setItem("transactionCode", auditLog.getUniqueIdentifier());
      } else {
        window.sessionStorage.setItem("transactionCode", logTransactionCode);
      }

      window.sessionStorage.setItem("EditDataLogging", log);
      this.logTransactionCode = logTransactionCode;
      this.initialLogging = false;
    },

    initQuantityVerification() {
      let existingQuantityVerificationList = [];
      this.workOrderProducts.forEach((x) => {
        if (x.workOrderProductQuantityVerificationResults) {
          if (x.workOrderProductQuantityVerificationResults.length > 0) {
            x.workOrderProductQuantityVerificationResults.forEach((item) => {
              existingQuantityVerificationList.push(item);
            });
          }
        }
      });
      if (existingQuantityVerificationList.length > 0) {
        this.loadQuantityVerificationProductDetails(existingQuantityVerificationList);
      }
    },

    loadQuantityVerificationProductDetails(quantityVerificationProductDetails) {
      this.productQuantityVerificationList = [];
      if (quantityVerificationProductDetails) {
        if (quantityVerificationProductDetails.length > 0) {
          let quantityVerificationList = [];
          quantityVerificationProductDetails.forEach((res) => {
            const quantityVerification = {
              id: res.id,
              workOrderProductId: res.workOrderProductId,
              productID: this.getSKUDisplay(res.workOrderProductId).productID,
              skuDisplay: this.getSKUDisplay(res.workOrderProductId).skuDisplay,
              po: this.getSKUDisplay(res.workOrderProductId).po,
              cartonQty: this.getSKUDisplay(res.workOrderProductId).cartonQty,
              qty: this.getSKUDisplay(res.workOrderProductId).qty,
              caseCountProduct: res.caseCountProduct,
              availableQuantityCarton: res.availableQuantityCarton,
              sampleSizeCarton: res.sampleSizeCarton,
              sampleSizeCarton2: res.sampleSizeCarton,
              sampleSizeCartonTotal: res.sampleSizeCartonTotal,
              excessShortQuantity: res.excessShortQuantity,
              sampleSizeProduct: res.sampleSizeProduct,
              sampleSizeProduct2: res.sampleSizeProduct,
              sampleSizeProductTotal: res.sampleSizeProductTotal,
              exportCartonNumbersOpened: res.exportCartonNumbersOpened,
              remarks: res.remarks,
              packedQty: res.packedQty,
              underPackingQty: res.underPackingQty,
              underProductionFinishingQty: res.underProductionFinishingQty,
              availableQuantityProduct: res.availableQuantityProduct,
              orderNumber: res.orderNumber,
            };
            this.productQuantityVerificationList.push(quantityVerification);
          });
        }
      }
    },

    getSKUDisplay(productId) {
      if (!productId || productId == "" || productId == null) {
        return null;
      }

      let skuDisplayData = this.skuDisplayList.find((x) => x.workOrderProductId === productId);
      return skuDisplayData;
    },

    onChangeInspectionLevel(value) {
      this.updateworkOrderSizeMeasurementObj().then(() => {});
      this.calculateSampleSize(value, this.workOrderSizeMeasurementObj.productSizeSamplingMethodology, false);
    },

    onChangeAQL() {
      this.updateworkOrderSizeMeasurementObj().then(() => {});
      this.calculateSampleSize(this.workOrderSizeMeasurementObj.productSizeInspectionLevel, this.workOrderSizeMeasurementObj.productSizeSamplingMethodology, false, true);
    },

    onChangeSamplingMethodology(value) {
      this.changeAQLBySamplingMethodolgy();
      this.updateworkOrderSizeMeasurementObj().then(() => {});
      this.calculateSampleSize(this.workOrderSizeMeasurementObj.productSizeInspectionLevel, value, false);
    },

    calculateSampleSize(inspectionLevel, samplingMethodology, isInitialCalculate, isChangeAql = false) {
      this.onChange();
      if(!inspectionLevel) {
        this.productSizeMeasurementList.forEach((item) => {
          item.sampleSize = null;
        });

        return;
      }
      
      let samplingPlanByInspectionLevelAndSamplingMethodology;
      
      if (inspectionLevel == "Fixed") {
        if(!isInitialCalculate && !isChangeAql) {
          this.workOrderSizeMeasurementObj.productSZCritical = null;
          this.workOrderSizeMeasurementObj.productSZMajor = null;
          this.workOrderSizeMeasurementObj.productSZMinor = null;
        }
      } else {
        const aqlCritical = this.workOrderSizeMeasurementObj.isVaryingAQL ? this.getAQL(this.workOrderSizeMeasurementObj.productAqlCriticalID) : "0";
        const aqlMinor = this.workOrderSizeMeasurementObj.isVaryingAQL ? this.getAQL(this.workOrderSizeMeasurementObj.productAqlMinorID) : "0";
        const aqlMajor = this.workOrderSizeMeasurementObj.isVaryingAQL ? this.getAQL(this.workOrderSizeMeasurementObj.productAqlMajorID) : "0";
        const aql = {
          aqlCritical: aqlCritical ? aqlCritical : "0",
          aqlMinor: aqlMinor ? aqlMinor : "0",
          aqlMajor: aqlMajor ? aqlMajor : "0"
        }
      
        samplingPlanByInspectionLevelAndSamplingMethodology = this.samPlan.getSamplingPlanByInspectionLevelAndSamplingMethodology(inspectionLevel, samplingMethodology, aql);
        var changeToMax = true;

        if (aqlCritical != null) {
          const criticalValue = aqlCritical.toLowerCase();
          if(isInitialCalculate && this.workOrderSizeMeasurementObj.productSZCritical) {
            changeToMax = false;
            //Do not change the value of this.workOrderSizeMeasurementObj.productSZCritical
          } else if (criticalValue === 'n/a' || criticalValue === 'not allowed') {
            this.workOrderSizeMeasurementObj.productSZCritical = 0;
          } else {
            const sampleSize = this.samPlan.getVaryingAQL(samplingPlanByInspectionLevelAndSamplingMethodology.totalProductPOQty, inspectionLevel, samplingMethodology, aqlCritical).sampleSize;
            this.workOrderSizeMeasurementObj.productSZCritical = sampleSize;
          }
        } else {
          this.workOrderSizeMeasurementObj.productSZCritical = null;
        }
  
        if (aqlMajor != null) {
          const majorValue = aqlMajor.toLowerCase();
          if(isInitialCalculate && this.workOrderSizeMeasurementObj.productSZMajor) {
            //Do not change the value of this.workOrderSizeMeasurementObj.productSZMajor
            changeToMax = false;
          } else if (majorValue === 'n/a' || majorValue === 'not allowed') {
            this.workOrderSizeMeasurementObj.productSZMajor = 0;
          } else {
            const sampleSize = this.samPlan.getVaryingAQL(samplingPlanByInspectionLevelAndSamplingMethodology.totalProductPOQty, inspectionLevel, samplingMethodology, aqlMajor).sampleSize;
            this.workOrderSizeMeasurementObj.productSZMajor = sampleSize;
          }
        } else {
          this.workOrderSizeMeasurementObj.productSZMajor = null;
        }
  
        if (aqlMinor != null) {
          const minorValue = aqlMinor.toLowerCase();
          if(isInitialCalculate && this.workOrderSizeMeasurementObj.productSZMinor) {
            //Do not change the value of this.workOrderSizeMeasurementObj.productSZMinor
            changeToMax = false;
          } else if (minorValue === 'n/a' || minorValue === 'not allowed') {
            this.workOrderSizeMeasurementObj.productSZMinor = 0;
          } else {
            const sampleSize = this.samPlan.getVaryingAQL(samplingPlanByInspectionLevelAndSamplingMethodology.totalProductPOQty, inspectionLevel, samplingMethodology, aqlMinor).sampleSize;
            this.workOrderSizeMeasurementObj.productSZMinor = sampleSize;
          }
        } else {
          this.workOrderSizeMeasurementObj.productSZMinor = null;
        }

        /*if (changeToMax){
          var list = [];
          list.push(this.workOrderSizeMeasurementObj.productSZCritical);
          list.push(this.workOrderSizeMeasurementObj.productSZMajor);
          list.push(this.workOrderSizeMeasurementObj.productSZMinor);
          var maxValue = Math.max(...(list));

          const criticalValue = aqlCritical.toLowerCase();
          const majorValue = aqlMajor.toLowerCase();
          const minorValue = aqlMinor.toLowerCase();


          if (aqlCritical != null && criticalValue !== 'n/a' && criticalValue !== 'not allowed'){
            this.workOrderSizeMeasurementObj.productSZCritical = maxValue;
          }
          if (aqlMajor != null && majorValue !== 'n/a' && majorValue !== 'not allowed'){
            this.workOrderSizeMeasurementObj.productSZMajor = maxValue;
          }
          if (aqlMinor != null && minorValue !== 'n/a' && minorValue !== 'not allowed'){
            this.workOrderSizeMeasurementObj.productSZMinor = maxValue;
          }
        }*/
      }
    
      this.productSizeMeasurementList.forEach((item) => {
        let productSampleSize = null;
        if (inspectionLevel == "Fixed") {
          if (isInitialCalculate) {
            productSampleSize = item.sampleSize;
          } else {
            if(!isChangeAql) {
              productSampleSize = null;
            } else {
              productSampleSize = item.sampleSize;
            }
          }
        } else {
          let filteredSKUSamplingList = samplingPlanByInspectionLevelAndSamplingMethodology?.SKUSamplingList?.filter((x) => x.id == item.workOrderProductId);
          if (filteredSKUSamplingList?.length > 0) {
            if(isInitialCalculate){
              if(samplingMethodology !== this.savedProductWeightSamplingMethodology || item.sampleSize == null) {
                productSampleSize = filteredSKUSamplingList[0].productSampleSize;
              } else {
                productSampleSize = item.sampleSize
              }
            }
            else{
               productSampleSize = filteredSKUSamplingList[0].productSampleSize;
            }
            
          }
        }

        item.sampleSize = productSampleSize;
      }); // end forEach item in productQuantityVerificationList  
      return;
    },

    getAllCountQuantityCarton() {
      let total = null;
      let sumAvailableQuantityCarton = this.productQuantityVerificationList.reduce(function(tot, arr) {
        total += Number(arr.availableQuantityCarton);
        return total;
      }, null);
      return sumAvailableQuantityCarton;
    },

    async saveCarton() {
      let workOrderProductSizeMeasurementResultObjList = [];
      this.cartonSizeMeasurementList.forEach((e) => {
        const addedCartonSizeMeasurement = {
          id: e.id,
          workOrderProductId: e.workOrderProductId,
          isCarton: true,
          specifiedLength: e.specifiedLength,
          specifiedWidth: e.specifiedWidth,
          specifiedHeight: e.specifiedHeight,
          actualLength: e.actualLength,
          actualWidth: e.actualWidth,
          actualHeight: e.actualHeight,
          dimensionalUnit: e.dimensionalUnit == null ? null : e.dimensionalUnit,
          dimensionalResult: e.dimensionalResult,
          specifiedWeight: e.specifiedWeight,
          actualWeight: e.actualWeight,
          weightUnit: e.weightUnit == null ? null : e.weightUnit,
          weightResult: e.weightResult,
          sampleSize: e.sampleSize,
          orderNumber: e.orderNumber,
          acceptCritical: e.acceptCritical,
          acceptMajor: e.acceptMajor,
          acceptMinor: e.acceptMinor,
          foundCritical: e.foundCritical,
          foundMajor: e.foundMajor,
          foundMinor: e.foundMinor,
          rejectCritical: e.rejectCritical,
          rejectMajor: e.rejectMajor,
          rejectMinor: e.rejectMinor,
          szCritical: e.szCritical,
          szMajor: e.szMajor,
          szMinor: e.szMinor,
          sectionId: 2,
        };

        workOrderProductSizeMeasurementResultObjList.push(addedCartonSizeMeasurement);
      });

      if (workOrderProductSizeMeasurementResultObjList.length > 0) {
        await this.utilityFn.getServerDataAsync("post", this.apiList.upsertWorkOrderProductSizeMeasurementResult, workOrderProductSizeMeasurementResultObjList);
      }
    },

    async saveProduct() {
      let workOrderProductSizeMeasurementResultObjList = [];
      this.productSizeMeasurementList.forEach((e) => {
        const addedProductWeightMeasurement = {
          id: e.id,
          workOrderProductId: e.workOrderProductId,
          isCarton: false,
          specifiedLength: e.specifiedLength,
          specifiedWidth: e.specifiedWidth,
          specifiedHeight: e.specifiedHeight,
          actualLength: e.actualLength,
          actualWidth: e.actualWidth,
          actualHeight: e.actualHeight,
          dimensionalUnit: e.dimensionalUnit == null ? null : e.dimensionalUnit,
          dimensionalResult: e.dimensionalResult,
          specifiedWeight: e.specifiedWeight,
          actualWeight: e.actualWeight,
          weightUnit: e.weightUnit == null ? null : e.weightUnit,
          weightResult: e.weightResult,
          sampleSize: e.sampleSize,
          orderNumber: e.orderNumber,
          acceptCritical: e.acceptCritical,
          acceptMajor: e.acceptMajor,
          acceptMinor: e.acceptMinor,
          foundCritical: e.foundCritical,
          foundMajor: e.foundMajor,
          foundMinor: e.foundMinor,
          rejectCritical: e.rejectCritical,
          rejectMajor: e.rejectMajor,
          rejectMinor: e.rejectMinor,
          szCritical: e.szCritical,
          szMajor: e.szMajor,
          szMinor: e.szMinor,
          sectionId: 2,
        };
        workOrderProductSizeMeasurementResultObjList.push(addedProductWeightMeasurement);
      });

      if (workOrderProductSizeMeasurementResultObjList.length > 0) {
        await this.utilityFn.getServerDataAsync("post", this.apiList.upsertWorkOrderProductSizeMeasurementResult, workOrderProductSizeMeasurementResultObjList);
      }
    },

    addChild(item, tab) {
      let newItem = Object.assign({}, item);
      newItem.id = null;
      newItem.workOrderProductId = item.workOrderProductId;
      newItem.clientProductId = item.clientProductId;
      newItem.skuDisplay = item.skuDisplay;
      newItem.isCarton = item.isCarton;
      newItem.specifiedLength = item.specifiedLength;
      newItem.specifiedWidth = item.specifiedWidth;
      newItem.specifiedHeight = item.specifiedHeight;
      newItem.actualLength = null;
      newItem.actualWidth = null;
      newItem.actualHeight = null;
      newItem.dimensionalUnit = item.dimensionalUnit;
      newItem.dimensionalResult = null;
      newItem.specifiedWeight = item.specifiedWeight;
      newItem.actualWeight = null;
      newItem.weightUnit = item.weightUnit;
      newItem.weightResult = null;
      newItem.sampleSize = null;

      newItem.acceptCritical = null;
      newItem.acceptMajor = null;
      newItem.acceptMinor = null;
      newItem.foundCritical = null;
      newItem.foundMajor = null;
      newItem.foundMinor = null;
      newItem.rejectCritical = null;
      newItem.rejectMajor = null;
      newItem.rejectMinor = null;
      newItem.szCritical = null;
      newItem.szMajor = null;
      newItem.szMinor = null;

      newItem.workOrderProductAttachments = item.workOrderProductAttachments;
      newItem.index = item.index + 1;
      switch (tab) {
        case "cartonSize":
          newItem.orderNumber = this.getMaxValue(item.workOrderProductId, true) + 1;

          this.cartonSizeMeasurementList.forEach((x, i) => {
            if (x.index > item.index) {
              x.index = x.index + 1;
            }
          });

          this.cartonSizeMeasurementList.push(newItem);

          break;
        case "productSize":
          newItem.orderNumber = this.getMaxValue(item.workOrderProductId, false) + 1;

          this.productSizeMeasurementList.forEach((x, i) => {
            if (x.index > item.index) {
              x.index = x.index + 1;
            }
          });

          this.productSizeMeasurementList.push(newItem);
          break;
        case "productWeight":
          newItem.orderNumber = this.getMaxValue(item.workOrderProductId, false) + 1;

          this.productSizeMeasurementList.forEach((x, i) => {
            if (x.index > item.index) {
              x.index = x.index + 1;
            }
          });

          this.productSizeMeasurementList.push(newItem);
          break;
        default:
      }
    },

    deleteChild(item, tab) {
      if (tab == "cartonSize") {
        this.deletedIndex = this.cartonSizeMeasurementList.indexOf(item);
     
      } else {
        this.deletedIndex = this.productSizeMeasurementList.indexOf(item);
      }

      this.deletedItem.id = item.id;
      this.deletedItem.workOrderProductId = item.workOrderProductId;
      this.deletedItem.isCarton = item.isCarton;
      this.deletedItem.specifiedLength = item.specifiedLength;
      this.deletedItem.specifiedWidth = item.specifiedWidth;
      this.deletedItem.specifiedHeight = item.specifiedHeight;
      this.deletedItem.actualLength = item.actualLength;
      this.deletedItem.actualWidth = item.actualWidth;
      this.deletedItem.actualHeight = item.actualHeight;
      this.deletedItem.dimensionalUnit = item.dimensionalUnit;
      this.deletedItem.dimensionalResult = item.dimensionalResult;
      this.deletedItem.specifiedWeight = item.specifiedWeight;
      this.deletedItem.actualWeight = item.actualWeight;
      this.deletedItem.weightUnit = item.weightUnit;
      this.deletedItem.weightResult = item.weightResult;
      this.deletedItem.sampleSize = item.sampleSize;
      this.deletedItem.orderNumber = item.orderNumber;
      this.deletedItem.acceptCritical = item.acceptCritical;
      this.deletedItem.acceptMajor = item.acceptMajor;
      this.deletedItem.acceptMinor = item.acceptMinor;
      this.deletedItem.foundCritical = item.foundCritical;
      this.deletedItem.foundMajor = item.foundMajor;
      this.deletedItem.foundMinor = item.foundMinor;
      this.deletedItem.rejectCritical = item.rejectCritical;
      this.deletedItem.rejectMajor = item.rejectMajor;
      this.deletedItem.rejectMinor = item.rejectMinor;
      this.deletedItem.szCritical = item.szCritical;
      this.deletedItem.szMajor = item.szMajor;
      this.deletedItem.szMinor = item.szMinor;

      if (this.deletedItem.id != null) {
        this.utilityFn.getServerData("delete", this.apiList.deleteWorkOrderProductSizeMeasurementResult + "/" + this.deletedItem.id, {}, (result) => {
          if (this.deletedItem.isCarton) {
            this.cartonSizeMeasurementList.splice(this.deletedIndex, 1);
          } else {
            this.productSizeMeasurementList.splice(this.deletedIndex, 1);
          }
        });
      } else {
        if (this.deletedItem.isCarton) {
          this.cartonSizeMeasurementList.splice(this.deletedIndex, 1);
        } else {
          this.productSizeMeasurementList.splice(this.deletedIndex, 1);
        }
      }
      if (this.deletedItem.isCarton) {          
        this.cartonSizeMeasurementList.forEach((x, i) => {
          if (x.index > item.index) {
            x.index = x.index - 1;
          }
        });
        this.calculateFCMResult('tab-1')
      }
      else {
        this.productSizeMeasurementList.forEach((x, i) => {
          if (x.index > item.index) {
            x.index = x.index - 1;
          }
        });
        this.calculateFCMResult('tab-3')
      }
    },

    /**
     * Copy the values of the row before the selected row
     */
    copyAboveRowCarton(index) {
      const aboveRow = this.cartonSizeMeasurementList.find((x) => x.indexMarkerCarton == index - 1);
      const currentRow = this.cartonSizeMeasurementList.find((x) => x.indexMarkerCarton == index);

      currentRow.specifiedLength = aboveRow.specifiedLength;
      currentRow.specifiedWidth = aboveRow.specifiedWidth;
      currentRow.specifiedHeight = aboveRow.specifiedHeight;
      currentRow.actualLength = aboveRow.actualLength;
      currentRow.actualWidth = aboveRow.actualWidth;
      currentRow.actualHeight = aboveRow.actualHeight;
      currentRow.specifiedWeight = aboveRow.specifiedWeight;
      currentRow.actualWeight = aboveRow.actualWeight;
      currentRow.sampleSize = aboveRow.sampleSize;
      currentRow.specifiedWeight = aboveRow.specifiedWeight;
      currentRow.dimensionalResult = aboveRow.dimensionalResult;

      if (this.workOrderSizeMeasurementObj.isVaryingAQL) {
        currentRow.foundCritical = aboveRow.foundCritical;
        currentRow.acceptCritical = aboveRow.acceptCritical;
        currentRow.rejectCritical = aboveRow.rejectCritical;
        currentRow.foundMajor = aboveRow.foundMajor;
        currentRow.acceptMajor = aboveRow.acceptMajor;
        currentRow.rejectMajor = aboveRow.rejectMajor;
        currentRow.foundMinor = aboveRow.foundMinor;
        currentRow.acceptMinor = aboveRow.acceptMinor;
        currentRow.rejectMinor = aboveRow.rejectMinor;
      }
    },

    copyAboveRowProductSize(index) {
      const aboveRow = this.productSizeMeasurementList.find((x) => x.indexMarkerProductSize == index - 1);
      const currentRow = this.productSizeMeasurementList.find((x) => x.indexMarkerProductSize == index);
      currentRow.specifiedLength = aboveRow.specifiedLength;
      currentRow.specifiedWidth = aboveRow.specifiedWidth;
      currentRow.specifiedHeight = aboveRow.specifiedHeight;
      currentRow.actualLength = aboveRow.actualLength;
      currentRow.actualWidth = aboveRow.actualWidth;
      currentRow.actualHeight = aboveRow.actualHeight;
      currentRow.specifiedWeight = aboveRow.specifiedWeight;
      currentRow.actualWeight = aboveRow.actualWeight;
      currentRow.dimensionalResult = aboveRow.dimensionalResult;

      if (currentRow.orderNumber == 1 && aboveRow.orderNumber == 1) {
        currentRow.sampleSize = aboveRow.sampleSize;
      }

      if (this.workOrderSizeMeasurementObj.isVaryingAQL) {
        currentRow.szCritical = aboveRow.szCritical;
        currentRow.szMajor = aboveRow.szMajor;
        currentRow.szMinor = aboveRow.szMinor;
        // currentRow.foundCritical = aboveRow.foundCritical;
        currentRow.acceptCritical = aboveRow.acceptCritical;
        currentRow.rejectCritical = aboveRow.rejectCritical;
        // currentRow.foundMajor = aboveRow.foundMajor;
        currentRow.acceptMajor = aboveRow.acceptMajor;
        currentRow.rejectMajor = aboveRow.rejectMajor;
        // currentRow.foundMinor = aboveRow.foundMinor;
        currentRow.acceptMinor = aboveRow.acceptMinor;
        currentRow.rejectMinor = aboveRow.rejectMinor;
      }
    },

    copyAboveRowProductWeight(index) {
      const aboveRow = this.productSizeMeasurementList.find((x) => x.indexMarkerProductWeight == index - 1);
      const currentRow = this.productSizeMeasurementList.find((x) => x.indexMarkerProductWeight == index);

      currentRow.specifiedWeight = aboveRow.specifiedWeight;
      currentRow.actualWeight = aboveRow.actualWeight;
      currentRow.weightResult = aboveRow.weightResult;

      if (currentRow.orderNumber == 1 && aboveRow.orderNumber == 1) {
        currentRow.sampleSize = aboveRow.sampleSize;
      }

      if (this.workOrderSizeMeasurementObj.isVaryingAQL) {
        // currentRow.foundCritical = aboveRow.foundCritical;
        currentRow.acceptCritical = aboveRow.acceptCritical;
        currentRow.rejectCritical = aboveRow.rejectCritical;
        // currentRow.foundMajor = aboveRow.foundMajor;
        currentRow.acceptMajor = aboveRow.acceptMajor;
        currentRow.rejectMajor = aboveRow.rejectMajor;
        // currentRow.foundMinor = aboveRow.foundMinor;
        currentRow.acceptMinor = aboveRow.acceptMinor;
        currentRow.rejectMinor = aboveRow.rejectMinor;
      }
    },

    resetCartonSizeIndexMarkers(e) {
      if (e.page == 1) {
        this.cartonSizeMeasurementList.forEach((x) => (x.indexMarkerCarton = null));
      }
    },

    resetProductSizeIndexMarkers(e) {
      if (e.page == 1) {
        this.productSizeMeasurementList.forEach((x) => (x.indexMarkerProductSize = null));
      }
    },

    resetProductWeightIndexMarkers(e) {
      if (e.page == 1) {
        this.productSizeMeasurementList.forEach((x) => (x.indexMarkerProductWeight = null));
      }
    },

    onRejectValueChange(tab) {
      switch(tab) {
        case 'productweight':
          this.productSizeMeasurementList.forEach(v => {
            this.ChangeProductSizeLengthWidthHeight(v);
          })
          break;
      }
    },

    // this will be needed by the save feature in the footer component
    async loadData() {
      await this.populateWorkorderList();
    },

    // only save when the isFormDirty is true - means user has changed the value in the UI
    // user can still force saving even if nothing has been changed in UI if "forceSave" is true
    // "forceSave" value will come from the Footer Component. The footer component has a Save button
    // that calls this method and will force this method to call the API to save the values
    async saveData(forceSave = false) {
      if (!forceSave && !this.isFormDirty)
        return

      try {
        const saveDataTableValues = async () => {
          //SizePOMData
          if (this.childWorkOrderSizeMeasurement) {
            if (Object.keys(this.childWorkOrderSizeMeasurement).length !== 0){
                this.workOrderSizeMeasurementObj.totalOfCritical= this.childWorkOrderSizeMeasurement.totalOfCritical? +this.childWorkOrderSizeMeasurement.totalOfCritical : 0;
                this.workOrderSizeMeasurementObj.totalNoOfSamplesMeasured = this.childWorkOrderSizeMeasurement.totalNoOfSamplesMeasured? +this.childWorkOrderSizeMeasurement.totalNoOfSamplesMeasured : 0;
                this.workOrderSizeMeasurementObj.totalFoundPomPlus = this.childWorkOrderSizeMeasurement.totalFoundPomPlus? +this.childWorkOrderSizeMeasurement.totalFoundPomPlus :0;
                this.workOrderSizeMeasurementObj.totalFoundPomMinus = this.childWorkOrderSizeMeasurement.totalFoundPomMinus? +this.childWorkOrderSizeMeasurement.totalFoundPomMinus : 0;
                this.workOrderSizeMeasurementObj.totalNoOfSampleOutOfPom = this.childWorkOrderSizeMeasurement.totalNoOfSampleOutOfPom;
                this.workOrderSizeMeasurementObj.percentageSampleOutOfPom = this.childWorkOrderSizeMeasurement.percentageSampleOutOfPom;
                this.workOrderSizeMeasurementObj.percentageFoundPomPlus = this.childWorkOrderSizeMeasurement.percentageFoundPomPlus;
                this.workOrderSizeMeasurementObj.percentageFoundPomMinus = this.childWorkOrderSizeMeasurement.percentageFoundPomMinus;
            }
          }

          const mappedCartonList = this.cartonSizeMeasurementList.map((x) => _.omit(x, ["indexMarkerCarton"]));
          const mappedCloneCartonList = this.cloneCartonSizeMeasurementList.map((x) => _.omit(x, ["indexMarkerCarton"]));

          const mappedProductList = this.productSizeMeasurementList.map((x) => _.omit(x, ["indexMarkerProductWeight", "indexMarkerProductSize"]));
          const mappedCloneProductList = this.cloneProductSizeMeasurementList.map((x) => _.omit(x, ["indexMarkerProductWeight", "indexMarkerProductSize"]));

          

          let filteredCartonSizeMeasurementList = mappedCartonList.filter((x) => {
            const originalValue = mappedCloneCartonList.find((item) => item.id == x.id);
            if (_.isEqual(x, originalValue)) return false;
            return true;
          });

          let filteredProductSizeMeasurementList = mappedProductList.filter((x) => {
            const originalValue = mappedCloneProductList.find((item) => item.id == x.id);
            if (_.isEqual(x, originalValue)) return false;
            return true;
          });
          console.log(filteredCartonSizeMeasurementList);
          console.log(filteredProductSizeMeasurementList);

          if (filteredCartonSizeMeasurementList.length > 0) {
            await this.saveCarton();
          }
          if (filteredProductSizeMeasurementList.length > 0) {
            await this.saveProduct();
          }
        };

        const updateSection = async () => {
          this.logChanges(false);
          let result = "";

          let workOrderSizeMeasurementObj = this.workOrderSizeMeasurementObj;
          let cartonSizeResult = workOrderSizeMeasurementObj.cartonSizeResultId ? this.workOrderResultList.find((x) => x.id == workOrderSizeMeasurementObj.cartonSizeResultId).name : null;
          let productSizeResult = workOrderSizeMeasurementObj.productSizeResultId ? this.workOrderResultList.find((x) => x.id == workOrderSizeMeasurementObj.productSizeResultId).name : null;

          if (cartonSizeResult == null && productSizeResult == null) {
            result = "";
          } else {
            if (cartonSizeResult == "Fail" || productSizeResult == "Fail") {
              result = "Non-Conformed";
            } else {
              if (cartonSizeResult == "Pending" || productSizeResult == "Pending") {
                result = "Pending";
              } else {
                if (cartonSizeResult == "Pass" || productSizeResult == "Pass") {
                  result = "Conformed";
                } else {
                  if (cartonSizeResult == "N/A" || productSizeResult == "N/A") {
                    result = "N/A";
                  } else {
                    result = "";
                  }
                }
              }
            }
          }
          this.inspectionSummarySectionResult.forEach(x => {
            if(x.sectionId==2){
              x.result = result;
            }
          })
          this.$store.dispatch("inspection/setInspectionSummaryResult", this.inspectionSummarySectionResult);
          if (!this.isSectionDisabled || this.sectionSubmitted) {
            const section = this.sectionDetail;
            section.result = result;

            const index = this._workOrderDetails.workOrderInspectionSections.findIndex((x) => x.sectionId == this.sectionId);
            if (index !== -1) {
              this._workOrderDetails.workOrderInspectionSections[index] = section;
              this.$store.dispatch("inspection/setWorkOrderDetails", _.cloneDeep(this._workOrderDetails));
            }
            
            await this.utilityFn.getServerDataAsync("post", this.apiList.sectionUpdate, section);
          }
          
          if (this.workOrderDetail.resources.length === 1) {
            this.submit_item();
          }
        };

        await Promise.all([this.updateworkOrderSizeMeasurementObj(), saveDataTableValues(), updateSection()]);
        return true;
      } catch (ex) {
        return false;
      }
    },

    async loadWorkOrderRatingOptions() {
      const result = await  this.utilityFn.getServerDataAsync("get", this.apiList.GetCompanyWOResultTypeAliases + "?companyId=" + this.workOrderDetail.clientId, {});
      this.workOrderRatingOptions = result.data.filter((x) => x.description && x.isActive);
    },

    getResultCodes(resultId) {
      const result = this.workOrderResultList.find(x => x.id == resultId);
      const woRating = this.workOrderRatingOptions.find(x => x.aimsSectionRating?.toLowerCase() == result?.name?.toLowerCase());

      if (woRating?.companyWorkOrderResultTypeCodes.length > 0) {
        this.isGenericCodes = false;
        return woRating.companyWorkOrderResultTypeCodes?.filter(x => x.isActive).sort((a,b) => (a.order < b.order) ? -1 : 1) || [];
      }
      this.isGenericCodes = true;
      return this.defaultFailCodeOptions.map(x => ({ code: x }));
    }
  },
  beforeRouteLeave(to, form, next) {
    if(this.IsPOMValid) {
      if((this._workOrderDetails.clientName?.toUpperCase() === "WALMART GLOBAL SOURCING" || this._workOrderDetails.clientName?.toUpperCase() === "ASDA STORES LIMITED") && !this.IsCriticalPOMValid) {
        this.snackbar = true;
        this.message = "Please add at least one Critical POM(s)";
        next(false);
        return;
      }
      if (to.path !== "/error") {
      //dont save if page is still loading
      if(!this.loadingDf) {
        this.saveData(true);
      }
        this.$store.dispatch("inspection/setChildWorkOrderSizeMeasurement", null);
        next(true);
        return;
      } else{
        next(false);
      }
    } else {
        this.snackbar = true;
        this.message = "Please input the Total # of Critical POM and Total No of Samples Measured.";
        next(false);
    }
  },
  beforeDestroy() {},
  watch: {
    isDataLoaded(val1, val2) {
      setTimeout(() => {
        this.isFormReadyCheck = true;
      });
    },
    workOrderSizeMeasurementObj: {
      handler(val1, val2) {
        this.isFormDirty = this.isFormReadyCheck; // && val1 != val 2;
      },
      deep: true
    },
    cartonSizeMeasurementList: {
      handler(val1, val2) {
        this.isFormDirty = this.isFormReadyCheck; // && val1 != val 2;
      },
      deep: true
    },
    productSizeMeasurementList: {
      handler(val1, val2) {
        this.isFormDirty = this.isFormReadyCheck; // && val1 != val 2;
      },
      deep: true
    },
    childWorkOrderSizeMeasurement: {
      handler(val1, val2) {
        //val2 or old value is null if POM/OML Tab is not yet loaded
        if(val2 === null) return;
        if(val2?.isPOM === undefined || val2?.isPOM === null) return;
        this.isFormDirty = this.isFormReadyCheck; // && val1 != val 2;
      },
      deep: true
    },
  }
};
</script>
<style lang="stylus" scoped>
.code-container {
    display: flex;
    flex-direction: column;
}

.helper-container {
    margin-bottom: -18px;
    margin-top: -18px;
    margin-left: 75px;
}
</style>
