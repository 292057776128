import { render, staticRenderFns } from "./AdditionalInformation.vue?vue&type=template&id=7f63a35d&scoped=true&"
import script from "./AdditionalInformation.vue?vue&type=script&lang=js&"
export * from "./AdditionalInformation.vue?vue&type=script&lang=js&"
import style0 from "./AdditionalInformation.vue?vue&type=style&index=0&id=7f63a35d&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f63a35d",
  null
  
)

export default component.exports