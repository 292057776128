<template>
  <div>
    <div class="workOrderInspectionOnsiteId">
      <div style="position: relative;">

        <v-col cols="12 " sm="12" md="12" style="text-align:right;">
          <v-btn id="onsite_submit" v-if="showButton" :disabled="isSectionDisabled" class="submit-btn" @click="submit_item(true)" small depressed color="#1867c0">Submit</v-btn>
        </v-col>
        <v-data-table
          v-model="selectedInspection"
          :fixed-header="true"
          height="700"
          :hide-default-footer="false"
          item-key="forUniqueKey"
          v-bind:headers="showHeaders"
          :items-per-page="-1"
          :items="getWorkInstructionOnsiteTests"
          :sort-by ="['onsiteTestName']"
          class="elevation-1"
          show-select
        >
          <template v-slot:top>           
            <v-toolbar flat color="white">
              <template>
                <v-switch id="isVaryingAQL" color="green" class="mt-5 mx-auto" v-model="isVaryingAQL" inset label="Apply Varying AQL" @change="select_varyinAQL_switch()" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-switch>
                <SpecialInstructions style="margin-left:2%" :workOrderId="$route.params.id"></SpecialInstructions>
              </template>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="750px">
                <template v-slot:activator="{ on }">
                  <v-btn id="onsite_formDialog_addTestCondducted" color="#B14C4C" dark class="mb-2" v-on="on" @click="addTestConducted()" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">Add Other Test Conducted</v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Add/Edit</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field id="onsite_formDialog_onsiteTestName" v-model="formData.name" label="*Onsite Tests Conducted" outlined :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                          <v-text-field maxLength="1500"  id="onsite_formDialog_criteria" v-model="formData.criteria" label="*Criteria" outlined :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                          <v-text-field id="onsite_formDialog_others" v-model="formData.others" :disabled="disableOtherAdd" label="Others" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            id="onsite_formDialog_samplingLevel"
                            v-model="formData.ofSamples"
                            :items="InspectionLevel"
                            @change="changeAddSamplingLevel()"
                            label="*Sampling Level"
                            item-text="samplingLevel"
                            item-value="samplingLevel"
                            outlined
                            :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"
                          ></v-select>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                          <v-text-field id="onsite_formDialog_sampleSize" v-model="formData.sampleSize" label="*Sample Size" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4"> </v-col>
                        <v-col v-show="isVaryingAQL" cols="12" sm="6" md="4">
                          <v-select id="onsite_formDialog_aql" v-model="formData.aql" @change="onChangeAQLLevel($event, formData.ofSamples, null)" :items="aqlLevel" item-value="id" item-text="name" label="*Aql" outlined :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"> </v-select>
                        </v-col>

                        <v-col v-show="isVaryingAQL" cols="12" sm="6" md="4"> </v-col>
                        <v-col v-show="isVaryingAQL" cols="12" sm="6" md="4"> </v-col>

                        <v-col cols="12" sm="6" md="4">
                          <v-text-field id="onsite_formDialog_found" v-model="formData.found" label="Found" @keyup="returnNumber(formData, 'found')" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                          <v-text-field id="onsite_formDialog_accepted" v-model="formData.accepted" label="Acc" @keyup="returnNumber(formData, 'accepted')" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                          <v-text-field id="onsite_formDialog_rejected" v-model="formData.rejected" label="Rej" @keyup="returnNumber(formData, 'rejected')" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                          <v-select id="onsite_formDialog_result" :items="resultList" v-model="formData.result" label="*Result" outlined v-on:change="showDescription(`${formData.result}`)" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-select>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                          <v-textarea id="onsite_formDialog_comments" v-model="formData.comments" outlined label="Comments" rows="1px" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-textarea>
                        </v-col>

                        <v-col cols="12" sm="6" md="4" v-show="failValue">
                          <!--  Change the description for new field-->
                          <v-textarea id="onsite_formDialog_findings" v-model="formData.findings" outlined label="Findings" rows="1px" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-textarea>
                        </v-col>

                        <v-col cols="12" sm="6" md="4" v-show="failValue">
                          <v-textarea id="onsite_formDialog_failReason" v-model="formData.failReason" outlined label="*Fail Reason" rows="1px" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn id="onsite_formDialog_close" color="blue darken-1" text @click="CloseAddDialog()" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">Close</v-btn>
                    <v-btn id="onsite_formDialog_save" color="blue darken-1" :disabled="disableSave" text @click="save" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">Save</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
            <v-row justify="end">
              <v-col cols="4">
                <div class="d-flex justify-end align-center px-4">
                 <v-autocomplete
                    v-bind:items="filteredWorkInstructionList"
                    v-model="workInstructionName"
                    item-text="wiName"
                    item-value="wiName"
                    label="Work Instruction Name"
                    :search-input.sync="searchWorkInstruction"
                    no-data-text="No Data Available"
                    :hide-no-data="!showNoDataText"
                    @click="getWorkInstructions()"
                    :disabled="btnWorkInstructionDisabled"
                    :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"
                  ></v-autocomplete>
                  <v-btn id="onsite_add_workinstruction" class="ml-2" @click="addNewOnsiteTests()" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
                    Add
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-toolbar flat color="white">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select v-model="onsiteTestObj.result" :items="resultListOverall" item-value="value" item-text="text" label="Onsite Test Overall Result" :disabled="disableOverallResultDropdown" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"> </v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-textarea maxlength="200" label="Remarks" v-model="onsiteTestObj.remarks" rows="1px" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-textarea>
                  <!-- <span>Maximum of 200 character</span> -->
                </v-col>
              </v-row>
            </v-toolbar>
            <v-toolbar flat color="white">
              <v-row>
                <v-col offset-sm="6" sm="6">
                  <v-row>
                    <v-col sm="6" md="3">
                      <v-row class="assign-all-container">
                        <label class="result-label">Result</label>
                        <v-select class="select-all-container" v-model="assignResultToAll" :items="items" item-value="value" item-text="text" outlined :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
                        </v-select>
                      </v-row>
                    </v-col>
                    <v-col sm="6" md="2">
                        <v-btn class="ml-2" @click="assignResult()" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">Assign</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>

          <template v-slot:header.deleteAllSelected="{ header }">
            <v-btn icon color="#c02032" @click="deleteSelectedOnsiteDialog()" :disabled="selectedInspection.length <= 0" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
              <v-icon color="#c02032" class="app-header-icon">delete</v-icon>
            </v-btn>
          </template>
          <template v-slot:header.addMinusOption="{ header }">
            <v-btn icon color="#c02032" @click="addOnsiteTestConducted()" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
              <v-icon color="#c02032" class="app-header-icon">mdi-plus</v-icon>
            </v-btn>
          </template>

          <template v-slot:header.image="{ header }">
            <!-- <v-btn icon color="#c02032" @click="addOnsiteTestConducted()">
              <v-icon color="#c02032" class="app-header-icon">mdi-plus</v-icon>
            </v-btn> -->
          </template>

          <template v-slot:item.onsiteTestName="{ item }">
            <v-autocomplete v-model="item.onsiteTestName" style="width: 250px;margin: auto;" v-on:change="changeOnsiteTestName($event,item)" item-text="onsiteTestName" item-value="onsiteTestName" :items="onsiteTestListDropdown" outlined :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-autocomplete>
          </template>
          <template v-slot:item.criteria="{ item }">
                <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="item.criteria" v-bind="attrs" v-on="on" maxLength="1500" outlined style="width: 100px;" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
                  </v-text-field>
                </template>
                <span>{{item.criteria}}</span>
              </v-tooltip>
            <!-- <v-select
              :id="'onsite_onsiteTestCondition_' + item.id"
              style="width: 200px;margin: auto;"
              v-model="item.testCondition"
              @change="changeTestCondition(item)"
              :items="filterTestCondition(item)"
              item-text="testCondition"
              item-value="testCondition"
              outlined
            ></v-select> -->
          </template>

          <template v-slot:item.criteriaBtn="{item}">
            <v-dialog v-model="dialogCriteria" persistent :retain-focus="false">
              <template v-slot:activator="{on}">
                <v-btn x-small v-on="on" @click="criteriaBtnClick(item)" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
                  <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
              </template>
              
              <v-card>
                <v-card-title>Criteria<v-spacer></v-spacer><v-icon @click="dialogCriteria = false" color="error">mdi-close-circle</v-icon></v-card-title>
                <v-card-text>
                  <v-container>
                    <div v-if="characterCount>charactherCountLimit">
                        <span style="color:red">Your Message exceeded maximum number of length 1500. Your text exceeded by: {{characterCount-charactherCountLimit}}</span>
                    </div>
                      <div class="showWysiwyg">
                        <wysiwyg v-model="dialogCriteriaTempText" />
                    </div>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                   <v-btn class="submit" color="#B14C4C" @click="submitDialogCriteria()" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">Submit</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <template v-slot:item.others="{ item }">
            <span>
              <v-text-field :id="'onsite_others_' + item.id" style="width: 100px;margin: auto;" v-model="item.others" :disabled="disableOther(item)" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-text-field>
            </span>
            <span :id="'onsite_others_readonly' + item.id" style="color:red" :hidden="disableOtherMessage(item)" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
              {{ messageOthers }}
            </span>
          </template>

          <template v-slot:item.samplingLevel="{ item }">
            <v-select :id="'onsite_samplingLevel_' + item.id" style="width: 100px;margin: auto;" v-model="item.samplingLevel" :items="InspectionLevel" item-value="samplingLevel" item-text="samplingLevel"  v-on:change="changeSamplinglevel(item)" outlined :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
            </v-select>
          </template>

          <template v-slot:item.sampleSize="{ item }">
                 <v-text-field  :id="'onsite_samplingSize_' + item.id" style="width: 100px;margin: auto;" v-model="item.sampleSize" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-text-field>                  

          </template>

          <template v-slot:item.infoNote="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span><v-icon v-if="item.infoNote != '' && item.infoNote != null" v-bind="attrs" v-on="on" color="rgba(0,0,0,.87)">info</v-icon></span>
              </template>
              <span>{{ item.infoNote}}</span>
               </v-tooltip>
          </template>
          <template v-slot:item.aql="{ item }">
            <v-select :id="'onsite_samplingAql_' + item.id" style="width: 100px;margin: auto;" v-model="item.aql" :items="aqlLevel" item-value="id" item-text="name"  v-on:change="onChangeAQLLevel($event, item.samplingLevel, item)" outlined :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"> </v-select>
          </template>
          <template v-slot:item.found="{ item }">
            <v-text-field :id="'onsite_found_' + item.id" style="width: 50px;margin: auto;" v-model="item.found" @keyup="returnNumber(item, 'found')" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-text-field>
          </template>

          <template v-slot:item.accepted="{ item }">
            <v-text-field :id="'onsite_accepted_' + item.id" style="width: 50px;margin: auto;" v-model="item.accepted" @keyup="returnNumber(item, 'accepted')" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-text-field>
          </template>

          <template v-slot:item.rejected="{ item }">
            <v-text-field :id="'onsite_rejected_' + item.id" style="width: 50px;margin: auto;" v-model="item.rejected" @keyup="returnNumber(item, 'rejected')" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-text-field>
          </template>
          <template v-slot:item.result="{ item }">
            <v-select :id="'onsite_result_' + item.id" :items="items" style="width: 130px;" v-model="item.result" @change="toggle_result(item)" outlined :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-select>
          </template>
          <template v-slot:item.comments="{ item }">
            <v-textarea :id="'onsite_comments     _' + item.id" style="width: 200px;" v-model="item.comments" :disabled="item.result == 0" outlined rows="1px" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-textarea>
          </template>
          <template v-slot:item.findings="{ item }">
            <v-textarea :id="'onsite_findings_' + item.id" style="width: 200px;" v-model="item.findings" outlined rows="1px" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-textarea>
          </template>
          <template v-slot:item.failreason="{ item }">
            <v-textarea :id="'onsite_failreason_' + item.id" style="width: 200px;" v-model="item.failReason" outlined rows="1px" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-textarea>
          </template>
          <template v-slot:item.factoryEquipmentPerformed="{ item }">
            <v-select :id="'onsite_factoryEquipmentPerformed_' + item.id" style="width: 100px;margin: auto;" v-model="item.factoryEquipmentPerformed" :items="factoryResult" item-value="value" item-text="text" outlined :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"> </v-select>
          </template>
          <template v-slot:item.factoryEquipmentCalibrationLabelAvailable="{ item }">
            <v-select :id="'onsite_factoryEquipmentCalibrationLabelAvailable_' + item.id" style="width: 100px;margin: auto;" v-model="item.factoryEquipmentCalibrationLabelAvailable" :items="factoryResult" item-value="value" item-text="text" outlined :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
            </v-select>
          </template>

          <template v-slot:item.data-table-select="{ item }">
            <v-row>
              <v-col>
                <v-checkbox v-model="selectedInspection" :value="item" style="margin:0px;padding:0px"  hide-details disabled:isSectionDisabled :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"/>
              </v-col>
            </v-row>
          </template>      
          <template v-slot:item.addMinusOption="{ item }">
            <v-row>
              <v-col>
                <v-btn icon @click="deleteOnsiteDialog(item)" :disabled="!item.isOnline" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
                  <v-icon color="#c02032">mdi-minus</v-icon>
                </v-btn>
              </v-col>
              <v-col>
              </v-col>
            </v-row>
          </template>

          <template v-slot:item.image="{ item }">
            <v-row>
              <v-col>
                <v-btn icon @click="option_file(item)" :disabled="!item.isOnline" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
                  <!-- <v-icon color="rgba(0,0,0,.87)">image</v-icon> -->
                  <v-icon color="#c02032">mdi-camera</v-icon>
                </v-btn>
              </v-col>
              <v-col>
                <!-- <v-btn icon @click="deleteOnsiteDialog(item)" :disabled="!item.isOnline">
                  <v-icon color="#c02032">mdi-minus</v-icon>
                </v-btn> -->
              </v-col>
            </v-row>
          </template>
        </v-data-table>

        <v-dialog id="onsite_deleteDialog" v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-toolbar flat color="pink" dark>
              <v-card-title>
                <span class="headline">Delete Confirmation</span>
              </v-card-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-label> Are you sure you want to delete? (Y/N) </v-label>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn id="onsite_deleteDialog_no" text @click="dialogDelete = false" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">No</v-btn>
              <v-btn id="onsite_deleteDialog_yes" color="red" text @click="deleteOnsiteTest()" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">Yes</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- 失败原因弹窗 -->
        <v-dialog persistent id="onsite_failDialog" v-model="dialogFail" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Remarks</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field id="onsite_failDialog_comment" v-model="formFailData.comments" label="Comment" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-text-field id="onsite_failDialog_findings" v-model="formFailData.findings" label="Findings" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-text-field id="onsite_failDialog_failReason" v-model="formFailData.failReason" label="Fail Reason* " @input="onChangeFailReason(formFailData.failReason)" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-radio-group v-model="formFailData.factoryEquipmentPerformed" label="Test Performed with Factory Equipment?" row :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
                    <v-radio label="Yes" value="1"></v-radio>
                    <v-radio label="No" value="0"></v-radio>
                    <v-radio label="N/A" value="3"></v-radio>
                  </v-radio-group>
                </v-row>

                <v-row>
                  <v-radio-group v-model="formFailData.factoryEquipmentCalibrationLabelAvailable" label="Factory Equipment current calibration label available?" row :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
                    <v-radio label="Yes" value="1"></v-radio>
                    <v-radio label="No" value="0"></v-radio>
                    <v-radio label="N/A" value="3"></v-radio>
                  </v-radio-group>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn id="onsite_failDialog_close" color="blue darken-1" text @click="close_fail_content()" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">Close</v-btn>
              <v-btn id="onsite_failDialog_save" color="blue darken-1" :disabled="failReasonIsEmpty" text @click="save_fail_content" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- 通过弹窗 -->
        <v-dialog id="onsite_passDialog" v-model="dialogPass" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Remarks</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field id="onsite_passDialog_comment" v-model="formPassData.description" label="Comments" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-radio-group v-model="formPassData.factoryEquipmentPerformed" label="Test Performed with Factory Equipment?" row :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
                    <v-radio label="Yes" value="1"></v-radio>
                    <v-radio label="No" value="0"></v-radio>
                    <v-radio label="N/A" value="3"></v-radio>
                  </v-radio-group>
                </v-row>

                <v-row>
                  <v-radio-group v-model="formPassData.factoryEquipmentCalibrationLabelAvailable" label="Factory Equipment current calibration label available?" row :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
                    <v-radio label="Yes" value="1"></v-radio>
                    <v-radio label="No" value="0"></v-radio>
                    <v-radio label="N/A" value="3"></v-radio>
                  </v-radio-group>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn id="onsite_passDialog_close" color="blue darken-1" text @click="close_pass_content()" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">Close</v-btn>
              <v-btn id="onsite_passDialog_save" color="blue darken-1" text @click="save_pass_content" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- 待定弹窗 -->
        <v-dialog id="onsite_pendingDialog" v-model="dialogPend" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Remarks</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field id="onsite_pendingDialog_comment" v-model="formPendData.description" label="Comments" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-radio-group v-model="formPendData.factoryEquipmentPerformed" label="Test Performed with Factory Equipment?" row :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
                    <v-radio label="Yes" value="1"></v-radio>
                    <v-radio label="No" value="0"></v-radio>
                    <v-radio label="N/A" value="3"></v-radio>
                  </v-radio-group>
                </v-row>

                <v-row>
                  <v-radio-group v-model="formPendData.factoryEquipmentCalibrationLabelAvailable" label="Factory Equipment current calibration label available?" row :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
                    <v-radio label="Yes" value="1"></v-radio>
                    <v-radio label="No" value="0"></v-radio>
                    <v-radio label="N/A" value="3"></v-radio>
                  </v-radio-group>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn id="onsite_pendingDialog_close" color="blue darken-1" text @click="dialogPend = false" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">Close</v-btn>
              <v-btn id="onsite_pendingDialog_save" color="blue darken-1" text @click="save_pend_content" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <div v-if="showFloatingButton">
          <FloatingButton></FloatingButton>
        </div>

        <!-- Progress bar -->
        <v-dialog v-model="showProgressDialog" :persistent="true">
          <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
              <div class="text-center">
                <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </div>

    <v-snackbar id="onsite_snackbar" v-model="snackbar" :timeout="timeout" :color="color">
      {{ message }}
      <v-btn id="onsite_snackbar_close" text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
let state = {
  appHeaderState: {
    show: true,
    title: "Onsite Tests",
    showMenu: true,
    showBack: true,
    showLogo: false,
    showTitle: true,
    showSearch: false,
    showCalendar: true,
  },
};

function setState(store) {
  store.dispatch("navigation/setAppHeader", state.appHeaderState);
}

import { mapActions, mapState } from "vuex";
import Vue from "vue";
import FloatingButton from "../../../components/common/FloatingButton";
import apiDetails from "../../../shared/request/apiDetails";
import * as _ from "lodash";
import {SpecialInstructions} from "../../../components/forms/SpecialInstructions";
import { defineAsyncComponent } from 'vue'


export default {
  name: "workOrderInspectionOnsiteId",
  components: {
    FloatingButton,
    "SpecialInstructions": defineAsyncComponent(() => import('../../../components/forms/SpecialInstructions')) 
  },
  data() {
    return {
      datatableRowsPerPage: {
        "footer-props": {
          "items-per-page-options": [10],
        },
      },
      onsiteTestObj: {
        result: "",
        remarks: "",
      },
      resultListOverall: [
        { text: "", value: 0 },
        { text: "Pending", value: 1 },
        { text: "Pass", value: 2 },
        { text: "Fail", value: 3 },
        { text: "N/A", value: 4 },
      ],
      showFloatingButton: false,
      samplingPlan: [],
      singleSelect: false,
      dialog: false,
      dialogFail: false,
      dialogPass: false,
      dialogPend: false,
      dialogCriteria: false,
      dialogCriteriaTempText: "",
      select_fail: ["Comments", "Findings", "Fail reason"],
      passValue: true,
      failValue: false,
      getValue: "",
      failReasonIsEmpty: true,
      snackbar: false,
      color: "red darken-2",
      message: "",
      timeout: 3000,
      // result state
      items: [
        {
          text: "-- Select --",
          value: 0,
        },
        {
          text: "Pending",
          value: 1,
        },
        {
          text: "Pass",
          value: 2,
        },
        {
          text: "Fail",
          value: 3,
        },
        {
          text: "N/A",
          value: 4,
        },
      ],
      factoryResult: [
        { text: "Yes", value: "1" },
        { text: "No", value: "0" },
        { text: "N/A", value: "3" },
      ],

      resultList: [
        { text: "-- Select --", value: 0 },
        { text: "Pending", value: 1 },
        { text: "Pass", value: 2 },
        { text: "Fail", value: 3 },
        { text: "N/A", value: 4 },
      ],

      thisOpenFailItem: {},
      thisOpenPassItem: {},

      formFailData: {
        comments: "",
        findings: "",
        failReason: "",
        factoryEquipmentPerformed: "",
        factoryEquipmentCalibrationLabelAvailable: "",
      },

      formPassData: {
        description: "",
        factoryEquipmentPerformed: "",
        factoryEquipmentCalibrationLabelAvailable: "",
      },
      formPendData: {
        description: "",
        factoryEquipmentPerformed: "",
        factoryEquipmentCalibrationLabelAvailable: "",
      },

      // defect table data
      defectHeader: [
        { text: "", align: "center", sortable: false, value: "deleteAllSelected" },
        { text: "", align: "center", sortable: false, value: "addMinusOption" },
        { text: "Onsite Tests Conducted", align: "center", sortable: true, value: "onsiteTestName" },
        { text: "Criteria", align: "center", sortable: false, value: "criteria" },
        { text: "", align: "center", sortable: false, value: "criteriaBtn" },
        { text: "Others", align: "center", sortable: false, value: "others", width: "200px" },
        { text: "Sampling Level", align: "center", sortable: false, value: "samplingLevel" },
        { text: "Sample Size", align: "center", sortable: false, value: "sampleSize" },
        { text: "", align: "center", sortable: false, value: "infoNote" },
        { text: "Found", align: "center", sortable: false, value: "found" },
        { text: "Acc", align: "center", sortable: false, value: "accepted" },
        { text: "Rej", align: "center", sortable: false, value: "rejected" },
        { text: "Result", align: "center", sortable: false, value: "result" },
        { text: "Comment", align: "center", sortable: false, value: "comments" },
        { text: "Findings", align: "center", sortable: false, value: "findings" },
        { text: "Fail Reason", align: "center", sortable: false, value: "failreason" },
        { text: "Test Performed w/ Factory Equipment?", align: "center", sortable: false, value: "factoryEquipmentPerformed", width: "150px" },
        { text: "Factory Equipment Current Calibration Label Availble?", align: "center", sortable: false, value: "factoryEquipmentCalibrationLabelAvailable", width: "150px" },
        { text: "", align: "center", sortable: false, value: "image" },
      ],

      defectHeaderAQL: [
        { text: "", align: "center", sortable: false, value: "deleteAllSelected" },
        { text: "", align: "center", sortable: false, value: "addMinusOption" },
        { text: "Onsite Tests Conducted", align: "center", sortable: true, value: "onsiteTestName" },
        { text: "Criteria", align: "center", sortable: false, value: "criteria" },
        { text: "", align: "center", sortable: false, value: "criteriaBtn" },
        { text: "Others", align: "center", sortable: false, value: "others", width: "200px" },
        { text: "Sampling Level", align: "center", sortable: false, value: "samplingLevel" },
        { text: "Sample Size", align: "center", sortable: false, value: "sampleSize" },
        { text: "", align: "center", sortable: false, value: "infoNote" },
        { text: "AQL", align: "center", sortable: false, value: "aql" },
        { text: "Found", align: "center", sortable: false, value: "found" },
        { text: "Acc", align: "center", sortable: false, value: "accepted" },
        { text: "Rej", align: "center", sortable: false, value: "rejected" },
        { text: "Result", align: "center", sortable: false, value: "result" },
        { text: "Comment", align: "center", sortable: false, value: "comments" },
        { text: "Findings", align: "center", sortable: false, value: "findings" },
        { text: "Fail Reason", align: "center", sortable: false, value: "failreason" },
        { text: "Test Performed w/ Factory Equipment?", align: "center", sortable: false, value: "factoryEquipmentPerformed", width: "150px" },
        { text: "Factory Equipment Current Calibration Label Availble?", align: "center", sortable: false, value: "factoryEquipmentCalibrationLabelAvailable", width: "150px" },
        { text: "", align: "center", sortable: false, value: "image" },
      ],

      WorkInstructionOnsiteTests: [],
      defectList: [
        {
          id: "",
          onsiteTest: "",
          condition: "",
          other: "",
          sampleSize: "",
          samplingLevel: "",
          aql: "",
          found: 0,
          accepted: "",
          rejected: "",
          result: 0,
          showRow: "",
          image: "",
        },
      ],
      InspectionLevel: [
        { samplingLevel: "I" },
        { samplingLevel: "II" },
        { samplingLevel: "III" },
        { samplingLevel: "S1" },
        { samplingLevel: "S2" },
        { samplingLevel: "S3" },
        { samplingLevel: "S4" },
        { samplingLevel: "Fixed" },
        { samplingLevel: "N/A" },
      ],

      aqlLevel: [],

      formData: {
        name: "",
        criteria: "",
        others: "",
        sampleSize: "",
        ofSamples: "",
        aql: "",
        found: 0,
        accepted: "",
        rejected: "",
        result: 1,
        comments: "",
        findings: "",
        failReason: "",
        passReason: "",
      },

      forDataOnSiteTest: {
        id: "",
        WorkOrderId: "",
        IsHidden: false,
        TestConducted: "",
        criteria: "",
        SampleSize: "",
        NumberOfSamples: "",
        aql: "",
        found: 0,
        accepted: "",
        rejected: "",
        Result: 0,
        Remarks: "",
        image: null,
        isDeleted: false,
      },

      itemMenu: [
        { title: "Edit", url_name: "workOrderDetailsId", id: 1 },
        // { title: 'Add Photo', url_name: 'pastInspectionsPreview' },
        { title: "Add Defect Photo", url_name: "pastInspectionsSignOff" },
        { title: "Add Inspection Photo from Gallery", url_name: "pastInspectionsSignOff" },
        { title: "Delete", url_name: "pastInspectionsSignOff" },
      ],
      returnedAcRe: [],

      selectedInspection: [],
      workOderList: [],
      onSiteTestConducted: [],
      getItem: [],
      getItemToSend: [],
      isNoRecord: false,
      itemToSend: [],
      workOrderId: "",
      workOrder: {
        resources: [],
        workOrderInspectionSections: [],
      },
      workInstructionId: "",
      newWorkInstructionId: "",
      workInstructionName: "",
      btnWorkInstructionDisabled: false,
      workInstructionList: [],
      filteredWorkInstructionList: [],
      workInstructionListLoaded: false,
      showNoDataText: false,
      searchWorkInstruction: null,

      onsiteList: [],
      selectedOnsiteName: "",
      AllWorkInstructionOnsiteTests: [],
      filteredWorkInstructionOnsiteTests: [],
      search: null,
      search1: null,
      filterCriteria: [],
      filterCriteria1: [],

      sectionId: 6,
      WorkOrderList: [],
      isAuthorized: false,
      sectionData: {
        OnsiteTests: {
          id: 6,
          name: "Onsite Tests",
          isAuthorized: false,
          isSectionSubmitted: true,
        },
      },
      messageOthers: "Must not be blank.",
      loading: false,
      select: null,
      select1: null,
      isDataSave: false,
      onsiteTestNameDelete: "",
      dialogDelete: false,
      deleteItem: null,
      deleteItemList:[],
      isVaryingAQL: false,
      showProgressDialog: false,

      // true if submit button is clicked
      sectionSubmitted: false,

      workInstructionOnsiteTestList: [],
      onsiteTestListDropdown:[],
      searchOnsiteTestName: null,
      filterWorkInstructionOnsiteTestList: [],

      isFormDirty: false,
      isFormReadyCheck: false,
      dialogCriteriaEdit:[],
      assignResultToAll: 0,
      testPerformedOptions: [
        {
          name: "Yes",
          value: "1"
        },
        {
          name: "No",
          value: "0"
        },{
          name: "N/A",
          value: "3"
        },
      ],
      charactherCountLimit: 1500
    };
  },

  beforeRouteLeave(to, form, next) {
    // let onsiteId = "";
    // this.UpsertOnsiteTestResult();
    // if (this.isDataSave == false) {
    //   this.UpsertOnsiteTest();
    // }
    if (to.path !== "/error") {
      this.saveData(true);
    }

    next();
  },
  beforeDestroy() {
    // if (this.workOrder.resources.length === 1) {
    //   this.submit_item();
    // }

    this.logChanges(false);
  },
  async activated() {
    if (window.navigator.onLine !== true) {
      let response = await caches.match(this.apiList.workOrderDetailsOT + "/" + this.$route.params.id);
      if (!response) {
        this.$router.replace({
          path: "/error",
          name: "error",
        });
      }
    }
  },
  async mounted() {
    if (window.navigator.onLine !== true) {
      let response = await caches.match(this.apiList.workOrderDetailsOT + "/" + this.$route.params.id);
      if (!response) {
        this.$router.replace({
          path: "/error",
          name: "error",
        });
      }
    }
    this.isAuthorized = this.$route.params.isAuthorized;
    setState(this.$store);

    this.utilityFn.getServerData("get", this.apiList.getLookupAsync, {}, (res) => {
      this.aqlLevel = res.data.data.aql.filter((x) => x.isActive === true);
    });

    this.samplingPlan = JSON.parse(localStorage.getItem("samplingPlanList"));
    this.showFloatingButton = this.samplingPlan == undefined ? false : this.samplingPlan.showFabButton == false ? false : true;
    this.workOrderId = this.$route.params.id;
    this.loadData();
    this.sectionData = this._sectionData.OnsiteTests ? this._sectionData : this.sectionData;
  },
  watch: {
    search(val) {
      this.AllWorkInstructionOnsiteTests.push({ onsiteTestName: val });
      if (val.length >= 5) {
        val && val !== this.select && this.querySelections(val);
      }
      if (val == "") {
        this.formData.name = "";
      }
    },

    search1(val) {
      val && val !== this.select1 && this.queryCriteria(val);
      if (val == "") {
        this.formData.criteria = "";
      }
    },

    searchWorkInstruction(val) {
      if (!val || val.length < 3) {
        this.workInstructionId = null;
        this.filteredWorkInstructionList = [];
        this.showNoDataText = false;
        return;
      }
      
      this.showNoDataText = true;
      this.filteredWorkInstructionList = this.workInstructionList.filter((x) => x.wiName.toLowerCase().includes(val.toLowerCase()));
    },

    isDataLoaded(val1, val2) {
      setTimeout(() => {
        this.isFormReadyCheck = true;
      });
    },

    WorkInstructionOnsiteTests: {
      handler(val1, val2) {
        this.isFormDirty = this.isFormReadyCheck;
      },
      deep: true
    },

    isVaryingAQL: {
      handler(val1, val2) {
        this.isFormDirty = this.isFormReadyCheck;
      },
      deep: true
    },

    onsiteTestObj: {
      handler(val1, val2) {
        this.isFormDirty = this.isFormReadyCheck;
      },
      deep: true
    }

  },
  computed: {
    ...mapState("inspection", {
      store_token: (state) => state.token,
      _sectionData: (state) => state.sectionData,
      _workOrderDetails: (state) => state.workOrderDetails,
      inspectionSummarySectionResult: (state) => state.inspectionSummarySectionResult,
    }),
    getWorkInstructionOnsiteTests(){
      return this.WorkInstructionOnsiteTests.filter((x) => x.isDeleted == false)
    },
    //Done to get the ordered headers
    showHeaders() {
      if (this.isVaryingAQL) {
        return this.defectHeaderAQL;
      }
      return this.defectHeader;
    },

    disableOtherAdd() {
      let val = true;
      if (this.formData.criteria == "Others") {
        val = false;
      }
      return val;
    },
    disableOverallResultDropdown() {
      let val = true;
      if (this.WorkInstructionOnsiteTests.length > 0) {
        val = false;
      }
      return val;
    },

    disableSave() {
      let val = true;
      if (this.formData.name !== "" && this.formData.criteria !== "" && this.formData.sampleSize !== "" && this.formData.ofSamples !== "") {
        val = false;
        if (this.getValue === "3" && this.formData.failReason === "") {
          val = true;
        }

        if (this.isVaryingAQL && (this.formData.aql === null || this.formData.aql == "")) {
          val = true;
        }
      }

      return val;
    },

    sectionDetail() {
      let section = this._workOrderDetails.workOrderInspectionSections.find((x) => x.sectionId == this.sectionId);
      if (section) {
        section.submitterId = this.utilityFn.getResourceId().toLowerCase();
      } else {
        section = {
          workOrderId: this.workOrder.workOrderId,
          sectionId: this.sectionId,
          isHidden: false,
          submitterId: this.utilityFn.getResourceId().toLowerCase(),
        };
      }

      return section;
    },

    isSectionDisabled() {
      return this.workOrder.resources.length == 0 || (this.workOrder.resources.length > 1 && this.sectionDetail.resourceId.toLowerCase() != this.utilityFn.getResourceId().toLowerCase()) || this.sectionDetail.isHidden || (!!this.sectionDetail.submittedDate && this.workOrder.resources.length > 1);
    },

    showButton() {
      return this.workOrder.resources.length > 1;
    },

    isDataLoaded() {
      return this.workInstructionOnsiteTestList.length > 0;
    },
    characterCount() {
      const div = document.createElement("div");
      div.innerHTML = this.dialogCriteriaTempText;
      const count = div.innerText?.length || 0;
      div.remove();

      return count;
    }
  },
  methods: {
    async loadData() {
      if (window.navigator.onLine === true) {
        this.$store.dispatch("navigation/setLoadingPage",{show: true  });
        //this.showProgressDialog = true;
      }
      const result = await this.utilityFn.getServerDataAsync("get", this.apiList.workOrderDetailsOT + "/" + this.workOrderId, {});
      this.workOrder = result.data[0];
      this.isVaryingAQL = this.workOrder.isOnsiteTestVaryingAQL;
      this.WorkOrderList = result.data[0];
      this.workInstructionId = result.data[0].workInstruction;
      this.workOrderInspectionSections = result.data[0].workOrderInspectionSections.filter((x) => x.sectionId == 6);
      this.onsiteTestObj.remarks = this.workOrderInspectionSections[0].findings;
      let onsiteTestResult = this.workOrderInspectionSections[0].result;
      switch (onsiteTestResult) {
        case "Conformed":
          this.onsiteTestObj.result = 2;
          break;
        case "Non-Conformed":
          this.onsiteTestObj.result = 3;
          break;
        case "Pending":
          this.onsiteTestObj.result = 1;
          break;
        case "N/A":
          this.onsiteTestObj.result = 4;
          break;
        default:
          this.onsiteTestObj.result = 0;
      }
      this.onsiteList = result.data[0].onSiteTests.filter((x) => x.isHidden == true);      
      var isRecompute = this.onsiteList.filter(x => x.recompute == true).length > 0 ? true : false;
      this.newWorkInstructionId = result.data[0].workInstruction2;
      const newWorkInstructionName = result.data[0].workInstruction2Name;
      // set initial option so the WI name will be shown in the dropdown
      if (this.newWorkInstructionId && newWorkInstructionName) {
        this.workInstructionList = [{
          isWalmart: true,
          wiId: this.newWorkInstructionId,
          wiName: newWorkInstructionName,
        }];
        this.filteredWorkInstructionList = this.workInstructionList.slice();
      }

      const protocol = this.workOrder.onsiteTestWorkInstruction ? this.workOrder.onsiteTestWorkInstruction : this.workInstructionId;
      const params = { onsiteTestWorkInstruction: protocol };
      const result2 = await this.utilityFn.getServerDataAsync("post", this.apiList.getWorkInstructionOnsiteTests + "/" + this.workOrder.clientId, params);
      this.workInstructionOnsiteTestList = result2.data.map(x => {
        if(x.onsiteTestCondition.length==0){
          x.onsiteTestCondition =[{'testCondition':'NA'}]
         
        }
         return x;
      });
      this.WorkInstructionOnsiteTests = [];
      let setCriteriaValue = '';
      const aql = {
            aqlCritical: "0",
            aqlMinor: "0",
            aqlMajor: "0"
      }
      //new code
      if(((!this.onsiteList || this.onsiteList.length == 0) && !result.data[0].anyOnSiteTests)) {
        this.WorkInstructionOnsiteTests=result2.data.map((x,index)=>{
                        let onsiteTest=this.onsiteList.filter(o=>o.testConducted===x.onsiteTestName)
                        if(onsiteTest.length!=0){
                          
                          let sampleSize = onsiteTest[0].sampleSize !='null'? onsiteTest[0].sampleSize : '';
                          if (isRecompute){
                            sampleSize = x.onsiteTestConducted.samplingLevel=='Fixed'?  '' : this.samPlan.getOverallProductSampleSizeByInspectionLevel(x.onsiteTestConducted.samplingLevel, aql);
                          }

                          setCriteriaValue = onsiteTest[0].criteria !='null'? this.deleteHtmlTag(onsiteTest[0].criteria) : '';                         
                            x.onsiteTestConducted = x.onsiteTestConducted || {samplingLevel:'',sampleSize:''},
                            x.criteria=setCriteriaValue,
                            x.criteriaToSave= setCriteriaValue == '' ? x.criteria : onsiteTest[0].criteria,

                            x.showRow=onsiteTest[0].isHidden,
                            x.others=onsiteTest[0].others!='null'? onsiteTest[0].others:'',
                            x.samplingLevel=onsiteTest[0].numberOfSamples,
                            x.sampleSize= sampleSize,
                            x.aql=  onsiteTest[0].aql !='null'? onsiteTest[0].aql : '' ,
                            x.found=  onsiteTest[0].found !='null'? onsiteTest[0].found : '' ,
                            x.accepted=  onsiteTest[0].accepted !='null'? onsiteTest[0].accepted : '' ,
                            x.rejected=  onsiteTest[0].rejected !='null'? onsiteTest[0].rejected : '' ,
                            x.result=onsiteTest[0].result==0? 0 : onsiteTest[0].result,
                            x.id=onsiteTest[0].id,
                            x.comments =  onsiteTest[0].comments !='null'? onsiteTest[0].comments : '',
                            x.findings = onsiteTest[0].findings !='null'? onsiteTest[0].findings : '',
                            x.failReason = onsiteTest[0].failReason !='null'? onsiteTest[0].failReason : '' ,
                            x.remarks = onsiteTest[0].remarks !='null'? onsiteTest[0].remarks : '',
                            x.image=null,
                            x.isDeleted=onsiteTest[0].isDeleted,
                            x.factoryEquipmentPerformed = (onsiteTest[0].factoryEquipmentPerformed !== null && onsiteTest[0].factoryEquipmentPerformed !== '') 
                              ? onsiteTest[0].factoryEquipmentPerformed 
                              : (x.onsiteTestConducted.testPerformed !== null && x.onsiteTestConducted.testPerformed !== '') 
                              ? this.testPerformedOptions.find(t => t.name === x.onsiteTestConducted.testPerformed)?.value 
                              : '';
                            x.factoryEquipmentCalibrationLabelAvailable = onsiteTest[0].factoryEquipmentCalibrationLabelAvailable  !=null? onsiteTest[0].factoryEquipmentCalibrationLabelAvailable : '',
                            x.isOnline = true,
                            x.isDeleted = onsiteTest[0].isDeleted,
                            x.infoNote =  onsiteTest[0].sampleSize
                        }
                        else {
                            x.onsiteTestConducted = x.onsiteTestConducted || {samplingLevel:'',sampleSize:''},
                            x.image=null,
                            x.showRow=false,
                            x.others='',
                            x.samplingLevel= x.onsiteTestConducted.samplingLevel,
                            x.sampleSize=x.onsiteTestConducted.samplingLevel=='Fixed'?  '' : this.samPlan.getOverallProductSampleSizeByInspectionLevel(x.onsiteTestConducted.samplingLevel, aql),
                            x.aql='',
                            x.found='',
                            x.accepted='',
                            x.rejected='',
                            x.result=0,
                            x.id='',
                            x.comments = '',
                            x.findings = '',
                            x.failReason = '',
                            x.remarks = '',
                            x.isDeleted=false,
                            x.factoryEquipmentPerformed = (x.onsiteTestConducted.testPerformed !== null && x.onsiteTestConducted.testPerformed !== '') ? this.testPerformedOptions.find(t => t.name === x.onsiteTestConducted.testPerformed)?.value : '',
                            x.factoryEquipmentCalibrationLabelAvailable = '',
                            x.isOnline = true,
                            x.infoNote =  x.onsiteTestConducted.sampleSize,
                            x.forUniqueKey = index,
                            x.criteria = (x.criteria !== null && x.criteria !== '') ? x.criteria : '',
                            x.criteriaToSave = (x.criteria !== null && x.criteria !== '') ? x.criteria : ''
                        }
                        return x
                    });

      } else {           
        this.onsiteList.forEach(x=>{
                const onsiteTestConducted = this.workInstructionOnsiteTestList.filter((y) => y.onsiteTestName == x.testConducted)[0];
                var criteria =x.criteria=='null'|| x.criteria==null?'':this.deleteHtmlTag(x.criteria); 

                var sampleSize = x.sampleSize !='null' ? x.sampleSize: '';
                console.log(sampleSize);
                if (isRecompute && x.numberOfSamples != 'Fixed'){
                  sampleSize = this.samPlan.getOverallProductSampleSizeByInspectionLevel(x.numberOfSamples, aql);
                }

                this.WorkInstructionOnsiteTests.push({
                    onsiteTestName:x.testConducted,
                    onsiteTestConducted : x.onsiteTestConducted || {samplingLevel:'',sampleSize:''},
                    onsiteTestCondition :  [{'testCondition':x.criteria=='null'|| x.criteria==null?'':x.criteria}],
                    criteria: criteria,
                    criteriaToSave: criteria == '' ? '' : x.criteria,
                    testCondition: x.criteria!='null'? x.criteria:'',
                    image:null,
                    showRow:x.isHidden,
                    others:x.others!='null'? x.others:'',
                    samplingLevel: x.numberOfSamples !='null'?  x.numberOfSamples:'',
                    sampleSize: sampleSize,
                    aql: x.aql !='null'? x.aql:'',
                    found: x.found !='null'? x.found:'',
                    accepted: x.accepted !='null'? x.accepted:'',
                    rejected: x.rejected !='null'? x.rejected:'',
                    result:x.result,
                    id:x.id,
                    comments : x.comments!='null'? x.result ==3?x.comments: x.remarks:'',
                    findings : x.findings!='null'? x.findings:'',
                    failReason : x.failReason !='null'?  x.failReason:'',
                    remarks : x.remarks !='null'? x.remarks:'',
                    isDeleted : x.isDeleted,
                    factoryEquipmentPerformed: (x.factoryEquipmentPerformed != null && x.factoryEquipmentPerformed != "") 
                              ? x.factoryEquipmentPerformed 
                              : (onsiteTestConducted?.onsiteTestConducted?.testPerformed !== null && onsiteTestConducted?.onsiteTestConducted?.testPerformed !== '') 
                              ? this.testPerformedOptions.find(t => t.name === onsiteTestConducted?.onsiteTestConducted?.testPerformed)?.value 
                              : '',
                    factoryEquipmentCalibrationLabelAvailable :  x.factoryEquipmentCalibrationLabelAvailable !=null? x.factoryEquipmentCalibrationLabelAvailable:'',
                    isOnline: true,
                    infoNote: onsiteTestConducted? onsiteTestConducted.onsiteTestConducted.sampleSize : '',
                    forUniqueKey: x.id
                });
                 let wiOnsite = this.workInstructionOnsiteTestList.filter(wo=> wo.onsiteTestName===x.testConducted)
                    if(wiOnsite.length===0){
                      this.workInstructionOnsiteTestList.push({
                        isWalmart:true,
                        onsiteTestCondition:[{'testCondition':x.criteria=='null'|| x.criteria==null?'':x.criteria}],
                        onsiteTestConducted:  x.onsiteTestConducted || {samplingLevel:'',sampleSize:''},
                        onsiteTestName:x.testConducted,

                      })
                    }
            }
        ) 
        
      }

       this.onsiteTestListDropdown = _.cloneDeep(this.workInstructionOnsiteTestList)
       this.selectedInspection = [];
     
      //end new code

      this.$store.dispatch("navigation/setLoadingPage",{show: false  });
      //this.showProgressDialog = false;
    },
    CalculateOnsiteTestOverallResult() {
      if (this.WorkInstructionOnsiteTests.filter((x) => x.result === 3 && x.onsiteTestName != "" && x.onsiteTestName != null).length > 0) {
        this.onsiteTestObj.result = 3;
      } else if (this.WorkInstructionOnsiteTests.filter((x) => x.result === 1 && x.onsiteTestName != "" && x.onsiteTestName != null).length > 0) {
        this.onsiteTestObj.result = 1;
      } else if (this.WorkInstructionOnsiteTests.filter((x) => x.result === 2 && x.onsiteTestName != "" && x.onsiteTestName != null).length > 0) {
        this.onsiteTestObj.result = 2;
      } else if (this.WorkInstructionOnsiteTests.filter((x) => x.result === 4 && x.onsiteTestName != "" && x.onsiteTestName != null).length > 0) {
        this.onsiteTestObj.result = 4;
      } else {
        this.onsiteTestObj.result = 0;
      }
    },
    filterTestCondition(item) {
      if (item.onsiteTestName == null || item.onsiteTestName == "") {
        return [{ testCondition: "" }];
      } else {
        return this.onsiteTestListDropdown.filter((x) => x.onsiteTestName == item.onsiteTestName).length > 0
          ? this.onsiteTestListDropdown.filter((x) => x.onsiteTestName == item.onsiteTestName)[0].onsiteTestCondition
          : [{ testCondition: item.testCondition }];
      }
    },
    addOnsiteTestConducted() {
      this.WorkInstructionOnsiteTests.push({
        onsiteTestName: "",
        onsiteTestConducted: null,
        onsiteTestCondition: [{ testCondition: "" }],
        testCondition: "",
        image: null,
        others: "",
        samplingLevel: "",
        sampleSize: "",
        aql: null,
        found: null,
        accepted: null,
        rejected: null,
        result: 0,
        id: null,
        comments: "",
        findings: "",
        failReason: "",
        remarks: "",
        factoryEquipmentPerformed: "",
        factoryEquipmentCalibrationLabelAvailable: "",
        isDeleted: false,
        showRow: false,
        isOnline: window.navigator.onLine,
        criteria:"",
        forUniqueKey: this.WorkInstructionOnsiteTests.length + 1
      });
      this.CalculateOnsiteTestOverallResult();
    },

    deleteSelectedItem () {    
      console.log('selectedInspection',this.selectedInspection)
    },
    disableAddTestConducted() {
      return this.AllWorkInstructionOnsiteTests.length == 0;
    },

    getAQL(id) {
      if (!id || id == "") {
        return null;
      }
      let aqlName = this.aqlLevel.find((x) => x.id === id).name;
      return aqlName;
    },

    getIdAQL(name) {
      if (!name || name == "") {
        return null;
      }
      let aqlId = this.aqlLevel.find((x) => x.name === name).id;
      return aqlId;
    },

    onChangeAQLLevel(aqlValue, inspectionLevel, item) {
      let varyingAQL = null;
      let aC = null;
      let rE = null;

      if (inspectionLevel == "Fixed" || inspectionLevel == "N/A" || aqlValue == "" || aqlValue == null || this.getAQL(aqlValue) == "N/A" || this.getAQL(aqlValue) == "Not Allowed") {
        aC = null;
        rE = null;
      } else {
        varyingAQL = this.samPlan.getVaryingAQL(this.samplingPlan.totalProductPOQty, inspectionLevel, this.workOrder.inspectionLevel, this.getAQL(aqlValue), this.workOrder.samplingPlan);
        aC = varyingAQL.aC;
        rE = varyingAQL.rE;
      }
      if (item == null) {
        this.formData.accepted = aC;
        this.formData.rejected = rE;
      } else {
        item.accepted = aC;
        item.rejected = rE;
      }
      this.WorkInstructionOnsiteTests = this.WorkInstructionOnsiteTests.slice()
    },

    select_varyinAQL_switch() {
      let sendIsVaryingAQL = { isOnsiteTestVaryingAQL: this.isVaryingAQL };
      if (this.workOrder.isOnsiteTestVaryingAQL !== undefined && this.isVaryingAQL !== this.workOrder.isOnsiteTestVaryingAQL) {
        this.utilityFn.getServerDataAsync("post", this.apiList.workOrderDetailsUpdate + "/" + this.workOrderId, sendIsVaryingAQL);
      }
    },

    closeDeleteDialog() {
      this.dialogDelete = false;
    },

    deleteSelectedOnsiteDialog() {
      if(this.selectedInspection && this.selectedInspection.length > 0){
        this.dialogDelete = true;     
        this.deleteItemList = []
        this.deleteItemList = this.selectedInspection;
      }

    },

    deleteOnsiteDialog(item) {
      this.dialogDelete = true;
      this.deleteItemList = []
      this.deleteItemList.push(item);
    },

    deleteOnsiteTest() {
      if (window.navigator.onLine === true) {
        this.showProgressDialog = true;
      }
      this.deleteItemList.forEach(item => {
        var index = this.WorkInstructionOnsiteTests.indexOf(item);
        this.WorkInstructionOnsiteTests[index].isDeleted = true;  
        this.WorkInstructionOnsiteTests = this.WorkInstructionOnsiteTests.slice()
      })
     

      this.showProgressDialog = false;
      this.selectedInspection = [];

      this.CalculateOnsiteTestOverallResult();
      this.dialogDelete = false;
    },
    async deleteFromIndexDB() {
      let reqUrl = this.msgList.onsiteTestURL;
      let searchString = this.workOrder.workOrderId;
      // this.utilityFn.delDupIndexDBEntr(
      //   reqUrl,
      //   searchString,
      //   (res) => {
      //     Promise.resolve(true);
      //   },
      //   (err) => {
      //     Promise.reject(false);
      //   }
      // );
    },
    //Calculate Onsite Test Overall Result
    async UpsertOnsiteTestResult() {
      if (!this.isSectionDisabled || this.sectionSubmitted) {
        const section = this.sectionDetail;
        section.result = this.getOnSiteTestResult();
        section.findings = this.onsiteTestObj.remarks;
        try {
          const index = this._workOrderDetails.workOrderInspectionSections.findIndex((x) => x.sectionId == this.sectionId);
          if (index !== -1) {
            this._workOrderDetails.workOrderInspectionSections[index] = section;
            this.$store.dispatch("inspection/setWorkOrderDetails", _.cloneDeep(this._workOrderDetails));
          }
          
          await this.utilityFn.getServerDataAsync("post", this.apiList.sectionUpdate, section);
        } catch (err) {
          if (window.navigator.onLine != true) {
            this.message = this.msgList.NoConnection;
            this.snackbar = true;
            const index = this._workOrderDetails.workOrderInspectionSections.findIndex((x) => x.sectionId == this.sectionId);
            if (index !== -1) {
              this._workOrderDetails.workOrderInspectionSections[index] = section;
              this.$store.dispatch("inspection/setWorkOrderDetails", _.cloneDeep(this._workOrderDetails));
            }
          }
        }
      }
    },

    async UpsertOnsiteTest() {
      const onsiteDataList = [];
      for (const test of this.WorkInstructionOnsiteTests) {
        if (test.onsiteTestName != "" && test.onsiteTestName != null) {
          const data = {
            id: test.id,
            workOrderId: this.workOrderId,
            testConducted: test.onsiteTestName,
            criteria: test.criteria != this.deleteHtmlTag(test.criteriaToSave) ? test.criteria : test.criteriaToSave,
            sampleSize: test.sampleSize,
            numberOfSamples: test.samplingLevel,
            aql: test.aql,
            found: test.found,
            accepted: test.accepted,
            rejected: test.rejected,
            others: test.others,
            result: test.result,
            isHidden: true,
            comments: test.result ==3? test.comments : '',
            failReason: test.failReason,
            findings: test.findings,
            remarks: test.result ==3? '' : test.comments,
            isDeleted: test.isDeleted,
            factoryEquipmentPerformed: test.factoryEquipmentPerformed,
            factoryEquipmentCalibrationLabelAvailable: test.factoryEquipmentCalibrationLabelAvailable,
            imageFile: test.image ? await this.getImageFile(test.image) : null,
          };
          onsiteDataList.push(data);
        }
      }
      if (onsiteDataList.length === 0) {
        return;
      }
      try {
        await this.utilityFn.getServerDataAsync("post", this.apiList.bulkUpsertWorkOrderOnSiteTests, onsiteDataList);

        let sendIsVaryingAQL = { isOnsiteTestVaryingAQL: this.isVaryingAQL };
        if (this.workOrder.isOnsiteTestVaryingAQL !== undefined && this.isVaryingAQL !== this.workOrder.isOnsiteTestVaryingAQL) {
          await this.utilityFn.getServerDataAsync("post", this.apiList.workOrderDetailsUpdate + "/" + this.workOrderId, sendIsVaryingAQL);
        }
        if (!this.isSectionDisabled || this.sectionSubmitted) {
          this.sectionHelper.updateSectionModifiedDate(this.sectionId);
        }
      } catch (err) {
        if (window.navigator.onLine != true) {
          try {
            if (this.workOrder.isOnsiteTestVaryingAQL !== undefined && this.isVaryingAQL !== this.workOrder.isOnsiteTestVaryingAQL) {
              await this.utilityFn.getServerDataAsync("post", this.apiList.workOrderDetailsUpdate + "/" + this.workOrderId, sendIsVaryingAQL);
            }
          } catch (err) {
            if (!this.isSectionDisabled || this.sectionSubmitted) {
              this.sectionHelper.updateSectionModifiedDate(this.sectionId);
            }
          }
        }
      }
    },

    addTestConducted() {
      this.formData.name = "";
      this.formData.criteria = "";
      this.formData.sampleSize = "";
      this.formData.ofSamples = "";
      this.formData.result = null;
      this.passValue = true;
      this.formData.comments = "";
      this.formData.failReason = "";
      this.formData.findings = "";
      this.formData.aql = "";
      this.formData.found = "";
      this.formData.accepted = "";
      this.formData.rejected = "";
      this.formData.factoryEquipmentPerformed = "";
      this.formData.factoryEquipmentCalibrationLabelAvailable = "";
      this.formData.remarks = "";
      this.passValue = true;
      this.failValue = false;
    },

    blurautocomplete1(item) {
      if (item && item.trim().length > 0) {
        this.filterCriteria1.push({ testCondition: item });
        this.formData.criteria = item;
      }
    },

    blurautocomplete(item) {
      this.formData.name = item;
    },

    disableResult(item) {
      let val = false;
      if (item.testCondition == "" || item.testCondition == "null" || item.testCondition == null) {
        val = true;
      }
      return val;
    },

    getOnSiteTestResult() {
      let result = "";
      switch (this.onsiteTestObj.result) {
        case 0:
          result = "";
          break;
        case 1:
          result = "Pending";
          break;
        case 2:
          result = "Conformed";
          break;
        case 3:
          result = "Non-Conformed";
          break;
        case 4:
          result = "N/A";
          break;
      }
      this.inspectionSummarySectionResult.forEach(x => {
        if(x.sectionId==6){
          x.result = result;
        }
      })
      this.$store.dispatch("inspection/setInspectionSummaryResult", this.inspectionSummarySectionResult);
      
      return result;
    },

    querySelections(v) {
      this.loading = true;
      // Simulated ajax query
      setTimeout(() => {
        this.filteredWorkInstructionOnsiteTests = this.AllWorkInstructionOnsiteTests.filter((e) => {
          return (e.onsiteTestName || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1;
        });
        this.loading = false;
      }, 500);
    },

    queryCriteria(v) {
      this.loading = true;
      // Simulated ajax query
      setTimeout(() => {
        this.filterCriteria1 = this.filterCriteria.filter((e) => {
          return (e.testCondition || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1;
        });
        this.loading = false;
      }, 500);
    },

    changeAddSamplingLevel() {
      if (this.formData.ofSamples != "Fixed" && this.formData.ofSamples != "N/A") {
        const aql = {
            aqlCritical: "0",
            aqlMinor: "0",
            aqlMajor: "0"
        }
        this.formData.sampleSize = this.samPlan.getOverallProductSampleSizeByInspectionLevel(this.formData.ofSamples, aql);
      } else {
        this.formData.sampleSize = "";
        let val = this.filteredWorkInstructionOnsiteTests.filter((x) => x.onsiteTestName == this.formData.name);
        if (val[0].onsiteTestConducted) {
          this.formData.sampleSize = val[0].onsiteTestConducted.sampleSize;
        }
      }
      this.onChangeAQLLevel(this.formData.aql, this.formData.ofSamples, null);
    },

    changeSamplinglevel(item) {
      if (item.samplingLevel != "Fixed" && item.samplingLevel != "N/A") {
        const aql = {
            aqlCritical: "0",
            aqlMinor: "0",
            aqlMajor: "0"
        }
        item.sampleSize = this.samPlan.getOverallProductSampleSizeByInspectionLevel(item.samplingLevel, aql);
      } else {
        item.sampleSize = "";
        let val = this.onsiteTestListDropdown.filter((x) => x.onsiteTestName == item.name);
        if (val[0].onsiteTestConducted) {
          item.sampleSize = val[0].onsiteTestConducted.sampleSize;
        }
      }
      if (this.isVaryingAQL) {
        this.onChangeAQLLevel(item.aql, item.samplingLevel, item);
      }
      this.WorkInstructionOnsiteTests = this.WorkInstructionOnsiteTests.slice()
    },

    changeAQLLevel(item, value) {
      item.aql = value;
      if (item.samplingLevel != "Fixed") {
        const aql = {
            aqlCritical: "0",
            aqlMinor: "0",
            aqlMajor: "0"
        }
        item.sampleSize = this.samPlan.getOverallProductSampleSizeByInspectionLevel(item.samplingLevel, aql);
      } else {
        item.accepted = "";
        item.rejected = "";
      }
    },

    CloseAddDialog() {
      this.formData.name = "";
      this.formData.criteria = "";
      this.formData.sampleSize = "";
      this.formData.ofSamples = "";
      this.formData.aql = "";
      this.formData.found = "";
      this.formData.accepted = "";
      this.formData.rejected = "";
      this.formData.result = 1;
      this.passValue = true;
      this.formData.comments = "";
      this.formData.failReason = "";
      this.formData.findings = "";
      this.formData.remarks = "";
      this.formData.factoryEquipmentPerformed = "";
      this.formData.factoryEquipmentCalibrationLabelAvailable = "";
      this.dialog = false;
    },
    changeOnsiteTestName(onsiteTestName,item) {
      let onsiteTestConducted = this.onsiteTestListDropdown.filter((x) => x.onsiteTestConducted.onsiteTestName == onsiteTestName)[0];
      const aql = {
            aqlCritical: "0",
            aqlMinor: "0",
            aqlMajor: "0"
      }
      var index = this.WorkInstructionOnsiteTests.indexOf(item);
      this.WorkInstructionOnsiteTests[index].onsiteTestConducted = onsiteTestConducted? onsiteTestConducted.onsiteTestConducted : '';
      this.WorkInstructionOnsiteTests[index].samplingLevel = onsiteTestConducted? onsiteTestConducted.onsiteTestConducted.samplingLevel:'';
      this.WorkInstructionOnsiteTests[index].sampleSize =  onsiteTestConducted? onsiteTestConducted.onsiteTestConducted.samplingLevel != "Fixed" ? this.samPlan.getOverallProductSampleSizeByInspectionLevel(onsiteTestConducted.onsiteTestConducted.samplingLevel, aql) : '' : '';
      this.WorkInstructionOnsiteTests[index].infoNote =  onsiteTestConducted?onsiteTestConducted.onsiteTestConducted.sampleSize :'';
      this.WorkInstructionOnsiteTests[index].testCondition = "";
      this.WorkInstructionOnsiteTests[index].factoryEquipmentPerformed = (onsiteTestConducted.onsiteTestConducted.testPerformed !== null && onsiteTestConducted.onsiteTestConducted.testPerformed !== '') ? this.testPerformedOptions.find(t => t.name === onsiteTestConducted.onsiteTestConducted.testPerformed)?.value : '';
      this.WorkInstructionOnsiteTests[index].criteria = (onsiteTestConducted.criteria !== null && onsiteTestConducted.criteria !== '') ? onsiteTestConducted.criteria : ''
      this.WorkInstructionOnsiteTests[index].criteriaToSave = (onsiteTestConducted.criteria !== null && onsiteTestConducted.criteria !== '') ? onsiteTestConducted.criteria : ''
      this.CalculateOnsiteTestOverallResult();
    },
    changeTestCondition(item) {
      item.others = "";
      this.onChange();
    },

    changeCriteria() {
      this.formData.others = "";
      this.onChange();
    },

    onsiteTestKeyDown() {
      // this.formData.name=val
    },

    onsiteTestNameChange() {
      let val = this.filteredWorkInstructionOnsiteTests.filter((x) => x.onsiteTestName == this.formData.name);
      if (val[0].onsiteTestConducted) {
        const aql = {
            aqlCritical: "0",
            aqlMinor: "0",
            aqlMajor: "0"
        }
        this.formData.ofSamples = val[0].onsiteTestConducted.samplingLevel;
        this.formData.sampleSize = val[0].onsiteTestConducted.samplingLevel != "Fixed" ? this.samPlan.getOverallProductSampleSizeByInspectionLevel(val[0].onsiteTestConducted.samplingLevel, aql) : val[0].onsiteTestConducted.sampleSize;
      }
      if (val[0].onsiteTestCondition) {
        this.filterCriteria = val[0].onsiteTestCondition;
      }
    },

    filterCrit(onsiteTestName) {
      let val = this.AllWorkInstructionOnsiteTests.filter((x) => x.onsiteTestName.toLowerCase().includes(onsiteTestName.toLowerCase()));
      return val[0].onsiteTestCondition;
    },

    disableOther(item) {
      let val = true;
      if (item.testCondition == "Others") {
        val = false;
      }
      return val;
    },

    disableOtherMessage(item) {
      let val = true;
      if (item.testCondition == "Others" && (item.others == "" || item.others == null)) {
        val = false;
      }
      return val;
    },

    getLatestOnSite() {
      this.apiFn.api.mainApi.getServerData("get", "/api/AIMS/GetWorkOrdersFromJob/" + this.$route.params.id, {}, (res) => {
        this.workOderList = res.data[0];
        this.onSiteTestConducted = res.data[0].onSiteTests;
        if (this.onSiteTestConducted !== null) {
          this.onSiteTestConducted.forEach((x) => {
            let testdefect = this.defectList.filter((defect) => {
              return defect.onsiteTest === x.testConducted;
            });

            if (testdefect.length > 0) {
              let index = this.defectList.findIndex((y) => y.onsiteTest === x.testConducted);
              this.defectList[index].onsiteTest = x.testConducted;
              this.defectList[index].criteria = x.criteria === "null" ? "" : x.criteria;
              this.defectList[index].sampleSize = x.sampleSize;
              this.defectList[index].ofSamples = x.numberOfSamples;
              this.defectList[index].aql = x.aql;
              this.defectList[index].found = x.found;
              this.defectList[index].accepted = x.accepted;
              this.defectList[index].rejected = x.rejected;
              this.defectList[index].result = x.result;
              this.defectList[index].showRow = x.isHidden;
              this.defectList[index].id = x.id;
              this.defectList[index].remarks = x.remarks;
              this.defectList[index].comments = x.comments;
              this.defectList[index].findings = x.findings;
              this.defectList[index].failReason = x.failReason;
              this.defectList[index].factoryEquipmentPerformed = x.factoryEquipmentPerformed;
              this.defectList[index].factoryEquipmentCalibrationLabelAvailable = x.factoryEquipmentCalibrationLabelAvailable;
            } else {
              this.defectList.push({
                onsiteTest: x.testConducted,
                criteria: x.criteria,
                sampleSize: x.sampleSize,
                ofSamples: x.numberOfSamples,
                aql: x.aql,
                found: x.found,
                accepted: x.accepted,
                rejected: x.rejected,
                result: x.result,
                id: x.id,
                menu: true,
              });
            }
          });
        }
      });
    },

    minus(item) {
      if (item.number == 0) {
        return;
      }
      item.number--;
    },

    plus(item) {
      item.number++;
    },

    option_file(item) {
      var inputObj = document.createElement("input");
      inputObj.setAttribute("id", "_ef");
      inputObj.setAttribute("type", "file");
      inputObj.setAttribute("style", "visibility:hidden");
      inputObj.click();
      inputObj.onchange = (res) => {
        if (inputObj.files.length > 0) {
          const file = inputObj.files[0];
          var sizeInfo = this.utilityFn.formatBytes(file.size);
          if (sizeInfo.sizeKB > 500) {
            this.$store.dispatch("navigation/setSnackbar", {
              color: "error",
              text: `Each upload only accepts image size less than 500KB. Please resize/compact the image with size greater than 500KB. Filename: ${file.name}.`,
            });
          } else {
            item.image = inputObj.files[0];
            this.$store.dispatch("navigation/setSnackbar", {
              text: `Photo successfully added. Filename: ${file.name}.`,
            });
          }
        }
      };
    },

    go_upload_photo() {
      this.$router.push({
        name: "uploadPhotoGalleryId",
        params: { id: this.$route.params.id, name: this.sectionData.OnsiteTests.name, sectionId: this.sectionData.OnsiteTests.id },
        query: { name: this.sectionData.OnsiteTests.name, sectionId: this.sectionData.OnsiteTests.id },
      });
    },

    close() {
      this.formData.name = "";
      this.formData.criteria = "";
      this.formData.sampleSize = "";
      this.formData.aql = "";
      this.formData.found = "";
      this.formData.accepted = "";
      this.formData.rejected = "";
      this.formData.ofSamples = "";
      this.formData.result = 1;
      this.passValue = true;
      this.formData.comments = "";
      this.formData.failReason = "";
      this.formData.findings = "";
      this.formData.remarks = "";
      this.dialog = false;
    },

    save() {
      this.onsiteTestListDropdown.push({ onsiteTestName: this.formData.name, onsiteTestCondition: [{ testCondition: this.formData.criteria }] });
      this.WorkInstructionOnsiteTests.push({
        onsiteTestName: this.formData.name,
        testCondition: this.formData.criteria,
        criteria: this.formData.criteria,
        criteriaToSave: this.formData.criteria,
        sampleSize: this.formData.sampleSize,
        aql: this.formData.aql,
        found: this.formData.found,
        accepted: this.formData.accepted,
        rejected: this.formData.rejected,
        samplingLevel: this.formData.ofSamples,
        result: this.formData.result,
        showRow: true,
        remarks: "",
        comments: this.formData.comments,
        failReason: this.formData.failReason,
        findings: this.formData.findings,
        onsiteTestCondition: { testCondition: this.formData.criteria },
        others: this.formData.others,
        factoryEquipmentPerformed: "",
        factoryEquipmentCalibrationLabelAvailable: "",
        isOnline: window.navigator.onLine,
        isDeleted: false,
        forUniqueKey: this.WorkInstructionOnsiteTests.length + 1
      });
      this.formData.name = "";
      this.formData.criteria = "";
      this.formData.sampleSize = "";
      this.formData.aql = "";
      this.formData.found = "";
      this.formData.accepted = "";
      this.formData.rejected = "";
      this.formData.ofSamples = "";
      this.formData.result = 1;
      this.passValue = true;
      this.formData.comments = "";
      this.formData.failReason = "";
      this.formData.findings = "";
      this.formData.remarks = "";
      this.formData.others = "";
      this.formData.description = "";
      this.formData.factoryEquipmentPerformed = "";
      this.formData.factoryEquipmentCalibrationLabelAvailable = "";
      this.close();
    },

    select_Inspection_switch(item) {
      if (item.showRow === true) {
        item.sampleSize = item.onsiteTestConducted.sampleSize;
        item.samplingLevel = item.onsiteTestConducted.samplingLevel;
      } else {
        item.samplingLevel = "";
        item.sampleSize = "";
        item.others = "";
        item.result = 0;
        item.testCondition = "";
      }
    },

    toggle_result(item) {
      item.comments = "";
      this.WorkInstructionOnsiteTests = this.WorkInstructionOnsiteTests.slice()
      this.CalculateOnsiteTestOverallResult();
      
    },

    // 保存失败原因并关闭弹窗
    save_fail_content() {
      this.dialogFail = false;
      this.WorkInstructionOnsiteTests.map((x) => {
        if (x.onsiteTestName == this.selectedOnsiteName) {
          x.comments = this.formFailData.comments;
          x.findings = this.formFailData.findings;
          x.failReason = this.formFailData.failReason;
          x.factoryEquipmentPerformed = +this.formFailData.factoryEquipmentPerformed;
          x.factoryEquipmentCalibrationLabelAvailable = +this.formFailData.factoryEquipmentCalibrationLabelAvailable;
        }
      });
      this.onChange();
    },

    // 关闭失败原因弹窗
    close_fail_content(item) {
      this.dialogFail = false;
    },

    // 通过提交备注
    save_pass_content() {
      this.dialogPass = false;
      this.WorkInstructionOnsiteTests.map((x) => {
        if (x.onsiteTestName == this.selectedOnsiteName) {
          x.remarks = this.formPassData.description;
          x.factoryEquipmentPerformed = +this.formPassData.factoryEquipmentPerformed;
          x.factoryEquipmentCalibrationLabelAvailable = +this.formPassData.factoryEquipmentCalibrationLabelAvailable;
        }
      });
      this.onChange();
    },

    // 待定提交备注
    save_pend_content() {
      this.dialogPend = false;
      this.WorkInstructionOnsiteTests.map((x) => {
        if (x.onsiteTestName == this.selectedOnsiteName) {
          x.remarks = this.formPendData.description;
          x.factoryEquipmentPerformed = +this.formPendData.factoryEquipmentPerformed;
          x.factoryEquipmentCalibrationLabelAvailable = +this.formPendData.factoryEquipmentCalibrationLabelAvailable;
        }
      });
      this.onChange();
    },

    close_pass_content() {
      this.dialogPass = false;
    },

    onChangeFailReason(item) {
      this.failReasonIsEmpty = true;
      if (item !== "") {
        this.failReasonIsEmpty = false;
      }
      this.onChange();
    },

    showDescription(changeValue) {
      this.passValue = changeValue === "2" || changeValue === "1" ? true : false;
      this.failValue = changeValue === "3" ? true : false;
      this.getValue = changeValue;
    },

    returnNumber(obj, name) {
      if (obj[name] == null) {
        //TAB was pressed
        return;
      }
      if (obj[name] != "") {
        obj[name] = obj[name].toString().replace(/[^-\d.]/g, ""); //清除“数字”和“.”以外的字符
        obj[name] = obj[name].toString().replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
        obj[name] = obj[name]
          .toString()
          .replace(".", "$#$")
          .replace(/\./g, "")
          .replace("$#$", ".");
        obj[name] = obj[name].toString().replace(/^(\-)*(\d+)\.(\d\d\d\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      }
    },

    disableDropDownResult(item) {
      let val = false;
      if (item.criteria === "" || item.sampleSize === "" || item.ofSamples === "") {
        val = true;
      }
      return val;
    },

    async submit_item(sectionSubmitted = false) {
      const errorMessage = await this.validateForm();
      if(errorMessage !== "") {
        this.snackbar = true;
        this.message = errorMessage;
        return;
      }
      
      this.sectionSubmitted = sectionSubmitted;

      const section = {
        sectionId: this.sectionId,
        workOrderId: this.$route.params.id,
      };
      this.utilityFn.getServerData(
        "post",
        this.apiList.submitInspectionSection,
        section,
        (res) => {
          this.sectionData.OnsiteTests.isSectionSubmitted = true;
          this.$store.dispatch("inspection/setSectionData", this.sectionData);

          const index = this._workOrderDetails.workOrderInspectionSections.findIndex((x) => x.sectionId == this.sectionId);
          if (index !== -1) {
            this._workOrderDetails.workOrderInspectionSections[index].submittedDate = new Date();
            this.$store.dispatch("inspection/setWorkOrderDetails", _.cloneDeep(this._workOrderDetails));
          }
        },
        (errFn) => {}
      );
    },

    onChange() {
      this.logChanges();
    },

    logChanges(log = true) {
      window.sessionStorage.setItem("EditDataLogging", log);
    },

    async addWorkInstructionOnsiteTests() {
      const newWorkInstructionId = this.filteredWorkInstructionList.find(w => w.wiName === this.workInstructionName)?.wiId;
      const params = { onsiteTestWorkInstruction: newWorkInstructionId };
      const result = await this.utilityFn.getServerDataAsync("post", this.apiList.getWorkInstructionOnsiteTests + "/" + this.workOrder.clientId, params);
      
    },

    async getWorkInstructions() {
      if (this.workInstructionListLoaded) return

      this.workInstructionListLoaded = true;
      const res = await this.utilityFn.getServerDataAsync("get", this.apiList.workInstructions + "/" + this.workOrder.clientId , { });
      this.workInstructionList = res.data;
      this.filteredWorkInstructionList = this.workInstructionList.slice();
    },

    async getImageFile(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>
          resolve({
            data: reader.result.split(",").pop(),
            fileName: file.name,
            fileType: file.type,
          });
        reader.onerror = (error) => reject(error);
      });
    },

    // only save when the isFormDirty is true - means user has changed the value in the UI
    // user can still force saving even if nothing has been changed in UI if "forceSave" is true
    // "forceSave" value will come from the Footer Component. The footer component has a Save button
    // that calls this method and will force this method to call the API to save the values
    async saveData(forceSave = false) {
      if (!forceSave && !this.isFormDirty)
        return

      try {
        const request = async () => {
          if (this.isDataSave == false) {
            if (window.navigator.onLine !== true) {
             await this.deleteFromIndexDB();
            }
            await this.UpsertOnsiteTest();
          }
          
          if (this.workOrder.resources.length === 1) {
            this.submit_item();
          }
        };

        await Promise.all([this.UpsertOnsiteTestResult(), request()]);

        return true;
      } catch (ex) {
        console.log("saveData error", ex);
        return false;
      }
    },

    async addNewOnsiteTests() {
      if (this.btnWorkInstructionDisabled)
        return

      this.btnWorkInstructionDisabled = true;
      const newWorkInstructionId = this.filteredWorkInstructionList.find(w => w.wiName === this.workInstructionName)?.wiId;
      const params = { onsiteTestWorkInstruction: newWorkInstructionId };
      let result = await this.utilityFn.getServerDataAsync("post", this.apiList.getWorkInstructionOnsiteTests + "/" + this.workOrder.clientId, params);
      // loop through each data and only add the unique value
      for (const v of result.data) {
        // set default option value for onsiteTestCondition if empty
        v.onsiteTestCondition = v.onsiteTestCondition.length === 0 ? [{'testCondition':'NA'}] : v.onsiteTestCondition;
        
        // add only onsite tests that is not yet present in the current list
        const index = this.WorkInstructionOnsiteTests.findIndex(x => x.onsiteTestName?.toLowerCase() === v.onsiteTestName?.toLowerCase());
        if (index === -1) {
          const aql = {
            aqlCritical: "0",
            aqlMinor: "0",
            aqlMajor: "0"
          }
          this.WorkInstructionOnsiteTests.push({
            onsiteTestName: v.onsiteTestName,
            onsiteTestConducted : v.onsiteTestConducted || { samplingLevel:'', sampleSize:'' },
            onsiteTestCondition : v.onsiteTestCondition,
            image: null,
            showRow: false,
            samplingLevel: v.onsiteTestConducted.samplingLevel,
            sampleSize: v.onsiteTestConducted.samplingLevel ==='Fixed' ?  '' : this.samPlan.getOverallProductSampleSizeByInspectionLevel(v.onsiteTestConducted.samplingLevel, aql),
            result: 0,
            isDeleted : false,
            isOnline: true,
            infoNote: v.onsiteTestConducted.sampleSize,
            criteria: (v.criteria !== null && v.criteria !== '') ? v.criteria : '',
            factoryEquipmentPerformed: (v.onsiteTestConducted.testPerformed !== null && v.onsiteTestConducted.testPerformed !== '') ? this.testPerformedOptions.find(t => t.name === v.onsiteTestConducted.testPerformed)?.value : '',
            forUniqueKey: this.WorkInstructionOnsiteTests.length + 1
          });

          // update the dropdown option to include the new onsite test name
          const option = this.onsiteTestListDropdown.find(x => x.onsiteTestName?.toLowerCase() === v.onsiteTestName?.toLowerCase());
          if (!option) {
            const aql = {
            aqlCritical: "0",
            aqlMinor: "0",
            aqlMajor: "0"
            }
            this.onsiteTestListDropdown.push({ 
              onsiteTestConducted : v.onsiteTestConducted || { samplingLevel:'', sampleSize:'' },
              onsiteTestName: v.onsiteTestName,
              onsiteTestCondition: v.onsiteTestCondition,
              samplingLevel: v.onsiteTestConducted.samplingLevel,
              sampleSize: v.onsiteTestConducted.samplingLevel ==='Fixed' ?  '' : this.samPlan.getOverallProductSampleSizeByInspectionLevel(v.onsiteTestConducted.samplingLevel, aql),
              infoNote: v.onsiteTestConducted.sampleSize,
            });
          }
        }        
      }
      this.WorkInstructionOnsiteTests = this.WorkInstructionOnsiteTests.slice();

      // Update OnsiteTestWorkInstruction
      // Only add unique value
      const splitCurrentOnsiteTestWorkInstruction = this.workOrder.onsiteTestWorkInstruction ? this.workOrder.onsiteTestWorkInstruction.split('|').map(x => x.trim()) : this.workOrder.workInstruction ? [this.workOrder.workInstruction] : [];
      const splitNewUniqueOnsiteTestWorkInstruction = newWorkInstructionId?.split('|').filter(x => !splitCurrentOnsiteTestWorkInstruction.includes(x?.trim())) || [];
      const splitOnsiteTestWorkInstruction = [...splitCurrentOnsiteTestWorkInstruction, ...splitNewUniqueOnsiteTestWorkInstruction];
      const onsiteTestWorkInstruction = splitOnsiteTestWorkInstruction.join('|');
      this.workOrder.onsiteTestWorkInstruction = onsiteTestWorkInstruction;

      // Update ProtocolNo
      // Only add unique value
      const splitCurrentProtocolNo = this.workOrder.protocolNo?.split(',').map(x => x.trim()) || [];
      const splitNewUniqueProtocolNo = newWorkInstructionId?.split(',').filter(x => !splitCurrentProtocolNo.includes(x?.trim())) || [];
      const splitProtocolNo = [...splitCurrentProtocolNo, ...splitNewUniqueProtocolNo];
      const trimmed = splitProtocolNo.map(x => {return x.trim()});
      const protocolNo = trimmed.join(', ');
      this.workOrder.protocolNo = protocolNo; 
      
      const data = [
        {
          op: "replace",
          path: "/ProtocolNo",
          value: protocolNo,
        },
        {
          op: "replace",
          path: "/OnsiteTestWorkInstruction",
          value: onsiteTestWorkInstruction,
        },
        {
          op: "replace",
          path: "/WorkInstruction2",
          value: newWorkInstructionId,
        },
      ];
      await  this.utilityFn.getServerDataAsync("patch", this.apiList.patchWorkOrderResults + "/" + this.workOrderId + "/6", data);
      
      // force refresh the dynamic form/ work order details page to reflect the updated value
      this.$store.dispatch("inspection/setDynamicFormForceRefresh", true);
      this.btnWorkInstructionDisabled = false;
    },

    // return a message if invalid, empty message means valid
    // make async for future update if need api call and for uniformity for other section if need to add validations
    async validateForm() {
      const activeOnsiteTests = this.WorkInstructionOnsiteTests.filter(x => !x.isDeleted);
      const hasMissingOnsiteTestResults = activeOnsiteTests.filter(x =>!x.result)?.length > 0;
      var sampleSizeNotEmpty = activeOnsiteTests.filter(x => (x.samplingLevel.toLowerCase() == "fixed" 
                                                                    && !x.sampleSize
                                                                    && x.sampleSize.toLowerCase() != "n/a") 
                                                                    || !x.sampleSize)?.length > 0;
      return hasMissingOnsiteTestResults ? 'Please assign the Onsite Test Result' : sampleSizeNotEmpty ? 'Please fill in Onsite Test Sample Size' : '';
    },
    submitDialogCriteria(){
      if (this.characterCount <= this.charactherCountLimit){
        this.dialogCriteria = false;
        var index = this.WorkInstructionOnsiteTests.indexOf(this.dialogCriteriaEdit);
        this.dialogCriteriaEdit.criteria = this.deleteHtmlTag(this.dialogCriteriaTempText);
        this.dialogCriteriaEdit.criteriaToSave = this.dialogCriteriaTempText;
        var editItem = Object.assign({},this.dialogCriteriaEdit);
        Object.assign(this.WorkInstructionOnsiteTests[index], editItem);
      }
    },
    criteriaBtnClick(item){
      this.dialogCriteriaEdit = item
      this.dialogCriteriaTempText = item.criteria != this.deleteHtmlTag(item.criteriaToSave) ? item.criteria : item.criteriaToSave;
    },
    deleteHtmlTag(value){
      const div = document.createElement('div');
      div.innerHTML = value;
      return div.textContent || div.innerText || '';
    },
    assignResult() {
      if(this.selectedInspection.length === 0) return;
      if(this.assignResultToAll === 0) return;

      this.selectedInspection.forEach(s => {
        var index = this.WorkInstructionOnsiteTests.indexOf(s);
        if(this.WorkInstructionOnsiteTests[index].result !== 0) return;
        this.WorkInstructionOnsiteTests[index].result = this.assignResultToAll;
      })

      this.WorkInstructionOnsiteTests = this.WorkInstructionOnsiteTests.slice();
      this.CalculateOnsiteTestOverallResult();
    }
  },
};
</script>

<style lang="stylus" scoped>
.workOrderInspectionOnsiteId
    padding 15px 10px

.submit-btn
    color: #fff !important

.showWysiwyg
    position: relative !important

.submit
    display block
    margin 15px auto
    margin-top 50px
    width 60%
    color: #cccac6 !important

.assign-all-container
    margin-left: 3px

.select-all-container
    width: 50%

.result-label
    margin-top: 20px
    margin-right: 10px
</style>
