#DynamicFormTable

<template>
    <div>
        <v-data-table
        :headers="headers"
        :items="datasource"
        :items-per-page="-1"
        :disable-sort="true"
        class="elevation-1"
        :auto-id="'dynamic table'+sectionName"
        >
            <template v-for="atom in editableColumns" v-slot:[`item.${atom.label}`]="{ item }">
                <div :key="atom.formAtomID" @contextmenu="rightClickHandler($event, item, atom.label)">
                    <v-edit-dialog
                        :return-value="item[atom.label]"
                        > {{ item[atom.label] }}
                            <template v-slot:input>
                                    <v-text-field v-if="atom.controlType == 'textbox'" v-model="item[atom.label]" :label="atom.label" single-line counter @change="sava_field_value(atom.formAtomID, item[atom.label], item['Key'])"></v-text-field>
                                    <v-select v-if="atom.controlType == 'dropdown'" v-model="item[atom.label]" :label="atom.label" :items="getItems(atom)" @change="sava_field_value(atom.formAtomID, item[atom.label], item['Key'])"></v-select>

                                    <v-menu v-if="atom.controlType == 'date'" 
                                    v-model="dateMenu[item['Key']]"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="item[atom.label]"
                                                :label="atom.label"
                                                prepend-icon="mdi-calendar"
                                                readonly                                                
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        :key="item['Key']"
                                        :value="computedDateFormatted(item[atom.label])" 
                                        @input="dateMenu[item['Key']] = false"
                                        @change="dateChange($event, item, atom.label, atom.formAtomID, item['Key'])"
                                        no-title
                                        color="blue darken-1"
                                        scrollable>                        
                                        </v-date-picker>
                                    </v-menu>
                            </template>
                    </v-edit-dialog>
                </div>
            </template>
        </v-data-table>

        <v-menu v-model="showMenu" :position-x="x" :position-y="y" :key="1" absolute offset-y >
            <v-list>
                <v-list-item
                :key="1" link @click="copy">
                <v-list-item-title>Copy To All</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

    </div>
</template>

<script>

export default {
    name: 'DynamicFormTable',
    data (){
        return {
            datasource: [],
            headers: [],
            editableColumns: [],
            dateMenu: [],
            showMenu: false,
            x: null,
            y: null,
            copyValue: ''
        }
    },
    computed: {
    },
        mounted() {
            this.datasource = JSON.parse(this.msgData.values[0].value);

            this.datasource = this.datasource.filter((value, index, self) => {
                return self.findIndex(x => { return x.Key === value.Key; }) === index;
            });

            if(this.msgFormAtoms) {
                const columns = this.msgFormAtoms.filter(x=> x.columnSize === 0);

                columns.forEach(header => {
                    this.headers.push({text: header.label, value: header.label});
                    if(header.isEditable)
                        this.editableColumns.push(header);
                });
            }

    },
    methods: {
        computedDateFormatted (date) {
            if(!date)
                return new Date().toJSON();
            return new Date(date).toJSON();
        },
        sava_field_value (id, val, key) {
            var data = [{
                "formAtomID": id,
                "value": val,
                "referenceKey": key
            }]

              this.utilityFn.getServerData('post', this.apiList.baseUrl + '/api/AIMS/UpsertFormValue/' + this.$route.params.id, data, res => {
                  console.log(res)

                  //this.sectionHelper.updatedDynamicSectionModifiedDate(this.sectionName);
              })
        },



        getItems(atom) {
            return atom.fieldLookupValues.result.map(x => {
                return { text: x.value, value: x.value }
            });
        },
        formatDate (date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        dateChange(event, atom, index, formAtomID, key) {
            atom[index] = this.formatDate(event);
            this.sava_field_value(formAtomID, atom[index], key);
        },
        rightClickHandler(event, item, col) {
            event.preventDefault();

            this.x = event.x;
            this.y = event.y+10;
            this.showMenu = true;

            this.copyCol = col;
            this.copyValue = item[col];
        },
        copy() {

            this.datasource.forEach((x,i) => {
                let atomID = this.editableColumns.filter(x=>x.label === this.copyCol)[0].formAtomID
                x[this.copyCol] = this.copyValue;
                this.sava_field_value(atomID, this.copyValue, x['Key']);
            });


            this.copyValue = null;
            this.copyCol = null;
        }
    },
    props: {
        msgData:{
            type: Object
        },
        msgFormAtoms:{
            type: Array
        },
        sectionName: {
            type: String
        }
    }
};
</script>

<style lang="stylus" scoped>
</style>
