<template>
  <transition name="slide-down">
    <header class="app-header-wrapper" v-show="show" :style="!online ? 'background-color:rgb(107, 107, 107)' : ''">
      <div class="app-header-left" style="min-width: 230px; margin-left: 20px">
        <v-btn icon v-if="showBack" @click.native="handleClick('back')">
          <v-icon color="white" class="app-header-icon">arrow_back</v-icon>
        </v-btn>
        <div id="logo" v-if="showLogo" @click="handleClick('logo')">
          <slot name="logo">
            <img v-if="logoIcon.src" :src="logoIcon.src" :alt="logoIcon.alt" class="app-header-icon" />
          </slot>
        </div>
        <slot name="title" v-if="showTitle && title != 'Barcode Verification'">
          {{ title }}
        </slot>
        
        <slot name="title" v-if="showTitle && title == 'Barcode Verification'">
          {{ title }} 
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <div v-on="on">
                  <v-icon color="blue">mdi-information-outline</v-icon>
                </div>
            </template>
            <span>Readability and Consistency within the Same Packaging</span>
        </v-tooltip>   
        </slot>

        <slot name="title" v-if="!showTitle">
          <img src="../../assets/images/ulsolutions_logo_horz_wht_rgb.png" style="width: 90px; margin-left: 10px"/>
        </slot>
      </div>

      <div class="app-header-middle" v-cloak>
        <v-text-field id="jobIDWorkOrderSearch" v-if="showSearch_job" dense dark label="Job ID / Work Order Search" prepend-inner-icon="search" hide-details @input="go_search(searchValue)" style="margin-top: 0px" v-model="searchValue"></v-text-field>

        <v-text-field
          id="workOrderProductSearch"
          v-if="showSearch_work"
          dense
          dark
          label="Work Order / Product Search"
          prepend-inner-icon="search"
          hide-details
          @input="go_search(searchValue)"
          style="margin-top: 0px"
          v-model="searchValue"
        ></v-text-field>

        <div v-if="isPageLoadingWarning" style="text-align: center; width: 100%; color: #FFFF00; font-size: 12px">
          Note: If you experience any page loading issue, please refresh your browser or clear your cache
        </div>
      </div>

      <div class="app-header-right" style="text-align: right; display: inline">

        <v-btn v-if="showSubmitFormButton && !showSearch_job && !showSearch_work" id="btnSubmitForm" color="#00518a" @click="go_resubmitPage()" style="margin-right: 20px;"> Submit Form</v-btn>
        <span id="online" style="font-size: 14px" v-show="online && onlineIsShow">
          <v-icon color="#fff" style="padding-bottom: 5px">wifi</v-icon>
          Online
        
        </span>
        <span id="offline" style="font-size: 14px" v-show="!online && onlineIsShow">
          <v-icon color="#fff" style="padding-bottom: 5px">signal_wifi_off</v-icon>
          Offline
       
          <v-btn @click="requestNotificationPermission()" 
          >Subscribe</v-btn>
        </span>
        <span id="calendar" v-if="showCalendar" style="margin: 0 15px; position: relative" @click="go_downloadCalendar">
          <v-icon color="#fff" style="padding-bottom: 5px">mdi-calendar-check</v-icon>
          <span
            style="
              position: absolute;
              top: -7px;
              right: -7px;
              font-size: 12px;
              background: green;
              border-radius: 20px;
              width: 20px;
              text-align: center;
            "
            >{{ calendarNumber }}</span
          >
        </span>
        <span id="downloadIsNotEqualToZero" v-if="onlineDownload && downLoadNumber != 0 && isLoggedIn" style="margin: 0 15px; position: relative" @click="go_downloadList">
          <v-icon color="#fff">cloud_download</v-icon>
          <span
            style="
              position: absolute;
              top: -7px;
              right: -7px;
              font-size: 12px;
              background: green;
              border-radius: 20px;
              width: 20px;
              text-align: center;
            "
            >{{ downLoadNumber }}</span
          >
        </span>

        <span id="downloadIsEqualToZero" v-if="onlineDownload && downLoadNumber == 0 && isLoggedIn" style="margin: 0 15px; position: relative">
          <v-icon color="#fff">cloud_download</v-icon>
          <span
            style="
              position: absolute;
              top: -7px;
              right: -7px;
              font-size: 12px;
              background: green;
              border-radius: 20px;
              width: 20px;
              text-align: center;
            "
            >{{ downLoadNumber }}</span
          >
        </span>

        <span id="resourceName" v-if="showResource">
          {{ resourceName }}
        </span>
        <slot id="actionSlot" name="actions" v-for="(action, actionIdx) in actions" :icon="action.icon" :route="action.route">
          <v-btn id="actionButton" icon="icon" @click.native="handleClick('action', { actionIdx, route: action.route })">
            <v-icon color="white" v-if="action.icon" class="app-header-icon">{{ action.icon }}</v-icon>
          </v-btn>
        </slot>

        <v-btn id="menu" icon v-if="showMenu" @click.native="handleClick('menu')">
          <v-icon color="white" class="app-header-icon">menu</v-icon>
        </v-btn>
        <v-btn id="edit" icon v-if="pageName == 'pastInspectionsPreview'" @click.native="go_workOrderDetails()">
          <v-icon color="white" class="app-header-icon">edit</v-icon>
        </v-btn>

        <v-btn id="info" icon v-if="cost" @click.native="go_expense('menu')">
          <v-icon color="white" class="app-header-icon">info</v-icon>
        </v-btn>
      </div>
      <v-snackbar v-model="snackbar" :timeout="timeout" :color="color">
        {{ message }}
        <v-btn id="close" text @click="snackbar = false"> Close </v-btn>
      </v-snackbar>
    </header>
  </transition>
</template>

<script>
import { mapState } from "vuex";
import apiDetails from "../../shared/request/apiDetails";

export default {
  name: "appHeader",
  data() {
    return {
      value: "",
      calendarNumber: 0,
      snackbar: false,
      color: "#4caf50",
      message: "",
      timeout: 3000,
       online: true,
      searchValue: "",
    };
  },
  computed: {
    ...mapState("navigation", {
      show: (state) => state.header.show,
      showMenu: (state) => state.header.showMenu,
      showBack: (state) => state.header.showBack,
      showLogo: (state) => state.header.showLogo,
      logoIcon: (state) => state.header.logoIcon,
      title: (state) => state.header.title,
      showTitle: (state) => state.header.showTitle,
      showSearch_job: (state) => state.header.showSearch_job, 
      showSearch_work: (state) => state.header.showSearch_work,
      actions: (state) => state.header.actions,
      pageName: (state) => state.header.pageName,
      onlineDownload: (state) => state.header.onlineDownload,
      onlineIsShow: (state) => state.header.onlineIsShow,
      cost: (state) => state.header.cost,
      showCalendar: (state) => state.header.showCalendar,
      showSubmitFormButton: (state) => state.header.showSubmitFormButton,
      showResource: (state) => state.header.showResource,
    }),
    ...mapState("navigation", {
      isPageSwitching: (state) => state.common.isPageSwitching,
    }),
    ...mapState("inspection", ["workOrder", "downLoadNumber", "resourceName"]),
    ...mapState("inspection", {
      search_value: (state) => state.searchValue,
      workOrderDetails: (state) => state.workOrderDetails,
      isLoggedIn: (state) => state.isLoggedIn
    }),
    
    isPageLoadingWarning() {
      const routes = [
          'dynamicFormId',
          'workOrderDetailsId',
          'uploadPhotoGalleryId',
          'uploadPhotoGalleryId',
          'workOrderInspectionQuantityId',
          'workOrderInspectionSizeId',
          'workOrderInspectionPackageId',
          'workOrderInspectionBarcodeId',
          'workOrderInspectionWorkmanshipId',
          'workOrderInspectionOnsiteId',
          'workOrderDefectId',
          'workOrderDefectEditId',
          'uploadPhotoInspectionId',
          'workOrderRemarksId',
          'workOrderDefectAddDefectPhotoId',
          'uploadPhotoGeneralId',
          'workOrderInspectionProductConformityId'
      ];

      return routes.indexOf(this.$route.name) != -1;
    }
  },
  watch: {
    search_value(val) {
      this.searchValue = val;
    },
  },
  mounted() {
    //console.log('mounted', this)
    window.addEventListener("online", () => {
      this.$store.dispatch("navigation/setOnline", true);
      //background sync using logs (need to change to workbox)
      //this.utilityFn.submitList();
      this.online = true;
    });
    window.addEventListener("offline", () => {
      this.$store.dispatch("navigation/setOnline", false);
       this.online = false;
    });
  },
  methods: {
    requestNotificationPermission() {
              
          Notification.requestPermission().then(result => {
            if(result === 'granted') {
              this.snackbar = true;
              this.color = "#4caf50";
              this.message = "You have accepted Notification,will send sync status for each post request";
            }
            else if(result === 'denied')
            {
                this.snackbar = true;
                this.color = "#4caf50";
               this.message = "You have not accepted Notification,please check console for sync status";
            }
            
           
          });
        },
    handleClick(source, { actionIdx, route } = {}) {
      if (this.isPageSwitching) {
        return;
      }
      let eventData = {};

      if (source === "action") {
        eventData.actionIdx = actionIdx;
      }

      this.$emit(`click-${source}`, eventData);
      if (route) {
        this.$router.push(route);
      }
    },
    go_search(value) {
      this.$store.dispatch("inspection/setSearchValue", value);
    },
    go_workOrderDetails() {
      localStorage.setItem("selectedWorkOrder", JSON.stringify(this.workOrder));
      this.apiFn.query.workOrderId = this.workOrder.displayId;
      this.$router.push({
        name: "workOrderDetailsId",
        params: { id: this.workOrder.id },
        query: { displayId: this.workOrder.displayId },
      });
    },
    go_downloadList() {
      this.$router.push({ name: "download" });
    },
    go_expense() {
      this.$router.push({
        name: "expenseDetail",
        query: { id: this.$route.query.id, jobId: this.$route.query.jobId },
      });
    },
    go_downloadCalendar() {
      const FileDownload = require("js-file-download");
      let resourceId = apiDetails.resourceId();
      this.utilityFn.getServerData(
        "get",
        this.apiList.getInspectionCalendar + "/" + resourceId,
        {},
        (res) => {
          FileDownload(res.data.data, "UpcomingInspections.ics");
          this.snackbar = true;
          this.color = "#4caf50";
          this.message = "UpcomingInspections.ics downloaded";
        },
        {}
      );
    },
    async go_resubmitPage() {
      if((this.workOrderDetails?.clientName.toLowerCase() === 'walmart global sourcing' || this.workOrderDetails?.clientName.toLowerCase() === 'asda stores limited') && this.workOrderDetails?.isPreShipOrFinalRandom === true) {
        //validate barcode verification
        var barcodeVerificationErrorMessage = await this.utilityFn.getServerDataAsync('get', `${this.apiList.validateBarcodeVerification}/${this.$route.params.id}`,{});
        if (barcodeVerificationErrorMessage.data){
          this.message = barcodeVerificationErrorMessage.data;
          this.snackbar = true;
          this.color = 'red darken-2';
          return;
        }

        //validate quantity verification
        var quantityVerificationErrorMessage = await this.utilityFn.getServerDataAsync('get', `${this.apiList.validateQuantityVerification}/${this.$route.params.id}`,{});
        if (quantityVerificationErrorMessage.data){
          this.message = quantityVerificationErrorMessage.data;
          this.snackbar = true;
          this.color = 'red darken-2';
          return;
        }

        //validate FCM
        var validateFCM = await this.utilityFn.getServerDataAsync('get',`${this.apiList.validateFCM}/${this.$route.params.id}`,{});
        if (validateFCM.data){
          this.message = validateFCM.data;
          this.snackbar = true;
          this.color = 'red darken-2';
          return;
        }
      }

      //check for required fields
      var requiredFields = await this.utilityFn.getServerDataAsync("get", this.apiList.dynamicGetMissingRequiredFields + "/" + this.$route.params.id, {});
      if (requiredFields.data.length > 0) {
        //display snackbar message
        this.snackbar = true;
        this.message = requiredFields.data[0];
        this.color = 'red darken-2';
        return;
      }

      const result = await this.utilityFn.getServerDataAsync('get', `${this.apiList.validateOnsiteTestResults}/${this.$route.params.id}`, { });
      let validationMsg = !result.data.item1 ? 'Please assign the Onsite Test Result' : !result.data.item2 ? 'Please fill in Onsite Test Sample Size' : '';
      if (validationMsg) {
        this.message = validationMsg;
        this.snackbar = true;
        this.color = 'red darken-2';
        return;
      }
      else{
        if((this.workOrderDetails?.clientName.toLowerCase() === 'walmart global sourcing' || this.workOrderDetails?.clientName.toLowerCase() === 'asda stores limited') && this.workOrderDetails?.isPreShipOrFinalRandom === true) {
          var validatePackageCheck = await this.utilityFn.getServerDataAsync('get',`${this.apiList.validatePackageCheck}/${this.$route.params.id}`,{});
            if (validatePackageCheck.data){
            if (validatePackageCheck.data.item1){
              this.snackbar = true;
              this.message = validatePackageCheck.data.item2;
              this.color = 'red darken-2';
              return;
            }
          }

          var validateWorksmanship = await this.utilityFn.getServerDataAsync('get',`${this.apiList.validateWorksmanship}/${this.$route.params.id}`,{});
            if (validateWorksmanship.data){
            if (validateWorksmanship.data.item1){
              this.snackbar = true;
              this.message = validateWorksmanship.data.item2;
              this.color = 'red darken-2';
              return;
            }
          }
        }

          this.$router.push({
          name: "pastInspectionsResubmit",
          params: { id: this.workOrderDetails.workOrderId, edit: true },
          query: {
            id: this.workOrderDetails.workOrderId,
            displayId: this.workOrderDetails.workOrderDisplayId,
          },
        });
      }

      
    },

  },
};
</script>

<style lang="stylus" scoped>
@require '../../assets/stylus/variable';

$btn-color = #fff;

.app-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $app-header-height;
  background: $colorPrimaryBlue;
  color: $btn-color;
  padding: 0;
  // box-shadow 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px rgba(0,0,0,.14), 0 1px 10px rgba(0,0,0,.12)
  transition: transform 0.3s ease-out;

  &.slide-down-enter, &.slide-down-leave-to {
    transform: translate(0, -100%);
  }

  & > div {
    display: flex;
    align-items: center;
  }

  .app-header-middle {
    flex: 1;
    font-size: 1.2em;
  }

  .app-header-icon {
    color: $btn-color;
    width: 20px;
    height: 20px;
  }


  #btnSubmitForm {
    color: #fff;
  }
}

>>>.v-input__control {
  min-height: 40px !important;
}
</style>
