import apiList from './apiList';
import axios from 'axios';
import store from "../../store/index";
import router from "../../router/index";
import { PublicClientApplication } from '@azure/msal-browser';

let resouceApplicationPath = apiList.getResourceApplication
let auth = {}
auth.isLoggedIn = function() {
    var tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));
    return tokenInfo != null && tokenInfo.token != null;
}

auth.getToken = function() {
    var tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));
    return tokenInfo != null && tokenInfo.token != null ? tokenInfo.token : "";
}

auth.authenticate = async function() {

  this.$msalInstance = new PublicClientApplication(
    store.state.msalConfig,
  );   

  var account = this.$msalInstance.getAllAccounts()[0];

  //no account, return false to redirect to login screen
  if(!account)
    return false;

  let request = {
    account: account,
    scopes: ['openid', 'offline_access', process.env.VUE_APP_CLIENT_ID]
  };
  let token;

  try {
    token = await this.$msalInstance.acquireTokenSilent(request);
  }
  catch(err) {
    console.log(err);   
  }

  //if b2c token is null or expired get a new onw
  if(!token) {
    await this.$msalInstance
        .loginPopup({})
        .then(async (response) => {

          const myAccounts = this.$msalInstance.getAllAccounts();           

          let request = {
            account:  myAccounts[0],
            scopes: ['openid', 'offline_access', process.env.VUE_APP_CLIENT_ID]
          };
          token = await this.$msalInstance.acquireTokenSilent(request);
        })
        .catch(error => {          
          console.error(`error during authentication: ${error}`);
          return false;
        });
  }

  let b2cToken = token.accessToken;

  if (b2cToken) {
    return await axios
      .get(apiList.baseUrl + "/api/AIMS/AimsAuthenticate", {
        headers: { b2cToken: b2cToken },
      })
      .then((response) => {
        if (response) {
          localStorage.setItem("tokenInfo", JSON.stringify(response.data));
          localStorage.setItem("username", response.data?.email ?? '');

          auth.getResourceApplication();

          return true;
        } else {
          return false;
        }
      })
      .catch((err) => {        
        if(err.response && err.response.status == 400) {
          console.log("You do not have access");
          //remove token from cache         
          router.push({name:'unauthorized'}).catch(() => {});
        }
      });
  }  
};

auth.login = async function () {

  this.$msalInstance = new PublicClientApplication(
    store.state.msalConfig,
  );

  this.$msalInstance.loginRedirect(); 
}

auth.loginPopup = async function () {
  this.$msalInstance = new PublicClientApplication(
    store.state.msalConfig,
  );

  return await this.$msalInstance
        .loginPopup({})
        .then(async (response) => {
          return true;
        })
        .catch(error => {          
          console.error(`error during login: ${error}`);
          return false;
        });
}

auth.getResourceApplication = async function () {
    try {
        //set true to avoid multiply call in the router.js

        var tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));

        if (tokenInfo) {
            let resourceID = tokenInfo.resourceId;
            let resourceName = tokenInfo.fullName;
            let isPerson = tokenInfo.isPerson;
            let personCompanyFactoryID = tokenInfo.FactoryId;
            let personCompanyFactoryName = tokenInfo.FactoryName;
            let coordinationOfficeName = tokenInfo.coordinationOffice;

            localStorage.setItem('resourceName', resourceName);
            localStorage.setItem('resourceID', resourceID);
            localStorage.setItem('personCompanyFactoryID', personCompanyFactoryID || '');
            localStorage.setItem('personCompanyFactoryName', personCompanyFactoryName || '');
            localStorage.setItem('coordinationOfficeName', coordinationOfficeName);
            localStorage.setItem('isLoggedIn', true);

            let employeeType = tokenInfo.employmentType;
                let qualificationStatus = tokenInfo.qualificationStatus;

                let employeeTypeList = [
                    { name: "Full Time UL Employee" },
                    { name: "Non-Exclusive Subcontractor" },
                    { name: "Freelancer" },
                    { name: "Factory Self-Inspector" }]

                let qualificationStatusList = [{ name: "Qualified" }]

                let employeeTypeIndex = employeeTypeList.findIndex(x => x.name == employeeType)
                let qualificationStatusIndex = qualificationStatusList.findIndex(x => x.name == qualificationStatus)

                //set if resource is external or internal
                localStorage.setItem('isInternal', false);
                if (employeeType === "Full Time UL Employee") localStorage.setItem('isInternal', true);

                // //authorization is base on the employeeType and qualicationStatus
                // if (employeeTypeIndex == -1 || qualificationStatusIndex == -1) authorize = false;

            //set value true if user has an access to dc application
            // localStorage.setItem('authorize', authorize);
        }

        //localStorage.setItem('resourceApplicationComplete', true);
        Promise.resolve(true);
        //})
    }
    catch (err) {
        console.log(err);
        Promise.reject(false);
    }
}

export default auth;