<template>
  <div name="InspectionSummary">
    <v-data-table v-model="selectedInspection" item-key="section" v-bind:headers="InspectionHeader" :items="inspectionList" :items-per-page="-1" :hide-default-footer="true" class="elevation-1" style="margin-top: 30px;" auto-id="wo inspection summary table">
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(item,index) in items" :key="item.name" :class="{ 'disabled-row': !item.isAuthorized || !item.showRow }" @click="go_inspection_details(item)">
            <td>{{ item.section }}</td>
            <td style="text-align: center;">{{ item.showRow === true ? item.result : 'N/A' }}</td>
            <td style="text-align: center;">
              <v-btn @click.stop="openDialog(item)" v-show="item.isAuthorized && item.showRow" small depressed style="width: 100px;margin: auto" :readonly="!item.showRow" :auto-id="'findings'+index">Findings</v-btn>

              <!-- <v-text-field class="text-center" max="8" @change="upsertFindings(item)" @click.stop="item.findings < 0? item.findings = 0 : item.findings" style="width: 100px;margin: auto"  v-model="item.findings" :readonly="!item.showRow"></v-text-field></td> -->
            </td>

            <td>
              <div @click.stop="select_Inspection_switch(item)">
                <v-switch color="green" v-show="item.isAuthorized" :disabled="isToggleDisabled(item.isSubmitted)" v-model="item.showRow" @change="onSectionToggle(item)" :auto-id="'select inspection switch'+index"></v-switch>
                <!-- <div @click.stop="select_Inspection_switch(item)" v-show="item.isAuthorized ||isLeadInspector " >
                                <v-switch color="green" v-model="item.showRow" @change="onSectionToggle(item)"></v-switch> -->
              </div>
            </td>
          </tr>
        </tbody>
        <v-dialog v-model="findingsDialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formData.section }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea v-model="formData.findings" maxlength="200" label="Findings" type="text" multi-line></v-textarea>
                    <span>Maximum of 200 character</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="findingsDialog = false" auto-id="wo inspection summary cancel button">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="upsertFindings()" auto-id="wo inspection summary save button">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showProgressDialog" :persistent="true">
          <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
              <div class="text-center">
                <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbarError" centered :timeout="timeoutError" :color="colorError">
      {{ messageError }}
    </v-snackbar>
  </div>
</template>

<script>

import { mapState } from "vuex";
import apiDetails from "@/shared/request/apiDetails";
import* as _ from "lodash";

export default {
  name: "InspectionSummary",
  data() {
    return {
      snackbarError: false,
      messageError: "Please select the Sampling Methodology.",
      colorError: "red darken-2",
      timeoutError: 3000,
      selectedInspection: [],
      selectedWorkOrder: {},
      // Inspection & Summary data
      InspectionHeader: [
        { text: "Section", align: "left", sortable: false, value: "section" },
        { text: "Result", align: "center", sortable: false, value: "result" },
        { text: "Findings", align: "center", sortable: false, value: "findings" },
        // { text: '', align: 'center',sortable: false, value: 'showRow' },
      ],
      inspectionListSummary: [
        {
          sectionId: 1,
          section: "Quantity Verification",
          result: "Conformed",
          findings: 0,
          showRow: true,
          url_name: "workOrderInspectionQuantityId",
          isAuthorized: false,
        },
        {
          sectionId: 21,
          section: "Product Conformity",
          result: "Conformed",
          findings: null,
          showRow: true,
          url_name: "workOrderInspectionProductConformityId",
        },
        {
          sectionId: 2,
          section: "Functional Check Measurement",
          result: "Conformed",
          findings: 0,
          showRow: true,
          url_name: "workOrderInspectionSizeId",
          isAuthorized: false,
        },
        {
          sectionId: 3,
          section: "Package Check",
          result: "Conformed",
          findings: 0,
          showRow: true,
          url_name: "workOrderInspectionPackageId",
          isAuthorized: false,
          isParent: true,
        },
        {
          sectionId: 4,
          section: "Barcode Verification",
          result: "Conformed",
          findings: 0,
          showRow: true,
          url_name: "workOrderInspectionBarcodeId",
          isAuthorized: false,
        },
        {
          sectionId: 5,
          section: "Workmanship",
          result: "Conformed",
          findings: 0,
          showRow: true,
          url_name: "workOrderInspectionWorkmanshipId",
          isAuthorized: false,
        },
        {
          sectionId: 6,
          section: "Onsite Tests",
          result: "Conformed",
          findings: 0,
          showRow: true,
          url_name: "workOrderInspectionOnsiteId",
          isAuthorized: false,
        },
      ],
      showButton: false,
      findingsDialog: false,
      formData: {
        section: "",
        findings: "",
        sectionId: "",
        isHidden: "",
        result: "",
      },
      showProgressDialog: false,
    };
  },
  activated() {
  },
  mounted() {
  },
  methods: {
    openDialog(item) {
      this.findingsDialog = true;
      this.formData.section = item.section;
      this.formData.findings = item.findings;
      this.formData.sectionId = item.sectionId;
      this.formData.isHidden = !item.showRow;
      this.formData.result = item.result;
    },

    isToggleDisabled(isSubmitted) {
      return this.inspectors.length === 1 ? false : this.isLeadInspector ? false : isSubmitted ? true : false;
    },

    upsertFindings() {
      const section = this.sectionDetail(this.formData.sectionId);
      section.isHidden = this.formData.isHidden;
      section.findings = this.formData.findings;
      section.result = this.formData.result;

      this.findingsDialog = false;
      if(window.navigator.onLine === true) {
        this.showProgressDialog = true;
      }
      this.utilityFn.getServerData('post', this.apiList.sectionUpdate, section, () => {
        const index = this._workOrderDetails.workOrderInspectionSections.findIndex(x => x.sectionId == section.sectionId)
        if (index !== -1) {
            this._workOrderDetails.workOrderInspectionSections[index] = section;
            this.$store.dispatch("inspection/setWorkOrderDetails", _.cloneDeep(this._workOrderDetails));
        }
        this.showProgressDialog = false;
      },(errRes) => {
        if(window.navigator.onLine !== true) {
          const index = this._workOrderDetails.workOrderInspectionSections.findIndex(x => x.sectionId == section.sectionId)
          if (index !== -1) {
              this._workOrderDetails.workOrderInspectionSections[index] = section;
              this.$store.dispatch("inspection/setWorkOrderDetails", _.cloneDeep(this._workOrderDetails));
          }
          alert("Findings saved offline, will auto update when device is online");
        }
        this.showProgressDialog = false;
      });
    },

    go_inspection_details(item) {
      if(this._samplingPlanLists.samplingMethodology==null){
        this.snackbarError=true;
        return
      }
      this.selectedWorkOrder = this.$route.params.id;
      this.$router.push({ name: item.url_name, params: { id: this.$route.params.id, showButton: this.showButton, isAuthorized: item.isAuthorized }, query: { displayId: this.$route.query.displayId } });
    },

    select_Inspection_switch(name) {
      var index = "";
      if ((index = this.selectedInspection.indexOf(name) == -1)) {
        this.selectedInspection.push(name);
      } else {
        this.selectedInspection.splice(index, 1);
      }
    },

    onSectionToggle(item) {
      let section = this.sectionDetail(item.sectionId);
      if (section) {
        section.isHidden = !item.showRow;
        section.result = item.result;
        section.findings = "";
        section.submitterId = this.utilityFn.getResourceId().toLowerCase();

        this.showProgressDialog = true;
        this.utilityFn.getServerData('post', this.apiList.sectionUpdate, section, () => {
          if (!item.showRow) {
            this.submit_item(section);
          }

          const index = this._workOrderDetails.workOrderInspectionSections.findIndex(x => x.sectionId == section.sectionId)
          if (index !== -1) {
              this._workOrderDetails.workOrderInspectionSections[index] = section;
              this.$store.dispatch("inspection/setWorkOrderDetails", _.cloneDeep(this._workOrderDetails));
          }
          this.showProgressDialog = false;
        });
      } 
    },

    submit_item(section) {
        this.utilityFn.getServerData('post', this.apiList.submitInspectionSection, section, () => {
            const index = this._workOrderDetails.workOrderInspectionSections.findIndex(x => x.sectionId == section.sectionId)
            if (index !== -1) {
                this._workOrderDetails.workOrderInspectionSections[index].submittedDate = new Date();
            }
        })
    },
    
    isSectionAuthorized(sectionId) {
      if (!this._workOrderDetails || !this._workOrderDetails.workOrderInspectionSections) return false;

      if (this.inspectors.length == 1) return true;

      const assignedSections = this._workOrderDetails.workOrderInspectionSections.filter((x) => x.resourceId && x.resourceId?.toLowerCase() === this.utilityFn.getResourceId().toLowerCase());
      const section = assignedSections.find((x) => x.sectionId === sectionId);
      return !!section;
    },
    
    sectionDetail(sectionId) {
      let section = this._workOrderDetails.workOrderInspectionSections.find(x => x.sectionId == sectionId);
      if (section) {
          section.submitterId = this.utilityFn.getResourceId().toLowerCase();
      } else {
          section = {
              workOrderId: this.$route.params.id,
              sectionId: sectionId,
              submitterId: this.utilityFn.getResourceId().toLowerCase()
          };
      }
      return section;
    }
  },

  computed: {
    ...mapState("inspection", {
        _workOrderDetails: (state) => state.workOrderDetails,
        inspectionSummarySectionResult: (state) => state.inspectionSummarySectionResult,
        _samplingPlanLists: (state) => state.samplingPlanLists,
    }),

    isLeadInspector() {
      return this.inspectors.findIndex((x) => x.resourceId && x.resourceId?.toLowerCase() === apiDetails.resourceId().toLowerCase() && x.isLeadInspector) != -1;
    },

    inspectionList() {
      const sections = [];
      this.inspectionListSummary.forEach(section => {
        const woSection = this._workOrderDetails.workOrderInspectionSections.find(x => x.sectionId == section.sectionId);
        const inspectionResult = this.inspectionSummarySectionResult.find(x => x.sectionId == section.sectionId);
        if (woSection) {
          section.isAuthorized = this.isSectionAuthorized(section.sectionId, section.isParent);
          section.showRow = woSection?.isHidden != 1;
          section.result = woSection?.result || '';
          section.findings =  woSection?.findings || '';
          section.isSubmitted = !!woSection?.submittedDate;
          sections.push(section);
        }
      })
      return sections;
    },
  },
  props: {
    inspectors: {
      type: Array,
      default: () => [],
    },
    sectionData: {
      type: Object,
      default: () => [],
    },
  },
};
</script>

<style lang="stylus" scoped>
tr
    background: #c2e0fd;
    cursor: pointer;
.disabled-row
    background:#eee;
    cursor: no-drop;
.disabled-cursor
    cursor: no-drop;
</style>
