<template>
    <div name="DoubleSamplingPlanIteration">
        <v-select v-if="samplingPlan == 'Double' " v-model="msgData.values[0].value" :items="items" @blur="sava_field_value(msgData.formAtomID, msgData.values[0].value, msgData.values[0].referenceKey)">
            <template v-slot:label>
                {{msgData.label}}<span v-if="msgData.isRequired" class="red--text"> *</span>
            </template>
        </v-select>
    </div>
</template>

<script>

export default {
    name: 'DoubleSamplingPlanIteration',
    data (){
        return {
            items:[],
            fieldReference:{
                label:'',
                formAtomID:'',
            },
            samplingPlan:'',
        }
    },
    computed: {
    },
        mounted() {
            this.fieldReference.label = this.msgData.fieldReference.split(':')[0]
            this.fieldReference.formAtomID = this.msgData.fieldReference.split(':')[1]

            this.samplingPlan = document.getElementById(this.fieldReference.formAtomID.toString()).value;

            this.items = this.msgData.fieldLookupValues.result.map(x => {
                return { text: x.value, value: x.key }
            });
    },
    methods: {
        sava_field_value (id, val, key) {
           
            var data = [{
                "formAtomID": id,
                "value": val,
                "referenceKey": key
            }]
            this.utilityFn.getServerData('post', this.apiList.baseUrl + '/api/AIMS/UpsertFormValue/' + this.$route.params.id, data, res => {
              this.sectionHelper.updatedDynamicSectionModifiedDate(this.sectionName);
          })
        }
    },
    props: {
        msgData:{
            type: Object
        },
        msgFormAtom:{
            type: Array
        },
        sectionName: {
            type: String
        }
    }
};
</script>

<style lang="stylus" scoped>
</style>
