<template>
  <div class="box">
    <div class="work_order">

    <div class="mass-class" v-if="!isOnlyInspector && isLeadInspector">
            <v-row>
              <v-col cols="12" md="3">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-expansion-panels class="mb-6">
                      <v-expansion-panel>
                        <v-expansion-panel-header disable-icon-rotate>
                          <v-text-field hide-details label="<Work Order ID>" auto-id="Work Order Id" ></v-text-field>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-data-table 
                                  :items-per-page="-1" 
                                  v-model="selectedWorkOrder" 
                                  :sort-by="['workOrderDisplayId']" 
                                  :headers="workOrderIDListHeaders" 
                                  :items="filteredWorkOrderList" 
                                  item-key="workOrderId" 
                                  show-select
                                  auto-id="Work Order Id Table">
                          </v-data-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="8">
                <v-row>
                  <v-col cols="6" md="4">
                    <v-select
                      v-model="selectedInspector"
                      :items="inspectorNameList"
                      item-value="resourceId"
                      item-text="fullName"
                      label="Inspector Name"
                      auto-id="Inspector Name"
                    />
                    <v-autocomplete
                      v-bind:items="filteredWorkInstructionList"
                      v-model="workInstructionName"
                      item-text="wiName"
                      item-value="wiName"
                      label="Work Instruction Name"
                      :search-input.sync="search"
                      no-data-text="No Data Available"
                      :hide-no-data="!showNoDataText"
                      @change="onChangeWorkInstructionName"
                      auto-id="work order index work instruction name"
                    ></v-autocomplete>
                    <v-autocomplete
                      v-bind:items="filteredWorkInstructionList"
                      v-model="protocolNo"
                      item-text="wiId"
                      item-value="wiId"
                      label="Protocol #"
                      :search-input.sync="searchProtocolNo"
                      no-data-text="No Data Available"
                      :hide-no-data="!showNoDataText"
                      @change="onChangeProtocolNo"
                      auto-id="work order index protocol #"
                    ></v-autocomplete>
                    <v-text-field
                      v-model="workInstructionNameDisplay"
                      label="Selected Work Instruction Name"
                      variant="underlined"
                      readonly
                      auto-id="Selected Work Instruction Name"
                    ></v-text-field>
                    <v-text-field
                      v-model="workInstructionId"
                      label="Selected Protocol #"
                      variant="underlined"
                      readonly
                      auto-id="Protocol #"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="4">
                    <v-card-actions>
                      <v-btn class="submit-btn" color="#1867c0"  :disabled="disabledApplyButton || isBatchAssignInProgress" @click="applyBatchAssign()" auto-id="Apply">
                        Apply
                        <v-progress-circular
                          class="ml-2"
                          indeterminate
                          color="primary"
                          :size="20"
                          v-if="isBatchAssignInProgress"
                        ></v-progress-circular>
                      </v-btn>
                       <v-btn class="submit-btn" color="#D3D3D3" @click=" clearBatchAssign()" auto-id="Clear">Clear</v-btn>
                 
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
    </div>
      <v-data-table
        id="tblWorkOrders"
        :search="search_value"
        :sort-by="['workOrderDisplayId', 'workOrderClassDescription', 'workOrderOwner', 'workOrderStatus']"
        :headers="workOrderHeaders"
        :items="filteredWorkOrderList"
        :items-per-page="-1"
        item-key="workOrderId"
        class="elevation-1"
        style="margin: 30px auto 0;width:96%;"
        auto-id="Work Orders Table"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.name" :class="{ 'disabled-row': !item.isSectionsAssignmentDone }" @click="go_WorkOrder_details(item)">
              <td>{{ item.workOrderDisplayId }}</td>
              <td>{{ item.workOrderClassDescription }}</td>
              <td>{{ item.workOrderOwner }}</td>
              <td>
                <v-row>
                  <v-col cols="6">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon style="margin-top:10px;" color="blue" v-bind="attrs" v-on="on" auto-id="phone">phone</v-icon>
                        </template>
                      <span style="font-size:20px;">{{item.workOrderOwnerPhoneNumber}}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="6">
                    <button @click.stop="copyToClipboard(item.wokrOrderOwnerEmailAddress)">
                      <span id="EmailButton" style="float: right;margin-right:20px;margin-top:10px;">
                        <v-icon color="blue" auto-id="email">email</v-icon>
                      </span>
                    </button>
                  </v-col>
                </v-row>
              </td>
              <!-- <td>
                <v-row>
                  <v-col cols="6">
                    {{ item.workOrderOwner }}
                  </v-col>
                  <v-col cols="3" style="overflow: hidden;">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon style="margin-top:10px;" color="blue" v-bind="attrs" v-on="on">phone</v-icon>
                        </template>
                      <span style="font-size:20px;">{{item.workOrderOwnerPhoneNumber}}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="3">
                    <button @click.stop="copyToClipboard(item.wokrOrderOwnerEmailAddress)">
                      <span id="EmailButton" style="float: right;margin-right:20px;margin-top:10px;">
                        <v-icon color="blue">email</v-icon>
                      </span>
                    </button>
                  </v-col>
                </v-row>
              </td> -->
              
              <td>
                <div class="status_bg" :style="'background:' + item.statusBg">{{ getStatus(item) }}</div>
              </td>
              <td >
                <div v-if="isOnlyInspector">{{ item.resources[0] && item.resources[0].fullName }}</div>
                <div v-else>{{ item.assignedResource  }}</div>
              </td>
              <td v-if="!isOnlyInspector">
                <!-- Single Inspector or Multi Inspector and section assignement done -->
                <p v-if="item.isSectionsAssignmentDone && (!isOnlyInspector || isLeadInspector)" class="viewPointer" @click.stop="go_assignment(item)" auto-id="view">
                  <br />
                  <u>View</u>
                </p>
                <!-- Multi Inspector && Lead Inspector && section assignment not yet done -->
                <v-btn v-if="!item.isSectionsAssignmentDone && !isOnlyInspector && isLeadInspector" depressed outlined @click.stop="go_assignment(item)" auto-id="mdi-check">
                  <v-icon>people_outline</v-icon>
                  ({{ item.resources.length }})
                </v-btn>
              </td>
              <td>
                  <v-icon v-if="item.workInstruction">mdi-check</v-icon>
              </td>
              <td>
                <button @click.stop="preview(item)" auto-id="mdi-file-eye">
                  <v-icon>mdi-file-eye</v-icon>
                </button>
              </td>

              <td>
                <div v-if="item.workOrderInspectionSections.length > 0 && (hideDownloadIcon(item) || (isOnlyInspector && item.dateSubmitted == null))">
                  <span @click.stop="download(item)" auto-id="download">
                    <v-icon :color="item.isDownloadBg">cloud_download</v-icon>
                  </span>
                  <v-progress-linear :value="item.isDownload" :color="item.isDownloadBg" style="width:70%;margin:3px auto"></v-progress-linear>
                </div>
              </td>
              <!-- <td v-if="isOnlyInspector && workOrderStatus != 'Rework'">
                                <span @click.stop="download(item)">
                                    <v-icon :color="item.isDownloadBg">cloud_download</v-icon>
                                </span>
                                <v-progress-linear :value="item.isDownload" :color="item.isDownloadBg" style="width:70%;margin:3px auto"></v-progress-linear>
                            </td> -->

              <td @click.stop>
                <span>
                  <v-menu bottom v-model="menu[item.index]" :close-on-content-click="false" :offset-y="true">
                    <template v-slot:activator="{ on }">
                      <v-btn v-show="!menu[item.index]" text icon v-on="on">
                        <v-icon>keyboard_arrow_down</v-icon>
                      </v-btn>
                      <v-btn v-show="menu[item.index]" text icon v-on="on">
                        <v-icon>keyboard_arrow_up</v-icon>
                      </v-btn>
                    </template>

                    <v-card>
                      <v-container>
                        <v-row>
                          <v-col cols="6">
                            <h3>SKUs</h3>
                            <textarea cols="24" rows="8" :value="getSkuList(item.workOrderProducts)" style="border:1px #ccc solid;width:100%;margin-top:10px;"> </textarea>
                          </v-col>
                          <v-col cols="6">
                            <h3>POs</h3>
                            <textarea cols="24" rows="8" :value="getPoList(item.workOrderProducts)" style="border:1px #ccc solid;width:100%;margin-top:10px;"> </textarea>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>

      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Work Order: 1</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <h3>SKU：</h3>
              <div class="sku_box">
                <p id="pSku">{{ this.dialogSku }}</p>
              </div>
              <h3>PO#：</h3>
              <div class="po_box">
                <p id="pPo">{{ this.dialogPo }}</p>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn id="btnDialogClose" color="blue darken-1" text @click="dialog = false" auto-id="DialogClose">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar v-model="snackbar" top :timeout="-1" color="#4caf50">
        <v-progress-circular :size="15" indeterminate color="primary"></v-progress-circular>
        <span style="padding-left:10px;">{{snackbarMsg}}</span>
      </v-snackbar>

      <!-- 下之前如果未分配提示选择分配后才能进行下载 -->
      <v-dialog v-model="dialog_instructionName">
        <v-card>
          <v-card-title>
            <span class="headline">Select Work Instruction Name</span>
          </v-card-title>

          <v-card-text>
            <span>Please enter at least 3 characters to search for Work Instruction Name.</span>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    id="txtWorkInstructionName"
                    :items="filteredWorkInstructionList"
                    v-model="workInstructionName"
                    item-text="wiName"
                    item-value="wiName"
                    label="Work Instruction Name"
                    :search-input.sync="search"
                    :hide-no-data="!showNoDataText"
                    no-data-text="No Data Available"
                    auto-id="Work Instruction Name"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn id="btnClose" color="blue darken-1" text @click="dialog_instructionName = false" auto-id="BtnClose">Close</v-btn>
            <v-btn id="btnSave" color="blue darken-1" text @click="save_instruction_name()" auto-id="BtnSave">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <report-preview :showDialog.sync="showReportPreview" :reportData="reportData"></report-preview>
    </div>

    <div class="hideFroala">
      <froalaView id="myHTMLId" v-model="myHTML" />
  </div>

  </div>
</template>

<script>
import { mapState } from "vuex";
import apiDetails from "../../shared/request/apiDetails";
import * as _ from "lodash";
import * as pbi from "powerbi-client";
import ReportPreview from "../../components/reports/ReportPreview";
import html2canvas from "html2canvas";

let state = {
  appHeaderState: {
    show: true,
    title: "7891",
    showMenu: true,
    showBack: true,
    showLogo: false,
    showTitle: true,
    showSearch_job: false,
    showSearch_work: true,
    showCalendar: true,
  },
};

function setState(store) {
  store.dispatch("navigation/setAppHeader", state.appHeaderState);
}
export default {
  name: "index",
  components: {
    ReportPreview,
  },
  data() {
    return {
      worklist: [
        {
          workOrderDisplayId: 1,
        },
      ],
      dialog: false,
      dialogSku: "",
      dialogPo: "",
      authority: 1,
      inspectorNameList: [],
      selectedInspector: null,
      selectedWorkOrder: [],
      workOrderIDListHeaders: [
        { text: "WORK ORDER", align: "center", value: "workOrderDisplayId" }
      ],
      workOrderHeaders: [
        { text: "WORK ORDER", align: "center", value: "workOrderDisplayId" },
        { text: "WORK ORDER CLASS", align: "center", value: "workOrderClassDescription" },
        { text: "OWNER", align: "center", value: "workOrderOwner" },
        { text: " ", align:"center",sortable: false},
        { text: "STATUS", align: "center", value: "workOrderStatus" },
        { text: "ASSIGNED", align: "center", sortable: false },
        { text: "ASSIGN", align: "center", sortable: false, value: "assign" },
        { text: "WI", align: "center", sortable: false },
        { text: "PREVIEW", align: "center", sortable: false },
        { text: "DOWNLOAD", align: "center", sortable: false, value: "download" },
        { text: " ", value: "data-table-expand" },
      ],
      workOrderList: [],
      isLeadInspector: false,
      isOnlyInspector: true,
      workOrderStatus: "",

      onSiteTestId: 6,
      assignedToOnsiteTest: false,

      menu: [],
      downloaded: [],
      showDownload: false,
      showDownloadHeader: false,

      thisItem: "",
      dialog_instructionName: false,
      workInstructionName: "",
      protocolNo: "",
      search: null,
      searchProtocolNo: null,
      showNoDataText: false,
      filteredWorkInstructionList: [],
      workInstructionList: [],
      sections: [],

      filteredWorkOrderList: [],
      snackbar: true,

      showReportPreview: false,
      reportData: {},

      isBatchAssignInProgress: false,
      snackbarMsg:"Loading",
      workInstructionId: "",
      workInstructionNameDisplay: "",
      savedWorkInstructionName: ""
    };
  },
  computed: {
    ...mapState("inspection", {
      store_workList: (state) => state.workList,
      store_token: (state) => state.token,
      downLoadNumber: (state) => state.downLoadNumber,
      enabledWorkOrderSearch: (state) => state.enabledWorkOrderSearch,
      search_value: (state) => state.searchValue,
    }),

    disabledApplyButton() {
        return this.selectedWorkOrder.length === 0 || (!this.selectedInspector && !this.workInstructionName);
    },
  },
  activated() {
    // set head title content
    state.appHeaderState.title = this.$route.query.id;
    setState(this.$store);

    this.resetOnActivated();
    this.getWorkOrderList();
    this.savedWorkInstructionName = "";
  },
  mounted() {
    this.getWorkOrderList();
    this.getGenericReportTemplates();

    state.appHeaderState.title = this.$route.query.id;
    setState(this.$store);
  },
  watch: {
    search(val) {
      if (!val || val.length < 3) {
        this.workInstructionName = null;
        this.filteredWorkInstructionList = [];
        this.showNoDataText = false;
        return;
      }

      this.showNoDataText = true;
      this.filteredWorkInstructionList = this.workInstructionList.filter((x) => x.wiName.toLowerCase().includes(val.toLowerCase()));
    },

    searchProtocolNo(val) {
      if (!val || val.length < 3) {
        this.workInstructionName = null;
        this.filteredWorkInstructionList = [];
        this.showNoDataText = false;
        return;
      }

      this.showNoDataText = true;
      this.filteredWorkInstructionList = this.workInstructionList.filter((x) => x.wiId.toLowerCase().includes(val.toLowerCase()));
    },

    enabledWorkOrderSearch: function(val) {
      this.searchEnabled = val;
      this.showSearchBar();
    },
  },
  methods: {
    // initload() {
    //   this.utilityFn.getServerData("get", this.apiList.sectionList, {}, (response) => {
    //     this.sections = response.data.data;
    //     this.getWorkOrderList();
    //   });
    // },

    resetOnActivated() {
      if (this.enabledWorkOrderSearch) {
        this.filteredWorkOrderList();
      }
    },

    setDefaultHeaders() {
      this.workOrderHeaders = [
        // { text: '', align: 'center',value: '    '},
        { text: "WORK ORDER", align: "center", value: "workOrderDisplayId" },
        { text: "WORK ORDER CLASS", align: "center", value: "workOrderClassDescription" },
        { text: "OWNER", align: "center", value: "workOrderOwner" },
        { text: " ", align:"center",sortable: false},
        { text: "STATUS", align: "center", value: "workOrderStatus" },
        { text: "ASSIGNED", align: "center", sortable: false },
        { text: "ASSIGN", align: "center", sortable: false, value: "assign" },
        { text: "WI", align: "center", sortable: false },
        { text: "PREVIEW", align: "center", sortable: false },
        { text: "DOWNLOAD", align: "center", sortable: false, value: "download" },
        { text: " ", value: "data-table-expand" },
      ];
    },

    getWorkOrderList() {
      this.snackbarMsg = "Loading";
      this.snackbar = true;
      this.utilityFn.getServerData("get", this.apiList.workOrderList + "/" + this.$route.params.id, {}, (res) => {
        this.setDefaultHeaders();
        this.workOrderList = res.data.data.map(x => {
          const assignedSections = x.workOrderInspectionSections.filter(y => !!y.resourceId);
          x.assignedResource = Array.from(new Set(assignedSections.map(x => x.resourceName))).join(', ');
          return x; 
        })

        this.workInstructionId = this.workOrderList[0].workInstruction;
        this.workInstructionNameDisplay = this.workOrderList[0].workInstructionName;
       
         this.inspectorNameList = this.workOrderList[0].resources.map((x) => {
              return {
                resourceId: x.resourceId,
                fullName: x.fullName
              };
            });
        this.authority = res.data.data.authority;
        for (let i = 0; i < this.workOrderList.length; i++) {
          this.workOrderList[i].assignInspectSections = [];
          this.utilityFn.getServerData("get", this.apiList.getWorkOrderInspectionSections + "/" + this.workOrderList[i].workOrderId, {}, (res) => {
            let assignableSections = res.data.data.filter((x) => x.isAssignable);
            this.workOrderList[i].assignInspectSections = assignableSections;
            this.filteredWorkOrderList = [...this.workOrderList];
          });
        }

        // 添加默认进度为0
        this.workOrderList.forEach((item, index) => {
          item.index = index;

          this.$set(item, "isDownload", 0);

          this.loadSections(item);
        });

        // 添加默认status颜色
        this.workOrderList.forEach((item) => {
          if (item.workOrderStatus === "Rework") this.$set(item, "statusBg", "green");
          else if (item.dateSubmitted) this.$set(item, "statusBg", "#80CA48");
          else this.$set(item, "statusBg", "#FFC328");
        });

        // 查询所有下载过的数据
        // this.utilityFn.db.transaction((tx) => {
        //   tx.executeSql("SELECT * FROM downloadList WHERE state=2 AND resourceId=?", [this.utilityFn.getResourceId()], (tx, results) => {
        //     var len = results.rows.length;
        //     for (var i = 0; i < len; i++) {
        //       this.downloaded.push(results.rows.item(i).displayId);
        //     }
        //     // 添加默认下载颜色
        //     this.workOrderList.forEach((item) => {
        //       if (this.downloaded.indexOf(item.workOrderDisplayId) != -1) {
        //         this.$set(item, "isDownloadBg", "rgba(0,0,0,.54)");
        //         item.isDownload = 100;
        //       } else {
        //         this.$set(item, "isDownloadBg", "green");
        //       }
        //     });
        //   });
        // });

        this.checkAssignedInspectors();
        // 如果不是领导过滤掉所有未分配给该用户的订单
        if (!this.isLeadInspector) {
          this.filterAssignedWorkOrders();
        }

        this.menu = this.workOrderList.map((x) => false);
        this.filteredWorkOrderList = [...this.workOrderList];
        this.snackbar = false;

        this.$store.dispatch("inspection/setWorkList", this.workOrderList);

        this.utilityFn.getServerData("get", this.apiList.workInstructions + "/" + this.workOrderList[0].clientId, {} ,
        (res) => {
          this.workInstructionList = res.data.data;

          this.getWorkOrderSections();
        }
      );
      });
    },
    /*  hideDownloadIcon:function(item){
            var val = false
            var allocatedPart = item.workOrderInspectionSections.filter(x=>{
                return x.resourceId && x.resourceId.toLowerCase() == this.utilityFn.getResourceId().toLowerCase()
            })
            if ((item.isSectionsAssignmentDone && allocatedPart.length===item.workOrderInspectionSections.length)  && (item.workOrderStatus != 'Rework' && item.dateSubmitted==null)) {
              val=true
            }
            return val
        }, */

    clearBatchAssign(){
      this.selectedWorkOrder = [];
      this.selectedInspector = '';
      this.workInstructionName = null;
    },

    async applyBatchAssign(){
      const sectionAssigments = [];
      this.selectedWorkOrder.forEach((workorder) => {  
        const sections = [];
        workorder.workOrderInspectionSections.forEach(section => {
          sections.push({
              id: section.id ?? null,
              workOrderId: workorder.workOrderId,
              dynamicSectionId: section.dynamicSectionId ?? null,
              sectionId : section.sectionId ?? null,
              resourceId: this.selectedInspector || section.resourceId,
              isHidden: section.isHidden ?? false
            });
        });
        
        sectionAssigments.push({
          workOrderId: workorder.workOrderId,
          workInstruction: this.filteredWorkInstructionList.find(w => w.wiName === this.workInstructionName)?.wiId || workorder.workInstruction,
          workInstructionName: this.workInstructionName || this.savedWorkInstructionName || this.workInstructionNameDisplay,
          workOrderSections: sections
        });
      });

      if(sectionAssigments.length > 0){
        this.isBatchAssignInProgress = true;
        const result = await this.utilityFn.getServerDataAsync("post", this.apiList.batchSectionAssignment, sectionAssigments);
        this.isBatchAssignInProgress = false;
        this.savedWorkInstructionName = result.data[0].workInstructionName;

        // update current list
        this.workOrderList.forEach(wo => {
          if (this.selectedWorkOrder.findIndex(x => x.workOrderId == wo.workOrderId) !== -1) {
            const sections = result.data.find(x => x.workOrderId == wo.workOrderId)?.workOrderSections || [];
            const assignedResource = this.inspectorNameList.find(x => x.resourceId == this.selectedInspector);

            wo.assignedResource = this.selectedInspector ? assignedResource.fullName : wo.assignedResource;
            wo.workInstruction = this.filteredWorkInstructionList.find(w => w.wiName === this.workInstructionName)?.wiId || wo.workInstruction
            wo.workOrderInspectionSections = sections;
            wo.isSectionsAssignmentDone = sections.filter(x => !!x.resourceId).length === sections.length;
          }
        })

        // clear batch assign panel
        this.clearBatchAssign();
      }  
    },
    hideDownloadIcon: function(item) {
      let val = false;
      let allocatedPartAssignedArray =  item.workOrderInspectionSections.filter(function(woInspectionSection) {
        return (
          item.workOrderInspectionSections.filter(function(assignSection) {
            return woInspectionSection.id == assignSection.id;
          }).length > 0
        );
      });
      let allocatedPart = allocatedPartAssignedArray.filter((x) => {
        return x.resourceId && x.resourceId?.toLowerCase() == this.utilityFn.getResourceId().toLowerCase();
      });
      if (item.isSectionsAssignmentDone && allocatedPart.length === item.workOrderInspectionSections.length && item.workOrderStatus != "Rework" && item.dateSubmitted == null) {
        val = true;
      }
      return val;
    },
    checkAssignedInspectors() {
      // 当前登录账号是否为lead
      if (this.workOrderList[0].resources.findIndex((x) => x.resourceId && x.resourceId.toString().toLowerCase() === this.utilityFn.getResourceId().toLowerCase() && x.isLeadInspector) === -1) {
        this.isLeadInspector = false;
      } else {
        this.isLeadInspector = true;
      }

      // 当前账号是否被分配了所有的部分
      //For Single Resource in JOB
      if (this.workOrderList[0].resources.length > 0) {
        this.isOnlyInspector = this.workOrderList[0].resources.length === 1;
        if (this.isOnlyInspector) {
          this.showDownloadHeader = true;
          this.workOrderHeaders = this.workOrderHeaders.filter(x => !['ASSIGN'].includes(x.text));
        }
      }
    },
    filterAssignedWorkOrders() {
      for (let i = 0; i < this.workOrderList.length; i++) {
        this.workOrderList[i].workOrderInspectionSections = this.workOrderList[i].workOrderInspectionSections || []; //
        let assignments = this.workOrderList[i].workOrderInspectionSections.filter((x) => (x.resourceId + "").toLowerCase() === this.utilityFn.getResourceId().toLowerCase());
        if (assignments.length > 0) {
          this.assignedToOnsiteTest = assignments.findIndex((x) => x.sectionId === this.onSiteTestId) !== -1;
        } else {
          this.workOrderList.splice(i, 1);
          i--;
        }
      }
    },
    async go_WorkOrder_details(item) {
      if(this.getStatus(item)=="Completed"){
        return;
      }

      if(this.getStatus(item)=="Rework") {
        //reroute to rework screen
        this.$router.push({
          name: "pastInspectionsResubmitReworkReason",
          params: { id: item.workOrderId, edit: true },
          query: { id: item.workOrderId, displayId: item.workOrderDisplayId, jobId: item.jobId, jobDisplayId: item.jobDisplayId }
        });

        return;
      }

      // 如果未分配完成不可点击进入详情
      if (this.isOnlyInspector || !item.workInstruction) {
        this.go_assignment(item);
        return;
      }
      //check if assigned form. if yes then go to dynamic
      if (item.formId != null && item.formId != "") {
        this.go_dynamic(item);
        return;
      }
      
      if (!this.isLeadInspector && !item.isSectionsAssignmentDone) {
        alert("WO not assign.");
        return false;
      } else {
        await this.createRemarksAndComments(item.workOrderId);
        this.$store.dispatch("inspection/setWorkOrderDetails", item);
        this.$router.push({ name: "workOrderDetailsId", params: { id: item.workOrderId, resources: item.resources }, query: { displayId: item.workOrderDisplayId } });
      }
    },
    go_assignment(item) {
      if(this.getStatus(item)=="Completed"){
        return;
      }
      this.$store.dispatch("inspection/setWorkOrderDetails", item);
      this.$router.push({
        name: "assignmentId",
        params: { id: item.workOrderId, resources: item.resources, workOrderDisplayId: item.workOrderDisplayId, isDynamic: item.formId != null && item.formId != "", displayId: item.workOrderDisplayId, workInstruction: item.workInstruction },
        query: { id: item.jobDisplayId },
      });
    },
    option_dialog(item) {
      this.dialogSku = item.skus.toString();
      this.dialogPo = item.pos.toString();
      this.dialog = true;
    },
    async go_dynamic(item) {
      if (!this.isLeadInspector && !item.isSectionsAssignmentDone) {
        return false;
      }
      await this.createRemarksAndComments(item.workOrderId);
      this.$store.dispatch("inspection/setWorkOrderDetails", item);
      this.$router.push({ name: "dynamicFormId", params: { id: item.workOrderId, resources: item.resources }, query: { displayId: item.workOrderDisplayId } });
    },
    download(item) {
      //commenting below condition after discussion with cynthia
      //if (!item.isSectionsAssignmentDone) return;

      if (item.isDownload >= 100) {
        // alert('Disable duplicate downloads')
        return false;
      }

      this.workInstructionId = item.workInstruction;
      this.filteredWorkInstructionList = this.workInstructionList.filter((x) => x.wiId === this.workInstructionId);

      // 如果是lead下载需要选择工作标准
      if ((this.isLeadInspector && !item.isSectionsAssignmentDone) || !item.isSectionsAssignmentDone) {
        this.thisItem = item;
        this.dialog_instructionName = true;
        return false;
      } else {
        this.dialog_instructionName = false;
      }
      // 开始下载
      this.utilityFn.doloadList(item, (res) => {
        // 下载完成或者错误后数量减1
        this.$store.dispatch("inspection/setDownloadNumber", this.downLoadNumber + 1);
      });
    },
    async save_instruction_name() {
      const workInstructionId = this.filteredWorkInstructionList.find(w => w.wiName === this.workInstructionName)?.wiId;
      this.thisItem.workInstruction = workInstructionId;
      this.$store.dispatch("inspection/setWorkOrderDetails", this.thisItem);
      await this.utilityFn.getServerDataAsync("patch", this.apiList.patchWorkOrderResults + "/" + this.thisItem.workOrderId + "/1", [{ op: "replace", path: "/workinstruction", value: workInstructionId }, { op: "replace", path: "/workinstructionName", value: this.workInstructionName }]);
      this.dialog_instructionName = false;
      // 开始下载
      this.utilityFn.doloadList(this.thisItem, (res) => {
        // 下载完成或者错误后数量减1
        this.$store.dispatch("inspection/setDownloadNumber", this.downLoadNumber + 1);
      });

    },
    loadSections(item) {
      if (item.workOrderInspectionSections.length !== 0) return;

      // const workOrderSectionList = [];
      // this.sections.forEach((section) => {
      //   workOrderSectionList.push({
      //     id: null,
      //     workOrderId: item.workOrderId,
      //     sectionId: section.id,
      //     sectionName: section.sectionName,
      //     isHidden: false,
      //     resourceId: this.utilityFn.getResourceId(),
      //     lastModifiedDate: null,
      //     fullName: null,
      //     result: null,
      //     workInstructionName: null,
      //     findings: null,
      //     submittedDate: null,
      //     isResourceReAssign: null,
      //   });
      // });

      // item.workOrderInspectionSections = workOrderSectionList;
    },

    getStatus(item) {
      if (item.workOrderStatus === "Rework") return "Rework";
      else if (item.dateSubmitted) return "Completed";
      else return "Pending Submission";
    },

    getPoList(woProducts) {
      return _.uniq(woProducts.map((p) => p.po)).join(", ");
    },

    getSkuList(woProducts) {
      return _.uniq(woProducts.map((p) => p.skuDisplay)).join(", ");
    },

    getGenericReportTemplates() {
      this.utilityFn.getServerData("get", this.apiList.getGenericReportTemplates, {}, (res) => {
        if (res.data) {
          this.genericTemplates = res.data.data;
        }
      });
    },

    preview(item) {
      this.reportData = {
        workOrderId: item.workOrderId,
        workOrderDisplayId: item.workOrderDisplayId,
        clientId: item.companyId,
        clientName: item.companyName,
        reportNumber: item.reportNumber,
        workOrderClassId: item.workOrderClassId,
        industryId: item.industryId,
        workOrderDestinations: item.workOrderDestinations,
        status: item.workOrderStatus,
        rating: item.workOrderRatingDescription,
      };

      this.showReportPreview = true;
    },
    copyToClipboard(text) {
      if (text){
        navigator.clipboard.writeText(text);
        this.snackbarMsg = "Copied Successfully"; 
      }else{
        this.snackbarMsg = "Copy value is empty";
      }
      this.snackbar = true;
      setTimeout(() => {
        this.snackbar = false;
      }, 1000);
    },
    onChangeWorkInstructionName() {
      this.protocolNo = this.filteredWorkInstructionList.find(x => x.wiName === this.workInstructionName)?.wiId;
    },
    onChangeProtocolNo() {
      this.workInstructionName = this.filteredWorkInstructionList.find(x => x.wiId === this.protocolNo)?.wiName;
    },
  async createRemarksAndComments(workOrderId){
      const statementGlossary = await this.utilityFn.getServerDataAsync("post", this.apiList.getStatementGlossaryByWorkOrderId + "/" + workOrderId, {})

      if (statementGlossary.data.length){
        statementGlossary.data.forEach((statement,statementIndex) => {
          let getCanvas
        let that = this;
        this.myHTML = statement.statement;
        let utilityFnLink = this.utilityFn;
        let apiRemarks = this.apiList.upsertWorkOrderRemarks;
        let wOId = workOrderId;
        var classification =   [
                { id: 1, description: "Fail" },
                { id: 2, description: "Pending" },
                { id: 3, description: "Informative" },
                { id: 4, description: "Abort" },
                { id: 5, description: "Pass"}, 
                { id: 6, description: "CAPA"},
                { id: 7, description: "Not Ready"}
            ];
        var myHtmlId = document.getElementById("myHTMLId");
        myHtmlId.textContent = statement.statement;
        console.log(myHtmlId);

        html2canvas(myHtmlId).then(async function(canvas) {
          var screenshot = canvas.toDataURL("image/png");
          const url = screenshot;
          fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], "File name", {
              type: "image/png",
            });
            getCanvas = file;

            that.getBase64(file).then((base64) => {
              getCanvas.base64 = base64;

              var classificationList = classification.filter(x => x.description == statement.classification);
              var classificationInt = null;
              if (classificationList.length != 0){
                classificationInt = classificationList[0].id;
              }
              const result = utilityFnLink.getServerDataAsync(
              "post",
              apiRemarks,
              {
                  workOrderId: wOId,
                  description: statement.statement,
                  classification: classificationInt,
                  sectionId: 12,
                  screen: 12,
                  image: getCanvas.base64,
                  validateCharCount : that.isWalmart,
                  order: statementIndex
              },
              (res) => {
                result = res.data;
                console.log(result);
              })
            });
          })
        });
        });
      }
      
      document.getElementById("myHTMLId").textContext = '';

    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }
  },
  beforeDestroy() {
    this.$store.dispatch("inspection/setEnabledWorkOrderSearch", false);
  },

  beforeRouteLeave(to, form, next) {
    this.showReportPreview = false;
    next();
  }
};
</script>

<style lang="stylus" scoped>
.work_order
    background #fff
.mass-class
    padding-left 25px
.dialog div
    margin-top 15px
.dialog p
     width 100%
     overflow scroll
     margin-top 15px
     font-size 16px
.sku_box, .po_box
    max-height 200px
    overflow scroll
.viewPointer
    cursor: pointer
table tr td
    text-align center
.disabled-row
    background-color: #c8c9cc

header
    display flex
    align-items center
    height 52px
    box-shadow 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px rgba(0,0,0,.14), 0 1px 10px rgba(0,0,0,.12)

form
    flex 1

.search-input
    width 100%
    outline none
    font-size 16px
    height 50px

.search-btn
    color #959595

.search-loading
    margin-top 30%
    display flex
    justify-content center

.search-content
    margin-top 20px

.status_bg
    border-radius 5px
    padding 3px 3px
    color #fff

#preview-container
    height 100%

#preview-dialog
    display flex
    flex-direction column
    height 98vh

#preview-dialog .v-card__text
    flex-grow 1
    height 85%

>>> .v-dialog
    max-height 100%

.hideFroala
  position: absolute !important
  z-index: -1 !important

</style>
