import store from "../../store/index";
import moment from 'moment';

const ALWAYS_BACK_PAGE = ["index"];
const ALWAYS_FORWARD_PAGE = [];
let HISTORY_STACK = [];
const CANYON_HISTORY_ARRAY_STACK_LOCAL_KEY =
  "CANYON_HISTORY_ARRAY_STACK_LOCAL_KEY";
const CANYON_HISTORY_STATE_STACK_LOCAL_KEY =
  "CANYON_HISTORY_STATE_STACK_LOCAL_KEY";
let HISTORY_STATE_STACK = [];
let supportHistory = false;
if (process.env.VUE_ENV === "client") {
  supportHistory = window.history && "state" in history;
}

function getHistoryStateKey() {
  return history.state ? history.state.key : "";
}

function saveHistoryToLocal(key, data) {
  try {
    localStorage.setItem(
      key,
      typeof data === "object" ? JSON.stringify(data) : data
    );
  } catch (err) { console.log(err);}
}

function isForwardByArray(to) {
  let index = HISTORY_STACK.indexOf(to.fullPath);
  if (index !== -1) {
    HISTORY_STACK.length = index + 1;
    return false;
  }
  return true;
}

function isForwardByHistory() {
  let index = HISTORY_STATE_STACK.indexOf(getHistoryStateKey());

  if (index !== HISTORY_STATE_STACK.length - 1 && index !== -1) {
    HISTORY_STATE_STACK.length = index + 1;
    return false;
  }
  return true;
}

export function isForward(to, from) {
  let res = true;
  if (supportHistory) {
    res = isForwardByHistory();
    setTimeout(() => {
      let pageKey = getHistoryStateKey();
      let index = HISTORY_STATE_STACK.indexOf(pageKey);
      if (res && index === -1) {
        HISTORY_STATE_STACK.push(pageKey);
      }

      saveHistoryToLocal(
        CANYON_HISTORY_STATE_STACK_LOCAL_KEY,
        HISTORY_STATE_STACK
      );
    }, 300);
  }
  else {
    //res = isForwardByArray(to, from);
    res = isForwardByArray(to);
    if (res) {
      HISTORY_STACK.push(to.fullPath);
    }

    saveHistoryToLocal(CANYON_HISTORY_ARRAY_STACK_LOCAL_KEY, HISTORY_STACK);
  }
  if (to.name && ALWAYS_BACK_PAGE.indexOf(to.name) !== -1) {
    res = false;
  }
  else if (to.name && ALWAYS_FORWARD_PAGE.indexOf(to.name) !== -1) {
    res = true;
  }

  return res;
}


export function initiaLizeLocalStorage() {
  if (localStorage.getItem("IsLoggedIn") == null){
    localStorage.setItem("IsLoggedIn", false);
    store.dispatch('inspection/setLoggedIn',false);
  }
  if (localStorage.getItem("token") == null) localStorage.setItem("token", "");
  if (localStorage.getItem("authorize") == null)
    localStorage.setItem("authorize", false);
  if (localStorage.getItem("authDataRequest") == null)
    localStorage.setItem("authDataRequest", false);
  if (localStorage.getItem("noAccess") == null)
    localStorage.setItem("noAccess", "false");
  if (localStorage.getItem("isOTPValid") == null)
    localStorage.setItem("isOTPValid", "false");
  if (localStorage.getItem("isOTPNeeded") == null)
    localStorage.setItem("isOTPNeeded", "false");
}

export function setPageTransitions(to,from) {
  if(store.getters['navigation/isPageTransitionEnabled']){
  //if (store.getters.isPageTransitionEnabled) {
    let effect = isForward(to, from) ? "slide-left" : "slide-right";
    store.dispatch("navigation/setPageTransitionType", "slide");
    store.dispatch("navigation/setPageTransitionEffect", effect);
  }
}

export function hasAccessExpired() {
  //validate if token access is expired
  let AccessExpired = true;
  if (localStorage.getItem("AccessExp") !== null) {
    let expTime = localStorage.getItem("AccessExp");
    let loginTime = moment(String(expTime)).format("MM/DD/YYYY hh:mm");
  
    if (
      new Date(new Date(loginTime).toLocaleString()).getTime() <
      new Date(new Date().toLocaleString()).getTime()
    ) {
      AccessExpired = true;
    } else {
      AccessExpired = false;
    }
  }
  return AccessExpired;
}

export function LoginSettings() {
      //set logout parameter if login
      if (localStorage.getItem('IsLoggedIn') === 'true') localStorage.setItem("logout",true);

      //remote value in local storage
     // localStorage.removeItem("resourceApplicationComplete");
      localStorage.removeItem('resourceName');
      localStorage.removeItem('resourceID');
      localStorage.removeItem('username');
      localStorage.removeItem('token');

      document.cookie = 'wresult=;Path=/;domain=.ul.com;Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie = 'userName=;Path=/;domain=.ul.com;Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie = 'requiredAuth=;Path=/;domain=.ul.com;Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie = 'dcAuthentication=;Path=/;domain=.ul.com;Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

      //set item
     // localStorage.setItem('authDataRequest',false);
      localStorage.setItem("authorize",false);
      localStorage.setItem('IsLoggedIn',false);
      localStorage.setItem('isInternal',false);
      localStorage.setItem('isOTPValid',false)
      localStorage.setItem('isOTPNeeded',false)
      localStorage.setItem('isNeedOTPValidation',false);
      store.dispatch('inspection/setLoggedIn',false);
}
