<template>
<div id="emailReport">
    <v-dialog v-model="showDialogControl" persistent width="400">
        <v-form ref="form" v-model="valid">
            <v-card id="email-report-dialog">
                <v-card-title>
                    <span class="headline">Email Report</span>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col>
                            <div class="d-block">Email To: {{data.email}}</div>
                            <div class="d-block">Work Order ID: {{data.workOrderDisplayId}}</div>
                            <div class="d-block">Report No: {{ reportNumber }}</div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <b class="v-label">Select Export Format</b>
                            <v-radio-group
                                v-model="reportFormat"
                                row
                                :disabled="sending"
                                :rules="[v => !!v || 'Please select report format']"
                                >
                                <v-radio
                                    label="PDF"
                                    value="pdf"
                                ></v-radio>
                                <v-radio
                                    label="Microsoft Word"
                                    value="docx"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn id="btnClose" color="blue darken-1" text @click="closeDialog()">Cancel</v-btn>
                    <v-btn id="btnClose" color="blue darken-1" text @click="sendEmail()" :disabled="sending">Send</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
        <v-dialog v-model="showInfo" width="240">
            <v-card id="info-report-dialog">
                <v-card-text>
                    {{ infoText }}
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn id="btn_Dialog_Close" color="green darken-1" text @click="closeInfo()">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</div>
</template>

<script>
    export default {
        props: {
            showDialog: {
                type: Boolean
            },
            data: {
                type: Object,
                default: {}
            }
        },

        data() {
            return {
                reportFormat: null,
                emailSuccess: false,
                sending: false,
                showInfo: false,
                infoText: null,
                valid: false,
                reportNumber: "",
                showDialogControl: false,
                saveReportNumber: false
            }
        },

        methods: {
            closeDialog() {
                this.showDialogControl = false;
                this.$emit('update:showDialog', false);
            },
            sendEmail() {
                this.$refs.form.validate();
                if (!this.valid)
                    return

                this.sending = true;
                const token = this.report.getExportAuth();
                if (!token) {
                    this.authenticate();
                } else {
                    this.invokeExport(token);
                }

                if (this.saveReportNumber){
                    this.utilityFn.getServerData("post", this.apiList.saveEmailReportDialogReportNumber +'/'+ this.$route.params.id, this.reportNumber, (res) => {
                        console.log(res);
                    });
                }

            },
            authenticate() {
                this.utilityFn.getServerData('get', this.apiList.getCFRExportAuthentication, {}, res => {
                    this.report.storeExportAuth(res.data.data);
                    this.invokeExport(res.data.data.access_token);
                })
            },

            invokeExport(token) {
                const data = {
                    emailAddress: this.data.email,
                    reportFormat: this.reportFormat,
                    workorderId: this.data.workOrderDisplayId
                };

                this.utilityFn.cfrExport(this.apiList.cfrExport, data, token, (success, result) => {
                    this.emailSuccess = success;
                    this.sending = false;

                    if (success) {
                        this.showInfo = false;
                        this.infoText = '';
                        this.closeDialog();
                    } else {
                        this.infoText = 'Unable to send email. An error occured.';
                        this.showInfo = true;
                    }
                })
            },

            closeInfo() {
                this.infoText = null;
                this.showInfo = false;
                this.closeDialog();
            },
            async getEmailReportNumber(){
                const reportNumber = await this.utilityFn.getServerDataAsync("get", this.apiList.getAndSaveEmailReportDialogReportNumber + "/" + this.data.workOrderId, {});
                this.reportNumber = reportNumber.data.item1;
                this.saveReportNumber = reportNumber.data.item2;
                return true;
            },
        },

        watch: {
            showDialog(val) {
                if (val){
                    this.showDialogControl = this.getEmailReportNumber();
                }
            },
            data(val) {
                this.sending = false;
                this.emailSuccess = false;
                this.reportFormat = null;
                this.infoText = null;
                this.showInfo = false;
                this.valid = false;

                if (this.$refs.form) {
                    this.$refs.form.reset();
                }
            }
        }
    }
</script>

<style lang="stylus" scoped>
    #email-report-dialog .v-card__text, #info-report-dialog .v-card__text
        padding-top 12px
        padding-bottom 12px

    >>> .error--text
        color #ff5252 !important
        caret-color #ff5252 !important;
</style>

