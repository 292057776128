// import axios from 'axios'


var baseUrl = process.env.VUE_APP_BASE_CANYON_URL;
var calendarApi = 'https://tstcalendarapi.azurewebsites.net';
var cfrExportInvokeUrl = process.env.VUE_APP_LOGIC_APP_URL;



var apiList = {}

apiList.baseUrl = baseUrl;
apiList.isSelfInspection = baseUrl + '/api/AIMS/GetSelfInspectionEligibilityAsync';
apiList.login = baseUrl + '/api/AIMS/authenticate';
apiList.JobList = baseUrl + '/api/AIMS/GetResourceSchedules';

apiList.pastInspection = baseUrl + '/api/AIMS/GetPastInspections';
apiList.workOrderList = baseUrl + '/api/AIMS/GetWorkOrderListFromJob';
//apiList.workOrderList = devBaseUrl + '/api/AIMS/GetWorkOrderListFromJob';
// 获取订单详情
//apiList.workOrderDetails = baseUrl + '/api/AIMS/GetWorkOrdersFromJob';
apiList.workOrderDetails = baseUrl + '/api/AIMS/GetWorkOrdersFromJobMod';
apiList.checkIfWorkOrderDetailsExists = baseUrl + '/api/AIMS/CheckIfWorkOrderDetailsExists';
apiList.getProductAttachment = baseUrl + '/api/AIMS/GetProductAttachment';
apiList.getWorkOrderAttachment = baseUrl + '/api/AIMS/GetWorkOrderAttachment';
apiList.workOrderDetailsQuantity = baseUrl + '/api/AIMS/GetWorkOrderQuantity';
apiList.workOrderSamplingParameterAllSectionDTO = baseUrl + '/api/AIMS/GetWorkOrderSamplingParameterAllSectionDTO';
apiList.workOrderAssignment = baseUrl + '/api/AIMS/GetWorkOrderAssignment';
apiList.workOrderDynamicForm = baseUrl + '/api/AIMS/GetWorkOrderDynamicForm';
apiList.workOrderDetailsById = baseUrl + '/api/AIMS/GetWorkOrderDetailsById';
apiList.woDetailsToAddWoDefect = baseUrl + '/api/AIMS/GetDetailsToAddWoDefect';
apiList.woDetailsToEditWoDefect = baseUrl + '/api/AIMS/GetDetailsToEditDetailsWoDefect';
apiList.woDetailsToAddWoDefectPhoto = baseUrl + '/api/AIMS/GetDetailsToAddDefectPhoto';
apiList.woDetailsToUploadPhotoAttch = baseUrl + '/api/AIMS/GetUploadPhotoInspectionAttachment';
apiList.workOrderDetailsSize = baseUrl + '/api/AIMS/GetWorkOrderSize';
apiList.workOrderDetailsPackage = baseUrl + '/api/AIMS/GetWorkOrderPackage';
apiList.workOrderDetailsBarCode = baseUrl + '/api/AIMS/GetWorkOrderBarcode';
apiList.workOrderDetailsWorkmanship = baseUrl + '/api/AIMS/GetWorkOrderWorkmanship';
apiList.workOrderDetailsOT = baseUrl + '/api/AIMS/GetWorkOrderOnsiteTests';
apiList.workOrderRework = baseUrl + '/api/AIMS/GetWorkOrderReworkDetails';
apiList.workOrderConfirmity = baseUrl + '/api/AIMS/GetWorkOrderProductConfirmity';
apiList.workOrderSignOffDetails = baseUrl + '/api/AIMS/GetWorkOrderSignOffDetails';
apiList.workOrderProductSplitDetails = baseUrl + '/api/AIMS/GetWorkOrderProductSplitDetails';
apiList.workOrderReworkFeedback = baseUrl + '/api/AIMS/GetWorkOrderReworkFeedback';
apiList.getInspectionPhotoDescriptionUrl = baseUrl + '/api/AIMS/GetInspectionPhotoDescription';
// 订单详情编辑
apiList.workOrderDetailsEdit = baseUrl + '/api/AIMS/PatchWorkOrderResults';
apiList.workOrderDetailsEdit2 = baseUrl + '/api/AIMS/UpsertWorkOrderDataCaptureFields';
apiList.updateWorkOrderOverallResult = baseUrl + '/api/AIMS/UpdateWorkOrderOverallResult'
apiList.workOrderDetailsUpdate = baseUrl + '/api/AIMS/UpdateWorkOrderResult'
apiList.workOrderDetailsColorAndSizeRangeUpdate = baseUrl + '/api/AIMS/UpsertColorAndSizeRangeForWorkOrderDataCaptureResult';
apiList.updateTolerance = baseUrl + '/api/AIMS/UpdateTolerance'

apiList.userDetails = baseUrl + '/api/AIMS/GetCurrentUserDetails';
// api in pages/assignment
apiList.assignment = baseUrl + '/api/AIMS/GetResourcesList';
apiList.assignmentUpdate = baseUrl + '/api/AIMS/UpsertWorkOrderIspectionSummaryPerResource';
apiList.assignmentListUpdate = baseUrl + '/api/AIMS/UpsertWorkOrderInspectionSectionsForResource';
apiList.sectionUpdate = baseUrl + '/api/AIMS/UpsertWorkOrderInspectionSection';
apiList.upsertSectionAssignment = baseUrl + '/api/AIMS/UpsertSectionAssignment';
apiList.upsertWorkOrderInspectionSummarySections = baseUrl + '/api/AIMS/UpsertWorkOrderInspectionSummarySections';
apiList.batchSectionAssignment = baseUrl + '/api/AIMS/BatchSectionAssignment';

// 项目列表
apiList.sectionList = baseUrl + '/api/AIMS/GetInspectionSections';

// 当前订单的权限分配情况
apiList.workOrderSectionList = baseUrl + '/api/AIMS/GetWorkOrderInspectionSummarySection';
apiList.workInstructionList = baseUrl + '/api/AIMS/GetAllWorkInstruction';
apiList.workInstructions = baseUrl + '/api/AIMS/GetWorkInstructions';
apiList.getWorkOrderInspectionSections = baseUrl + '/api/AIMS/GetWorkOrderInspectionSections';

// 提交模块部分的数据
apiList.submitInspectionSection = baseUrl + '/api/AIMS/SubmitWorkOrderInspectionSection';

// api in pages/index
apiList.expenseList = baseUrl + '/api/AIMS/GetExpenses';

// api in pages/expense
apiList.currencyList = baseUrl + '/api/AIMS/GetAllCurrencies';
apiList.expenseUpload = baseUrl + '/api/AIMS/UpdateExpense/';

// api in components remarks
// 获取Remarks & Comments 表格数据
apiList.getWorkOrderRemarks = baseUrl + '/api/AIMS/GetWorkOrderRemarks';
apiList.deleteWorkOrderRemarks = baseUrl + '/api/AIMS/DeleteWorkOrderRemark';
apiList.validateRemarksCharacterCount = baseUrl + '/api/AIMS/ValidateRemarksCharacterCount';
apiList.sortWorkOrderRemarks = baseUrl + '/api/AIMS/SortWorkOrderRemarks';
apiList.getStatementGlossary = baseUrl + '/api/AIMS/GetStatementGlossary';

//dynamic form
// get select option list
apiList.dynamicFormData = baseUrl + '/api/AIMS/GetWorkOrderFormWithValues';
apiList.dynamicFormSelectList = baseUrl + '/api/AIMS/Lookup';
apiList.baseUrl = baseUrl;
apiList.dynamicFormAtomData = baseUrl + '/api/AIMS/GetWorkOrderFormAtomValue'
apiList.dynamicGetMissingRequiredFields = baseUrl + '/api/AIMS/GetMissingRequiredFields'
apiList.getClientDefinedFieldLookupAndValuesByWorkOrderId = baseUrl + '/api/AIMS/GetClientDefinedFieldLookupAndValuesByWorkOrderIdForAims'
apiList.getClientDefinedFieldValuesByWorkOrderId = baseUrl + '/api/AIMS/GetClientDefinedFieldValuesByWorkOrderId'
apiList.saveClientDefinedFieldsFromAims = baseUrl + '/api/AIMS/SaveClientDefinedFieldsFromAims'

// public api
apiList.getGeoCountires = baseUrl + '/api/AIMS/GetAllGeoCountriesLookups';
apiList.getUnitsOfMeasurement = baseUrl + '/api/AIMS/GetUnitsOfMeasurement';
apiList.getResourceOrEmployees = baseUrl + '/api/AIMS/GetResourceOrEmployeeBySearchText';

apiList.getLookupAsync = baseUrl + '/api/AIMS/WorkOrderLookupsAsync';
apiList.getClientCustomConfigs = baseUrl + '/api/AIMS/GetClientCustomConfigs';

apiList.upsertWorkOrderDataCaptureFields = baseUrl + '/api/AIMS/UpsertWorkOrderDataCaptureFields';
apiList.upsertProductResult = baseUrl + '/api/AIMS/UpsertWorkOrderProductResults';
apiList.upsertWorkOrderProduct = baseUrl + '/api/AIMS/UpsertWorkOrderProduct';
apiList.updateWorkOrderProduct = baseUrl + '/api/AIMS/UpdateWorkOrderProduct';
apiList.bulkupdateWorkOrderProductResult = baseUrl + '/api/AIMS/BulkUpdateWorkOrderProductResult';
apiList.patchWorkOrderResults = baseUrl + '/api/AIMS/PatchWorkOrderResults';
apiList.upsertWorkOrderRemarks =  baseUrl + '/api/AIMS/UpsertWorkOrderRemarkOffline';
apiList.upsertWorkOrderPackageCheck =  baseUrl + '/api/AIMS/UpsertWorkOrderPackageCheck';
apiList.bulkUpsertWorkOrderProductResult = baseUrl + '/api/AIMS/BulkUpsertWorkOrderProductResult';
apiList.getAimsConfigurations = baseUrl + '/api/AIMS/GetAIMSConfiguration';
apiList.getCompanySubscriptionByWorkOrderId = baseUrl + '/api/AIMS/GetCompanySubscriptionByWorkOrderId';

// image
apiList.uploadWorkOrderImage = baseUrl + '/api/AIMS/UploadWorkOrderImage';
apiList.uploadWorkOrderProductImage = baseUrl + '/api/AIMS/UploadWorkOrderProductImage';
apiList.uploadWorkOrderProductDefectImage = baseUrl + '/api/AIMS/UploadDefectImage';
apiList.deleteImage = baseUrl + '/api/AIMS/DeleteImage';
apiList.updateWorkOrderImages = baseUrl + '/api/AIMS/UpdateWorkOrderImages';
apiList.updateWorkOrderProductImages = baseUrl + '/api/AIMS/UpdateWorkOrderProductImages';
apiList.updateDefectImages = baseUrl + '/api/AIMS/UpdateDefectImages';

// activity log
apiList.createActivity = baseUrl + '/api/AIMS/CreateActivityLog';
apiList.getActivityLogs = baseUrl + '/api/AIMS/GetActivityLogs';

// onsite
apiList.upsertWorkOrderOnSiteTests = baseUrl + '/api/AIMS/UpsertWorkOrderOnSiteTests';
apiList.bulkUpsertWorkOrderOnSiteTests = baseUrl + '/api/AIMS/BulkUpsertWorkOrderOnSiteTests';
apiList.getWorkInstructionOnsiteTests = baseUrl + '/api/AIMS/GetWorkInstructionOnsiteTests';
apiList.getAllWorkInstructionOnsiteTests = baseUrl + '/api/AIMS/GetAllWorkInstructionOnsiteTests';
apiList.deleteWorkOrderOnSiteTest = baseUrl + '/api/AIMS/DeleteWorkOrderOnSiteTest';
apiList.validateOnsiteTestResults = baseUrl + '/api/AIMS/ValidateOnsiteTestResults';
apiList.getAdditionalWorkInstructionOnsiteTest = baseUrl + '/api/AIMS/GetAdditionalWorkInstructionOnsiteTests';


// others
apiList.createWorkOrderCDFReport = baseUrl + '/api/AIMS/CreateWorkOrderCDFReport';
apiList.updateWorkOrderCDF = baseUrl + '/api/AIMS/UpdateWorkOrderCDFReport';
apiList.deleteWorkOrderCDF = baseUrl + '/api/AIMS/deleteWorkOrderCDF';

apiList.createWorkOrderSerialNumberSampled = baseUrl + '/api/AIMS/CreateWorkOrderSerialNumberSampled';
apiList.updateWorkOrderSerialNumberSampled = baseUrl + '/api/AIMS/UpdateWorkOrderSerialNumberSampled';
apiList.deleteWorkOrderSerialNumberSampled = baseUrl + '/api/AIMS/DeleteWorkOrderSerialNumberSampled';

apiList.createWorkOrderSampledCartonNumber = baseUrl + '/api/AIMS/CreateWorkOrderSampledCartonNumber';
apiList.updateWorkOrderSampledCartonNumber = baseUrl + '/api/AIMS/UpdateWorkOrderSampledCartonNumber';
apiList.DeleteWorkOrderSampledCartonNumber = baseUrl + '/api/AIMS/DeleteWorkOrderSampledCartonNumber';

apiList.upsertWorkOrderInspectionEnvironment = baseUrl + '/api/AIMS/UpsertWorkOrderInspectionEnvironment';
apiList.upsertWorkOrderDrawnSample = baseUrl + '/api/AIMS/UpsertWorkOrderDrawnSample';
apiList.updateWorkOrderDataCaptureModifiedDate = baseUrl + '/api/AIMS/UpdateWorkOrderDataCaptureModifiedDate';




// defects
apiList.updateWorkOrderProductDefects = baseUrl + '/api/AIMS/UpsertWorkOrderProductDefects';
apiList.getWorkOrderProductDefects = baseUrl + '/api/AIMS/GetWorkOrderProductDefects';
apiList.getDefectNames = baseUrl + '/api/AIMS/GetDefectNames';
apiList.getDefectCategories = baseUrl + '/api/AIMS/GetDefectCategories';
apiList.getDefectClassifications = baseUrl + '/api/AIMS/GetDefectClassifications';
apiList.deleteWorkOrderProductDefect = baseUrl + '/api/AIMS/DeleteWorkOrderProductDefect';

//dcl defect
apiList.getClientDefectList = baseUrl + '/api/AIMS/GetClientDefectList';
apiList.getCompanyDclTypeAliases = baseUrl + '/api/AIMS/GetCompanyDclTypeAliases';
apiList.getIsApplyToDclTypeAlias = baseUrl + '/api/AIMS/GetIsApplyToDclTypeAlias';

//QuantityVerification
apiList.upsertWorkOrderQuantityVerification = baseUrl + '/api/AIMS/UpsertWorkOrderQuantityVerification';
apiList.upsertWorkOrderProductQuantityVerificationResult = baseUrl + '/api/AIMS/UpsertWorkOrderProductQuantityVerificationResult';
apiList.deleteWorkOrderProductQuantityVerificationResult = baseUrl + '/api/AIMS/DeleteWorkOrderProductQuantityVerificationResult'
// barcode
apiList.upsertWorkOrderBarcodeVerification = baseUrl + '/api/AIMS/UpsertWorkOrderBarcodeVerification';
apiList.upsertWorkOrderProductBarcodeVerificationResult = baseUrl + '/api/AIMS/UpsertWorkOrderProductBarcodeVerificationResult';
apiList.deleteWorkOrderProductBarcodeVerificationResult = baseUrl + '/api/AIMS/DeleteWorkOrderProductBarcodeVerificationResult'
// size measurement Functional Check Measurement
apiList.upsertWorkOrderSizeMeasurement = baseUrl + '/api/AIMS/UpsertWorkOrderSizeMeasurement';
apiList.upsertWorkOrderProductSizeMeasurementResult = baseUrl + '/api/AIMS/UpsertWorkOrderProductSizeMeasurementResult';
apiList.deleteWorkOrderProductSizeMeasurementResult = baseUrl + '/api/AIMS/DeleteWorkOrderProductSizeMeasurementResult';
apiList.patchWorkOrderProductSizeMeasurementResult = baseUrl + '/api/AIMS/PatchWorkOrderProductSizeMeasurementResult';

// product conformity
apiList.upsertWorkOrderSubSections = baseUrl + '/api/AIMS/UpsertWorkOrderSubSections/';

//general previous report number
apiList.getPreviousReportNumberAndValidation = baseUrl +'/api/AIMS/GetPreviousReportNumberAndValidation';

// DynamicForm Builder
// apiList.getForm = dynamicFormBuilderApi + '/api/AIMS/GetForm';
// apiList.getForms = dynamicFormBuilderApi + '/api/AIMS/GetForms'
// apiList.getFieldLibraries = dynamicFormBuilderApi + '/api/AIMS/GetFieldLibraries';
// apiList.getAtoms = dynamicFormBuilderApi + '/api/AIMS/GetAtoms';
// apiList.delDynamicFormBuilder = dynamicFormBuilderApi + '/api/AIMS';
// apiList.addDynamicFormBuilderApi = dynamicFormBuilderApi + '/api/AIMS';
// apiList.CloneForm = dynamicFormBuilderApi + '/api/AIMS/CloneForm';

// apiList.getTemplates = dynamicFormBuilderApi + '/api/AIMS/GetTemplates';
// apiList.getIndustries = dynamicFormBuilderApi + '/api/AIMS/GetIndustries';
// apiList.GetClassifications = dynamicFormBuilderApi + '/api/AIMS/GetClassifications';
// apiList.GetClients = dynamicFormBuilderApi + '/api/AIMS/GetClients';
// apiList.GetStyles = dynamicFormBuilderApi + '/api/AIMS/GetStyles';
// apiList.dynamicFormBuilderApi = dynamicFormBuilderApi;


// Calendar
apiList.getInspectionCalendar  =  calendarApi + '/api/AIMS/GetInspectionCalendar';

//signOff
apiList.GetCompanyWorkOrderResultTypeAliases  =  baseUrl + '/api/AIMS/GetCompanyWorkOrderResultTypeAliases';
apiList.GetCompanyWOResultTypeAliases = baseUrl + '/api/AIMS/GetCompanyWOResultTypeAliases';
apiList.upsertWorkOrderDraftVersion = baseUrl + '/api/AIMS/UpsertWorkOrderDraftVersion';
apiList.submitWorkOrderReport = baseUrl + '/api/AIMS/SubmitWorkOrderReport';
apiList.getWorkOrderDraftVersion = baseUrl + '/api/AIMS/GetWorkOrderDraftVersion/';
apiList.getAllWorkOrderAbortiveCode =  baseUrl + '/api/AIMS/GetAllWorkOrderAbortiveCode'

// preview
apiList.GetBarcodeTypes  =  baseUrl + '/api/AIMS/GetBarcodeTypes';

// Rework
apiList.getReworkReason =  baseUrl + '/api/AIMS/GetReworkReasons'
apiList.upsertSubmitReworkReport =  baseUrl + '/api/AIMS/SubmitReworkReport'
apiList.resubmitWorkOrderReport =  baseUrl + '/api/AIMS/ResubmitWorkOrderReport'

//Split Work Order
apiList.getSplitWorkOrders = baseUrl + '/api/AIMS/GetSplitWorkOrders'
apiList.getUpsertSplitWorkOrder = baseUrl + '/api/AIMS/UpsertSplitWorkOrder'

//authentication
apiList.getResourceApplication = baseUrl + '/api/AIMS/GetResourceApplication'

//pointofmeasure
apiList.getAllCriticalPomList =  baseUrl + '/api/AIMS/GetAllCriticalPomList'
apiList.getAllProductSizeResultList =  baseUrl + '/api/AIMS/GetAllProductSizeResultList'
apiList.getStylePerWorkOrderId =  baseUrl + '/api/AIMS/GetStylePerWorkOrderId'
apiList.sampleDetailUpsert =  baseUrl + '/api/AIMS/SampleDetailUpsert'
apiList.getSampleDetailPointOfMeasurementPerWorkOrderId =  baseUrl + '/api/AIMS/GetSampleDetailPointOfMeasurementPerWorkOrderId'
apiList.workOrderProductPointOfMeasurementUpsert =  baseUrl + '/api/AIMS/WorkOrderProductPointOfMeasurementUpsert'
apiList.upsertWorkOrderSizeMeasurement =  baseUrl + '/api/AIMS/UpsertWorkOrderSizeMeasurement'
apiList.getWOPSD =  baseUrl + '/api/AIMS/GetWOPSD'
apiList.getWOPPOM =  baseUrl + '/api/AIMS/GetWOPPOM'
apiList.updateSDPOM =  baseUrl + '/api/AIMS/UpdateSDPOM'
apiList.deleteSampleDetails =  baseUrl + '/api/AIMS/DeleteSampleDetails'
apiList.deleteSampleDetailPointOfMeasurement =  baseUrl + '/api/AIMS/DeleteSampleDetailPointOfMeasurement'

//WorkOrderSizeMeasurement
apiList.getAllWorkOrderPendingFailCode =  baseUrl + '/api/AIMS/GetAllWorkOrderPendingFailCode'

//report
apiList.getGenericReportTemplates = baseUrl + '/api/AIMS/GetGenericReportTemplates'
apiList.getEmbeddedPBIReport = baseUrl + '/api/AIMS/GetEmbeddedPBIReport'
apiList.getCompanyReportTemplates = baseUrl + '/api/AIMS/GetReportTemplates'
apiList.GetReportAccessToken = baseUrl + '/api/AIMS/GetReportAccessToken'

apiList.getInspectionTime = baseUrl+ '/api/AIMS/GetInspectionTime'
apiList.upsertInspectionTime = baseUrl+ '/api/AIMS/UpsertInspectionTime'
apiList.deleteInspectionTime = baseUrl +'/api/AIMS/DeleteInspectionTime'

apiList.getCFRExportAuthentication = baseUrl + '/api/AIMS/getCFRExportAuthentication'
apiList.cfrExport = cfrExportInvokeUrl

//ASDA WEEE Technical Data
apiList.upsertTechnicalData = baseUrl + '/api/AIMS/UpsertTechnicalData'
apiList.deleteTechnicalData = baseUrl + '/api/AIMS/DeleteTechnicalDataRow'
apiList.upsertTechnicalDataRequired = baseUrl + '/api/AIMS/UpsertTechnicalDataRequired'
apiList.downloadWEEEExcel = baseUrl + '/api/AIMS/DownloadWEEEExcel';

//Product Client Defined Field
apiList.upsertWorkOrderProductCompanyClientDefinedField = baseUrl + '/api/AIMS/UpsertWorkOrderProductCompanyClientDefinedField';
apiList.getProductCompanyClientDefinedField = baseUrl + '/api/AIMS/GetProductCompanyClientDefinedField';

//Bulk Delete

apiList.bulkDeleteWorkOrderProductBarcodeVerificationResults = baseUrl + '/api/AIMS/BulkDeleteWorkOrderProductBarcodeVerificationResults';
apiList.bulkDeleteWorkOrderProductSizeMeasurementResults = baseUrl + '/api/AIMS/BulkDeleteWorkOrderProductSizeMeasurementResults';
apiList.bulkDeleteWorkOrderProductQuantityVerificationResults = baseUrl + '/api/AIMS/bulkDeleteWorkOrderProductQuantityVerificationResults';

// API for Stock Inspected
apiList.getApplicableIndustryList = baseUrl + '/api/AIMS/GetApplicableIndustryList';
apiList.getAllStockInspectedOriginalList = baseUrl + '/api/AIMS/GetAllStockInspectedOriginalList';


apiList.getWorkOrderClientRetailerNotificationTemplate = baseUrl + '/api/AIMS/GetWorkOrderClientRetailerNotificationTemplate';

apiList.getAndSaveEmailReportDialogReportNumber = baseUrl + '/api/AIMS/GetAndSaveEmailReportDialogReportNumber';
apiList.saveEmailReportDialogReportNumber = baseUrl + '/api/AIMS/SaveEmailReportDialogReportNumber';

apiList.getStatementGlossaryByWorkOrderId = baseUrl + '/api/AIMS/GetStatementGlossaryByWorkOrderId';
apiList.validateRemarksAndCommentClassification = baseUrl + '/api/AIMS/ValidateRemarksAndCommentsClassification'
apiList.validatePackageCheck = baseUrl + '/api/AIMS/ValidatePackageCheck'
apiList.validateWorksmanship = baseUrl + '/api/AIMS/ValidateWorksmanship'
apiList.validateBarcodeVerification = baseUrl + '/api/AIMS/ValidateBarcodeVerification'
apiList.validateFCM = baseUrl + '/api/AIMS/ValidateFunctionalCheckMeasurement'
apiList.validateQuantityVerification = baseUrl + '/api/AIMS/ValidateQuantityVerification'
apiList.getConcatenatedSpecialInstructions = baseUrl + '/api/AIMS/GetConcatenatedSpecialInstructions'

apiList.updateWorkOrderSizeMeasurementRecompute = baseUrl + '/api/AIMS/UpdateWorkOrderSizeMeasurementRecompute';

export default apiList
