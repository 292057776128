<template>
    <div class="content" style="margin-top:16px;">

        <v-row>
            <v-col v-if="hideButton" cols="12 " sm="12" md="12" style="text-align:right;">
                <v-btn id="btnSubmit" v-if="hideButton && isAuthorized" :disabled="isSectionSubmitted" class="submit-btn" @click="submit_item" small depressed color="#1867c0" auto-id="additional information submit">Submit</v-btn>
            </v-col>
        </v-row>

        <br/>
        <v-row class="mt-2">
        </v-row>

            <v-row v-for="(splittedIndex,index) in splittedClientDefinedFields" :key="index">
            <v-col cols="4" v-for="(fields,fieldsIndex) in splittedIndex" :key=fieldsIndex>
                <v-select
                    v-if="fields.fieldType == 'dropdown'"
                    :items="fields.values"
                    item-text="description"
                    item-value="id"
                    v-model="getCdfValue(fields.clientDefinedLabelId).value"
                    :label="fields.description"
                    @change="fixClientDefinedFields(fields.clientDefinedLabelId,fields.fieldType,$event)"
                    :auto-id="fields.description"
                >
                </v-select>
            
                <v-text-field
                    v-if="fields.fieldType == 'text'"
                    v-model="getCdfValue(fields.clientDefinedLabelId).value"
                    :label="fields.description"
                    @change="fixClientDefinedFields(fields.clientDefinedLabelId,fields.fieldType,$event)"
                    :auto-id="fields.description"
                >
                </v-text-field>

                <v-text-field
                    v-if="fields.fieldType == 'email'"
                    v-model="getCdfValue(fields.clientDefinedLabelId).value"
                    :label="fields.description"
                    @change="fixClientDefinedFields(fields.clientDefinedLabelId,fields.fieldType,$event)"
                    :auto-id="fields.description"
                >
                </v-text-field>

                <v-menu
                    v-if="fields.fieldType == 'date'"
                    :close-on-content-click="false"
                    max-width="290"
                >
                <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="computedDateFormatted(getCdfValue(fields.clientDefinedLabelId).value).value"
                        persistent-hint
                        prepend-icon="event"
                        :readonly="false"
                        :label="fields.description"
                        v-on="on"
                        :append-icon="true ? 'edit' : ''"
                        @keyup="formatInputtedDate($event,fields.clientDefinedLabelId)"
                        :auto-id="fields.description"
                    >
                    </v-text-field>
                </template>
                    <v-date-picker
                        v-model="getCdfValue(fields.clientDefinedLabelId).value"
                        color="blue darken-1"
                        @change="fixClientDefinedFields(fields.clientDefinedLabelId,fields.fieldType,$event)"
                        :auto-id="fields.description"
                    >
                    </v-date-picker>
                </v-menu> 

                <v-autocomplete
                    hide-details
                    v-bind:items="fields.values"
                    v-model="getCdfValueMultiple(fields.clientDefinedLabelId).value"
                    item-text="description"
                    item-value="id"
                    :label="fields.description"
                    small-chips
                    multiple
                    v-if="fields.fieldType == 'multiselect'"
                    @change="fixClientDefinedFieldsMultiple(fields.clientDefinedLabelId,$event)"
                    :auto-id="fields.description"
                >
                    <template v-slot:selection="data">
                        <v-chip close @click:close="remove(data.item)">
                            {{ data.item.description }}
                        </v-chip>
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row> 

    <v-row>
        <v-col cols="12" sm="11">
        </v-col>
        <v-col cols="12" sm="1">
            <v-btn small outlined color="error" @click="saveClientDefinedFields()" auto-id="additional information save">Save</v-btn>
        </v-col>
    </v-row>

    </div>
</template> 

<script>

import {mapState} from 'vuex';

export default {
    name: 'AdditionalInformation',
    data (){
        return {
            clientDefinedFields: [],
            clientDefinedFieldValues:[],
            clientDefinedFieldText: [],
            cdfModels:[],
            selectedMultiple:[],
            splittedClientDefinedFields: [],
        }
    },
    watch: {
        // $route (to,from){
        //     if(from.name == 'dynamicFormId' || from.name == 'workOrderDetailsId')
        //         this.saveClientDefinedFields(from.params.id);
        // }
    },
    async mounted() {
        await this.loadData();
        //window.addEventListener('beforeunload', this.saveClientDefinedFields);  
    },
    //async activated(){
   //},
     beforeDestroy(){
         //window.removeEventListener('beforeunload', this.saveClientDefinedFields);  
    },
    deactivated(){
        this.saveClientDefinedFields(this.$route.params.id);
    },
    methods: {
        async loadData() {
            const cdfLookup = await this.utilityFn.getServerDataAsync("get", this.apiList.getClientDefinedFieldLookupAndValuesByWorkOrderId + "/" + this.$route.params.id, {});
            this.clientDefinedFields = cdfLookup.data;
            let splittedClientDefinedFieldsTemp = cdfLookup.data.slice();
            while (splittedClientDefinedFieldsTemp.length > 0){
                let chunk = splittedClientDefinedFieldsTemp.splice(0,3);
                this.splittedClientDefinedFields.push(chunk);
            } 

            const cdfValue = await this.utilityFn.getServerDataAsync("get", this.apiList.getClientDefinedFieldValuesByWorkOrderId + "/" + this.$route.params.id, {});
            this.clientDefinedFieldValues = cdfValue.data.clientDefinedValues;
            this.clientDefinedFieldText = cdfValue.data.clientDefinedTextValues;

            this.clientDefinedFields.forEach(element => {
                var cdfFieldValue = this.clientDefinedFieldValues.filter(x => x.labelId == element.clientDefinedLabelId);
                if (cdfFieldValue.length != 0){
                    for (let index = 0; index < cdfFieldValue.length; index++) {
                        this.cdfModels.push({cdfId: element.clientDefinedLabelId, value: cdfFieldValue[index].valueId, fieldType: element.fieldType});
                    }
                }
                var cdfValueText = this.clientDefinedFieldText.filter(x => x.labelId == element.clientDefinedLabelId)[0];
                if (cdfValueText){
                    var formatIfDate = cdfValueText.value;
                    if (element.fieldType == 'date'){
                        formatIfDate = this.formatDateToIso(cdfValueText.value);
                    }
                    this.cdfModels.push({cdfId: element.clientDefinedLabelId, value: formatIfDate, fieldType: element.fieldType});
                }
            });

        },
        getCdfValue(clientDefinedLabelId){
            var returnObj = this.cdfModels.filter(x => x.cdfId == clientDefinedLabelId);
            if (returnObj.length > 0){
                return returnObj[0];
            }
            else {
                return {value:""};
            }
        },
        formatDate(date) {
            if (!date) return null;
            var formatDate = new Date(date);
            return `${formatDate.getMonth()+1}/${formatDate.getDate()}/${formatDate.getFullYear()}`;
        },
        computedDateFormatted(date){
            return {value:this.formatDate(date)};
        },
        formatInputtedDate(val,cdfValueId){
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                let splitted = [];
                if (val.target.value.split('/').length > 2)
                { 
                    splitted = val.target.value.split('/');
                    if (splitted[0].length == 1 && (splitted[2].length == 4) || splitted[2].length == 2)
                    {     
                        if (splitted[2].length == 2)
                        {
                            var padDate = new Date().getFullYear().toString().slice(0,2);
                            splitted[2] = padDate + splitted[2];
                        }

                        var returnObj = this.cdfModels.filter(x => x.cdfId == cdfValueId);
                        if (returnObj.length == 1){
                            this.cdfModels.filter(x => x.cdfId == cdfValueId)[0].value = `${splitted[2]}-${this.padDigit(splitted[0])}-${splitted[1]}`;
                        }
                    }
                }
            }
            , 1000);
        },
        padDigit(value) {
            const digit = 2;
            return (new Array(digit).fill(0).join('') + value).slice(-digit);
        },
        fixClientDefinedFields(cdfId,fieldType,cdfValue){

            if (cdfId != null && cdfValue != null){
                if (this.cdfModels.filter(x => x.cdfId == cdfId).length == 0){
                    this.cdfModels.push({cdfId:cdfId, value: cdfValue})
                    if (fieldType == 'date' || fieldType == 'text' || fieldType == 'email'){
                        this.clientDefinedFieldText.push({labelId:cdfId,value:cdfValue});
                    }
                    else{
                        this.clientDefinedFieldValues.push({labelId:cdfId,valueId:cdfValue});
                    }
                }

                this.clientDefinedFieldValues.forEach(element => {
                    var val = this.cdfModels.filter(x => x.cdfId == element.labelId);
                    //skip multiple cdf
                    if (val.length == 1){
                        element.valueId = val[0].value
                    }
                });
                this.clientDefinedFieldText.forEach(element => {
                    var valText = this.cdfModels.filter(x => x.cdfId == element.labelId);
                    for (let index = 0; index < valText.length; index++) {
                        element.value = valText[index].value;
                    }
                });
            }
        },
        remove(e) {
            var filterModel = this.cdfModels.filter(x => x.value == e.id );
            for (let index = 0; index < filterModel.length; index++) {
                var toDeleteIndex = this.cdfModels.indexOf(filterModel[index]) 
                if (toDeleteIndex > -1){
                    this.cdfModels.splice(toDeleteIndex,1);
                }
            }

            var filterCdfModel = this.clientDefinedFieldValues.filter(x => x.valueId == e.id);
            for (let index = 0; index < filterCdfModel.length; index++) {
                var toDeleteIndex = this.clientDefinedFieldValues.indexOf(filterCdfModel[index]) 
                if (toDeleteIndex > -1){
                    this.clientDefinedFieldValues.splice(toDeleteIndex,1);
                }
            }
        },        
        getCdfValueMultiple(clientDefinedLabelId){
            var cdfModelValue = this.cdfModels.filter(x => x.cdfId == clientDefinedLabelId);
            if (cdfModelValue.length > 0){
                var returnObj = [];
                cdfModelValue.forEach(element => {
                    {
                        returnObj.push(element.value)
                    }
                });
                return {value: returnObj};
            }
            else {
                return {value:""};
            }
        },
        fixClientDefinedFieldsMultiple(cdfId,cdfValue){
            var filter = this.cdfModels.filter(x => x.cdfId == cdfId);
            for (let index = 0; index < filter.length; index++) {
                var toDeleteIndex = this.cdfModels.indexOf(filter[index]) 
                if (toDeleteIndex > -1){
                    this.cdfModels.splice(toDeleteIndex,1);
                }
            }
            for (let index = 0; index < cdfValue.length; index++) {
                this.cdfModels.push({cdfId:cdfId,value:cdfValue[index]});                
            }

            var filterCdfModel = this.clientDefinedFieldValues.filter(x => x.labelId == cdfId);
            for (let index = 0; index < filterCdfModel.length; index++) {
                var toDeleteIndex = this.clientDefinedFieldValues.indexOf(filterCdfModel[index]) 
                if (toDeleteIndex > -1){
                    this.clientDefinedFieldValues.splice(toDeleteIndex,1);
                }
            }

            for (let index = 0; index < cdfValue.length; index++) {
                this.clientDefinedFieldValues.push({labelId:cdfId,valueId:cdfValue[index]});                
            }
        },
        saveClientDefinedFields(workOrderId = ""){

            this.cdfModels.forEach(element => {
                if (element.fieldType == 'date'){
                    var valText = this.clientDefinedFieldText.filter(x => x.labelId == element.cdfId);
                    if (valText.length == 1){
                        var textIndex = this.clientDefinedFieldText.indexOf(valText[0]);
                        this.clientDefinedFieldText[textIndex].value = this.formatDateToDatabase(element.value);
                    }
                }
            });

            var data = 
            {
                fieldTextValues: this.clientDefinedFieldText,
                fieldValues: this.clientDefinedFieldValues
            };

            if (workOrderId == ""){
                workOrderId = this.$route.params.id;
            } 

            this.utilityFn.getServerData("post", this.apiList.saveClientDefinedFieldsFromAims +'/'+ workOrderId, data, (res) => {
                console.log(res);
                this.loading = false;
            });
        },
        submit_item() {
            const section = {
                sectionId: 25,//25 AdditionalInformation
                workOrderId: this.$route.params.id
            };
            this.utilityFn.getServerData("post", this.apiList.submitInspectionSection, section, (res) => {
                this.sectionData.AdditionalInformation.isSectionSubmitted = true;
                this.$store.dispatch('inspection/setSectionData', this.sectionData);
            });
        },
        formatDateToIso(date) {
            if (!date) return null;
            var formatDate = new Date(date);
            return `${formatDate.getFullYear()}-${formatDate.getMonth()+1}-${formatDate.getDate()}`;
        },
        formatDateToDatabase(date){
            if (!date) return null;
            var formatDate = new Date(date);
            var month = ("0" + (formatDate.getMonth() + 1)).slice(-2);
            var date = ("0" + (formatDate.getDate())).slice(-2); 
            return `${formatDate.getFullYear()}-${month}-${date}T00:00:00.000Z`;
        }
    },
    computed: {
        ...mapState('inspection', {
            sectionData: state => state.sectionData,
        })    
    },
    props: {
        hideButton: Boolean,
        isSectionSubmitted: Boolean,
        isAuthorized: Boolean
    }
};
</script>

<style lang="stylus" scoped>
.submit-btn
    color: #fff !important
</style>
