<template>
  <div name="SizeMeasurementPOM">
    <div>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field v-model="workOrderSizeMeasurements.totalOfCritical" 
          @keyup="returnNumber(workOrderSizeMeasurements, 'totalOfCritical')" 
          :rules="IsWalmart?[(v) => !!v || 'Required']: []"  @change="changeWorkOrderSizeMeasurements()"
          hide-details label="Total # of Critical POM" append-icon="edit" ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field v-model="workOrderSizeMeasurements.totalNoOfSamplesMeasured" 
          @keyup="returnNumber(workOrderSizeMeasurements, 'totalNoOfSamplesMeasured')" 
          :rules="IsWalmart?[(v) => !!v || 'Required']: []" @change="changeWorkOrderSizeMeasurements()" hide-details label="Total No of Samples Measured" append-icon="edit" ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field hide-details label="Total No of Samples(Out of POM)" @change="changeWorkOrderSizeMeasurements()" v-model="totalNoOfSamples" readonly></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field v-model="percentSamples" hide-details label="% Samples(Out of POM)" @change="changeWorkOrderSizeMeasurements()" readonly></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field v-model="totalOutofPOMPositive" hide-details label="Total Found(POM)(+)" @change="changeWorkOrderSizeMeasurements()" readonly></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field v-model="totalOutofPOMNegative" hide-details label="Total Found(POM)(-)" @change="changeWorkOrderSizeMeasurements()" readonly></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field v-model="percentFoundPositive" hide-details label="% Found(POM)(+)" @change="changeWorkOrderSizeMeasurements()" readonly></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field v-model="percentFoundNegative" hide-details label="% Found(POM)(-)" @change="changeWorkOrderSizeMeasurements()" readonly></v-text-field>
          <v-spacer />
        </v-col>
      </v-row>

      <v-expansion-panels class="mb-6">
        <v-expansion-panel>
          <v-expansion-panel-header disable-icon-rotate>
            <v-row @click.stop>
              <v-col cols="12" sm="3">
                <v-text-field v-model="sampleDetails.size" hide-details label="Enter Size"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field v-model="sampleDetails.color" hide-details label="Enter Color"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field v-model="sampleDetails.sampleNo" type="text" hide-details label="Enter Sample Size #"></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-select v-if="showStockNumberOption == true" v-model="sampleDetails.stockNo" hide-details label="Enter Stock #" v-bind:items="stockNumber" item-text="stockNo" item-value="id"> </v-select>
                <v-select v-if="showStockNumberOption == false" v-model="sampleDetails.stockNo" hide-details label="Client Product ID" v-bind:items="clientProductIdList" item-text="clientProductId" item-value="productId"> </v-select>
              </v-col>
              <v-col cols="12" sm="1">
                <v-btn icon color="#c02032" :disabled="disableAddSampleDetails()" @click.stop="addSampleDetails(sampleDetails)">
                  <v-icon color="#c02032" class="app-header-icon">add</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-data-table :headers="productSampleDetailsHeader" :items="workorderProductSampleDetails" :items-per-page="-1" :hide-default-header="false" v-bind="datatableRowsPerPage" :hide-default-footer="false" class="elevation-1" >
              <template v-slot:item.stockNo="{ item }">
                <!-- <span>{{getStockNo(item.stockNo)}}</span> -->

                <v-select :readonly="item.readonly" v-if="showStockNumberOption == true" v-model="item.stockNo" hide-details label="Enter Stock #" v-bind:items="stockNumber" item-text="stockNo" item-value="id"> </v-select>
                <v-select :readonly="item.readonly" v-if="showStockNumberOption == false" v-model="item.stockNo" hide-details label="Client Product ID" v-bind:items="clientProductIdList" item-text="clientProductId" item-value="productId"> </v-select>
              </template>
              <template v-slot:item.size="{ item }">
                <v-text-field style="width: 100px;margin: auto;" class="number_text" v-model="item.size" :readonly="item.readonly"> </v-text-field>
              </template>
              <template v-slot:item.color="{ item }">
                <v-text-field style="width: 100px;margin: auto;" class="number_text" v-model="item.color" :readonly="item.readonly"> </v-text-field>
              </template>
              <template v-slot:item.sampleNo="{ item }">
                <v-text-field style="width: 100px;margin: auto;" class="number_text" v-model="item.sampleNo" :readonly="item.readonly"> </v-text-field>
              </template>
              <template v-slot:item.edit="{ item }">
                <v-btn :disabled="item.readonly" icon color="#c02032" @click="editSampleDetails(item)">
                  <v-icon color="#c02032" class="app-header-icon">save</v-icon>
                </v-btn>
                <span></span>
              </template>
              <template v-slot:item.delete="{ item }">
                <v-btn :disabled="item.readonly" icon color="#c02032" @click="del_show_dialog(item, 'sampledetails')">
                  <v-icon color="#c02032" class="app-header-icon">delete</v-icon>
                </v-btn>
                <span></span>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-row>
        <v-col cols="12" sm="6">
          <v-btn color="#c02032" text @click="dialogCriticalPOM = true"> <v-icon color="#c02032">add</v-icon><span class="headline"> Add Critical POM</span> </v-btn>
        </v-col>
        <v-col cols="12" sm="6"> </v-col>
      </v-row>

      <v-dialog v-model="dialogCriticalPOM" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Add Critical POM</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-select v-bind:items="criticalPOM" item-text="name" item-value="id" v-model="criticalPOMDetails.criticalPomId" label="Critical POM" @change="onChangeCriticalPOM()" single-line bottom></v-select>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field v-model="criticalPOMDetails.others" label="Others" maxlength="100" :disabled="disableOthers"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field v-model="criticalPOMDetails.specificPom" label="Specific POM"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="criticalPOMDetails.upperTol" type="text" @keyup="returnNumber(criticalPOMDetails, 'upperTol')" label="Upper Tolerance"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="criticalPOMDetails.lowerTol" type="text" @keyup="returnNumber(criticalPOMDetails, 'lowerTol')" label="Lower Tolerance"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeModal()">Close</v-btn>
            <v-btn color="blue darken-1" :disabled="disableSaveCriticalPom" text @click="saveCriticalPOMDetails()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div v-for="(item, index) in criticalPOMListSort" :key="index">
        <v-expansion-panels class="mb-6">
          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate>
              <v-toolbar-title>
                <v-data-table :headers="criticalPOMHeader" :items="filterCriticalPOM(item.id, item.tempId)" :items-per-page="-1" style="margin-top: 30px;" hide-default-footer :hide-default-header="false" :item-key="index + item.id">
                  <template v-slot:item.criticalPomId="{ item }">
                    <span>{{ getCriticalPom(item.criticalPomId) }}</span>
                  </template>
                  <template v-slot:item.outOfPomPlus="{ item }">
                    <span>{{ (item.outOfPomPlus = getOutOfPomPlus(item.sampleDetailPointOfMeasurements)) }}</span>
                  </template>
                  <template v-slot:item.outOfPomMinus="{ item }">
                    <span>{{ (item.outOfPomMinus = getOutOfPomMinus(item.sampleDetailPointOfMeasurements)) }}</span>
                  </template>
                  <template v-slot:item.edit="{ item }">
                    <v-btn :disabled="item.readonly" icon color="#c02032" @click="editCriticalPOMDetails(item)">
                      <v-icon color="#c02032" class="app-header-icon">edit</v-icon>
                    </v-btn>
                    <span></span>
                  </template>
                  <template v-slot:item.delete="{ item }">
                    <v-btn :disabled="item.readonly" icon color="#c02032" @click="del_show_dialog(item, 'criticalpom')">
                      <v-icon color="#c02032" class="app-header-icon">delete</v-icon>
                    </v-btn>
                    <span></span>
                  </template>
                </v-data-table>
              </v-toolbar-title>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-data-table v-if="item.readonly" :headers="skuDetailsCriticalHeader" hide-default-header v-bind="datatableRowsPerPage" :items="item.sampleDetailPointOfMeasurements" :items-per-page="-1" :hide-default-footer="false" class="elevation-1" :item-key="index + item.id + item.tempId">
                <template v-slot:item.workOrderProductSampleDetail.stockNo="{ item }">
                  <span>{{ item.workOrderProductSampleDetail != null ? (showStockNumberOption == true ? getStockNo(item.workOrderProductSampleDetail.stockNo) : getClientProductId(item.workOrderProductSampleDetail.stockNo)) : null }}</span>
                </template>
                <template v-slot:item.actualSize="{ item }">
                  <span></span>
                </template>
                <template v-slot:item.delete="{ item }">
                  <v-btn disabled icon color="#c02032">
                    <v-icon color="#c02032" class="app-header-icon">delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>

              <v-data-table v-else :headers="skuDetailsCriticalHeader" hide-default-header v-bind="datatableRowsPerPage" :items="item.sampleDetailPointOfMeasurements" :items-per-page="-1" :hide-default-footer="false" class="elevation-1"  :item-key="index + item.id + item.tempId">
                <template v-slot:item.workOrderProductSampleDetail.stockNo="{ item }">
                  <span>{{ item.workOrderProductSampleDetail != null ? (showStockNumberOption == true ? getStockNo(item.workOrderProductSampleDetail.stockNo) : getClientProductId(item.workOrderProductSampleDetail.stockNo)) : null }}</span>
                </template>
                <template v-slot:item.actualSize="{ item }">
                  <v-text-field v-if="item.workOrderProductSampleDetail != null && !item.workOrderProductSampleDetail.readonly" style="width: 100px;margin: auto;" class="number_text" v-model="item.actualSize" @change="changeActualSize(item)"> </v-text-field>
                </template>
                <template v-slot:item.delete="{ item }">
                  <v-btn  v-if="item.workOrderProductSampleDetail != null" :disabled="!item.workOrderProductSampleDetail || item.workOrderProductSampleDetail.readonly" icon color="#c02032" @click="del_show_dialog(item, 'sampledetailpointofmeasurement')">
                    <v-icon color="#c02032" class="app-header-icon">delete</v-icon>
                  </v-btn>
                  <span></span>
                </template>
              </v-data-table>
              <!-- <template>
                            <h1>
                                <p v-show="noDefect(item.id)">No Defect</p>
                            </h1>
                            </template> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <v-dialog v-model="showProgressDialog" :persistent="true">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <div class="text-center">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog id="k_tab2_confirm_dialog" v-model="showDialogDeleteSampleDetailPointOfMeasurment" width="300">
        <v-card>
          <v-card-title class="headline"></v-card-title>
          <v-card-text>Are you sure you want to delete this Sample Details?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn id="sample_details_yes" color="blue darken-1" text @click="deleteSampleDetailPointOfMeasurement(delSampleDetailPointOfMeasure)">Yes</v-btn>
            <v-btn id="sample_details_delete_no" color="blue darken-1" text @click="showDialogDeleteSampleDetailPointOfMeasurment = false">No</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog id="k_tab2_confirm_dialog" v-model="showDialogDeleteSampleDetails" width="300">
        <v-card>
          <v-card-title class="headline"></v-card-title>
          <v-card-text>Are you sure you want to delete this Sample Details?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn id="sample_details_yes" color="blue darken-1" text @click="deleteSampleDetails(delSampleDetails)">Yes</v-btn>
            <v-btn id="sample_details_delete_no" color="blue darken-1" text @click="showDialogDeleteSampleDetails = false">No</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog id="k_tab2_confirm_dialog" v-model="showDialogDeleteCriticalPOM" width="300">
        <v-card>
          <v-card-title class="headline"></v-card-title>
          <v-card-text>Are you sure you want to delete this Critical POM?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn id="critical_pom_yes" color="blue darken-1" text @click="deleteCriticalPOM(delCriticalPOM)">Yes</v-btn>
            <v-btn id="critical_pom_no" color="blue darken-1" text @click="showDialogDeleteCriticalPOM = false">No</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Vue from "vue";
import * as _ from "lodash";
import VueLodash from "vue-lodash";
import apiDetails from "@/shared/request/apiDetails";

export default {
  name: "SizeMeasurementPOM",
  props: {
    workOrderId: String,
    productSizeMeasurementList: Array,
    workOrder: Object,
  },
  data() {
    return {
      datatableRowsPerPage: {
        "footer-props": {
          "items-per-page-options": [10],
        },
      },
      delSampleDetails: null,
      delCriticalPOM: null,
      delSampleDetailPointOfMeasure: null,
      showDialogDeleteSampleDetailPointOfMeasurment: false,
      showDialogDeleteCriticalPOM: false,
      showDialogDeleteSampleDetails: false,
      showProgressDialog: false,
      workOrderProductSampleDetailsHeader: [
        {
          text: "Size",
          align: "center",
          sortable: false,
          value: "size",
          width: "18%",
        },
        {
          text: "Color",
          align: "center",
          sortable: false,
          value: "color",
          width: "18%",
        },
        {
          text: "Sample Size #",
          align: "center",
          sortable: false,
          value: "sampleNo",
          width: "18%",
        },
        {
          text: "Stock #",
          align: "center",
          sortable: false,
          value: "stockNo",
          width: "36%",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "edit",
          width: "5%",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "delete",
          width: "5%",
        },
      ],
      skuDetailsCriticalHeader: [
        {
          text: "Size",
          align: "center",
          sortable: false,
          value: "workOrderProductSampleDetail.size",
        },
        {
          text: "Color",
          align: "center",
          sortable: false,
          value: "workOrderProductSampleDetail.color",
        },
        {
          text: "Sample Size #",
          align: "center",
          sortable: false,
          value: "workOrderProductSampleDetail.sampleNo",
        },
        {
          text: "Stock #",
          align: "center",
          sortable: false,
          value: "workOrderProductSampleDetail.stockNo",
        },
        {
          text: "Actual Size",
          align: "center",
          sortable: false,
          value: "actualSize",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "delete",
        },
      ],

      criticalPOMHeader: [
        {
          text: "#",
          align: "center",
          sortable: false,
          value: "number",
          width: "5%",
        },
        {
          text: "Critical POM",
          align: "center",
          sortable: false,
          value: "criticalPomId",
          width: "600px",
        },
        {
          text: "Others",
          align: "center",
          sortable: false,
          value: "others",
          width: "300px",
        },
        {
          text: "Specific POM",
          align: "center",
          sortable: false,
          value: "specificPom",
          width: "300px",
        },
        {
          text: "Upper Tolerance",
          align: "center",
          sortable: false,
          value: "upperTol",
          width: "8%",
        },
        {
          text: "Lower Tolerance",
          align: "center",
          sortable: false,
          value: "lowerTol",
          width: "8%",
        },
        {
          text: "Out of POM(+)",
          align: "center",
          sortable: false,
          value: "outOfPomPlus",
          width: "8%",
        },
        {
          text: "Out of POM(-)",
          align: "center",
          sortable: false,
          value: "outOfPomMinus",
          width: "8%",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "edit",
          width: "5%",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "delete",
          width: "5%",
        },
      ],
      skuDetailsList: [],
      criticalPOMList: [],
      sampleDetails: {
        id: "",
        size: "",
        color: "",
        sampleNo: "",
        stockNo: "",
      },
      criticalPOMDetails: {
        id: "",
        criticalPomId: "",
        criticalPom: "",
        others: "",
        specificPom: "",
        upperTol: "",
        lowerTol: "",
        outOfPomPlus: "",
        outOfPomMinus: "",
      },
      dialogCriticalPOM: false,
      criticalPOM: [],
      productSizeResult: [],
      stockNumber: [],
      workorderProductSampleDetails: [],
      workOrderSizeMeasurements: {
        totalNoOfSamplesMeasured: null,
        totalOfCritical: null,
      },
      productSizeMeasurementId: "",
      showStockNumberOption: null,
    };
  },
  async mounted() {
    if (window.navigator.onLine !== true) {
      let response = await caches.match(this.apiList.getWOPSD + "/" + this.workOrderId);
      if (!response) {
        this.$router.replace({
          path: "/error",
          name: "error",
        });
      }
    }
    this.$store.dispatch("navigation/setLoadingPage",{show: true});
    if (window.navigator.onLine === "true") {
      this.showProgressDialog = true;
    }
    if (this.productSizeMeasurementList.length > 0) {
      this.productSizeMeasurementId = this.productSizeMeasurementList.filter((x) => x.isCarton == false)[0].id;
    }
    
    this.utilityFn.getServerData("get", this.apiList.getAllCriticalPomList, {}, (result) => {
      this.criticalPOM = result.data.data;
    });
    this.utilityFn.getServerData("get", this.apiList.getAllProductSizeResultList, {}, (result) => {
      this.productSizeResult = result.data.data;
    });

    this.utilityFn.getServerData("get", this.apiList.getStylePerWorkOrderId + "/" + this.workOrderId, {}, (result) => {
      this.stockNumber = result.data.data.filter((x) => x.stockNo != null);
      this.loadData();
      //#region
      //  this.utilityFn.getServerData('get', this.apiList.getSampleDetailPointOfMeasurementPerWorkOrderId + '/' + '141c0a00-a263-4956-6a11-08d8132a9e28', {}, result => {
      //     this.skuDetailsList = result.data.data.sampleDetailPOM[0];
      //     this.workOrderSizeMeasurements = result.data.data.workOrderSizeMeasurements
      //         // this.skuDetailsList.forEach(x => {
      //         //     let criticalPOMList = this.criticalPOMList.filter(y => y.id === x.workOrderProductPointOfMeasurements[0].id)
      //         //     if(criticalPOMList.length === 0){
      //         //         let workOrderProductPointOfMeasurements = x.workOrderProductPointOfMeasurements[0]
      //         //         workOrderProductPointOfMeasurements.number = this.criticalPOMList.length + 1
      //         //         this.criticalPOMList.push(workOrderProductPointOfMeasurements)

      //         //     }
      //         // })
      //         this.showProgressDialog=false;
      //     })
      //#endregion
    });

    this.utilityFn.getServerData("get", this.apiList.getClientCustomConfigs, { clientId: this.workOrder.clientId }, (res) => {
      if (res.data) {
        this.showStockNumberOption = !!res.data.data.find((x) => x.configKey === "DISPLAY_STOCK_NUMBER_POM" && x.isActive);
      }
    });
  },
  methods: {
    returnNumber(obj, name) {
      if (obj[name] == null) {
        //TAB was pressed
        return;
      }
      if (obj[name] != "") {
        obj[name] = obj[name].toString().replace(/[^-\d.]/g, ""); //清除“数字”和“.”以外的字符
        obj[name] = obj[name].toString().replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
        obj[name] = obj[name]
          .toString()
          .replace(".", "$#$")
          .replace(/\./g, "")
          .replace("$#$", ".");
        obj[name] = obj[name].toString().replace(/^(\-)*(\d+)\.(\d\d\d\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      }
    },
    onChangeCriticalPOM() {
      this.criticalPOMDetails.others = "";
    },
    deleteSampleDetailPointOfMeasurement(item) {
      this.showProgressDialog = true;
      this.utilityFn.getServerData("delete", this.apiList.deleteSampleDetailPointOfMeasurement + "/" + item.id, {}, (result) => {
        this.showDialogDeleteSampleDetailPointOfMeasurment = false;
        this.loadData();
      },() => {
        if (window.navigator.onLine !== true) {
          this.criticalPOMList.forEach(pom => {
            pom.sampleDetailPointOfMeasurements = pom.sampleDetailPointOfMeasurements.filter(x => x.id !== item.id); 
          });
          this.showDialogDeleteSampleDetailPointOfMeasurment = false;
        }
        this.showProgressDialog = false;
      });

      console.log(item);
    },
    changeWorkOrderSizeMeasurements() {
      this.workOrderSizeMeasurements.totalFoundPomPlus = this.totalOutofPOMPositive;
      this.workOrderSizeMeasurements.totalFoundPomMinus = this.totalOutofPOMNegative;
      this.workOrderSizeMeasurements.totalNoOfSampleOutOfPom = this.totalNoOfSamples;
      this.workOrderSizeMeasurements.percentageSampleOutOfPom = this.percentageSamples;
      this.workOrderSizeMeasurements.percentageFoundPomPlus = this.percentageFoundPositive;
      this.workOrderSizeMeasurements.percentageFoundPomMinus = this.percentageFoundNegative;
      this.$store.dispatch("inspection/setChildWorkOrderSizeMeasurement", this.workOrderSizeMeasurements);
    },
    editCriticalPOMDetails(item) {
      this.dialogCriticalPOM = true;
      this.criticalPOMDetails = {
        id: item.id,
        criticalPomId: item.criticalPomId,
        specificPom: item.specificPom,
        upperTol: item.upperTol,
        lowerTol: item.lowerTol,
        outOfPomPlus: item.outOfPomPlus,
        outOfPomMinus: item.outOfPomMinus,
        others: item.others,
      };
    },
    editSampleDetails(item) {
      this.showProgressDialog = true;
      this.utilityFn.getServerData(
        "post",
        this.apiList.sampleDetailUpsert + "/" + this.productSizeMeasurementId,
        item,
        (result) => {
          this.loadData();
        },
        () => {
          if (window.navigator.onLine !== true) {
            // alert(this.msgList.NoConnection);
            this.showProgressDialog = false;
          }
           this.showProgressDialog = false;
        }
      );
    },
    returnNumber(obj, name) {
      if (obj[name] == null) {
        //TAB was pressed
        return;
      }
      if (obj[name] != "") {
        obj[name] = obj[name].replace(/[^-\d.]/g, ""); //清除“数字”和“.”以外的字符
        obj[name] = obj[name].replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
        obj[name] = obj[name]
          .replace(".", "$#$")
          .replace(/\./g, "")
          .replace("$#$", ".");
        obj[name] = obj[name].replace(/^(\-)*(\d+)\.(\d\d\d\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      }
    },
    del_show_dialog(item, details) {
      switch (details) {
        case "sampledetails":
          this.showDialogDeleteSampleDetails = true;
          this.delSampleDetails = item;
          break;
        case "criticalpom":
          this.showDialogDeleteCriticalPOM = true;
          this.delCriticalPOM = item;
          break;
        case "sampledetailpointofmeasurement":
          this.showDialogDeleteSampleDetailPointOfMeasurment = true;
          this.delSampleDetailPointOfMeasure = item;
          break;
      }
    },
    disableAddSampleDetails() {
      let value = true;
      if (this.sampleDetails.size != "" && this.sampleDetails.color != "" && this.sampleDetails.sampleNo != "" && this.sampleDetails.stockNo != "") {
        value = false;
      }
      return value;
    },
    changeActualSize(item) {
      let criticalPOMList = this.criticalPOMList.filter((x) => x.id == item.woppomId);
      if (item.actualSize >= criticalPOMList[0].lowerTol && item.actualSize <= criticalPOMList[0].upperTol) {
        item.actualSize = "";
      }
      this.showProgressDialog = true;
      let data = {
        id: item.id,
        woppomId: item.woppomId,
        wopsdId: item.wopsdId,
        wopsmrId: item.wopsmrId,
        actualSize: item.actualSize,
      };
      this.utilityFn.getServerData("post", this.apiList.updateSDPOM, data, (result) => {
        this.showProgressDialog = false;
        this.changeWorkOrderSizeMeasurements();
      },() => {
        this.showProgressDialog = false;
      });
    },
    loadData() {
      this.utilityFn.getServerData("get", this.apiList.getWOPSD + "/" + this.workOrderId, {}, (result) => {
        this.workorderProductSampleDetails = result.data.data;
      });
      this.utilityFn.getServerData("get", this.apiList.getWOPPOM + "/" + this.workOrderId + "/" + this.productSizeMeasurementId, {}, (result) => {
        this.criticalPOMList = result.data.data.workOrderProductPointOfMeasurements;
        this.$store.dispatch("inspection/setCriticalPOMList", this.criticalPOMList);
        this.criticalPOMList = this.criticalPOMList.map((x) => {
          x.criticalPomName = this.getCriticalPom(x.criticalPomId);
          return x;
        });
        this.workOrderSizeMeasurements = this.workOrderSizeMeasurements.totalOfCritical == null || this.workOrderSizeMeasurements.totalNoOfSamplesMeasured == null ? result.data.data.workOrderSizeMeasurements : this.workOrderSizeMeasurements;
        let number = 1;
        this.criticalPOMList = _.orderBy(this.criticalPOMList, "criticalPomName", "asc");
        this.criticalPOMList.forEach((x) => {
          x.number = number++;
          return x;
        });
        this.showProgressDialog = false;
        this.$store.dispatch("navigation/setLoadingPage",{show: this.showProgressDialog });
        this.changeWorkOrderSizeMeasurements();
      },() => {
        this.showProgressDialog = false;
        this.$store.dispatch("navigation/setLoadingPage",{show: this.showProgressDialog });
      });

      //#region
      //  this.utilityFn.getServerData('get', this.apiList.getSampleDetailPointOfMeasurementPerWorkOrderId + '/' + '141c0a00-a263-4956-6a11-08d8132a9e28', {}, result => {
      //         this.skuDetailsList = result.data.data.sampleDetailPOM[0];
      //        // this.workOrderSizeMeasurements = result.data.data.workOrderSizeMeasurements
      //             // this.skuDetailsList.forEach(x => {
      //             //     let criticalPOMList = this.criticalPOMList.filter(y => y.id === x.workOrderProductPointOfMeasurements[0].id)
      //             //     if(criticalPOMList.length === 0){
      //             //         let workOrderProductPointOfMeasurements = x.workOrderProductPointOfMeasurements[0]
      //             //         workOrderProductPointOfMeasurements.number = this.criticalPOMList.length + 1
      //             //         this.criticalPOMList.push(workOrderProductPointOfMeasurements)

      //             //     }
      //             // })
      //             this.showProgressDialog=false;
      //         })
      //#endregion
    },
    getCriticalPom(id) {
      let criticalPom = this.criticalPOM.filter((x) => x.id === id);
      return criticalPom[0].name;
    },
    getStockNo(stockNoId) {
      let stockNo = this.stockNumber.filter((x) => x.id === stockNoId);
      return stockNo[0].stockNo;
    },
    getClientProductId(id) {
      let item = this.clientProductIdList.find((x) => x.productId === id);
      return item ? item.clientProductId : "";
    },

    filterCriticalPOM(id, tempId) {
      let criticalPOMList = this.criticalPOMList.filter((x) => x.id === id && (!tempId || x.tempId === tempId));
      return criticalPOMList;
    },
    addSampleDetails(sampleDetails) {
      this.showProgressDialog = true;
      this.utilityFn.getServerData(
        "post",
        this.apiList.sampleDetailUpsert + "/" + this.productSizeMeasurementId,
        this.sampleDetails,
        (result) => {
          this.loadData();
          this.clearSampleDetails();
        },
        () => {
          if (window.navigator.onLine !== true) {
            const wopSampleDetail = {...this.sampleDetails, readonly: true, tempId: Math.random().toString() };
            this.workorderProductSampleDetails.push(wopSampleDetail);
            
            this.criticalPOMList.forEach(pom => {
              pom.sampleDetailPointOfMeasurements.push({
                actualSize: null,
                id: null,
                woppomId: null,
                wopsdId: null,
                wopsmrId: null,
                tempId: wopSampleDetail.tempId,
                workOrderProductSampleDetail: wopSampleDetail
              })
            })

            // alert(this.msgList.NoConnection);
            this.showProgressDialog = false;
            this.clearSampleDetails();
          }
          this.showProgressDialog = false;
        }
      );
    },
    deleteSampleDetails(sampleDetails) {
      this.showProgressDialog = true;
      this.utilityFn.getServerData("delete", this.apiList.deleteSampleDetails + "/" + sampleDetails.id + "/WOPSD", {}, (result) => {
        this.showDialogDeleteSampleDetails = false;
        this.loadData();
      },() => {
        if (window.navigator.onLine !== true) {
          this.workorderProductSampleDetails = this.workorderProductSampleDetails.filter(x => x.id !== sampleDetails.id);
          this.criticalPOMList.forEach(pom => {
            pom.sampleDetailPointOfMeasurements = pom.sampleDetailPointOfMeasurements.filter(x => x.wopsdId !== sampleDetails.id); 
          });
          this.showDialogDeleteSampleDetails = false;
        }
        this.showProgressDialog = false;
      });
    },
    deleteCriticalPOM(criticalPOM) {
      this.showProgressDialog = true;
      this.utilityFn.getServerData("delete", this.apiList.deleteSampleDetails + "/" + criticalPOM.id + "/WOPPOM", {}, (result) => {
        this.showDialogDeleteCriticalPOM = false;
        this.loadData();
      },() => {
        if (window.navigator.onLine !== true) {
          this.criticalPOMList = this.criticalPOMListSort.filter(x => x.id !== criticalPOM.id);
          this.showDialogDeleteCriticalPOM = false;
        }
        this.showProgressDialog = false;
      });
    },
    clearSampleDetails() {
      this.sampleDetails = {
        id: "",
        size: "",
        color: "",
        sampleNo: "",
        stockNo: "",
      };
    },
    closeModal() {
      this.dialogCriticalPOM = false
      this.clearCriticalPOMDetails();
    },
    saveCriticalPOMDetails() {
      this.showProgressDialog = true;

      this.utilityFn.getServerData(
        "post",
        this.apiList.workOrderProductPointOfMeasurementUpsert + "/" + this.productSizeMeasurementId,
        this.criticalPOMDetails,
        (result) => {
          this.loadData();
          this.clearCriticalPOMDetails();
          this.dialogCriticalPOM = false;
        },
        () => {
          if (window.navigator.onLine != true) {
            // alert(this.msgList.NoConnection);
            const sampleDetailPointOfMeasurements = this.workorderProductSampleDetails.map(wopSampleDetail => ({
              actualSize: wopSampleDetail.readonly ? "" : wopSampleDetail.actualSize,
              id: wopSampleDetail.readonly ? "" : wopSampleDetail.actualSize,
              woppomId:wopSampleDetail.readonly ? "" : wopSampleDetail.woppomId,
              wopsdId: wopSampleDetail.id,
              wopsmrId: wopSampleDetail.readonly ? "" : wopSampleDetail.wopsmrId,
              tempId: wopSampleDetail.readonly ? wopSampleDetail.tempId : null,
              workOrderProductSampleDetail: wopSampleDetail
            }));
            this.criticalPOMList.push({ ...this.criticalPOMDetails, sampleDetailPointOfMeasurements, readonly: true, tempId: Math.random().toString() });
            this.$store.dispatch("inspection/setCriticalPOMList", this.criticalPOMList);
            this.showProgressDialog = false;
            this.dialogCriticalPOM = false;
            this.clearCriticalPOMDetails();
          } 
        }
      );
    },
    clearCriticalPOMDetails() {
      this.criticalPOMDetails = {
        id: "",
        criticalPomId: "",
        specificPom: "",
        upperTol: "",
        lowerTol: "",
        outOfPomPlus: "",
        outOfPomMinus: "",
        others: "",
      };
    },

    getOutOfPomPlus(sampleDetailPointOfMeasurements) {
      let total = sampleDetailPointOfMeasurements.filter((x) => {
        if (x.actualSize != "" && x.actualSize != null) {
          if (!x.actualSize.includes("-")) {
            return x;
          }
        }
      });
      return total.length;
    },
    getOutOfPomMinus(sampleDetailPointOfMeasurements) {
      let total = sampleDetailPointOfMeasurements.filter((x) => {
        if (x.actualSize != "" && x.actualSize != null) {
          if (x.actualSize.includes("-")) {
            return x;
          }
        }
      });
      return total.length;
    },
  },
  computed: {
    ...mapState("inspection", {
      _workOrderDetails: (state) => state.workOrderDetails,
    }),
    IsWalmart(){ 
      return this._workOrderDetails.clientName == "WALMART GLOBAL SOURCING" || this._workOrderDetails.clientName.toLowerCase() == "asda stores limited";
    },
    disableSaveCriticalPom(){
      return  this.criticalPOMDetails.criticalPomId == "" || this.criticalPOMDetails.specificPom  == "" || this.criticalPOMDetails.lowerTol  == "" || this.criticalPOMDetails.upperTol  == ""
    },
    disableOthers() {
      return this.criticalPOMDetails.criticalPomId != null && this.criticalPOMDetails.criticalPomId != "" ? this.criticalPOM.filter((x) => x.id == this.criticalPOMDetails.criticalPomId)[0].name != "Others" : true;
    },

    criticalPOMListSort() {
      return _.orderBy(this.criticalPOMList, "criticalPomName", "asc");
    },
    totalNoOfSamples: function() {
      let total = this.workorderProductSampleDetails.length;
      return total;
    },
    percentSamples: function() {
      let percentSamples = 0;
      if (this.totalNoOfSamples != 0 && this.workOrderSizeMeasurements.totalNoOfSamplesMeasured != null && this.workOrderSizeMeasurements.totalNoOfSamplesMeasured != "" && this.workOrderSizeMeasurements.totalNoOfSamplesMeasured != 0) {
        percentSamples = (this.totalNoOfSamples / this.workOrderSizeMeasurements.totalNoOfSamplesMeasured) * 100;
      }
      return percentSamples.toFixed(1) + " %";
    },
    percentageSamples: function() {
      let percentSamples = 0;
      if (this.totalNoOfSamples != 0 && this.workOrderSizeMeasurements.totalNoOfSamplesMeasured != null && this.workOrderSizeMeasurements.totalNoOfSamplesMeasured != "" && this.workOrderSizeMeasurements.totalNoOfSamplesMeasured != 0) {
        percentSamples = this.totalNoOfSamples / this.workOrderSizeMeasurements.totalNoOfSamplesMeasured;
      }
      return percentSamples.toFixed(3);
    },

    totalOutofPOMPositive: function() {
      let total = this.criticalPOMList.reduce(function(prev, cur) {
        return Number(prev) + Number(cur.outOfPomPlus == null ? 0 : cur.outOfPomPlus);
      }, 0);
      return total;
    },

    totalOutofPOMNegative: function() {
      let total = this.criticalPOMList.reduce(function(prev, cur) {
        return Number(prev) + Number(cur.outOfPomMinus == null ? 0 : cur.outOfPomMinus);
      }, 0);
      return total;
    },

    percentFoundPositive: function() {
      let total = 0;
      if (this.workOrderSizeMeasurements.totalOfCritical != "" && this.workOrderSizeMeasurements.totalOfCritical != null && this.workOrderSizeMeasurements.totalOfCritical != 0) {
        total = (this.totalOutofPOMPositive / this.workOrderSizeMeasurements.totalOfCritical) * 100;
      }
      // return Math.round(total) + " %";
      return total.toFixed(1) + " %";
    },
    percentageFoundPositive: function() {
      let total = 0;
      if (this.workOrderSizeMeasurements.totalOfCritical != "" && this.workOrderSizeMeasurements.totalOfCritical != null && this.workOrderSizeMeasurements.totalOfCritical != 0) {
        total = this.totalOutofPOMPositive / this.workOrderSizeMeasurements.totalOfCritical;
      }
      return total.toFixed(3);
    },
    percentageFoundNegative: function() {
      let total = 0;
      if (this.workOrderSizeMeasurements.totalOfCritical != "" && this.workOrderSizeMeasurements.totalOfCritical != null && this.workOrderSizeMeasurements.totalOfCritical != 0) {
        total = this.totalOutofPOMNegative / this.workOrderSizeMeasurements.totalOfCritical;
      }
      return total.toFixed(3);
    },
    percentFoundNegative: function() {
      let total = 0;
      if (this.workOrderSizeMeasurements.totalOfCritical != "" && this.workOrderSizeMeasurements.totalOfCritical != null && this.workOrderSizeMeasurements.totalOfCritical != 0) {
        total = (this.totalOutofPOMNegative / this.workOrderSizeMeasurements.totalOfCritical) * 100;
      }
      //return Math.round(total) + " %";
      return total.toFixed(1) + " %";
    },

    clientProductIdList() {
      const list = this.workOrder.products.map((x) => ({ productId: x.productID, clientProductId: x.clientProductId }));
      return _.uniqBy(list, "clientProductId");
    },

    productSampleDetailsHeader() {
      const headers = [];
      this.workOrderProductSampleDetailsHeader.forEach((item) => {
        if (item.text === "Stock #" && this.showStockNumberOption == false) {
          headers.push({ ...item, text: "Client Product ID" });
        } else {
          headers.push(item);
        }
      });
      return headers;
    },
  },

  beforeDestroy() {
    this.criticalPOMList.forEach((item) => {
      let criticalPOMDetails = {
        id: item.id,
        criticalPomId: item.criticalPomId,
        specificPom: item.specificPom,
        upperTol: item.upperTol,
        lowerTol: item.lowerTol,
        outOfPomPlus: item.outOfPomPlus,
        outOfPomMinus: item.outOfPomMinus,
        others: item.others,
      };
      this.utilityFn.getServerData("post", this.apiList.workOrderProductPointOfMeasurementUpsert + "/" + this.productSizeMeasurementId, criticalPOMDetails, 
      (result) => {});
    });

    //  this.workOrderSizeMeasurements.totalNoOfSampleOutOfPom = this.totalNoOfSamples
    //     //this.workOrderSizeMeasurements.percentageSampleOutOfPom = this.percentSamples
    //     this.workOrderSizeMeasurements.totalFoundPomPlus = this.totalOutofPOMPositive
    //     this.workOrderSizeMeasurements.totalFoundPomMinus = this.totalOutofPOMNegative
    //     // this.workOrderSizeMeasurements.percentageFoundPomPlus = this.percentFoundPositive
    //     // this.workOrderSizeMeasurements.percentageFoundPomMinus = this.percentFoundPositive
    //     this.workOrderSizeMeasurements.totalNoOfSamplesMeasured = +this.workOrderSizeMeasurements.totalNoOfSamplesMeasured == 0? null : +this.workOrderSizeMeasurements.totalNoOfSamplesMeasured
    //     this.workOrderSizeMeasurements.totalOfCritical = +this.workOrderSizeMeasurements.totalOfCritical == 0? null :  +this.workOrderSizeMeasurements.totalOfCritical

    //     this.utilityFn.getServerData('post', this.apiList.upsertWorkOrderSizeMeasurement , this.workOrderSizeMeasurements, result => {
    //         })
    //         console.log('workOrderSizeMeasurements',this.workOrderSizeMeasurements)
  },
};
</script>

<style lang="stylus" scoped>
   >>>.v-expansion-panel-content__wrap {
   padding: 0;
   }
   >>>.number_text input {
   text-align: center;
   }
   .workOrderInspectionSizeId
       padding 15px 10px

   .tab_item
       padding 25px 15px
       overflow hidden
       position relative

   .submit-btn
       color: #fff !important

.del_button
	position: absolute;
	right: -3px;
	top: -5px;
.my-text-style >>> .v-text-field__slot input {
    color: red
  }
</style>
