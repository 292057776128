export default {
    navigationState(state) {
      return state;
    },
    isPageTransitionEnabled(state) {
      return state.pageTransition.enable;
    },
    showSideBar(state){
     return state.header.show && state.header.showMenu;
    }
}