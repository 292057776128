<template>
  <div>
    <div class="pastInspectionsPreview" @touchmove="stopTouchmove($event)">
      <p class="title">DRAFT SUMMARY</p>
      <v-expansion-panels multiple v-model="panel">
        <v-expansion-panel key="1">
          <v-expansion-panel-header id="ds_GeneralSummary">General Summary</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="6" sm="6" md="6" id="client_name">Client Name：{{ detailsData.client }}</v-col>
              <v-col cols="6" sm="6" md="6" id="overall_Result">Overall Result：{{ detailsData.result }}</v-col>
              <v-col cols="12" sm="12" md="12" id="supplier_Name">Supplier Name：{{ detailsData.supplier }}</v-col>
              <v-col cols="12" sm="12" md="12" id="inspection_Level">Inspection Level：{{ detailsData.inspectionLevel }}</v-col>
              <v-col cols="12" sm="12" md="12" id="sample_Size">Sample Size：{{ detailsData.sampleSize }}</v-col>
              <v-col cols="12" sm="12" md="12" id="product_Type">Product Type：{{ detailsData.productType }}</v-col>
            </v-row>
            <!-- <v-data-table
              :headers="generalSummaryHeader"
              :items="detailsData.products"
              :hide-default-footer="true"
              class="elevation-1"
              style="margin-top: 30px;"
            ></v-data-table> -->
            <v-data-table id="tbl_GeneralSummary" :headers="generalSummaryHeader" :items="detailsData.products" :items-per-page="-1" :hide-default-footer="true" class="elevation-1" style="margin-top: 30px;">
              <template v-slot:body="{ items }">
                <tbody>
                  <tr :id="'row_' + item.po" v-for="item in items" :key="item.name">
                    <td>{{ item.po }}</td>
                    <td>{{ item.skuDisplay }}</td>
                    <td>{{ item.productDescription }}</td>
                    <td>{{ item.qty }}</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header id="ds_InspectionSummary">Inspection Summary</v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- <v-data-table
              :headers="InspectionHeader"
              :items="InspectionList"
              :hide-default-footer="true"
              class="elevation-1"
              style="margin-top: 30px;"
            >
            </v-data-table> -->
            <v-data-table id="tbl_InspectionList" :headers="InspectionHeader" :items="InspectionList" :items-per-page="-1" :hide-default-footer="true" class="elevation-1" style="margin-top: 30px;">
              <template v-slot:body="{ items }">
                <tbody>
                  <tr :id="'row_' + item.sectionId" v-for="item in items" :key="item.name">
                    <td>{{ item.section }}</td>
                    <td>{{ item.result }}</td>
                    <td>{{ item.findings }}</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header id="ds_FindingSummary">Finding Summary</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table id="tbl_FindingSummary">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"></th>
                    <th class="text-left">Found</th>
                    <th class="text-left">Allowed</th>
                  </tr>
                </thead>
                <tbody>
                  <tr id="row_Critical">
                    <td>Critical</td>
                    <td>{{ workOrder.foundCritical }}</td>
                    <td>{{ workOrder.allowedCritical }}</td>
                  </tr>
                  <tr id="row_Major">
                    <td>Major</td>
                    <td>{{ workOrder.foundMajor }}</td>
                    <td>{{ workOrder.allowedMajor }}</td>
                  </tr>
                  <tr id="row_Minor">
                    <td>Minor</td>
                    <td>{{ workOrder.foundMinor }}</td>
                    <td>{{ workOrder.allowedMinor }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-col span="23">
              <div class="select_type">
                <span id="btn_Critical" :class="change_type == 1 ? 'active' : ''" @click="change_type1('Critical')">Critical</span>
                <span id="btn_Major" :class="change_type == 2 ? 'active' : ''" @click="change_type1('Major')">Major</span>
                <span id="btn_Minor" :class="change_type == 3 ? 'active' : ''" @click="change_type1('Minor')">Minor</span>
              </div>
            </v-col>
            <!-- <v-data-table
            v-bind="datatableRowsPerPage"
              :headers="FindingHeader"
              :items="findingSummary"
              :hide-default-footer="false"
              class="elevation-1"
              style="margin-top: 30px;"
              key="index"
            >
              <template v-slot:item.id="{ item }">
                  <span>{{addIndex(findingSummary.map(function(x) {return x.id; }).indexOf(item.id))}} </span>
            </template>
          </v-data-table> -->

            <v-data-table id="tbl_FindingSummaryList" v-bind="datatableRowsPerPage" :headers="FindingHeader" :items="findingSummary" :items-per-page="-1" :hide-default-footer="false" class="elevation-1" style="margin-top: 30px;" key="index">
              <template v-slot:body="{ items }">
                <tbody>
                  <tr
                    :id="
                      'row_' +
                        addIndex(
                          findingSummary
                            .map(function(x) {
                              return x.id;
                            })
                            .indexOf(item.id)
                        )
                    "
                    v-for="item in items"
                    :key="item.name"
                  >
                    <td>
                      <span
                        >{{
                          addIndex(
                            findingSummary
                              .map(function(x) {
                                return x.id;
                              })
                              .indexOf(item.id)
                          )
                        }}
                      </span>
                    </td>
                    <td>{{ item.defectCategory.name }}</td>
                    <td>{{ item.defectName.name }}</td>
                    <td>{{ item.qty }}</td>
                    <td>{{ item.skuDisplay }}</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header id="ds_SupplierSignOff">Supplier Sign-Off</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="pastInspectionsSignOff" @touchmove="stopTouchmove($event)" style="position: relative;top: -50px;padding-top: 70px;">
              <v-row>
                <v-col cols="9" sm="7" md="10">
                  <p class="top_title">*Supplier/ Factory representative</p>
                </v-col>
                 <v-col cols="1" sm="1" md="1">
                  <div id="btns">
                      <button @click.stop="onImageSelection" id="reset">
                      <span style="vertical-align: middle;">Upload</span>
                    </button>
                  </div>
                </v-col>
                <v-col cols="1" sm="1" md="1">
                  <div id="btns">
                    <button id="reset" @click="reset('Supplier')">
                      <v-icon color="#fff">refresh</v-icon>
                      <span style="vertical-align: middle;">Reset</span>
                    </button>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                <span v-show="isUploadSignature" > {{uploadImageFileName}}</span>
                </v-col>
              </v-row>
              <div v-show="isUploadSignature" id="div_forUploadSignature" style="border:1px #ccc solid;">
                <img id="imageUpload" :key="url" v-if="url" :src="url"  />
              </div>

              <div v-show="!isUploadSignature" id="div_forSignature" style="border:1px #ccc solid;">
                <!-- <signature ref="signature" background="#fff"></signature> -->
                <VueSignaturePad width="100%" height="500px" ref="signaturePad" :options="signatureOptions" />
              </div>

              <!-- Supplier/Factory Representative Name -->
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field maxlength="30" id="txtRepresentativeName" v-model="representativeName" label="* Supplier/Factory Representative Name (English Only)"></v-text-field>
                </v-col>
              </v-row>

               <!-- Supplier/Factory Comment -->
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field maxlength="500" id="txtSupplierFactoryComment" v-model="factoryComment" label="Supplier/Factory Comment"></v-text-field>
                </v-col>
              </v-row>

              <!-- Inspector Signature -->
              <v-row>
                <v-col cols="7" sm="7" md="7">
                  <p class="top_title">*Inspector Signature</p>
                </v-col>
                <v-col cols="5" sm="5" md="5">
                  <div id="btns">
                    <button id="reset" @click="reset('Inspector')">
                      <v-icon color="#fff">refresh</v-icon>
                      <span style="vertical-align: middle;">Reset</span>
                    </button>
                  </div>
                </v-col>
              </v-row>

              <div id="div_inspectorForSignature" style="border:1px #ccc solid;">
                <VueSignaturePad width="100%" height="500px" ref="inspectorSignaturePad" :options="signatureOptions" />
              </div>

              <br />

              <!-- Factory Time IN | OUT -->
              <v-row class="rowClass">
                <v-col class="colClass" cols="1" sm="3" md="2">     
                  <span class="mr-right-3">Factory Time In</span>
                </v-col>
                <v-col cols="1" sm="2" md="1"></v-col>
                <v-col cols="1" sm="4" md="2">     
                  <span class="mr-right-3">Factory Time Out</span>
                </v-col>
              </v-row> 

              <v-row>

                <!-- Factory Time In -->
                <v-col cols="1" sm="2" md="1">
                  <v-text-field id="txtFactoryTimeIn" maxlength="2" height="90px" max class="hrClass textAlignCenter" @keyup="keyUpValue($event, 'FactoryTimeIn')" v-model="factoryTimeIn" hide-details outlined dense></v-text-field>
                  <span class="hintClass">01-12</span>
                </v-col>  
                <span class="colonClass">:</span>
                <v-col cols="1" sm="2" md="1">
                  <v-text-field id="txtFactoryTimeInMin" maxlength="2" height="90px"  class="hrClass textAlignCenter" @keyup="keyUpMinValue($event, 'FactoryTimeInMin')" v-model="factoryTimeInMin" hide-details outlined dense></v-text-field>
                  <span class="hintClass">00-59</span>
                </v-col>
                <v-col cols="1" sm="1" md="1">
                  <div class="divClass">
                    <v-btn id="btnTimeInAM" elevation="2" depressed small :color="timeInAm ? '#c2e0fd' : '#fff'" class="btnSize btnSpacing" @click="convertTime('TimeInAM', true)">AM</v-btn>
                    <br />
                    <v-btn id="btnTimeInPM" elevation="2" depressed small :color="timeInPm ? '#c2e0fd' : '#fff'" class="btnSize" @click="convertTime('TimeInPM', false)">PM</v-btn>                         
                  </div>
                </v-col>

                <!-- Factory Time Out -->                
                <v-col cols="1" sm="2" md="1">
                  <v-text-field id="txtFactoryTimeOut" maxlength="2" height="90px"  class="hrClass textAlignCenter" @keyup="keyUpValue($event, 'FactoryTimeOut')" v-model="factoryTimeOut" hide-details outlined dense></v-text-field>
                  <span class="hintClass">01-12</span>
                </v-col>  
                <span class="colonClass">:</span>
                <v-col cols="1" sm="2" md="1">
                  <v-text-field id="txtFactoryTimeOutMin" maxlength="2" height="90px"  class="hrClass textAlignCenter" @keyup="keyUpMinValue($event, 'FactoryTimeOutMin')" v-model="factoryTimeOutMin" hide-details outlined dense></v-text-field>
                  <span class="hintClass">00-59</span>
                </v-col>
                <v-col cols="1" sm="1" md="1">
                  <div class="divClass">
                    <v-btn id="btnTimeOutAM" elevation="2" depressed small :color="timeOutAm ? '#c2e0fd' : '#fff'" class="btnSize btnSpacing" @click="convertTime('TimeOutAM', true)">AM</v-btn>
                    <br />
                    <v-btn id="btnTimeOutPM" elevation="2" depressed small :color="timeOutPm ? '#c2e0fd' : '#fff'" class="btnSize" @click="convertTime('TimeOutPM', false)">PM</v-btn>                         
                  </div>
                </v-col>

              </v-row>

              <!-- Inspection Start | End Time -->
              <v-row class="rowClass">
                <v-col class="colClass" cols="1" sm="4" md="2">     
                  <span class="mr-right-3">Inspection Start Time</span>
                </v-col>
                <v-col cols="1" sm="1" md="1"></v-col>
                <v-col cols="1" sm="4" md="2" class="colPadding">     
                  <span class="mr-right-3">Inspection End Time</span>
                </v-col>
              </v-row>  

              <v-row>

                <!-- Inspection Start -->
                <v-col cols="1" sm="2" md="1">
                  <v-text-field id="txtInspectionStartTime" maxlength="2" height="90px"  class="hrClass textAlignCenter" @keyup="keyUpValue($event, 'InspectionStartTime')" v-model="inspectionStartTime" hide-details outlined dense></v-text-field>
                  <span class="hintClass">01-12</span>
                </v-col>  
                <span class="colonClass">:</span>
                <v-col cols="1" sm="2" md="1">
                  <v-text-field id="txtInspectionStartTimeMin" maxlength="2" height="90px"  class="hrClass textAlignCenter" @keyup="keyUpMinValue($event, 'InspectionStartTimeMin')" v-model="inspectionStartTimeMin" hide-details outlined dense></v-text-field>
                  <span class="hintClass">00-59</span>
                </v-col>
                <v-col cols="1" sm="1" md="1">
                  <div class="divClass">
                    <v-btn id="btnInspectionStartTimeAM" elevation="2" depressed small :color="startTimeAm ? '#c2e0fd' : '#fff'" class="btnSize btnSpacing" @click="convertTime('StartTimeAM', true)">AM</v-btn>
                    <br />
                    <v-btn id="btnInspectionStartTimePM" elevation="2" depressed small :color="startTimePm ? '#c2e0fd' : '#fff'" class="btnSize" @click="convertTime('StartTimePM', false)">PM</v-btn>                         
                  </div>
                </v-col>

                <!-- Inspection End -->                
                <v-col cols="1" sm="2" md="1">
                  <v-text-field id="txtInspectionEndTime" maxlength="2" height="90px"  class="hrClass textAlignCenter" @keyup="keyUpValue($event, 'InspectionEndTime')" v-model="inspectionEndTime" hide-details outlined dense></v-text-field>
                  <span class="hintClass">01-12</span>
                </v-col>  
                <span class="colonClass">:</span>
                <v-col cols="1" sm="2" md="1">
                  <v-text-field id="txtInspectionEndTimeMin" maxlength="2" height="90px"  class="hrClass textAlignCenter" @keyup="keyUpMinValue($event, 'InspectionEndTimeMin')" v-model="inspectionEndTimeMin" hide-details outlined dense></v-text-field>
                  <span class="hintClass">00-59</span>
                </v-col>
                <v-col cols="1" sm="1" md="1">
                  <div class="divClass">
                    <v-btn id="btnInspectionEndTimeAM" elevation="2" depressed small :color="endTimeAm ? '#c2e0fd' : '#fff'" class="btnSize btnSpacing" @click="convertTime('EndTimeAM', true)">AM</v-btn>
                    <br />
                    <v-btn id="btnInspectionEndTimePM" elevation="2" depressed small :color="endTimePm ? '#c2e0fd' : '#fff'" class="btnSize" @click="convertTime('EndTimePM', false)">PM</v-btn>
                  </div>
                </v-col>
              </v-row>

              <br />

              <!-- Email Draft -->
              <v-row id="chk_EmailDraft">
                <v-checkbox v-model="formData.emailToSupplier" @change="emailToggle($event)" :label="'Email Draft Summary'"></v-checkbox>
              </v-row>

              <v-row>
                <v-btn id="preview-report" color="#07f" class="ma-2 white--text" width="200" :disabled="!workOrder.workOrderId" @click="previewReport()">
                  Preview Draft Report
                  <v-icon right dark>
                    mdi-file-eye
                  </v-icon>
                </v-btn>
                <!-- <v-btn
                    id="upload-attachment"
                    color="#07f"
                    class="ma-2 white--text"
                    width="200"
                    @click="uploadAttachment()">
                    Attach Draft Report
                    <v-icon right dark>
                        mdi-file-upload
                    </v-icon>
                </v-btn>
                <span id="display-attachment-name" class="d-flex align-center">
                    {{ displayAttachmentName() }}
                </span> -->
              </v-row>

              <!-- <v-row id="forEmailSupplier">
                <v-col cols="12" sm="12" md="12">
                  <v-text-field id="emailSupplier"
                    v-model="formData.emailAddress"
                    :disabled="!formData.emailToSupplier"
                    type="email"
                    label="Enter Supplier Email Address"
                  ></v-text-field>
                </v-col>
              </v-row> -->
              <v-row v-if="formData.emailToSupplier">
                <v-col>
                  <label>Select Export Format</label>
                  <v-radio-group v-model="formData.reportFormat" row :rules="[(v) => !!v || 'Required']">
                    <v-radio label="PDF" value="pdf"></v-radio>
                    <v-radio label="Microsoft Word" value="docx"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row id="forOverAllResult">
                <v-col cols="12" sm="12" md="12">
                  <v-select id="forSelectOverAllResult" v-bind:items="ratings" item-text="description" item-value="id" v-model="formData.ratingId" @change="onRatingSelectChange()" label="Overall Result*" single-line bottom></v-select>
                </v-col>
                <v-col v-show="showFailCodes">
                  <v-container fluid>
                    <span class="required">{{ resultCodeLabel }} *</span>
                    <v-btn id="toolTip" v-show="!isCustomResultCodes" icon dark @click="show_toopltips = true">
                      <v-icon color="rgb(115, 115, 115)" style="margin-left: 15px;">help</v-icon>
                    </v-btn>
                    <v-checkbox v-for="(item, index) in resultCodes" :key="index" v-model="formData.failCodes" :label="resultCodeDescription(item)" :value="resultCodeValue(item)"> </v-checkbox>
                  </v-container>
                  <v-dialog v-model="show_toopltips" width="500px">
                    <v-card>
                      <v-card-content>
                        <table class="fail_code">
                          <tr>
                            <td valign="top">UL0</td>
                            <td>
                              <p>Finding of issue on product that was not covered by below reasons</p>
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">UL1</td>
                            <td>
                              <p>
                                <strong>Product Deviation</strong>
                              </p>
                              <p>Product is not conform with specification/ gold seal sample</p>
                              <p>- Dimension</p>
                              <p>- Color and style</p>
                              <p>- Accessory</p>
                              <p>- Required function and feature</p>
                              <p>- Material</p>
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">UL2</td>
                            <td>
                              <p>
                                <strong>Retail Packaging Deviation</strong>
                              </p>
                              <p>Labeling / Manuals / Hang tag / Markings / Artwork / Barcode</p>
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">UL3</td>
                            <td>
                              <p>
                                <strong>Shipping Carton Deviation</strong>
                              </p>
                              <p>Export carton dimension / weight / shipping marks</p>
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">UL4</td>
                            <td>
                              <p>
                                <strong>Onsite test Issues</strong>
                              </p>
                              <p>- No equipment to conduct test</p>
                              <p>- Factory refuse to conduct functional test</p>
                              <p>- Functional test failed</p>
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">UL5</td>
                            <td>
                              <p>
                                <strong>Carton Drop Test</strong>
                              </p>
                              <p>- Factory refuse to conduct carton drop test</p>
                              <p>- Carton drop test failed</p>
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">UL6</td>
                            <td>
                              <p>Client specification/ Approval Sample not available during inspection</p>
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">UL7</td>
                            <td>
                              <p>Exceed AQL</p>
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">UL8</td>
                            <td>
                              <p>Shortage of Shipment Quantity</p>
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">UL9</td>
                            <td>
                              <p>
                                <strong>Missing Documents</strong>
                              </p>
                              <p>- CDF not available</p>
                              <p>- Client POs</p>
                              <p>- Product specification requirement</p>
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">UL10</td>
                            <td>
                              <p>All issues related for Sample Collection</p>
                            </td>
                          </tr>
                        </table>
                      </v-card-content>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn id="btn_Close" color="blue darken-1" text @click="show_toopltips = false">Close</v-btn>
                        <!-- <v-btn color="blue darken-1" text @click="save">Save</v-btn> -->
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>

              <v-btn id="btn_Submit" class="submit" color="#B14C4C" :disabled="!isFormValid" @click="showPopupMessage">Submit</v-btn>
            </div>

            <v-dialog v-model="noticeDialog" persistent max-width="290">
              <v-card>
                <v-card-title class="headline">Note</v-card-title>
                <v-card-text>Email Notification will be sent to the representative Service Specialist</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    id="btn_Dialog"
                    color="green darken-1"
                    text
                    @click="
                      noticeDialog = false;
                      confirmDialog = true;
                    "
                    >Close</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="confirmDialog" width="240">
              <v-card>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn id="btn_Dialog_Confirm" color="green darken-1" text @click="submit">Confirm</v-btn>
                  <v-btn id="btn_Dialog_Close" color="green darken-1" text @click="confirmDialog = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="showProgressDialog" :persistent="true">
              <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                  <div class="text-center">
                    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                  </div>
                </v-card-text>
              </v-card>
            </v-dialog>

            <v-snackbar v-model="snackbarError" :timeout="timeoutError" :color="colorError">
              {{ messageError }}
              <v-btn text @click="snackbarError = false"> Close </v-btn>
            </v-snackbar>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- <signature ref="signature" background="#ccc"></signature> -->

      <report-preview :showDialog.sync="showReportPreview" :reportData="reportData"></report-preview>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import * as moment from "moment";
import * as _ from "lodash";
import * as pbi from "powerbi-client";
import ReportPreview from "@/components/reports/ReportPreview";
let el = "";
let ctx = "";

let state = {
  appHeaderState: {
    pageName: "pastInspectionsPreview",
    show: true,
    title: "Work Order:8911",
    showMenu: false,
    showBack: true,
    showLogo: false,
    showTitle: true,
    showSearch_job: false,
    showSearch_work: false,
  },
};

const overallResultCodes = {
  PASS: "Pass",
  FAIL: "Fail",
  PENDING: "Pending",
  ABORT: "Abort",
};

function setState(store) {
  store.dispatch("navigation/setAppHeader", state.appHeaderState);
}

export default {
  name: "pastInspectionsPreview",
  components: {
    ReportPreview,
  },
  data() {
    return {
      url: null,
      isUploadSignature: false,
      hasImage: false,
      image: null,
      imageType: null,
      uploadImageFileName: null,
      InspectionList: [
        {
          sectionId: 1,
          section: "Quantity Verification",
          result: "Conformed",
          findings: 0,
          show: true,
        },
        {
          sectionId: 2,
          section: "Functional Check Measurement",
          result: "Conformed",
          findings: 0,
          show: true,
        },
        {
          sectionId: 3,
          section: "Package Check",
          result: "Conformed",
          findings: 0,
          show: true,
        },
        {
          sectionId: 4,
          section: "Barcode Verification",
          result: "Conformed",
          findings: 0,
          show: true,
        },
        {
          sectionId: 5,
          section: "Workmanship",
          result: "Conformed",
          findings: 0,
          show: true,
        },
        {
          sectionId: 6,
          section: "Onsite Tests",
          result: "Conformed",
          findings: 0,
          show: true,
        },
      ],
      detailsData: {
        products: [],
      },
      panel: [3],
      status: [],
      step: -1,
      first: "",
      mp: {},
      lineWidth: {
        type: String,
        default: "5",
      },
      lineColor: {
        type: String,
        default: "#ff0000",
      },
      background: "#dddddd",
      bgOpacity: {
        type: String,
        default: "1",
      },
      emailAddress: true,

      // Product list
      generalSummaryHeader: [
        { text: "PO Number", sortable: false, value: "po" },
        { text: "SKU Number", sortable: false, value: "skuDisplay" },
        { text: "Product Description", sortable: false, value: "productDescription" },
        { text: "Quantity", sortable: false, value: "qty" },
      ],
      generalSummaryList: [
        {
          po: "a",
          skuDisplay: "a",
          productDescription: "a",
          qty: 10,
        },
      ],

      InspectionHeader: [
        { text: "Section", sortable: false, value: "section" },
        { text: "Result", sortable: false, value: "result" },
        { text: "Findings", sortable: false, value: "findings" },
      ],
      // InspectionList: [{
      //     section: 'a',
      //     result: 'a',
      //     findings: 10,
      // }],
      count: 0,
      FindingHeader: [
        { text: "#", sortable: false, value: "id" },
        { text: "Defect Category", sortable: false, value: "defectCategory.name" },
        { text: "Defect Name", sortable: false, value: "defectName.name" },
        { text: "Qty", sortable: false, value: "qty" },
        { text: "SKUs", sortable: false, value: "skuDisplay" },
      ],
      FindingList: [
        {
          number: "a",
          sku: "a",
          po: "a",
          sku: 10,
        },
      ],
      // Finding Summary 切换控制变量
      change_type: 1,
      select_value: 1,
      show_toopltips: false,

      // Overall Result
      items: [
        { text: "Pass", value: 1 },
        { text: "Fail", value: 2 },
        { text: "Pending", value: 3 },
      ],
      checkbox: false,

      // Supplier Signoff,
      failCodes: ["UL0", "UL1", "UL2", "UL3", "UL4", "UL5", "UL6", "UL7", "UL8", "UL9", "UL10"],
      select_value: 1,
      show_toopltips: false,
      formData: {
        emailToSupplier: true,
        emailAddress: "",
        ratingId: null,
        failCodes: [],
        reportFormat: null,
      },
      workOrder: {},
      ratings: [],
      noticeDialog: false,
      confirmDialog: false,
      showProgressDialog: false,
      signatureOptions: {
        penColor: "black",
      },
      workOrderId: "",
      WorkOrderProductDefects: [],
      workOrderProducts: [],
      inspectionSummarySections: [],
      InspectionListTable: [],
      datatableRowsPerPage: {
        "footer-props": {
          "items-per-page-options": [10],
        },
      },
      ratings: [],
      factoryComment: "",
      representativeName: "",
      factoryTimeIn: '00',
      factoryTimeInMin: '00',
      factoryTimeOut: '00',
      factoryTimeOutMin: '00',
      inspectionStartTime: '00',
      inspectionStartTimeMin: '00',
      inspectionEndTime: '00',
      inspectionEndTimeMin: '00',
      getFactoryTimeIn: null,
      getFactoryTimeOut: null,
      getInspectionStartTime: null,
      getInspectionEndTime: null,
      getFactoryTimeInMin: null,
      getFactoryTimeOutMin: null,
      getInspectionStartTimeMin: null,
      getInspectionEndTimeMin: null,
      timeInAm: true,
      timeInPm: false,
      timeOutAm: true,
      timeOutPm: false,
      startTimeAm: true,
      startTimePm: false,
      endTimeAm: true,
      endTimePm: false,
      snackbarError: false,
      messageError: "",
      timeoutError: 3000,
      colorError: "red darken-2",
      errorMessage1: "Factory Time Out should not be earlier than the factory time in",
      errorMessage2: "Inspection start time should not be earlier than the factory time in",
      errorMessage3: "Inspection end time should not be less than or equal to inspection start time",
      errorMessage4: "Please input valid minute(s)",
      errorMessage5: "Please input valid hour(s)",
      errorMessage: "",
      isMinuteValid: true,
      isValueNull: true,

      // power bi preview
      showReportPreview: false,
      reportAttachment: null,
      reportData: {},
      remarkLists : []
    };
  },
  beforeRouteLeave(to, form, next) {
    this.showReportPreview = false;
    state.appHeaderState.pageName = "";
    setState(this.$store);
    next();
  },
  mounted() {
    // set head content
    state.appHeaderState.pageName = "pastInspectionsPreview";
    state.appHeaderState.title = "Work Order:" + this.$route.query.displayId;
    setState(this.$store);

    this.showProgressDialog = false;
    this.workOrderId = this.$route.query.id;
    // this.apiFn.api.workOrderApi.getWorkOrderDetails(this.workOrderId).then((wo) => {
    this.utilityFn.getServerData("get", this.apiList.workOrderDetails + "/" + this.$route.query.id, {}, (res) => {
      this.$store.dispatch("inspection/setWorkList", {
        id: res.data.data[0].workOrderId,
        displayId: res.data.data[0].workOrderDisplayId,
      });
      // this.$store.commit('appData/setWorkOrder', 'ewq');

      console.log("getWorkOrderDetails", res.data.data[0]);
      this.workOrder = res.data.data[0];
      this.workOrderProducts = this.workOrder.products;
      this.detailsData = this.workOrder;
      this.detailsData.jobDisplayId = this.workOrder.jobDisplayId;


      this.utilityFn.getServerData("get", this.apiList.GetCompanyWOResultTypeAliases + "?companyId=" + this.workOrder.clientId, {}, (res) => {
        this.showProgressDialog = false;
        this.ratings = res.data.data.filter((x) => x.description && x.isActive);
        this.formData.ratingId = this.workOrder.overAllResult ? this.workOrder.overAllResult.id : this.workOrder.workOrderRatingId;
        this.formData.failCodes = this.workOrder.workOrderFailCodes.map(wf => wf.failCode);
      });
      this.computeInspectionResults();
    });

    this.utilityFn.getServerData("get", this.apiList.getWorkOrderProductDefects, { workOrderId: this.$route.query.id }, (res) => {
      this.workOrderProductDefects = res.data.data;
      //console.log("workOrderProductDefects");
      //console.log(this.workOrderProductDefects)
    });
    this.utilityFn.getServerData("get", this.apiList.workOrderSectionList + "/" + this.$route.query.id, {}, (res) => {
      this.inspectionSummarySections = res.data.data;
      //console.log("inspectionSummarySections");
      //console.log(this.inspectionSummarySections)
    });
    
    // this.apiFn.GetWorkOrderProductDefects(this.workOrderId).then((result) => {
    //   this.WorkOrderProductDefects = result;
    // });
    // this.apiFn.GetInspectionSummarySections(this.workOrderId).then((result) => {
    //   this.inspectionSummarySections = result;
    // });
    
    this.panel = [];

    this._getJobList.forEach((x) => {
      x.workOrders.forEach((y) => {
        if (y.id == this.$route.query.id) {
          let getDataCaptureModifiedDate = moment(x.dataCaptureModifiedDate).format("HH") == "Invalid date" ? "00" : moment(x.dataCaptureModifiedDate).format("HH");
          this.inspectionStartTime = getDataCaptureModifiedDate;
          this.getInspectionStartTime = Number(moment(x.dataCaptureModifiedDate).format("H") == "Invalid date" ? "0" : moment(x.dataCaptureModifiedDate).format("H"));
          if (getDataCaptureModifiedDate <= 11) {
            this.startTimeAm = true;
            this.startTimePm = false;
          } else {
            this.startTimeAm = false;
            this.startTimePm = true;
          }
        }
      });
    });

      this.utilityFn.getServerData('get', this.apiList.getWorkOrderRemarks + '/' +  this.$route.query.id, {}, result => {
        console.log('result remarks', result)
        this.remarkLists =  result.data.data;
      },errres =>{
          console.log(errres);
      })

  },
  methods: {
    onImageSelection(event) {
      event.preventDefault();
      var inputObj = document.createElement("input");
      inputObj.setAttribute("accept", "image/*");
      inputObj.setAttribute("id", "_ef");
      inputObj.setAttribute("type", "file");
      inputObj.setAttribute("style", "visibility:hidden");
      document.body.appendChild(inputObj);
      inputObj.click();
      inputObj.onchange = (res) => {
        const loadImage = async () => {
          let file = inputObj.files[0]
          this.resize(file);    
          this.hasImage = true;
          this.uploadImageFileName = file.name
          this.isUploadSignature = true    
        };
        loadImage().then(() => {     
          console.log("done");   
        });
      };
    },

    resize(item){

      //create a FileReader
      var reader = new FileReader();
      const that = this;

      //image turned to base64-encoded Data URI.
      reader.readAsDataURL(item);
      reader.name = item.name;//get the image's name
      reader.size = item.size; //get the image's size
      reader.type = item.type; //get the image's type
      reader.onload = function(event) {
        var img = new Image();//create a image
        img.src = event.target.result;//result is base64-encoded Data URI
        img.name = event.target.name;//set name (optional)
        img.size = event.target.size;//set size (optional)

        img.onload = function(el) {
          var elem = document.createElement('canvas');//create a canvas

          var maxWidth = el.target.width;//without px
          var maxHeight = 500;//without px
          var ratio = Math.min(maxWidth / el.target.width, maxHeight / el.target.height);
          elem.height = el.target.height*ratio
          elem.width = el.target.width*ratio

          //draw in canvas
          var ctx = elem.getContext('2d');
          ctx.drawImage(el.target, 0, 0, elem.width, elem.height);

          //get the base64-encoded Data URI from the resize image
          var srcEncoded = ctx.canvas.toDataURL( reader.type, 1);

          //assign it to thumb src
          that.imageType = reader.type
          that.image = srcEncoded
          that.url = srcEncoded

          /*Now you can send "srcEncoded" to the server and
          convert it to a png o jpg. Also can send
          "el.target.name" that is the file's name.*/

        }
      }
    },
    removeImage () {
      this.url1 = null
    },

    addIndex(num) {
      return (num = num + 1);
    },
    computeInspectionResults() {
      let workordersection = this.workOrder.workOrderInspectionSections;
      if (workordersection.length != 0) {
        this.InspectionList.map((x) => {
          let section = workordersection.filter((y) => y.sectionId === x.sectionId);
          (x.result = section[0].result), (x.findings = section[0].findings);
          return x;
        });
      }

      // let qvIndex = this.InspectionList.findIndex(
      //   x => x.section === "Quantity Verification"
      // );
      // let smIndex = this.InspectionList.findIndex(
      //   x => x.section === "Size Measurement"
      // );
      // let pcIndex = this.InspectionList.findIndex(
      //   x => x.section === "Package Check"
      // );
      // let bvIndex = this.InspectionList.findIndex(
      //   x => x.section === "Barcode Verification"
      // );
      // let wmIndex = this.InspectionList.findIndex(
      //   x => x.section === "Workmanship"
      // );
      // let osIndex = this.InspectionList.findIndex(
      //   x => x.section === "Onsite Tests"
      // );

      // // update results

      // // packageCheck

      // if(qvIndex!=null) {
      //   this.InspectionList[qvIndex].result = this.quantityVerResult()
      //   ? "Conformed"
      //   : "Non-Conformed";
      // }

      // this.InspectionList[smIndex].result =
      //   this.sizeMeasurementResult() == null
      //     ? ""
      //     : this.sizeMeasurementResult()
      //     ? "Conformed"
      //     : "Non-Conformed";
      // this.InspectionList[pcIndex].result =
      //   this.packageCheckResult() == null
      //     ? ""
      //     : this.packageCheckResult()
      //     ? "Conformed"
      //     : "Non-Conformed";
      // this.InspectionList[bvIndex].result =
      //   this.barcodeVerificationResult() == null
      //     ? ""
      //     : this.barcodeVerificationResult()
      //     ? "Conformed"
      //     : "Non-Conformed";
      // this.InspectionList[wmIndex].result = this.workmanshipResult()
      //   ? "Conformed"
      //   : "Non-Conformed";

      // if (this.workOrder.onSiteTests.length === 0) {
      //   this.InspectionList[osIndex].result = "";
      //   this.InspectionList[osIndex].findings = "";
      // } else {
      //   this.InspectionList[osIndex].result = this.onSiteTestResult()
      //     ? "Conformed"
      //     : "Non-Conformed";
      //   this.InspectionList[osIndex].findings =
      //     this.InspectionList[osIndex].result === "Conformed"
      //       ? 0
      //       : this.workOrder.onSiteTests.filter(x => +x.result !== 2).length;
      // }
      // this.findingSummaryTable=[]

      //  this.InspectionList.forEach(obj => {
      //             let inspectionSection = this.inspectionSummarySections.filter(x => x.sectionId === obj.sectionId);
      //             if(inspectionSection.length!==0){
      //               if(inspectionSection[0].isHidden===false){
      //               this.InspectionListTable.push({
      //                 sectionId: obj.sectionId,
      //                 section: obj.section,
      //                 result: obj.result,
      //                 findings: obj.findings,
      //                 show: obj.show
      //               })
      //               }
      //             }
      //             else {
      //               this.InspectionListTable.push({
      //                 sectionId: obj.sectionId,
      //                 section: obj.section,
      //                 result: obj.result,
      //                 findings: obj.findings,
      //                 show: obj.show
      //               })
      //             }

      // 	});

      console.log("showsection", this.InspectionListTable);
    },
    quantityVerResult() {
      let result = true;
      let productLength = this.workOrderProducts.length;
      let workOrderProductResults = this.workOrderProducts.filter((x) => x.workOrderProductResults.length != 0);
      if (productLength == workOrderProductResults.length) {
        let productResultCompare = workOrderProductResults.filter((x) => x.qty == x.workOrderProductResults.availQtyPrd && x.cartonQty == x.workOrderProductResults.availQtyCrtn);
        if (productResultCompare.length == productLength) {
          result = true;
        } else {
          result = false;
        }
      } else {
        result = false;
      }
      return result;
    },
    sizeMeasurementResult() {
      let productsCount = this.workOrderProducts.length;
      let cartonSize = this.workOrderProducts.filter((x) => x.workOrderProductResults && x.workOrderProductResults.rsltSizeCrtn != null);
      let cartonWeight = this.workOrderProducts.filter((x) => x.workOrderProductResults && x.workOrderProductResults.rsltWCrtn != null);
      let productSize = this.workOrderProducts.filter((x) => x.workOrderProductResults && x.workOrderProductResults.rsltSizePrd != null);
      let productWeight = this.workOrderProducts.filter((x) => x.workOrderProductResults && x.workOrderProductResults.rsltWPrd != null);

      return (
        cartonSize.filter((x) => +x.workOrderProductResults.rsltSizeCrtn === 1).length === productsCount &&
        cartonWeight.filter((x) => +x.workOrderProductResults.rsltWCrtn === 1).length === productsCount &&
        productSize.filter((x) => +x.workOrderProductResults.rsltSizePrd === 1).length === productsCount &&
        productWeight.filter((x) => +x.workOrderProductResults.rsltWPrd === 1).length === productsCount
      );
    },
    // -1, no findings yet, 0 - Fail, > 1 Pass
    packageCheckResult() {
      let productsCount = this.workOrderProducts.length;
      let packageCheck = this.workOrderProducts.filter((x) => x.workOrderProductResults && x.workOrderProductResults.pkgCheckResult != null);

      if (!packageCheck) return null;

      return (packageCheck.filter((x) => +x.workOrderProductResults.pkgCheckResult === 1) || []).length === productsCount;
    },
    barcodeVerificationResult() {
      let productsCount = this.workOrderProducts.length;
      let bvCarton = this.workOrderProducts.filter((x) => x.workOrderProductResults && x.workOrderProductResults.bvOverallRsltCrtn != null);
      let bvProduct = this.workOrderProducts.filter((x) => x.workOrderProductResults && x.workOrderProductResults.bvOverallRsltPrd != null);

      if (!bvCarton && !bvProduct) return null;

      return bvProduct.filter((x) => x.workOrderProductResults.bvOverallRsltCrtn === 1).length === productsCount && bvProduct.filter((x) => x.workOrderProductResults.bvOverallRsltPrd === 1).length === productsCount;
    },
    workmanshipResult: function() {
      let result = false;
      if (this.workOrder.allowedCritical < this.workOrder.foundCritical || this.workOrder.allowedMajor < this.workOrder.foundMajor || this.workOrder.allowedMinor < this.workOrder.foundMinor) {
        result = false;
      } else {
        result = true;
      }
      return result;
    },
    onSiteTestResult() {
      return this.workOrder.onSiteTests.filter((x) => +x.result === 2).length === this.workOrder.onSiteTests.length;
    },
    change_type1(type) {
      if (type == "Critical") {
        this.change_type = 1;
      } else if (type == "Major") {
        this.change_type = 2;
      } else if (type == "Minor") {
        this.change_type = 3;
      }
    },
    // 阻止IOS设备，签名时页面会产生下拉问题。
    stopTouchmove(e) {
      e.preventDefault();
    },

    touchstart(e) {
      this.mp = {
        x: e.touches[0].clientX - e.target.offsetLeft,
        y: e.touches[0].clientY - e.target.offsetTop,
      };
      this.saveStatus();
    },
    touchmove(e) {
      let that = this;
      ctx.beginPath();
      ctx.moveTo(that.mp["x"], that.mp["y"]);
      // that.mp = {x: e.touches[0].clientX - e.target.offsetLeft, y: e.touches[0].clientY - e.target.offsetTop};
      that.mp = { x: e.offsetX, y: e.offsetY };
      ctx.lineTo(that.mp["x"], that.mp["y"]);
      ctx.fill();
      ctx.closePath();
      ctx.stroke();
    },
    touchend() {
      this.mp = {};
    },
    mousedown(e) {
      this.mp = {};
      // this.mp = {x: e.clientX - e.target.offsetLeft, y: e.clientY - e.target.offsetTop}
      this.mp = { x: e.offsetX, y: e.offsetY };
      this.saveStatus();
    },
    mousemove(e) {
      // console.log(e.offsetX)
      let that = this;
      if (that.mp["x"] && that.mp["y"]) {
        // 如果这里不做判断，那么只要鼠标移动到画布就自动画了
        ctx.beginPath();
        ctx.moveTo(that.mp["x"], that.mp["y"]);
        // that.mp = {x: e.clientX - e.target.offsetLeft, y: e.clientY - e.target.offsetTop};
        that.mp = { x: e.offsetX, y: e.offsetY };
        ctx.lineTo(that.mp["x"], that.mp["y"]);
        ctx.fill();
        ctx.closePath();
        ctx.stroke();
      }
    },
    mouseup() {
      this.mp = {};
    },
    mouseout() {
      this.mp = {};
    },
    // reset() {
    //   let that = this;
    //   ctx.clearRect(0, 0, el.offsetWidth, el.offsetHeight);
    //   let canvasPic = new Image();
    //   canvasPic.src = that.firstStatus;
    //   canvasPic.addEventListener("load", () => {
    //     ctx.drawImage(canvasPic, 0, 0);
    //   });
    //   that.step = -1;
    //   that.status = [];
    // },
    getBase64PNG() {
      return el.toDataURL("image/png");
    },
    getHorizontal(callback) {
      let that = this;
      for (let i of document.querySelectorAll('canvas[data-none="1"]')) {
        i.parentNode.removeChild(i);
      }
      let hiddenEl = document.createElement("canvas");
      hiddenEl.dataset.none = "1";
      hiddenEl.width = el.offsetHeight;
      hiddenEl.height = el.offsetWidth;
      document.getElementById("sigBox").appendChild(hiddenEl);

      let hiddenCtx = document.querySelector('canvas[data-none="1"]').getContext("2d");
      let img = new Image();
      img.src = that.getBase64PNG();
      img.onload = function() {
        let width = Math.round(el.offsetHeight / 2);
        let height = Math.round(el.offsetWidth / 2);
        hiddenCtx.save();
        hiddenCtx.translate(width, height);
        hiddenCtx.rotate((-90 * Math.PI) / 180);
        hiddenCtx.drawImage(img, -img.width / 2, -img.height / 2);
        hiddenCtx.restore();
        callback(document.querySelector('canvas[data-none="1"]').toDataURL("image/png"));
      };
    },
    getBlob() {
      let that = this;
      let arr = that.getBase64PNG().split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    // getFile(filename) {
    //   let that = this;
    //   let _filename = filename || "default";
    //   let dataurl = that.getBase64PNG();
    //   let arr = dataurl.split(","),
    //     mime = arr[0].match(/:(.*?);/)[1],
    //     bstr = atob(arr[1]),
    //     n = bstr.length,
    //     u8arr = new Uint8Array(n);
    //   while (n--) {
    //     u8arr[n] = bstr.charCodeAt(n);
    //   }
    //   return new File([u8arr], _filename, { type: mime });
    // },
    draw(base64) {
      let canvasPic = new Image();
      canvasPic.src = base64;
      canvasPic.addEventListener("load", () => {
        ctx.drawImage(canvasPic, 0, 0);
      });
    },
    saveStatus() {
      let that = this;
      that.step++;
      if (that.step < that.status.length) {
        that.status.length = that.step; // 截断数组
      }
      that.status.push(that.getBase64PNG());
    },
    undo(callback) {
      let that = this;
      if (that.step >= 0) {
        ctx.clearRect(0, 0, el.offsetWidth, el.offsetHeight);
        let canvasPic = new Image();
        canvasPic.src = that.status[that.step];
        canvasPic.addEventListener("load", () => {
          ctx.drawImage(canvasPic, 0, 0);
        });
        that.step--;
      } else {
        // eslint-disable-next-line
        console.warn("无法再后退了");
        if (callback) callback();
      }
    },
    redo(callback) {
      let that = this;
      if (that.step < that.status.length - 2) {
        that.step++;
        let canvasPic = new Image();
        if (that.status[that.step + 1]) {
          canvasPic.src = that.status[that.step + 1];
          canvasPic.addEventListener("load", () => {
            ctx.clearRect(0, 0, el.offsetWidth, el.offsetHeight);
            ctx.drawImage(canvasPic, 0, 0);
          });
        }
      } else {
        // eslint-disable-next-line
        console.warn("无法再重做了");
        if (callback) callback();
      }
    },

    // 阻止IOS设备，签名时页面会产生下拉问题。
    stopTouchmove(e) {
      e.preventDefault();
    },

    emailToggle() {
      if (!this.formData.emailToSupplier) {
        this.formData.emailAddress = "";
      }
    },
    onRatingSelectChange() {
      this.formData.failCodes = [];
    },
    reset(val) {
      if (val == "Supplier" && this.isUploadSignature){
        this.url = null
        this.hasImage = false;
        this.uploadImageFileName = null
        this.imageType = null
        this.isUploadSignature = false
      }
      if (this.$refs.signaturePad && val == "Supplier") {
        this.$refs.signaturePad.clearSignature();
      }
      if (this.$refs.inspectorSignaturePad && val == "Inspector") {
        this.$refs.inspectorSignaturePad.clearSignature();
      }
    },
    getData() {
      console.log(this.$refs["signature"].getFile());
    },
    // 阻止IOS设备，签名时页面会产生下拉问题。
    stopTouchmove(e) {
      e.preventDefault();
    },

    async isValidRemarksCommentSection(){

      var isMissingClassification = false;
      var missingClassification = await this.utilityFn.getServerDataAsync('get', `${this.apiList.validateRemarksAndCommentClassification}/${this.workOrder.workOrderId}`,{});
      if (missingClassification.data){
        isMissingClassification = true;
      }
      if(  this.workOrder.client == "WALMART GLOBAL SOURCING" || this.workOrder.client == "ASDA Stores Limited"){
        let ratingValue = this.ratings.filter(x => x.id == this.formData.ratingId)[0].description

        if(ratingValue.includes("Pass") && !isMissingClassification){
          return true;
        }
        
        if( !this.remarkLists || this.remarkLists.length == 0){
            this.snackbarError = true;
            this.messageError = "Missing Remark/Comment";
            return false;
        }       
        if(ratingValue.includes("Fail")) {
          let remarksListsFail =  this.remarkLists.filter(x => x.classification == 1)
          if(remarksListsFail.length == 0) {
            this.snackbarError = true;
            this.messageError = "Please provide the Fail Comment(s)";
          return false;
          }
        } else if(ratingValue.includes("Pending")) {
          let remarksListsPending =  this.remarkLists.filter(x => x.classification == 2)
            if(remarksListsPending.length == 0) {
              this.snackbarError = true;
              this.messageError = "Please provide the Pending Comment(s)";
            return false;
            }
        } else if(ratingValue.includes("Abort")) {
          let remarksListsAbort =  this.remarkLists.filter(x => x.classification == 4)
            if(remarksListsAbort.length == 0) {
              this.snackbarError = true;
              this.messageError = "Please provide the Abort Comment(s)";
          return false;
            }
        }
      }

      if (isMissingClassification){
        this.snackbarError = true;
        this.messageError = "Missing Classification Type in Remarks/Comments";
        return false;
      }
      return true
    },

    async showPopupMessage() {
      var isValid = await this.isValidRemarksCommentSection(); 
      if(isValid){
        if (this.formData.emailToSupplier) {
          this.noticeDialog = true;
        } else {
          this.confirmDialog = true;
        }
      }     
    },
    async submit() {
      const validationMsg = this.validateInspectionTime();
      if (validationMsg) {
        this.messageError = validationMsg;
        this.snackbarError = true;
        this.confirmDialog = false;
        return;
      }

      this.showProgressDialog = true;
      let form = new FormData();

      let signatureFile = null
      if(this.isUploadSignature){
         signatureFile = await this.getFileUpload(this.image, "SignatureUpload");
      }
      else{
         signatureFile =  await this.getFile(this.$refs.signaturePad.saveSignature().data, "Signature");
      }

      form.append("Signature", signatureFile);
      form.append("SupplierEmailAddress", this.formData.emailAddress);
      form.append("WorkOrderResultId", this.formData.ratingId);

      const inspectorSignatureFile = await this.getFile(this.$refs.inspectorSignaturePad.saveSignature().data, "InspectorSignature");
      form.append("InspectorSignature", inspectorSignatureFile);
      form.append("RepresentativeName", this.representativeName);
      form.append('FactoryTimeIn', this.factoryTimeIn + this.factoryTimeInMin);
      form.append('FactoryTimeOut', this.factoryTimeOut + this.factoryTimeOutMin);
      form.append('InspectionStartTime', this.inspectionStartTime + this.inspectionStartTimeMin);
      form.append('InspectionEndTime', this.inspectionEndTime + this.inspectionEndTimeMin);
      form.append("FactoryComment", this.factoryComment);
      this.formData.failCodes.forEach((code) => {
        form.append("FailCodes", code);
      });

      if (this.reportAttachment) {
        form.append("ReportAttachment", this.reportAttachment);
      }

      this.utilityFn.getServerData("post", this.apiList.submitWorkOrderReport + "/" + this.workOrder.workOrderId, form, (result) => {
        if (result) {
          if (this.formData.emailToSupplier) {
            const token = this.report.getExportAuth();
            if (!token) {
              this.authenticate();
            } else {
              this.invokeExport(token);
            }
          }

          localStorage.setItem("signedOffWorkOrder", JSON.stringify(this.workOrder));
          this.showProgressDialog = false;
          this.$router.go(-1);
        }
      });
    },
    async getFile(dataUrl, fileName) {
      const res = await fetch(dataUrl);
      const blob = await res.blob();
      return new File([blob], fileName + " - " + this.$route.query.displayId, { type: "image/png" });
    },

    async getFileUpload(dataUrl, fileName) {
      const res = await fetch(dataUrl);
      const blob = await res.blob();
      return new File([blob], fileName + " - " + this.$route.query.displayId, { type: this.imageType });
    },

    getTheResult(value, isAM){
      let valueReturn
      this.isMinuteValid = true
      value = parseInt(value)
      if (isAM) {
        if (value < 10) {
          valueReturn = '0' + value 
        }
        else {
          if(value == 12) {
            valueReturn = '00'
          }
          else {
            valueReturn = value 
          }
        }
      }
      else {
        if (value <= 11) {
          valueReturn = value + 12 
        }
        else {
          valueReturn = 12
        }
      }
      return valueReturn
    },

    convertTime(val, isAM) {
        if (val== 'TimeInAM' || val== 'TimeInPM') {
          this.factoryTimeIn =  this.getFactoryTimeIn != null ? this.getTheResult(this.getFactoryTimeIn, isAM) : this.factoryTimeIn
          this.isValueNull = this.getFactoryTimeIn == null ? true : false
        }
        if (val== 'TimeOutAM' || val== 'TimeOutPM') {
          this.factoryTimeOut =  this.getFactoryTimeOut != null ? this.getTheResult(this.getFactoryTimeOut, isAM) : this.factoryTimeOut
          this.isValueNull = this.getFactoryTimeOut == null ? true : false
        }
        if (val== 'StartTimeAM' || val== 'StartTimePM') {
          this.inspectionStartTime =  this.getInspectionStartTime != null ? this.getTheResult(this.getInspectionStartTime, isAM) : this.inspectionStartTime
          this.isValueNull = this.getInspectionStartTime == null ? true : false
        }
        if (val== 'EndTimeAM' || val== 'EndTimePM') {
          this.inspectionEndTime =  this.getInspectionEndTime != null ? this.getTheResult(this.getInspectionEndTime, isAM) : this.inspectionEndTime
          this.isValueNull = this.getInspectionEndTime == null ? true : false
        }

        if (!this.isValueNull) {
          if (this.isMinuteValid) {
              if (val == 'TimeInAM' || val == 'TimeInPM') {
                  if ((this.factoryTimeOut == '00' || this.factoryTimeOut == null) || (Number(this.factoryTimeOut) >= Number(this.factoryTimeIn))) {
                      if (
                          Number(this.inspectionStartTime) > Number(this.factoryTimeIn)
                              || (Number(this.inspectionStartTime) == Number(this.factoryTimeIn) && Number(this.inspectionStartTimeMin) >= Number(this.factoryTimeInMin))
                              || this.inspectionStartTime == '00'
                              || this.inspectionStartTime == null) {
                          this.factoryTimeIn = this.factoryTimeIn
                          this.factoryTimeInMin = this.factoryTimeInMin
                          if (val == 'TimeInAM') {
                              this.timeInAm = true
                              this.timeInPm = false
                          }
                          if (val == 'TimeInPM') {
                              this.timeInAm = false
                              this.timeInPm = true
                          }
                      }
                      else {
                          this.factoryTimeIn = this.getFactoryTimeIn
                          this.messageError = this.errorMessage2
                          this.timeInAm = false
                          this.timeInPm = false
                          this.snackbarError = true;
                      }
                  }
                  else {
                      this.factoryTimeIn = this.getFactoryTimeIn
                      this.messageError = this.errorMessage1
                      this.timeInAm = false
                      this.timeInPm = false
                      this.snackbarError = true;
                  }
              }
              if (val == 'TimeOutAM' || val == 'TimeOutPM') {
                  if (Number(this.factoryTimeOut) > Number(this.factoryTimeIn)
                      || (Number(this.factoryTimeOut) == Number(this.factoryTimeIn) && Number(this.factoryTimeOutMin) >= Number(this.factoryTimeInMin))) {
                      this.factoryTimeOut = this.factoryTimeOut
                      this.factoryTimeOutMin = this.factoryTimeOutMin
                      if (val == 'TimeOutAM') {
                          this.timeOutAm = true
                          this.timeOutPm = false
                      }
                      if (val == 'TimeOutPM') {
                          this.timeOutAm = false
                          this.timeOutPm = true
                      }
                  }
                  else {
                      this.factoryTimeOut = this.getFactoryTimeOut
                      this.messageError = this.errorMessage1
                      this.timeOutAm = false
                      this.timeOutPm = false
                      this.snackbarError = true;
                  }
              }
              if (val == 'StartTimeAM' || val == 'StartTimePM') {
                  if (Number(this.inspectionStartTime) > Number(this.factoryTimeIn)
                      || (Number(this.inspectionStartTime) == Number(this.factoryTimeIn) && Number(this.inspectionStartTimeMin) >= Number(this.factoryTimeInMin))) {
                      if (Number(this.inspectionEndTime) > Number(this.inspectionStartTime)
                          || (Number(this.inspectionEndTime) == Number(this.inspectionStartTime) && Number(this.inspectionEndTimeMin) > Number(this.inspectionStartTimeMin))
                          || this.inspectionEndTime == null
                          || this.inspectionEndTime == '00') {
                          this.inspectionStartTime = this.inspectionStartTime
                          this.inspectionStartTimeMin = this.inspectionStartTimeMin
                          if (val == 'StartTimeAM') {
                              this.startTimeAm = true
                              this.startTimePm = false
                          }
                          if (val == 'StartTimePM') {
                              this.startTimeAm = false
                              this.startTimePm = true
                          }
                      }
                      else {
                          this.inspectionStartTime = this.getInspectionStartTime
                          this.messageError = this.errorMessage3
                          this.startTimeAm = false
                          this.startTimePm = false
                          this.snackbarError = true;
                      }
                  }
                  else {
                      this.inspectionStartTime = this.getInspectionStartTime
                      this.messageError = this.errorMessage2
                      this.startTimeAm = false
                      this.startTimePm = false
                      this.snackbarError = true;
                  }
              }
              if (val == 'EndTimeAM' || val == 'EndTimePM') {
                  if (Number(this.inspectionEndTime) > Number(this.inspectionStartTime)
                      || (Number(this.inspectionEndTime) == Number(this.inspectionStartTime) && Number(this.inspectionEndTimeMin) > Number(this.inspectionStartTimeMin))
                      || this.inspectionStartTime == null
                      || this.inspectionStartTime == '00') {
                      this.inspectionEndTime = this.inspectionEndTime
                      this.inspectionEndTimeMin = this.inspectionEndTimeMin
                      if (val == 'EndTimeAM') {
                          this.endTimeAm = true
                          this.endTimePm = false
                      }
                      if (val == 'EndTimePM') {
                          this.endTimeAm = false
                          this.endTimePm = true
                      }
                  }
                  else {
                      this.inspectionEndTime = this.getInspectionEndTime
                      this.messageError = this.errorMessage3
                      this.endTimeAm = false
                      this.endTimePm = false
                      this.snackbarError = true;
                  }
              }
          }
          else {
              this.messageError = this.errorMessage
              this.snackbarError = true;
          }
        }
    },

    keyUpValue(val, input) {
      let checkIfNumbers = /^[0-9]+$/;
      let isNumber = checkIfNumbers.test(val.target.value)
  
      if (isNumber && val.target.value != '' && (val.target.value == 0 || Number(val.target.value) <= 12)) {
        if(input == 'FactoryTimeIn') {
          this.getFactoryTimeIn = val.target.value
        }
        if(input == 'FactoryTimeOut') {
          this.getFactoryTimeOut = val.target.value
        }
        if(input == 'InspectionStartTime') {
          this.getInspectionStartTime = val.target.value
        }
        if(input == 'InspectionEndTime') {
          this.getInspectionEndTime = val.target.value
        }
      }
      else {
          if (val.target.value.length != 0) {
            if(input == 'FactoryTimeIn') {
              this.factoryTimeIn = this.getFactoryTimeIn
            }
            if(input == 'FactoryTimeOut') {
              this.factoryTimeOut = this.getFactoryTimeOut
            }
            if(input == 'InspectionStartTime') {
              this.inspectionStartTime = this.getInspectionStartTime
            }
            if(input == 'InspectionEndTime') {
              this.inspectionEndTime = this.getInspectionEndTime
            }
          } 
      }
    },

    keyUpMinValue(val, input) {
      let checkIfNumbers = /^[0-9]+$/;
      let isNumber = checkIfNumbers.test(val.target.value)
      if (isNumber && val.target.value != '' && (val.target.value == 0 || Number(val.target.value) <= 59)) {
        if(input == 'FactoryTimeInMin') {
          this.getFactoryTimeInMin = val.target.value
        }
        if(input == 'FactoryTimeOutMin') {
          this.getFactoryTimeOutMin = val.target.value
        }
        if(input == 'InspectionStartTimeMin') {
          this.getInspectionStartTimeMin = val.target.value
        }
        if(input == 'InspectionEndTimeMin') {
          this.getInspectionEndTimeMin = val.target.value
        }
      }
      else {
        if (val.target.value.length != 0) {
          if(input == 'FactoryTimeInMin') {
            this.factoryTimeInMin = this.getFactoryTimeInMin
          }
          if(input == 'FactoryTimeOutMin') {
            this.factoryTimeOutMin = this.getFactoryTimeOutMin
          }
          if(input == 'InspectionStartTimeMin') {
            this.inspectionStartTimeMin = this.getInspectionStartTimeMin
          }
          if(input == 'InspectionEndTimeMin') {
            this.inspectionEndTimeMin = this.getInspectionEndTimeMin
          }
        }
      }
    },

    resultCodeDescription(item) {
      return item.code ? item.code + " - " + item.description : item;
    },

    resultCodeValue(item) {
      return item.code ? item.code : item;
    },

    // preview
    previewReport() {
      this.reportData = {
        workOrderId: this.workOrder.workOrderId,
        workOrderDisplayId: this.workOrder.workOrderDisplayId,
        clientId: this.workOrder.clientId,
        companyName: this.workOrder.client,
        reportNumber: this.workOrder.reportNumber,
        workOrderClassId: this.workOrder.workOrderClassId,
        industryId: this.workOrder.industryId,
        workOrderDestinations: this.workOrder.workOrderDestinations,
        status: this.workOrder.status,
        rating: this.workOrder.overallResultDescription
      };
      this.showReportPreview = true;
    },

    uploadAttachment() {
      const that = this;
      const inputObj = document.createElement("input");
      inputObj.setAttribute("id", "_ef");
      inputObj.setAttribute("type", "file");
      inputObj.setAttribute("style", "visibility:hidden");
      document.body.appendChild(inputObj);
      inputObj.click();
      inputObj.onchange = (res) => {
        if (inputObj.files[0]) {
          console.log("inputObj.files[0]", inputObj.files[0]);
          that.reportAttachment = inputObj.files[0];

          console.log("inputObj.files[0]", that.reportAttachment);
        }
      };
    },

    displayAttachmentName() {
      return this.reportAttachment ? this.reportAttachment.name : "";
    },

    authenticate() {
      this.utilityFn.getServerData("get", this.apiList.getCFRExportAuthentication, {}, (res) => {
        this.report.storeExportAuth(res.data.data);
        this.invokeExport(res.data.data.access_token);
      });
    },

    invokeExport(token) {
      const data = {
        emailAddress: localStorage.getItem("username"),
        reportFormat: this.formData.reportFormat,
        workorderId: this.workOrder.workOrderDisplayId,
      };
      this.utilityFn.cfrExport(this.apiList.cfrExport, data, token, (success, result) => {
        console.log("invokeExport", result);
      });
    },

    // validate time in and time out, return a message if has an error or empty if valid
    validateInspectionTime() {
      const factoryTimeIn = Number(this.padDigit(this.factoryTimeIn) + this.padDigit(this.factoryTimeInMin));
      const factoryTimeOut = Number(this.padDigit(this.factoryTimeOut) + this.padDigit(this.factoryTimeOutMin));
      const inspectionStartTime = Number(this.padDigit(this.inspectionStartTime) + this.padDigit(this.inspectionStartTimeMin));
      const inspectionEndTime = Number(this.padDigit(this.inspectionEndTime) + this.padDigit(this.inspectionEndTimeMin));

      // factory time out should not be before the factory time in
      if (factoryTimeOut < factoryTimeIn) {
        return this.errorMessage1;
      }

      // inspection start time should not be before the factory time in
      if (inspectionStartTime != 0 && inspectionStartTime < factoryTimeIn) {
        return this.errorMessage2;
      }

      // inspection end time should not be equal or before the inspection start time
      if (inspectionEndTime != 0 && inspectionEndTime <= inspectionStartTime) {
        return this.errorMessage3;
      }

      // return empty string if all input times are valid
      return '';
    },
    
    // add padding 0 to single digit
    padDigit(value) {
      const digit = 2;
      return (new Array(digit).fill(0).join('') + value).slice(-digit);
    }
  },
  computed: {
    ...mapState("inspection", {
      _getJobList: (state) => state.jobList, 
      _remarksLists: state => state.remarksLists,
      _workorderDetails: state => state.workOrderDetails
    }),
    findingSummary() {
      return this.WorkOrderProductDefects.filter((defect) => {
        return defect.defectClassificationId === this.change_type;
      });
    },
    isFormValid() {
      let isEmailValid = true;
      let isRatingValid = true;
      let isSignatureValid = this.isUploadSignature ? this.image != null :  this.$refs.signaturePad && this.$refs.signaturePad.toData().length > 0;
      let isInspectorSignatureValid = this.$refs.inspectorSignaturePad && this.$refs.inspectorSignaturePad.toData().length > 0;
      let isRepresentativeNameValid = this.representativeName != "";

      let isFactoryTimeInValid = false;
      let isFactoryTimeOutValid = false;
      let isInspectorStartTimeValid = false;
      let isInspectorEndTimeValid = false;

      if (this.factoryTimeIn != null && this.factoryTimeOut != null && this.inspectionStartTime != null && this.inspectionEndTime != null) {
        isFactoryTimeInValid = (this.timeInAm == false && this.timeInPm == false) ? false : true;
        isFactoryTimeOutValid = (this.timeOutAm == false && this.timeOutPm == false) ? false : true;
        isInspectorStartTimeValid = (this.startTimeAm == false && this.startTimePm == false) ? false : true;
        isInspectorEndTimeValid = (this.endTimeAm == false && this.endTimePm == false) ? false : true;
      }

      // if fail - will be updated later base on the business rules
      if (this.formData.emailToSupplier) {
        isEmailValid = !!this.formData.reportFormat;
      }

      isRatingValid = this.formData.ratingId != null;
      const rating = this.ratings.find((x) => x.id === this.formData.ratingId);
      if (rating) {
        isRatingValid = rating.workOrderResultType.description === "Pass";

        if (rating.workOrderResultType.description === "Fail") {
          isRatingValid = this.formData.failCodes.length > 0;
        }
      }

      return isEmailValid && isRatingValid && isSignatureValid && isInspectorSignatureValid && isRepresentativeNameValid && isFactoryTimeInValid && isFactoryTimeOutValid && isInspectorStartTimeValid && isInspectorEndTimeValid;
    },

    rating() {
      return this.ratings.find((x) => x.id === this.formData.ratingId);
    },

    showFailCodes() {
      return (
        this.rating &&
        (this.rating.workOrderResultType.description === overallResultCodes.FAIL ||
          ((this.rating.description.toLowerCase().indexOf(overallResultCodes.ABORT.toLowerCase()) != -1 || this.rating.description.toLowerCase().indexOf(overallResultCodes.PENDING.toLowerCase()) != -1) &&
            this.rating.companyWorkOrderResultTypeCodes.length > 0))
      );
    },

    resultCodes() {
      const codes = this.rating ? _.orderBy(this.rating.companyWorkOrderResultTypeCodes.filter(x => x.isActive), ["order"], ["asc"]).map((c) => ({ code: c.code, description: c.description })) : [];

      return codes.length > 0 ? codes : this.failCodes;
    },

    isCustomResultCodes() {
      return !!this.rating && this.rating.companyWorkOrderResultTypeCodes.length > 0;
    },

    resultCodeLabel() {
      // const this.rating = this.ratings.find(x => x.id === this.formData.ratingId);
      if (this.rating && this.rating.companyWorkOrderResultTypeCodes.length > 0) {
        if (this.rating.description.toLowerCase().indexOf(overallResultCodes.ABORT.toLowerCase()) != -1) {
          return "Abortive Code";
        } else if (this.rating.description.toLowerCase().indexOf(overallResultCodes.PENDING.toLowerCase()) != -1) {
          return "Pending Code";
        }
      }
      return "Fail Code";
    },
  },
};
</script>

<style lang="stylus" scoped>
.pastInspectionsPreview {
  background: #fff;
}

.title {
  text-align: center;
  padding-top: 30px;
}

.select_type > span {
  padding: 5px 20px;
  display: inline-block;
}

.select_type > .active {
  background: #1890FF;
  color: #fff;
}

.top_title {
  color: red;
  font-size: 16px;
}

.sign_box {
  border: 1px #ccc solid;
  height: 300px;
}

.submit {
  color: #fff;
  display: block;
  margin: 40px auto;
  width: 60%;
}

.signature_box #btns {
  float: right;
  background: rgb(0, 118, 255);
  color: #fff;
}

.signature_box button {
  height: 40px;
  width: 100px;
}

#btns {
  float: right;
  background: rgb(0, 118, 255);
  color: #fff;
}

#reset {
  height: 40px;
  width: 100px;
}

.fail_code {
  padding: 20px;
  padding-bottom: 0px;
}

.fail_code p {
  margin-bottom: 5px;
}

.btnSize
  height: 30px;
  width: 1%;
  color: #000;

#preview-container
    height 100%

#preview-dialog
    display flex
    flex-direction column
    height 98vh

#preview-dialog .v-card__text
    flex-grow 1
    height 85%

>>> .v-dialog
    max-height 100%
    
>>> .v-input input 
  max-height: 80px
  right: 2px;
  position: relative;

>>> .fileInputSignature
    display: none;

>>> .fileInputSignature fileInputSignature--loaded
    display: none;

.hrClass       
  text-align: center;
  width: 107px;
  border-radius: 10px;
  height: 74px;
  font-size: 70px;
    
.colonClass
  font-size: 100px;
  font-family: 'Material Design Icons';
  position: relative;
  bottom: 31px;

.divClass
  top: 25px;
  position: relative;
  right: 20px;

.hintClass
  color: red;
  font-style: italic;
  position: relative;
  padding-left: 30px;
  text-align: center;
  top: 15px;

.rowClass
  text-align: center;

.colClass
  text-align: left;

.colPadding
  padding-left: 30px;
  
.btnSpacing
    bottom: 5px;
</style>
