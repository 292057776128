<template>
  <div class="pulse-validator">
    <v-menu offset-y content-class="pulse-validator-menu">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            class="pulse-button"
            color="error"
            dark
            icon
            v-bind="attrs"
            v-on="on"
        >
          <v-icon large>
              mdi-alert-circle
          </v-icon>
        </v-btn>
      </template>
      <v-list
        color="error"
        >
        <v-list-item
          v-for="(error, index) in errors"
          :key="index"
        >
          <v-list-item-title class="pulse-validator-error">{{ error }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>


<script>
    export default {
        props: {
            errors: {
                type: Array,
                default: () => []
            },
        },
    }
</script>

<style lang="stylus" scoped>

</style>
