<template>
    <div>
        <div class="setting">
            <v-row style="background: #fff;width: 96%; margin: 15px auto;">
                <v-col cols="12" sm="12" md="12">
                    <v-select :items="items_language" label="Language" v-model="language_value"></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <v-select :items="items_map" label="Default Map Application" v-model="map_value"></v-select>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>

    import {mapActions} from 'vuex';

    function setState(store) {
        store.dispatch('navigation/setAppHeader', {
            show: true,
            title: 'Settings',
            showTitle: true,
            showMenu: false,
            showBack: true,
            showLogo: false,
            showSearch_job: false,
            showSearch_work: false
        });
    }

    export default {
        name: 'setting',
        data () {
            return {
                language_value: 'Chinese',
                map_value: 'Baidu Maps',
                items_language: [
                    {
                        text: 'English',
                    },
                    {
                        text: 'Chinese',
                    }
                ],
                items_map: [
                    {
                        text: 'Google Maps',
                    },
                    {
                        text: 'Baidu Maps',
                    }
                ]
            };
        },
        mounted () {
            // set head content
            setState(this.$store);
        },
        methods: {}
    };
</script>

<style lang="stylus" scoped>
    
    .profile
        background #fff
        position absolute
        top 0
        bottom 0

    .logo_img
        display block
        margin 10% auto 5%
    .default_avatar 
        width 150px
        height 150px
        display block
        margin auto
    .formDate
        padding 15px
        width 90%
        margin auto

    .submit
        display block
        margin 7% auto 0;
        width 80%
    
</style>
