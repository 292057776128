<template>
    <div>
        <div class="splitWorkOrderForm">

            <!-- table for split -->
             <v-row>
                <v-col cols="12" sm="12" md="12">
                    <v-data-table :headers="splitPO" :items="splitPOList" :items-per-page="-1" :hide-default-footer="false" class="elevation-1" style="margin-top: 30px;">
                        <template v-slot:body="{ items }">
                            <tbody>
                                <tr :id="'row_'+ item.poNo" v-for="item in items" :key="item.name" >
                                    <td style="text-align: center;">{{ item.poNo }}</td>
                                    <td style="text-align: center;">{{ item.requestId }}</td>
                                    <td style="text-align: center;">{{ item.vendorStockNo }}</td>
                                    <td style="text-align: center;">{{ item.newStockNo == false ? 'N' : 'Y' }}</td>
                                    <td>
                                        <div  class="v-input">
                                            <v-checkbox :disabled="splitPOList.length <= 1 || (item.isSplit2 || item.isSplit3 || item.isSplit4)" v-model="item.isSplit1" color="rgb(23, 201, 100)" @change="isSplitToggle($event, item)" ></v-checkbox>
                                        </div>
                                    </td>
                                    <td>
                                        <div  class="v-input">
                                            <v-checkbox :disabled="splitPOList.length <= 1 || (item.isSplit1 || item.isSplit3 || item.isSplit4)" v-model="item.isSplit2" color="rgb(23, 201, 100)" @change="isSplitToggle($event, item)"></v-checkbox>
                                        </div>
                                    </td>
                                    <td>
                                        <div  class="v-input">
                                            <v-checkbox :disabled="splitPOList.length <= 1 || (item.isSplit1 || item.isSplit2 || item.isSplit4)" v-model="item.isSplit3" color="rgb(23, 201, 100)" @change="isSplitToggle($event, item)"></v-checkbox>
                                        </div>
                                    </td>
                                    <td>
                                        <div  class="v-input">
                                            <v-checkbox :disabled="splitPOList.length <= 1 || (item.isSplit1 || item.isSplit2 || item.isSplit3)" v-model="item.isSplit4" color="rgb(23, 201, 100)" @change="isSplitToggle($event, item)"></v-checkbox>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>

            <!-- text for reason -->
             <v-row>
                <v-col cols="12" sm="12" md="12">
                    <div class="textAreaStyle">
                        <v-textarea 
                            v-model="reasonForSplit"
                            :full-width="true"
                            placeholder="Enter reason for splitting the work order *"
                            :solo="true"
                            @keyup="charCount()" > 
                        </v-textarea>
                    </div>
                    <div class="traceCharacter">
                        <span >{{ remaincharactersText }} Characters</span>
                    </div>
                </v-col>
            </v-row>

            <!-- button -->
            <v-row>
                <v-col cols="4" sm="4" md="2" class="btnAlignment">
                    <!-- <v-btn class="textColor" color="#B14C4C"  @click="cancelSplit">Cancel</v-btn> --> <!-- || listToSplit.length === 0> -->
                    <v-btn class="textColor btnWidth" color="#B14C4C" style="color:#fff" :disabled="!isButtonIsEnable || reasonForSplit.length == 0"  @click="confirmDialog = true">Submit for UL Approval</v-btn>
                 </v-col>
            </v-row>
        </div>

        <!-- Dialog box -->
        <v-dialog v-model="confirmDialog" width="350">
            <v-card>
                <v-card-title class="headline"></v-card-title>
                <v-card-text class="cardText">Split work order request has been sent to the UL Service Specialist for approval in Canyon</v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="submit">Close</v-btn>
                <!-- <v-btn color="green darken-1" text @click="submit ">Submit</v-btn>
                <v-btn color="green darken-1" text @click="confirmDialog = false">Cancel</v-btn> -->
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Progress bar -->
        <v-dialog v-model="showProgressDialog" :persistent="true">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                    <div class="text-center">
                        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

    import {mapState} from 'vuex';
    import apiDetails from '../../shared/request/apiDetails';
    import * as _ from 'lodash';
    import * as moment from "moment";

    let state = {
        appHeaderState: {
            show: true,
            showMenu: true,
            showBack: true,
            showLogo: false,
            showTitle: true,
            title: 'Work Order: ',
            showSearch_job: false,
            showSearch_work: false,
            showCalendar: true
        }
    };

    function setState(store) {
        store.dispatch('navigation/setAppHeader', state.appHeaderState);
    }
    export default {
        name: 'splitWorkOrderForm',
        data () {
            return {

                showProgressDialog: false,
                confirmDialog: false,
                maxcharacter: 500,
                remaincharactersText: '500',
                reasonForSplit: '',
                otherIsRequired: false,
                splitPO: 
                [
                    { text: 'PO No.', align: 'center', sortable: false, value: 'poNo'},
                    { text: 'Request ID', align: 'center', sortable: false, value: 'requestId', disable: true},
                    { text: 'Vendor Stock #', align: 'center', sortable: false, value: 'vendorStockNo' },
                    { text: 'New Stock #', align: 'center', sortable: false, value: 'newStockNo' },
                    { text: 'Split # 1', align: 'center', sortable: false, value: 'isSplit1' },
                    { text: 'Split # 2', align: 'center', sortable: false, value: 'isSplit2' },
                    { text: 'Split # 3', align: 'center', sortable: false, value: 'isSplit3' },
                    { text: 'Split # 4', align: 'center', sortable: false, value: 'isSplit4' },
                ],
                listToSplit: [],
                splitPOList: [],
                isButtonIsEnable: false

            };
        }, 
        activated() {
            state.appHeaderState.title = 'Work Order:' + this.$route.query.id;
            setState(this.$store);
            this.getRecordToSplit()
        },

        mounted () {
            state.appHeaderState.title = 'Work Order:' + this.$route.query.id;
            setState(this.$store);

            this.getRecordToSplit()

        },

        methods: {
            getRecordToSplit() {
                let prevListToSplit = JSON.parse(localStorage.getItem('listToSplit'))
                this.showProgressDialog = true
                let workOrderId = this.$route.params.id
                this.utilityFn.getServerData('get', this.apiList.workOrderProductSplitDetails + '/' + workOrderId, {}, result => {

                    let checkIfExist = result.data.data.map(x => {
                        return {
                            poNo: x.po,
                            requestId: x.clientProductId,
                            vendorStockNo: x.style,
                            newStockNo: x.isNew,
                            isSplit1: x.isSplit1 == null || x.isSplit1 == false ? false : true,
                            isSplit2: x.isSplit2 == null || x.isSplit2 == false ? false : true,
                            isSplit3: x.isSplit3 == null || x.isSplit3 == false ? false : true,
                            isSplit4: x.isSplit4 == null || x.isSplit4 == false ? false : true,
                            workOrderProductId: x.workOrderProductId,
                            splitWorkOrderId: x.splitWorkOrderId
                        }
                    });
                    this.splitPOList = checkIfExist
                    this.listToSplit = this.splitPOList.filter(x => x.isSplit1 == true ||  x.isSplit2 == true ||  x.isSplit3 == true ||  x.isSplit4 == true)
                    this.showProgressDialog = false
                });
            },

            submit() {
                this.confirmDialog = false
                let getSplit1 = this.listToSplit.filter( x => x.isSplit1 == true)
                let getSplit2 = this.listToSplit.filter( x => x.isSplit2 == true)
                let getSplit3 = this.listToSplit.filter( x => x.isSplit3 == true)
                let getSplit4 = this.listToSplit.filter( x => x.isSplit4 == true)

                if (getSplit1.length > 0) {
                    this.splitSendRequest(getSplit1)
                }
                if (getSplit2.length > 0) {
                    this.splitSendRequest(getSplit2)
                }
                if (getSplit3.length > 0) {
                    this.splitSendRequest(getSplit3)
                }
                if (getSplit4.length > 0) {
                    this.splitSendRequest(getSplit4)
                }
            },

            splitSendRequest(listOfSplit) {
                let FormSplitWorkOrder = {
                    id: null,
                    workOrderId: this.$route.params.id,
                    requestorId: localStorage.getItem('resourceID'),
                    requestDate: new Date().toLocaleDateString(),
                    reason: this.reasonForSplit,
                    approverId: null,
                    approvedDate: null,
                    rejectorId: null,
                    rejectedDate: null,
                    splitWorkOrderWorkOrderProducts: listOfSplit
                }
                this.showProgressDialog = true
                this.utilityFn.getServerData('post', this.apiList.getUpsertSplitWorkOrder, FormSplitWorkOrder, result => {
                this.showProgressDialog = false
                    if (result) {
                        localStorage.setItem('listToSplit', JSON.stringify(this.listToSplit))
                    }
                });
            },

            charCount() {
                if(this.reasonForSplit.length >= this.maxcharacter){
                    var remainCharacters = this.maxcharacter - this.reasonForSplit.length 

                    if (remainCharacters <= -1) {
                        if (remainCharacters < -1) {
                            this.reasonForSplit = this.reasonForSplit.slice(0, 500);
                        }
                        else {
                            this.reasonForSplit = this.reasonForSplit.slice(0, -1);
                        }

                    }else {
                        this.remaincharactersText =  remainCharacters
                    }
                }else{
                    var remainCharacters = this.maxcharacter - this.reasonForSplit.length
                    this.remaincharactersText =  remainCharacters
                } 
            },
            
            isSplitToggle(isTrue, item) {
                let arrayList = {
                    id: null,
                    splitWorkOrderId: item.splitWorkOrderId == undefined ? null : item.splitWorkOrderId,
                    workOrderProductId: item.workOrderProductId,
                    isSplit1: item.isSplit1,
                    isSplit2: item.isSplit2,
                    isSplit3: item.isSplit3,
                    isSplit4: item.isSplit4
                }
                if (isTrue) {
                    this.listToSplit.push(arrayList)
                }
                else {
                    var indexToSplice = this.listToSplit.findIndex(x => x.workOrderProductId == item.workOrderProductId)
                    this.listToSplit.splice(indexToSplice, 1)
                }
                if (this.listToSplit.length ==  this.splitPOList.length) {
                    this.isButtonIsEnable = false
                }
                else if(this.listToSplit.length == 0){
                    this.isButtonIsEnable = false
                }
                else {
                    this.isButtonIsEnable = true
                }
            }
        },

        computed: {
            ...mapState('inspection', {
                _getJobList: state => state.jobList
            }),
        }
    }
</script>

<style lang="stylus" scoped>
    
    .splitWorkOrderForm
        background #fff
        padding 15px
    
    .top_title
        color red
        font-size 16px

    .submit
        width 60%
        color #fff
        margin 20px auto
        margin-top 30px
        display block

     #btns  {
        float: right;
        background: rgb(0, 118, 255);
        color: #fff;
    }

    button {
        height: 40px;
        width: 100px;
    }
    .fail_code
        padding 20px;
        padding-bottom 0px;
    .fail_code p 
        margin-bottom 5px;
    
    .traceCharacter
        text-align: right !important;

    .textAreaStyle
        text-align: left !important;
    
    .cardText
        text-align: center;

    .textColor        
        color #fff;

    .btnWidth        
        width: 100%;

    .v-input
        align-items: center;
        justify-content: center;

    .btnAlignment    
        position: absolute; 
        right: 0;
    
</style>