<template>
  <div name="FormBuild" class="mt-3">
    <v-select
      :id="msgData.formAtomID.toString()"
      v-if="msgData.controlType == 'dropdown' && (!this.key || this.showCoditionalField())"
      v-model="msgData.values[0].value"
      :items="items"
      :readonly="!msgData.isEditable"
      
      @change="onChangeDropdown(msgData.values[0].value)"
      :append-icon="msgData.isEditable ? '$dropdown' : ''"
      :auto-id="msgData.label"
    >
      <template v-slot:label>
        {{msgData.label}}<span v-if="msgData.isRequired" class="red--text"> *</span>
      </template>
    </v-select>
    <v-text-field
      :id="msgData.formAtomID.toString()"
      v-if="msgData.controlType == 'textbox' && (!this.key || this.showCoditionalField()) && !msgData.maxLength"
      v-model="msgData.values[0].value"
      :readonly="!msgData.isEditable"
      :append-icon="msgData.isEditable == true ? 'edit' : ''"
      @change="sava_field_value(msgData.formAtomID, msgData.values[0].value, msgData.values[0].referenceKey)"
      :auto-id="msgData.label"
    >
      <template v-slot:label>
        {{msgData.label}}<span v-if="msgData.isRequired" class="red--text"> *</span>
      </template>
    </v-text-field>
    <v-text-field
      :id="msgData.formAtomID.toString()"
      v-if="msgData.controlType == 'textbox' && (!this.key || this.showCoditionalField()) && msgData.maxLength"
      v-model="msgData.values[0].value"
      :readonly="!msgData.isEditable"
      :append-icon="msgData.isEditable == true ? 'edit' : ''"
      :maxlength="msgData.maxLength"
      :counter="msgData.maxLength" 
      @change="sava_field_value(msgData.formAtomID, msgData.values[0].value, msgData.values[0].referenceKey)"
      :auto-id="msgData.label"
    >
    <template v-slot:label>
        {{msgData.label}}<span v-if="msgData.isRequired" class="red--text"> *</span>
      </template>
    </v-text-field>
    <v-text-field
      :id="msgData.formAtomID.toString()"
      v-if="msgData.controlType == 'number' && (!this.key || this.showCoditionalField())"
      v-model="msgData.values[0].value"
      type="number"
      :readonly="!msgData.isEditable"
      :append-icon="msgData.isEditable == true ? 'edit' : ''"
      @change="sava_field_value(msgData.formAtomID, msgData.values[0].value, msgData.values[0].referenceKey)"
      :auto-id="msgData.label"
    >
      <template v-slot:label>
        {{msgData.label}}<span v-if="msgData.isRequired" class="red--text"> *</span>
      </template>
    </v-text-field>
    <p hidden :id="msgData.formAtomID.toString()" v-if="msgData.controlType == 'hidden' && (!this.key || this.showCoditionalField())">{{ msgData.values[0].value }}</p>
    <label :id="msgData.formAtomID.toString()" v-if="msgData.controlType == 'label' && (!this.key || this.showCoditionalField())">{{ msgData.values[0].value ? msgData.values[0].value : msgData.label }}</label>
    <v-autocomplete
      :id="msgData.formAtomID.toString()"
      v-if="msgData.controlType == 'Lookup' && (!this.key || this.showCoditionalField())"
      v-model="msgData.values[0].value"
      :items="items"
      @blur="sava_field_value(msgData.formAtomID, msgData.values[0].value, msgData.values[0].referenceKey)"
      :auto-id="msgData.label"
    >
      <template v-slot:label>
        {{msgData.label}}<span v-if="msgData.isRequired" class="red--text"> *</span>
      </template>
    </v-autocomplete>
    <!-- <v-autocomplete v-if="msgData.controlType =='Lookup'" v-model="sasa" :items="items" :label="msgData.label"></v-autocomplete> -->
    <v-switch :id="msgData.formAtomID.toString()" 
    @change="switched(msgData.formAtomID, msgData.values[0].value)"
    v-if="msgData.controlType == 'switch' && (!this.key || this.showCoditionalField())" 
    v-model="msgData.values[0].value" 
    :false-value="'false'" 
    :true-value="'true'" 
    color="green"
    :auto-id="msgData.label"
    >
      <template v-slot:label>
        {{msgData.label}}<span v-if="msgData.isRequired" class="red--text"> *</span>
      </template>
    </v-switch>
    <question v-if="msgData.controlType == 'question category' && (!this.key || this.showCoditionalField())" :msgData="msgData" @blur="sava_field_value(msgData.formAtomID, msgData.values[0].value, msgData.values[0].referenceKey)"></question>
    <dynamicFormTable v-if="msgData.controlType == 'product table' && (!this.key || this.showCoditionalField())" :msgData="msgData" :msgFormAtoms="msgFormAtoms" :sectionName="sectionName"></dynamicFormTable>
    <referenceDocuments v-if="msgData.controlType == 'Reference Document Table' && (!this.key || this.showCoditionalField())" :msgData="msgData"></referenceDocuments>
    <asda-weee-technical-data v-if="msgData.controlType == 'WEEE Data Table' && (!this.key || this.showCoditionalField())" :msgData="msgData" :msgFormAtoms="msgFormAtoms" :sectionName="sectionName"></asda-weee-technical-data>
    <dataTable v-if="msgData.controlType == 'data table' && (!this.key || this.showCoditionalField())" :formAtom="msgData" :columnAtoms="msgFormAtoms" :sectionName="sectionName"></dataTable>
    <totalMajorMinorAql v-if="msgData.controlType == 'major minor aql' && (!this.key || this.showCoditionalField())" :msgData="msgData" :msgFormAtoms="msgFormAtoms" :sectionName="sectionName"></totalMajorMinorAql>
    <sampling-methodology-field v-if="msgData.controlType == 'sampling methodology'" :msgData="msgData" :msgFormAtom="msgFormAtoms" :sectionName="sectionName"></sampling-methodology-field>
    <double-samplingPlan-iteration-field v-if="msgData.controlType == 'double sampling plan iteration'" :msgData="msgData" :msgFormAtom="msgFormAtoms" :sectionName="sectionName"></double-samplingPlan-iteration-field>
    <referenceReportNumber v-if="msgData.controlType == 'Reference Report Number'" :msgData="msgData"></referenceReportNumber>
    <template :id="msgData.formAtomID.toString()" v-if="msgData.controlType == 'multi-select' && (!this.key || this.showCoditionalField())">
      <v-autocomplete v-model="selectedItems" :items="items" small-chips multiple append-icon="edit" @change="add" @blur="sava_field_value(msgData.formAtomID, msgData.values[0].value, msgData.values[0].referenceKey)" :auto-id="msgData.label">
        <template v-slot:selection="data">
          <v-chip close @click:close="remove(data.item)">
            {{ data.item.text }}
          </v-chip>
        </template>
        <template v-slot:label>
        {{msgData.label}}<span v-if="msgData.isRequired" class="red--text"> *</span>
      </template>
      </v-autocomplete>
    </template>
    <template :id="msgData.formAtomID.toString()" v-if="msgData.controlType == 'date' && (!this.key || this.showCoditionalField())" :auto-id="msgData.label">
      <v-menu v-model="dateMenu" :close-on-content-click="false" max-width="290">
        <template v-slot:activator="{ on }">
          <v-text-field v-model="computedDateFormatted" persistent-hint prepend-icon="event" :readonly="!msgData.isEditable" v-on="on" :auto-id="msgData.label">
            <template v-slot:label>
              {{msgData.label}}<span v-if="msgData.isRequired" class="red--text"> *</span>
            </template>
          </v-text-field>
        </template>
        <v-date-picker v-model="date" no-title @change="sava_field_value(msgData.formAtomID, formatDate(date), msgData.values[0].referenceKey)" @input="dateMenu = false" color="blue darken-1" :auto-id="msgData.label"></v-date-picker>
      </v-menu>
    </template>
    <v-text-field
      type="time"
      suffix="12-hour clock"
      :id="msgData.formAtomID.toString()"
      v-if="msgData.controlType == 'time' && (!this.key || this.showCoditionalField())"
      v-model="msgData.values[0].value"
      :readonly="!msgData.isEditable"
      :append-icon="msgData.isEditable == true ? 'edit' : ''"
      @change="sava_field_value(msgData.formAtomID, msgData.values[0].value, msgData.values[0].referenceKey)"
      :auto-id="msgData.label"
    >
      <template v-slot:label>
        {{msgData.label}}<span v-if="msgData.isRequired" class="red--text"> *</span>
      </template>
    </v-text-field>
    <div v-if="msgData.controlType == 'radio button' && (!this.key || this.showCoditionalField())">
      <span v-html="msgData.label"><span v-if="msgData.isRequired" class="red--text"> *</span></span>
      <v-radio-group
        v-model="msgData.values[0].value"
        :readonly="!msgData.isEditable"
        :append-icon="msgData.isEditable == true ? 'edit' : ''"
        @change="sava_field_value(msgData.formAtomID, msgData.values[0].value, msgData.values[0].referenceKey)"
        row
        :auto-id="msgData.label"
      >
        <v-radio id="defect_classification" v-for="(item, index) in msgData.fieldLookupValues.result" :key="index" :label="item.value" :value="item.key" :auto-id="item.value"></v-radio>
      </v-radio-group>
    </div>
    <v-dialog v-model="showSampleDialog" :persistent="true" width="500">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          Please check the status of Approved Sample and Reference Sample
        </v-card-text>
        <v-card-actions>
          <v-btn id="closeSampleDialog" text @click="closeSampleDialog">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Question from "./Question";
import SamplingMethodologyField from "./SamplingMethodology";
import DoubleSamplingPlanIterationField from "./DoubleSamplingPlanIteration";
import DynamicFormTable from "./DynamicFormTable";
import ReferenceDocuments from "./ReferenceDocuments";
import DataTable from "./DataTable";
import TotalMajorMinorAql from "./TotalMajorMinorAql";
import ASDAWEEETechnicalData from './ASDAWEEETechnicalData';
import ReferenceReportNumber from "./ReferenceReportNumber";

export default {
  name: "FormBuild",
  components: {
    question: Question,
    dynamicFormTable: DynamicFormTable,
    referenceDocuments: ReferenceDocuments,
    dataTable: DataTable,
    totalMajorMinorAql: TotalMajorMinorAql,
    "sampling-methodology-field": SamplingMethodologyField,
    "double-samplingPlan-iteration-field": DoubleSamplingPlanIterationField,
    "asda-weee-technical-data": ASDAWEEETechnicalData,
    referenceReportNumber: ReferenceReportNumber
  },
  data() {
    return {
      selectedItems: [],
      items: [],
      dateMenu: false,
      date: null,
      key: null,
      value: null,
      conditionalkey: false,
      showSampleDialog: false,
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  mounted() {
    if (this.msgData.controlType == "dropdown" || this.msgData.controlType == "Lookup") {
      this.items = this.msgData.fieldLookupValues.result.map((x) => {
        return { text: x.value, value: x.key };
      });

      if(this.msgData.label.toLowerCase() === 'approved sample' || this.msgData.label.toLowerCase() === 'ref sample') {
        this.items.sort((a, b) => {
          const valueA = a.text.toString().toUpperCase();
          const valueB = b.text.toString().toUpperCase();

          if (valueA < valueB) {
            return -1;
          } else if (valueA > valueB) {
            return 1;
          } else {
            return 0;
          }
        });
      }
    }
    if (this.msgData.controlType == "multi-select") {
      this.items = this.msgData.fieldLookupValues.result.map((x) => {
        return { text: x.value, value: x.key };
      });
      this.selectedItems = this.msgData.values[0] && this.msgData.values[0].value ? this.msgData.values[0].value.split(",") : [];
    }

    if (this.msgData.controlType == "date" && this.msgData.values[0].value) {
      const [month, day, year] = this.msgData.values[0].value.split("/");
      this.date = `${year}-${month}-${day}`;
    }
    if (this.msgData.fieldReference && this.msgData.fieldReference.toLowerCase().includes("conditionalkey")) {
      this.conditionalkey = true;
    }

    this.storeConditionalKeyValue(this.msgData.formAtomID, this.msgData.values ? this.msgData.values[0].value : null);

    if (this.msgData.fieldReference && this.msgData.fieldReference.toLowerCase().includes("conditionalfield")) {
      const [ConditionalField, key, value] = this.msgData.fieldReference.split(":");
      this.key = key;
      this.value = value;
    }
  },
  methods: {
    showCoditionalField() {
      var result = false;
      if (this.key && this.msgConditionFieldData && this.msgConditionFieldData.length > 0) {
        this.msgConditionFieldData.forEach((x) => {
          if (x && x.formAtomID == this.key && x.value == this.value) {
            result = true;
          }
        });
      }
      return result;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    saveFieldValueFromSection() {
      if(this.msgData.isEditable)
        this.sava_field_value(this.msgData.formAtomID, this.msgData.values[0].value, this.msgData.values[0].referenceKey);
    },
    onChangeDropdown(val) {
      const flattenFormAtoms = this.tabItemRows.map(x => x.formAtoms)?.flat(1);
      const approvedSample = flattenFormAtoms?.find(x => x.label?.toLowerCase() === 'approved sample');
      const refSample = flattenFormAtoms?.find(x => x.label?.toLowerCase() === 'ref sample');

      if(this.msgData.label.toLowerCase() === 'approved sample') {
        const approvedSampleValue = approvedSample?.fieldLookupValues?.result?.find(x => x.key === val)?.value;
        const refSampleValue = refSample?.fieldLookupValues?.result?.find(x => x.key === refSample?.values[0]?.value)?.value;

        if(approvedSampleValue?.toLowerCase() === 'yes' && refSampleValue?.toLowerCase() === 'yes') {
          this.showSampleDialog = true;
        }
      }

      if(this.msgData.label.toLowerCase() === 'ref sample') {
        const refSampleValue = refSample?.fieldLookupValues?.result?.find(x => x.key === val)?.value;
        const approvedSampleValue = approvedSample?.fieldLookupValues?.result?.find(x => x.key === approvedSample?.values[0]?.value)?.value;

        if(approvedSampleValue?.toLowerCase() === 'yes' && refSampleValue?.toLowerCase() === 'yes') {
          this.showSampleDialog = true;
        }
      }

      this.sava_field_value(this.msgData.formAtomID, this.msgData.values[0].value, this.msgData.values[0].referenceKey)
    },
    sava_field_value(id, val, key) {
      this.storeConditionalKeyValue(id, val);
      
      var data = [
        {
          formAtomID: id,
          value: val,
          referenceKey: key,
        },
      ];
      this.utilityFn.getServerData("post", this.apiList.baseUrl + "/api/AIMS/UpsertFormValue/" + this.$route.params.id, data, (res) => {
        console.log(res);
      },
      (error) => {
        this.$store.dispatch("navigation/setSnackbar",{
                  color :'error',
                  text:'Error Saving ' + this.msgData.label});
      });

      if(this.msgData.label.toLowerCase() === 'minor aql'
        || this.msgData.label.toLowerCase() === 'major aql'
        || this.msgData.label.toLowerCase() === 'critical aql') {
          const aqlData = {
            workOrderID: this.$route.params.id,
            aqlName: this.msgData.label,
            value: val
          }
          this.utilityFn.getServerData("post", this.apiList.baseUrl + "/api/AIMS/UpdateAQL", aqlData, (res) => {
            console.log(res);
          },
          (error) => {
            this.$store.dispatch("navigation/setSnackbar",{
                      color :'error',
                      text:'Error Saving ' + this.msgData.label});
          });
        }

      //this.sectionHelper.updatedDynamicSectionModifiedDate(this.sectionName);

      
    },
    remove(e) {
      const index = this.selectedItems.findIndex((x) => x.toLowerCase() == e.value.toLowerCase());
      this.selectedItems.splice(index, 1);

      this.msgData.values[0].value = this.selectedItems.join(",");
    },
    add() {
      this.msgData.values[0].value = this.selectedItems.join(",");
    },
    switched(id, val) {
      this.$root.$emit(id.toString(), val);
      this.sava_field_value(id, val.toString(), this.msgData.values[0].referenceKey);
    },
    storeConditionalKeyValue(id, val) {
      //if fieldreference contains conditional key store it
      if (this.conditionalkey) {
        const value = this.msgData.fieldLookupValues ? this.msgData.fieldLookupValues.result.find((x) => x.key == val) : null;
        if (value) {
          var keyvalue = {
            formAtomID: String(id),
            value: String(value.value),
          };
          this.$emit("childToParentConditionalField", keyvalue);
        }
      }
    },
    closeSampleDialog() {
      this.showSampleDialog = false;
    },
  },
  props: {
    tabItemRows: {
      type: Array
    },
    msgData: {
      type: Object,
    },
    msgFormAtoms: {
      type: Array,
    },
    msgConditionFieldData: {
      type: Array,
    },
    sectionName: {
      type: String,
    },
  },
};
</script>

<style lang="stylus" scoped></style>
