#ASDAWEEEDataTable

<template>
    <div>
        <v-data-table v-if="datasource"
        :headers="headers"
        :items="datasource"
        :items-per-page="-1"
        :disable-sort="true"
        class="elevation-1"
        auto-id="asweedata table"
        >
            <template v-for="atom in editableColumns" v-slot:[`item.${atom.label}`]="{ item }">
                <div v-if="atom.label != 'Id'" :key="atom.formAtomID" @contextmenu="rightClickHandler($event, item, atom.label)">
                    <v-edit-dialog 
                        :return-value="item[atom.label]"
                        > {{ item[atom.label] }}
                            <template v-slot:input style="min-width:100px">                                   
                                <v-row>
                                    <v-col>
                                        <v-btn icon @click="rightClickedItem = item; editItem(item)" auto-id="aswee edit">
                                            <v-icon color="rgba(0,0,0,.87)">edit</v-icon>
                                        </v-btn>                                          
                                    </v-col>
                                    <v-col>
                                        <v-btn icon @click="rightClickedItem = item; deleteItem()" auto-id="aswee delete">
                                            <v-icon color="rgba(0,0,0,.87)">delete</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                    </v-edit-dialog>                    
                </div>       
            </template>            
        </v-data-table>
        <v-menu v-model="showMenu" :position-x="x" :position-y="y" :key="1" absolute offset-y  auto-id="aswee menu">
            <v-list auto-id="aswee list">
                <v-list-item
                :key="1" link @click="editItem()" auto-id="aswee list item edit">
                <v-list-item-title>Edit Item</v-list-item-title>
                </v-list-item>
                <v-list-item
                :key="2" link @click="deleteItem()" auto-id="aswee list item delete">
                <v-list-item-title>Delete Item</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-dialog v-model="deleteDialog" max-width="300px">
            <v-card>
                <v-toolbar flat color="pink" dark>
                <v-card-title>
                    <span class="headline">Delete Confirmation</span>
                </v-card-title>
                </v-toolbar>
                <v-card-text>
                <v-container>
                    <v-row>
                    Are you sure you want to delete?
                    </v-row>
                </v-container>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn id="btnDeleteDialogCancel" text @click="close" auto-id="aswee cancel">Cancel</v-btn>
                <v-btn id="btnDeleteDialogDelete" color="red" text @click="deleteRecord()" auto-id="aswee delete">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showWEEEForm" max-width="800px" persistent>            
            <v-card>
                <v-toolbar flat color="#ff5252" dark>
                    <v-card-title>
                        <span class="headline">WEEE Technical Team Data</span>
                    </v-card-title>
                </v-toolbar>
                <v-card-text>                    
                    <v-container>                        
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select :items="getDropDownList('Item Type')" v-model="fItemType" @change="resetSubsections()" label="WEEE Item Type*" required auto-id="aswee wee item type"></v-select>
                            </v-col>   
                        </v-row>
                        <v-row>                                            
                            <v-col cols="12" sm="6">
                                <v-select :items="getDropDownList('Vendor Stock #')" v-model="fVendorStock" @change="resetItemNumber()" label="Vendor Stock #*" required auto-id="aswee vendor stock"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="getDropDownList('Item Number')" v-model="fItemNumber" label="WIN Number*" v-if="fVendorStock && fVendorStock != ''" required auto-id="aswee win number"></v-select>
                            </v-col>            
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">                                        
                                            <v-combobox v-on="on" v-bind="attrs" :items="getDropDownList('Brand Name')" v-model="fBrandName" label="Brand Name*" auto-id="aswee brand name"></v-combobox>                                    
                                    </template>
                                    <span>Identify the Brand Name (i.e ASDA, George Home) on the package</span>
                                </v-tooltip>                                    
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="fSpecCode" v-on:change="generateDeptNo()" label="Spec Code*" required auto-id="aswee spec code"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="fDeptNo" label="Department # for WEEE*" required auto-id="aswee department #"></v-text-field>
                            </v-col>
                        </v-row>  
                    </v-container>    
                    <v-expansion-panels accordion v-model="panel" multiple>
                        <v-expansion-panel>
                            <v-expansion-panel-header style="background-color:pink">WEEE Information</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="12">
                                            <v-select :items="getDropDownList('WEEE Category')" v-model="fCategory" label="WEEE Category*" required auto-id="aswee wee category"></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field v-on="on" v-bind="attrs" v-model="fProductNetWeight" label="Product Net. Weight (g)" auto-id="aswee product net"></v-text-field>    
                                                </template>
                                                <span>Weight must be excluded any packaging</span>
                                            </v-tooltip>                                                                                        
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header style="background-color:pink">Battery Directive Information</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="12">
                                            <v-select :disabled="isBatteryDirectiveInformationDisabled()" :items="getDropDownList('Are Batteries Supplied with the Product')" v-model="fBatterySupplied" label="Are Batteries Supplied with the Product?*" auto-id="aswee batteries suppied"></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div v-on="on">
                                                        <v-select :disabled="isBatteryDirectiveInformationDisabled()" v-bind="attrs" :items="getDropDownList('Battery Chemistry')" v-model="fBatteryChemistry" label="Battery Chemistry" auto-id="aswee battery chemistry"></v-select>  
                                                    </div>                                                      
                                                </template>
                                                <span>If batteries are not supplied with product leave blank</span>
                                            </v-tooltip>                                            
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div v-on="on">
                                                        <v-combobox :disabled="isBatteryDirectiveInformationDisabled()" v-bind="attrs" :items="getDropDownList('Battery Size')" v-model="fBatterySize" label="Battery Size" auto-id="aswee battery size"></v-combobox>    
                                                    </div>                                                    
                                                </template>
                                                <span>If not a standard, please state 'Custom Battery'. If batteries are not supplied with product leave blank</span>
                                            </v-tooltip>                                            
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field :disabled="isBatteryDirectiveInformationDisabled()" v-on="on" v-bind="attrs" v-model="fBatteryWeight" label="Battery Weight (g)" auto-id="aswee battery weight"></v-text-field>    
                                                </template>
                                                <span>If batteries are not supplied with product leave blank</span>
                                            </v-tooltip>                                            
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="12">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field :disabled="isBatteryDirectiveInformationDisabled()" v-on="on" v-bind="attrs" v-model="fNoOfBatteriesRequired" label="Batteries Required per Product" auto-id="aswee batery required"></v-text-field>    
                                                </template>
                                                <span>Number of Batteries Supplied with Product</span>
                                            </v-tooltip>                                            
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header style="background-color:pink">Bulb Information</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div v-on="on">
                                                        <v-combobox :disabled="isBulbInformationDisabled()" v-bind="attrs" :items="getDropDownList('Cap Type')" v-model="fCapType" label="Cap Type" auto-id="aswee cap type"></v-combobox>
                                                    </div>
                                                </template>
                                                <span>e.g. E27</span>
                                            </v-tooltip>                                            
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field :disabled="isBulbInformationDisabled()" v-model="fRatedWattage" label="Rated Wattage (W)" auto-id="aswee rated wattage"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field :disabled="isBulbInformationDisabled()" v-model="fRatedLampLife" label="Rated Lamp Life (hours)" auto-id="aswee rated lamp life"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field :disabled="isBulbInformationDisabled()" v-on="on" v-bind="attrs" v-model="fPackageSize" label="Package Size" auto-id="aswee package size"></v-text-field>
                                                </template>
                                                <span>Bulbs Quantity per Selling Package</span>
                                            </v-tooltip>                                            
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field :disabled="isBulbInformationDisabled()" v-model="fSingleBulbWeight" label="Single Bulb Weight (g)" auto-id="aswee single bulb"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-combobox :disabled="isBulbInformationDisabled()" :items="getDropDownList('Energy Class')" v-model="fEnergyClass" label="Energy Class" auto-id="aswee energy class"></v-combobox>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div v-on="on">
                                                        <v-select :disabled="isBulbInformationDisabled()" v-bind="attrs" :items="getDropDownList('Dimmable/ Non Dimmable')" v-model="fDimmable" label="Dimmable/ Non Dimmable" auto-id="aswee dimmable/non"></v-select>
                                                    </div>
                                                </template>
                                                <span>If the bulb is with dimmable (or say adjustable light intensity), then please input dimmable, if not, then pls input non dimmable</span>
                                            </v-tooltip>                                            
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>                    
                    <v-container v-if="errors.length">
                        <b>Please correct the following error(s):</b>
                        <ul>
                            <li v-for="error in errors" :key="error" auto-id="aswee list errors">{{ error }}</li>
                        </ul>
                    </v-container>                                        
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="formClose()" auto-id="aswee close">Close</v-btn>
                    <v-btn color="blue darken-1" text @click="formSave()" auto-id="aswee save">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-btn id="'remarks_add_button" class="add_remarks" color="#B14C4C" style="color:#fff" @click="addItem()" auto-id="aswee add new row">Add New Row</v-btn>
    </div>
</template>

<script>

export default {
    name: 'ASDAWEEEDataTable',
    data (){
        return {
            datasource: [],
            headers: [],
            editableColumns: [],
            dateMenu: [],
            showMenu: false,
            x: null,
            y: null,
            copyValue: '',
            rightClickedItem: null,
            deleteDialog: false,
            buttonDisable: false,
            showWEEEForm: false,

            panel: [0,1,2],

            //WEEE Fields
            fId: null,
            fItemType: null,
            fVendorStock: null,
            fItemNumber: null,
            fBrandName: null,
            fSpecCode: null,

            fDeptNo: null,
            fCategory: null,
            fProductNetWeight: null,        
            fBatterySupplied: null,
            fBatteryChemistry: null,

            fBatterySize: null,
            fBatteryWeight: null,
            fNoOfBatteriesRequired: null,
            fCapType: null,
            fRatedWattage: null,

            fRatedLampLife: null,
            fPackageSize: null,
            fSingleBulbWeight: null,
            fEnergyClass: null,
            fDimmable: null,

            //validation
            errors: [],
            fIndex: -1
        }
    },
    computed: {
    },
        mounted() {
            this.deleteDialog = false;
            this.showWEEEForm = false;

            this.datasource = JSON.parse(this.msgData.values[0].value);

            if(this.msgData.fieldReference) {
                const ids = this.msgData.fieldReference.split(",");
                const columns = this.msgFormAtoms.filter(x=> ids.includes(x.formAtomID.toString()));
                columns.forEach(header => {  
                    if (header.label != 'Id') {
                        this.headers.push({text: header.label, value: header.label});
                        if(header.isEditable)
                            this.editableColumns.push(header);
                    }
                });
            }

    },
    methods: {
        computedDateFormatted (date) {
            return new Date(date).toJSON();
        },
        sava_field_value (id, val, key) {
            var data = [{
                "formAtomID": id,
                "value": val,
                "referenceKey": key
            }]

              this.utilityFn.getServerData('post', this.apiList.baseUrl + '/api/AIMS/UpsertFormValue/' + this.$route.params.id, data, res => {
                  console.log(res)

                  //this.sectionHelper.updatedDynamicSectionModifiedDate(this.sectionName);
              })
        },
        close() {
            this.deleteDialog = false;
        },

        isBatteryDirectiveInformationDisabled() {
            return this.fItemType && this.fItemType == "Battery Not Supplied with Product";
        },

        isBulbInformationDisabled() {
            return this.fItemType && this.fItemType != "Bulb Product";
        },

        resetSubsections() {
            if (this.isBatteryDirectiveInformationDisabled())
            {
                this.fBatterySupplied = "No";
                this.fBatteryChemistry = null;
                this.fBatterySize = null;
                this.fBatteryWeight = null;
                this.fNoOfBatteriesRequired = null;
            }
            else
                this.fBatterySupplied = null;
                        
            if (this.isBulbInformationDisabled()) 
            {
                this.fCapType = null;
                this.fRatedWattage = null;
                this.fRatedLampLife = null;
                this.fSingleBulbWeight = null;
                this.fPackageSize = null;
                this.fEnergyClass = null;
                this.fDimmable = null;
            }
        },

        resetItemNumber() {
            this.fItemNumber = null;
        },

        isFormValid() {
            this.errors = [];

            if (!this.fItemType || this.fItemType.value == "") this.errors.push('WEEE Item Type is required');
            if (!this.fVendorStock || this.fVendorStock.value == "") this.errors.push('Vendor Stock # is required');
            if (!this.fItemNumber || this.fItemNumber.value == "") this.errors.push('WIN Number is required');
            if (!this.fSpecCode || this.fSpecCode.value == "") this.errors.push('Spec Code is required');
            if (!this.fBrandName || this.fBrandName.value == "") this.errors.push('Brand Name is required');
            if (!this.fDeptNo || this.fDeptNo.value == "") this.errors.push('Department # for WEEE is required');
            
            if (!this.fCategory || this.fCategory.value == "") this.errors.push('WEEE Category is required');
            if (!this.fBatterySupplied || this.fBatterySupplied.value == "") this.errors.push('Are Batteries Supplied with the Product? is required');

            if (this.fBatterySupplied && this.fBatterySupplied == "Yes") {
                if (!this.fBatterySize || this.fBatterySize.value == "") this.errors.push('Battery Size is required');
                if (!this.fBatteryWeight || this.fBatteryWeight.value == "") this.errors.push('Battery Weight is required');
                if (!this.fBatteryChemistry || this.fBatteryChemistry.value == "") this.errors.push('Battery Chemistry is required');
                if (!this.fNoOfBatteriesRequired || this.fNoOfBatteriesRequired.value == "") this.errors.push('Batteries Required per Product is required');
            }

            //numeric validation
            if (this.fProductNetWeight && isNaN(parseFloat(this.fProductNetWeight))) this.errors.push('Product Net. Weight (g) must be numeric.');
            if (this.fProductNetWeight && (!isNaN(parseFloat(this.fProductNetWeight)) && parseFloat(this.fProductNetWeight) <= 0)) this.errors.push('Product Net. Weight (g) must be a positive number.');
            
            if (this.fNoOfBatteriesRequired && isNaN(parseInt(this.fNoOfBatteriesRequired))) this.errors.push('Batteries Required per Product must be a whole number.');
            if (this.fNoOfBatteriesRequired && (!isNaN(parseInt(this.fNoOfBatteriesRequired)) && parseInt(this.fNoOfBatteriesRequired) <= 0)) this.errors.push('Batteries Required per Product must be a positive number.');
           
            if (this.errors && this.errors.length > 0) 
                return false;
            else
                return true;
        },

        addItem() {
            this.clearFields();            
            this.showWEEEForm = true;            
        },

        clearFields() {
            this.errors = [];
            this.fIndex = -1;

            this.fId = null;
            this.fItemType = null;
            this.fVendorStock = null;
            this.fItemNumber = null;
            this.fBrandName = null;
            this.fSpecCode = null;

            this.fDeptNo = null;
            this.fBatterySupplied = null;
            this.fBatteryChemistry = null;
            this.fBatterySize = null;
            this.fBatteryWeight = null;

            this.fNoOfBatteriesRequired = null;
            this.fCapType = null;
            this.fCategory = null;
            this.fProductNetWeight = null;
            this.fRatedWattage = null;

            this.fRatedLampLife = null;
            this.fPackageSize = null;
            this.fSingleBulbWeight = null;
            this.fEnergyClass = null;
            this.fDimmable = null;
        },

        deleteRecord() {
            if (this.rightClickedItem) {
                this.utilityFn.getServerData("post", this.apiList.deleteTechnicalData + '/' + this.rightClickedItem.Id, null, res => {
                    if (res && res.data) {
                        var url = this.apiList.dynamicFormAtomData + '/' + this.$route.params.id + '/' + this.msgData.formAtomID;
                        this.utilityFn.getServerData("get", url, {}, result => {                            
                            if (result?.data?.data?.value) {
                                this.datasource = JSON.parse(result?.data?.data?.value);
                                this.datasource.sort(function (a, b) {
                                    return a.Id - b.Id;
                                });   
                            }
                            else
                                this.datasource = [];
                            
                            this.deleteDialog = false; 
                        });
                    }                   
                });
            }
        },

        editItem() {
            if (this.rightClickedItem)
            {
                this.clearFields(); 

                let entry = this.rightClickedItem;

                this.fIndex = this.datasource.indexOf(entry);

                this.fId = entry['Id'];
                this.fItemType = entry['Item Type'];
                this.fVendorStock = entry['Vendor Stock #'];
                this.fItemNumber = entry['Item Number'];
                this.fBrandName = entry['Brand Name'];
                this.fSpecCode = entry['Spec Code'];

                this.fDeptNo = entry['Department # for WEEE'];
                this.fCategory = entry['WEEE Category'];
                this.fProductNetWeight = entry['Product Net Weight (g)'];
                this.fBatterySupplied = entry['Are Batteries Supplied with the Product'];
                this.fBatteryChemistry = entry['Battery Chemistry'];

                this.fBatterySize = entry['Battery Size'];
                this.fBatteryWeight = entry['Battery Weight (g)'];
                this.fNoOfBatteriesRequired = entry['Batteries Required per Product'];
                this.fCapType = entry['Cap Type'];
                this.fRatedWattage = entry['Rated Wattage'];
                
                this.fRatedLampLife = entry['Rated Lamp Life (hours)'];
                this.fPackageSize = entry['Package Size'];
                this.fSingleBulbWeight = entry['Single Bulb Weight (g)'];
                this.fEnergyClass = entry['Energy Class'];
                this.fDimmable = entry['Dimmable/ Non Dimmable'];

                this.showWEEEForm = true;
            }
            
        },

        deleteItem() {
            this.deleteDialog = true;
        },

        getItems(atom) {
            if(atom.label != 'Item Number') {
                return atom.fieldLookupValues.result.map(x => {
                    return { text: x.value, value: x.value }
                });
            }
            else {
                let results = atom.fieldLookupValues.result.map(x => {                  
                    let item = x.value?.split(',');                  
                    if (item && this.fVendorStock && item[0] == this.fVendorStock)
                        return {text: item[1], value: item[1]};
                    else
                        return {text: '', value: ''};
                });

                results = results.filter(x => x.text != '');
                return results ?? [{text: '', value: ''}];
            }            
        },

        getDropDownList(label) {
            if (this.editableColumns) {
                var atom = this.editableColumns.find(x => x.label == label);
                return atom ? this.getItems(atom) : null;
            }            
        },

        generateDeptNo() {
            if ((!this.fDeptNo || this.fDeptNo == '') && (this.fSpecCode && this.fSpecCode != ''))
            {
                //check the format
                var specs = this.fSpecCode.split('.');

                if (specs && specs.length == 4 && specs[0] && specs[0].length <= 3)
                    this.fDeptNo = 'D' + specs[0].padStart(3,"0");
            }
        },

        formClose() {            
            this.showWEEEForm = false;
        },

        formSave() {
            if (this.isFormValid())   {
                var record = new Object();

                record.WorkOrderId = this.$route.params.id;
                
                record.Id = this.fId;                
                record.VendorStock = this.fVendorStock;
                record.ItemNumber = this.fItemNumber;
                record.ItemType = this.fItemType;
                record.SpecCode = this.fSpecCode;
                
                record.DeptNoForWEEE = this.fDeptNo;
                record.BrandName = (this.fBrandName && this.fBrandName.value) ? this.fBrandName.value : this.fBrandName;
                record.Category = this.fCategory;
                record.ProductNetWeight = this.fProductNetWeight;
                record.BatterySuppliedWithProduct = this.fBatterySupplied;
                record.BatterySize = (this.fBatterySize && this.fBatterySize.value) ? this.fBatterySize.value : this.fBatterySize;

                record.BatteryWeight = this.fBatteryWeight;
                record.BatteryChemistry = this.fBatteryChemistry;
                record.BatteryRequiredPerProduct = this.fNoOfBatteriesRequired;
                record.CAPType = (this.fCapType && this.fCapType.value) ? this.fCapType.value : ((!this.fCapType || this.fCapType == '') ? "N/A" : this.fCapType);
                record.SingleBulbWeight = (!this.fSingleBulbWeight || this.fSingleBulbWeight == '') ? "N/A" : this.fSingleBulbWeight;

                record.PackageSize = (!this.fPackageSize || this.fPackageSize == '') ? "N/A" : this.fPackageSize;
                record.EnergyClass = (this.fEnergyClass && this.fEnergyClass.value) ? this.fEnergyClass.value : ((!this.fEnergyClass || this.fEnergyClass == '') ? "N/A" : this.fEnergyClass);
                record.RatedWattage = (!this.fRatedWattage || this.fRatedWattage == '') ? "N/A" : this.fRatedWattage;
                record.RatedLampLife = (!this.fRatedLampLife || this.fRatedLampLife == '') ? "N/A" : this.fRatedLampLife;
                record.Dimmable = (!this.fDimmable || this.fDimmable == '') ? "N/A" : this.fDimmable;

                this.utilityFn.getServerData("post", this.apiList.upsertTechnicalData, record, res => {
                    if (res && res.data)
                    {
                        var url = this.apiList.dynamicFormAtomData + '/' + res.data.workOrderId + '/' + this.msgData.formAtomID;
                        this.utilityFn.getServerData("get", url, {}, result => {
                                                   
                            this.datasource = JSON.parse(result?.data?.data?.value);   
                            this.datasource.sort(function (a, b) {
                                    return a.Id - b.Id;
                                });                      
                            this.showWEEEForm = false; 
                        });                        
                    } 
                });
            }
        },
        
        formatDate (date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },

        dateChange(event, atom, index, formAtomID, key) {
            atom[index] = this.formatDate(event);
            this.sava_field_value(formAtomID, atom[index], key);
        },

        rightClickHandler(event, item, col) {
            event.preventDefault();

            this.x = event.x;
            this.y = event.y+10;
            this.showMenu = true;

            this.copyCol = col;
            this.copyValue = item[col];
            this.rightClickedItem = item;
        }
    },
    props: {
        msgData:{
            type: Object
        },
        msgFormAtoms:{
            type: Array
        },
        sectionName: {
            type: String
        }
    }
};
</script>

<style lang="stylus" scoped>
</style>
