let headerDetail = {    
    'Content-Type': 'application/json',
    // get Authorization() {
    //     return token();
    // }
}

let headerDetailMultiPart = {
    'Content-Type': 'multipart/form-data',
    get Authorization() {
        return token();
    }
}

let multiPartHeader = {
    'Content-Type': 'multipart/form-data'
}

var token = function() {
    let token = JSON.parse(localStorage.getItem("tokenInfo"));
    return token === '' ? '' : 'Bearer ' + token.token;
}

var resourceId = function() {
    let token = JSON.parse(localStorage.getItem("tokenInfo"));
    let resourceID = token.resourceId; //resources.findIndex(x => x.userName == userName);
    return resourceID === '' ? '' : resourceID;
}

var apiDetails = {
    header: {
        headersDetail: headerDetail
    },
    headerMulti: {
        headersDetail: headerDetailMultiPart
    },
    multiPart: {
        multiPartHeader: multiPartHeader
    },
    apiToken: {
        get apiTokenDetail() {
            return token();
        }
    },
    resourceId: resourceId,
}

export default apiDetails
