<template>
    <div class="d-flex">
        <v-text-field label="Reference Report Number" readonly v-model="referenceReportNum" auto-id="reference report number">
        </v-text-field>
        <v-btn x-small outlined color="error" @click="redirectToView()" :disabled="disablePreviewButton()" auto-id="reference report number preview">
            <v-icon dark>
                mdi-file-eye
            </v-icon>
        </v-btn>
    </div>
</template> 

<script>

export default {
    name: 'ReferenceReportNumber',
    data (){
        return {
            referenceReportNum:"",
            notEmploymentType: false,
            referenceReportNumIds: []
        }
    },
    computed: {
        
    },
    mounted() {
        this.loadData();
    },
    methods: {
        loadData() {
            this.utilityFn.getServerData(
        "get",
        this.apiList.getPreviousReportNumberAndValidation + "/" + this.$route.params.id,
        {},
        (res) => {
            this.referenceReportNum = res.data.data.item1;
            this.notEmploymentType = !res.data.data.item2;
            this.referenceReportNumIds = res.data.data.item3;
        },
        {});
        },
        redirectToView(){
            this.$router.push({ name: 'previewReferenceNumber', params: { id: this.referenceReportNumIds[0].workOrderId, workOrderPreviewList: this.referenceReportNumIds,counter:0 }, query: { id: this.referenceReportNumIds[0].workOrderDisplayId } });
        },
        disablePreviewButton(){
            if (!this.notEmploymentType && this.referenceReportNumIds.length > 0){
                return false;
            }

            return true;
        }
    },
    props: {
        msgData:{
            type: Object
        },
    }
};
</script>

<style lang="stylus" scoped>
</style>
