#ReferenceDocuments

<template>
    <div>
        <v-tabs v-model="tabModel" background-color="#399dff" class="elevation-2" dark style="margin-top:30px;" show-arrows>
            <v-tab href="#tab-1" auto-id="reference documents">
                Reference Documents
            </v-tab>
            <v-tab href="#tab-2" auto-id="client technical documents">
                Client Technical Documents
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabModel" auto-id="referece documents tab model">
            <v-tab-item :value="'tab-1'">
                <v-data-table
                    :headers="referenceDocHeaders"
                    :items="referenceDocSource"
                    :items-per-page="-1"
                    class="elevation-1"
                    auto-id="table referece document">
                    <template v-slot:item.fileName="{ item }">
                        <a @click="downloadDocument(item.id)" auto-id="download document">{{item.fileName}}</a>
                    </template>
                    <template v-slot:item.fileSize="{ item }">
                        {{formatFileSize(item.fileSize)}}
                    </template>
                </v-data-table>
            </v-tab-item>
            <v-tab-item :value="'tab-2'">
                <v-data-table
                    :headers="clientTechDocHeaders"
                    :items="clientTechDocSource"
                    :items-per-page="-1"
                    class="elevation-1"
                    auto-id="table client document">
                    <template v-slot:item.description="{ item }">
                        <a :href="formatLink(item.link)" target="_blank" auto-id="download client document">{{item.description}}</a>
                    </template>
                </v-data-table>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>

export default {
    name: 'ReferenceDocuments',
    data (){
        return {
            referenceDocSource: [],
            clientTechDocSource: [],
            referenceDocHeaders: [
                { text: 'File Name', value: 'fileName' },
                { text: 'Type', value: 'fileType' },
                { text: 'File Size', value: 'fileSize' }
            ],
            clientTechDocHeaders: [
                { text: 'File Name', value: 'description' },
            ],
            tabModel: null
        }
    },
    computed: {
    },
        mounted() {
            this.utilityFn.getServerData("get", this.apiList.baseUrl + "/api/AIMS/GetWorkOrderReferenceDocuments/" + this.$route.params.id, null, (res) => {
                this.referenceDocSource = res.data.data;
            });

            this.utilityFn.getServerData("get", this.apiList.baseUrl + "/api/AIMS/GetWorkOrderClientTechnicalDocuments/" + this.$route.params.id, null, (res) => {
                this.clientTechDocSource = res.data.data;
            });
    },
    methods: {
        downloadDocument(id) {
            this.utilityFn.getServerData("get", this.apiList.baseUrl + "/api/AIMS/DownloadWorkOrderReferenceDocument/" + id, null, (res) => {
                var a = document.createElement("a"); //Create <a>
                a.href = "data:application/octet-stream;base64," + res.data.data.data; //Image Base64 Goes here
                a.download = res.data.data.fileName; //File name Here
                a.click(); //Downloaded file
            });
        },
        formatFileSize(size) {
            return (Math.round((size / 1024) * 100) / 100) + " KB";
        },
        formatLink(url) {
            if (!url.match(/^https?:\/\//i)) {
                url = 'http://' + url;
            }
            return url;
        }
    },
    props: {
        msgData:{
            type: Object
        },
        sectionName: {
            type: String
        }
    }
};
</script>

<style lang="stylus" scoped>
</style>
