<template>
  <div>
    <v-overlay :value="loadingWod"></v-overlay>
    <v-snackbar class="snackbar-loading" v-model="loadingWod" top :timeout="0" color="#4caf50">
      <v-progress-circular :size="15" indeterminate color="primary"></v-progress-circular>
      <span style="padding-left:10px;">Loading</span>
    </v-snackbar>
    <div class="workOrderDetailsId">
      <v-tabs v-model="tab" dark grow background-color="#00518A" style="position: fixed;z-index: 20;top: 50px;">
        <v-tab href="#tab-1" auto-id="general">General</v-tab>
        <v-tab href="#tab-2" @click="updateValue()" auto-id="onsite observation">Onsite Observation</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" style="width: 96%; margin: 70px auto; background: transparent;">
        <v-tab-item value="tab-1">
          <v-expansion-panels v-model="optionPanelsGeneral" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header @click="UpsertSampling()" :style="'background: #c2e0fd'" auto-id="inspection time">
                <v-row no-gutters>
                  <v-col cols="5" class="tab_title">Inspection Time</v-col>
                  <v-col cols="3" v-if="fromCanyon" @click.stop>
                    <v-select :items="inspectors" v-model="inspectionTimeForResourceModel" item-text="fullName" item-value="resourceId" @change="resetInspectionTimeCount()" auto-id="reset inspection time">

                    </v-select>
                  </v-col>
                  <v-col cols="3" v-if="!fromCanyon"></v-col>
                  <v-col cols="2"></v-col>
                  <v-col cols="2" style="text-align:right;padding-right:10px;">
                    <div style="position: relative;">
                      <div
                        :style="
                          workOrder.status == 'Approved'
                            ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop'
                            : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                        "
                      ></div>
                      <span @click.stop="go_upload_photo('Inspection Time', 0, Sections.InspectionTime.isSectionSubmitted)" auto-id="inspection time upload">
                        <icon name="camera"></icon>
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <!-- InspectionTime component -->
                <div style="position: relative;">
                  <div
                    :style="
                      inspectionTimeDisableEnable()
                        ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop'
                        : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                    "
                  ></div>
                  <div v-if="generalData.workOrderId">
                    <InspectionTime 
                      v-for="(count,index) in InspectionTimeCount" :key="count" 
                      @UpdateInspectionTimeCount="updateInspectionTimeCount"
                      :workOrderId="generalData.workOrderId" 
                      :clientName="generalData.clientName" 
                      :resourceId="resourceIdForInspectionTime()"
                      :day="`Day ${index+1}`" 
                      :inspectionTimeCount="InspectionTimeCount.length"
                      :indexNumber= count>
                    </InspectionTime>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header @click="UpsertSampling()" :style="!Sections.General.isAuthorized ? 'background:#eee' : 'background: #c2e0fd'" auto-id="general tab">
                <v-row no-gutters>
                  <v-col cols="10" class="tab_title">General</v-col>
                  <v-col cols="2" style="text-align:right;padding-right:10px;">
                    <div style="position: relative;">
                      <div
                        :style="
                          workOrder.status == 'Approved' || !Sections.General.isAuthorized
                            ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop'
                            : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                        "
                      ></div>
                      <span @click.stop="go_upload_photo('General', 7, Sections.General.isSectionSubmitted)" auto-id="general camera">
                        <icon name="camera"></icon>
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <!-- general component -->
                <div style="position: relative;">
                  <div
                    :style="
                      workOrder.status == 'Approved' || (inspectors.length > 1 && (!Sections.General.isAuthorized || Sections.General.isSectionSubmitted || workOrder.status == 'Approved'))
                        ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop'
                        : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                    "
                  ></div>
                  <General :generalData="generalData" :sectionData="Sections.General"></General>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="isAdditionalInformationTrue">
              <v-expansion-panel-header :style="!Sections.AdditionalInformation.isAuthorized ? 'background:#eee' : 'background: #c2e0fd'">
                <v-row no-gutters>
                  <v-col cols="10" class="tab_title"> Additional Information</v-col>
                  <v-col cols="2" style="text-align:right;padding-right:10px;">
                    <div style="position: relative;">
                      <div
                        :style="
                          workOrder.status == 'Approved' || !Sections.AdditionalInformation.isAuthorized
                            ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop'
                            : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                        "
                      ></div>
                      <span @click.stop="go_upload_photo('Additional Information', 25, Sections.AdditionalInformation.isSectionSubmitted)" auto-id="additional information camera">
                        <icon name="camera"></icon>
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <div style="position: relative;">
                  <div
                    :style="
                      workOrder.status == 'Approved' || (inspectors.length > 1 && (!Sections.AdditionalInformation.isAuthorized || Sections.AdditionalInformation.isSectionSubmitted || workOrder.status == 'Approved'))
                        ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop'
                        : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                    "
                  ></div>
                  <AdditionalInformation :hideButton="generalData.hideButton" :isAuthorized="Sections.AdditionalInformation.isAuthorized" :isSectionSubmitted="Sections.AdditionalInformation.isSectionSubmitted"></AdditionalInformation>
                </div>               
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header @click="UpsertSampling()" :style="!Sections.SupplierSite.isAuthorized ? 'background:#eee' : 'background: #c2e0fd'" auto-id="supplier/site tab">
                <v-row no-gutters>
                  <v-col cols="10" class="tab_title">Supplier/Site</v-col>
                  <v-col cols="2" style="text-align:right;padding-right:10px;">
                    <div style="position: relative;">
                      <div
                        :style="
                          workOrder.status == 'Approved' || !Sections.SupplierSite.isAuthorized
                            ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop'
                            : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                        "
                      ></div>
                      <span @click.stop="go_upload_photo('Supplier', 8, Sections.SupplierSite.isSectionSubmitted)" auto-id="supplier/site camera">
                        <icon name="camera"></icon>
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <!-- supplier component -->
                <div style="position: relative;">
                  <div
                    :style="
                      workOrder.status == 'Approved' || (inspectors.length > 1 && (workOrder.status == 'Approved' || !Sections.SupplierSite.isAuthorized || Sections.SupplierSite.isSectionSubmitted))
                        ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop'
                        : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                    "
                  ></div>
                  <Supplier :generalData="generalData" :sectionData="Sections.SupplierSite"></Supplier>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header :style="(Sections.SupplierSite.isAuthorized || workOrder.resources?.length > 1) ? 'background: #c2e0fd' : 'background:#eee'" auto-id="reference documents tab">
                <v-row no-gutters>
                  <v-col cols="10" class="tab_title">Reference Documents / Client Technical Documents</v-col>
                  <v-col cols="2" style="text-align:right;padding-right:10px;">
                    <div style="position: relative;">
                      <div
                        :style="
                          workOrder.status == 'Approved'
                            ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop'
                            : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                        "
                      ></div>
                      <span @click.stop="go_upload_photo('ReferenceDocuments', 26, Sections.SupplierSite.isSectionSubmitted)" auto-id="reference document camera">
                        <icon name="camera"></icon>
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <ReferenceDocuments></ReferenceDocuments>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header @click="updateValue()" :style="!Sections.Sampling.isAuthorized ? 'background:#eee' : 'background: #c2e0fd'" auto-id="sampling tab">
                <v-row no-gutters>
                  <v-col cols="10" class="tab_title">Sampling</v-col>
                  <v-col cols="2" style="text-align:right;padding-right:10px;">
                    <div style="position: relative;">
                      <div
                        :style="
                          workOrder.status == 'Approved' || !Sections.Sampling.isAuthorized
                            ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop'
                            : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                        "
                      ></div>
                      <span @click.stop="go_upload_photo('Sampling', 9, Sections.Sampling.isSectionSubmitted)" auto-id="sampling camera">
                        <icon name="camera"></icon>
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- sampling component -->
                <div style="position: relative;">
                  <div
                    :style="
                      workOrder.status == 'Approved' || (inspectors.length > 1 && (workOrder.status == 'Approved' || !Sections.Sampling.isAuthorized || Sections.Sampling.isSectionSubmitte))
                        ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop'
                        : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                    "
                  ></div>
                  <Sampling :generalData="generalData" :sectionData="Sections.Sampling" @updateShowFloating="setShowFloatingbutton"></Sampling>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-expansion-panels v-model="optionPanelsOnsite" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header :style="!Sections.Product.isAuthorized ? 'background:#eee' : 'background: #c2e0fd'" @click="auto_submit_item('Product')" auto-id="product tab">
                <v-row no-gutters>
                  <v-col cols="10" class="tab_title">Product</v-col>
                  <v-col cols="2" style="text-align:right;padding-right:10px;">
                    <div style="position: relative;">
                      <div
                        :style="
                          workOrder.status == 'Approved' || !Sections.Product.isAuthorized
                            ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop'
                            : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                        "
                      ></div>
                      <span @click.stop="go_upload_photo('Product', 10, Sections.Product.isSectionSubmitted)" auto-id="product camera">
                        <icon name="camera"></icon>
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div style="position: relative;">
                  <div
                    :style="
                      workOrder.status == 'Approved' || (inspectors.length > 1 && (workOrder.status == 'Approved' || !Sections.Product.isAuthorized || Sections.Product.isSectionSubmitted))
                        ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop'
                        : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                    "
                  ></div>
                  <v-col v-if="inspectors.length > 1" cols="12 " sm="12" md="12" style="text-align:right;">
                    <v-btn :disabled="Sections.Product.isSectionSubmitted" class="submit-btn" @click="submit_item('Product')" small depressed color="#1867c0" auto-id="product submit">Submit</v-btn>
                  </v-col>
                  <div class="text-center d-flex pb-4" style="margin-top: 30px;">
                    <v-btn @click="email(selectedJobDetail.workOrderOwnerEmail)" auto-id="product email">
                      <v-icon>email</v-icon>
                    </v-btn>
                    <v-btn @click="call(selectedJobDetail.workOrderOwnerPhone)" style="margin-left: 10px;" auto-id="product phone">
                      <v-icon>phone</v-icon>
                    </v-btn>
                  </div>

                  <v-row>
                    <v-col cols="6" sm="6" md="6">
                      <v-text-field v-model="workOrder.colors" @change="changeProductColorOrSizeRange()" label="Color(s)" maxLength="100" auto-id="product color"></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6" md="6">
                        <v-text-field v-model="workOrder.sizeRange" @change="changeProductColorOrSizeRange()" label="Size Range" maxLength="100" auto-id="product size range"></v-text-field>
                    </v-col>
                  </v-row>
                  
                  <v-expansion-panels class="mb-6">
                    <v-expansion-panel v-for="(item, index) in workOrderProducts" :key="index">
                      <v-expansion-panel-header expand-icon="mdi-menu-down" :auto-id="item.productDescription">{{ item.productDescription }}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="6" sm="6" md="6">
                            <v-text-field hide-details :value="item.skuDisplay" label="SKU" readonly></v-text-field>
                          </v-col>
                          <v-col cols="5" sm="5" md="5">
                            <v-text-field hide-details :value="item.po" label="PO#" readonly></v-text-field>
                          </v-col>
                          <v-col cols="1" sm="1" md="1">
                            <label style="cursor: pointer;" @click.stop="go_upload_product_photo(item)" :auto-id="'camera'+index">
                              <icon name="camera"></icon>
                            </label>
                          </v-col>
                          <v-col cols="6" sm="6" md="6">
                            <v-text-field :id="'product_category' + index" :value="item.productCategory" label="Product Category" readonly></v-text-field>
                          </v-col>
                          <v-col cols="6" sm="6" md="6">
                              <v-text-field :id="'product_subcategory' + index" :value="item.productSubCategory" label="Product Sub-Category" readonly></v-text-field>
                          </v-col>       
                          <v-col cols="6" sm="6" md="6">
                            <v-text-field hide-details :value="combineModelStyle(item.model, item.style)" label="Style/Model/Item" append-icon="edit" :auto-id="'product style/model/item'+item"></v-text-field>
                          </v-col>
                          <v-col cols="6" sm="6" md="6">
                            <v-text-field hide-details :value="item.color" label="Design/Color(s)" append-icon="edit" :auto-id="'design/color'+index"></v-text-field>
                          </v-col>
                          <v-col cols="3" sm="3" md="3">
                            <v-text-field hide-details :value="item.length" label="Size Range(length)" append-icon="edit" :auto-id="'size/range length'+index"></v-text-field>
                          </v-col>
                          <v-col cols="3" sm="3" md="3">
                            <v-text-field hide-details :value="item.width" label="Size Range(width)" append-icon="edit" :auto-id="'size/range width'+index"></v-text-field>
                          </v-col>
                          <v-col cols="3" sm="3" md="3">
                            <v-text-field hide-details :value="item.height" label="Size Range(height)" append-icon="edit" :auto-id="'size/range height'+index"></v-text-field>
                          </v-col>
                          <v-col cols="3" sm="3" md="3">
                            <v-text-field hide-details :value="item.dimensionalUnit" label="Unit" append-icon="edit" :auto-id="'unit'+index"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header :style="!Sections.StockInspected.isAuthorized ? 'background:#eee' : 'background: #c2e0fd'" @click="auto_submit_item('StockInspected')" auto-id="stock inspected tab">
                <v-row no-gutters>
                  <v-col cols="10" class="tab_title">Stock Inspected</v-col>
                  <v-col cols="2" style="text-align:right;padding-right:10px;">
                    <div style="position: relative;">
                      <div
                        :style="
                          workOrder.status == 'Approved' || !Sections.StockInspected.isAuthorized
                            ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop'
                            : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                        "
                      ></div>
                      <span @click.stop="go_upload_photo('Stock Inspected', 11, Sections.StockInspected.isSectionSubmitted)" auto-id="stock inspected camera">
                        <icon name="camera"></icon>
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div style="position: relative;">
                  <div
                    :style="
                      workOrder.status == 'Approved' || (inspectors.length > 1 && (workOrder.status == 'Approved' || !Sections.StockInspected.isAuthorized || Sections.StockInspected.isSectionSubmitted))
                        ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop'
                        : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                    "
                  ></div>
                  <v-col v-if="inspectors.length > 1" cols="12 " sm="12" md="12" style="text-align:right;">
                    <v-btn :disabled="Sections.StockInspected.isSectionSubmitted" class="submit-btn" @click="submit_item('StockInspected')" small depressed color="#1867c0" auto-id="stock inspected submit">Submit</v-btn>
                  </v-col>
                  <v-card style="margin-top: 30px;">
                    <v-card-title style="padding-bottom:0px;">
                      <div class="select_sort">
                        <span :class="change_sort_type == 'sku' ? 'active' : ''" @click="change_sort('sku')" auto-id="stock sku">SKU</span>
                        <span :class="change_sort_type == 'po' ? 'active' : ''" @click="change_sort('po')" auto-id="stock po#">PO#</span>
                      </div>
                      <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-title style="padding-top:0px;">
                      <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details auto-id="stock inspected search"></v-text-field>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="workOrderProducts" :items-per-page="5" :search="search" auto-id="stock inspected table">
                      <template v-slot:item.menu="{ item }">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on }">
                            <v-btn icon dark v-on="on">
                              <v-icon color="rgba(0,0,0,.87)">menu</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item v-for="(x, index) in stockInspected_menu" :key="index" auto-id="stock inspected menu">
                              <v-list-item-title>
                                <router-link :to="{ name: x.url_name, params: { id: $route.params.id, headerTitle: 'Stock Inspected ' }, query: { id: item.id, headerTitle: 'Stock Inspected ', workOrderId: $route.params.id } }">
                                  {{ x.title }}
                                </router-link>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </v-card>
                  <v-row style="padding-left: 0">
                    <v-col cols="12" sm="12" @click="go_add_defect" class="add_defect_button" auto-id="stock inspected add defect button">
                      <icon name="plus-circle"></icon>
                      <span style="vertical-align: text-bottom;">Add Defect</span>
                    </v-col>
                  </v-row>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header :style="!Sections.RemarksComments.isAuthorized ? 'background:#eee' : 'background: #c2e0fd'" auto-id="remarks and comments tab">
                <v-row no-gutters>
                  <v-col cols="10" class="tab_title">Remarks & Comments</v-col>
                  <v-col cols="2" style="text-align:right;padding-right:10px;">
                    <div style="position: relative;">
                      <div
                        :style="
                          workOrder.status == 'Approved' || !Sections.RemarksComments.isAuthorized
                            ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop'
                            : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                        "
                      ></div>
                      <span @click.stop="go_upload_photo('Remarks and Comments', 12, Sections.RemarksComments.isSectionSubmitted)" auto-id="remarks and comments camera">
                        <icon name="camera"></icon>
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div style="position: relative;">
                  <div
                    :style="
                      workOrder.status == 'Approved' || (inspectors.length > 1 && (workOrder.status == 'Approved' || !Sections.RemarksComments.isAuthorized || Sections.RemarksComments.isSectionSubmitted))
                        ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop'
                        : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                    "
                  ></div>
                  <v-col v-if="inspectors.length > 1" cols="12 " sm="12" md="12" style="text-align:right;">
                    <v-btn :disabled="Sections.RemarksComments.isSectionSubmitted" class="submit-btn" @click="submit_item('RemarksComments')" small depressed color="#1867c0" auto-id="remarks and comments submit">Submit</v-btn>
                  </v-col>
                  <work-order-remarks :generalData="generalData" :isAuthorized="Sections.RemarksComments.isAuthorized"></work-order-remarks>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                :style="
                  !Sections.QuantityVerification.isAuthorized ||
                  !Sections.SizeMeasurement.isAuthorized ||
                  !Sections.PackageCheck.isAuthorized ||
                  !Sections.BarcodeVerification.isAuthorized ||
                  !Sections.Workmanship.isAuthorized ||
                  !Sections.OnsiteTests.isAuthorized
                    ? !Sections.QuantityVerification.isAuthorized &&
                      !Sections.SizeMeasurement.isAuthorized &&
                      !Sections.PackageCheck.isAuthorized &&
                      !Sections.BarcodeVerification.isAuthorized &&
                      !Sections.Workmanship.isAuthorized &&
                      !Sections.OnsiteTests.isAuthorized
                      ? 'background:#eee'
                      : 'background:#c2e0fd'
                    : 'background: #c2e0fd'
                "
                auto-id="inspection summary tab"
              >
                <v-row no-gutters>
                  <v-col cols="10" class="tab_title">Inspection Summary</v-col>
                  <v-col cols="2" style="text-align:right;padding-right:10px;">
                    <div style="position: relative;">
                      <div
                        :style="
                          workOrder.status == 'Approved' ||
                          (!Sections.QuantityVerification.isAuthorized &&
                            !Sections.SizeMeasurement.isAuthorized &&
                            !Sections.PackageCheck.isAuthorized &&
                            !Sections.BarcodeVerification.isAuthorized &&
                            !Sections.Workmanship.isAuthorized &&
                            !Sections.OnsiteTests.isAuthorized)
                            ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                            : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                        "
                      ></div>
                      <span
                        @click.stop="
                          go_upload_photo(
                            'Inspection Summary',
                            16,
                            Sections.QuantityVerification.isSectionSubmitted &&
                              Sections.SizeMeasurement.isSectionSubmitted &&
                              Sections.PackageCheck.isSectionSubmitted &&
                              Sections.BarcodeVerification.isSectionSubmitted &&
                              Sections.Workmanship.isSectionSubmitted &&
                              Sections.OnsiteTests.isSectionSubmitted
                          )
                        "
                        auto-id="inspection summary camera"
                      >
                        <icon name="camera"></icon>
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- Inspection Summary component -->
                <div style="position: relative;">
                  <div
                    :style="
                      workOrder.status == 'Approved' ||
                      (!Sections.QuantityVerification.isAuthorized &&
                        !Sections.SizeMeasurement.isAuthorized &&
                        !Sections.PackageCheck.isAuthorized &&
                        !Sections.BarcodeVerification.isAuthorized &&
                        !Sections.Workmanship.isAuthorized &&
                        !Sections.OnsiteTests.isAuthorized)
                        ? 'background-color: transparent; position: absolute; width: 100%; z-index: 999; height: 100%; cursor: no-drop'
                        : 'background-color: transparent; position: absolute; width: 100%; z-index: 999;'
                    "
                    auto-id="wo inspection summary tab"
                  ></div>
                  <wo-inspection-summary :SectionListValue="getSectionList" :inspectors="inspectors" :sectionData="sectionData"></wo-inspection-summary>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                :style="!Sections.CDF.isAuthorized &&
                      !Sections.SerialNumberSampled.isAuthorized && 
                      !Sections.InspectionEnvironment.isAuthorized && 
                      !Sections.DrawnSamples.isAuthorized && 
                      !Sections.OtherAttachments.isAuthorized ? 'background: #eee' : 'background:#c2e0fd'"
                      auto-id="others tab">
                <v-row no-gutters>
                  <v-col cols="10" class="tab_title">Others</v-col>
                  <v-col cols="2" style="text-align:right;padding-right:10px;">
                    <span @click.stop="go_upload_photo('Others', 17, Sections.CDF.isSectionSubmitted)" auto-id="others camera">
                      <icon name="camera"></icon>
                    </span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div style="position: relative;">
                  <!-- Other component -->
                  <other v-if="generalData.workOrderId" :generalData="generalData"></other>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
            <v-expansion-panel-header auto-id="overall result tab">
              <v-row no-gutters>
                <v-col cols="10" class="tab_title">Overall Result</v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <overall-result v-if="generalData.workOrderId" :workOrder="generalData"></overall-result>
            </v-expansion-panel-content>
          </v-expansion-panel>
          </v-expansion-panels>
        </v-tab-item>
        <div v-if="showFloatingButton">
          <FloatingButton></FloatingButton>
        </div>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import General from "../../components/workorder/General";
import Supplier from "../../components/workorder/Supplier";
import Sampling from "../../components/workorder/Sampling";
import Product from "../../components/workorder/Product";
import StockInspected from "../../components/workorder/StockInspected";
import WorkOrderRemarks from "../../components/workorder/WorkOrderRemarks";
import WoInspectionSummary from "../../components/workorder/WoInspectionSummary";
import Other from "../../components/workorder/Other";
import * as moment from "moment";
import FloatingButton from "../../components/common/FloatingButton";
import apiDetails from "../../shared/request/apiDetails";
import auditLog from "../../shared/request/auditLogs";
import InspectionTime from "../../components/inspection/InspectionTime";
import ReferenceDocuments from "../../components/forms/ReferenceDocuments";
import OverallResult from "@/components/workorder/OverallResult";
import AdditionalInformation from '@/components/workorder/AdditionalInformation.vue';

let state = {
  appHeaderState: {
    show: true,
    title: "Work Order:8911",
    showMenu: true,
    showBack: true,
    showLogo: false,
    showTitle: true,
    showSearch_job: false,
    showSearch_work: false,
    showCalendar: true,
  },
};

function setState(store) {
  store.dispatch("navigation/setAppHeader", state.appHeaderState);
}

export default {
  name: "workOrderDetailsId",
  components: {
    General,
    Supplier,
    Sampling,
    Product,
    StockInspected,
    WorkOrderRemarks,
    WoInspectionSummary,
    Other,
    FloatingButton,
    InspectionTime,
    ReferenceDocuments,
    OverallResult,
    AdditionalInformation
  },
  data() {
    return {
      loadingWod: false,
      showFloatingButton: false,
      // General & Onsite Observation tabs
      tab: "tab-2",
      // Details 默认打开的下拉菜单
      optionPanelsGeneral: [],
      // Onsite 默认打开的下拉菜单
      optionPanelsOnsite: [],
      // Details data
      generalData: {
        clientName: "",
        workOrderDisplayID: "",
        destination: "",
        shipmentQty: "",
        sampleSize: "",
        authority: {},
        hideButton: false,
        resources: [],
      },

      // Stock Inspected 切换控制变量
      change_sort_type: "sku",
      // Stock Inspected 菜单
      stockInspected_menu: [
        { title: "Add Defect", url_name: "workOrderDefectId", id: 1 },
        { title: "Edit", url_name: "workOrderDefectEditId" },
        { title: "Add Defect Photo from Gallery", url_name: "workOrderDefectAddDefectPhotoId" },
        { title: "Add Inspection Photo", url_name: "uploadPhotoInspectionId" },
      ],

      // Inspection & Summary data
      InspectionHeader: [
        { text: "Section", align: "left", sortable: false, value: "section" },
        { text: "Result", align: "center", sortable: false, value: "result" },
        { text: "Findings", align: "center", sortable: false, value: "findings" },
        { text: "", align: "center", sortable: false, value: "showRow" },
      ],
      InspectionList: [
        {
          sectionId: 1,
          section: "Quantity Verification",
          result: "Conformed",
          findings: null,
          showRow: true,
          url_name: "workOrderInspectionQuantityId",
        },
        {
          sectionId: 21,
          section: "Product Conformity",
          result: "Conformed",
          findings: null,
          showRow: true,
          url_name: "workOrderInspectionProductConformityId",
        },
        {
          sectionId: 2,
          section: "Functional Check Measurement",
          result: "Conformed",
          findings: null,
          showRow: true,
          url_name: "workOrderInspectionSizeId",
        },
        {
          sectionId: 3,
          section: "Package Check",
          result: "Conformed",
          findings: null,
          showRow: true,
          url_name: "workOrderInspectionPackageId",
        },
        {
          sectionId: 4,
          section: "Barcode Verification",
          result: "Conformed",
          findings: null,
          showRow: true,
          url_name: "workOrderInspectionBarcodeId",
        },
        {
          sectionId: 5,
          section: "Workmanship",
          result: "Conformed",
          findings: null,
          showRow: true,
          url_name: "workOrderInspectionWorkmanshipId",
        },
        {
          sectionId: 6,
          section: "Onsite Tests",
          result: "Conformed",
          findings: null,
          showRow: true,
          url_name: "workOrderInspectionOnsiteId",
        },
      ],

      // other table
      otherTab1List: [],
      otherTab2List: [],
      otherTab3List: [],

      // stock inspected search
      search: "",
      // stock inspected headers
      headers: [
        { text: "SKU", align: "left", value: "skuDisplay" },
        { text: "PO#", align: "left", value: "po" },
        { text: "Menu", align: "left", sortable: false, value: "menu" },
      ],
      unitOfMeasurements: [],

      // product 联系方式
      selectedJobDetail: {
        workOrderOwnerEmail: "",
        workOrderOwnerPhone: "",
      },

      // Remarks and Comments
      workOrderRemarks: [],

      // others CDF
      // cdf starts at section 7
      cdfSectionList: [
        {
          sectionId: 7,
          section: "CDF Listed Component Verificaton Report",
          result: null,
          findings: null,
          showRow: true,
          url_name: null,
        },
        {
          sectionId: 8,
          section: "Serial Numbers Sampled",
          result: null,
          findings: null,
          showRow: true,
          url_name: null,
        },
        {
          sectionId: 9,
          section: "Sample Carton Number",
          result: null,
          findings: null,
          showRow: true,
          url_name: null,
        },
      ],
      productQuantityVerificationList: [],
      inspectionSummarySections: [],
      samplingToPatch: [],
      showCountry: [],
      workOrder: {},
      workOrderProducts: null,
      // 权限列表
      Sections: {
        InspectionTime: {id: 0, name: "Inspection Time", isAuthorized: false, isSectionSubmitted: false},
        QuantityVerification: { id: 1, name: "Quantity Verification", isAuthorized: false, isSectionSubmitted: false },
        SizeMeasurement: { id: 2, name: "Functional Check Measurement", isAuthorized: false, isSectionSubmitted: false },
        PackageCheck: { id: 3, name: "Package Check", isAuthorized: false, isSectionSubmitted: false },
        BarcodeVerification: { id: 4, name: "Barcode Verification", isAuthorized: false, isSectionSubmitted: false },
        Workmanship: { id: 5, name: "Workmanship", isAuthorized: false, isSectionSubmitted: false },
        OnsiteTests: { id: 6, name: "Onsite Tests", isAuthorized: false, isSectionSubmitted: false },
        General: { id: 7, name: "General", isAuthorized: false, isSectionSubmitted: false },        
        SupplierSite: { id: 8, name: "Supplier/Site", isAuthorized: false, isSectionSubmitted: false },
        Sampling: { id: 9, name: "Sampling", isAuthorized: false, isSectionSubmitted: false },
        Product: { id: 10, name: "Product", isAuthorized: false, isSectionSubmitted: false },
        StockInspected: { id: 11, name: "Stock Inspected", isAuthorized: false, isSectionSubmitted: false },
        RemarksComments: { id: 12, name: "Remarks and Comments", isAuthorized: false, isSectionSubmitted: false },
        CDF: { id: 13, name: "CDF Listed Component Verification Report", isAuthorized: false, isSectionSubmitted: false },
        SerialNumberSampled: { id: 14, name: "Serial Number Sampled", isAuthorized: false, isSectionSubmitted: false },
        SampleCartonNumber: { id: 15, name: "Sample Carton Number", isAuthorized: false, isSectionSubmitted: false },
        OtherAttachments: { id: 20, name: "Other Attachments", isAuthorized: false, isSectionSubmitted: false },
        ProductConformity: { id: 21, name: "Product Conformity", isAuthorized: false, isSectionSubmitted: false },
        InspectionEnvironment: { id: 18, name: "Inspection Environment", isAuthorized: false, isSectionSubmitted: false },
        DrawnSamples: { id: 19, name: "Drawn Samples", isAuthorized: false, isSectionSubmitted: false },
        AdditionalInformation: { id: 25, name: "Additional Information", isAuthorized: false, isSectionSubmitted: false },
      },

      samplingPlan: {
        SKUSamplingList: [
          {
            id: "",
            workOrderID: "",
            productId: "",
            SKUDisplay: "",
            PO: "",
            productQty: 0,
            cartonQty: 0,
            productProRata: 0,
            cartonProRata: 0,
            productSampleSize: 0,
            cartonSampleSize: 0,
            actualProductSampleSize: 0,
            actualCartonSampleSize: 0,
            AC: 0,
            RE: 0,
          },
        ],
        totalProductPOQty: 0,
        totalProductSampleSize: 0,
        totalCartonPOQty: 0,
        totalCartonSampleSize: 0,
      },
      // 当前用户id
      getResourceId: "",
      getSectionList: [],

      // 当前订单所有分配人员
      inspectors: [],
      clientDefectList: [],
      isDraftNav: "false",
      InspectionTimeCount: [],
      fromCanyon:false,
      inspectionTimeForResourceModel: null,
      isAdditionalInformationTrue: false
    };
  },
  // 离开页面触发
  beforeRouteLeave(to, form, next) {
    if (!this.generalData || !this.generalData.workInstruction) {
      next();
      return;
    }

    // General 离开页面保存数据
    var edit2Data = {
      // "Destination": this.generalData.destination,
      ShipmentQty: this.generalData.shipmentQty,
      WorkOrderDestinations: this.generalData.workOrderDestinations.map((x) => {
        x.workOrder = null;
        x.id = null;
        return x;
      }),
    };
    this.utilityFn.getServerData(
      "post",
      this.apiList.workOrderDetailsEdit2 + "/" + this.generalData.workOrderId,
      edit2Data,
      (res) => {},
      (res) => {
        // this.utilityFn.db.transaction((tx) => {
        //   tx.executeSql("DELETE FROM LOGS WHERE url=? AND data=?", [this.apiList.workOrderDetailsEdit2 + "/" + this.generalData.workOrderId, JSON.stringify(edit2Data)]);
        // });
      }
    );

    //Comment this Code Because of the conflict with this Bug 103051
    var data = [
      // General 部分数据
      { op: "replace", path: "/workOrderId", value: this.generalData.workOrderId },
      { op: "replace", path: "/technicalFileAvailable", value: this.generalData.technicalFileAvailable },
      { op: "replace", path: "/technicalFileProvidedBy", value: this.generalData.technicalFileProvidedBy },
      { op: "replace", path: "/refSample", value: this.generalData.refSample },
      { op: "replace", path: "/refSampleProvidedBy", value: this.generalData.refSampleProvidedBy },
      { op: "replace", path:"/approvedSample", value: this.generalData.approvedSample },
      { op: "replace", path:"/approvedSampleBy", value: this.generalData.approvedSampleBy },
      
      // Sampling 部分数据
      { op: "replace", path: "/totalExportCrtns", value: this.generalData.totalExportCrtns },
      { op: "replace", path: "/pkgAvailable", value: this.generalData.pkgAvailable },
      { op: "replace", path: "/crtns", value: this.generalData.crtns },
      { op: "replace", path: "/samplingMethodology", value: this.generalData.samplingMethodology },
      { op: "replace", path: "/samplingPlan", value: this.generalData.samplingPlan },
      { op: "replace", path: "/sampleSize", value: this.generalData.sampleSize },
    ];
    if (
      this.generalData.technicalFileAvailable != null ||
      this.generalData.technicalFileProvidedBy != null ||
      this.generalData.refSample != null ||
      this.generalData.refSampleProvidedBy != null ||
      this.generalData.totalExportCrtns != null ||
      this.generalData.pkgAvailable != null ||
      this.generalData.crtns != null ||
      this.generalData.samplingMethodology != null ||
      this.generalData.samplingPlan != null ||
      this.generalData.approveSample != null ||
      this.generalData.approveSampleBy != null
    ) {
      this.utilityFn.getServerData(
        "patch",
        this.apiList.workOrderDetailsEdit + "/" + this.generalData.workOrderId + "/9",
        data,
        (res) => {},
        (res) => {
          // this.utilityFn.db.transaction((tx) => {
          //   tx.executeSql("DELETE FROM LOGS WHERE url=?", [this.apiList.workOrderDetailsEdit + "/" + this.generalData.workOrderId + "/9"]);
          // });
        }
      );
    }

    // 离线时删除上一次的草稿，保存最新的草稿
    if (window.navigator.onLine != true) {
      // this.utilityFn.db.transaction((tx) => {
      //   tx.executeSql("DELETE FROM draft WHERE displayId=?", [this.generalData.workOrderDisplayId]);
      //   tx.executeSql("INSERT INTO draft (displayId, data, resourceId) VALUES ( ?, ?, ?)", [this.generalData.workOrderDisplayId, JSON.stringify(this.generalData), this.utilityFn.getResourceId()], (tx, results) => {});
      // });
    }

    this.UpsertSampling();
    // if (!this.generalData.hideButton) {
    //     this.submit_item('Product')
    //     this.submit_item('StockInspected')
    //     this.submit_item('RemarksComments')
    //     this.submit_others()
    // }
    next();
  },
  async activated() {
    state.appHeaderState.title = "Work Order:" + this.$route.query.displayId;
    setState(this.$store);
    if (this.InspectionTimeCount.length == 0){
        this.InspectionTimeCount.push(1);
    }
    // this.utilityFn.getServerData("get", this.apiList.workOrderSectionList + "/" + this.workOrderId, {}, (res) => {
    //   this.inspectionSummarySections = res.data.data;
    //   this.initInspectionSummarySections();
    // });
    //this.getWorkOrderDetails();
    // 默认选择general
    //this.setDefaultLandingSectionAndTab();

    //this.setLoggingInfo();
    await this.loadSections();
  },

  mounted() {

    this.getAndSetCompanySubscriptionByWorkOrderId();
    
    // this.utilityFn.db.transaction((tx) => {
    //   tx.executeSql("SELECT * FROM downloadList Where resourceId=?", [this.utilityFn.getResourceId()], (tx, results) => {
    //     const len = results.rows.length;
    //     this.$store.dispatch("inspection/setDownloadNumber", len);
    //   });
    // });
    // 设置头部标题
    state.appHeaderState.title = "Work Order:" + this.$route.query.displayId;
    // 获取订单id
    this.workOrderId = this.$route.params.id;
    // 获取当前用户 ResourceId

    this.getResourceId = this.utilityFn.getResourceId();

    // 获取Remarks & Comments 表格数据
    this.utilityFn.getServerData("get", this.apiList.getWorkOrderRemarks + "/" + this.$route.params.id, {}, (result) => {
      if (result.data) {
        this.workOrderRemarks = result.data.data.map((rem, i) => {
          rem.index = 1 + i++;
          return rem;
        });
      }
    });

    //clear required fields as this does not apply on this form
    this.$store.dispatch("inspection/setFormAtomsList", []);

    this.utilityFn.getServerData("get", this.apiList.getUnitsOfMeasurement, {}, (res) => {
      this.unitOfMeasurements = res.data.data;
    });

    this.utilityFn.getServerData("get", this.apiList.workOrderSectionList + "/" + this.workOrderId, {}, (res) => {
      this.inspectionSummarySections = res.data.data;
      this.initInspectionSummarySections();
    });

    // 获取wo详情数据

    //if (window.navigator.onLine == true) {
    this.getWorkOrderDetails();
    /*  if(this.isDraftNav  === 'false') {  
      this.getWorkOrderDetails();
    } else {
       this.getDraftData();
    } */

    // 默认打开Product

    // // 如果是Edit跳转则默认打开 Stock Inspected
    // if (this.$route.params.edit) {
    //   this.tab = "tab-2";
    //   this.optionPanelsOnsite = 1;
    // }

    this.setDefaultLandingSectionAndTab();

    this.getListofInspectionSections();

    this.setLoggingInfo();
    if (this.$route.query.fromCanyon !== undefined){
      localStorage.setItem('fromCanyon',true);
      this.fromCanyon = true;
    }


  },
  methods: {
    getDraftData() {
      // this.utilityFn.db.transaction((tx) => {
      //   tx.executeSql("SELECT * FROM draft WHERE displayId=" + this.$route.query.displayId, [], (tx, results) => {
      //     if (results.rows.length == 0) {
      //       this.getWorkOrderDetails();
      //     } else {
      //       this.workOrder = JSON.parse(results.rows.item(0).data);

      //       this.$store.dispatch("inspection/setWorkOrderDetails", this.workOrder);
      //       // If work instruction is still null, redirect to the assignment/workinstruction page
      //       if (!this.workOrder.workInstruction) {
      //         this.$router.push({ name: "assignmentId", params: { id: this.workOrder.workOrderId, workOrderDisplayId: this.workOrder.workOrderDisplayId, isDynamic: !!this.workOrder.formId, workInstruction: this.workOrder.workInstruction } });
      //         return;
      //       }

      //       this.inspectors = this.workOrder.resources;
      //       this.getSectionList = this.workOrder.workOrderInspectionSections;

      //       this.generalData = this.workOrder; // .map((x) => { x.technicalFileAvailable=x.technicalFileAvailable ? 'Yes':'No'; return x;});

      //       this.workOrderProducts = this.workOrder.products;
      //       this.otherTab1List = this.workOrder.workOrderCDFs.map((x, i) => {
      //         x.no = 1 + i++;
      //         return x;
      //       });
      //       this.otherTab2List = this.workOrder.workOrderSerialNumbersSampled;
      //       this.otherTab3List = this.workOrder.workOrderSampledCartonNumbers;
      //       this.selectedJobDetail.workOrderOwnerEmail = this.workOrder.workOrderOwnerEmail;
      //       this.selectedJobDetail.workOrderOwnerPhone = this.workOrder.workOrderOwnerPhone;

      //       this.generalData.hideButton = this.workOrder.resources.length === 1 ? false : true;

      //       this.Sections.hideButton = this.workOrder.resources.length === 1 ? false : true;

      //       this.generalData.orderQty = this.workOrder.products.reduce(function(tot, arr) {
      //         return tot + arr.qty;
      //       }, 0);

      //       this.apiFn.workOrderDetails = this.workOrder;
      //       this.loadSamplingSize();
      //       //commenting below cause will be called only offline and when from draft mode
      //       // this.utilityFn.getServerData("get", this.apiList.getGeoCountires, {}, (result) => {
      //       //   this.showCountry = result.data.data;
      //       // });

      //       this.$store.dispatch("inspection/setWorkOrderDetails", this.workOrder);
      //       this.loadSections();
      //     }
      //   });
      // });
    },
    getWorkOrderDetails() {
      this.loadingWod = true;
      this.utilityFn.getServerData("get", this.apiList.workOrderDetailsById + "/" + this.$route.params.id, {}, (result) => {
        this.loadingWod = false;
        this.workOrder = result.data.data;
        this.$store.dispatch("inspection/setWorkOrderDetails", this.workOrder);
        this.$store.dispatch("inspection/setCurrentActiveWorkOrder", this.workOrder);
        this.$store.dispatch("inspection/setInspectionSummaryResult", this.workOrder.workOrderInspectionSections);

        // If work instruction is still null, redirect to the assignment/workinstruction page
        if (!this.workOrder.workInstruction) {
          this.$router.push({ name: "assignmentId", params: { id: this.workOrder.workOrderId, workOrderDisplayId: this.workOrder.workOrderDisplayId, isDynamic: !!this.workOrder.formId, workInstruction: this.workOrder.workInstruction } });
          return;
        }

        this.inspectors = this.workOrder.resources;
        this.getSectionList = this.workOrder.workOrderInspectionSections;
        this.generalData = this.workOrder; // .map((x) => { x.technicalFileAvailable=x.technicalFileAvailable ? 'Yes':'No'; return x;});

        this.workOrderProducts = this.workOrder.products;
        this.otherTab1List = this.workOrder.workOrderCDFs.map((x, i) => {
          x.no = 1 + i++;
          return x;
        });
        this.otherTab2List = this.workOrder.workOrderSerialNumbersSampled;
        this.otherTab3List = this.workOrder.workOrderSampledCartonNumbers;
        this.selectedJobDetail.workOrderOwnerEmail = this.workOrder.workOrderOwnerEmail;
        this.selectedJobDetail.workOrderOwnerPhone = this.workOrder.workOrderOwnerPhone;

        this.$store.dispatch("inspection/setClientDefectsLoadingStatus", 0);
        this.utilityFn.getServerData("get", this.apiList.getClientDefectList + "/" + this.workOrder.clientId, { dclTypeId: null, isActive: true }, (result) => {
          this.clientDefectList = result.data.data;
          this.$store.dispatch("inspection/setClientDefectList", this.clientDefectList);
          this.$store.dispatch("inspection/setClientDefectsLoadingStatus", 1);
        });

        this.generalData.hideButton =  this.workOrder.resources.length === 1 ? false : true;

        this.Sections.hideButton =  this.workOrder.resources.length === 1 ? false : true;

        this.generalData.orderQty = this.workOrder.products.reduce(function(tot, arr) {
          return tot + arr.qty;
        }, 0);

        this.apiFn.workOrderDetails = this.workOrder;
        this.skuDisplayList =  this.workOrder.products.map((x) => {
          return {
            workOrderProductId: x.id,
            skuDisplay: x.skuDisplay,
            po: x.po,
            cartonQty: x.cartonQty,
            qty: x.qty,
            caseCountProduct: x.casePackage,
            productID: x.productID,
          };
        });
        this.initQuantityVerification();

        this.utilityFn.getServerData("get", this.apiList.getGeoCountires, {}, (result) => {
          this.showCountry = result.data.data;
        });

      
        this.loadSections();
        this.optionPanelsOnsite = [3,5];
        state.appHeaderState.showSubmitFormButton = this.workOrder.status === "Rework";
        setState(this.$store);
      });
    },
    getListofInspectionSections() {
      this.utilityFn.getServerData("get", this.apiList.workOrderSectionList + "/" + this.$route.params.id, {}, (result) => {
        this.inspectionSummarySections = result.data.data;
        this.initInspectionSummarySections();
      });
    },
    auto_submit_item(sectionName) {
      if (this.workOrder && this.workOrder.resources.length === 1) {
        this.submit_item(sectionName);
      }
    },
    submit_item(sectionName) {
      const section = {
        sectionId: this.Sections[sectionName].id,
        workOrderId: this.workOrderId,
        submitterId: this.getResourceId,
      };
      this.utilityFn.getServerData(
        "post",
        this.apiList.submitInspectionSection,
        section,
        (res) => {
          this.Sections[sectionName].isSectionSubmitted = true;
          this.$store.dispatch("inspection/setSectionData", this.Sections);
        },
        (res) => {
          // this.utilityFn.db.transaction((tx) => {
          //   tx.executeSql("DELETE FROM LOGS WHERE url=? AND data=?", [this.apiList.submitInspectionSection, JSON.stringify(section)]);
          // });
        }
      );
    },
    submit_others() {
      [13, 14, 15].forEach((sectionId) => {
        const section = {
          sectionId: sectionId,
          workOrderId: this.$route.params.id,
          submitterId: this.getResourceId,
        };
        this.utilityFn.getServerData(
          "post",
          this.apiList.submitInspectionSection,
          section,
          (res) => {
            this.Sections.CDF.isSectionSubmitted = true;
            this.Sections.SerialNumberSampled.isSectionSubmitted = true;
            this.Sections.SampleCartonNumber.isSectionSubmitted = true;
            this.$store.dispatch("inspection/setSectionData", this.Sections);
          },
          (res) => {
            // this.utilityFn.db.transaction((tx) => {
            //   tx.executeSql("DELETE FROM LOGS WHERE url=? AND data=?", [this.apiList.submitInspectionSection, JSON.stringify(section)]);
            // });
          }
        );
      });
    },

    call(number) {
      window.location.href = "tel:" + number;
    },
    email(address) {
      window.location.href = "mailto:" + address;
    },
    go_add_defect() {
      this.$router.push({ name: "workOrderDefectId", params: { id: this.$route.params.id } });
    },
    change_sort(type) {
      this.headers.splice(1, 1);
      this.change_sort_type = type;
      if (type == "sku") {
        this.headers.unshift({ text: "SKU", align: "left", value: "skuDisplay" });
      } else {
        this.headers.unshift({ text: "PO#", align: "left", value: "po" });
      }
    },
    go_upload_photo(type, sectionid, Sections) {
      if (!Sections || this.workOrder.resources.length == 1) {
        switch (type) {
          case "Supplier":
            this.$router.push({ name: "uploadPhotoGeneralId", params: { id: this.$route.params.id } });
            break;
          default:
            this.$router.push({ name: "uploadPhotoGalleryId", params: { id: this.$route.params.id, name: type, sectionId: sectionid } });
            break;
        }
      }
    },
    go_upload_product_photo(item) {
      this.$router.push({
        name: "uploadPhotoGalleryId",
        params: { id: this.$route.params.id },
        query: { workOrderProductId: item.id, uploadType: "ProductUpload", name: item.skuDisplay, sectionId: this.Sections.Product.id, productName: item.productDescription },
      });
    },
    go_remark(remarkId) {
      this.$router.push({ name: "workOrderRemarksId", params: { id: this.workOrder.workOrderId }, query: { remarkId: remarkId } });
    },

    initInspectionSummarySections() {
      for (let i = 0; i < this.InspectionList.length; i++) {
        const index = this.inspectionSummarySections.findIndex((x) => x.sectionId == this.InspectionList[i].sectionId);
        if (index !== -1) {
          const section = this.inspectionSummarySections[index];
          this.InspectionList[i].showRow = !section.isHidden;
        }
      }
      for (let i = 0; i < this.cdfSectionList.length; i++) {
        const index = this.inspectionSummarySections.findIndex((x) => x.sectionId == this.cdfSectionList[i].sectionId);
        if (index !== -1) {
          const section = this.inspectionSummarySections[index];
          this.cdfSectionList[i].showRow = !section.isHidden;
        }
      }
    },

    initQuantityVerification() {
      let existingQuantityVerificationList = [];
      this.workOrderProducts.forEach((x) => {
        if (x.workOrderProductQuantityVerificationResults) {
          if (x.workOrderProductQuantityVerificationResults.length > 0) {
            x.workOrderProductQuantityVerificationResults.forEach((item) => {
              existingQuantityVerificationList.push(item);
            });
          }
        }
      });
      if (existingQuantityVerificationList.length > 0) {
        this.loadQuantityVerificationProductDetails(existingQuantityVerificationList);
      }
      
      this.loadSamplingSize();
    },

    loadQuantityVerificationProductDetails(quantityVerificationProductDetails) {
      this.productQuantityVerificationList = [];
      if (quantityVerificationProductDetails) {
        if (quantityVerificationProductDetails.length > 0) {
          let quantityVerificationList = [];
          quantityVerificationProductDetails.forEach((res) => {
            const quantityVerification = {
              id: res.id,
              workOrderProductId: res.workOrderProductId,
              productID: this.getSKUDisplay(res.workOrderProductId).productID,
              skuDisplay: this.getSKUDisplay(res.workOrderProductId).skuDisplay,
              po: this.getSKUDisplay(res.workOrderProductId).po,
              cartonQty: this.getSKUDisplay(res.workOrderProductId).cartonQty,
              qty: this.getSKUDisplay(res.workOrderProductId).qty,
              caseCountProduct: res.caseCountProduct,
              availableQuantityCarton: res.availableQuantityCarton,
              sampleSizeCarton: res.sampleSizeCarton,
              sampleSizeCarton2: res.sampleSizeCarton,
              sampleSizeCartonTotal: res.sampleSizeCartonTotal,
              excessShortQuantity: res.excessShortQuantity,
              sampleSizeProduct: res.sampleSizeProduct,
              sampleSizeProduct2: res.sampleSizeProduct,
              sampleSizeProductTotal: res.sampleSizeProductTotal,
              exportCartonNumbersOpened: res.exportCartonNumbersOpened,
              remarks: res.remarks,
              packedQty: res.packedQty,
              underPackingQty: res.underPackingQty,
              underProductionFinishingQty: res.underProductionFinishingQty,
              availableQuantityProduct: res.availableQuantityProduct,
              orderNumber: res.orderNumber,
            };
            this.productQuantityVerificationList.push(quantityVerification);
          });
        }
      }

    },
    getSKUDisplay(productId) {
      if (!productId || productId == "" || productId == null) {
        return null;
      }

      let skuDisplayData = this.skuDisplayList.find((x) => x.workOrderProductId === productId);
      return skuDisplayData;
    },

    loadSamplingSize() {
      let changedSamplingMethodology = false
       if(this.workOrderProducts.length == 1 &&  this.generalData.samplingMethodology == null){
        this.generalData.samplingMethodology = "Separated Sample Size";
        changedSamplingMethodology = true
      }
      else if(this.workOrderProducts.length > 1 && this.generalData.samplingMethodology == null){
        this.generalData.samplingMethodology = "Combined Sample Size";
        changedSamplingMethodology = true
      }
      
      let inspectionLevel = this.generalData.quantityVerification == null ? this.generalData.aqlLevels : this.generalData.quantityVerification.productInspectionLevel;
      let samplingPlan1 = this.generalData.inspectionLevel;
      let round = this.generalData.samplingPlan;
      let productType = this.generalData.productType;

      

      let workorderSamplingParameters = {
        samplingMethodology : this.generalData.samplingMethodology,
        inspectionLevel : inspectionLevel,
        samplingPlan1 : samplingPlan1,
        round : round,
        productType : productType,
        aqlMinor:this.generalData.aqlMinor,
        aqlMajor: this.generalData.aqlMajor,
        aqlCritical: this.generalData.aqlCritical,
        workorderId : this.generalData.workOrderId,
        changedSamplingMethodology: changedSamplingMethodology
      }

      this.samplingPlan.SKUSamplingList = this.workOrderProducts.map((x) => {
        return {
          id: x.id,
          workOrderID: x.workOrderID,
          productId: x.productID,
          SKUDisplay: x.skuDisplay,
          PO: x.po,
          productQty: this.getTotalAvailableQuantityProduct(x.id),
          cartonQty: this.getTotalAvailableQuantityCarton(x.id),
          productSampleSize: 0,
          cartonSampleSize: 0,
          actualProductSampleSize: 0,
          actualCartonSampleSize: 0,
          aqlMinor: this.generalData.aqlMinor,
          aqlMajor: this.generalData.aqlMajor,
          aqlCritical: this.generalData.aqlCritical,
          minorAC: 0,
          minorRE: 0,
          majorAC: 0,
          majorRE: 0,
          criticalAC: 0,
          criticalRE: 0,
        };
      });

      let returnedSampleSizePlan = this.samPlan.getAutoComputeSampleSize(this.generalData.samplingMethodology, this.samplingPlan.SKUSamplingList, inspectionLevel, samplingPlan1, round, productType, "General", false);
      let samplinPlan = {...workorderSamplingParameters, ...returnedSampleSizePlan};
      this.$store.dispatch("inspection/setSamplingPlanList", samplinPlan)     
        var data = [
          {
            op: "replace",
            path: "/sampleSize",
            value: samplinPlan.totalProductSampleSize
          },
          {
            op: "replace",
            path: "/workmanshipSampleSize",
            value: samplinPlan.totalProductSampleSize
          },
          {
            op: "replace",
            path: "/samplingMethodology",
            value: samplinPlan.samplingMethodology,
          },
        ];

        this.utilityFn.getServerData("patch", this.apiList.patchWorkOrderResults + "/" + this.generalData.workOrderId  + "/1", data, (res) => {});  


      return returnedSampleSizePlan;
    },

    getTotalAvailableQuantityProduct(workOrderProductId) {
      let totalProduct = null;
      let productofackedQtyAndUnderPackingQty = this.productQuantityVerificationList.reduce(function(tot, arr) {
        if (arr.workOrderProductId == workOrderProductId && arr.availableQuantityCarton == null && arr.caseCountProduct == null && arr.underPackingQty == null) {
          return totalProduct;
        }
        if (arr.workOrderProductId == workOrderProductId && arr.availableQuantityCarton == -1) {
          return (totalProduct = -1);
        }

        if (arr.workOrderProductId == workOrderProductId && (arr.availableQuantityCarton != null || arr.caseCountProduct != null || arr.underPackingQty != null)) {
          if (arr.packedQty == null && arr.availableQuantityProduct != null) {
            totalProduct += Number(arr.availableQuantityProduct);
          } else {
            totalProduct += Number(arr.packedQty) + Number(arr.underPackingQty == -1 ? 0 : arr.underPackingQty);
          }
        }
        return totalProduct;
      }, null);

      return productofackedQtyAndUnderPackingQty;
    },

    getTotalAvailableQuantityCarton(workOrderProductId) {
      let total = null;
      let sumAvailableQuantityCarton = this.productQuantityVerificationList.reduce(function(tot, arr) {
        if (arr.workOrderProductId == workOrderProductId) {
          if (arr.availableQuantityCarton == null) {
            return total;
          }
          total += Number(arr.availableQuantityCarton);
        }
        return total;
      }, null);
      return sumAvailableQuantityCarton;
    },

    // 更新数据
    UpsertSampling() {
      // this.updateValue()
      // this.samplingToPatch.push({
      //     workOrderId: this.generalData.workOrderId,
      //     totalExportCrtns: this.generalData.totalExportCrtns,
      //     pkgAvailable: this.generalData.pkgAvailable,
      //     crtns: this.generalData.crtns,
      //     pkgInspected: this.generalData.pkgInspected,
      //     technicalFileAvailable: this.generalData.technicalFileAvailable,
      //     technicalFileProvidedBy: this.generalData.technicalFileProvidedBy,
      //     refSample: this.generalData.refSample,
      //     refSampleProvidedBy: this.generalData.refSampleProvidedBy,
      //     samplingMethodology: this.generalData.samplingMethodology,
      //     samplingPlan: this.generalData.samplingPlan,
      //     sampleSize: this.generalData.sampleSize
      // })
      // this.utilityFn.getServerData('patch', this.apiList.patchWorkOrderResults + '/' + this.samplingToPatch[0].workOrderId + '/9', [
      //     { "op": "replace", "path": "/workOrderId", "value":  this.samplingToPatch[0].workOrderId},
      //     { "op": "replace", "path": "/totalExportCrtns", "value":  this.samplingToPatch[0].totalExportCrtns},
      //     { "op": "replace", "path": "/pkgAvailable", "value": this.samplingToPatch[0].pkgAvailable },
      //     { "op": "replace", "path": "/crtns", "value": this.samplingToPatch[0].crtns },
      //     { "op": "replace", "path": "/pkgInspected", "value":  this.samplingToPatch[0].pkgInspected},
      //     { "op": "replace", "path": "/technicalFileAvailable", "value": this.samplingToPatch[0].technicalFileAvailable },
      //     { "op": "replace", "path": "/technicalFileProvidedBy", "value": this.samplingToPatch[0].technicalFileProvidedBy },
      //     { "op": "replace", "path": "/refSample", "value": this.samplingToPatch[0].refSample },
      //     { "op": "replace", "path": "/refSampleProvidedBy", "value": this.samplingToPatch[0].refSampleProvidedBy },
      //     { "op": "replace", "path": "/samplingMethodology", "value": this.samplingToPatch[0].samplingMethodology },
      //     { "op": "replace", "path": "/samplingPlan", "value": this.samplingToPatch[0].samplingPlan },
      //     { "op": "replace", "path": "/sampleSize", "value": this.samplingToPatch[0].sampleSize }
      // ], res => {
      //     this.samplingToPatch = []
      // });
    },
    combineModelStyle(model, style) {
      let tempStyle = style == null ? "" : style;
      let tempModel = model == null ? "" : model;
      return tempStyle + " " + tempModel;
    },

    // 更新当前选项卡的值
    updateValue() {
      // 如果为离线则不更新,因为无法及时获取到数据
      if (window.navigator.onLine == true) {
        if (
          (this.generalData.destination !== null && this.generalData.destination !== "" && this.generalData.destination !== Object) ||
          (this.generalData.shipmentQty !== null && this.generalData.shipmentQty !== "" && this.generalData.shipmentQty !== Object) ||
          (this.generalData.sampleSize !== null && this.generalData.sampleSize !== "" && this.generalData.sampleSize !== Object)
        ) {
          this.utilityFn.getServerData(
            "patch",
            this.apiList.patchWorkOrderResults + "/" + this.generalData.workOrderId + "/" + this.Sections.General.id,
            [
              { op: "replace", path: "/workOrderId", value: this.generalData.workOrderId },
              { op: "replace", path: "/totalExportCrtns", value: this.generalData.totalExportCrtns },
              { op: "replace", path: "/pkgAvailable", value: this.generalData.pkgAvailable },
              { op: "replace", path: "/crtns", value: this.generalData.crtns },
              { op: "replace", path: "/samplingMethodology", value: this.generalData.samplingMethodology },
              { op: "replace", path: "/samplingPlan", value: this.generalData.samplingPlan },
              { op: "replace", path: "/sampleSize", value: this.generalData.sampleSize },
            ],
            (res) => {
              this.utilityFn.getServerData(
                "post",
                this.apiList.upsertWorkOrderDataCaptureFields + "/" + this.generalData.workOrderId,
                {
                  Destination: this.generalData.destination,
                  ShipmentQty: this.generalData.shipmentQty,
                },
                (res2) => {}
              );
            }
          );
        }
      }
    },
    // 设置所有权限
    async loadSections() {
      this.Sections.QuantityVerification.isAuthorized = this.isSectionAuthorized(this.Sections.QuantityVerification.id);
      this.Sections.SizeMeasurement.isAuthorized = this.isSectionAuthorized(this.Sections.SizeMeasurement.id);
      this.Sections.PackageCheck.isAuthorized = this.isSectionAuthorized(this.Sections.PackageCheck.id);
      this.Sections.BarcodeVerification.isAuthorized = this.isSectionAuthorized(this.Sections.BarcodeVerification.id);
      this.Sections.Workmanship.isAuthorized = this.isSectionAuthorized(this.Sections.Workmanship.id);
      this.Sections.OnsiteTests.isAuthorized = this.isSectionAuthorized(this.Sections.OnsiteTests.id);
      this.Sections.General.isAuthorized = this.isSectionAuthorized(this.Sections.General.id);
      this.Sections.AdditionalInformation.isAuthorized = this.isSectionAuthorized(this.Sections.AdditionalInformation.id);
      this.Sections.SupplierSite.isAuthorized = this.isSectionAuthorized(this.Sections.SupplierSite.id);
      this.Sections.Sampling.isAuthorized = this.isSectionAuthorized(this.Sections.Sampling.id);
      this.Sections.Product.isAuthorized = this.isSectionAuthorized(this.Sections.Product.id);
      this.Sections.StockInspected.isAuthorized = this.isSectionAuthorized(this.Sections.StockInspected.id);
      this.Sections.RemarksComments.isAuthorized = this.isSectionAuthorized(this.Sections.RemarksComments.id);
      this.Sections.CDF.isAuthorized = this.isSectionAuthorized(this.Sections.CDF.id);
      this.Sections.SerialNumberSampled.isAuthorized = this.isSectionAuthorized(this.Sections.SerialNumberSampled.id);
      this.Sections.SampleCartonNumber.isAuthorized = this.isSectionAuthorized(this.Sections.SampleCartonNumber.id);
      this.Sections.OtherAttachments.isAuthorized = this.isSectionAuthorized(this.Sections.OtherAttachments.id);
      this.Sections.DrawnSamples.isAuthorized = this.isSectionAuthorized(this.Sections.DrawnSamples.id);
      this.Sections.InspectionEnvironment.isAuthorized = this.isSectionAuthorized(this.Sections.InspectionEnvironment.id);

      this.Sections.QuantityVerification.isSectionSubmitted = this.isSectionSubmitted(this.Sections.QuantityVerification.id);
      this.Sections.SizeMeasurement.isSectionSubmitted = this.isSectionSubmitted(this.Sections.SizeMeasurement.id);
      this.Sections.PackageCheck.isSectionSubmitted = this.isSectionSubmitted(this.Sections.PackageCheck.id);
      this.Sections.BarcodeVerification.isSectionSubmitted = this.isSectionSubmitted(this.Sections.BarcodeVerification.id);
      this.Sections.Workmanship.isSectionSubmitted = this.isSectionSubmitted(this.Sections.Workmanship.id);
      this.Sections.OnsiteTests.isSectionSubmitted = this.isSectionSubmitted(this.Sections.OnsiteTests.id);
      this.Sections.General.isSectionSubmitted = this.isSectionSubmitted(this.Sections.General.id);
      this.Sections.AdditionalInformation.isSectionSubmitted = this.isSectionSubmitted(this.Sections.AdditionalInformation.id);
      this.Sections.SupplierSite.isSectionSubmitted = this.isSectionSubmitted(this.Sections.SupplierSite.id);
      this.Sections.Sampling.isSectionSubmitted = this.isSectionSubmitted(this.Sections.Sampling.id);
      this.Sections.Product.isSectionSubmitted = this.isSectionSubmitted(this.Sections.Product.id);
      this.Sections.StockInspected.isSectionSubmitted = this.isSectionSubmitted(this.Sections.StockInspected.id);
      this.Sections.RemarksComments.isSectionSubmitted = this.isSectionSubmitted(this.Sections.RemarksComments.id);
      this.Sections.CDF.isSectionSubmitted = this.isSectionSubmitted(this.Sections.CDF.id);
      this.Sections.SerialNumberSampled.isSectionSubmitted = this.isSectionSubmitted(this.Sections.SerialNumberSampled.id);
      this.Sections.SampleCartonNumber.isSectionSubmitted = this.isSectionSubmitted(this.Sections.SampleCartonNumber.id);
      this.$store.dispatch("inspection/setSectionData", this.Sections);
      return true;
    },
    isSectionAuthorized(sectionId) {
      if (!this.workOrder) return false;

      if (this.workOrder.workOrderInspectionSections){
        const index = this.workOrder.workOrderInspectionSections.findIndex((x) => x.sectionId == sectionId && x.resourceId?.toLowerCase() == this.utilityFn.getResourceId().toLowerCase());
        return index != -1 || this.workOrder.resources.length === 1;
      }

      return false;

    },
    isSectionSubmitted(sectionId) {

      if (!this.workOrder) return false;
      if (this.workOrder.workOrderInspectionSections){
        const index = this.workOrder.workOrderInspectionSections.findIndex((x) => x.sectionId == sectionId);
        return index != -1 && this.workOrder.workOrderInspectionSections[index].submittedDate != null;
      }
      return false;
    },
    // loadSamplingSize(data) {

      

    //     this.samplingPlan.SKUSamplingList = data.products.map(x => {
    //         return {
    //             id: x.id,
    //             workOrderID: x.workOrderID,
    //             productId: x.productID,
    //             SKUDisplay: x.skuDisplay,
    //             PO: x.po,
    //             productQty:x.workOrderProductResults.availQtyPrd,
    //             cartonQty:  x.workOrderProductResults.availQtyCrtn,
    //             productSampleSize: x.workOrderProductResults.sampleSizePrd,
    //             cartonSampleSize: x.workOrderProductResults.sampleSizeCrtn,
    //             actualProductSampleSize: 0,
    //             actualCartonSampleSize: 0,
    //             aqlMinor: data.aqlMinor,
    //             aqlMajor: data.aqlMajor,
    //             aqlCritical: data.aqlCritical,
    //             minorAC: 0,
    //             minorRE: 0,
    //             majorAC: 0,
    //             majorRE: 0,
    //             criticalAC: 0,
    //             criticalRE: 0,
    //         }
    //     });

    //     let returnedSampleSizePlan = this.samPlan.getAutoComputeSampleSize(this.generalData.samplingMethodology, this.samplingPlan.SKUSamplingList, this.generalData.aqlLevels, this.generalData.inspectionLevel, this.generalData.samplingPlan, this.generalData.productType, "Details", false)
    //     this.showFloatingButton = returnedSampleSizePlan == undefined ? false : returnedSampleSizePlan.showFabButton == false ? false : true;
    //     this.generalData.sampleSize = returnedSampleSizePlan.totalProductSampleSize

    //     this.upsertWorkOrderProductResultSampling(returnedSampleSizePlan.SKUSamplingList)
    // },

    upsertWorkOrderProductResultSampling(SKUSamplingList) {
      if (this.samplingPlan.SKUSamplingList.length <= 1) {
        let workOrderProductResults = this.generalData.products[0].workOrderProductResults;
        SKUSamplingList.forEach((element) => {
          let workOrderProductID = element.id;
          let sampleSizePrd = workOrderProductResults.sampleSizePrd == null ? (element.productSampleSize == undefined ? null : element.productSampleSize) : workOrderProductResults.sampleSizePrd;
          let sampleSizeProductWorkmanship = workOrderProductResults.sampleSizeProductWorkmanship == null ? (element.productSampleSize == undefined ? null : element.productSampleSize) : workOrderProductResults.sampleSizeProductWorkmanship;
          let sampleSizeProductSizeMeasurement =
            workOrderProductResults.sampleSizeProductSizeMeasurement == null ? (element.productSampleSize == undefined ? null : element.productSampleSize) : workOrderProductResults.sampleSizeProductSizeMeasurement;
          let sampleSizeProductWeightMeasurement =
            workOrderProductResults.sampleSizeProductWeightMeasurement == null ? (element.productSampleSize == undefined ? null : element.productSampleSize) : workOrderProductResults.sampleSizeProductWeightMeasurement;
          let sampleSizeCrtn = workOrderProductResults.sampleSizeCrtn == null ? (element.cartonSampleSize == undefined ? null : element.cartonSampleSize) : workOrderProductResults.sampleSizeCrtn;
          let sampleSizeCartonSizeMeasurement = workOrderProductResults.sampleSizeCartonSizeMeasurement == null ? (element.cartonSampleSize == undefined ? null : element.cartonSampleSize) : workOrderProductResults.sampleSizeCartonSizeMeasurement;
          let samlpleSizeCartonWeightMeasurement =
            workOrderProductResults.samlpleSizeCartonWeightMeasurement == null ? (element.cartonSampleSize == undefined ? null : element.cartonSampleSize) : workOrderProductResults.samlpleSizeCartonWeightMeasurement;
          let allowedMinorPO = workOrderProductResults.allowedMinorPO == null ? (element.minorAC == undefined ? null : element.minorAC) : workOrderProductResults.allowedMinorPO;
          let allowedMajorPO = workOrderProductResults.allowedMajorPO == null ? (element.majorAC == undefined ? null : element.majorAC) : workOrderProductResults.allowedMajorPO;
          let allowedCriticalPO = workOrderProductResults.allowedCriticalPO == null ? (element.criticalAC == undefined ? null : element.criticalAC) : workOrderProductResults.allowedCriticalPO;

          const formPatch = [
            { op: "replace", path: "/sampleSizePrd", value: sampleSizePrd },
            { op: "replace", path: "/sampleSizeProductWorkmanship", value: sampleSizeProductWorkmanship },
            { op: "replace", path: "/sampleSizeProductSizeMeasurement", value: sampleSizeProductSizeMeasurement },
            { op: "replace", path: "/sampleSizeProductWeightMeasurement", value: sampleSizeProductWeightMeasurement },
            { op: "replace", path: "/sampleSizeCrtn", value: sampleSizeCrtn },
            { op: "replace", path: "/sampleSizeCartonSizeMeasurement", value: sampleSizeCartonSizeMeasurement },
            { op: "replace", path: "/samlpleSizeCartonWeightMeasurement", value: samlpleSizeCartonWeightMeasurement },
            { op: "replace", path: "/allowedMinorPO", value: allowedMinorPO },
            { op: "replace", path: "/allowedMajorPO", value: allowedMajorPO },
            { op: "replace", path: "/allowedCriticalPO", value: allowedCriticalPO },
          ];

          this.utilityFn.getServerData("get", this.apiList.getUnitsOfMeasurement, {}, (res) => {
            this.unitOfMeasurements = res.data.data;
          });
        });
      }
    },

    setShowFloatingbutton(isShow) {
      this.showFloatingButton = isShow;
    },

    setLoggingInfo() {
      window.sessionStorage.setItem("EditDataPage", "WorkOrderDetails");
      window.sessionStorage.setItem("transactionCode", auditLog.getUniqueIdentifier());
    },

    setDefaultLandingSectionAndTab() {
      const beforeRouteName = window.sessionStorage.getItem("beforeRouteName");
      if (beforeRouteName === "workOrderId" || beforeRouteName === "assignmentId" || beforeRouteName === "index") {
        this.tab = "tab-1";
        this.optionPanelsGeneral = [];
      }
    },
     updateInspectionTimeCount(count,isDelete){
      if (isDelete == 'delete'){
        this.InspectionTimeCount.splice(this.InspectionTimeCount.indexOf(count),1);
      }
      else{
        this.InspectionTimeCount.push(Math.max.apply(Math,this.InspectionTimeCount)+1);
      }
    },
    changeProductColorOrSizeRange(){
            var data = {
                WorkOrderId: this.generalData.workOrderId,
                Colors: this.workOrder.colors,
                SizeRange: this.workOrder.sizeRange
            };
            this.utilityFn.getServerData('post', this.apiList.workOrderDetailsColorAndSizeRangeUpdate, data, result => {
                if (result){
                    console.log('color size range success save');
                }
            });
        },  
    resourceIdForInspectionTime(){
      if (this.inspectionTimeForResourceModel == null && this.fromCanyon){
        return null
      }else{
        return this.inspectionTimeForResourceModel == null ? this.utilityFn.getResourceId() : this.inspectionTimeForResourceModel
      }
    },
    resetInspectionTimeCount(){
      this.InspectionTimeCount = [];
      this.InspectionTimeCount.push(1);
    },
    inspectionTimeDisableEnable(){
      if (this.workOrder.status == 'Approved' && !this.fromCanyon){
        return true;
      }
      return false;
    },
    async getAndSetCompanySubscriptionByWorkOrderId(){
      const result = await this.utilityFn.getServerDataAsync("get", this.apiList.getCompanySubscriptionByWorkOrderId + "/" + this.$route.params.id, {});
      this.isAdditionalInformationTrue = result.data.additionalInformationSectionInAIMS;
    }
  },
  computed: {
    ...mapState("inspection", {
      store_token: (state) => state.token,
      sectionData: (state) => state.sectionData,
      formAtomsList: (state) => state.formAtomsList,
      workOrder: (state) => state.workOrderDetails,
    }),
  },

  beforeRouteEnter(to, from, next) {
    window.sessionStorage.setItem("beforeRouteName", from.name);

    if (from.name === "workOrderId" || from.name === "assignmentId") {
      auditLog.setAuditHeaders({ transactionCode: auditLog.getUniqueIdentifier(), entityKey: null });
    }
    next();
  },
};
</script>

<style lang="stylus" scoped>
.workOrderDetailsId
    background #e8e8e8
.tab_title
    font-weight 700
.submit-btn
    color: #fff !important
.snackbar-loading
        align-items center
        display flex
        justify-content center
</style>
