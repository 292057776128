<template>
  <div>
    <div class="workOrderInspectionWorkmanshipId">
      <div style="position: relative;">
        <v-row style="margin-bottom:1%;">
          <v-col cols="12" sm="2" md="2" style="text-align:left;">
            <SpecialInstructions :workOrderId="$route.params.id"></SpecialInstructions>
        </v-col>
        <v-col cols="12" sm="10" md="10" style="text-align:right;">
          <v-btn id="workmanship_submit" v-if="showButton" :disabled="isSectionDisabled" class="submit-btn" @click="submit_item(true)" small depressed color="#1867c0">Submit</v-btn>
          <v-btn id="workmanship_uploadPhoto" :disabled="isSectionDisabled" class="submit-btn" small depressed color="#1867c0" @click="go_upload_photo('Workmanship', 5)">
            <v-icon>camera_alt</v-icon>
          </v-btn>
        </v-col>
        </v-row>
        <v-tabs v-model="tab" background-color="rgb(0, 81, 138)" dark>
          <v-tab href="#tab-1">SKU</v-tab>
          <v-tab href="#tab-2">Defect</v-tab>
          <v-tab href="#tab-3">Defect Classfication</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item class="tab_item" :value="'tab-1'" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field id="workmanship_tab1_aqlLevels" hide-details v-model="WorkOrderList.aqlLevels" label="Inspection Level" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  id="workmanship_tab1_sampleSize"
                  hide-details
                  v-model="WorkOrderList.workmanshipSampleSize"
                  :label="sampleSizeLabel()"
                  @change="upsertWorkmanshipResultTable"
                  @keyup="returnNumberSampleSize(WorkOrderList, 'workmanshipSampleSize')"
                  type="text">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select id="workmanship_tab1_result" hide-details v-bind:items="select_result" v-model="WorkOrderList.workmanshipResult" label="Result" append-icon="edit" @change="ChangeResult()"></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <div class="code-container">
                  <div v-if="WorkOrderList.workmanshipResult == '2' && isGenericCodes" class="helper-container">
                    <GenericCodeHelper></GenericCodeHelper>
                  </div>
                  <v-form v-model="isSKUResultValid">
                    <v-select
                        id="workmanship_tab1_workmanshipPendingFailCodeId"
                        v-if="WorkOrderList.workmanshipResult == '2'"
                        v-model="WorkOrderList.workmanshipResultCode"
                        :items="failCodeOptions"
                        :item-text="(item) => item.description ? `${item.code} - ${item.description}` : `${item.code}`"
                        item-value="code"
                        :rules="[(v) => !!v || 'Required']"
                        label="Fail Code"
                        append-icon="edit" >
                    </v-select>
                  </v-form>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  id="workmanship_tab1_workmanshipResultRemarks"
                  label="Remarks"
                  v-model="WorkOrderList.workmanshipResultRemarks "
                  :counter="200"
                  :rules="[(v) => !v || v.length <= 200 || 'Maximum 200 characters only']">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="2">
                <v-text-field hide-details  @change="upsertWorkmanshipResultTable" v-model="workOrder.workmanshipSampleSizeCritical" @keyup="returnNumber(workOrder, 'workmanshipSampleSizeCritical')" label="SZ -Critical"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="2">
                <v-text-field hide-details  @change="upsertWorkmanshipResultTable"  v-model="workOrder.workmanshipSampleSizeMajor" @keyup="returnNumber(workOrder, 'workmanshipSampleSizeMajor')" label="SZ -Major"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="2">
                <v-text-field hide-details  @change="upsertWorkmanshipResultTable" v-model="workOrder.workmanshipSampleSizeMinor" @keyup="returnNumber(workOrder, 'workmanshipSampleSizeMinor')" label="SZ -Minor"></v-text-field>
              </v-col>
            </v-row>

            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"></th>
                    <th class="text-center">Found</th>
                    <th></th>
                    <th></th>
                    <th v-if="hideAllowed()" class="text-center">Acc</th>
                    <th></th>

                    <th v-if="hideAllowed()" class="text-center">Rej</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Critical</td>
                    <td>
                      <v-text-field id="workmanship_tab1_foundCritical" class="number_text" v-model="foundSummary.foundCritical" type="number" readonly></v-text-field>
                    </td>
                    <td></td>
                    <td></td>
                    <td v-if="hideAllowed()">
                      <v-text-field id="workmanship_tab1_allowedCritical" class="number_text" @change="upsertWorkmanshipResultTable" v-model="WorkOrderList.allowedCritical"></v-text-field>
                    </td>
                    <td></td>
                    <td v-if="hideAllowed()">
                      <v-text-field id="workmanship_tab1_rejectedCritical" class="number_text" @change="upsertWorkmanshipResultTable" v-model="WorkOrderList.rejectedCritical"></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td>Major</td>
                    <td>
                      <v-text-field id="workmanship_tab1_foundMajor" class="number_text"  v-model="foundSummary.foundMajor" type="number" readonly></v-text-field>
                    </td>
                    <td></td>
                    <td></td>
                    <td v-if="hideAllowed()">
                      <v-text-field id="workmanship_tab1_allowedMajor" class="number_text" @change="upsertWorkmanshipResultTable" v-model="WorkOrderList.allowedMajor"></v-text-field>
                    </td>
                    <td></td>
                    <td v-if="hideAllowed()">
                      <v-text-field id="workmanship_tab1_rejectedMajor" class="number_text" @change="upsertWorkmanshipResultTable" v-model="WorkOrderList.rejectedMajor"></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td>Minor</td>
                    <td>
                      <v-text-field id="workmanship_tab1_foundMinor" class="number_text"  v-model="foundSummary.foundMinor" type="number" readonly></v-text-field>
                    </td>
                    <td></td>
                    <td></td>
                    <td v-if="hideAllowed()">
                      <v-text-field id="workmanship_tab1_allowedMinor" class="number_text" @change="upsertWorkmanshipResultTable" v-model="WorkOrderList.allowedMinor"></v-text-field>
                    </td>
                    <td></td>
                    <td v-if="hideAllowed()">
                      <v-text-field id="workmanship_tab1_rejectedMinor" class="number_text" @change="upsertWorkmanshipResultTable" v-model="WorkOrderList.rejectedMinor"></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
             <v-row>
              <v-col cols="12" sm="6"  v-if="_workOrderDetails.clientName == 'WALMART GLOBAL SOURCING' || _workOrderDetails.clientName.toLowerCase() == 'asda stores limited'">
                <v-text-field id="workmanship_tab1_notCountedDefects" hide-details v-model="WorkOrderList.notCountedDefects" label="Other defects observed but not counted in the AQL Result" ></v-text-field>
              </v-col>        
            </v-row>
            <v-dialog id="workmaship_dialog" v-model="dialog_sku" max-width="500px">
              <v-card v-if="clientDefectsLoadingStatus === 0">
                <v-card-text>
                  <div class="d-flex flex-column align-center mx-auto pa-10">
                    <v-progress-circular :size="50" indeterminate color="primary"></v-progress-circular>
                    <span >Loading Client Defects...</span>
                  </div>
                </v-card-text>
              </v-card>
              <v-card v-else>
                <v-card-title>
                  <span class="headline">Add</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-checkbox class="ml-2" label="Apply All SKUs" :input-value="formDataSku.workOrderProductIds.length === Products.length" value :indeterminate="formDataSku.workOrderProductIds.length > 0 && formDataSku.workOrderProductIds.length < Products.length" @change="onToggleProductSelection(formDataSku, $event, Products)"></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                          <v-select id="workmanship_tab1_dialog_sku" v-bind:items="Products" item-text="skuDisplay" item-value="id" v-model="formDataSku.workOrderProductIds" label="SKU" single-line bottom multiple></v-select>
                      </v-col>                      
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4" v-if="showDclType">
                        <v-select 
                          id="workmanship_tab1_dialog_defectType" 
                          v-bind:items="filteredDclTypeAliases"  
                          label="Defect Type"
                          item-text="description"
                          item-value="id"
                          v-model="formDataSku.dclTypeAliasId"
                          @change="onDclTypeAliasChange('skuSave')"
                          single-line bottom></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          id="workmanship_tab1_dialog_defectCategory"
                          :items="isDCLOrGeneric() ? clientDefectListSection : DefectCategories"
                          :item-text="isDCLOrGeneric() ? 'productCategory' : 'name'"
                          :item-value="isDCLOrGeneric() ? 'productCategoryId' : 'id'"
                          v-model="formDataSku.category"
                          label="Select Defect Category"
                          single-line
                          bottom
                          @change="onChangeDefectCategory('skuSave')"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-autocomplete
                          id="workmanship_tab1_dialog_defectNames"
                          label="Enter defect"
                          :items="filterSkuDefectNames"
                          :item-text="isDCLOrGeneric() ? 'description' : 'name'"
                          item-value="id"
                          @change="onDefectNameChange('SkuAdd')"
                          v-model="formDataSku.name"
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="6" md="4" v-show="isDCLOrGeneric()">
                        <v-autocomplete
                          id="defect_code"
                          label="Defect Code"
                          :items="filteredSkuDefectCodeList"
                          :item-text="isDCLOrGeneric() ? 'defectCode' : 'code'"
                          :item-value="isDCLOrGeneric() ? 'defectCode' : 'code'"
                          v-model="formDataSku.defectCode"
                          @change="onDefectCodeChange('SkuAdd')"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          id="workmanship_tab1_dialog_number"
                          v-model="formDataSku.number"
                          class="number_text"
                          prepend-inner-icon="mdi-minus"
                          append-icon="mdi-plus"
                          @keyup="returnNumber(formDataSku, 'number')"
                          label="Number"
                          @click:prepend-inner="_minus(formDataSku, 'add')"
                          @click:append="_plus(formDataSku, 'add')"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="6">
                        <v-radio-group id="workmanship_tab1_dialog_grade" v-model="formDataSku.grade" row style="padding-top: 0;">
                          <v-radio id="defect_classification" v-for="(item, index) in filteredSkuDefectClassificationList" :key="index" :label="item.name" :value="item.id"></v-radio>
                        </v-radio-group>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <AutocompleteSearch :url="getInspectionPhotoDescriptionUrl" @onSelect="onSelect" :itemText="itemText" :itemValue="itemValue" :isOutlined="isOutlined" :label="label" :allowManualInput="allowManualInput" :hideSelected="hideSelected" :existingValue="existingValue"></AutocompleteSearch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn id="workmanship_tab1_dialog_close" color="blue darken-1" text @click="dialog_sku = false">Close</v-btn>
                  <v-btn id="workmanship_tab1_dialog_save" color="blue darken-1" :disabled="disableSaveSku" text @click="save('skuList')">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Edit Sku -->
            <v-dialog id="workmanship_tab1_dialog_edit" v-model="dialog_sku_edit" max-width="500px">
              <v-card v-if="clientDefectsLoadingStatus === 0">
                <v-card-text>
                  <div class="d-flex flex-column align-center mx-auto pa-10">
                    <v-progress-circular :size="50" indeterminate color="primary"></v-progress-circular>
                    <span >Loading Client Defects...</span>
                  </div>
                </v-card-text>
              </v-card>
              <v-card v-else>
                <v-card-title>
                  <span class="headline">Edit</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-checkbox class="ml-2" label="Apply All SKUs" :input-value="formDataSkuEdit.workOrderProductIds.length === Products.length" value :indeterminate="formDataSkuEdit.workOrderProductIds.length > 0 && formDataSkuEdit.workOrderProductIds.length < Products.length" @change="onToggleProductSelection(formDataSkuEdit, $event, Products)"></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                          <v-select id="workmanship_tab1_dialog_edit_sku" v-bind:items="Products" item-text="skuDisplay" item-value="id" v-model="formDataSkuEdit.workOrderProductIds" label="SKU" single-line bottom multiple></v-select>
                      </v-col>                      
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4" v-if="showDclType">
                        <v-select 
                          id="workmanship_tab1_dialog_edit_defectType" 
                          v-bind:items="filteredDclTypeAliases"  
                          label="Defect Type"
                          item-text="description"
                          item-value="id"
                          v-model="formDataSkuEdit.dclTypeAliasId"
                          @change="onDclTypeAliasChange('skuUpdate')"
                          single-line bottom></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          id="workmanship_tab1_dialog_defectCategory"
                          :items="isDCLOrGeneric() ? clientDefectListSection : DefectCategories"
                          :item-text="isDCLOrGeneric() ? 'productCategory' : 'name'"
                          :item-value="isDCLOrGeneric() ? 'productCategoryId' : 'id'"
                          v-model="formDataSkuEdit.defectCategoryId"
                          label="Select Defect Category"
                          single-line
                          bottom
                          @change="onChangeDefectCategory('skuUpdate')"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-autocomplete
                          id="workmanship_tab1_dialog_defectNames"
                          label="Enter defect"
                          :items="filterSkuDefectNamesEdit"
                          :item-text="isDCLOrGeneric() ? 'description' : 'name'"
                          item-value="id"
                          @change="onDefectNameChange('SkuEdit')"
                          v-model="formDataSkuEdit.defectNameId"
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="6" md="4" v-show="isDCLOrGeneric()">
                        <v-autocomplete
                          id="defect_code"
                          label="Defect Code"
                          :items="filterSkuDefectCodeListEdit"
                          :item-text="isDCLOrGeneric() ? 'defectCode' : 'code'"
                          :item-value="isDCLOrGeneric() ? 'defectCode' : 'code'"
                          v-model="formDataSkuEdit.defectCode"
                          @change="onDefectCodeChange('SkuEdit')"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          id="workmanship_tab1_dialog_edit_qty"
                          v-model="formDataSkuEdit.qty"
                          label="Number"
                          class="number_text"
                          prepend-inner-icon="mdi-minus"
                          @keyup="returnNumber(formDataSkuEdit, 'qty')"
                          append-icon="mdi-plus"
                          @click:prepend-inner="_minus(formDataSkuEdit, 'edit')"
                          @click:append="_plus(formDataSkuEdit, 'edit')"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="6" sm="6">
                        <v-radio-group id="workmanship_tab1_dialog_edit_defectClassification" v-model="formDataSkuEdit.defectClassificationId" row style="padding-top: 0;">
                          <v-radio id="defect_classification" v-for="(item, index) in filterSkuDefectClassificationListEdit" :key="index" :label="item.name" :value="item.id"></v-radio>
                        </v-radio-group>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <AutocompleteSearch :url="getInspectionPhotoDescriptionUrl" @onSelect="onSelect" :itemText="itemText" :itemValue="itemValue" :isOutlined="isOutlined" :label="label" :allowManualInput="allowManualInput" :hideSelected="hideSelected" :existingValue="existingValue"></AutocompleteSearch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn id="workmanship_tab1_dialog_edit_close" color="blue darken-1" text @click="dialog_sku_edit = false; existingValue = null">Close</v-btn>
                  <v-btn id="workmanship_tab1_dialog_edit_update" color="blue darken-1" :disabled="disableUpdateSku" text @click="update('skuList')">Update</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog id="workmanship_tab1_confirm_dialog" v-model="showConfirmDialogSku" width="300">
              <v-card>
                <v-card-title class="headline"></v-card-title>
                <v-card-text>Are you sure you want to delete this item?</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn id="workmanship_tab1_confirm_dialog_yes" color="blue darken-1" text @click="del_suk_table('skuList')">Yes</v-btn>
                  <v-btn id="workmanship_tab1_confirm_dialog_no" color="blue darken-1" text @click="showConfirmDialogSku = false">No</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <div v-for="(item_p, index) in Products" :key="index">
              <v-expansion-panels class="mb-6 mt-6">
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    <v-toolbar-title>
                      <!-- {{item.skuDisplay}}  -->

                      <v-data-table :headers="skuHeaders" :items="filterSku(item_p.id)" :items-per-page="-1" style="margin-top: 30px;" hide-default-footer>
                        <template v-slot:header.critical="{ header }">
                          <v-row>
                            <v-col cols="12">Critical</v-col>
                            <v-col cols="4">F</v-col>
                            <v-col cols="4">A</v-col>
                            <v-col cols="4">R</v-col>
                          </v-row>
                        </template>
                        <template v-slot:header.major="{ header }">
                          <v-row>
                            <v-col cols="12">Major</v-col>
                            <v-col cols="4">F</v-col>
                            <v-col cols="4">A</v-col>
                            <v-col cols="4">R</v-col>
                          </v-row>
                        </template>
                        <template v-slot:header.minor="{ header }">
                          <v-row>
                            <v-col cols="12">Minor</v-col>
                            <v-col cols="4">F</v-col>
                            <v-col cols="4">A</v-col>
                            <v-col cols="4">R</v-col>
                          </v-row>
                        </template>

                        <template v-slot:item.critical="{ item }">
                          <v-row>
                            <v-col cols="4">{{ foundCriticalPerSku(item.id) }}</v-col>
                            <v-col cols="4">
                              <!-- {{ WorkOrderList.aqlCritical == "N/A" ? "N/A" : item.criticalAC }} -->
                              <v-text-field hide-details v-model="item.criticalAC" @change="calculateResult()" outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="4" >
                              <!-- {{ WorkOrderList.aqlCritical == "N/A" ? "N/A" : item.criticalRE }} -->
                              <v-text-field hide-details v-model="item.criticalRE" @change="calculateResult()" outlined dense></v-text-field>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item.major="{ item }">
                          <v-row>
                            <v-col cols="4">{{ foundMajorPerSku(item.id) }}</v-col>
                            <v-col cols="4" >
                              <!-- {{ WorkOrderList.aqlMajor == "N/A" ? "N/A" : item.majorAC }} -->
                              <v-text-field hide-details v-model="item.majorAC" @change="calculateResult()" outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="4" >
                              <!-- {{ WorkOrderList.aqlMajor == "N/A" ? "N/A" : item.majorRE }} -->
                              <v-text-field hide-details v-model="item.majorRE" @change="calculateResult()" outlined dense></v-text-field>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item.minor="{ item }">
                          <v-row>
                            <v-col cols="4">{{ foundMinorPerSku(item.id) }}</v-col>
                            <v-col cols="4" >
                              <!-- {{ WorkOrderList.aqlMinor == "N/A" ? "N/A" : item.minorAC }} -->
                              <v-text-field hide-details v-model="item.minorAC" @change="calculateResult()" outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="4" >
                              <!-- {{ WorkOrderList.aqlMinor == "N/A" ? "N/A" : item.minorRE }} -->
                              <v-text-field hide-details v-model="item.minorRE" @change="calculateResult()" outlined dense></v-text-field>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item.defectAdd="{ item }">
                          <!-- <template v-slot:activator="{ on }"> -->
                          <!-- <v-btn :id="'workmanship_tab1_panel_addDefect_' + index" color="#c02032" >
                            <v-icon color="#c02032" class="app-header-icon">add</v-icon>Defect
                          </v-btn> -->
                          <v-btn :id="'workmanship_tab1_panel_addDefect_' + index" color="red" @click.stop="add_defect_sku(item)" class="ma-2 white--text"> <v-icon left dark> mdi-plus-circle </v-icon> Defect </v-btn>
                          <!-- </template> -->
                        </template>
                        <!-- <template v-slot:expanded-item="{ item }">
                                <td colspan="7">
                                    <v-row>
                                        <v-col cols="6">
                                            <h3>**</h3>
                                        </v-col>
                                    </v-row>
                                </td>
                        </template>-->
                      </v-data-table>
                    </v-toolbar-title>
                    <!-- <template v-slot:actions>
                                        <span style="margin:4px 5px;color:#c02032;" @click.stop="add_defect_sku(item)">
                                            <v-icon color="#c02032">add_circle</v-icon>
                                            Add Defect
                                        </span>
                    </template>-->
                  </v-expansion-panel-header>
                  <!-- filterWorkOrderDefectSkuData =  -->
                  <v-expansion-panel-content>
                    <v-data-table :headers="skuHeader" hide-default-header v-bind="datatableRowsPerPage" :items="item_p.itemList" :items-per-page="-1" :hide-default-footer="false" class="elevation-1">
                      <template v-slot:item.defectClassification.name="{ item }">
                        <!-- v-model="toggle_exclusive" -->
                        <v-btn-group :id="'workmanship_tab1_panel_toggle_defectClassicationName' + item.id" class="v-item-group theme--light v-btn-toggle">
                          <v-btn :id="'workmanship_tab1_panel_toggle_critical' + item.id" depressed :disabled="item.defectClassificationId == 1" @click="updateClassfication(item, 1)">Critical</v-btn>
                          <v-btn :id="'workmanship_tab1_panel_toggle_major' + item.id" depressed :disabled="item.defectClassificationId == 2" @click="updateClassfication(item, 2)">Major</v-btn>
                          <v-btn :id="'workmanship_tab1_panel_toggle_minor' + item.id" depressed :disabled="item.defectClassificationId == 3" @click="updateClassfication(item, 3)">Minor</v-btn>
                        </v-btn-group>
                      </template>

                      <template v-slot:item.qty="{ item }">
                        <v-text-field
                          :id="'workmanship_tab1_panel_qty' + item.id"
                          style="width: 100px;margin: auto;"
                          class="number_text"
                          prepend-inner-icon="mdi-minus"
                          @keyup="returnNumber(item, 'qty')"
                          append-icon="mdi-plus"
                          v-model="item.qty"
                          @click:prepend-inner="minus(item)"
                          @click:append="plus(item)"
                        ></v-text-field>
                      </template>
                      <!-- 1111 -->
                      <template v-slot:item.menu="{ item }">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on }">
                            <v-btn icon dark v-on="on" :id="'workmanship_tab1_panel_menu' + item.id">
                              <v-icon color="rgba(0,0,0,.87)">menu</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item>
                              <v-list-item-title :id="'workmanship_tab1_panel_menu_item_edit_' + item.id" @click="edit_defects(item, item_p.itemList, 'skuList')">Edit</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title>
                                <router-link
                                  :id="'workmanship_tab1_panel_menu_item_addDefectPhotoFromGallery_' + item.id"
                                  :to="{ name: 'workOrderDefectAddDefectPhotoId', params: { headerTitle: ' Workmanship ' }, query: { id: item.workOrderProductId, defectId: item.id } }"
                                  >Add Defect Photo From Gallery</router-link
                                >
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title>
                                <router-link :id="'workmanship_tab1_panel_menu_item_addInspectionPhoto_' + item.id" :to="{ name: 'uploadPhotoInspectionId', params: { headerTitle: ' Workmanship ' }, query: { id: item.workOrderProductId } }"
                                  >Add Inspection Photo</router-link
                                >
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title :id="'workmanship_tab1_panel_menu_item_addDefectPhotoFromGallery_' + item.id" @click="del_show_dialog(item, item_p.itemList, 'skuList')">Delete</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                    <template>
                      <h1>
                        <p v-show="noDefect(item_p.id)">No Defect</p>
                      </h1>
                    </template>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </v-tab-item>

          <v-tab-item class="tab_item" :value="'tab-2'" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field id="workmanship_tab2_aqlLevels" hide-details v-model="WorkOrderList.aqlLevels" label="Inspection Level" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  id="workmanship_tab2_sampleSize"
                  hide-details
                  v-model="WorkOrderList.workmanshipSampleSize"
                  label="Sample Size"
                  @change="upsertWorkmanshipResultTable"
                  @keyup="returnNumberSampleSize(WorkOrderList, 'workmanshipSampleSize')"
                  type="text"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select id="workmanship_tab2_result" hide-details v-bind:items="select_result" v-model="WorkOrderList.workmanshipResult" label="Result" append-icon="edit" @change="ChangeResult()"></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <div class="code-container">
                  <div v-if="WorkOrderList.workmanshipResult == '2' && isGenericCodes" class="helper-container">
                    <GenericCodeHelper></GenericCodeHelper>
                  </div>
                  <v-form v-model="isDefectResultValid">
                    <v-select
                        id="workmanship_tab2_workmanshipPendingFailCodeId"
                        v-if="WorkOrderList.workmanshipResult == '2'"
                        v-model="WorkOrderList.workmanshipResultCode"
                        :items="failCodeOptions"
                        :item-text="(item) => item.description ? `${item.code} - ${item.description}` : `${item.code}`"
                        item-value="code"
                        :rules="[(v) => !!v || 'Required']"
                        label="Fail Code"
                        append-icon="edit" >
                    </v-select>
                  </v-form>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  id="workmanship_tab2_workmanshipResultRemarks"
                  label="Remarks"
                  v-model="WorkOrderList.workmanshipResultRemarks "
                  :counter="200"
                  :rules="[(v) => !v || v.length <= 200 || 'Maximum 200 characters only']">
                </v-text-field>
              </v-col>
            </v-row>

            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"></th>
                    <th class="text-center">Found</th>
                    <th></th>
                    <th></th>
                    <th v-if="hideAllowed()" class="text-center">Acc</th>
                    <th></th>
                    <th v-if="hideAllowed()" class="text-center">Rej</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Critical</td>
                    <td>
                      <v-text-field id="workmanship_tab2_foundCritical" class="number_text" v-model="foundSummary.foundCritical" type="number" readonly></v-text-field>
                    </td>
                    <td></td>
                    <td></td>
                    <td v-if="hideAllowed()">
                      <v-text-field id="workmanship_tab2_allowedCritical" class="number_text"  @change="upsertWorkmanshipResultTable" v-model="WorkOrderList.allowedCritical"></v-text-field>
                    </td>
                    <td></td>
                    <td v-if="hideAllowed()">
                      <v-text-field id="workmanship_tab2_rejectedCritical" class="number_text" @change="upsertWorkmanshipResultTable" v-model="WorkOrderList.rejectedCritical"></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td>Major</td>
                    <td>
                      <v-text-field id="workmanship_tab2_foundMajor" class="number_text" v-model="foundSummary.foundMajor" type="number" readonly></v-text-field>
                    </td>
                    <td></td>
                    <td></td>
                    <td v-if="hideAllowed()">
                      <v-text-field id="workmanship_tab2_allowedMajor" class="number_text" @change="upsertWorkmanshipResultTable" v-model="WorkOrderList.allowedMajor"></v-text-field>
                    </td>
                    <td></td>
                    <td v-if="hideAllowed()">
                      <v-text-field id="workmanship_tab2_rejectedMajor" class="number_text" @change="upsertWorkmanshipResultTable" v-model="WorkOrderList.rejectedMajor"></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td>Minor</td>
                    <td>
                      <v-text-field id="workmanship_tab2_foundMinor" class="number_text" v-model="foundSummary.foundMinor" type="number" readonly></v-text-field>
                    </td>
                    <td></td>
                    <td></td>
                    <td v-if="hideAllowed()">
                      <v-text-field id="workmanship_tab2_allowedMinor" class="number_text" @change="upsertWorkmanshipResultTable" v-model="WorkOrderList.allowedMinor"></v-text-field>
                    </td>
                    <td></td>
                    <td v-if="hideAllowed()">
                      <v-text-field id="workmanship_tab2_rejectedMinor" class="number_text" @change="upsertWorkmanshipResultTable" v-model="WorkOrderList.rejectedMinor"></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-dialog id="workmanship_tab2_dialog_defect" v-model="dialog_defect" max-width="500px">
              <v-card v-if="clientDefectsLoadingStatus === 0">
                <v-card-text>
                  <div class="d-flex flex-column align-center mx-auto pa-10">
                    <v-progress-circular :size="50" indeterminate color="primary"></v-progress-circular>
                    <span >Loading Client Defects...</span>
                  </div>
                </v-card-text>
              </v-card>
              <v-card v-else>
                <v-card-title>
                  <span class="headline">Add</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-checkbox class="ml-2" label="Apply All SKUs" :input-value="formDataDefect.workOrderProductIds.length === Products.length" value :indeterminate="formDataDefect.workOrderProductIds.length > 0 && formDataDefect.workOrderProductIds.length < Products.length" @change="onToggleProductSelection(formDataDefect, $event, Products)"></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                          <v-select id="workmanship_tab2_dialog_defect_sku" v-bind:items="Products" item-text="skuDisplay" item-value="id" v-model="formDataDefect.workOrderProductIds" label="SKU" single-line bottom multiple></v-select>
                      </v-col>                      
                    </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="4" v-if="showDclType">
                          <v-select
                            id="workmanship_tab2_dialog_defect_defectType" 
                            v-bind:items="filteredDclTypeAliases"  
                            label="Defect Type"
                            item-text="description"
                            item-value="id"
                            v-model="formDataDefect.dclTypeAliasId"
                            @change="onDclTypeAliasChange('defectSave')"
                            single-line bottom></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            id="workmanship_tab2_dialog_defect_defectCategory"
                            :items="isDCLOrGeneric() ? clientDefectListSection : DefectCategories"
                            :item-text="isDCLOrGeneric() ? 'productCategory' : 'name'"
                            :item-value="isDCLOrGeneric() ? 'productCategoryId' : 'id'"
                            v-model="formDataDefect.category"
                            label="Select Defect Category"
                            single-line
                            bottom
                            @change="onChangeDefectCategory('defectSave')"
                          ></v-select>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                          <v-autocomplete
                            id="workmanship_tab2_dialog_defect_defectName"
                            label="Enter defect"
                            :items="filterDataDefectNames"
                            :item-text="isDCLOrGeneric() ? 'description' : 'name'"
                            item-value="id"
                            @change="onDefectNameChange('DefectAdd')"
                            v-model="formDataDefect.name"
                          ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="6" md="4" v-show="isDCLOrGeneric()">
                          <v-autocomplete
                            id="defect_code"
                            label="Defect Code"
                            :items="filteredDataDefectCodeList"
                            :item-text="isDCLOrGeneric() ? 'defectCode' : 'code'"
                            :item-value="isDCLOrGeneric() ? 'defectCode' : 'code'"
                            v-model="formDataDefect.defectCode"
                            @change="onDefectCodeChange('DefectAdd')"
                          ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            id="workmanship_tab2_dialog_defect_number"
                            v-model="formDataDefect.number"
                            class="number_text"
                            prepend-inner-icon="mdi-minus"
                            @keyup="returnNumber(formDataDefect, 'number')"
                            append-icon="mdi-plus"
                            label="Number"
                            @click:prepend-inner="_minus(formDataDefect, 'add')"
                            @click:append="_plus(formDataDefect, 'add')"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="6" sm="6">
                          <v-radio-group id="workmanship_tab2_dialog_defect_grade" v-model="formDataDefect.grade" row style="padding-top: 0;">
                            <v-radio id="defect_classification" v-for="(item, index) in filteredDataDefectClassificationList" :key="index" :label="item.name" :value="item.id"></v-radio>
                          </v-radio-group>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <AutocompleteSearch :url="getInspectionPhotoDescriptionUrl" @onSelect="onSelect" :itemText="itemText" :itemValue="itemValue" :isOutlined="isOutlined" :label="label" :allowManualInput="allowManualInput" :hideSelected="hideSelected" :existingValue="existingValue"></AutocompleteSearch>
                        </v-col>
                      </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn id="workmanship_tab2_dialog_defect_close" color="blue darken-1" text @click="dialog_defect = false">Close</v-btn>
                  <v-btn id="workmanship_tab2_dialog_defect_save" color="blue darken-1" :disabled="disableSaveDefect" text @click="save('defectList')">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Edit Defect -->
            <v-dialog id="workmanship_tab2_dialog_defect_edit" v-model="dialog_defect_edit" max-width="500px">
              <v-card v-if="clientDefectsLoadingStatus === 0">
                <v-card-text>
                  <div class="d-flex flex-column align-center mx-auto pa-10">
                    <v-progress-circular :size="50" indeterminate color="primary"></v-progress-circular>
                    <span >Loading Client Defects...</span>
                  </div>
                </v-card-text>
              </v-card>
              <v-card v-else>
                <v-card-title>
                  <span class="headline">Edit</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-checkbox class="ml-2" label="Apply All SKUs" :input-value="formDataDefectEdit.workOrderProductIds.length === Products.length" value :indeterminate="formDataDefectEdit.workOrderProductIds.length > 0 && formDataDefectEdit.workOrderProductIds.length < Products.length" @change="onToggleProductSelection(formDataDefectEdit, $event, Products)"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-select id="workmanship_tab2_dialog_defect_edit_sku" v-bind:items="Products" item-text="skuDisplay" item-value="id" v-model="formDataDefectEdit.workOrderProductIds" label="SKU" single-line bottom multiple></v-select>
                        </v-col>                        
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="4" v-if="showDclType">
                          <v-select
                            id="workmanship_tab2_dialog_defect_edit_defectType" 
                            v-bind:items="filteredDclTypeAliases"  
                            label="Defect Type"
                            item-text="description"
                            item-value="id"
                            v-model="formDataDefectEdit.dclTypeAliasId"
                            @change="onDclTypeAliasChange('defectUpdate')"
                            single-line bottom></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            id="workmanship_tab2_dialog_defect_edit_defectCategory"
                            :items="isDCLOrGeneric() ? clientDefectListSection : DefectCategories"
                            :item-text="isDCLOrGeneric() ? 'productCategory' : 'name'"
                            :item-value="isDCLOrGeneric() ? 'productCategoryId' : 'id'"
                            v-model="formDataDefectEdit.defectCategoryId"
                            label="Select Defect Category"
                            single-line
                            bottom
                            @change="onChangeDefectCategory('defectUpdate')"
                          ></v-select>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                          <v-autocomplete
                            id="workmanship_tab2_dialog_defect_edit_defectName"
                            label="Enter defect"
                            :items="filterDataDefectNamesEdit"
                            :item-text="isDCLOrGeneric() ? 'description' : 'name'"
                            item-value="id"
                            @change="onDefectNameChange('DefectEdit')"
                            v-model="formDataDefectEdit.defectNameId"
                          ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="6" md="4" v-show="isDCLOrGeneric()">
                          <v-autocomplete
                            id="defect_code"
                            label="Defect Code"
                            :items="filterDataDefectCodeListEdit"
                            :item-text="isDCLOrGeneric() ? 'defectCode' : 'code'"
                            :item-value="isDCLOrGeneric() ? 'defectCode' : 'code'"
                            v-model="formDataDefectEdit.defectCode"
                            @change="onDefectCodeChange('DefectEdit')"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            id="workmanship_tab2_dialog_defect_edit_qty"
                            v-model="formDataDefectEdit.qty"
                            class="number_text"
                            prepend-inner-icon="mdi-minus"
                            @keyup="returnNumber(formDataDefectEdit, 'qty')"
                            append-icon="mdi-plus"
                            label="Number"
                            @click:prepend-inner="_minus(formDataDefectEdit, 'edit')"
                            @click:append="_plus(formDataDefectEdit, 'edit')"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="6" sm="6">
                          <v-radio-group id="workmanship_tab2_dialog_defect_edit_defectClassification" v-model="formDataDefectEdit.defectClassificationId" row style="padding-top: 0;">
                            <v-radio id="defect_classification" v-for="(item, index) in filteredDataDefectClassificationList" :key="index" :label="item.name" :value="item.id"></v-radio>
                          </v-radio-group>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <AutocompleteSearch :url="getInspectionPhotoDescriptionUrl" @onSelect="onSelect" :itemText="itemText" :itemValue="itemValue" :isOutlined="isOutlined" :label="label" :allowManualInput="allowManualInput" :hideSelected="hideSelected" :existingValue="existingValue"></AutocompleteSearch>
                        </v-col>
                      </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn id="workmanship_tab2_dialog_defect_edit_close" color="blue darken-1" text @click="dialog_defect_edit = false; existingValue = null">Close</v-btn>
                  <v-btn id="workmanship_tab2_dialog_defect_edit_update" color="blue darken-1" :disabled="disableUpdateDefect" text @click="update('defectList')">Update</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog id="workmanship_tab2_confirm_dialog" v-model="showConfirmDialogDefect" width="300">
              <v-card>
                <v-card-title class="headline"></v-card-title>
                <v-card-text>Are you sure you want to delete this item?</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn id="workmanship_tab2_confirm_dialog_yes" color="blue darken-1" text @click="del_suk_table('defectList')">Yes</v-btn>
                  <v-btn id="workmanship_tab2_confirm_dialog_no" color="blue darken-1" text @click="showConfirmDialogDefect = false">No</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <div v-for="(item_p, index) in groupByDefectName" :key="index">
              <!-- <p>{{groupByDefectName}}</p> -->
              <!-- <p>{{defectItemList[item_p[0].defectName.name]}}</p> -->
              <!-- Critical -->
              <v-expansion-panels class="mb-6" v-show="filterWorkOrderDefectName(item_p[0].defectName.name, 1).length != 0">
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    <v-toolbar-title>{{ item_p[0].defectName.name }} | Critical</v-toolbar-title>
                    <template v-slot:actions>
                      <span :id="'workmanship_tab2_panel_addDefect_' + index" style="margin:4px 5px;color:#c02032;" @click.stop="add_defect_name(item_p, 1)"> <v-icon color="#c02032">add_circle</v-icon>Add Defect </span>
                    </template>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <!-- :items=" filterWorkOrderDefectName(item_p[0].defectName.name,1)" -->
                    <v-data-table v-bind="datatableRowsPerPage" :headers="defectHeader" hide-default-header :items="defectItemList[item_p[0].defectName.name].critical" :items-per-page="-1" :hide-default-footer="false" class="elevation-1" style="margin-top: 30px;">
                      <template v-slot:item.qty="{ item }">
                        <v-text-field
                          :id="'workmanship_tab2_panel_qty_' + item.qty"
                          style="width: 100px;margin: auto;"
                          class="number_text"
                          prepend-inner-icon="mdi-minus"
                          append-icon="mdi-plus"
                          v-model="item.qty"
                          @keyup="returnNumber(item, 'qty')"
                          @click:prepend-inner="minus(item)"
                          @click:append="plus(item)"
                        ></v-text-field>
                      </template>

                      <template v-slot:item.menu="{ item }">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on }">
                            <v-btn icon dark v-on="on" :id="'workmanship_tab2_panel_menu_' + item.id">
                              <v-icon color="rgba(0,0,0,.87)">menu</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item>
                              <v-list-item-title :id="'workmanship_tab2_panel_menu_item_edit_' + item.id" @click="edit_defects(item, item_p.itemList, 'defectList')">Edit</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title>
                                <router-link
                                  :id="'workmanship_tab2_panel_menu_item_addDefectPhoto_' + item.id"
                                  :to="{ name: 'workOrderDefectAddDefectPhotoId', params: { headerTitle: ' Workmanship ' }, query: { id: item.workOrderProductId, defectId: item.id } }"
                                  >Add Defect Photo From Gallery</router-link
                                >
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title>
                                <router-link :id="'workmanship_tab2_panel_menu_item_addInspectionPhoto_' + item.id" :to="{ name: 'uploadPhotoInspectionId', params: { headerTitle: ' Workmanship ' }, query: { id: item.workOrderProductId } }"
                                  >Add Inspection Photo</router-link
                                >
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title :id="'workmanship_tab2_panel_menu_item_delete_' + item.id" @click="del_show_dialog(item, defectItemList[item_p[0].defectName.name].critical, 'defectList')">Delete</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <!-- Major -->
              <v-expansion-panels class="mb-6" v-show="filterWorkOrderDefectName(item_p[0].defectName.name, 2).length != 0">
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    <v-toolbar-title>{{ item_p[0].defectName.name }} | Major</v-toolbar-title>
                    <template v-slot:actions>
                      <span :id="'workmanship_tab2_panel_major_addDefect_' + index" style="margin:4px 5px;color:#c02032;" @click.stop="add_defect_name(item_p, 2)"> <v-icon color="#c02032">add_circle</v-icon>Add Defect </span>
                    </template>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <v-data-table v-bind="datatableRowsPerPage" :headers="defectHeader" hide-default-header :items="defectItemList[item_p[0].defectName.name].major" :items-per-page="-1" :hide-default-footer="false" class="elevation-1" style="margin-top: 30px;">
                      <template v-slot:item.qty="{ item }">
                        <v-text-field
                          :id="'workmanship_tab2_panel_major_qty_' + item.id"
                          style="width: 100px;margin: auto;"
                          class="number_text"
                          prepend-inner-icon="mdi-minus"
                          @keyup="returnNumber(item, 'qty')"
                          append-icon="mdi-plus"
                          v-model="item.qty"
                          @click:prepend-inner="minus(item)"
                          @click:append="plus(item)"
                        ></v-text-field>
                      </template>

                      <template v-slot:item.menu="{ item }">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on }">
                            <v-btn icon dark v-on="on" :id="'workmanship_tab2_panel_major_menu_' + item.id">
                              <v-icon color="rgba(0,0,0,.87)">menu</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item>
                              <v-list-item-title :id="'workmanship_tab2_panel_major_menu_item_edit_' + item.id" @click="edit_defects(item, item_p.itemList, 'defectList')">Edit</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title>
                                <router-link
                                  :id="'workmanship_tab2_panel_major_menu_item_addDefectPhoto_' + item.id"
                                  :to="{ name: 'workOrderDefectAddDefectPhotoId', params: { headerTitle: ' Workmanship ' }, query: { id: item.workOrderProductId, defectId: item.id } }"
                                  >Add Defect Photo From Gallery</router-link
                                >
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title>
                                <router-link :id="'workmanship_tab2_panel_major_menu_item_addInspectionPhoto_' + item.id" :to="{ name: 'uploadPhotoInspectionId', params: { headerTitle: ' Workmanship ' }, query: { id: item.workOrderProductId } }"
                                  >Add Inspection Photo</router-link
                                >
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title :id="'workmanship_tab2_panel_major_menu_item_delete_' + item.id" @click="del_show_dialog(item, defectItemList[item_p[0].defectName.name].major, 'defectList')">Delete</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <!-- Minor -->
              <v-expansion-panels class="mb-6" v-show="filterWorkOrderDefectName(item_p[0].defectName.name, 3).length != 0">
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    <v-toolbar-title>{{ item_p[0].defectName.name }} | Minor</v-toolbar-title>
                    <template v-slot:actions>
                      <span :id="'workmanship_tab2_panel_minor_addDefect_' + index" style="margin:4px 5px;color:#c02032;" @click.stop="add_defect_name(item_p, 3)"> <v-icon color="#c02032">add_circle</v-icon>Add Defect </span>
                    </template>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <v-data-table v-bind="datatableRowsPerPage" :headers="defectHeader" hide-default-header :items="defectItemList[item_p[0].defectName.name].minor" :items-per-page="-1" :hide-default-footer="false" class="elevation-1" style="margin-top: 30px;">
                      <template v-slot:item.qty="{ item }">
                        <v-text-field
                          :id="'workmanship_tab2_panel_minor_qty_' + item.id"
                          style="width: 100px;margin: auto;"
                          class="number_text"
                          @keyup="returnNumber(item, 'qty')"
                          prepend-inner-icon="mdi-minus"
                          append-icon="mdi-plus"
                          v-model="item.qty"
                          @click:prepend-inner="minus(item)"
                          @click:append="plus(item)"
                        ></v-text-field>
                      </template>

                      <template v-slot:item.menu="{ item }">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on }">
                            <v-btn icon dark v-on="on" :id="'workmanship_tab2_panel_minor_menu_' + item.id">
                              <v-icon color="rgba(0,0,0,.87)">menu</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item>
                              <v-list-item-title :id="'workmanship_tab2_panel_minor_menu_item_edit_' + item.id" @click="edit_defects(item, item_p.itemList, 'defectList')">Edit</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title>
                                <router-link
                                  :id="'workmanship_tab2_panel_minor_menu_item_addDefectPhoto_' + item.id"
                                  :to="{ name: 'workOrderDefectAddDefectPhotoId', params: { headerTitle: ' Workmanship ' }, query: { id: item.workOrderProductId, defectId: item.id } }"
                                  >Add Defect Photo From Gallery</router-link
                                >
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title>
                                <router-link :id="'workmanship_tab2_panel_minor_menu_item_addInspectionPhoto_' + item.id" :to="{ name: 'uploadPhotoInspectionId', params: { headerTitle: ' Workmanship ' }, query: { id: item.workOrderProductId } }"
                                  >Add Inspection Photo</router-link
                                >
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title :id="'workmanship_tab2_panel_minor_menu_item_delete_' + item.id" @click="del_show_dialog(item, defectItemList[item_p[0].defectName.name].minor, 'defectList')">Delete</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </v-tab-item>

          <v-tab-item class="tab_item" :value="'tab-3'" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field id="workmanship_tab3_aqlLevels" hide-details v-model="WorkOrderList.aqlLevels" label="Inspection Level" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  id="workmanship_tab3_sampleSize"
                  hide-details
                  v-model="WorkOrderList.workmanshipSampleSize"
                  label="Sample Size"
                  @change="upsertWorkmanshipResultTable"
                  @keyup="returnNumberSampleSize(WorkOrderList, 'workmanshipSampleSize')"
                  type="text"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select id="workmanship_tab3_result" hide-details v-bind:items="select_result" v-model="WorkOrderList.workmanshipResult" label="Result" append-icon="edit" @change="ChangeResult()"></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <div class="code-container">
                  <div v-if="WorkOrderList.workmanshipResult == '2' && isGenericCodes" class="helper-container">
                    <GenericCodeHelper></GenericCodeHelper>
                  </div>
                  <v-form v-model="isDefectClassificationValid">
                    <v-select
                        id="workmanship_tab3_workmanshipPendingFailCodeId"
                        v-if="WorkOrderList.workmanshipResult == '2'"
                        v-model="WorkOrderList.workmanshipResultCode"
                        :items="failCodeOptions"
                        :item-text="(item) => item.description ? `${item.code} - ${item.description}` : `${item.code}`"
                        item-value="code"
                        :rules="[(v) => !!v || 'Required']"
                        label="Fail Code"
                        append-icon="edit" >
                    </v-select>
                  </v-form>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                id="workmanship_tab3_workmanshipResultRemarks"
                  label="Remarks"
                  v-model="WorkOrderList.workmanshipResultRemarks "
                  :counter="200"
                  :rules="[(v) => !v || v.length <= 200 || 'Maximum 200 characters only']">
                </v-text-field>
              </v-col>
            </v-row>

            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"></th>
                    <th class="text-center">Found</th>
                    <th></th>
                    <th></th>
                    <th v-if="hideAllowed()" class="text-center">Acc</th>
                    <th></th>

                    <th v-if="hideAllowed()" class="text-center">Rej</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Critical</td>
                    <td>
                      <v-text-field id="workmanship_tab3_foundCritical" class="number_text" v-model="foundSummary.foundCritical" type="number" readonly></v-text-field>
                    </td>
                    <td></td>
                    <td></td>
                    <td v-if="hideAllowed()">
                      <v-text-field id="workmanship_tab13_allowedCritical" @change="upsertWorkmanshipResultTable" class="number_text" v-model="WorkOrderList.allowedCritical"></v-text-field>
                    </td>
                    <td></td>
                    <td v-if="hideAllowed()">
                      <v-text-field id="workmanship_tab3_rejectedCritical" @change="upsertWorkmanshipResultTable" class="number_text" v-model="WorkOrderList.rejectedCritical"></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td>Major</td>
                    <td>
                      <v-text-field id="workmanship_tab3_foundMajor" class="number_text" v-model="foundSummary.foundMajor" type="number" readonly></v-text-field>
                    </td>
                    <td></td>
                    <td></td>
                    <td v-if="hideAllowed()">
                      <v-text-field id="workmanship_tab3_allowedMajor" @change="upsertWorkmanshipResultTable" class="number_text" v-model="WorkOrderList.allowedMajor"></v-text-field>
                    </td>
                    <td></td>
                    <td v-if="hideAllowed()">
                      <v-text-field id="workmanship_tab3_rejectedMajor" @change="upsertWorkmanshipResultTable" class="number_text" v-model="WorkOrderList.rejectedMajor"></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td>Minor</td>
                    <td>
                      <v-text-field id="workmanship_tab3_foundMinor" class="number_text" v-model="foundSummary.foundMinor" type="number" readonly></v-text-field>
                    </td>
                    <td></td>
                    <td></td>
                    <td v-if="hideAllowed()">
                      <v-text-field id="workmanship_tab3_allowedMinor" @change="upsertWorkmanshipResultTable" class="number_text" v-model="WorkOrderList.allowedMinor"></v-text-field>
                    </td>
                    <td></td>
                    <td v-if="hideAllowed()">
                      <v-text-field id="workmanship_tab3_rejectedMinor" @change="upsertWorkmanshipResultTable" class="number_text" v-model="WorkOrderList.rejectedMinor"></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-dialog id="workmanship_tab3_dialog_classification" v-model="dialog_classFication" max-width="500px">
              <v-card v-if="clientDefectsLoadingStatus === 0">
                <v-card-text>
                  <div class="d-flex flex-column align-center mx-auto pa-10">
                    <v-progress-circular :size="50" indeterminate color="primary"></v-progress-circular>
                    <span >Loading Client Defects...</span>
                  </div>
                </v-card-text>
              </v-card>
              <v-card v-else>
                <v-card-title>
                  <span class="headline">Add</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-checkbox class="ml-2" label="Apply All SKUs" :input-value="formDataClassFication.workOrderProductIds.length === Products.length" value :indeterminate="formDataClassFication.workOrderProductIds.length > 0 && formDataClassFication.workOrderProductIds.length < Products.length" @change="onToggleProductSelection(formDataClassFication, $event, Products)"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-select id="workmanship_tab3_dialog_classification_sku" v-bind:items="Products" item-text="skuDisplay" item-value="id" v-model="formDataClassFication.workOrderProductIds" label="SKU" single-line bottom multiple></v-select>
                        </v-col>                        
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="4" v-if="showDclType">
                          <v-select
                            id="workmanship_tab3_dialog_classification_defectType" 
                            v-bind:items="filteredDclTypeAliases"  
                            label="Defect Type"
                            item-text="description"
                            item-value="id"
                            v-model="formDataClassFication.dclTypeAliasId"
                            @change="onDclTypeAliasChange('classificationSave')"
                            single-line bottom></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            id="workmanship_tab3_dialog_classification_defectCategory"
                            :items="isDCLOrGeneric() ? clientDefectListSection : DefectCategories"
                            :item-text="isDCLOrGeneric() ? 'productCategory' : 'name'"
                            :item-value="isDCLOrGeneric() ? 'productCategoryId' : 'id'"
                            v-model="formDataClassFication.category"
                            label="Select Defect Category"
                            single-line
                            bottom
                            @change="onChangeDefectCategory('classificationSave')"
                          ></v-select>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                          <v-autocomplete
                            id="workmanship_tab3_dialog_classification_defectName"
                            label="Enter defect"
                            :items="filterDataClassFicationNames"
                            :item-text="isDCLOrGeneric() ? 'description' : 'name'"
                            item-value="id"
                            @change="onDefectNameChange('ClassFicationAdd')"
                            v-model="formDataClassFication.name"
                          ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="6" md="4" v-show="isDCLOrGeneric()">
                          <v-autocomplete
                            id="defect_code"
                            label="Defect Code"
                            :items="filteredDataClassFicationCodeList"
                            :item-text="isDCLOrGeneric() ? 'defectCode' : 'code'"
                            :item-value="isDCLOrGeneric() ? 'defectCode' : 'code'"
                            v-model="formDataClassFication.defectCode"
                            @change="onDefectCodeChange('ClassFicationAdd')"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            id="workmanship_tab3_dialog_classification_number"
                            v-model="formDataClassFication.number"
                            label="Number"
                            class="number_text"
                            prepend-inner-icon="mdi-minus"
                            @keyup="returnNumber(formDataClassFication, 'number')"
                            append-icon="mdi-plus"
                            @click:prepend-inner="_minus(formDataClassFication, 'add')"
                            @click:append="_plus(formDataClassFication, 'add')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="6">
                          <v-radio-group id="workmanship_tab3_dialog_classification_grade" v-model="formDataClassFication.grade" row style="padding-top: 0;">
                            <v-radio id="defect_classification" v-for="(item, index) in filteredDataClassFicationClassificationList" :key="index" :label="item.name" :value="item.id"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <AutocompleteSearch :url="getInspectionPhotoDescriptionUrl" @onSelect="onSelect" :itemText="itemText" :itemValue="itemValue" :isOutlined="isOutlined" :label="label" :allowManualInput="allowManualInput" :hideSelected="hideSelected" :existingValue="existingValue"></AutocompleteSearch>
                        </v-col>
                      </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn id="workmanship_tab3_dialog_classification_close" color="blue darken-1" text @click="dialog_classFication = false">Close</v-btn>
                  <v-btn id="workmanship_tab3_dialog_classification_save" color="blue darken-1" :disabled="disableSaveClassification" text @click="save('classFicationList')">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Edit Classification -->
            <v-dialog id="workmanship_tab3_dialog_classification_edit" v-model="dialog_classFication_edit" max-width="500px">
              <v-card v-if="clientDefectsLoadingStatus === 0">
                <v-card-text>
                  <div class="d-flex flex-column align-center mx-auto pa-10">
                    <v-progress-circular :size="50" indeterminate color="primary"></v-progress-circular>
                    <span >Loading Client Defects...</span>
                  </div>
                </v-card-text>
              </v-card>
              <v-card v-else>
                <v-card-title>
                  <span class="headline">Edit</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-checkbox class="ml-2" label="Apply All SKUs" :input-value="formDataClassFicationEdit.workOrderProductIds.length === Products.length" value :indeterminate="formDataClassFicationEdit.workOrderProductIds.length > 0 && formDataClassFicationEdit.workOrderProductIds.length < Products.length" @change="onToggleProductSelection(formDataClassFicationEdit, $event, Products)"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-select id="workmanship_tab3_dialog_classification_edit_sku" v-bind:items="Products" item-text="skuDisplay" item-value="id" v-model="formDataClassFicationEdit.workOrderProductIds" label="SKU" single-line bottom multiple></v-select>
                        </v-col>                        
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="4" v-if="showDclType">
                          <v-select
                            id="workmanship_tab3_dialog_classification_edit_defectType" 
                            v-bind:items="filteredDclTypeAliases"  
                            label="Defect Type"
                            item-text="description"
                            item-value="id"
                            v-model="formDataClassFicationEdit.dclTypeAliasId"
                            @change="onDclTypeAliasChange('classificationUpdate')"
                            single-line bottom></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            id="workmanship_tab3_dialog_classification_edit_defectCategory"
                            :items="isDCLOrGeneric() ? clientDefectListSection : DefectCategories"
                            :item-text="isDCLOrGeneric() ? 'productCategory' : 'name'"
                            :item-value="isDCLOrGeneric() ? 'productCategoryId' : 'id'"
                            v-model="formDataClassFicationEdit.defectCategoryId"
                            label="Select Defect Category"
                            single-line
                            bottom
                            @change="onChangeDefectCategory('classificationUpdate')"
                          ></v-select>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                          <v-autocomplete
                            id="workmanship_tab3_dialog_classification_edit_defectName"
                            label="Enter defect"
                            :items="filterDataClassFicationNamesEdit"
                            :item-text="isDCLOrGeneric() ? 'description' : 'name'"
                            item-value="id"
                            @change="onDefectNameChange('ClassFicationEdit')"
                            v-model="formDataClassFicationEdit.defectNameId"
                          ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="6" md="4" v-show="isDCLOrGeneric()">
                          <v-autocomplete
                            id="defect_code"
                            label="Defect Code"
                            :items="filteredDataClassFicationCodeListEdit"
                            :item-text="isDCLOrGeneric() ? 'defectCode' : 'code'"
                            :item-value="isDCLOrGeneric() ? 'defectCode' : 'code'"
                            v-model="formDataClassFicationEdit.defectCode"
                            @change="onDefectCodeChange('ClassFicationEdit')"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            id="workmanship_tab3_dialog_classification_edit_qty"
                            v-model="formDataClassFicationEdit.qty"
                            label="Number"
                            class="number_text"
                            prepend-inner-icon="mdi-minus"
                            @keyup="returnNumber(formDataClassFicationEdit, 'qty')"
                            append-icon="mdi-plus"
                            @click:prepend-inner="_minus(formDataClassFicationEdit, 'edit')"
                            @click:append="_plus(formDataClassFicationEdit, 'edit')"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="6" sm="6">
                          <v-radio-group id="workmanship_tab1_dialog_edit_defectClassification" v-model="formDataClassFicationEdit.defectClassificationId" row style="padding-top: 0;">
                            <v-radio id="defect_classification" v-for="(item, index) in filterDataClassFicationClassificationListEdit" :key="index" :label="item.name" :value="item.id"></v-radio>
                          </v-radio-group>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <AutocompleteSearch :url="getInspectionPhotoDescriptionUrl" @onSelect="onSelect" :itemText="itemText" :itemValue="itemValue" :isOutlined="isOutlined" :label="label" :allowManualInput="allowManualInput" :hideSelected="hideSelected" :existingValue="existingValue"></AutocompleteSearch>
                        </v-col>
                      </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn id="workmanship_tab3_dialog_classification_edit_close" color="blue darken-1" text @click="dialog_classFication_edit = false; existingValue = null">Close</v-btn>
                  <v-btn id="workmanship_tab3_dialog_classification_edit_update" color="blue darken-1" :disabled="disableUpdateClassification" text @click="update('classFicationList')">Update</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog id="workmanship_tab3_confirm_dialog" v-model="showConfirmDialogClassification" width="300">
              <v-card>
                <v-card-title class="headline"></v-card-title>
                <v-card-text>Are you sure you want to delete this item?</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn id="workmanship_tab3_confirm_dialog_yes" color="blue darken-1" text @click="del_suk_table('classFicationList')">Yes</v-btn>
                  <v-btn id="workmanship_tab3_confirm_dialog_no" color="blue darken-1" text @click="showConfirmDialogClassification = false">No</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <div v-for="(groupitem, index) in DefectClassifications" :key="index">
              <v-expansion-panels class="mb-6">
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    <!-- <p>{{filterWorkOrderDefectClassification(groupitem.id)}}</p> -->
                    <v-toolbar-title>{{ groupitem.name }}</v-toolbar-title>
                    <template v-slot:actions>
                      <span style="margin:4px 5px;color:#c02032;" @click.stop="add_defect_classification(groupitem)"> <v-icon color="#c02032">add_circle</v-icon>Add Defect </span>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-data-table v-bind="datatableRowsPerPage" :headers="classFicationHeader" hide-default-header :items="filterWorkOrderDefectClassification(groupitem.id)" :items-per-page="-1" :hide-default-footer="false" class="elevation-1" style="margin-top: 30px;">
                      <template v-slot:item.qty="{ item }">
                        <v-text-field
                          :id="'workmanship_tab3_panel_addDefect_' + groupitem.name + '_' + index"
                          style="width: 100px;margin: auto;"
                          class="number_text"
                          prepend-inner-icon="mdi-minus"
                          append-icon="mdi-plus"
                          @keyup="returnNumber(item, 'qty')"
                          v-model="item.qty"
                          @click:prepend-inner="minus(item)"
                          @click:append="plus(item)"
                        ></v-text-field>
                      </template>

                      <template v-slot:item.menu="{ item }">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on }">
                            <v-btn icon dark v-on="on">
                              <v-icon :id="'workmanship_tab3_panel_menu_' + groupitem.name + '_' + item.id" color="rgba(0,0,0,.87)">menu</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item>
                              <v-list-item-title :id="'workmanship_tab3_panel_menu_edit_' + groupitem.name + '_' + item.id" color="rgba(0,0,0,.87)" @click="edit_defects(item, '', 'classFicationList')">Edit</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title>
                                <router-link
                                  :id="'workmanship_tab3_panel_menu_item_addDefectPhoto_' + groupitem.name + '_' + item.id"
                                  color="rgba(0,0,0,.87)"
                                  :to="{ name: 'workOrderDefectAddDefectPhotoId', params: { headerTitle: ' Workmanship ' }, query: { id: item.workOrderProductId, defectId: item.id } }"
                                  >Add Defect Photo From Gallery</router-link
                                >
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title>
                                <router-link
                                  :id="'workmanship_tab3_panel_menu_item_addInspectionPhoto_' + groupitem.name + '_' + item.id"
                                  color="rgba(0,0,0,.87)"
                                  :to="{ name: 'uploadPhotoInspectionId', params: { headerTitle: ' Workmanship ' }, query: { id: item.workOrderProductId } }"
                                  >Add Inspection Photo</router-link
                                >
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title :id="'workmanship_tab3_panel_menu_item_delete_' + groupitem.name + '_' + item.id" color="rgba(0,0,0,.87)" @click="del_show_dialog(item, groupitem.itemList, 'classFicationList')"
                                >Delete</v-list-item-title
                              >
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <v-snackbar id="workmanship_snackbar" v-model="snackbar" :timeout="timeout" :color="color">
        {{ message }}
        <v-btn text id="workmanship_snackbar_close" @click="snackbar = false">Close</v-btn>
      </v-snackbar>

      <v-dialog id="workmanship_progressDialog" v-model="showProgressDialog" :persistent="true">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <div class="text-center">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div v-if="showFloatingButton">
      <FloatingButton></FloatingButton>
    </div>
  </div>
</template>

<script>
let state = {
  appHeaderState: {
    show: true,
    title: "Workmanship",
    showMenu: true,
    showBack: true,
    showLogo: false,
    showTitle: true,
    showSearch: false,
    showCalendar: true,
  },
};

function setState(store) {
  store.dispatch("navigation/setAppHeader", state.appHeaderState);
}

import { mapActions, mapState } from "vuex";
import Vue from "vue";
import lodash from "lodash";
import VueLodash from "vue-lodash";
import apiDetails from "../../../shared/request/apiDetails";
import FloatingButton from "../../../components/common/FloatingButton";
import GenericCodeHelper from "../../../views/common/GenericCodeHelper.vue"
import AutocompleteSearch from "../../../components/common/AutocompleteSearch"
import { defineAsyncComponent } from 'vue'

Vue.use(VueLodash, lodash);

export default {
  name: "workOrderInspectionWorkmanshipId",
  components: {
    FloatingButton,
    GenericCodeHelper,
    AutocompleteSearch,
    "SpecialInstructions": defineAsyncComponent(() => import('../../../components/forms/SpecialInstructions'))
  },
  data() {
    return {
      showFloatingButton: false,
      dialog_sku1: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      workOrderFailCode:[],
      skuHeaders: [
        {
          text: "",
          align: "center",
          value: "skuDisplay",
          width: 200,
        },
        {
          text: "Sample Size",
          align: "center",
          value: "productSampleSize",
        },
        {
          text: "Critical",
          align: "center",
          sortable: false,
          value: "critical",
        },
        {
          text: "Major",
          align: "center",
          sortable: false,
          value: "major",
        },
        {
          text: "Minor",
          align: "center",
          sortable: false,
          value: "minor",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "defectAdd",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "menu",
        },
        // { text: '', value: 'data-table-expand' }
      ],
      workmanship_Result: 3,
      select_result: [
        {
          text: "Within AQL",
          value: "1",
          alias: "Pass"
        },
        {
          text: "Outside AQL",
          value: "2",
          alias: "Fail"
        },
        {
          text: "Pending",
          value: "3",
          alias: "Pending"
        },
        {
          text: "N/A",
          value: "4",
          alias: "N/A"
        },
      ],
      skuList: [
        {
          defectId: "1232131",
          sampleSize: 0,
          critical: 0,
          major: 0,
          minor: 50,
        },
      ],
      datatableRowsPerPage: {
        "footer-props": {
          "items-per-page-options": [-1],
        },
      },
      loadingDf: false,
      showProgressDialog: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      tab: null,
      dialog: false,
      snackbar: false,
      message: "",
      timeout: 3000,
      color: "red darken-2",
      // sku table data
      dialog_sku: false,
      dialog_sku_edit: false,
      showConfirmDialogSku: false,
      showConfirmDialogDefect: false,
      showConfirmDialogClassification: false,
      skuHeader: [
        {
          text: "category",
          align: "center",
          sortable: false,
          value: "defectName.defectCategory.name",
        },
        {
          text: "name",
          align: "center",
          sortable: false,
          value: "defectName.name",
        },
        {
          text: "Description",
          align: "center",
          sortable: false,
          value: "defectDetails",
        },
        {
          text: "Level",
          align: "center",
          sortable: false,
          value: "defectClassification.name",
        },
        {
          text: "Number",
          align: "center",
          sortable: false,
          value: "qty",
        },
        {
          text: "Menu",
          align: "center",
          sortable: false,
          value: "menu",
        },
      ],
      sku_menu: [
        {
          title: "Edit",
          url_name: "workOrderDetailsId",
          id: 1,
        },
        // { title: 'Add Photo', url_name: 'pastInspectionsPreview' },
        {
          title: "Add Defect Photo",
          url_name: "pastInspectionsSignOff",
        },
        {
          title: "Add Inspection Photo from Gallery",
          url_name: "pastInspectionsSignOff",
        },
        {
          title: "Delete",
          url_name: "pastInspectionsSignOff",
        },
      ],
      formDataSku: {
        workOrderProductIds: [],
        workOrderProductId: "",
        category: "",
        name: "",
        detail: "",
        number: 0,
        grade: "",
        defectCode: null,
        clientDefectId: null,
        dclTypeAliasId: null,
      },
      formDataSkuEdit: {
        id: "",
        workOrderProductIds: [],
        workOrderProductId: "",
        defectCategoryId: "",
        defectNameId: "",
        defectDetails: "",
        qty: 0,
        defectClassificationId: "",
        defectCode: null,
        clientDefectId: null,
        dclTypeAliasId: null,
      },
      // defect table data
      dialog_defect: false,
      dialog_defect_edit: false,
      defectHeader: [
        {
          text: "SKU#",
          align: "center",
          sortable: false,
          value: "skuDisplay",
        },
        {
          text: "PO#",
          align: "center",
          sortable: false,
          value: "po",
        },
        {
          text: "category",
          align: "center",
          sortable: false,
          value: "defectName.defectCategory.name",
        },
        {
          text: "Description",
          align: "center",
          sortable: false,
          value: "defectDetails",
        },
        {
          text: "Number",
          align: "center",
          sortable: false,
          value: "qty",
        },
        {
          text: "Menu",
          align: "center",
          sortable: false,
          value: "menu",
        },
      ],

      formDataDefect: {
        workOrderProductIds: [],
        workOrderProductId: "",
        category: "",
        name: "",
        detail: "",
        number: 0,
        grade: "",
        defectCode: null,
        clientDefectId: null,
        dclTypeAliasId: null,
      },
      formDataDefectEdit: {
        id: "",
        workOrderProductIds: [],
        workOrderProductId: "",
        defectCategoryId: "",
        defectNameId: "",
        defectDetails: "",
        qty: 0,
        defectClassificationId: "",
        defectCode: null,
        clientDefectId: null,
        dclTypeAliasId: null,
      },

      // defect classfication table data
      dialog_classFication: false,
      dialog_classFication_edit: false,
      classFicationHeader: [
        {
          text: "SKU#",
          align: "center",
          sortable: false,
          value: "skuDisplay",
        },
        {
          text: "PO#",
          align: "center",
          sortable: false,
          value: "po",
        },
        {
          text: "category",
          align: "center",
          sortable: false,
          value: "defectName.defectCategory.name",
        },
        {
          text: "Name",
          align: "center",
          sortable: false,
          value: "defectName.name",
        },
        {
          text: "Description",
          align: "center",
          sortable: false,
          value: "defectDetails",
        },
        {
          text: "Number",
          align: "center",
          sortable: false,
          value: "qty",
        },
        {
          text: "Menu",
          align: "center",
          sortable: false,
          value: "menu",
        },
      ],
      formDataClassFication: {
        workOrderProductIds: [],
        workOrderProductId: "",
        category: "",
        name: "",
        detail: "",
        number: 0,
        grade: "",
        defectCode: null,
        clientDefectId: null,
        dclTypeAliasId: null,
      },
      formDataClassFicationEdit: {
        id: "",
        workOrderProductIds: [],
        workOrderProductId: "",
        defectCategoryId: "",
        defectNameId: "",
        defectDetails: "",
        qty: 0,
        defectClassificationId: "",
        defectCode: null,
        clientDefectId: null,
        dclTypeAliasId: null,
      },

      workOrder: {
        resources: [],
        workOrderInspectionSections: [],
      },
      WorkOrderList: [],
      Products: [],
      DefectClassifications: [],
      DefectCategories: [],
      DefectNames: [],
      WorkOrderProductDefects: [],
      files: [],
      workOrderId: "",
      delItem: {
        id: "",
      },
      editItem: {
        id: "",
      },
      paginationObject: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
      },
      samplingPlan: [],

      sectionId: 5,
      // showButton: false,
      samplingMethodology: "",

      sectionData: {
        Workmanship: {
          id: 5,
          name: "Workmanship",
          isAuthorized: false,
          isSectionSubmitted: true,
        },
      },

      filterWorkOrderDefectSkuData: [],
      clientDefectListSection: [],
      // defectItemList: {},
      dclTypeAliases: [],
      showDclType: false,



      // true if submit button is clicked
      sectionSubmitted: false,
      productsClone: null,
      workOrderRatingOptions: [],
      defaultFailCodeOptions: ['UL0', 'UL1', 'UL2', 'UL3', 'UL4', 'UL5', 'UL6', 'UL7', 'UL8', 'UL9', 'UL10'],
      isFormDirty: false,
      isFormReadyCheck: false,

      productSelectionToggle: false,
      isGenericCodes: false,
      defectToEdit: null,
      isSKUResultValid: true,
      isDefectResultValid: true,
      isDefectClassificationValid: true,
      getInspectionPhotoDescriptionUrl: "",
      itemText: "description",
      itemValue: "description",
      isOutlined: false,
      label: "Enter defect details",
      allowManualInput: true,
      hideSelected: true,
      existingValue: null
    };
  },

  beforeDestroy() {
    if(!this.loadingDf) {
      this.saveData();
    }
  },
  async activated() {
    if (window.navigator.onLine !== true) {
      let response = await caches.match(this.apiList.workOrderDetailsWorkmanship + "/" + this.$route.params.id);
      if (!response) {
        this.$router.replace({
          path: "/error",
          name: "error",
        });
      }
    }
    this.remapSamplingPlan()
  },
  async mounted() {
    this.getInspectionPhotoDescriptionUrl = this.apiList.getInspectionPhotoDescriptionUrl + "/" + this.$route.params.id;
    setState(this.$store);
    if (window.navigator.onLine !== true) {
      let response = await caches.match(this.apiList.workOrderDetailsWorkmanship + "/" + this.$route.params.id);
      if (!response) {
        this.$router.replace({
          path: "/error",
          name: "error",
        });
      }
    }

    const wormanshipDefectList = this.clientDefectList?.filter((x) => x.dclType == "Workmanship")?.map((x) => x.clientDefects) || [];
    this.clientDefectListSection = wormanshipDefectList.length > 0 ? wormanshipDefectList[0] : [];
    this.clientDefectListSection = this.clientDefectListSection.filter(x => x.isProductCategoryActive);

    this.loadingDf = true;
     this.$store.dispatch("navigation/setLoadingPage",{show: this.loadingDf });

      this.utilityFn.getServerData("get", this.apiList.getAllWorkOrderPendingFailCode, {}, (res) => {
      this.workOrderFailCode = res.data.data.filter((x) => x.type == "Fail");
      // this.workOrderFailAndPendingCode()
    });

    // //console.log("clientDefectListSection", this.clientDefectListSection);

    // // this.showButton = this.$route.params.showButton
    // // this.sectionData.Workmanship.isAuthorized = this.$route.params.isAuthorized
    // this.samplingPlan = JSON.parse(localStorage.getItem("samplingPlanList"));

    // this.showFloatingButton = this.samplingPlan == undefined ? false : this.samplingPlan.showFabButton == false ? false : true;
    // this.workOrderId = this.$route.params.id;

    // let result = await this.utilityFn.getServerDataAsync("get", this.apiList.workOrderDetailsWorkmanship + "/" + this.workOrderId, {});

    // let prodresult = await this.utilityFn.getServerDataAsync("get", this.apiList.getWorkOrderProductDefects, { workOrderId: this.workOrderId });

    // if (Object.keys(result).length != 0 && Object.keys(prodresult).length != 0) {
    //   this.workOrderDisplayId = result.data[0].workOrderDisplayId;
    //   this.jobDisplayId = result.data[0].jobDisplayId;
    //   this.workOrder = result.data[0];
    //   this.Products = this.workOrder.products;
    //   if (this.samplingPlan) {
    //     this.workOrder.workmanshipSampleSizeCritical = this.workOrder.workmanshipSampleSizeCritical;
    //     this.workOrder.workmanshipSampleSizeMajor = this.workOrder.workmanshipSampleSizeMajor;
    //     this.workOrder.workmanshipSampleSizeMinor = this.workOrder.workmanshipSampleSizeMinor;
    //   }

    //   this.WorkOrderList = this.workOrder;
    //   this.samplingMethodology = this.workOrder.samplingMethodology;
    //   this.WorkOrderList.allowedCritical = this.WorkOrderList.allowedCritical;
    //   this.WorkOrderList.allowedMajor = this.WorkOrderList.allowedMajor;
    //   this.WorkOrderList.allowedMinor = this.WorkOrderList.allowedMinor;
    //   this.WorkOrderList.rejectedCritical = this.WorkOrderList.rejectedCritical;
    //   this.WorkOrderList.rejectedMajor = this.WorkOrderList.rejectedMajor;
    //   this.WorkOrderList.rejectedMinor = this.WorkOrderList.rejectedMinor;

    //   console.log("getWorkOrderProductDefects0", prodresult);
    //   if (this.isDCLOrGeneric()) {
    //     //DCL Defect
    //     this.WorkOrderProductDefects = prodresult.data.filter((x) => x.clientDefectId != null && x.sectionId != 2 && x.sectionId != 3);
    //   } else {
    //     this.WorkOrderProductDefects = prodresult.data.filter((x) => x.clientDefectId == null && x.defectCategoryId != 7 && x.defectCategoryId != 8);
    //   }
    //   this.WorkOrderProductDefects.map((x) => {
    //     let products = this.Products.filter((y) => y.id == x.workOrderProductId);
    //     x.po = products[0].po;
    //     return x;
    //   });
    //   this.Products.forEach((item) => {
    //     this.$set(item, "itemList", this.filterWorkOrderDefectSku(item.id));
    //   });
    //   this.Products.map((x) => {
    //     let val = this.samplingPlan.SKUSamplingList.filter((y) => y.id === x.id);
    //     (x.criticalAC = x.workOrderProductResults.allowedCriticalPO ),
    //       (x.majorAC = x.workOrderProductResults.allowedMajorPO ),
    //       (x.minorAC = x.workOrderProductResults.allowedMinorPO),
    //       (x.criticalRE = x.workOrderProductResults.rejectedCriticalPO ),
    //       (x.majorRE = x.workOrderProductResults.rejectedMajorPO ),
    //       (x.minorRE = x.workOrderProductResults.rejectedMinorPO ),
    //       (x.productSampleSize = x.workOrderProductResults.sampleSizeProductWorkmanship == null ? val[0].productSampleSize : x.workOrderProductResults.sampleSizeProductWorkmanship),
    //       (x.page = 1),
    //       (x.pageCount = 0),
    //       (x.itemsPerPage = 5),
    //       (x.skuDisplay = x.skuDisplay + " - " + x.po);
    //     return x;
    //   });
    //   this.productsClone = _.cloneDeep(this.Products);
    //   this.WorkOrderList.workmanshipSampleSize =
    //     this.WorkOrderList.workmanshipSampleSize == null
    //       ? this.getWorkmanshipSampleSize()
    //       : this.WorkOrderList.workmanshipSampleSize;
      
    // }
    
    await this.LoadData(true);
      

    this.utilityFn.getServerData("get", `${this.apiList.getCompanyDclTypeAliases}/${this._workOrderDetails.clientId}/0`, {}, (result) => {
      this.dclTypeAliases = result.data && result.data.data.filter(x => x.isActive);
    });

    this.utilityFn.getServerData("get", this.apiList.getIsApplyToDclTypeAlias + '/' + this._workOrderDetails.clientId, {  }, (result) => {
      this.showDclType = result.data.data;
    });

    this.utilityFn.getServerData("get", this.apiList.getDefectNames, {}, (result) => {
      this.DefectNames = result.data.data;
    });
    this.utilityFn.getServerData("get", this.apiList.getDefectCategories, {}, (result) => {
      this.DefectCategories = result.data.data.filter((x) => x.name != "Functional Check Measurement" && x.name != "Package Check");
    });
    this.utilityFn.getServerData("get", this.apiList.getDefectClassifications, {}, (result) => {
      this.DefectClassifications = result.data.data;
      this.DefectClassifications.forEach((item) => {
        this.$set(item, "itemList", this.filterWorkOrderDefectClassification(item.id));
      });
    });

    this.sectionData = this._sectionData.Workmanship ? this._sectionData : this.sectionData;

    this.loadingDf = false;
    this.$store.dispatch("navigation/setLoadingPage",{show: this.loadingDf });
    
    await this.loadWorkOrderRatingOptions();
  },
  computed: {
    ...mapState("inspection", {
      store_token: (state) => state.token,
      _sectionData: (state) => state.sectionData,
      clientDefectList: (state) => state.clientDefectList,
      _workOrderDetails: (state) => state.workOrderDetails,
      clientDefectsLoadingStatus: (state) => state.clientDefectsLoadingStatus,
      _samplingPlanLists: (state) => state.samplingPlanLists,
      inspectionSummarySectionResult: (state) => state.inspectionSummarySectionResult,
    }),

    foundSummary() {
       const foundCritical = this.WorkOrderProductDefects
                                            .filter(x => x.defectClassificationId == 1)
                                            .reduce((total, item) => Number(total) + Number(item.qty), 0);
        const foundMajor = this.WorkOrderProductDefects
                                            .filter(x => x.defectClassificationId == 2)
                                            .reduce((total, item) => Number(total) + Number(item.qty), 0);
        const foundMinor = this.WorkOrderProductDefects
                                            .filter(x => x.defectClassificationId == 3)
                                            .reduce((total, item) => Number(total) + Number(item.qty), 0);

        return { foundCritical, foundMajor, foundMinor };
    },

    //Tab 1 Add formDataSku
    filterSkuDefectNames() {
      if (this.isDCLOrGeneric()) {
        return this.clientDefectListSection.filter((x) => x.productCategoryId == this.formDataSku.category  && (!this.formDataSku.dclTypeAliasId || this.formDataSku.dclTypeAliasId == x.companyDclTypeAliasId) );
      } else {
        return this.DefectNames.filter((defect) => {
          return defect.defectCategoryId === this.formDataSku.category;
        });
      }
    },
    filteredSkuDefectCodeList: function() {
      if (this.isDCLOrGeneric()) {
        return this.clientDefectListSection.filter((x) => x.productCategoryId == this.formDataSku.category && (!this.formDataSku.dclTypeAliasId || this.formDataSku.dclTypeAliasId == x.companyDclTypeAliasId));
      } else {
        return this.DefectNames.filter((x) => x.defectCategoryId == this.formDataSku.category || x.defectName == this.formDataSku.name);
      }
    },
    filteredSkuDefectClassificationList: function() {
      if (this.isDCLOrGeneric()) {
        if (this.formDataSku.name) {
          return this.clientDefectListSection.find((x) => x.id == this.formDataSku.name)?.defectSeverities;
        }
      } else {
        return this.DefectClassifications;
      }
      return this.DefectClassifications;
    },
    filteredSkuDefectList() {
      if (this.isDCLOrGeneric()) {
        return this.clientDefectListSection.filter((x) => (!this.formDataSku.category ||x.productCategoryId == this.formDataSku.category)
                                                        && (!this.formDataSku.code || this.formDataSku.code == x.defectCode )
                                                        && (!this.formDataSku.name || this.formDataSku.name == x.id));
      } else {
        return this.DefectNames.filter((x) => x.defectCategoryId == this.formDataSku.category || x.defectName == this.formDataSku.name);
      }
    },
    filteredSkuDclTypeAliases() {
      // const defectList = this.filteredSkuDefectList.filter((x) => !this.formDataSku.category || x.productCategoryId == this.formDataSku.category);
      const uniqueDclTypeIds = _.uniq(this.filteredSkuDefectList.map(x => x.companyDclTypeAliasId));
      return this.filteredDclTypeAliases.filter(x => uniqueDclTypeIds.indexOf(x.id) !== -1);
    },

    //Tab 1 Edit formDataSkuEdit
    filterSkuDefectNamesEdit() {
      if (this.isDCLOrGeneric()) {
        return this.clientDefectListSection.filter((x) => x.productCategoryId == this.formDataSkuEdit.defectCategoryId && (!this.formDataSkuEdit.dclTypeAliasId || this.formDataSkuEdit.dclTypeAliasId == x.companyDclTypeAliasId));
      } else {
        return this.DefectNames.filter((defect) => {
          return defect.defectCategoryId === this.formDataSkuEdit.defectCategoryId;
        });
      }
    },
    filterSkuDefectCodeListEdit: function() {
      if (this.isDCLOrGeneric()) {
        return this.clientDefectListSection.filter((x) => x.productCategoryId == this.formDataSkuEdit.defectCategoryId && (!this.formDataSkuEdit.dclTypeAliasId || this.formDataSkuEdit.dclTypeAliasId == x.companyDclTypeAliasId));
      } else {
        return this.DefectNames.filter((x) => x.defectCategoryId == this.formDataSkuEdit.defectCategoryId || x.defectName == this.formDataSkuEdit.defectNameId);
      }
    },
    filterSkuDefectClassificationListEdit: function() {
      if (this.isDCLOrGeneric()) {
        if (this.formDataSkuEdit.defectNameId) {
          return this.clientDefectListSection.find((x) => x.id == this.formDataSkuEdit.defectNameId)?.defectSeverities;
        }
      } else {
        return this.DefectClassifications;
      }
      return this.DefectClassifications;
    },
    filteredSkuDefectListEdit() {
      if (this.isDCLOrGeneric()) {
        return this.clientDefectListSection.filter((x) => (!this.formDataSkuEdit.defectCategoryId || x.productCategoryId == this.formDataSkuEdit.defectCategoryId)
                                                        && (!this.formDataSkuEdit.defectCode || this.formDataSkuEdit.defectCode == x.defectCode )
                                                        && (!this.formDataSkuEdit.defectNameId || this.formDataSkuEdit.defectNameId == x.id));
      } else {
        return this.DefectNames.filter((x) => x.defectCategoryId == this.formDataSkuEdit.defectCategoryId || x.defectName == this.formDataSkuEdit.defectNameId);
      }
    },
    filteredSkuDclTypeAliasesEdit() {
      // const defectList = this.filteredSkuDefectListEdit.filter((x) => !this.formDataSkuEdit.category || x.productCategoryId == this.formDataSkuEdit.defectCategoryId);
      const uniqueDclTypeIds = _.uniq(this.filteredSkuDefectListEdit.map(x => x.companyDclTypeAliasId));
      return this.filteredDclTypeAliases.filter(x => uniqueDclTypeIds.indexOf(x.id) !== -1);
    },

    //Tab 2 Add formDataDefect
    filterDataDefectNames() {
      if (this.isDCLOrGeneric()) {
        return this.clientDefectListSection.filter((x) => x.productCategoryId == this.formDataDefect.category && (!this.formDataDefect.dclTypeAliasId || this.formDataDefect.dclTypeAliasId == x.companyDclTypeAliasId));
      } else {
        return this.DefectNames.filter((defect) => {
          return defect.defectCategoryId === this.formDataDefect.category;
        });
      }
    },
    filteredDataDefectCodeList: function() {
      if (this.isDCLOrGeneric()) {
        return this.clientDefectListSection.filter((x) => x.productCategoryId == this.formDataDefect.category && (!this.formDataDefect.dclTypeAliasId || this.formDataDefect.dclTypeAliasId == x.companyDclTypeAliasId));
      } else {
        return this.DefectNames.filter((x) => x.defectCategoryId == this.formDataDefect.category || x.defectName == this.formDataDefect.name);
      }
    },
    filteredDataDefectClassificationList: function() {
      if (this.isDCLOrGeneric()) {
        if (this.formDataDefect.name) {
          return this.clientDefectListSection.find((x) => x.id == this.formDataDefect.name)?.defectSeverities;
        }
      } else {
        return this.DefectClassifications;
      }
      return this.DefectClassifications;
    },

    //Tab 2 Edit formDataDefectEdit
    filterDataDefectNamesEdit() {
      if (this.isDCLOrGeneric()) {
        return this.clientDefectListSection.filter((x) => x.productCategoryId == this.formDataDefectEdit.defectCategoryId && (!this.formDataDefectEdit.dclTypeAliasId || this.formDataDefectEdit.dclTypeAliasId == x.companyDclTypeAliasId));
      } else {
        return this.DefectNames.filter((defect) => {
          return defect.defectCategoryId === this.formDataDefectEdit.defectCategoryId;
        });
      }
    },
    filterDataDefectCodeListEdit: function() {
      if (this.isDCLOrGeneric()) {
        return this.clientDefectListSection.filter((x) => x.productCategoryId == this.formDataDefectEdit.defectCategoryId && (!this.formDataDefectEdit.dclTypeAliasId || this.formDataDefectEdit.dclTypeAliasId == x.companyDclTypeAliasId));
      } else {
        return this.DefectNames.filter((x) => x.defectCategoryId == this.formDataDefectEdit.defectCategoryId || x.defectName == this.formDataDefectEdit.defectNameId);
      }
    },
    filterDataDefectClassificationListEdit: function() {
      if (this.isDCLOrGeneric()) {
        if (this.formDataDefectEdit.defectNameId) {
          return this.clientDefectListSection.find((x) => x.id == this.formDataDefectEdit.defectNameId)?.defectSeverities;
        }
      } else {
        return this.DefectClassifications;
      }
      return this.DefectClassifications;
    },

    //Tab 3 Add formDataClassFication
    filterDataClassFicationNames() {
      if (this.isDCLOrGeneric()) {
        return this.clientDefectListSection.filter((x) => x.productCategoryId == this.formDataClassFication.category && (!this.formDataClassFication.dclTypeAliasId || this.formDataClassFication.dclTypeAliasId == x.companyDclTypeAliasId));
      } else {
        return this.DefectNames.filter((defect) => {
          return defect.defectCategoryId === this.formDataClassFication.category;
        });
      }
    },
    filteredDataClassFicationCodeList: function() {
      if (this.isDCLOrGeneric()) {
        return this.clientDefectListSection.filter((x) => x.productCategoryId == this.formDataClassFication.category && (!this.formDataClassFication.dclTypeAliasId || this.formDataClassFication.dclTypeAliasId == x.companyDclTypeAliasId));
      } else {
        return this.DefectNames.filter((x) => x.defectCategoryId == this.formDataClassFication.category || x.defectName == this.formDataClassFication.name);
      }
    },
    filteredDataClassFicationClassificationList: function() {
      if (this.isDCLOrGeneric()) {
        if (this.formDataClassFication.name) {
          return this.clientDefectListSection.find((x) => x.id == this.formDataClassFication.name)?.defectSeverities;
        }
      } else {
        return this.DefectClassifications;
      }
      return this.DefectClassifications;
    },

    //Tab 3 Edit formDataClassFicationEdit
    filterDataClassFicationNamesEdit() {
      if (this.isDCLOrGeneric()) {
        return this.clientDefectListSection.filter((x) => x.productCategoryId == this.formDataClassFicationEdit.defectCategoryId && (!this.formDataClassFicationEdit.dclTypeAliasId || this.formDataClassFicationEdit.dclTypeAliasId == x.companyDclTypeAliasId));
      } else {
        return this.DefectNames.filter((defect) => {
          return defect.defectCategoryId === this.formDataClassFicationEdit.defectCategoryId;
        });
      }
    },
    filteredDataClassFicationCodeListEdit: function() {
      if (this.isDCLOrGeneric()) {
        return this.clientDefectListSection.filter((x) => x.productCategoryId == this.formDataClassFicationEdit.defectCategoryId && (!this.formDataClassFicationEdit.dclTypeAliasId || this.formDataClassFicationEdit.dclTypeAliasId == x.companyDclTypeAliasId));
      } else {
        return this.DefectNames.filter((x) => x.defectCategoryId == this.formDataClassFicationEdit.defectCategoryId || x.defectName == this.formDataClassFicationEdit.defectNameId);
      }
    },
    filterDataClassFicationClassificationListEdit: function() {
      if (this.isDCLOrGeneric()) {
        if (this.formDataClassFicationEdit.defectNameId) {
          return this.clientDefectListSection.find((x) => x.id == this.formDataClassFicationEdit.defectNameId)?.defectSeverities;
        }
      } else {
        return this.DefectClassifications;
      }
      return this.DefectClassifications;
    },

    filteredDclTypeAliases() {
      return this.dclTypeAliases.filter(x => x.dclType.name === 'Workmanship');
    },

    defectItemList() {
      let defect = {};

      Object.keys(this.groupByDefectName).forEach(key => {
        defect[key] = {
          critical: this.filterWorkOrderDefectName(key, 1),
          major: this.filterWorkOrderDefectName(key, 2),
          minor: this.filterWorkOrderDefectName(key, 3),
        };
      });

      return defect;
    },

    groupByDefectName() {
      var a1 = _.groupBy(this.WorkOrderProductDefects, "defectName.name");
      return a1;
    },
    groupByDefectClassification() {
      //  return _.orderBy(arrays, 'name', 'asc')
      return _.groupBy(this.WorkOrderProductDefects, "defectClassification.name");
    },
    groupByDefectSku() {
      return _.groupBy(this.WorkOrderProductDefects, "skuDisplay");
    },

    disableSaveSku() {
      let val = false;
      if (this.formDataSku.workOrderProductIds.length === 0 || this.formDataSku.category == "" || this.formDataSku.name == "" || this.formDataSku.detail == "" || this.formDataSku.number == "0" || this.formDataSku.grade == ""
                                                          || this.formDataSku.category == null || this.formDataSku.name == null || this.formDataSku.detail == null || this.formDataSku.number == null || this.formDataSku.grade == null) {
        val = true;
      }
      return val;
    },
    disableUpdateSku() {
      let val = false;
      console.log(this.formDataSkuEdit);
      if (
        this.formDataSkuEdit.workOrderProductIds.length  === 0 ||
        this.formDataSkuEdit.defectCategoryId == "" ||
        this.formDataSkuEdit.defectNameId == "" ||
        this.formDataSkuEdit.defectDetails == "" ||
        this.formDataSkuEdit.qty == "0" ||
        this.formDataSkuEdit.defectClassificationId == "" ||
        this.formDataSkuEdit.defectNameId == null ||
        this.formDataSkuEdit.defectCode == null
      ) {
        val = true;
      }
      return val;
    },
    disableSaveDefect() {
      let val = false;
      if (this.formDataDefect.workOrderProductIds.length === 0 || this.formDataDefect.category == "" || this.formDataDefect.name == "" || this.formDataDefect.detail == "" || this.formDataDefect.number == "0" || this.formDataDefect.grade == "") {
        val = true;
      }
      return val;
    },
    disableUpdateDefect() {
      let val = false;
      if (
        this.formDataDefectEdit.workOrderProductIds.length === 0 ||
        this.formDataDefectEdit.defectCategoryId == "" ||
        this.formDataDefectEdit.defectNameId == "" ||
        this.formDataDefectEdit.defectNameId == null ||
        this.formDataDefectEdit.defectDetails == "" ||
        this.formDataDefectEdit.qty == "0" ||
        this.formDataDefectEdit.defectClassificationId == ""
      ) {
        val = true;
      }
      return val;
    },
    disableSaveClassification() {
      let val = false;
      if (
        this.formDataClassFication.workOrderProductIds.length === 0 ||
        this.formDataClassFication.category == "" ||
        this.formDataClassFication.name == "" ||
        this.formDataClassFication.detail == "" ||
        this.formDataClassFication.number == "0" ||
        this.formDataClassFication.grade == ""
      ) {
        val = true;
      }
      return val;
    },
    disableUpdateClassification() {
      let val = false;
      if (
        this.formDataClassFicationEdit.workOrderProductIds.length === 0 ||
        this.formDataClassFicationEdit.defectCategoryId == "" ||
        this.formDataClassFicationEdit.defectNameId == "" ||
        this.formDataClassFicationEdit.defectDetails == "" ||
        this.formDataClassFicationEdit.qty == "0" ||
        this.formDataClassFicationEdit.defectClassificationId == ""
      ) {
        val = true;
      }
      return val;
    },

    sectionDetail() {
      let section = this._workOrderDetails.workOrderInspectionSections.find((x) => x.sectionId == this.sectionId);
      if (section) {
        section.submitterId = this.utilityFn.getResourceId().toLowerCase();
      } else {
        section = {
          workOrderId: this.workOrder.workOrderId,
          sectionId: this.sectionId,
          isHidden: false,
          submitterId: this.utilityFn.getResourceId().toLowerCase(),
        };
      }

      return section;
    },

    isSectionDisabled() {
      return this.workOrder.resources.length == 0 || (this.workOrder.resources.length > 1 && this.sectionDetail.resourceId.toLowerCase() != this.utilityFn.getResourceId().toLowerCase()) || this.sectionDetail.isHidden   || (!!this.sectionDetail.submittedDate && this.workOrder.resources.length > 1);
    },

    showButton() {
      return this.workOrder.resources.length > 1;
    },

    isDataLoaded() {
      return this.Products.length > 0;
    },

    failCodeOptions() {
      return this.getResultCodes(this.WorkOrderList.workmanshipResult);
    }
  },
  methods: {
    onDefectNameChange(tab) {
      switch (tab) {
        case "SkuAdd":
          if (this.formDataSku.name) {
            if (this.isDCLOrGeneric()) {
              this.formDataSku.defectCode = this.filteredSkuDefectCodeList.find((x) => x.id == this.formDataSku.name).defectCode;
            } else {
              this.formDataSku.defectCode = this.DefectNames.find((x) => x.id == this.formDataSku.name).code;
            }

            if (this.filteredSkuDefectList.findIndex(x => x.companyDclTypeAliasId == this.formDataSku.dclTypeAliasId) === -1) {
              this.formDataSku.dclTypeAliasId = null;
            }
            
          }
          break;
        case "SkuEdit":
          if (this.formDataSkuEdit.defectNameId) {
            if (this.isDCLOrGeneric()) {
              this.formDataSkuEdit.defectCode = this.filterSkuDefectCodeListEdit.find((x) => x.id == this.formDataSkuEdit.defectNameId).defectCode;
            } else {
              this.formDataSkuEdit.defectCode = this.DefectNames.find((x) => x.id == this.formDataSkuEdit.defectNameId).code;
            }
          }
          break;

        //defect
        case "DefectAdd":
          if (this.formDataDefect.name) {
            if (this.isDCLOrGeneric()) {
              this.formDataDefect.defectCode = this.filteredDataDefectCodeList.find((x) => x.id == this.formDataDefect.name).defectCode;
            } else {
              this.formDataDefect.defectCode = this.DefectNames.find((x) => x.id == this.formDataDefect.name).code;
            }
          }
          break;
        case "DefectEdit":
          if (this.formDataDefectEdit.defectNameId) {
            if (this.isDCLOrGeneric()) {
              this.formDataDefectEdit.defectCode = this.filterDataDefectCodeListEdit.find((x) => x.id == this.formDataDefectEdit.defectNameId).defectCode;
            } else {
              this.formDataSkuEdit.defectCode = this.DefectNames.find((x) => x.id == this.formDataSkuEdit.defectNameId).code;
            }
          }
          break;

        //classification
        case "ClassFicationAdd":
          if (this.formDataClassFication.name) {
            if (this.isDCLOrGeneric()) {
              this.formDataClassFication.defectCode = this.filteredDataClassFicationCodeList.find((x) => x.id == this.formDataClassFication.name).defectCode;
            } else {
              this.formDataClassFication.defectCode = this.DefectNames.find((x) => x.id == this.formDataClassFication.name).code;
            }
          }
          break;
        case "ClassFicationEdit":
          if (this.formDataClassFicationEdit.defectNameId) {
            if (this.isDCLOrGeneric()) {
              this.formDataClassFicationEdit.defectCode = this.filteredDataClassFicationCodeListEdit.find((x) => x.id == this.formDataClassFicationEdit.defectNameId).defectCode;
            } else {
              this.formDataClassFicationEdit.defectCode = this.DefectNames.find((x) => x.id == this.formDataClassFicationEdit.defectNameId).code;
            }
          }
          break;
      }
    },

    onDefectCodeChange(tab) {
      switch (tab) {
        case "SkuAdd":
          if (this.formDataSku.defectCode) {
            if (this.isDCLOrGeneric()) {
              this.formDataSku.name = this.filterSkuDefectNames.find((x) => x.defectCode == this.formDataSku.defectCode).id;
            } else {
              this.formDataSku.name = this.DefectNames.find((x) => x.code == this.formDataSku.defectCode).id;
            }

            if (this.filteredSkuDefectList.findIndex(x => x.companyDclTypeAliasId == this.formDataSku.dclTypeAliasId) === -1) {
              this.formDataSku.dclTypeAliasId = null;
            }
          }
          break;
        case "SkuEdit":
          if (this.formDataSkuEdit.defectCode) {
            if (this.isDCLOrGeneric()) {
              this.formDataSkuEdit.defectNameId = this.filterSkuDefectNamesEdit.find((x) => x.defectCode == this.formDataSkuEdit.defectCode).id;
            } else {
              this.formDataSkuEdit.defectNameId = this.DefectNames.find((x) => x.code == this.formDataSkuEdit.defectCode).id;
            }
          }
          break;

        case "DefectAdd":
          if (this.formDataDefect.defectCode) {
            if (this.isDCLOrGeneric()) {
              this.formDataDefect.name = this.filterDataDefectNames.find((x) => x.defectCode == this.formDataDefect.defectCode).id;
            } else {
              this.formDataDefect.name = this.DefectNames.find((x) => x.code == this.formDataDefect.defectCode).id;
            }
          }
          break;
        case "DefectEdit":
          if (this.formDataDefectEdit.defectCode) {
            if (this.isDCLOrGeneric()) {
              this.formDataDefectEdit.defectNameId = this.filterDataDefectNamesEdit.find((x) => x.defectCode == this.formDataDefectEdit.defectCode).id;
            } else {
              this.formDataDefectEdit.defectNameId = this.DefectNames.find((x) => x.code == this.formDataDefectEdit.defectCode).id;
            }
          }
          break;

        case "ClassFicationAdd":
          if (this.formDataClassFication.defectCode) {
            if (this.isDCLOrGeneric()) {
              this.formDataClassFication.name = this.filterDataClassFicationNames.find((x) => x.defectCode == this.formDataClassFication.defectCode).id;
            } else {
              this.formDataClassFication.name = this.DefectNames.find((x) => x.code == this.formDataClassFication.defectCode).id;
            }
          }
          break;
        case "ClassFicationEdit":
          if (this.formDataClassFicationEdit.defectCode) {
            if (this.isDCLOrGeneric()) {
              this.formDataClassFicationEdit.defectNameId = this.filterDataClassFicationNamesEdit.find((x) => x.defectCode == this.formDataClassFicationEdit.defectCode).id;
            } else {
              this.formDataClassFicationEdit.defectNameId = this.DefectNames.find((x) => x.code == this.formDataClassFicationEdit.defectCode).id;
            }
          }
          break;
      }
    },

    isDCLOrGeneric() {
      if (this.clientDefectListSection && this.clientDefectListSection.length > 0) {
        return true;
      } else {
        return false;
      }
    },

    getAQLSZ(aqlValue) {
      let varyingAQL = this.samPlan.getVaryingAQL(this.samplingPlan.totalProductPOQty, this.workOrder.aqlLevels, this.workOrder.inspectionLevel, aqlValue, this.workOrder.samplingPlan);
      return varyingAQL.sampleSize;
    },
    returnNumberSampleSize(obj, name) {
      if (obj[name] != "") {
        obj[name] = obj[name].replace(/[^-\d]/g, ""); //清除“数字”和“.”以外的字符
      }
    },
    returnNumber(obj, name) {
      if (obj[name]) {
        obj[name] = obj[name].toString().replace(/[^\d]/g, ""); //清除“数字”和“.”以外的字符
        obj[name] = obj[name].replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
        obj[name] = obj[name]
          .replace(".", "$#$")
          .replace(/\./g, "")
          .replace("$#$", ".");
      }
      console.log(obj[name]);
    },
    workmanshipResult() {
      let result = "1";
      if ((this.WorkOrderList.aqlCritical == "N/A" || this.WorkOrderList.aqlMajor == "N/A" || this.WorkOrderList.aqlMinor == "N/A") && this.WorkOrderList.workmanshipResult == null) {
        result = "4";
      } else {
        if (this._samplingPlanLists.samplingMethodology === "Combined Sample Size") {
          if (this.WorkOrderProductDefects.length > 0) {
            if ((this.WorkOrderList.rejectedCritical <= this.foundSummary.foundCritical && this.foundSummary.foundCritical !=0) 
                  || (this.WorkOrderList.rejectedMajor <= this.foundSummary.foundMajor && this.foundSummary.foundMajor != 0)
                  || this.WorkOrderList.rejectedMinor <= this.foundSummary.foundMinor && this.foundSummary.foundMinor != 0) {
              result = "2";
            } else {
              result = "1";
            }
          }
        } else if (this._samplingPlanLists.samplingMethodology === "Separated Sample Size" || this._samplingPlanLists.samplingMethodology === null) {
          result = "1";
          if (this.Products.filter((x) => x.criticalRE <= this.foundCriticalPerSku(x.id) && this.foundCriticalPerSku(x.id) != 0 
                  ||  x.majorRE <= this.foundMajorPerSku(x.id) && this.foundMajorPerSku(x.id) !=0
                  ||  x.minorRE <= this.foundMinorPerSku(x.id) && this.foundMinorPerSku(x.id) != 0).length > 0) {
            result = "2";
          }
        }
      }
      return result;
    },
    getSkuPo(workOrderProductId) {
      return this.Products.find((x) => x.id == workOrderProductId);
    },
    ChangeResult() {
      this.WorkOrderList.workmanshipResultCode = null;
      var other = {
        headers: {
          wresult: this.store_token,
          "Content-Type": "application/json",
        },
      };
      const data = [
        {
          op: "replace",
          path: "/workmanshipSampleSize",
          value: this.WorkOrderList.workmanshipSampleSize,
        },
        {
          op: "replace",
          path: "/workmanshipresult",
          value: this.WorkOrderList.workmanshipResult,
        },
        {
          op: "replace",
          path: "/workmanshipSampleSizeCritical",
          value: this.workOrder.workmanshipSampleSizeCritical,
        },
        {
          op: "replace",
          path: "/workmanshipSampleSizeMajor",
          value: this.workOrder.workmanshipSampleSizeMajor,
        },
        {
          op: "replace",
          path: "/workmanshipSampleSizeMinor",
          value: this.workOrder.workmanshipSampleSizeMinor,
        },
        {
          op: "replace",
          path: "/NotCountedDefects",
          value: this.workOrder.notCountedDefects,
        },
        {
          op: "replace",
          path: "/WorkmanshipPendingFailCodeId",
          value:  this.WorkOrderList.workmanshipResult == '2' ? this.workOrder.workmanshipPendingFailCodeId : null,
        },
        {
          op: "replace",
          path: "/WorkmanshipResultCode",
          value: this.WorkOrderList.workmanshipResult == '2' ? this.WorkOrderList.workmanshipResultCode : null,
        },
        {
          op: "replace",
          path: "/WorkmanshipResultRemarks",
          value:  this.workOrder.workmanshipResultRemarks,
        },
      ];

      this.onChange();

      this.utilityFn.getServerData(
        "patch",
        this.apiList.patchWorkOrderResults + "/" + this.workOrderId + "/5",
        data,
        (res) => {},
        (errFn) => {}
      );
    },

    getWorkmanshipSampleSize(){

      const sampling = this.samPlan.getSamplingPlanByInspectionLevelAndSamplingMethodology(this.workOrder.aqlLevels, this.workOrder.samplingMethodology)
      if (sampling) {
        return sampling.totalProductSampleSize;
      }
      return null

    },


    getworkmanshipResult() {
      let result = "Within AQL";
      if (this.WorkOrderProductDefects.length > 0) {
        if (this.WorkOrderList.allowedCritical < this.foundSummary.foundCritical || this.WorkOrderList.allowedMajor < this.foundSummary.foundMajor || this.WorkOrderList.allowedMinor < this.foundSummary.foundMinor) {
          result = "Outside AQL";
        } else {
          result = "Within AQL";
        }
      }
      return result;
    },
    hideAllowed: function() {
      let val = false;
      if (this._samplingPlanLists.SKUSamplingList.length > 1 && this._samplingPlanLists.samplingMethodology === "Combined Sample Size") {
        val = true;
      } else {
        val = false;
      }
      return val;
    },
    filterSku(id) {
      let products = this.Products.filter((prod) => prod.id === id);
      return products;
    },
    foundCriticalPerSku(id) {
      let defects = this.WorkOrderProductDefects.filter((defect) => {
        return defect.defectClassificationId == 1 && defect.workOrderProductId === id;
      });
      let total = defects.reduce(function(prev, cur) {
        return Number(prev) + Number(cur.qty);
      }, 0);
      return total;
    },
    foundMajorPerSku(id) {
      let defects = this.WorkOrderProductDefects.filter((defect) => {
        return defect.defectClassificationId == 2 && defect.workOrderProductId === id;
      });
      let total = defects.reduce(function(prev, cur) {
        return Number(prev) + Number(cur.qty);
      }, 0);
      return total;
    },
    foundMinorPerSku(id) {
      let defects = this.WorkOrderProductDefects.filter((defect) => {
        return defect.defectClassificationId == 3 && defect.workOrderProductId === id;
      });
      let total = defects.reduce(function(prev, cur) {
        return Number(prev) + Number(cur.qty);
      }, 0);
      return total;
    },
    changePagination(item) {
      console.log(item);
    },
    noDefect(workOrderProductId) {
      let noDef = false;
      let defect = this.WorkOrderProductDefects.filter((x) => x.workOrderProductId == workOrderProductId);
      if (defect.length == 0) {
        noDef = true;
      }
      return noDef;
    },
    isDefectDuplicate(data, type) {
      let result = false;
      let defectExist = "";
      switch (type) {
        case "add":
          if (this.isDCLOrGeneric()) {
            defectExist = this.WorkOrderProductDefects.filter(
              (x) => x.defectCode === data.defectCode &&
                x.clientDefect.productCategoryId === data.category &&
                x.defectDetails === data.detail &&
                x.defectClassificationId == data.grade &&
                (x.defectNameId == data.name || x.clientDefectId == data.name) &&
                data.workOrderProductIds.includes(x.workOrderProductId)
            );
          } else {
            defectExist = this.WorkOrderProductDefects.filter(
              (x) => x.defectCode === data.defectCode &&
                x.defectCategoryId === data.category &&
                x.defectDetails === data.detail &&
                x.defectClassificationId == data.grade &&
                x.defectNameId === data.name &&
                data.workOrderProductIds.includes(x.workOrderProductId)
            );
          }

          if (defectExist == 0) {
            result = false;
          } else {
            result = true;
          }
          break;
        case "update":
          if (this.isDCLOrGeneric()) {
            defectExist = this.WorkOrderProductDefects.filter(
              (x) =>
                x.defectCategoryId == data.defectCategoryId &&
                (x.defectNameId == data.defectNameId || x.clientDefectId == data.clientDefectId) &&
                x.defectClassificationId == data.defectClassificationId &&
                x.defectDetails == data.defectDetails &&
                x.workOrderProductId == data.workOrderProductId &&
                x.defectCode == data.defectCode &&
                x.id != data.id
            );
          } else {
            defectExist = this.WorkOrderProductDefects.filter(
              (x) =>
                x.defectCategoryId == data.defectCategoryId &&
                (x.defectNameId == data.defectNameId || x.clientDefectId == data.clientDefectId) &&
                x.defectClassificationId == data.defectClassificationId &&
                x.defectDetails == data.defectDetails &&
                x.workOrderProductId == data.workOrderProductId &&
                x.defectCode == data.defectCode &&
                x.id != data.id
            );
          }

          if (defectExist == 0) {
            result = false;
          } else {
            result = true;
          }
          break;
      }

      return result;
    },
    go_gallery() {
      this.$router.push({
        name: "uploadPhotoGallery",
      });
    },
    updateClassfication(item, classificationId) {  
      const originalVal = item.defectClassificationId;
      item.defectClassificationId = classificationId;   

      if (this.isDefectDuplicate(item, "update")) {
        item.defectClassificationId = originalVal;
        this.message = "Cannot be edited. Defect already exist!";
        this.snackbar = true;        
      } else {
        //item.defectClassificationId=classificationId
        if (window.navigator.onLine == true) {
          this.showProgressDialog = true;
        }
        const data = {
            id: item.id,
            workOrderProductId: item.workOrderProductId,
            clientDefectId: item.clientDefectId,
            defectNameId: item.defectNameId,
            defectCode: item.defectCode,
            defectCategoryId: item.defectCategoryId,
            defectClassificationId: item.defectClassificationId,
            defectDetails: item.defectDetails,
            qty: item.qty,
            sectionId: 5,
          };
          
        this.utilityFn.getServerData(
          "post",
          this.apiList.updateWorkOrderProductDefects,
          [data],
          (result) => {
            
            this.upsertDefect(data);

            this.showProgressDialog = false;
          },
          (errres) => {
            console.log(errres);
            if (window.navigator.onLine != true) {
              this.message = this.msgList.NoConnection;
              this.snackbar = true;
            }
          }
        );
      }
    },
    add_defect_sku(item) {
      this.editItem.item_p = item;
      this.formDataSku.category = "";
      this.formDataSku.name = "";
      this.formDataSku.detail = "";
      this.formDataSku.number = "0";
      this.formDataSku.grade = "";
      this.formDataSku.workOrderProductIds = [];
      this.formDataSku.dclTypeAliasId = null;
      this.dialog_sku = true;

      this.page = 0;
    },
    add_defect_classification(item) {
      this.editItem.item_p = item;
      this.formDataClassFication.workOrderProductId = "";
      this.formDataClassFication.category = "";
      this.formDataClassFication.name = "";
      this.formDataClassFication.detail = "";
      this.formDataClassFication.number = "0";
      this.formDataClassFication.grade = "";
      this.formDataClassFication.grade = item.id;
      this.formDataClassFication.dclTypeAliasId = null;
      this.formDataClassFication.workOrderProductIds = [];
      this.dialog_classFication = true;
    },
    add_defect_name(item, classification) {
      this.editItem.item_p = item;
      this.formDataDefect.workOrderProductId = "";
      this.formDataDefect.category = "";
      this.formDataDefect.name = "";
      this.formDataDefect.detail = "";
      this.formDataDefect.number = "0";
      this.formDataDefect.grade = classification;
      this.formDataDefect.category = item[0].defectCategoryId;
      this.formDataDefect.name = item[0].defectNameId;
      this.formDataDefect.dclTypeAliasId = null;
      this.formDataDefect.workOrderProductIds = [];
      if (item[0].defectNameId) {
        if (this.isDCLOrGeneric()) {
          this.formDataDefect.defectCode = this.filterSkuDefectCodeListEdit.find((x) => x.id == item[0].defectNameId).defectCode;
        } else {
          this.formDataDefect.defectCode = this.DefectNames.find((x) => x.id == item[0].defectNameId).code;
        }
      }
      this.dialog_defect = true;
    },

    async saveDataTableValues() {
      const patchData = [];
      this.Products.forEach((currentItem) => {
        // check if row/item has been modified
        const originalValue = this.productsClone.find((x) => x.id == currentItem.id);
        if (!_.isEqual(currentItem, originalValue)) {
          const data = [
            { op: "replace", path: "/allowedCriticalPO", value: currentItem.criticalAC },
            { op: "replace", path: "/allowedMajorPO", value: currentItem.majorAC },
            { op: "replace", path: "/allowedMinorPO", value: currentItem.minorAC },
            { op: "replace", path: "/rejectedCriticalPO", value: currentItem.criticalRE },
            { op: "replace", path: "/rejectedMajorPO", value: currentItem.majorRE },
            { op: "replace", path: "/rejectedMinorPO", value: currentItem.minorRE },
          ];

          patchData.push({ id: currentItem.id, patch: data, sectionId: 5 });
        }
      });

        if (patchData.length > 0) {
          await this.utilityFn.getServerData("patch", this.apiList.bulkUpsertWorkOrderProductResult, patchData, (result) => {
        });
      }
    },

    async upsertWorkmanshipResultTable() {
      const data = [
        {
          op: "replace",
          path: "/workmanshipsamplesize",
          value: this.WorkOrderList.workmanshipSampleSize,
        },
        {
          op: "replace",
          path: "/allowedCritical",
          value: this.WorkOrderList.aqlCritical == "N/A" ? -1 : this.WorkOrderList.allowedCritical,
        },
        {
          op: "replace",
          path: "/allowedMajor",
          value: this.WorkOrderList.aqlMajor == "N/A" ? -1 : this.WorkOrderList.allowedMajor,
        },
        {
          op: "replace",
          path: "/allowedMinor",
          value: this.WorkOrderList.aqlMinor == "N/A" ? -1 : this.WorkOrderList.allowedMinor,
        },
        {
          op: "replace",
          path: "/rejectedCritical",
          value: this.WorkOrderList.aqlCritical == "N/A" ? -1 : this.WorkOrderList.rejectedCritical,
        },
        {
          op: "replace",
          path: "/rejectedMajor",
          value: this.WorkOrderList.aqlMajor == "N/A" ? -1 : this.WorkOrderList.rejectedMajor,
        },
        {
          op: "replace",
          path: "/rejectedMinor",
          value: this.WorkOrderList.aqlMinor == "N/A" ? -1 : this.workOrder.rejectedMinor,
        },
        {
          op: "replace",
          path: "/foundCritical",
          value: this.foundSummary.foundCritical,
        },
        {
          op: "replace",
          path: "/foundMajor",
          value: this.foundSummary.foundMajor,
        },
        {
          op: "replace",
          path: "/foundMinor",
          value: this.foundSummary.foundMinor,
        },
        {
          op: "replace",
          path: "/workmanshipSampleSizeCritical",
          value: this.workOrder.workmanshipSampleSizeCritical,
        },
        {
          op: "replace",
          path: "/workmanshipSampleSizeMajor",
          value: this.workOrder.workmanshipSampleSizeMajor,
        },
        {
          op: "replace",
          path: "/workmanshipSampleSizeMinor",
          value: this.workOrder.workmanshipSampleSizeMinor,
        },
        {
          op: "replace",
          path: "/NotCountedDefects",
          value: this.workOrder.notCountedDefects,
        },
        {
          op: "replace",
          path: "/WorkmanshipPendingFailCodeId",
          value:  this.WorkOrderList.workmanshipResult == '2' ? this.workOrder.workmanshipPendingFailCodeId : null,
        },
        {
          op: "replace",
          path: "/WorkmanshipResultRemarks",
          value:  this.workOrder.workmanshipResultRemarks,
        },
        {
          op: "replace",
          path: "/WorkmanshipResultCode",
          value: this.WorkOrderList.workmanshipResult == '2' ? this.WorkOrderList.workmanshipResultCode : null,
        },
        {
          op: "replace",
          path: "/workmanshipresult",
          value: this.WorkOrderList.workmanshipResult,
        },
        {
          op: "replace",
          path: "/recompute",
          value: false,
        },
      ];
      await this.utilityFn.getServerDataAsync("patch", this.apiList.patchWorkOrderResults + "/" + this.workOrderId + "/5", data);

      this.onChange();
      this.calculateResult()
    },
    onChangeDefectCategory(type) {
      switch (type) {
        case "skuSave":
          this.formDataSku.name = "";
          this.formDataSku.defectCode = null;
          break;
        case "defectSave":
          this.formDataDefect.name = "";
          this.formDataDefect.defectCode = null;
          break;
        case "classificationSave":
          this.formDataClassFication.name = "";
          this.formDataClassFication.defectCode = null;
          break;
        case "skuUpdate":
          this.formDataSkuEdit.defectNameId = "";
          this.formDataSkuEdit.defectCode = null;
          break;
        case "defectUpdate":
          this.formDataDefectEdit.defectNameId = "";
          this.formDataDefectEdit.defectCode = null;
          break;
        case "classificationUpdate":
          this.formDataClassFicationEdit.defectNameId = "";
          this.formDataClassFicationEdit.defectCode = null;
          break;
      }
    },
    onDclTypeAliasChange(type) {
      let defectCount = 0;
      switch (type) {
        case "skuSave":
          defectCount = this.filterSkuDefectNames.filter(x => x.defectCode == this.formDataSku.defectCode && x.companyDclTypeAliasId == this.formDataSku.dclTypeAliasId).length; 
          if (defectCount == 0) {
            this.formDataSku.name = "";
            this.formDataSku.defectCode = null;
          }         
          break;
        case "defectSave":
          defectCount = this.filterDataDefectNames.filter(x => x.defectCode == this.formDataDefect.defectCode && x.companyDclTypeAliasId == this.formDataDefect.dclTypeAliasId).length; 
          if (defectCount == 0) {
            this.formDataDefect.name = "";
            this.formDataDefect.defectCode = null;
          }
          break;
        case "classificationSave":
          defectCount = this.filterDataClassFicationNames.filter(x => x.defectCode == this.formDataClassFication.defectCode && x.companyDclTypeAliasId == this.formDataClassFication.dclTypeAliasId).length; 
          if (defectCount == 0) {
            this.formDataClassFication.name = "";
            this.formDataClassFication.defectCode = null;
          }
          break;
        case "skuUpdate":
          defectCount = this.filterSkuDefectNamesEdit.filter(x => x.defectCode == this.formDataSkuEdit.defectCode && x.companyDclTypeAliasId == this.formDataSkuEdit.dclTypeAliasId).length; 
          if (defectCount == 0) {
            this.formDataSkuEdit.defectNameId = "";
            this.formDataSkuEdit.defectCode = null;
          }
          break;
        case "defectUpdate":
          defectCount = this.filterDataDefectNamesEdit.filter(x => x.defectCode == this.formDataDefectEdit.defectCode && x.companyDclTypeAliasId == this.formDataDefectEdit.dclTypeAliasId).length; 
          if (defectCount == 0) {
            this.formDataDefectEdit.defectNameId = "";
            this.formDataDefectEdit.defectCode = null;
          }
          break;
        case "classificationUpdate":
          defectCount = this.filterDataClassFicationNamesEdit.filter(x => x.defectCode == this.formDataClassFicationEdit.defectCode && x.companyDclTypeAliasId == this.formDataClassFicationEdit.dclTypeAliasId).length; 
          if (defectCount == 0) {
            this.formDataClassFicationEdit.defectNameId = "";
            this.formDataClassFicationEdit.defectCode = null;
          }
          break;
      }
    },
    onToggleProductSelection(form, currentValue, options) {
      if(currentValue) {
        form['workOrderProductIds'] = options.map(x => x.id);
      } else {
        form['workOrderProductIds'] = [];
      }
    },
    async LoadData(isInitial = false) {
      this.showFloatingButton = this._samplingPlanLists == undefined ? false : this._samplingPlanLists.showFabButton == false ? false : true;
      this.workOrderId = this.$route.params.id;

      let result = await this.utilityFn.getServerDataAsync("get", this.apiList.workOrderDetailsWorkmanship + "/" + this.workOrderId, {});
      let prodresult = await this.utilityFn.getServerDataAsync("get", this.apiList.getWorkOrderProductDefects, { workOrderId: this.workOrderId });

      if (Object.keys(result).length != 0 && Object.keys(prodresult).length != 0) {
        this.workOrderDisplayId = result.data[0].workOrderDisplayId;
        this.jobDisplayId = result.data[0].jobDisplayId;
        this.workOrder = result.data[0];
        this.Products = this.workOrder.products;
        const totalLotQtyProduct = this.workOrder.products.reduce(function (tot, arr) {
          return Number(tot) + Number(arr.qty);
        }, 0);
        /*const totalLotQtyProduct = this._samplingPlanLists.totalSampleSizePerSKU.reduce(function (tot, arr) {
            return Number(tot) + Number(arr.totalProductQuantity);
        }, 0);*/

        const aqlCriticalSelected = this.workOrder.aqlCritical;
        const aqlMinorSelected = this.workOrder.aqlMinor;
        const aqlMajorSelected = this.workOrder.aqlMajor;
        const aqlCriticalVal = +aqlCriticalSelected;
        const aqlMinorVal = +aqlMinorSelected;
        const aqlMajorVal = +aqlMajorSelected;

        let aqlCriticalSamplingPlan = 0;
        let aqlMinorSamplingPlan = 0;
        let aqlMajorSamplingPlan = 0;
        let zeroAQL = 0;

        var changeToMax = true;

        if(aqlCriticalVal !== 0 && !isNaN(aqlCriticalVal)) {
          aqlCriticalSamplingPlan = this.samPlan.getVaryingAQL(totalLotQtyProduct, this.workOrder.aqlLevels, this.workOrder.inspectionLevel, aqlCriticalVal)?.sampleSize;
        }

        if(aqlMinorVal !== 0 && !isNaN(aqlMinorVal)) {
          aqlMinorSamplingPlan = this.samPlan.getVaryingAQL(totalLotQtyProduct, this.workOrder.aqlLevels, this.workOrder.inspectionLevel, aqlMinorVal)?.sampleSize;
        }

        if(aqlMajorVal !== 0 && !isNaN(aqlMajorVal)) {
          aqlMajorSamplingPlan = this.samPlan.getVaryingAQL(totalLotQtyProduct, this.workOrder.aqlLevels, this.workOrder.inspectionLevel, aqlMajorVal)?.sampleSize;
        }
        
        zeroAQL = this.samPlan.getVaryingAQL(totalLotQtyProduct, this.workOrder.aqlLevels, this.workOrder.inspectionLevel, 0, 0).sampleSize;

        if(isInitial && !this.workOrder.isAqlCriticalChanged && !this.workOrder.recompute && (this.workOrder.workmanshipSampleSizeCritical == 0 || this.workOrder.workmanshipSampleSizeCritical)) {
          //Do not change the value of this.workOrder.workmanshipSampleSizeCritical
          changeToMax = false;
        } else if (aqlCriticalSelected.toLowerCase() === 'n/a' || aqlCriticalSelected.toLowerCase() === 'not allowed') {
          this.workOrder.workmanshipSampleSizeCritical = 0;
        } else if (aqlCriticalSamplingPlan === 0) {
          this.workOrder.workmanshipSampleSizeCritical = zeroAQL;
        } else {
          this.workOrder.workmanshipSampleSizeCritical = aqlCriticalSamplingPlan;
        }

        if(isInitial && !this.workOrder.isAqlMinorChanged && !this.workOrder.recompute && (this.workOrder.workmanshipSampleSizeMinor == 0 || this.workOrder.workmanshipSampleSizeMinor)) {
          //Do not change the value of this.workOrder.workmanshipSampleSizeMinor
          changeToMax = false;
        } else if (aqlMinorSelected.toLowerCase() === 'n/a' || aqlMinorSelected.toLowerCase() === 'not allowed') {
          this.workOrder.workmanshipSampleSizeMinor = 0;
        } else if (aqlMinorSamplingPlan === 0) {
          this.workOrder.workmanshipSampleSizeMinor = zeroAQL;
        } else {
          this.workOrder.workmanshipSampleSizeMinor = aqlMinorSamplingPlan;
        }

        if(isInitial && !this.workOrder.isAqlMajorChanged && !this.workOrder.recompute &&(this.workOrder.workmanshipSampleSizeMajor == 0 || this.workOrder.workmanshipSampleSizeMajor)) {
          //Do not change the value of this.workOrder.workmanshipSampleSizeMajor
          changeToMax = false;
        } else if (aqlMajorSelected.toLowerCase() === 'n/a' || aqlMajorSelected.toLowerCase() === 'not allowed') {
          this.workOrder.workmanshipSampleSizeMajor = 0;
        } else if (aqlMajorSamplingPlan === 0) {
          this.workOrder.workmanshipSampleSizeMajor = zeroAQL;
        } else {
          this.workOrder.workmanshipSampleSizeMajor = aqlMajorSamplingPlan;
        }

        /*if (changeToMax){
          var list = [];
          list.push(this.workOrder.workmanshipSampleSizeCritical);
          list.push(this.workOrder.workmanshipSampleSizeMinor);
          list.push(this.workOrder.workmanshipSampleSizeMajor);
          var maxValue = Math.max(...(list));

          if (maxValue > this.workOrder.workmanshipSampleSize){
             this.workOrder.workmanshipSampleSize = maxValue;
          }

          if (aqlCriticalSelected != null && aqlCriticalSelected.toLowerCase() === 'n/a' && aqlCriticalSelected.toLowerCase() === 'not allowed'){
            this.workOrder.workmanshipSampleSizeCritical = maxValue;
          }
          if (aqlMinorSelected != null && aqlMinorSelected.toLowerCase() === 'n/a' && aqlMinorSelected.toLowerCase() === 'not allowed'){
            this.workOrder.workmanshipSampleSizeMinor = maxValue;
          }
          if (aqlMajorSelected != null && aqlMajorSelected.toLowerCase() === 'n/a' && aqlMajorSelected.toLowerCase() === 'not allowed'){
            this.workOrder.workmanshipSampleSizeMajor = maxValue;
          }
        }*/

        if (this.isDCLOrGeneric()) {
          //DCL Defect
          this.WorkOrderProductDefects = prodresult.data.filter((x) => x.clientDefectId != null && x.sectionId != 2 && x.sectionId != 3);
        } else {
          this.WorkOrderProductDefects = prodresult.data.filter((x) => x.clientDefectId == null && x.defectCategoryId != 7 && x.defectCategoryId != 8);
        }

        this.WorkOrderProductDefects.map((x) => {
          let products = this.Products.filter((y) => y.id == x.workOrderProductId);
          x.po = products[0].po;
          return x;
        });

        this.Products.forEach((item) => {
          this.$set(item, "itemList", this.filterWorkOrderDefectSku(item.id));
        });
        this.WorkOrderList = this.workOrder;
        this.samplingMethodology = this.workOrder.samplingMethodology;

        if((!this.WorkOrderList.notCountedDefects  || this.WorkOrderList.notCountedDefects == null)&& (this._workOrderDetails.clientName == "WALMART GLOBAL SOURCING" || this._workOrderDetails.clientName.toUpperCase() == 'ASDA STORES LIMITED')){
          this.WorkOrderList.notCountedDefects = 'NIL'
        }
        
        this.Products.map((x) => {
          let val = this._samplingPlanLists.SKUSamplingList.filter((y) => y.id === x.id);
          (x.criticalAC = x.workOrderProductResults.allowedCriticalPO ),
            (x.majorAC = x.workOrderProductResults.allowedMajorPO),
            (x.minorAC = x.workOrderProductResults.allowedMinorPO ),
            (x.criticalRE = x.workOrderProductResults.rejectedCriticalPO),
            (x.majorRE = x.workOrderProductResults.rejectedMajorPO),
            (x.minorRE = x.workOrderProductResults.rejectedMinorPO),
            (x.productSampleSize = x.workOrderProductResults.sampleSizeProductWorkmanship == null ? val[0].productSampleSize : x.workOrderProductResults.sampleSizeProductWorkmanship),
            (x.page = 1),
            (x.pageCount = 0),
            (x.itemsPerPage = 5),
            (x._skuDisplay = x.skuDisplay),
            (x.skuDisplay = x.skuDisplay + " - " + x.po);
          return x;
        });

        this.productsClone = _.cloneDeep(this.Products);
        this.WorkOrderList.workmanshipSampleSize = this._samplingPlanLists.changedSamplingMethodology ? this._samplingPlanLists.totalProductSampleSize : this.WorkOrderList.workmanshipSampleSize == null ? this.getWorkmanshipSampleSize() : this.WorkOrderList.workmanshipSampleSize;

        this._samplingPlanLists.changedSamplingMethodology = null
        this.$store.dispatch("inspection/setSamplingPlanList", this._samplingPlanLists)   
            
        if (!isInitial) {
          this.calculateResult();
          this.ChangeResult();
        }
      }
        this.$store.dispatch("navigation/setLoadingPage",{show: false });
    },

    remapSamplingPlan(){
      this.Products.map((x) => {
          let val = this._samplingPlanLists.SKUSamplingList.filter((y) => y.id === x.id);
          (x.criticalAC = x.workOrderProductResults.allowedCriticalPO ),
            (x.majorAC = x.workOrderProductResults.allowedMajorPO),
            (x.minorAC = x.workOrderProductResults.allowedMinorPO ),
            (x.criticalRE = x.workOrderProductResults.rejectedCriticalPO ),
            (x.majorRE = x.workOrderProductResults.rejectedMajorPO),
            (x.minorRE = x.workOrderProductResults.rejectedMinorPO),
            (x.productSampleSize = x.workOrderProductResults.sampleSizeProductWorkmanship == null ? val[0].productSampleSize : x.workOrderProductResults.sampleSizeProductWorkmanship),
            (x.page = 1),
            (x.pageCount = 0),
            (x.itemsPerPage = 5),
            (x._skuDisplay = x.skuDisplay),
            (x.skuDisplay = x.skuDisplay + " - " + x.po);
          return x;
        });

        this.productsClone = _.cloneDeep(this.Products);
        this.WorkOrderList.workmanshipSampleSize = this._samplingPlanLists.changedSamplingMethodology ? this._samplingPlanLists.totalProductSampleSize : this.WorkOrderList.workmanshipSampleSize == null ? this.getWorkmanshipSampleSize() : this.WorkOrderList.workmanshipSampleSize;

        this._samplingPlanLists.changedSamplingMethodology = null
        this.$store.dispatch("inspection/setSamplingPlanList", this._samplingPlanLists)   
    },
    
    remapWorkOrderProductDefects() {
      this.WorkOrderProductDefects.map((x) => {
        let product = this.Products.find((y) => y.id == x.workOrderProductId);
        x.po = product.po;
        x.skuDisplay = product._skuDisplay;
        return x;
      });

      this.Products.forEach((item) => {
        this.$set(item, "itemList", this.filterWorkOrderDefectSku(item.id));
      });

      this.calculateResult();
      this.ChangeResult();
    },
    
    filterWorkOrderDefectName(defectName, classification) {
      return this.WorkOrderProductDefects.filter((defect) => {
        return defect.defectName.name === defectName && defect.defectClassificationId === classification;
      });
    },
    filterWorkOrderDefectClassification(classificationId) {
      return this.WorkOrderProductDefects.filter((defect) => defect.defectClassificationId === classificationId);
    },
    filterWorkOrderDefectSku(workOrderProductId) {
      return this.WorkOrderProductDefects.filter((defect) => {
        return defect.workOrderProductId === workOrderProductId;
      });
    },
    calculateResult() {
      if (this.WorkOrderList.workmanshipResult != "3" && this.WorkOrderList.workmanshipResult != "4") {
        this.WorkOrderList.workmanshipResult = this.workmanshipResult();
      }
      this.saveDataTableValues()
      // this.WorkOrderList.workmanshipResult =  this.WorkOrderList.workmanshipResult=='3' || this.WorkOrderList.workmanshipResult=='4'?  this.WorkOrderList.workmanshipResult :   this.workmanshipResult()
    },
    minus(item) {
      //can't be 0
      if (item.qty == 1) {
        return;
      }

      item.qty--;
      const data = {
          id: item.id,
          workOrderProductId: item.workOrderProductId,
          clientDefectId: item.clientDefectId,
          defectNameId: item.defectNameId,
          defectCode: item.defectCode,
          defectCategoryId: item.defectCategoryId,
          defectClassificationId: item.defectClassificationId,
          defectDetails: item.defectDetails,
          qty: item.qty,
          sectionId: 5,
      };

      if (window.navigator.onLine == true) {
        this.showProgressDialog = true;
        this.utilityFn.getServerData("post", this.apiList.updateWorkOrderProductDefects, [data], (result) => {
          
          this.upsertDefect(data);

          this.showProgressDialog = false;
        });
      } else {
        this.utilityFn.getServerData("post", this.apiList.updateWorkOrderProductDefects, [data], (result) => {});
        this.message = this.msgList.NoConnection;
        this.snackbar = true;
      }
    },

    plus(item) {
      item.qty++;
      const data = {
          id: item.id,
          workOrderProductId: item.workOrderProductId,
          clientDefectId: item.clientDefectId,
          defectNameId: item.defectNameId,
          defectCode: item.defectCode,
          defectCategoryId: item.defectCategoryId,
          defectClassificationId: item.defectClassificationId,
          defectDetails: item.defectDetails,
          qty: item.qty,
          sectionId: 5,
      };

      if (window.navigator.onLine == true) {
        this.showProgressDialog = true;
        this.utilityFn.getServerData("post", this.apiList.updateWorkOrderProductDefects, [data], (result) => {
          
          this.upsertDefect(data);

          this.showProgressDialog = false;
        });
      } else {
        this.utilityFn.getServerData(
          "post",
          this.apiList.updateWorkOrderProductDefects,
          [data],
          (result) => {},
          (errres) => {
            console.log(errres);
            this.message = this.msgList.NoConnection;
            this.snackbar = true;
          }
        );
      }
    },
    _minus(item, type) {
      switch (type) {
        case "add":
          if (item.number == 0) {
            return;
          }
          item.number--;
          break;

        case "edit":
          if (item.qty == 0) {
            return;
          }
          item.qty--;
          break;
      }

      // this.calculateResult()
    },
    _plus(item, type) {
      switch (type) {
        case "add":
          item.number++;
          break;

        case "edit":
          item.qty++;
          break;
      }
      //this.calculateResult()
    },
    result_minus(item, type) {
      switch (type) {
        case "critical":
          if (item.allowedCritical == 0) {
            return;
          }
          item.allowedCritical--;
          break;

        case "major":
          if (item.allowedMajor == 0) {
            return;
          }
          item.allowedMajor--;
          break;

        case "minor":
          if (item.allowedMinor == 0) {
            return;
          }
          item.allowedMinor--;
          break;
      }
      const data = {
          id: item.id,
          workOrderProductId: item.workOrderProductId,
          clientDefectId: item.clientDefectId,
          defectNameId: item.defectNameId,
          defectCode: item.defectCode,
          defectCategoryId: item.defectCategoryId,
          defectClassificationId: item.defectClassificationId,
          defectDetails: item.defectDetails,
          qty: item.qty,
          sectionId: 5,
        };
      
      this.utilityFn.getServerData("post", this.apiList.updateWorkOrderProductDefects, [data], (result) => {
        this.upsertDefect(data);
      });
      //  this.apiFn.upsertWorkmanshipResultTable(item)
      // .then(result => {
      //     this.LoadData()
      //       console.log('Results table updated.')
      //         });
      //this.calculateResult()
    },
    result_plus(item, type) {
      switch (type) {
        case "critical":
          item.allowedCritical++;
          break;

        case "major":
          item.allowedMajor++;
          break;

        case "minor":
          item.allowedMinor++;
          break;
      }
      const data = {
          id: item.id,
          workOrderProductId: item.workOrderProductId,
          clientDefectId: item.clientDefectId,
          defectNameId: item.defectNameId,
          defectCode: item.defectCode,
          defectCategoryId: item.defectCategoryId,
          defectClassificationId: item.defectClassificationId,
          defectDetails: item.defectDetails,
          qty: item.qty,
          sectionId: 5,
        };
      
      this.utilityFn.getServerData("post", this.apiList.updateWorkOrderProductDefects, [data], (result) => {
        this.upsertDefect(data);
      });
      //  this.apiFn.upsertWorkmanshipResultTable(item)
      // .then(result => {
      //     this.LoadData()
      //       console.log('Results table updated.')
      //         });
      //  this.calculateResult()
    },
    close() {
      this.dialog_sku = false;
      this.dialog_sku_edit = false;
      this.dialog_defect = false;
      this.dialog_defect_edit = false;
      this.dialog_classFication = false;
      this.dialog_classFication_edit = false;
      this.showConfirmDialogSku = false;
      this.showConfirmDialogDefect = false;
      this.showConfirmDialogClassification = false;
    },
    async save(type) {
      switch (type) {
        case "skuList":
          if (this.isDefectDuplicate(this.formDataSku, "add")) {
            this.message = "Defect already exist!";
            this.snackbar = true;
          } else {
            this.close();
            this.existingValue = this.formDataSku.detail;
            const data = this.formDataSku.workOrderProductIds.map(id => ({
              workOrderProductId: id,
              clientDefectId: this.isDCLOrGeneric() ? this.formDataSku.name : null,
              defectNameId: this.isDCLOrGeneric() ? null : this.formDataSku.name,
              defectCode: this.formDataSku.defectCode,
              defectCategoryId: this.isDCLOrGeneric() ? null : this.formDataSku.category,
              defectClassificationId: this.formDataSku.grade,
              dclTypeAliasId: this.formDataSku.dclTypeAliasId,
              defectDetails: this.formDataSku.detail,
              qty: this.formDataSku.number,
              sectionId: 5,
            }))

            try {
              this.showProgressDialog = true;
              let res = await this.utilityFn.getServerDataAsync("post", this.apiList.updateWorkOrderProductDefects, data);
              
              if (res.data && res.data[0]) {
                res.data.forEach(x => {
                  this.upsertDefect(x, x.id);
                })
              }

              this.showProgressDialog = false;
            } catch (err) {
              this.showProgressDialog = false;
              console.log(err);
              if (window.navigator.onLine != true) {
                this.message = this.msgList.NoConnection;
                this.snackbar = true;
                /* let offline_data = {...data,id: Math.random() };
                    this.WorkOrderProductDefects.push(data);
                    this.WorkOrderProductDefects.map((x) => {
                      let products = this.Products.filter((y) => y.id == x.workOrderProductId);
                      x.po = products[0].po;
                      return x;
                    });
                    this.Products.forEach((item) => {
                      this.$set(item, "itemList", this.filterWorkOrderDefectSku(item.id));
                    }); */
              }
            }
          }

          break;
        case "defectList":
          if (this.isDefectDuplicate(this.formDataDefect, "add")) {
            this.message = "Defect already exist!";
            this.snackbar = true;
          } else {
            this.close();
            this.existingValue = this.formDataDefect.detail;
            const data = this.formDataDefect.workOrderProductIds.map(id => ({
              workOrderProductId: id,
              clientDefectId: this.isDCLOrGeneric() ? this.formDataDefect.name : null,
              defectNameId: this.isDCLOrGeneric() ? null : this.formDataDefect.name,
              defectCode: this.formDataDefect.defectCode,
              defectCategoryId: this.isDCLOrGeneric() ? null : this.formDataDefect.category,
              defectClassificationId: this.formDataDefect.grade,
              dclTypeAliasId: this.formDataDefect.dclTypeAliasId,
              defectDetails: this.formDataDefect.detail,
              qty: this.formDataDefect.number,
              sectionId: 5,
            }))

            try {
              this.showProgressDialog = true;
              let res = await this.utilityFn.getServerDataAsync("post", this.apiList.updateWorkOrderProductDefects, data);
              
              if (res.data && res.data[0]) {
                res.data.forEach(x => {
                  this.upsertDefect(x, x.id);
                })
              }

              this.showProgressDialog = false;
            } catch (err) {
              this.showProgressDialog = false;
              console.log(err);
              if (window.navigator.onLine != true) {
                this.message = this.msgList.NoConnection;
                this.snackbar = true;
              }
            }
          }
          break;
        case "classFicationList":
          if (this.isDefectDuplicate(this.formDataClassFication, "add")) {
            this.message = "Defect already exist!";
            this.snackbar = true;
          } else {
            this.close();
            this.existingValue = this.formDataClassFication.detail;
            const data = this.formDataClassFication.workOrderProductIds.map(id => ({
                workOrderProductId: id,
                clientDefectId: this.isDCLOrGeneric() ? this.formDataClassFication.name : null,
                defectNameId: this.isDCLOrGeneric() ? null : this.formDataClassFication.name,
                defectCode: this.formDataClassFication.defectCode,
                defectCategoryId: this.isDCLOrGeneric() ? null : this.formDataClassFication.category,
                defectClassificationId: this.formDataClassFication.grade,
                dclTypeAliasId: this.formDataClassFication.dclTypeAliasId,
                defectDetails: this.formDataClassFication.detail,
                qty: this.formDataClassFication.number,
                sectionId: 5,
            }))

            try {
              this.showProgressDialog = true;
              let res = await this.utilityFn.getServerDataAsync("post", this.apiList.updateWorkOrderProductDefects, data);
              
              if (res.data && res.data[0]) {
                res.data.forEach(x => {
                  this.upsertDefect(x, x.id);
                })
              }

              this.showProgressDialog = false;
            } catch (err) {
              this.showProgressDialog = false;
              console.log(err);
              if (window.navigator.onLine != true) {
                this.message = this.msgList.NoConnection;
                this.snackbar = true;
              }
            }
          }
          break;
      }
      //this.calculateResult()
      this.onChange();
    },
    async update(type) {
      switch (type) {
        case "skuList":
          if (this.isDefectDuplicate(this.formDataSkuEdit, "update")) {
            this.message = "Cannot be edited. Defect already exist!";
            this.snackbar = true;
          } else {
            this.close();
            const data = this.formDataSkuEdit.workOrderProductIds.map((workOrderProductId) => {
              const id = this.WorkOrderProductDefects.find((x) => x.workOrderProductId === workOrderProductId 
                && x.clientDefectId === this.defectToEdit.clientDefectId
                && (x.clientDefect?.productCategoryId === this.defectToEdit.defectCategoryId || x.defectCategoryId === this.defectToEdit.defectCategoryId)
                && x.defectClassificationId === this.defectToEdit.defectClassificationId
                && x.defectDetails === this.defectToEdit.defectDetails)?.id;

              return {
                id: id,
                workOrderProductId: workOrderProductId,
                clientDefectId: this.isDCLOrGeneric() ? this.formDataSkuEdit.defectNameId : null,
                defectNameId: this.isDCLOrGeneric() ? null : this.formDataSkuEdit.defectNameId,
                defectCode: this.formDataSkuEdit.defectCode,
                defectCategoryId: this.isDCLOrGeneric() ? null : this.formDataSkuEdit.defectCategoryId,
                defectClassificationId: this.formDataSkuEdit.defectClassificationId,
                dclTypeAliasId: this.formDataSkuEdit.dclTypeAliasId,
                defectDetails: this.formDataSkuEdit.defectDetails,
                qty: this.formDataSkuEdit.qty,
                sectionId: 5,
              }
            })

            try {
              this.showProgressDialog = true;
              let res = await this.utilityFn.getServerDataAsync("post", this.apiList.updateWorkOrderProductDefects, data);
              
              res.data.forEach(x => this.upsertDefect(x));
              this.defectToEdit = null;
              this.showProgressDialog = false;
            } catch (err) {
              console.log(err);
              this.showProgressDialog = false;
              if (window.navigator.onLine != true) {
                this.message = this.msgList.NoConnection;
                this.snackbar = true;
                this.editItem.item_p.forEach((item, index) => {
                  if (item.id == this.editItem.id) {
                    item.workOrderProductId = this.formDataSkuEdit.workOrderProductId;
                    item.defectCategoryId = this.formDataSkuEdit.defectCategoryId;
                    item.defectNameId = this.formDataSkuEdit.defectNameId;
                    item.qty = this.formDataSkuEdit.qty;
                    item.defectClassificationId = this.formDataSkuEdit.defectClassificationId;
                    item.dclTypeAliasId = this.formDataSkuEdit.dclTypeAliasId;
                    item.defectDetails = this.formDataSkuEdit.defectDetails;
                  }
                });
              }
            }
          }
          break;
        case "defectList":
          if (this.isDefectDuplicate(this.formDataDefectEdit, "update")) {
            this.message = "Cannot be edited. Defect already exist!";
            this.snackbar = true;
          } else {
            this.close();

            const data = this.formDataDefectEdit.workOrderProductIds.map((workOrderProductId) => {
              const id = this.WorkOrderProductDefects.find((x) => x.workOrderProductId === workOrderProductId
                && x.clientDefectId === this.defectToEdit.clientDefectId
                && (x.clientDefect?.productCategoryId === this.defectToEdit.defectCategoryId || x.defectCategoryId === this.defectToEdit.defectCategoryId)
                && x.defectClassificationId === this.defectToEdit.defectClassificationId
                && x.defectDetails === this.defectToEdit.defectDetails)?.id;

              return {
                id: id,
                workOrderProductId: workOrderProductId,
                clientDefectId: this.isDCLOrGeneric() ? this.formDataDefectEdit.defectNameId : null,
                defectNameId: this.isDCLOrGeneric() ? null : this.formDataDefectEdit.defectNameId,
                defectCode: this.formDataDefectEdit.defectCode,
                defectCategoryId: this.isDCLOrGeneric() ? null : this.formDataDefectEdit.defectCategoryId,
                defectClassificationId: this.formDataDefectEdit.defectClassificationId,
                dclTypeAliasId: this.formDataDefectEdit.dclTypeAliasId,
                defectDetails: this.formDataDefectEdit.defectDetails,
                qty: this.formDataDefectEdit.qty,
                sectionId: 5,
              }
            })

            try {
              this.showProgressDialog = true;
              let res = await this.utilityFn.getServerDataAsync("post", this.apiList.updateWorkOrderProductDefects, data);
              
              res.data.forEach(x => this.upsertDefect(x));
              this.defectToEdit = null;
              this.showProgressDialog = false;
            } catch (err) {
              this.showProgressDialog = false;
              console.log(err);
              if (window.navigator.onLine != true) {
                this.message = this.msgList.NoConnection;
                this.snackbar = true;
                this.editItem.item_p.forEach((item, index) => {
                  if (item.id == this.editItem.id) {
                    item.workOrderProductId = this.formDataDefectEdit.workOrderProductId;
                    item.defectCategoryId = this.formDataDefectEdit.defectCategoryId;
                    item.defectNameId = this.formDataDefectEdit.defectNameId;
                    item.qty = this.formDataDefectEdit.qty;
                    item.defectClassificationId = this.formDataDefectEdit.defectClassificationId;
                    item.dclTypeAliasId = this.formDataDefectEdit.dclTypeAliasId;
                    item.defectDetails = this.formDataDefectEdit.defectDetails;
                  }
                });
              }
            }
          }
          break;
        case "classFicationList":
          if (this.isDefectDuplicate(this.formDataClassFicationEdit, "update")) {
            this.message = "Cannot be edited. Defect already exist!";
            this.snackbar = true;
          } else {
            this.close();

            const data = this.formDataClassFicationEdit.workOrderProductIds.map((workOrderProductId) => {
              const id = this.WorkOrderProductDefects.find((x) => x.workOrderProductId === workOrderProductId
                && x.clientDefectId === this.defectToEdit.clientDefectId
                && (x.clientDefect?.productCategoryId === this.defectToEdit.defectCategoryId || x.defectCategoryId === this.defectToEdit.defectCategoryId)
                && x.defectClassificationId === this.defectToEdit.defectClassificationId
                && x.defectDetails === this.defectToEdit.defectDetails)?.id;

              return {
                id: id,
                workOrderProductId: workOrderProductId,
                clientDefectId: this.isDCLOrGeneric() ? this.formDataClassFicationEdit.defectNameId : null,
                defectNameId: this.isDCLOrGeneric() ? null : this.formDataClassFicationEdit.defectNameId,
                defectCode: this.formDataClassFicationEdit.defectCode,
                defectCategoryId: this.isDCLOrGeneric() ? null : this.formDataClassFicationEdit.defectCategoryId,
                defectClassificationId: this.formDataClassFicationEdit.defectClassificationId,
                dclTypeAliasId: this.formDataClassFicationEdit.dclTypeAliasId,
                defectDetails: this.formDataClassFicationEdit.defectDetails,
                qty: this.formDataClassFicationEdit.qty,
                sectionId: 5,
              }
            })

            try {
              this.showProgressDialog = true;
              let res = await this.utilityFn.getServerDataAsync("post", this.apiList.updateWorkOrderProductDefects, data);
              
              res.data.forEach(x => this.upsertDefect(x));
              this.defectToEdit = null;
              this.showProgressDialog = false;
            } catch (err) {
              console.log(err);
              this.showProgressDialog = false;
              if (window.navigator.onLine != true) {
                this.message = this.msgList.NoConnection;
                this.snackbar = true;
                this.editItem.item_p.forEach((item, index) => {
                  if (item.id == this.editItem.id) {
                    item.workOrderProductId = this.formDataClassFicationEdit.workOrderProductId;
                    item.defectCategoryId = this.formDataClassFicationEdit.defectCategoryId;
                    item.defectNameId = this.formDataClassFicationEdit.defectNameId;
                    item.qty = this.formDataClassFicationEdit.qty;
                    item.defectClassificationId = this.formDataClassFicationEdit.defectClassificationId;
                    item.dclTypeAliasId = this.formDataClassFicationEdit.dclTypeAliasId;
                    item.defectDetails = this.formDataClassFicationEdit.defectDetails;
                  }
                });
              }
            }
          }
          break;
      }
      //this.calculateResult()
    },
    edit_defects(item, item_p, table_array) {
      this.editItem.id = item.id;
      this.editItem.item_p = item_p;
      this.defectToEdit = {
        defectCategoryId: this.isDCLOrGeneric() ? item.clientDefect.productCategoryId : item.defectCategoryId,
        clientDefectId: item.clientDefectId,
        defectClassificationId: item.defectClassificationId,
        defectDetails: item.defectDetails
      };
      this.existingValue = item.defectDetails;
      switch (table_array) {
        case "skuList":
          this.dialog_sku_edit = true;
          this.formDataSkuEdit.id = item.id;
          this.formDataSkuEdit.workOrderProductIds = [item.workOrderProductId];
          this.formDataSkuEdit.workOrderProductId = item.workOrderProductId;
          this.formDataSkuEdit.defectCategoryId = this.isDCLOrGeneric() ? item.clientDefect.productCategoryId : item.defectCategoryId;
          this.formDataSkuEdit.defectNameId = this.isDCLOrGeneric() ? item.clientDefectId : item.defectNameId;
          this.formDataSkuEdit.clientDefectId = item.clientDefectId;
          this.formDataSkuEdit.defectCode = item.defectCode;
          this.formDataSkuEdit.qty = item.qty;
          this.formDataSkuEdit.defectClassificationId = item.defectClassificationId;
          this.formDataSkuEdit.defectDetails = item.defectDetails;
          this.formDataSkuEdit.dclTypeAliasId = item.dclTypeAliasId;
          break;
        case "defectList":
          this.dialog_defect_edit = true;
          this.formDataDefectEdit.id = item.id;
          this.formDataDefectEdit.workOrderProductIds = [item.workOrderProductId];
          this.formDataDefectEdit.workOrderProductId = item.workOrderProductId;
          this.formDataDefectEdit.defectCategoryId = this.isDCLOrGeneric() ? item.clientDefect.productCategoryId : item.defectCategoryId;
          this.formDataDefectEdit.defectNameId = this.isDCLOrGeneric() ? item.clientDefectId : item.defectNameId;
          this.formDataDefectEdit.clientDefectId = item.clientDefectId;
          this.formDataDefectEdit.defectCode = item.defectCode;
          this.formDataDefectEdit.qty = item.qty;
          this.formDataDefectEdit.defectClassificationId = item.defectClassificationId;
          this.formDataDefectEdit.defectDetails = item.defectDetails;
          this.formDataDefectEdit.dclTypeAliasId = item.dclTypeAliasId;
          break;
        case "classFicationList":
          this.dialog_classFication_edit = true;
          this.formDataClassFicationEdit.id = item.id;
          this.formDataClassFicationEdit.workOrderProductIds = [item.workOrderProductId];
          this.formDataClassFicationEdit.workOrderProductId = item.workOrderProductId;
          this.formDataClassFicationEdit.defectCategoryId = this.isDCLOrGeneric() ? item.clientDefect.productCategoryId : item.defectCategoryId;
          this.formDataClassFicationEdit.defectNameId = this.isDCLOrGeneric() ? item.clientDefectId : item.defectNameId;
          this.formDataClassFicationEdit.clientDefectId = item.clientDefectId;
          this.formDataClassFicationEdit.defectCode = item.defectCode;
          this.formDataClassFicationEdit.qty = item.qty;
          this.formDataClassFicationEdit.defectClassificationId = item.defectClassificationId;
          this.formDataClassFicationEdit.defectDetails = item.defectDetails;
          this.formDataClassFicationEdit.dclTypeAliasId = item.dclTypeAliasId;
          break;
      }
    },
    del_show_dialog(item, item_p, table_array) {
      this.delItem.id = item.id;
      this.delItem.item_p = item_p;
      switch (table_array) {
        case "skuList":
          this.showConfirmDialogSku = true;
          break;
        case "defectList":
          this.showConfirmDialogDefect = true;
          break;
        case "classFicationList":
          this.showConfirmDialogClassification = true;
          break;
      }
    },

    async del_suk_table(table_array) {
      try {
        this.showProgressDialog = true;
        let response = await this.utilityFn.getServerDataAsync("delete", this.apiList.deleteWorkOrderProductDefect + "/" + this.delItem.id, {});
        
        this.WorkOrderProductDefects = this.WorkOrderProductDefects.filter(x => x.id !== this.delItem.id);
        this.remapWorkOrderProductDefects();
        
        this.close();
        this.showProgressDialog = false;
      } catch (err) {
        this.showProgressDialog = false;
        console.log(err);
        if (window.navigator.onLine != true) {
          this.Products.forEach((item1) => {
            this.delItem.item_p.forEach((item, index) => {
              if (item.id == this.delItem.id) {
                this.delItem.item_p.splice(index, 1);
              }
            });
          });
          this.message = this.msgList.NoConnection;
          this.snackbar = true;
        }
        this.close();
      }
      //this.calculateResult()
    },

    option_gallery() {
      this.$router.push({
        name: "uploadPhotoGallery",
      });
    },
    option_file(item) {
      var inputObj = document.createElement("input");
      inputObj.setAttribute("id", "_ef");
      inputObj.setAttribute("type", "file");
      inputObj.setAttribute("style", "visibility:hidden");
      inputObj.onchange = (res) => {
        var form = new FormData();
        form.append("Image", inputObj.files[0]);
        form.append("defectid", item.id);
        form.append("Description", "Tab1");
        this.apiFn.UploadDefectImage(form).then((result) => {
          console.log("Image Upload");
        });
      };
      inputObj.click();
    },
    async submit_item(sectionSubmitted = false, fromSave = false) {
      if(!this.isSKUResultValid || !this.isDefectResultValid || !this.isDefectClassificationValid) {
        this.snackbar = true;
        this.message = "Missing Input Required Fields";
        return;
      }
      if ((this._workOrderDetails.clientName.toUpperCase() == 'WALMART GLOBAL SOURCING' || this._workOrderDetails.clientName.toUpperCase() == 'ASDA STORES LIMITED') && this._workOrderDetails.isPreShipOrFinalRandom && !fromSave){
        var validateWorksmanship = await this.utilityFn.getServerDataAsync('get',`${this.apiList.validateWorksmanship}/${this.$route.params.id}`,{});
          if (validateWorksmanship.data){
            if (validateWorksmanship.data.item1){
              this.snackbar = true;
              this.message = validateWorksmanship.data.item2;
              return;
            }
          }
      }
        
      this.sectionSubmitted = sectionSubmitted;

      const section = {
        sectionId: this.sectionId,
        workOrderId: this.$route.params.id,
      };
      await this.utilityFn.getServerDataAsync("post", this.apiList.submitInspectionSection, section);
      this.sectionData.Workmanship.isSectionSubmitted = true;
      this.$store.dispatch("inspection/setSectionData", this.sectionData);

      const index = this._workOrderDetails.workOrderInspectionSections.findIndex((x) => x.sectionId == this.sectionId);
      if (index !== -1) {
        this._workOrderDetails.workOrderInspectionSections[index].submittedDate = new Date();
        this.$store.dispatch("inspection/setWorkOrderDetails", _.cloneDeep(this._workOrderDetails));
      }
    },
    go_upload_photo() {
      this.$router.push({
        name: "uploadPhotoGalleryId",
        params: {
          id: this.$route.params.id,
          name: this.sectionData.Workmanship.name,
          sectionId: this.sectionData.Workmanship.id,
        },
        query: {
          name: this.sectionData.Workmanship.name,
          sectionId: this.sectionData.Workmanship.id,
        },
      });
    },

    onChange() {
      this.logChanges();
    },

    logChanges(log = true) {
      window.sessionStorage.setItem("EditDataLogging", log);
    },

    // only save when the isFormDirty is true - means user has changed the value in the UI
    // user can still force saving even if nothing has been changed in UI if "forceSave" is true
    // "forceSave" value will come from the Footer Component. The footer component has a Save button
    // that calls this method and will force this method to call the API to save the values
    async saveData(forceSave = false) {
      if (!forceSave && !this.isFormDirty)
        return

      try {
        const request1 = async () => {
          let section = this.workOrder.workOrderInspectionSections.find((x) => x.sectionId == this.sectionId);
          let result = "";
          switch (this.WorkOrderList.workmanshipResult) {
            case "1":
              result = "Conformed";
              break;
            case "2":
              result = "Non-Conformed";
              break;
            case "3":
              result = "Pending";
              break;
            case "4":
              result = "N/A";
              break;
          }
          this.inspectionSummarySectionResult.forEach(x => {
            if(x.sectionId==5){
              x.result = result;
            }
          })
          this.$store.dispatch("inspection/setInspectionSummaryResult", this.inspectionSummarySectionResult);
          
          if (!this.isSectionDisabled || this.sectionSubmitted) {
            const section = this.sectionDetail;
            section.result = result;
            
            const index = this._workOrderDetails.workOrderInspectionSections.findIndex((x) => x.sectionId == this.sectionId);
            if (index !== -1) {
              this._workOrderDetails.workOrderInspectionSections[index] = section;
              this.$store.dispatch("inspection/setWorkOrderDetails", _.cloneDeep(this._workOrderDetails));
            }

            await this.utilityFn.getServerDataAsync("post", this.apiList.sectionUpdate, section);
          }
          
          if (this.workOrder.resources.length === 1) {
            await this.submit_item(false,true);
          }

          this.logChanges(false);
        };

        await Promise.all([this.upsertWorkmanshipResultTable(), request1(), this.saveDataTableValues()]);

        return true;
      } catch (ex) {
        console.log("saveData error", ex);
        return false;
      }
    },

    /**
     * Update or Insert defect to WorkOrderProductDefects model
     */
    upsertDefect(data, id) {
      this.existingValue = null;
      if (!data) return

      // populate underlying object for the defect before inserting or updating
      if (this.isDCLOrGeneric()) {
        const clientDefect = this.clientDefectListSection.find(x => x.id == data.clientDefectId);
        data.clientDefect = clientDefect;
        data.defectName = {
          id: 0,
          code: clientDefect.defectCode,
          name: clientDefect.description,
          defectCategoryId: null,
          defectCategory: null
        };
        data.defectCategory = null;
      } else {
        data.clientDefect = null;
        data.defectCategory = this.DefectCategories.find(x => x.id == data.defectCategoryId);
        data.defectName = this.DefectNames.find(x => x.id == data.defectNameId);
      }
      
      data.defectClassification = this.DefectClassifications.find(x => x.id == data.defectClassificationId);
      
      // check if defect is existing in the list
      // insert if not yet present in the list
      // update if already in the list
      const index = this.WorkOrderProductDefects.findIndex(x => x.id === data.id);
      if (index === -1) {
        data.id = id;
        this.WorkOrderProductDefects.push(data);
      }
      else {
        data.defectClassification = this.DefectClassifications.find(x => x.id == data.defectClassificationId);
        this.$set(this.WorkOrderProductDefects, index, data);
      }

      this.remapWorkOrderProductDefects();
    },


    async loadWorkOrderRatingOptions() {
      const result = await  this.utilityFn.getServerDataAsync("get", this.apiList.GetCompanyWOResultTypeAliases + "?companyId=" + this._workOrderDetails.clientId, {});
      this.workOrderRatingOptions = result.data.filter((x) => x.description && x.isActive);
    },

    getResultCodes(resultId) {
      const result = this.select_result.find(x => x.value == resultId);
      const woRating = this.workOrderRatingOptions.find(x => x.aimsSectionRating?.toLowerCase() == result?.alias?.toLowerCase());

      if (woRating?.companyWorkOrderResultTypeCodes.length > 0) {
        this.isGenericCodes = false;
        return woRating.companyWorkOrderResultTypeCodes?.filter(x => x.isActive).sort((a,b) => (a.order < b.order) ? -1 : 1) || [];
      }
      this.isGenericCodes = true;
      return this.defaultFailCodeOptions.map(x => ({ code: x }));
    },
    onSelect(value) {
      if(this.dialog_sku) {
        this.formDataSku.detail = value?.description;
      }

      if(this.dialog_sku_edit) {
        this.formDataSkuEdit.defectDetails = value?.description;
      }

      if(this.dialog_defect) {
        this.formDataDefect.detail = value?.description;
      }

      if(this.dialog_defect_edit) {
        this.formDataDefectEdit.defectDetails = value?.description;
      }

      if(this.dialog_classFication) {
        this.formDataClassFication.detail = value?.description;
      }

      if(this.dialog_classFication_edit) {
        this.formDataClassFicationEdit.defectDetails = value?.description;
      }
    },
    sampleSizeLabel(){
      return this._workOrderDetails.clientName.toUpperCase() == 'WALMART GLOBAL SOURCING' || 'ASDA STORES LIMITED' ? "Sample Size *" : "Sample Size";
    }
  },
  watch: {
    isDataLoaded(val1, val2) {
      setTimeout(() => {
        this.isFormReadyCheck = true;
      });
    },

    WorkOrderList: {
      handler(val1, val2) {
        this.isFormDirty = this.isFormReadyCheck;
      },
      deep: true
    },

    workOrder: {
      handler(val1, val2) {
        this.isFormDirty = this.isFormReadyCheck;
      },
      deep: true
    },
    
    Products: {
      handler(val1, val2) {
        this.isFormDirty = this.isFormReadyCheck;
      },
      deep: true
    }
  },
};
</script>
<style lang="stylus" scoped>
.workOrderInspectionWorkmanshipId {
  padding: 15px 10px;
}

v-btn-group button {
  height: 48px !important;
}

.tab_item {
  padding: 25px 15px;
  overflow: hidden;
  position: relative;
}

>>>.v-expansion-panel-content__wrap {
  padding: 0;
}

>>>.number_text input {
  text-align: center;
}

.submit-btn {
  color: #fff !important;
}

.code-container {
  display: flex;
  flex-direction: column;
}

.helper-container {
  margin-bottom: -18px;
  margin-top: -18px;
  margin-left: 75px;
}
</style>
