import * as moment from 'moment';

var data = {}


data.buildBaseEmbeddedUrl = function (url) {
    if (!url || url.indexOf('groups') === -1 || url.indexOf('rdlreports') === -1) return url;

    const el = document.createElement('a');
    el.href = url;

    const routes = el.pathname.split('/');
    const groupIndex = routes.indexOf('groups');
    const reportIndex = routes.indexOf('rdlreports');
    const groupId = routes[groupIndex + 1];
    const reportId = routes[reportIndex + 1];

    // build
    return `https://app.powerbi.com/rdlEmbed?reportId=${reportId}&groupId=${groupId}`;
}

data.storeExportAuth = function(authData) {
    if (authData) {
        const cfrExportTokenExpiration = moment().add(authData.expires_in, 'seconds').toDate().getTime();
        localStorage.setItem('cfrExportTokenExpiration', cfrExportTokenExpiration);
        localStorage.setItem('cfrExportToken', authData.access_token);
    }
}

data.getExportAuth = function() {
    const currentExportTokenExpiration = localStorage.getItem('cfrExportTokenExpiration');
    const token = localStorage.getItem('cfrExportToken');

    const currentTime = moment().toDate().getTime();

    if (!token || !currentExportTokenExpiration || currentTime >= Number(currentExportTokenExpiration)) {
        localStorage.setItem('cfrExportToken', null);
        return null;
    } else {
        return token;
    }
}

export default data
