import axios from "axios";
import auditLog from "./auditLogs";
import apiList from "./apiList";
import router from "../../router/index";
import auth from '../../shared/request/auth';

const noAdditionalHeaders = [apiList.cfrExport];

export default function setup() {
  axios.interceptors.request.use(    
    function(config) {
      if (noAdditionalHeaders.indexOf(config.url) === -1 && !config.url.includes("logout")) {    
        const auditHeader = auditLog.getHeaderDetails();
        config.headers.TransactionCode = auditHeader.transactionCode;
        config.headers.AppName = "AIMS";        
        config.headers.Module = auditHeader.module;
        config.headers.UserAction = router.currentRoute.path;
        config.headers.EntityKey = auditHeader.entityKey;
        config.headers.ResourceId = auditHeader.resourceId;
        config.headers.EditDataLogging = auditHeader.editDataLogging;
        config.headers.EditDataLoggingGeneral = auditHeader.editDataLoggingGeneral;

        // image related tags
        config.headers.SectionId = auditHeader.sectionId;
        config.headers.WorkOrderProductId = auditHeader.workOrderProductId;
        config.headers.ImageDescription = auditHeader.imageDescription;
        config.headers.DefectId = auditHeader.defectId;
      }

      config.headers['Authorization'] = "Bearer " + auth.getToken();

      //console.log('Interceptor1', Object.assign({}, config));
      return config;
    },
    function(err) {
      return Promise.reject(err);
    }
  );



  async function onRejectHandler(error) {
        
    //if we recieve 401 error, then call authenticate to get new token
    if(error.response.status === 401) {
      return auth.authenticate().then((result) => {
        if(result) {
          return axios.request(error.config);
        }
        return;
      });
    }
    
    return Promise.reject(error);
  }



  axios.interceptors.response.use(
    async function(value, onRejectHandler) {
      let respUrl = String(value.request["responseURL"]);
      if(respUrl.includes("/error")) {
        try {
          await router.replace({
            path: "/error",
            name: "error",
          });
          return Promise.reject("Fetch Failed");
        } catch (err) {
          throw new Error(`Problem handling routing in interceptor: ${err}.`);
        }
      }
      try {
        if (value.config.method !== "get" && value.status === 200) {
          auditLog.createActivity(value.config.headers, value.config.url, value.config.data, value.data);
        }
        return value;
      } catch (e) {
        return onRejectHandler(e);
      }
    }, // onRejected
    onRejectHandler
  );
}
