<template>
<div>
    <v-dialog v-model="barcodeDialog" max-width="50%">
                          <v-card>                     
                            <v-card-title>
                              <span class="headline">Barcode Scanner</span>
                            </v-card-title>                         
                            <v-card-text>
                              <v-container>
                                <v-row>
                                <div id="interactive" class="viewport">
                                <video class="videoCamera" autoplay="true" preload="auto" src="" muted="true"
                                        playsinline="true"></video>
                                <canvas class="drawingBuffer"></canvas>
                                </div>
                                </v-row>                               
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn  text @click="close">Close</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>                            
    </div>

</template>
<script>
    import Vue from 'vue';
     import Quagga from 'quagga';
    Vue.use(Quagga)
   
    export default  {
        props:{
            barcodeValue: String,
            showDialog: Boolean,
            barcodeType: String,
            parentData: Object,
        },
        data(){ 
            return {
                scannedValue:'',
                scannedBarcode: {
                  scannedValue:'',
                  isScanned:false,
                  screen:'',
                },
                barcodeDialog: true,
                type:'',
                config:null,
                start:false,
                barcode:'',
            }
        },
       

        mounted () { 
            this.barcodeDialog = true;
                         
            this.barcode = '';
            this.config = this.buildConfig(this.parentData);
            this.startScanner();
           
            Quagga.onProcessed((result) => this.onProcessed(result));
            Quagga.onDetected((result) => this.logCode(result));  
          

        },

        methods:{
            formatBarcodeType(barcodeType) {
                
                barcodeType = barcodeType.replace(/[\-]/g, '_')
                let barcodeReaderType = (barcodeType.replace(/\s+/g, '')).toLowerCase();                        
                if (barcodeReaderType === 'ean_13') {
                    return [{
                        format: "ean_reader",
                        config: {}
                            }];
                }
                if (barcodeReaderType === 'gs1_128') {
                    return [{
                        format: "code_128_reader",
                        config: {}
                            }];
                }
                
                if (barcodeReaderType === 'itf_14') {
                    return [{
                        format: "i2of5_reader",
                        config: {}
                            }];
                }
                if (barcodeReaderType === 'upc_a') {
                    return [{
                        format: "upc_reader",
                        config: {}
                            }];
                }
                if (barcodeReaderType === 'ucc_128') {
                    return [{
                        format: "upc_e_reader",
                        config: {}
                            }];
                }
                return [{
                    format: barcodeReaderType + "_reader",
                    config: {}
                        }];
            },

            startScanner() {    
                Quagga.init(this.config, (err) => {
                    if (err) {
                        return console.log(err);              
                    }
                    Quagga.start();
                    this.start = true;
                });
            },
            buildConfig(select) {
                return {
                        inputStream: {
                        name: 'Live',
                        type: select.type,
                        target: '#interactive',
                        constraints: {
                            width: {min: 640},
                            height: {min: 480},
                            facingMode: "environment",
                            aspectRatio: {min: 1, max: 100}
                        },
                        singleChannel: true // true: only the red color-channel is read
                    },
                    locator: {
                        patchSize: 'medium',
                        halfSample: true
                    },
                    locate: true,
                    numOfWorkers: 2,
                    frequency: 10,
                    decoder: {
                        readers: this.formatBarcodeType(select.barcodeType)
                    },
                             
                };
            },

            onProcessed(result) {
            var drawingCtx = Quagga.canvas.ctx.overlay,
            drawingCanvas = Quagga.canvas.dom.overlay;

                if (result) {
                    if (result.boxes) {
                        drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
                        result.boxes.filter(function (box) {
                            return box !== result.box;
                        }).forEach(function (box) {
                            Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, drawingCtx, {color: "green", lineWidth: 2});
                        });
                    }

                    if (result.box) {
                        Quagga.ImageDebug.drawPath(result.box, {x: 0, y: 1}, drawingCtx, {color: "#00F", lineWidth: 2});
                    }

                    if (result.codeResult && result.codeResult.code) {
                        Quagga.ImageDebug.drawPath(result.line, {x: 'x', y: 'y'}, drawingCtx, {color: 'red', lineWidth: 3});
                    }
                }
            },

            logCode(result) {
                const code = result.codeResult.code;
                if (this.barcode !== code) {
                    this.barcode = code;                
                    this.scannedBarcode.scannedValue = code;
                    this.scannedBarcode.isScanned = true;
                    this.scannedBarcode.screen = this.parentData.screen;
                    this.scannedValue = code;
                    this.$emit('changeValue', this.scannedBarcode);
                    this.close();

                }

            },


            loadConfig(event) {
                
            },
            close() {             
                    Quagga.stop();
                    this.barcodeDialog = false;
            },
        },
        beforeCreate(){
         
        },
        beforeDestroy () {
            Quagga.stop();
        },
    }
</script>

<style lang="stylus" scoped>
    .drawingBuffer {
    position: absolute;
    top: 0;
    left: 0;
    }
      .videoCamera{
        width:100%;
        height:100%;
    }
</style>