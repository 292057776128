var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"sidebarWrapper",staticClass:"sidebar-wrapper",class:_vm.wrapperClass},[_c('div',{ref:"sidebarScroller",staticClass:"sidebar-scroller",style:({
            'padding-left': _vm.widthProp
        })},[_c('div',{staticClass:"sidebar-main",style:({
                width: _vm.widthProp
            }),on:{"scroll":function($event){$event.stopPropagation();}}},[_vm._t("default")],2),_c('div',{ref:"sidebarToggle",staticClass:"touch-toggle",style:({
                'opacity': _vm.opacity,
                'padding-left': _vm.widthProp
            }),on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleClick.apply(null, arguments)}}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }