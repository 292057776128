<template>
  <div>
    <div class="uploadPhotoGeneralId">
      <v-row v-for="(groupAttachment, gaIndex) in groupedAttachments" :key="gaIndex">
        <v-col cols="12" sm="10">
          <span class="section_header">{{ groupAttachment.description }}</span>
          <v-btn depressed class="delete_selected" v-if="groupAttachment.attachments.length > 0" @click="delete_all_images(gaIndex, groupAttachment.attachments)">Delete All</v-btn>
        </v-col>
        <v-col cols="12" sm="2">          
          <v-btn icon dark style="float: right;" @click="option_file(groupAttachment.description)">
            <v-icon color="rgba(0,0,0,.87)">mdi-camera</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" sm="12">
          <draggable class="imglist select_img" v-model="groupAttachment.attachments">
            <transition-group tag="ul">
              <li v-for="(attachment, aIndex) in groupAttachment.attachments" :key="aIndex" class="item">
                <div v-if="!attachment.src">
                  Loading...
                  {{ loadImage(gaIndex, aIndex, attachment.fileUploadId) }}
                </div>
                <div v-if="attachment.src === 'loading'">
                  Loading...
                </div>
                <div v-else class="holder">
                  <span class="badge-number">{{ aIndex + 1 }}</span>
                  <span v-if="!isImageTemporary(attachment) || !showProgressDialog" class="del_button" @click="del_img(gaIndex, aIndex, attachment.fileUploadId)">
                    <icon name="times-circle" class="icon"></icon>
                  </span>
                  <img class="imgItem" :src="attachment.src" />
                </div>
              </li>
            </transition-group>
          </draggable>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" sm="12" md="6">
          <v-text-field label="Enter Photo Label" v-model="customDescription"></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-btn color="red darken-1" text @click="option_file(customDescription)" :disabled="!customDescription">Add New Photo Label</v-btn>
        </v-col>
      </v-row>

      <v-dialog v-model="showProgressDialog" :persistent="true">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <v-container v-for="(image, index) in addedImages" :key="index">
              <v-progress-linear :value="image.uploadProgress" color="light-blue" height="25" reactive>
                <template v-slot="{ value }">
                  <strong>{{ image.fileName }} {{ Math.ceil(image.uploadProgress) }}%</strong>
                </template>
              </v-progress-linear>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import * as _ from "lodash";
import { mapState } from "vuex";
let state = {
  appHeaderState: {
    show: true,
    title: "Supplier/Site",
    showMenu: true,
    showBack: true,
    showLogo: false,
    showTitle: true,
    showSearch: false,
    showCalendar: true,
  },
};

function setState(store) {
 // store.dispatch("navigation", state.appHeaderState);
  store.dispatch('navigation/setAppHeader', state.appHeaderState);
}
export default {
  name: "uploadPhotoGeneralId",

  components: {
    draggable,
  },

  data() {
    return {
      imageList1: [
        "https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1",
        "https://cn.bing.com/th?id=OIP.m2xhGEIwS2d5EJZEU9XLBwAAAA&pid=Api&rs=1",
        "https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1",
        "https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1",
        "https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1",
      ],
      imageList2: [
        "https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1",
        "https://cn.bing.com/th?id=OIP.m2xhGEIwS2d5EJZEU9XLBwAAAA&pid=Api&rs=1",
        "https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1",
        "https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1",
        "https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1",
      ],
      imageList3: [
        "https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1",
        "https://cn.bing.com/th?id=OIP.m2xhGEIwS2d5EJZEU9XLBwAAAA&pid=Api&rs=1",
        "https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1",
        "https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1",
        "https://cn.bing.com/th?id=OIP.piv-T61QrgN-B0HkMQuJCQAAAA&pid=Api&rs=1",
      ],
      newLabelList: [
        {
          value: "",
          imgList: [],
        },
      ],

      customDescription: null,
      workOrder: {},
      attachments: [],
      groupedAttachments: [],
      groupedDescriptions: [],
      addedImages: [],
      showProgressDialog: false,
      isWalmart: false, 
      staticDescriptions:  ["Factory Gate", "Warehouse"],
    };
  },
  mounted() {
    setState(this.$store);

    this.validateIfWalmart();

     this.utilityFn.getServerData("get", this.apiList.getWorkOrderAttachment + 
        "?workOrderId=" + this.$route.params.id + "&sectionId=8", {}, (res) => {
          if (res) {
            this.attachments = res.data.data
               .map((attachment) => {
                attachment.src = null;
                attachment.added = true;
                attachment.selected = false;
                return attachment;
              });
           
            this.groupTheAttachments();
          }
        });
    /* this.apiFn.getWorkOrderDetails(this.$route.params.id).then((result) => {
      this.workOrder = result[0];
      this.attachments = this.workOrder.workOrderAttachments
        .filter((attachment) => attachment.sectionId === 8)
        .map((attachment) => {
          attachment.src = null;
          attachment.added = true;
          return attachment;
        });
      this.groupTheAttachments();
    }); */

    this.setSessionDetails();
  },
  methods: {
    validateIfWalmart() {
      let val = this._workOrderDetails
      if (val == null) {
        this.descriptionList = []
      }
      else {      
        this.isWalmart = val.clientName == 'WALMART GLOBAL SOURCING' || val.clientName.toLowerCase() == 'asda stores limited' ? true : false
        if (this.isWalmart) {
         this.staticDescriptions = ["Factory Gate","Factory Name Board", "Warehouse"]
        } 
      }

    },
    option_file(description) {
      var inputObj = document.createElement("input");
      inputObj.setAttribute("id", "_ef");
      inputObj.setAttribute("multiple", "multiple");
      inputObj.setAttribute("type", "file");
      inputObj.setAttribute("style", "visibility:hidden");
      document.body.appendChild(inputObj);
      inputObj.click();
      inputObj.onchange = (res) => {
        // 弹出上传进度
        
        for (let i = 0; i < inputObj.files.length; i++) {
          const file = inputObj.files[i];
          var sizeInfo = this.utilityFn.formatBytes(file.size)    
          console.log('sizeInfo=>',sizeInfo)
          if(sizeInfo.sizeKB > 500){
            this.$store.dispatch("navigation/setSnackbar",{
              color :'error',
              text:`Each upload only accepts image size less than 500KB. Please resize/compact the image with size greater than 500KB. Filename: ${file.name}.`
              });
          }
          else{
            if (window.navigator.onLine) {
              this.showProgressDialog = true;
            } else {
              alert("Photo saved offline, will auto update when device is online");
            }

          const addedImage = { id: i, uploadProgress: 0, fileName: file.name };
          this.addedImages.push(addedImage);

          const form = new FormData();
          form.append("image", file);
          form.append('workOrderId', this.$route.params.id);
          form.append("description", description);
          form.append("screen", 8);

          this.apiFn
            .uploadWorkOrderImage(form, (progress) => {
              const imgIndex = this.addedImages.findIndex((x) => x.id === addedImage.id);
              if(this.addedImages[imgIndex])   this.addedImages[imgIndex].uploadProgress = progress;
            })
            .then((result) => {
              const imgIndex = this.addedImages.findIndex((x) => x.id === addedImage.id);
              this.addedImages.splice(imgIndex, 1);

              // get the base64 string of the file
              this.getBase64(file).then((base64) => {
                // create a new attachment entry and append to the current attachments array
                const attachment = { src: base64, ...result };
                this.attachments.push(attachment);

                this.checkUploadStatus();
              });
            });
          }
          
        }
      };

      this.logChanges();
    },

    del_img(groupedAttachmentIndex, attachmentIndex, imageId) {
      this.groupedAttachments[groupedAttachmentIndex].attachments.splice(attachmentIndex, 1);
      
      const attIndex = this.attachments.findIndex(x => x.fileUploadId == imageId)
      this.attachments.splice(attIndex,1)
     
     this.apiFn.deleteImage(imageId).then((result) => {});

      this.logChanges();


    },
    
    delete_all_images(gaIndex, attachments) {
      if(confirm("Are you sure to delete photo(s)?")) {
        var del_img_ids = attachments.map(x => x.fileUploadId);

        //delete each image
        _.forEach(del_img_ids, (x) => {        

          let index = attachments.findIndex((y) => y.fileUploadId === x);
          this.groupedAttachments[gaIndex].attachments.splice(index, 1);
    
          const attIndex = this.attachments.findIndex(y => y.fileUploadId == x)
          this.attachments.splice(attIndex,1)

          this.apiFn.deleteImage(x).then((result) => {});
        });
        this.logChanges();
      }
    },

    add_new_photo_label() {
      this.newLabelList.push({
        value: "",
        imageList: [],
      });
    },
    afterRead(imageFiles) {
      // this.showProgressDialog = true;
      const _images = [];
      for (let i = 0; i < imageFiles.length; i++) {
        const imageFile = imageFiles[i];
        const file = imageFile.file;
        var fileSize = file.size;
        const addedImage = { id: i, uploadProgress: 0, fileName: file.name };
        this.addedImages.push(addedImage);

        const form = new FormData();
        form.append("image", file);
        form.append('workOrderId', this.$route.params.id);
        form.append("description", "");
        form.append("screen", 8);
        formData.append("order", (this.groupedAttachments.length + 1) * 100 + (i + 1));

        this.apiFn
          .uploadWorkOrderImage(form, (progress) => {
            this.addedImages[i].uploadProgress = progress;
          })
          .then((result) => {
            const imgIndex = this.addedImages.findIndex((x) => x.id === addedImage.id);
            this.addedImages.splice(imgIndex, 1);
            this.checkUploadStatus();

            // get the base64 string of the file
            this.getBase64(file).then((base64) => {
              // create a new attachment entry and append to the current attachments array
              const attachment = { src: base64, ...result };
              this.defects[defectIndex].workOrderDefectAttachments.push(attachment);
            });
          });
      }

      this.logChanges();
    },
    loadImage(groupedAttachmentIndex, attachmentIndex, imageId) {
      this.groupedAttachments[groupedAttachmentIndex].attachments[attachmentIndex].src = "loading";
      this.groupedAttachments[groupedAttachmentIndex].attachments = [...this.groupedAttachments[groupedAttachmentIndex].attachments];

      this.apiFn.getImage(imageId).then((result) => {
        let src = "data:" + result.fileType + ";base64," + result.data;
        this.groupedAttachments[groupedAttachmentIndex].attachments[attachmentIndex].src = src;
        this.groupedAttachments[groupedAttachmentIndex].attachments = [...this.groupedAttachments[groupedAttachmentIndex].attachments];
      });
    },
    groupTheAttachments() {
      this.addedImages = [];
      this.groupedAttachments = [];
      this.groupedDescriptions = _.uniq(_.map(this.attachments, "description"));
      this.staticDescriptions.forEach((desc) => {
        let index = this.groupedDescriptions.indexOf(desc);
        if (index !== -1) {
          this.groupedDescriptions.splice(index, 1);
        }
      });
      this.groupedDescriptions.unshift(...this.staticDescriptions);
      this.groupedDescriptions.forEach((description) => {
        const filteredAttachments = this.attachments.filter((x) => x.description === description);
        this.groupedAttachments.push({
          description,
          attachments: _.orderBy(filteredAttachments, ["order"], ["asc"]),
        });
      });
    },
    loadTempImage(index, file) {
      this.getBase64(file).then((base64) => {
        this.addedImages[index].src = base64;
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    // check if the image being displayed is a newly added image
    isImageTemporary(attachment) {
      if(attachment && attachment.fileUploadId)
        return attachment.fileUploadId.indexOf("temp_") !== -1;
       else
         return true; 
    },
    checkUploadStatus() {
      this.showProgressDialog = this.addedImages.length !== 0;
      if (!this.showProgressDialog) {
        this.customDescription = null;
        this.groupTheAttachments();
      }
    },
    saveImageOrder() {
      this.groupedAttachments.forEach((g, gIndex) => {
        const base = (gIndex + 1) * 100;
        g.attachments.forEach((a, aIndex) => {
          a.order = base + aIndex + 1;
          a.isIncludeInCFR = true;
          a.sectionId = 8;
          a.src = null;
        });

        this.utilityFn.getServerData("put", this.apiList.updateWorkOrderImages, g.attachments, (result) => {});
        // this.apiFn.updateWorkOrderImages(g.attachments);
      });
    },

    logChanges() {
      window.sessionStorage.setItem("EditDataLogging", true);
    },
    setSessionDetails() {
      window.sessionStorage.setItem("SectionId", 8);
    },
  },
   computed: { 
    ...mapState("inspection", {
      _workOrderDetails: (state) => state.workOrderDetails
    }),
   },
  beforeDestroy() {
    this.saveImageOrder();
  },
};
</script>

<style lang="stylus" scoped>
.row
    background #fff
    margin 15px
    padding 15px 0
.item
    display inline-block
    width 12%
    margin 1.14%
.holder
    position relative
.imglist .holder .imgItem
    width 100%

.public_img, .select_img
    text-align left
    // border 1px #ccc solid
    width 96%
    margin 20px auto
    background #ffffff
    padding 10px 0
.public_img .imgItem
    width 12%
    margin 1.14%

.del_button
    position absolute
    right -3px
    top -5px

.checkbox_img
  position: absolute;
  bottom: -45px;
  left: -5px;
  
.delete_selected
  margin-left:16px;

.section_header
  font-size: 1.17em;
  font-weight: bold;
</style>
