<template>
    <div class="box">
        <div class="expense">
            <v-row style="background:#fff;margin:auto;">
                <v-col cols="12" sm="12" md="12">
                    <h3>Actual Expense</h3>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <v-btn  @click="getJobDetails()">Work Order</v-btn>
                </v-col>
                <v-col id="currencyId" cols="12" sm="12" md="12">
                    <v-select v-model="form.currencyId" :items="currencyList" label="Currency" item-text="name" item-value="id" @change="onValuesChange"> </v-select>
                </v-col>
                <!-- <v-col cols="12" sm="12" md="12">
                    <h3 style="text-align:center;font-size:23px;margin-bottom:30px;">Currency</h3>
                </v-col> -->
                <v-col id="id_air" cols="12" sm="6" md="6" style="text-align:center;">
                    <input style="border:1px #ccc solid;text-align:center;" type="text" v-model="form.air" @keyup="returnNumber(form, 'air')" >
                    <p style="margin-top:20px;">Air</p>
                </v-col>
                <v-col id="id_transportation" cols="12" sm="6" md="6" style="text-align:center;">
                    <input style="border:1px #ccc solid;text-align:center;" type="text" v-model="form.transportation" @keyup="returnNumber(form, 'transportation')" >
                    <p style="margin-top:20px;">Transportation</p>
                </v-col>
                <v-col id="id_hotel" cols="12" sm="6" md="6" style="text-align:center;">
                    <input style="border:1px #ccc solid;text-align:center;" type="text" v-model="form.hotel" @keyup="returnNumber(form, 'hotel')" >
                    <p style="margin-top:20px;">Hotel</p>
                </v-col>
                <v-col id="id_meals" cols="12" sm="6" md="6" style="text-align:center;">
                    <input style="border:1px #ccc solid;text-align:center;" type="text" v-model="form.meals" @keyup="returnNumber(form, 'meals')" >
                    <p style="margin-top:20px;">Meals</p>
                </v-col>
                <v-col id="id_misc" cols="12" sm="6" md="6" style="text-align:center;">
                    <input style="border:1px #ccc solid;text-align:center;" type="text" v-model="form.misc" @keyup="returnNumber(form, 'misc')" >
                    <p style="margin-top:20px;">Misc</p>
                </v-col>
                 <v-col id="id_totalExpense" cols="12" sm="6" md="6" style="text-align:center;">
                    <input style="border:0px #ccc solid;text-align:center;" v-model="totalExpense" type="text" readonly >
                    <p style="margin-top:20px;">Total Expense</p>
                </v-col>
                <v-col id="btn_Save" cols="12" sm="12" md="12" style="text-align:center;">
                    <v-btn class="submit" color="#B14C4C" min-width="500" dark style="margin:20px auto;display:block;"  @click="submit">save</v-btn>
                </v-col>
            </v-row>
          
            <v-snackbar v-model="snackbar" top :timeout="-1" color="#4caf50">
                <v-progress-circular :size="15" indeterminate color="primary"></v-progress-circular>
                <span style="padding-left:10px;">Loading</span>
            </v-snackbar>

            <v-snackbar
            v-model="snackbarError"
            :timeout="timeoutError"
            :color="colorError"
            >
            {{ messageError }}
            <v-btn
                text
                @click="snackbarError = false"
            >
                Close
            </v-btn>
            </v-snackbar>
        </div>

        <v-dialog v-model="showJobDetails" width="100%">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                
                    <div v-if="isCurrentlyLoading" class="text-center">
                        <v-progress-circular  :size="50" color="primary" indeterminate></v-progress-circular>
                    </div>
                    <div v-else-if="!isCurrentlyLoading && !jobDetails" class="text-center">
                        Unable to load the data.
                    </div>
                     <div v-else-if="!isCurrentlyLoading && jobDetails">
                        <v-row style="background: #fff;width: 100%;margin: 10px auto;">
                            <v-col v-if="jobDetails.dataCaptureModifiedDate" cols="12" sm="10" md="10" class="state_tag_In-progress">
                            <span>In-progress</span>
                            </v-col>
                            <v-col v-else cols="12" sm="10" md="10" class="state_tag_New">
                            <span>New</span>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="py-2">
                            <label>Job ID</label>
                            <span id="JobID">{{ jobDetails.jobDisplayId }}</span>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="py-2">
                            <label>Job Start Date</label>
                            <span id="JobStartDate">{{ jobDetails.jobStartDate | dateFormat("MM/DD/YYYY") }}</span>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="py-2">
                            <label>Work Order ID</label>
                            <span id="WorkOrder">{{ jobDetails.workOrderDisplayIds }}</span>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="py-2">
                            <label>Job End Date</label>
                            <span id="JobEndDate">{{ jobDetails.jobEndDate | dateFormat("MM/DD/YYYY") }}</span>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="py-2">
                            <label>Service Type</label>
                            <span id="ServiceType">{{ jobDetails.serviceType }}</span>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" class="py-2">
                            <label>Work Order Class</label>
                            <span id="WorkOrderClass">{{ jobDetails.workOrderClasses }}</span>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" class="py-2">
                            <label>Industry</label>
                            <span id="Industry">{{ jobDetails.workOrderIndustries }}</span>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" class="py-2">
                            <label>Client Name</label>
                            <span id="ClientName">{{ jobDetails.clientName }}</span>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="py-2">
                            <label>Inspection Date</label>
                            <span id="InspectionDate">{{ jobDetails.inspectionDate | dateFormat("MM/DD/YYYY") }}</span>
                            </v-col>
                            <v-col cols="12" sm="8" md="8" style="overflow: hidden;" class="py-2">
                            <label>Phone Number</label>
                            <span id="PhoneNumber">{{ jobDetails.factoryContactPhoneNumber }}</span>
                            <span id="PhoneNumberButton" @click.stop="call(jobDetails.factoryContactPhoneNumber)" style="float: right;margin-right:30px;">
                                <v-icon color="blue">phone</v-icon>
                            </span>
                            </v-col>
                            <v-col cols="12" sm="8" md="8" class="py-2">
                            <label>Email</label>
                            <span id="Email">{{ jobDetails.factoryContactEmail }}</span>

                            <span id="EmailButton" @click.stop="email(jobDetails.factoryContactEmail)" style="float: right;margin-right:30px;">
                                <v-icon color="blue">email</v-icon>
                            </span>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" class="py-2">
                            <label>Company Contact</label>
                            <span id="CompanyContact">{{ jobDetails.factoryContactName }}</span>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" class="py-2">
                            <label>Factory Name</label>
                            <span id="FactoryName">{{ jobDetails.factoryName }}</span>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" class="py-2">
                            <label>Product Description</label>
                            <span id="ProductDescription">{{ jobDetails.productDescription }}</span>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" class="py-2">
                            <label>Factory Address</label>
                            <span id="FactoryAddress">{{ jobDetails.factoryAddress }}</span>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-show="jobDetails.resources.length > 1" class="py-2">
                            <label>Lead Inspector</label>
                            <span id="LeadInspector">{{ jobDetails.leadInspector }}</span>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" class="py-2">
                            <label>Assigned Inspectors</label>
                            <span id="AssignedInspectors">{{ jobDetails.inspectors }}</span>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import {mapState} from 'vuex';
import apiDetails from "../../shared/request/apiDetails";

    let state = {
        appHeaderState: {
            show: true,
            showMenu: true,
            showBack: true,
            showLogo: false,
            showTitle: true,
            title: 'expenseDetail',
            showSearch_job: false,
            showSearch_work: false,
            showCalendar: true
        }
    };

    function setState(store) {
        store.dispatch('navigation/setAppHeader', state.appHeaderState);
    }
    export default {
        name: 'expenseDetail',
        data () {
            return {
                snackbarError: false,
                messageError:'',
                timeoutError: 3000,
                colorError:'red darken-2',
                form: {
                    currencyId: '',
                    air: '',
                    transportation: '',
                    hotel: '',
                    meals: '',
                    misc: ''
                },
                currencyList: [],
                snackbar: false,
                expense:[],
                visible: true,
                workOrderIds: '',
                showWorkOrders: false,
                jobDetails: null,
                showJobDetails: false,
                isCurrentlyLoading: false
            };
        },
        mounted () {
            this.$emit('msgExpenseCount', '20')
            // set head title content
            state.appHeaderState.title = 'Expense：Job ID ' + this.$route.params.expense.jobDisplayId;
            setState(this.$store);

            this.workOrderIds = this.$route.params.workOrderIds;
          
 // get currency list
            this.utilityFn.getServerData('post', this.apiList.currencyList, {}, res => {
                this.currencyList = res.data;
            },{})
            this.expense=this.$route.params.expense
            this.form.currencyId=this.expense.currency,
            this.form.air=this.expense.air,
            this.form.hotel=this.expense.hotel,
            this.form.meals=this.expense.meals,
            this.form.transportation=this.expense.transportation,
            this.form.misc=this.expense.misc
          
          
            if(this.form.air==0 || this.form.air==null)
                this.form.air=''
            if(this.form.transportation==0 || this.form.transportation==null)
                this.form.transportation=''
            if(this.form.hotel==0 || this.form.hotel==null)
                this.form.hotel=''
            if(this.form.meals==0 || this.form.meals==null)
                this.form.meals=''
            if(this.form.misc==0 || this.form.misc==null)
                this.form.misc=''
         },
        computed:{
            ...mapState("inspection", {
                store_jobList: (state) => state.jobList,
            }),
            totalExpense:function(){
                let val = Number(this.form.air) + Number(this.form.transportation) +
                          Number(this.form.hotel) + Number(this.form.meals) + Number(this.form.misc)
                return val
            },
        },
        methods: {
            onValuesChange(result1) {
                 this.form.currencyId =  result1;
            },
            submit () {
                if (this.form.air != '' || this.form.transportation != '' ||
                this.form.hotel != '' || this.form.meals != '' || this.form.misc != '') {
                        if(this.form.air=='' || this.form.air==null)
                            this.form.air=0
                        if(this.form.transportation=='' || this.form.transportation==null)
                            this.form.transportation=0
                        if(this.form.hotel=='' || this.form.hotel==null)
                            this.form.hotel=0
                        if(this.form.meals=='' || this.form.meals==null)
                            this.form.meals=0
                        if(this.form.misc=='' || this.form.misc==null)
                            this.form.misc=0
                         this.snackbar = true;
                        this.utilityFn.getServerData('post', this.apiList.expenseUpload 
                        + this.expense.expenseId, this.form, res => {
                         this.snackbar = false;
                        this.$router.go(-1)
                },{})
                    } else {
                         this.messageError = 'Either Air, Transportation, Hotel, Meals and Misc fields must not be blank.'
                        this.snackbarError = true;
                    }
               
            },
            returnNumber (obj, name) {
                if (obj[name] != '') {
                    obj[name] = obj[name].replace(/[^\d.]/g,"");  
                    obj[name] = obj[name].replace(/\.{2,}/g,"."); 
                    obj[name] = obj[name].replace(".","$#$").replace(/\./g,"").replace("$#$",".");
                    //obj[name] = obj[name].replace(/^(\-)*(\d+)\.(\d\d\d\d\d).*$/,'$1$2.$3');
                    //eslint-disable-next-line
                    obj[name] = obj[name].replace(/^(\-)*(\d+)\.(\d\d\d\d\d).*$/,'$1$2.$3');
                }
            },
            async getJobDetails() {
                this.showJobDetails = true;
                if (this.jobDetails || this.isCurrentlyLoading) return 
                
                this.isCurrentlyLoading = true;
                const result = await this.utilityFn.getServerDataAsync("get", `${this.apiList.JobList}?jobId=${this.$route.params.expense.jobId}`);
                const todayTime = new Date(new Date().toLocaleDateString()).getTime();
                const data = result.data.data;
                if (data.length > 0) {
                    this.jobDetails = data[0];
                    const jobEndDate = new Date(new Date(this.jobDetails.jobEndDate).toLocaleDateString()).getTime();
                    const resources = this.jobDetails.resources.map((res) => ({ ...res, day: new Date(new Date(res.day).toLocaleDateString())}));
                    for (let j = 0; j < resources.length; j++) {
                        resources[j].schedules.forEach((schedule) => {
                        let inspectionDate = new Date(new Date(schedule).toLocaleDateString()).getTime();
                            if (inspectionDate == todayTime) {
                                this.jobDetails.inspectionDate = new Date(inspectionDate);
                            } else if (inspectionDate > todayTime) {
                                this.jobDetails.inspectionDate = new Date(inspectionDate);
                            } else if (jobEndDate < todayTime) {
                                this.jobDetails.inspectionDate = new Date(inspectionDate);
                            }
                        });
                    }

                    this.jobDetails.workOrderDisplayIds = this.jobDetails.workOrders.map(y => y.workOrderDisplayId).join(', ');
                    this.jobDetails.workOrderClasses = [...new Set(this.jobDetails.workOrders.map(y => y.workOrderClass))].join(', ');
                    this.jobDetails.workOrderIndustries = [...new Set(this.jobDetails.workOrders.map(y => y.workOrderIndustry))].join(', ');
                    this.jobDetails.leadInspector = resources.length > 1 ? resources.find(y => y.isLeadInspector)?.fullName : '';
                    this.jobDetails.inspectors = resources.length > 1 ? resources.filter(y => !y.isLeadInspector).map(y => y.fullName)?.join(', ') : resources.map(y => y.fullName)?.join(', ');
                } else {
                    this.jobDetails = null;
                }
                this.isCurrentlyLoading = false;
            },
            call(number) {
                window.location.href = "tel:" + number;
            },
            email(address) {
                window.location.href = "mailto:" + address;
            },
        }
    };
</script>

<style lang="stylus" scoped>
    >>>.picker-backdrop
        position: static;
    >>>.picker-wrapper
        position: static;

        
    .state_tag_In-progress
        margin-bottom 10px
        padding 0
        line-height 30px

    .state_tag_In-progress span
        background #8BC34A
        color #fff
        text-align center
        display:inline-block
        width 100px

    .state_tag_New
        margin-bottom 10px
        padding 0

    .state_tag_New span
        background #FF9800
        color #fff
        text-align center
        display:inline-block
        width 80px

    .v-tabs-items
        background transparent !important

    label
        display inline-block
        width 140px
        color: #666666
        font-size: 13px
    span
        color #0C0C0C
        font-size: 14px
</style>
