import axios from "axios";
import apiDetails from "../request/apiDetails";
import apiList from "../request/apiList";

let workOrderResult = {};

workOrderResult.getWorkOrderDetails = async function(workOrderId) {
  let data;
  await axios
    .get(apiList.baseUrl + "/api/AIMS/GetWorkOrdersFromJob/" + workOrderId, {
      headers: apiDetails.header.headersDetail,
    })
    .then((response) => {
      data = response.data;
    })
    .catch((response) => {
      console.log(response);
    });
  return data;
};

workOrderResult.getCompanyWorkOrderResultTypeAliases = async function(companyId) {
  let data;
  await axios.get(this.apiList.GetCompanyWOResultTypeAliases + "?companyId=" + companyId, {
		headers: apiDetails.header.headersDetail,
  }).then((res) => {
    data = res.data;
  }).catch((error) => {
		console.log('error', error);
	});
  return data;
};

workOrderResult.WorkOrderSampling = async function(data) {
  await axios
    .patch(
      apiList.baseUrl + "/api/AIMS/PatchWorkOrderResults/" + data[0].workOrderId + "/9",
      [
        { op: "replace", path: "/workOrderId", value: data[0].workOrderId },
        { op: "replace", path: "/totalExportCrtns", value: data[0].totalExportCrtns },
        { op: "replace", path: "/pkgAvailable", value: data[0].pkgAvailable },
        { op: "replace", path: "/crtns", value: data[0].crtns },
        { op: "replace", path: "/pkgInspected", value: data[0].pkgInspected },
        { op: "replace", path: "/technicalFileAvailable", value: data[0].technicalFileAvailable },
        { op: "replace", path: "/technicalFileProvidedBy", value: data[0].technicalFileProvidedBy },
        { op: "replace", path: "/refSample", value: data[0].refSample },
        { op: "replace", path: "/refSampleProvidedBy", value: data[0].refSampleProvidedBy },
        { op: "replace", path: "/samplingMethodology", value: data[0].samplingMethodology },
        { op: "replace", path: "/samplingPlan", value: data[0].samplingPlan },
        { op: "replace", path: "/sampleSize", value: data[0].sampleSize },
      ],
      {
        headers: apiDetails.header.headersDetail,
      }
    )
    .then((response) => {
      console.log("Testing", response);
    })
    .catch((error) => {
      console.log(error);
    });
};

workOrderResult.UpsertWorkOrderDataCaptureFields = async function(data) {
  // console.log('UpsertWorkOrderDataCaptureFields => ',data)
  await axios
    .post(
      apiList.baseUrl + "/api/AIMS/UpsertWorkOrderDataCaptureFields/" + data.workOrderId,

      {
        Destination: data.destination,
        ShipmentQty: data.shipmentQty,
        SampleSize: data.sampleSize,
      },
      {
        headers: apiDetails.header.headersDetail,
      }
    )
    .then((response) => {
      workOrderResult.data = response.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
  return workOrderResult.data;
};

workOrderResult.patchWorkOrderResults = async function(data) {
  await axios
    .patch(apiList.baseUrl + "/api/AIMS/PatchWorkOrderResults/" + data.workOrderId, [{ op: "replace", path: "/sampleSize", value: data.sampleSize }], {
      headers: apiDetails.header.headersDetail,
    })
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default workOrderResult;
