<template>
  <div id="reportView">
    <v-dialog v-model="showDialog" persistent max-width="900">
      <v-card id="report-view" >
        <v-card-title>
          <span class="headline">Preview</span>
        </v-card-title>
        <v-card-text v-if="showDialog">
          <div v-if="showPreviewContainer" id="preview-container"></div>
          <div v-if="!showPreviewContainer && !previewUnavailable" class="d-flex justify-center align-center" style="height: 100%;">
            <v-progress-circular :size="50" indeterminate color="primary"></v-progress-circular>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn id="btnClose" color="blue darken-1" text @click="closePreview()">Close</v-btn>
        </v-card-actions>
      </v-card>

      <!-- <v-snackbar id="test" v-model="previewUnavailable" top :timeout="-1" absolute bottom color="success" outlined center>
        <span style="padding: 15px; width: 100%; text-align: center;">Report Unavailable</span>
      </v-snackbar> -->
    </v-dialog>
  </div>
</template>

<script>
import * as pbi from "powerbi-client";

export default {
  props: {
    showDialog: {
      type: Boolean,
    },
    reportData: {
      type: Object,
    },
  },

  data() {
    return {
      // power bi preview
      showReport: false,
      showPreviewContainer: false,
      genericTemplates: [],
      previewUnavailable: false,
      reworkAbortReportConfig: null,
    };
  },
 mounted() {
       //this.getEmbeddedReport();
  },
  methods: {
    getEmbeddedReport() {
       let reportUrl = this.reportData.url
        if (reportUrl) {
          const reportData = {
            reportUrl: this.reportData.url,
            clientName: this.reportData.clientName,
            workOrderId: this.reportData.workOrderDisplayId.toString(),
            reportVersion: "DRAFT"
          };
              this.utilityFn.getServerData("post", this.apiList.getEmbeddedPBIReport, reportData, async (res) => {
              if (!this.showDialog) {
                return;
              }
              if (!res || !res.data) {
                this.previewUnavailable = true;
                return;
              }
              this.showPreviewContainer = true;
              const reportView = () => {
                const result = res.data;
                const embedUrl = result.embedUrl + "&rp:ReportVersion=DRAFT";
                const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
                const config = {
                  type: "report",
                  tokenType: pbi.models.TokenType.Aad,
                  accessToken: result.accessToken,
                  embedUrl: embedUrl,
                  id: result.id,
                  permissions: pbi.models.Permissions.All,
                  viewMode: pbi.models.ViewMode.Edit,
                  settings: {
                    filterPaneEnabled: false, // this will show/hide filter options
                    navContentPaneEnabled: false,
                  },
                };
                const pbiContainerElement = document.getElementById("preview-container");
                const pbiReport = powerbi.embed(pbiContainerElement, config);
                pbiReport.off("loaded");
                pbiReport.on("loaded", (event) => {
                  console.log("loaded", event);
                });
              };
              setTimeout(reportView);
            });
        } else {
          this.previewUnavailable = true;
          return;
        }
      
    },

    closePreview() {
      this.previewUnavailable = false;
      this.showPreviewContainer = false;
      this.$emit("update:showDialog", false);
    },

  },

  watch: {
    showDialog(val) {
      console.log("Preview Component showDialog", val);
    },
    reportData(val) {
      this.getEmbeddedReport();
      console.log("Preview Component reportData", val);
    },
  },
};
</script>

<style lang="stylus" scoped>
#preview-container
    height 100%

#report-view
    display flex
    flex-direction column
    height 98vh

#report-view .v-card__text
    flex-grow 1
    height 85%

>>> .v-dialog
    max-height 100%
</style>
