<template>
  <div>
    <div class="workOrderInspectionBarcodeId">
      <div style="position: relative;">
        
        <v-col cols="12 " sm="12" md="12" style="text-align:right;">
          <v-btn id="barcode_submit" v-if="showButton" :disabled="isSectionDisabled" class="submit-btn" @click="submit_item(true)" small depressed color="#1867c0">Submit</v-btn>
          <v-btn id="barcode_uploadphoto" :disabled="isSectionDisabled" class="submit-btn" small depressed color="#1867c0" @click="go_upload_photo()">
            <v-icon>camera_alt</v-icon>
          </v-btn>
        </v-col>
        <v-tabs v-model="tab" background-color="rgb(0, 81, 138)" dark>
          <v-tab href="#tab-1">
            Carton
          </v-tab>
          <v-tab href="#tab-2">
            Product
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" touchless >
          <v-tab-item class="tab_item" :value="'tab-1'" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
            <pulse-validator v-if="errorsCartonBarcodeTab.length > 0" :errors="errorsCartonBarcodeTab"></pulse-validator>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="workOrderBarcodeVerificationObj.cartonResultId"
                  :items="workOrderResultList"
                  item-value="id"
                  item-text="name"
                  :label="resultLabel"
                  @change="changeBarcodeVerificationResult('cartonResultCode')"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <div class="code-container">
                  <div v-if="(cartonResultValue == 'Pending' || cartonResultValue == 'Fail') && isGenericCodes" class="helper-container">
                    <GenericCodeHelper></GenericCodeHelper>
                  </div>
                  <v-form v-model="isCartonResultValid">
                    <v-select v-if="cartonResultValue == 'Pending' || cartonResultValue == 'Fail'"
                        v-model="workOrderBarcodeVerificationObj.cartonResultCode" 
                        :item-text="(item) => item.description ? `${item.code} - ${item.description}` : `${item.code}`"
                        item-value="code"
                        :items="cartonFailCodeOptions"
                        :rules="[(v) => !!v || 'Required']"
                        :label="cartonResultValue == 'Pending' ? 'Pending Code' : 'Fail Code'">
                    </v-select>
                  </v-form>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Remarks"
                  v-model="workOrderBarcodeVerificationObj.cartonRemarks"
                  :counter="200"
                  :rules="[(v) => !v || v.length <= 200 || 'Maximum 200 characters only']"
                  @keyup="charCount(workOrderBarcodeVerificationObj)"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  id="barcode_carton_inspectionLevel"
                  hide-details
                  v-model="workOrderBarcodeVerificationObj.cartonInspectionLevel"
                  :items="inspectionLevel1Options"
                  item-value="name"
                  item-text="name"
                  label="Inspection Level"
                  @change="onChangeInspectionLevel($event, 'carton')"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  id="barcode_carton_samplingMethodology"
                  v-model="workOrderBarcodeVerificationObj.cartonSamplingMethodology"
                  :items="SamplingMethodology"
                  label="Sampling Methodology"
                  item-text="name"
                  item-value="name"
                  @change="onChangeSamplingMethodology($event, 'carton')"
                  readonly
                  >></v-select
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  id="barcode_carton_sampleSize"
                  hide-details
                  v-model="workOrderBarcodeVerificationObj.cartonSampleSize"
                  label="Carton Sample Size"
                  type="number"
                  @change="onChange"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col ccols="12" md="4">
                  <v-expansion-panels class="mb-6">
                    <v-expansion-panel>
                      <v-expansion-panel-header disable-icon-rotate>
                        <v-text-field hide-details label="<SKU> - <PO>" @click.stop v-model="batchUpdateSearchListCarton"></v-text-field>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-data-table v-bind="datatableRowsPerPage" height="500px" :items-per-page="-1" v-model="selectedClientProductIdCarton" :sort-by="['index', 'po', 'skuDisplay','orderNo']" :headers="skuHeader" :items="filterWorkOrderProductsCarton" item-key="id" show-select>
                          <template v-slot:item.sku_po="{ item }">
                            <span>{{ item.skuDisplay + " - " + item.po }}</span>
                          </template>
                        </v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
                  <v-col cols="6" md="2">
                    <v-card-actions>
                      <v-btn class="submit-btn" color="#1867c0"  @click="applyBatchUppdate('Carton')" >Apply</v-btn>
                    </v-card-actions>
                  </v-col> 
           </v-row>



              <v-data-table  
                :sort-by="['index', 'po', 'skuDisplay','orderNo']"
                v-bind:headers="cartonHeader"
                :items="cartonBarcodeList"
                :hide-default-footer="false" 
                class="elevation-1"
                style="margin-top: 30px;"
                id="carton-barcode-verification-data-table"
                :loading = loadingBarcodeVerificationList 
                loading-text="Loading... Please wait"
                @update:options="resetCartonIndexMarkers"
                :items-per-page="-1">

                <template v-slot:progress>
                    <v-progress-linear indeterminate></v-progress-linear>
                </template>

                <template v-slot:body="{ items, pagination }">
                    <tbody>
                        <tr
                            v-for="(item,index) in items"
                            :key="item.name"
                        >
                            <td>
                              <span style="display: none;">{{ item.indexMarkerCarton = (index + pagination.pageStart)  }}</span>
                              {{ item.index }}
                            </td>
                            <td>
                              {{ item.clientProductId }}
                            </td>
                            <td>{{ item.skuDisplay }}</td>
                            <td>{{ item.po }}</td>
                            <td>{{ scannableDescription(item.scannable) }}</td>
                            <td>
                                <v-text-field hide-details  :id="'barcode_carton_codeOnLabel' + item.id" v-model="item.codeOnLabel" @change="changecalculateRowBarcodeResult(item)" outlined dense></v-text-field>
                           
                            </td>
                            <td>
                              <v-icon  @click="openBarcodeScanner('cartonCodeonLabel',item,index)">mdi-barcode</v-icon>
                            </td>
                            <td>
                                <v-text-field hide-details  :id="'barcode_carton_codeScanned' + item.id" v-model="item.codeScanned" @change="changecalculateRowBarcodeResult(item)" outlined dense></v-text-field>
                                
                            </td>
                            <td>
                              <v-icon  @click="openBarcodeScanner('cartonCodeScanned',item,index)">mdi-barcode</v-icon>
                            </td>
                            <td>
                                <v-text-field hide-details :id="'barcode_carton_codeSpecified' + item.id"  v-model="item.codeSpecified"  v-bind:readonly="item.isFromCanyon" @change="changecalculateRowBarcodeResult(item)" outlined dense></v-text-field>
                            </td>
                            <td>                            
                                <v-select 
                                  hide-details
                                  :id="'barcode_carton_barcodeTypes'+ item.id" 
                                  v-bind:items="barcodeTypeOptions" 
                                  v-model="item.barcodeTypeId" 
                                  item-text="description" 
                                  item-value="id" 
                                  outlined
                                  dense>
                                </v-select>
                            </td>
                            <td>
                              <!-- <v-text-field hide-details :id="'barcode_carton_barcodeLabel' + item.id"  v-model="item.barcodeLabel"  outlined dense></v-text-field>   -->
                               <v-select 
                                  hide-details
                                  :id="'barcode_carton_barcodeLabel'+ item.id" 
                                  v-bind:items="barcodeLabelOptions" 
                                  v-model="item.barcodeLabel" 
                                  item-text="text" 
                                  item-value="text" 
                                  outlined
                                  dense>
                                </v-select>
                                                    
                            </td>
                            <td>   
                               <v-select hide-details :id="'barcode_carton_resultDescription'+ item.id"  
                                v-bind:items="resultOptions"  
                                v-model="item.result" 
                                item-text="text" 
                                item-value="id"  
                                outlined 
                                dense
                                @change="calculateBarcodeVerificationResult('tab-1')"
                               > </v-select>
                            </td>
                            <td>
                                <div class="d-flex align-center">                                  
                                  <v-btn v-if="item.orderNo == 1" icon dark :id="'barcode_carton_addNewEntry_' + item.id">
                                    <v-icon color="black"  @click.stop="addChild(item,'carton')">add</v-icon>
                                  </v-btn>
                               
                                  <v-btn  v-else icon dark :id="'barcode_carton_deleteEntry_' + item.id">
                                    <v-icon color="black" @click.stop="deleteChild(item,index)">delete</v-icon>
                                  </v-btn>

                                  <v-btn v-if="item.indexMarkerCarton >= 1" icon title="Copy From Above"  @click="copyAboveRowCarton(item.indexMarkerCarton)">
                                    <v-icon>mdi-content-copy</v-icon>
                                  </v-btn>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>



           
          </v-tab-item>

          <v-tab-item class="tab_item" :value="'tab-2'" :style="isSectionDisabled ? 'cursor: no-drop; pointer-events: none;': ''">
             <pulse-validator v-if="errorsProductBarcodeTab.length > 0" :errors="errorsProductBarcodeTab"></pulse-validator>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="workOrderBarcodeVerificationObj.productResultId"
                  :items="workOrderResultList"
                  item-value="id"
                  item-text="name"
                  :label="resultLabel"
                  @change="changeBarcodeVerificationResult('productResultCode')"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <div class="code-container">
                  <div v-if="(productResultValue == 'Pending' || productResultValue == 'Fail') && isGenericCodes" class="helper-container">
                    <GenericCodeHelper></GenericCodeHelper>
                  </div>
                  <v-form v-model="isProductResultValid">
                    <v-select v-if="productResultValue == 'Pending' || productResultValue == 'Fail'"
                        v-model="workOrderBarcodeVerificationObj.productResultCode" 
                        :item-text="(item) => item.description ? `${item.code} - ${item.description}` : `${item.code}`"
                        item-value="code"
                        :items="productFailCodeOptions"
                        :rules="[(v) => !!v || 'Required']"
                        :label="productResultValue == 'Pending' ? 'Pending Code' : 'Fail Code'">
                    </v-select>
                  </v-form>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Remarks"
                  v-model="workOrderBarcodeVerificationObj.productRemarks"
                  :counter="200"
                  :rules="[(v) => !v || v.length <= 200 || 'Maximum 200 characters only']"
                  @keyup="charCount(workOrderBarcodeVerificationObj)"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  id="barcode_product_inspectionLevel"
                  hide-details
                  v-model="workOrderBarcodeVerificationObj.productInspectionLevel"
                  :items="inspectionLevelOptions"
                  item-value="name"
                  item-text="name"
                  label="Inspection Level"
                  @change="onChangeInspectionLevel($event, 'product')"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  id="barcode_product_samplingMethodology"
                  v-model="workOrderBarcodeVerificationObj.productSamplingMethodology"
                  :items="SamplingMethodology"
                  label="Sampling Methodology"
                  item-text="name"
                  item-value="name"
                  @change="onChangeSamplingMethodology($event, 'product')"
                  readonly
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field id="barcode_product_sampleSize" hide-details v-model="workOrderBarcodeVerificationObj.productSampleSize" label="Sample Size" type="number" @change="onChange"></v-text-field>
              </v-col>            
            </v-row>

            <v-row>
              <v-col ccols="12" md="4">
                  <v-expansion-panels class="mb-6">
                    <v-expansion-panel>
                      <v-expansion-panel-header disable-icon-rotate>
                        <v-text-field hide-details label="<SKU> - <PO>" @click.stop v-model="batchUpdateSearchListProduct"></v-text-field>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-data-table v-bind="datatableRowsPerPage" height="500px" :items-per-page="-1" v-model="selectedClientProductIdProduct" :sort-by="['index', 'po', 'skuDisplay','orderNo']" :headers="skuHeader" :items="filterWorkOrderProductsProduct" item-key="id" show-select>
                          <template v-slot:item.sku_po="{ item }">
                            <span>{{ item.skuDisplay + " - " + item.po }}</span>
                          </template>
                        </v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              <v-col cols="6" md="2">
                    <v-card-actions>
                      <v-btn class="submit-btn" color="#1867c0"  @click="applyBatchUppdate('Product')" >Apply</v-btn>
                    </v-card-actions>
                  </v-col> 
           </v-row>

            <v-data-table  
                :sort-by="['index', 'po', 'skuDisplay','orderNo']"
                v-bind:headers="productHeader"
                :items="barcodeList"
                :hide-default-footer="false" 
                class="elevation-1"
                style="margin-top: 30px;"
                id="product-barcode-verification-data-table"
                :loading = loadingBarcodeVerificationList 
                loading-text="Loading... Please wait"
                @update:options="resetProductIndexMarkers"
                :items-per-page="-1">

                <template v-slot:progress>
                    <v-progress-linear indeterminate></v-progress-linear>
                </template>

                <template v-slot:body="{ items, pagination }">
                    <tbody>
                        <tr
                            v-for="(item,index) in items"
                            :key="item.name"
                        >
                            <td>
                              <span style="display: none;">{{ item.indexMarkerProduct = (index + pagination.pageStart)  }}</span>
                              {{ item. index}}
                            </td>
                            <td>
                              {{ item.clientProductId }}
                            </td>
                            <td>{{ item.skuDisplay }}</td>
                            <td>{{ item.po }}</td>
                            <td>{{ scannableDescription(item.scannable) }}</td>
                            <td>
                                <v-text-field hide-details  :id="'barcode_product_codeOnLabel' + item.id" v-model="item.codeOnLabel" @change="changecalculateRowBarcodeResult(item)" outlined dense></v-text-field>
                           
                            </td>
                            <td>
                              <v-icon  @click="openBarcodeScanner('productCodeonLabel',item,index)">mdi-barcode</v-icon>
                            </td>
                            <td>
                                <v-text-field hide-details  :id="'barcode_product_codeScanned' + item.id" v-model="item.codeScanned" @change="changecalculateRowBarcodeResult(item)" outlined dense></v-text-field>
                                
                            </td>
                            <td>
                              <v-icon  @click="openBarcodeScanner('productCodeScanned',item,index)">mdi-barcode</v-icon>
                            </td>
                            <td>
                                <v-text-field hide-details :id="'barcode_product_codeSpecified' + item.id"  v-model="item.codeSpecified"  v-bind:readonly="item.isFromCanyon" @change="changecalculateRowBarcodeResult(item)" outlined dense></v-text-field>
                            </td>
                            <td>                            
                                <v-select 
                                  hide-details
                                  :id="'barcode_product_barcodeTypes'+ item.id" 
                                  v-bind:items="barcodeTypeOptions" 
                                  v-model="item.barcodeTypeId" 
                                  item-text="description" 
                                  item-value="id" 
                                  outlined
                                  dense>
                                </v-select>
                            </td>
                            <td>
                              <!-- <v-text-field hide-details :id="'barcode_product_barcodeLabel' + item.id"  v-model="item.barcodeLabel"  outlined dense></v-text-field>     -->
                              <v-select 
                                  hide-details
                                  :id="'barcode_product_barcodeLabel'+ item.id" 
                                  v-bind:items="barcodeLabelOptions" 
                                  v-model="item.barcodeLabel" 
                                  item-text="text" 
                                  item-value="text" 
                                  outlined
                                  dense>
                                </v-select>                      
                            </td>
                            <td>   
                               <v-select hide-details :id="'barcode_product_resultDescription'+ item.id"  v-bind:items="resultOptions"  v-model="item.result" item-text="text" item-value="id"  outlined dense
                                 @change="calculateBarcodeVerificationResult('tab-2')"> </v-select>
                            </td>
                            <td>
                              <div class="d-flex align-center">
                                <v-btn v-if="item.orderNo == 1" icon dark :id="'barcode_product_addNewEntry_' + item.id">
                                  <v-icon color="black"  @click.stop="addChild(item,'product')">add</v-icon>
                                </v-btn>
                          
                                <v-btn  v-else icon dark :id="'barcode_product_deleteEntry_' + item.id">
                                  <v-icon color="black" @click.stop="deleteChild(item)">delete</v-icon>
                                </v-btn>          

                                <v-btn v-if="item.indexMarkerProduct >= 1" icon title="Copy From Above"  @click="copyAboveRowProduct(item.indexMarkerProduct)">
                                  <v-icon>mdi-content-copy</v-icon>
                                </v-btn>
                              </div>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>

          </v-tab-item>
          <div v-if="showFloatingButton">
            <FloatingButton></FloatingButton>
          </div>
        </v-tabs-items>
      </div>
    </div>

    <v-snackbar id="barcode_snackbar" v-model="snackbar" :timeout="timeout" :color="color">
      {{ message }}
      <v-btn id="barcode_snackbar_close" text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog id="barcode_delete_dialog" v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-toolbar flat color="pink" dark>
          <v-card-title>
            <span class="headline">Delete Confirmation</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field id="barcode_deletedItem_sku" readonly v-model="deletedItem.skuDisplay" label="SKU#"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field id="barcode_deletedItem_codeSpecified" readonly v-model="deletedItem.codeSpecified" label="Code Specified"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field id="barcode_deletedItem_codeOnLabel" readonly v-model="deletedItem.codeOnLabel" label="Barcode Printed on Label"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field id="barcode_deletedItem_barcodeTypes" readonly v-model="deletedItem.barcodeTypeItems" label="Barcode Type"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field id="barcode_deletedItem_barcodeLabel" readonly v-model="deletedItem.barcodeLabel" label="Barcode Label"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field id="barcode_deletedItem_barcodeResult" readonly v-model="deletedItem.barcodeResult" label="Result"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn id="barcode_deletedItem_cancel" text @click="close">Cancel</v-btn>
          <v-btn id="barcode_deletedItem_delete" color="red" text @click="deleteBarcode">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <barcode-scanner id="barcode_barcodeScanner" v-if="barcodeDialog" :key="codeScannerKey" :parentData="barcodeData" @changeValue="barcodeScannerChangeValue($event)"> </barcode-scanner>
  </div>
</template>

<script>
let state = {
  appHeaderState: {
    show: true,
    title: "Barcode Verification",
    showMenu: true,
    showBack: true,
    showLogo: false,
    showTitle: true,
    showSearch: false,
    showCalendar: true,
  },
};

function setState(store) {
  store.dispatch("navigation/setAppHeader", state.appHeaderState);
}

import { mapActions, mapState } from "vuex";
import BarcodeScanner from "../../../components/workorder/BarCodeScanner";
import FloatingButton from "../../../components/common/FloatingButton";
import apiDetails from "../../../shared/request/apiDetails";
import auditLog from "../../../shared/request/auditLogs";
import * as _ from "lodash";
import PulseValidator from "@/components/common/PulseValidator";
import GenericCodeHelper from "../../../views/common/GenericCodeHelper.vue"

export default {
  name: "workOrderInspectionBarcodeId",
  data() {
    return {
       datatableRowsPerPage: {
        "footer-props": {
          "items-per-page-options": [10, 25, 50, 100, -1],
        },
      },
      loadingDf: false,
      skuHeader: [{ text: "ALL", align: "left", value: "sku_po" }],
      batchUpdateSearchListCarton: null,
       batchUpdateSearchListProduct: null,
      selectedClientProductIdCarton: [],
      selectedClientProductIdProduct: [],
      SamplingMethodology: [],
      inspectionLevelOptions: [],
      inspectionLevel1Options: [],
      showFloatingButton: false,
      samplingPlan: [],
      barcodeDialog: false,
      codeScannerKey: 0,
      barcodeData: {
        barcodeType: "code-128",
        type: "LiveStream",
        showDialog: true,
        screen: "",
      },
      scannedValue: "",
      isEdit: true,
      snackbar: false,
      color: "red darken-2",
      message: "",
      timeout: 3000,
      tab: null,
      deleteDialog: false,
      barcodeLabelOptions: [
        {
          text: "Master carton",
        },
        {
          text: "Inner carton",
        },
        {
          text: "Gift box",
        },
        {
          text: "Hangtag",
        },
        {
          text: "Product",
        },
        {
          text: "Label",
        },
        {
          text: "Polybag",
        },
      ],
      scannableOptions: [
        {
          id: 1,
          text: "Yes",
        },
        {
          id: 0,
          text: "No",
        },
      ],

      resultOptions: [
        {
          id: 1,
          text: "Pass",
        },
        {
          id: 0,
          text: "Fail",
        },
      ],

      inbox: {},
      // carton table data
      cartonDialog: false,
      cartonHeader: [
        {
          text: "#",
          align: "left",
          sortable: true,
          value: "index",
        },
        {
          text: "Client Product ID",
          align: "left",
          sortable: true,
          value: "clientProductId",
        },
        {
          text: "SKU#",
          align: "left",
          width: 90,
          value: "skuDisplay",
        },
        {
          text: "PO No.",
          align: "center",
          sortable: true,
          value: "po",
        },
        {
          text: "Scannable",
          align: "center",
          sortable: false,
          value: "scannable",
        },
        {
          text: "Barcode Printed on Label",
          align: "center",
          sortable: false,
          value: "codeOnLabel",
          width: 150,
        },
           {
          text: "",
          align: "center",
          sortable: false,
          width: 10,
          value: "codeOnLabelScanner"
        },
        {
          text: "Barcode Scanned",
          align: "center",
          sortable: false,
          value: "codeScanned",
          width: 150,
        },
        {
          text: "",
          align: "center",
          sortable: false,
          width: 10,
           value: "codeScannedScanner"
        },
        {
          text: "Code Specified",
          align: "center",
          sortable: false,
          value: "codeSpecified",
          width: 150,
        },
        {
          text: "Barcode Type",
          align: "center",
          sortable: false,
          value: "barcodeTypeId",
          width: 200,
        },
        {
          text: "Barcode Label",
          align: "center",
          sortable: false,
          value: "barcodeLabel",
          width: 200,
        },
        {
          text: "Result",
          align: "center",
          sortable: false,
          value: "result",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          width: 10,
          value: "menu",
        },
      ],

      cartonEditedItem: {
        id: null,
        workOrderProductID: null,
        scannable: 0,
        codeSpecified: null,
        codeOnLabel: null,
        codeScanned: null,
        barcodeTypeItems: [],
        barcodeLabel: null,
        barcodeResult: null,
        orderNo: 0,
        isFromCanyon: false,
      },

      deletedItem: {
        id: null,
        skuDisplay: null,
        workOrderProductID: null,
        codeSpecified: null,
        codeOnLabel: null,
        codeScanned: null,
        barcodeTypeItems: [],
        barcodeLabel: null,
        barcodeResult: null,
      },

      deletedBarcodeProductIds:[],
      // product table data
      productDialog: false,
      productHeader: [
        {
          text: "#",
          align: "left",
          sortable: true,
          value: "index",
        },
        {
          text: "Client Product ID",
          align: "left",
          sortable: true,
          value: "clientProductId",
        },
        {
          text: "SKU#",
          align: "left",
          width: 90,
          value: "skuDisplay",
        },
        {
          text: "PO No.",
          align: "center",
          sortable: true,
          value: "po",
        },
        {
          text: "Scannable",
          align: "center",
          sortable: false,
          value: "scannable",
        },
        {
          text: "Barcode Printed on Label",
          align: "center",
          sortable: false,
          value: "codeOnLabel",
          width: 200,
        },
           {
          text: "",
          align: "center",
          sortable: false,
          width: 10,
          value: "codeOnLabelScanner"
        },
        {
          text: "Barcode Scanned",
          align: "center",
          sortable: false,
          value: "codeScanned",
          width: 200,
        },
           {
          text: "",
          align: "center",
          sortable: false,
          width: 10,
           value: "codeScannedScanner"
        },
        {
          text: "Code Specified",
          align: "center",
          sortable: false,
          value: "codeSpecified",
          width: 200,
        },
        {
          text: "Barcode Type",
          align: "center",
          sortable: false,
          value: "barcodeTypeId",
          width: 200,
        },
        {
          text: "Barcode Label",
          align: "center",
          sortable: false,
          value: "barcodeLabel",
          width: 200,
        },
        {
          text: "Result",
          align: "center",
          sortable: false,
          value: "result",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          width: 10,
          value: "menu",
        },
      ],

      productQuantityVerificationList: [],

      productBarcodeTypeItems: [],

      productEditedItem: {
        id: null,
        workOrderProductID: null,
        scannable: 0,
        codeSpecified: null,
        codeOnLabel: null,
        codeScanned: null,
        barcodeTypeItems: [],
        barcodeLabel: null,
        barcodeResult: null,
        orderNo: 0,
        isFromCanyon: false,
      },

      cartonbarcodeVerificationObj: {
        totalQuantity: 0,
        workOrderId: null,
        cartonSampleSize: null,
        cartonBarcodeAllListResult: null,
        cartonInspectionLevel: null,
      },

      productbarcodeVerificationObj: {
        totalQuantity: 0,
        workOrderId: null,
        productSampleSize: null,
        productBarcodeAllListResult: null,
        productInspectionLevel: null,
      },

      //Workorder Objects

      workOrderBarcodeVerificationObj: {
        workOrderId: null,
        cartonSampleSize: null,
        cartonInspectionLevel: null,
        cartonResult: null,
        cartonRemarks: null,
        productSampleSize: null,
        productRemarks: null,
        productInspectionLevel: null,
        productResult: null,
        cartonSamplingMethodology: null,
        productSamplingMethodology: null,
        cartonResultId: null,
        productResultId: null,
        cartonPendingCodeId: null,
        productPendingCodeId: null,
        sectionId: 4,
      },

      barcodeTypeOptions: [],
      workOrderId: "",
      workOrder: {
        resources: [],
        workOrderInspectionSections: []
      },
      workOrderProducts: [],
      selectedWorkOrder: [],
      cartonBarcodeList: [],
      barcodeList: [],
      skuDisplayList: [],
      cloneBarcodeList:[],
      cloneCartonBarcodeList:[],

      workOrderProductBarcodeVerificationResultObj: {
        id: null,
        workOrderProductId: null,
        isCarton: null,
        result: null,
        scannable: null,
        codeOnLabel: null,
        codeScanned: null,
        codeSpecified: null,
        barcodeTypeId: null,
        barcodeLabel: null,
      },
      workOrderResultList: [],
      sectionId: 4,
      sectionData: {
        BarcodeVerification: {
          id: 4,
          name: "Barcode Verification",
          isAuthorized: false,
          isSectionSubmitted: false,
        },
      },
      maxcharacter: 200,
      loadingBarcodeVerificationList: false,
      currentIndex :null,

      // true if submit button is clicked
      sectionSubmitted: false,
      getWorkOrderFailCode: [],
      getWorkOrderPendingCode: [],
      workOrderFailCode: [],
      workOrderPendingCode: [],
      workOrderRatingOptions: [],
      defaultFailCodeOptions: ['UL0', 'UL1', 'UL2', 'UL3', 'UL4', 'UL5', 'UL6', 'UL7', 'UL8', 'UL9', 'UL10'],
      isFormDirty: false,
      isFormReadyCheck: false,
      cartonInspections: ["Square Root", "Fixed", "S3"],
      isGenericCodes: false,
      isCartonResultValid: true,
      isProductResultValid: true,
      walmartUsa: "WAL-MART USA"
    };
  },
  components: {
    "barcode-scanner": BarcodeScanner,
    FloatingButton,
    PulseValidator,
    GenericCodeHelper
  },
  beforeMount() {
    this.utilityFn.getServerData("get", this.apiList.GetBarcodeTypes, {}, (result) => {
      this.barcodeTypeOptions = result.data.data;
    });
  },
  async activated() {
     if (window.navigator.onLine !== true) {
        let response = await caches.match(this.apiList.workOrderDetailsBarCode + "/" + this.$route.params.id);
        if(!response) {
          this.$router.replace({
              path: "/error",
              name: "error",
          });
        }
    }
    
  },
  async mounted() {
    if (window.navigator.onLine !== true) {
        let response = await caches.match(this.apiList.workOrderDetailsBarCode + "/" + this.$route.params.id);
        if(!response) {
          this.$router.replace({
              path: "/error",
              name: "error",
          });
        }
    }
    this.utilityFn.getServerData("get", this.apiList.getAllProductSizeResultList, {}, (result) => {
      this.workOrderResultList = result.data.data;
    });
    this.utilityFn.getServerData("get", this.apiList.getAllWorkOrderPendingFailCode, {}, (res) => {
      this.workOrderPendingCode = res.data.data.filter((x) => x.type == "Pending");
      this.workOrderFailCode = res.data.data.filter((x) => x.type == "Fail");
      // this.workOrderFailAndPendingCode()
    });
    this.utilityFn.getServerData("get", this.apiList.getLookupAsync, {}, (res) => {
      let tempInspectionLevel = res.data.data.inspectionClass.filter((x) => x.inspectionClassFieldName.name === "Inspection Level 2");
      this.SamplingMethodology = res.data.data.inspectionClass.filter((x) => x.inspectionClassFieldName.name === "Sample Size Type");
      this.inspectionLevel1Options = tempInspectionLevel.filter((x) => this.cartonInspections.includes(x.name));
      this.inspectionLevelOptions = tempInspectionLevel.filter((x) => x.name != "Square Root");
    });

    setState(this.$store);

    this.samplingPlan = JSON.parse(localStorage.getItem("samplingPlanList"));
    this.showFloatingButton = this.samplingPlan == undefined ? false : this.samplingPlan.showFabButton == false ? false : true;
    this.sectionData = this._sectionData.BarcodeVerification ? this._sectionData : this.sectionData;

    this.populateWorkorderList();
    await this.loadWorkOrderRatingOptions();
  },
  methods: {
    isWalmart() {
      const clientName = this._workOrderDetails?.clientName || this._workOrderDetails?.companyName;
      return clientName?.toLowerCase() == "walmart global sourcing" || clientName?.toLowerCase() == "asda stores limited";
    },

    workOrderFailAndPendingCode() {
      this.getWorkOrderPendingCode.forEach(e => {
        if (e.details != null) {
          this.workOrderPendingCode.push(e.description +' - '+ e.details)
        }
        else {
          this.workOrderPendingCode.push(e.description)
        }
      });

      this.getWorkOrderFailCode.forEach(e => {
        if (e.details != null) {
          this.workOrderFailCode.push(e.description +' - '+ e.details)
        }
        else {
          this.workOrderFailCode.push(e.description)
        }
      });
    },

    applyBatchUppdate(tab){
      if(tab == 'Carton'){
        if(this.selectedClientProductIdCarton.length > 0){
            this.selectedClientProductIdCarton.forEach(wop =>{
              let cartonSelectedWOP = this.cartonBarcodeList.filter(x => x.workOrderProductId == wop.workOrderProductId);
            
              cartonSelectedWOP.forEach((item)=>{            
                   item.codeOnLabel = item.codeSpecified
                   item.codeScanned = item.codeSpecified
                   item.result =  !item.codeSpecified ? null :  1                               
              })
            })
            
        }
        this.calculateBarcodeVerificationResult('tab-1')
      }
      if(tab == 'Product'){
        if(this.selectedClientProductIdProduct.length > 0){
            this.selectedClientProductIdProduct.forEach(wop =>{
              let selectedWOP = this.barcodeList.filter(x => x.workOrderProductId == wop.workOrderProductId);
             
              selectedWOP.forEach((item)=>{  
                  item.codeOnLabel = item.codeSpecified
                  item.codeScanned = item.codeSpecified
                  item.result =  !item.codeSpecified ? null :  1    
              })
            })
            
        }
        this.calculateBarcodeVerificationResult('tab-2')
      }
    },

     getMaxValue(workOrderProductId, isCarton) {
      if (isCarton) {
        let filteredCartonBarcodeList = this.cartonBarcodeList.filter((x) => x.workOrderProductId == workOrderProductId);

        return Math.max.apply(
          Math,
          filteredCartonBarcodeList.map((x) => {
            return x.orderNo;
          })
        );
      } else {
        let filteredBarcodeList = this.barcodeList.filter((x) => x.workOrderProductId == workOrderProductId);

        return Math.max.apply(
          Math,
          filteredBarcodeList.map((x) => {
            return x.orderNo;
          })
        );
      }
    },
     
    addChild (item,tab) {

      let newItem = Object.assign({}, item);
      newItem.id = null;
      newItem.skuDisplay = item.id;
      newItem.workOrderProductId = item.workOrderProductId;
      newItem.clientProductId = item.clientProductId;
      newItem.skuDisplay = item.skuDisplay;
      newItem.isCarton = null;
      newItem.result = null;
      newItem.scannable = false;
      newItem.codeOnLabel = null;
      newItem.codeScanned = null;
      newItem.codeSpecified = null;
      newItem.barcodeTypeId = null
      newItem.barcodeLabel = null;

      if (tab == "carton") {
        let maxValue = this.getMaxValue(item.workOrderProductId, true)
        newItem.orderNo = maxValue  + 1;
        newItem.isCarton = true;
        newItem.index = item.index + maxValue;
        if (this.retailMarketCdf.toLowerCase() == this.walmartUsa.toLowerCase() && this.isWalmart()){
          newItem.barcodeTypeId = 5;
        }
        this.cartonBarcodeList.forEach((x) => x.index = x.index >= newItem.index? (x.index + 1) : x.index);
        this.cartonBarcodeList.push(newItem)
        this.calculateBarcodeVerificationResult('tab-1')       
      } 
      else {
        let maxValue = this.getMaxValue(item.workOrderProductId, false)
        newItem.orderNo = maxValue  + 1;
        newItem.isCarton = false;
        newItem.index = item.index + maxValue;
        if (this.retailMarketCdf.toLowerCase() == this.walmartUsa.toLowerCase() && this.isWalmart()){
          newItem.barcodeTypeId = 6;
        }

        this.barcodeList.forEach((x) => x.index = x.index >= newItem.index? (x.index + 1) : x.index);
        this.barcodeList.push(newItem)
        this.calculateBarcodeVerificationResult('tab-2')
      }


    },
     deleteBarcode() {
      if(this.deletedItem.isCarton){
        this.cartonBarcodeList.splice(this.deletedItem.deleteIndex, 1)
        
        this.cartonBarcodeList.forEach(x => {
          if(x.index >= this.deletedItem.index){
              x.index = x.index -1;
          }
          if(x.workOrderProductId == this.deletedItem.workOrderProductId && x.orderNo >= this.deletedItem.orderNo ){
            x.orderNo = x.orderNo -1
          }
        })      
        // this.cartonBarcodeList.forEach((x) => x.index = x.index >= this.deletedItem.index? (x.index -1 ) : x.index);
      
        this.calculateBarcodeVerificationResult('tab-1')
      }
      else{
    
        this.barcodeList.splice(this.deletedItem.deleteIndex,1)
        this.barcodeList.forEach(x => {
          if(x.index >= this.deletedItem.index){
              x.index = x.index -1;
          }
          if(x.workOrderProductId == this.deletedItem.workOrderProductId && x.orderNo >= this.deletedItem.orderNo ){
            x.orderNo = x.orderNo -1
          }
        })
       // this.barcodeList.forEach((x) => x.index = x.index >= this.deletedItem.index? (x.index + 1) : x.index);

        this.calculateBarcodeVerificationResult('tab-2')
      }

      if(this.deletedItem.id){
          this.deletedBarcodeProductIds.push(this.deletedItem.id)
      }      
      this.close();
  
    },

    deleteChild (item) {
      if(item.isCarton){
         this.deletedItem.deleteIndex = this.cartonBarcodeList.indexOf(item);
      }
      else{
         this.deletedItem.deleteIndex = this.barcodeList.indexOf(item);
      }

      this.deletedItem.index = item.index;
      (this.deletedItem.id = item.id), (this.deletedItem.skuDisplay = item.skuDisplay);
      (this.deletedItem.workOrderProductId = item.workOrderProductId),
      (this.deletedItem.codeSpecified = item.codeSpecified),
      (this.deletedItem.codeOnLabel = item.codeOnLabel),
      (this.deletedItem.codeScanned = item.codeScanned),
      (this.deletedItem.barcodeTypeItems = this.getTypeBarcode(item.barcodeTypeId)),
      (this.deletedItem.barcodeLabel = item.barcodeLabel),
      (this.deletedItem.barcodeResult = this.resultDescription(item.result)),
      (this.deletedItem.isCarton = item.isCarton),
      (this.deletedItem.orderNo = item.orderNo),
      (this.deleteDialog = true);
  
    },

    changecalculateRowBarcodeResult(item){
      let result = this.compareBarcodes(!item.codeSpecified ? null : item.codeSpecified.trim(), 
                                        !item.codeOnLabel ? null : item.codeOnLabel.trim(), 
                                        !item.codeScanned ? null : item.codeScanned.trim());
      item.result = result
      if(item.isCarton){
        this.calculateBarcodeVerificationResult('tab-1')
      }
      else{
        this.calculateBarcodeVerificationResult('tab-2')
      }
    },

    calculateBarcodeResult(tab) {
      let result = null;
      let cartonFail = this.cartonBarcodeList.filter((x) => x.result == 0);
      let cartonPass = this.cartonBarcodeList.filter((x) => x.result == 1);

      let productFail = this.barcodeList.filter((x) => x.result == 0);
      let productPass = this.barcodeList.filter((x) => x.result == 1);
      switch (tab) {
        case "carton":
          if (cartonFail.length > 0) {
            result = "Fail";
          } else {
            if (cartonPass.length > 0) {
              result = "Pass";
            }
          }
          break;
        case "product":
          if (productFail.length > 0) {
            result = "Fail";
          } else {
            if (productPass.length > 0) {
              result = "Pass";
            }
          }
          break;
      }
      return result;
    },
    checkBarcodeResult(barcodeResult) {
      let value = "";
      if (this.workOrderResultList.filter((x) => x.id == barcodeResult).length != 0) {
        value = this.workOrderResultList.filter((x) => x.id == barcodeResult)[0].name;
      }
      return value;
    },

      getBarcodeResultByName(resultName) {
      let value = "";
      if (this.workOrderResultList.filter((x) => x.name == resultName).length != 0) {
        value = this.workOrderResultList.filter((x) => x.name == resultName)[0].id;
      }
      return value;
    },

    changeBarcodeVerificationResult(key) {
      this.workOrderBarcodeVerificationObj[key] = null;
    },

    async SubmitBarcodeResult() {
      console.log("submitbarcode");

      let result = null;

      let cartonResult = this.workOrderBarcodeVerificationObj.cartonResultId ? this.workOrderResultList.find((x) => x.id == this.workOrderBarcodeVerificationObj.cartonResultId).name : null;
      let productResult = this.workOrderBarcodeVerificationObj.productResultId ? this.workOrderResultList.find((x) => x.id == this.workOrderBarcodeVerificationObj.productResultId).name : null;

      if (this.workOrderBarcodeVerificationObj.cartonResultId != null || this.workOrderBarcodeVerificationObj.productResultId != null) {
        if (cartonResult == "Fail" || productResult == "Fail") {
          result = "Non-Conformed";
        } else {
          if (cartonResult == "Pending" || productResult == "Pending") {
            result = "Pending";
          } else {
            if (cartonResult == "Pass" || productResult == "Pass") {
              result = "Conformed";
            } else {
              if (cartonResult == "N/A" || productResult == "N/A") {
                result = "N/A";
              } else {
                result = "";
              }
            }
          }
        }
      }
          this.inspectionSummarySectionResult.forEach(x => {
            if(x.sectionId==4){
              x.result = result;
            }
          })
          this.$store.dispatch("inspection/setInspectionSummaryResult", this.inspectionSummarySectionResult);
          


      if (!this.isSectionDisabled || this.sectionSubmitted) {
        const section = this.sectionDetail;
        section.result = result;

        const index = this._workOrderDetails.workOrderInspectionSections.findIndex(x => x.sectionId == this.sectionId)
        if (index !== -1) {
            this._workOrderDetails.workOrderInspectionSections[index] = section;
            this.$store.dispatch("inspection/setWorkOrderDetails", _.cloneDeep(this._workOrderDetails));
        }

        await this.utilityFn.getServerDataAsync("post", this.apiList.sectionUpdate, section);
      }


      // if (this.workOrder.resources.length === 1) {
      //   await this.submit_item();
      // }
    },
    barcodeVerificationResult() {
      let productsCount = this.workOrderProducts.length;
      let bvCarton = this.workOrderProducts.filter((x) => x.workOrderProductResults && x.workOrderProductResults.bvOverallRsltCrtn != null);
      let bvProduct = this.workOrderProducts.filter((x) => x.workOrderProductResults && x.workOrderProductResults.bvOverallRsltPrd != null);

      if (!bvCarton && !bvProduct) return null;

      return bvProduct.filter((x) => x.workOrderProductResults.bvOverallRsltCrtn === 1).length === productsCount && bvProduct.filter((x) => x.workOrderProductResults.bvOverallRsltPrd === 1).length === productsCount;
    },
    scannableDescription(scannableId) {
      if (scannableId == null || scannableId == undefined) {
        return "";
      }
      const item = this.scannableOptions.find((x) => x.id === +scannableId);
      return item ? item.text : "";
    },

    resultDescription(resultId) {
      if (resultId == null || resultId == undefined) {
        return "";
      }

      const item = this.resultOptions.find((x) => x.id === +resultId);
      return item ? item.text : "";
    },

    compareBarcodes(item1, item2, item3) {
       if (!item1 || !item2 || !item3) {
        return null;
      }

      item1 = item1.toUpperCase();
      item2 = item2.toUpperCase();
      item3 = item3.toUpperCase();
 
      if (item1 == item2 && item2 == item3) {
        return 1;
      } else {
        return 0;
      }
    },

    bitToBool(item) {
      if (!item || item == 1 || item == "1") {
        return true;
      } else {
        return false;
      }
    },
 

    async updateworkOrderBarcodeVerificationObj() {


      this.workOrderBarcodeVerificationObj.workOrderId = this.$route.params.id;
      this.workOrderBarcodeVerificationObj.cartonSampleSize = this.workOrderBarcodeVerificationObj.cartonSampleSize == null ? null : this.workOrderBarcodeVerificationObj.cartonSampleSize;
      this.workOrderBarcodeVerificationObj.cartonInspectionLevel = this.workOrderBarcodeVerificationObj.cartonInspectionLevel == null ? this.isWalmart() ? "S3" : "Square Root" : this.workOrderBarcodeVerificationObj.cartonInspectionLevel;
   
      this.workOrderBarcodeVerificationObj.productSampleSize = this.workOrderBarcodeVerificationObj.productSampleSize == null ? null : this.workOrderBarcodeVerificationObj.productSampleSize;
      this.workOrderBarcodeVerificationObj.productInspectionLevel = this.workOrderBarcodeVerificationObj.productInspectionLevel == null ? this.workOrder.aqlLevels : this.workOrderBarcodeVerificationObj.productInspectionLevel;
      this.workOrderBarcodeVerificationObj.productResult = this.workOrderBarcodeVerificationObj.productResult == null ? null : this.workOrderBarcodeVerificationObj.productResult == "Pass" ? 1 : 0;
      //this.workOrderBarcodeVerificationObj.cartonSamplingMethodology = this.workOrderBarcodeVerificationObj.cartonSamplingMethodology == null ? this.workOrder.samplingMethodology : this.workOrderBarcodeVerificationObj.cartonSamplingMethodology;
      this.workOrderBarcodeVerificationObj.cartonSamplingMethodology =  this.workOrder.samplingMethodology;
      
      //this.workOrderBarcodeVerificationObj.productSamplingMethodology = this.workOrderBarcodeVerificationObj.productSamplingMethodology == null ? this.workOrder.samplingMethodology : this.workOrderBarcodeVerificationObj.productSamplingMethodology;
      this.workOrderBarcodeVerificationObj.productSamplingMethodology =  this.workOrder.samplingMethodology;
      
      this.workOrderBarcodeVerificationObj.cartonResultId = this.workOrderBarcodeVerificationObj.cartonResultId == null ? null : this.workOrderBarcodeVerificationObj.cartonResultId;
      this.workOrderBarcodeVerificationObj.productResultId = this.workOrderBarcodeVerificationObj.productResultId == null ? null : this.workOrderBarcodeVerificationObj.productResultId;
      this.workOrderBarcodeVerificationObj.cartonPendingCodeId = this.workOrderBarcodeVerificationObj.cartonPendingCodeId == null ? null : this.workOrderBarcodeVerificationObj.cartonPendingCodeId;
      this.workOrderBarcodeVerificationObj.productPendingCodeId = this.workOrderBarcodeVerificationObj.productPendingCodeId == null ? null : this.workOrderBarcodeVerificationObj.productPendingCodeId;
      this.workOrderBarcodeVerificationObj.cartonRemarks = this.workOrderBarcodeVerificationObj.cartonRemarks == null ? null : this.workOrderBarcodeVerificationObj.cartonRemarks;
      this.workOrderBarcodeVerificationObj.productRemarks = this.workOrderBarcodeVerificationObj.productRemarks == null ? null : this.workOrderBarcodeVerificationObj.productRemarks;
      this.workOrderBarcodeVerificationObj.recompute = false;
      await this.utilityFn.getServerDataAsync("post", this.apiList.upsertWorkOrderBarcodeVerification, this.workOrderBarcodeVerificationObj);
    },


    async populateWorkorderList() {
      this.loadingDf = true;
      this.$store.dispatch("navigation/setLoadingPage",{show: this.loadingDf });
      this.workOrderId = this.$route.params.id;
      this.loadingBarcodeVerificationList = true;
      const result = await this.utilityFn.getServerDataAsync("get", this.apiList.workOrderDetailsBarCode + "/" + this.workOrderId, {});
      if (result && result.data) {
          this.cartonbarcodeVerificationObj.totalQuantity = result.data[0].products.reduce(function(tot, arr) {
            return tot + arr.cartonQty;
          }, 0);

          this.productbarcodeVerificationObj.totalQuantity = result.data[0].products.reduce(function(tot, arr) {
            return tot + arr.qty;
          }, 0);
          this.workOrder = result.data[0];
          console.log('this.workOrder =>',this.workOrder)


          this.workOrderProducts = result.data[0].products.map((x) => {
            x.workOrderProductResults = x.workOrderProductResults || {};
            x.workOrderProductBarcodeVerificationResults = x.workOrderProductBarcodeVerificationResults || {};
            return x;
          });

          this.skuDisplayList = result.data[0].products.map((x) => {
            return {
              workOrderProductID: x.id,
              skuDisplay: x.skuDisplay,
              po: x.po,
              clientProductId: x.clientProductId,
            };
          });

          
          this.cartonBarcodeList = [];
          this.barcodeList = [];
          this.loadCartonProductBarcodeDetails(this.workOrderProducts);         
          this.initQuantityVerification();
          this.populateWorkorderLevel(this.workOrder.barcodeVerification);
          this.loadingDf = false;
          this.$store.dispatch("navigation/setLoadingPage",{show: this.loadingDF });
          if (this.workOrder.barcodeVerification.recompute){
            this.calculateSampleSize(this.workOrderBarcodeVerificationObj.cartonInspectionLevel, this.workOrderBarcodeVerificationObj.cartonSamplingMethodology, "carton", false);
            this.calculateSampleSize(this.workOrderBarcodeVerificationObj.productInspectionLevel, this.workOrderBarcodeVerificationObj.productSamplingMethodology, "product", false);
          }
        }
    },



    loadCartonProductBarcodeDetails(workorderProduct) {
      workorderProduct.forEach((x) => {
        if (x.workOrderProductBarcodeVerificationResults) {
          if (x.workOrderProductBarcodeVerificationResults.length > 0) {
            this.loadBarcodeDetails(_.orderBy(x.workOrderProductBarcodeVerificationResults, ['po', 'skuDisplay','orderNo'],['asc']) );
          }
        }
      });
    },

    loadBarcodeDetails(workOrderProductBarcodeVerificationResults) {
      if (workOrderProductBarcodeVerificationResults) {
        if (workOrderProductBarcodeVerificationResults.length > 0) {
      
          workOrderProductBarcodeVerificationResults.forEach((res) => {
            if (res.isCarton) {
              var barcodeTypeId = this.walmartUsa.toLowerCase() == this.retailMarketCdf.toLowerCase() ? 5 : null;
              if (res.barcodeTypeId != null){
                barcodeTypeId = res.barcodeTypeId;
              }
              const addedCartonBarcode = {
      
                id: res.id,
                clientProductId: this.getSKUDisplay(res.workOrderProductId).clientProductId,
                skuDisplay: this.getSKUDisplay(res.workOrderProductId).skuDisplay,
                workOrderProductId: res.workOrderProductId,
                po: this.getSKUDisplay(res.workOrderProductId).po,
                isCarton: res.isCarton,
                result: res.result,
                scannable: res.scannable,
                codeOnLabel: res.codeOnLabel,
                codeScanned: res.codeScanned,
                codeSpecified: res.codeSpecified,
                barcodeTypeId: barcodeTypeId,
                barcodeLabel: res.barcodeLabel,
                orderNo: res.orderNo,
                sectionId: 4,
              };
              this.cartonBarcodeList.push(addedCartonBarcode);
            } else {

              let isExistingCodeSpecified = this.barcodeList.some((x) => x.workOrderProductId == res.workOrderProductId && x.codeSpecified == res.codeSpecified && x.codeScanned == res.codeScanned && res.orderNo != 1)
              if(!isExistingCodeSpecified){
              
                var barcodeTypeId = this.walmartUsa.toLowerCase() == this.retailMarketCdf.toLowerCase() ? 6 : null;
                if (res.barcodeTypeId != null){
                  barcodeTypeId = res.barcodeTypeId;
                }

                const addedBarcode = {
                
                  id: res.id,
                  clientProductId: this.getSKUDisplay(res.workOrderProductId).clientProductId,
                  skuDisplay: this.getSKUDisplay(res.workOrderProductId).skuDisplay,
                  workOrderProductId: res.workOrderProductId,
                  po: this.getSKUDisplay(res.workOrderProductId).po,
                  isCarton: res.isCarton,
                  result: res.result,
                  scannable: res.scannable,
                  codeOnLabel: res.codeOnLabel,
                  codeScanned: res.codeScanned,
                  codeSpecified: res.codeSpecified,
                  barcodeTypeId: barcodeTypeId,
                  barcodeLabel: res.barcodeLabel,
                  orderNo: res.orderNo,
                  sectionId: 4,
                };
                this.barcodeList.push(addedBarcode);
              }
            }
          
          });

          this.cartonBarcodeList = _.orderBy(this.cartonBarcodeList, ['po', 'skuDisplay','orderNo'],['asc']);
          this.barcodeList = _.orderBy(this.barcodeList, ['po', 'skuDisplay','orderNo'],['asc']);
          this.cartonBarcodeList.forEach((x, i) => x.index = (i + 1));
          this.barcodeList.forEach((x, i) => x.index = (i + 1));
        }
      }
      this.loadingBarcodeVerificationList = false
      this.cloneCartonBarcodeList = _.cloneDeep(this.cartonBarcodeList)
      this.cloneBarcodeList = _.cloneDeep(this.barcodeList)
    },

    initQuantityVerification() {
      let existingQuantityVerificationList = [];
      this.workOrderProducts.forEach((x) => {
        if (x.workOrderProductQuantityVerificationResults) {
          if (x.workOrderProductQuantityVerificationResults.length > 0) {
            x.workOrderProductQuantityVerificationResults.forEach((item) => {
              existingQuantityVerificationList.push(item);
            });
          }
        }
      });
      if (existingQuantityVerificationList.length > 0) {
        this.loadQuantityVerificationProductDetails(existingQuantityVerificationList);
      }
    },

    loadQuantityVerificationProductDetails(quantityVerificationProductDetails) {
      this.productQuantityVerificationList = [];
      if (quantityVerificationProductDetails) {
        if (quantityVerificationProductDetails.length > 0) {
          let quantityVerificationList = [];
          quantityVerificationProductDetails.forEach((res) => {
            const quantityVerification = {
              id: res.id,
              workOrderProductId: res.workOrderProductId,
              productID: this.getSKUDisplay(res.workOrderProductId).productID,
              skuDisplay: this.getSKUDisplay(res.workOrderProductId).skuDisplay,
              po: this.getSKUDisplay(res.workOrderProductId).po,
              cartonQty: this.getSKUDisplay(res.workOrderProductId).cartonQty,
              qty: this.getSKUDisplay(res.workOrderProductId).qty,
              caseCountProduct: res.caseCountProduct,
              availableQuantityCarton: res.availableQuantityCarton,
              sampleSizeCarton: res.sampleSizeCarton,
              availableQuantityProduct: res.availableQuantityProduct,
              excessShortQuantity: res.excessShortQuantity,
              sampleSizeProduct: res.sampleSizeProduct,
              sampleSizeCarton2: res.sampleSizeCarton,
              sampleSizeCartonTotal: res.sampleSizeCartonTotal,
              sampleSizeProduct2: res.sampleSizeProduct,
              sampleSizeProductTotal: res.sampleSizeProductTotal,
              exportCartonNumbersOpened: res.exportCartonNumbersOpened,
              remarks: res.remarks,
              packedQty: res.packedQty,
              underPackingQty: res.underPackingQty,
              underProductionFinishingQty: res.underProductionFinishingQty,
              orderNumber: res.orderNumber,
            };
            this.productQuantityVerificationList.push(quantityVerification);
          });
        }
      }

      console.log("this.productQuantityVerificationList", this.productQuantityVerificationList);
    },

    _productQuantityVerificationList() {
      const list = [];
      this.productQuantityVerificationList.forEach((item) => {
        const index = list.findIndex((x) => x.productID === item.productID);
        // check if product is already in the list
        if (index === -1) {
          list.push(item);
        } else if (index !== -1) {
          if (list[index].po === item.po) {
            list[index].availableQuantityProduct = list[index].availableQuantityProduct + item.availableQuantityProduct;
            list[index].qty = list[index].qty + item.qty;
            list[index].availableQuantityCarton = list[index].availableQuantityCarton + item.availableQuantityCarton;
          } else {
            list.push(item);
          }
        }
      });

      return list;
    },

   

    populateWorkorderLevel(workOrderBarcodeVerificationDetails) {
      console.log("workOrderBarcodeVerificationDetails", workOrderBarcodeVerificationDetails);
 
      let cartonResult = this.calculateBarcodeVerificationResult('tab-1'); 
      let productResult = this.calculateBarcodeVerificationResult('tab-2');

        if(!workOrderBarcodeVerificationDetails){
          //Carton Tab
          this.workOrderBarcodeVerificationObj.cartonResultId =  cartonResult 
          this.workOrderBarcodeVerificationObj.cartonPendingCodeId = null;     
          this.workOrderBarcodeVerificationObj.cartonRemarks = null;        
          this.workOrderBarcodeVerificationObj.cartonInspectionLevel = this.isWalmart() ? "S3" : "Square Root";
          this.workOrderBarcodeVerificationObj.cartonSamplingMethodology =this._samplingPlanLists.samplingMethodology ? this._samplingPlanLists.samplingMethodology: this.workOrder.samplingMethodology;
          this.workOrderBarcodeVerificationObj.cartonSampleSize = null;

          //Product Tab   
          this.workOrderBarcodeVerificationObj.productResultId =  productResult; 
          this.workOrderBarcodeVerificationObj.productPendingCodeId =null;    
          this.workOrderBarcodeVerificationObj.productRemarks =null;
          this.workOrderBarcodeVerificationObj.productInspectionLevel = this.workOrder.aqlLevels;
          this.workOrderBarcodeVerificationObj.productSamplingMethodology =this._samplingPlanLists.samplingMethodology ? this._samplingPlanLists.samplingMethodology: this.workOrder.samplingMethodology;
          this.workOrderBarcodeVerificationObj.productSampleSize = null;
        }
        else{
          //Carton Tab
          this.workOrderBarcodeVerificationObj.cartonResultId =  workOrderBarcodeVerificationDetails.cartonResultId  == null ? cartonResult : workOrderBarcodeVerificationDetails.cartonResultId  
          this.workOrderBarcodeVerificationObj.cartonPendingCodeId = workOrderBarcodeVerificationDetails.cartonPendingCodeId;     
          this.workOrderBarcodeVerificationObj.cartonRemarks = workOrderBarcodeVerificationDetails.cartonRemarks;        
          this.workOrderBarcodeVerificationObj.cartonInspectionLevel = workOrderBarcodeVerificationDetails.cartonInspectionLevel
          this.workOrderBarcodeVerificationObj.cartonSamplingMethodology = this._samplingPlanLists.samplingMethodology ? this._samplingPlanLists.samplingMethodology: this.workOrder.samplingMethodology;   
          this.workOrderBarcodeVerificationObj.cartonSampleSize = workOrderBarcodeVerificationDetails.cartonSampleSize;
          this.workOrderBarcodeVerificationObj.cartonResultCode = workOrderBarcodeVerificationDetails.cartonResultCode;

          //Product Tab  
      
          this.workOrderBarcodeVerificationObj.productResultId =  workOrderBarcodeVerificationDetails.productResultId  == null ? productResult : workOrderBarcodeVerificationDetails.productResultId      
          this.workOrderBarcodeVerificationObj.productPendingCodeId = workOrderBarcodeVerificationDetails.productPendingCodeId;    
          this.workOrderBarcodeVerificationObj.productRemarks = workOrderBarcodeVerificationDetails.productRemarks;
          this.workOrderBarcodeVerificationObj.productInspectionLevel = workOrderBarcodeVerificationDetails.productInspectionLevel
          this.workOrderBarcodeVerificationObj.productSamplingMethodology = this._samplingPlanLists.samplingMethodology ? this._samplingPlanLists.samplingMethodology: this.workOrder.samplingMethodology;
          this.workOrderBarcodeVerificationObj.productSampleSize = workOrderBarcodeVerificationDetails.productSampleSize;    
          this.workOrderBarcodeVerificationObj.productResultCode = workOrderBarcodeVerificationDetails.productResultCode;    
        }
      
  

     
    },

    getSquareRootCarton(quantity, totalCartons) {
      let squareRootCarton = null;
      if (this.workOrderProducts.length > 1 && this.workOrderBarcodeVerificationObj.cartonSamplingMethodology == "Combined Sample Size") {
        return (squareRootCarton = Math.ceil((Math.sqrt(totalCartons) / totalCartons) * quantity));
      }
      return squareRootCarton;
    },

    onChangeInspectionLevel(value, tab) {
      if (tab == "carton") {
        this.calculateSampleSize(value, this.workOrderBarcodeVerificationObj.cartonSamplingMethodology, tab, false);
      } else {
        this.calculateSampleSize(value, this.workOrderBarcodeVerificationObj.productSamplingMethodology, tab, false);
      }
    },
    onChangeSamplingMethodology(value, tab) {
      if (tab == "carton") {
        this.calculateSampleSize(this.workOrderBarcodeVerificationObj.cartonInspectionLevel, value, tab, false);
      } else {
        this.calculateSampleSize(this.workOrderBarcodeVerificationObj.productInspectionLevel, value, tab, false);
      }
    },

    calculateSampleSize(inspectionLevel, samplingMethodology, tab, isInitialCalculate) {
      const uniqueSkuList = _.uniq(this.workOrder.products.map((x) => x.productID));
      const uniquePoList = _.uniq(this.workOrder.products.map((x) => x.po));

      if (tab == "carton") {
        if (inspectionLevel == "Fixed" || inspectionLevel == "S3") {
          if (isInitialCalculate) {
            this.workOrderBarcodeVerificationObj.cartonSampleSize = this.workOrderBarcodeVerificationObj.cartonSampleSize == null ? null : this.workOrderBarcodeVerificationObj.cartonSampleSize;
          } else {
            this.workOrderBarcodeVerificationObj.cartonSampleSize = null;
          }
          return;
        }
        if (inspectionLevel == "Square Root") {
          const totalAvailCartonQty = this.productQuantityVerificationList.reduce((prev, cur) => prev + cur.availableQuantityCarton, 0);
          const sqrtTotalAvailCartonQty = Math.ceil(Math.sqrt(totalAvailCartonQty));
          const sumAllSqrtPerSkuCartonQty = this.productQuantityVerificationList.reduce((prev, cur) => prev + Math.ceil(Math.sqrt(cur.availableQuantityCarton)), 0);

          if (this.workOrderProducts.length == 1) {
            return (this.workOrderBarcodeVerificationObj.cartonSampleSize = Math.ceil(Math.sqrt(totalAvailCartonQty)));
          }

          if (this.workOrderProducts.length > 1 && samplingMethodology == "Combined Sample Size") {
            //const sqrtTotal = this.productQuantityVerificationList.reduce((prev, cur) => Number(prev) + this.getSquareRootCarton(cur.availableQuantityCarton || 0, totalAvailCartonQty), 0);
            const sqrtTotal = Math.ceil(Math.sqrt(this._samplingPlanLists.totalCartonPOQty))
            return (this.workOrderBarcodeVerificationObj.cartonSampleSize = sqrtTotal);
          }

          if (this.workOrderProducts.length > 1) {
            return (this.workOrderBarcodeVerificationObj.cartonSampleSize = sumAllSqrtPerSkuCartonQty);
          }
        }
      } else {
        if (inspectionLevel == "Fixed") {
          if (isInitialCalculate) {
            this.workOrderBarcodeVerificationObj.productSampleSize = this.workOrderBarcodeVerificationObj.productSampleSize == null ? null : this.workOrderBarcodeVerificationObj.productSampleSize;
          } else {
            this.workOrderBarcodeVerificationObj.productSampleSize = null;
          }
          return;
        } else {
          const aql = {
            aqlCritical: "0",
            aqlMinor: "0",
            aqlMajor: "0"
          }
          this.workOrderBarcodeVerificationObj.productSampleSize = this.samPlan.getSamplingPlanByInspectionLevelAndSamplingMethodology(inspectionLevel, samplingMethodology, aql).totalProductSampleSize;
        }
      }
    },

    hasAllTrue(target) {
      let resultValues = [];

      for (var member in target) {
        if (target[member].result == null) {
          return null;
        } else {
          resultValues.push(target[member].result);
        }
      }

      if (resultValues.length == 0) {
        return null;
      }

      let checker = resultValues.every((v) => v === true);
      return checker;
    },

    close() {
      this.cartonDialog = false;
      this.productDialog = false;
      this.deleteDialog = false;
    },

    getTypeBarcode(typeId) {
      if (!typeId || typeId == "") {
        return null;
      }
      let barcodetype = this.barcodeTypeOptions.find((x) => x.id === typeId).description;
      return barcodetype;
    },

    getIdBarcodeType(description) {
      if (!description || description == "") {
        return null;
      }
      let barcodetype = this.barcodeTypeOptions.find((x) => x.description === description).id;
      return barcodetype;
    },

    getSKUDisplay(productId) {
      if (!productId || productId == "" || productId == null) {
        return null;
      }
      let skuDisplay = this.skuDisplayList.find((x) => x.workOrderProductID === productId);
      return skuDisplay;
    },

    delete_item(item,index,tab) {
      this.resetDialog();
      (this.deletedItem.id = item.id), (this.deletedItem.skuDisplay = item.skuDisplay);
      (this.deletedItem.workOrderProductId = item.workOrderProductId),
      (this.deletedItem.codeSpecified = item.codeSpecified),
      (this.deletedItem.codeOnLabel = item.codeOnLabel),
      (this.deletedItem.codeScanned = item.codeScanned),
      (this.deletedItem.barcodeTypeItems = this.getTypeBarcode(item.barcodeTypeId)),
      (this.deletedItem.barcodeLabel = item.barcodeLabel),
      (this.deletedItem.barcodeResult = this.resultDescription(item.result)),
      (this.deletedItem.orderNo = item.orderNo),
      (this.deleteDialog = true);
    },

    show_dialog(tab, item) {
      let newItem = [];

      newItem.id = null;
      newItem.skuDisplay = item.id;
      newItem.workOrderProductId = item.workOrderProductId;
      newItem.clientProductId = item.clientProductId;
      newItem.skuDisplay = item.skuDisplay;
      newItem.isCarton = null;
      newItem.result = null;
      newItem.scannable = null;
      newItem.codeOnLabel = null;
      newItem.codeScanned = null;
      newItem.codeSpecified = null;
      newItem.barcodeTypeId = null;
      newItem.barcodeLabel = null;

      if (tab == "cartonDialog") {
        newItem.orderNo = this.getMaxValue(item.workOrderProductId, true) + 1;
        newItem.isCarton = true;
        this.carton_barcode_edit_row(newItem);
      } else {
        newItem.orderNo = this.getMaxValue(item.workOrderProductId, false) + 1;
        newItem.isCarton = false;
        this.product_barcode_edit_row(newItem);
      }
    },

   

    resetDialog() {
      this.cartonEditedItem.id = null;
      this.cartonEditedItem.specifiedLabel = null;
      this.cartonEditedItem.codeOnLabel = null;
      this.cartonEditedItem.codeScanned = null;
      this.cartonEditedItem.barcodeTypeItems = null;
      this.cartonEditedItem.barcodeLabel = null;
      this.cartonEditedItem.barcodeResult = null;
      this.cartonEditedItem.workOrderProductID = null;
      this.cartonEditedItem.scannable = null;
      this.cartonEditedItem.orderNo = null;

      this.productEditedItem.id = null;
      this.productEditedItem.codeSpecified = null;
      this.productEditedItem.codeOnLabel = null;
      this.productEditedItem.codeScanned = null;
      this.productEditedItem.barcodeTypeItems = null;
      this.productEditedItem.barcodeLabel = null;
      this.productEditedItem.barcodeResult = null;
      this.productEditedItem.workOrderProductID = null;
      this.productEditedItem.scannable = null;
      this.productEditedItem.orderNo = null;

      this.deletedItem.id = null;
      this.deletedItem.skuDisplay = null;
      this.deletedItem.workOrderProductID = null;
      this.deletedItem.codeSpecified = null;
      this.deletedItem.codeOnLabel = null;
      this.deletedItem.codeScanned = null;
      this.deletedItem.barcodeTypeItems = null;
      this.deletedItem.barcodeLabel = null;
      this.deletedItem.barcodeResult = null;
    },

    carton_barcode_edit_row(item) {
      this.resetDialog();
      this.isEdit = item.id == null ? false : true;
      this.cartonDialog = true;
      this.cartonEditedItem.clientProductId = item.clientProductId;
      this.cartonEditedItem.skuDisplay = item.skuDisplay;
      this.cartonEditedItem.id = item.id == undefined ? null : item.id;
      this.cartonEditedItem.codeSpecified = item.codeSpecified == null ? null : item.codeSpecified;
      this.cartonEditedItem.codeOnLabel = item.codeOnLabel == null ? null : item.codeOnLabel;
      this.cartonEditedItem.codeScanned = item.codeScanned == null ? null : item.codeScanned;
      this.cartonEditedItem.barcodeTypeItems = item.barcodeTypeId == null ? null : item.barcodeTypeId;
      this.cartonEditedItem.barcodeLabel = item.barcodeLabel == null ? null : item.barcodeLabel;
      this.cartonEditedItem.barcodeResult = item.result == null ? null : item.result;
      this.cartonEditedItem.workOrderProductID = item.workOrderProductId;
      this.cartonEditedItem.scannable = item.scannable == null ? false : item.scannable;
      this.cartonEditedItem.orderNo = item.orderNo == null ? null : item.orderNo;
      this.checkFromCanyon(this.cartonEditedItem.workOrderProductID, this.cartonEditedItem.codeSpecified, this.cartonEditedItem.orderNo, "carton");
    },

    product_barcode_edit_row(item) {
      this.resetDialog();
      this.isEdit = item.id == null ? false : true;
      this.productDialog = true;
      this.productEditedItem.id = item.id == undefined ? null : item.id;
      this.productEditedItem.clientProductId = item.clientProductId;
      this.productEditedItem.skuDisplay = item.skuDisplay;
      this.productEditedItem.codeSpecified = item.codeSpecified == null ? null : item.codeSpecified;
      this.productEditedItem.codeOnLabel = item.codeOnLabel == null ? null : item.codeOnLabel;
      this.productEditedItem.codeScanned = item.codeScanned == null ? null : item.codeScanned;
      this.productEditedItem.barcodeTypeItems = item.barcodeTypeId == null ? null : item.barcodeTypeId;
      this.productEditedItem.barcodeLabel = item.barcodeLabel == null ? null : item.barcodeLabel;
      this.productEditedItem.barcodeResult = item.result == null ? null : item.result;
      this.productEditedItem.workOrderProductID = item.workOrderProductId;
      this.productEditedItem.scannable = item.scannable == null ? false : item.scannable;
      this.productEditedItem.orderNo = item.orderNo == null ? null : item.orderNo;
      this.checkFromCanyon(this.productEditedItem.workOrderProductID, this.productEditedItem.codeSpecified, this.productEditedItem.orderNo, "product");
    },

    showBarcodeModeDialog(tab) {},

    calculateBarcodeVerificationResult(tab) {
                switch(tab) {
                    case 'tab-1' :
                            // Non-Conformed [If 1 SKU result is null]
                            if (this.cartonBarcodeList.filter(p => p.result == null).length  > 0) {
                                this.workOrderBarcodeVerificationObj.cartonResultId = null;
                            }
                      
                             // Non-Conformed [If 1 SKU result is FAIL]
                            else if (this.cartonBarcodeList.filter(p => p.result == 0).length > 0) {
                                this.workOrderBarcodeVerificationObj.cartonResultId =this.getBarcodeResultByName('Fail');
                            }
                            // PENDING [If 1 SKU result is PENDING and no FAIL]
                            else if (this.cartonBarcodeList.filter(p => p.result == 2).length > 0) {
                                this.workOrderBarcodeVerificationObj.cartonResultId = '2';
                            }
                            //  N/A [If All SKUs result is N/A]
                            else if (this.cartonBarcodeList.filter(p => p.result == 3).length == this.cartonBarcodeList.length) {
                                this.workOrderBarcodeVerificationObj.cartonResultId = '3';
                            }
                            // Conform [If All SKUs result is PASS or with N/A]
                            else {
                                this.workOrderBarcodeVerificationObj.cartonResultId = this.getBarcodeResultByName('Pass');
                            }
                            break;
                    case 'tab-2' :
                            // Non-Conformed [If 1 SKU result is null]
                            if (this.barcodeList.filter(p => p.result == null).length  > 0) {
                                this.workOrderBarcodeVerificationObj.productResultId = null;
                            }
                        
                            // Non-Conformed [If 1 SKU result is FAIL]
                            else if (this.barcodeList.filter(p => p.result == 0).length > 0) {
                                this.workOrderBarcodeVerificationObj.productResultId = this.getBarcodeResultByName('Fail');
                            }
                            // PENDING [If 1 SKU result is PENDING and no FAIL]
                            else if (this.barcodeList.filter(p => p.result == 2).length > 0) {
                                this.workOrderBarcodeVerificationObj.productResultId = '2';
                            }
                            //  N/A [If All SKUs result is N/A]
                            else if (this.barcodeList.filter(p => p.result == 3).length == this.barcodeList.length) {
                                this.workOrderBarcodeVerificationObj.productResultId = '3';
                            }
                            // Conform [If All SKUs result is PASS or with N/A]
                            else {
                                this.workOrderBarcodeVerificationObj.productResultId = this.getBarcodeResultByName('Pass');
                            }
                            break; 
                }
            },

    openBarcodeScanner(tab, item,index) {
      console.log('index => ' ,index)
      this.currentIndex = index
      //必须要先选择编码否则会报错
      if ((tab == "cartonCodeonLabel" || tab == "cartonCodeScanned") && item.barcodeTypeId == null) {
        this.snackbar = true;
        this.message = "Barcode Type is required";
        return;
      }

      if ((tab == "productCodeonLabel" || tab == "productCodeScanned") && item.barcodeTypeId == null) {
        this.snackbar = true;
        this.message = "Barcode Type is required";
        return;
      }

      this.barcodeDialog = true;
      this.codeScannerKey += 1;
      if (tab == "cartonCodeonLabel") {
        this.barcodeData.barcodeType = this.getTypeBarcode(item.barcodeTypeId);
        this.barcodeData.type = "LiveStream";
        this.barcodeData.screen = tab;
      }

      if (tab == "cartonCodeScanned") {
        this.barcodeData.barcodeType = this.getTypeBarcode(item.barcodeTypeId);
        this.barcodeData.type = "LiveStream";
        this.barcodeData.screen = tab;
      }

      if (tab == "productCodeonLabel") {
        this.barcodeData.barcodeType = this.getTypeBarcode(item.barcodeTypeId);
        this.barcodeData.type = "LiveStream";
        this.barcodeData.screen = tab;
      }
      if (tab == "productCodeScanned") {
        this.barcodeData.barcodeType = this.getTypeBarcode(item.barcodeTypeId);
        this.barcodeData.type = "LiveStream";
        this.barcodeData.screen = tab;
      }
    },

    barcodeScannerChangeValue(item) {
      let barcodeItem = null
      if (item.screen == "cartonCodeonLabel") {
        this.cartonBarcodeList[this.currentIndex].scannable = item.isScanned;
        this.cartonBarcodeList[this.currentIndex].codeOnLabel = item.scannedValue;
        barcodeItem = this.cartonBarcodeList[this.currentIndex]
      }
      if (item.screen == "cartonCodeScanned") {
        this.cartonBarcodeList[this.currentIndex].codeScanned = item.scannedValue;
         barcodeItem = this.cartonBarcodeList[this.currentIndex]
      }
      if (item.screen == "productCodeonLabel") {
        this.barcodeList[this.currentIndex].scannable = item.isScanned;
        this.barcodeList[this.currentIndex].codeOnLabel = item.scannedValue;
         barcodeItem = this.barcodeList[this.currentIndex]
      }
      if (item.screen == "productCodeScanned") {
        this.barcodeList[this.currentIndex].codeScanned = item.scannedValue;
        barcodeItem = this.barcodeList[this.currentIndex]
      }

      this.changecalculateRowBarcodeResult(barcodeItem)

      this.currentIndex = null;

      
    },

    checkFromCanyon(workorderProductId, codeSpecified, orderNo, tab) {
      var obj = this.workOrderProducts;
      if (tab == "carton") {
        if (codeSpecified == null || codeSpecified == "") {
          this.cartonEditedItem.isFromCanyon = false;
          return;
        }
        if (orderNo == 1 && codeSpecified != null) {
          for (let item in obj) {
            if (obj[item].id == workorderProductId && obj[item].cartonBarcode == codeSpecified) {
              this.cartonEditedItem.isFromCanyon = true;
              return;
            }
          }
        }
        this.cartonEditedItem.isFromCanyon = false;
        return;
      } else {
        if (codeSpecified == null || codeSpecified == "") {
          this.productEditedItem.isFromCanyon = false;
          return;
        }

        if (orderNo == 1 && codeSpecified != null) {
          for (let item in obj) {
            if (obj[item].id == workorderProductId && obj[item].barcode == codeSpecified) {
              this.productEditedItem.isFromCanyon = true;
              return;
            }
          }
        }
        this.productEditedItem.isFromCanyon = false;
        return;
      }
    },

    isDataExist(value, obj) {
      let result = {
        valid: true,
        message: [],
      };

      obj = obj.map((x) => {
        x.codeSpecified = x.codeSpecified ? x.codeSpecified.toUpperCase() : null;
        x.codeOnLabel = x.codeOnLabel ? x.codeOnLabel.toUpperCase() : null;
        x.codeScanned = x.codeScanned ? x.codeScanned.toUpperCase() : null;
        return x;
      });

      for (let item in obj) {
        if (obj[item].id != value.id && (value.codeSpecified.toUpperCase() == obj[item].codeSpecified || value.codeSpecified.toUpperCase() == obj[item].codeOnLabel || value.codeSpecified.toUpperCase() == obj[item].codeScanned)) {
          result.message.push("Code Specified");
          result.valid = false;
        }

        if (obj[item].id != value.id && (value.codeOnLabel.toUpperCase() == obj[item].codeOnLabel || value.codeOnLabel.toUpperCase() == obj[item].codeSpecified || value.codeOnLabel.toUpperCase() == obj[item].codeScanned)) {
          result.message.push("Barcode Printed on Label");
          result.valid = false;
        }
        if (obj[item].id != value.id && (value.codeScanned.toUpperCase() == obj[item].codeOnLabel || value.codeScanned.toUpperCase() == obj[item].codeSpecified || value.codeScanned.toUpperCase() == obj[item].codeScanned)) {
          result.message.push("Barcode Scanned");
          result.valid = false;
        }
      }

      return result;
    },

    async submit_item(sectionSubmitted = false) {
      const errorMessage = await this.validateForm();
      if(errorMessage !== "") {
        this.snackbar = true;
        this.message = errorMessage;
        return;
      }

      if(!this.isCartonResultValid || !this.isProductResultValid) {
        this.snackbar = true;
        this.message = "Missing Input Required Fields";
        return;
      }
      
      this.sectionSubmitted = sectionSubmitted;

      const section = {
        sectionId: this.sectionId,
        workOrderId: this.$route.params.id,
      };
      
      await this.utilityFn.getServerDataAsync("post", this.apiList.submitInspectionSection, section)
      this.sectionData.BarcodeVerification.isSectionSubmitted = true;
      this.$store.dispatch("inspection/setSectionData", this.sectionData);

      const index = this._workOrderDetails.workOrderInspectionSections.findIndex(x => x.sectionId == this.sectionId)
      if (index !== -1) {
        this._workOrderDetails.workOrderInspectionSections[index].submittedDate = new Date();
        this.$store.dispatch("inspection/setWorkOrderDetails", _.cloneDeep(this._workOrderDetails));
      }
    },

    go_upload_photo() {
      this.$router.push({
        name: "uploadPhotoGalleryId",
        params: {
          id: this.$route.params.id,
          name: this.sectionData.BarcodeVerification.name,
          sectionId: this.sectionData.BarcodeVerification.id,
        },
        query: {
          name: this.sectionData.BarcodeVerification.name,
          sectionId: this.sectionData.BarcodeVerification.id,
        },
      });
    },

    async postInitialDefaultValues() {
      await this.updateworkOrderBarcodeVerificationObj();
      auditLog.resetTransactionCode();
    },

    onChange() {
      this.logChanges();
    },

    logChanges(log = true) {
      window.sessionStorage.setItem("EditDataLogging", log);
    },

    charCount(item) {
      if (item.cartonRemarks.length >= this.maxcharacter) {
        var remainCharacters = this.maxcharacter - item.cartonRemarks.length;

        if (remainCharacters <= -1) {
          if (remainCharacters < -1) {
            item.cartonRemarks = item.cartonRemarks.slice(0, 200);
          } else {
            item.cartonRemarks = item.cartonRemarks.slice(0, -1);
          }
        }
      }
    },

    copyAboveRowCarton(index) {
      const aboveRow = this.cartonBarcodeList.find(x => x.indexMarkerCarton == (index-1));
      const currentRow = this.cartonBarcodeList.find(x => x.indexMarkerCarton == index);

      currentRow.codeOnLabel = aboveRow.codeOnLabel;
      currentRow.codeScanned = aboveRow.codeScanned;
      currentRow.codeSpecified = aboveRow.codeSpecified;
      currentRow.barcodeTypeId = aboveRow.barcodeTypeId;
      currentRow.barcodeLabel = aboveRow.barcodeLabel;
      currentRow.result = aboveRow.result;
      this.calculateBarcodeVerificationResult('tab-1')
    },

    copyAboveRowProduct(index) {
      const aboveRow = this.barcodeList.find(x => x.indexMarkerProduct == (index-1));
      const currentRow = this.barcodeList.find(x => x.indexMarkerProduct == index);

      currentRow.codeOnLabel = aboveRow.codeOnLabel;
      currentRow.codeScanned = aboveRow.codeScanned;
      currentRow.codeSpecified = aboveRow.codeSpecified;
      currentRow.barcodeTypeId = aboveRow.barcodeTypeId;
      currentRow.barcodeLabel = aboveRow.barcodeLabel;
      currentRow.result = aboveRow.result;
      this.calculateBarcodeVerificationResult('tab-2')
    },

    resetCartonIndexMarkers(e) {
      if (e.page == 1) {
          this.cartonBarcodeList.forEach(x => x.indexMarkerCarton = null );
      }
    },

    resetProductIndexMarkers(e) {
      if (e.page == 1) {
          this.barcodeList.forEach(x => x.indexMarkerProduct = null );
      }
    },

    removeDuplicateBarcode(){
      let newBarcode = [];
      let duplicateBarcode = []

        this.barcodeList.forEach((res) => { 
              let isExistingCodeSpecified =  newBarcode.some((x) => x.workOrderProductId == res.workOrderProductId && x.codeSpecified == res.codeSpecified && x.codeScanned == res.codeScanned && res.orderNo != 1)
              if(!isExistingCodeSpecified){
                newBarcode.push(res);
              }
              else{
                duplicateBarcode.push(res)
              }
          });
        this.barcodeList = newBarcode;
        if(duplicateBarcode.length > 0){  
          this.calculateBarcodeVerificationResult('tab-2')
        }
    },

    // this will be needed by the save feature in the footer component
    async loadData() {
        await this.populateWorkorderList();
    },
    

    // only save when the isFormDirty is true - means user has changed the value in the UI
    // user can still force saving even if nothing has been changed in UI if "forceSave" is true
    // "forceSave" value will come from the Footer Component. The footer component has a Save button
    // that calls this method and will force this method to call the API to save the values
    async saveData(forceSave = false) {
      if (!forceSave && !this.isFormDirty)
        return
      
      try {

        const request1  = async () => {
          if(this.deletedBarcodeProductIds.length > 0) {
            await this.utilityFn.getServerDataAsync('post', this.apiList.bulkDeleteWorkOrderProductBarcodeVerificationResults , this.deletedBarcodeProductIds);
            this.deletedBarcodeProductIds = [];
          }
        }

        const request2 = async () => {
          //Filter cartonBarcodeList new added child row with data & row with Changes
          const mappedCartonBarcodeList = this.cartonBarcodeList.map(x => (_.omit(x, ['indexMarkerCarton'])));
          const mappedCloneCartonBarcodeList = this.cloneCartonBarcodeList.map(x => (_.omit(x, ['indexMarkerCarton'])));

          let filteredCartonBarcodeList = mappedCartonBarcodeList.filter(x => {
            const originalValue = mappedCloneCartonBarcodeList.find(item => item.id == x.id);
            if ((x.scannable == null && x.codeOnLabel == null &&  x.codeSpecified == null &&  x.barcodeLabel == null && x.orderNo != 1 )||
                _.isEqual(x, originalValue))
                return false

            return true;
          })

          //Filter barcodeList new added child row with data & row with Changes
          const mappedBarcodeList = this.barcodeList.map(x => (_.omit(x, ['indexMarkerProduct'])));
          // Temporary Solution: Always save Product Barcode List For Bug: 423466
          //const mappedCloneBarcodeList = this.cloneBarcodeList.map(x => (_.omit(x, ['indexMarkerProduct'])));

          // let filteredBarcodeList = mappedBarcodeList.filter(x => {
          //   const originalValue = mappedCloneBarcodeList.find(item => item.id == x.id);
          //   if ((x.scannable == null && x.codeOnLabel == null &&  x.codeSpecified == null &&  x.barcodeLabel == null && x.orderNo != 1 )||
          //       _.isEqual(x, originalValue))
          //     return false
            
          //   return true;
          // })

          const workOrderProductBarcodeVerificationResultObjList = [...filteredCartonBarcodeList];
          // Temporary Solution: Always save Product Barcode List For Bug: 423466
          workOrderProductBarcodeVerificationResultObjList.push(...mappedBarcodeList);

          if(workOrderProductBarcodeVerificationResultObjList.length > 0) { 
            this.onChange();
            await this.utilityFn.getServerDataAsync("post", this.apiList.upsertWorkOrderProductBarcodeVerificationResult, workOrderProductBarcodeVerificationResultObjList);
            
            // clone latest
            this.cloneCartonBarcodeList = _.cloneDeep(this.cartonBarcodeList);
            this.cloneBarcodeList = _.cloneDeep(this.barcodeList);
          }
        }

        await Promise.all([ this.removeDuplicateBarcode()]);
        await Promise.all([ request1(), request2() ]);

        await Promise.all([ this.updateworkOrderBarcodeVerificationObj(), this.SubmitBarcodeResult()]);

        return true;
      } catch(ex) {
        console.log('saveData error', ex);
        return false;
      }
    },

    async loadWorkOrderRatingOptions() {
      const result = await  this.utilityFn.getServerDataAsync("get", this.apiList.GetCompanyWOResultTypeAliases + "?companyId=" + this._workOrderDetails.clientId, {});
      this.workOrderRatingOptions = result.data.filter((x) => x.description && x.isActive);
    },

    getResultCodes(resultId) {
      const result = this.workOrderResultList.find(x => x.id == resultId);
      const woRating = this.workOrderRatingOptions.find(x => x.aimsSectionRating?.toLowerCase() == result?.name?.toLowerCase());

      if (woRating?.companyWorkOrderResultTypeCodes.length > 0) {
        this.isGenericCodes = false;
        return woRating.companyWorkOrderResultTypeCodes?.filter(x => x.isActive).sort((a,b) => (a.order < b.order) ? -1 : 1) || [];
      }
      this.isGenericCodes = true;
      return this.defaultFailCodeOptions.map(x => ({ code: x }));
    },
    async validateForm() {
      if(this._workOrderDetails.clientName.toLowerCase() !== 'walmart global sourcing' && this._workOrderDetails.clientName.toLowerCase() !== 'asda stores limited') return '';
      if(this._workOrderDetails.isPreShipOrFinalRandom !== true) return '';
      if(!this.workOrderBarcodeVerificationObj.cartonResultId) return 'Missing Result in Carton Barcode Verification';
      if(!this.workOrderBarcodeVerificationObj.productResultId) return 'Missing Result in Product Barcode Verification';

      if(this._workOrderDetails.retailMarket?.fieldValue?.toLowerCase() === 'wal-mart usa') {
        for (let i = 0; i < this.barcodeList.length; i++) {
          if(!this.barcodeList[i].barcodeTypeId) return 'Missing Barcode Type in Product Barcode Verification';
        }
      }
  
      return '';
    },
  },
  computed: {
    ...mapState("inspection", {
      store_token: (state) => state.token,
      _sectionData: (state) => state.sectionData,
      _workOrderDetails: (state) => state.workOrderDetails,
      inspectionSummarySectionResult: (state) => state.inspectionSummarySectionResult,
      _samplingPlanLists: (state) => state.samplingPlanLists,
      formAtomsList: (state) => state.formAtomsList,
      retailMarketCdf: (state) => state.retailMarketCdf
    }),

     filterWorkOrderProductsCarton: function() {
      if (this.batchUpdateSearchListCarton != null || this.batchUpdateSearchListCarton != "") {
        return this.cartonBarcodeList.filter((x) => {
          return (x.skuDisplay || "").toLowerCase().indexOf((this.batchUpdateSearchListCarton || "").toLowerCase()) > -1 || (x.po || "").toLowerCase().indexOf((this.batchUpdateSearchListCarton || "").toLowerCase()) > -1;
        });
      } else {
        return this.cartonBarcodeList;
      }
    },
    filterWorkOrderProductsProduct: function() {
      if (this.batchUpdateSearchListProduct != null || this.batchUpdateSearchListProduct != "") {
        return this.barcodeList.filter((x) => {
          return (x.skuDisplay || "").toLowerCase().indexOf((this.batchUpdateSearchListProduct || "").toLowerCase()) > -1 || (x.po || "").toLowerCase().indexOf((this.batchUpdateSearchListProduct || "").toLowerCase()) > -1;
        });
      } else {
        return this.barcodeList;
      }
    },

    sectionDetail() {
        let section = this._workOrderDetails.workOrderInspectionSections.find(x => x.sectionId == this.sectionId);
        if (section) {
            section.submitterId = this.utilityFn.getResourceId().toLowerCase();
        } else {
            section = {
                workOrderId: this.workOrder.workOrderId,
                sectionId: this.sectionId,
                isHidden: false,
                submitterId: this.utilityFn.getResourceId().toLowerCase()
            };
        }

        return section;
    },

    isSectionDisabled() {
        return this.workOrder.resources.length  == 0 || (this.workOrder.resources.length > 1 && this.sectionDetail.resourceId.toLowerCase() != this.utilityFn.getResourceId().toLowerCase()) || this.sectionDetail.isHidden || (!!this.sectionDetail.submittedDate && this.workOrder.resources.length > 1);
    },

    showButton() {
      return this.workOrder.resources.length > 1;
    },

    errorsProductBarcodeTab(){
        const _errors = [];
            this.barcodeList.forEach((wop, index) => {
                const atIndex = ' at index ' + wop.index;

                if (!wop.codeOnLabel) {
                    _errors.push('Invalid Barcode Printed on Label ' + atIndex);
                }
                if (!wop.codeScanned) {
                    _errors.push('Invalid Barcode Scanned ' + atIndex);
                }
                if (!wop.codeSpecified) {
                    _errors.push('Invalid Code Specified ' + atIndex);
                }
                if (!wop.barcodeTypeId) {
                    _errors.push('Invalid Barcode Type ' + atIndex);
                }
                if (!wop.barcodeLabel) {
                    _errors.push('Invalid Barcode Label ' + atIndex);
                }      
            });

            return _errors;
    },

    errorsCartonBarcodeTab(){
        const _errors = [];
            this.cartonBarcodeList.forEach((wop, index) => {
                const atIndex = ' at index ' + wop.index;

                if (!wop.codeOnLabel) {
                    _errors.push('Invalid Barcode Printed on Label ' + atIndex);
                }
                if (!wop.codeScanned) {
                    _errors.push('Invalid Barcode Scanned ' + atIndex);
                }
                if (!wop.codeSpecified) {
                    _errors.push('Invalid Code Specified ' + atIndex);
                }
                if (!wop.barcodeTypeId) {
                    _errors.push('Invalid Barcode Type ' + atIndex);
                }
                if (!wop.barcodeLabel) {
                    _errors.push('Invalid Barcode Label ' + atIndex);
                }      

            });

            return _errors;
    },
    
    // will be a flag for the submit form in the footer
    isDataLoaded() {
        return this.workOrderProducts.length > 0 && !this.loadingBarcodeVerificationList;
    },

    cartonResultValue() {
      return this.checkBarcodeResult(this.workOrderBarcodeVerificationObj.cartonResultId);
    },

    cartonFailCodeOptions() {
      return this.getResultCodes(this.workOrderBarcodeVerificationObj.cartonResultId);
    },

    productResultValue() {
      return this.checkBarcodeResult(this.workOrderBarcodeVerificationObj.productResultId);
    },

    productFailCodeOptions() {
      return this.getResultCodes(this.workOrderBarcodeVerificationObj.productResultId);
    },

    resultLabel() {
      return (this._workOrderDetails.clientName.toLowerCase() === 'walmart global sourcing' || this._workOrderDetails.clientName.toLowerCase() === 'asda stores limited') && this._workOrderDetails.isPreShipOrFinalRandom  ? 'Result *' : 'Result';
    }
  },


  beforeRouteLeave (to, from, next) {
     if(to.path !== '/error')
     {
        if(!this.loadingDf) {
          this.saveData().then(() => {});
        }
     }
 
    next()
  },

  beforeDestroy() {
    this.logChanges(false);
  },

  watch: {
    isDataLoaded(val1, val2) {
      setTimeout(() => {
        this.isFormReadyCheck = true;
      });
    },

    deletedBarcodeProductIds: {
      handler(val1, val2) {
        this.isFormDirty = this.isFormReadyCheck;
      },
      deep: true
    },

    cartonBarcodeList: {
      handler(val1, val2) {
        this.isFormDirty = this.isFormReadyCheck;
      },
      deep: true
    },
    
    barcodeList: {
      handler(val1, val2) {
        this.isFormDirty = this.isFormReadyCheck;
      },
      deep: true
    },
    
    updateworkOrderBarcodeVerificationObj: {
      handler(val1, val2) {
        this.isFormDirty = this.isFormReadyCheck;
      },
      deep: true
    },
    
    workOrderBarcodeVerificationObj: {
      handler(val1, val2) {
        this.isFormDirty = this.isFormReadyCheck;
      },
      deep: true
    }
  }
};
</script>

<style lang="stylus" scoped>
.workOrderInspectionBarcodeId
    padding 15px 10px

.tab_item
    padding 25px 15px
    overflow hidden
    position relative

.upload_file_box
    border:1px #ccc dashed
    width: 100px
    height: 100px
    line-height: 100px
    text-align: center

.error--text
    color #ff5252 !important
    caret-color #ff5252 !important

.submit-btn
    color: #fff !important

.code-container {
    display: flex;
    flex-direction: column;
}
  
.helper-container {
    margin-bottom: -18px;
    margin-top: -18px;
    margin-left: 75px;
}
</style>
